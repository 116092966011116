import APIs from '@repositories/APIs';
import type {
  ContractCreateRq,
  ContractFilter,
  ContractUpdateRq,
  ContractUpdateStatusRq,
} from '@repositories/contactRepository';
import { makeAutoObservable, runInAction } from 'mobx';
import { ContractModel } from '@models/ContractModel';

class ContractStore {
  private _contracts: Map<string, ContractModel>;

  private _currContract: ContractModel;

  private _totalElements: number;

  constructor() {
    this._contracts = new Map<string, ContractModel>();
    this._totalElements = 0;
    this._currContract = new ContractModel();

    makeAutoObservable<ContractStore>(this);
  }

  async fetchContracts(queries: ContractFilter, useStore?: boolean) {
    const result = await APIs.SoftwareContract.getList(queries);
    if (useStore === undefined || useStore) {
      runInAction(() => {
        this._contracts.clear();
        result.content.forEach(contract => {
          this._contracts.set(contract.id, new ContractModel(contract));
        });
        this._totalElements = result.totalElements;
      });
    }
    return result.content;
  }

  async fetchContract(id: string, useStore?: boolean) {
    const result = await APIs.SoftwareContract.getOne(id);
    if (useStore === undefined || useStore) {
      runInAction(() => {
        const exist = this._contracts.get(id);
        if (exist) {
          exist.update(result);
          this._currContract = exist;
        } else {
          this._currContract = new ContractModel(result);
          this._contracts.set(result.id, this._currContract);
        }
      });
    }
    return result;
  }

  async addContract(data: ContractCreateRq) {
    const result = await APIs.SoftwareContract.create(data);
    runInAction(() => {
      this._currContract = new ContractModel(result);
      this._contracts.set(result.id, this._currContract);
    });
    return result;
  }

  async deleteContract(id: string) {
    await APIs.SoftwareContract.delete(id);
    runInAction(() => {
      this._contracts.delete(id);
    });
  }

  updateContract = async (data: ContractUpdateRq, id: ContractId) => {
    const result = await APIs.SoftwareContract.update(data, id);
    const target = this._contracts.get(id);
    if (target) {
      target.update(result);
    }
  };

  updateContractStatus = async (data: ContractUpdateStatusRq, id: ContractId) => {
    const result = await APIs.SoftwareContract.updateStatus(data, id);
    const target = this._contracts.get(id);
    if (target) {
      target.update(result);
    }
  };

  get contracts(): ContractModel[] {
    return Array.from(this._contracts.values());
  }

  get currContract(): ContractModel {
    return this._currContract;
  }

  get totalElements(): number {
    return this._totalElements;
  }
}

const contractStore = new ContractStore();

export default contractStore;
