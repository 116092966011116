import type { SelectChangeEvent } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { SearchTextField } from '@components/index';
import { CardElement } from '@components/products';
import { useStore } from '@stores/RootStore';
import uiStore from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { CheckIcon, ChevronIcon, ErrorIcon } from '@icons/index';
import type { Filters } from '@repositories/softwareRepository/Types';
import { useGetSoftwareList } from '@queryHooks/useSoftware';
import type { SoftwareSimpleModel } from '@models/softwareModels';

interface MobileCategoriesProps {
  all: string;
  type: string;
  onCategoryChange: (selectedCategoryId: string) => void;
}

const MenuProps = {
  PaperProps: {
    style: {
      margin: '8px 0 0 15px',
      boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
      borderRadius: '4px',
    },
  },
  sx: {
    '& .MuiList-root': {
      borderRadius: '4px',
    },
  },
};
const SubMenuProps = {
  PaperProps: {
    style: {
      margin: '8px 0 0',
      boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
      borderRadius: '4px',
    },
  },
  sx: {
    '& .MuiList-root': {
      borderRadius: '4px',
    },
  },
};

export const MobileCategories = observer(({ type, onCategoryChange, all }: MobileCategoriesProps) => {
  const { t } = useTranslation();
  const { categoryStore } = useStore();
  const categories = categoryStore.categories;
  const rootCategory = categories?.find(category => category.name === type);
  const [mainCategoryId, setMainCategoryId] = useState<string>('');
  const [subCategoryId, setSubCategoryId] = useState<string>('');

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const selectedCategoryId = event.target.value;
    setMainCategoryId(selectedCategoryId);
    setSubCategoryId('');
    onCategoryChange(selectedCategoryId);
  };

  const handleSubCategoryChange = (event: SelectChangeEvent<string>) => {
    setSubCategoryId(event.target.value);
    onCategoryChange(event.target.value);
  };

  const handleAllCategoryClick = (e: React.MouseEvent<HTMLLIElement>) => {
    setMainCategoryId(e.currentTarget.id);
    setSubCategoryId('');
    onCategoryChange(all);
  };

  const leafCategories = categoryStore.getChildCategories(mainCategoryId);

  return (
    <>
      <Select value={mainCategoryId || t('Acc_Main_12')} onChange={handleCategoryChange} MenuProps={MenuProps}>
        <MenuItem value={mainCategoryId || t('Acc_Main_12')} onClick={e => handleAllCategoryClick(e)}>
          {t('Acc_Main_12')} <span>{`(${rootCategory?.softwareCount ?? 0})`}</span>
        </MenuItem>
        {categoryStore.getChildCategories(rootCategory?.id ?? '')?.map(sub => {
          return (
            <MenuItem key={nanoid()} value={sub.id}>
              {sub.name} <span>{`(${sub.softwareCount})`}</span>
            </MenuItem>
          );
        })}
      </Select>

      <Select
        value={subCategoryId || '서브 카테고리'}
        onChange={handleSubCategoryChange}
        disabled={!leafCategories.length}
        MenuProps={SubMenuProps}
        renderValue={(value: string) => leafCategories?.find(({ id }) => id === value)?.name || '서브 카테고리'}
      >
        {leafCategories.map(leafCategory => (
          <MenuItem key={leafCategory.id} value={leafCategory.id} disabled={mainCategoryId === ''}>
            {leafCategory.name}
            <span>{`(${leafCategory.softwareCount || 0})`}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
});

interface Props {
  type: 'Software' | 'Hardware' | string;
}
export const Products = observer(({ type }: Props) => {
  const { t } = useTranslation();
  const { authStore, categoryStore } = useStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [curCategory, setCurCategory] = useState<CategoryId>(() => (state?.categoryId ? state.categoryId : ''));
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([t('Acc_Main_12')]);
  const [queries, setQueries] = useState<Filters>({ size: 1000 });
  const [expandedId, setExpandedId] = React.useState<string>();
  const allIdRef = useRef('');

  const showOnlySubscribing = !!queries.tenantId;
  const showOnlyProviders = !!queries.hasProviderId;
  const categories = categoryStore.categories;
  const rootCategory = categories?.find(category => category.name === type);
  const allCategoryIds = categoryStore.xGetChildCategoryIds(curCategory);

  const { data, isSuccess: isSoftwareListSuccess } = useGetSoftwareList(
    {
      ...queries,
      category: allCategoryIds,
      tenantId: showOnlySubscribing ? authStore.curTenant.id : undefined,
      hasProviderId: showOnlyProviders ? true : undefined,
    },
    {
      enabled: !!curCategory,
    },
  );
  const softwares = data?.content ?? [];

  useEffect(() => {
    const allId = categoryStore.categories.filter(ct => ct.name === type)[0]?.id;
    allIdRef.current = allId;
    setCurCategory(allId);
  }, [type, categoryStore.categories]);

  const handleSearch = useCallback((keyword: string) => {
    if (keyword) {
      setQueries(prev => {
        return { ...prev, keyword, isRootTenant: false };
      });
    } else {
      setQueries(prev => {
        return { ...prev, keyword: undefined, isRootTenant: true };
      });
    }
  }, []);

  const handleClickCategory = (e: React.MouseEvent<HTMLDivElement>, breadcrumbs: string[], panel?: string) => {
    e.stopPropagation();
    setBreadcrumbs(breadcrumbs);
    setCurCategory(e.currentTarget.id);

    const hasChildCategory = categoryStore.getChildCategories(e.currentTarget.id).length > 0;
    if (panel && hasChildCategory) {
      setExpandedId(prev => (prev !== panel ? panel : ''));
    }
  };

  const handleProductClick = (e: React.MouseEvent<HTMLLIElement>, software: SoftwareSimpleModel) => {
    e.stopPropagation();
    navigate(`../product/${software.id}`);
  };

  const handleSubscribingCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueries(prev => ({ ...prev, tenantId: event.target.checked ? authStore.curTenant.id : undefined }));
  };
  const handleProvierCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueries(prev => ({ ...prev, hasProviderId: event.target.checked ? true : undefined }));
  };

  return (
    <Container>
      <BodyWrapper>
        {matches ? (
          <MobileSelectWrapper>
            <MobileCategories
              all={allIdRef.current}
              type={type}
              onCategoryChange={selectedCategoryId => setCurCategory(selectedCategoryId)}
            />
          </MobileSelectWrapper>
        ) : (
          <CategoriesWrap>
            <CategoriesTitle component="strong" variant="headline5">
              {type === 'Software'
                ? t('Subscrib_Detail_Overview_11')
                : type === 'Hardware'
                ? t('Subscrib_Detail_Overview_12')
                : type}
            </CategoriesTitle>
            <BorderedSearchField
              placeholder={t('Common_01')}
              debounce={800}
              onChange={handleSearch}
              onSearch={handleSearch}
            />
            <CategoriesListWrap>
              <CategoryAllWrapper id={allIdRef.current} onClick={e => handleClickCategory(e, [t('Acc_Main_12')])}>
                <CategoryInfoTitle
                  className="test-test-test"
                  $color={
                    breadcrumbs.find(value => value === t('Acc_Main_12'))
                      ? theme.colors['text-purple']
                      : theme.colors['text-gray-main']
                  }
                >
                  {t('Acc_Main_12')}
                </CategoryInfoTitle>
                <CategoryInfoNum
                  $color={
                    breadcrumbs.find(value => value === t('Acc_Main_12'))
                      ? theme.colors['text-purple']
                      : theme.colors['text-gray-light']
                  }
                >
                  {`(${rootCategory?.softwareCount || 0})`}
                </CategoryInfoNum>
              </CategoryAllWrapper>
              {categoryStore.getChildCategories(rootCategory?.id ?? '')?.map(sub => {
                const leafCategories = categoryStore.getChildCategories(sub.id);
                const isSelected = breadcrumbs.find(value => value === sub.name);
                return (
                  <CategoryElement key={sub.id}>
                    <AccordionWrap
                      expanded={expandedId === sub.id}
                      id={sub.id}
                      onClick={e => handleClickCategory(e, [sub.name], sub.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          leafCategories.length > 0 ? (
                            <ChevronIcon width={18} height={18} color={theme.colors['ic-gray-main']} rotateNum={180} />
                          ) : null
                        }
                      >
                        <CategoryInfoTitle
                          $color={isSelected ? theme.colors['text-purple'] : theme.colors['text-gray-main']}
                        >
                          {sub.name}
                        </CategoryInfoTitle>
                        <CategoryInfoNum
                          $color={isSelected ? theme.colors['text-purple'] : theme.colors['text-gray-light']}
                        >
                          {`(${sub.softwareCount})`}
                        </CategoryInfoNum>
                      </AccordionSummary>
                      <AccordionDetails>
                        {leafCategories.map(leafCategory => (
                          <CategoryInfoWrapper
                            key={leafCategory.id}
                            id={leafCategory.id}
                            onClick={e => handleClickCategory(e, [sub.name, leafCategory.name])}
                          >
                            <CategoryItemTitle
                              component="strong"
                              variant={breadcrumbs[1] === leafCategory.name ? 'headline8' : 'body2'}
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              color={
                                breadcrumbs[1] === leafCategory.name
                                  ? theme.colors['text-purple']
                                  : theme.colors['text-gray-sub-darker']
                              }
                            >
                              {leafCategory.name}
                            </CategoryItemTitle>
                            <CategoryItemNum
                              component="span"
                              variant={breadcrumbs[1] === leafCategory.name ? 'headline8' : 'body2'}
                              color={
                                breadcrumbs[1] === leafCategory.name
                                  ? theme.colors['text-purple']
                                  : theme.colors['text-gray-light']
                              }
                            >
                              ({leafCategory.softwareCount})
                            </CategoryItemNum>
                          </CategoryInfoWrapper>
                        ))}
                      </AccordionDetails>
                    </AccordionWrap>
                  </CategoryElement>
                );
              })}
            </CategoriesListWrap>
          </CategoriesWrap>
        )}
        <ListWrap>
          <HeaderWrapper>
            <Breadcrumbs separator="/">
              {breadcrumbs.map((breadcrumb, index) => {
                const key = `${breadcrumb}_${index}`; // breadcrumb는 순서 변경이 일어나지 않기 때문에 key에 index 사용 가능
                return (
                  <Typography
                    key={key}
                    variant={
                      index === breadcrumbs.length - 1 ? 'body1' : breadcrumbs.length <= 3 ? 'headline7' : 'button2'
                    }
                    color={
                      index === breadcrumbs.length - 1 ? theme.colors['text-gray-main'] : theme.colors['text-purple']
                    }
                    component="span"
                    className="category_title"
                  >
                    {breadcrumb}
                  </Typography>
                );
              })}
            </Breadcrumbs>
            <StoreFilterBox>
              <StyledFormControlLabel
                value="end"
                control={
                  <Checkbox
                    checked={showOnlySubscribing}
                    onChange={handleSubscribingCheckChange}
                    icon={<CheckIcon width={16} height={16} color={theme.colors['ic-gray-light']} />}
                    checkedIcon={<CheckIcon width={16} height={16} color={theme.colors['ic-purple-light']} />}
                  />
                }
                label={t('Product_Use_Request_13')}
                labelPlacement="end"
              />
              {uiStore.isItAdminTypeUi ? (
                <StyledFormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={showOnlyProviders}
                      onChange={handleProvierCheckChange}
                      icon={<CheckIcon width={16} height={16} color={theme.colors['ic-gray-light']} />}
                      checkedIcon={<CheckIcon width={16} height={16} color={theme.colors['ic-purple']} />}
                    />
                  }
                  label={t('Ad_Store_Purchase_Filter_01')}
                  labelPlacement="end"
                />
              ) : null}
            </StoreFilterBox>
          </HeaderWrapper>
          <CardWrapper>
            {softwares?.map(sw => (
              <CardElement sw={sw} key={sw.id} isHardware={type !== 'Hardware'} onClick={handleProductClick} />
            ))}
            {isSoftwareListSuccess && softwares.length === 0 && (
              <EmptyBillingWrapper>
                <div>
                  <ErrorIcon width={34} height={34} color={theme.colors['ic-purple']} />
                </div>
                <p>{t('Ad_Store_Purchase_Filter_04')}</p>
              </EmptyBillingWrapper>
            )}
          </CardWrapper>
        </ListWrap>
      </BodyWrapper>
    </Container>
  );
});

const Container = styled('div')`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - var(--employee-sub-gnb-height));
  overflow: auto;
`;
const MobileSelectWrapper = styled('div')`
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  padding: 16px 16px 12px;
  margin: 0 auto;
  & .MuiInputBase-root {
    padding: 5px 7px 5px 11px;
    & .MuiSelect-select ~ .MuiOutlinedInput-notchedOutline {
      border-radius: 16px;
    }
    & .MuiSelect-select {
      ${fonts.Body2};
      color: ${({ theme }) => theme.colors['text-gray-main']};
      & span {
        color: ${({ theme }) => theme.colors['text-gray-light']};
      }
    }
    &.Mui-disabled .MuiSelect-select {
      color: ${({ theme }) => theme.colors['text-gray-light']};
      -webkit-text-fill-color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &.Mui-focused {
      & .MuiSelect-select ~ .MuiOutlinedInput-notchedOutline {
        border-radius: 16px;
      }
    }
  }
`;
const StoreFilterBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  gap: 2px;
  padding: 6px 8px;
  & .MuiFormControlLabel-label {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  }
  & .MuiButtonBase-root.Mui-checked + span {
    color: ${({ theme }) => theme.colors['text-purple-light']};
    font-weight: bold;
  }
`;

const HeaderWrapper = styled(Box)`
  max-width: 988px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
  margin: 34px 0 16px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    display: none;
  } ;
`;

const BodyWrapper = styled(Box)`
  display: flex;
  padding: 0 20px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-sizing: content-box;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    width: auto;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: auto;
    flex-direction: column;
    gap: 0px;
    padding: 0;
  } ;
`;

const CategoriesWrap = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - var(--employee-sub-gnb-height));
  max-width: 220px;
  overflow: auto;
  position: fixed;
  margin-top: 38px;
  box-sizing: content-box;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: 100%;
    position: relative;
  } ;
`;

const CategoriesTitle = styled(Typography)`
  display: inline-block;
  margin-bottom: 16px;
` as typeof Typography;

const CategoriesListWrap = styled(Box)`
  width: 100%;
  padding: 0;
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: 100%;
  } ;
`;

const CategoryElement = styled(Box)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

const CategoryAllWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  align-items: center;
  cursor: pointer;
  padding: 18px 7px 17px 0;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

const CategoryInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px 6px 8px 0;
`;

const CategoryInfoTitle = styled('strong')<{ $color: string }>`
  margin-right: 2px;
  color: ${({ $color }) => $color};
  ${fonts.Headline8};
`;

const CategoryInfoNum = styled('span')<{ $color: string }>`
  margin-right: auto;
  color: ${({ $color }) => $color};
  ${fonts.Headline8};
`;

const CategoryItemTitle = styled(Typography)`
  margin-right: 2px;
  white-space: nowrap;
` as typeof Typography;

const CategoryItemNum = styled(Typography)`` as typeof Typography;

const ListWrap = styled(Box)`
  width: 100%;
  margin-left: 292px;

  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: calc(100% - 32px);
    height: auto;
    padding: 0 16px 20px;
    margin-left: 0;
    margin: 0 auto;
  } ;
`;

const CardWrapper = styled('ul')`
  max-width: 988px;
  width: 100%;
  display: flex;
  gap: 40px 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 30px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    gap: 16px 8px;
    padding-bottom: 0;
  } ;
`;

const AccordionWrap = styled(Accordion)`
  background-color: #fafbfc;
  box-shadow: none;
  & .MuiAccordionSummary-root {
    min-height: 36px;
    cursor: pointer;
    padding: 18px 7px 18px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &.Mui-expanded {
      min-height: auto;
    }
  }
  & .MuiAccordionSummary-content {
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
  & .MuiCollapse-root.MuiCollapse-entered {
    padding-bottom: 10px;
  }
`;

const BorderedSearchField = styled(SearchTextField)`
  width: 100%;
  height: 32px;
  border-radius: 2px;
  margin-bottom: 8px;
  & .MuiInputBase-root {
    width: 100%;
    height: 100%;
    padding: 0px 8px;
    gap: 4px;
    background: ${({ theme: { colors } }) => colors.White};
  }

  & .MuiInputBase-input {
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    ${fonts.Body2}
    padding: 0;
    &::placeholder {
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
      opacity: 1;
    }
  }

  & .MuiInputAdornment-positionEnd .MuiSvgIcon-root {
    color: black;
  }
  & .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  & .notranslate {
    display: none;
  }
`;

const EmptyBillingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding-top: 134px;
  & > div {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
    margin-bottom: 20px;
  }
  & > p {
    ${fonts.Headline7};
  }
`;
