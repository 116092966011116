import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Modal, Select, useTheme, Box, MenuItem, Snackbar, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { DatePicker } from '@components/datePicker';
import { Button, Label } from '@components/index';
import authStore from '@stores/AuthStore';
import { fonts } from '@theme/fontsCustomer';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import { DashIcon } from '@icons/DashIcon';
import { XIcon } from '@icons/XIcon';
import { TriangleIcon } from '@icons/index';
import { useGetProductPlanList } from '@queryHooks/useSoftware';
import { useAddSubscriptionUseRequest } from '@queryHooks/useSubscriptionUseRequest';
import { FormUseRequest, useFormUser } from './validationSchema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  swId: string;
}

const StyledTriangleIcon = (props: JSX.Element) => {
  const theme = useTheme();
  return (
    <CustomTriangleIcon
      width={16}
      height={16}
      rotateNum={180}
      color={theme.colors['ic-gray-dark']}
      className="MuiSvgIcon-root MuiSelect-icon"
      {...props}
    />
  );
};

export const UsageRequestModal = ({ isOpen, onClose, swId }: Props) => {
  const { t } = useTranslation();
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const { mutate: applySubscription } = useAddSubscriptionUseRequest();
  const { data: plans } = useGetProductPlanList(swId, { enabled: isOpen });
  const theme = useTheme();
  const { register, handleSubmit, reset, control, formState, trigger, watch } = useFormUser();

  const [selectedStartDate] = watch(['startDate']);

  const handleStartCalrendarToggle = () => {
    setIsStartCalendarOpen(prev => !prev);
  };

  const handleEndCalrendarToggle = () => {
    setIsEndCalendarOpen(prev => !prev);
  };

  const handleToast = {
    open: () => {
      setToastOpen(true);
    },
    close: () => {
      setToastOpen(false);
    },
  };

  const validation = {
    success: (data: FormUseRequest) => {
      const rqData = {
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
        inquiry: data.inquiry,
        productPlanId: data.productPlanId,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        tenantId: authStore.curTenant.id,
      };
      applySubscription(rqData, {
        onSuccess: () => {
          handleToast.open();
          onClose();
        },
      });
    },
  };

  const handleDateOnChange = (onChange: UnknownFunction, type: 'startDate' | 'endDate') => (value: string | null) => {
    onChange(value);
    if (type === 'endDate') {
      trigger('startDate');
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 424,
        marginTop: 7,
        boxShadow: 'none',
        borderRadius: '0 0 2px 2px',
      },
    },
  };
  const toastMessage = <>사용 신청을 완료했습니다.</>;

  const calendarStartIcon = () => {
    return (
      <CalendarStartIcon
        width={20}
        height={20}
        color={isStartCalendarOpen ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
      />
    );
  };
  const calendarEndIcon = () => {
    return (
      <CalendarStartIcon
        width={20}
        height={20}
        color={isEndCalendarOpen ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
      />
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} sx={{ '& .MuiBackdrop-root': { background: 'transparent' } }}>
        <ModalWrapper onSubmit={handleSubmit(validation.success)}>
          <Header>
            {t('Product_Cardview_01')}
            <IconButton onClick={onClose}>
              <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
            </IconButton>
          </Header>
          <Body>
            <Element>
              <strong className="title">
                {t('Subscrib_Main_12')}
                <span>*</span>
              </strong>
              <Controller
                control={control}
                name="productPlanId"
                render={({ field: { onChange, value = '' } }) => (
                  <>
                    <Select
                      {...register('productPlanId')}
                      size="large"
                      fullWidth
                      displayEmpty
                      value={value}
                      IconComponent={StyledTriangleIcon}
                      error={!!formState.errors.productPlanId}
                      onChange={onChange}
                      renderValue={(value: string) => {
                        const findPlanIdInfor = plans?.find(plan => plan.planId === value);
                        return value !== '' ? (
                          <>
                            <SelectedTitle>{plans?.find(plan => plan.planId === value)?.planName}</SelectedTitle>
                            {findPlanIdInfor?.isInUseByTenant ? (
                              <Label className="usebytenant" variant="contain" color="gradient">
                                {t('Ad_Store_Product_State')}
                              </Label>
                            ) : null}
                          </>
                        ) : (
                          <strong>{t('Product_Use_Request_02')}</strong>
                        );
                      }}
                      MenuProps={MenuProps}
                      sx={{
                        '&.Mui-error': {
                          '& .MuiSelect-select strong': {
                            color: `${theme.colors['text-red-light']}`,
                          },
                          '& svg g path': {
                            fill: `${theme.colors['text-red-light']}`,
                          },
                        },
                      }}
                    >
                      {plans?.map(plan => {
                        return (
                          <SelectItem key={nanoid()} value={plan.planId}>
                            <span className="plan-name-wrapper">
                              <span className="plan-name">{plan.planName}</span>
                              {!plan.isBasicPlan && <CustomCard>custom</CustomCard>}
                            </span>
                            {plan.isInUseByTenant ? (
                              <Label variant="contain" color="gradient">
                                {t('Ad_Store_Product_State')}
                              </Label>
                            ) : null}
                          </SelectItem>
                        );
                      })}
                    </Select>
                    {!!formState.errors.productPlanId && (
                      <ErrorText className="plan">{formState.errors.productPlanId?.message}</ErrorText>
                    )}
                  </>
                )}
              />
            </Element>
            <Element>
              <strong className="title">{t('Acc_Detail_04')}</strong>
              <DateRangeField>
                <Picker>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { ref, onChange, value } }) => {
                      return (
                        <DatePicker
                          inputRef={ref}
                          inputFormat={t('DateFormat_YMD')}
                          value={value}
                          onChange={handleDateOnChange(onChange, 'endDate')}
                          onOpen={handleStartCalrendarToggle}
                          onClose={handleStartCalrendarToggle}
                          components={{
                            OpenPickerIcon: calendarStartIcon,
                          }}
                          renderInput={params => (
                            <StyledDatePickerInput {...params} fullWidth error={!!formState.errors.startDate} />
                          )}
                          showDaysOutsideCurrentMonth
                        />
                      );
                    }}
                  />
                  <DashIcon width={10} height={10} color={theme.colors['ic-gray-dark']} />
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field: { ref, onChange, value } }) => (
                      <DatePicker
                        inputRef={ref}
                        inputFormat={t('DateFormat_YMD')}
                        value={value}
                        onOpen={handleEndCalrendarToggle}
                        onClose={handleEndCalrendarToggle}
                        onChange={handleDateOnChange(onChange, 'endDate')}
                        components={{
                          OpenPickerIcon: calendarEndIcon,
                        }}
                        renderInput={params => (
                          <StyledDatePickerInput
                            {...params}
                            fullWidth
                            error={!!formState.errors.endDate || formState.errors.startDate?.message === t('Date_02')}
                          />
                        )}
                        showDaysOutsideCurrentMonth
                        shouldDisableDate={day => {
                          const disableDate = dayjs(selectedStartDate).add(1, 'D');
                          return dayjs.isDayjs(day) ? day.isBefore(disableDate) : false;
                        }}
                      />
                    )}
                  />
                </Picker>
                <p className="detail">{t('Product_Use_Request_06')}</p>
                {formState.errors.startDate?.message && <ErrorText>{formState.errors.startDate?.message}</ErrorText>}
              </DateRangeField>
            </Element>
            <Element>
              <strong className="title inquiry">
                {t('Product_Use_Request_07')}
                <span>*</span>
              </strong>
              {!!formState.errors.inquiry && <ErrorText>{formState.errors.inquiry?.message}</ErrorText>}
              <StyledTextField
                {...register('inquiry')}
                className="inquiry"
                multiline
                rows={9}
                error={!!formState.errors.inquiry}
                placeholder={t('Product_Use_Request_08')}
              />
            </Element>
          </Body>
          <SubmitButton variant="contain" size="extraLarge" type="submit">
            {t('Ad_Store_Purchase_Request_03')}
          </SubmitButton>
        </ModalWrapper>
      </Modal>
      <ToastPopup
        open={toastOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleToast.close}
        message={toastMessage}
      />
    </>
  );
};

const ModalWrapper = styled.form`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 480px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  overflow: hidden;
  z-index: 1050;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 6px 30px 5px rgba(0, 0, 0, 0.05);
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  padding: 18px 20px 16px 28px;
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 12px 28px 0;
  width: 100%;
  height: 100%;
  max-height: 508px;
  overflow: auto;
`;

const Element = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  & .title {
    ${fonts.Headline8};
    margin-bottom: 6px;
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & > span {
      margin-left: 2px;
      color: ${({ theme: { colors } }) => colors['ic-red-light']};
    }
    &.inquiry {
      margin-bottom: 0;
    }
  }
  & .detail {
    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
  & .MuiInputBase-root {
    padding: 10px 12px;
  }
  & .MuiInputBase-root textarea::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
  & .MuiSelect-select strong {
    ${fonts.Button4};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

const StyledTextField = styled(TextField)`
  background: ${({ theme }) => theme.colors['bg-gray-lighter']};
  &.inquiry {
    margin-top: 6px;
  }
  & .MuiInputBase-root.Mui-error textarea::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const DateRangeField = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Picker = styled(Box)`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 4px;
  & > svg {
    flex-shrink: 0;
  }
  & > .MuiFormControl-root {
    width: 50%;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.colors['bg-gradient']};
  border-image-slice: 1;
  border-radius: 0;
`;

const SelectItem = styled(MenuItem)`
  display: flex;
  gap: 4px;
  flex-direction: row;
  padding: 10px 4px 10px 7px;
  align-items: center;
  max-width: 414px;
  & .plan-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 314px;
    & .plan-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const CustomCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  ${fonts.Caption4}
  color: ${props => props.theme.colors['text-gray-sub-darker']};
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 2px;
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
`;

const ToastPopup = styled(Snackbar)`
  & .MuiPaper-root {
    width: 212px;
    padding: 14px 24px 14px 24px;
    background-color: ${({ theme }) => theme.colors['bg-bk-opacity-85%']};
    border-radius: 3px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
    min-width: max-content;
  }
  & .MuiSnackbarContent-message {
    padding: 0;
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-white']};
  }
  & .MuiSnackbarContent-action {
    padding: 0;
    margin: 0;
    margin-left: auto;
  }
`;

const SelectedTitle = styled.strong`
  & ~ span {
    margin-left: 4px;
  }
`;

const ErrorText = styled('p')`
  ${fonts.Body4};
  color: ${({ theme }) => theme.colors['text-red-light']};
  &.plan {
    margin-top: 4px;
  }
`;

const StyledDatePickerInput = styled(DatePicker.Input)`
  & .MuiInputBase-root {
    &.Mui-disabled {
      background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
    }
    &.Mui-disabled.Mui-error,
    &.Mui-error {
      background-color: ${({ theme: { colors } }) => colors['bg-white']};
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme: { colors } }) => colors['border-red-light']};
      }
      & .MuiSelect-select {
        color: ${({ theme: { colors } }) => colors['text-red-light']};
        -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
      }
      & .MuiInputBase-input {
        &::-webkit-input-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
        &:-moz-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
        &::-moz-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
        &:-ms-input-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors['border-red-light']};
      }
      & svg g path {
        fill: ${({ theme }) => theme.colors['ic-red-light']};
        opacity: 1;
      }
    }
  }
`;

const CustomTriangleIcon = styled(TriangleIcon)`
  top: calc(50% - 8px) !important;
  right: 9px !important;
  &.MuiSelect-iconOpen {
    transform: rotate(0) !important;
  }
`;
