import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 574px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  border: 1px solid #fff;
`;

export const ModalTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 0px 20px 0 30px;
  align-items: center;
  justify-content: space-between;
  height: 52px;
`;

export const ModalContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 444px;
  padding: 16px 30px 28px 30px;
  gap: 24px;
`;

export const ProductTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 13px;
  align-items: center;
`;

export const ProductTitle = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const SubscriptionTypeWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SubscriptionDurationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Duration = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const RequestWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;
