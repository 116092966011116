import React, { HTMLAttributes, MouseEvent, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

type Props = PropsWithChildren<PopoverProps> & HTMLAttributes<HTMLDivElement>;

export type Placement = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

type PopoverProps = {
  open: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  popoverContent?: React.ReactNode;
  value?: React.ReactNode;
  placement?: Placement;
};

type PopoverStyleProps = {
  top: number;
  left: number;
  width?: number;
};

export const Popover = ({
  open,
  children,
  popoverContent,
  onClose,
  placement = 'bottom-left',
  value,
  className,
}: Props) => {
  const childrenTarget = useRef<HTMLDivElement | null>(null);
  const popoverContentTarget = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [wrapperWidth, setWrapperWidth] = useState<number | undefined>(undefined);

  const handleClosePopover = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onClose) onClose();
  };

  useLayoutEffect(() => {
    const positionSetting = () => {
      if (childrenTarget.current && popoverContentTarget.current) {
        const childrenTargetRect = childrenTarget.current.getBoundingClientRect();
        setWrapperWidth(childrenTargetRect.width);

        const popoverContentTargetRect = popoverContentTarget.current.getBoundingClientRect();
        if (placement.includes('top')) {
          setPosition(prev => ({ ...prev, top: childrenTargetRect.top - popoverContentTargetRect.height }));
        } else if (placement.includes('bottom')) {
          setPosition(prev => ({ ...prev, top: childrenTargetRect.top + childrenTargetRect.height + 2 }));
        }

        if (placement.includes('left') && wrapperWidth) {
          setPosition(prev => ({
            ...prev,
            left: childrenTargetRect.left - popoverContentTargetRect.width + wrapperWidth,
          }));
        } else if (placement.includes('center')) {
          setPosition(prev => ({
            ...prev,
            left: childrenTargetRect.left - popoverContentTargetRect.width / 2 + childrenTargetRect.width / 4,
          }));
        } else if (placement.includes('right')) {
          setPosition(prev => ({
            ...prev,
            left: childrenTargetRect.left,
          }));
        }
      }
    };
    positionSetting();

    document.addEventListener('scroll', positionSetting, { capture: true });

    return () => {
      document.removeEventListener('scroll', positionSetting, { capture: true });
      setPosition({
        top: 0,
        left: 0,
      });
    };
  }, [open, placement, popoverContent, wrapperWidth]);

  return (
    <>
      <PopoverWrapper ref={childrenTarget} value={value}>
        {children}
      </PopoverWrapper>
      {open
        ? createPortal(
            <BackgroundWrapper onClick={handleClosePopover}>
              <ContainerWrapper
                className={className}
                ref={popoverContentTarget}
                top={position.top}
                left={position.left}
                width={wrapperWidth}
              >
                {popoverContent}
              </ContainerWrapper>
            </BackgroundWrapper>,
            document.body,
          )
        : null}
    </>
  );
};

const PopoverWrapper = styled.div<{ value: React.ReactNode }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;
const ContainerWrapper = styled.div<PopoverStyleProps>`
  position: absolute;
  z-index: 1201;
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  width: ${({ width }) => `${width}px`};
`;
