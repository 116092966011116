import { IOauthRepository } from '@repositories/oauthRepository/IOauthRepository';
import { OauthFilter } from '@repositories/oauthRepository/Types';
import { KEYS, repository } from '@repositories/Repository';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const oauthRepo = repository.get<IOauthRepository>(KEYS.OAUTH_REPOSITORY);

export const oauthQueryKey = {
  all: ['oauth'] as const,
  details: () => [...oauthQueryKey.all, 'detail'] as const,
  detail: (query: OauthFilter) => [...oauthQueryKey.details(), query] as const,
};

export const useCallbackOauth = (queries: OauthFilter, options?: UseQueryOptionsType<void>) => {
  return useQuery<void, AxiosError>({
    queryKey: oauthQueryKey.detail(queries),
    queryFn: async () => {
      const result = await oauthRepo.callback(queries);
      return result;
    },
    ...options,
  });
};
