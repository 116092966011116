import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import type { Filters } from '@repositories/subscriptionRepository';
import { useGetSubscriptionListInfinite } from '@queryHooks/useSubscription';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';
import { SubscriptionItem } from './SubscriptionItem';

type Props = {
  onSubscriptionClick: (model: SubscriptionListModel) => void;
};
export const SubscriptionList = ({ onSubscriptionClick }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [queries, setQueries] = useState<Filters>({ keyword: null, type: 'EXTERNAL' });

  const { data: pagedSubscriptionList, isSuccess } = useGetSubscriptionListInfinite(queries);
  const subscriptionList = pagedSubscriptionList?.pages.flatMap(({ content }) => content);
  const totalElements = pagedSubscriptionList?.pages[0].totalElements ?? 0;

  const handleSearchChange = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setQueries(prev => ({ ...prev, keyword: e.target.value }));
      }, 500),
    [],
  );

  return (
    <>
      <SearchSubscriptionWrapper>
        <Subtitle>{t('Acc_Create_02')}</Subtitle>
        <TextField fullWidth onChange={handleSearchChange} placeholder={t('Acc_Create_03')} />
      </SearchSubscriptionWrapper>
      <Background>
        {isSuccess && totalElements === 0 && (
          <NoData>
            <ErrorIconWrapper>
              <ErrorIcon width={34} height={34} color={colors['ic-purple-light']} />
            </ErrorIconWrapper>
            {queries.keyword ? (
              <NoDataDescription>{t('Subscrib_Edit_11')}</NoDataDescription>
            ) : (
              <>
                <NoDataDescription>{t('Acc_Create_04')}</NoDataDescription>
                <NoDataDescriptionSmall>{t('Acc_Create_05')}</NoDataDescriptionSmall>
              </>
            )}
          </NoData>
        )}
        <SubscriptionGrid>
          {subscriptionList?.map(subscription => (
            <SubscriptionItem
              key={subscription.id}
              subscription={subscription}
              onSubscriptionClick={onSubscriptionClick}
            />
          ))}
        </SubscriptionGrid>
        <Shadow />
      </Background>
    </>
  );
};

const SearchSubscriptionWrapper = styled.div`
  padding: 12px 28px 20px;
`;

const Subtitle = styled.div`
  ${fonts.Headline8}
  margin-bottom: 6px;
`;

const Background = styled.div`
  border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  height: calc(100% - 158px);
  background: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  position: relative;
`;

const NoData = styled.div`
  padding: 170px 0px 287px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubscriptionGrid = styled.ul`
  display: grid;
  width: 100%;
  height: calc(100% - 32px);
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 50px;
  overflow-y: auto;
  column-gap: 44px;
  row-gap: 40px;
  padding: 28px 44px 20px;
  overflow: auto;
`;

const ErrorIconWrapper = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  padding: 15px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const NoDataDescription = styled.p`
  ${fonts.Headline7}
  margin-bottom: 4px;
`;

const NoDataDescriptionSmall = styled.small`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const Shadow = styled.div`
  width: 100%;
  height: 20px;
  background: ${({ theme: { colors } }) => colors['bg-gray-light-gradient-90']};
  position: absolute;
  bottom: 32px;
`;
