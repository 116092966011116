import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { Link } from 'react-router-dom';

export const RelatedAppsWapper = styled(Box)`
  max-width: 330px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 28px;
  position: sticky;
  top: 190px;
  right: 0;
  margin-top: 12px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    display: none;
  } ;
`;
export const RelatedAppsCategory = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const RelatedAppsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const RelatedAppWrapper = styled(Link)`
  background: #ffffff;
  border: 1px solid #e1e4e8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 14px;
  text-decoration: none;
`;

export const AppWrapper = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const AppInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

export const RelatedAppCategory = styled('strong')`
  ${fonts.Headline9};
  color: ${({ theme }) => theme.colors['text-gray-main']};
  margin-bottom: 6px;
`;

export const RelatedAppDescrpition = styled('p')`
  ${fonts.Paragraph3};
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  max-width: 266px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
` as typeof Typography;
