import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PageTemplate: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => (
  <PageContainer className={className}>
    <ContentsWrapper>{children}</ContentsWrapper>
  </PageContainer>
);

const PageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  &.subscription {
    width: calc(100% - 256px);
    justify-content: flex-start;
  }
`;

const ContentsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1664px;
  box-sizing: content-box;
`;
/** TODO: 나중에 반응형 적용
 * ${MediaQuery.between('md', 'lg')} {
    width: 1184px;
  }
  ${MediaQuery.between('sm', 'md')} {
    width: 1024px;
  }
  ${MediaQuery.between('xs', 'sm')} {
    width: 768px;
  }
 */
