const defaultWidth = 24;
const defaultHeight = 24;

export const WorkflowIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3309 10.8864C13.5273 11.1324 13.9597 11.41 15.1201 11.41L17.193 11.41C18.2071 11.41 19.1344 11.6174 19.6875 12.4499C19.9383 12.8274 20.0578 13.2601 20.1211 13.6846C20.1845 14.1093 20.2 14.589 20.2 15.0995C20.2 15.5966 19.8049 15.9995 19.3176 15.9995C18.8303 15.9995 18.4352 15.5966 18.4352 15.0995C18.4352 14.61 18.4191 14.241 18.3765 13.9552C18.3338 13.6692 18.2731 13.5295 18.2267 13.4597C18.1853 13.3975 18.0504 13.21 17.193 13.21L15.1201 13.21C13.7352 13.21 12.6487 12.8824 11.9626 12.0231C11.313 11.2096 11.2 10.1229 11.2 9.09951C11.2 8.60246 11.595 8.19951 12.0823 8.19951C12.5696 8.19951 12.9647 8.60246 12.9647 9.09951C12.9647 10.0761 13.0979 10.5947 13.3309 10.8864Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8691 10.8864C10.6726 11.1324 10.2403 11.41 9.07987 11.41L7.00695 11.41C5.99285 11.41 5.06553 11.6174 4.51242 12.4499C4.26163 12.8274 4.14213 13.2601 4.07883 13.6846C4.01549 14.1093 4 14.589 4 15.0995C4 15.5966 4.39504 15.9995 4.88235 15.9995C5.36966 15.9995 5.7647 15.5966 5.7647 15.0995C5.7647 14.61 5.78086 14.241 5.82347 13.9552C5.86612 13.6692 5.92686 13.5295 5.97329 13.4597C6.01462 13.3975 6.1496 13.21 7.00695 13.21L9.07987 13.21C10.4647 13.21 11.5512 12.8824 12.2374 12.0231C12.887 11.2096 13 10.1229 13 9.09951C13 8.60246 12.605 8.19951 12.1176 8.19951C11.6303 8.19951 11.2353 8.60246 11.2353 9.09951C11.2353 10.0761 11.1021 10.5947 10.8691 10.8864Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.09985 3.0001C8.09985 1.95076 8.95051 1.1001 9.99985 1.1001H13.9999C15.0492 1.1001 15.8999 1.95076 15.8999 3.0001V7.0001C15.8999 8.04944 15.0492 8.9001 13.9999 8.9001H9.99985C8.95051 8.9001 8.09985 8.04944 8.09985 7.0001V3.0001ZM9.99985 2.9001C9.94463 2.9001 9.89985 2.94487 9.89985 3.0001V7.0001C9.89985 7.05533 9.94463 7.1001 9.99985 7.1001H13.9999C14.0551 7.1001 14.0999 7.05533 14.0999 7.0001V3.0001C14.0999 2.94487 14.0551 2.9001 13.9999 2.9001H9.99985Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.899902 17.0001C0.899902 15.9508 1.75056 15.1001 2.7999 15.1001H6.7999C7.84924 15.1001 8.6999 15.9508 8.6999 17.0001V21.0001C8.6999 22.0494 7.84924 22.9001 6.7999 22.9001H2.7999C1.75056 22.9001 0.899902 22.0494 0.899902 21.0001V17.0001ZM2.7999 16.9001C2.74467 16.9001 2.6999 16.9449 2.6999 17.0001V21.0001C2.6999 21.0553 2.74467 21.1001 2.7999 21.1001H6.7999C6.85513 21.1001 6.8999 21.0553 6.8999 21.0001V17.0001C6.8999 16.9449 6.85513 16.9001 6.7999 16.9001H2.7999Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2998 17.0001C15.2998 15.9508 16.1505 15.1001 17.1998 15.1001H21.1998C22.2491 15.1001 23.0998 15.9508 23.0998 17.0001V21.0001C23.0998 22.0494 22.2491 22.9001 21.1998 22.9001H17.1998C16.1505 22.9001 15.2998 22.0494 15.2998 21.0001V17.0001ZM17.1998 16.9001C17.1446 16.9001 17.0998 16.9449 17.0998 17.0001V21.0001C17.0998 21.0553 17.1446 21.1001 17.1998 21.1001H21.1998C21.255 21.1001 21.2998 21.0553 21.2998 21.0001V17.0001C21.2998 16.9449 21.255 16.9001 21.1998 16.9001H17.1998Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

WorkflowIcon.displayName = 'WorkflowIcon';
