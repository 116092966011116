import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { SxProps } from '@mui/system';

interface AutocompleteFilterProps {
  id?: string;
  placeholder?: string;
  sx?: SxProps;
  label?: string;
  onChange?: (value: any) => void;
  value?: any;
  options: string[] | any[];
  disabled?: boolean;
  getOptionLabel?: (obj: any) => string;
  isOptionEqualToValue?: (option1: any, ontion2: any) => boolean;
}

const AutocompleteFilter = ({
  onChange,
  options = [],
  getOptionLabel,
  isOptionEqualToValue,
  ...props
}: AutocompleteFilterProps) => {
  const [inputText, setInputText] = useState(props?.value);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: any): void => {
    if (typeof value === 'string') {
      setInputText(value);
    } else if (value && getOptionLabel) {
      setInputText(getOptionLabel(value));
    } else {
      setInputText('');
    }
    if (onChange) onChange(value);
  };

  return (
    <Autocomplete
      {...props}
      value={props.value || null}
      size="small"
      disablePortal
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      disabled={props?.disabled}
      onChange={handleChange}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            value={inputText}
            placeholder={props.placeholder}
            fullWidth
          />
        );
      }}
    />
  );
};

export default AutocompleteFilter;
