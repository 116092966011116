import { useMemo } from 'react';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { KEYS, repository } from '@repositories/Repository';
import ITenantRepository from '@repositories/tenantRepository/ITenantRepository';
import {
  Filters,
  TenantAddMemberRq,
  TenantMemberFilter,
  TenantMemberRoleUpdateRq,
  Tenant,
  TenantUpdateWithFileRq,
  TenantMemberStatusUpdateDTO,
  TenantUpdateDTO,
  MyTenantFilter,
  TenantCreateRq,
} from '@repositories/tenantRepository/Types';
import { Page } from '@type/Page';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import { ConnectionRespModel } from '@models/connection/ConnectionRespModel';
import { TenantListModel } from '@models/tenantModels/TenantListModel';
import { TenantModel } from '@models/tenantModels/TenantModel';
import { TenantSimpleModel } from '@models/tenantModels/TenantSimpleModel';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const tenantRepo = repository.get<ITenantRepository>(KEYS.TENANT_REPOSITORY);

export const tenantQueryKey = {
  all: ['Tenant'] as const,
  lists: () => [...tenantQueryKey.all, 'list'] as const,
  list: (filters: Filters) => [...tenantQueryKey.lists(), { ...filters }] as const,
  myList: (filters?: MyTenantFilter) => [...tenantQueryKey.lists(), 'my', { ...filters }] as const,
  details: () => [...tenantQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...tenantQueryKey.details(), id] as const,
};

export const tenantMemberQueryKey = {
  all: ['TenantMember'] as const,
  lists: () => [...tenantMemberQueryKey.all, 'list'] as const,
  list: (id: string, filters: TenantMemberFilter) => [...tenantMemberQueryKey.lists(), id, { ...filters }] as const,
  listInfinite: (id: string, filters: TenantMemberFilter) =>
    [...tenantMemberQueryKey.list(id, filters), 'infinite'] as const,
  details: () => [...tenantMemberQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...tenantMemberQueryKey.details(), id] as const,
};

export const useGetTenantList = (queries?: Filters, options?: UseQueryOptionsType<Page<TenantListModel>>) =>
  useQuery<Page<TenantListModel>, AxiosError>(
    tenantQueryKey.list(queries ?? {}),
    async () => {
      const result = await tenantRepo.getList(queries);
      return { ...result, content: result.content.map(tenant => new TenantListModel(tenant)) };
    },
    {
      ...options,
    },
  );

export const useGetMyTenantList = (queries?: MyTenantFilter, options?: UseQueryOptionsType<TenantSimpleModel[]>) =>
  useQuery<TenantSimpleModel[], AxiosError>({
    queryKey: tenantQueryKey.myList(queries),
    queryFn: async () => {
      const result = await tenantRepo.getMyTenants(queries);
      return result.map(tenantSimple => new TenantSimpleModel(tenantSimple));
    },
    ...options,
  });

export const useGetTenant = (id: TenantId, options?: UseQueryOptionsType<TenantModel>) =>
  useQuery<TenantModel, AxiosError>({
    queryKey: tenantQueryKey.detail(id),
    queryFn: async () => {
      const result = await tenantRepo.getOne(id);
      return new TenantModel(result);
    },
    ...options,
    enabled: !!id && (options?.enabled !== undefined ? options.enabled : true),
  });

export const useGetTenantMembers = (
  id: TenantId,
  queries?: TenantMemberFilter,
  options?: UseQueryOptionsType<Page<TenantMemberSimpleModel>>,
) => {
  const result = useQuery<Page<TenantMemberSimpleModel>, AxiosError>(
    tenantMemberQueryKey.list(id, queries ?? {}),
    async () => {
      const result = await tenantRepo.getMembers(id, queries);
      return { ...result, content: result.content.map(member => new TenantMemberSimpleModel(member)) };
    },
    {
      ...options,
      cacheTime: 100 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    },
  );

  const initialData = useMemo(() => ({ content: [], totalElements: 0 }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useGetTenantMember = (
  id: TenantId,
  userId: UserId,
  options?: UseQueryOptionsType<TenantMemberSimpleModel>,
) => {
  const result = useQuery<TenantMemberSimpleModel, AxiosError>(
    tenantMemberQueryKey.detail(userId),
    async () => {
      const result = await tenantRepo.getMember(id, userId);
      return new TenantMemberSimpleModel(result);
    },
    {
      ...options,
      cacheTime: 100 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    },
  );

  return result;
};

export const useGetTenantMembersInfinite = (
  id: TenantId,
  queries?: Omit<TenantMemberFilter, 'page'>,
  options?: UseInfiniteQueryOptions<Page<TenantMemberSimpleModel>>,
) =>
  useInfiniteQuery({
    queryKey: tenantMemberQueryKey.listInfinite(id, queries ?? {}),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await tenantRepo.getMembers(id, { ...queries, page: pageParam });
      return { ...result, content: result.content.map(member => new TenantMemberSimpleModel(member)), pageParam };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useAddTenantMembers = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: TenantAddMemberRq[]) => {
      const result = await tenantRepo.addMembers(rqData[0].tenantId, rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tenantMemberQueryKey.lists());
      },
    },
  );

  return { ...result };
};

export const useDeleteTenantMember = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async ({ id, userId }: { id: TenantId; userId: UserId }): Promise<void> => {
      await tenantRepo.deleteMember(id, userId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tenantMemberQueryKey.lists());
      },
    },
  );

  return { ...result };
};

export const useUpdateTenantMemberRole = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: TenantMemberRoleUpdateRq) => {
      const { userId } = await tenantRepo.updateMemberRole(data.tenantId, data);
      return userId;
    },
    {
      onSuccess: userId => {
        queryClient.invalidateQueries(tenantMemberQueryKey.detail(userId));
        queryClient.invalidateQueries(tenantMemberQueryKey.lists());
      },
    },
  );
};

export const useAddTenant = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: TenantCreateRq) => {
      const result = await tenantRepo.create(rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tenantQueryKey.lists());
      },
    },
  );

  return { ...result, data: result.data };
};

export const useDeleteTenant = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: TenantId): Promise<void> => {
      const result = await tenantRepo.delete(id);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tenantQueryKey.lists());
      },
    },
  );
};

export const useUpdateTenant = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, rqData }: { id: string; rqData: TenantUpdateDTO }): Promise<Tenant> => {
      const result = await tenantRepo.update(id, rqData);
      return result;
    },
    {
      onSuccess: (data: Tenant) => {
        queryClient.invalidateQueries(tenantQueryKey.detail(data.id));
        queryClient.invalidateQueries(tenantQueryKey.lists());
      },
    },
  );
};

export const useUpdateTenantLogo = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async ({ id, tenant, logoImage }: TenantUpdateWithFileRq) => {
      const result = await tenantRepo.updateWithFile(id, tenant, logoImage);
      return result.id;
    },
    onSuccess: (id: string) => {
      queryClient.invalidateQueries(tenantQueryKey.list({ id }));
      queryClient.invalidateQueries(tenantQueryKey.detail(id));
    },
  });

  return result;
};

export const useUpdateTenantMemberStatus = () => {
  const queryClient = useQueryClient();

  type MutationVariable = {
    tenantId: string;
    userId: string;
    rqData: TenantMemberStatusUpdateDTO;
  };

  return useMutation({
    mutationFn: async (variable: MutationVariable) => {
      const result = await tenantRepo.updateMemberStatus(variable.tenantId, variable.userId, variable.rqData);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
    },
  });
};

export const useDeleteTenantMemberUsingFlag = () => {
  const queryClient = useQueryClient();

  type MutationVariable = {
    tenantId: string;
    userId: string;
  };

  return useMutation({
    mutationFn: async (variable: MutationVariable) => {
      const result = await tenantRepo.deleteMemberUsingFlag(variable.tenantId, variable.userId);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
    },
  });
};

export const useGetSMPConnections = (tenantId: string, options?: UseQueryOptionsType<ConnectionRespModel>) => {
  const result = useQuery<ConnectionRespModel, AxiosError>(
    ['SMPConnection', tenantId],
    async () => {
      const result = await tenantRepo.getSMPConnections({ tenantId });
      return new ConnectionRespModel(result);
    },
    {
      ...options,
      cacheTime: 100 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    },
  );

  return result;
};
