import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, TableCell, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { DashIcon, AddIcon, TriangleIcon, DepthIcon, PencilIcon, CheckIcon, XIcon } from '@icons/index';
import { UserGroupCreateRq } from '@repositories/userGroupsRepository/Types';
import { useDeleteUserGroup, useAddUserGroup, useGetUserGroups, useUpdateUserGroup } from '@queryHooks/useUserGroup';
import { UserGroupSimpleModel } from '@models/userGroupModels/UserGroupSimpleModel';
import { useGroupForm } from './validationSchema';

type FormData = {
  name: string;
  userGroupCode: string;
};

type Edit = {
  name: boolean;
  code: boolean;
};
interface Props {
  idx?: number;
  rowData: UserGroupSimpleModel;
  depth?: number;
}

export const UserGroupTableSubRow = observer(({ rowData: userGroup, depth = 0 }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    uiStore: { alertStore: modal },
  } = useStore();
  const [isDuplicationGroupCode, setIsDuplicationGroupCode] = useState(false);
  const [isFolded, setIsFolded] = useState<boolean>(depth !== 0);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isVisibleHover, setIsVisibleHover] = useState<boolean>(false);
  const [isEditHover, setIsEditHover] = useState<Edit>({ code: false, name: false });
  const [isEdit, setIsEdit] = useState<Edit>({ code: false, name: false });
  const { data: subUserGroups } = useGetUserGroups(
    { tenantId: userGroup.tenantId, parentUserGroupId: userGroup.userGroupId },
    { enabled: !isFolded },
  );
  const { mutate: deleteUserGroup } = useDeleteUserGroup();
  const { mutate: createUserGroup } = useAddUserGroup();
  const { mutate: updateUserGroup } = useUpdateUserGroup();

  const {
    register,
    reset,
    getValues,
    formState: { errors },
  } = useGroupForm();

  const {
    register: createGroupRegister,
    handleSubmit: createGroupHandleSubmit,
    reset: createGroupReset,
    formState: { errors: createGroupErrors },
    trigger: createGroupTrigger,
  } = useGroupForm();

  useEffect(() => {
    reset({ name: userGroup.name, userGroupCode: userGroup.userGroupCode });
  }, [reset, userGroup.name, userGroup.userGroupCode]);

  const handleToggleFold = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isCreating) {
      e.stopPropagation();
      setIsFolded(!isFolded);
    }
  };

  const handleClickUserGroupRow = () => {
    if (userGroup) {
      navigate(userGroup.userGroupId);
    }
  };

  const handleClickAddSubGroup = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCreating(true);
  };

  const rqDelete = () => {
    deleteUserGroup(userGroup?.userGroupId as string);
  };

  const handleClickRemoveTenant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    modal.open({
      useCancel: true,
      title: `${t('Member_Group_Delete_01')}`,
      message: `${t('Member_Group_Delete_02')}`,
      onConfirm: rqDelete,
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      cancelName: t('Subscrib_Detail_BuyRequest_13'),
      type: 'error',
    });
  };

  const removeUserGroupCreateSubRow = () => {
    setIsCreating(false);
    setIsDuplicationGroupCode(false);
    createGroupReset();
  };

  const validateHandler = {
    success: async (data: FormData) => {
      try {
        const rqData: UserGroupCreateRq = {
          name: data.name,
          parentUserGroupId: userGroup.userGroupId,
          tenantId: userGroup.tenantId,
          userGroupCode: data.userGroupCode,
        };
        createUserGroup(rqData, {
          onError: error => {
            handleDuplicationGroupCodeError(error);
          },
          onSuccess: () => {
            removeUserGroupCreateSubRow();
            setIsFolded(false);
          },
        });
      } catch (error) {
        console.log('에러 발생');
      }
    },
    fail: (err: any) => {
      console.error('사용자 그룹 생성 실패: ', err);
    },
  };

  const handleDuplicationGroupCodeError = (error: any) => {
    if (axios.isAxiosError(error) && error.response) {
      const { messageId } = error.response.data?.errorCode || {};
      if (messageId === 'USER_GROUP.ERROR.DUPLICATED_USER_GROUP_CODE') {
        setIsDuplicationGroupCode(true);
        return;
      }
      setIsDuplicationGroupCode(false);
    }
    console.error('예상치 못한 오류:', error);
  };

  const handleNameEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsEdit(prev => ({ ...prev, name: true }));
    setIsEditHover(prev => ({ ...prev, name: false }));
  };
  const handleCodeEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsEdit(prev => ({ ...prev, code: true }));
    setIsEditHover(prev => ({ ...prev, code: false }));
  };

  const handleKeyupGroup = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.currentTarget.dataset.key === 'name') {
      if (e.key === 'Enter' && getValues('name').length < 21) {
        const updateDTO = userGroup.getUpdateDTO({ name: getValues('name') });
        updateUserGroup(updateDTO, {
          onSuccess: () => {
            setIsEdit(prev => {
              return { ...prev, name: false };
            });
          },
        });
      }
      if (e.key === 'Escape') {
        reset({ name: userGroup.name, userGroupCode: userGroup.userGroupCode });
        setIsEdit(prev => {
          return { ...prev, name: false };
        });
      }
    }

    if (e.currentTarget.dataset.key === 'code') {
      if (e.key === 'Enter' && getValues('userGroupCode').length < 21) {
        const updateDTO = userGroup.getUpdateDTO({
          name: getValues('name'),
          userGroupCode: getValues('userGroupCode'),
        });
        updateUserGroup(updateDTO, {
          onError: error => {
            handleDuplicationGroupCodeError(error);
          },
          onSuccess: () => {
            setIsEdit(prev => {
              return { ...prev, code: false };
            });
          },
        });
      }
      if (e.key === 'Escape') {
        reset({ name: userGroup.name, userGroupCode: userGroup.userGroupCode });
        setIsEdit(prev => {
          return { ...prev, code: false };
        });
        setIsDuplicationGroupCode(false);
      }
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  const handleKeyupAddGroup = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.code === 'Enter') {
      const result: boolean = await createGroupTrigger(['name', 'userGroupCode']);
      if (result) {
        if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
        }
      }
    }
    if (e.key === 'Escape') {
      removeUserGroupCreateSubRow();
    }
  };

  const handleEditClick = (key: 'name' | 'code') => {
    if (key === 'name' && getValues('name').length < 21) {
      const updateDTO = userGroup.getUpdateDTO({ name: getValues('name') });
      updateUserGroup(updateDTO, {
        onSuccess: () => {
          setIsEdit(prev => ({ ...prev, name: false }));
        },
      });
    } else if (key === 'code' && getValues('userGroupCode').length < 21) {
      const updateDTO = userGroup.getUpdateDTO({
        name: getValues('name'),
        userGroupCode: getValues('userGroupCode'),
      });
      updateUserGroup(updateDTO, {
        onError: error => {
          handleDuplicationGroupCodeError(error);
        },
        onSuccess: () => {
          setIsEdit(prev => {
            return { ...prev, code: false };
          });
        },
      });
    }
  };

  const handleCancelClick = (key: 'name' | 'code') => {
    if (key === 'name') {
      reset({ name: userGroup.name, userGroupCode: userGroup.userGroupCode });
      setIsEdit(prev => ({ ...prev, name: false }));
    } else if (key === 'code') {
      reset({ name: userGroup.name, userGroupCode: userGroup.userGroupCode });
      setIsEdit(prev => ({ ...prev, code: false }));
    }
    setIsDuplicationGroupCode(false);
  };

  const onEditNameClick = () => {
    handleEditClick('name');
  };

  const onEditCodeClick = () => {
    handleEditClick('code');
  };

  const onCancelNameClick = () => {
    handleCancelClick('name');
  };

  const onCancelCodeClick = () => {
    handleCancelClick('code');
  };

  return (
    <>
      <StyledTableRow
        hover
        onMouseLeave={() => setIsVisibleHover(false)}
        onMouseEnter={() => setIsVisibleHover(true)}
        className="sub-user-group"
        onClick={handleClickUserGroupRow}
      >
        <StyledTableCell
          onMouseLeave={() =>
            setIsEditHover(prev => {
              return { ...prev, name: false };
            })
          }
          onMouseEnter={() =>
            setIsEditHover(prev => {
              if (isEdit.name === true) {
                return prev;
              }
              return { ...prev, name: true };
            })
          }
        >
          <NameWrapper
            sx={{ paddingLeft: `${depth * 14}px` }}
            className={userGroup.subUserGroupCount !== 0 ? 'toggle' : isEdit.name ? 'edit-depth' : 'depth'}
          >
            {userGroup.subUserGroupCount !== 0 ? (
              <ToggleButton variant="outline" onClick={handleToggleFold}>
                {isFolded ? (
                  <TriangleIcon rotateNum={180} width={16} height={16} />
                ) : (
                  <TriangleIcon width={16} height={16} />
                )}
              </ToggleButton>
            ) : (
              <DepthIconWrap>
                <DepthIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
              </DepthIconWrap>
            )}

            {isEdit.name ? (
              <Tooltip
                title={errors.name?.message}
                arrow
                placement="bottom-start"
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      maxWidth: 'none',
                    },
                    '& .MuiTooltip-arrow': {
                      transform: 'translate(20px, 0) !important',
                    },
                  },
                }}
              >
                <StyledTextField
                  {...register('name', {
                    required: '그룹 이름을 입력해주세요',
                  })}
                  size="small"
                  fullWidth
                  error={!!errors.name?.message}
                  placeholder={t('Member_Group_Bulk_Create_18')}
                  onClick={e => e.stopPropagation()}
                  onKeyUp={handleKeyupGroup}
                  data-key="name"
                  InputProps={{
                    endAdornment: (
                      <NewDepthBtnWrap>
                        <Button
                          type="submit"
                          variant="outline"
                          size="xsmall"
                          paddingHorizontal={4}
                          onClick={onEditNameClick}
                        >
                          <CheckIcon width={14} height={14} color={theme.colors['ic-green']} />
                        </Button>
                        <Button onClick={onCancelNameClick} variant="outline" size="xsmall" paddingHorizontal={4}>
                          <XIcon width={14} height={14} color={theme.colors['ic-gray-main']} />
                        </Button>
                      </NewDepthBtnWrap>
                    ),
                  }}
                  autoFocus
                  sx={{ '& .MuiInputBase-root': { padding: '6px 4px 6px 11px' } }}
                />
              </Tooltip>
            ) : (
              <Typography variant="body2" component="span">
                {userGroup?.name || '-'}
              </Typography>
            )}
            {isEditHover.name && (
              <EditButton size="small" variant="outline" onClick={handleNameEdit} paddingHorizontal={4}>
                <PencilIcon width={14} height={14} />
              </EditButton>
            )}
          </NameWrapper>
        </StyledTableCell>

        <StyledTableCell
          onMouseLeave={() =>
            setIsEditHover(prev => {
              return { ...prev, code: false };
            })
          }
          onMouseEnter={() =>
            setIsEditHover(prev => {
              if (isEdit.code === true) {
                return prev;
              }
              return { ...prev, code: true };
            })
          }
          className={isEdit.code ? 'edit-name' : ''}
        >
          <CodeWrapper>
            {isEdit.code ? (
              <Tooltip
                title={isDuplicationGroupCode ? t('Member_Group_Delete_07') : errors.userGroupCode?.message}
                arrow
                placement="bottom-start"
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      maxWidth: 'none',
                    },
                    '& .MuiTooltip-arrow': {
                      transform: 'translate(20px, 0) !important',
                    },
                  },
                }}
              >
                <StyledTextField
                  {...register('userGroupCode', { required: '그룹 코드를 입력해주세요' })}
                  size="small"
                  fullWidth
                  placeholder={t('Member_Group_Bulk_Create_17')}
                  error={!!errors.userGroupCode?.message || isDuplicationGroupCode}
                  onClick={e => e.stopPropagation()}
                  onKeyUp={handleKeyupGroup}
                  data-key="code"
                  autoFocus
                  InputProps={{
                    endAdornment: (
                      <NewDepthBtnWrap>
                        <Button
                          type="submit"
                          variant="outline"
                          size="xsmall"
                          paddingHorizontal={4}
                          onClick={onEditCodeClick}
                        >
                          <CheckIcon width={14} height={14} color={theme.colors['ic-green']} />
                        </Button>
                        <Button onClick={onCancelCodeClick} variant="outline" size="xsmall" paddingHorizontal={4}>
                          <XIcon width={14} height={14} color={theme.colors['ic-gray-main']} />
                        </Button>
                      </NewDepthBtnWrap>
                    ),
                  }}
                />
              </Tooltip>
            ) : (
              userGroup.userGroupCode
            )}
            {isEditHover.code && (
              <EditButton size="small" variant="outline" onClick={handleCodeEdit} paddingHorizontal={4}>
                <PencilIcon width={14} height={14} />
              </EditButton>
            )}
          </CodeWrapper>
        </StyledTableCell>
        <StyledTableCell align="left">{userGroup?.totalMemberCount}</StyledTableCell>
        <StyledTableCell>
          <DescriptionWrapper>
            {userGroup?.totalSubscriptionCount}
            <ButtonWrapper>
              {isVisibleHover && depth === 0 ? (
                <DepthButton size="small" variant="outline" paddingHorizontal={4} onClick={handleClickAddSubGroup}>
                  <AddIcon width={14} height={14} />
                </DepthButton>
              ) : isVisibleHover && depth >= 0 ? (
                <>
                  <DepthButton size="small" variant="outline" paddingHorizontal={4} onClick={handleClickAddSubGroup}>
                    <AddIcon width={14} height={14} />
                  </DepthButton>
                  <DepthButton size="small" variant="outline" paddingHorizontal={4} onClick={handleClickRemoveTenant}>
                    <DashIcon width={14} height={14} />
                  </DepthButton>
                </>
              ) : null}
            </ButtonWrapper>
          </DescriptionWrapper>
        </StyledTableCell>
      </StyledTableRow>

      {isCreating && (
        <StyledTableRow>
          <CreatingTableCell>
            <NewDepthForm sx={{ paddingLeft: `${(depth + 1) * 14}px` }}>
              <DepthIconWrap>
                <DepthIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
              </DepthIconWrap>
              <NewTenantField>
                <Tooltip
                  title={createGroupErrors.name?.message}
                  arrow
                  placement="bottom-start"
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        maxWidth: 'none',
                      },
                      '& .MuiTooltip-arrow': {
                        transform: 'translate(20px, 0) !important',
                      },
                    },
                  }}
                >
                  <TextField
                    {...createGroupRegister('name', { required: '그룹 이름을 입력해주세요' })}
                    size="small"
                    fullWidth
                    error={!!createGroupErrors.name?.message}
                    placeholder={t('Member_Group_Bulk_Create_18')}
                    data-key="name"
                    onClick={e => e.stopPropagation()}
                    onKeyUp={handleKeyupAddGroup}
                  />
                </Tooltip>
              </NewTenantField>
            </NewDepthForm>
          </CreatingTableCell>
          <CreatingTableCell>
            <Tooltip
              title={isDuplicationGroupCode ? t('Member_Group_Delete_07') : createGroupErrors.userGroupCode?.message}
              arrow
              placement="bottom-start"
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    maxWidth: 'none',
                  },
                  '& .MuiTooltip-arrow': {
                    transform: 'translate(20px, 0) !important',
                  },
                },
              }}
            >
              <TextField
                {...createGroupRegister('userGroupCode', { required: '그룹 코드를 입력해주세요' })}
                size="small"
                fullWidth
                error={!!createGroupErrors.userGroupCode?.message || isDuplicationGroupCode}
                placeholder={t('Member_Group_Bulk_Create_17')}
                data-key="code"
                onClick={e => e.stopPropagation()}
                onKeyUp={handleKeyupAddGroup}
              />
            </Tooltip>
          </CreatingTableCell>
          <CreatingTableCell align="center">
            <NewDepthBtnWrap>
              <form ref={formRef} onSubmit={createGroupHandleSubmit(validateHandler.success, validateHandler.fail)}>
                <Button type="submit" variant="outline" size="xsmall" paddingHorizontal={4}>
                  <CheckIcon width={14} height={14} color={theme.colors['ic-green']} />
                </Button>
              </form>
              <Button onClick={removeUserGroupCreateSubRow} variant="outline" size="xsmall" paddingHorizontal={4}>
                <XIcon width={14} height={14} color={theme.colors['ic-gray-main']} />
              </Button>
            </NewDepthBtnWrap>
          </CreatingTableCell>
          <CreatingTableCell align="center" />
        </StyledTableRow>
      )}

      {!isFolded
        ? subUserGroups?.map(userGroup => {
            return <UserGroupTableSubRow rowData={userGroup} depth={depth + 1} key={userGroup.userGroupId} />;
          })
        : null}
    </>
  );
});

const NameWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
  &.edit-depth {
    gap: 0;
  }
`;
const CodeWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const DepthButton = styled(Button)`
  height: 24px;
  border-color: ${({ theme }) => theme.colors['border-gray-light']};
`;

const ToggleButton = styled(Button)`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  flex-shrink: 0;
`;
const DepthIconWrap = styled('div')`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const NewDepthForm = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
  & > svg {
    flex-shrink: 0;
  }
`;

const NewTenantField = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  & > svg {
    flex-shrink: 0;
  }
  & .MuiInputBase-input {
    padding: 6px 12px;
    &:placeholder {
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
  }
`;

const NewDepthBtnWrap = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTableCell = styled(TableCell)`
  padding: 0 7px 0 16px;
  &:last-of-type {
    padding-right: 16px;
  }
  &:first-of-type {
    padding: 0 7px 0 20px;
  }
  &.edit-name {
    padding: 0 4px;
  }
`;

const CreatingTableCell = styled(TableCell)`
  border-width: 1px 0 1px 0;
  padding: 0 8px 0 4px;
  &:first-of-type {
    padding: 0 8px 0 24px;
  }
`;

const StyledTableRow = styled(TableRow)`
  background-color: ${({ theme }) => theme.colors['bg-white']};
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
  &:hover.MuiTableRow-hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
  &.sub-user-group {
    cursor: pointer;
  }
`;

const EditButton = styled(Button)`
  height: 24px;
  margin-left: auto;
`;

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 4px 4px 4px 11px;
  }
  & .MuiInputBase-root.Mui-error button:first-of-type svg g path {
    fill: ${({ theme }) => theme.colors['ic-green']};
  }
  & .MuiInputBase-root.Mui-error button:last-of-type svg g path {
    fill: ${({ theme }) => theme.colors['ic-gray-main']};
  }
`;
