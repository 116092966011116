import { Button } from '@components/index';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const ToggleButton = styled(Button)`
  width: 16px;
  height: 16px;
  background: none;
  border: none;
`;

export const SelectWrapper = styled('div')`
  position: relative;
  width: 100%;
`;

export const SelectedButton = styled('button')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #fff;
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 2px 2px 0 0;
  cursor: pointer;
  text-align: left;
  span {
    width: 100%;
    max-width: 245px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const OptionWrapper = styled('ul')`
  position: absolute;
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 4px 5px;
  background-color: ${({ theme }) => theme.colors['bg-white']};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 0 0 2px 2px;
  box-sizing: border-box;
  z-index: 1302;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
`;
export const OptionItem = styled('li')<{ depth?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  ${fonts.Body2}
  color: ${({ theme }) => theme.colors['text-gray-main']};
  padding: 10px 7px;
  padding-left: ${({ depth }) => (depth ? depth * 20 + 7 : 7)}px;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
`;

export const Dimmed = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1301;
`;
