const defaultWidth = 24;
const defaultHeight = 24;

export const ThunderFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7161 1.32915C15.9458 1.12888 16.237 1.01285 16.5415 1.00018C16.846 0.987515 17.1458 1.07897 17.3914 1.25947C17.637 1.43997 17.8138 1.69875 17.8926 1.99317C17.9714 2.28759 17.9476 2.60008 17.8251 2.87915L15.1471 9.00015H19.3081C20.9311 9.00015 21.6801 11.0162 20.4511 12.0752L8.10208 22.7212C7.90585 22.891 7.65788 22.9895 7.39856 23.0005C7.13924 23.0114 6.88385 22.9343 6.67397 22.7816C6.4641 22.6289 6.31211 22.4096 6.24277 22.1595C6.17342 21.9094 6.1908 21.6431 6.29208 21.4042L8.99608 15.0002H4.67408C3.05508 15.0002 2.30408 12.9922 3.52608 11.9302L15.7161 1.33015V1.32915Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ThunderFillIcon.displayName = 'ThunderFillIcon';
