import APIs from '@repositories/APIs';
import {
  PurchaseContract,
  SoftwarePurchaseChangeStatus,
  SoftwarePurchaseContractCreateRq,
  SoftwarePurchaseContractFilter,
  SoftwarePurchaseParams,
  SoftwarePurchaseUpdateRq,
} from '@type/Purchase';
import { makeAutoObservable, runInAction } from 'mobx';

class PurchaseContractStore {
  private readonly _purchaseContracts: Map<PurchaseContractId, PurchaseContract>;

  private _purchaseContract: PurchaseContract | null;

  private _purchaseContractTotalElements: number;

  constructor() {
    this._purchaseContracts = new Map<PurchaseContractId, PurchaseContract>();
    this._purchaseContract = null;
    this._purchaseContractTotalElements = 0;

    makeAutoObservable<PurchaseContractStore>(this);
  }

  async fetchContracts(queries?: SoftwarePurchaseContractFilter) {
    const result = await APIs.PurchaseContract.getList(queries);
    runInAction(() => {
      this._purchaseContracts.clear();
      result.content.forEach(contract => {
        this._purchaseContracts.set(contract.id, contract);
      });
      this._purchaseContractTotalElements = result.totalElements;
    });
    return result.totalElements;
  }

  async fetchContract(params: SoftwarePurchaseParams) {
    const result = await APIs.PurchaseContract.getOne(params);
    runInAction(() => {
      this._purchaseContract = result;
    });
  }

  async createContract(data: SoftwarePurchaseContractCreateRq) {
    const result = await APIs.PurchaseContract.create(data);
    runInAction(() => {
      this._purchaseContracts.set(result.id, result);
    });
    return result;
  }

  async updateContract(params: SoftwarePurchaseParams, data: SoftwarePurchaseUpdateRq) {
    const result = await APIs.PurchaseContract.update(params, data);
    runInAction(() => {
      this._purchaseContract = result;
    });
  }

  async deleteSoftwarePlan(params: SoftwarePurchaseParams) {
    await APIs.PurchaseContract.delete(params);
    runInAction(() => {
      this._purchaseContract = null;
      this._purchaseContracts.delete(params.id);
    });
  }

  async changeContractStatus(params: SoftwarePurchaseParams, data: SoftwarePurchaseChangeStatus) {
    const result = await APIs.PurchaseContract.changeStatus(params, data);
    runInAction(() => {
      this._purchaseContract = result;
    });
    return this._purchaseContract;
  }

  clearPurchaseContract() {
    this._purchaseContract = null;
  }

  get purchaseContracts(): PurchaseContract[] {
    return Array.from(this._purchaseContracts.values());
  }

  get purchaseContract(): PurchaseContract {
    return this._purchaseContract as PurchaseContract;
  }

  get purchaseContractTotalElements(): number {
    return this._purchaseContractTotalElements;
  }
}

const purchaseContractStore = new PurchaseContractStore();

export default purchaseContractStore;
