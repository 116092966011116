import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { KEYS, repository } from '@repositories/Repository';
import type { Filters, SoftwareCategory, SoftwareCategoryCreateRq } from '@repositories/softwareCategoryRepository';
import ISoftwareCategoryRepository from '@repositories/softwareCategoryRepository/ISoftwareCategoryRepository';
import { Page } from '@type/Page';
import { QueryUtil } from '@utils/queryUtil';
import { SoftwareCategoryListModel } from '@models/softwareModels/SoftwareCategoryListModel';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const catRepo = repository.get<ISoftwareCategoryRepository>(KEYS.SOFTWARE_CATEGORY_REPOSITORY);
const catQueryKey = {
  all: ['cat'] as const,
  lists: () => [...catQueryKey.all, 'list'] as const,
  list: (filter?: Filters) => [...catQueryKey.lists(), filter] as const,
  details: () => [...catQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...catQueryKey.details(), id] as const,
};

export const useGetCategories = (filter?: Filters, options?: UseQueryOptionsType<Page<SoftwareCategoryListModel>>) =>
  useQuery<Page<SoftwareCategoryListModel>, AxiosError>({
    queryKey: catQueryKey.list(filter),
    queryFn: async () => {
      const result = await catRepo.getList(filter);
      return {
        ...result,
        content: result.content.map(dto => new SoftwareCategoryListModel(dto)),
      };
    },
    ...options,
  });

export const useGetCategory = (id: string, options?: UseQueryOptionsType<SoftwareCategory>) =>
  useQuery({
    queryKey: catQueryKey.detail(id),
    queryFn: async () => {
      const result = await catRepo.getOne(id);
      return result;
    },
    enabled: QueryUtil.withRequired(!!id, options?.enabled),
    ...options,
  });

export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SoftwareCategoryCreateRq) => {
      await catRepo.create(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(catQueryKey.lists());
    },
  });
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: string; data: SoftwareCategoryCreateRq }) => {
      const result = await catRepo.update(id, data);
      return result.id;
    },
    onSuccess: id => {
      queryClient.invalidateQueries(catQueryKey.lists());
      queryClient.invalidateQueries(catQueryKey.detail(id));
    },
  });
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      await catRepo.delete(id);
      return id;
    },
    onSuccess: id => {
      queryClient.invalidateQueries(catQueryKey.lists());
      queryClient.removeQueries(catQueryKey.detail(id));
    },
  });
};
