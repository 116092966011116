import React, { createContext, useContext } from 'react';
import workflowDetailStore from '@pages/customer/manager/workflow/workflowMgmt/detail/WorkflowDetailStore';
import appStore from './AppStore';
import authStore from './AuthStore';
import categoryStore from './CategoryStore';
import contractStore from './ContractStore';
import inOutcomeStore from './InOutcomeStore';
import planStore from './PlanStore';
import purchaseContractStore from './PurchaseContractStore';
import softwareStore from './SoftwareStore';
import uiStore from './UiStore';
import usageStore from './UsageStore';

class RootStore {
  uiStore;
  authStore;
  appStore;
  softwareStore;
  usageStore;
  contractStore;
  planStore;
  purchaseContractStore;
  inOutcomeStore;
  categoryStore;
  workflowDetailStore;

  constructor() {
    this.uiStore = uiStore;
    this.authStore = authStore;
    this.appStore = appStore;
    this.softwareStore = softwareStore;
    this.usageStore = usageStore;
    this.contractStore = contractStore;
    this.planStore = planStore;
    this.purchaseContractStore = purchaseContractStore;
    this.inOutcomeStore = inOutcomeStore;
    this.categoryStore = categoryStore;
    this.workflowDetailStore = workflowDetailStore;

    // Object.defineProperty(globalThis, 'rootStore', {
    //   enumerable: false,
    //   configurable: false,
    //   writable: true,
    //   value: this,
    // });
  }
}

export const store = new RootStore();

export const StoreContext = createContext(store);
export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
export const useStore = () => {
  return useContext(StoreContext);
};
