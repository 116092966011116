import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Avatar, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { Button } from '@components/index';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { Filters } from '@repositories/softwareRepository';
import { useGetSoftwareListInfinite } from '@queryHooks/useSoftware';
import { DomUtil } from '@utils/DomUtil';
import { useStore } from '@stores/RootStore';
import { SoftwareSimpleModel } from '@models/softwareModels';

type AppListProps = {
  onProductClick: (product: SoftwareSimpleModel) => void;
  onAddRequestClick: () => void;
};
export const AppList = ({ onProductClick, onAddRequestClick }: AppListProps) => {
  const { t } = useTranslation();
  const { categoryStore } = useStore();
  const [filter, setFilter] = useState<Filters>({ name: '' });

  const {
    data: pagedSoftwareList,
    isSuccess,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSoftwareListInfinite(filter);

  const handleScroll = debounce((event: React.UIEvent<HTMLUListElement>) => {
    if (DomUtil.isScrollBottom(event.target, 100) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 100);

  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, name: event.target.value }));
  };

  const handleProductClick = (app: SoftwareSimpleModel) => () => {
    onProductClick(app);
  };

  const filterSoftWareList = pagedSoftwareList?.pages
    .map(page => page.content)
    .flat()
    .filter(list => {
      if (!list.categoryId) {
        return false;
      }
      const findCategory = categoryStore.findRootCategory(list.categoryId);
      // 문구류를 필터링 하기 위한 임시 코드 입니다.
      return findCategory?.code !== 'STATIONERY' && list.categoryCount > 0;
    });

  return (
    <AppListContainer>
      <SelectItem>
        <Title>{t('Subscrib_Create_02')}</Title>
        <TextField
          size="medium"
          fullWidth
          placeholder={t('Acc_Create_03')}
          value={filter.name}
          onChange={handleSearchNameChange}
          autoFocus
        />
      </SelectItem>
      <Apps>
        <AppGrid onScroll={handleScroll}>
          {isSuccess ? (
            filterSoftWareList?.map(software => (
              <AppItem key={software.id} onClick={handleProductClick(software)}>
                <Avatar
                  className="app-icon"
                  src={software.iconUrl}
                  imgProps={{ width: 48, height: 48, loading: 'lazy' }}
                />
                <span>{software.name}</span>
              </AppItem>
            ))
          ) : (
            <Loader left={45} top={55} />
          )}
        </AppGrid>
        <Footer>
          <ButtonWrapper>
            <Button className="find-button" variant="text" onClick={onAddRequestClick}>
              {t('Subscrib_Create_03')}
            </Button>
          </ButtonWrapper>
        </Footer>
      </Apps>
    </AppListContainer>
  );
};

const AppListContainer = styled.section``;

const Title = styled.h3`
  ${fonts.Headline8}
  margin: 0px 0px 6px;
`;

const SelectItem = styled.div`
  padding: 12px 28px 20px;
`;

const Apps = styled.div`
  position: relative;
  height: 590px;
`;

const AppGrid = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 112px;
  column-gap: 26px;
  row-gap: 10px;
  border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  padding: 20px 28px;
  overflow-y: auto;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  height: calc(100% - 71px);
`;

const AppItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  gap: 6px;
  cursor: pointer;
  &:hover {
    & .app-icon {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
    }
    & span {
      text-decoration: underline;
    }
  }
  & .app-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid ${({ theme: { colors } }) => colors['border-bk-opacity-10%']};
    border-radius: 6px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
    background: ${({ theme: { colors } }) => colors['bg-white']};
    img {
      max-width: 40px;
      max-height: 40px;
      border-radius: 6px;
    }
  }

  & span {
    max-width: 88px;
    height: 56px;
    ${fonts.Body2}
    line-height: 19.5px;
    word-wrap: break-word;
    text-align: center;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 13px 0px 38px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};

  & .find-button {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-purple-light']}
  }
  &::after {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    position: absolute;
    top: -18px;
    left: 0;
    background: ${({ theme: { colors } }) => colors['bg-gray-light-gradient-90']};
  }
`;
