import { useSelectedLocation } from '@customHooks/useSelectedLocation';
import * as S from './FloatingLnbStyles';

type Menu = {
  name: string;
  icon?: React.ReactNode;
  to: string;
};

interface Props {
  title: string;
  menuItem: Menu[];
}

export const FloatingLnb = ({ title, menuItem }: Props) => {
  const [curMenu] = useSelectedLocation({ itemList: menuItem.map(({ name, to }) => ({ name, path: to })) });

  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Divider />
      {menuItem?.map(menu => {
        return <MenuItem key={menu.name} menu={menu} isSelected={curMenu?.path === menu.to} />;
      })}
    </S.Container>
  );
};

const MenuItem: React.FC<{ menu: Menu; className?: string; isSelected: boolean }> = ({
  menu: { name, to, icon },
  isSelected,
}) => (
  <S.MenuContainer className={isSelected ? 'selected' : ''}>
    <S.MenuLink to={to ?? ''}>
      <span>{name}</span>
      {icon}
    </S.MenuLink>
  </S.MenuContainer>
);
