const defaultWidth = 24;
const defaultHeight = 24;

export const ReceiptColorIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        d="M6.50024 3.49986C6.73657 3.49986 7.34824 2.8189 7.50024 2.99986L8.46011 2.65616L9.50024 2.99986C9.65225 2.8189 9.99569 2.65616 10.232 2.65616C10.4683 2.65616 10.8482 2.3189 11.0002 2.49986L11.8201 2.65616L13.0002 2.49986C13.1522 2.3189 13.7639 2.49986 14.0002 2.49986C14.2366 2.49986 14.5375 2.4752 14.6895 2.65616H15.1801L16.5002 2.49986C16.6522 2.3189 16.7639 2.99986 17.0002 2.99986C17.2366 2.99986 17.3482 2.4752 17.5002 2.65616H18.5401L20.0002 2.99986C20.1522 2.8189 20.2639 3.00003 20.5002 3.00003C20.7366 3.00003 21.3482 3.81904 21.5002 4L22.0002 4.5L22.5127 4.41451L22.5 15.1878L22.5 15.1996V19.5996C22.5 21.146 21.2464 22.3996 19.7 22.3996C18.1536 22.3996 16.9 21.146 16.9 19.5996L10.7527 15.9999L21.5002 10.4999L20.9 13.4999C20.9 14.1626 20.2373 14.7684 20.9 14.7684C21.5628 14.7684 20.9 20.2624 20.9 19.5996L6.16755 3.14375H20.2201L19.1527 4.41451C19.0007 4.59547 18.7764 4.69996 18.5401 4.69996C18.3038 4.69996 18.0796 4.59547 17.9275 4.41451L16.8601 3.14375L15.7927 4.41451C15.6407 4.59547 15.4164 4.69996 15.1801 4.69996C14.9438 4.69996 14.7196 4.59547 14.5675 4.41451L13.5001 3.14375L12.4327 4.41451C12.2807 4.59547 12.0564 4.69996 11.8201 4.69996C11.5838 4.69996 11.3596 4.59547 11.2075 4.41451L10.1401 3.14375L9.07268 4.41451C8.92067 4.59547 8.69644 4.69996 8.46011 4.69996C8.22379 4.69996 19.439 4.88091 19.287 4.69996L22.0002 9.49986L16.9 21.9999L4.5 16.3998V5.14121V5.5V5L5.50024 3.99986C5.65225 3.8189 6.26392 3.49986 6.50024 3.49986Z"
        fill="#8A63D2"
      />
      <g filter="url(#filter0_d_2796_12247)">
        <path
          d="M18.5 15.3999H1.5V19.3999C1.5 21.0568 2.84315 22.3999 4.5 22.3999H19.5C20.7552 22.3547 21.5 22 22.1 21C21.5 21.5 18.5 22 18.5 19V16V15.3999Z"
          fill="#5A32A3"
        />
      </g>
      <g filter="url(#filter1_d_2796_12247)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1404 6.2998H8.9402H8.92457V6.29997C8.52309 6.30828 8.2002 6.63626 8.2002 7.03963C8.2002 7.443 8.52309 7.77097 8.92457 7.77929V7.77945H8.93959L8.9402 7.77945L8.9408 7.77945H18.1398C18.14 7.77945 18.1402 7.77945 18.1404 7.77945C18.1406 7.77945 18.1408 7.77945 18.141 7.77945H18.1683V7.77894C18.564 7.76428 18.8804 7.43889 18.8804 7.03963C18.8804 6.64036 18.564 6.31497 18.1683 6.30032V6.2998H18.1404ZM8.96568 10.2202H14.4148C14.4232 10.2199 14.4317 10.2197 14.4402 10.2197C14.4488 10.2197 14.4573 10.2199 14.4657 10.2202H14.4708V10.2203C14.8653 10.2364 15.1802 10.5612 15.1802 10.9595C15.1802 11.3579 14.8653 11.6827 14.4708 11.6988V11.6998H8.92457V11.6992C8.52309 11.6909 8.2002 11.3629 8.2002 10.9595C8.2002 10.551 8.5315 10.2197 8.9402 10.2197C8.94872 10.2197 8.95722 10.2199 8.96568 10.2202Z"
          fill="#E6DCFD"
        />
      </g>
      <path d="M4.5 3.5C4.5 2.67157 5.17157 2 6 2H21C21.8284 2 22.5 2.67157 22.5 3.5V5H4.5V3.5Z" fill="#8A63D2" />
      <defs>
        <filter
          id="filter0_d_2796_12247"
          x="0.5"
          y="14.8999"
          width="22.6"
          height="9"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2796_12247" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2796_12247" result="shape" />
        </filter>
        <filter
          id="filter1_d_2796_12247"
          x="7.7002"
          y="6.2998"
          width="11.6802"
          height="6.40002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2796_12247" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2796_12247" result="shape" />
        </filter>
      </defs>
    </g>
  </svg>
);
