import { useForm, type UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type FormTCRq = {
  inquiry: string;
};

export const useTCForm = () => {
  const validationSchema = Yup.object().shape({
    inquiry: Yup.string().required('문의 내용을 작성해주세요.'),
  });

  const formOptions: UseFormProps<FormTCRq> = {
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      inquiry: '',
    },
  };
  return useForm<FormTCRq>(formOptions);
};
