const defaultWidth = 24;
const defaultHeight = 24;

export const ExcelIcon = ({ width = defaultWidth, height = defaultHeight, color = '#176F2C' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0591 2.51074C15.3091 2.38574 16.3938 3.36736 16.3938 4.62361V19.3764C16.3938 20.6326 15.3091 21.6143 14.0591 21.4893L4.91212 20.5746C3.82663 20.466 3 19.5526 3 18.4617V5.5383C3 4.4474 3.82663 3.53399 4.91212 3.42544L14.0591 2.51074ZM17.5712 20.3299H19.4958C20.6685 20.3299 21.6192 19.3793 21.6192 18.2065V6.44616C21.6192 5.27343 20.6685 4.32275 19.4958 4.32275H17.5712V5.95614H19.4958C19.7664 5.95614 19.9858 6.17553 19.9858 6.44616V18.2065C19.9858 18.4772 19.7664 18.6966 19.4958 18.6966H17.5712V20.3299ZM6.22875 8.40688H8.07674L9.92535 10.8717L11.7739 8.40703H13.6219L10.8494 12.1037L13.6207 15.7989H11.7727L9.92535 13.3357L8.07787 15.799H6.22987L9.00136 12.1037L6.22875 8.40688Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ExcelIcon.displayName = 'ExcelIcon';
