import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import {
  Filter,
  LicenseCreateDTO,
  LicenseSubscriptionUserUpdateDTO,
  LicenseUpdateDTO,
  LicenseSubscriptionUserBulkUpdateDTO,
} from '@repositories/licenseRepository';
import { ILicenseRepository } from '@repositories/licenseRepository/ILicenseRepository';
import { LicenseStatus } from '@repositories/subscriptionUserRepository/Types';
import { Page } from '@type/Page';
import { QueryUtil } from '@utils/queryUtil';
import { LicenseModel } from '@models/LicenseModel';
import { SubscriptionUserModel } from '@models/SubscriptionUserModel';
import { sbQueryKey } from './useSubscription';

const licenseRepo = repository.get<ILicenseRepository>(KEYS.LICENSE_REPOSITORY);

const licenseKeys = {
  all: ['license'] as const,
  lists: () => [...licenseKeys.all, 'list'] as const,
  details: () => [...licenseKeys.all, 'detail'] as const,
  list: (queries?: Filter) => [...licenseKeys.lists(), queries] as const,
  listInfinite: (queries: Filter) => [...licenseKeys.list(queries), 'infinite'] as const,
  detail: (id: string) => [...licenseKeys.details(), id] as const,
  userList: (id: string) => [...licenseKeys.lists(), 'user', id] as const,
};

export const useGetLicenses = (queries: Filter, options?: UseQueryOptions<Page<LicenseModel>>) =>
  useQuery({
    queryKey: licenseKeys.list(queries),
    queryFn: async () => {
      const result = await licenseRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new LicenseModel(dto)),
      };
    },
    ...options,
  });

export const useGetLicenseListInfinite = (queries: Filter, options?: UseInfiniteQueryOptions<Page<LicenseModel>>) =>
  useInfiniteQuery({
    queryKey: licenseKeys.listInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await licenseRepo.getList({ ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new LicenseModel(dto)),
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetLicense = (id: string, options?: UseQueryOptions<LicenseModel>) =>
  useQuery({
    queryKey: licenseKeys.detail(id),
    queryFn: async () => {
      const result = await licenseRepo.getOne(id);
      return new LicenseModel(result);
    },
    enabled: QueryUtil.withRequired(!!id, options?.enabled),
    ...options,
  });

export const useAddLicense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: LicenseCreateDTO) => {
      const result = await licenseRepo.create(data);
      return result;
    },
    onSuccess: created => {
      if (created.subscription?.id) {
        queryClient.invalidateQueries(licenseKeys.list({ subscriptionId: created.subscription.id }));
      }
    },
  });
};

export const useUpdateLicense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: LicenseUpdateDTO) => {
      const result = await licenseRepo.update(data.licenseId, data);
      return result;
    },
    onSuccess: updated => {
      if (updated.licenseId && updated.subscription?.id) {
        queryClient.invalidateQueries(licenseKeys.list({ subscriptionId: updated.subscription.id }));
        queryClient.invalidateQueries(licenseKeys.detail(updated.licenseId));
        // NOTE. 라이선스 정보 수정에 따른 구독 기간 변경이 일어날 수 있음.
        queryClient.invalidateQueries(sbQueryKey.detail(updated.subscription.id));
        queryClient.invalidateQueries(sbQueryKey.lists());
      }
    },
  });
};

export const useDeleteLicense = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ licenseId, subscriptionId }: { licenseId: string; subscriptionId: string }) => {
      await licenseRepo.delete(licenseId);
      return subscriptionId;
    },
    onSuccess: subscriptionId => {
      queryClient.invalidateQueries(licenseKeys.list({ subscriptionId }));
    },
  });
};

export const useGetLicenseUserList = (
  id: string,
  subscriptionId: string,
  options?: UseQueryOptions<SubscriptionUserModel[]>,
) =>
  useQuery({
    queryKey: licenseKeys.userList(id),
    queryFn: async () => {
      const result = await licenseRepo.getUserList(id);
      return result.map(dto => new SubscriptionUserModel(dto, subscriptionId));
    },
    ...options,
  });

export const usePatchLicenseUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      subscriptionId,
      data,
    }: {
      subscriptionId: string;
      data: LicenseSubscriptionUserUpdateDTO;
    }) => {
      const result = await licenseRepo.patchUser(data.licenseId, data);
      return result;
    },
    onSuccess: (updated, variables) => {
      const { subscriptionId } = variables;
      if (updated.licenseId) {
        queryClient.invalidateQueries(sbQueryKey.lists());
        queryClient.invalidateQueries(licenseKeys.lists());
        queryClient.invalidateQueries(licenseKeys.list({ subscriptionId }));
        queryClient.invalidateQueries(licenseKeys.detail(updated.licenseId));
        queryClient.invalidateQueries(sbQueryKey.detail(subscriptionId));
      }
    },
  });
};

export const useCreateBulkLicenseUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      subscriptionId,
      data,
    }: {
      subscriptionId: string;
      data: LicenseSubscriptionUserBulkUpdateDTO;
    }) => {
      const result = await licenseRepo.updateUser(data.licenseId, data);
      return result;
    },
    onSuccess: (updated, variables) => {
      const { subscriptionId } = variables;
      if (updated.licenseId) {
        queryClient.invalidateQueries(sbQueryKey.lists());
        queryClient.invalidateQueries(licenseKeys.lists());
        queryClient.invalidateQueries(licenseKeys.list({ subscriptionId }));
        queryClient.invalidateQueries(licenseKeys.detail(updated.licenseId));
        queryClient.invalidateQueries(sbQueryKey.detail(subscriptionId));
      }
    },
  });
};

export const useLicenseStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ licenseId, data }: { licenseId: string; data: LicenseStatus }) => {
      const result = await licenseRepo.updateLicenseStatus(licenseId, data);
      return result;
    },
    onSuccess: updated => {
      if (updated.licenseId && updated.subscription?.id) {
        queryClient.invalidateQueries(licenseKeys.list({ subscriptionId: updated.subscription.id }));
        queryClient.invalidateQueries(licenseKeys.detail(updated.licenseId));
        queryClient.invalidateQueries(sbQueryKey.detail(updated.subscription.id));
        queryClient.invalidateQueries(sbQueryKey.lists());
      }
    },
  });
};
