import type { ReactNode } from 'react';
import type React from 'react';
import { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

type CollapseProps = {
  children: ReactNode;
  open: boolean;
  orientation?: 'horizontal' | 'vertical';
  width?: string;
  height?: string;
  className?: string;
};

const CollapseContainer = styled.div<{
  orientation: 'horizontal' | 'vertical';
  width: string;
  height: string;
}>`
  transition: ${({ orientation }) => (orientation === 'horizontal' ? 'width' : 'height')} 300ms;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const Collapse: React.FC<CollapseProps> = ({
  children,
  open,
  orientation = 'vertical',
  width,
  height,
  className = '',
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [dynamicSize, setDynamicSize] = useState({
    width: width || '0',
    height: height || '0',
  });

  useEffect(() => {
    if (contentRef.current) {
      const measuredWidth = contentRef.current.scrollWidth;
      const measuredHeight = contentRef.current.scrollHeight;

      setDynamicSize({
        width: width || (open ? `${measuredWidth}px` : '0'),
        height: height || (open ? `${measuredHeight}px` : '0'),
      });
    }
  }, [open, width, height]);

  return (
    <CSSTransition in={open} timeout={300} classNames="collapse" unmountOnExit>
      <CollapseContainer
        ref={contentRef}
        className={className}
        orientation={orientation}
        width={dynamicSize.width}
        height={dynamicSize.height}
      >
        {children}
      </CollapseContainer>
    </CSSTransition>
  );
};
