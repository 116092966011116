import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, ButtonGroup } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { nanoid } from 'nanoid';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { Button } from '@components/index';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { GraphIcon, TableIcon } from '@icons/index';
import { SubscriptionDailyUsageFilter, SubscriptionMonthlyUsageFilter } from '@repositories/subscriptionRepository';
import { Usage as RealtimeUsage } from '@repositories/usage';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetUsageMetricList } from '@queryHooks/useSoftware';
import { dateFormat, dateTimeFormat } from '@utils/dateUtil';
import { numberFormat } from '@utils/numberFormat';
import { SubscriptionDetailOutletContext } from '../SubscriptionDetail';
import { OverallUsage } from './OverallUsage';
import { SkeletonUsage } from './SkeletonUsage';
import { UsageChart } from './UsageChart';
import { UsageFilter } from './UsageFilter';
import { UsageTable } from './UsageTable';

export type UsageUiFiler = {
  viewMode: 'chart' | 'table';
  dateMode: 'month' | 'day';
  startDate: DateTimeString;
  endDate: DateTimeString;
};

export const Usage = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();

  const curDayjs = dayjs();

  const {
    usage: { usageUiFilter, setUsageUiFilter },
  } = useOutletContext<SubscriptionDetailOutletContext>();
  const usageApiFilter: SubscriptionDailyUsageFilter | SubscriptionMonthlyUsageFilter =
    usageUiFilter.dateMode === 'day'
      ? {
          dateFrom: dateFormat(usageUiFilter.startDate, '', 'YYYY-MM-DD'),
          dateTo: dateFormat(usageUiFilter.endDate, '', 'YYYY-MM-DD'),
          sort: ['date.desc'],
        }
      : {
          monthFrom: dateFormat(usageUiFilter.startDate, '', 'YYYY-MM'),
          monthTo: dateFormat(usageUiFilter.endDate, '', 'YYYY-MM'),
          sort: ['month.desc'],
        };

  const { data: usageMetrics } = useGetUsageMetricList(subscription.software.id);

  const handleChangeViewMode = (selectedMode: typeof usageUiFilter.viewMode) => () => {
    setUsageUiFilter(prev => ({ ...prev, viewMode: selectedMode }));
  };

  const handleTableFilterUpdate = {
    updateDateMode: (dateMode: typeof usageUiFilter.dateMode) => {
      const startDayjs: Dayjs =
        dateMode === 'day'
          ? dayjs.max(curDayjs.add(-1, 'month'), dayjs(subscription.startDate))
          : dayjs(subscription.startDate);
      const endDayjs: Dayjs = curDayjs;
      setUsageUiFilter(prev => ({
        ...prev,
        dateMode,
        startDate: startDayjs.format('YYYY-MM-DD'),
        endDate: endDayjs.format('YYYY-MM-DD'),
      }));
    },
    updateStartDate: (startDate: Dayjs | null) => {
      if (startDate && startDate.isValid()) {
        setUsageUiFilter(prev => ({ ...prev, startDate: startDate.format('YYYY-MM-DD') }));
      } else {
        setUsageUiFilter(prev => ({ ...prev, startDate: subscription.startDate }));
      }
    },
    updateEndDate: (endDate: Dayjs | null) => {
      if (endDate && endDate.isValid()) {
        setUsageUiFilter(prev => ({ ...prev, endDate: endDate.format('YYYY-MM-DD') }));
      } else {
        setUsageUiFilter(prev => ({ ...prev, endDate: curDayjs.format('YYYY-MM-DD') }));
      }
    },
  };

  const originalDataCount = usageMetrics?.length || 0;

  const totalColumns = Math.ceil(originalDataCount / 4) * 4;
  const formattedDateTime = dateTimeFormat(subscription.realtimeUsageDateTime, '-', { fullFormat: true });

  return (
    <Container>
      <SummaryWrapper>
        {usageMetrics && usageMetrics.length > 0 ? (
          <>
            <BaseDate>
              {t('Subscrib_Detail_Usage_01', { date: formattedDateTime, timezone: dayjs.tz.guess() })}
            </BaseDate>
            <DataWrapper>
              <OverallUsage />
              <ModuleWrapper>
                <Title>{t('Subscrib_Detail_Usage_03')}</Title>
                <GridWrapper>
                  {usageMetrics?.map(({ usageMetricId, usageMetricCode, usageMetricName }) => {
                    const realtimeUsage = subscription.getModuleRealtimeUsage(usageMetricCode);
                    return (
                      <ModuleItem
                        key={usageMetricId}
                        usageMetricCode={usageMetricCode}
                        usageMetricName={usageMetricName}
                        realtimeUsage={realtimeUsage}
                        className={usageMetrics.length < 5 ? 'one-line' : 'multi-line'}
                      />
                    );
                  })}
                  {Array.from({ length: totalColumns - (usageMetrics ?? []).length }).map(() => (
                    <ModuleEmptyItem key={nanoid()} className="empty-item" />
                  ))}
                </GridWrapper>
              </ModuleWrapper>
            </DataWrapper>
          </>
        ) : (
          <SkeletonUsage />
        )}
      </SummaryWrapper>
      <GrayDivider />
      <Header>
        <UsageFilter
          filter={{
            dateMode: usageUiFilter.dateMode,
            startDayjs: dayjs(usageUiFilter.startDate),
            endDayjs: dayjs(usageUiFilter.endDate),
          }}
          onDateModeChange={handleTableFilterUpdate.updateDateMode}
          onStartDateChange={handleTableFilterUpdate.updateStartDate}
          onEndDateChange={handleTableFilterUpdate.updateEndDate}
          disabled={usageMetrics?.length === 0}
        />
        <ViewModeButtonGroup>
          <ViewModeButton
            size="small"
            variant="outline"
            className={usageUiFilter.viewMode === 'chart' ? 'selected' : 'none'}
            onClick={handleChangeViewMode('chart')}
            disabled={usageMetrics?.length === 0}
          >
            <GraphIcon
              width={16}
              height={16}
              color={usageUiFilter.viewMode === 'chart' ? colors['border-gray-light'] : colors['border-gray-darker']}
            />
          </ViewModeButton>
          <ViewModeButton
            size="small"
            variant="outline"
            className={usageUiFilter.viewMode === 'table' ? 'selected' : 'none'}
            onClick={handleChangeViewMode('table')}
            disabled={usageMetrics?.length === 0}
          >
            <TableIcon
              width={16}
              height={16}
              color={usageUiFilter.viewMode === 'table' ? colors['border-gray-light'] : colors['border-gray-darker']}
            />
          </ViewModeButton>
        </ViewModeButtonGroup>
      </Header>
      <div style={{ margin: '0px 16px' }}>
        <ErrorBoundary fallback={<Loader />}>
          <Suspense fallback={<Loader />}>
            {usageUiFilter.viewMode === 'chart' ? (
              <UsageChart chartMode={usageUiFilter.dateMode} chartApiFilter={usageApiFilter} />
            ) : (
              <UsageTable tableMode={usageUiFilter.dateMode} tableApiFilter={usageApiFilter} />
            )}
          </Suspense>
        </ErrorBoundary>
      </div>
    </Container>
  );
};

type ModuleItemProps = {
  usageMetricCode: string;
  usageMetricName: string;
  realtimeUsage?: RealtimeUsage;
  className?: string;
};
const ModuleItem = ({ usageMetricCode, usageMetricName, realtimeUsage, className }: ModuleItemProps) => {
  const renderValue = () => {
    if (!realtimeUsage) {
      return <div>-</div>;
    }
    const { limit, value, unit } = realtimeUsage;
    const formattedValue = numberFormat({ num: value, maxFractionDigit: 5 });

    if (limit > 0) {
      // 단위와 제한이 있는 경우
      const formattedLimit = numberFormat({ num: limit, maxFractionDigit: 5 });
      return (
        <ModuleItemValueContainer justifyContent="space-between">
          <ModuleItemValue>{formattedValue}</ModuleItemValue>
          <ModuleItemLimitUnit>{unit ? `/ ${formattedLimit} ${unit}` : `/ ${formattedLimit}`}</ModuleItemLimitUnit>
        </ModuleItemValueContainer>
      );
    }
    return (
      <ModuleItemValueContainer gap="2px">
        <ModuleItemValue>{unit ? `${formattedValue} ${unit}` : formattedValue}</ModuleItemValue>
      </ModuleItemValueContainer>
    );
  };

  return (
    <ModuleItemContainer key={usageMetricCode} className={className}>
      <div className="key">{usageMetricName}</div>
      {renderValue()}
    </ModuleItemContainer>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 12px 0px;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    height: calc(100% - 245px);
  } ;
`;

const SummaryWrapper = styled.div`
  padding: 32px;
`;

const BaseDate = styled.span`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin-bottom: 2px;
`;

const DataWrapper = styled.div`
  display: flex;
`;

export const Title = styled.span`
  ${fonts.Headline7}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

export const ModuleWrapper = styled.div`
  width: 100%;
`;

export const GridWrapper = styled.div`
  width: 100%;
  margin: 15px 0px 51px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
`;

export const ModuleItemContainer = styled.div`
  min-width: 180px;
  padding: 17px 20px;
  ${fonts.Body2}
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};

  &:nth-of-type(4n) {
    border-right: none;
  }

  & > .key {
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  &.one-line {
    border-bottom: none;
  }
  &.multi-line:nth-last-of-type(-n + 4) {
    border-bottom: 0;
  }
`;

export const ModuleEmptyItem = styled('div')`
  min-width: 180px;
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  &:last-of-type {
    border-right: none;
  }
`;

export const ModuleItemValueContainer = styled(Box)`
  display: flex;
  flex-shrink: 0;
`;

export const ModuleItemValue = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ModuleItemLimitUnit = styled.span`
  ${fonts.Body6}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
  flex-shrink: 0;
  margin-left: 6px;
`;

export const GrayDivider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 16px 20px;
  width: 100%;
  justify-content: space-between;
`;

export const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
  cursor: pointer;
`;

export const ViewModeButtonGroup = styled(ButtonGroup)`
  background: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 2px;
`;

export const ViewModeButton = styled(Button)`
  width: 28px;
  height: 28px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors['bg-gray-light']};
  border-radius: 1px;
  cursor: pointer;

  &.selected {
    background: ${({ theme: { colors } }) => colors['bg-gray-light']};
  }

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;
