import styled from '@emotion/styled';
import { MessageText } from '@components/text/MessageText';
import { fonts } from '@theme/fontsCustomer';

export const TriggerExecView = () => {
  return (
    <Container>
      <ToolbarName>트리거 실행</ToolbarName>
      <Body>
        <MessageText>트리거 실행 결과가 존재하지 않습니다.</MessageText>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: inherit;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  border: 0px;
  padding: 28px 0 20px;
  position: relative;
`;

const ToolbarName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 18px;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;
