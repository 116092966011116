import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import { fonts } from '@theme/fontsCustomer';
import { useGetTenantBillingStat } from '@queryHooks/useTenantBillingStat';
import { numberFormat } from '@utils/numberFormat';

export const TotalOptimizable = () => {
  const { data: tenantBillingStat, isSuccess } = useGetTenantBillingStat({ month: dayjs().format('YYYY-MM') });

  return (
    <SummaryWrapper>
      <SummaryItem>
        <SummaryItemTitle>년 기준</SummaryItemTitle>
        <SummaryItemValueWrapper>
          <SummaryValue>
            {isSuccess ? (
              <>
                <small>(KRW)</small>
                {numberFormat({ num: tenantBillingStat.remainingAnnualPaymentReductionEstimate })}
              </>
            ) : (
              <Skeleton width={230} />
            )}
          </SummaryValue>
          {/* <SummaryValuePercent $value={12}>
            {isSuccess ? (
              <>
                <CircleFillIcon width={16} height={16} color={colors['ic-red-light']} rotateNum={180} />
                {`${tenantBillingStat.optimizableAnnualPaymentEstimateGrowthRate.toFixed(2)}%`}
              </>
            ) : (
              <Skeleton width={90} />
            )}
          </SummaryValuePercent> */}
        </SummaryItemValueWrapper>
      </SummaryItem>
      <SummaryItem>
        <SummaryItemTitle>월 기준</SummaryItemTitle>
        <SummaryItemValueWrapper>
          <SummaryValue>
            {isSuccess ? (
              <>
                <small>(KRW)</small>
                {numberFormat({ num: tenantBillingStat.remainingMonthlyPaymentReductionEstimate })}
              </>
            ) : (
              <Skeleton width={230} />
            )}
          </SummaryValue>
          <SummaryValuePercent $value={12}>
            {/* {isSuccess ? (
              <>
                <CircleFillIcon width={16} height={16} color={colors['ic-red-light']} rotateNum={180} />
                {`${tenantBillingStat.optimizableMonthlyPaymentEstimateGrowthRate.toFixed(2)}%`}
              </>
            ) : (
              <Skeleton width={90} />
            )} */}
          </SummaryValuePercent>
        </SummaryItemValueWrapper>
      </SummaryItem>
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 24px;
`;

const SummaryItem = styled.div`
  padding: 0px 36px;

  & + & {
    border-left: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  }
`;

const SummaryItemTitle = styled.span`
  ${fonts.Headline9}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const SummaryItemValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`;

const SummaryValue = styled.span`
  display: flex;
  align-items: flex-end;
  ${fonts.Headline3};
  color: ${({ theme: { colors } }) => colors['text-purple']};

  & > small {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin: 0px 6px 7px 0px;
  }
`;

const SummaryValuePercent = styled.span<{ $value: number }>`
  display: flex;
  align-items: center;
  gap: 3px;
  ${fonts.Headline7};
  color: ${({ $value, theme: { colors } }) => ($value === 0 ? colors['text-gray-main'] : colors['text-red-light'])};
`;
