// generateGraph.ts
import type { NodeDataType } from './GraphType';
import { type Node, type Edge, MarkerType } from 'reactflow';
import dagre from '@dagrejs/dagre';
import { WorkflowActionDTOModel } from '@models/workflow/WorkflowActionDTOModel';
import { WorkflowTriggerDTOModel } from '@models/workflow/WorkflowTriggerDTOModel';

const generateTriggerNode = (model?: WorkflowTriggerDTOModel): Node<NodeDataType> => {
  return {
    id: `trigger`,
    type: 'trigger',
    position: { x: 0, y: 0 },
    data: {
      index: 0,
      type: 'trigger',
      triggerModel: model ? model.toCreateDTO() : new WorkflowTriggerDTOModel({}).toCreateDTO(),
      baseTriggerModel: model ? model.baseTrigger : undefined,
    },
  };
};

export const generateActionNode = (actionIndex: number, model?: WorkflowActionDTOModel): Node<NodeDataType> => {
  return {
    id: `action-${actionIndex}`,
    type: 'action',
    data: {
      index: actionIndex + 1,
      type: 'action',
      actionModel: model
        ? model.toCreateDTO()
        : new WorkflowActionDTOModel({ type: 'CONNECTION_ACTION' }).toCreateDTO(),
      connectionModel: model ? model.connection : undefined,
      isSmpAction: model
        ? model.type === 'CONNECTION_ACTION' && (!model.connection || !model.connection.connectionId)
        : false,
      actionIndex,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

export const generateEndNode = (actionIndex: number): Node<NodeDataType> => {
  return {
    id: `end-${actionIndex}`,
    type: 'end',
    position: { x: 0, y: 0 },
    data: {
      index: -1,
      type: 'end',
    },
  };
};

export const generateNormalEdge = (sourceId: string, targetId: string) => {
  return {
    id: `${sourceId}:${targetId}`,
    source: sourceId,
    target: targetId,
    sourceHandle: 'normal',
    targetHandle: 'normal',
    type: 'normal',
    data: {
      edgeType: 'default',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  };
};

export const generateExceptionEdge = (sourceId: string, targetId: string) => {
  return {
    id: `${sourceId}:${targetId}`,
    source: sourceId,
    target: targetId,
    sourceHandle: 'exception',
    targetHandle: 'exception',
    type: 'exception',
    data: { edgeType: 'default' },
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  };
};

export const generateConditionThenEdge = (sourceId: string, targetId: string) => {
  return {
    id: `${sourceId}:${targetId}`,
    source: sourceId,
    target: targetId,
    sourceHandle: 'conditionThen',
    targetHandle: 'conditionThen',
    type: 'conditionThen',
    data: { edgeType: 'default' },
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  };
};

const nodeSizes = new Map([
  ['trigger', { width: 376, height: 110 }],
  ['action', { width: 376, height: 110 }],
  ['end', { width: 100, height: 50 }],
]);

export function getLayoutedElements(nodes: Node[], edges: Edge[]) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: 'TB', align: 'UL' });

  nodes.forEach(node => {
    const size = nodeSizes.get(node.type ?? 'action') ?? { width: 376, height: 110 };
    dagreGraph.setNode(node.id, { ...size });
  });

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const size = nodeSizes.get(node.type ?? 'action') ?? { width: 376, height: 110 };

    return {
      ...node,
      position: {
        x: nodeWithPosition.x - size.width / 2,
        y: nodeWithPosition.y - size.height / 2,
      },
      style: { ...node.style, width: size.width },
    };
  });

  return { nodes: layoutedNodes, edges };
}

interface GenerateGraphModel {
  trigger?: WorkflowTriggerDTOModel;
  actions: WorkflowActionDTOModel[];
}

export const generateGraph = ({ trigger, actions }: GenerateGraphModel) => {
  const nodes: Node<NodeDataType>[] = [];
  const edges: Edge[] = [];
  const visitedMap = new Map<number, Node>();

  // 트리거 노드 추가
  const triggerNode = generateTriggerNode(trigger);
  nodes.push(triggerNode);

  // 트리거에서 첫 번째 액션으로 엣지 추가
  if (actions.length > 0) {
    const actionNode = generateGraphFromAction(0);
    if (actionNode) {
      edges.push(generateNormalEdge(triggerNode.id, actionNode.id));
    }
  } else {
    // 리프 노드인 경우 종료 노드 추가
    const endNode = generateEndNode(0);
    nodes.push(endNode);
    edges.push(generateNormalEdge(triggerNode.id, endNode.id));
  }

  function generateGraphFromAction(actionIndex: number): Node<NodeDataType> | undefined {
    if (!actions[actionIndex]) return undefined;

    if (visitedMap.has(actionIndex)) {
      return visitedMap.get(actionIndex);
    }

    const action = actions[actionIndex];
    if (!action) return undefined;

    // 액션 노드 추가
    const actionNode = generateActionNode(actionIndex, action);
    nodes.push(actionNode);

    visitedMap.set(actionIndex, actionNode);

    // nextStep 엣지 처리
    // NOTE. -1 이면 종료, undefined 이면 +1
    let hasNext = false;
    const nextActionStep = action.nextStep ? action.nextStep : actionIndex + 1;
    if (nextActionStep >= 0) {
      hasNext = true;
      const targetActionNode = generateGraphFromAction(nextActionStep);
      if (targetActionNode) {
        edges.push(generateNormalEdge(actionNode.id, targetActionNode.id));
      }
    }

    // 리프 노드인 경우 종료 노드 추가
    if (!hasNext) {
      const endNode = generateEndNode(actionIndex);
      nodes.push(endNode);
      edges.push(generateNormalEdge(actionNode.id, endNode.id));
    }

    // conditionThenStep 엣지 처리
    const conditionThenStep = action.conditionThenStep ?? -1;
    if (conditionThenStep >= 0) {
      const targetConditionThenNode = generateGraphFromAction(conditionThenStep);
      if (targetConditionThenNode) {
        edges.push(generateConditionThenEdge(actionNode.id, targetConditionThenNode.id));
      }
    }

    // exceptionCatchStep 엣지 처리
    const exceptionCatchStep = action.exceptionCatchStep ?? -1;
    if (exceptionCatchStep >= 0) {
      const targetExceptionNode = generateGraphFromAction(exceptionCatchStep);
      if (targetExceptionNode) {
        edges.push(generateExceptionEdge(actionNode.id, targetExceptionNode.id));
      }
    }

    return actionNode;
  }

  // 레이아웃 적용
  return getLayoutedElements(nodes, edges);
};

export type NodeTraverseInfo = {
  prevNode?: Node<NodeDataType>;
  incomingEdge?: Edge;
  incomingEdges: Edge[];
  outgoingEdges: Edge[];
};

export const traverseGraph = (
  nodes: Node[],
  edges: Edge[],
  callback: (node: Node<NodeDataType>, nodeInfo: NodeTraverseInfo) => void,
) => {
  const triggerNode = nodes[0];

  if (!triggerNode) return;

  // 맵 구성
  const nodeMap = new Map<string, Node>();
  nodes.forEach(node => {
    nodeMap.set(node.id, node);
  });
  const outgoingEdgeMap = new Map<string, Edge[]>();
  edges.forEach(edge => {
    const items = outgoingEdgeMap.get(edge.source);
    if (!items) {
      outgoingEdgeMap.set(edge.source, [edge]);
    } else {
      items.push(edge);
    }
  });
  const incomingEdgeMap = new Map<string, Edge[]>();
  edges.forEach(edge => {
    const items = incomingEdgeMap.get(edge.target);
    if (!items) {
      incomingEdgeMap.set(edge.target, [edge]);
    } else {
      items.push(edge);
    }
  });

  const triggerEdge = outgoingEdgeMap.get(triggerNode.id)?.[0];
  if (!triggerEdge) return;

  const firstActionNode = nodeMap.get(triggerEdge.target);
  if (!firstActionNode) return;

  // 트리거 노드 방문
  callback(triggerNode, { incomingEdges: [], outgoingEdges: [triggerEdge] });

  const visited = new Set<string>();

  function traverseActionNode(
    actionNode: Node,
    prevNode: Node,
    edge: Edge,
    callback: (node: Node, nodeInfo: NodeTraverseInfo) => void,
  ) {
    if (visited.has(actionNode.id)) {
      return;
    }
    visited.add(actionNode.id);

    const incomingEdges = incomingEdgeMap.get(actionNode.id)?.filter(edge => nodeMap.get(edge.source)) ?? [];
    const outgoingEdges = outgoingEdgeMap.get(actionNode.id)?.filter(edge => nodeMap.get(edge.target)) ?? [];

    // 액션 노드 콜백 호출
    callback(actionNode, { prevNode, incomingEdge: edge, incomingEdges, outgoingEdges });

    // 일반 노드 방문
    const normalEdges = outgoingEdges.filter(edge => edge.type === 'normal');
    normalEdges.forEach(edge => {
      const nextActionNode = nodeMap.get(edge.target);
      if (nextActionNode) {
        traverseActionNode(nextActionNode, actionNode, edge, callback);
      }
    });

    // 조건 노드 방문
    const conditonThenEdges = outgoingEdges.filter(edge => edge.type === 'conditionThen');
    conditonThenEdges.forEach(edge => {
      const nextActionNode = nodeMap.get(edge.target);
      if (nextActionNode) {
        traverseActionNode(nextActionNode, actionNode, edge, callback);
      }
    });

    // 예외 노드 방문
    const exceptionEdges = outgoingEdges.filter(edge => edge.type === 'exception');
    exceptionEdges.forEach(edge => {
      const nextActionNode = nodeMap.get(edge.target);
      if (nextActionNode) {
        traverseActionNode(nextActionNode, actionNode, edge, callback);
      }
    });
  }

  traverseActionNode(firstActionNode, triggerNode, triggerEdge, callback);
};
