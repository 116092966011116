// CustomEdge.tsx
import { useState } from 'react';
import type { EdgeProps } from 'reactflow';
import { BaseEdge, getBezierPath } from 'reactflow';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { PlusIcon } from '@icons/PlusIcon';

export const NormalEdge = observer(
  ({
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  }: EdgeProps) => {
    const theme = useTheme();
    const { workflowDetailStore } = useStore();

    const [isHover, setIsHover] = useState<boolean>(false);

    const [edgePath] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    // 버튼 위치 계산
    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;

    const handleClickAddBtn = (source: string) => {
      // 선택된 인덱스의 다음에 액션 노드와 더하기 노드 추고, 선택 상태로
      const newActionNode = workflowDetailStore.addActionNode(source, undefined);
      workflowDetailStore.curNodeId = newActionNode.id;
      workflowDetailStore.setToolbarView('actionSelect');
    };

    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
        {/* '+' 버튼 */}
        {workflowDetailStore.checkEditable() && (
          <foreignObject
            width={24}
            height={24}
            x={midX - 12}
            y={midY - 12}
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            <Add
              onClick={() => handleClickAddBtn(source)}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <PlusIcon color={isHover ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']} />
            </Add>
          </foreignObject>
        )}
      </>
    );
  },
);

const Add = styled.div`
  display: flex;
  flex-direction: row;
  width: 24px;
  height: 24px;
  align-items: center;
  border: 1px solid ${props => props.theme.colors['border-gray-dark']};
  border-radius: 3px;
  background: ${props => props.theme.colors['bg-white']};
  justify-content: center;
  :hover {
    border: 1px solid ${props => props.theme.colors['border-purple']};
    background-color: ${props => props.theme.colors['bg-purple-light-50']};
  }
`;
