import Slider from 'react-slick';
import styled from '@emotion/styled';
import service06 from '@images/bg_info02_service03.png';
import service07 from '@images/bg_info02_service04.png';
import service08 from '@images/bg_info02_service05.png';
import spot from '@images/bg_spot.png';

import card02 from '@images/card01_02.png';
import card03 from '@images/card01_03.png';
import card04 from '@images/card01_04.png';
import card05 from '@images/card01_05.png';
import card06 from '@images/card01_06.png';

import card0201 from '@images/card02_01.png';
import card0203 from '@images/card02_03.png';
import card0204 from '@images/card02_04.png';

import card0302 from '@images/card03_02.png';
import card01 from '@images/megaFitCard01_01.png';
import card0202 from '@images/megaFitCard02_02.png';
import card0301 from '@images/megaFitCard03_01.png';
import pcService01Card01 from '@images/pc_service01_card01.png';
import pcService01Card02 from '@images/pc_service01_card02.png';
import pcService02Card01 from '@images/pc_service02_card01.png';
import pcService02Card02 from '@images/pc_service02_card02.png';
import pcService02Card03 from '@images/pc_service02_card03.png';

import section02card01 from '@images/section02_card01.png';
import section02card02 from '@images/section02_card02.png';
import section02card03 from '@images/section02_card03.png';
import section02card04 from '@images/section02_card04.png';
import section02card05 from '@images/section02_card05.png';
import section02card06 from '@images/section02_card06.png';
import section02card07 from '@images/section02_card07.png';
import section02card08 from '@images/section02_card08.png';
import section02card09 from '@images/section02_card09.png';
import section02card10 from '@images/section02_card10.png';
import section02card11 from '@images/section02_card11.png';
import section02card12 from '@images/section02_card12.png';

export const Container = styled('div')`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;
export const Content = styled('div')`
  width: 100%;
  height: 100%;
  .spot {
    height: calc(100vh - 60px);
    background: #0d0e12 url(${spot}) 50% 0 no-repeat;
    background-size: 2164px 1054px;
    -webkit-background-size: 2164px 1054px;
    @media screen and (max-width: 1240px) {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
    }
    .inner {
      width: 100%;
      height: 100%;
      position: relative;
      padding-top: 122px;
      @media screen and (max-width: 1240px) {
        padding-top: 56px;
      }
    }
    figure {
      img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    h2 {
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 70px;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: 1px;
      span {
        font-size: 78px;
        font-weight: 600;
      }
      @media screen and (max-width: 1240px) {
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        .mobile {
          display: block;
        }
        span {
          display: inline-block;
          margin-top: 5px;
          font-size: 32px;
        }
      }
    }
    p {
      margin-top: 21px;
      color: #fff;
      text-align: center;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 3px rgba(0, 0, 0, 0.12);
      font-family: Pretendard;
      font-size: 22px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 1px;
      @media screen and (max-width: 1240px) {
        margin-top: 16px;
        font-size: 13px;
        font-weight: 600;
        line-height: 19px;
      }
    }
    button {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 36px;
      padding: 16px 32px;
      margin: 44px auto 0;
      cursor: pointer;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      border: none;
      background: linear-gradient(to left, #6f42c1 0%, #b93a86 100%);
      border-image-slice: 1;
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.11), 0px 16px 24px 2px rgba(0, 0, 0, 0.07),
        0px 6px 30px 5px rgba(0, 0, 0, 0.1);
      @media screen and (max-width: 1240px) {
        height: 48px;
        margin: 32px auto 0;
        padding:12px 32.5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  .section01 {
    width: 100%;
    height: 100%;

    .inner {
      padding: 208px 0 220px;
      margin: 0 20px;
      background-color: ${({ theme }) => theme.colors['bg-white']};
      @media screen and (max-width: 1240px) {
        padding: 60px 0 39px;
      }
    }
    h3 {
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.5px;
      background: linear-gradient(90deg, #b93a86 0%, #6f42c1 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 1240px) {
        font-size: 14px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
    .info_title {
      color: #222;
      font-family: Pretendard;
      font-size: 40px;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.5px;
      span {
        color: #909090;
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 1240px) {
      margin: 20px auto 0;
      .info_title {
        color: #222;
        font-size: 24px;
        span {
          display: block;
          color: #909090;
        }
      }
    }
    .service_info01 {
      max-width: 1271px;
      width: 100%;
      height: 1044px;
      margin: 24px auto 0;
      text-align: center;
      @media screen and (max-width: 1240px) {
        margin: 20px auto 0;
      }
    }
    .service_info02 {
      max-width: 844px;
      width: 100%;
      height: 1044px;
      margin: 220px auto 0;
      text-align: center;
      @media screen and (max-width: 1240px) {
        margin: 120px auto 0;
      }
    }
    .service_info03 {
      max-width: 844px;
      width: 100%;
      height: 588px;
      margin: 220px auto 0;
      text-align: center;
      .info_title {
        font-weight: 700;
        span {
          margin-right: 12px;
        }
      }
      @media screen and (max-width: 1240px) {
        margin: 120px auto 0;
      }
    }
  }
  .banner {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    p {
      color: #222;
      text-align: center;
      font-family: Pretendard;
      font-size: 36px;
      line-height: normal;
      font-weight: 500;
      letter-spacing: 0.5px;
      strong {
        display: block;
        margin: 20px 0 0;
        font-size: 60px;
        font-weight: 800;
        letter-spacing: 3px;
        background: linear-gradient(90deg, #b93a86 0%, #6f42c1 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    @media screen and (max-width: 1240px) {
      height: 310px;
      p {
        font-size: 18px;
        strong {
          margin: 10px 0 0;
          font-size: 32px;
          font-weight: 800;
          letter-spacing: 1.5px;
        }
      }
    }
  }
  .section02 {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    .inner {
      padding: 210px 0 160px;
      margin: 0 20px;
      text-align: center;
      @media screen and (max-width: 1240px) {
        padding: 50px 0 60px;
      }
    }
    h3 {
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.5px;
      background: linear-gradient(90deg, #b93a86 0%, #6f42c1 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .info_title {
      color: #222;
      font-family: Pretendard;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      text-align: center;
    }
    @media screen and (max-width: 1240px) {
      margin: 20px auto 0;
      .info_title {
        color: #222;
        text-align: center;
        font-family: Pretendard;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }
    .service_info01 {
      max-width: 1280px;
      width: 100%;
      margin: 24px auto 0;
      & .card01 {
        height: 500px;
        margin-top: 60px;
        background-image: url(${pcService01Card01});
        background-size: contain;
      }
      & .card02 {
        height: 520px;
        margin-top: 24px;
        background-image: url(${pcService01Card02});
        background-size: contain;
      }
      @media screen and (max-width: 1240px) {
        margin: 20px auto 0;
        height: auto;
        & li {
          width: 328px;
          height: 430px;
          margin: 0 auto;
          background-size: contain;
          background-repeat: no-repeat;
        }
        & .card01 {
          margin-top: 40px;
          background-image: url(${section02card01});
        }
        & .card02 {
          margin-top: 16px;
          background-image: url(${section02card02});
        }
      }
    }
    .service_info02 {
      max-width: 1282px;
      width: 100%;
      height: 1212px;
      margin: 200px auto 0;
      .card-wrapper.pc {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      & .card01 {
        width: 100%;
        height: 500px;
        margin-top: 60px;
        background-image: url(${pcService02Card01});
        background-size: contain;
        background-repeat: no-repeat;
      }
      & .card02 {
        width: 630px;
        height: 580px;
        margin-top: 24px;
        background-image: url(${pcService02Card02});
        background-size: contain;
        background-repeat: no-repeat;
      }
      & .card03 {
        width: 630px;
        height: 580px;
        margin-top: 24px;
        background-image: url(${pcService02Card03});
        background-size: contain;
        background-repeat: no-repeat;
      }
      @media screen and (max-width: 1240px) {
        margin: 130px auto 0;
        height: auto;
        & li {
          margin: 0 auto;
          background-size: contain;
          background-repeat: no-repeat;
        }
        & .card01 {
          width: 328px;
          height: 430px;
          margin-top: 40px;
          background-image: url(${section02card03});
        }
        & .card02 {
          width: 328px;
          height: 410px;
          margin-top: 20px;
          background-image: url(${section02card04});
        }
        & .card03 {
          width: 328px;
          height: 410px;
          margin-top: 20px;
          background-image: url(${section02card05});
        }
      }
    }
    .service_info03 {
      max-width: 1280px;
      width: 100%;
      height: 1870px;
      margin: 200px auto 0;
      background: #fff url(${service06}) 50% 50% no-repeat;
      background-size: contain;
      @media screen and (max-width: 1240px) {
        margin: 130px auto 0;
        height: auto;
        & li {
          width: 328px;
          height: 430px;
          margin: 0 auto;
          background-size: contain;
          background-repeat: no-repeat;
        }
        & .card01 {
          height: 470px;
          margin-top: 40px;
          background-image: url(${section02card06});
        }
        & .card02 {
          height: 470px;
          margin-top: 20px;
          background-image: url(${section02card07});
        }
        & .card03 {
          margin-top: 20px;
          background-image: url(${section02card08});
        }
        & .card04 {
          height: 450px;
          margin-top: 20px;
          background-image: url(${section02card09});
        }
      }
    }
    .service_info04 {
      max-width: 1280px;
      width: 100%;
      height: 1156px;
      margin: 200px auto 0;
      background: #fff url(${service07}) 50% 50% no-repeat;
      background-size: contain;
      @media screen and (max-width: 1240px) {
        margin: 130px auto 0;
        height: auto;
        & li {
          width: 328px;
          height: 440px;
          margin: 0 auto;
          background-size: contain;
          background-repeat: no-repeat;
        }
        & .card01 {
          margin-top: 40px;
          background-image: url(${section02card10});
        }
        & .card02 {
          margin-top: 20px;
          background-image: url(${section02card11});
        }
      }
    }
    .service_info05 {
      max-width: 1280px;
      width: 100%;
      height: 796px;
      margin: 220px auto 0;
      background: #fff url(${service08}) 50% 50% no-repeat;
      background-size: contain;
      @media screen and (max-width: 1240px) {
        margin: 130px auto 0;
        height: auto;
        & .card01 {
          width: 328px;
          height: 430px;
          margin: 40px auto 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(${section02card12});
        }
      }
    }
  }
  .banner02 {
    width: 100%;
    height: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    @media screen and (max-width: 1240px) {
      height: auto;
      gap: 24px;
      padding: 110px 0 180px;
    }
    p {
      text-align: center;
      font-family: Pretendard;
      font-size: 50px;
      font-weight: 800;
      line-height: 62px;
      letter-spacing: 0.5px;
      background: linear-gradient(90deg, #b93a86 0%, #6f42c1 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media screen and (max-width: 1240px) {
        font-size: 28px;
        font-weight: 800;
        line-height: 33px;
        letter-spacing: 0.5px;
      }
    }
    button {
      width: 167px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px
      letter-spacing: 1px;
      padding: 8px 16px;
      border: 0;
      color: #fff;
      border-radius: 46px;
      background: linear-gradient(90deg, #b93a86 0%, #6f42c1 100%);
      box-shadow: 0px 8px 10px -5px rgba(111, 66, 193, 0.08), 0px 16px 24px 2px rgba(111, 66, 193, 0.1),
        0px 6px 20px 5px rgba(111, 66, 193, 0.1);
      cursor: pointer;
      @media screen and (max-width: 1240px) {
        width: 184px;
        height: 48px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1px;
      }
    }
  }
  .footer {
    width: 100%;
    height: 180px;
    border-top: 1px solid #e1e4e8;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    @media screen and (max-width: 1240px) {
      height: 240px;
    }
    .inner {
      max-width: 1320px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0 40px;
      @media screen and (max-width: 1240px) {
       flex-direction: column;
       align-items: flex-start;
       padding: 31px 40px 74px;
      }
    }
    p {
      color: #8C8C8C;
      text-align: center;
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 500;
      line-height: normal;
    }
    @media screen and (max-width: 1240px) {
      p {
        font-size: 14px;
        text-align: left;
      }
    }
  }
  .blind {
    overflow: hidden !important;
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: 1240px) {
    .mobile {
      display: block;
    }
    .pc {
      display:none;
    }
    .section01,
    .section02 {
      .service_info01,
      .service_info02,
      .service_info03,
      .service_info04,
      .service_info05 {
        max-width: 100%;
        width: 100%;
        height: auto;
        background: none;
      }
    }
  }
`;

export const SpotCarousel = styled(Slider)`
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
  @media screen and (max-width: 1240px) {
    &.slick-slider {
      width: calc(100% - 32px);
      height: auto;
    }
  }
  @media screen and (max-height: 968px) {
    &.slick-slider {
      max-width: 800px;
      width: 100%;
      height: auto;
    }
  }
`;

export const SectionCarousel = styled('div')`
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  & .swiper-wrapper {
    height: 422px;
    transition-timing-function: linear !important;
    max-width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 40px auto 0;
    box-sizing: border-box;
    & .swiper-slide {
      flex: 1 0 auto;
      width: 100%;
      max-width: 280px;
      height: 390px;
      text-align: left;
    }
  }
  & .swiper-slide {
    padding: 176px 0 0 24px;
    border-radius: 12px;
    background-position: 20px 20px;
    background-repeat: no-repeat;
    background-color: #f5f6f8;
    background-size: 240px 173px;
    -webkit-background-size: 240px 173px;
    strong {
      color: #4c2888;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    p {
      margin-top: 10px;
      color: #5a5b5e;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.15px;
    }

    @media screen and (min-width: 1240px) {
      padding: 268px 37px 0 40px;
      margin: 0;
      border-radius: 20px;
      background-position: 24px 24px;
      background-size: 365px 264px;
      -webkit-background-size: 365px 264px;

      strong {
        font-size: 18px;
        line-height: 28px;
      }
    }
    &.card01 {
      background-image: url(${card01});
    }
    &.card02 {
      background-image: url(${card02});
    }
    &.card03 {
      background-image: url(${card03});
    }
    &.card04 {
      background-image: url(${card04});
    }
    &.card05 {
      background-image: url(${card05});
    }
    &.card06 {
      background-image: url(${card06});
    }
    &.card0201 {
      background-image: url(${card0201});
    }
    &.card0202 {
      background-image: url(${card0202});
    }
    &.card0203 {
      background-image: url(${card0203});
    }
    &.card0204 {
      background-image: url(${card0204});
    }
    &.card0301 {
      background-image: url(${card0301});
    }
    &.card0302 {
      background-image: url(${card0302});
    }
  }
  & .swiper-pagination {
    bottom: 0;
    line-height: 8px;
  }
  .swiper-pagination-bullet {
    background: #c1c1c1;
  }
  .swiper-pagination-bullet-active {
    background: #6f42c1;
  }
  @media screen and (min-width: 1240px) {
    max-width: 100%;
    & .swiper-wrapper {
      max-width: 1271px;
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px 16px;
      margin: 100px auto 0;

      transform: none !important;
      transition: none !important;
      & .swiper-slide {
        flex: 1 0 auto;
        width: 100%;
        max-width: 413px;
        height: 440px;
      }
    }
    & .swiper-pagination {
      display: none;
    }
  }
`;
