import { makeAutoObservable } from 'mobx';

export class ErrorNotiUiStore {
  visible: boolean;

  content: string;

  constructor() {
    this.visible = false;
    this.content = '서비스 실패';

    makeAutoObservable(this);
  }

  open = (content: string) => {
    this.content = content ?? '서비스 실패';

    this.visible = true;
  };

  close = () => {
    this.visible = false;
  };
}
