import { useState } from 'react';
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Typography, useMediaQuery } from '@mui/material';
import { CheckboxIcon } from '@icons/index';
import { useAddTenantRequest } from '@queryHooks/useTenantRequest';
import { TenantRequestCreateFormData, TenantRequestCreateSchema } from '@schema/tenantRequestSchema';
import { TenantRequestDone } from './TenantRequestDone';
import * as S from './TenantRequestStyles';

export const TenantRequest = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const { mutateAsync: addTenantRequest, isLoading: isAddTenantRequestLoading } = useAddTenantRequest();

  const hookForm: UseFormReturn<TenantRequestCreateFormData> = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(TenantRequestCreateSchema),
  });
  const handleValid: SubmitHandler<TenantRequestCreateFormData> = formData => {
    if (isAddTenantRequestLoading) {
      return;
    }
    if (!formData.privacyAgreement) {
      setIsCheckboxError(true);
      return;
    }
    addTenantRequest(formData).then(() => {
      setIsFormSubmitted(true);
    });
  };

  const handleInvalid: SubmitErrorHandler<TenantRequestCreateFormData> = formData => {
    if (!formData.privacyAgreement) {
      setIsCheckboxError(true);
    }
  };

  return isFormSubmitted ? (
    <TenantRequestDone />
  ) : (
    <FormProvider {...hookForm}>
      <S.Form onSubmit={hookForm.handleSubmit(handleValid, handleInvalid)}>
        <S.Title>{t('Sign_In_03')}</S.Title>

        <S.Description variant="headline8" component="p" color={colors['text-gray-sub-dark']}>
          {t('Service_Request_01')}
        </S.Description>

        <S.TextFieldContainer>
          <Controller
            control={hookForm.control}
            name="username"
            render={({ field: { ref, value, ...field } }) => (
              <S.StyledTextField
                size={matches ? 'medium' : 'large'}
                inputRef={ref}
                defaultValue={value}
                error={!!hookForm.formState.errors.username}
                helperText={hookForm.formState.errors.username?.message}
                placeholder={`* ${t('Workflow_Main_05')}`}
                {...field}
              />
            )}
          />

          <Controller
            control={hookForm.control}
            name="phone"
            render={({ field: { ref, value, ...field } }) => (
              <S.StyledTextField
                size={matches ? 'medium' : 'large'}
                inputRef={ref}
                defaultValue={value}
                error={!!hookForm.formState.errors.phone}
                helperText={hookForm.formState.errors.phone?.message}
                placeholder={`* ${t('Service_Request_03')}`}
                {...field}
              />
            )}
          />

          <Controller
            control={hookForm.control}
            name="companyName"
            render={({ field: { ref, value, ...field } }) => (
              <S.StyledTextField
                size={matches ? 'medium' : 'large'}
                inputRef={ref}
                defaultValue={value}
                error={!!hookForm.formState.errors.companyName}
                helperText={hookForm.formState.errors.companyName?.message}
                placeholder={`* ${t('Service_Request_04')}`}
                {...field}
              />
            )}
          />

          <Controller
            control={hookForm.control}
            name="companyEmail"
            render={({ field: { ref, value, ...field } }) => (
              <S.StyledTextField
                size={matches ? 'medium' : 'large'}
                inputRef={ref}
                defaultValue={value}
                error={!!hookForm.formState.errors.companyEmail}
                helperText={hookForm.formState.errors.companyEmail?.message}
                placeholder={`* ${t('Service_Request_05')}`}
                {...field}
              />
            )}
          />

          <Controller
            control={hookForm.control}
            name="jobTitle"
            render={({ field: { ref, value, ...field } }) => (
              <S.StyledTextField
                size={matches ? 'medium' : 'large'}
                inputRef={ref}
                defaultValue={value}
                error={!!hookForm.formState.errors.jobTitle}
                helperText={hookForm.formState.errors.jobTitle?.message}
                placeholder={t('Service_Request_06')}
                {...field}
              />
            )}
          />
          <S.InquiryWraper>
            <Typography variant="headline8" component="strong">
              {t('Service_Request_07')}
            </Typography>
            <Controller
              control={hookForm.control}
              name="inquiry"
              render={({ field: { ref, value, ...field } }) => (
                <S.TextArea
                  multiline
                  fullWidth
                  rows={matches ? 7 : 5}
                  inputRef={ref}
                  defaultValue={value}
                  error={!!hookForm.formState.errors.inquiry}
                  helperText={hookForm.formState.errors.inquiry?.message}
                  {...field}
                />
              )}
            />
          </S.InquiryWraper>
        </S.TextFieldContainer>
        <S.CheckContainer>
          <S.EssentialCheckboxWrap>
            <S.FormCheckLabel
              control={
                <Controller
                  control={hookForm.control}
                  name="privacyAgreement"
                  render={({ field }) => (
                    <Checkbox
                      size="small"
                      icon={<CheckboxIcon color={colors['ic-gray-lighter']} />}
                      checkedIcon={<CheckboxIcon bgColor={colors['bg-purple']} color={colors['ic-white']} />}
                      {...field}
                    />
                  )}
                />
              }
              label={
                <Trans
                  i18nKey="Service_Request_08"
                  components={{
                    1: <Typography variant="headline8" component="span" />,
                    2: (
                      <Typography
                        variant="headline8"
                        component="a"
                        href="/"
                        target="_blank"
                        color={colors['text-purple-light']}
                      />
                    ),
                  }}
                />
              }
            />
            {hookForm.watch('privacyAgreement') || !isCheckboxError ? null : (
              <Typography variant="body4" component="p" color={colors['text-red-light']} className="error-text">
                {t('Service_Request_10')}
              </Typography>
            )}
          </S.EssentialCheckboxWrap>
          <S.FormCheckLabel
            control={
              <Controller
                control={hookForm.control}
                name="marketingAgreement"
                render={({ field }) => (
                  <Checkbox
                    size="small"
                    icon={<CheckboxIcon color={colors['ic-gray-lighter']} />}
                    checkedIcon={<CheckboxIcon bgColor={colors['bg-purple']} color={colors['ic-white']} />}
                    {...field}
                  />
                )}
              />
            }
            label={
              <Trans
                i18nKey="Service_Request_09"
                components={{
                  1: <Typography variant="headline8" component="span" />,
                  2: (
                    <Typography
                      variant="headline8"
                      component="a"
                      href="/"
                      target="_blank"
                      color={colors['text-purple-light']}
                    />
                  ),
                }}
              />
            }
          />
        </S.CheckContainer>
        <S.SubmitButtonWrapper>
          <S.Submit variant="contain" size="2extraLarge" type="submit">
            {t('Service_Request_13')}
          </S.Submit>
        </S.SubmitButtonWrapper>
      </S.Form>
    </FormProvider>
  );
};
