import { useTranslation } from 'react-i18next';
import { Avatar, Typography } from '@mui/material';
import { useGetProductRelations } from '@queryHooks/useProductRelations';
import { useGetSoftwareList } from '@queryHooks/useSoftware';
import type { SoftwareModel } from '@models/softwareModels';
import { ProductRelationDetailModel } from '@models/softwareModels/ProductRelationDetailModel';
import * as S from './ProductDetailRelatedProductsStyles';

interface Props {
  software: SoftwareModel;
}

export const ProductDetailRelatedProducts = ({ software }: Props) => {
  const { t } = useTranslation();
  const { data: productRelations } = useGetProductRelations(software.id ?? '');
  const { data: softwaresInCategory } = useGetSoftwareList({
    category: software.categories?.map(category => category.id),
    size: 6,
  });

  let relatedProducts = productRelations.filter(product => product.type === 'RELATED');

  // NOTE. 없으면 같은 카테고리의 제품.
  if (relatedProducts.length === 0 && softwaresInCategory.totalElements > 0) {
    relatedProducts = softwaresInCategory.content
      .filter(product => product.id !== software.id)
      .map(
        product =>
          new ProductRelationDetailModel({
            productId: product.id,
            name: product.name,
            categoryName: product.category?.name,
            description: product.description,
            iconUrl: product.iconUrl,
            type: 'RELATED',
          }),
      );
  }

  const recommendProducts = productRelations.filter(product => product.type === 'RECOMMEND');

  // NOTE. 컴포넌트를 만들지 않아야 함. (null)
  if (relatedProducts.length === 0 && recommendProducts.length === 0) return null;

  return (
    <S.RelatedAppsWapper>
      <S.RelatedAppsCategory>
        <Typography variant="headline8" component="strong">
          {t('Ad_Store_Product_Detail_03')}
        </Typography>
        <S.RelatedAppsList>
          {relatedProducts.length > 0 ? (
            relatedProducts.map(product => <RelatedProduct key={product.productId} product={product} />)
          ) : (
            <Typography>없음</Typography>
          )}
        </S.RelatedAppsList>
      </S.RelatedAppsCategory>
      {recommendProducts.length > 0 && (
        <S.RelatedAppsCategory>
          <Typography variant="headline8" component="strong">
            {t('Ad_Store_Product_Detail_04')}
          </Typography>
          <S.RelatedAppsList>
            {recommendProducts.map(product => (
              <RelatedProduct key={product.productId} product={product} />
            ))}
          </S.RelatedAppsList>
        </S.RelatedAppsCategory>
      )}
    </S.RelatedAppsWapper>
  );
};

interface ProductRelationProps {
  product: ProductRelationDetailModel;
}

const RelatedProduct = ({ product }: ProductRelationProps) => {
  return (
    <li>
      <S.RelatedAppWrapper to={`../product/${product.productId}`}>
        {product.categoryName ? <S.RelatedAppCategory>{product.categoryName}</S.RelatedAppCategory> : null}
        <S.AppWrapper>
          <Avatar sx={{ width: '24px', height: '24px', borderRadius: 0 }} src={product.iconUrl} />
          <S.AppInfoWrapper>
            {product.name ? (
              <Typography variant="body2" component="p">
                {product.name}
              </Typography>
            ) : null}
            {product.description ? (
              <S.RelatedAppDescrpition variant="paragraph4" component="p" noWrap>
                {product.description}
              </S.RelatedAppDescrpition>
            ) : null}
          </S.AppInfoWrapper>
        </S.AppWrapper>
      </S.RelatedAppWrapper>
    </li>
  );
};
