import { RoutingTabs } from '@components/tab';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - 80px);
  margin-left: 292px;
  overflow: auto;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  p {
    ${fonts.Subtitle4};
    line-height: 42px;
    & .count {
      font-weight: 700;
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 24px;
`;

export const StyledRoutingTabs = styled(RoutingTabs)`
  &.subscription_tab {
    & a:after {
      background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
    }
  }
`;
