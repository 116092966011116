import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import QueryString from 'qs';
import { ObjectUtil } from '@utils/ObjectUtil';

type Props<T> = {
  defaultFilter: T;
  whiteList: readonly string[];
};

/**
 * URL Query String과 조회 API의 Paramter를 연동하기 위한 custom hook.
 * @param defaultFilter - API Paramter 기본값
 * @param whiteList - API Parameter 중 URL에서 표시할 Parameter 목록
 */
export const useSearchParamsFilter = <T extends Record<string, unknown>>({ defaultFilter, whiteList }: Props<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const whiteListRef = useRef(whiteList);

  const [filter, setFilter] = useState<T>(() => {
    const params: Record<string, string | string[]> = {};
    whiteListRef.current.forEach(key => {
      const value = searchParams.getAll(key);
      if (value.length > 0) {
        if (value.length === 1) {
          params[key] = value[0];
        } else {
          params[key] = value;
        }
      }
    });
    return { ...defaultFilter, ...params };
  });

  const updateFilter = (newFilter: React.SetStateAction<T>) => {
    const newFilterObject = typeof newFilter === 'function' ? newFilter(filter) : newFilter;
    const removeEmpty = ObjectUtil.removeEmpty(newFilterObject);

    if (Object.keys(removeEmpty).length === 0) {
      clearFilter();
      return;
    }
    setFilter(removeEmpty);
    const updatedFilter = { ...removeEmpty };

    const urlQueryObject: { [key in typeof whiteListRef.current[number]]?: unknown } = {};
    whiteListRef.current.forEach(param => {
      if (updatedFilter[param] !== undefined && updatedFilter[param] !== '') {
        urlQueryObject[param] = updatedFilter[param];
      }
    });
    const queryString = QueryString.stringify(urlQueryObject, { arrayFormat: 'repeat', skipNulls: true });
    setSearchParams(queryString, { replace: true });
  };

  const clearFilter = () => {
    const queryString = QueryString.stringify(defaultFilter, { arrayFormat: 'repeat', skipNulls: true });
    setSearchParams(queryString, { replace: true });
    setFilter(defaultFilter);
  };

  return [filter, updateFilter, clearFilter] as const;
};
