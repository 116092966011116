import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, Box, IconButton, Typography, css, useTheme } from '@mui/material';
import { Button } from '@components/buttons';
import { CartModal } from '@pages/customer/common/cart/CartModal';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ArrowIcon, CartIcon, ImageIcon } from '@icons/index';
import { transientOptions } from '@utils/CommonUtil';
import type { SoftwareSimpleModel } from '@models/softwareModels';
import { PurchaseRequestModal } from './purchaseRequest/PurchaseRequestModal';
import { UsageRequestModal } from './usageRequest';

interface Props {
  sw: SoftwareSimpleModel;
  className?: string;
  isHardware?: boolean;
  onClick: (e: React.MouseEvent<HTMLLIElement>, software: SoftwareSimpleModel) => void;
}

export const CardElement = ({ sw, className, isHardware = false, onClick }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authStore, uiStore } = useStore();
  const navigate = useNavigate();
  const [isOpenRqModal, setIsOpenRqModal] = useState<boolean>(false);
  const [isOpenPurchaseRqModal, setIsOpenPurchaseRqModal] = useState<boolean>(false);
  const [isOpenCartModal, setIsOpenCartModal] = useState<boolean>(false);

  const handleClickCard = (e: React.MouseEvent<HTMLLIElement>) => {
    onClick(e, sw);
  };

  const handleClickAddItemToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenCartModal(true);
  };

  const colorList = [
    'text-blue-light',
    'text-green',
    'text-yellow',
    'text-orange',
    'text-red',
    'text-pink',
    'text-purple-light',
  ] as const;

  const makeColor = (str: string) => {
    const array = str.split('');
    const numberArray = array.map(char => char.charCodeAt(0));
    const sum = numberArray.reduce((acc, cur) => acc + cur, 0);
    const index = sum % 7;

    return colorList[index];
  };
  const color = makeColor(sw.category.name);

  const handleClickRqModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenRqModal(true);
  };

  const handlClickPurchaseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenPurchaseRqModal(true);
  };

  const handleClickLoginBtn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      navigate('/login');
    },
    [navigate],
  );

  const handleHomePageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    window.open(sw.url);
  };

  return (
    <>
      <Container
        className={className}
        $isOpenRqModal={isOpenRqModal}
        $setIsOpenPurchaseRqModal={isOpenPurchaseRqModal}
        onClick={handleClickCard}
      >
        <UseApp display={`${sw.isSubscribing ? 'flex' : 'none'}`}>
          <Typography component="span" variant="caption1" color={theme.colors['text-white']}>
            {t('Subscrib_Main_02')}
          </Typography>
        </UseApp>
        {sw.thumbnailUrl ? (
          <Logo src={sw.thumbnailUrl} className={isHardware ? '' : 'hardware'} />
        ) : sw.iconUrl ? (
          <Icon src={sw.iconUrl} className={isHardware ? '' : 'hardware'} />
        ) : (
          <NonImage>
            <ImageIcon width={28} height={28} />
          </NonImage>
        )}
        <CardContentWrapper component="div" className={isHardware ? '' : 'hardware'}>
          <CategoriesText component="span" variant="caption1" color={theme.colors[color]}>
            {sw.category.name || 'UnCategory'}
          </CategoriesText>
          <AppName className="category-title" component="strong" variant="headline5">
            {sw.name}
          </AppName>
          <AppDescription variant="paragraph3" className={isHardware ? '' : 'hardware'}>
            {sw.description}
          </AppDescription>
        </CardContentWrapper>
        {uiStore.isItAdminTypeUi && sw.providerId === null ? (
          <ButtonWrapper>
            <StyledButton
              onClick={handleHomePageClick}
              endIcon={
                <ArrowIcon
                  width={16}
                  height={16}
                  color={
                    isOpenRqModal || isOpenPurchaseRqModal ? theme.colors['ic-white'] : theme.colors['ic-purple-light']
                  }
                />
              }
            >
              {t('Ad_Store_Product_Card_02')}
            </StyledButton>
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <SubscriptionBtn
              $isOpenRqModal={isOpenRqModal}
              $isOpenPurchaseRqModal={isOpenPurchaseRqModal}
              className={uiStore.isItAdminTypeUi ? 'it-admin-btn' : ''}
              onClick={
                uiStore.isItAdminTypeUi
                  ? handlClickPurchaseModal
                  : !authStore.isLogin
                  ? handleClickLoginBtn
                  : handleClickRqModal
              }
              endIcon={
                <ArrowIcon
                  width={16}
                  height={16}
                  color={
                    isOpenRqModal || isOpenPurchaseRqModal ? theme.colors['ic-white'] : theme.colors['ic-purple-light']
                  }
                />
              }
            >
              {uiStore.isItAdminTypeUi ? t('Ad_Store_Product_Card') : t('Product_Cardview_01')}
            </SubscriptionBtn>
            {uiStore.isItAdminTypeUi && (
              <AddCartBtn onClick={handleClickAddItemToCart}>
                <CartIcon width={21.33} height={21.33} color={theme.colors['ic-purple']} />
              </AddCartBtn>
            )}
          </ButtonWrapper>
        )}
      </Container>
      <UsageRequestModal isOpen={isOpenRqModal} onClose={() => setIsOpenRqModal(false)} swId={sw.id} />
      {isOpenPurchaseRqModal ? (
        <PurchaseRequestModal
          isOpen={isOpenPurchaseRqModal}
          onClose={() => setIsOpenPurchaseRqModal(false)}
          swId={sw.id}
        />
      ) : null}
      {isOpenCartModal ? (
        <CartModal isOpen={isOpenCartModal} onClose={() => setIsOpenCartModal(false)} swId={sw.id} />
      ) : null}
    </>
  );
};

const Container = styled('li', transientOptions)<{
  $isOpenRqModal: boolean;
  $setIsOpenPurchaseRqModal: boolean;
}>`
  position: relative;
  width: 232px;
  height: 310px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 0.5px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  ${({ $isOpenRqModal, $setIsOpenPurchaseRqModal, theme: { colors } }) =>
    $isOpenRqModal || $setIsOpenPurchaseRqModal
      ? css`
          border: 1px solid transparent;
          background-image: linear-gradient(#fff, #fff), ${colors['border-gradient']};
          background-origin: border-box;
          background-clip: content-box, border-box;
        `
      : ''}
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: ${({ theme: { colors } }) => colors.White};
  padding: 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  & .MuiAvatar-root img {
    transition: all 0.2s linear;
  }
  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
    & img {
      transform: scale(120%);
    }
    & .MuiAvatar-root.hardware {
      & img {
        transform: scale(110%);
      }
    }
    & .category-title {
      color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
    }
  }
  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    width: calc(100% / 3 - 8px);
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    width: calc(100% / 2 - 4px);
    height: 204px;
  }
`;

type UseAppProps = {
  display: 'flex' | 'none';
};
const UseApp = styled(Box)<UseAppProps>`
  display: ${({ display }) => display};
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 48px;
  line-height: 20px;
  letter-spacing: 1px;
  padding: 2px 8px;
  border-radius: 4px 0 5px 0;
  background: ${({ theme }) => theme.colors['border-gradient']};
  border-image-slice: 1;
  z-index: 1;
  flex-shrink: 0;
`;

const CardContentWrapper = styled('div', { shouldForwardProp: propName => propName !== 'disableHoverRipple' })<{
  disableHoverRipple?: boolean;
  component: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 11px 20px 8px 20px;
  align-items: stretch;
  & .MuiTouchRipple-root {
    display: none;
  }
  ${({ disableHoverRipple }) =>
    disableHoverRipple
      ? css`
          &:hover {
            background-color: transparent;
          }
        `
      : ''}
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    padding: 2px 12px 14px 12px;
  } ;
`;

const CategoriesText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; ;
` as typeof Typography;

const Logo = styled(Avatar)`
  width: 100%;
  max-width: 160px;
  margin: 0 auto;
  height: 54px;
  padding: 24px 36px 24px;
  box-sizing: content-box;
  border-radius: 0;
  & > img {
    object-fit: contain;
  }
  &.hardware {
    height: 100px;
    padding: 16px 0 6px;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    min-width: 108px;
    max-width: fit-content;
    height: 32px;
    padding: 18px 10px;
    &.hardware {
      height: 58px;
      padding: 18px 0 6px;
    }
  } ;
`;

const Icon = styled(Avatar)`
  width: 100%;
  margin: 0 auto;
  height: 64px;
  align-self: center;
  padding: 24px 0 14px;
  box-sizing: content-box;
  border-radius: 0;
  & > img {
    object-fit: contain;
  }
  &.hardware {
    height: 80px;
    padding: 23px 0 19px;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    min-width: 108px;
    height: 56px;
    padding: 6px 0 12px;
    &.hardware {
      height: 56px;
      padding: 18px 0 6px;
    }
  } ;
`;

const AppName = styled(Typography)`
  margin-bottom: 2px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: keep-all;
` as typeof Typography;

const AppDescription = styled(Typography)`
  height: 40px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: start;
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  &.hardware {
    height: 40px;
    -webkit-line-clamp: 2;
  }
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 20px;
  padding: 12px 0 14px;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    display: none;
  } ;
`;

const AddCartBtn = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
  &:hover {
    background: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
`;
const SubscriptionBtn = styled(Button, transientOptions)<{
  $isOpenRqModal: boolean;
  $isOpenPurchaseRqModal: boolean;
}>`
  width: 100%;
  height: 36px;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 30px;
  padding: 8px 16px;
  &:hover {
    background: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
  & .MuiTouchRipple-root {
    display: none;
  }
  & span {
    ${fonts.Button4};
  }
  ${({ $isOpenRqModal, $isOpenPurchaseRqModal, theme: { colors } }) =>
    $isOpenRqModal || $isOpenPurchaseRqModal
      ? css`
          background-color: ${colors['bg-purple']};
          color: ${colors['text-white']};
          &:hover {
            background-color: ${colors['bg-purple']};
          }
        `
      : null}
  &.it-admin-btn {
    margin-right: 8px;
  }
`;
const NonImage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border: 1px dotted ${({ theme }) => theme.colors['border-gray-light']};
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 30px;
  padding: 8px 16px;
  &:hover {
    background: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
`;
