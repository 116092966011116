import type { WorkflowOutletContext } from '../WorkflowDetail';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { ActionConfig } from '../action/ActionConfig';
import { ActionSelect } from '../action/ActionSelect';
import { WorkflowViewer } from '../graph/WorkflowViewer';
import { TriggerConfig } from '../trigger/TriggerConfig';
import { TriggerSelect } from '../trigger/TriggerSelect';
import { OptionBar } from './OptionBar';
import { WorkflowBasicInfoView } from './WorkflowBasicInfoView';

export const WorkflowInfo = observer(() => {
  const { workflowDetailStore } = useStore();

  const { workflow } = useOutletContext<WorkflowOutletContext>();
  const [isToolbarOpen, setIsToolbarOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    workflowDetailStore.setIsEdit(false);
    workflowDetailStore.setToolbarView('workflowInfo');
  }, [workflowDetailStore]);

  useEffect(() => {
    setIsToolbarOpen(!!workflowDetailStore.toolbar);
  }, [workflowDetailStore.toolbar]);

  return (
    <Container>
      <Body>
        <WorkflowViewer workflow={workflow} />
        <OptionBar open={isToolbarOpen}>
          {workflowDetailStore.toolbar === 'workflowInfo' && <WorkflowBasicInfoView workflow={workflow} />}
          {workflowDetailStore.toolbar === 'triggerSelect' && <TriggerSelect />}
          {workflowDetailStore.toolbar === 'triggerConfig' && <TriggerConfig />}
          {workflowDetailStore.toolbar === 'actionSelect' && <ActionSelect />}
          {workflowDetailStore.toolbar === 'actionConfig' && <ActionConfig />}
        </OptionBar>
      </Body>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 4px;
  width: 100%;
  height: calc(100vh - 236px);
  box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;
  margin-top: 10px;
`;
