import { Typography } from '@mui/material';
import { Label } from '@components/Label';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react-lite';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import * as S from './HistoryStyles';

export const History = observer(() => {
  const { t } = useTranslation();
  const { categoryStore } = useStore();
  const subscription = useSubscriptionModel();
  const { subscriptionRequest } = subscription;
  const category = subscription.softwareCategory
    ? categoryStore.findRootCategory(subscription.softwareCategory.id)
    : null;
  const categoryName = category?.name || subscription.softwareCategoryName;

  // FIXME: 추후 데이터 제대로 수정
  return (
    <S.Container>
      <S.HistoryList>
        <S.HistoryItem>
          <Typography variant="headline7" component="strong">
            {t('Subscrib_GNB_06')}
          </Typography>
          <S.SubBodyWrap>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Detail_UseRequest_06')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {categoryName}
              </Typography>
            </S.SubItem>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Detail_UseRequest_25')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {subscription.tenant.name}
              </Typography>
            </S.SubItem>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Detail_UseRequest_07')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {subscription.software.name}
              </Typography>
            </S.SubItem>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Detail_BuyRequest_06')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {subscriptionRequest.formattedCreatedDate}
              </Typography>
            </S.SubItem>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Main_12')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {subscription.plan.planName}
              </Typography>
            </S.SubItem>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Detail_BuyRequest_02')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {subscriptionRequest.user.email || '-'}
              </Typography>
            </S.SubItem>
            <S.SubItem>
              <S.SubItemTitle variant="headline9" component="strong">
                {t('Subscrib_Detail_UseRequest_15')}
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                {subscriptionRequest.inquiry || '-'}
              </Typography>
            </S.SubItem>
          </S.SubBodyWrap>
        </S.HistoryItem>
        <S.HistoryItem>
          <Typography variant="headline7" component="strong">
            {t('Request_Processing')}
          </Typography>
          {subscriptionRequest
            .getHistories(true)
            .slice(0)
            .reverse()
            .map(history => {
              return (
                <S.SubBodyWrap key={history.createdDate}>
                  <S.SubItem>
                    <S.SubItemTitle variant="headline9" component="strong">
                      {t('Subscrib_Detail_UseRequest_09')}
                    </S.SubItemTitle>
                    <Typography variant="headline8" component="div">
                      {subscription.subscriptionRequest.isValidModel ? (
                        <Label variant="solid" color="green">
                          {history.status || 'CREATED'}
                        </Label>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </S.SubItem>
                  <S.SubItem>
                    <S.SubItemTitle variant="headline9" component="strong">
                      {t('Subscrib_Detail_UseRequest_19')}
                    </S.SubItemTitle>
                    <Typography variant="headline8" component="p">
                      {dayjs(history.createdDate).format('YYYY.MM.DD HH:mm')}
                    </Typography>
                  </S.SubItem>
                  <S.SubItem>
                    <S.SubItemTitle variant="headline9" component="strong">
                      {t('Acc_Detail_12')}
                    </S.SubItemTitle>
                    <Typography variant="headline8" component="p">
                      {history.managerName}
                    </Typography>
                  </S.SubItem>
                  <S.SubItem>
                    {/* <S.SubItemTitle variant="headline9" component="strong">
                공란
              </S.SubItemTitle>
              <Typography variant="headline8" component="p">
                공란
              </Typography> */}
                  </S.SubItem>
                  <S.SubItem>
                    <S.SubItemTitle variant="headline9" component="strong">
                      {t('Subscrib_Detail_UseRequest_15')}
                    </S.SubItemTitle>
                    <Typography variant="headline8" component="p">
                      {history.comment}
                    </Typography>
                  </S.SubItem>
                </S.SubBodyWrap>
              );
            })}
        </S.HistoryItem>
      </S.HistoryList>
    </S.Container>
  );
});
