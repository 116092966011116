// ExceptionEdge.tsx
import type { EdgeProps } from 'reactflow';
import { BaseEdge, getBezierPath } from 'reactflow';

export const ExceptionEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: 'red', strokeWidth: 1, strokeDasharray: '5,5', ...style }}
      />
      <text>
        <textPath href={`#${id}`} style={{ fontSize: 12, fill: 'red' }} startOffset="50%" textAnchor="middle">
          Exception
        </textPath>
      </text>
    </>
  );
};
