import { TenantRequestListModel, TenantRequestModel } from '@models/tenantRequest';
import { KEYS, repository } from '@repositories/Repository';
import type {
  TenantRequestCreateDTO,
  TenantRequestFilter,
  TenantRequestPatchProcessDTO,
} from '@repositories/tenantRequestRepository';
import { TenantRequestRepository } from '@repositories/tenantRequestRepository/TenantRequestRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

const tenantRequestRepo = repository.get<TenantRequestRepository>(KEYS.TENANT_REQUEST_REPOSITORY);
const tenantRequestQueryKey = {
  all: ['tenantRequest'] as const,
  lists: () => [...tenantRequestQueryKey.all, 'list'] as const,
  details: () => [...tenantRequestQueryKey.all, 'detail'] as const,

  list: (query?: TenantRequestFilter) => [...tenantRequestQueryKey.lists(), query] as const,
  detail: (id: string) => [...tenantRequestQueryKey.details(), id] as const,
};

export const useGetTenantRequestList = (query: TenantRequestFilter) => {
  const result = useQuery({
    queryKey: tenantRequestQueryKey.list(query),
    queryFn: async () => {
      const result = await tenantRequestRepo.getList(query);
      return { ...result, content: result.content.map(dto => new TenantRequestListModel(dto)) };
    },
  });

  const initialData = useMemo(() => ({ content: [] as TenantRequestListModel[], totalElements: 0 }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useGetTenantRequest = (id: string) => {
  const result = useQuery({
    queryKey: tenantRequestQueryKey.detail(id),
    queryFn: async () => {
      const dto = await tenantRequestRepo.getOne(id);
      return new TenantRequestModel(dto);
    },
    enabled: !!id,
  });

  const initialData = useMemo(() => new TenantRequestModel(), []);
  return { ...result, data: result.data ?? initialData };
};

export const useAddTenantRequest = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (data: TenantRequestCreateDTO) => {
      await tenantRequestRepo.create(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tenantRequestQueryKey.lists());
    },
  });

  return { ...result };
};

export const usePatchProcessTenantRequest = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: TenantRequestPatchProcessDTO }) => {
      await tenantRequestRepo.patchProcess(id, data);
      return id;
    },
    onSuccess: (id: string) => {
      queryClient.invalidateQueries(tenantRequestQueryKey.detail(id));
      queryClient.invalidateQueries(tenantRequestQueryKey.lists());
    },
  });

  return { ...result };
};
