import dayjs from 'dayjs';
import type { Contract, ContractModulePrices } from '@repositories/contactRepository';
import type { LicenseDTO } from '@repositories/licenseRepository';
import type { SoftwareCategory } from '@repositories/softwareCategoryRepository';
import type { Software, SoftwareModule, ModuleUsagePrice } from '@repositories/softwareRepository';
import type {
  Subscription,
  ContractCondition,
  SubscriptionStatus,
  SubscriptionType,
  UsageCollector,
  SubscriptionUpdateRq,
  UserSubscriptionUpdateDTO,
} from '@repositories/subscriptionRepository';
import type { Tenant } from '@repositories/tenantRepository';
import type { ModuleUsageList, RealtimeUsage, Usage } from '@repositories/usage';
import type { UserGroupSimple } from '@repositories/userGroupsRepository/Types';
import type { UsageWorkflowDTO } from '@repositories/workflowRepository/Types';
import type { PurchaseContract } from '@type/Purchase';
import { ObjectUtil } from '@utils/ObjectUtil';
import i18n from '@locales/i18n';
import { SubscriptionRequestModel } from './SubscriptionRequestModel';
import { SubscriptionConnectionModel } from './connection/SubscriptionConnectionModel';
import { FileSimpleModel } from './file/FileSimpleModel';
import { SoftwareCategoryModel } from './softwareModels/SoftwareCategoryModel';
import { UserSimpleModel } from './user/UserSimpleModel';

export class SubscriptionModel {
  // FIXME: _ 붙은 멤버 변수들 dto로 교체하기
  private readonly dto: Subscription;
  private readonly _connection: SubscriptionConnectionModel | null;
  private readonly _attachments: FileSimpleModel[];

  private readonly _parentSubscription: Subscription;
  private readonly _nextPaymentDate: DateTimeString;
  private readonly _tenant: Tenant;
  private readonly _software: Software;
  private readonly _type: SubscriptionType;
  private readonly _softwareContract: Contract;
  private readonly _name: string;
  private readonly _description?: string;
  private readonly _startDate: string;
  private readonly _endDate?: string;
  private readonly _timeZone?: string;
  private readonly _contractConditions?: ContractCondition[];
  private readonly _moduleUsagePrices: ModuleUsagePrice[];
  /**
   * @deprecated
   */
  private readonly _currencyUnit?: CurrencyUnit;
  private readonly _properties?: { [key: string]: string };
  private readonly _purchaseContract?: PurchaseContract;
  private readonly _subSubscriptions: Subscription[];
  private readonly _realtimeUsage: RealtimeUsage;
  private _usageCollector?: UsageCollector;
  private readonly _version: number;
  private readonly _status: SubscriptionStatus;
  private _isQuantityContract: boolean;
  private readonly _createdBy?: string | undefined;
  private readonly _updatedBy?: string | undefined;
  private readonly _licenses: LicenseDTO[];
  private readonly _subscriptionRequest: SubscriptionRequestModel;
  private readonly _usageWorkflow: UsageWorkflowDTO;
  private readonly _userGroup: UserGroupSimple;
  private readonly _salesManager: UserSimpleModel;
  private readonly _techicalSupportManager: UserSimpleModel;
  private readonly _owner: UserSimpleModel;
  private readonly _purchaseContractId?: string;

  constructor(dto: Subscription) {
    this.dto = dto;
    this._connection = dto.connection ? new SubscriptionConnectionModel(dto.connection) : null;
    this._attachments = Array.isArray(dto.attachments)
      ? dto.attachments.map(attachment => new FileSimpleModel(attachment))
      : [];

    this._parentSubscription = dto?.parentSubscription ?? {
      id: '',
      plan: {
        currencyUnit: 'KRW',
        isBasicPlan: true,
        planId: '',
        planName: '',
        planType: 'MAIN',
        pricingType: 'Free',
        planScope: 'SUBSCRIPTION',
      },
      attachments: [],
      memo: null,
    };
    this._nextPaymentDate = dto?.nextPaymentDate ?? '-';
    this._tenant = dto?.tenant ?? { id: '', tenantCode: '', members: [], name: '', level: -1 };
    this._software = dto?.software ?? { id: '', productCode: '', name: '', planVisibilityScope: 'ALL_USERS' };
    this._type = dto?.type || 'FIXED_2';
    this._softwareContract = dto?.softwareContract ?? {
      createdBy: '',
      createdDate: '',
      updatedBy: '',
      updatedDate: '',
      contractModulePrices: {},
      currencyUnit: 'USD',
      deleted: false,
      endDate: '',
      id: '',
      software: { id: '', productCode: '', name: '', planVisibilityScope: 'ALL_USERS' },
      startDate: '',
      status: 'REJECTED',
      tenant: { id: '', tenantCode: '', members: [], name: '', level: -1 },
      version: -1,
      timeZone: '',
      properties: {},
    };
    this._name = dto?.name ?? '';
    this._description = dto?.description ?? '';
    this._startDate = dto?.startDate ?? '';
    this._endDate = dto?.endDate ?? '';
    this._timeZone = dto?.timeZone ?? 'Asia/Seoul';
    this._contractConditions = dto?.contractConditions ?? [];
    this._moduleUsagePrices = dto?.moduleUsagePrices ?? [];
    this._properties = dto?.properties ?? undefined;
    this._purchaseContract = dto?.purchaseContract;
    this._subSubscriptions = dto?.subSubscriptions ?? [];

    this._realtimeUsage = dto?.realtimeUsage ?? {
      dateTime: '',
      subscriptionId: '',
      usageList: [],
    };

    this._usageCollector = dto?.usageCollector;
    this._version = dto?.version ?? -1;
    this._status = dto?.status ?? 'REJECTED';
    this._isQuantityContract = dto?.isQuantityContract ?? false;

    this._createdBy = dto?.createdBy;
    this._updatedBy = dto?.updatedBy;

    this._licenses = dto?.licenses ?? [];
    this._subscriptionRequest = new SubscriptionRequestModel(dto?.subscriptionRequest);
    this._usageWorkflow = dto.usageWorkflow ?? { id: '', status: 'STOPPED' };
    this._userGroup = dto?.userGroup ?? {};
    this._salesManager = new UserSimpleModel(dto?.salesManager);
    this._techicalSupportManager = new UserSimpleModel(dto?.technicalSupportManager);
    this._owner = new UserSimpleModel(dto?.owner);
    this._purchaseContractId = dto.purchaseContract?.id;
  }

  get purchaseContractId() {
    return this._purchaseContractId;
  }

  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this.dto.createdDate;
  }

  get updatedBy() {
    return this._updatedBy;
  }

  get updatedDate() {
    return this.dto.updatedDate;
  }

  get status() {
    return this._status;
  }

  get version() {
    return this._version;
  }

  get usageCollector() {
    return this._usageCollector;
  }

  get subSubscriptions() {
    return this._subSubscriptions;
  }

  get purchaseContract() {
    return this._purchaseContract;
  }

  get moduleUsagePrices() {
    return this._moduleUsagePrices;
  }

  get parentSubscription() {
    return this._parentSubscription;
  }

  get softwareContract() {
    return this._softwareContract;
  }

  get tenant() {
    return this._tenant;
  }

  get software() {
    return this._software;
  }

  get type() {
    return this._type;
  }

  get id() {
    return this.dto.id;
  }

  get contractConditions() {
    return this._contractConditions;
  }

  get isQuantityContract() {
    return this._isQuantityContract;
  }

  get currencyUnit() {
    return this.dto.plan?.currencyUnit ?? 'USD';
  }

  get timeZone() {
    return this._timeZone;
  }

  get properties() {
    return this._properties;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description || '-';
  }

  get startDate() {
    return this._startDate;
  }

  get endDate() {
    return this._endDate;
  }

  get randomCategory() {
    if (this.software.categories?.length) {
      return this.software.categories[0];
    }
    return new SoftwareCategoryModel();
  }

  get connection() {
    return this._connection;
  }

  get realtimeUsage(): RealtimeUsage {
    return this._realtimeUsage;
  }

  get realtimeUsageDateTime() {
    return this._realtimeUsage.dateTime;
  }

  get realtimeUsageList(): ModuleUsageList[] {
    return this._realtimeUsage.usageList;
  }

  getModuleRealtimeUsage(moduleCode: ModuleCode): Usage | undefined {
    return this.realtimeUsageList.find(({ module }) => module === moduleCode)?.usages[0];
  }

  get modulesRealtimeUsage() {
    return this.modules.map(({ moduleCode, name }) => ({
      moduleCode,
      name,
      realtimeUsage: this.getModuleRealtimeUsage(moduleCode),
    }));
  }

  get paymentDueDate() {
    return this.dto.paymentDueDate;
  }

  get nextPaymentDate() {
    return this._nextPaymentDate;
  }

  get licenses(): LicenseDTO[] {
    return this._licenses;
  }

  get mainLicense(): LicenseDTO | undefined {
    const today = dayjs();
    const activeLicense = this.licenses.filter(
      ({ plan, startDate, endDate }) =>
        plan?.planType === 'MAIN' &&
        (endDate ? today.isAfter(startDate) && today.isBefore(endDate) : today.isAfter(startDate)),
    )[0];
    return activeLicense ?? this.licenses[0];
  }

  get plan() {
    return (
      this.dto.plan ?? {
        // FIXME: plan이 없는 과거 데이터를 위한 임시 처리
        currencyUnit: 'KRW',
        isBasicPlan: true,
        planId: '',
        planName: '',
        planType: 'MAIN',
        pricingType: 'Free',
        planScope: 'SUBSCRIPTION',
      }
    );
  }

  get isPlanEditable(): boolean {
    return this.status === 'OPEN' || this.status === 'REQUESTED' || this.status === 'REJECTED';
  }

  get isActive(): boolean {
    return this.status === 'ACTIVE';
  }

  get subscriptionRequest(): SubscriptionRequestModel {
    return this._subscriptionRequest;
  }

  get usageWorkflow() {
    return this._usageWorkflow;
  }

  get isLatestUsageWorkflowFailed(): boolean {
    return this._usageWorkflow.latestExecutionStatus === 'FAILED';
  }

  get userGroup(): UserGroupSimple {
    return this._userGroup;
  }

  get userGroupName(): string {
    return this.userGroup.name || this.tenant.name || '-';
  }

  get subscriptionType(): SubscriptionType | undefined {
    if (this._type === 'SUB') {
      return this.parentSubscription?.type;
    }

    return this._type;
  }

  setUsageCollector = (usageCollector: UsageCollector | undefined) => {
    this._usageCollector = usageCollector;
  };

  get contractModulePrices(): ContractModulePrices {
    if (!this.softwareContract) {
      return {};
    }

    if (!this.subscriptionType) {
      return {};
    }

    return this.softwareContract.contractModulePrices;
  }

  get moduleUsagePriceArray(): ModuleUsagePrice[] {
    const modulePrices = this.moduleUsagePrices;
    if (!modulePrices) {
      return [];
    }

    return modulePrices.map(modulePrice => ({
      ...modulePrice,
      currencyUnit: modulePrice.currencyUnit ?? '',
    }));
  }

  get modules(): SoftwareModule[] {
    return this.software.modules ?? [];
  }

  get moduleCodes(): ModuleCode[] {
    return this.software.modules ? this.software.modules.map(({ moduleCode }) => moduleCode) : [];
  }

  setQuantityContract = (isQuantityContract: boolean) => {
    this._isQuantityContract = isQuantityContract;
  };

  get subscriptionTypes(): SubscriptionType[] {
    if (!this.software.moduleUsagePrices) {
      return [];
    }
    return ObjectUtil.keys(this.software.moduleUsagePrices) as SubscriptionType[];
  }

  get softwareIconUrl(): string {
    return this.software?.iconUrl ?? '';
  }

  get softwareCategory(): SoftwareCategory | undefined {
    return this.software.categories && this.software.categories.length > 0 ? this.software.categories[0] : undefined;
  }

  get softwareCategoryName(): string {
    return this.software.categories && this.software.categories.length > 0 ? this.software.categories[0].name : '-';
  }

  get softwareCategoryNames(): string {
    return this.software.categories?.map(({ name }) => name).join(', ') ?? '';
  }

  get commitmentPeriod() {
    switch (this.mainLicense?.planOption?.commitmentPeriod) {
      case 'ANNUAL':
        return i18n.t('Subscrib_Detail_Overview_Info_01');
      case 'QUARTER':
        return i18n.t('Subscrib_Detail_Overview_Info_02');
      case 'SEMIANNUAL':
        return i18n.t('Subscrib_Detail_Overview_Info_03');
      default:
        return '-';
    }
  }

  get paymentPeriod() {
    switch (this.mainLicense?.planOption?.paymentPeriod) {
      case 'ANNUAL':
        return i18n.t('Subscrib_Detail_Overview_Info_04');
      case 'MONTH':
        return i18n.t('Subscrib_Detail_Overview_Info_05');
      case 'ONCE':
        return i18n.t('Subscrib_Detail_Overview_Info_06');
      case 'QUARTER':
        return i18n.t('Subscrib_Detail_Overview_Info_07');
      case 'SEMIANNUAL':
        return i18n.t('Subscrib_Detail_Overview_Info_08');
      default:
        return '-';
    }
  }

  /**
   * FIXME: status 값과 별개로 구독의 startDate, endDate로 판단한 status.
   * 기존 status 값이 사용하는 곳이 많아서 임시로 추가
   */
  get viewStatus() {
    const nowDate = dayjs();

    const isSubscribing =
      (nowDate.isAfter(this.startDate) && (nowDate.isBefore(this.endDate) || nowDate.isSame(this.endDate, 'day'))) ||
      this.endDate === '';
    if (isSubscribing) {
      const endDateFrom = dayjs(this.endDate).add(-1, 'month');
      return nowDate.isAfter(endDateFrom) ? 'SCHEDULED_TO_END' : 'ACTIVE';
    }

    const isEndSubscription = nowDate.add(1, 'day').isAfter(this.endDate);
    if (isEndSubscription) {
      return 'INACTIVE';
    }

    const isScheduledToStart = nowDate.add(-1, 'day').isBefore(this.startDate);
    if (isScheduledToStart) {
      return 'SCHEDULED_TO_START';
    }

    return 'REQUESTED';
  }

  get statusStr() {
    switch (this.viewStatus) {
      case 'ACTIVE':
        return i18n.t('Subscrib_Main_02');
      case 'INACTIVE':
        return i18n.t('Subscrib_Main_05');
      case 'SCHEDULED_TO_START':
        return i18n.t('Subscrib_Main_03');
      case 'SCHEDULED_TO_END':
        return i18n.t('Subscrib_Main_04');
      case 'REQUESTED':
        return i18n.t('Subscrib_Detail_Overview_Info_09');
      default:
        return '-';
    }
  }

  get isExternalSubscription() {
    return this._type === 'EXTERNAL';
  }

  get salesManager() {
    return this._salesManager;
  }

  get technicalSupportManager() {
    return this._techicalSupportManager;
  }

  get owner() {
    return this._owner;
  }

  get attachments() {
    return this._attachments;
  }

  get memo() {
    return this.dto.memo ?? '';
  }

  getSubscriptionUpdateRq(updateValue: Partial<SubscriptionUpdateRq>): SubscriptionUpdateRq {
    return {
      contractConditions: this._contractConditions,
      currencyUnit: this._currencyUnit,
      description: this._description,
      endDate: this._endDate,
      id: this.dto.id,
      isQuantityContract: this._isQuantityContract,
      memo: this.dto.memo,
      moduleUsagePrices: this._moduleUsagePrices,
      name: this._name,
      paymentDueDate: this.dto.paymentDueDate,
      planId: this.dto.plan.planId,
      planOptionId: this.mainLicense?.planOption?.planOptionId,
      properties: this._properties,
      purchaseContractId: this._purchaseContractId,
      startDate: this._startDate,
      subscriptionRequestId: this.subscriptionRequest.id === -1 ? null : this.subscriptionRequest.id,
      timeZone: this._timeZone,
      type: this._type,
      userGroupId: this.dto.userGroup?.userGroupId,
      useSeparateModulePrice: this.dto.useSeparateModulePrice,
      ...updateValue,
    };
  }

  getUserSubscriptionUpdateDTO(updateValue: Partial<UserSubscriptionUpdateDTO>): UserSubscriptionUpdateDTO {
    return {
      description: this.dto.description,
      endDate: this.dto.endDate,
      id: this.dto.id,
      memo: this.dto.memo,
      name: this.dto.name ?? '',
      ownerId: this.dto.owner?.id,
      paymentDueDate: this.dto.paymentDueDate,
      planId: this.dto.plan?.planId ?? '',
      planOptionId: this.mainLicense?.planOption?.planOptionId ?? '',
      startDate: this.dto.startDate ?? '',
      timeZone: this.dto.timeZone,
      userGroupId: this.dto.userGroup?.userGroupId,
      ...updateValue,
    };
  }
}
