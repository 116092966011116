import { type AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { type FileSimpleDTO } from '@repositories/fileRepository/Types';
import { Page } from '@type/Page';
import ITenantMonthlyBillingRepository from './ITenantMonthlyBillingRepository';
import {
  StatusUpdateRq,
  TenantMonthlyBillingDTO,
  TenantBillingGenerateRq,
  TenantBillingId,
  TenantBillingsFilters,
} from './Types';

@injectable()
export class TenantMonthlyBillingRepository implements ITenantMonthlyBillingRepository {
  async getOne(billingId: TenantBillingId): Promise<TenantMonthlyBillingDTO> {
    const result = await API.get<TenantMonthlyBillingDTO>(
      `/api/v1/tenant-monthly-billings/${billingId.id}/monthly-billings/${billingId.month}`,
    );
    return result.data;
  }
  async getList(queries?: TenantBillingsFilters): Promise<Page<TenantMonthlyBillingDTO>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat', skipNulls: true });
    const result = await API.get<Page<TenantMonthlyBillingDTO>>(`/api/v1/tenant-monthly-billings?${queryString}`);
    return result.data;
  }
  async update(/* id: string, data: unknown */): Promise<TenantMonthlyBillingDTO> {
    throw new Error('Method not implemented.');
  }

  async delete(billingId: TenantBillingId): Promise<void> {
    await API.delete<void>(`/api/v1/tenant-monthly-billings/${billingId.id}/monthly-billings/${billingId.month}`);
  }

  async updateStatus(rqData: StatusUpdateRq): Promise<TenantMonthlyBillingDTO> {
    const result = await API.put<TenantMonthlyBillingDTO>(
      `/api/v1/tenant-monthly-billings/${rqData.tenantId}/monthly-billings/${rqData.month}/status`,
      rqData,
    );
    return result.data;
  }

  async generateTenantBilling(rqData: TenantBillingGenerateRq): Promise<TenantMonthlyBillingDTO> {
    const result = await API.post<TenantMonthlyBillingDTO>('/api/v1/actions/produce-tenant-monthly-billings', rqData);
    return result.data;
  }

  async fileUpload(
    tenantId: string,
    month: string,
    files: File[],
    config?: AxiosRequestConfig,
  ): Promise<FileSimpleDTO[]> {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    const result = await API.post<FileSimpleDTO[]>(
      `/api/v1/tenant-monthly-billings/${tenantId}/monthly-billings/${month}/files`,
      formData,
      config,
    );
    return result.data;
  }

  async fileDelete(tenantId: string, month: string, fileId: string): Promise<void> {
    await API.delete(`/api/v1/tenant-monthly-billings/${tenantId}/monthly-billings/${month}/files/${fileId}`);
  }
}
