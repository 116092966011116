import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';
import { Label } from '@components/Label';
import { TableBodyCell } from '@components/table';
import { type Filter } from '@repositories/monthlyBillingRepository';
import { dateFormat } from '@utils/dateUtil';
import { currencyNumberFormat } from '@utils/numberFormat';
import { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';

interface Props {
  billing: SubscriptionBillingModel;
  queries: Filter;
}

export const BillingRow = ({ billing, queries }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const handleClick = (id: string) => {
    navigate(`/manager/${param.tenantId}/billings/history/subscription/${id}`);
  };
  return (
    <TableRow>
      <TableBodyCell $isFilterApplied={!!queries.monthFrom || !!queries.monthTo}>
        {dateFormat(billing.month, '-', t('DateFormat_YM'))}
      </TableBodyCell>
      <TableCell>
        {`${dateFormat(billing.startDate, '-', t('DateFormat_YMD'))} ~ ${dateFormat(
          billing.endDate,
          '-',
          t('DateFormat_YMD'),
        )}`}
      </TableCell>
      <TableCell>{currencyNumberFormat(billing.originalCost, billing.originalCurrencyUnit, 'text')}</TableCell>
      <TableCell>{currencyNumberFormat(billing.cost, billing.currencyUnit, 'text')}</TableCell>
      <TableCell>{dateFormat(billing.paymentDate, '-', t('DateFormat_YMD'))}</TableCell>
      <TableCell>
        <Label variant="solid" color={billing.isForecasted ? 'blue' : billing.isPayment ? 'gray' : 'yellow'}>
          {/* FIXME: i18n */}
          {billing.isForecasted ? '정산 예측' : billing.isPayment ? t('Acc_Detail_06') : t('Acc_Detail_07')}
        </Label>
      </TableCell>
      <StyledCell onClick={() => handleClick(billing.id)}>{t('Subscrib_Detail_Acc')}</StyledCell>
    </TableRow>
  );
};
const StyledCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors['text-blue-light']};
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;
