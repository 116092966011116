import { MonthlySales, MonthlySalesStat } from '@type/Statistics';
import { ObjectUtil } from '@utils/ObjectUtil';
import { makeAutoObservable } from 'mobx';
import type { MonthlySummaryWithTotal, MonthlyData } from '@type/Summary';
import { MonthlyDataMapper } from '@utils/mapper';

export class MonthlySalesStatModel implements MonthlySalesStat {
  data: MonthlySales[];

  totalAmount: number;

  totalOriginalAmount: number;

  currencyUnit: CurrencyUnit;

  originalCurrencyUnit: CurrencyUnit;

  constructor(dto?: MonthlySalesStat) {
    this.data = [];
    this.totalAmount = -1;
    this.totalOriginalAmount = -1;
    this.currencyUnit = 'KRW';
    this.originalCurrencyUnit = 'USD';

    if (dto) {
      this.update(dto);
    }

    makeAutoObservable(this);
  }

  update = (dto: Partial<MonthlySalesStat>) => {
    ObjectUtil.update(this, dto);
  };

  get monthlyData(): MonthlySummaryWithTotal {
    const monthlyData = {} as MonthlyData;

    MonthlyDataMapper.forEach(month => {
      monthlyData[month] = 0;
    });

    this.data.forEach(element => {
      const month = parseInt(element.month.split('-')[1], 10);
      monthlyData[MonthlyDataMapper[month]] = element.amount;
    });

    delete monthlyData['-'];
    return {
      name: '',
      data: monthlyData,
      unit: this.currencyUnit,
      total: this.totalAmount,
    };
  }

  get lineChartData(): { name: string; data: number }[] {
    return this.data.map(element => ({
      name: MonthlyDataMapper[parseInt(element.month.split('-')[1], 10)].toString(),
      data: element.amount,
    }));
  }
}
