import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { MenuItem, Popover, Select, SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DashIcon } from '@icons/DashIcon';
import { ResetIcon } from '@icons/ResetIcon';
import { Button, DatePicker } from '..';

enum RangeType {
  DAY = 'DAY',
  PERIOD = 'PERIOD',
  WITHIN_1_WEEK = 'WITHIN_1_WEEK',
  WITHIN_1_MONTH = 'WITHIN_1_MONTH',
  WITHIN_2_MONTH = 'WITHIN_2_MONTH',
  WITHIN_3_MONTH = 'WITHIN_3_MONTH',
}

export type DateFilterItemType = {
  displayName: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  disableStartDate?: boolean;
  disableEndDate?: boolean;
};

type Props = {
  anchorEl: HTMLButtonElement;
  open: boolean;
  selectedDate: { from: Dayjs | null; to: Dayjs | null; menuValue: string };
  itemList?: DateFilterItemType[];
  onClose: () => void;
  onFilterChange: (from: Dayjs | null, to: Dayjs | null, menuValue: string) => void;
  format?: string;
};

export const DateFilterContents = ({
  anchorEl,
  open,
  selectedDate,
  itemList = [],
  onClose,
  onFilterChange,
  format,
}: Props) => {
  const { t } = useTranslation();

  const selectedMenu = selectedDate.menuValue || RangeType.DAY;
  const selectedItem = itemList.find(item => item.displayName === selectedMenu);

  const enableDayFilter = !format || format.includes('DD');

  const handleMenuChange = (event: SelectChangeEvent<string>) => {
    const curDayjs = dayjs();
    switch (event.target.value) {
      case RangeType.DAY:
        onFilterChange(selectedDate.from, selectedDate.from, event.target.value);
        break;
      case RangeType.PERIOD:
        onFilterChange(selectedDate.from, selectedDate.to, event.target.value);
        break;
      case RangeType.WITHIN_1_WEEK:
        onFilterChange(curDayjs, curDayjs.add(1, 'week'), event.target.value);
        break;
      case RangeType.WITHIN_1_MONTH:
        onFilterChange(curDayjs, curDayjs.add(1, 'month'), event.target.value);
        break;
      case RangeType.WITHIN_2_MONTH:
        onFilterChange(curDayjs, curDayjs.add(2, 'month'), event.target.value);
        break;
      case RangeType.WITHIN_3_MONTH:
        onFilterChange(curDayjs, curDayjs.add(3, 'month'), event.target.value);
        break;
      default: {
        const customFilter = itemList?.find(({ displayName }) => displayName === event.target.value);
        if (customFilter) {
          onFilterChange(customFilter.startDate, customFilter.endDate ?? customFilter.startDate, event.target.value);
        }
        break;
      }
    }
  };

  const handleDateChange = (dateType: 'from' | 'to') => (value: Dayjs | null) => {
    if (value) {
      const newMenuValue =
        selectedMenu !== RangeType.DAY && selectedMenu !== RangeType.PERIOD ? RangeType.PERIOD : selectedDate.menuValue;
      if (dateType === 'from') {
        onFilterChange(value, selectedMenu === RangeType.DAY ? value : selectedDate.to, newMenuValue);
      } else {
        onFilterChange(selectedDate.from, value, newMenuValue);
      }
    }
  };

  const handleResetClick = () => {
    onFilterChange(null, null, RangeType.DAY);
  };

  return (
    <Container
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <SelectWrapper>
        <Select
          value={selectedMenu}
          onChange={handleMenuChange}
          size="small"
          SelectDisplayProps={{ style: { paddingRight: '0px', borderRadius: 0 } }}
          sx={{
            width: '120px',
            '& .MuiPaper-root': {
              borderRadius: 0,
            },
          }}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                width: '120px',
                borderRadius: 0,
                boxShadow: 'none',
              },
            },
          }}
        >
          <MenuItem value={RangeType.DAY}>{t('Subscrib_Main_Filter_01')}</MenuItem>
          <MenuItem value={RangeType.PERIOD}>{t('Subscrib_Main_Filter_02')}</MenuItem>
          {enableDayFilter && itemList.length === 0 && (
            <MenuItem value={RangeType.WITHIN_1_WEEK}>{t('Subscrib_Main_Filter_03')}</MenuItem>
          )}
          {itemList.length === 0 && (
            <MenuItem value={RangeType.WITHIN_1_MONTH}>{t('Subscrib_Main_Filter_04')}</MenuItem>
          )}
          {itemList.length === 0 && (
            <MenuItem value={RangeType.WITHIN_2_MONTH}>{t('Subscrib_Main_Filter_05')}</MenuItem>
          )}
          {itemList.length === 0 && (
            <MenuItem value={RangeType.WITHIN_3_MONTH}>{t('Subscrib_Main_Filter_06')}</MenuItem>
          )}
          {itemList.map(({ displayName }) => (
            <MenuItem key={displayName} value={displayName}>
              {displayName}
            </MenuItem>
          ))}
        </Select>
        <DatePickerWrapper>
          <DatePicker
            inputFormat={format ?? t('DateFormat_YMD')}
            value={selectedDate.from}
            onChange={handleDateChange('from')}
            views={enableDayFilter ? ['year', 'month', 'day'] : ['year', 'month']}
            disabled={!!selectedItem?.disableStartDate}
            renderInput={props => <DatePicker.Input {...props} size="small" sx={{ width: '142px' }} />}
          />
          {selectedMenu !== RangeType.DAY && (
            <>
              <IconWrap>
                <DashIcon width={10} height={10} />
              </IconWrap>
              <DatePicker
                inputFormat={format ?? t('DateFormat_YMD')}
                value={selectedDate.to}
                onChange={handleDateChange('to')}
                views={enableDayFilter ? ['year', 'month', 'day'] : ['year', 'month']}
                disabled={!!selectedItem?.disableEndDate}
                renderInput={props => <DatePicker.Input {...props} size="small" sx={{ width: '142px' }} />}
              />
            </>
          )}
        </DatePickerWrapper>
      </SelectWrapper>
      <ResetButton
        variant="text"
        size="medium"
        color="gray"
        startIcon={<ResetIcon width={16} height={16} />}
        onClick={handleResetClick}
      >
        {t('Subscrib_Main_Filter_07')}
      </ResetButton>
    </Container>
  );
};

const Container = styled(Popover)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px;
    box-shadow: unset;
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResetButton = styled(Button)`
  margin-left: auto;
`;

const IconWrap = styled('div')`
  margin: 0 6px;
`;
