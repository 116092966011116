import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { TechnicalSupportDetailInfo } from './TechnicalSupportDetailInfo';

export const TechnicalSupportDetail = () => {
  const navigator = useNavigate();
  const { tenantId } = useParams();
  return (
    <TechnicalSupportListLayOut>
      <Tittle onClick={() => navigator(`/manager/${tenantId}/subscriptions/technical-support`)}>
        <ChevronIcon rotateNum={270} />
        목록
      </Tittle>
      <TechnicalSupportDetailInfo />
    </TechnicalSupportListLayOut>
  );
};

const TechnicalSupportListLayOut = styled.section`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
`;

const Tittle = styled.article`
  display: flex;
  align-items: center;
  ${fonts.Headline6}
  color: ${({ theme }) => theme.colors['text-gray-main']};
`;
