const defaultWidth = 24;
const defaultHeight = 24;

export const PencilLineIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2458 10.8612L17.2571 10.8502L19.776 8.3313C20.4624 7.64488 20.4624 6.53198 19.776 5.84557L17.4452 3.51481C16.7588 2.8284 15.6459 2.82839 14.9595 3.51481L12.4492 6.02505L3.61365 14.4181C3.39495 14.6268 3.23379 14.8885 3.14576 15.1777L1.63722 20.1344C1.54514 20.4369 1.62731 20.7656 1.85093 20.9892C2.07454 21.2128 2.4032 21.295 2.70575 21.2029L7.68368 19.6879C7.94643 19.6079 8.18686 19.4674 8.38554 19.2778L17.244 10.863L17.2458 10.8612ZM7.18503 18.0494L3.74311 19.097L4.78417 15.6764C4.78641 15.669 4.79059 15.6622 4.79616 15.6569L13.0333 7.83451L15.4275 10.2287L7.20303 18.0389C7.19794 18.0438 7.19177 18.0474 7.18503 18.0494ZM16.2342 4.72594L18.565 7.0567C18.5826 7.0743 18.5826 7.10283 18.565 7.12043L16.653 9.03237L14.2585 6.63788L16.1705 4.72594C16.1881 4.70834 16.2166 4.70834 16.2342 4.72594Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9 15.2002C16.4029 15.2002 16 15.6031 16 16.1002C16 16.5973 16.4029 17.0002 16.9 17.0002H22.1C22.5971 17.0002 23 16.5973 23 16.1002C23 15.6031 22.5971 15.2002 22.1 15.2002H16.9ZM13.9 19.2002C13.4029 19.2002 13 19.6031 13 20.1002C13 20.5973 13.4029 21.0002 13.9 21.0002H22.1C22.5971 21.0002 23 20.5973 23 20.1002C23 19.6031 22.5971 19.2002 22.1 19.2002H13.9Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PencilLineIcon.displayName = 'PencilLineIcon';
