import { AnnualAchievement, OverallProfitSummaryStat } from '@type/Statistics';
import { ObjectUtil } from '@utils/ObjectUtil';
import { makeAutoObservable } from 'mobx';

export class OverallProfitSummaryStatModel implements OverallProfitSummaryStat {
  currencyUnit: CurrencyUnit;

  originalCurrencyUnit: CurrencyUnit;

  profit: number;

  profitRate: number;

  purchaseAchievementRate: number;

  purchaseAmount: number;

  purchaseOriginalAmount: number;

  salesAchievementRate: number;

  salesAmount: number;

  salesOriginalAmount: number;

  targetPurchaseAmount: number;

  targetSalesAmount: number;

  year: DateTimeString;

  constructor(dto?: OverallProfitSummaryStat) {
    this.currencyUnit = 'KRW';
    this.originalCurrencyUnit = 'USD';
    this.profit = -1;
    this.profitRate = -1;
    this.purchaseAchievementRate = -1;
    this.purchaseAmount = -1;
    this.purchaseOriginalAmount = -1;
    this.salesAchievementRate = -1;
    this.salesAmount = -1;
    this.salesOriginalAmount = -1;
    this.targetPurchaseAmount = -1;
    this.targetSalesAmount = -1;
    this.year = new Date().getFullYear().toString();

    if (dto) {
      this.update(dto);
    }

    makeAutoObservable(this);
  }

  update = (dto: Partial<OverallProfitSummaryStat>) => {
    ObjectUtil.update(this, dto);
  };

  get annualAchievementData(): AnnualAchievement {
    return {
      expectedPurchase: this.purchaseAmount ?? 0,
      purchase: this.purchaseAmount ?? 0,
      purchaseAchievementRate: this.purchaseAchievementRate ?? 0,
      expectedSales: this.salesAmount ?? 0,
      sales: this.salesAmount ?? 0,
      salesAchievementRate: this.salesAchievementRate ?? 0,
    };
  }
}
