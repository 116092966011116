import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  position: absolute;
  top: 0;
  left: 0;
`;

export const IconWrapper = styled.div`
  padding: 6px;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: ${({ theme: { colors } }) => colors['bg-gradient']};
  margin-bottom: 28px;
`;

export const Message = styled.p`
  ${fonts.Headline4}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 12px;

  & > strong {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

export const SubMessage = styled.p`
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin-bottom: 52px;
`;

export const GoToHome = styled(Link)`
  padding: 12px 24px;
  background: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 3px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  text-decoration: none;
`;
