import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  TenantMemberStatus,
  TenantMemberTextStatus,
  TotalSubscriptionUserListFilter,
} from '@repositories/subscriptionUserRepository/Types';
import { useGetSubscriptionList } from '@queryHooks/useSubscription';
import { useTotalSubscriptionUser } from '@queryHooks/useSubscriptionUser';
import { TotalSubscriptionUserListModel } from '@models/subscription/TotalSubscriptionUserListModel';
import { FilterSelect, SelectItem } from './FilterSelect';
import { SubscriptionFilterType } from './SubscriptionUserMgmtList';

export const SubscriptionFilterSelect = ({
  subscriptionFilterPackage,
  userCountFilterPackage,
}: SubscriptionFilterType) => {
  const { queries, setQueries } = subscriptionFilterPackage;
  const { setUserCountKey } = userCountFilterPackage;
  const { tenantId } = useParams();

  const [selectFieldFilter, setSelectFieldFilter] = useState<TotalSubscriptionUserListFilter>({
    page: 0,
    size: 100000,
    softwareId: queries.softwareId,
  });

  const { data: selectFieldList } = useTotalSubscriptionUser({ ...selectFieldFilter });

  const selectFieldLists: TotalSubscriptionUserListModel[] =
    selectFieldList?.pages.flatMap(({ content }) => content) ?? [];

  const [softwareList, setSoftwareList] = useState<SelectItem | null>(null);

  const { data: tenantBySoftwareList } = useGetSubscriptionList({
    tenantId,
  });

  const tenantBySoftwareLists = tenantBySoftwareList?.content
    .flatMap(({ software, userCount }) => (userCount > 0 ? [software] : []))
    .map(software => {
      return { value: software.name, id: software.id, name: software.name, icon: software.iconUrl };
    }) as SelectItem[];

  const { subscriptionLists, memberByStatusLists } = selectFieldLists.reduce(
    (acc, data) => {
      acc.subscriptionLists.push({
        value: data.subscriptionName,
        id: data.subscriptionId,
        name: data.subscriptionName,
      });
      acc.memberByStatusLists.push({
        value: data.tenantMemberStatus,
        name: data.tenantMemberStatus,
      });
      return acc;
    },
    {
      subscriptionLists: [] as SelectItem[],
      memberByStatusLists: [] as SelectItem[],
    },
  );

  const refreshSoftware = tenantBySoftwareLists.find(software => software.id === queries.softwareId) as SelectItem;
  const refreshSubscription = subscriptionLists.find(subscription => {
    return subscription.id === queries.subscriptionId;
  }) as SelectItem;

  useEffect(() => {
    if (!softwareList) {
      setSoftwareList(refreshSoftware);
    }
  }, [softwareList, refreshSoftware]);

  const handleFilter = {
    changeSoftwareList: (item: SelectItem | null) => {
      const softwareId = item?.id || '';
      const subscriptionId = item && softwareList?.value !== item?.value ? '' : undefined;

      setSoftwareList(item);
      setSelectFieldFilter(prevFilter => ({ ...prevFilter, softwareId }));

      setQueries(prevFilter => ({
        ...prevFilter,
        softwareId,
        subscriptionId: item ? subscriptionId : '',
      }));
      setUserCountKey(prevFilter => ({
        ...prevFilter,
        softwareId,
        subscriptionId: item ? subscriptionId : '',
      }));
    },
    changeSubscriptionList: (item: SelectItem | null) => {
      setQueries(prevFilter => ({ ...prevFilter, subscriptionId: item?.id }));
      setUserCountKey(prevFilter => ({ ...prevFilter, subscriptionId: item?.id }));
    },
    changeMemberByStatus: (item: SelectItem | null) => {
      const memberStatus = TotalSubscriptionUserListModel.getTenantMemberStatus(
        item?.value as TenantMemberTextStatus,
      ) as TenantMemberStatus;
      setQueries(prevFilter => ({ ...prevFilter, memberStatus }));
      setUserCountKey(prevFilter => ({ ...prevFilter, memberStatus }));
    },
  };

  return (
    <Filters>
      <FilterSelect
        id="softwareList"
        data={tenantBySoftwareLists?.reduce((acc, cur) => {
          if (!acc.find(item => item.value === cur.value)) {
            acc.push(cur);
          }
          return acc;
        }, [] as SelectItem[])}
        placeholder="제품"
        onChange={handleFilter.changeSoftwareList}
        value={{
          value: refreshSoftware?.value,
          icon: refreshSoftware?.icon,
          name: refreshSoftware?.name,
        }}
      />
      <FilterSelect
        id="subscriptionList"
        data={subscriptionLists.reduce((acc, cur) => {
          if (!acc.find(item => item.value === cur.value)) {
            acc.push(cur);
          }
          return acc;
        }, [] as SelectItem[])}
        placeholder="구독"
        onChange={handleFilter.changeSubscriptionList}
        value={{
          value: refreshSubscription?.value ?? '',
          name: refreshSubscription?.name ?? '',
        }}
        disabled={!softwareList}
      />
      <FilterSelect
        id="memberByStatusList"
        data={memberByStatusLists.reduce((acc, cur) => {
          if (!acc.find(item => item.value === cur.value)) {
            acc.push(cur);
          }
          return acc;
        }, [] as SelectItem[])}
        placeholder="사용자 구분"
        onChange={handleFilter.changeMemberByStatus}
        value={{
          value: TotalSubscriptionUserListModel.getTenantMemberTextStatus(queries.memberStatus as string),
          name: TotalSubscriptionUserListModel.getTenantMemberTextStatus(queries.memberStatus as string),
        }}
      />
    </Filters>
  );
};

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 28px;
  height: 72px;
  background-color: ${props => props.theme.colors['bg-gray-light-50']};
  border-bottom: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 7px 7px 0px 0px;
  gap: 12px;
`;
