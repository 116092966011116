const defaultWidth = 24;
const defaultHeight = 24;

export const FilterIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M2.75 6C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H21.25C21.6642 7.5 22 7.16421 22 6.75C22 6.33579 21.6642 6 21.25 6H2.75Z"
          fill={color}
        />
        <path
          d="M6 11.75C6 11.3358 6.33579 11 6.75 11H17.25C17.6642 11 18 11.3358 18 11.75C18 12.1642 17.6642 12.5 17.25 12.5H6.75C6.33579 12.5 6 12.1642 6 11.75Z"
          fill={color}
        />
        <path
          d="M10 16.6875C10 16.2733 10.3358 15.9375 10.75 15.9375H13.25C13.6642 15.9375 14 16.2733 14 16.6875C14 17.1017 13.6642 17.4375 13.25 17.4375H10.75C10.3358 17.4375 10 17.1017 10 16.6875Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.7998 6.95C1.7998 6.42533 2.22513 6 2.7498 6H21.2498C21.7745 6 22.1998 6.42533 22.1998 6.95C22.1998 7.47467 21.7745 7.9 21.2498 7.9H2.7498C2.22513 7.9 1.7998 7.47467 1.7998 6.95ZM5.7998 11.95C5.7998 11.4253 6.22513 11 6.7498 11H17.2498C17.7745 11 18.1998 11.4253 18.1998 11.95C18.1998 12.4747 17.7745 12.9 17.2498 12.9H6.7498C6.22513 12.9 5.7998 12.4747 5.7998 11.95ZM9.7998 16.8875C9.7998 16.3628 10.2251 15.9375 10.7498 15.9375H13.2498C13.7745 15.9375 14.1998 16.3628 14.1998 16.8875C14.1998 17.4122 13.7745 17.8375 13.2498 17.8375H10.7498C10.2251 17.8375 9.7998 17.4122 9.7998 16.8875Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

FilterIcon.displayName = 'FilterIcon';
