import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Tooltip } from '@mui/material';
import { Cell, Pie, PieChart } from 'recharts';
import { Label } from '@components/Label';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { numberFormat } from '@utils/numberFormat';

export const OverallUsage = () => {
  const subscription = useSubscriptionModel();
  const overallUsages = subscription.realtimeUsage.usageList.find(({ module }) => module === 'OVERALL_USAGE')?.usages;
  const { t } = useTranslation();
  return !overallUsages || overallUsages.length === 0 ? null : (
    <TotalWrapper>
      <Title>{t('Acc_Main_12')}</Title>
      {overallUsages[0].limit > 0 ? (
        <WithLimit usage={overallUsages[0].value} limit={overallUsages[0].limit} unit={overallUsages[0].unit} />
      ) : (
        <WithoutLimit usage={overallUsages[0].value} unit={overallUsages[0].unit} />
      )}
    </TotalWrapper>
  );
};

type WithLimitProps = {
  usage: number;
  limit: number;
  unit: string;
};
const WithLimit = ({ usage, limit, unit }: WithLimitProps) => {
  const { t } = useTranslation();
  const cellColors = [colorDefinition.orange[300], '#D9D9D9'];
  const pieChartData = [
    { name: t('Acc_Detail_14'), data: usage },
    { name: t('Usage_Remaining'), data: limit - usage },
  ];

  return (
    <PieChartWrapper>
      <PieChart width={296} height={296} margin={{ top: -28, left: -28 }}>
        <Pie
          data={pieChartData}
          nameKey="name"
          dataKey="data"
          innerRadius={45}
          outerRadius={120}
          startAngle={90}
          endAngle={450}
        >
          {pieChartData.map((entry, index) => (
            <Cell
              key={entry.name as string}
              strokeWidth={0}
              fill={cellColors[index % cellColors.length]}
              style={{ filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.09))', outline: 'unset' }}
            />
          ))}
        </Pie>
      </PieChart>
      <Legend>
        <Tooltip arrow title={`${numberFormat({ num: usage })} ${unit}`}>
          <TotalAmount>
            <span>{numberFormat({ num: usage, maxFractionDigit: 5 })}</span>
            <small>{`(${unit})`}</small>
          </TotalAmount>
        </Tooltip>
        <Remain>
          <span>{t('Subscrib_Detail_Usage_02')}</span>
          <Label className="value" variant="outline" color="red">
            {numberFormat({ num: limit - usage, maxFractionDigit: 5 })}
          </Label>
          <Label variant="solid" color="gray">
            {unit}
          </Label>
        </Remain>
        <Limit>
          <span>제한 사용량</span>
          <Label className="value" variant="outline" color="gray">
            {numberFormat({ num: limit, maxFractionDigit: 5 })}
          </Label>
          <Label variant="solid" color="gray">
            {unit}
          </Label>
        </Limit>
        <LegendItem>
          <Box width="12px" height="12px" sx={{ backgroundColor: cellColors[0] }} />
          {t('Acc_Detail_14')}
        </LegendItem>
        <LegendItem>
          <Box width="12px" height="12px" sx={{ backgroundColor: cellColors[1] }} />
          {t('Usage_Remaining')}
        </LegendItem>
      </Legend>
    </PieChartWrapper>
  );
};

type WithoutLimitProps = {
  usage: number;
  unit: string;
};
const WithoutLimit = ({ usage, unit }: WithoutLimitProps) => {
  const ceiledUsage = Math.ceil(usage * 1000) / 1000;
  return (
    <WithoutLimitContainer>
      <span className="usage">{ceiledUsage}</span>
      <span className="unit">{`(${unit})`}</span>
    </WithoutLimitContainer>
  );
};

const TotalWrapper = styled.div`
  flex-shrink: 0;
`;

const Title = styled.span`
  ${fonts.Headline7}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const PieChartWrapper = styled.div`
  margin: -14px 40px 0px 28px;
  display: flex;
`;

const Legend = styled.div`
  margin: 22px 0px 0px 0px;
`;

const TotalAmount = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;

  ${fonts.Headline4}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};

  & > span {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${fonts.Headline11}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-right: 6px;
  }

  & > div {
    margin-bottom: 7px;
  }

  & > small {
    ${fonts.Body5}
    margin-bottom: 11px;
  }
`;

const Remain = styled.span`
  display: flex;
  align-items: center;
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin-bottom: 2px;

  & > .value {
    margin: 0px 2px 0px 6px;
  }
`;

const Limit = styled.div`
  display: flex;
  align-items: center;
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin-bottom: 24px;

  & > .value {
    margin: 0px 2px 0px 6px;
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${fonts.Body2}
  margin-bottom: 10px;
`;

const WithoutLimitContainer = styled.div`
  margin: 33px 177px 51px 88px;

  & .usage {
    display: inline-block;
    ${fonts.Headline1}
    margin-right: 4px;
    word-wrap: break-word;
  }

  & .unit {
    display: inline-block;
    ${fonts.Body4}
    color: ${({ theme: { colors } }) => colors['text-gray-light']}
  }
`;
