import styled from '@emotion/styled';
import { TableCell } from '@mui/material';
import { transientOptions } from '@utils/CommonUtil';

export const TableBodyCell = styled(TableCell, transientOptions)<{ $isFilterApplied?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${({ $isFilterApplied, theme: { colors } }) =>
    $isFilterApplied ? colors['bg-gray-lighter'] : 'transparent'};
`;
