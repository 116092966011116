import { useQuery } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import { SubscriptionCountStatRepository } from '@repositories/SubscriptionCountStatRepository';
import { SubscriptionCountStatModel } from '@models/SubscriptionCountStatModel';

const repo = repository.get<SubscriptionCountStatRepository>(KEYS.USER_SUBSCRIPTION_STAT_REPOSITORY_DEPRECATED);
const queryKey = {
  all: ['userSubscriptionStat'] as const,
  details: () => [...queryKey.all, 'detail'] as const,

  detail: () => [...queryKey.details()] as const,
};

export const useSubscriptionCountStat = (timeZone: string) =>
  useQuery({
    queryKey: queryKey.detail(),
    queryFn: async () => {
      const result = await repo.getOne(timeZone);
      return new SubscriptionCountStatModel(result);
    },
  });
