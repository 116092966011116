import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import CircleUpFillIcon from '@icons/CircleUpFillIcon';
import { useGetTenantMemberSummary } from '@queryHooks/useTenantMember';
import { WgBox, WgContainer, WgSummary, WgTitle } from '../styles';

export const MemberWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const handleClickSeeMore = () => {
    navigate(`/manager/${params.tenantId}/usergroups/memberMgmt/active`);
  };

  const { data: tenantMemberSummary, isSuccess } = useGetTenantMemberSummary(params.tenantId as string);

  return (
    <WgContainer>
      <WgTitle>
        <span>{t('Member_Group_07')}</span>
        <Button className="see-more" onClick={handleClickSeeMore} variant="text">
          {t('Home_Add')}
          <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
        </Button>
      </WgTitle>
      <WgSummary>
        <span className="count">
          <span className="count-number">{tenantMemberSummary?.totalMemberCount}</span>
          <span className="counting-unit">{t('Home_People')}</span>
        </span>
        <span>
          <MonthVariation>
            {t('Acc_Main_08')}
            {isSuccess && tenantMemberSummary.monthlyTotalMemberIncrease >= 0 ? (
              <span className="up">
                <CircleUpFillIcon /> {tenantMemberSummary.monthlyTotalMemberIncrease}
              </span>
            ) : (
              <span className="down">
                <CircleUpFillIcon color={theme.colors['ic-red-light']} rotateNum={180} />
                {tenantMemberSummary?.monthlyTotalMemberIncrease}
              </span>
            )}
          </MonthVariation>
        </span>
      </WgSummary>
      <Type>
        <WgBox width={132}>
          <Member>
            <span>{t('Home_Member_01')}</span>
            <span className="count">
              {tenantMemberSummary?.totalNormalMemberCount}
              <span className="unit">{t('Home_People')}</span>
            </span>
          </Member>
        </WgBox>
        <WgBox width={132}>
          <Member>
            <span>{t('Home_Member_02')}</span>
            <span className="count">
              {tenantMemberSummary?.totalCancelMemberCount}
              <span className="unit">{t('Home_People')}</span>
            </span>
          </Member>
        </WgBox>
      </Type>
    </WgContainer>
  );
};

const MonthVariation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  margin-bottom: 3px;
  & .up {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${fonts.Headline7};
    color: ${props => props.theme.colors['text-green-light']};
    gap: 2px;
  }
  & .down {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${fonts.Headline7};
    color: ${props => props.theme.colors['text-red-light']};
    gap: 2px;
  }
`;

const Type = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Member = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  height: 101px;
  padding: 0px 16px;
  & .count {
    ${fonts.Headline5};
    color: ${props => props.theme.colors['text-gray-main']};
    & .unit {
      margin-left: 2px;
      ${fonts.Body2};
      color: ${props => props.theme.colors['text-gray-main']};
    }
  }
`;
