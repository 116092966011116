import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { PlugIcon } from '@icons/PlugIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { numberTickFormatter } from '@utils/ChartUtil';

const { getStringSize } = require('recharts/lib/util/DOMUtils');

type Props = {
  monthFrom: string;
  monthTo: string;
};
export const SkeletonBarChart = ({ monthFrom, monthTo }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [chartData, setChartData] = useState<{ month: DateTimeString; value: number }[]>([]);
  const subscription = useSubscriptionModel();

  useEffect(() => {
    const getDummyData = () => {
      const returnVal: { month: DateTimeString; value: number }[] = [];
      let dateIter = dayjs(monthFrom);
      const endDate = dayjs(monthTo);
      while (dateIter.isBefore(endDate, 'month')) {
        returnVal.push({
          month: dateIter.format('YYYY.MM'),
          value: 500,
        });

        dateIter = dateIter.add(1, 'month');
      }

      return returnVal;
    };
    setChartData(getDummyData());
  }, [monthFrom, monthTo]);

  const yAxisTickFormatter = numberTickFormatter(chartData.map(({ value }) => value));

  const yAxisTickStyle = { ...fonts.Caption2, color: colors['text-gray-lighter'] };
  const maxValue = Math.max(...chartData.map(({ value }) => value));
  const yAxisWidth = getStringSize(yAxisTickFormatter(maxValue), yAxisTickStyle).width + 15; // tickMargin 고려 + 넉넉히 2 더해줌

  return (
    <Container>
      <ResponsiveContainer width="100%" height={265}>
        <BarChart data={chartData}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="month" stroke="1" tick={{ ...fonts.Caption2, color: colors['text-gray-lighter'] }} />
          <YAxis
            stroke="1"
            width={yAxisWidth}
            tick={yAxisTickStyle}
            tickFormatter={yAxisTickFormatter}
            domain={[0, 500]}
          />
          <Bar dataKey="value" fill={colors['graph-gray-lighter']} barSize={10} />
        </BarChart>
      </ResponsiveContainer>
      {subscription.connection?.isConnected ? (
        <Message>
          <IconWrapper>
            <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
          </IconWrapper>
          {t('Subscrib_Detail_Overview_32')}
        </Message>
      ) : (
        <Message>
          <IconWrapper>
            <PlugIcon width={22} height={22} color={colors['ic-purple-light']} />
          </IconWrapper>
          {t('Subscrib_Detail_Overview_24')}
        </Message>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  & .recharts-cartesian-axis-tick > text > tspan {
    fill: ${({ theme: { colors } }) => colors['text-gray-lighter']};
  }
`;

const Message = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${fonts.Headline7}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const IconWrapper = styled.div`
  padding: 7px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;
