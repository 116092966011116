import { RefCallback } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { SquareFillIcon } from '@icons/SquareFillIcon';
import { numberFormat } from '@utils/numberFormat';

type Props = {
  valueRef: RefCallback<HTMLDivElement>;
  valueMaxWidth: number;
  iconColor: string;
  name: string;
  share: number;
  value: number;
  currencyUnit: CurrencyUnit;
};
export const SmallCardContent = ({ valueRef, valueMaxWidth, iconColor, name, share, value, currencyUnit }: Props) => {
  return (
    <Container>
      <SmallCardMeta $width={`calc(100% - 24px - ${valueMaxWidth}px)`}>
        <SquareFillIcon color={iconColor} />
        <Tooltip label={name} visibleOnlyEllipsis placement="bottom-center">
          <span className="name">{name}</span>
        </Tooltip>
        <span className="share">({share}%)</span>
      </SmallCardMeta>
      <div className="cost" ref={valueRef}>{`(${currencyUnit})${numberFormat({ num: value })}`}</div>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 344px;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 8px;
  background: ${props => props.theme.colors['bg-white']};
  border: 0.5px solid ${props => props.theme.colors['border-gray-lighter']};
  box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;

  border-radius: 7px;
  gap: 5px;

  & .cost {
    ${fonts.Headline8};
    color: ${props => props.theme.colors['text-gray-main']};
    max-width: 164px;
  }
`;

export const SmallCardMeta = styled.div<{ $width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  width: ${({ $width }) => $width ?? '150px'};

  & .name {
    margin-left: 4px;
    margin-right: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  & .share {
    color: ${props => props.theme.colors['text-gray-main']};
  }
`;
