import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FileIcon } from '@components/FileIcon';
import { IconButton } from '@components/buttons';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { DownloadIcon } from '@icons/DownloadIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { Loading20Icon } from '@icons/LoadingIcon';
import { XIcon } from '@icons/XIcon';
import { useDownloadFile } from '@queryHooks/useFile';
import { download } from '@utils/fileUtil';
import type { FileSimpleModel } from '@models/file/FileSimpleModel';

type FileMeta = {
  fileId: string;
  fileName: string;
  isFinished: boolean;
};

type Props = {
  dummyFiles: FileMeta[];
  attachments: FileSimpleModel[];
  onAttachFileClick: () => void;
  onDeleteFileClick: (attachment: FileSimpleModel) => void;
};
export const BillingAttachmentInfo = ({ dummyFiles, attachments, onAttachFileClick, onDeleteFileClick }: Props) => {
  const { colors } = useTheme();
  const { mutate: fileDownload } = useDownloadFile();

  const handleDownloadFileClick = (attachment: FileSimpleModel) => () => {
    fileDownload(attachment.fileId, {
      onSuccess: data => {
        download(data, attachment.originalName);
      },
    });
  };

  const handleDeleteFileClick = (attachment: FileSimpleModel) => () => {
    onDeleteFileClick(attachment);
  };

  return (
    <>
      <SubtitleWrapper>
        <Subtitle>첨부 파일</Subtitle>
        <Button variant="outline" size="small" paddingHorizontal={16} onClick={onAttachFileClick}>
          추가
        </Button>
      </SubtitleWrapper>
      <AttachmentsWrapper $isEmptyFile={attachments.length === 0 && dummyFiles.length === 0}>
        {attachments.length === 0 && dummyFiles.length === 0 && (
          <EmptyFiles>
            <ErrorIconWrapper>
              <ErrorIcon width={16} height={16} color={colors['ic-purple-light']} />
            </ErrorIconWrapper>
            첨부된 파일이 없습니다.
          </EmptyFiles>
        )}
        {dummyFiles.map(dummy => (
          <Attachment key={dummy.fileId} $isDummy>
            <FileInfo $isDummy>
              <FileIcon fileName={dummy.fileName} />
              <FileName>{dummy.fileName}</FileName>
            </FileInfo>
            <Loading20Icon style={{ marginLeft: 'auto' }} />
          </Attachment>
        ))}
        {attachments.map(attachment => (
          <Attachment key={attachment.fileId}>
            <FileInfo>
              <FileIcon fileExtension={attachment.fileExtension} />
              <FileName>{attachment.fileName}</FileName>
              {attachment.fileExtension && `.${attachment.fileExtension}`}
            </FileInfo>
            <ButtonWrapper>
              <IconButton variant="outline" size="extraSmall" onClick={handleDownloadFileClick(attachment)}>
                <DownloadIcon width={14} height={14} color={colors['ic-gray-main']} />
              </IconButton>
              <IconButton variant="outline" size="extraSmall" onClick={handleDeleteFileClick(attachment)}>
                <XIcon width={14} height={14} color={colors['ic-gray-main']} />
              </IconButton>
            </ButtonWrapper>
          </Attachment>
        ))}
      </AttachmentsWrapper>
    </>
  );
};

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Subtitle = styled.h3`
  display: inline-block;
  ${fonts.Headline8}
  margin: 8px 0px 4px 8px;
`;

const AttachmentsWrapper = styled.div<{ $isEmptyFile?: boolean }>`
  padding: 16px 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  background-image: ${({ $isEmptyFile }) =>
    `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23${
      $isEmptyFile ? 'E1E4E8' : 'D1D5DA'
    }' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 10px;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: none;
  align-items: center;
  gap: 6px;
  margin-left: 12px;
`;

const Attachment = styled.div<{ $isDummy?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  padding: ${({ $isDummy }) => ($isDummy ? '9px 10px' : '7px 10px')};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};

  ${({ $isDummy, theme: { colors } }) =>
    $isDummy
      ? ''
      : css`
          &:hover {
            background-color: ${colors['state-white-purple-hover']};
            ${ButtonWrapper} {
              display: flex;
            }
          }
        `}
`;

const FileInfo = styled.div<{ $isDummy?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 0px;
  opacity: ${({ $isDummy }) => ($isDummy ? 0.4 : 1)};
`;

const FileName = styled.div`
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmptyFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  ${fonts.Body6};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin: -4px 0px -6px;
  grid-column: 1 / 6;
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;
