const defaultWidth = 20;
const defaultHeight = 20;

export const CheckedBgIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  bgColor = '#fff',
}: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill={bgColor} stroke={bgColor} />
        <path
          d="M14.3252 6.26843C14.5946 5.99907 15.0313 5.99907 15.3007 6.26843C15.57 6.53779 15.57 6.97451 15.3007 7.24387L8.81348 13.7311C8.68225 13.8623 8.50367 13.9351 8.31809 13.933C8.13252 13.931 7.9556 13.8542 7.82732 13.7201L4.72475 10.4765C4.46144 10.2012 4.47115 9.76461 4.74643 9.5013C5.0217 9.23799 5.45832 9.24769 5.72163 9.52297L8.33672 12.2569L14.3252 6.26843Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3947 6.17467C15.7162 6.4961 15.7162 7.01724 15.3947 7.33867L8.90755 13.8259C8.75095 13.9825 8.53785 14.0693 8.3164 14.0669C8.09495 14.0644 7.88384 13.9728 7.73076 13.8128L4.62819 10.5692C4.31398 10.2407 4.32556 9.71968 4.65405 9.40547C4.98254 9.09126 5.50356 9.10284 5.81777 9.43133L8.33862 12.0668L14.2307 6.17467C14.5522 5.85324 15.0733 5.85324 15.3947 6.17467Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CheckedBgIcon.displayName = 'CheckedBgIcon';
