import { Box, Typography } from '@mui/material';
import React from 'react';
import * as S from './TabStyles';

interface Props {
  tabs: string[];
  value: number;
  onChangeTab: (value: number) => void;
}

export const Tab = ({ tabs, value, onChangeTab }: Props) => {
  const handleChange = (e: unknown, tab: number) => {
    onChangeTab(tab);
  };

  return (
    <S.StyledTabs value={value} onChange={handleChange}>
      {tabs.map((tab: string) => (
        <S.StyledTab
          key={tab}
          label={
            <Typography variant="button2" component="span">
              {tab}
            </Typography>
          }
        />
      ))}
    </S.StyledTabs>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tab: number;
}

export const TabPanel = ({ index, tab, children }: TabPanelProps) => {
  return <Box hidden={tab !== index}>{tab === index && <Box>{children}</Box>}</Box>;
};
