import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import { fonts } from '@theme/fontsCustomer';
import { CircleDashFillIcon, CircleFillIcon, RectangleFillIcon } from '@icons/index';
import { useGetTenant } from '@queryHooks/useTenant';
import { useGetTenantBillingStat } from '@queryHooks/useTenantBillingStat';
import { transientOptions } from '@utils/CommonUtil';
import { numberFormat } from '@utils/numberFormat';

export const TenantBillingStats = () => {
  const { colors } = useTheme();
  const { tenantId } = useParams();
  const { t } = useTranslation();

  const { data: tenant } = useGetTenant(tenantId ?? '');
  const { data: tenantBillingStat, isLoading } = useGetTenantBillingStat({ month: dayjs().format('YYYY-MM') });

  const dashboardNumber = (value?: number) =>
    value && tenant ? numberFormat({ num: value ?? 0, style: 'currency', currency: tenant.currencyUnit }) : '-';

  const dashboardPercent = (value?: number) => {
    if (isLoading) {
      return <Skeleton width="40%" height={26} />;
    }
    if (value === undefined || value === null) {
      return null;
    }

    return (
      <DashboardItemPercent $value={value}>
        <span>{t('Subscrib_Main_07')}</span>
        {value === 0 ? (
          <CircleDashFillIcon width={16} height={16} color={colors['ic-gray-light']} />
        ) : (
          <CircleFillIcon
            width={16}
            height={16}
            rotateNum={value < 0 ? 180 : 0}
            color={value < 0 ? colors['ic-red-light'] : colors['ic-green-light']}
          />
        )}
        <b className={value === 0 ? 'gray' : ''}>{numberFormat({ num: value, maxFractionDigit: 5 })}%</b>
      </DashboardItemPercent>
    );
  };

  return (
    <Container>
      <DashboardContainer>
        <DashboardItem>
          <DashboardItemTitle>
            <RectangleFillIcon width={24} height={24} color={colors['ic-purple-light']} />
            {t('Acc_Main_01')}
          </DashboardItemTitle>
          <DashboardItemValueWrapper>
            <DashboardItemValue>{dashboardNumber(tenantBillingStat?.annualBillingAmountEstimate)}</DashboardItemValue>
          </DashboardItemValueWrapper>
        </DashboardItem>
        <DashboardItem>
          <DashboardItemTitle>
            <RectangleFillIcon width={24} height={24} color={colors['ic-yellow-dark']} />
            {t('Acc_Main_02')}
          </DashboardItemTitle>
          <DashboardItemValueWrapper>
            <DashboardItemValue>{dashboardNumber(tenantBillingStat?.annualPaymentEstimate)}</DashboardItemValue>
          </DashboardItemValueWrapper>
        </DashboardItem>
        <DashboardItem>
          <DashboardItemTitle>
            <RectangleFillIcon width={24} height={24} color={colors['ic-pink-light']} />
            {t('Acc_Main_03')}
          </DashboardItemTitle>
          <DashboardItemValueWrapper>
            <DashboardItemValue>{dashboardNumber(tenantBillingStat?.monthlyBillingAmount)}</DashboardItemValue>
            {dashboardPercent(tenantBillingStat?.monthlyBillingAmountEstimateGrowthRate)}
          </DashboardItemValueWrapper>
        </DashboardItem>
        <DashboardItem>
          <DashboardItemTitle>
            <RectangleFillIcon width={24} height={24} color={colors['ic-pink-light']} />
            {t('Acc_Main_04')}
          </DashboardItemTitle>
          <DashboardItemValueWrapper>
            <DashboardItemValue>{dashboardNumber(tenantBillingStat?.monthlyPayment)}</DashboardItemValue>
            {dashboardPercent(tenantBillingStat?.monthlyPaymentEstimateGrowthRate)}
          </DashboardItemValueWrapper>
        </DashboardItem>
      </DashboardContainer>
      <Notice>{t('Acc_Main_28')}</Notice>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 56px;
`;

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 5px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-dark']};
`;

const DashboardItem = styled.div`
  padding: 34px 36px 24px 28px;
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  &:last-of-type {
    border-right: none;
  }
`;

const DashboardItemTitle = styled.div`
  ${fonts.Headline7}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & > svg {
    flex-shrink: 0;
  }
`;

const DashboardItemValueWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-left: 6px;
  container-type: inline-size;
  container-name: dashboard;
  @container dashboard (max-width: 400px) {
    strong {
      font-size: 28px !important;
    }
  }
  @container dashboard (max-width: 350px) {
    strong {
      font-size: 26px !important;
    }
  }
`;

const DashboardItemValue = styled.strong`
  ${fonts.Headline3}
`;

const DashboardItemPercent = styled('div', transientOptions)<{ $value: number }>`
  display: flex;
  align-items: center;

  & > span {
    ${fonts.Body4}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-right: 6px;
  }
  & svg {
    flex-shrink: 0;
  }
  & > b {
    ${fonts.Headline6}
    color: ${({ $value, theme: { colors } }) =>
      $value > 0 ? colors['text-green-light'] : $value < 0 ? colors['text-red-light'] : colors['text-gray-light']};
    margin-left: 4px;
  }
`;

const Notice = styled.p`
  ${fonts.Body4}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
  text-align: right;
  margin-top: 8px;
`;
