import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Modal, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { XIcon } from '@icons/XIcon';
import i18n from '@locales/i18n';

export const Alert = observer(() => {
  const theme = useTheme();
  const {
    uiStore: { alertStore: modal },
  } = useStore();

  const successClass = modal.type === 'success' ? 'success' : 'err';
  const languageClass = i18n.language === 'en' ? 'en' : '';

  const combinedClassName = `${successClass} ${languageClass}`;

  return (
    <Modal open={modal.visible}>
      <ModalWrapper>
        <Header>
          {modal.title}
          <IconButton onClick={modal.close}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </IconButton>
        </Header>
        {React.isValidElement(modal.message) ? modal.message : <Body>{modal.message}</Body>}
        <Footer>
          {modal.useCancel ? (
            <>
              <SuccessButton
                className={combinedClassName}
                variant="contain"
                size="extraLarge"
                onClick={modal.onConfirm}
                autoFocus
              >
                {modal.confirmName}
              </SuccessButton>
              <StyledButton variant="outline" size="extraLarge" onClick={modal.close}>
                {modal.cancelName}
              </StyledButton>
            </>
          ) : (
            <Button
              className={modal.type === 'success' ? 'success' : ''}
              variant="contain"
              size="extraLarge"
              onClick={modal.onConfirm}
              fullWidth
              autoFocus
            >
              {modal.confirmName}
            </Button>
          )}
        </Footer>
      </ModalWrapper>
    </Modal>
  );
});

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 100px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: inherit;
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  padding: 0px 18px 0px 28px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 48px;
  width: inherit;
  padding: 12px 24px 4px;
  ${fonts.Paragraph1};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
  padding: 24px 28px 28px;
  & > button {
    &.success {
      &:first-of-type {
        color: ${({ theme }) => theme.colors['text-white']};
        background-color: ${({ theme }) => theme.colors['bg-purple']};
      }
    }
    &:first-of-type {
      color: ${({ theme }) => theme.colors['text-white']};
      background-color: ${({ theme }) => theme.colors['bg-red-light']};
    }
    &.err {
      &:first-of-type {
        color: ${({ theme }) => theme.colors['text-white']};
        background-color: ${({ theme }) => theme.colors['bg-red-light']};
      }
    }
  }
`;

const SuccessButton = styled(Button)`
  flex: 1;
  &.en {
    flex: 2;
  }
`;

const StyledButton = styled(Button)`
  flex: 1;
`;
