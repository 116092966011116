import { UsageMetric } from '@repositories/softwareRepository';

export class UsageMetricModel implements UsageMetric {
  private readonly _usageMetricId: string;
  private readonly _usageMetricName: string;
  private readonly _usageMetricCode: string;
  private readonly _description: string;
  private readonly _usageUnit: string;
  private readonly _isBillable: boolean;
  private readonly _defaultBillableUsageExpression: string;
  private readonly _defaultCostExpression: string;
  private readonly _softwareId: string;

  constructor(softwareId: string, dto?: UsageMetric) {
    this._usageMetricId = dto?.usageMetricId || '';
    this._usageMetricName = dto?.usageMetricName || '';
    this._usageMetricCode = dto?.usageMetricCode || '';
    this._description = dto?.description || '';
    this._usageUnit = dto?.usageUnit || '';
    this._isBillable = dto?.isBillable || false;
    this._defaultBillableUsageExpression = dto?.defaultBillableUsageExpression || '';
    this._defaultCostExpression = dto?.defaultCostExpression || '';
    this._softwareId = softwareId;
  }

  public get usageMetricId(): string {
    return this._usageMetricId;
  }

  public get usageMetricName(): string {
    return this._usageMetricName;
  }

  public get usageMetricCode(): string {
    return this._usageMetricCode;
  }

  public get description(): string {
    return this._description;
  }

  public get usageUnit(): string {
    return this._usageUnit;
  }

  public get isBillable(): boolean {
    return this._isBillable;
  }

  public get defaultBillableUsageExpression(): string {
    return this._defaultBillableUsageExpression;
  }

  public get defaultCostExpression(): string {
    return this._defaultCostExpression;
  }

  public get softwareId(): string {
    return this._softwareId;
  }
}
