import { XIcon } from '@icons/XIcon';
import { IconButton, MenuItem, Modal, Select, useTheme, Radio, SelectChangeEvent } from '@mui/material';
import { useGetTenantMembers, useUpdateTenantMemberRole } from '@queryHooks/useTenant';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { SearchIcon } from '@icons/SearchIcon';
import { RadioIcon, RadioOffIcon } from '@icons/index';
import { useGetUserGroups } from '@queryHooks/index';
import { nanoid } from 'nanoid';
import { TenantMemberFilter } from '@repositories/tenantRepository/Types';
import { useTranslation } from 'react-i18next';
import * as S from './ManagerChangeModalStyle';

interface Props {
  isClose: () => void;
  isOpen: boolean;
}

export const ManagerChangeModal = observer(({ isOpen, isClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [userId, setUserId] = useState<string>('');
  const [textFieldFilters, setTextFieldFilters] = useState<TenantMemberFilter>({
    userStatus: 'ACTIVE',
    tenantMemberStatus: 'ACTIVE',
    userGroupId: 'all',
  });
  const {
    authStore,
    uiStore: { alertStore: modal },
  } = useStore();

  const { data: members } = useGetTenantMembers(authStore.curTenant.id, {
    ...textFieldFilters,
    userGroupId: textFieldFilters.userGroupId === 'all' ? undefined : textFieldFilters.userGroupId,
  });
  const { mutate: updateRole } = useUpdateTenantMemberRole();
  const { data: userGroups } = useGetUserGroups({
    tenantId: authStore.managerTenantInfo.id,
  });

  const handleClickRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.target.value);
  };

  const handleChangeSelectValue = (event: SelectChangeEvent<string>) => {
    setTextFieldFilters(prev => ({ ...prev, userGroupId: event.target.value }));
  };

  const handleSuperAdminChangeBtn = () => {
    if (userId !== undefined) {
      updateRole({ memberRole: 'SUPER_ADMIN', tenantId: authStore.curTenant.id, userId });
    }
  };
  const debounceOnChangeSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTextFieldFilters(prev => {
      return { ...prev, keyword: e.target.value };
    });
  };

  return (
    <Modal open={isOpen}>
      <S.ModalWrapper>
        <S.Header>
          <S.HeaderTitle>{t('Member_Manager_18')}</S.HeaderTitle>
          <IconButton onClick={isClose}>
            <XIcon color={theme.colors['ic-gray-main']} width={24} height={24} />
          </IconButton>
        </S.Header>
        <S.Body>
          <S.Search>
            <Select
              value={textFieldFilters.userGroupId}
              size="small"
              sx={{ width: '139px', flexShrink: '0' }}
              onChange={handleChangeSelectValue}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '139px',
                    maxHeight: '328px',
                    marginTop: '5px',
                    borderRadius: '0 0 2px 2px',
                    boxShadow: '0',
                    border: `1px solid ${theme.colors['border-gray-light']}`,
                    '& .MuiList-root': {
                      border: 'none',
                      padding: '4px 0',
                      '& .MuiMenuItem-root': {
                        margin: '0 5px',
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem value="all">{t('Acc_Main_12')}</MenuItem>
              {userGroups?.map(group => {
                return (
                  <MenuItem key={nanoid()} value={group.userGroupId}>
                    {group.name || '-'}
                  </MenuItem>
                );
              })}
            </Select>
            <S.StyledTextField
              fullWidth
              size="small"
              placeholder="구성원 이름 검색"
              onChange={e => debounceOnChangeSearch(e)}
              InputProps={{
                startAdornment: (
                  <S.IconWrapper>
                    <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                  </S.IconWrapper>
                ),
              }}
            />
          </S.Search>
          <ul>
            {members.content
              .filter(member => member.memberRole !== 'USER')
              .map(user => {
                return (
                  <li key={user.id}>
                    <span>{user.name}</span>
                    <span>{user.username}</span>
                    <Radio
                      value={user.id}
                      icon={
                        user.memberRole === 'SUPER_ADMIN' ? (
                          <RadioIcon bgColor={theme.colors['bg-white']} color={theme.colors['bg-gray-dark']} />
                        ) : (
                          <RadioOffIcon color={theme.colors['border-gray-light']} />
                        )
                      }
                      checked={userId === user.id}
                      onChange={handleClickRadio}
                      checkedIcon={<RadioIcon bgColor={theme.colors['bg-white']} color={theme.colors['bg-purple']} />}
                      disabled={user.memberRole === 'SUPER_ADMIN' && true}
                    />
                  </li>
                );
              })}
          </ul>
        </S.Body>
        <S.Footer>
          <S.StyledButton
            variant="contain"
            size="extraLarge"
            disabled={userId === '' && true}
            onClick={() =>
              modal.open({
                title: `${t('Member_Manager_18')}`,
                confirmName: t('Member_Manager_change'),
                message: `${t('Member_Manager_19')}`,
                useCancel: true,
                onConfirm: handleSuperAdminChangeBtn,
              })
            }
          >
            {t('Member_Manager_change')}
          </S.StyledButton>
          <S.StyledButton variant="outline" size="extraLarge" onClick={isClose}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </S.StyledButton>
        </S.Footer>
      </S.ModalWrapper>
    </Modal>
  );
});
