import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useGetTenantList } from '@queryHooks/useTenant';
import i18n from '@locales/i18n';
import { UserGroupTableRow } from './UserGroupTableRow';

export const UserGroupTable = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: tenants } = useGetTenantList({ isRootTenant: true });
  const {
    uiStore: { userGroupCreateStore: modal },
  } = useStore();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <GroupAddButton
        size="small"
        variant="contain"
        paddingHorizontal={16}
        onClick={e => setAnchorEl(e.currentTarget)}
        className={i18n.language === 'en' ? 'en' : ''}
      >
        {t('Member_Group_Create_01')}
        <ChevronIcon width={16} height={16} color={theme.colors['ic-white']} rotateNum={menuOpen ? undefined : 180} />
      </GroupAddButton>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            minWidth: '160px',
            marginTop: '4px',
            borderRadius: '3px',
            '& .MuiList-root': {
              borderRadius: '3px',
            },
            '& .MuiButtonBase-root': {
              padding: '10px 11px',
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            modal.open();
          }}
        >
          {t('Member_Group_Create_Individual')}
        </MenuItem>
        <MenuItem onClick={() => navigate('batch-register')}>{t('Member_Group_Bulk_Create_01')}</MenuItem>
      </Menu>
      <GroupTableWrap>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" width={700}>
                {t('Member_User_Bulk_Create_18')}
              </StyledTableCell>
              <StyledTableCell align="left" width={300}>
                {t('Member_Group_Bulk_Create_17')}
              </StyledTableCell>
              <StyledTableCell align="left" width={300}>
                {t('Member_Group_14')}
              </StyledTableCell>
              <StyledTableCell align="left" width={300}>
                {t('Member_Group_05')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants?.content.map((tenant, idx) => {
              return <UserGroupTableRow idx={idx} rowData={tenant} key={tenant.id} />;
            })}
          </TableBody>
        </Table>
      </GroupTableWrap>
    </>
  );
});

const GroupTableWrap = styled(TableContainer)`
  display: flex;
  flex-direction: column;
`;
const GroupAddButton = styled(Button)`
  max-width: 102px;
  margin-bottom: 12px;
  margin-left: 8px;
  gap: 2px;
  &.en {
    max-width: 100%;
  }
`;

const StyledTableCell = styled(TableCell)`
  &:last-of-type {
    padding-right: 16px;
  }
`;
