import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { ModalBody } from '@pages/customer/manager/userGroup/licenseRetrieve/ModalBody';
import { useStore } from '@stores/RootStore';
import { colors } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { DangerFillIcon } from '@icons/DangerFillIcon';
import { SubscriptionUserUpdateRq } from '@repositories/subscriptionRepository';
import {
  useDeleteSubscriptionUser,
  useGetSubscriptionUser,
  useUpdateSubscriptionUser,
} from '@queryHooks/useSubscription';
import { useSubscriptionUserListWithCanRevoke } from '@queryHooks/useSubscriptionUser';
import { SoftwareIcon, SoftwareLabel } from '../list/SubscriptionUserTable';
import { useSubscriptionUserForm } from './validationSchema';

type WarningInfo = {
  message: JSX.Element;
  icon: JSX.Element;
};

type StatusWarings = {
  [key: string]: WarningInfo;
};

const AbnormalStateWarningText = ({
  status,
  isMonthlyInactiveUser,
}: {
  status: string;
  isMonthlyInactiveUser?: boolean;
}) => {
  const statusWarnings: StatusWarings = {
    '정상 구성원': {
      message: isMonthlyInactiveUser ? (
        <>
          <EmphasizeText>한 달 이상</EmphasizeText>&nbsp;로그인하지 않은 사용자입니다.&nbsp;
          <EmphasizeText>사용 여부</EmphasizeText>를 확인해 주세요.
        </>
      ) : (
        <>
          <EmphasizeText>비활성 상태</EmphasizeText>의 사용자입니다.&nbsp;<EmphasizeText>사용 여부</EmphasizeText>를
          확인해 주세요.
        </>
      ),
      icon: <DangerFillIcon width={16} height={16} />,
    },
    '탈퇴 구성원': {
      message: (
        <>
          <EmphasizeText>탈퇴한 구성원</EmphasizeText>입니다.&nbsp;<EmphasizeText>라이선스 회수</EmphasizeText>가
          필요합니다.
        </>
      ),
      icon: <DangerFillIcon width={16} height={16} />,
    },
    비구성원: {
      message: (
        <>
          <EmphasizeText>구성원이 아닌 사용자</EmphasizeText>입니다.&nbsp;사용 여부를 확인 후&nbsp;
          <EmphasizeText>라이선스를 관리</EmphasizeText>해 주세요.
        </>
      ),
      icon: <DangerFillIcon width={16} height={16} />,
    },
  };
  const warning = statusWarnings[status];
  if (!warning) return null;

  return (
    <UserInfoWarning>
      {warning.icon}
      &nbsp;
      {warning.message}
    </UserInfoWarning>
  );
};

export const SubscriptionUserDetail = () => {
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const {
    state: { id, loginId, memberStatus, softwareIconUrl, softwareName, subscriptionName },
  }: {
    state: {
      id: string;
      loginId: string;
      memberStatus: string;
      softwareIconUrl: string;
      softwareName: string;
      subscriptionName: string;
    };
  } = useLocation();

  const [isMemoEditMode, setIsMemoEditMode] = useState<boolean>(true);
  const { reset, register, handleSubmit, watch } = useSubscriptionUserForm();

  const watchMemoLength = watch('memo')?.length;

  const { data: subscriptionUserDetail, isSuccess: getSubscriptionUserDetail } = useGetSubscriptionUser(
    id ?? '',
    loginId ?? '',
  );

  useEffect(() => {
    reset({
      memo: subscriptionUserDetail?.memo,
    });
  }, [reset, subscriptionUserDetail?.memo]);

  const today = dayjs();
  const isMonthlyInactiveUser = today.diff(dayjs(subscriptionUserDetail?.lastLoginTime), 'month') > 0;
  const isAbnormalStatus =
    memberStatus !== '정상 구성원' ||
    (memberStatus === '정상 구성원' && isMonthlyInactiveUser) ||
    (getSubscriptionUserDetail && subscriptionUserDetail?.userStatus !== 'ACTIVE');

  const {
    uiStore: { alertStore, toastStore },
  } = useStore();

  const { data: userLicenses } = useSubscriptionUserListWithCanRevoke(
    {
      canRevoke: true,
      loginId: subscriptionUserDetail?.userEmail ?? '',
    },
    { enabled: getSubscriptionUserDetail },
  );

  const autoLicenses = userLicenses?.filter(
    license => license.subscription.id === subscriptionUserDetail?.subscriptionId,
  );

  const retrieveMode = autoLicenses && autoLicenses[0] !== undefined ? 'auto' : 'manual';

  const { mutate: deleteUserFromSubscription } = useDeleteSubscriptionUser();
  const { mutate: updateSubscriptionUser } = useUpdateSubscriptionUser();

  const handleClickRetrieve = () => {
    if (retrieveMode === 'auto') {
      alertStore.open({ title: '라이선스 회수', message: '라이선스 자동 회수가 가능한 구독입니다.' });
    } else {
      alertStore.open({
        title: '라이선스 회수',
        type: 'error',
        message: <ModalBody type={retrieveMode} />,
        confirmName: '회수',
        cancelName: '취소',
        onConfirm: () => {
          deleteUserFromSubscription(
            {
              subscriptionId: subscriptionUserDetail?.subscriptionId ?? '',
              userLoginIdOrUserId:
                (subscriptionUserDetail?.userId ?? '') || (subscriptionUserDetail?.userLoginId ?? ''),
            },
            {
              onSuccess: () => {
                toastStore.open('라이선스 회수가 완료되었습니다.');
              },
            },
          );
        },
        useCancel: true,
      });
    }
  };

  const handleSubmitForm: SubmitHandler<SubscriptionUserUpdateRq> = formData => {
    const processRqData: SubscriptionUserUpdateRq = {
      memo: formData.memo,
      billableStatus: subscriptionUserDetail?.billableStatus ?? 'BILLABLE',
      lastLoginTime: subscriptionUserDetail?.lastLoginTime,
      userEmail: subscriptionUserDetail?.userEmail,
      userId: subscriptionUserDetail?.userId,
      userLoginId: subscriptionUserDetail?.userLoginId ?? '',
      userName: subscriptionUserDetail?.userName,
      userOrganization: subscriptionUserDetail?.userOrganization,
      userRole: subscriptionUserDetail?.userRole,
      userType: subscriptionUserDetail?.userType,
    };

    updateSubscriptionUser(
      { subscriptionId: subscriptionUserDetail?.subscriptionId ?? '', rqData: processRqData },
      {
        onSuccess: () => {
          setIsMemoEditMode(!isMemoEditMode);
          toastStore.open('저장을 완료했습니다.');
        },
      },
    );
  };

  const handleEditMemo = () => {
    setIsMemoEditMode(!isMemoEditMode);
    reset({
      memo: subscriptionUserDetail?.memo,
    });
  };

  const handleClickSubscription = () => {
    navigate(`/manager/${tenantId}/subscriptions/list/${id}/user`);
  };

  return (
    <Container>
      {getSubscriptionUserDetail ? (
        <>
          <Header onClick={() => navigate(-1)}>
            <ChevronIcon rotateNum={270} />
            <HeaderTitle>사용자 목록</HeaderTitle>
          </Header>
          <Body>
            <UserInfoWrapper>
              <UserInfoTitle>사용자 정보</UserInfoTitle>
              {isAbnormalStatus ? (
                <AbnormalStateWarningText
                  status={memberStatus}
                  isMonthlyInactiveUser={isMonthlyInactiveUser && subscriptionUserDetail.userStatus === 'ACTIVE'}
                />
              ) : null}
              <Divider isAbnormalStatus={isAbnormalStatus} />
              <UserInfoSection>
                <UserInfoDetailEl hasLabel>
                  <UserInfoDetailElName>구분</UserInfoDetailElName>
                  <UserInfoDetailElValue>
                    <Label
                      variant="solid"
                      color={memberStatus === '정상 구성원' ? 'green' : memberStatus === '탈퇴 구성원' ? 'red' : 'gray'}
                    >
                      {memberStatus}
                    </Label>
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>이름</UserInfoDetailElName>
                  <UserInfoDetailElValue>{subscriptionUserDetail.userName ?? '-'}</UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName />
                  <UserInfoDetailElValue />
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>계정</UserInfoDetailElName>
                  <UserInfoDetailElValue>{subscriptionUserDetail.user?.email ?? '-'}</UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>그룹</UserInfoDetailElName>
                  <UserInfoDetailElValue>{subscriptionUserDetail.userGroup?.name ?? '-'}</UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>연락처</UserInfoDetailElName>
                  <UserInfoDetailElValue>
                    {subscriptionUserDetail.user?.phone ? subscriptionUserDetail.user?.phone : '-'}
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
              </UserInfoSection>
            </UserInfoWrapper>
            <UseSubscriptionListWrapper>
              <UseSubscriptionListTitle>사용 항목</UseSubscriptionListTitle>
              <Divider style={{ marginTop: 0 }} />
              <UserInfoSection>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>제품명</UserInfoDetailElName>
                  <UserInfoDetailElValue>
                    <SoftwareLabel>
                      <SoftwareIcon src={softwareIconUrl} />
                      {softwareName}
                    </SoftwareLabel>
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>구독명</UserInfoDetailElName>
                  <UserInfoDetailElValue className="subscription" onClick={handleClickSubscription}>
                    {subscriptionName}
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName />
                  <UserInfoDetailElValue />
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>메일 계정</UserInfoDetailElName>
                  <UserInfoDetailElValue>{subscriptionUserDetail.userEmail ?? '-'}</UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>권한</UserInfoDetailElName>
                  <UserInfoDetailElValue>{subscriptionUserDetail.userRole ?? '-'}</UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl>
                  <UserInfoDetailElName>마지막 사용 날짜</UserInfoDetailElName>
                  <UserInfoDetailElValue>
                    {subscriptionUserDetail.lastLoginTime !== null
                      ? dayjs(subscriptionUserDetail.lastLoginTime).format('YYYY.MM.DD')
                      : '-'}
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl hasLabel>
                  <UserInfoDetailElName>과금 여부</UserInfoDetailElName>
                  <UserInfoDetailElValue>
                    <StyledLabel
                      variant="solid"
                      color={subscriptionUserDetail.billableStatus === 'BILLABLE' ? 'white' : 'red'}
                    >
                      {subscriptionUserDetail.billableStatus === 'BILLABLE' ? 'Y' : 'N'}
                    </StyledLabel>
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl hasLabel>
                  <UserInfoDetailElName>상태</UserInfoDetailElName>
                  <UserInfoDetailElValue>
                    <Label variant="solid" color={subscriptionUserDetail.userStatus === 'ACTIVE' ? 'blue' : 'gray'}>
                      {subscriptionUserDetail.userStatus === 'ACTIVE' ? '활성' : '비활성'}
                    </Label>
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
                <UserInfoDetailEl memberStatus={memberStatus}>
                  <UserInfoDetailElName>관리</UserInfoDetailElName>
                  <UserInfoDetailElValue className="licenseButton" onClick={handleClickRetrieve}>
                    라이선스 회수
                  </UserInfoDetailElValue>
                </UserInfoDetailEl>
              </UserInfoSection>
              <UserMemoSection>
                <UpdateMemoForm onSubmit={handleSubmit(handleSubmitForm)}>
                  <UserMemoHeader>
                    <UserMemoTitle>
                      메모
                      {!isMemoEditMode ? (
                        <TextCountBox>
                          ( <TextCount>{watchMemoLength}</TextCount> / 5000 )
                        </TextCountBox>
                      ) : null}
                    </UserMemoTitle>
                    {isMemoEditMode ? (
                      <UserMemoButton variant="outlined" size="small" onClick={handleEditMemo}>
                        수정
                      </UserMemoButton>
                    ) : (
                      <UserMemoBox>
                        <UserMemoButton variant="outlined" size="small" type="submit">
                          저장
                        </UserMemoButton>
                        <UserMemoButton variant="outlined" size="small" onClick={handleEditMemo}>
                          취소
                        </UserMemoButton>
                      </UserMemoBox>
                    )}
                  </UserMemoHeader>
                  <UserMemo
                    {...register('memo')}
                    placeholder="메모를 작성해주세요."
                    disabled={isMemoEditMode}
                    maxLength={5000}
                  />
                </UpdateMemoForm>
              </UserMemoSection>
            </UseSubscriptionListWrapper>
          </Body>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  padding: 29px 32px;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fonts.Headline6};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 40px;
  gap: 4px;
  cursor: pointer;
`;

const HeaderTitle = styled.strong``;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 750px;
`;

const UserInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 24px;
`;

const UserInfoTitle = styled.span`
  ${fonts.Headline7};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const UserInfoWarning = styled.div`
  ${fonts.Body2}
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  height: fit-content;
  padding: 18px 16px;
  background-color: ${props => props.theme.colors['bg-red-lighter']};
  border: 1px solid ${props => props.theme.colors['border-red-lighter-more']};
  border-radius: 5px;
  color: ${props => props.theme.colors['text-gray-main']};
`;

const Divider = styled.hr<{ isAbnormalStatus?: boolean }>`
  margin-top: ${props => (props.isAbnormalStatus ? '12' : '16')}px;
  width: 100%;
  border: 0;
  height: 1px;
  background: ${props => props.theme.colors['border-gray-light']};
  margin-bottom: 0;
`;

const UserInfoSection = styled.section`
  width: 750px;
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: repeat(2, 72px);

  & :nth-of-type(3n) {
    border-right: none;
  }
`;

const UserInfoDetailEl = styled.div<{ hasLabel?: boolean; memberStatus?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => (props.hasLabel ? '4' : '2')}px;
  padding: 14px 20px ${props => (props.hasLabel ? '12' : '14')}px 20px;
  box-sizing: border-box;

  border-right: 1px solid ${colors.light['border-gray-w-lighter']};
  border-bottom: 1px solid ${colors.light['border-gray-w-lighter']};
  & .licenseButton {
    ${fonts.Headline8}
    cursor: pointer;
    color: ${colors.light['text-blue-light']};
  }
  & .licenseButton:hover {
    font: ${fonts.Underline3};
  }

  & .subscription {
    color: ${colors.light['text-blue-light']};
  }

  & .subscription:hover {
    ${fonts.Underline3}
    cursor: pointer;
  }
`;

const UserInfoDetailElName = styled.span`
  ${fonts.Headline9};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;

const UserInfoDetailElValue = styled.span`
  height: 20px;
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const UseSubscriptionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 72px 24px 0px 24px;
  width: 750px;
`;

const UseSubscriptionListTitle = styled.span`
  ${fonts.Headline7};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 16px;
`;

const StyledLabel = styled(Label)`
  ${fonts.Caption5}
  background-color: ${colors.light['bg-white']};
`;

const UserMemoSection = styled.section`
  width: 750px;
  display: flex;
  flex-direction: column;
`;
const UserMemoHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 76px 0px 10px 0;
`;

const UserMemoTitle = styled.strong`
  font: ${fonts.Headline7};
  display: flex;
  gap: 6px;
  align-items: center;
`;

const UserMemoBox = styled.div`
  display: flex;
  gap: 8px;
`;

const UserMemoButton = styled(Button)`
  font: ${fonts.Button4};
  padding: 8px 12px;
  min-width: 56px;
`;

const UserMemo = styled.textarea`
  ${fonts.Body2}
  width: 750px;
  height: 192px;
  display: flex;
  padding: 6px 12px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid ${colors.light['border-gray-lighter']};
  resize: none;
  background-color: ${colors.light['bg-gray-lighter']};
  ::placeholder {
    color: ${colors.light['text-gray-light']};
  }
`;

const TextCountBox = styled.span`
  ${fonts.Body2}
  color: ${colors.light['text-gray-light']};
`;
const TextCount = styled.span`
  color: ${colors.light['text-purple-light']};
`;

const UpdateMemoForm = styled.form``;

const EmphasizeText = styled.span`
  color: ${props => props.theme.colors['text-red']};
`;
