import styled from '@emotion/styled';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { fonts } from '@theme/fontsCustomer';

export const NoTriggerConfig = observer(() => {
  return (
    <Container>
      <EmptyCondtionDescription>{t('Workflow_Create_73')}</EmptyCondtionDescription>{' '}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
`;

const EmptyCondtionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['bg-purple-lighter']};
  padding: 18px 24px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-purple']};
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
`;
