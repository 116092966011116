import type { NodeDataType } from './GraphType';
import type { NodeProps } from 'reactflow';
import { Handle, Position } from 'reactflow';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import 'reactflow/dist/base.css';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';

type Props = NodeProps<NodeDataType>;

export const EndNode = observer(({ isConnectable: isNodeConnectable }: Props) => {
  const theme = useTheme();
  const { workflowDetailStore } = useStore();
  const isEditable = workflowDetailStore.checkEditable();
  const isConnectable = isNodeConnectable && isEditable;

  return (
    <Container isSelected={false}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        style={{ background: theme.colors['border-gray-light'], minWidth: '1px' }}
      />
      <End>End</End>
      <Handle
        type="target"
        position={Position.Top}
        id="normal"
        isConnectable={isConnectable}
        style={{
          background: theme.colors['border-gray-light'],
          width: 10,
          height: 10,
          borderRadius: '50%',
          border: '1px solid gray',
        }}
      />
    </Container>
  );
});

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 50px;

  background-color: white;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 25px;
`;

const End = styled.div`
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-purple']};
`;
