const defaultWidth = 16;
const defaultHeight = 16;

export const CheckboxBgIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  bgColor = '#fff',
}: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill={bgColor} stroke={color} />
      </g>
    </svg>
  );
};

CheckboxBgIcon.displayName = 'CheckboxBgIcon';
