import type { TechnicalSupportModalOption } from '../Products';
import type { FormTCRq } from './validationSchema';
import type { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { Modal } from '@mui/material';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { colors } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { useAddTechnicalInquiry } from '@queryHooks/useTechnicalSupport';
import { useTCForm } from './validationSchema';

type TechnicalSupportCreateModalProps = {
  modalOption: TechnicalSupportModalOption;
  onClose: () => void;
};

export const TechnicalSupportCreateModal = ({ modalOption, onClose }: TechnicalSupportCreateModalProps) => {
  const { isOpen, subscriptionId, tenantId } = modalOption;

  const { mutate: addTechnicalInquiry } = useAddTechnicalInquiry();

  const { control, watch, handleSubmit } = useTCForm();
  const {
    uiStore: { toastStore },
  } = useStore();

  const inquiry = watch('inquiry');

  const validation = {
    success: (data: FormTCRq) => {
      const createInquiryData = {
        inquiry: data.inquiry,
        subscriptionId,
        tenantId,
      };
      addTechnicalInquiry(createInquiryData, {
        onSuccess: () => {
          toastStore.open({ message: '문의를 완료했습니다.', hideAction: true });
          onClose();
        },
        onError: () => {
          toastStore.open({ message: '문의를 실패했습니다.', hideAction: true });
          onClose();
        },
      });
    },
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Container onSubmit={handleSubmit(validation.success)}>
        <StyledModalHeader>기술지원 문의</StyledModalHeader>
        <StyledModalBody>
          <Description>
            <DescriptionList>
              <BulletIcon color={colors.light['ic-gray-lighter']} /> 담당자가 확인후 연락드릴 예정입니다.
            </DescriptionList>
            <DescriptionList>
              <BulletIcon color={colors.light['ic-gray-lighter']} /> 문의 내역은 회사 관리자가 확인 가능합니다.
            </DescriptionList>
          </Description>
          <InquiryTittle>
            <span className="inquiry">
              문의 내용<span className="mark">*</span>
            </span>
            <span className="inquiry-count">({inquiry.length}/300)</span>
          </InquiryTittle>
          <Controller
            control={control}
            name="inquiry"
            render={({ field: { onChange, value } }) => (
              <InquiryTextArea
                placeholder="문의 내용을 작성해주세요."
                value={value}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  if (e.target.value.length < 301) onChange(e);
                }}
              />
            )}
          />
        </StyledModalBody>
        <StyledModalFooter>
          <StyledFooterButton variant="contain" type="submit" size="extraLarge" fullWidth>
            완료
          </StyledFooterButton>
          <StyledFooterButton variant="outline" size="extraLarge" fullWidth onClick={onClose}>
            취소
          </StyledFooterButton>
        </StyledModalFooter>
      </Container>
    </Modal>
  );
};

const Container = styled.form`
  position: absolute;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  width: 440px;
  background-color: ${({ theme }) => theme.colors.White};
  border: 2px solid #fff;
  border-radius: 6px;
  padding: 0px 28px;
`;

const StyledModalHeader = styled.article`
  ${fonts.Headline6}
  color: ${({ theme }) => theme.colors['text-gray-main']};
  padding: 18px 0 16px;
`;

const StyledModalBody = styled.article`
  height: 440px;
`;

const Description = styled.div`
  width: 100%;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors['bg-gray-light']};
  padding: 12px 0 12px 8px;
  margin-top: 4px;
`;

const DescriptionList = styled.div`
  ${fonts.Body4}
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
`;

const InquiryTittle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  .inquiry {
    ${fonts.Headline8}
    color: ${({ theme }) => theme.colors['text-gray-main']};
    .mark {
      color: ${({ theme }) => theme.colors['text-red-light']};
    }
  }
  .inquiry-count {
    ${fonts.Body4}
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const InquiryTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 10px 12px;
  resize: none;
  margin-top: 4px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-radius: 2px;
`;

const StyledModalFooter = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding: 24px 0px 28px;
`;

const StyledFooterButton = styled(Button)`
  flex-shrink: 1;
`;
