import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

interface Props {
  status: string;
  backgroundColor?: string;
  color?: string;
}

export const StatusChip = ({ status, backgroundColor, color }: Props) => {
  return (
    <Container bg={backgroundColor} fontColor={color}>
      {status}
    </Container>
  );
};

const Container = styled(Box)<{ bg?: string; fontColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
  width: fit-content;
  background-color: ${props => props.bg || '#28A745'};
  color: ${props => props.fontColor || '#fff'};
  height: 18px;
  border-radius: 2px;
  ${fonts.Caption4}
`;
