import { Button, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { SoftwareYearPlanCreateRq } from '@type/YearPlan';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as S from './SoftwarePlanCreateStyles';

export const SoftwarePlanCreate = observer(() => {
  const { uiStore, softwareStore, planStore } = useStore();
  const [softwareId, setSoftwareId] = useState<string>('');

  useEffect(() => {
    const rqFetchSoftwares = async () => {
      try {
        await softwareStore.fetchSoftwares();
      } catch (error) {
        console.error('err', error);
      }
    };
    if (uiStore.isVisibleSoftwarePlanCreateModal) {
      rqFetchSoftwares();
    }
  }, [softwareStore, uiStore.isVisibleSoftwarePlanCreateModal]);

  const { register, handleSubmit, reset } = useForm<SoftwareYearPlanCreateRq>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      purchaseAmount: 0,
      salesAmount: 0,
    },
  });

  const validationHandler = {
    success: async (data: SoftwareYearPlanCreateRq) => {
      try {
        const rqData = { ...data };
        rqData.year = uiStore.softwarePlanYear;
        rqData.softwareId = softwareId;
        await planStore.createSoftwarePlan(rqData);
        handleClose();
      } catch (error) {
        console.error('err', error);
      }
    },
    fail: (/* err: any */) => {
      //
    },
  };

  const handleChangeSelect = (e: SelectChangeEvent) => {
    setSoftwareId(e.target.value);
  };

  const handleClose = () => {
    uiStore.closeSoftwareCreateModal();
    setSoftwareId('');
    reset();
  };

  return (
    <Modal open={uiStore.isVisibleSoftwarePlanCreateModal} onClose={handleClose}>
      <S.Container>
        <S.ModalTitleWrapper>
          <Typography variant="h4">Create Software Plan</Typography>
        </S.ModalTitleWrapper>
        <S.ModalContentWrapper>
          <S.ContentElement>
            <Typography>Software</Typography>
            <Select fullWidth size="small" value={softwareId} onChange={handleChangeSelect}>
              {softwareStore.softwares.map(sw => {
                return (
                  <MenuItem key={nanoid()} value={sw.id}>
                    {sw.name}
                  </MenuItem>
                );
              })}
            </Select>
          </S.ContentElement>
          <S.ContentElement>
            <Typography whiteSpace="nowrap">매입 목표</Typography>
            <TextField size="small" fullWidth {...register('purchaseAmount')} />
          </S.ContentElement>
          <S.ContentElement>
            <Typography whiteSpace="nowrap">매출 목표</Typography>
            <TextField size="small" fullWidth {...register('salesAmount')} />
          </S.ContentElement>
        </S.ModalContentWrapper>
        <form onSubmit={handleSubmit(validationHandler.success, validationHandler.fail)}>
          <S.ButtonsWrapper>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="outlined" type="submit">
              Create
            </Button>
          </S.ButtonsWrapper>
        </form>
      </S.Container>
    </Modal>
  );
});
