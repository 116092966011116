import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { PlugIcon } from '@icons/PlugIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';

export const SkeletonUsage = () => {
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();

  return (
    <Container>
      <DateLabel>YYYY.MM.DD 기준</DateLabel>
      <TitleBox>
        <Text>전체</Text>
        <Text>항목 별</Text>
      </TitleBox>
      <ViewBox>
        <TotalView>
          <Donut />
          <TotalSkeleton>
            <FirstFloor>
              <SkeletonBox width={65} height={33} />
              <SkeletonBox width={65} height={24} />
            </FirstFloor>
            <SkeletonBox width={139} height={24} marginTop={12} />
            <SkeletonBox width={139} height={16} marginTop={30} />
            <SkeletonBox width={139} height={16} marginTop={16} />
          </TotalSkeleton>
        </TotalView>
        <ItemView>
          <SkeletonGrid>
            {Array.from({ length: 12 }).map(() => (
              <SkeletonItem key={nanoid()}>
                <dt>
                  <SkeletonBox height={16} />
                </dt>
                <dd>
                  <SkeletonBox height={16} marginTop={4} />
                </dd>
              </SkeletonItem>
            ))}
          </SkeletonGrid>
        </ItemView>
      </ViewBox>
      {subscription.connection?.isConnected ? (
        <SubscriptionConnectMessage>
          <IconWrapper>
            <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
          </IconWrapper>
          해당 서비스에서 제공하지 않는 데이터입니다.
        </SubscriptionConnectMessage>
      ) : (
        <SubscriptionConnectMessage>
          <IconWrapper>
            <PlugIcon width={22} height={22} color={colors['ic-purple-light']} />
          </IconWrapper>
          구독과의 연결이 필요합니다.
        </SubscriptionConnectMessage>
      )}
    </Container>
  );
};
// ? '해당 서비스에서 제공하지 않는 데이터입니다.'
// : '구독과의 연결이 필요합니다.'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DateLabel = styled.div`
  ${fonts.Body2}
  color: ${({ theme }) => theme.colors['text-gray-lighter']};
  margin-bottom: 2px;
`;

const TitleBox = styled.div`
  display: flex;
`;

const Text = styled.span`
  min-width: 638px;
  ${fonts.Headline7}
  color: ${({ theme }) => theme.colors['text-gray-lighter']};
`;

const ViewBox = styled.div`
  display: flex;
`;

const TotalView = styled.div`
  min-width: 580px;
  display: flex;
  padding-left: 58px;
  gap: 60px;
  position: relative;

  ::after {
    content: '';
    display: block;
    width: 1px;
    height: 259px;
    background-color: ${({ theme }) => theme.colors['border-gray-w-lighter']};
    position: absolute;
    right: 0;
    flex-shrink: 0;
  }
`;

const ItemView = styled.div`
  padding-left: 58px;
  width: 100%;
`;

const Donut = styled.div`
  display: inline-block;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  border: 80px solid ${({ theme }) => theme.colors['graph-gray-lighter']};
`;

const SkeletonBox = styled.div<{ width?: number; height: number; marginTop?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => `${height}px`};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light-50']};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : undefined)};
`;

const TotalSkeleton = styled.div`
  display: flex;
  margin-top: 18px;
  flex-direction: column;
`;

const FirstFloor = styled.div`
  display: flex;
  gap: 9px;
  height: 33px;
  flex-wrap: wrap-reverse;
`;

const SkeletonGrid = styled.dl`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1px;
  column-gap: 1px;
  background-color: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  margin-bottom: 72px;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  margin-top: 8px;
`;

const SkeletonItem = styled.div`
  padding: 15px 20px;
  background-color: white;
  height: 72px;
  & > dt {
    padding-right: 67.2%;
  }
  & > dd {
  }
`;

const SubscriptionConnectMessage = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${fonts.Headline7}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const IconWrapper = styled.div`
  padding: 7px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;
