import styled from '@emotion/styled';
import { Box, TableCell } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
`;

export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;
export const ErrorTableCell = styled(TableCell)`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    padding: 28px 0 30px;
    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
      border-radius: 50%;
    }
  }
`;
