import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TableCell, TableRow as MuiTableRow, useMediaQuery, Menu, MenuItem } from '@mui/material';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { PopoverContainer, PopoverRow } from '@components/popover';
import { Popover } from '@components/popover/Popover';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { TriangleIcon } from '@icons/TriangleIcon';
import { LicenseStatus } from '@repositories/subscriptionUserRepository/Types';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useDeleteLicense, useLicenseStatusUpdate } from '@queryHooks/useLicense';
import { dateFormat } from '@utils/dateUtil';
import { LicenseModel } from '@models/LicenseModel';
import { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';
import { LicenseEditorModal } from './LicenseEditorModal';
import { LicenseMultiUserListPopup } from './LicenseMultiUserListPopup';
import { LicenseUserAssignment } from './LicenseUserAssignment';
import { LicenseUserInfoPopup } from './LicenseUserInfoPopup';

type Props = {
  license: LicenseModel;
  isEarliestLicense?: boolean;
  subscriptionConnection?: SubscriptionConnectionModel;
};

export const LicenseRow = ({ license, isEarliestLicense, subscriptionConnection }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    uiStore: { alertStore, toastStore },
  } = useStore();
  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null);
  const [threeDotAnchorEl, setThreeDotAnchorEl] = useState<HTMLButtonElement>();
  const [threeDotSelectLicense, setThreeDotSelectLicense] = useState<LicenseModel>();
  const isScreenWidthReduceCheck = useMediaQuery(theme.breakpoints.down('xxl'));
  const subscription = useSubscriptionModel();
  const [isUpdateLicenseOpen, setIsUpdateLicenseOpen] = useState<boolean>(false);
  const [isAssignmentOpen, setIsAssignmentOpen] = useState<boolean>(false);
  const [isLicenseType, setIsLicenseType] = useState<boolean>(false);
  const [isMultiUsersAnchorEl, setIsMultiUsersAnchorEl] = useState<HTMLTableCellElement | null>(null);
  const [isStatusSelect, setIsStatusSelect] = useState<boolean>(false);

  const { mutate: licenseDelete } = useDeleteLicense();
  const { mutate: licenseStatusUpdate } = useLicenseStatusUpdate();

  const handleOpenUserInfo = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
    if (license.licenseUserType !== 'INDIVIDUAL' && license.subscriptionUsers.length > 1) {
      setIsMultiUsersAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseUserInfo = () => {
    if (license.licenseUserType !== 'INDIVIDUAL' && license.subscriptionUsers.length > 1) {
      setIsMultiUsersAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const handleLicenseRowClick = () => {
    navigate(`${license.licenseId}`);
  };

  const handleThreeDotClick = (event: React.MouseEvent<HTMLButtonElement>, row: LicenseModel) => {
    event.stopPropagation();
    setThreeDotAnchorEl(event.currentTarget);
    setThreeDotSelectLicense(row);
  };

  const handleMenuClose = () => {
    setThreeDotAnchorEl(undefined);
  };

  const handleUpdateAdditionalClick = () => {
    setThreeDotAnchorEl(undefined);
    setIsUpdateLicenseOpen(true);
  };

  const handleUpdateLicenseClose = () => {
    setIsUpdateLicenseOpen(false);
  };

  const handleAssignmentClick = () => {
    setThreeDotAnchorEl(undefined);
    setIsAssignmentOpen(true);
    setIsLicenseType(license.licenseUserType === 'INDIVIDUAL');
  };

  const handleAssignmentClose = () => {
    setIsAssignmentOpen(false);
  };

  const handleDeleteAdditionalClick = () => {
    setThreeDotAnchorEl(undefined);
    alertStore.open({
      title: t('Subscrib_Detail_License_28'),
      message: t('Subscrib_Detail_License_29'),
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      onConfirm: () => {
        if (threeDotSelectLicense) {
          licenseDelete(
            { licenseId: threeDotSelectLicense.licenseId, subscriptionId: subscription.id },
            {
              onSuccess: () => {
                toastStore.open(t('Subscrib_Detail_License_30'));
              },
            },
          );
        }
      },
      useCancel: true,
      type: 'error',
    });
  };

  const licenseStatusCheck: () => LicenseStatus = () => {
    if (!license.isActive) {
      return 'EXPIRED';
    }
    return license.licenseStatus;
  };

  const handleClickStatusBox = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setIsStatusSelect(!isStatusSelect);
  };

  const handleCloseStatusBox = () => {
    setIsStatusSelect(false);
  };

  const handleClickLiceseStatusUpdate = (updateStatus: 'ACTIVE' | 'INACTIVE') => {
    if (updateStatus === license.licenseStatus) return;
    licenseStatusUpdate({ licenseId: license.licenseId, data: updateStatus });
  };

  return (
    <>
      <TableRow onClick={handleLicenseRowClick}>
        <TableCell>{license.licenseCode ? license.licenseCode : '-'}</TableCell>
        <TableCell>
          {license.licenseUserType === 'INDIVIDUAL' ? (
            <Label variant="solid" color="yellow">
              {t('Subscrib_Detail_License_33')}
            </Label>
          ) : (
            <Label variant="solid" color="pink">
              {t('Subscrib_Detail_License_34')}
            </Label>
          )}
        </TableCell>
        <TableCell>{license.plan.planName}</TableCell>
        <TableCell>{license.planOption.optionName}</TableCell>
        <TableCell>
          {dateFormat(license.startDate, '-', t('DateFormat_YMD'))} ~{' '}
          {dateFormat(license.endDate, '-', t('DateFormat_YMD'))}
        </TableCell>
        <TableCell>{license.currencyUnit}</TableCell>
        {license.subscriptionUsers.length > 0 && license.subscriptionUsers[0].userLoginId ? (
          <StyledUserCell>
            <StyledSpan
              onClick={handleOpenUserInfo}
              isOpenUserPopup={license.subscriptionUsers.length > 1 ? isMultiUsersAnchorEl : anchorEl}
            >
              {license.subscriptionUsers[0].user?.name
                ? license.subscriptionUsers[0].user.name
                : license.subscriptionUsers[0].userName}{' '}
              {license.subscriptionUsers.length > 1
                ? `${t('GNB_Profile_MyProfile_05')} ${license.subscriptionUserCount - 1} ${t('Home_People')}`
                : ''}
            </StyledSpan>
          </StyledUserCell>
        ) : (
          <TableCell>-</TableCell>
        )}
        <TableCell sx={{ padding: '0px 4px' }}>
          {(() => {
            switch (licenseStatusCheck()) {
              case 'ACTIVE':
                return (
                  <Popover
                    open={isStatusSelect}
                    onClose={handleCloseStatusBox}
                    popoverContent={
                      <PopoverContainer>
                        <PopoverRow
                          onClick={() => handleClickLiceseStatusUpdate('ACTIVE')}
                          selected={license.licenseStatus === 'ACTIVE'}
                        >
                          <Label variant="solid" color="blue">
                            활성
                          </Label>
                        </PopoverRow>
                        <PopoverRow
                          onClick={() => handleClickLiceseStatusUpdate('INACTIVE')}
                          selected={license.licenseStatus === 'INACTIVE'}
                        >
                          <Label variant="solid" color="gray">
                            비활성
                          </Label>
                        </PopoverRow>
                      </PopoverContainer>
                    }
                  >
                    <StatusBox isStatusSelect={isStatusSelect} onClick={event => handleClickStatusBox(event)}>
                      <Label variant="solid" color="blue">
                        활성
                      </Label>
                      <TriangleIcon width={16} height={16} rotateNum={isStatusSelect ? 0 : 180} />
                    </StatusBox>
                  </Popover>
                );
              case 'EXPIRED':
                return (
                  <StatusBox>
                    <Label variant="solid" color="white">
                      만료
                    </Label>
                  </StatusBox>
                );
              case 'INACTIVE':
                return (
                  <Popover
                    open={isStatusSelect}
                    onClose={handleCloseStatusBox}
                    popoverContent={
                      <PopoverContainer>
                        <PopoverRow
                          onClick={() => handleClickLiceseStatusUpdate('ACTIVE')}
                          selected={license.licenseStatus === 'ACTIVE'}
                        >
                          <Label variant="solid" color="blue">
                            활성
                          </Label>
                        </PopoverRow>
                        <PopoverRow
                          onClick={() => handleClickLiceseStatusUpdate('INACTIVE')}
                          selected={license.licenseStatus === 'INACTIVE'}
                        >
                          <Label variant="solid" color="gray">
                            비활성
                          </Label>
                        </PopoverRow>
                      </PopoverContainer>
                    }
                  >
                    <StatusBox isStatusSelect={isStatusSelect} onClick={event => handleClickStatusBox(event)}>
                      <Label variant="solid" color="gray">
                        비활성
                      </Label>
                      <TriangleIcon width={16} height={16} rotateNum={isStatusSelect ? 0 : 180} />
                    </StatusBox>
                  </Popover>
                );
              default:
                return null;
            }
          })()}
        </TableCell>
        <StyledCell align="center">
          <ThreeDotButton onClick={event => handleThreeDotClick(event, license)}>
            <ThreeDotIcon
              width={18}
              height={18}
              color={threeDotAnchorEl ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']}
            />
          </ThreeDotButton>
        </StyledCell>
      </TableRow>
      {license.subscriptionUsers.length > 0 && (
        <LicenseUserInfoPopup
          anchorEl={anchorEl}
          onClose={handleCloseUserInfo}
          isScreenWidthReduceCheck={isScreenWidthReduceCheck}
          subscriptionId={subscription.id}
          userLoginId={license.subscriptionUsers[0].userLoginId}
        />
      )}
      {license.subscriptionUsers.length > 1 && (
        <LicenseMultiUserListPopup
          anchorEl={isMultiUsersAnchorEl}
          licneseId={license.licenseId}
          onClose={handleCloseUserInfo}
          isScreenWidthReduceCheck={isScreenWidthReduceCheck}
        />
      )}
      <StyledMenu
        anchorEl={threeDotAnchorEl}
        open={!!threeDotAnchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <StyledMenuItem
          onClick={handleAssignmentClick}
          disabled={subscriptionConnection?.isManuallyGetLicense && !subscriptionConnection.isManuallyAddLicense}
        >
          라이선스 사용자 관리
        </StyledMenuItem>
        <StyledMenuItem onClick={handleUpdateAdditionalClick}>{t('Subscrib_Detail_License_27')}</StyledMenuItem>
        <DeleteMenuItem
          onClick={handleDeleteAdditionalClick}
          disabled={
            isEarliestLicense ||
            (subscriptionConnection?.isManuallyGetLicense && !subscriptionConnection.isManuallyAddLicense)
          }
        >
          {t('Subscrib_Detail_License_28')}
        </DeleteMenuItem>
      </StyledMenu>
      <LicenseEditorModal
        subscription={subscription}
        selectLicense={threeDotSelectLicense}
        dialogType="update"
        open={isUpdateLicenseOpen}
        onClose={handleUpdateLicenseClose}
      />
      {isAssignmentOpen && (
        <LicenseUserAssignment
          license={license}
          licenseType={isLicenseType}
          subscriptionConnection={subscriptionConnection}
          open={isAssignmentOpen}
          onClose={handleAssignmentClose}
        />
      )}
    </>
  );
};

const StyledUserCell = styled(TableCell)`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-blue-light']} !important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`;

const StyledCell = styled(TableCell)`
  white-space: nowrap;
`;

const ThreeDotButton = styled(Button)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: none;
    border-radius: 0;
  }
  & .MuiList-root {
    width: 100%;
    min-width: 160px;
    border-radius: 2px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

const DeleteMenuItem = styled(MenuItem)`
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

const StyledSpan = styled.span<{ isOpenUserPopup: HTMLTableCellElement | null }>`
  display: inline-flex;
  padding-right: 8px;
  text-decoration: ${props => (props.isOpenUserPopup ? 'underline' : undefined)};
  &:hover {
    text-decoration: underline;
  }
`;

const StatusBox = styled.div<{ isStatusSelect?: boolean }>`
  padding: 6px 8px 6px 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ isStatusSelect, theme }) =>
    isStatusSelect ? `1px solid ${theme.colors['border-gray-light']}` : undefined};
  border-radius: 2px;
`;
