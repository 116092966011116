import type { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { useGetSubscription } from '@queryHooks/useSubscription';
import { useGetMonthlyUsages } from '@queryHooks/useUsage';
import { useGetMonthlyBillingInfo } from '@queryHooks/useUserMonthlyBilling';
import { dateFormat } from '@utils/dateUtil';
import { DetailSettingStep } from '../../AddExistingBillingModal';
import { BillingMonthSelect } from './BillingMonthSelect';
import { BillingCreate } from './create/BillingCreate';
import { BillingGenerate } from './generate/BillingGenerate';

type Props = {
  subscriptionId: SubscriptionId;
  onClose: () => void;
  step: DetailSettingStep;
  onNextStep: (nextStep: DetailSettingStep) => void;
};

export const DetailSetting = ({ subscriptionId, onClose, step, onNextStep }: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { alertStore },
  } = useStore();
  const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));

  const { data: subscription } = useGetSubscription(subscriptionId);
  const { data: monthlyUsages } = useGetMonthlyUsages(
    {
      subscriptionId,
      monthFrom: dateFormat(selectedMonth, undefined, 'YYYY-MM'),
      monthTo: dateFormat(selectedMonth, undefined, 'YYYY-MM'),
    },
    { enabled: !!selectedMonth },
  );
  const { data: billingInfo } = useGetMonthlyBillingInfo(
    {
      subscriptionId: subscription?.id ?? '',
      month: selectedMonth?.format('YYYY-MM') ?? '',
    },
    {
      enabled: !!subscription?.id && !!selectedMonth,
    },
  );

  const hasUsage = !!monthlyUsages?.content[0];

  const handleNextStepClick = () => {
    if (!selectedMonth) {
      return;
    }

    const isValid = !!billingInfo?.startDate && !!billingInfo?.endDate;
    if (isValid) {
      let nextStep;
      if (step === DetailSettingStep.SELECT_MONTH) {
        nextStep = hasUsage ? DetailSettingStep.GENERATE : DetailSettingStep.CREATE;
      } else {
        nextStep = step + 1;
      }
      onNextStep(nextStep);
    } else {
      alertStore.open({
        title: t('Acc_Create_01'),
        message: t('Acc_Create_33'),
        useCancel: true,
        onConfirm: () => {
          onNextStep(DetailSettingStep.CREATE);
        },
      });
    }
  };

  return (
    <>
      <SubscriptionInfoGrid>
        <AppInfoWrapper>
          <AppIcon src={subscription?.softwareIconUrl} width={56} height={56} />
          <NameWrapper>
            <SoftwareName>{subscription?.software.name}</SoftwareName>
            <SubscriptionName>{subscription?.name}</SubscriptionName>
          </NameWrapper>
        </AppInfoWrapper>
        <SubInfo>
          <dt>{t('Subscrib_Edit_01')}</dt>
          <dd>
            {subscription?.commitmentPeriod} / {subscription?.paymentPeriod}
          </dd>
        </SubInfo>
        <SubInfo>
          <dt>{t('Subscrib_Edit_07')}</dt>
          <dd>{dateFormat(subscription?.paymentDueDate, '-', t('DateFormat_YMD'))}</dd>
        </SubInfo>
      </SubscriptionInfoGrid>
      {step === DetailSettingStep.SELECT_MONTH && (
        <BillingMonthSelect
          selectedMonth={selectedMonth}
          onChange={setSelectedMonth}
          onNextStep={handleNextStepClick}
          onClose={onClose}
        />
      )}
      {(step === DetailSettingStep.GENERATE ||
        step === DetailSettingStep.GENERATE_ATTACH_FILE ||
        step === DetailSettingStep.GENERATE_RESULT) &&
        selectedMonth &&
        subscription &&
        billingInfo && (
          <BillingGenerate
            step={step}
            selectedMonth={selectedMonth}
            subscription={subscription}
            billingInfo={billingInfo}
            onNextStep={handleNextStepClick}
            onClose={onClose}
          />
        )}
      {(step === DetailSettingStep.CREATE || step === DetailSettingStep.CREATE_ATTACH_FILE) &&
        selectedMonth &&
        subscription && (
          <BillingCreate
            step={step}
            selectedMonth={selectedMonth}
            subscription={subscription}
            onNextStep={handleNextStepClick}
            onClose={onClose}
          />
        )}
    </>
  );
};

const SubscriptionInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 88px 72px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  margin: 12px 28px 0px;
`;

const AppInfoWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 0px 28px;
  padding: 17px 0px 13px;
  grid-column: 1 / 3;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
`;

const SubInfo = styled.div`
  margin: 14px 0px;
  padding: 0px 28px;
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  & > dt {
    ${fonts.Body4}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-bottom: 2px;
  }
  & > dd {
    ${fonts.Body2}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }

  &:last-of-type {
    border-right: none;
  }
`;

const AppIcon = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 6px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-bk-opacity-10%']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  width: calc(100% - 56px - 16px);
  flex-grow: 1;
`;

const SoftwareName = styled.div`
  ${fonts.Headline8}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubscriptionName = styled.div`
  ${fonts.Body1}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
