import { Button } from '@components/index';
import styled from '@emotion/styled';
import { Box, TableCell as MuiTableCell, TextField } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const ModalWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  min-height: 644px;
  max-height: 644px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border: 1px solid #fff;
  border-radius: 6px;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: inherit;
  padding: 0px 20px 0px 28px;
`;

export const HeaderTitle = styled(Box)`
  ${fonts.Headline6}
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 12px 28px 0px;
  min-height: 484px;
  max-height: 484px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  & > ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  }
  & li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
    ${fonts.Body2};
    &:last-of-type {
      border-bottom: none;
    }
  }
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 24px 28px;
  border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

export const Candidates = styled(Box)`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 6px 4px;
  width: 100%;
  max-height: 74px;
  margin-bottom: 12px;
  overflow-y: auto;
`;

export const Search = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  width: 168px;
`;

export const TableCell = styled(MuiTableCell)`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  height: 42px;
  :first-of-type {
    padding-left: 16px;
  }
  :last-of-type {
    padding-right: 16px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

export const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;
