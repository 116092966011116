import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { EmptyIcon } from '@icons/EmptyIcon';
import { useGetSubscriptionUseRequests } from '@queryHooks/useSubscriptionUseRequest';
import { List, WgBox, WgContainer, WgSummary, WgTitle } from '../styles';

export const SbUsageRqWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantId } = useParams();
  const navigate = useNavigate();

  const handleClickSeeMore = () => {
    navigate(`/manager/${tenantId}/subscriptions/usage-registers`);
  };

  const handleClickRq = (id: string) => {
    navigate(`/manager/${tenantId}/subscriptions/usage-registers/${id}`);
  };

  const { data: useRqs } = useGetSubscriptionUseRequests({
    status: ['CREATED', 'PENDING'],
    sort: ['createdDate.desc'],
  });

  return (
    <WgContainer width={415}>
      <WgTitle>
        <span>{t('Ad_Home_Use_Request_01')}</span>
        <span className="see-more">
          <Button className="see-more" onClick={handleClickSeeMore} variant="text">
            {t('Home_Add')}
            <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
          </Button>
        </span>
      </WgTitle>
      <WgSummary>
        <span className="count">
          <span className="count-number">{useRqs?.totalElements}</span>
          <span className="counting-unit">{t('Home_People')}</span>
        </span>
      </WgSummary>
      <WgBox width={385}>
        <List>
          {useRqs?.content.length ? (
            useRqs?.content.map(rq => {
              return (
                <Request key={rq.id} onClick={() => handleClickRq(rq.id)}>
                  <span className="meta-info">
                    <span className="user">{rq.userName}</span>
                    <div className="subscription">
                      <Avatar sx={{ width: '16px', height: '16px' }} variant="square" src={rq.productIconUrl} />
                      <span>{rq.productName}</span>
                    </div>
                  </span>
                  <span className="date">
                    <span className="line" />
                    {dayjs().year() === dayjs(rq.createdDate).year()
                      ? dayjs(rq.createdDate).format('MM.DD')
                      : dayjs(rq.createdDate).format(t('DateFormat_YMD'))}
                  </span>
                </Request>
              );
            })
          ) : (
            <Empty>
              <EmptyIcon />
              {t('Home_Empty')}
            </Empty>
          )}
        </List>
      </WgBox>
    </WgContainer>
  );
};

const Request = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-main']};
  :hover {
    cursor: pointer;
  }

  & .meta-info {
    display: flex;
    flex-direction: row;
    :hover {
      text-decoration: underline;
      color: ${props => props.theme.colors['text-purple']};
    }
    & .user {
      width: 64px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    & .subscription {
      word-break: break-all;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 183px;
      padding-left: 21px;
      gap: 6px;
      & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  & .date {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    color: ${props => props.theme.colors['text-gray-light']};
    width: 83px;
    & .line {
      position: absolute;
      left: -1px;
      width: 1px;
      height: 11px;
      background-color: ${props => props.theme.colors['border-gray-light']};
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 100dvh;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;
