import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

export const SmallAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  background-color: transparent;
`;

export const LargeAvatar = styled(Avatar)`
  width: 68px;
  height: 68px;
  background-color: transparent;
`;
