const defaultWidth = 16;
const defaultHeight = 16;

export const AddIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        d="M7.99994 2.95C7.6202 2.95 7.31236 3.25784 7.31236 3.63758V7.31242H3.63758C3.25784 7.31242 2.95 7.62026 2.95 8C2.95 8.37974 3.25784 8.68758 3.63758 8.68758H7.31236V12.3624C7.31236 12.7422 7.6202 13.05 7.99994 13.05C8.37968 13.05 8.68753 12.7422 8.68753 12.3624V8.68758H12.3624C12.7422 8.68758 13.05 8.37974 13.05 8C13.05 7.62026 12.7422 7.31242 12.3624 7.31242H8.68753V3.63758C8.68753 3.25784 8.37968 2.95 7.99994 2.95Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

AddIcon.displayName = 'AddIcon';
