import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { AlertModal } from '@components/alert';
import { useStore } from '@stores/RootStore';
import { OrderModifyModal } from '../cart/orderModifyModal';
import { Alert } from './alert';
import { UserGroupCreate } from './userGroupCreate';

export const Modals = observer(() => {
  const { uiStore } = useStore();

  useEffect(() => {
    const handleCloseAlertStore = () => {
      uiStore.alertStore.close();
    };

    window.addEventListener('popstate', handleCloseAlertStore);

    return () => {
      window.removeEventListener('popstate', handleCloseAlertStore);
    };
  }, [uiStore.alertStore]);

  return (
    <ErrorBoundary>
      {uiStore.userGroupCreateStore.visible ? <UserGroupCreate /> : null}
      {uiStore.orderModifyModalStore.visible && <OrderModifyModal />}
      <Alert />
      <AlertModal />
    </ErrorBoundary>
  );
});
