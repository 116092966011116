import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const WgContainer = styled.div<{ width?: number; height?: number }>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? `${props.width}px` : '306px')};
  height: ${props => (props.height ? `${props.height}px` : '268px')};
  padding: 22px 16px 16px;
  border: 0.5px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 5px;
  background: ${props => props.theme.colors['bg-white']};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
`;

export const WgTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${fonts.Headline12};
  color: ${props => props.theme.colors['text-gray-main']};
  padding: 0px 2px 0px 8px;

  & .see-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${fonts.Body4};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const WgSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  color: ${props => props.theme.colors['text-gray-main']};
  margin-top: 9px;
  padding: 0px 4px 0px 8px;
  & .count {
    display: flex;
    flex-direction: row;
    align-items: end;
    & .count-number {
      ${fonts.Headline3};
      margin-right: 2px;
    }
    & .counting-unit {
      ${fonts.Body1};
      margin-bottom: 5px;
    }
  }
`;

export const WgBox = styled.div<{ width?: number; height?: number; marginTop?: number }>`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '30px')};
  width: ${props => (props.width ? `${props.width}px` : '274px')};
  min-height: ${props => (props.height ? `${props.height}px` : '127px')};
  height: fit-content;
  padding: 13px 0px;
  border-radius: 7px;
  background: ${props => props.theme.colors['bg-gray-light-50']};
  &.padding {
    padding: 13px 16px 13px 8px;
  }
`;

export const List = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  padding: 0px 16px;
`;
