import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { subscriptionUserRepository } from '@repositories/Repository';
import {
  TotalSubscriptionUserCountDTO,
  TotalSubscriptionUserCountFilter,
  TotalSubscriptionUserListFilter,
  UserLicenseListFilter,
  UserSubscriptionListWithCanRevokeFilter,
} from '@repositories/subscriptionUserRepository/Types';
import { Page } from '@type/Page';
import { UserSubscriptionDTOModel } from '@models/UserSubscriptionDTOModel';
import { TotalSubscriptionUserListModel } from '@models/subscription/TotalSubscriptionUserListModel';

export const sbUserLicenseKeys = {
  userLicenseList: (queries: UserLicenseListFilter) => ['userLicenses', queries] as const,
  userSubscriptionListWithCanRevoke: (queries: UserSubscriptionListWithCanRevokeFilter) =>
    ['userSubscriptionList', queries] as const,
};

export const sbTotalUserListKeys = {
  all: ['subscription'] as const,
  lists: () => [...sbTotalUserListKeys.all, 'list'] as const,
  list: (queries?: TotalSubscriptionUserListFilter) => [...sbTotalUserListKeys.lists(), queries] as const,
  listInfinite: (queries?: TotalSubscriptionUserListFilter) =>
    [...sbTotalUserListKeys.list(queries), 'infinite'] as const,
};

export const sbTotalUserCountKeys = {
  all: ['userCount'] as const,
  lists: () => [...sbTotalUserCountKeys.all, 'list'] as const,
  list: (queries?: TotalSubscriptionUserCountFilter) => [...sbTotalUserCountKeys.lists(), queries] as const,
};

export const useSubscriptionUserLicenses = (
  queries: UserLicenseListFilter,
  options?: UseQueryOptions<Page<UserSubscriptionDTOModel>>,
) =>
  useQuery({
    queryKey: sbUserLicenseKeys.userLicenseList(queries),
    queryFn: async () => {
      const licenses = await subscriptionUserRepository.getUserLicenseList(queries);
      return { ...licenses, content: licenses.content.map(license => new UserSubscriptionDTOModel(license)) };
    },
    ...options,
  });

export const useTotalSubscriptionUser = (
  queries: TotalSubscriptionUserListFilter,
  options?: UseInfiniteQueryOptions<Page<TotalSubscriptionUserListModel>>,
) => {
  return useInfiniteQuery<Page<TotalSubscriptionUserListModel>>({
    queryKey: sbTotalUserListKeys.listInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await subscriptionUserRepository.getTotalSubscriptionUserList({ ...queries, page: pageParam });
      return { ...result, content: result.content.map(dto => new TotalSubscriptionUserListModel(dto)) };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });
};

export const useTotalSubscriptionUserCount = (
  queries: TotalSubscriptionUserCountFilter,
  options?: UseQueryOptions<TotalSubscriptionUserCountDTO>,
) => {
  return useQuery({
    queryKey: sbTotalUserCountKeys.list(queries),
    queryFn: async () => {
      const result = await subscriptionUserRepository.getTotalSubscriptionUserCount({ ...queries });
      return result;
    },
    ...options,
  });
};

export const useSubscriptionUserListWithCanRevoke = (
  queries: UserSubscriptionListWithCanRevokeFilter,
  options?: UseQueryOptions<UserSubscriptionDTOModel[]>,
) =>
  useQuery({
    queryKey: sbUserLicenseKeys.userSubscriptionListWithCanRevoke(queries),
    queryFn: async () => {
      const subscriptionLists = await subscriptionUserRepository.getUserSubscriptionListWithCanRevoke(queries);
      return subscriptionLists.map(subscriptionList => new UserSubscriptionDTOModel(subscriptionList));
    },
    ...options,
  });
