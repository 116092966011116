import styled from '@emotion/styled';
import Slider from 'react-slick';

export const StyledSlider = styled(Slider)`
  width: 100%;
  /* height: 100%; */

  & .slick-dots {
    bottom: 24px;

    & li {
      width: 32px;
      height: 3px;
      margin: 4px;

      & button,
      & button::before {
        width: 100%;
        height: 100%;
        padding: 0px;
        border-radius: 3px;
        content: unset;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    & li.slick-active {
      & button,
      & button::before {
        width: 100%;
        height: 100%;
        padding: 0px;
        border-radius: 3px;
        content: unset;
        background-color: #ffffff;
      }
    }
  }

  & button {
    margin: 0px;
    padding: 0px;
    border: none;
    cursor: pointer;
  }
`;
StyledSlider.displayName = 'StyledSlider';
