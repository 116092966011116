import { IColorSet, colors } from './theme/colorsCustomer';

// @deprecated

interface OpacityColor {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export interface IColorSetDeprecated {
  Text: {
    'text-gray-main': string;
  };

  // @deprecated
  Black: string;
  D_Grey: string;
  Grey: string;
  L_Grey: string;
  Grey_blue: string;
  Silver: string;
  White: string;
  D_Blue: string;
  Navy: string;
  M_Blue: string;
  Azure: string;
  Sky: string;
  L_Blue: string;
  Line: string;
  D_Green: string;
  Forest: string;
  M_Green: string;
  Herbal: string;
  Mint: string;
  L_Green: string;
  D_Yellow: string;
  Lemon: string;
  M_Yellow: string;
  Straw: string;
  Sand: string;
  L_Yellow: string;
  D_Red: string;
  Scarlet: string;
  M_Red: string;
  Raspberry: string;
  Rubicund: string;
  L_Red: string;
  Neutral: string;

  grey: OpacityColor;
  primary: OpacityColor;
  greenAccent: OpacityColor;
  redAccent: OpacityColor;
  blueAccent: OpacityColor;
}

// FIXME: 제거하고 theme에서 src/theme/colorsCustomer의 colors 사용하도록 수정 필요
export const ColorSet: { dark: IColorSetDeprecated & IColorSet; light: IColorSetDeprecated & IColorSet } = {
  dark: {
    ...colors.dark,
    // @deprecated
    Text: {
      'text-gray-main': '#24292E',
    },
    Black: '#212121',
    D_Grey: '#3A3A49',
    Grey: '#5C5C6B',
    L_Grey: '#878795',
    Grey_blue: '#E9E9ED',
    Silver: '#FAFAFB',
    White: '#111111',
    D_Blue: '#2146AD',
    Navy: '#2254DE',
    M_Blue: '#4676FB',
    Azure: '#7593FA',
    Sky: '#ADC0F8',
    L_Blue: '#F2F5FF',
    Line: '#E9EFF4',
    D_Green: '#115B43',
    Forest: '#1B6E53',
    M_Green: '#009262',
    Herbal: '#32C997',
    Mint: '#84DFC1',
    L_Green: '#F1FBF8',
    D_Yellow: '#E48900',
    Lemon: '#FFA826',
    M_Yellow: '#FFB240',
    Straw: '#FFC670',
    Sand: '#FFD596',
    L_Yellow: '#FFF8EC',
    D_Red: '#C43025',
    Scarlet: '#E01507',
    M_Red: '#E02B1D',
    Raspberry: '#FF5A4F',
    Rubicund: '#F0857D',
    L_Red: '#FFF1F0',
    Neutral: '#FFFFFF',
    grey: {
      100: '#e0e0e0',
      200: '#c2c2c2',
      300: '#a3a3a3',
      400: '#858585',
      500: '#666666',
      600: '#525252',
      700: '#3d3d3d',
      800: '#292929',
      900: '#141414',
    },
    primary: {
      100: '#d0d1d5',
      200: '#a1a4ab',
      300: '#727681',
      400: '#101624',
      500: '#141b2d',
      600: '#101624',
      700: '#0c101b',
      800: '#080b12',
      900: '#040509',
    },
    greenAccent: {
      100: '#dbf5ee',
      200: '#b7ebde',
      300: '#94e2cd',
      400: '#70d8bd',
      500: '#4cceac',
      600: '#3da58a',
      700: '#2e7c67',
      800: '#1e5245',
      900: '#0f2922',
    },
    redAccent: {
      100: '#f8dcdb',
      200: '#f1b9b7',
      300: '#e99592',
      400: '#e2726e',
      500: '#db4f4a',
      600: '#af3f3b',
      700: '#832f2c',
      800: '#58201e',
      900: '#2c100f',
    },
    blueAccent: {
      100: '#e1e2fe',
      200: '#c3c6fd',
      300: '#a4a9fc',
      400: '#868dfb',
      500: '#6870fa',
      600: '#535ac8',
      700: '#3e4396',
      800: '#2a2d64',
      900: '#151632',
    },
  },
  light: {
    ...colors.light,
    // @deprecated
    Text: {
      'text-gray-main': '#24292E',
    },
    Black: '#212121',
    D_Grey: '#3A3A49',
    Grey: '#5C5C6B',
    L_Grey: '#878795',
    Grey_blue: '#E9E9ED',
    Silver: '#FAFAFB',
    White: '#FFFFFF',
    D_Blue: '#2146AD',
    Navy: '#2254DE',
    M_Blue: '#4676FB',
    Azure: '#7593FA',
    Sky: '#ADC0F8',
    L_Blue: '#F2F5FF',
    Line: '#E9EFF4',
    D_Green: '#115B43',
    Forest: '#1B6E53',
    M_Green: '#009262',
    Herbal: '#32C997',
    Mint: '#84DFC1',
    L_Green: '#F1FBF8',
    D_Yellow: '#E48900',
    Lemon: '#FFA826',
    M_Yellow: '#FFB240',
    Straw: '#FFC670',
    Sand: '#FFD596',
    L_Yellow: '#FFF8EC',
    D_Red: '#C43025',
    Scarlet: '#E01507',
    M_Red: '#E02B1D',
    Raspberry: '#FF5A4F',
    Rubicund: '#F0857D',
    L_Red: '#FFF1F0',
    Neutral: '#FFFFFF',
    grey: {
      100: '#141414',
      200: '#292929',
      300: '#3d3d3d',
      400: '#525252',
      500: '#666666',
      600: '#858585',
      700: '#a3a3a3',
      800: '#c2c2c2',
      900: '#e0e0e0',
    },
    primary: {
      100: '#040509',
      200: '#080b12',
      300: '#0c101b',
      400: '#FAFCFF',
      500: '#141b2d',
      600: '#434957',
      700: '#727681',
      800: '#a1a4ab',
      900: '#d0d1d5',
    },
    greenAccent: {
      100: '#0f2922',
      200: '#1e5245',
      300: '#2e7c67',
      400: '#3da58a',
      500: '#4cceac',
      600: '#70d8bd',
      700: '#94e2cd',
      800: '#b7ebde',
      900: '#dbf5ee',
    },
    redAccent: {
      100: '#2c100f',
      200: '#58201e',
      300: '#832f2c',
      400: '#af3f3b',
      500: '#db4f4a',
      600: '#e2726e',
      700: '#e99592',
      800: '#f1b9b7',
      900: '#f8dcdb',
    },
    blueAccent: {
      100: '#151632',
      200: '#2a2d64',
      300: '#3e4396',
      400: '#535ac8',
      500: '#6870fa',
      600: '#868dfb',
      700: '#a4a9fc',
      800: '#c3c6fd',
      900: '#e1e2fe',
    },
  },
};

// from https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const opacityMap = {
  '100': 'FF',
  '90': 'E6',
  '80': 'CC',
  '70': 'B3',
  '60': '99',
  '50': '80',
  '40': '66',
  '30': '4D',
  '20': '33',
  '15': '26',
  '10': '1A',
  '6': '0F',
  '5': '0D',
  '0': '00',
};
