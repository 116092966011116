import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Popover, useMediaQuery, useTheme } from '@mui/material';
import { colors } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { XIcon } from '@icons/XIcon';
import { SubscriptionUserDTO } from '@repositories/subscriptionRepository';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetLicenseUserList } from '@queryHooks/useLicense';
import { LicenseUserInfoPopup } from './LicenseUserInfoPopup';

type Props = {
  anchorEl: HTMLElement | null;
  licneseId: string;
  onClose: () => void;
  isScreenWidthReduceCheck: boolean;
};

export const LicenseMultiUserListPopup = ({ anchorEl, licneseId, onClose, isScreenWidthReduceCheck }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const subscription = useSubscriptionModel();
  const [userLoginId, setUserLoginId] = useState<string>('');
  const [anchorElUser, setAnchorElUser] = useState<HTMLDivElement | null>(null);
  const isScreenWidthReduceCheckUser = useMediaQuery('(max-width: 3000px)');

  const { data: licenseUserList } = useGetLicenseUserList(licneseId, subscription.id, { enabled: !!anchorEl });

  const handleUserInfoOpen = (event: React.MouseEvent<HTMLDivElement>, loginId: string) => {
    setAnchorElUser(event.currentTarget);
    setUserLoginId(loginId);
  };

  const handleCloseUserInfo = () => {
    setAnchorElUser(null);
    setUserLoginId('');
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isScreenWidthReduceCheck ? 'left' : 'right',
      }}
      transformOrigin={{ vertical: 'top', horizontal: isScreenWidthReduceCheck ? 'right' : 'left' }}
      PaperProps={{
        sx: {
          borderRadius: '6px',
          boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);',
        },
      }}
    >
      <UserListContainer>
        <UserHeader>
          <UserTitle>{t('Subscrib_Detail_Overview_16')}</UserTitle>
          <IconButton onClick={onClose}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </IconButton>
        </UserHeader>
        <UserTotalBox>
          <TotalText>{t('Acc_Main_12')}</TotalText>
          <TotalNumber>{licenseUserList?.length}</TotalNumber>
        </UserTotalBox>
        <UserListBox>
          <ul>
            {licenseUserList?.map((item: SubscriptionUserDTO) => (
              <UserBox
                key={item?.userLoginId}
                style={{
                  backgroundColor:
                    item.userLoginId === userLoginId
                      ? colors.light['state-white-purple-pressed']
                      : colors.light['bg-white'],
                }}
              >
                <UserInfoBox onClick={event => handleUserInfoOpen(event, item.userLoginId)}>
                  <UserText
                    style={{
                      color:
                        item.userLoginId === userLoginId ? colors.light['text-purple'] : colors.light['text-gray-main'],
                    }}
                  >{`${item?.user?.name || item.userName} (${item?.user?.email || item.userLoginId})`}</UserText>
                </UserInfoBox>
              </UserBox>
            ))}
          </ul>
        </UserListBox>
        <LicenseUserInfoPopup
          anchorEl={anchorElUser}
          onClose={handleCloseUserInfo}
          isScreenWidthReduceCheck={isScreenWidthReduceCheckUser}
          subscriptionId={subscription.id}
          userLoginId={userLoginId}
          multi
        />
      </UserListContainer>
    </Popover>
  );
};

const UserListContainer = styled.div`
  padding: 18px 2px 36px 28px;
  width: 400px;
  height: 512px;
  flex-shrink: 0;
`;

const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UserTitle = styled.span`
  ${fonts.Headline6}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const UserTotalBox = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 4px;
  width: 344px;
  height: 27px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

const TotalText = styled.span`
  ${fonts.Headline8}
`;

const TotalNumber = styled.span`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-purple-light']};
`;

const UserListBox = styled.div`
  height: 378px;
  padding-right: 28px;
  overflow: scroll;
`;

const UserBox = styled.div`
  & :hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
  }
`;

const UserInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 16px;
  width: 344px;
  height: 42px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  cursor: pointer;
`;

const UserText = styled.span`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
`;
