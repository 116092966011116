import { ForwardedRef, forwardRef } from 'react';

const defaultWidth = 24;
const defaultHeight = 24;

export const QuestionIcon = forwardRef(
  (
    { width = defaultWidth, height = defaultHeight, color = '#24292E', ...props }: SvgProps,
    ref: ForwardedRef<SVGSVGElement>,
  ) => (
    <svg
      // Mui Tooltip 사용할 때 aria-label 등의 props도 전달받기 위해 {...props} 사용
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.799805 12C0.799805 5.8144 5.81422 0.799988 11.9998 0.799988C18.1854 0.799988 23.1998 5.8144 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12ZM11.9998 2.49999C6.7531 2.49999 2.4998 6.75328 2.4998 12C2.4998 17.2467 6.7531 21.5 11.9998 21.5C17.2465 21.5 21.4998 17.2467 21.4998 12C21.4998 6.75328 17.2465 2.49999 11.9998 2.49999Z"
        fill={color}
      />
      <path
        d="M10.9084 7.76466C10.6886 7.91117 10.5327 8.11188 10.421 8.33541C10.2357 8.70589 9.78524 8.85606 9.41475 8.67082C9.04427 8.48558 8.8941 8.03507 9.07934 7.66459C9.28008 7.26312 9.59296 6.83883 10.0763 6.51659C10.5644 6.19121 11.1814 6 11.9377 6C12.5954 6 13.3066 6.19469 13.872 6.61875C14.4575 7.05783 14.8752 7.7399 14.8752 8.625C14.8752 9.65836 14.3624 10.3447 13.8484 10.8404C13.6592 11.0229 13.45 11.1976 13.2696 11.3483C13.218 11.3914 13.1685 11.4328 13.1228 11.4715C12.9402 11.6263 12.7976 11.756 12.6877 11.88V13.25C12.6877 13.6642 12.3519 14 11.9377 14C11.5235 14 11.1877 13.6642 11.1877 13.25V11.7765C11.1877 11.5403 11.2545 11.2726 11.4349 11.0413C11.6538 10.7607 11.9203 10.5245 12.1528 10.3274C12.2164 10.2734 12.2772 10.2227 12.3356 10.1738L12.3366 10.173C12.5082 10.0295 12.6602 9.90252 12.8071 9.76078C13.1745 9.40641 13.3752 9.08472 13.3752 8.625C13.3752 8.26009 13.2199 8.00467 12.972 7.81875C12.7041 7.61781 12.3216 7.5 11.9377 7.5C11.4439 7.5 11.1234 7.62129 10.9084 7.76466Z"
        fill={color}
      />
      <path
        d="M12.9376 16.5C12.9376 17.0523 12.4899 17.5 11.9376 17.5C11.3853 17.5 10.9376 17.0523 10.9376 16.5C10.9376 15.9477 11.3853 15.5 11.9376 15.5C12.4899 15.5 12.9376 15.9477 12.9376 16.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8875 11.958C12.9809 11.8602 13.0997 11.7532 13.252 11.6241C13.297 11.5859 13.346 11.545 13.3976 11.5018L13.3999 11.5C13.579 11.3504 13.7927 11.1719 13.9871 10.9844C14.5205 10.4699 15.075 9.73502 15.075 8.62499C15.075 7.67037 14.6222 6.93146 13.9919 6.45874C13.3868 6.0049 12.6317 5.79999 11.9375 5.79999C11.1463 5.79999 10.4897 6.00051 9.96523 6.35017C9.4467 6.69586 9.11289 7.14997 8.90031 7.57513C8.66567 8.04441 8.85588 8.61505 9.32516 8.84969C9.79444 9.08433 10.3651 8.89412 10.5997 8.42484C10.6996 8.22512 10.8345 8.05418 11.0192 7.93106C11.1979 7.81192 11.4788 7.69999 11.9375 7.69999C12.2848 7.69999 12.6236 7.80749 12.8519 7.97874C13.0544 8.13064 13.175 8.32902 13.175 8.62499C13.175 9.0086 13.0156 9.28169 12.6681 9.61682C12.5272 9.75275 12.3808 9.87521 12.2083 10.0194M12.2083 10.0194L12.2073 10.0202C12.1489 10.069 12.0875 10.1204 12.0233 10.1748C11.7896 10.373 11.5095 10.6203 11.2771 10.9183C11.0647 11.1906 10.9875 11.5039 10.9875 11.7765V13.25C10.9875 13.7747 11.4128 14.2 11.9375 14.2C12.4622 14.2 12.8875 13.7747 12.8875 13.25V11.958M13.1375 16.5C13.1375 17.1627 12.6002 17.7 11.9375 17.7C11.2747 17.7 10.7375 17.1627 10.7375 16.5C10.7375 15.8372 11.2747 15.3 11.9375 15.3C12.6002 15.3 13.1375 15.8372 13.1375 16.5Z"
        fill={color}
      />
    </svg>
  ),
);

QuestionIcon.displayName = 'QuestionIcon';
