import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, useTheme } from '@mui/material';
import { Cell, Label, Pie, PieChart, Tooltip as RechartsTooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { Dropdown } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { TenantPaymentStatAggregateFilter } from '@repositories/tenantBillingStatRepository/Types';
import { useGetTenantPaymentStat } from '@queryHooks/useTenantBillingStat';
import { numberFormat } from '@utils/numberFormat';
import i18n from '@locales/i18n';
import { SmallCardContent } from '../SmallCardContent';
import { CardWgContainer, CardWgContents, PieChartContainer } from '../styles';

type PieChartData = {
  share: number;
  sbName: string;
  currencyUnit: CurrencyUnit;
  cost: number;
  iconUrl: string | undefined;
};

export const Cost5Wg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();

  const [valueMaxWidth, setValueMaxWidth] = useState(0);
  const [filter, setFilter] = useState<TenantPaymentStatAggregateFilter>({
    period: 'YEAR',
    type: 'PRODUCT',
    tenantId: params.tenantId,
  });
  const [activeIdx, setActiveIdx] = useState<number | undefined>(undefined);

  const { data: paymentsStat, isSuccess } = useGetTenantPaymentStat(filter);

  const totalCost = isSuccess
    ? paymentsStat?.reduce((acc, cur) => {
        return acc + cur.cost;
      }, 0)
    : 0;

  const calcPercentage = (value: number) => {
    return parseFloat(((value / totalCost) * 100).toFixed(2));
  };

  const chartData: PieChartData[] =
    paymentsStat
      ?.map(payment => {
        return {
          cost: payment.cost,
          sbName: payment.name,
          share: calcPercentage(payment.cost),
          currencyUnit: payment.currencyUnit,
          iconUrl: payment.iconUrl,
        };
      })
      .sort((a, b) => (a.sbName === 'ETC' ? 1 : b.sbName === 'ETC' ? -1 : b.cost - a.cost)) ?? [];

  const handleChangeDate = (value: string | number) => {
    if (value === 'YEAR' || value === 'MONTH') {
      setFilter(prev => {
        return { ...prev, period: value };
      });
    }
  };

  const handleChangeType = (value: string | number) => {
    if (value === 'PRODUCT' || value === 'SUBSCRIPTION') {
      setFilter(prev => {
        return { ...prev, type: value };
      });
    }
  };

  const renderTooltip = ({ payload }: TooltipProps<ValueType, NameType>) => {
    if (Array.isArray(payload) && payload.length > 0) {
      const targetData: PieChartData = payload[0].payload;

      return (
        <TooltipContainer>
          <div className="sb-info">
            <Avatar
              sx={{ width: '16px', height: '16px', borderRadius: '2px' }}
              variant="square"
              src={targetData.iconUrl}
            />
            {targetData.sbName}
          </div>
          <div className="cost">{`(${targetData.currencyUnit || 'KRW'})${numberFormat({
            num: targetData.cost,
          })}`}</div>
        </TooltipContainer>
      );
    }

    return null;
  };

  const handleMouseEnter = (data: any, index: number) => {
    setActiveIdx(index);
  };

  const colorSet = [
    theme.colors['graph-purple-light'],
    theme.colors['graph-blue-light'],
    theme.colors['graph-orange'],
    theme.colors['graph-green-light'],
    theme.colors['graph-yellow'],
    theme.colors['graph-gray-dark'],
  ];

  const total = chartData.reduce((acc, data) => {
    return acc + data.cost;
  }, 0);

  const currencyUnit = chartData[0]?.currencyUnit || 'KRW';

  const customLabel = (props: any) => {
    const { viewBox } = props;
    return (
      <>
        <rect
          x={viewBox.cx - 18.5}
          y={viewBox.cy - 22}
          width={37}
          height={20}
          stroke={theme.colors['border-gray-light']}
          strokeWidth={1}
          fill={theme.colors['bg-white']}
          rx={2}
          ry={2}
        />
        <text x={viewBox.cx} y={viewBox.cy - 12} textAnchor="middle" dominantBaseline="central">
          <CurrencyTspan>{currencyUnit}</CurrencyTspan>
        </text>
        <text x={viewBox.cx} y={viewBox.cy + 12} textAnchor="middle" dominantBaseline="central">
          <LabelTspan>{numberFormat({ num: total })}</LabelTspan>
        </text>
      </>
    );
  };

  return (
    <CardWgContainer>
      <div className="title">
        비용 TOP 5
        <DropdownWrapper>
          <Dropdown
            variant="no-line"
            size="small"
            value={filter.period}
            onChange={handleChangeDate}
            width={i18n.language === 'ko' ? '79px' : '112px'}
          >
            <Dropdown.Menu value="YEAR">{t('Acc_Main_11')}</Dropdown.Menu>
            <Dropdown.Menu value="MONTH">{t('Acc_Main_09')}</Dropdown.Menu>
          </Dropdown>
          <Dropdown
            variant="no-line"
            size="small"
            value={filter.type}
            onChange={handleChangeType}
            width={i18n.language === 'ko' ? '95px' : '109px'}
          >
            <Dropdown.Menu value="PRODUCT">{t('Ad_Analysis_fileter_01')}</Dropdown.Menu>
            <Dropdown.Menu value="SUBSCRIPTION">{t('Ad_Analysis_fileter_02')}</Dropdown.Menu>
          </Dropdown>
        </DropdownWrapper>
      </div>
      <PieChartContainer>
        <PieChart width={600} height={250}>
          <RechartsTooltip content={renderTooltip} />
          <Pie
            data={chartData}
            dataKey="share"
            innerRadius={60}
            outerRadius={100}
            activeIndex={activeIdx}
            activeShape={{
              innerRadius: 58,
              outerRadius: 102,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setActiveIdx(undefined)}
            stroke="none"
            strokeWidth={0}
            startAngle={90}
            endAngle={-270}
          >
            {chartData.map((data, idx) => {
              return (
                <Cell
                  fill={colorSet[idx]}
                  key={String(data)}
                  style={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.09)' }}
                />
              );
            })}
            <Label
              position="center"
              fontSize={14}
              fill={theme.colors['text-gray-main']}
              style={{ whiteSpace: 'pre-wrap' }}
              content={customLabel}
            />
          </Pie>
        </PieChart>
      </PieChartContainer>
      <CardWgContents>
        {chartData?.map((payment, idx) => (
          <SmallCardContent
            key={payment.sbName + payment.cost.toString()}
            valueRef={ref => {
              if (ref) {
                setValueMaxWidth(prev => Math.max(ref.clientWidth, prev));
              }
            }}
            valueMaxWidth={valueMaxWidth}
            iconColor={colorSet[idx]}
            name={payment.sbName}
            share={payment.share}
            value={payment.cost}
            currencyUnit={payment.currencyUnit}
          />
        ))}
      </CardWgContents>
    </CardWgContainer>
  );
};

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 198px;
  padding: 12px 16px 16px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-main']};
  border-radius: 3px;
  gap: 18px;
  & .sb-info {
    display: flex;
    align-items: center;
    gap: 6px;
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-light']};
  }
  & .cost {
    ${fonts.Headline7};
    color: ${props => props.theme.colors['text-white']};
  }
`;

const CurrencyTspan = styled.tspan`
  ${fonts.Caption4};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const LabelTspan = styled.tspan`
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const DropdownWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
