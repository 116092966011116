import { useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { IconButton } from '@components/buttons';
import { YearlyBillingChart } from '@pages/customer/manager/billing/dashboard/YearlyBillingChart';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { SquareFillIcon } from '@icons/SquareFillIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';

export const BillingLineChart = () => {
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();

  const [year, setYear] = useState(dayjs().year());

  const handleYearChangeClick = (diff: 1 | -1) => () => {
    setYear(prev => prev + diff);
  };

  return (
    <ChartWrapper>
      <Header>
        <YearSelector>
          <IconButton variant="outline" size="extraSmall" onClick={handleYearChangeClick(-1)}>
            <ChevronIcon rotateNum={-90} />
          </IconButton>
          <Year>{year}</Year>
          <IconButton variant="outline" size="extraSmall" onClick={handleYearChangeClick(1)}>
            <ChevronIcon rotateNum={90} />
          </IconButton>
        </YearSelector>
        <Legend>
          <SquareFillIcon width={20} height={20} color={colors['graph-gray']} />
          <span>예측 금액</span>
        </Legend>
      </Header>
      <YearlyBillingChart barSize={30} height={414} year={year} subscriptionId={subscription.id} />
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  padding: 23px 32px 22px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const YearSelector = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
`;

const Year = styled.span`
  ${fonts.Headline12};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  ${fonts.Body4}

  & > span {
    margin: 0px 24px 0px 4px;
  }
`;
