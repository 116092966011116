import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { OptimizableCost } from './optimizableCost/OptimizableCost';
import { CostSummary } from './summary/CostSummary';

export const CostAnalyze = () => {
  return (
    <Container>
      <Title>진단</Title>
      <CostSummary />

      <SubTitle>줄일 수 있는 금액</SubTitle>
      <OptimizableCost />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow-y: auto;
  padding: 28px 32px 40px;
`;

const Title = styled.h2`
  padding: 0px 8px;
  ${fonts.Headline5};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 24px;
`;

const SubTitle = styled.h3`
  ${fonts.Headline6}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin: 0px 8px 16px;
`;
