import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Fade, Menu, MenuItem, Typography, popoverClasses, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { SmallAvatar } from '@components/avatar';
import { Button } from '@components/index';
import { RoutingTabs } from '@components/tab';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import logoImg from '@images/megaFitLogo.png';
import TailImg from '@images/tail.png';
import {
  PlusIcon,
  SmpLogoIcon,
  DropdownIcon,
  PersonIcon,
  LogoutIcon,
  ProfileIcon,
  MobileLogoIcon,
} from '@icons/index';
import localStorage from '@repositories/browserStorage/localStorage/LocalStorage';
import { useGetMyTenantList } from '@queryHooks/useTenant';
import { useLogout } from '@queryHooks/useUser';
import type { TenantSimpleModel } from '@models/tenantModels';
import i18n from '../../../../locales/i18n';
import { MobileGnb } from './MobileGnb';
import { MyProfileModal } from './myProfileModal';

const profileStyles = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.06);',
    borderRadius: '3px',
    border: '1px solid #e1e4e8',
    '& .MuiList-root': {
      padding: '6px',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '3px',
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -6,
      right: 40,
      width: 14,
      height: 7,
      bgcolor: 'background.paper',
      background: `url(${TailImg}) 0 0 no-repeat`,
      backgroundSize: '14px 7px',
      webkitBackgroundSize: '14px 7px',
      zIndex: 0,
    },
  },
};

const itAdminStyles = {
  elevation: 0,
  sx: {
    width: '210px',
    overflow: 'visible',
    backgroundColor: 'transparent',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.06);',
    borderRadius: '3px',
    border: '1px solid #e1e4e8',
    '& .MuiMenu-list': {
      padding: '6px',
      marginBottom: '36px',
      zIndex: '1001',
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '& .Mui-focusVisible': {
      background: '#F3F0FA',
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -7,
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: 14,
      height: 7,
      bgcolor: 'background.paper',
      background: `url(${TailImg}) 0 0 no-repeat`,
      backgroundSize: '14px 7px',
      webkitBackgroundSize: '14px 7px',
      zIndex: 0,
    },
    '& .MuiList-root': {
      boxShadow: 'none',
      border: 'none',
      borderRadius: '1.5px 1.5px 0 0',
    },
  },
};

export const TopGnb = observer(() => {
  const theme = useTheme();
  const {
    authStore,
    uiStore: { profileModalStore: modal },
  } = useStore();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLangOpen, setIsLangOpen] = useState<boolean>(false);

  const tenantMenuOwnerRef = useRef<HTMLDivElement>(null);
  const [isTenantMenuOpen, setIsTenantMenuOpen] = useState(false);

  const profileMenuOwnerRef = useRef<HTMLDivElement>(null);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const handleTenantSelectHover = () => {
    setIsTenantMenuOpen(true);
  };

  const handleTenantSelectLeave = () => {
    setIsTenantMenuOpen(false);
  };

  const handleProfileHover = () => {
    setIsProfileMenuOpen(true);
  };

  const handleProfileLeave = () => {
    setIsProfileMenuOpen(false);
  };

  const { data: myTenants } = useGetMyTenantList(
    { hasAdminRole: true },
    { enabled: authStore.isItAdmin && authStore.curTenant.id !== '' },
  );
  const { logout } = useLogout();

  const handleClickLoginBtn = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleIntroduceInquiryClick = () => {
    navigate('/tenant-request');
  };

  const handleClickManagerConsole = (tenant: TenantSimpleModel) => {
    window.open(`/manager/${tenant.id}`, '_blank');
  };

  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    dayjs.locale(lang);
    localStorage.set('lang', lang);
    document.cookie = `SMP_lang=${lang}; path=/; secure;`;
  };

  return (
    <Container>
      {matches ? (
        <>
          <LogoLinkWrap to="/store">
            <MobileLogoIcon />
          </LogoLinkWrap>
          {/* 전체검색 임시 삭제 */}
          {/* <S.RightElementWrapper>
            <S.GnbIconButton onClick={() => navigate('/search')}>
              <SearchIcon width={28} height={28} />
            </S.GnbIconButton>
          </S.RightElementWrapper> */}
          <MobileGnb />
        </>
      ) : (
        <>
          <LogoLinkWrap to="/store">
            <img src={logoImg} alt="fit" style={{ width: 120, height: 31 }} />
            {/* <SmpLogoIcon /> */}
          </LogoLinkWrap>
          <MenusWrapper>
            <StyledLink
              variant="line"
              tabList={[
                { name: t('GNB_Menu_01'), path: '/introduction' },
                { name: `${t('Ad_GNB_Menu_01')}`, path: '/store' },
                { name: `MY ${t('Workflow_Create_68')}`, path: '/subscriptions' },
                // { name: '고객지원', path: '/support' },
              ]}
            />
          </MenusWrapper>
          <UserButtonWrapper>
            {/* 전체검색 임시 삭제 */}
            {/* <S.IconsWrapper>
              <S.GnbIconButton onClick={() => navigate('/search')}>
                <SearchIcon width={28} height={28} />
              </S.GnbIconButton>
            </S.IconsWrapper> */}
            {authStore.isLogin ? (
              <>
                {authStore.isItAdmin && (
                  <>
                    <DropdownOwner
                      ref={tenantMenuOwnerRef}
                      onMouseEnter={handleTenantSelectHover}
                      onMouseLeave={handleTenantSelectLeave}
                    >
                      <IconSelectButton
                        variant="text"
                        size="small"
                        endIcon={<DropdownIcon width={16} height={16} />}
                        className={isTenantMenuOpen ? 'menu-open' : ''}
                      >
                        {t('GNB_Menu_06')}
                      </IconSelectButton>
                    </DropdownOwner>
                    <Menu
                      anchorEl={tenantMenuOwnerRef.current}
                      open={isTenantMenuOpen}
                      MenuListProps={{
                        onMouseEnter: handleTenantSelectHover,
                        onMouseLeave: handleTenantSelectLeave,
                        sx: { pointerEvents: 'auto' },
                      }}
                      disableAutoFocusItem
                      TransitionComponent={Fade}
                      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                      PaperProps={itAdminStyles}
                      sx={{ [`&.${popoverClasses.root}`]: { pointerEvents: 'none', marginTop: '-1px' } }}
                    >
                      {myTenants?.map(tenant => {
                        return (
                          <CustomMenuItem
                            key={tenant.id}
                            value={tenant.id}
                            sx={{ width: '100%' }}
                            onClick={() => handleClickManagerConsole(tenant)}
                          >
                            <Typography variant="body1" noWrap>
                              {tenant.name}
                            </Typography>
                          </CustomMenuItem>
                        );
                      })}
                      <AddTenantApprove value="approveTenant">
                        <PlusIcon color={theme.colors['text-pink']} width={18} height={18} />
                        <MenuItemText noWrap variant="button3">
                          <span>{t('Top_Gnb_01')}</span>
                        </MenuItemText>
                      </AddTenantApprove>
                    </Menu>
                  </>
                )}
                <DropdownOwner
                  ref={profileMenuOwnerRef}
                  onMouseEnter={handleProfileHover}
                  onMouseLeave={handleProfileLeave}
                >
                  <Profile className={authStore.isItAdmin ? 'admin-profile' : ''}>
                    <SmallAvatar>
                      <ProfileIcon />
                    </SmallAvatar>
                  </Profile>
                </DropdownOwner>
                <Menu
                  anchorEl={profileMenuOwnerRef.current}
                  open={isProfileMenuOpen}
                  MenuListProps={{
                    onMouseEnter: handleProfileHover,
                    onMouseLeave: handleProfileLeave,
                    sx: { pointerEvents: 'auto' },
                  }}
                  disableAutoFocusItem
                  TransitionComponent={Fade}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                  PaperProps={profileStyles}
                  sx={{ [`&.${popoverClasses.root}`]: { pointerEvents: 'none', marginTop: '-1px' } }}
                >
                  <StyledMenuItem
                    value="profile"
                    onClick={() => {
                      modal.open();
                      handleProfileLeave();
                    }}
                  >
                    <ProfileMenuWrapper>
                      <PersonIcon width={18} height={18} />
                      <Typography noWrap component="span" variant="body1" color={theme.colors['text-gray-main']}>
                        {t('GNB_Profile_Menu_01')}
                      </Typography>
                    </ProfileMenuWrapper>
                  </StyledMenuItem>
                  {/* [23.12.19]영문화 string 작업 완료 전까지 임시 조치 */}
                  {/* <StyledMenuItem value="language">
                    <LanguageWrapper onClick={() => setIsLangOpen(true)}>
                      <GlobeIcon width={18} height={18} />
                      <Typography
                        noWrap
                        component="span"
                        variant="body1"
                        color={theme.colors['text-gray-main']}
                        sx={{ marginRight: 'auto' }}
                      >
                        {t('GNB_Profile_Menu_02')}
                      </Typography>
                      <TriangleIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
                      {isLangOpen && (
                        <Languages>
                          <Language onClick={() => handleChangeLang('ko')}>
                            <span className={i18n.language === 'ko' ? 'ko' : undefined}>한국어</span>
                            {i18n.language === 'ko' && (
                              <CheckIcon width={20} height={20} color={theme.colors['ic-purple-light']} />
                            )}
                          </Language>
                          <Language onClick={() => handleChangeLang('en')}>
                            <span className={i18n.language === 'en' ? 'en' : undefined}>English</span>
                            {i18n.language === 'en' && (
                              <CheckIcon width={20} height={20} color={theme.colors['ic-purple-light']} />
                            )}
                          </Language>
                        </Languages>
                      )}
                    </LanguageWrapper>
                  </StyledMenuItem> */}
                  <StyledMenuItem
                    value="logout"
                    onClick={() => {
                      handleProfileLeave();
                      logout();
                      navigate('/');
                    }}
                  >
                    <ProfileMenuWrapper>
                      <LogoutIcon width={18} height={18} />
                      <Typography noWrap component="span" variant="body1" color={theme.colors['text-gray-main']}>
                        {t('GNB_Profile_Menu_03')}
                      </Typography>
                    </ProfileMenuWrapper>
                  </StyledMenuItem>
                </Menu>
              </>
            ) : (
              <LoginWrap>
                <IntroductionBtn
                  onClick={handleIntroduceInquiryClick}
                  variant="contain"
                  size="small"
                  paddingHorizontal={16}
                >
                  {t('GNB_Menu_04')}
                </IntroductionBtn>
                <LoginBtn onClick={handleClickLoginBtn} variant="contain" size="small" paddingHorizontal={16}>
                  {t('GNB_Menu_05')}
                </LoginBtn>
              </LoginWrap>
            )}
          </UserButtonWrapper>
        </>
      )}
      {modal.visible && <MyProfileModal />}
    </Container>
  );
});

const Container = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--employee-gnb-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  flex-shrink: 0;
  gap: 40px;
  z-index: 1001;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    height: var(--mobile-employee-gnb-height);
    padding: 0 26px 0 24px;
    gap: 15px;
  } ;
`;

const UserButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
`;

const LogoLinkWrap = styled(Link)`
  width: 120px;
  height: 31px;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: 120px;
    height: 31px;
    margin-right: auto;
  }
  z-index: 1001;
`;

const MenusWrapper = styled(Box)`
  max-width: 1440px;
  height: 100%;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  justify-content: center;
  gap: 32px;
  align-items: center;
`;
const AddTenantApprove = styled(MenuItem)`
  overflow: visible;
  position: absolute;
  right: 0;
  bottom: -36px;
  left: 0;
  justify-content: center;
  gap: 2px;
  padding: 8px 0;
  width: 100%;
  border-radius: 0 0 3px 3px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  }
`;

const ProfileMenuWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 6px;
`;

const Languages = styled.ul`
  position: absolute;
  right: 202px;
  top: -7px;
  width: 180px;
  height: fit-content;
  padding: 6px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 3px;
  background-color: ${props => props.theme.colors['bg-white']};
  z-index: 1001;
`;

const Language = styled.li`
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 11px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: ${props => props.theme.colors['text-purple-light']};
  ${fonts.Body2};

  &:hover {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
  & > span {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-main']};
    &.ko,
    &.en {
      color: ${({ theme }) => theme.colors['text-purple-light']};
    }
  }
`;

const CustomMenuItem = styled(MenuItem)`
  padding: 8px 11px;
  :hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
  }
  && .MuiTouchRipple-child {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-pressed']};
  }
  && .MuiTouchRipple-rippleVisible {
    animation: none;
  }
`;

const MenuItemText = styled(Typography)`
  background: linear-gradient(90deg, #b13cad 2.73%, #7e49cb 96.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const LoginWrap = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 12px;
`;

const IntroductionBtn = styled(Button)`
  position: relative;
  border: 0;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors['bg-gradient']};
  border-image-slice: 1;
  z-index: 1;
  span {
    background: ${({ theme }) => theme.colors['bg-gradient']};
    border-image-slice: 1;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 2px * 2);
    height: calc(100% - 2px * 2);
    background: #fff;
    border-radius: calc(16px - 2px);
    z-index: -1;
  }
  flex-shrink: 0;
  &:hover {
    background: ${({ theme }) => theme.colors['state-gradient-hover']};
    box-shadow: none;
  }
`;

const LoginBtn = styled(Button)`
  position: relative;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors['bg-gradient']};
  border-image-slice: 1;
  z-index: 1;
  color: #fff;
  flex-shrink: 0;
  box-shadow: none;
  border: 0;
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors['state-gradient-hover']};
    box-shadow: none;
    border: 0;
  }
`;

const StyledLink = styled(RoutingTabs)`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  & .en-tab-item {
    min-width: auto;
    padding: 0 46px;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-left: 10px;
  &.admin-profile {
    margin-left: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  width: 198px;
  padding: 8px 4px 8px 11px;
  border-radius: 2px;
  overflow: visible;
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
`;

const DropdownOwner = styled.div`
  height: 100%;
  padding: 15px 0px;
  display: flex;
  align-items: center;
`;

const IconSelectButton = styled(Button)`
  cursor: auto;
  gap: 6px;
  padding: 6px 8px 6px 12px;
  font-weight: 500;
  z-index: 1001;
  &.menu-open {
    background: ${({ theme }) => theme.colors['state-white-hover']};
    border-radius: 3px;
  }
`;
