import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type ITenantMonthlyBillingRepository from '@repositories/tenantMonthlyBillingRepository/ITenantMonthlyBillingRepository';
import type {
  StatusUpdateRq,
  TenantBillingGenerateRq,
  TenantBillingId,
  TenantBillingsFilters,
} from '@repositories/tenantMonthlyBillingRepository/Types';
import { TenantMonthlyBillingModel } from '@models/TenantMonthlyBillingModel';

const tenantBillingRepo = repository.get<ITenantMonthlyBillingRepository>(KEYS.TENANT_MONTHLY_BILLING_REPOSITORY);

export const tenantBillingKeys = {
  all: ['tenantBillings'] as const,
  lists: () => [...tenantBillingKeys.all, 'list'] as const,
  list: (filter?: TenantBillingsFilters) => [...tenantBillingKeys.lists(), { ...filter }] as const,
  listInfinite: (filter?: TenantBillingsFilters) => [...tenantBillingKeys.list(filter), 'infinite'] as const,
  details: () => [...tenantBillingKeys.all, 'detail'] as const,
  detail: (filter: TenantBillingId) => [...tenantBillingKeys.details(), filter] as const,
};

export const useGetTenantBilling = (
  billingId: TenantBillingId,
  options?: UseQueryOptionsType<TenantMonthlyBillingModel>,
) =>
  useQuery<TenantMonthlyBillingModel, AxiosError>({
    queryKey: tenantBillingKeys.detail(billingId),
    queryFn: async () => {
      const result = await tenantBillingRepo.getOne(billingId);
      return new TenantMonthlyBillingModel(result);
    },
    ...options,
    cacheTime: 5 * 60 * 1000,
    staleTime: 4 * 60 * 1000,
    enabled: !!billingId && (options?.enabled !== undefined ? options.enabled : true),
  });

export const useGetTenantBillings = (filter?: TenantBillingsFilters) =>
  useQuery({
    queryKey: tenantBillingKeys.list(filter),
    queryFn: async () => {
      const result = await tenantBillingRepo.getList(filter);
      return {
        ...result,
        content: result.content.map(dto => new TenantMonthlyBillingModel(dto)),
      };
    },
  });

export const useGetTenantBillingsInfinite = (filter?: TenantBillingsFilters) =>
  useInfiniteQuery({
    queryKey: tenantBillingKeys.listInfinite(filter),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await tenantBillingRepo.getList({ ...filter, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new TenantMonthlyBillingModel(dto)),
      };
    },
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useDeleteTenantBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (billingId: TenantBillingId) => {
      await tenantBillingRepo.delete(billingId);
      return billingId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tenantBillingKeys.lists());
      queryClient.removeQueries(tenantBillingKeys.details());
    },
  });
};

export const useUpdateTenantBillingStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: StatusUpdateRq) => {
      const result = await tenantBillingRepo.updateStatus(rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(tenantBillingKeys.detail({ id: updated.tenant.id, month: updated.month }));
      queryClient.invalidateQueries(tenantBillingKeys.lists());
    },
  });
};

export const useGenerateTenantBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: TenantBillingGenerateRq) => {
      const result = await tenantBillingRepo.generateTenantBilling(rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(tenantBillingKeys.detail({ id: updated.tenant.id, month: updated.month }));
      queryClient.invalidateQueries(tenantBillingKeys.lists());
    },
  });
};

export const useUploadTenantBillingAttachments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      tenantId,
      month,
      files,
      config,
    }: {
      tenantId: string;
      month: string;
      files: File[];
      config?: AxiosRequestConfig;
    }) => {
      await tenantBillingRepo.fileUpload(tenantId, month, files, config);
      return { tenantId, month };
    },
    onSuccess: ({ tenantId, month }) => {
      queryClient.invalidateQueries(tenantBillingKeys.detail({ id: tenantId, month }));
    },
  });
};

export const useDeleteTenantBillingAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ tenantId, month, fileId }: { tenantId: string; month: string; fileId: string }) => {
      await tenantBillingRepo.fileDelete(tenantId, month, fileId);
      return { tenantId, month };
    },
    onSuccess: ({ tenantId, month }) => {
      queryClient.invalidateQueries(tenantBillingKeys.detail({ id: tenantId, month }));
    },
  });
};
