import { UserGroupMemberModel } from '@models/userGroupModels/UserGroupMemberModel';
import { UserGroupModel } from '@models/userGroupModels/UserGroupModel';
import { UserGroupSimpleModel } from '@models/userGroupModels/UserGroupSimpleModel';
import { KEYS, repository } from '@repositories/Repository';
import IUserGroupsRepository from '@repositories/userGroupsRepository/IUserGroupsRepository';
import {
  UserGroupCreateRq,
  UserGroupListCreateDTO,
  UserGroupMemberCreateRq,
  UserGroupMemberFilter,
  UserGroupsFilter,
  UserGroupUpdateRq,
} from '@repositories/userGroupsRepository/Types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { UseQueryOptionsType } from './UseQueryOptionsType';
import { tenantQueryKey } from './useTenant';
import { sbQueryKey } from './useSubscription';

const userGroupRepo = repository.get<IUserGroupsRepository>(KEYS.USERGROUP_REPOSITORY);

export const userGroupMemberQueryKey = {
  all: ['UserGroupMember'] as const,
  lists: () => [...userGroupMemberQueryKey.all, 'list'] as const,
  list: (id: string, filters?: UserGroupMemberFilter) => [...userGroupMemberQueryKey.lists(), id, { filters }] as const,
  details: () => [...userGroupMemberQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...userGroupMemberQueryKey.details(), id] as const,
};

export const userGroupQueryKey = {
  all: ['UserGroup'] as const,
  lists: () => [...userGroupQueryKey.all, 'list'] as const,
  list: (id: string, filters: UserGroupsFilter) => [...userGroupQueryKey.lists(), id, { filters }] as const,
  details: () => [...userGroupQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...userGroupQueryKey.details(), id] as const,
};

export const useGetUserGroups = (queries: UserGroupsFilter, options?: UseQueryOptionsType<UserGroupSimpleModel[]>) => {
  const result = useQuery<UserGroupSimpleModel[], AxiosError>({
    queryKey: userGroupQueryKey.list(queries.tenantId, queries),
    queryFn: async () => {
      const userGroupSimples = await userGroupRepo.getList(queries);
      return userGroupSimples.map(usergroupSimple => new UserGroupSimpleModel(usergroupSimple));
    },
    ...options,
  });

  return { ...result };
};

export const useGetUserGroup = (userGroupId: string, options?: UseQueryOptionsType<UserGroupModel>) => {
  const result = useQuery<UserGroupModel, AxiosError>({
    queryKey: userGroupQueryKey.detail(userGroupId),
    queryFn: async () => {
      const userGroup = await userGroupRepo.getOne(userGroupId);
      return new UserGroupModel(userGroup);
    },
    ...options,
  });

  return { ...result };
};

export const useAddUserGroup = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (userGroupCreateRq: UserGroupCreateRq) => {
      const userGroup = await userGroupRepo.create(userGroupCreateRq);
      return new UserGroupModel(userGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(userGroupQueryKey.lists());
    },
  });

  return { ...result };
};

export const useUpdateUserGroup = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (userGroupUpdateRq: UserGroupUpdateRq) => {
      const userGroup = await userGroupRepo.update(userGroupUpdateRq.userGroupId, userGroupUpdateRq);
      return new UserGroupModel(userGroup);
    },
    onSuccess: data => {
      queryClient.invalidateQueries(userGroupQueryKey.lists());
      queryClient.invalidateQueries(userGroupQueryKey.detail(data.userGroupId));
    },
  });

  return { ...result };
};

export const useDeleteUserGroup = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (userGroupId: string) => {
      const userGroup = await userGroupRepo.delete(userGroupId);
      return userGroup;
    },
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries(userGroupQueryKey.lists());
      queryClient.invalidateQueries(userGroupQueryKey.detail(variable));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });

  return { ...result };
};

export const useAddUserGroupMembers = () => {
  type Variable = {
    userGroupId: string;
    rqData: UserGroupMemberCreateRq[];
  };

  const queryClient = useQueryClient();

  const result = useMutation(
    async (params: Variable) => {
      const result = await userGroupRepo.addUsergroupMembers(params.userGroupId, params.rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userGroupMemberQueryKey.lists());
        queryClient.invalidateQueries(tenantQueryKey.lists());
      },
    },
  );

  return { ...result };
};

export const useGetUserGroupMembers = (
  userGroupId: UserGroupId,
  queries?: UserGroupMemberFilter,
  options?: UseQueryOptionsType<Page<UserGroupMemberModel>>,
) =>
  useQuery<Page<UserGroupMemberModel>, AxiosError>(
    userGroupMemberQueryKey.list(userGroupId, queries),
    async () => {
      const result = await userGroupRepo.getUsergroupMembers(userGroupId, queries);
      return {
        ...result,
        content: result.content.map(userGroupMember => new UserGroupMemberModel(userGroupMember)),
      };
    },
    {
      ...options,
    },
  );

export const useDeleteUserGroupMember = () => {
  type Variable = {
    userGroupId: UserGroupId;
    userId: UserId;
  };

  const queryClient = useQueryClient();

  const result = useMutation(
    async (params: Variable): Promise<void> => {
      await userGroupRepo.deleteUserGroupMember(params.userGroupId, params.userId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userGroupMemberQueryKey.lists());
        queryClient.invalidateQueries(tenantQueryKey.lists());
      },
    },
  );

  return { ...result };
};

export const useAddBatchUsergroups = () => {
  type Variable = {
    rqData: UserGroupListCreateDTO;
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Variable) => {
      await userGroupRepo.addBatchUsergroups(data.rqData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(userGroupQueryKey.all);
    },
  });
};
