// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const FolderIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.1625 4.91667C4.03133 4.91667 3.925 5.02238 3.925 5.15278V18.8472C3.925 18.9776 4.03133 19.0833 4.1625 19.0833H19.8375C19.9687 19.0833 20.075 18.9776 20.075 18.8472V7.92708C20.075 7.79668 19.9687 7.69097 19.8375 7.69097H11.79C11.2399 7.69097 10.7254 7.42043 10.4158 6.96836L9.08134 5.0199C9.03711 4.95532 8.96361 4.91667 8.88502 4.91667H4.1625ZM2.5 5.15278C2.5 4.23997 3.24433 3.5 4.1625 3.5H8.88502C9.43514 3.5 9.94964 3.77054 10.2592 4.22261L11.5937 6.17108C11.6379 6.23566 11.7114 6.27431 11.79 6.27431H19.8375C20.7557 6.27431 21.5 7.01428 21.5 7.92708V18.8472C21.5 19.76 20.7557 20.5 19.8375 20.5H4.1625C3.24433 20.5 2.5 19.76 2.5 18.8472V5.15278Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.1623 5.11647C4.14049 5.11647 4.1248 5.13374 4.1248 5.15258V18.847C4.1248 18.8659 4.14049 18.8831 4.1623 18.8831H19.8373C19.8591 18.8831 19.8748 18.8659 19.8748 18.847V7.92689C19.8748 7.90805 19.8591 7.89078 19.8373 7.89078H11.7898C11.174 7.89078 10.5976 7.58795 10.2506 7.08118L8.91613 5.13271C8.90937 5.12283 8.89775 5.11647 8.88483 5.11647H4.1623ZM11.7585 6.05787C11.7652 6.06775 11.7769 6.07411 11.7898 6.07411H19.8373C20.8648 6.07411 21.6998 6.90252 21.6998 7.92689V18.847C21.6998 19.8714 20.8648 20.6998 19.8373 20.6998H4.1623C3.13478 20.6998 2.2998 19.8714 2.2998 18.847V5.15258C2.2998 4.12822 3.13478 3.2998 4.1623 3.2998H8.88483C9.50061 3.2998 10.077 3.60263 10.4241 4.1094L11.7585 6.05787Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

FolderIcon.displayName = 'FolderIcon';
