import type { CreateStyled } from '@emotion/styled';
import { CurrencyUnits } from './commonVars';

/**
 * emotion/styled에 Transient Props를 지원하기 위한 함수
 * @example const Styled = styled(ELEMENT, transientOptions)`css...`
 */
export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

/**
 * A wrapper for "JSON.parse()" to support "undefined" value
 */
export const parseJSON = <T>(value: string | null | undefined): T | undefined => {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    return undefined;
  }
};

export const getSortOrderBy = (sort: SortString | SortString[] | undefined) => {
  if (!sort) {
    return undefined;
  }
  if (Array.isArray(sort)) {
    return sort.map(sortString => sortString.split('.')[0])[0];
  }
  return sort.split('.')[0];
};

/**
 * 현재 적용된 sort에서 orderBy의 order를 계산해주는 함수
 */
export const getSortOrder = (sort: SortString | SortString[] | undefined, orderBy: string): SortOrder | undefined => {
  if (!sort) {
    return undefined;
  }

  let sortOrder: SortOrder | undefined;
  if (Array.isArray(sort)) {
    const order = sort.find(sortString => sortString.split('.')[0] === orderBy)?.split('.')[1];
    if (order === 'asc' || order === 'desc' || order === undefined) {
      sortOrder = order;
    }
  } else {
    const [splittedOrderBy, splittedOrder] = sort.split('.');
    sortOrder = splittedOrderBy === orderBy && isOrderString(splittedOrder) ? splittedOrder : undefined;
  }

  return sortOrder;
};

/**
 * 파라미터의 문자열이 'asc', 'desc', undefined인지 확인해주는 타입 가드
 */
export const isOrderString = (str: string | undefined): str is 'asc' | 'desc' | undefined => {
  return str === 'asc' || str === 'desc' || str === undefined;
};

/**
 *
 */
export const isCurrencyUnit = (str: string | undefined): str is CurrencyUnit => {
  for (let i = 0; i < CurrencyUnits.length; i += 1) {
    if (CurrencyUnits[i] === str) {
      return true;
    }
  }

  return false;
};

export const insertAt = (original: string, index: number | undefined, insertString: string): string => {
  const end = index === undefined ? original.length : index > original.length ? original.length : index;
  return original.slice(0, end) + insertString + original.slice(end);
};
