import { Trans, useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useGetTenantBillingsInfinite } from '@queryHooks/useBilling';
import { useGetMonthlyBillingListInfinite } from '@queryHooks/useMonthlyBilling';
import { WgContainer, WgTitle } from '../styles';
import { EmptyPayment } from './EmptyPayment';
import { Payment } from './Payment';

type Filter = {
  sort?: SortString[];
  monthFrom?: DateTimeString | null;
  monthTo?: DateTimeString | null;
};

export const RecentPaymentWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantId } = useParams();
  const navigate = useNavigate();

  const handleClickSeeMore = () => {
    navigate(`/manager/${tenantId}/billings/history`);
  };

  // 초기 날짜 설정
  const initialFilter = {
    sort: [],
    monthFrom: dayjs().format('YYYY-MM'),
    monthTo: dayjs().format('YYYY-MM'),
  };

  const [queries] = useSearchParamsFilter<Filter>({
    defaultFilter: initialFilter,
    whiteList: ['sort'],
  });

  const { data: tenantBillings, isSuccess: isTenantBillingSuccess } = useGetTenantBillingsInfinite({
    sort: ['month.desc'],
    ...queries,
  });

  const { data: subscriptionBillings, isSuccess: isSubscriptionBillingSuccess } = useGetMonthlyBillingListInfinite({
    planScope: 'SUBSCRIPTION',
    sort: ['month.desc'],
    includesForecasted: true,
    ...queries,
  });

  const completedPayments =
    tenantBillings?.pages.flatMap(page => page.content).filter(billing => billing.isPayment) ?? [];
  const subscriptionPayments =
    subscriptionBillings?.pages.flatMap(page => page.content).filter(billing => billing.isPayment) ?? [];

  const paymentRows =
    isTenantBillingSuccess && isSubscriptionBillingSuccess ? [...completedPayments, ...subscriptionPayments] : [];

  return (
    <WgContainer height={516}>
      <WgTitle>
        <span>{t('Ad_Home_Billing_01')}</span>
        <span className="see-more">
          <Button className="see-more" onClick={handleClickSeeMore} variant="text">
            {t('Home_Add')}
            <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
          </Button>
        </span>
      </WgTitle>
      <Total>
        <Trans
          i18nKey="Ad_Home_Billing_03"
          values={{ number: paymentRows?.length }}
          components={{ 1: <span className="count" /> }}
        />
      </Total>
      {paymentRows?.length ? (
        <Payments>
          {paymentRows?.map(payment => {
            return <Payment key={payment.cost} billing={payment} />;
          })}
        </Payments>
      ) : (
        <EmptyPayment />
      )}
    </WgContainer>
  );
};

export const Total = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
  padding: 0px 8px 0px;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-light']};
  & .count {
    ${fonts.Headline8};
    color: ${props => props.theme.colors['text-purple-light']};
    margin: 0px 2px 0px 4px;
  }
`;

export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: 400px;
`;
