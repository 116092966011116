import type { HTMLAttributes, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

type CommonProps = HTMLAttributes<HTMLSpanElement>;

type VaraintSolid = StrictPropsWithChildren<{
  variant: 'solid';
  color: 'gray' | 'blue' | 'green' | 'yellow' | 'orange' | 'red' | 'pink' | 'purple' | 'gradient' | 'white';
}>;

type VariantOutline = StrictPropsWithChildren<{
  variant: 'outline';
  color: 'purple' | 'gray' | 'red';
}>;

type VariantContain = StrictPropsWithChildren<{
  variant: 'contain';
  color: 'gray' | 'green' | 'yellow' | 'pink' | 'gradient' | 'red';
}>;

type VariantIcon = PropsWithChildren<{
  variant: 'icon';
  color: 'green' | 'red' | 'gray';
  icon: React.ReactNode;
}>;

type Props = (VaraintSolid | VariantOutline | VariantContain | VariantIcon) & CommonProps;

export const Label = forwardRef<HTMLSpanElement, Props>(({ children, ...props }, ref) => {
  const { variant, color, ...restProps } = props;
  const icon = variant === 'icon' ? props.icon : null;

  return (
    <>
      {variant === 'solid' && (
        <SolidLabel {...restProps} ref={ref} color={color}>
          <span>{children}</span>
        </SolidLabel>
      )}
      {variant === 'outline' && (
        <OutlineLabel {...restProps} ref={ref} color={color}>
          <span>{children}</span>
        </OutlineLabel>
      )}
      {variant === 'contain' && (
        <ContainLabel {...restProps} ref={ref} color={color}>
          <span>{children}</span>
        </ContainLabel>
      )}
      {variant === 'icon' && (
        <IconLabel {...restProps} ref={ref} color={color} $isOnlyIcon={variant === 'icon' && !children}>
          {icon}
          {children && <span>{children}</span>}
        </IconLabel>
      )}
    </>
  );
});

const CommonStyle = css`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 2px;
  &[role='button'] {
    cursor: pointer;
  }
`;

const SolidLabel = styled('span')<Omit<VaraintSolid, 'variant'>>`
  ${CommonStyle}
  padding: 0px 5px;
  ${fonts.Caption4}
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};

  ${({ color, theme: { colors } }) => {
    switch (color) {
      case 'gray':
        return css`
          color: ${colors['text-gray-sub-darker']};
          background: ${colors['bg-gray-light']};
        `;
      case 'green':
        return css`
          color: ${colors['text-green-dark']};
          background: ${colors['bg-green-lighter']};
        `;
      case 'blue':
        return css`
          color: ${colors['text-blue']};
          background: ${colors['bg-blue-lighter']};
        `;
      case 'yellow':
        return css`
          color: ${colors['text-yellow-dark']};
          background: ${colors['bg-yellow-lighter']};
        `;
      case 'red':
        return css`
          color: ${colors['text-red']};
          background: ${colors['bg-red-lighter']};
        `;
      case 'pink':
        return css`
          color: ${colors['text-pink']};
          background: ${colors['bg-pink-lighter']};
        `;
      case 'purple':
        return css`
          color: ${colors['text-purple']};
          background: ${colors['bg-purple-lighter']};
        `;
      case 'orange':
        return css`
          color: ${colors['text-orange']};
          background: ${colors['bg-orange-lighter']};
        `;
      case 'gradient':
        return css`
          color: ${colors['text-white']};
          background: ${colors['bg-gradient']};
        `;
      case 'white':
        return css`
          color: ${colors['text-gray-main']};
          background: ${colors['bg-white']};
        `;
      default:
        return '';
    }
  }}
`;

const OutlineLabel = styled('span')<Omit<VariantOutline, 'variant'>>`
  ${CommonStyle}
  padding: 1px 5px;
  ${fonts.Caption5}
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  background: ${({ theme: { colors } }) => colors['bg-white']};

  ${({ color, theme: { colors } }) => {
    switch (color) {
      case 'purple':
        return css`
          color: ${colors['text-purple']};
        `;
      case 'gray':
        return css`
          color: ${colors['text-gray-sub-darker']};
        `;
      case 'red':
        return css`
          color: ${colors['text-red-light']};
        `;
      default:
        return '';
    }
  }}
`;

const ContainLabel = styled('span')<Omit<VariantContain, 'variant'>>`
  ${CommonStyle}
  padding: 0 4px;
  ${fonts.Caption4}

  ${({ color, theme: { colors } }) => {
    switch (color) {
      case 'gray':
        return css`
          color: ${colors['text-gray-sub-darker']};
          background: ${colors['bg-gray-dark']};
        `;
      case 'green':
        return css`
          color: ${colors['text-white']};
          background: ${colors['bg-green']};
        `;
      case 'yellow':
        return css`
          color: ${colors['text-gray-sub-darker']};
          background: ${colors['bg-yellow']};
        `;
      case 'pink':
        return css`
          color: ${colors['text-white']};
          background: ${colors['bg-pink']};
        `;
      case 'red':
        return css`
          color: ${colors['text-white']};
          background: ${colors['bg-red-light']};
        `;
      case 'gradient':
        return css`
          background: ${colors['bg-gray-light']};
          span {
            ${fonts.Caption5}
            background: ${colors['text-gradient']};
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
          }
        `;
      default:
        return '';
    }
  }}
`;

const IconLabel = styled('span', { shouldForwardProp: propName => !propName.startsWith('$') && propName !== 'icon' })<
  Omit<VariantIcon, 'variant' | 'icon'> & {
    $isOnlyIcon: boolean;
  }
>`
  ${CommonStyle}
  ${fonts.Caption4}
  color: ${({ theme: { colors } }) => colors['text-white']};
  padding: ${({ $isOnlyIcon }) => ($isOnlyIcon ? '2px' : '0px 4px')};
  gap: ${({ $isOnlyIcon }) => ($isOnlyIcon ? '0px' : '2px')};

  ${({ color, theme: { colors } }) => {
    switch (color) {
      case 'green':
        return css`
          background: ${colors['bg-green']};
        `;
      case 'red':
        return css`
          background: ${colors['bg-red-light']};
        `;
      case 'gray':
        return css`
          background: ${colors['bg-gray-dark-50']};
        `;
      default:
        return '';
    }
  }}
`;
