const defaultWidth = 24;
const defaultHeight = 24;

export const CalendarStartIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  ...rest
}: SvgProps) => {
  return (
    <svg {...rest} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99538 1C7.49553 1 7.90098 1.40545 7.90098 1.90561V3.85981H16.0991V1.90561C16.0991 1.40545 16.5046 1 17.0047 1C17.5049 1 17.9103 1.40545 17.9103 1.90561V3.85981H20.3412C21.3678 3.85981 22.2 4.69206 22.2 5.71869V20.971C22.2 21.9977 21.3678 22.8299 20.3412 22.8299H3.65893C2.6323 22.8299 1.80005 21.9977 1.80005 20.971V5.71869C1.80005 4.69206 2.6323 3.85981 3.65893 3.85981H6.08977V1.90561C6.08977 1.40545 6.49522 1 6.99538 1ZM3.65893 5.67103C3.6326 5.67103 3.61126 5.69237 3.61126 5.71869V8.62617H20.3888V5.71869C20.3888 5.69237 20.3675 5.67103 20.3412 5.67103H3.65893ZM20.3888 10.4374H3.61126V20.971C3.61126 20.9974 3.6326 21.0187 3.65893 21.0187H20.3412C20.3675 21.0187 20.3888 20.9974 20.3888 20.971V10.4374Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CalendarStartIcon.displayName = 'CalendarStartIcon';
