import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { DialogActions, DialogTitle, Dialog as MuiDialog, useTheme } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { XIcon } from '@icons/XIcon';
import { transientOptions } from '@utils/CommonUtil';
import { Button } from '..';

export type DialogButtonProps = {
  text: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: () => void;
};

export type DialogProps = StrictPropsWithChildren<{
  open: boolean;
  title?: string | React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  height?: number;
  buttonProps?: DialogButtonProps | [DialogButtonProps, DialogButtonProps];
  className?: string;
  onClose?: () => void;
  useCustomContent?: boolean;
  paddingHorizontal?: string;
}>;

/**
 * @param open - 다이얼로그 열림, 닫힘 상태
 * @param onClose - 다이얼로그 닫을 때 콜백 함수
 * @param title - 다이얼로그 Header에 표시할 타이틀
 * @param buttonProps - 다이얼로그 Footer에 표시할 버튼. 다이얼로그 Body에 Form을 사용하는 경우, children에 Dialog.Footer 컴포넌트를 이용해 버튼을 표시할 수도 있음
 * @param height - 다이얼로그의 전체 높이
 * @param className - className
 * @param size - 다이얼로그 width (small: 400px, medium: 480px, large: 600px)
 * @param useCustomContent - Body, Footer 영역 기본 스타일을 제거하고 children을 그대로 사용
 */
export const Dialog = ({
  open,
  onClose,
  title,
  buttonProps,
  height,
  children,
  className,
  paddingHorizontal,
  size = 'small',
  useCustomContent = false,
}: DialogProps) => {
  const theme = useTheme();
  const sizeMap = { small: '400px', medium: '480px', large: '600px' };

  const handleClick = (onClick: DialogButtonProps['onClick']) => () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: sizeMap[size], height: height ?? 'auto', transition: 'height 300ms', maxHeight: 'unset' },
      }}
      className={className}
    >
      <Header $isTextTitle={typeof title === 'string'}>
        {title}
        {onClose ? (
          <Button className="close-btn" onClick={onClose}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </Button>
        ) : null}
      </Header>
      {useCustomContent ? (
        children
      ) : (
        <Body $hasFooter={!!buttonProps} $paddingHorizontal={paddingHorizontal}>
          {children}
          {buttonProps ? (
            <Footer $paddingHorizontal={paddingHorizontal}>
              {Array.isArray(buttonProps) ? (
                <>
                  <Button variant="contain" size="extraLarge" onClick={handleClick(buttonProps[0].onClick)}>
                    {buttonProps[0].text}
                  </Button>
                  <Button variant="outline" size="extraLarge" onClick={handleClick(buttonProps[1].onClick)}>
                    {buttonProps[1].text}
                  </Button>
                </>
              ) : (
                <Button {...buttonProps} variant="contain" size="extraLarge" onClick={handleClick(buttonProps.onClick)}>
                  {buttonProps.text}
                </Button>
              )}
            </Footer>
          ) : null}
        </Body>
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(MuiDialog)`
  & .MuiPaper-root {
    border-radius: 6px;
  }
`;

const Header = styled(DialogTitle, transientOptions)<{ $isTextTitle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 16px ${({ $isTextTitle }) => ($isTextTitle ? '28px' : '24px')};
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};

  & > .close-btn {
    margin-left: auto;
  }
`;

const Body = styled('div', transientOptions)<{ $hasFooter?: boolean; $paddingHorizontal?: string }>`
  padding: 12px ${({ $paddingHorizontal }) => $paddingHorizontal || '28px'} 4px;
  margin-bottom: ${({ $hasFooter }) => ($hasFooter ? '100px' : '0px')};
  overflow-y: auto;
`;
Dialog.Body = Body;

const Footer = styled(DialogActions, transientOptions)<{ $paddingHorizontal?: string }>`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: absolute;
  width: calc(100% - ${({ $paddingHorizontal }) => $paddingHorizontal || '56px'});
  bottom: 28px;

  & > button {
    flex: 1;
  }
`;
Dialog.Footer = Footer;
