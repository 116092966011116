const defaultWidth = 26;
const defaultHeight = 24;

export const CardColorIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <g filter="url(#filter0_d_2796_12254)">
        <path
          d="M1.5 5C1.5 3.89543 2.39543 3 3.5 3H22.5C23.6046 3 24.5 3.89543 24.5 5V19C24.5 20.1046 23.6046 21 22.5 21H3.5C2.39543 21 1.5 20.1046 1.5 19V5Z"
          fill="#8A63D2"
        />
      </g>
      <g filter="url(#filter1_d_2796_12254)">
        <path d="M1.5 7H24.5V10H1.5V7Z" fill="#4C2888" />
      </g>
      <g filter="url(#filter2_d_2796_12254)">
        <rect x="15.5" y="16" width="5.5" height="2" rx="1" fill="#E6DCFD" />
      </g>
      <defs>
        <filter
          id="filter0_d_2796_12254"
          x="0.5"
          y="2.5"
          width="25"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2796_12254" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2796_12254" result="shape" />
        </filter>
        <filter
          id="filter1_d_2796_12254"
          x="0.5"
          y="6.5"
          width="25"
          height="5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2796_12254" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2796_12254" result="shape" />
        </filter>
        <filter
          id="filter2_d_2796_12254"
          x="15"
          y="16"
          width="6.5"
          height="3"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2796_12254" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2796_12254" result="shape" />
        </filter>
      </defs>
    </g>
  </svg>
);

CardColorIcon.displayName = 'CardColorIcon';
