import styled from '@emotion/styled';
import { Autocomplete, Box, Button, css, MenuItem, Select, TextField } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  padding: 16px 24px;
  background: #f6f8fa;
  border: 1px solid #e1e4e8;
  border-radius: 3px;
`;

export const FilterContainer = styled(Box)`
  display: flex;
  gap: 12px;
`;

const TextStyle = css`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #24292f;
`;

export const FilterSelect = styled(Select)`
  width: 160px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04);

  & .MuiSelect-select {
    ${TextStyle}
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e1e4e8;
    border-radius: 3px;
  }
` as unknown as typeof Select;

export const FilterMenuItem = styled(MenuItem)`
  ${TextStyle}
`;

export const FilterAutocomplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 100%;
    box-sizing: border-box;
  }
  & .MuiAutocomplete-inputRoot {
    width: 160px;
    background: #ffffff;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04);
    ${TextStyle}

    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e1e4e8;
      border-radius: 3px;
    }
  }
` as typeof Autocomplete;

export const AutocompleteOption = styled.li`
  ${TextStyle}
`;

export const SearchInput = styled(TextField)`
  width: 242px;
  height: 32px;
  margin-left: auto;
  background: #ffffff;

  & .MuiInputBase-root {
    padding-left: 8px;

    & .MuiInputBase-input {
      ${TextStyle}
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e1e4e8;
    border-radius: 2px 0px 0px 2px;
  }
`;

export const SearchButton = styled(Button)`
  height: 32px;
  padding: 6px 12px;
  background: #f6f8fa;
  border: 1px solid #e1e4e8;
  border-left: 0px;
  border-radius: 0px 2px 2px 0px;
  ${TextStyle}
`;
