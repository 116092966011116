import { ProductRequestModel } from '@models/productRequest';
import { KEYS, repository } from '@repositories/Repository';
import { Filter } from '@repositories/productRequestRepository';
import IProductRequestRepository from '@repositories/productRequestRepository/IProductRequestRepository';
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Page } from '@type/Page';

const productRequestRepo = repository.get<IProductRequestRepository>(KEYS.PRODUCT_REQUEST_REPOSITORY);

const productRequestQueryKey = {
  all: ['productRequest'] as const,
  lists: () => [...productRequestQueryKey.all, 'list'] as const,
  details: () => [...productRequestQueryKey.all, 'detail'] as const,

  list: (queries: Filter) => [productRequestQueryKey.lists(), { ...queries }] as const,
  listInfinite: (queries: Filter) => [productRequestQueryKey.list(queries), 'infinite'] as const,
  detail: (id: string) => [productRequestQueryKey.lists(), id] as const,
};

export const useGetProductRequestsInfinite = (
  queries: Omit<Filter, 'page'>,
  options?: UseInfiniteQueryOptions<Page<ProductRequestModel>>,
) =>
  useInfiniteQuery({
    queryKey: productRequestQueryKey.listInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await productRequestRepo.getList({ ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new ProductRequestModel(dto)),
        pageParam,
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetProductRequest = (id: string, options: UseQueryOptions<ProductRequestModel>) =>
  useQuery({
    queryKey: productRequestQueryKey.detail(id),
    queryFn: async () => {
      const result = await productRequestRepo.getOne(id);
      return new ProductRequestModel(result);
    },
    ...options,
    enabled: !!id && options ? !!options.enabled : true,
  });

export const useAddProductRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: productRequestRepo.create,
    onSuccess: () => {
      queryClient.invalidateQueries(productRequestQueryKey.lists());
    },
  });
};
