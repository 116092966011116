import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const ConfirmMail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { email } = params;
  return (
    <Container>
      <ConfirmMailIcon />
      <ConfirmMailTitle>{t('Confirm_Mail_01')}</ConfirmMailTitle>
      <ConfirmMailDescription>
        <span>
          <p className="email">{email}</p>
          <p>{t('Confirm_Mail_02')}</p>
        </span>
        <p>{t('Confirm_Mail_03')}</p>
      </ConfirmMailDescription>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height));
  align-items: center;
  padding-top: 130px;
`;

const ConfirmMailTitle = styled.div`
  ${fonts.Headline3};
  margin-top: 40px;
`;

const ConfirmMailDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  ${fonts.Paragraph1};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  & > span {
    display: flex;
    flex-direction: row;
  }
  & .email {
    ${fonts.Headline7};
    color: ${props => props.theme.colors['text-purple-light']};
  }
`;

const ConfirmMailIcon = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill="#F5F0FF" fillOpacity="0.7" />
      <g filter="url(#filter0_d_247_1320)">
        <path
          d="M27 40.4786C27 38.6187 28.0324 36.9125 29.68 36.0494L48 26.4532C49.0526 25.8838 50.8561 25.8146 52 26.4532L70.32 36.0494C71.9676 36.9125 73 38.6187 73 40.4786V66.4532C73 69.2147 70.7614 71.4532 68 71.4532H32C29.2386 71.4532 27 69.2147 27 66.4532V40.4786Z"
          fill="#959DA5"
        />
        <rect x="32" y="38" width="36" height="32" rx="3" fill="white" />
        <path
          d="M27 43L48 54C49.6444 54.6853 50.4742 54.6836 52 54L73 43V67C73 69.7614 70.7614 72 68 72H32C29.2386 72 27 69.7614 27 67V43Z"
          fill="#E1E4E8"
        />
        <circle cx="71" cy="66" r="7" fill="#EA4A5A" />
        <path
          d="M73.7008 62.7998C73.756 62.7998 73.8008 62.8446 73.8008 62.8998V69.1851C73.8008 69.2404 73.756 69.2851 73.7008 69.2851H72.5543C72.5215 69.2851 72.4908 69.269 72.4721 69.242L69.8771 65.4908C69.8716 65.4829 69.8626 65.4781 69.853 65.4781C69.8367 65.4781 69.8236 65.4913 69.8236 65.5075V69.1851C69.8236 69.2404 69.7788 69.2851 69.7236 69.2851H68.4008C68.3456 69.2851 68.3008 69.2404 68.3008 69.1851V62.8998C68.3008 62.8446 68.3456 62.7998 68.4008 62.7998H69.5648C69.5978 62.7998 69.6287 62.8161 69.6473 62.8433L72.2138 66.5915C72.2203 66.6011 72.2312 66.6068 72.2428 66.6068C72.2622 66.6068 72.278 66.5911 72.278 66.5716V62.8998C72.278 62.8446 72.3228 62.7998 72.378 62.7998H73.7008Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_247_1320"
          x="21"
          y="21"
          width="63"
          height="59"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_247_1320" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_247_1320" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
