import { ConnectionResp, ConnectionStatus } from '@repositories/subscriptionConnectionRepository/Types';
import { ConnectorRespModel } from './ConnectorRespModel';

export class ConnectionRespModel {
  private readonly _callbackUri: string;
  private readonly _connectionInfo: any;
  private readonly _connector: ConnectorRespModel;
  private readonly _connectorId: string;
  private readonly _context: {
    contextData?: any;
  };
  private readonly _id: string;
  private readonly _name: string;
  private readonly _status: ConnectionStatus;
  private readonly _statusMessage: string;

  constructor(dto: ConnectionResp) {
    this._callbackUri = dto.callbackUri || '';
    this._connectionInfo = dto.connectionInfo || {};
    this._connector = new ConnectorRespModel(dto.connector || {});
    this._connectorId = dto.connectorId || '';
    this._context = dto.context || { contextData: '' };
    this._id = dto.id || '';
    this._name = dto.name || '';
    this._status = dto.status || 'DISCONNECTED';
    this._statusMessage = dto.statusMessage || '';
  }

  get callbackUri() {
    return this._callbackUri;
  }

  get connectionInfo() {
    return this._connectionInfo;
  }

  get connector() {
    return this._connector;
  }

  get connectorId() {
    return this._connectorId;
  }

  get context() {
    return this._context;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get status() {
    return this._status;
  }

  get statusMessage() {
    return this._statusMessage;
  }
}
