import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, debounce, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCompanyList } from '@queryHooks/useCompany';
import { CompanyModel } from '@models/CompanyModel';
import { CompanyFilter } from '@repositories/companyRepository';

export type CompanySelectModel = {
  id: CompanyId;
  name: string;
};

interface Props {
  value: CompanyModel | null;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: CompanySelectModel | null) => void;
  fullWidth?: boolean;
  readOnly?: boolean;
  label?: string;
  className?: string; // NOTE. 스타일드 컴포넌트를 사용하기 위해서는  클래스이름을 추가해야함.
}

export const CompanySelect = ({ value, onChange, fullWidth, readOnly, label, className }: Props) => {
  const [filter, setFilter] = useState<CompanyFilter>({ size: 15 });
  const [initialLoding, setInitialLoading] = useState<boolean>(true);

  const {
    isFetching,
    data: { content: companies },
  } = useCompanyList(filter);

  useEffect(() => {
    if (!isFetching) setInitialLoading(false);
  }, [isFetching]);

  const handleSelectUser = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: CompanySelectModel | null) => {
      if (onChange) {
        onChange(event, value);
      }
    },
    [onChange],
  );

  const debounceOnChange = useMemo(
    () =>
      debounce(value => {
        setFilter({ ...filter, keyword: value ?? undefined });
      }, 500),
    [filter, setFilter],
  );

  const handleChangeInputText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      debounceOnChange(event.target.value);
    },
    [debounceOnChange],
  );

  // 초기 데이터 로딩
  if (initialLoding) return <CircularProgress />;

  // 모델 변환 필요(CompanyModel => CompanySelectModel)
  const candidates: CompanySelectModel[] = companies.map(company => ({ id: company.id, name: company.name }));

  return (
    <Autocomplete
      options={candidates}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={candidate => {
        if (candidate.id) return candidate.name;
        return '';
      }}
      value={value}
      blurOnSelect
      onChange={handleSelectUser}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} onChange={handleChangeInputText} label={label} />
      )}
      fullWidth={fullWidth}
      readOnly={readOnly}
      className={className}
    />
  );
};
