import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import 'reflect-metadata';
import ITenantBillingStatRepository from './ITenantBillingStatRepository';
import {
  TenantBillingStatDTO,
  TenantBillingStatFilters,
  TenantPaymentStatAggregateFilter,
  TenantPaymentStatDTO,
} from './Types';

@injectable()
export class TenantBillingStatRepository implements ITenantBillingStatRepository {
  async getCostStat(queries: TenantPaymentStatAggregateFilter): Promise<TenantPaymentStatDTO[]> {
    const queryString = qs.stringify(queries);
    const result = await API.get<TenantPaymentStatDTO[]>(`/api/v1/statistics/tenant-payment-stat?${queryString}`);
    return result.data;
  }

  async getOne(queries: TenantBillingStatFilters): Promise<TenantBillingStatDTO> {
    const queryString = qs.stringify(queries);
    const result = await API.get<TenantBillingStatDTO>(`/api/v1/statistics/tenant-billing-stat?${queryString}`);
    return result.data;
  }
}
