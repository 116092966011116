import type { InputBaseComponentProps, TextFieldProps } from '@mui/material';
import type { ElementType, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { CurrencyAdorment } from '@components/inputs/CurrencyAdorment';
import { UsageUnitAdorment } from '@components/inputs/UsageUnitAdorment';
import { EmptyRow, TableHeaderCell } from '@components/table';
import { CurrencyInput } from '@pages/customer/common/CurrencyInput';
import { fonts } from '@theme/fontsCustomer';
import type { MonthlyBillingUpdateHookForm } from '@hookForms/MonthlyBillingUpdateHookForm';
import { dateFormat } from '@utils/dateUtil';
import { currencyNumberFormat, numberFormat } from '@utils/numberFormat';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { MetricUsageCostModel } from '@models/billing/MetricUsageCostModel';

type Props = {
  billing: SubscriptionBillingModel;
  metricUsageCosts: MetricUsageCostModel[];
  isEditMode: boolean;
};
export const MetricCostDetail = ({ billing, metricUsageCosts, isEditMode }: Props) => {
  const { t } = useTranslation();

  const { control } = useFormContext<MonthlyBillingUpdateHookForm>();

  const renderMetricCostEditField = (
    name: 'usage' | 'price' | 'originalCost' | 'cost',
    index: number,
    inputComponent?: ElementType<InputBaseComponentProps>,
    startAdornment?: ReactNode,
  ) => (
    <Controller
      control={control}
      name={`moduleUsageCosts.${index}.${name}`}
      render={({ field: { ...props } }) => (
        <EditTextField
          {...props}
          InputProps={{
            inputComponent,
            startAdornment,
          }}
        />
      )}
    />
  );

  const renderUsageValue = (usage: MetricUsageCostModel, index: number) => {
    if (isEditMode) {
      return renderMetricCostEditField('usage', index, undefined, <UsageUnitAdorment unit={usage.usageUnit} />);
    }

    return `${usage.usageUnit ? `[${usage.usageUnit}]` : ''} ${numberFormat({
      num: usage.usage,
      maxFractionDigit: 5,
    })}`;
  };

  const renderUsagePrice = (usage: MetricUsageCostModel, index: number) => {
    if (isEditMode) {
      return renderMetricCostEditField(
        'price',
        index,
        CurrencyInput,
        <CurrencyAdorment currency={billing.originalCurrencyUnit} />,
      );
    }

    return currencyNumberFormat(usage.price ?? 0, billing.originalCurrencyUnit, 'text');
  };

  const renderUsageOriginalCost = (usage: MetricUsageCostModel, index: number) => {
    if (isEditMode) {
      return renderMetricCostEditField(
        'originalCost',
        index,
        CurrencyInput,
        <CurrencyAdorment currency={billing.originalCurrencyUnit} />,
      );
    }

    return currencyNumberFormat(usage.originalCost ?? 0, billing.originalCurrencyUnit, 'text');
  };

  const renderUsageCost = (usage: MetricUsageCostModel, index: number) => {
    if (isEditMode) {
      return renderMetricCostEditField(
        'cost',
        index,
        CurrencyInput,
        <CurrencyAdorment currency={billing.currencyUnit} />,
      );
    }

    return currencyNumberFormat(usage.cost ?? 0, billing.currencyUnit, 'text');
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell width={414}>{t('Acc_Main_15')}</TableHeaderCell>
            <TableHeaderCell width={280}>{t('Acc_Create_09')}</TableHeaderCell>
            <TableHeaderCell width={200}>{t('Acc_Detail_14')}</TableHeaderCell>
            <TableHeaderCell width={170}>{t('Acc_Detail_15')}</TableHeaderCell>
            <TableHeaderCell width={240}>{t('Acc_Main_18')}</TableHeaderCell>
            <TableHeaderCell width={240}>{t('Acc_Main_19')}</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!billing.moduleUsageCosts || billing.moduleUsageCosts.length === 0 ? (
            <EmptyRow colSpan={6} title={<EmptyRowDescription>{t('Acc_Create_22')}</EmptyRowDescription>} />
          ) : (
            metricUsageCosts?.map((moduleCost, index) => (
              <TableRow key={moduleCost.usageMetricCode}>
                <TableCell>{moduleCost.usageMetricName}</TableCell>
                <TableCell>
                  {dateFormat(billing.startDate, '-', t('DateFormat_YMD'))} ~{' '}
                  {dateFormat(billing.endDate, '-', t('DateFormat_YMD'))}
                </TableCell>
                <TableCell>{moduleCost.usage ? renderUsageValue(moduleCost, index) : '-'}</TableCell>
                <TableCell>{moduleCost.price ? renderUsagePrice(moduleCost, index) : '-'}</TableCell>
                <TableCell>{moduleCost.originalCost ? renderUsageOriginalCost(moduleCost, index) : '-'}</TableCell>
                <TableCell>{moduleCost.cost ? renderUsageCost(moduleCost, index) : '-'}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EmptyRowDescription = styled.p`
  ${fonts.Body2}
`;

const EditTextFieldCss = css`
  margin: -6px -12px;
  width: calc(100% + 24px);
  & .MuiInputBase-root {
    padding: 4px 4px 4px 12px;
  }
`;

const EditTextField = styled((props: TextFieldProps) => <TextField size="small" autoFocus fullWidth {...props} />)`
  ${EditTextFieldCss};
`;
