import { makeAutoObservable } from 'mobx';

export class ManagerAddFromUserStore {
  private _visible: boolean;

  constructor() {
    this._visible = false;
    makeAutoObservable(this);
  }

  get visible(): boolean {
    return this._visible;
  }

  open = () => {
    this._visible = true;
  };

  close = () => {
    this.clear();
  };

  private readonly clear = () => {
    this._visible = false;
  };
}
