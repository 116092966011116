import {
  SubscriptionUseRequestHistory,
  SubscriptionUseRequestStatus,
} from '@repositories/subscriptionUseRequestRepository/Types';
import dayjs from 'dayjs';
import i18next from 'i18next';

export class SubscriptionUseRequestHistoryModel {
  private readonly _comment: string;
  private readonly _managerName: string;
  private readonly _status: SubscriptionUseRequestStatus;
  private readonly _createdDate: string;

  constructor(dto?: SubscriptionUseRequestHistory) {
    this._comment = dto?.comment || '';
    this._managerName = dto?.managerName || '';
    this._status = dto?.status || 'CREATED';
    this._createdDate = dto?.createdDate || '';
  }

  get comment(): string {
    return this._comment;
  }

  get managerName(): string {
    return this._managerName;
  }

  get status(): SubscriptionUseRequestStatus {
    return this._status;
  }

  get statusStr(): '대기' | '보류' | '승인' | '반려' {
    switch (this.status) {
      case 'PENDING':
        return i18next.t('Subscrib_Detail_UseRequest_11');
      case 'APPROVED':
        return i18next.t('Subscrib_Detail_UseRequest_12');
      case 'REJECTED':
        return i18next.t('Subscrib_Detail_UseRequest_13');
      default:
        return i18next.t('Subscrib_Detail_UseRequest_10');
    }
  }

  get statusLabelColor(): 'yellow' | 'green' | 'gray' | 'pink' {
    switch (this.status) {
      case 'PENDING':
        return 'yellow';
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'gray';
      default:
        return 'pink';
    }
  }

  get createdDate(): string {
    return dayjs(this._createdDate).format('YYYY.MM.DD');
  }
}
