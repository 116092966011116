import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { parseJSON } from '@utils/CommonUtil';

type LocalStorageKeys =
  | 'subscriptionListRowsPerPage'
  | 'subscriptionRequestListRowsPerPage'
  | 'tenantListRowsPerPage'
  | 'customTimezone';

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useLocalStorage = <T>(key: LocalStorageKeys | string, initialValue: T): [T, SetValue<T>] => {
  const readValue = useCallback((): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const writeValue: SetValue<T> = useCallback(
    value => {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);
    },
    [key, storedValue],
  );

  // useEffect(() => {
  //   setStoredValue(readValue());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return [storedValue, writeValue];
};
