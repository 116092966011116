import styled from '@emotion/styled';
import { TextField, useTheme } from '@mui/material';
import { Button } from '@components/buttons/Button';
import { fonts } from '@theme/fontsCustomer';
import { CheckIcon } from '@icons/CheckIcon';
import { XIcon } from '@icons/XIcon';

export const DescriptionEditTextField = styled(TextField)`
  position: relative;
  right: 4px;
  z-index: 2;
  margin-right: 8px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border-radius: 2px;
  & .MuiInputBase-root {
    ${fonts.Body2};
  }
`;

interface DescriptionEditEndAdornmentProps {
  textLength: number;
  maxTextLength: number;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const DescriptionEditEndAdornment = ({
  textLength,
  maxTextLength,
  onConfirm,
  onCancel,
}: DescriptionEditEndAdornmentProps) => {
  const { colors } = useTheme();

  return (
    <>
      <CurrentCheckSpan>{textLength}</CurrentCheckSpan>
      <LimitCheckSpan>/</LimitCheckSpan>
      <LimitCheckSpan>{maxTextLength}</LimitCheckSpan>
      <CheckIconButton variant="outline" onClick={onConfirm}>
        <CheckIcon width={14} height={14} color={colors['ic-green-light']} />
      </CheckIconButton>
      <XIconButton variant="outline" onClick={onCancel}>
        <XIcon width={14} height={14} />
      </XIconButton>
    </>
  );
};

const CurrentCheckSpan = styled.span`
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  text-align: right;

  ${fonts.Body4}
`;

const LimitCheckSpan = styled.span`
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
  margin: 0px 0px 0px 2px;
  ${fonts.Body4}
`;

const CheckIconButton = styled(Button)`
  border-radius: 2px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  margin-left: 10px;
  padding: 4px;
`;

const XIconButton = styled(Button)`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 2px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  margin: 0px 0px 0px 4px;
  padding: 4px;
`;
