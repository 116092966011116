import React, { HTMLProps, RefObject, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs, { Dayjs } from 'dayjs';
import { fonts } from '@theme/fontsCustomer';
import { CaretIcon } from '@icons/CaretIcon';
import { FilterIcon } from '@icons/FilterIcon';
import { transientOptions } from '@utils/CommonUtil';
import { CheckFilterContents, type CheckFilterItemType } from './CheckFilterContents';
import { DateFilterContents, type DateFilterItemType } from './DateFilterContents';

type SortProps = {
  disableSort?: boolean;
  orderBy?: string;
  order?: 'asc' | 'desc' | undefined;
  onSortChange?: (orderBy: string, order: 'asc' | 'desc' | undefined) => void;
};

type CheckFilterProps = {
  filterType?: 'check';
  filterKey: string;
  itemList: CheckFilterItemType[];
  onFilterChange?: (filterKey: string, filterValues: string[]) => void;
  enableFilterSearch?: boolean;
  enableFilterAll?: boolean;
};

type DateFilterProps = {
  filterType?: 'date';
  filterKey: string;
  itemList?: DateFilterItemType[];
  onFilterChange?: (filterKey: string, from: Dayjs | null, to: Dayjs | null) => void;
  format?: string;
};

type FilterProps =
  | (CheckFilterProps | DateFilterProps)
  | {
      filterType?: never;
      filterKey?: never;
      itemList?: never;
      onFilterChange?: never;
    };

type CommonProps = {
  align?: 'left' | 'center' | 'right';
};

type Props = (SortProps & FilterProps) & Omit<HTMLProps<HTMLTableCellElement>, 'as'> & CommonProps;

/**
 * 정렬, 필터링을 지원하는 Tabel Header Cell (filterType === 'date'는 TBD)
 * @param orderBy - 정렬에 사용할 Key.
 * @param order - 정렬 상태. 기본: undefined, 오름차순: 'asc', 내림차순: 'desc'
 * @param onSortChange - 정렬 변경시 불리는 callback 함수
 * @param filterType - 필터 종류. 'date' | 'check'.
 * @param filterKey - 필터에 사용할 Key.
 * @param itemList - filterType === 'check'인 경우 사용. 필터들의 목록
 * @param enableFilterSearch - filterType === 'check'인 경우 사용. 필터 검색 기능 활성화 여부
 * @param enableFilterAll - filterType === 'check'인 경우 사용. 전체 선택 기능 활성화 여부
 * @param onFilterChange - 필터를 선택하고 창을 닫았을 때 불리는 callback 함수. filterType에 따라 파라미터가 다름
 * @param align - 'left', 'center', 'right'
 */
export const TableHeaderCell = ({
  orderBy,
  order,
  onSortChange,
  children,
  align = 'left',
  disableSort = false,
  ...props
}: Props) => {
  const { filterType, filterKey, onFilterChange, itemList } = props;

  // const itemList = filterType === 'check' ? props.itemList : [];
  const enableFilterSearch = filterType === 'check' ? props.enableFilterSearch : false;
  const enableFilterAll = filterType === 'check' ? props.enableFilterAll : false;
  const format = filterType === 'date' ? props.format : undefined;

  // props의 특정 필드를 제외하기 위한 코드. 의도적으로 no-unused-vars를 비활성화 시킴
  const htmlProps =
    filterType === 'check'
      ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (({ filterType, filterKey, onFilterChange, itemList, enableFilterSearch, enableFilterAll, ...object }) =>
          object)(props)
      : // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (({ filterType, filterKey, onFilterChange, itemList, ...object }) => object)(props);

  const { colors } = useTheme();
  const [searchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [checkedFilterValues, setCheckedFilterValues] = useState<Set<string>>(new Set<string>());

  const [selectedDate, setSelectedDate] = useState<{ from: Dayjs | null; to: Dayjs | null; menuValue: string }>({
    from: null,
    to: null,
    menuValue: '',
  });
  const anchorRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement | null>(null);

  const isSortable = !!orderBy && !!onSortChange;
  const isFilterable = (filterType === 'check' && itemList.length > 0) || filterType === 'date';
  const isFilterApplied =
    filterType === 'check'
      ? !!searchParams.get(filterKey)
      : !!searchParams.get(`${filterKey}From`) || !!searchParams.get(`${filterKey}To`);

  const handleClick = () => {
    if (isSortable) {
      const nextOrder = order === undefined ? 'desc' : order === 'desc' ? 'asc' : undefined;
      onSortChange(orderBy, nextOrder);
    }
  };

  const handleFilter = {
    showButton: () => {
      setIsMouseHover(true);
    },
    hideButton: () => {
      if (!isFilterOpen) {
        setIsMouseHover(false);
      }
    },
    open: () => {
      // 필터 열 때 searchParams에 적용된 값으로 필터 상태 초기화
      if (filterType === 'check') {
        setCheckedFilterValues(() => {
          const appliedFilterValue = searchParams.getAll(filterKey);
          return new Set<string>(
            appliedFilterValue.length > 0 ? appliedFilterValue : itemList.flatMap(({ filterValue }) => filterValue),
          );
        });
      } else {
        setSelectedDate(prev => {
          const appliedFromValue = searchParams.get(`${filterKey}From`);
          const appliedToValue = searchParams.get(`${filterKey}To`);
          return appliedFromValue && appliedToValue
            ? {
                from: dayjs(appliedFromValue),
                to: dayjs(appliedToValue),
                menuValue: prev.menuValue,
              }
            : { from: null, to: null, menuValue: prev.menuValue };
        });
      }

      setIsFilterOpen(true);
    },
    close: () => {
      setIsFilterOpen(false);
      setIsMouseHover(false);

      if (isFilterable && typeof onFilterChange === 'function') {
        if (filterType === 'check') {
          onFilterChange(
            filterKey,
            checkedFilterValues.size > 0 &&
              checkedFilterValues.size !== itemList.flatMap(({ filterValue }) => filterValue).length
              ? Array.from(checkedFilterValues)
              : [],
          );
        } else if (filterType === 'date') {
          onFilterChange(filterKey, selectedDate.from, selectedDate.to);
        }
      }
    },
    checkFilterChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      { filterValue, checked }: { filterValue: string | string[] | 'ALL'; checked: boolean },
    ) => {
      setCheckedFilterValues(prev => {
        if (filterType === 'check' && filterValue === 'ALL') {
          if (checked) {
            return new Set(itemList.flatMap(({ filterValue }) => filterValue));
          }
          return new Set();
        }

        const next = new Set<string>(prev);
        if (Array.isArray(filterValue)) {
          if (checked) {
            filterValue.forEach(value => next.add(value));
          } else {
            filterValue.forEach(value => next.delete(value));
          }
        } else if (checked) {
          next.add(filterValue);
        } else {
          next.delete(filterValue);
        }
        return next;
      });
    },
    dateFilterChange: (from: Dayjs | null, to: Dayjs | null, menuValue: string) => {
      setSelectedDate({ from, to, menuValue });
    },
  };

  return (
    <>
      <StyledTH
        {...htmlProps}
        $isFilterApplied={isFilterApplied}
        $isSortable={isSortable}
        onMouseEnter={handleFilter.showButton}
        onMouseLeave={handleFilter.hideButton}
        align={align}
      >
        <Contents>
          <TitleWrapper onClick={disableSort ? undefined : handleClick}>
            {isSortable && (
              <CaretIcon
                upArrowColor={
                  disableSort
                    ? colors['ic-gray-lighter']
                    : order === 'asc'
                    ? colors['ic-purple-light']
                    : colors['ic-gray-light']
                }
                downArrowColor={
                  disableSort
                    ? colors['ic-gray-lighter']
                    : order === 'desc'
                    ? colors['ic-purple-light']
                    : colors['ic-gray-light']
                }
              />
            )}
            <strong>{children}</strong>
          </TitleWrapper>
          {isFilterable && (isMouseHover || isFilterApplied) && (
            <FilterButton
              ref={anchorRef}
              $isFilterOpen={isFilterOpen}
              $isFilterApplied={isFilterApplied}
              onClick={handleFilter.open}
            >
              <FilterIcon
                width={16}
                height={16}
                color={
                  isFilterApplied
                    ? colors['ic-purple-light']
                    : colors['ic-gray-dark']
                }
              />
            </FilterButton>
          )}
        </Contents>
      </StyledTH>
      {!!anchorRef.current &&
        (filterType === 'check' && filterKey ? (
          <CheckFilterContents
            anchorEl={anchorRef.current}
            open={isFilterOpen}
            onClose={handleFilter.close}
            enableFilterSearch={enableFilterSearch}
            enableFilterAll={enableFilterAll}
            itemList={itemList}
            checkedFilterValues={checkedFilterValues}
            onFilterCheckChange={handleFilter.checkFilterChange}
          />
        ) : filterType === 'date' ? (
          <DateFilterContents
            anchorEl={anchorRef.current}
            open={isFilterOpen}
            selectedDate={selectedDate}
            itemList={itemList}
            onClose={handleFilter.close}
            onFilterChange={handleFilter.dateFilterChange}
            format={format}
          />
        ) : null)}
    </>
  );
};

const StyledTH = styled('th', {
  shouldForwardProp: (propName: string) => propName !== 'itemList' && !propName.startsWith('$'),
})<{ $isFilterApplied: boolean; $isSortable: boolean }>`
  text-align: left;
  ${fonts.Headline9};
  color: ${({ theme }) => theme.colors['text-gray-main']};
  background-color: ${({ theme, $isFilterApplied }) =>
    $isFilterApplied ? theme.colors['state-white-purple-hover'] : theme.colors['bg-gray-lighter']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  padding: 11px ${({ $isSortable }) => ($isSortable ? '12px' : '16px')};

  & strong {
    color: ${({ $isFilterApplied, theme: { colors } }) =>
      $isFilterApplied ? colors['text-purple-light'] : colors['text-gray-sub-darker']};
    margin: ${({ align }) => (align === 'center' ? '0 auto' : align === 'left' ? '0 auto 0 0' : '0 0 0 auto')};
  }
`;

const Contents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  flex-grow: 1;
`;

const FilterButton = styled('button', transientOptions)<{ $isFilterOpen: boolean; $isFilterApplied: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  background-color: ${({ $isFilterOpen, $isFilterApplied, theme: { colors } }) =>
    $isFilterOpen
      ? colors['state-white-pressed']
      : $isFilterApplied
      ? colors['state-white-purple-hover']
      : colors['bg-gray-lighter']};
  padding: 0px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  cursor: pointer;
`;
