import { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import { Dialog } from '@components/Dialog';
import { Stepper } from '@components/stepper/Stepper';
import { useStore } from '@stores/RootStore';
import { useUiStore } from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { type ExternalSubscriptionCreateRq } from '@repositories/subscriptionRepository';
import { useAddExternalSubscription, useUpdateUserSubscription } from '@queryHooks/useSubscription';
import { dateFormat } from '@utils/dateUtil';
import { type SubscriptionModel } from '@models/SubscriptionModel';
import { getErrorMessage } from '@locales/i18nErrorMessage';
import { AdditionalStep } from './AdditionalStep';
import { BasicStep } from './BasicStep';
import { type SubscriptionEditForm, useSubScriptionEditForm } from './SubscriptionEditValidationSchema';

type BodyProps = {
  onSubmitted: () => void;
  subscription?: SubscriptionModel;
  softwareInfo: {
    id: string;
    iconUrl: string;
    name: string;
  };
  onClose: () => void;
  step: 'BASIC' | 'ADDITIONAL';
  onNextStep: () => void;
};

export const SubscriptionEditDialogBody = ({
  subscription,
  softwareInfo,
  onSubmitted,
  onClose,
  step,
  onNextStep,
}: BodyProps) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { alertStore } = useUiStore();
  const [selectedUserGroup, setSelectedUserGroup] = useState<{ userGroupId: string; name: string }>({
    userGroupId: '',
    name: authStore.managerTenantInfo.name,
  });

  const [attachFiles, setAttachFiles] = useState<File[]>([]);

  const formOptions = useSubScriptionEditForm();
  const { handleSubmit, trigger, reset } = formOptions;

  const { mutate: addExternalSubscription, isLoading: isAddSubscriptionLoading } = useAddExternalSubscription();
  const { mutate: updateUserSubscription, isLoading: isUpdateLoading } = useUpdateUserSubscription();

  const isEditMode = !!subscription;

  useEffect(() => {
    if (subscription) {
      reset({
        name: subscription.name,
        planId: subscription.plan.planId,
        planOptionId: subscription.mainLicense?.planOption?.planOptionId ?? '',
        timezone: subscription.timeZone,
        startDate: subscription.startDate,
        endDate: subscription.endDate || null,
        paymentDueDate: subscription.paymentDueDate,
        owner: {
          id: subscription.owner.id,
          name: subscription.owner.name,
          email: subscription.owner.email,
        },
        memo: subscription.memo,
      });
      setSelectedUserGroup({
        userGroupId: subscription.userGroup.userGroupId ?? '',
        name: subscription.userGroupName,
      });
    }
  }, [reset, subscription]);

  const handleValid: SubmitHandler<SubscriptionEditForm> = formData => {
    if (!isUpdateLoading && isEditMode) {
      updateUserSubscription(
        subscription.getUserSubscriptionUpdateDTO({
          name: formData.name,
          planId: formData.planId,
          planOptionId: formData.planOptionId,
          timeZone: formData.timezone,
          startDate: dateFormat(formData.startDate, '', 'YYYY-MM-DD'),
          endDate: dateFormat(formData.endDate, '', 'YYYY-MM-DD'),
          paymentDueDate: dateFormat(formData.paymentDueDate, '', 'YYYY-MM-DD'),
          ownerId: formData.owner?.id,
          userGroupId: selectedUserGroup.userGroupId === '' ? undefined : selectedUserGroup.userGroupId,
          memo: formData.memo ?? null,
        }),
        {
          onSuccess: onSubmitted,
        },
      );
    } else if (!isAddSubscriptionLoading && !isEditMode) {
      const rqData: ExternalSubscriptionCreateRq = {
        endDate: dateFormat(formData.endDate, undefined, 'YYYY-MM-DD'),
        name: formData.name,
        ownerId: formData.owner?.id,
        paymentDueDate: dateFormat(formData.paymentDueDate, undefined, 'YYYY-MM-DD'),
        planId: formData.planId,
        planOptionId: formData.planOptionId,
        softwareId: softwareInfo.id,
        startDate: dateFormat(formData.startDate, undefined, 'YYYY-MM-DD'),
        tenantId: authStore.managerTenantInfo.id,
        timeZone: formData.timezone,
        userGroupId: selectedUserGroup.userGroupId,
        memo: formData.memo ?? null,
      };
      addExternalSubscription(
        { subscription: rqData, files: attachFiles },
        {
          onSuccess: onSubmitted,
          onError: (error: unknown) => {
            alertStore.open({
              title: t('Subscrib_Create_11'),
              message: getErrorMessage(error).message,
            });
          },
        },
      );
    }
  };

  const handleNextStepClick = () => {
    const validationCheck = async () => {
      const isValid = await trigger(['name', 'planId', 'planOptionId', 'timezone', 'startDate', 'paymentDueDate']);
      if (isValid) {
        onNextStep();
      }
    };
    validationCheck();
  };

  return (
    <Dialog.Body $hasFooter>
      <FormProvider {...formOptions}>
        <form onSubmit={handleSubmit(handleValid)}>
          <ProductInfo>
            <StyledAvatar src={softwareInfo.iconUrl} />
            {softwareInfo.name}
          </ProductInfo>
          {subscription ? (
            <BasicStep
              subscription={subscription}
              softwareInfo={softwareInfo}
              selectedUserGroup={selectedUserGroup}
              onUserGroupChange={setSelectedUserGroup}
              onNextStepClick={handleNextStepClick}
              onClose={onClose}
            />
          ) : (
            <>
              <Stepper
                value={step}
                steps={[
                  { value: 'BASIC', label: '기본 정보' },
                  { value: 'ADDITIONAL', label: '추가 정보' },
                ]}
              />
              {step === 'BASIC' && (
                <BasicStep
                  subscription={subscription}
                  softwareInfo={softwareInfo}
                  selectedUserGroup={selectedUserGroup}
                  onUserGroupChange={setSelectedUserGroup}
                  onNextStepClick={handleNextStepClick}
                  onClose={onClose}
                />
              )}
              {step === 'ADDITIONAL' && (
                <AdditionalStep selectedFiles={attachFiles} onSelectedFilesUpdated={setAttachFiles} onClose={onClose} />
              )}
            </>
          )}
        </form>
      </FormProvider>
    </Dialog.Body>
  );
};

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 13px 28px;
  margin-bottom: 18px;
  background: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border-radius: 3px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  ${fonts.Headline7}
`;

const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 0px 0px, rgba(0, 0, 0, 0.07) 0px 0px 3px 0px;
  border: 1px solid ${({ theme }) => theme.colors['border-bk-opacity-10%']};
  background: ${({ theme: { colors } }) => colors['bg-white']};
  img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }
`;
