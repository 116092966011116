import type {
  EventName,
  WorkflowBaseTriggerDTO,
  WorkflowTriggerType,
} from '@repositories/workflowBaseTriggerRepository/Types';
import { TenantSimpleModel } from '@models/tenantModels';
import { WorkflowBaseTriggerFieldDTOModel } from './WorkflowBaseTriggerFieldDTOModel';

export class WorkflowBaseTriggerDTOModel {
  private readonly _description: string;
  private readonly _eventName: EventName;
  private readonly _fields: WorkflowBaseTriggerFieldDTOModel[];
  private readonly _id: string;
  private _name: string;
  private readonly _tenant: TenantSimpleModel;
  private readonly _type: WorkflowTriggerType;

  constructor(dto: WorkflowBaseTriggerDTO) {
    this._description = dto.description || '';
    this._eventName = dto.eventName || 'TENANT_MEMBER_JOINED';
    this._fields = dto.fields?.length ? dto.fields.map(field => new WorkflowBaseTriggerFieldDTOModel(field)) : [];
    this._id = dto.id || '';
    this._name = dto.name || '';
    this._tenant = new TenantSimpleModel(dto.tenant);
    this._type = dto.type || 'DIRECT_API_TRIGGER';
  }

  get description() {
    return this._description;
  }

  get eventName() {
    return this._eventName;
  }

  get fields() {
    return this._fields;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get tenant() {
    return this._tenant;
  }

  get type() {
    return this._type;
  }

  get isConfigurable() {
    return !(this._eventName === 'TENANT_MEMBER_JOINED' || this._eventName === 'TENANT_MEMBER_LEFT');
  }
}
