import dayjs from 'dayjs';
import i18next from 'i18next';
import type {
  CollectorStatus,
  SubscriptionList,
  SubscriptionStatus,
  SubscriptionType,
} from '@repositories/subscriptionRepository';
import type { UsageWorkflowDTO } from '@repositories/workflowRepository/Types';
import { SubscriptionSimpleModel } from './SubscriptionSimpleModel';
import { SubscriptionConnectionModel } from './connection/SubscriptionConnectionModel';
import { SoftwareSimpleModel } from './softwareModels';
import { TenantSimpleModel } from './tenantModels/TenantSimpleModel';
import { RealtimeUsageModel } from './usageModels/RealtimeUsageModel';
import { UserGroupSimpleModel } from './userGroupModels/UserGroupSimpleModel';

export class SubscriptionListModel {
  private readonly _dto: SubscriptionList;
  private readonly _connection?: SubscriptionConnectionModel;

  private readonly _activeUserCount: number;
  private readonly _collectorStatus: CollectorStatus;
  private readonly _description: string;
  private readonly _endDate: string;
  private readonly _id: string;
  private readonly _isQuantityContract: boolean;
  private readonly _name: string;
  private readonly _nextPaymentDate: DateTimeString;
  private readonly _parentSubscription: SubscriptionSimpleModel;
  private readonly _paymentDueDate: DateTimeString;
  private readonly _planName: string;
  private readonly _realtimeUsage: RealtimeUsageModel;
  private readonly _requestedUserCount: number;
  private readonly _software: SoftwareSimpleModel;
  private readonly _softwareContractId: string;
  private readonly _startDate: string;
  private readonly _status: SubscriptionStatus;
  private readonly _subSubscriptionCount: number;
  private readonly _subscriptionRequestId: number;
  private readonly _tenant: TenantSimpleModel;
  private readonly _timeZone: string;
  private readonly _type: SubscriptionType;
  private readonly _usageWorkflow: UsageWorkflowDTO;
  private readonly _userCount: number;
  private readonly _userGroup: UserGroupSimpleModel;
  private readonly _version: number;

  constructor(dto: SubscriptionList) {
    this._dto = dto;
    this._connection = dto.connection ? new SubscriptionConnectionModel(dto.connection) : undefined;

    this._id = dto.id;
    this._parentSubscription = new SubscriptionSimpleModel(dto.parentSubscription);
    this._paymentDueDate = dto.paymentDueDate ?? '-';
    this._nextPaymentDate = dto.nextPaymentDate ?? '-';
    this._tenant = new TenantSimpleModel(dto.tenant);
    this._software = new SoftwareSimpleModel(dto.software);
    this._type = dto.type || 'ON_DEMAND';
    this._softwareContractId = dto.softwareContractId || '';
    this._name = dto.name || '';
    this._description = dto.description || '';
    this._startDate = dto.startDate || '';
    if (!dto.endDate) {
      if (this._type === 'FIXED') {
        this._endDate = dayjs(dto.startDate).add(1, 'year').add(-1, 'day').format('YYYY-MM-DD');
      } else if (this._type === 'FIXED_2') {
        this._endDate = dayjs(dto.startDate).add(2, 'year').add(-1, 'day').format('YYYY-MM-DD');
      } else if (this._type === 'FIXED_3') {
        this._endDate = dayjs(dto.startDate).add(3, 'year').add(-1, 'day').format('YYYY-MM-DD');
      } else {
        this._endDate = '';
      }
    } else {
      this._endDate = dto.endDate;
    }
    this._timeZone = dto.timeZone || '';
    this._subSubscriptionCount = dto.subSubscriptionCount || 0;
    this._realtimeUsage = new RealtimeUsageModel(dto.realtimeUsage);
    this._collectorStatus = dto.collectorStatus || 'STOP';
    this._version = dto.version || 0;
    this._status = dto.status || 'OPEN';
    this._userCount = dto.userCount || 0;
    this._activeUserCount = dto.activeUserCount || 0;
    this._requestedUserCount = dto.requestedUserCount || 0;
    this._isQuantityContract = dto.isQuantityContract || false;
    this._subscriptionRequestId = dto.subscriptionRequestId || 0;
    this._usageWorkflow = dto.usageWorkflow ?? { id: '', status: 'STOPPED' };
    this._userGroup = new UserGroupSimpleModel(dto.userGroup);
    this._planName = dto.planName ?? '';
  }

  get connection(): SubscriptionConnectionModel | undefined {
    return this._connection;
  }
  get isQuantityContract(): boolean {
    return this._isQuantityContract;
  }

  get subscriptionRequestId(): number {
    return this._subscriptionRequestId;
  }

  get userGroup() {
    return this._userGroup;
  }

  get id(): string {
    return this._id;
  }

  get parentSubscription(): SubscriptionSimpleModel {
    return this._parentSubscription;
  }

  get paymentDueDate(): DateTimeString {
    return this._paymentDueDate;
  }

  get nextPaymentDate(): DateTimeString {
    return this._nextPaymentDate;
  }

  get tenant(): TenantSimpleModel {
    return this._tenant;
  }

  get tenantName(): string {
    return this.tenant.name;
  }

  get tenantCode(): string {
    return this.tenant.tenantCode;
  }

  get userGroupId(): string {
    return this.tenant.id;
  }

  get software(): SoftwareSimpleModel {
    return this._software;
  }

  get softwareName(): string {
    return this.software.name;
  }

  get softwareIconUrl(): string {
    return this.software.iconUrl;
  }

  get softwareCategoryName(): string {
    return this.software.category?.name;
  }

  get type(): SubscriptionType {
    return this._type;
  }

  get subscriptionTypeStr(): string {
    switch (this.type) {
      case 'FIXED':
        return '1 year';
      case 'FIXED_2':
        return '2 year';
      case 'FIXED_3':
        return '3 year';
      case 'ON_DEMAND':
        return 'On Demand';
      case 'SUB':
        return 'Sub-subscription';
      default:
        return '';
    }
  }

  get softwareContractId(): string {
    return this._softwareContractId;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get startDate(): string {
    return this._startDate;
  }

  get endDate(): string {
    return this._endDate;
  }

  get period(): string {
    return `${this.startDate} ~ ${this.endDate || ''}`;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  get subSubscriptionCount(): number {
    return this._subSubscriptionCount;
  }

  get realtimeUsage(): RealtimeUsageModel {
    return this._realtimeUsage;
  }

  get usage() {
    const module = this.realtimeUsage.moduleUsageList.length > 0 ? this.realtimeUsage.moduleUsageList[0].module : '';
    const usages =
      this.realtimeUsage.moduleUsageList.length > 0
        ? this.realtimeUsage.moduleUsageList[0].usages
        : [{ value: 0, unit: '' }];

    const { value, unit } = usages[0];
    return { module, value, unit };
  }

  get collectorStatus(): CollectorStatus {
    return this._collectorStatus;
  }

  get version(): number {
    return this._version;
  }

  get originalStatus() {
    return this._status;
  }

  get status(): 'REQUESTED' | 'INACTIVE' | 'ACTIVE' | 'SCHEDULED_TO_START' | 'SCHEDULED_TO_END' {
    const nowDate = dayjs();

    const isSubscribing =
      (nowDate.isAfter(this.startDate) && (nowDate.isBefore(this.endDate) || nowDate.isSame(this.endDate, 'day'))) ||
      this.endDate === '';
    if (isSubscribing) {
      const endDateFrom = dayjs(this.endDate).add(-1, 'month');
      return nowDate.isAfter(endDateFrom) ? 'SCHEDULED_TO_END' : 'ACTIVE';
    }

    const isEndSubscription = nowDate.add(1, 'day').isAfter(this.endDate);
    if (isEndSubscription) {
      return 'INACTIVE';
    }

    const isScheduledToStart = nowDate.add(-1, 'day').isBefore(this.startDate);
    if (isScheduledToStart) {
      return 'SCHEDULED_TO_START';
    }

    return 'REQUESTED';
  }

  get statusStr() {
    switch (this.status) {
      case 'ACTIVE':
        return i18next.t('Subscrib_Main_02');
      case 'INACTIVE':
        return i18next.t('Subscrib_Main_05');
      case 'SCHEDULED_TO_START':
        return i18next.t('Subscrib_Main_03');
      case 'SCHEDULED_TO_END':
        return i18next.t('Subscrib_Main_04');
      case 'REQUESTED':
        return '구독 승인 대기';
      default:
        return '-';
    }
  }

  get usageWorkflow() {
    return this._usageWorkflow;
  }

  get isLatestUsageWorkflowFailed(): boolean {
    return this._usageWorkflow.latestExecutionStatus === 'FAILED';
  }

  get userCount() {
    return this._userCount;
  }

  get activeUserCount() {
    return this._activeUserCount;
  }

  get requestedUserCount() {
    return this._requestedUserCount;
  }

  get planName() {
    return this._planName;
  }

  get isExternalSubscription() {
    return this._type === 'EXTERNAL';
  }
}
