import type { Filters, SoftwareCategory, SoftwareCategoryCreateRq, SoftwareCategoryListDTO } from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { Page } from '@type/Page';
import ISoftwareCategoryRepository from './ISoftwareCategoryRepository';

@injectable()
export class SoftwareCategoryRepository implements ISoftwareCategoryRepository {
  async create(data: SoftwareCategoryCreateRq): Promise<SoftwareCategory> {
    const result = await API.post<SoftwareCategory>('/api/v1/software-categories', data);
    return result.data;
  }

  async getOne(id: string): Promise<SoftwareCategory> {
    const result = await API.get<SoftwareCategory>(`/api/v1/software-categories/${id}`);
    return result.data;
  }

  async getList(queries?: Filters): Promise<Page<SoftwareCategoryListDTO>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat' });
    const result = await API.get<Page<SoftwareCategoryListDTO>>(`/api/v1/software-categories?${queryString}`);
    return result.data;
  }

  async update(id: string, data: SoftwareCategoryCreateRq): Promise<SoftwareCategory> {
    const result = await API.put<SoftwareCategory>(`/api/v1/software-categories/${id}`, data);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    await API.delete<void>(`/api/v1/software-categories/${id}`);
  }
}
