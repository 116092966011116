import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useStore } from '@stores/RootStore';
import { FolderIcon, OrganizationIcon, DesktopIcon } from '@icons/index';
import type { SoftwareModel } from '@models/softwareModels';
import * as S from '../SummaryStyles';

export const AppInfo = ({ software }: { software: SoftwareModel }) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { colors } = useTheme();
  return (
    <S.AppInfoContainer>
      <div className="title">{software.name}</div>
      <S.AppDescription>{software.description}</S.AppDescription>
      <S.AppInfo>
        <div>
          <FolderIcon width={20} height={20} color={colors['ic-gray-dark']} />
          <span>{software.categoryNames}</span>
        </div>
        <div>
          <OrganizationIcon width={20} height={20} color={colors['ic-gray-dark']} />
          <span>{software.companyName}</span>
        </div>
        <div>
          <DesktopIcon width={20} height={20} color={colors['ic-gray-dark']} />
          <a href={software.url} target="_blank" rel="noreferrer">
            {software.url}
          </a>
        </div>
      </S.AppInfo>

      {software.relatedProducts.length > 0 && (
        <>
          <S.Related>{t('Product_Detail_View_Recom_01')}</S.Related>
          <S.RelatedProducts>
            {software.relatedProducts.map(related => (
              <S.IconWrapper key={related.productId}>
                <Link
                  key={related.productId}
                  to={`/manager/${authStore.curTenant.id}/store/product/${related.productId}`}
                >
                  <img alt="icon" src={related.iconUrl} width={36} height={36} />
                </Link>
              </S.IconWrapper>
            ))}
          </S.RelatedProducts>
        </>
      )}
    </S.AppInfoContainer>
  );
};
