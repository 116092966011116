import { t } from 'i18next';
import type { TenantMemberStatus } from '@repositories/userRepository';

export const getMemberStatusLabel = (memberStatus?: TenantMemberStatus) => {
  switch (memberStatus) {
    case 'ACTIVE':
      return t('Subscription.memberStatus.ACTIVE');
    case 'BLOCKED':
      return t('Subscription.memberStatus.BLOCKED');
    default:
      return t('Subscription.memberStatus.NON_MEMBER');
  }
};
