import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { FloatingLnb } from '@pages/customer/common/lnb/FloatingLnb';
import { ChevronIcon } from '@icons/ChevronIcon';
import { withLogin } from '@hoc/withLogin';
import * as S from './SubscriptionStyles';

export const Subscription: React.FC = withLogin(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <S.Page>
      <S.Container>
        <FloatingLnb
          title={`MY ${t('Workflow_Create_68')}`}
          menuItem={[
            {
              name: t('Subscrib_Main_02'),
              to: 'list',
              icon: <ChevronIcon width={18} height={18} rotateNum={90} color={theme.colors['ic-gray-dark']} />,
            },
            {
              name: t('Apply_Detail_01'),
              to: 'usage-apply',
              icon: <ChevronIcon width={18} height={18} rotateNum={90} color={theme.colors['ic-gray-dark']} />,
            },
            {
              name: t('Technical_Support_01'),
              to: 'technical-support',
              icon: <ChevronIcon width={18} height={18} rotateNum={90} color={theme.colors['ic-gray-dark']} />,
            },
            {
              name: t('Subscription_01'),
              to: 'subscriptions-company',
              icon: <ChevronIcon width={18} height={18} rotateNum={90} color={theme.colors['ic-gray-dark']} />,
            },
          ]}
        />
        <Outlet />
      </S.Container>
    </S.Page>
  );
});
