import { TenantSimple } from '@repositories/tenantRepository/Types';

export class TenantSimpleModel {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _tenantCode: string;
  private readonly _description: string;

  constructor(dto?: TenantSimple) {
    this._id = dto?.id || '';
    this._name = dto?.name || '';
    this._tenantCode = dto?.tenantCode || '';
    this._description = dto?.description || '';
  }

  get id() {
    return this._id;
  }

  get tenantCode() {
    return this._tenantCode;
  }

  get description() {
    return this._description;
  }

  get name() {
    return this._name;
  }
}
