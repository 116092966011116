import { LocalStorageKey } from './LocalStorageKey';
import { BrowserStorageMapper } from '../BrowserStorageMapper';

const PREFIX = 'SMP_';

class LocalStorage {
  private readonly storage: typeof window.localStorage;

  constructor() {
    this.storage = window.localStorage;
  }

  private getOriginKey(key: string) {
    return `${PREFIX}${key}`;
  }

  get<T>(key: LocalStorageKey, mapper?: BrowserStorageMapper<T>): T | string | null | T[] {
    const originObj = this.storage.getItem(this.getOriginKey(key));

    if (originObj && mapper) {
      const jsonObj = JSON.parse(originObj);
      if (Array.isArray(jsonObj)) {
        return jsonObj.map(obj => mapper.fromJson(obj) as T);
      }
      return mapper.fromJson(jsonObj) as T;
    }
    return originObj;
  }

  set(key: LocalStorageKey, value: string): void {
    this.storage.setItem(this.getOriginKey(key), value);
  }

  clear(): void {
    this.storage.clear();
  }

  removeItem(key: LocalStorageKey): void {
    this.storage.removeItem(this.getOriginKey(key));
  }

  key(idx: number): string | null {
    return this.storage.key(idx);
  }

  get length(): number {
    return this.storage.length;
  }
}

const localStorage = new LocalStorage();

export default localStorage;
