import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 520px;
  background-color: ${({ theme }) => theme.colors.White};
  border: 2px solid #fff;
  border-radius: 16px;
`;

export const ModalTitleWrapper = styled(Box)`
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Line};
  padding: 0px 24px;
`;

export const ModalContentWrapper = styled(Box)`
  padding: 16px 24px;
`;

export const ContentElement = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 8px 0px;
`;

export const ContentNameElement = styled(Box)`
  width: 150px;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: right;
  margin: 24px 24px 0px 0px;
  gap: 8px;
`;
