const defaultWidth = 24;
const defaultHeight = 24;

export const KeyIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M16.3541 8.79163C16.9869 8.79163 17.4999 8.27863 17.4999 7.6458C17.4999 7.01298 16.9869 6.49998 16.3541 6.49998C15.7213 6.49998 15.2083 7.01298 15.2083 7.6458C15.2083 8.27863 15.7213 8.79163 16.3541 8.79163Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4374 1C11.2608 1 7.87497 4.38583 7.87497 8.56247C7.87497 9.37414 8.00308 10.157 8.24055 10.8914L1.46985 17.6621C1.16901 17.9629 1 18.3709 1 18.7964V21.3958C1 22.2818 1.71821 23 2.60416 23H3.97915C4.86511 23 5.58331 22.2818 5.58331 21.3958V20.479C5.58331 20.3524 5.68591 20.2498 5.81248 20.2498H8.31981C8.5034 20.2534 8.68478 20.1833 8.81955 20.0485L9.01504 19.853C9.16546 19.7026 9.24996 19.4986 9.24996 19.2859V17.9581C9.24996 17.8316 9.35256 17.729 9.47913 17.729H10.4747C10.9001 17.729 11.3081 17.56 11.609 17.2591L13.1087 15.7594C13.843 15.9968 14.6258 16.1249 15.4374 16.1249C19.6141 16.1249 22.9999 12.7391 22.9999 8.56247C22.9999 4.38583 19.6141 1 15.4374 1ZM9.24996 8.56247C9.24996 5.14522 12.0202 2.37499 15.4374 2.37499C18.8547 2.37499 21.6249 5.14522 21.6249 8.56247C21.6249 11.9797 18.8547 14.7499 15.4374 14.7499C14.6398 14.7499 13.8791 14.5994 13.1808 14.3257C12.9264 14.226 12.6371 14.2865 12.4438 14.4797L10.6367 16.2869C10.5937 16.3298 10.5354 16.354 10.4747 16.354H9.47913C8.59318 16.354 7.87497 17.0722 7.87497 17.9581V18.8748H5.81248C4.92653 18.8748 4.20832 19.593 4.20832 20.479V21.3958C4.20832 21.5224 4.10572 21.625 3.97915 21.625H2.60416C2.47759 21.625 2.37499 21.5224 2.37499 21.3958V18.7964C2.37499 18.7356 2.39914 18.6773 2.44211 18.6343L9.52025 11.5562C9.71348 11.363 9.77393 11.0736 9.67421 10.8192C9.40055 10.1209 9.24996 9.3601 9.24996 8.56247Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4372 2.5748C12.1305 2.5748 9.44977 5.25548 9.44977 8.56227C9.44977 9.33453 9.59553 10.0706 9.86023 10.746C9.98895 11.0745 9.91092 11.448 9.66148 11.6974L2.58334 18.7756C2.57787 18.781 2.5748 18.7885 2.5748 18.7962V21.3956C2.5748 21.4118 2.58786 21.4248 2.60396 21.4248H3.97896C3.99507 21.4248 4.00812 21.4118 4.00812 21.3956V20.4788C4.00812 19.4823 4.81587 18.6746 5.81228 18.6746H7.67477V17.9579C7.67477 16.9615 8.48252 16.1538 9.47893 16.1538H10.4745C10.4822 16.1538 10.4896 16.1507 10.4951 16.1452L12.3022 14.3381C12.5517 14.0886 12.9252 14.0106 13.2536 14.1393C13.929 14.404 14.665 14.5497 15.4372 14.5497C18.744 14.5497 21.4247 11.8691 21.4247 8.56227C21.4247 5.25548 18.744 2.5748 15.4372 2.5748ZM7.67477 8.56227C7.67477 4.27518 11.1501 0.799805 15.4372 0.799805C19.7243 0.799805 23.1997 4.27518 23.1997 8.56227C23.1997 12.8494 19.7243 16.3247 15.4372 16.3247C14.6475 16.3247 13.884 16.2065 13.1642 15.9864L11.7502 17.4003C11.4118 17.7387 10.9529 17.9288 10.4745 17.9288H9.47893C9.46283 17.9288 9.44977 17.9418 9.44977 17.9579V19.2857C9.44977 19.5515 9.34419 19.8063 9.15626 19.9943L8.96077 20.1898C8.78727 20.3633 8.55393 20.4537 8.31777 20.4496H5.81228C5.79618 20.4496 5.78312 20.4627 5.78312 20.4788V21.3956C5.78312 22.3921 4.97537 23.1998 3.97896 23.1998H2.60396C1.60756 23.1998 0.799805 22.3921 0.799805 21.3956V18.7962C0.799805 18.3177 0.989885 17.8588 1.32823 17.5205L8.01318 10.8355C7.79301 10.1157 7.67477 9.35208 7.67477 8.56227ZM17.6997 7.64561C17.6997 8.38889 17.0972 8.99144 16.3539 8.99144C15.6106 8.99144 15.0081 8.38889 15.0081 7.64561C15.0081 6.90233 15.6106 6.29978 16.3539 6.29978C17.0972 6.29978 17.6997 6.90233 17.6997 7.64561Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
