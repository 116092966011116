import type { WorkflowTriggerCreateDTO, WorkflowTriggerDTO } from '@repositories/workflowRepository/Types';
import { WorkflowBaseTriggerDTOModel } from './WorkflowBaseTriggerDTOModel';
import { WorkflowTriggerConditionDTOModel } from './WorkflowTriggerConditionDTOModel';

export class WorkflowTriggerDTOModel {
  private _baseTrigger: WorkflowBaseTriggerDTOModel;
  private readonly _conditions: WorkflowTriggerConditionDTOModel[];
  private readonly _cronExpression?: string;
  private readonly _cronTimeZone?: string;
  private readonly _customConditionExpression?: string;
  private readonly _id: string;

  constructor(dto: WorkflowTriggerDTO) {
    this._baseTrigger = new WorkflowBaseTriggerDTOModel(dto.baseTrigger || {});
    this._conditions = dto.conditions?.length
      ? dto.conditions.map(condition => new WorkflowTriggerConditionDTOModel(condition))
      : [];
    this._cronExpression = dto.cronExpression;
    this._cronTimeZone = dto.cronTimeZone;
    this._customConditionExpression = dto.customConditionExpression;
    this._id = dto.id || '';
  }

  get baseTrigger() {
    return this._baseTrigger;
  }

  set baseTrigger(value: WorkflowBaseTriggerDTOModel) {
    this._baseTrigger = value;
  }

  get conditions() {
    return this._conditions;
  }

  get cronExpression() {
    return this._cronExpression;
  }

  get cronTimeZone() {
    return this._cronTimeZone;
  }

  get customConditionExpression() {
    return this._customConditionExpression;
  }

  get id() {
    return this._id;
  }

  toCreateDTO(): WorkflowTriggerCreateDTO {
    return {
      baseTriggerId: this._baseTrigger.id,
      conditions: this._conditions.map(condition => ({
        fieldName: condition.fieldName,
        logicalOperator: condition.logicalOperator,
        operator: condition.operator,
        value: condition.value,
      })),
      cronExpression: this._cronExpression,
      cronTimeZone: this._cronTimeZone,
      customConditionExpression: this._customConditionExpression,
    };
  }
}
