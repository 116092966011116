const defaultWidth = 24;
const defaultHeight = 24;

export const EyeIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M4.06995 13.8417C4.41471 12.8937 5.24658 11.4081 6.58561 10.1724C7.90926 8.95091 9.69578 8 11.9938 8C14.2918 8 16.0783 8.95091 17.402 10.1724C18.741 11.4081 19.5729 12.8937 19.9176 13.8417C20.1064 14.3608 20.6801 14.6285 21.1992 14.4398C21.7182 14.2511 21.986 13.6773 21.7972 13.1583C21.3541 11.9397 20.3542 10.1753 18.7583 8.7026C17.1471 7.21576 14.8958 6 11.9938 6C9.0918 6 6.84045 7.21576 5.22925 8.7026C3.63343 10.1753 2.63348 11.9397 2.19036 13.1583C2.00162 13.6773 2.26938 14.2511 2.78841 14.4398C3.30745 14.6285 3.88121 14.3608 4.06995 13.8417Z"
          fill={color}
        />
        <path
          d="M11.9866 18.8181C14.2711 18.8181 16.123 16.9662 16.123 14.6818C16.123 12.3973 14.2711 10.5454 11.9866 10.5454C9.70216 10.5454 7.85024 12.3973 7.85024 14.6818C7.85024 16.9662 9.70216 18.8181 11.9866 18.8181Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

EyeIcon.displayName = 'EyeIcon';
