import { makeAutoObservable } from 'mobx';
import { SubscriptionModel } from '@models/SubscriptionModel';
import { TenantModel } from '@models/tenantModels';
import { SubscriptionRequestModel } from '@models/SubscriptionRequestModel';
import { SoftwareModel } from '@models/softwareModels';

type OpenParams = {
  parentSubscription?: SubscriptionModel;
  tenant?: TenantModel;
  software?: SoftwareModel;
  subscriptionRequest?: SubscriptionRequestModel;
};

export class ApplicationCreateStore {
  private _visible: boolean;

  private _parentSubscription: SubscriptionModel | undefined;

  private _tenant: TenantModel | undefined;

  private _software: SoftwareModel | undefined;

  private _subscriptionRequest: SubscriptionRequestModel | undefined;

  constructor() {
    this._visible = false;
    this._parentSubscription = undefined;
    this._tenant = undefined;
    this._software = undefined;
    this._subscriptionRequest = undefined;

    makeAutoObservable(this);
  }

  open = ({ parentSubscription, tenant, software, subscriptionRequest }: OpenParams) => {
    this._visible = true;
    this._parentSubscription = parentSubscription;
    this._tenant = tenant;
    this._software = software;
    this._subscriptionRequest = subscriptionRequest;
  };

  close = () => {
    this.clear();
  };

  private clear = () => {
    this._visible = false;
    this._parentSubscription = undefined;
  };

  get visible(): boolean {
    return this._visible;
  }

  get parentSubscription(): SubscriptionModel | undefined {
    return this._parentSubscription;
  }

  get tenant(): TenantModel | undefined {
    return this._tenant;
  }

  get software(): SoftwareModel | undefined {
    return this._software;
  }

  get subscriptionRequest(): SubscriptionRequestModel | undefined {
    return this._subscriptionRequest;
  }
}
