import { Label } from '@components/index';
import styled from '@emotion/styled';
import { Avatar, Divider, TextField } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 32px 32px 32px;
  height: fit-content;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 28px 0px 30px 0px;
  align-items: center;
  & .title {
    ${fonts.Headline6};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px;
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
`;

export const Subscriptions = styled.div`
  display: flex;
  flex-direction: column;
  & .total {
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-bottom: 16px;
    & .count {
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
      margin-left: 4px;
    }
  }
  & .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const Subscription = styled.div`
  display: flex;
  flex-direction: row;
  width: 298px;
  height: 96px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  :hover {
    cursor: pointer;
  }

  & .info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0 16px 0;
    overflow: hidden;
    & .app-name-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
    }
    & .app-name {
      ${fonts.Headline8};
      color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .subscription-name {
      ${fonts.Body1};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  margin: 24px 24px 24px 27px;
  border-radius: 6px;
`;

export const StyledDivider = styled(Divider)`
  margin: 17px 0;
  border-color: ${({ theme }) => theme.colors['border-gray-w-lighter']};
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 10px 16px;
    background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

export const IconWrapper = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export const StyledLabel = styled(Label)`
  &.icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin: 0;
    padding: 0;
  }
`;
