import { AxiosError } from 'axios';

export type SmpAxiosError = AxiosError<{ errorCode: { messageId: string }; message: string }>;

const handleDefaultErr = (err: SmpAxiosError) => {
  //
};

const handleCommonErr = (err: SmpAxiosError) => {
  //
};

/**
 * HttpErrHandler를 extends를 통해 필요한 http err status를 확장하여 사용하세요
 */
export interface HttpErrHandler {
  [index: number | string]: ((err: SmpAxiosError) => void) | undefined;
  default?: (err: SmpAxiosError) => void;
  common?: (err: SmpAxiosError) => void;
}

const defaultHandlers: HttpErrHandler = {
  default: handleDefaultErr,
  common: handleCommonErr,
};

export const handleError = (error: SmpAxiosError, handlers?: HttpErrHandler) => {
  const httpStatus = error.response?.status as number; // HTTP Status

  switch (true) {
    case handlers && !!handlers[httpStatus]:
      // 우선순위 1. 컴포넌트에서 (HTTP Status) Key로 재정의한 핸들러
      handlers?.[httpStatus]?.(error);
      break;
    case !!defaultHandlers[httpStatus]:
      // 우선순위 3. Hook에서 (HTTP Status) Key 조합으로 정의한 핸들러
      defaultHandlers[httpStatus]?.(error);
      break;
    default:
      // 우선순위 5. 어디에서도 정의되지 못한 에러를 처리하는 핸들러
      if (handlers && !!handlers.default) handlers?.default(error);
      else handleDefaultErr(error);
  }

  // 공통 처리 로직 수행
  if (handlers && !!handlers.common) handlers?.common(error);
  else handleCommonErr(error);
};
