import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Label } from '@components/index';
import { EmptyRow, TableBodyCell, TableHeaderCell } from '@components/table';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { getSortOrder } from '@utils/CommonUtil';
import { TotalSubscriptionUserListModel } from '@models/subscription/TotalSubscriptionUserListModel';
import { SubscriptionUserTableType } from './SubscriptionUserMgmtList';

export const SubscriptionUserTable = ({
  subscriptionUserLists,
  scrollPackage,
  subscriptionUserFilterPackage,
}: SubscriptionUserTableType) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [setLastSubscriptionList] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting) {
        scrollPackage.handleEndReached();
      }
    },
  });

  const handleChangeSort = (orderBy: string, order: SortOrder | undefined) => {
    subscriptionUserFilterPackage.setQueries(prevFilter => ({
      ...prevFilter,
      sort: order ? [`${orderBy}.${order}`] : [],
    }));
  };

  const handleClickDetailInfo = (subscriptionUser: TotalSubscriptionUserListModel) => {
    navigate(`${pathname}/${subscriptionUser.subscriptionId}`, {
      state: {
        id: subscriptionUser.subscriptionId,
        loginId: subscriptionUser.userLoginId,
        memberStatus: subscriptionUser.tenantMemberStatus,
        softwareIconUrl: subscriptionUser.softwareIconUrl,
        softwareName: subscriptionUser.softwareName,
        subscriptionName: subscriptionUser.subscriptionName,
      },
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell
              width={284}
              height={42}
              orderBy="softwareName"
              order={getSortOrder(subscriptionUserFilterPackage.queries.sort, 'softwareName')}
              onSortChange={handleChangeSort}
            >
              제품명
            </TableHeaderCell>
            <TableHeaderCell
              width={360}
              height={42}
              orderBy="subscriptionName"
              order={getSortOrder(subscriptionUserFilterPackage.queries.sort, 'subscriptionName')}
              onSortChange={handleChangeSort}
            >
              구독명
            </TableHeaderCell>
            <TableHeaderCell
              width={240}
              height={42}
              orderBy="userName"
              order={getSortOrder(subscriptionUserFilterPackage.queries.sort, 'userName')}
              onSortChange={handleChangeSort}
            >
              사용자 이름
            </TableHeaderCell>
            <TableHeaderCell width={320} height={42}>
              메일 계정
            </TableHeaderCell>
            <TableHeaderCell
              width={140}
              height={42}
              orderBy="isAbnormal"
              order={getSortOrder(subscriptionUserFilterPackage.queries.sort, 'isAbnormal')}
              onSortChange={handleChangeSort}
            >
              이상 감지
            </TableHeaderCell>
            <TableHeaderCell
              width={200}
              height={42}
              orderBy="tenantMember"
              order={getSortOrder(subscriptionUserFilterPackage.queries.sort, 'tenantMember')}
              onSortChange={handleChangeSort}
            >
              사용자 구분
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionUserLists.length > 0 ? (
            subscriptionUserLists.map((subscriptionUser, index) => (
              <StyleTableRow
                key={`${subscriptionUser.userName}${String(index)}`}
                onClick={() => handleClickDetailInfo(subscriptionUser)}
              >
                <TableBodyCell>
                  <SoftwareLabel>
                    <SoftwareIcon src={subscriptionUser.softwareIconUrl} />
                    {subscriptionUser.softwareName}
                  </SoftwareLabel>
                </TableBodyCell>
                <TableBodyCell>{subscriptionUser.subscriptionName}</TableBodyCell>
                <TableBodyCell>{subscriptionUser.userName}</TableBodyCell>
                <TableBodyCell>{subscriptionUser.userEmail}</TableBodyCell>
                <TableBodyCell>
                  <Label variant="outline" color={subscriptionUser.isAbnormal === 'Y' ? 'red' : 'gray'}>
                    {subscriptionUser.isAbnormal}
                  </Label>
                </TableBodyCell>
                <TableBodyCell>
                  <Label
                    variant="solid"
                    color={
                      subscriptionUser.tenantMemberStatus === '정상 구성원'
                        ? 'green'
                        : subscriptionUser.tenantMemberStatus === '탈퇴 구성원'
                        ? 'red'
                        : 'gray'
                    }
                  >
                    {subscriptionUser.tenantMemberStatus ? subscriptionUser.tenantMemberStatus : '비구성원'}
                  </Label>
                </TableBodyCell>
              </StyleTableRow>
            ))
          ) : (
            <EmptyRow colSpan={6} title="구독 사용자가 없습니다." />
          )}
        </TableBody>
      </Table>
      {scrollPackage.hasNextPage && <div ref={setLastSubscriptionList} />}
    </TableContainer>
  );
};

const StyleTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const SoftwareLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const SoftwareIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;
