import dayjs from 'dayjs';
import type { SubscriptionRequestDTO } from '@repositories/subscriptionRequestRepository';
import i18n from '@locales/i18n';

export class SubscriptionRequestModel {
  private readonly dto: SubscriptionRequestDTO;

  constructor(dto?: SubscriptionRequestDTO) {
    this.dto = dto ?? {
      id: -1,
      tenant: { id: '', name: '', tenantCode: '' },
      software: { id: '', name: '', productCode: '', planVisibilityScope: 'ALL_USERS' },
      user: { id: '', email: '', username: '', name: '', phone: '', status: 'CREATED' },
      manager: { id: '', email: '', username: '', name: '', phone: '' },
      status: 'CREATED',
      inquiry: '',
      createdDate: '',
      startDate: '',
      endDate: '',
      timeZone: '',
      productPlan: {},
      histories: [],
      type: 'EXTERNAL',
    };
  }

  get isValidModel() {
    return this.id !== -1;
  }

  get productPlanId() {
    return this.dto.productPlan.planId ?? '';
  }

  get productPlanName() {
    return this.dto.productPlan.planName ?? '-';
  }

  hasProductPlanName() {
    return !!this.dto.productPlan.planName;
  }

  get id() {
    return this.dto.id;
  }

  get tenant() {
    return this.dto.tenant;
  }

  get software() {
    return this.dto.software;
  }

  get inquiry() {
    return this.dto.inquiry;
  }

  get requesterName() {
    return this.dto.user.name || '-';
  }

  get managerId() {
    return this.dto.manager?.id ?? '';
  }

  get managerName() {
    return this.dto.manager?.name || '-';
  }

  get user() {
    return this.dto.user;
  }

  get startDate() {
    return this.dto.startDate;
  }

  get endDate() {
    return this.dto.endDate;
  }

  hasCreatedDate() {
    return !!this.dto.createdDate;
  }

  hasSubscriptionId() {
    return this.dto.id === -1;
  }
  get formattedCreatedDateTime() {
    const createdDate = dayjs(this.dto.createdDate).format('YYYY.MM.DD HH:mm');
    return createdDate === 'Invalid Date' ? '-' : createdDate;
  }

  get formattedCreatedDate() {
    const createdDate = dayjs(this.dto.createdDate).format('YYYY.MM.DD');
    return createdDate === 'Invalid Date' ? '-' : createdDate;
  }

  get formattedStartDate() {
    const startDate = dayjs(this.dto.startDate).format('YYYY.MM.DD');
    return startDate === 'Invalid Date' ? '-' : startDate;
  }

  get formattedEndDate() {
    const endDate = dayjs(this.dto.endDate).format('YYYY.MM.DD');
    return endDate === 'Invalid Date' ? '-' : endDate;
  }

  get usagePeriod() {
    if (this.formattedStartDate === '-' && this.formattedEndDate === '-') {
      return '-';
    }
    if (this.formattedStartDate === '-') {
      return `~ ${this.formattedEndDate}`;
    }
    if (this.formattedEndDate === '-') {
      return `${this.formattedStartDate} ~`;
    }
    return `${this.formattedStartDate} ~ ${this.formattedEndDate}`;
  }

  get status() {
    return this.dto.status;
  }

  get timeZone() {
    return this.dto.timeZone;
  }

  get statusStr() {
    return this.getStatusStr(this.status);
  }

  getStatusStr(status: string) {
    switch (status) {
      case 'APPROVED':
        return i18n.t('Subscrib_Detail_UseRequest_12');
      case 'REJECTED':
        return i18n.t('Subscrib_Detail_UseRequest_13');
      case 'CREATED':
        return i18n.t('Subscrib_Detail_UseRequest_10');
      default:
        return i18n.t('Subscrib_Detail_UseRequest_11');
    }
  }

  get statusLabelColor() {
    return this.getStatusLabelColor(this.status);
  }

  getStatusLabelColor(status: string) {
    switch (status) {
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'gray';
      case 'CREATED':
        return 'pink';
      default:
        return 'yellow';
    }
  }

  get histories() {
    return this.dto.histories.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
  }

  getHistories(excludesFirst: boolean) {
    if (this.dto.histories.length === 1) {
      return this.dto.histories;
    }
    return this.dto.histories
      .filter(history => (excludesFirst ? history.status !== 'CREATED' : true))
      .sort((a, b) => (dayjs(a.createdDate).isBefore(dayjs(b.createdDate)) ? 1 : -1));
  }
}
