import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { DescriptionEditEndAdornment, DescriptionEditTextField } from '@components/textfield/DescriptionEditTextField';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PencilIcon } from '@icons/PencilIcon';
import { PlayIcon } from '@icons/PlayIcon';
import type { TriggerInputType, WorkflowExecCreateDTO } from '@repositories/workflowRepository/Types';
import { useAddWorkflow, useExecuteWorkflow, useGetWorkflow, useUpdateWorkflow } from '@queryHooks/useWorkflow';
import type { WorkflowExecModel } from '@models/workflow/WorkflowExecModel';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';
import { getErrorMessage } from '@locales/i18nErrorMessage';
import { WorkflowExecModal } from './exec/WorkflowExecModal';

export const WorkflowDetailHeader = observer(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const isCreate = location.pathname.includes('create');
  const params = useParams();
  const { workflowId, tenantId } = params;

  const {
    workflowDetailStore,
    uiStore: { toastStore, alertStore },
  } = useStore();
  const isEditMode = workflowDetailStore.checkEditable();

  const [isTitleEdit, setIsTitleEdit] = useState<boolean>(false);
  const [tempTitle, setTempTitle] = useState<string>('');
  const [isExecModalOpen, setIsExecModalOpen] = useState<boolean>(false);

  const { data: workflowDetail } = useGetWorkflow(!isCreate ? (workflowId as string) : '', {
    enabled: !isCreate,
  });

  const { mutate: createWorkflow } = useAddWorkflow();
  const { mutate: updateWorkflow } = useUpdateWorkflow(workflowId as string);
  const { mutate: executeWorkflow } = useExecuteWorkflow(workflowId as string);

  useLayoutEffect(() => {
    if (isCreate) {
      workflowDetailStore.setTitle(t('Workflow_Create_01'));
    } else workflowDetailStore.setTitle(workflowDetail?.name || '');
  }, [isCreate, t, workflowDetail?.name, workflowDetailStore]);

  const handleChangeWorkflowName = () => {
    const workflowCreateDTO = workflowDetailStore.toWorflowCreateDTO(tenantId ?? '');
    if (!workflowCreateDTO) return;
    updateWorkflow(workflowCreateDTO, {
      onSuccess: () => {
        toastStore.open(t('Workflow_Create_70'));
      },
      onError: error => {
        alertStore.open({
          title: t('Workflow_Create_72'),
          message: getErrorMessage(error).message,
        });
      },
    });
  };

  const handleClickCreate = () => {
    if (!workflowDetailStore.triggerNode?.data.triggerModel) return;

    const workflowCreateDTO = workflowDetailStore.toWorflowCreateDTO(tenantId ?? '');
    if (!workflowCreateDTO) return;

    if (isCreate) {
      createWorkflow(workflowCreateDTO, {
        onSuccess: (model: WorkflowModel) => {
          const currentPath = location.pathname;
          const newPath = currentPath.replace('/create', `/${model.id}`);
          navigate(newPath);
          workflowDetailStore.setIsEdit(false);
          toastStore.open(t('Workflow_Create_26'));
        },
        onError: error => {
          alertStore.open({
            title: t('Workflow_Create_27'),
            message: getErrorMessage(error).message,
          });
        },
      });
    } else {
      updateWorkflow(workflowCreateDTO, {
        onSuccess: () => {
          navigate('..', { relative: 'path' });
          workflowDetailStore.setIsEdit(false);
          toastStore.open(t('Workflow_Create_70'));
        },
        onError: error => {
          alertStore.open({
            title: t('Workflow_Create_72'),
            message: getErrorMessage(error).message,
          });
        },
      });
    }
  };

  const handleClickModify = () => {
    workflowDetailStore.setIsEdit(true);
    navigate('edit');
  };

  const handleClickCancel = () => {
    navigate('..', { relative: 'path' });
  };

  const handleClickExecute = () => {
    setIsExecModalOpen(true);
  };

  const handleConfirmExecute = (formData: TriggerInputType) => {
    const execCreateDTO: WorkflowExecCreateDTO = {
      inputData: formData,
    };

    executeWorkflow(execCreateDTO, {
      onSuccess: (model: WorkflowExecModel) => {
        navigate(`/manager/${tenantId}/workflows/management/${workflowId}/executions/${model.id}`);
      },
      onError: error => {
        alertStore.open({
          title: t('Workflow_Create_72'),
          message: getErrorMessage(error).message,
        });
      },
    });
  };

  return (
    <>
      <Title isEdit={isTitleEdit}>
        {isEditMode || isTitleEdit ? (
          <DescriptionEditTextField
            value={workflowDetailStore.title}
            fullWidth
            size="small"
            onChange={e => {
              if (e.target.value.length < 51) workflowDetailStore.setTitle(e.target.value);
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') setIsTitleEdit(false);
              if (e.key === 'Escape') {
                workflowDetailStore.setTitle(tempTitle);
                setIsTitleEdit(false);
              }
            }}
            InputProps={{
              endAdornment: !isEditMode && (
                <DescriptionEditEndAdornment
                  textLength={workflowDetailStore.title.length}
                  maxTextLength={50}
                  onConfirm={() => {
                    setIsTitleEdit(false);
                    handleChangeWorkflowName();
                  }}
                  onCancel={() => {
                    workflowDetailStore.setTitle(tempTitle);
                    setIsTitleEdit(false);
                  }}
                />
              ),
            }}
          />
        ) : (
          <>
            <span>{workflowDetailStore.title}</span>
            <IconButton
              onClick={() => {
                setIsTitleEdit(true);
                setTempTitle(workflowDetailStore.title);
              }}
            >
              <PencilIcon width={14} height={14} />
            </IconButton>
          </>
        )}
      </Title>
      <ButtonContainer>
        {isCreate ? (
          <>
            <Button size="medium" variant="contain" paddingHorizontal={67} onClick={handleClickCreate}>
              {t('Create')}
            </Button>
            <Button size="medium" variant="outline" paddingHorizontal={67} onClick={handleClickCancel}>
              {t('Cancel')}
            </Button>
          </>
        ) : !workflowDetailStore.isEdit ? (
          <>
            <Button
              size="medium"
              variant="contain"
              paddingHorizontal={67}
              onClick={handleClickModify}
              disabled={workflowDetail?.status === 'ACTIVE'}
            >
              {t('Edit')}
            </Button>
            <Button
              color="blue"
              size="medium"
              variant="outline"
              paddingHorizontal={57}
              onClick={handleClickExecute}
              disabled={workflowDetail?.status !== 'ACTIVE'}
            >
              <ExecuteIconText>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <PlayIcon width={14} height={14} color="blue" />
                  {t('Execute')}
                </div>
              </ExecuteIconText>
            </Button>
          </>
        ) : (
          <>
            <Button size="medium" variant="contain" paddingHorizontal={67} onClick={handleClickCreate}>
              {t('Save')}
            </Button>
            <Button color="red" size="medium" variant="outline" paddingHorizontal={67} onClick={handleClickCancel}>
              {t('Cancel')}
            </Button>
          </>
        )}
      </ButtonContainer>
      {workflowDetail && isExecModalOpen && (
        <WorkflowExecModal
          open={isExecModalOpen}
          workflow={workflowDetail}
          onSubmit={handleConfirmExecute}
          onClose={() => setIsExecModalOpen(false)}
        />
      )}
    </>
  );
});

const Title = styled.div<{ isEdit: boolean }>`
  width: 100%;
  display: flex;
  gap: ${props => (props.isEdit ? '0px' : '7px')};
  align-items: center;
  ${fonts.Headline6};
`;

const ExecuteIconText = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
`;
