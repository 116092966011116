import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TextField, useTheme } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { SearchIcon } from '@icons/SearchIcon';
import type { Filter } from '@repositories/subscriptionConnectionRepository/Types';
import { useGetSubscriptionConnections } from '@queryHooks/useSubscriptionConnection';
import { Action } from './Action';
import { ControlAction } from './ControlAction';
import { SMPAction } from './SMPAction';

export const ActionSelect = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [filter, setFilter] = useState<Filter>({
    productName: '',
  });

  const { data: connections } = useGetSubscriptionConnections(filter);

  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, productName: event.target.value }));
  };

  return (
    <Container>
      <ToolbarName>{t('Workflow_Create_38')}</ToolbarName>
      <StyledTextField
        fullWidth
        size="medium"
        value={filter.productName}
        placeholder={t('Workflow_Create_39')}
        onChange={handleSearchNameChange}
        InputProps={{
          startAdornment: (
            <IconWrapper>
              <SearchIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
            </IconWrapper>
          ),
        }}
      />
      <SubscriptionHead>
        <SubscriptionHeadTitle>{t('Workflow_Create_Control')}</SubscriptionHeadTitle>
        <SubscriptionHeadDescription>{t('Workflow_Create_ControlDesc')}</SubscriptionHeadDescription>
      </SubscriptionHead>
      <Subscriptions>
        <ControlAction />
      </Subscriptions>
      <SubscriptionHead>
        <SubscriptionHeadTitle>SMP</SubscriptionHeadTitle>
        <SubscriptionHeadDescription>{t('Workflow_Create_49')}</SubscriptionHeadDescription>
      </SubscriptionHead>
      <Subscriptions>
        <SMPAction />
      </Subscriptions>
      <SubscriptionHead>
        <SubscriptionHeadTitle>{t('Workflow_Create_68')}</SubscriptionHeadTitle>
        <SubscriptionHeadDescription>{t('Workflow_Create_25')}</SubscriptionHeadDescription>
      </SubscriptionHead>
      <Subscriptions>
        {connections?.totalElements === 0 && (
          <ErrorBox>
            <span>
              <ErrorIcon width={14.67} height={14.67} color={theme.colors['ic-purple']} />
            </span>
            <p>{t('Action_Select_01')}</p>
          </ErrorBox>
        )}
        {connections?.content
          .filter(con => con.status === 'CONNECTED')
          .filter(con => con.connection.connector.actions.length)
          ?.map(con => {
            return <Action key={con.connectionId} subscriptionConnection={con} />;
          })}
      </Subscriptions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: inherit;
`;

const ToolbarName = styled.strong`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
  border-radius: 2px;
  ${fonts.Body2};
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 10px 16px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    ${fonts.Body2};
  }
  & .MuiInputBase-input {
    ${fonts.Body2};
  }
`;

const IconWrapper = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const SubscriptionHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-top: 36px;
`;

const SubscriptionHeadTitle = styled.strong`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const SubscriptionHeadDescription = styled.p`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const Subscriptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

const ErrorBox = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors['bg-white']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);

  & span {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
  }
  & p {
    ${fonts.Body2};
  }
`;
