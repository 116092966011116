import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Modal, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { UserGroupSelect } from '@components/userGroupSelect/UserGroupSelect';
import { useStore } from '@stores/RootStore';
import { XIcon } from '@icons/XIcon';
import { UserGroupCreateRq } from '@repositories/userGroupsRepository/Types';
import { useAddUserGroup } from '@queryHooks/index';
import { useGetTenantList } from '@queryHooks/useTenant';
import * as S from './UserGroupCreateStyles';
import { FormUser, useUserGroupForm } from './validationSchema';

export const UserGroupCreate = observer(() => {
  const { t } = useTranslation();
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isUserGroupCodeError, setIsUserGroupCodeError] = useState<boolean>(false);
  const [selectMenuCurValue, setSelectMenuCurValue] = useState({
    userGroupId: '',
    name: '',
  });
  const theme = useTheme();
  const {
    uiStore: { userGroupCreateStore: modal },
  } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useUserGroupForm();

  const { mutate } = useAddUserGroup();
  const { data: tenants } = useGetTenantList({ isRootTenant: true }, { enabled: modal.visible });

  const validateHandler = {
    success: (data: FormUser) => {
      try {
        if (tenants) {
          const rqData: UserGroupCreateRq = {
            name: data.name,
            parentUserGroupId: selectMenuCurValue.userGroupId,
            description: data.description,
            tenantId: tenants.content[0].id,
            userGroupCode: data.code,
          };
          mutate(rqData, {
            onError: error => {
              console.error('에러', error);
              if (axios.isAxiosError(error) && error.response) {
                const { messageId } = error.response.data?.errorCode || {};
                if (messageId === 'USER_GROUP.ERROR.DUPLICATED_USER_GROUP_CODE') {
                  setIsUserGroupCodeError(true);
                }
              }
            },
            onSuccess: () => {
              modal.close();
              reset();
              setIsUserGroupCodeError(false);
            },
          });
        }
      } catch (error) {
        console.error('에러', error);
      }
    },
    fail: () => {
      console.log('error');
    },
  };
  const nameValue = watch('name');

  const handleSeleted = (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.currentTarget.dataset.name !== undefined && e.currentTarget.dataset.userGroupId !== undefined) {
      setSelectMenuCurValue({
        name: e.currentTarget.dataset.name,
        userGroupId: e.currentTarget.dataset.userGroupId,
      });
    }
    setIsSelectOpen(!isSelectOpen);
  };

  useEffect(() => {
    setSelectMenuCurValue({ userGroupId: '', name: tenants?.content[0].name ?? '' });
  }, [tenants]);

  return (
    <Modal open={modal.visible} onClose={modal.close}>
      <S.Continer>
        <S.ModalHeader>
          <Typography variant="headline6" component="strong">
            {t('Member_Group_Create_Individual')}
          </Typography>
          <S.ModalCloseBtn size="small" variant="text" onClick={modal.close}>
            <XIcon width={24} height={24} />
          </S.ModalCloseBtn>
        </S.ModalHeader>
        <form onSubmit={handleSubmit(validateHandler.success, validateHandler.fail)}>
          <S.ContentWrapper>
            <S.ContentItem>
              <Typography variant="headline8" component="strong">
                {t('Member_Group_Bulk_Create_18')}
              </Typography>
              <TextField
                {...register('name', { required: '테넌트 이름을 입력해주세요' })}
                autoComplete="off"
                error={!!errors.name?.message && nameValue.length === 0}
                placeholder={t('Member_Group_Bulk_Create_18')}
                helperText={nameValue.length === 0 && errors.name?.message}
              />
            </S.ContentItem>
            <S.ContentItem>
              <Typography variant="headline8" component="strong">
                {t('Member_Group_Bulk_Create_17')}
              </Typography>
              <TextField
                {...register('code', { required: '그룹 코드를 입력해주세요.' })}
                autoComplete="off"
                error={!!errors.code?.message || isUserGroupCodeError}
                placeholder={t('Member_Group_Bulk_Create_17')}
                helperText={isUserGroupCodeError ? `${t('Member_Group_Delete_07')}` : errors.code?.message}
              />
            </S.ContentItem>
            <S.ContentItem>
              <Typography variant="headline8" component="strong">
                {t('Member_Group_Create_04')}
              </Typography>

              <UserGroupSelect
                selectedValue={{ name: selectMenuCurValue.name, userGroupId: selectMenuCurValue.userGroupId }}
                onSelectChange={handleSeleted}
              />
              <Typography variant="body4" component="p" color={theme.colors['text-gray-light']}>
                {t('Member_Group_Create_09')}
              </Typography>
            </S.ContentItem>
            <S.ContentItem>
              <Typography variant="headline8" component="strong">
                {t('Member_Group_Create_05')}
              </Typography>
              <S.DescriptionField
                {...register('description')}
                autoComplete="off"
                placeholder={t('Member_Group_Create_05')}
                multiline
                rows={5}
              />
            </S.ContentItem>
          </S.ContentWrapper>
          <S.BottomBtnsWrapper>
            <StyledButton
              size="extraLarge"
              variant={nameValue.length === 0 ? 'outline' : 'contain'}
              disabled={nameValue.length === 0}
              type="submit"
            >
              {t('Member_User_24')}
            </StyledButton>
            <Button size="extraLarge" variant="outline" onClick={modal.close}>
              {t('Subscrib_Detail_BuyRequest_13')}
            </Button>
          </S.BottomBtnsWrapper>
        </form>
      </S.Continer>
    </Modal>
  );
});

const StyledButton = styled(Button)`
  &:disabled {
    background-color: ${({ theme }) => theme.colors['bg-gray-dark']};
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &:hover {
      background-color: ${({ theme }) => theme.colors['bg-gray-dark']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
  }
`;
