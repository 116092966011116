// CustomEdge.tsx
import type { EdgeProps } from 'reactflow';
import { BaseEdge, getBezierPath } from 'reactflow';
import { observer } from 'mobx-react';

export const ConditionThenEdge = observer(
  ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd }: EdgeProps) => {
    const [edgePath] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    return (
      <>
        <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={{ ...style }} />
        <text>
          <textPath href={`#${id}`} style={{ fontSize: 12, fill: 'blue' }} startOffset="50%" textAnchor="middle">
            Then
          </textPath>
        </text>
      </>
    );
  },
);
