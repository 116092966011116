// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const CircleDashFillIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  rotateNum = 0,
  bgColor = '#fff',
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotateNum}deg)` }}
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.799805 12C0.799805 5.81446 5.81422 0.800049 11.9998 0.800049C18.1854 0.800049 23.1998 5.81446 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12Z"
          fill={color}
        />
        <rect x="6.5" y="11.1001" width="11" height="2.2" rx="1.1" fill={bgColor} />
      </g>
    </svg>
  );
};

CircleDashFillIcon.displayName = 'CircleDashFillIcon';
