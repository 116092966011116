import type { InputBaseComponentProps, TextFieldProps } from '@mui/material';
import type { ElementType, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TextField, css } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { DatePicker } from '@components/datePicker';
import { Dropdown } from '@components/dropdown';
import { ErrorText } from '@components/form/ErrorText';
import { CurrencyAdorment } from '@components/inputs/CurrencyAdorment';
import { CurrencyInput } from '@pages/customer/common/CurrencyInput';
import { fonts } from '@theme/fontsCustomer';
import type { BillingStatus } from '@repositories/monthlyBillingRepository';
import type { MonthlyBillingUpdateHookForm } from '@hookForms/MonthlyBillingUpdateHookForm';
import {
  useRecalculateMonthlyBillingCost,
  useRecalculateMonthlyBillingOriginalCost,
  useUpdateMonthlyBillingStatus,
} from '@queryHooks/useMonthlyBilling';
import { transientOptions } from '@utils/CommonUtil';
import { dateFormat } from '@utils/dateUtil';
import { currencyNumberFormat } from '@utils/numberFormat';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import { CostMismatchTooltipIcon } from './CostMismatchTooltipIcon';

type Props = {
  subscription: SubscriptionModel;
  billing: SubscriptionBillingModel;
  isEditable: boolean;
  isEditMode: boolean;
};

export const BillingInfo = ({ subscription, billing, isEditable, isEditMode = false }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<MonthlyBillingUpdateHookForm>();

  const { mutate: updateMonthlyBillingStatus } = useUpdateMonthlyBillingStatus();
  const { mutate: recalculateBillingCost } = useRecalculateMonthlyBillingCost();
  const { mutate: recalculateBillingOriginalCost } = useRecalculateMonthlyBillingOriginalCost();

  const handleChangeBillingStatus = (value: string | number) => {
    updateMonthlyBillingStatus({ id: billing.id, status: value as BillingStatus });
  };

  const renderBillingInfoEditField = (
    name: 'cost' | 'originalCost' | 'exchangeRate',
    inputComponent?: ElementType<InputBaseComponentProps>,
    startAdornment?: ReactNode,
  ) => (
    <>
      <Controller
        control={control}
        name={`${name}`}
        render={({ field: { ...props } }) => (
          <EditTextField
            {...props}
            InputProps={{
              inputComponent,
              startAdornment,
            }}
          />
        )}
      />
      {errors[name]?.message && <ErrorText>{errors[name]?.message}</ErrorText>}
    </>
  );

  const renderOriginalCostTextField = () => {
    if (isEditMode) {
      return renderBillingInfoEditField(
        'originalCost',
        CurrencyInput,
        <CurrencyAdorment currency={billing.originalCurrencyUnit} />,
      );
    }

    return <span>{currencyNumberFormat(billing.originalCost, billing.originalCurrencyUnit, 'text')}</span>;
  };

  const renderExchangeRateTextField = () => {
    if (isEditMode) {
      return renderBillingInfoEditField('exchangeRate', undefined, undefined);
    }

    return (
      <span>
        {billing.exchangeRate ? currencyNumberFormat(billing.exchangeRate, billing.currencyUnit, 'text') : '-'}
      </span>
    );
  };

  const renderCostTextField = () => {
    if (isEditMode) {
      return renderBillingInfoEditField('cost', CurrencyInput, <CurrencyAdorment currency={billing.currencyUnit} />);
    }

    return <span>{currencyNumberFormat(billing.cost, billing.currencyUnit, 'text')}</span>;
  };

  const renderPaymentDateDatePicker = () => {
    if (isEditMode) {
      return (
        <>
          <Controller
            control={control}
            name="paymentDate"
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={value}
                inputFormat={t('DateFormat_YMD')}
                onChange={value => {
                  return onChange(dayjs(value).format('YYYY.MM.DD'));
                }}
                showDaysOutsideCurrentMonth
                renderInput={props => (
                  <DatePickerInput {...props} onChange={onChange} size="small" fullWidth autoFocus />
                )}
              />
            )}
          />
          {errors.paymentDate?.message && <ErrorText>{errors.paymentDate.message}</ErrorText>}
        </>
      );
    }

    return dateFormat(billing?.paymentDate, '-', t('DateFormat_YMD'));
  };

  const handleClickRecalculateOriginalCostBtn = () => {
    recalculateBillingOriginalCost(billing.id);
  };

  const handleClickRecalculateCostBtn = () => {
    recalculateBillingCost(billing.id);
  };

  return (
    <BillingInfoGrid>
      <BillingInfoItem>
        <BillingInfoItemTitle>
          <BillingInfoItemTitleText>{t('Acc_Main_06')}</BillingInfoItemTitleText>
        </BillingInfoItemTitle>
        <dd>{dateFormat(billing?.month, '-', t('DateFormat_YM'))}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle>
          <BillingInfoItemTitleText>{t('Subscrib_Main_12')}</BillingInfoItemTitleText>
        </BillingInfoItemTitle>
        <dd>{billing.subscription.productPlan.planName}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle>
          <BillingInfoItemTitleText>
            <BillingInfoItemTitleText>{t('Subscrib_Edit_01')}</BillingInfoItemTitleText>
          </BillingInfoItemTitleText>
        </BillingInfoItemTitle>
        <dd>{`${subscription.commitmentPeriod} / ${subscription.paymentPeriod}`}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle>
          <BillingInfoItemTitleText>{t('Acc_Main_16')}</BillingInfoItemTitleText>
        </BillingInfoItemTitle>
        <dd>
          {billing?.isConsolidatedBilling ? (
            <Label variant="solid" color="purple">
              {t('Acc_Main_24')}
            </Label>
          ) : (
            <Label variant="solid" color="orange">
              {t('Acc_Main_25')}
            </Label>
          )}
        </dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle $isEditState={isEditMode}>
          <BillingInfoItemTitleText>{t('Acc_Create_31')}</BillingInfoItemTitleText>
          {subscription.isExternalSubscription && (billing.originalCostDiff ?? 0) !== 0 && (
            <CostMismatchTooltipIcon
              message={t('Acc_Detail_OriginalCostMismatched')}
              onClickButton={handleClickRecalculateOriginalCostBtn}
            />
          )}
        </BillingInfoItemTitle>
        <dd>{renderOriginalCostTextField()}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle $isEditState={isEditMode}>
          <BillingInfoItemTitleText>{t('Acc_Create_10')}</BillingInfoItemTitleText>
        </BillingInfoItemTitle>
        <dd>{renderExchangeRateTextField()}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle $isEditState={isEditMode}>
          <BillingInfoItemTitleText>{t('Acc_Main_13')}</BillingInfoItemTitleText>
          {subscription.isExternalSubscription && (billing.costDiff ?? 0) !== 0 && (
            <CostMismatchTooltipIcon
              message={t('Acc_Detail_CostMismatched')}
              onClickButton={handleClickRecalculateCostBtn}
            />
          )}
        </BillingInfoItemTitle>
        <dd>{renderCostTextField()}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle $isEditState={isEditMode}>
          <BillingInfoItemTitleText>{t('Acc_Main_20')}</BillingInfoItemTitleText>
        </BillingInfoItemTitle>
        <dd> {renderPaymentDateDatePicker()}</dd>
      </BillingInfoItem>
      <BillingInfoItem>
        <BillingInfoItemTitle>상태</BillingInfoItemTitle>
        <dd>
          {isEditable ? (
            <StateDropdown
              value={billing.status}
              onChange={handleChangeBillingStatus}
              variant="no-line"
              spaceBetweenMenu={3}
            >
              <Dropdown.Menu value="CREATED">{t('Acc_Detail_07')}</Dropdown.Menu>
              <Dropdown.Menu value="PAID">{t('Acc_Detail_06')}</Dropdown.Menu>
            </StateDropdown>
          ) : (
            <Label variant="solid" color={billing.isForecasted ? 'blue' : billing.isPayment ? 'gray' : 'yellow'}>
              {/* FIXME: i18n */}
              {billing.isForecasted ? '정산 예측' : billing.isPayment ? t('Acc_Detail_06') : t('Acc_Detail_07')}
            </Label>
          )}
        </dd>
      </BillingInfoItem>
      <BillingInfoItem>
        {!billing.isManuallyEdited && (
          <>
            <BillingInfoItemTitle>{t('Acc_Detail_13')}</BillingInfoItemTitle>
            <dd>-</dd>
          </>
        )}
      </BillingInfoItem>
    </BillingInfoGrid>
  );
};

const BillingInfoGrid = styled.dl`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 1px;
  column-gap: 1px;
  background-color: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  margin-bottom: 48px;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

const BillingInfoItem = styled.div`
  padding: 15px 20px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  position: relative;

  & > dd {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

const BillingInfoItemTitle = styled('dt', transientOptions)<{ $isEditState?: boolean }>`
  ${fonts.Headline9}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin-bottom: 2px;
  opacity: ${({ $isEditState }) => ($isEditState ? 0.2 : 1)};
`;

const BillingInfoItemTitleText = styled.span`
  vertical-align: middle;
`;

const EditTextFieldCss = css`
  margin: -6px -12px;
  width: calc(100% + 24px);
  & .MuiInputBase-root {
    padding: 4px 4px 4px 12px;
  }
`;

const EditTextField = styled((props: TextFieldProps) => <TextField size="small" autoFocus fullWidth {...props} />)`
  ${EditTextFieldCss};
`;

const DatePickerInput = styled(DatePicker.Input)`
  ${EditTextFieldCss}
`;

const StateDropdown = styled(Dropdown)`
  margin: -4px 0px -4px -12px;
  width: 108px;
  padding: 3px 8px 3px 12px;
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;
