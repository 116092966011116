import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Box, IconButton, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import ReactPlayer from 'react-player/lazy';
import { Carousel } from '@components/carousel';
import { Editor } from '@components/editor';
import { Button } from '@components/index';
import { PurchaseRequestModal } from '@components/products/purchaseRequest/PurchaseRequestModal';
import { UsageRequestModal } from '@components/products/usageRequest';
import { Tab, TabPanel } from '@components/tab';
import { CartModal } from '@pages/customer/common/cart/CartModal';
import { useStore } from '@stores/RootStore';
import uiStore from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { CartIcon, DesktopIcon, FolderIcon, OrganizationIcon, SupportIcon, DescriptionIcon } from '@icons/index';
import { useGetSoftware } from '@queryHooks/useSoftware';
import { transientOptions } from '@utils/index';
import { ContactUs } from '../../contactUs/ContactUs';
import { ProductDetailRelatedProducts } from './ProductDetailRelatedProducts';
import { ProductPlan } from './ProductPlan';

export const ProductDetail = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname + location.search;
  const { id: softwareId } = useParams();
  const [isOpenPurchaseRqModal, setIsOpenPurchaseRqModal] = useState<boolean>(false);
  const [isOpenRqModal, setIsOpenRqModal] = useState<boolean>(false);
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState<boolean>(false);
  const [isOpenCartModal, setIsOpenCartModal] = useState<boolean>(false);
  const { data: software } = useGetSoftware(softwareId ?? '');
  const {
    authStore,
    uiStore: { isItAdminTypeUi },
  } = useStore();
  const [tab, setTab] = useState<number>(0);
  const [isSupportHover, setIsSupportHover] = useState(false);
  const [isCartHover, setIsCartHover] = useState(false);

  const hasLoginAndPlanVisibility = (): boolean => {
    const { planVisibilityScope } = software;
    const { isLogin } = authStore;

    if (planVisibilityScope === 'ALL_USERS') return true;
    if (planVisibilityScope === 'AUTHENTICATED_USERS') return isLogin;

    return false;
  };

  const handleChangeTab = (value: number) => {
    setTab(value);
  };

  const handleClickSubscribe = useCallback(() => {
    setIsOpenRqModal(true);
  }, []);

  const handleClickContactUs = useCallback(() => {
    setIsOpenContactUsModal(true);
  }, []);

  const handleClickAddItemToCart = () => {
    setIsOpenCartModal(true);
  };

  const handlClickPurchaseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenPurchaseRqModal(true);
  };

  const handleClickLoginBtn = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleClickLogin = () => {
    navigate(`/login`, { state: { routeAfterLogin: currentUrl } });
  };

  const isPreviewInfiniteLoop = software.previews?.length >= 3;

  return (
    <>
      <Container>
        <Inner>
          <Header $isItAdminTypeUi={isItAdminTypeUi}>
            <TitleBox>
              <Icon src={software.iconUrl} />
              <Title>{software.name}</Title>
            </TitleBox>
            {software.providerId !== null && (
              <ButtonBox>
                <ContactButton
                  onMouseEnter={() => setIsSupportHover(true)}
                  onMouseLeave={() => setIsSupportHover(false)}
                  onClick={handleClickContactUs}
                  className={matches ? 'mobile-contact' : ''}
                  startIcon={
                    <SupportIcon
                      width={24}
                      height={24}
                      color={
                        isSupportHover
                          ? theme.colors['ic-purple']
                          : matches
                          ? theme.colors['ic-white']
                          : theme.colors['ic-gray-main']
                      }
                    />
                  }
                >
                  {t('Product_Inquiry_01')}
                </ContactButton>
                {isItAdminTypeUi ? (
                  <CartButton
                    onMouseEnter={() => setIsCartHover(true)}
                    onMouseLeave={() => setIsCartHover(false)}
                    onClick={handleClickAddItemToCart}
                  >
                    <CartIcon
                      width={24}
                      height={24}
                      color={isCartHover ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
                    />
                  </CartButton>
                ) : null}
                <SubscribeButton
                  onClick={
                    uiStore.isItAdminTypeUi
                      ? handlClickPurchaseModal
                      : authStore.isLogin
                      ? handleClickSubscribe
                      : handleClickLoginBtn
                  }
                >
                  <SubscribeTitle>
                    {isItAdminTypeUi ? t('Subscrib_Detail_BuyRequest_09') : t('Product_Cardview_01')}
                  </SubscribeTitle>
                </SubscribeButton>
              </ButtonBox>
            )}
          </Header>
          <Content>
            <DescriptionGrid>
              <DescriptionCell>
                <FolderIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
                <DescriptionText>{software.categoryNames}</DescriptionText>
              </DescriptionCell>
              <DescriptionCell>
                <DesktopIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
                <DescriptionLinkText href={software.url} target="_blank">
                  {software.url}
                </DescriptionLinkText>
              </DescriptionCell>
              <DescriptionCell>
                <OrganizationIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
                <DescriptionText>{software.companyName}</DescriptionText>
              </DescriptionCell>
              <DescriptionCell>
                <DescriptionIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
                <DescriptionText>{software.description}</DescriptionText>
              </DescriptionCell>
            </DescriptionGrid>

            <PreviewCarousel
              centerMode={!!matches}
              centerPadding={matches ? '16px' : '0'}
              infiniteLoop={isPreviewInfiniteLoop}
            >
              {software.previews?.map(preview =>
                preview?.dataType === 'video' ? (
                  <ReactPlayer
                    className="preview"
                    key={preview.url}
                    url={preview.url}
                    width={matches ? '100%' : '418px'}
                    height={matches ? '100%' : '260px'}
                    light={false}
                  />
                ) : (
                  <div className="preview img">
                    <img key={preview.url} src={preview?.url} alt="previewImg" width="100%" height="100%" />
                  </div>
                ),
              )}
            </PreviewCarousel>

            <AppDetailsWrapper>
              <AppDetails>
                <Tab tabs={['OVERVIEW', 'PLAN']} value={tab} onChangeTab={handleChangeTab} />
                <TabContent>
                  <TabPanel index={0} tab={tab}>
                    <Editor value={software.overview} readOnly />
                  </TabPanel>
                  <TabPanel index={1} tab={tab}>
                    {hasLoginAndPlanVisibility() ? (
                      <ProductPlan />
                    ) : (
                      <BlurWrapper>
                        <BlurBox>
                          <ProductPlan />
                        </BlurBox>
                        <LoginBox onClick={handleClickLogin}>로그인하고 요금제 확인하기</LoginBox>
                      </BlurWrapper>
                    )}
                  </TabPanel>
                </TabContent>
              </AppDetails>

              {software.id && <ProductDetailRelatedProducts software={software} />}
            </AppDetailsWrapper>
          </Content>
        </Inner>
      </Container>
      {isOpenPurchaseRqModal ? (
        <PurchaseRequestModal
          isOpen={isOpenPurchaseRqModal}
          onClose={() => setIsOpenPurchaseRqModal(false)}
          swId={software.id}
        />
      ) : null}

      <UsageRequestModal isOpen={isOpenRqModal} onClose={() => setIsOpenRqModal(false)} swId={software.id} />
      <ContactUs isOpen={isOpenContactUsModal} onClose={() => setIsOpenContactUsModal(false)} swId={software.id} />
      <CartModal isOpen={isOpenCartModal} onClose={() => setIsOpenCartModal(false)} swId={software.id} />
    </>
  );
});

const BlurWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const BlurBox = styled.div`
  filter: blur(8px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  ${fonts.Button2}
  position: absolute;
  display: flex;
  width: 380px;
  height: 54px;
  top: 150px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors['bg-gradient']};
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  color: ${({ theme }) => theme.colors['text-white']};
  cursor: pointer;
`;

const Container = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #fff ${({ theme }) => theme.breakpoints.between('xs', 'xl')} {
    margin-top: 40px;
    padding: 0 32px;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors['bg-gray-light']};
  } ;
`;
const Inner = styled(Box)`
  max-width: 1280px;
  height: 100%;
  padding-top: 30px;
  margin: 0 auto;
`;
const Content = styled(Box)`
  width: 100%;
  height: 100%;

  margin: 0 auto;
  background-color: #fff;
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    padding: 24px 0 24px;
    margin-top: 10px;
  } ;
`;

const Header = styled(Box, transientOptions)<{ $isItAdminTypeUi: boolean }>`
  display: flex;
  align-items: center;
  position: sticky;
  top: var(--employee-gnb-height);
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
  padding: ${({ $isItAdminTypeUi }) => ($isItAdminTypeUi ? '50px' : '60px')} 0 32px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 14px;
    position: initial;
    padding: 8px 16px 16px;
  } ;
`;
const TitleBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Icon = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 12px;
  margin-right: 16px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    width: 72px;
    height: 72px;
    border-radius: 14px;
  } ;
`;
const Title = styled('h2')`
  ${fonts.Headline2};
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    ${fonts.Headline4};
    align-self: flex-start;
    margin-top: 4px;
  } ;
`;
const ButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  flex-shrink: 0;
  gap: 12px;
  margin-left: auto;
  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    margin-left: 20px;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    width: 100%;
    justify-content: flex-start;
    gap: 0;
    margin-left: 0;
  } ;
`;
const ContactButton = styled(Button)`
  width: 48px;
  height: 48px;
  min-width: auto;
  margin-left: auto;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  margin-left: 36px;
  &:hover {
    color: #ffffff;
    background: ${({ theme }) => theme.colors['state-white-purple-pressed']};
    border: 1px solid #5a32a3;
  }
  & > span {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    width: 100%;
    height: 40px;
    gap: 4px;
    margin: 0;
    padding: 0 30px;
    background: ${({ theme }) => theme.colors['bg-purple']};
    border: 1px solid ${({ theme }) => theme.colors['border-purple']};
    & > span {
      display: block;
      ${fonts.Button2};
      color: ${({ theme }) => theme.colors['text-white']};
    }
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    width: 100%;
    height: 40px;
    gap: 4px;
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors['bg-purple']};
    border: 1px solid ${({ theme }) => theme.colors['border-purple']};

    & > span {
      display: block;
      ${fonts.Button2};
      color: ${({ theme }) => theme.colors['text-white']};
    }
    &:hover {
      color: #fff;
      background: ${({ theme }) => theme.colors['bg-purple']};
      border: 1px solid ${({ theme }) => theme.colors['border-purple']};
    }
  }
`;

const CartButton = styled(IconButton)`
  width: 48px;
  height: 48px;
  padding: 12px;
  background: ${({ theme }) => theme.colors['bg-white']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 3px;
  &:hover {
    color: #ffffff;
    background: ${({ theme }) => theme.colors['state-white-purple-pressed']};
    border: 1px solid #5a32a3;
  }
`;

const SubscribeButton = styled(Button)`
  max-width: 330px;
  min-width: 330px;
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.colors['bg-purple']};
  border: 1px solid ${({ theme }) => theme.colors['border-bk-opacity-15%']};
  border-radius: 3px;
  &:hover {
    color: #ffffff;
    background: ${({ theme }) => theme.colors['state-purple-hover']};
    border: 1px solid #5a32a3;
  }
  &.Mui-disabled {
    background-color: #d1d5da;
    color: #959da5;
    border-color: #d1d5da;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    display: none;
  } ;
`;
const SubscribeTitle = styled('span')`
  ${fonts.Button2};
  color: ${({ theme }) => theme.colors['text-white']};
`;

const DescriptionGrid = styled(Box)`
  width: 100%;
  background: ${({ theme }) => theme.colors['bg-gray-lighter']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 24px 50px;
  gap: 14px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    padding: 24px 28px;
    margin: 0 16px;
    gap: 20px;
  } ;
`;
const DescriptionCell = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  overflow: hidden;
  & svg {
    flex-shrink: 0;
  }
`;

const DescriptionText = styled('p')`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${fonts.Body2};
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    max-height: 60px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: initial;
  } ;
`;
const DescriptionLinkText = styled('a')`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &[href] {
    color: ${({ theme }) => theme.colors['text-blue-light']};
  }
  ${fonts.Body2};
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    max-height: 60px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: initial;
  } ;
`;

const PreviewCarousel = styled(Carousel)`
  padding: 50px 0px 80px 0px;
  & .slick-prev {
    left: -16px;
  }
  & .slick-next {
    right: -7px;
  }
  & .slick-slide {
    & > div {
      width: 418px;
      height: 260px;
      & .preview {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        &.img {
          width: 418px;
          height: 260px;
        }
        &::after {
          display: block;
          content: '';
          width: 418px;
          height: 260px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          border: 1px solid rgba(0, 0, 0, 0.1);
          z-index: -1;
          border-radius: 3px;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: 28px 0px 34px;
    & .slick-slide {
      padding: 0 8px;
      & > div {
        width: 100%;
        height: 204px;
        & .preview {
          border-radius: 3px;
          overflow: hidden;
          &.img {
            width: 418px;
            height: 260px;
            ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
              width: 100%;
              height: 204px;
            }
          }
        }
      }
    }
  }
`;

const AppDetails = styled(Box)`
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px 0px;
  flex: 3;
  ${({ theme }) => theme.breakpoints.between('sm', 'xl')} {
    max-width: calc(100% - 330px - 50px);
  }
  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    padding: 0;
  }
  & .MuiTabs-root {
    position: sticky;
    top: 185px;
    overflow: initial;
    z-index: 100;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'lg')} {
    & .MuiTabs-root {
      position: relative;
      top: 0;
      overflow: hidden;
      z-index: 0;
    }
  }
`;

const AppDetailsWrapper = styled(Box)`
  display: flex;
  gap: 50px;
  & .MuiTabs-root {
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid #e1e4e8;
      z-index: 1;
    }
  }
  & .MuiTabs-indicator {
    z-index: 10;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    flex-direction: column;
    gap: 0;
  } ;
`;

const TabContent = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    padding: 20px 16px;
    margin: 0;
  } ;
`;
