const defaultWidth = 20;
const defaultHeight = 20;

export const ImportantFillIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  ...props
}: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M10.8644 2.19187C11.0057 1.84681 11.4943 1.84681 11.6356 2.19187L13.7514 7.35973C13.7937 7.4631 13.8758 7.54514 13.9792 7.58746L19.147 9.70329C19.4921 9.84456 19.4921 10.3332 19.147 10.4745L13.9792 12.5903C13.8758 12.6326 13.7937 12.7147 13.7514 12.818L11.6356 17.9859C11.4943 18.331 11.0057 18.331 10.8644 17.9859L8.74857 12.818C8.70625 12.7147 8.62421 12.6326 8.52084 12.5903L3.35298 10.4745C3.00793 10.3332 3.00793 9.84456 3.35298 9.70329L8.52084 7.58746C8.62421 7.54514 8.70625 7.4631 8.74857 7.35973L10.8644 2.19187Z"
          fill={color}
        />
        <path
          d="M3.51864 13.0655C3.6034 12.8585 3.8966 12.8585 3.98136 13.0655L4.73734 14.912C4.76273 14.974 4.81196 15.0232 4.87398 15.0486L6.72044 15.8046C6.92748 15.8894 6.92748 16.1826 6.72044 16.2673L4.87398 17.0233C4.81196 17.0487 4.76273 17.0979 4.73734 17.1599L3.98136 19.0064C3.8966 19.2134 3.6034 19.2134 3.51864 19.0064L2.76266 17.1599C2.73727 17.0979 2.68804 17.0487 2.62602 17.0233L0.779557 16.2673C0.572522 16.1826 0.572522 15.8894 0.779557 15.8046L2.62602 15.0486C2.68804 15.0232 2.73727 14.974 2.76266 14.912L3.51864 13.0655Z"
          fill={color}
        />
        <path
          d="M3.51864 1.39852C3.6034 1.19149 3.8966 1.19149 3.98136 1.39852L4.73734 3.24499C4.76273 3.30701 4.81196 3.35623 4.87398 3.38162L6.72044 4.13761C6.92748 4.22237 6.92748 4.51556 6.72044 4.60033L4.87398 5.35631C4.81196 5.3817 4.76273 5.43093 4.73734 5.49294L3.98136 7.33941C3.8966 7.54644 3.6034 7.54644 3.51864 7.33941L2.76266 5.49294C2.73727 5.43092 2.68804 5.3817 2.62602 5.35631L0.779557 4.60032C0.572522 4.51556 0.572521 4.22237 0.779557 4.1376L2.62602 3.38162C2.68804 3.35623 2.73727 3.30701 2.76266 3.24499L3.51864 1.39852Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ImportantFillIcon.displayName = 'ImportantFillIcon';
