import type { ChangeEvent, HTMLAttributes } from 'react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import type { TableComponents } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';
import styled from '@emotion/styled';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  css,
  useTheme,
} from '@mui/material';
import * as FileSaver from 'file-saver';
import { nanoid } from 'nanoid';
import * as XLSX from 'xlsx';
import { Button, EmptyRow } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { ChevronIcon } from '@icons/ChevronIcon';
import { DownloadIcon } from '@icons/DownloadIcon';
import { CheckboxOffNoIcon, CheckedBgIcon, ExcelIcon, RadioIcon, RadioOffIcon } from '@icons/index';
import type { TenantMemberBulkUpdateDTO } from '@repositories/tenantMemberRepository/Types';
import { useAddBatchUsers } from '@queryHooks/useTenantMember';
import { useGetUserGroups } from '@queryHooks/useUserGroup';
import { transientOptions } from '@utils/CommonUtil';

type Sheet = {
  name: string;
  account: string;
  groupCode: string;
  phone?: string;
  checked: boolean;
  id: string;
};

type ExcelSheet = {
  이름: string;
  계정: string;
  '그룹 코드': string;
  연락처?: string;
};

type ValidateRow = {
  code: Error;
  name: Error;
  account: Error;
};

type Error = {
  err: boolean;
  message: string;
};

type BatchType = 'update' | 'replace';

type TableContext = {
  emptyDataMessage: string;
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
`;

const StyledTable = styled(Table)`
  border-radius: 5px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
`;

const tableComponents: TableComponents<Sheet, TableContext> = {
  Scroller: React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>((props, ref) => (
    <TableWrapper {...props} ref={ref} />
  )),
  Table: StyledTable,
  TableHead,
  TableRow: ({ children, ...props }) => <StyledTableRow {...props}>{children}</StyledTableRow>,
  TableBody,
  EmptyPlaceholder: ({ context }) =>
    context ? (
      <TableBody>
        <EmptyRow colSpan={5} title={context.emptyDataMessage} />
      </TableBody>
    ) : null,
};

export const UserBatchAdd = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const {
    uiStore: { toastStore, alertStore },
  } = useStore();
  const navigate = useNavigate();

  const params = useParams();
  const tenantId = params.tenantId as string;

  const fileRef = useRef<HTMLInputElement | null>(null);

  const [batchType, setBatchType] = useState<BatchType | undefined>(undefined);
  const [usersFromSheet, setUsersFromSheet] = useState<Sheet[]>([]);
  const [uploadFile, setUploadFile] = useState<FileList | null>(null);
  const [textFieldFocused, setTextFieldFocused] = useState(false);
  const [scrollTarget, setScrollTarget] = useState<HTMLElement | null>(null);
  const [showErrorRowsOnly, setShowErrorRowsOnly] = useState(false);
  const [errUsers, setErrUsers] = useState<Sheet[]>([]);

  const totalRows = usersFromSheet.length;
  const checkedCount = showErrorRowsOnly
    ? errUsers.filter(user => user.checked).length
    : usersFromSheet.filter(user => user.checked).length;

  const { mutate: addBatchUsers } = useAddBatchUsers();
  const { data: groups } = useGetUserGroups({ tenantId });

  const validateUserRow = (target: Sheet): ValidateRow => {
    const errors: ValidateRow = {
      account: {
        err: false,
        message: '',
      },
      code: {
        err: false,
        message: '',
      },
      name: {
        err: false,
        message: '',
      },
    };

    // 현재 테이블에서 계정 중복 처리
    const duplOfTable = usersFromSheet.filter(user => user.account === target.account);
    if (duplOfTable.length > 1) {
      errors.account.err = true;
      errors.account.message = t('Member_User_Bulk_Create_10');
    }

    // 계정 공백
    if (!target.account?.length) {
      errors.account.err = true;
      errors.account.message = t('Member_Group_Delete_05');
    }

    // 존재 하지 않는 그룹코드
    const groupCodes = target.groupCode ? target.groupCode.split(',') : [];
    if (!(groupCodes.length === 1 && groupCodes[0] === '')) {
      const checkCodes = groupCodes.map(code => !groups?.some(group => group.userGroupCode === String(code).trim()));
      if (checkCodes.some(checked => checked)) {
        errors.code.err = true;
        errors.code.message = t('Member_Group_Bulk_Create_23');
      }
    }

    // 이름 공백
    if (!target.name?.length) {
      errors.name.err = true;
      errors.name.message = t('Member_Group_Delete_05');
    }

    return errors;
  };

  const errorCount = usersFromSheet.filter(user => {
    const err = validateUserRow(user);
    return err.account.err || err.code.err || err.name.err;
  }).length;

  const handleFileUpload = {
    clickFileUploadBtn: () => {
      fileRef.current?.click();
    },
    changeFileUpload: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        setUploadFile(e.target.files);
        const changeCheckedOrigin = usersFromSheet.map(v => {
          return { ...v, checked: false };
        });
        setUsersFromSheet(changeCheckedOrigin);

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target) {
            const bufferArray = e.target.result;
            const fileInfo = XLSX.read(bufferArray, {
              type: 'buffer',
              cellText: false,
              cellDates: true,
            });
            const sheetName = fileInfo.SheetNames[0];
            const rawData = fileInfo.Sheets[sheetName];
            const usersOfExcel: ExcelSheet[] = XLSX.utils.sheet_to_json(rawData);

            if (usersOfExcel.length) {
              const nonEmptyRows = usersOfExcel.filter(user => {
                // 행에 데이터가 1개라도 존재하는지 확인하여 빈 행을 제거합니다.
                return Object.values(user).some(value => value !== undefined && value !== null && value !== '');
              });

              const users: Sheet[] = nonEmptyRows.map(user => {
                return {
                  name: user['이름'],
                  account: user['계정'],
                  groupCode: user['그룹 코드'],
                  phone: user['연락처'],
                  checked: false,
                  id: nanoid(),
                };
              });

              const errorRows = users.filter(user => {
                const err = validateUserRow(user);
                return err.account.err || err.code.err || err.name.err;
              });

              setUsersFromSheet(users);
              setErrUsers(errorRows);
            }
          }
        };
        setUploadFile(e.target.files);
      }
    },
  };

  const handleExcelDownload = () => {
    const sheetData = new Array(299);
    sheetData.fill(['', '', '', '']);
    sheetData.unshift([`${t('Workflow_Main_05')}`, '계정', '그룹 코드', t('Product_Inquiry_04')]);

    const ws = XLSX.utils.aoa_to_sheet(sheetData);

    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('유저일괄등록');
    workbook.Sheets['유저일괄등록'] = ws;
    sheetData
      .filter((sheet, idx) => idx !== 0)
      .forEach((sheet, idx) => {
        workbook.Sheets['유저일괄등록'][`D${idx + 1}`].z = '@';
      });

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    FileSaver.saveAs(excelFile, '유저일괄등록양식.xlsx');
  };

  const handleTable = {
    addRow: () => {
      const newRow = { checked: false, groupCode: '', name: '', account: '', id: nanoid(), phone: '' };
      const origin = [...usersFromSheet];
      origin.unshift(newRow);
      setUsersFromSheet(origin);
      const errorOrigin = [...errUsers];
      errorOrigin.unshift(newRow);
      setErrUsers(errorOrigin);
    },
    deleteRow: () => {
      const origin = [...usersFromSheet];
      const errOrigin = [...errUsers];
      setUsersFromSheet(origin.filter(v => !v.checked));
      setErrUsers(errOrigin.filter(v => !v.checked));

      toastStore.open(
        t('Member_User_Bulk_Create_11', {
          number: showErrorRowsOnly
            ? origin.length - errOrigin.filter(v => !v.checked).length
            : origin.length - origin.filter(v => !v.checked).length,
        }),
      );
    },

    changeCheckbox: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked: boolean, id: string) => {
      const updatedUsers = usersFromSheet.map(user => {
        if (user.id === id) {
          return { ...user, checked };
        }
        return user;
      });

      if (showErrorRowsOnly) {
        setErrUsers(prev => {
          const target = prev.find(row => row.id === id);
          if (target) {
            target.checked = checked;
          }
          return prev;
        });
      }
      setUsersFromSheet(updatedUsers);
    },

    changeAllCheckbox: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked: boolean) => {
      const changeCheckedOrigin = usersFromSheet.map(v => {
        return { ...v, checked };
      });

      if (showErrorRowsOnly) {
        const updatedErrorUsers = changeCheckedOrigin.filter(user => {
          const err = validateUserRow(user);
          return err.account.err || err.code.err || err.name.err;
        });
        setErrUsers(updatedErrorUsers);
      }
      setUsersFromSheet(changeCheckedOrigin);
    },

    changeUserRow: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      const origin = [...usersFromSheet];
      const idx = origin.findIndex(group => group.id === id);
      const target: any = origin[idx];
      target[e.currentTarget.name] = e.currentTarget.value;
      origin.splice(idx, 1, target);
      setUsersFromSheet(origin);
    },
  };

  const handleShowErrorRowsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setShowErrorRowsOnly(target.checked);

    if (target.checked) {
      const filteredErrUsers = usersFromSheet.filter(user => {
        const err = validateUserRow(user);
        return err.account.err || err.code.err || err.name.err;
      });
      setErrUsers(filteredErrUsers);
    } else {
      setUsersFromSheet(
        usersFromSheet.map(user => {
          const errorUser = validateUserRow(user);
          if (errorUser.account.err || errorUser.code.err || errorUser.name.err) {
            return {
              ...user,
            };
          }
          return {
            ...user,
            checked: false,
          };
        }),
      );
    }
  };

  const handleClickRegisterBtn = () => {
    const targetErrUsers = showErrorRowsOnly
      ? errUsers
      : usersFromSheet.filter(user => {
          const err = validateUserRow(user);
          return err.account.err || err.code.err || err.name.err;
        });
    if (targetErrUsers.length) {
      alertStore.open({
        title: `${t('Member_User_Bulk_Create_14')}`,
        message: `${t('Member_Group_Bulk_Create_26')}`,
        useCancel: false,
        confirmName: `${t('Member_User_24')}`,
      });
    } else {
      alertStore.open({
        title: t('Member_User_27'),
        message: `${t('Member_User_Bulk_Create_15')}`,
        useCancel: true,
        confirmName: `${t('Member_User_24')}`,
        onConfirm: () => {
          const rqData: TenantMemberBulkUpdateDTO = {
            isMailSend: false,
            willRemoveUser: batchType === 'replace',
            tenantMemberList: usersFromSheet.map(user => {
              return {
                email: user.account,
                username: user.name,
                memberRole: 'USER',
                phone: user.phone,
                userGroupCodeList: user.groupCode ? [user.groupCode] : [],
              };
            }),
          };

          addBatchUsers(
            { tenantId, rqData },
            {
              onSuccess: () => {
                setBatchType(undefined);
                setUploadFile(null);
                setUsersFromSheet([]);
              },
            },
          );
        },
      });
    }
  };

  const handleChangeRadio = (batchType: BatchType) => {
    if (uploadFile) {
      alertStore.open({
        title: `${t('Member_User_Bulk_Create_12')}`,
        message: `${t('Member_User_Bulk_Create_13')}`,
        useCancel: true,
        confirmName: `${t('Member_User_24')}`,
        onConfirm: () => {
          setUploadFile(null);
          setUsersFromSheet([]);
          setBatchType(batchType);
          // 오류 모아보기 상태를 초기화합니다.
          setErrUsers([]);
          setShowErrorRowsOnly(false);
        },
      });
    } else {
      setBatchType(batchType);
    }
  };

  const handleTextFieldFocus = () => {
    setTextFieldFocused(true);
  };

  const defaultUser: Sheet = {
    name: '',
    account: '',
    groupCode: '',
    checked: false,
    id: nanoid(),
    phone: '',
  };
  const allChecked =
    (showErrorRowsOnly && errUsers.length > 0 && errUsers.length === errUsers.filter(user => user.checked).length) ||
    (!showErrorRowsOnly &&
      usersFromSheet.length > 0 &&
      usersFromSheet.length === usersFromSheet.filter(user => user.checked).length);

  const renderFixedHeaderContent = () => (
    <TableRow>
      <STableCell width={40} align="center">
        <Checkbox
          checked={allChecked}
          onChange={handleTable.changeAllCheckbox}
          icon={<CheckboxOffNoIcon width={16} height={16} color={theme.colors['border-gray-light']} />}
          checkedIcon={
            <CheckedBgIcon
              width={16}
              height={16}
              bgColor={theme.colors['bg-purple']}
              color={theme.colors['bg-white']}
            />
          }
        />
      </STableCell>
      <HeaderTableCell width={140}>
        {t('Workflow_Main_05')}
        <span>*</span>
      </HeaderTableCell>
      <HeaderTableCell width={271}>
        {t('Member_User_14')}
        <span>*</span>
      </HeaderTableCell>
      <HeaderTableCell width={160}>{t('Member_Group_Bulk_Create_17')}</HeaderTableCell>
      <HeaderTableCell width={140}>
        {t('Member_User_16')}
        <span>*</span>
      </HeaderTableCell>
    </TableRow>
  );

  const renderItemContent = (index: number, user: Sheet = defaultUser) => {
    const error = validateUserRow(user);
    return (
      <>
        <STableCell align="center">
          <Checkbox
            checked={user.checked}
            onChange={(e, checked) => handleTable.changeCheckbox(e, checked, user.id)}
            icon={<CheckboxOffNoIcon width={16} height={16} color={theme.colors['border-gray-light']} />}
            checkedIcon={
              <CheckedBgIcon
                width={16}
                height={16}
                bgColor={theme.colors['bg-purple']}
                color={theme.colors['bg-white']}
              />
            }
          />
        </STableCell>
        <STableCell>
          <Tooltip
            title={error.name.message}
            placement="bottom-start"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'none',
                },
                '& .MuiTooltip-arrow': {
                  transform: 'translate(20px, 0) !important',
                },
              },
            }}
          >
            <TableTextfield
              name="name"
              value={user.name}
              placeholder={t('Workflow_Main_05')}
              type="text"
              $err={error.name.err}
              onChange={e => handleTable.changeUserRow(e, user.id)}
              onFocus={handleTextFieldFocus}
              // onBlur={() => handleTable.blurUserRow(user.id)}
            />
          </Tooltip>
        </STableCell>
        <STableCell>
          <Tooltip
            title={error.account.message}
            placement="bottom-start"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'none',
                },
                '& .MuiTooltip-arrow': {
                  transform: 'translate(20px, 0) !important',
                },
              },
            }}
          >
            <TableTextfield
              name="account"
              value={user.account}
              placeholder={t('Member_User_14')}
              type="text"
              $err={error.account.err}
              onChange={e => handleTable.changeUserRow(e, user.id)}
              onFocus={handleTextFieldFocus}
              // onBlur={() => handleTable.blurUserRow(user.id)}
            />
          </Tooltip>
        </STableCell>
        <STableCell>
          <Tooltip
            title={error.code.message}
            placement="bottom-start"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'none',
                },
                '& .MuiTooltip-arrow': {
                  transform: 'translate(20px, 0) !important',
                },
              },
            }}
          >
            <TableTextfield
              name="groupCode"
              value={user.groupCode}
              placeholder={t('Member_Group_Bulk_Create_17')}
              type="text"
              $err={error.code.err}
              onChange={e => handleTable.changeUserRow(e, user.id)}
              onFocus={handleTextFieldFocus}
              // onBlur={() => handleTable.blurUserRow(user.id)}
            />
          </Tooltip>
        </STableCell>
        <STableCell>
          <TableTextfield
            name="phone"
            value={user.phone}
            placeholder={t('Member_User_16')}
            type="text"
            onChange={e => handleTable.changeUserRow(e, user.id)}
            onFocus={handleTextFieldFocus}
            // onBlur={() => handleTable.blurUserRow(user.id)}
          />
        </STableCell>
      </>
    );
  };

  return (
    <Container ref={setScrollTarget}>
      <Content>
        <div className="header">
          <IconButton onClick={() => navigate(-1)}>
            <ChevronIcon rotateNum={270} />
          </IconButton>
          <Title>{t('Member_User_27')}</Title>
        </div>
        <div className="body">
          <DescriptionBox>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              <span className="description">{t('Member_User_Bulk_Create_01')}</span>
            </li>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              <span className="description">{t('Member_User_Bulk_Create_02')}</span>
            </li>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              <span className="description">{t('Member_Group_Bulk_Create_04')}</span>
            </li>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              <span className="description">{t('Member_User_Bulk_Create_03')}</span>
            </li>
          </DescriptionBox>
          <BatchRegister>
            <div className="title">{t('Member_User_Bulk_Create_04')}</div>
            <div className="type-select-box">
              <div className="type-select">
                <Radio
                  size="small"
                  checked={batchType === 'update'}
                  onClick={() => handleChangeRadio('update')}
                  icon={<RadioOffIcon width={16} hanging={16} color={theme.colors['border-gray-light']} />}
                  checkedIcon={<RadioIcon bgColor={theme.colors['bg-white']} color={theme.colors['bg-purple']} />}
                />
                <p>
                  {t('Member_User_Bulk_Create_05')}
                  <span className="description">{t('Member_User_Bulk_Create_06')}</span>
                </p>
              </div>
              <div className="type-select">
                <Radio
                  size="small"
                  checked={batchType === 'replace'}
                  onClick={() => handleChangeRadio('replace')}
                  icon={<RadioOffIcon width={16} hanging={16} color={theme.colors['border-gray-light']} />}
                  checkedIcon={<RadioIcon bgColor={theme.colors['bg-white']} color={theme.colors['bg-purple']} />}
                />
                <p>
                  {t('Member_User_Bulk_Create_07')}
                  <span className="description">{t('Workflow_Create_63')}</span>
                </p>
              </div>
            </div>
            <div className="file-upload">
              <TextField
                size="small"
                fullWidth
                disabled
                value={uploadFile ? uploadFile[0].name : ''}
                InputProps={{
                  startAdornment: uploadFile ? (
                    <StyledInputAdornment position="start">
                      <ExcelIcon width={20} height={20} />
                    </StyledInputAdornment>
                  ) : undefined,
                }}
              />
              <div className="buttons">
                <Button
                  size="medium"
                  paddingHorizontal={16}
                  variant="contain"
                  disabled={batchType === undefined}
                  onClick={handleFileUpload.clickFileUploadBtn}
                >
                  {t('Member_Group_Bulk_Create_10')}
                </Button>
                <FileUpload
                  type="file"
                  ref={fileRef}
                  onChange={handleFileUpload.changeFileUpload}
                  onClick={e => {
                    e.currentTarget.value = '';
                  }}
                />
                <Button size="medium" paddingHorizontal={16} variant="outline" onClick={handleExcelDownload}>
                  <ExcelBtn>
                    <DownloadIcon width={20} height={20} />
                    <span>{t('Member_Group_Bulk_Create_11')}</span>
                  </ExcelBtn>
                </Button>
              </div>
            </div>
          </BatchRegister>

          <TableContainer>
            <TableInfoWrapper>
              <div className="count-wrapper">
                <strong className="total">
                  {t('Acc_Main_12')}
                  {checkedCount ? (
                    <span className="count">
                      {checkedCount}/{totalRows}
                    </span>
                  ) : (
                    <span className="count">{totalRows}</span>
                  )}
                </strong>
                <span className="err">
                  (오류<span className={errorCount > 0 ? 'err_num' : ''}>{errorCount}</span>개)
                </span>
              </div>
              <div className="tablecell-wrapper">
                <div className="err-collect">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showErrorRowsOnly}
                        onChange={handleShowErrorRowsChange}
                        checkedIcon={
                          <CheckedBgIcon
                            width={16}
                            height={16}
                            color={theme.colors['bg-white']}
                            bgColor={theme.colors['bg-purple']}
                          />
                        }
                        icon={<CheckboxOffNoIcon width={16} height={16} bgColor={theme.colors['bg-white']} />}
                      />
                    }
                    label={t('Member_Group_Bulk_Create_14')}
                  />
                </div>
                <div className="tablecell-btns">
                  <Button
                    disabled={uploadFile === null}
                    size="small"
                    variant="outline"
                    paddingHorizontal={12}
                    onClick={handleTable.addRow}
                  >
                    {t('Member_Group_Bulk_Create_15')}
                  </Button>
                  <Button
                    disabled={uploadFile === null}
                    size="small"
                    variant="outline"
                    paddingHorizontal={12}
                    onClick={handleTable.deleteRow}
                  >
                    {t('Member_Group_Bulk_Create_16')}
                  </Button>
                  <Button
                    size="small"
                    variant="contain"
                    paddingHorizontal={24}
                    onClick={handleClickRegisterBtn}
                    disabled={usersFromSheet.length === 0 || batchType === undefined}
                  >
                    {t('Member_Group_Bulk_Create_21')}
                  </Button>
                </div>
              </div>
            </TableInfoWrapper>
            <TableVirtuoso
              data={showErrorRowsOnly ? errUsers : usersFromSheet}
              components={tableComponents}
              fixedHeaderContent={renderFixedHeaderContent}
              itemContent={renderItemContent}
              context={{ emptyDataMessage: t('Member_Group_Bulk_Create_20') }}
              customScrollParent={scrollTarget ?? undefined}
            />
          </TableContainer>
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  padding: 28px 32px;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  & .header {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  & .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }
  & .footer {
    display: flex;
    flex-direction: row;
    &.hasScroll {
      position: sticky;
      left: 0;
      bottom: 0;
      background-color: ${props => props.theme.colors['bg-white']};
    }
  }
`;

const Title = styled.span`
  ${fonts.Headline6};
`;

const DescriptionBox = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  min-height: 118px;
  background-color: ${props => props.theme.colors['bg-gray-light']};
  padding: 16px 32px;
  border-radius: 3px;
  & > li {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }
  & .description {
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
  }
  margin-bottom: 40px;
`;

const BatchRegister = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 0px 24px 50px;
  gap: 24px;
  & .title {
    ${fonts.Headline7};
  }
  & .type-select-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .type-select {
      display: flex;
      gap: 8px;
      align-items: center;
      p {
        ${fonts.Body2}
      }
      & .description {
        margin-left: 4px;
        color: ${props => props.theme.colors['text-gray-sub-dark']};
      }
    }
  }
  & .file-upload {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 630px;
    & .MuiInputBase-root.Mui-disabled {
      background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors['border-gray-light']};
      }
    }
    & .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      button:disabled {
        background: ${({ theme }) => theme.colors['bg-gray-dark']};
        color: ${({ theme }) => theme.colors['text-gray-light']};
      }
    }
  }
`;

const ExcelBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  ${fonts.Button3};
`;

const TableContainer = styled.div`
  width: 800px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 24px 40px;
`;

const TableInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: -29px;
  left: 0;
  background-color: ${props => props.theme.colors['bg-white']};
  margin: 29px 0 12px;
  z-index: 1001;
  & .count-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  & .tablecell-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  & .total {
    ${fonts.Headline8};
    & .count {
      margin-left: 4px;
      color: ${props => props.theme.colors['text-purple-light']};
    }
  }
  & .err {
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-light']};
    & > span {
      display: inline-block;
      margin: 0 1px 0 4px;
    }
    & .err_num {
      color: ${props => props.theme.colors['text-red-light']};
    }
  }
  & .err-collect > .MuiFormControlLabel-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 0;
    & .MuiFormControlLabel-label {
      ${fonts.Body2};
      color: ${props => props.theme.colors['text-gray-light']};
    }
    & .MuiButtonBase-root.Mui-checked + .MuiFormControlLabel-label {
      color: ${props => props.theme.colors['text-gray-main']};
    }
  }
  & .tablecell-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const FileUpload = styled.input`
  display: none;
`;

const TableWrapper = styled.div``;
const STableCell = styled(TableCell)`
  padding: 5px 8px;
  text-overflow: clip;
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-collapse: collapse;
  & .MuiFormControl-root {
    width: 100%;
  }
`;
const HeaderTableCell = styled(TableCell)`
  span {
    display: inline-block;
    color: ${({ theme }) => theme.colors['text-red-light']};
    margin-left: 1px;
    vertical-align: text-bottom;
  }
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-collapse: collapse;
`;

const TableTextfield = styled(TextField, transientOptions)<{ $err?: boolean }>`
  & .MuiInputBase-root {
    height: 32px;
    width: 100%;
    border-radius: 2px;
    padding: 6px 12px;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      ${props =>
        props.$err &&
        css`
          border: 1px solid ${props.theme.colors['border-red-light']};
        `};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      ${props =>
        props.$err &&
        css`
          border: 1px solid ${props.theme.colors['border-red-light']};
        `};
    }
  }
  & .MuiInputBase-input {
    ${fonts.Body2};
    color: ${props => (props.$err ? props.theme.colors['text-red-light'] : props.theme.colors['text-gray-main'])};
    &::placeholder {
      color: ${props => (props.$err ? props.theme.colors['text-red-light'] : props.theme.colors['text-gray-light'])};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: ${props =>
      props.$err
        ? `1px solid ${props.theme.colors['border-red-light']}`
        : `1px solid ${props.theme.colors['border-gray-light']}`};
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;
