import { ForwardedRef, createContext, forwardRef, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';
import { CalendarPickerView, DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { fonts } from '@theme/fontsCustomer';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import { transientOptions } from '@utils/CommonUtil';

type Props<TInputDate, TDate = TInputDate> = Omit<DesktopDatePickerProps<TInputDate, TDate>, 'renderInput'> & {
  renderInput?: DesktopDatePickerProps<TInputDate, TDate>['renderInput'];
};

type ContextProps = {
  isPickerOpen: boolean;
};
const DatePickerContext = createContext<ContextProps>({ isPickerOpen: false });
const useDatePickerContext = () => useContext(DatePickerContext);
const DatePickerProvider = ({ value, children }: StrictPropsWithChildren<{ value: ContextProps }>) => (
  <DatePickerContext.Provider value={value}>{children}</DatePickerContext.Provider>
);

const DatePickerGeneric = <TInputDate = Dayjs, TDate = TInputDate>(
  { components, OpenPickerButtonProps, onChange, onAccept, ...props }: Props<TInputDate, TDate>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [dayUnit, setDayUnit] = useState<CalendarPickerView>(() => {
    const settingDayUnit = props.views ?? null;
    if (settingDayUnit) return settingDayUnit[settingDayUnit.length - 1];
    return 'day';
  });

  const defaultProps: Omit<DesktopDatePickerProps<TInputDate, TDate>, 'value' | 'onChange'> = {
    OpenPickerButtonProps: { style: { right: '8px' } },
    components: { OpenPickerIcon },
    renderInput: params => <DatePickerInput {...params} />,
    inputFormat: t('DateFormat_YMD'),
    views: ['year', 'month', 'day'],
    disableHighlightToday: true,
  };

  const inputFormat = props.inputFormat ?? defaultProps.inputFormat;

  const handleOpen = () => {
    setIsPickerOpen(true);

    if (typeof props.onOpen === 'function') {
      props.onOpen();
    }
  };

  const handleClose = () => {
    setIsPickerOpen(false);

    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };

  const handleChange = (value: TDate | null, keyboardInputValue?: string | undefined) => {
    const customOnChangeAccept = (value: TDate | null, keyboardInputValue?: string | undefined) => {
      onChange(value, keyboardInputValue);
      if (typeof onAccept === 'function') {
        onAccept(value);
      }
    };
    if (keyboardInputValue) {
      if (inputFormat === 'MMM DD, YYYY' && dayjs(keyboardInputValue, 'MM DD, YYYY', true).isValid()) {
        customOnChangeAccept(dayjs(keyboardInputValue) as TDate, keyboardInputValue);
      } else if (inputFormat === 'MMM YYYY' && dayjs(keyboardInputValue, 'MM YYYY', true).isValid()) {
        const reverseDate = (data: string) => {
          const subDate = data.split(' '); // 10 2023
          return subDate[1] + subDate[0];
        };
        customOnChangeAccept(dayjs(reverseDate(keyboardInputValue)) as TDate, keyboardInputValue);
      } else if (dayjs(keyboardInputValue, inputFormat, true).isValid()) {
        customOnChangeAccept(value, keyboardInputValue);
      }
    } else if (
      (value && inputFormat === t('DateFormat_YM') && dayUnit === 'month') ||
      (value && inputFormat === t('DateFormat_YMD') && dayUnit === 'day')
    ) {
      customOnChangeAccept(value, keyboardInputValue);
    }
  };

  return (
    <DatePickerProvider value={{ isPickerOpen }}>
      <DesktopDatePicker
        {...defaultProps}
        {...props}
        OpenPickerButtonProps={{ ...defaultProps.OpenPickerButtonProps, ...OpenPickerButtonProps }}
        components={{ ...defaultProps.components, ...components }}
        ref={openPickerRef => {
          if (openPickerRef) {
            selectRef.current = openPickerRef;
            if (typeof ref === 'function') {
              ref(openPickerRef);
              // eslint-disable-next-line react/destructuring-assignment
            } else if (ref?.current) {
              // eslint-disable-next-line no-param-reassign
              ref.current = openPickerRef;
            }
          }
        }}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        onViewChange={setDayUnit}
        inputFormat={inputFormat}
        PopperProps={{
          placement: 'bottom-start',
          anchorEl: selectRef.current,
        }}
        PaperProps={{
          sx: {
            width: '244px',
            height: '272px',
            borderRadius: '2px',
            border: `1px solid ${colors['border-gray-lighter']}`,
            marginTop: '4px',
            boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
            '& .MuiCalendarOrClockPicker-root': {
              height: '100%',
              '& > div': {
                width: '100%',
                height: '100%',
              },
            },
            '& .MuiCalendarPicker-root': {
              width: '100%',
              '& .MuiDayPicker-header': {
                padding: '0px 10px',
                '& .MuiDayPicker-weekDayLabel': {
                  ...fonts.Caption2,
                  '&:first-child': {
                    color: colors['text-red-light'],
                  },
                },
              },
              '& .MuiDayPicker-monthContainer': {
                padding: '0px 10px',
              },
            },
            /* Header */
            '& .MuiPickersCalendarHeader-root': {
              padding: '0px 12px 0px 20px',
            },
            /* Header prev/next month */
            '& .MuiPickersArrowSwitcher-spacer': {
              width: '12px',
            },
            '& .MuiPickersDay-root': {
              width: '24px',
              height: '24px',
              '&.Mui-selected': {
                backgroundColor: `${colors['bg-purple-lighter']} !important`,
                color: `${colors['text-purple-light']} !important`,
                '&:hover': {
                  backgroundColor: `${colors['state-white-purple-hover']}`,
                },
              },
              '&:hover': {
                backgroundColor: `${colors['state-white-purple-hover']}`,
              },
            },
            '& .MuiPickersDay-today': {
              border: 'none',
            },
            '& .MuiDayPicker-slideTransition': {
              minHeight: '164px',
            },
            '& .MuiDayPicker-weekContainer': {
              justifyContent: 'space-between',
              '& .MuiPickersDay-dayWithMargin': {
                ...fonts.Caption1,
                color: colors['text-gray-main'],
                '&.MuiPickersDay-dayOutsideMonth': {
                  color: colors['text-gray-lighter'],
                },
              },
              '& .MuiPickersDay-dayWithMargin:first-child': {
                color: colors['text-red-light'],
                '&.MuiPickersDay-dayOutsideMonth': {
                  color: colors['text-red-lighter'],
                },
              },
            },
            '& .PrivatePickersMonth-root': {
              ...fonts.Body2,
              height: '32px',
              '&.Mui-selected': {
                color: colors['text-purple-light'],
                backgroundColor: colors['bg-purple-lighter'],
                '&:focus': {
                  backgroundColor: colors['bg-purple-lighter'],
                },
                '&:hover': {
                  backgroundColor: colors['state-white-purple-hover'],
                },
              },
            },
            '& .MuiMonthPicker-root': {
              width: 'calc(100% - 8px)',
            },
            '& .MuiYearPicker-root': {
              maxHeight: '202px',
            },
            '& .PrivatePickersYear-yearButton': {
              ...fonts.Body2,
              width: '78px',
              '&.Mui-selected': {
                color: colors['text-purple-light'],
                backgroundColor: colors['bg-purple-lighter'],
                '&:focus': {
                  backgroundColor: colors['bg-purple-lighter'],
                },
                '&:hover': {
                  backgroundColor: colors['state-white-purple-hover'],
                },
              },
            },
          },
        }}
      />
    </DatePickerProvider>
  );
};

const DatePickerInput = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { isPickerOpen } = useDatePickerContext();
  return (
    <DatePickerTextField
      size="small"
      {...props}
      ref={ref}
      $isPickerOpen={isPickerOpen}
      inputProps={{
        ...props.inputProps,
        // FIXME: MMMM도 처리할 수 있도록 정규식으로 수정 필요
        placeholder: props.inputProps?.placeholder?.toUpperCase()?.replaceAll('MMM', 'MM'),
      }}
    />
  );
}) as typeof TextField;

const DatePickerGenericWithRef = forwardRef(DatePickerGeneric) as <TInputDate, TDate = TInputDate>(
  props: Props<TInputDate, TDate> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof DatePickerGeneric>;

const DatePickerTextField = styled(TextField, transientOptions)<{ $isPickerOpen?: boolean }>`
  & div.MuiInputBase-root {
    padding: 6px 0px 6px 12px;
  }

  & div.MuiInputBase-root.Mui-disabled {
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    }
    & .MuiInputBase-input {
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
  }

  & .MuiInputAdornment-root > .MuiButtonBase-root {
    right: 0px !important;
    margin-right: 8px;
    &:hover {
      background-color: transparent;
    }
    & .MuiTouchRipple-root {
      display: none;
    }
  }

  & .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ $isPickerOpen, theme }) =>
      $isPickerOpen ? theme.colors['border-purple'] : theme.colors['border-gray-light']};
  }

  & .Mui-disabled {
    &::-webkit-input-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-lighter']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &:-moz-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-lighter']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &::-moz-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-lighter']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &:-ms-input-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-lighter']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors['border-gray-light']};
    }
    & svg g path {
      fill: ${({ theme }) => theme.colors['ic-gray-lighter']};
      opacity: 1;
    }
  }
`;

const OpenPickerIcon = ({ width = 16, height = 16, ...rest }: SvgProps) => {
  const { colors } = useTheme();
  const { isPickerOpen } = useDatePickerContext();
  return (
    <CalendarStartIcon
      {...rest}
      width={width}
      height={height}
      color={isPickerOpen ? colors['ic-purple'] : colors['ic-gray-dark']}
    />
  );
};

export const DatePicker = Object.assign(DatePickerGenericWithRef, {
  Input: DatePickerInput,
  OpenPickerIcon,
});
