import { UseFormProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { CurrencyUnits } from '@utils/commonVars';

export type GenerateMonthlyBillingForm = {
  paymentDate: DateTimeString | null;
  currencyUnit: CurrencyUnit;
  exchangeRate: number;
};

export const useGenerateBillingForm = () => {
  const generateMonthlyBillingSchema: yup.SchemaOf<GenerateMonthlyBillingForm> = yup.object().shape({
    paymentDate: yup
      .string()
      .required('필수 입력 항목입니다.')
      .nullable()
      .test('date-checker', '날짜 포맷만 입력 가능합니다.', value => dayjs(value).isValid()),
    currencyUnit: yup
      .mixed()
      .oneOf([...CurrencyUnits])
      .required('필수 입력 항목입니다.'),
    exchangeRate: yup.number().required().min(0, '0 이상 입력만 가능합니다.').typeError('숫자 입력만 가능합니다.'),
  });

  return useForm<GenerateMonthlyBillingForm>({
    resolver: yupResolver(generateMonthlyBillingSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      paymentDate: null,
    },
  });
};

export type AddMonthlyBillingForm = {
  startDate: DateTimeString | null;
  endDate: DateTimeString | null;
  paymentDate: DateTimeString | null;
  originalCost: number;
  currencyUnit: CurrencyUnit;
  exchangeRate: number | null;
  cost: number;
};

export const useAddMonthlyBillingForm = () => {
  const addMonthlyBillingSchema: yup.SchemaOf<AddMonthlyBillingForm> = yup.object().shape({
    startDate: yup
      .string()
      .required('필수 입력 항목입니다.')
      .nullable()
      .test('date-checker', '날짜 포맷만 입력 가능합니다.', value => dayjs(value).isValid()),
    endDate: yup
      .string()
      .required('필수 입력 항목입니다.')
      .nullable()
      .test('date-checker', '날짜 포맷만 입력 가능합니다.', value => dayjs(value).isValid()),
    paymentDate: yup
      .string()
      .required('필수 입력 항목입니다.')
      .nullable()
      .test('date-checker', '날짜 포맷만 입력 가능합니다.', value => dayjs(value).isValid()),
    originalCost: yup
      .number()
      .min(0, '0 이상 입력만 가능합니다.')
      .required('필수 입력 항목입니다.')
      .typeError('숫자 입력만 가능합니다.'),
    currencyUnit: yup
      .mixed()
      .oneOf([...CurrencyUnits], '필수 입력 항목입니다.')
      .required('필수 입력 항목입니다.'),
    exchangeRate: yup
      .number()
      .min(0, '0 이상 입력만 가능합니다.')
      .required('필수 입력 항목입니다.')
      .typeError('숫자 입력만 가능합니다.')
      .nullable(),
    cost: yup
      .number()
      .min(0, '0 이상 입력만 가능합니다.')
      .required('필수 입력 항목입니다.')
      .typeError('숫자 입력만 가능합니다.'),
  });

  const addMonthlyBillingFormOptions: UseFormProps<AddMonthlyBillingForm> = {
    resolver: yupResolver(addMonthlyBillingSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      paymentDate: null,
    },
  };

  return useForm<AddMonthlyBillingForm>(addMonthlyBillingFormOptions);
};
