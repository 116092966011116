import { injectable } from 'inversify';
import API from '@repositories/Axios';
import ITenantMemberRepository from './ITenantMemberRepository';
import 'reflect-metadata';
import { TenantMemberBulkUpdateDTO, TenantMemberSummaryDTO } from './Types';

@injectable()
export class TenantMemberRepository implements ITenantMemberRepository {
  async getMemberSummary(tenantId: string): Promise<TenantMemberSummaryDTO> {
    const result = await API.get<TenantMemberSummaryDTO>(`/api/v1/tenant/${tenantId}/members-summary`);
    return result.data;
  }

  async addBatchMember({
    tenantId,
    rqData,
  }: {
    tenantId: string;
    rqData: TenantMemberBulkUpdateDTO;
  }): Promise<unknown> {
    const result = await API.post<unknown>(`/api/v1/tenant/${tenantId}/bulk-members`, rqData);
    return result.data;
  }
}
