import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { type ITimezoneOption, useTimezoneSelect } from 'react-timezone-select';
import {
  Modal,
  Typography,
  Button,
  MenuItem,
  Select,
  Box,
  TextField,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { useStore } from '@stores/RootStore';
import { useGetTenantList } from '@queryHooks/useTenant';
import { useGetUserList } from '@queryHooks/useUser';
import { ModulePurchaseCost, SoftwarePurchaseContractCreateRq } from '@type/Purchase';
import * as S from './PurchaseContractCreateStyles';

type Module = ModulePurchaseCost & {
  name?: string;
};

type FormData = SoftwarePurchaseContractCreateRq & {
  modulePurchaseCosts: Module[];
};

type SelectValues = {
  tenantId: string;
  softwareId: string;
  providerId: string;
  timezone: ITimezoneOption;
  ownerId: string;
  billingMonthCycle: '0' | '1' | '3' | '6' | '12';
};

export const PurchaseContractCreate = observer(() => {
  const {
    uiStore: { isVisiblePurchaseContractCreateModal, closePurchaseContractCreateModal },
    purchaseContractStore,
    softwareStore,
  } = useStore();

  const [selectValues, setSelectValues] = useState<SelectValues>({
    timezone: {
      value: 'Asia/Seoul',
      label: '(GMT+9:00) Seoul',
      offset: 9,
      abbrev: 'KST',
      altName: 'Korean Standard Time',
    },
    tenantId: '',
    providerId: '',
    softwareId: '',
    ownerId: '',
    billingMonthCycle: '0',
  });

  const [startDate, setStartDate] = useState<string>(dayjs().add(-2, 'd').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [useModule, setUseModule] = useState<boolean>(false);

  const { options: timezoneOptions } = useTimezoneSelect({ labelStyle: 'original' });
  const GMT = timezoneOptions.find(({ value }) => value === 'Etc/GMT');
  if (GMT) {
    GMT.value = 'UTC';
  }

  const { data: pagedUserList } = useGetUserList({});
  const { data: pagedTenants } = useGetTenantList();

  useEffect(() => {
    const rqFetchPreData = async () => {
      try {
        await Promise.all([await softwareStore.fetchSoftwares(), await softwareStore.fetchProviders()]);
      } catch (error) {
        console.error('err', error);
      }
    };

    if (isVisiblePurchaseContractCreateModal) {
      rqFetchPreData();
    }

    return () => {
      setSelectValues({
        timezone: {
          value: 'Asia/Seoul',
          label: '(GMT+9:00) Seoul',
          offset: 9,
          abbrev: 'KST',
          altName: 'Korean Standard Time',
        },
        tenantId: '',
        providerId: '',
        softwareId: '',
        ownerId: '',
        billingMonthCycle: '0',
      });
      setUseModule(false);
      remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisiblePurchaseContractCreateModal, softwareStore]);

  useEffect(() => {
    softwareStore.getSoftware(selectValues.softwareId)?.modules?.forEach(module => {
      add({ name: module.name, moduleCode: module.moduleCode, cost: 0, originalCost: 0, price: 0, quantity: 0 });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValues.softwareId]);

  const handleClose = () => {
    closePurchaseContractCreateModal();
  };

  const { register, handleSubmit, reset, control } = useForm<FormData>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      amount: 0,
      originalAmount: 0,
    },
  });

  const { remove, fields: swModules, append: add } = useFieldArray({ control, name: 'modulePurchaseCosts' });

  const formatOptionLabel = useCallback((data: unknown) => {
    const { abbrev, label, value } = data as ITimezoneOption;
    return <Box>{abbrev === 'GMT' ? label : value}</Box>;
  }, []);

  const handleChangeTimezone = useCallback((timezone: ITimezoneOption) => {
    setSelectValues(prev => {
      return { ...prev, timezone };
    });
  }, []);

  const dateHandler = {
    start: (date: string | null) => {
      setStartDate(dayjs(date as string).format('YYYY-MM-DD'));
    },
    end: (date: string | null) => {
      setEndDate(dayjs(date as string).format('YYYY-MM-DD'));
    },
  };

  const handleChangeSelectValue = (type: string, value: string) => {
    setSelectValues(prev => {
      return { ...prev, [type]: value };
    });
  };

  const validationHandler = {
    success: async (data: FormData) => {
      try {
        const rqData: SoftwarePurchaseContractCreateRq = {
          endDate,
          startDate,
          providerId: selectValues.providerId,
          softwareId: selectValues.softwareId,
          ownerId: selectValues.ownerId,
          tenantId: selectValues.tenantId,
          amount: data.amount,
          currencyUnit: 'KRW',
          exchangeRate: data.exchangeRate,
          originalAmount: data.originalAmount,
          originalCurrencyUnit: 'USD',
          name: data.name,
          description: data.description,
          billingMonthCycle: parseInt(selectValues.billingMonthCycle, 10),
          isFixedExchangeRate: false,
        };
        const timezone = Object.values(selectValues.timezone)[0];
        rqData.timeZone = timezone === 'Etc/GMT' ? 'UTC' : (timezone as string);

        const purchaseCosts = data.modulePurchaseCosts.map((cost: Module) => {
          const value = { ...cost };
          if (value.name) delete value.name;
          return value;
        });

        rqData.modulePurchaseCosts = purchaseCosts;

        await purchaseContractStore.createContract(rqData);
        handleClose();
        reset();
      } catch (error) {
        console.error('err', error);
      }
    },
    fail: (/* err: any */) => {
      //
    },
  };

  return (
    <Modal open={isVisiblePurchaseContractCreateModal} onClose={handleClose}>
      <S.Container>
        <form onSubmit={handleSubmit(validationHandler.success, validationHandler.fail)}>
          <S.ModalTitleWrapper>
            <Typography variant="h4">Create Purchase Contract</Typography>
          </S.ModalTitleWrapper>
          <S.ModalContentWrapper>
            <S.ContentElementWrapper>
              <S.ContentElement>
                <Typography>Contract Name</Typography>
                <TextField {...register('name')} size="small" />
              </S.ContentElement>
              <S.ContentElement>
                <Typography>Description</Typography>
                <TextField {...register('description')} size="small" />
              </S.ContentElement>
            </S.ContentElementWrapper>

            <S.ContentElementWrapper>
              <S.ContentElement>
                <Typography>Owner</Typography>
                <Select
                  value={selectValues.ownerId}
                  size="small"
                  fullWidth
                  onChange={(event: SelectChangeEvent<string> /* child: React.ReactNode */) =>
                    handleChangeSelectValue('ownerId', event.target.value)
                  }
                >
                  {pagedUserList?.content.map(user => {
                    return (
                      <MenuItem key={nanoid()} value={user.id}>
                        {user.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </S.ContentElement>
              <S.ContentElement>
                <Typography>Tenant</Typography>
                <Select
                  label="tenant"
                  value={selectValues.tenantId}
                  size="small"
                  fullWidth
                  onChange={(event: SelectChangeEvent<string> /* child: React.ReactNode */) =>
                    handleChangeSelectValue('tenantId', event.target.value)
                  }
                >
                  {pagedTenants?.content.map(tenant => {
                    return (
                      <MenuItem key={nanoid()} value={tenant.id}>
                        {tenant.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </S.ContentElement>
            </S.ContentElementWrapper>
            <S.ContentElementWrapper>
              <S.ContentElement>
                <Typography>Distributor</Typography>
                <Select
                  value={selectValues.providerId}
                  size="small"
                  fullWidth
                  onChange={(event: SelectChangeEvent<string> /* child: React.ReactNode */) =>
                    handleChangeSelectValue('providerId', event.target.value)
                  }
                  required
                >
                  {softwareStore.providers?.map(provider => {
                    return (
                      <MenuItem key={nanoid()} value={provider.id}>
                        {provider.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </S.ContentElement>
              <S.ContentElement>
                <Typography>Software</Typography>
                <Select
                  value={selectValues.softwareId}
                  size="small"
                  fullWidth
                  onChange={(event: SelectChangeEvent<string> /* child: React.ReactNode */) => {
                    remove();
                    handleChangeSelectValue('softwareId', event.target.value);
                  }}
                  required
                >
                  {softwareStore.softwares?.map(sw => {
                    return (
                      <MenuItem key={nanoid()} value={sw.id}>
                        {sw.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </S.ContentElement>
            </S.ContentElementWrapper>
            <S.ContentElementWrapper>
              <S.ContentElement>
                <Typography>Time Zone</Typography>
                <Select
                  size="medium"
                  fullWidth
                  value={selectValues.timezone.value}
                  onChange={e => {
                    const tz = timezoneOptions.find(tz => tz.value === e.target.value);
                    if (tz) {
                      handleChangeTimezone(tz);
                    }
                  }}
                  MenuProps={{ sx: { height: '300px' } }}
                >
                  {timezoneOptions.map(({ value }) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </S.ContentElement>
              <S.ContentElement>
                <Typography>Contract Calidate Period</Typography>
                <S.DateElement>
                  <DesktopDatePicker
                    inputFormat="YYYY-MM-DD"
                    value={startDate}
                    onChange={dateHandler.start}
                    renderInput={params => <TextField {...params} sx={{ flexGrow: 1 }} size="small" />}
                  />
                  <Box>~</Box>
                  <DesktopDatePicker
                    inputFormat="YYYY-MM-DD"
                    value={endDate}
                    onChange={dateHandler.end}
                    renderInput={params => <TextField {...params} sx={{ flexGrow: 1 }} size="small" />}
                  />
                </S.DateElement>
              </S.ContentElement>
            </S.ContentElementWrapper>
            <S.ContentElementWrapper>
              <S.ContentElement>
                <Typography>Amount (KRW)</Typography>
                <TextField {...register('amount')} size="small" />
              </S.ContentElement>
              <S.ContentElement>
                <Typography>Billing Month Cycle</Typography>
                <Select
                  value={selectValues.billingMonthCycle}
                  size="small"
                  fullWidth
                  onChange={(event: SelectChangeEvent<string>, child: React.ReactNode) =>
                    handleChangeSelectValue('billingMonthCycle', event.target.value)
                  }
                >
                  <MenuItem value="0">일시불</MenuItem>
                  <MenuItem value="1">달 (Month)</MenuItem>
                  <MenuItem value="3">분기 (Quarter)</MenuItem>
                  <MenuItem value="6">반기 (Semiannual)</MenuItem>
                  <MenuItem value="12">연 (Annual)</MenuItem>
                </Select>
              </S.ContentElement>
            </S.ContentElementWrapper>
            <S.ContentElementWrapper>
              <S.ContentElement>
                <Typography>Original Amount (USD)</Typography>
                <TextField {...register('originalAmount')} size="small" />
              </S.ContentElement>
              <S.ContentElement>
                <Typography>ExchangeRate</Typography>
                <TextField {...register('exchangeRate')} size="small" />
              </S.ContentElement>
            </S.ContentElementWrapper>
            <S.ContentTableWrapper>
              <Box display="flex" alignItems="center">
                <Typography>Modules</Typography>
                <Checkbox
                  onChange={() => {
                    setUseModule(!useModule);
                  }}
                />
              </Box>
              {useModule ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Module Name</TableCell>
                        <TableCell>Cost (KRW)</TableCell>
                        <TableCell>Original Cost (USD)</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {swModules.map((module, idx) => {
                        return (
                          <TableRow key={nanoid()}>
                            <TableCell>{module.name}</TableCell>
                            <TableCell>
                              <TextField
                                {...register(`modulePurchaseCosts.${idx}.cost`)}
                                size="small"
                                // onChange={e => {
                                //   const acc = swModules.reduce(
                                //     (acc, prev, index) =>
                                //       acc + (idx === index ? 0 : Number(getValues(`modulePurchaseCosts.${index}.cost`))),
                                //     Number(e.target.value),
                                //   );
                                //   setValue('amount', acc);
                                // }}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField {...register(`modulePurchaseCosts.${idx}.originalCost`)} size="small" />
                            </TableCell>
                            <TableCell>
                              <TextField {...register(`modulePurchaseCosts.${idx}.price`)} size="small" />
                            </TableCell>
                            <TableCell>
                              <TextField {...register(`modulePurchaseCosts.${idx}.quantity`)} size="small" />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </S.ContentTableWrapper>
          </S.ModalContentWrapper>

          <S.ButtonsWrapper>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="outlined" type="submit">
              Create
            </Button>
          </S.ButtonsWrapper>
        </form>
      </S.Container>
    </Modal>
  );
});
