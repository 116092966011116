const defaultWidth = 24;
const defaultHeight = 24;

const ToolsIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.08695 2.72474C8.08215 2.72632 8.07194 2.73041 8.05679 2.74103C6.28135 3.98603 5.12305 6.04582 5.12305 8.37606C5.12305 10.932 6.51703 13.1632 8.5896 14.3494C9.13034 14.6589 9.52062 15.2547 9.48201 15.9446L9.4819 15.9465L9.18062 21.6931C9.16005 22.0855 8.82529 22.3869 8.43292 22.3663C8.04054 22.3457 7.73914 22.011 7.75971 21.6186L8.06118 15.8687L8.06136 15.8654C8.06626 15.7796 8.01661 15.6609 7.88283 15.5843C5.38554 14.155 3.7002 11.4628 3.7002 8.37606C3.7002 5.56239 5.10091 3.07596 7.23988 1.57605C7.76682 1.20654 8.38936 1.23049 8.861 1.51485C9.32049 1.79189 9.62877 2.30648 9.62877 2.90038V7.37157C9.62877 7.45392 9.67149 7.53037 9.74162 7.57353L11.8759 8.88694C11.9521 8.93384 12.0483 8.93384 12.1245 8.88694L14.2588 7.57353C14.3289 7.53037 14.3716 7.45392 14.3716 7.37157V2.90037C14.3716 2.30648 14.6799 1.79189 15.1394 1.51485C15.611 1.23049 16.2336 1.20654 16.7605 1.57605C18.8995 3.07596 20.3002 5.56239 20.3002 8.37606C20.3002 11.4628 18.6149 14.155 16.1176 15.5843C15.9838 15.6609 15.9341 15.7796 15.939 15.8654L15.9392 15.8687L16.2407 21.6186C16.2612 22.011 15.9598 22.3457 15.5675 22.3663C15.1751 22.3869 14.8403 22.0855 14.8198 21.6931L14.5185 15.9465L14.5184 15.9447C14.4797 15.2547 14.87 14.6589 15.4108 14.3494C17.4834 13.1632 18.8773 10.932 18.8773 8.37606C18.8773 6.04582 17.719 3.98603 15.9436 2.74103C15.9284 2.73041 15.9182 2.72632 15.9134 2.72474C15.9086 2.72314 15.904 2.72288 15.904 2.72288C15.901 2.72283 15.8906 2.7234 15.8741 2.73337C15.8396 2.75415 15.7945 2.80759 15.7945 2.90037V7.37157C15.7945 7.94802 15.4954 8.4832 15.0045 8.78532L12.8702 10.0987C12.3367 10.4271 11.6637 10.4271 11.1302 10.0987L8.99591 8.78532C8.50497 8.4832 8.20591 7.94802 8.20591 7.37157V2.90038C8.20591 2.80759 8.16079 2.75415 8.12633 2.73337C8.10979 2.7234 8.09942 2.72283 8.09643 2.72288C8.09643 2.72288 8.0918 2.72314 8.08695 2.72474Z"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ToolsIcon;
