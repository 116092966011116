import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import * as Yup from 'yup';

export type FormRequest = {
  productPlanId: string;
  inquiry: string;
  softwareId: string;
};

export const useItAdminFormOptions = () => {
  const validationItAdminSchema = Yup.object().shape({
    productPlanId: Yup.string().required(),
    inquiry: Yup.string(),
  });
  const ItAdminFormOptions: UseFormProps<FormRequest> = {
    resolver: yupResolver(validationItAdminSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<FormRequest>(ItAdminFormOptions);
};
