import { AxiosError } from 'axios';
import i18n from './i18n';

type ErrorMessageType = {
  errorType?: string;
  message: string;
};

export const getErrorMessage = (error: unknown): ErrorMessageType => {
  if (!(error instanceof AxiosError)) return { message: error instanceof Error ? error.message : 'Unknown Error' };
  const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

  const messageId: string = error.response?.data.errorCode?.messageId;
  if (messageId) {
    const i18nMessage = i18n.t(`Error.${messageId}`);
    return {
      errorType: messageId,
      message: i18nMessage ?? error.response?.data.message,
    };
  }
  return { errorType: UNKNOWN_ERROR, message: error.message };
};
