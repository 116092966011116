import { makeAutoObservable } from 'mobx';
import { Software } from '@repositories/softwareRepository';
import { Tenant, TenantSimple } from '@repositories/tenantRepository';
import { GetProfitStatFilter, MonthlyProfitByType, MonthlyProfitByTypeStat } from '@type/Statistics';
import { MonthlyData, MonthlySummaryWithTotal } from '@type/Summary';
import { ObjectUtil } from '@utils/ObjectUtil';
import { MonthlyDataMapper } from '@utils/mapper';

export class MonthlyProfitByTypeStatModel<DataType extends Software | TenantSimple>
  implements MonthlyProfitByTypeStat<DataType>
{
  aggregator: GetProfitStatFilter;

  currencyUnit: CurrencyUnit;

  data: MonthlyProfitByType<DataType>[];

  originalCurrencyUnit: CurrencyUnit;

  totalProfit: number;

  totalProfitRate: number;

  totalPurchaseAmount: number;

  totalPurchaseOriginalAmount: number;

  totalSalesAmount: number;

  totalSalesOriginalAmount: number;

  isSoftwareType: boolean;

  constructor(isSoftwareType: boolean, dto?: MonthlyProfitByTypeStat<DataType>) {
    this.aggregator = {};
    this.currencyUnit = 'KRW';
    this.data = [];
    this.originalCurrencyUnit = 'USD';
    this.totalProfit = -1;
    this.totalProfitRate = -1;
    this.totalPurchaseAmount = -1;
    this.totalPurchaseOriginalAmount = -1;
    this.totalSalesAmount = -1;
    this.totalSalesOriginalAmount = -1;
    this.isSoftwareType = isSoftwareType;

    if (dto) {
      this.update(dto);
    }

    makeAutoObservable(this);
  }

  update = (dto: Partial<MonthlyProfitByTypeStat<DataType>>) => {
    ObjectUtil.update(this, dto);
  };

  get monthlyData(): MonthlySummaryWithTotal[] {
    return this.data.map(element => {
      let name = '';
      if (this.isSoftwareType) {
        const { software } = element as MonthlyProfitByType<Software>;
        name = software ? software.name : 'Others';
      } else {
        const { tenant } = element as MonthlyProfitByType<Tenant>;
        name = tenant ? tenant.name : 'Others';
      }

      const monthlyData = {} as MonthlyData;
      MonthlyDataMapper.forEach(month => {
        monthlyData[month] = 0;
      });

      delete monthlyData['-'];
      element.data.forEach(({ month, profit }) => {
        const nMonth = parseInt(month.split('-')[1], 10);
        monthlyData[MonthlyDataMapper[nMonth]] = profit;
      });

      return {
        name,
        data: monthlyData,
        unit: this.currencyUnit,
        total: element.totalProfit,
      };
    });
  }

  get barChartDataBySoftware(): { name: string; data: number; percent: number }[] {
    if (!this.isSoftwareType) {
      return [];
    }

    const data = this.data as unknown as MonthlyProfitByType<Software>[];
    return data.map(({ software, totalProfit }) => {
      return {
        name: software ? software.name : 'Others',
        data: totalProfit,
        percent: (totalProfit * 100) / this.totalProfit,
      };
    });
  }

  get barCharDataByTenant(): { name: string; data: number; percent: number }[] {
    if (this.isSoftwareType) {
      return [];
    }

    const data = this.data as unknown as MonthlyProfitByType<Tenant>[];
    return data.map(({ tenant, totalProfit }) => ({
      name: tenant ? tenant.name : 'Others',
      data: totalProfit,
      percent: (totalProfit * 100) / this.totalProfit,
    }));
  }
}
