import { XIcon } from '@icons/XIcon';
import { Order } from '@models/softwareModels/CartProductModel';
import { Avatar, Button, IconButton, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CartProductModelMapper from '@repositories/browserStorage/mapper/CartProductModelMapper';
import sessionStorage from '@repositories/browserStorage/sessionStorage/SessionStorage';
import { SubscriptionType } from '@repositories/subscriptionRepository';
import { useStore } from '@stores/RootStore';
import dayjs from 'dayjs';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './OrderModifyModalStyles';

export const OrderModifyModal = observer(() => {
  const { t } = useTranslation();
  const {
    uiStore: { orderModifyModalStore: modal },
  } = useStore();

  const [order, setOrder] = useState<Order>({
    subscriptionType: modal.product.subscriptionType || 'ON_DEMAND',
    startDate: modal.product.startDate || dayjs().format('YYYY-MM-DD'),
    endDate: modal.product.endDate || dayjs().format('YYYY-MM-DD'),
    request: modal.product.request || '',
  });

  const autoSetEndDate = (subscriptionType: SubscriptionType, startDate: string) => {
    if (subscriptionType === 'ON_DEMAND')
      setOrder(prev => {
        return { ...prev, endDate: dayjs(startDate).format('YYYY-MM-DD') };
      });
    if (subscriptionType === 'FIXED')
      setOrder(prev => {
        return { ...prev, endDate: dayjs(startDate).add(1, 'y').format('YYYY-MM-DD') };
      });
    if (subscriptionType === 'FIXED_2')
      setOrder(prev => {
        return { ...prev, endDate: dayjs(startDate).add(2, 'y').format('YYYY-MM-DD') };
      });
    if (subscriptionType === 'FIXED_3')
      setOrder(prev => {
        return { ...prev, endDate: dayjs(startDate).add(3, 'y').format('YYYY-MM-DD') };
      });
  };

  const handleClickConfirmBtn = () => {
    const origin = _.cloneDeep(modal.product);
    origin.setOrder(order);

    const originCartItems = sessionStorage.get('cartProduct', CartProductModelMapper);
    if (Array.isArray(originCartItems)) {
      originCartItems.splice(modal.idx, 1, origin);
      sessionStorage.set('cartProduct', JSON.stringify(originCartItems));
    }
    modal.close();
  };

  return (
    <Modal open={modal.visible}>
      <S.Container>
        <S.ModalTitleWrapper>
          <Typography variant="headline5" color="#24292F">
            주문수정
          </Typography>
          <IconButton onClick={modal.close}>
            <XIcon width={24} height={24} />
          </IconButton>
        </S.ModalTitleWrapper>
        <S.ModalContentWrapper>
          <S.ProductTitleWrapper>
            <Avatar sx={{ width: '56px', height: '56px' }} />
            <S.ProductTitle>
              <Typography variant="headline6" color="#24292E">
                {modal.product.productName}
              </Typography>
              <Typography variant="body2" color="#6A737D">
                {modal.product.companyName}
              </Typography>
            </S.ProductTitle>
          </S.ProductTitleWrapper>
          <S.SubscriptionTypeWrapper>
            <Typography fontSize="14px" fontWeight={700} lineHeight="20px" letterSpacing="0.15px">
              구독 종류*
            </Typography>
            <Select
              fullWidth
              size="medium"
              value={order.subscriptionType}
              onChange={e => {
                setOrder(prev => {
                  return { ...prev, subscriptionType: e.target.value as SubscriptionType };
                });
                autoSetEndDate(e.target.value as SubscriptionType, order.startDate);
              }}
            >
              <MenuItem value="ON_DEMAND">On-Demand</MenuItem>
              <MenuItem value="FIXED">1년 약정</MenuItem>
              <MenuItem value="FIXED_2">2년 약정</MenuItem>
              <MenuItem value="FIXED_3">3년 약정</MenuItem>
            </Select>
          </S.SubscriptionTypeWrapper>
          <S.SubscriptionDurationWrapper>
            <Typography fontSize="14px" fontWeight={700} lineHeight="20px" letterSpacing="0.15px">
              {t('Subscrib_Detail_Overview_13')}*
            </Typography>
            <S.Duration>
              <DatePicker
                value={order.startDate}
                inputFormat="YYYY년 MM월 DD일"
                onChange={value => {
                  const start = dayjs(value).format('YYYY-MM-DD');
                  setOrder(prev => {
                    return { ...prev, startDate: start };
                  });
                  autoSetEndDate(order.subscriptionType, start);
                }}
                renderInput={params => <TextField {...params} />}
              />
              <div>~</div>
              <DatePicker
                value={order.endDate}
                inputFormat="YYYY년 MM월 DD일"
                onChange={value => {
                  const end = dayjs(value).format('YYYY-MM-DD');
                  setOrder(prev => {
                    return { ...prev, endDate: end };
                  });
                }}
                renderInput={params => <TextField {...params} />}
                disabled={order.subscriptionType !== 'ON_DEMAND'}
              />
            </S.Duration>
          </S.SubscriptionDurationWrapper>
          <S.RequestWrapper>
            <Typography fontSize="14px" fontWeight={700} lineHeight="20px" letterSpacing="0.15px">
              요청 사항*
            </Typography>
            <TextField
              value={order.request}
              onChange={e => {
                setOrder(prev => {
                  return { ...prev, request: e.target.value };
                });
              }}
              multiline
              rows={4}
            />
          </S.RequestWrapper>
          <S.ButtonsWrapper>
            <Button fullWidth variant="outlined" onClick={modal.close}>
              {t('Subscrib_Detail_BuyRequest_13')}
            </Button>
            <Button fullWidth variant="contained" onClick={handleClickConfirmBtn}>
              {t('Member_User_24')}
            </Button>
          </S.ButtonsWrapper>
        </S.ModalContentWrapper>
      </S.Container>
    </Modal>
  );
});
