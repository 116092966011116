import type { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { Area, Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import type {
  SubscriptionDailyUsageFilter,
  SubscriptionMonthlyUsageFilter,
} from '@repositories/subscriptionRepository';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetUsageMetricList } from '@queryHooks/useSoftware';
import { useGetSubscriptionDailyUsage, useGetSubscriptionMonthlyUsage } from '@queryHooks/useSubscription';
import { numberTickFormatter } from '@utils/ChartUtil';
import { numberFormat } from '@utils/numberFormat';
import { DailyUsageModel } from '@models/usageModels/DailyUsageModel';
import { SkeletonBarChart } from '../summary/slots/SkeletonChart';

type LineChartData = {
  date: string;
  value: number;
  limit: number;
  unit: string;
  isNotMeasured?: true;
  isForecasted: boolean;
};
type Props =
  | {
      chartMode: 'day';
      chartApiFilter: SubscriptionDailyUsageFilter;
    }
  | {
      chartMode: 'month';
      chartApiFilter: SubscriptionMonthlyUsageFilter;
    };
export const UsageChart = ({ chartMode, chartApiFilter }: Props) => {
  const subscription = useSubscriptionModel();

  const startDayjs = dayjs(chartMode === 'day' ? chartApiFilter.dateFrom : chartApiFilter.monthFrom);
  const endDayjs = dayjs(chartMode === 'day' ? chartApiFilter.dateTo : chartApiFilter.monthTo);

  const { data: usageMetrics, isSuccess: isUsageMetricsSuccess } = useGetUsageMetricList(subscription.software.id);
  const { data: dailyUsageData, isSuccess: isDailyUsagesSuccess } = useGetSubscriptionDailyUsage(
    subscription.id,
    {
      ...chartApiFilter,
      size: Math.max(endDayjs.diff(startDayjs, 'day'), 50),
    },
    {
      enabled: chartMode === 'day' && !!chartApiFilter.dateFrom && !!chartApiFilter.dateTo,
    },
  );
  const { data: monthlyUsageData, isSuccess: isMonthlyUsagesSuccess } = useGetSubscriptionMonthlyUsage(
    subscription.id,
    {
      ...chartApiFilter,
      size: Math.max(endDayjs.diff(startDayjs, 'month'), 50),
    },
    {
      enabled: chartMode === 'month' && !!chartApiFilter.monthFrom && !!chartApiFilter.monthTo,
    },
  );
  const isUsagesSuccess = chartMode === 'day' ? isDailyUsagesSuccess : isMonthlyUsagesSuccess;

  const usageData = chartMode === 'day' ? dailyUsageData : monthlyUsageData;
  const totalUsageKey =
    usageData.content[0]?.usageList.findIndex(({ module }) => module === 'TOTAL_USAGE') >= 0 ? 'TOTAL_USAGE' : '';

  const lineChartDatas: { [moduleName: string]: LineChartData[] } = (() => {
    if (!isUsageMetricsSuccess || !isUsagesSuccess) {
      return {};
    }

    const returnVal: { [moduleName: string]: LineChartData[] } = {};

    const totalUsageMetric = totalUsageKey ? [{ usageMetricCode: totalUsageKey, usageMetricName: 'Total' }] : [];

    [...totalUsageMetric, ...usageMetrics].forEach(({ usageMetricCode, usageMetricName }) => {
      if (!returnVal[usageMetricName]) {
        returnVal[usageMetricName] = [];
      }
      const usageList = usageData.content.reduce((accum, usage) => {
        const date = usage instanceof DailyUsageModel ? usage.date : usage.month;
        const curModuleUsage = usage.usageList.find(({ module }) => module === usageMetricCode);
        if (curModuleUsage) {
          accum.push({
            date,
            value: curModuleUsage.usages[0].value,
            limit: curModuleUsage.usages[0].limit,
            unit: curModuleUsage.usages[0].unit,
            isForecasted: usage.isForecasted,
          });
        }
        return accum;
      }, [] as LineChartData[]);

      let dateIter: Dayjs = startDayjs;
      const lastDayjs: Dayjs = endDayjs;
      while (!dateIter.isAfter(lastDayjs, chartMode)) {
        const formattedDate = dateIter.format(chartMode === 'day' ? 'YYYY.MM.DD' : 'YYYY.MM');
        // eslint-disable-next-line no-loop-func
        const usage = usageList.find(({ date }) => dayjs(date).isSame(dateIter, chartMode));
        if (usage) {
          returnVal[usageMetricName].push({
            ...usage,
            date: formattedDate,
          });
        } else {
          returnVal[usageMetricName].push({
            date: formattedDate,
            value: 0,
            limit: 0,
            unit: '',
            isNotMeasured: true,
            isForecasted: true,
          });
        }

        dateIter = dateIter.add(1, chartMode);
      }
    });

    return returnVal;
  })();

  return isUsageMetricsSuccess && isUsagesSuccess ? (
    <LineChartContainer>
      {usageMetrics.length === 0 ? (
        <SkeletonBarChart monthFrom={startDayjs.format('YYYY-MM')} monthTo={endDayjs.format('YYYY-MM')} />
      ) : (
        <>
          {totalUsageKey && <ModuleChart title="Total" data={lineChartDatas.Total} />}
          {usageMetrics.map(({ usageMetricId, usageMetricName }) => (
            <ModuleChart key={usageMetricId} title={usageMetricName} data={lineChartDatas[usageMetricName]} />
          ))}
        </>
      )}
    </LineChartContainer>
  ) : null;
};

const ModuleChart = ({ title, data }: { title: string; data: LineChartData[] }) => {
  const { colors } = useTheme();
  const yAxisTickFormatter = numberTickFormatter(data.map(({ value }) => value));

  return (
    <div>
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" height={273}>
        {/* NOTE: margin-left: -10은 title과 align을 맞추기 위해 임의로 넣은 값 */}
        <ComposedChart data={data} margin={{ left: -10 }}>
          <defs>
            <linearGradient id="yellow-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.yellow[300]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.yellow[100]}`} />
            </linearGradient>
            <linearGradient id="border-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.yellow[500]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.yellow[300]}`} />
            </linearGradient>
            <linearGradient id="forecasted-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.gray[200]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.gray[50]}`} />
            </linearGradient>
            <linearGradient id="forecasted-border-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.gray[300]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.gray[200]}`} />
            </linearGradient>
            <linearGradient id="bg-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.yellow[100]}FF`} />
              <stop offset="100%" stopColor={`${colorDefinition.yellow[100]}00`} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={colors['border-gray-w-lighter']} strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="date"
            axisLine={{ strokeWidth: '0px' }}
            tickLine={false}
            tick={{ ...fonts.Caption2, color: colors['text-gray-light'] }}
          />
          <YAxis
            axisLine={{ strokeWidth: '0px' }}
            tickLine={false}
            tick={{ ...fonts.Caption2, color: colors['text-gray-light'] }}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip content={<UsageTooltip moduleName={title} />} wrapperStyle={{ zIndex: 1000 }} />
          <Area
            dataKey="value"
            fill="url(#bg-gradient)"
            strokeWidth={0}
            isAnimationActive={false}
            dot={false}
            activeDot={false}
          />
          <Bar dataKey="value" fill="url(#yellow-gradient)" maxBarSize={26} shape={<BarShape uniqueId={title} />} />
          <Line
            key={nanoid()}
            dataKey="value"
            strokeWidth={1}
            stroke={colorDefinition.yellow[600]}
            isAnimationActive={false}
            dot={{
              r: 3,
              stroke: colors['bg-white'],
              strokeWidth: 1,
              fill: colors['border-yellow'],
              fillOpacity: 1,
            }}
            activeDot={{
              r: 6,
              stroke: colors['border-yellow'],
              strokeWidth: 5,
              fill: colors['bg-white'],
              fillOpacity: 1,
              filter: 'drop-shadow(0px 2px 6px  rgba(0, 0, 0, 0.09))',
            }}
            filter="drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
ModuleChart.displayName = 'ModuleChart';

type BarShapeProps = UnknownObject & { payload: LineChartData; index: number; uniqueId: string };
const BarShape = (props: BarShapeProps) => {
  const {
    fill,
    width: borderWidth,
    height: borderHeight,
    x: borderX,
    y: borderY,
    index,
    uniqueId,
    payload: { isForecasted },
  } = props;
  const borderR = 5;
  const r = 4;
  const width = borderWidth - 2;
  const height = borderHeight - 1;
  const x = borderX + 1;
  const y = borderY + 1;

  if (!y || borderHeight <= 0 || height <= 0) {
    return null;
  }

  const trimmedUniqueId = uniqueId.replaceAll(' ', '');
  return (
    <>
      <defs>
        <clipPath id={`${trimmedUniqueId}-border-round-corner-${index}`}>
          <rect x={borderX} y={borderY} width={borderWidth} height={borderHeight + borderR} rx={borderR} ry={borderR} />
        </clipPath>
        <clipPath id={`${trimmedUniqueId}-round-corner-${index}`}>
          <rect x={x} y={y} width={width} height={height + r} rx={r} ry={r} />
        </clipPath>
      </defs>
      <rect
        fill={isForecasted ? 'url(#forecasted-border-gradient)' : 'url(#border-gradient)'}
        width={borderWidth}
        height={borderHeight}
        x={borderX}
        y={borderY}
        clipPath={`url(#${trimmedUniqueId}-border-round-corner-${index})`}
      />
      <rect
        fill={isForecasted ? 'url(#forecasted-gradient)' : fill}
        width={width}
        height={height}
        x={x}
        y={y}
        clipPath={`url(#${trimmedUniqueId}-round-corner-${index})`}
      />
    </>
  );
};

const UsageTooltip = ({
  active,
  payload,
  label,
  moduleName,
}: {
  active?: boolean;
  payload?: { value: number; payload: LineChartData }[];
  label?: string;
  moduleName: string;
}) => {
  const { t } = useTranslation();
  return active && payload && payload.length > 0 ? (
    <TooltipContainer>
      <p>{label}</p>
      <dl>
        <dt>
          {moduleName === t('Acc_Main_12')
            ? t('Acc_Main_12')
            : payload[0].payload.isForecasted
            ? '예측 사용량'
            : t('Acc_Detail_14')}
        </dt>
        <dd className={moduleName === t('Acc_Main_12') ? 'summary' : undefined}>
          {payload[0].payload.isNotMeasured
            ? '-'
            : `${numberFormat({ num: payload[0].value, maxFractionDigit: 5 })} ${payload[0].payload.unit}`}
        </dd>
      </dl>
    </TooltipContainer>
  ) : null;
};

const LineChartContainer = styled.div`
  & > div {
    margin-bottom: 20px;
    border: 1px solid red;
    padding: 30px 35px 15px;
    border-radius: 8px;
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
    box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;
  }

  & .title {
    margin-bottom: 16px;
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }

  & .recharts-cartesian-grid-horizontal > line {
    stroke: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};

    &:first-of-type {
      stroke-dasharray: none;
    }

    &:last-of-type {
      stroke: none;
    }
  }

  & .recharts-cartesian-axis-tick > text > tspan {
    fill: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
`;

const TooltipContainer = styled.div`
  min-width: 230px;
  background: ${({ theme: { colors } }) => colors['bg-gray-main']};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  padding: 12px 16px;
  z-index: 99999999;

  & > p {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
    margin-bottom: 18px;
  }

  & > dl {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    row-gap: 6px;
    margin: 0px;

    & > dt {
      display: flex;
      align-items: center;
      gap: 11px;
      ${fonts.Body2}
      color: ${({ theme: { colors } }) => colors['text-white']};
    }
    & > dd {
      ${fonts.Headline8}
      color: ${({ theme: { colors } }) => colors['text-white']};
      margin: 0px;
      text-align: right;

      &.summary {
        color: ${({ theme: { colors } }) => colors['text-yellow-light']};
      }
    }
  }
`;
