import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import IllustExit from '@icons/IllustExit';
import { IllustGroup } from '@icons/IllustGroup';
import IllustInactive from '@icons/IllustInactive';
import { IllustNew } from '@icons/IllustNew';
import { IllustNomember } from '@icons/IllustNomember';
import { IllustOut } from '@icons/IllustOut';
import { IllustSchedule } from '@icons/IllustSchedule';
import type { WorkflowBaseTriggerDTOModel } from '@models/workflow/WorkflowBaseTriggerDTOModel';

interface InnerTriggerProps {
  trigger: WorkflowBaseTriggerDTOModel;
}

export const InnerTrigger = ({ trigger }: InnerTriggerProps) => {
  return (
    <InnerTriggerContainer>
      <TriggerIcon>
        {trigger.eventName === 'TENANT_MEMBER_JOINED' && <IllustNew />}
        {trigger.eventName === 'TENANT_MEMBER_LEFT' && <IllustExit />}
        {trigger.eventName === 'MEMBER_GROUP_JOINED' && <IllustGroup />}
        {trigger.eventName === 'MEMBER_GROUP_LEFT' && <IllustOut />}
        {trigger.eventName === 'SCHEDULED_EVENT_TRIGGERED' && <IllustSchedule />}
        {trigger.eventName === 'INACTIVE_USER_DETECTED' && <IllustInactive />}
        {trigger.eventName === 'EXTERNAL_USER_DETECTED' && <IllustNomember />}
      </TriggerIcon>
      <TriggerInfo>
        <TriggerName>{trigger.name}</TriggerName>
        <TriggerDescription>{trigger.description}</TriggerDescription>
      </TriggerInfo>
    </InnerTriggerContainer>
  );
};

interface Props {
  trigger: WorkflowBaseTriggerDTOModel;
  onClick: (trigger: WorkflowBaseTriggerDTOModel) => void;
  selected?: string;
}

export const Trigger = ({ trigger, onClick: handleClick, selected }: Props) => {
  return (
    <TriggerElements onClick={() => handleClick(trigger)} isSelected={selected === trigger.id}>
      <InnerTrigger trigger={trigger} />
    </TriggerElements>
  );
};

const TriggerElements = styled.div<{ isSelected?: boolean }>`
  display: flex;
  width: 100%;
  padding: 18px 30px;
  background-color: ${props =>
    props.isSelected ? props.theme.colors['bg-purple-lighter'] : props.theme.colors['bg-white']};
  border: 1px solid
    ${props =>
      props.isSelected ? props.theme.colors['border-purple-lighter'] : props.theme.colors['border-gray-lighter']};
  border-radius: 5px;

  :hover {
    border: 1px solid ${({ theme: { colors } }) => colors['border-purple-light']};
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
  }
`;

const InnerTriggerContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

const TriggerIcon = styled.span`
  display: flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 4px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
`;

const TriggerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const TriggerName = styled.strong`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const TriggerDescription = styled.p`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;
