import { makeAutoObservable } from 'mobx';
import type { Software } from '@repositories/softwareRepository';
import type { Tenant, TenantSimple } from '@repositories/tenantRepository';
import { MonthlySalesAggregate, MonthlySalesByType, MonthlySalesByTypeStat } from '@type/Statistics';
import { MonthlyData, MonthlySummaryWithTotal } from '@type/Summary';
import { ObjectUtil } from '@utils/ObjectUtil';
import { MonthlyDataMapper } from '@utils/mapper';

export class MonthlySalesByTypeStatModel<DataType extends Software | TenantSimple>
  implements MonthlySalesByTypeStat<DataType>
{
  aggregator: MonthlySalesAggregate;

  data: MonthlySalesByType<DataType>[];

  totalAmount: number;

  totalOriginalAmount: number;

  currencyUnit: CurrencyUnit;

  originalCurrencyUnit: CurrencyUnit;

  isSoftwareType: boolean;

  constructor(isSoftwareType: boolean, dto?: MonthlySalesByTypeStat<DataType>) {
    this.aggregator = {
      monthFrom: '',
      monthTo: '',
      softwareId: '',
      subscriptionId: '',
      tenantId: '',
      tenantLevel: -1,
    };
    this.data = [];
    this.totalAmount = -1;
    this.totalOriginalAmount = -1;
    this.currencyUnit = 'KRW';
    this.originalCurrencyUnit = 'USD';
    this.isSoftwareType = isSoftwareType;

    if (dto) {
      this.update(dto);
    }
    makeAutoObservable(this);
  }

  update = (dto: Partial<MonthlySalesByTypeStat<DataType>>) => {
    ObjectUtil.update(this, dto);
  };

  get monthlyData(): MonthlySummaryWithTotal[] {
    return this.data.map(element => {
      let name = '';
      if (this.isSoftwareType) {
        const { software } = element as MonthlySalesByType<Software>;
        name = software ? software.name : 'Others';
      } else {
        const { tenant } = element as MonthlySalesByType<Tenant>;
        name = tenant ? tenant.name : 'Others';
      }

      const monthlyData = {} as MonthlyData;
      MonthlyDataMapper.forEach(month => {
        monthlyData[month] = 0;
      });

      delete monthlyData['-'];

      element.data.forEach(({ month, amount }) => {
        const nMonth = parseInt(month.split('-')[1], 10);
        monthlyData[MonthlyDataMapper[nMonth]] = amount;
      });

      return {
        name,
        data: monthlyData,
        unit: this.currencyUnit,
        total: element.totalAmount,
      };
    });
  }
}
