import { RecommendationModel } from '@models/RecommendationModel';
import { KEYS, repository } from '@repositories/Repository';
import { RecommendationCreateRq, RecommendationFilter } from '@repositories/recommendationRepository';
import IRecommendationRepository from '@repositories/recommendationRepository/IRecommendationRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const recommendationRepo = repository.get<IRecommendationRepository>(KEYS.RECOMMENDATION_REPOSITORY);

const recommendationQueryKey = {
  all: ['recommendation'] as const,
  lists: () => [...recommendationQueryKey.all, 'list'] as const,
  list: (filter: RecommendationFilter) => [...recommendationQueryKey.lists(), filter] as const,
};

export const useRecommendationList = (
  queries: RecommendationFilter,
  options?: UseQueryOptionsType<Page<RecommendationModel>>,
) => {
  const result = useQuery<Page<RecommendationModel>, AxiosError>(
    recommendationQueryKey.list(queries),
    async () => {
      const result = await recommendationRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new RecommendationModel(dto)),
      };
    },
    {
      ...options,
    },
  );

  const initialData = useMemo(() => ({ content: [] as RecommendationModel[], totalElements: 0 }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useAddRecommendation = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: RecommendationCreateRq) => recommendationRepo.create({ ...data }), {
    onSuccess: () => {
      queryClient.invalidateQueries(recommendationQueryKey.lists());
    },
  });
};

export const useDeleteRecommendation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, tenantId }: { id: string; tenantId?: string }) => recommendationRepo.delete(id, tenantId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(recommendationQueryKey.lists());
      },
    },
  );
};
