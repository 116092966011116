import styled from '@emotion/styled';

export const FormItemContainer = styled.div<{ direction?: 'row' | 'column'; border?: string; margin?: string }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ direction }) => direction ?? 'column'};
  gap: ${({ direction }) => (direction === 'row' ? 'none' : '10px')};
  border: ${({ border }) => border ?? 'none'};
  margin: ${({ margin }) => margin ?? ''};
`;
