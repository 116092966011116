const defaultWidth = 16;
const defaultHeight = 16;

export const DepthIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.45947 3.23677C5.14378 3.23677 4.88787 3.49268 4.88787 3.80837L4.88786 10.5448C4.88787 10.8605 5.14378 11.1164 5.45947 11.1164L12.1959 11.1164C12.5116 11.1164 12.7675 10.8605 12.7675 10.5448C12.7675 10.2291 12.5116 9.97322 12.1959 9.97322L6.03108 9.97322L6.03108 3.80837C6.03108 3.49268 5.77516 3.23677 5.45947 3.23677Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.23108 9.77335L6.23108 3.8085C6.23108 3.38236 5.88562 3.0369 5.45947 3.0369C5.03333 3.0369 4.68787 3.38236 4.68787 3.8085L4.68786 10.545C4.68786 10.9711 5.03332 11.3166 5.45947 11.3166L12.1959 11.3166C12.6221 11.3166 12.9675 10.9711 12.9675 10.545C12.9675 10.1188 12.6221 9.77335 12.1959 9.77335L6.23108 9.77335Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

DepthIcon.displayName = 'DepthIcon';
