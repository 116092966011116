import { SVGProps } from 'react';
import { styled } from '@mui/material';
import { transientOptions } from '@utils/CommonUtil';

type SvgProps = SVGProps<SVGSVGElement> & {
  width?: string;
  height?: string;
  color?: string;
  margin?: string;
};

export const DottedBorderIcon = ({ width = '100%', height = '1px', margin, color = '#d1d5da' }: SvgProps) => {
  return (
    <StyledDiv $margin={margin}>
      <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="4.37114e-08" y1="0.5" x2="672" y2="0.500059" stroke={color} strokeDasharray="4 4" />
      </svg>
    </StyledDiv>
  );
};

DottedBorderIcon.displayName = 'DottedBorderIcon';

const StyledDiv = styled('div', transientOptions)<{ $margin: SvgProps['width'] }>`
  width: 100%;
  height: auto;
  display: inline-flex;
  margin: ${({ $margin }) => $margin || '0px'};
`;
