import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { IOauthRepository } from './IOauthRepository';
import { OauthFilter } from './Types';
import 'reflect-metadata';

@injectable()
export class OauthRepository implements IOauthRepository {
  async callback(queries: OauthFilter): Promise<void> {
    const queryString = qs.stringify(queries);
    const result = await API.get<void>(`/api/v1/oauth/callback?${queryString}`);
    return result.data;
  }
}
