interface IEventTarget extends EventTarget {
  scrollHeight?: number;
  scrollTop?: number;
  clientHeight?: number;
}
export const DomUtil = {
  /**
   * 주어진 HTML 요소의 스크롤이 최하단에 위치하는지 확인합니다.
   * @param element 스크롤을 확인할 HTML 요소
   * @param threshold 스크롤이 최하단으로 판단되기 위한 임계값 (기본값: 500)
   * @returns 스크롤이 최하단에 위치하면 true, 그렇지 않으면 false
   */
  isScrollBottom: (target: HTMLElement | IEventTarget, threshold = 500): boolean => {
    if (!target.scrollHeight || !target.scrollTop || !target.clientHeight) {
      return false;
    }
    try {
      return target.scrollHeight - target.scrollTop - target.clientHeight < threshold;
    } catch {
      return false;
    }
  },
};
