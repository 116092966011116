import { Tenant, TenantPath } from '@repositories/tenantRepository/Types';
import dayjs, { Dayjs } from 'dayjs';
import { CompanySimpleModel } from '@models/CompanySimpleModel';

export class TenantModel {
  private readonly _id: string;
  private readonly _tenantCode: string;
  private readonly _description?: string;
  private readonly _name: string;
  private readonly _level: number;
  private readonly _properties?: Record<string, string>;
  private readonly _subTenants?: TenantModel[];
  private readonly _parentTenant?: TenantModel;
  private readonly _tenantPath?: TenantPath;
  private readonly _company?: CompanySimpleModel;
  private readonly _logoImageUrl: string;
  private readonly _currencyUnit: CurrencyUnit;

  private readonly _createdBy: string;
  private readonly _createdDate: Dayjs;
  private readonly _updatedBy: string;
  private readonly _updatedDate: Dayjs;

  constructor(dto?: Tenant) {
    this._id = dto?.id || '';
    this._tenantCode = dto?.tenantCode || '';
    this._description = dto?.description;
    this._name = dto?.name || '';
    this._level = dto?.level || 0;
    this._properties = dto?.properties;
    this._subTenants = dto?.subTenants ? dto?.subTenants?.map(tenant => new TenantModel(tenant)) : [];
    this._parentTenant = dto?.parentTenant ? new TenantModel(dto?.parentTenant) : undefined;
    this._tenantPath = dto?.tenantPath;
    this._company = dto?.company ? new CompanySimpleModel(dto.company) : undefined;
    this._logoImageUrl = dto?.logoImageUrl ?? '';
    this._currencyUnit = dto?.currencyUnit ?? 'USD';

    this._createdBy = dto?.createdBy || '';
    this._updatedBy = dto?.updatedBy || '';
    this._createdDate = dayjs(dto?.createdDate) || dayjs();
    this._updatedDate = dayjs(dto?.updatedDate) || dayjs();
  }

  get id(): string {
    return this._id;
  }

  get tenantCode(): string {
    return this._tenantCode;
  }

  get description(): string {
    if (this._description === undefined) return '';
    return this._description;
  }

  get name(): string {
    return this._name;
  }

  get level(): number {
    return this._level;
  }

  get properties(): Record<string, string> {
    return this._properties ?? {};
  }

  get subTenants(): TenantModel[] {
    return this._subTenants as TenantModel[];
  }

  hasParentTenant(): boolean {
    return !!this._parentTenant;
  }

  get parentTenant(): TenantModel {
    if (this._parentTenant === undefined) return new TenantModel();
    return this._parentTenant;
  }

  get tenantPath(): TenantPath | undefined {
    return this._tenantPath;
  }

  isCompanyTenant() {
    return !!this._company;
  }

  get company() {
    return this._company;
  }

  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this._createdDate;
  }

  get updatedBy() {
    return this._updatedBy;
  }

  get updatedDate() {
    return this._updatedDate;
  }

  get logoImageUrl() {
    return this._logoImageUrl;
  }

  get currencyUnit() {
    return this._currencyUnit;
  }
}
