import { ExcelFillIcon } from '@icons/ExcelFillIcon';
import { HangulFillIcon } from '@icons/HangulFillIcon';
import { NoFileFillIcon } from '@icons/NoFileFillIcon';
import { PDFFillIcon } from '@icons/PDFFillIcon';
import { PPTFillIcon } from '@icons/PPTFillIcon';
import { WordFillIcon } from '@icons/WordFillIcon';

type Props = {
  fileName?: string;
  fileExtension?: string;
} & SvgProps;
export const FileIcon = ({ fileName, fileExtension, ...svgProps }: Props) => {
  let extension = '';
  if (fileExtension) {
    extension = fileExtension;
  } else if (fileName && fileName.includes('.')) {
    const arr = fileName.split('.').filter(splitted => splitted !== '');
    if (arr.length > 0) {
      extension = arr[arr.length - 1];
    }
  }

  switch (extension.toLowerCase()) {
    case 'xls':
    case 'xlsx':
      return <ExcelFillIcon {...svgProps} />;
    case 'doc':
    case 'docx':
      return <WordFillIcon {...svgProps} />;
    case 'ppt':
    case 'pptx':
      return <PPTFillIcon {...svgProps} />;
    case 'hwp':
      return <HangulFillIcon {...svgProps} />;
    case 'pdf':
      return <PDFFillIcon {...svgProps} />;
    default:
      return <NoFileFillIcon {...svgProps} />;
  }
};
