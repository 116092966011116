import { AggregateUserCountByStatusDTO } from '@repositories/userStatRepository/Types';

export class AggregateUserCountByStatusDTOModel {
  private readonly _dto: AggregateUserCountByStatusDTO;

  constructor(dto: AggregateUserCountByStatusDTO) {
    this._dto = dto;
  }

  get billableUserCount(): number {
    return this._dto.billableUserCount || 0;
  }

  get iconUrl(): string {
    return this._dto.iconUrl || '';
  }

  get inactiveUserCount(): number {
    return this._dto.inactiveUserCount || 0;
  }

  get softwareId(): string {
    return this._dto.softwareId || '';
  }

  get softwareName(): string {
    return this._dto.softwareName || '';
  }

  get subscriptionId(): string {
    return this._dto.subscriptionId || '';
  }

  get subscriptionName(): string {
    return this._dto.subscriptionName || '';
  }
}
