/**
 * size에 따라 내부 값들이 조금씩 달라서 width, height 값을 고정시킴
 */

export const Loading20Icon = ({ color = '#6f42c1', ...props }: SvgProps) => (
  <svg width={20} height={20} fill="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color}
        strokeWidth="10"
        r="32"
        strokeDasharray="150.79644737231007 52.26548245743669"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </g>
  </svg>
);

export const Loading48Icon = ({ color = '#6f42c1', ...props }: SvgProps) => (
  <svg width={48} height={48} fill="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color}
        strokeWidth="8"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </g>
  </svg>
);
