import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Container = styled.div`
  margin-top: 12px;
`;

export const HistoryList = styled.ul`
  width: 100%;
`;

export const HistoryItem = styled.li`
  width: 100%;
  padding: 32px 24px 40px;
`;

export const SubHeaderWrap = styled.div`
  margin-bottom: 16px;
`;

export const SubBodyWrap = styled.ul`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 17px;
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
`;

export const SubItem = styled.li`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  &:nth-of-type(odd) {
    border-right: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  }
  &:nth-of-type(even) {
    padding-left: 20px;
  }
  &:last-of-type {
    width: 100%;
    border-right: none;
  }
`;

export const SubItemTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
` as typeof Typography;
