import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 700px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border: 2px solid #fff;
  border-radius: 10px;

  overflow: auto;
  padding: 32px;
  gap: 24px;
`;

export const ModalTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const FieldsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BtnsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 16px;
`;

export const TypeWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const DateWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const CellWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const PropertiesWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
