import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

interface Props {
  isNext?: boolean;
}

export const EmptyPayment = ({ isNext = false }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#F5F0FF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9.74976C17.3418 9.74976 16.8083 10.2833 16.8083 10.9414V20.4748C16.8083 21.1329 17.3418 21.6664 18 21.6664C18.6581 21.6664 19.1916 21.1329 19.1916 20.4748V10.9414C19.1916 10.2833 18.6581 9.74976 18 9.74976ZM18 26.2498C18.7594 26.2498 19.375 25.6341 19.375 24.8748C19.375 24.1154 18.7594 23.4998 18 23.4998C17.2406 23.4998 16.625 24.1154 16.625 24.8748C16.625 25.6341 17.2406 26.2498 18 26.2498Z"
          fill="#6F42C1"
        />
      </svg>

      <span>{isNext ? t('Acc_Main_33') : t('Acc_Main_32')}</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  height: 100%;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 7px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
`;
