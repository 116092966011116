import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import qs from 'qs';
import { ISubscriptionRequestRepository } from './ISubscriptionRequestRepository';
import type {
  SubscriptionRequestCreateDTO,
  SubscriptionRequestDTO,
  SubscriptionRequestFilterDTO,
  SubscriptionRequestHistoryDTO,
  SubscriptionRequestManagerUpdateRq,
  SubscriptionRequestStatusUpdateRq,
  SubscriptionRequestUpdateDTO,
} from './Types';

@injectable()
export class SubscriptionRequestRepository implements ISubscriptionRequestRepository {
  async create(data: SubscriptionRequestCreateDTO): Promise<SubscriptionRequestDTO> {
    const result = await API.post<SubscriptionRequestDTO>(`/api/v1/subscription-requests`, data);
    return result.data;
  }

  async getOne(id: number): Promise<SubscriptionRequestDTO> {
    const result = await API.get<SubscriptionRequestDTO>(`/api/v1/subscription-requests/${id}`);
    return result.data;
  }

  async getList(queries?: SubscriptionRequestFilterDTO): Promise<Page<SubscriptionRequestDTO>> {
    const queryString = qs.stringify(queries, { skipNulls: true });
    const result = await API.get<Page<SubscriptionRequestDTO>>(`/api/v1/subscription-requests?${queryString}`);
    return result.data;
  }

  async update(id: number, data: SubscriptionRequestUpdateDTO): Promise<SubscriptionRequestDTO> {
    const result = await API.put<SubscriptionRequestDTO>(`/api/v1/subscription-requests/${id}`, data);
    return result.data;
  }

  async delete(id: number): Promise<void> {
    await API.delete<SubscriptionRequestDTO>(`/api/v1/subscription-requests/${id}`);
  }

  async updateManager(id: number, data: SubscriptionRequestManagerUpdateRq): Promise<SubscriptionRequestHistoryDTO> {
    const result = await API.patch<SubscriptionRequestHistoryDTO>(`/api/v1/subscription-requests/${id}/manager`, data);
    return result.data;
  }

  async updateStatus(id: number, data: SubscriptionRequestStatusUpdateRq): Promise<SubscriptionRequestHistoryDTO> {
    const result = await API.patch<SubscriptionRequestHistoryDTO>(`/api/v1/subscription-requests/${id}/status`, data);
    return result.data;
  }
}
