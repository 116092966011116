const defaultWidth = 24;
const defaultHeight = 24;

export const ResetIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M12.0015 2.93182C15.6452 2.93182 18.7885 5.0802 20.2306 8.18182H17.9659C17.5705 8.18182 17.25 8.50234 17.25 8.89773C17.25 9.29311 17.5705 9.61364 17.9659 9.61364H21.5455C22.0726 9.61364 22.5 9.18627 22.5 8.65909V5.07955C22.5 4.68416 22.1795 4.36364 21.7841 4.36364C21.3887 4.36364 21.0682 4.68416 21.0682 5.07955V6.69896C19.2458 3.58958 15.8683 1.5 12.0015 1.5C6.20178 1.5 1.5 6.20091 1.5 12C1.5 17.7991 6.20178 22.5 12.0015 22.5C17.7135 22.5 22.3604 17.9403 22.4998 12.2622C22.5095 11.8669 22.1969 11.5386 21.8017 11.5289C21.4064 11.5192 21.0781 11.8318 21.0684 12.227C20.9481 17.1301 16.9347 21.0682 12.0015 21.0682C6.99235 21.0682 2.93182 17.0081 2.93182 12C2.93182 6.99188 6.99235 2.93182 12.0015 2.93182Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.911 7.98181H17.9661C17.4603 7.98181 17.0502 8.39187 17.0502 8.89771C17.0502 9.40356 17.4603 9.81362 17.9661 9.81362H21.5456C22.1833 9.81362 22.7002 9.29672 22.7002 8.65908V5.07953C22.7002 4.57369 22.2901 4.16362 21.7843 4.16362C21.2784 4.16362 20.8684 4.57369 20.8684 5.07953V6.00723C18.9451 3.16789 15.6922 1.29999 12.0017 1.29999C6.09155 1.29999 1.3002 6.09041 1.3002 12C1.3002 17.9096 6.09155 22.7 12.0017 22.7C17.8225 22.7 22.5579 18.0535 22.6999 12.2671C22.7123 11.7614 22.3124 11.3414 21.8068 11.329C21.3011 11.3165 20.8811 11.7164 20.8686 12.2221C20.751 17.0169 16.8262 20.8682 12.0017 20.8682C7.10297 20.8682 3.13201 16.8976 3.13201 12C3.13201 7.10236 7.10297 3.13181 12.0017 3.13181C15.4523 3.13181 18.4444 5.10201 19.911 7.98181Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ResetIcon.displayName = 'ResetIcon';
