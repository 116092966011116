import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { nanoid } from 'nanoid';
import { EmptyRow } from '@components/table';
import { fonts } from '@theme/fontsCustomer';
import { SearchIcon } from '@icons/SearchIcon';
import { SubscriptionRequestFilterDTO } from '@repositories/subscriptionRequestRepository';
import { useGetSubscriptionRequests } from '@queryHooks/useSubscriptionRequest';

import { BuyApplyHistoryRow } from './BuyApplyHistoryRow';

export const BuyApplyHistoryList = () => {
  const { t } = useTranslation();
  const tableHeader: TableHeader[] = [
    { name: t('Workflow_Main_04'), align: 'center', width: 72 },
    { name: t('Subscrib_Detail_BuyRequest_06'), align: 'left', width: 140 },
    { name: t('Subscrib_Detail_UseRequest_06'), align: 'left', width: 140 },
    { name: t('Subscrib_Detail_UseRequest_07'), align: 'left', width: 240 },
    { name: t('Subscrib_Main_12'), align: 'left', width: 318 },
    { name: t('Subscrib_Detail_BuyRequest_02'), align: 'left', width: 260 },
    { name: t('Workflow_Main_07'), align: 'left', width: 110 },
  ];
  const theme = useTheme();
  const [filter, setFilter] = useState<SubscriptionRequestFilterDTO>({
    productName: '',
    sort: ['createdDate.desc'],
  });
  const { data: subscriptionRequestsData } = useGetSubscriptionRequests(filter);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, productName: e.target.value }));
  };

  return (
    <Container>
      <div className="header">
        <span className="title">{t('Subscrib_GNB_06')}</span>
      </div>
      <div className="body">
        <TableToolbar>
          <strong>
            {t('Acc_Main_12')}
            <span className="count">{subscriptionRequestsData?.totalElements}</span>
          </strong>
          <StyledTextField
            size="small"
            value={filter.productName}
            placeholder={t('Sub_BuyApply_History_List_Search')}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconWrapper>
                  <SearchIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                </IconWrapper>
              ),
            }}
          />
        </TableToolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map(head => {
                  return (
                    <TableCell key={nanoid()} align={head.align} width={head.width} sx={{ cursor: 'pointer' }}>
                      {head.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: `${theme.colors['bg-white']}` }}>
              {!subscriptionRequestsData?.content.length && (
                <EmptyRow colSpan={tableHeader.length} title={t('Subscrib_Detail_BuyRequest_03')} />
              )}
              {subscriptionRequestsData?.content.map((rq, idx) => {
                return <BuyApplyHistoryRow data={rq} key={rq.id} idx={idx + 1} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height) - var(--employee-sub-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  padding: 60px 32px;
  background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};

  & .header {
    display: flex;
    align-itesm: center;
    flex-direction: column;
    & .title {
      ${fonts.Headline4}
    }
  }
  & .body {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
const TableToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  strong {
    margin-top: 2px;
    ${fonts.Headline7};
  }
  & .count {
    margin-left: 4px;
    color: ${props => props.theme.colors['text-purple-light']};
  }
  height: 32px;
`;
const StyledTextField = styled(TextField)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    width: 250px;
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;
const IconWrapper = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;
