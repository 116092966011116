const defaultWidth = 24;
const defaultHeight = 24;

export const NameIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.75C1 3.7835 1.7835 3 2.75 3H21.25C22.2165 3 23 3.7835 23 4.75V19.25C23 20.2165 22.2165 21 21.25 21H2.75C1.7835 21 1 20.2165 1 19.25V4.75ZM2.75 4.5C2.61193 4.5 2.5 4.61193 2.5 4.75V19.25C2.5 19.3881 2.61193 19.5 2.75 19.5H21.25C21.3881 19.5 21.5 19.3881 21.5 19.25V4.75C21.5 4.61193 21.3881 4.5 21.25 4.5H2.75ZM12 10.5C12 10.0858 12.3358 9.75 12.75 9.75H18.25C18.6642 9.75 19 10.0858 19 10.5C19 10.9142 18.6642 11.25 18.25 11.25H12.75C12.3358 11.25 12 10.9142 12 10.5ZM12 13.5C12 13.0858 12.3358 12.75 12.75 12.75H18.25C18.6642 12.75 19 13.0858 19 13.5C19 13.9142 18.6642 14.25 18.25 14.25H12.75C12.3358 14.25 12 13.9142 12 13.5ZM5.35345 10.5461C5.35345 9.55414 6.20258 8.75 7.25004 8.75C8.2975 8.75 9.14663 9.55414 9.14663 10.5461C9.14663 11.2257 8.74799 11.8172 8.16006 12.1223C9.48268 12.4867 10.4571 13.6255 10.4999 14.9859C10.5043 15.1275 10.3867 15.2458 10.2371 15.25H4.26289C4.11331 15.2458 3.99567 15.1275 4.00012 14.9859C4.04288 13.6254 5.01734 12.4867 6.33999 12.1223C5.75207 11.8172 5.35345 11.2257 5.35345 10.5461Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.899902 4.75039C0.899902 3.72866 1.72818 2.90039 2.7499 2.90039H21.2499C22.2716 2.90039 23.0999 3.72866 23.0999 4.75039V19.2504C23.0999 20.2721 22.2716 21.1004 21.2499 21.1004H2.7499C1.72818 21.1004 0.899902 20.2721 0.899902 19.2504V4.75039ZM2.7499 4.60039C2.66706 4.60039 2.5999 4.66755 2.5999 4.75039V19.2504C2.5999 19.3332 2.66706 19.4004 2.7499 19.4004H21.2499C21.3327 19.4004 21.3999 19.3332 21.3999 19.2504V4.75039C21.3999 4.66755 21.3327 4.60039 21.2499 4.60039H2.7499ZM11.8999 10.5004C11.8999 10.0309 12.2805 9.65039 12.7499 9.65039H18.2499C18.7193 9.65039 19.0999 10.0309 19.0999 10.5004C19.0999 10.9698 18.7193 11.3504 18.2499 11.3504H12.7499C12.2805 11.3504 11.8999 10.9698 11.8999 10.5004ZM11.8999 13.5004C11.8999 13.0309 12.2805 12.6504 12.7499 12.6504H18.2499C18.7193 12.6504 19.0999 13.0309 19.0999 13.5004C19.0999 13.9698 18.7193 14.3504 18.2499 14.3504H12.7499C12.2805 14.3504 11.8999 13.9698 11.8999 13.5004Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
