const defaultWidth = 24;
const defaultHeight = 24;

export const CloseIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23657 5.23657C5.55199 4.92114 6.06339 4.92114 6.37882 5.23657L12 10.8578L17.6212 5.23657C17.9366 4.92114 18.448 4.92114 18.7634 5.23657C19.0789 5.55199 19.0789 6.06339 18.7634 6.37882L13.1422 12L18.7634 17.6212C19.0789 17.9366 19.0789 18.448 18.7634 18.7634C18.448 19.0789 17.9366 19.0789 17.6212 18.7634L12 13.1422L6.37882 18.7634C6.06339 19.0789 5.55199 19.0789 5.23657 18.7634C4.92114 18.448 4.92114 17.9366 5.23657 17.6212L10.8578 12L5.23657 6.37882C4.92114 6.06339 4.92114 5.55199 5.23657 5.23657Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09495 5.09513C5.48848 4.70161 6.12651 4.70161 6.52004 5.09513L11.9998 10.5749L17.4796 5.09513C17.8731 4.70161 18.5111 4.70161 18.9047 5.09513C19.2982 5.48866 19.2982 6.1267 18.9047 6.52023L13.4249 12L18.9047 17.4798C19.2982 17.8733 19.2982 18.5113 18.9047 18.9048C18.5111 19.2984 17.8731 19.2984 17.4796 18.9048L11.9998 13.4251L6.52004 18.9048C6.12651 19.2984 5.48848 19.2984 5.09495 18.9048C4.70142 18.5113 4.70142 17.8733 5.09495 17.4797L10.5747 12L5.09495 6.52023C4.70142 6.1267 4.70142 5.48866 5.09495 5.09513Z"
        fill={color}
      />
    </g>
  </svg>
);

CloseIcon.displayName = 'CloseIcon';
