import type { SelectChangeEvent } from '@mui/material';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Divider,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { Tooltip, Button, SkeletonRow, TableHeaderCell, EmptyRow, SearchTextField } from '@components/index';
import { ModalBody } from '@pages/customer/manager/userGroup/licenseRetrieve/ModalBody';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import type { SubscriptionUserFilter, SubscriptionUserUpdateRq } from '@repositories/subscriptionRepository';
import { useSubscriptionModel, useSearchParamsFilter, useIntersectionObserver } from '@customHooks/index';
import {
  useDeleteSubscriptionUser,
  useGetSubscriptionUsersInfinite,
  useUpdateSubscriptionUser,
} from '@queryHooks/useSubscription';
import { useGetSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import { getSortOrder } from '@utils/CommonUtil';
import type { SubscriptionUserModel } from '@models/SubscriptionUserModel';
import { AddUserDialog } from './AddUserDialog';
import AddUserFromSchemaDialog from './AddUserFromSchemaDialog';
import { LastLoginUserBarChart } from './LastLoginUserBarChart';
import { LinkUserDialog } from './LinkUserDialog';
import { UserInfoDialog } from './UserInfoDialog';
import { UserStatusChart } from './UserStatusChart';
import { UserTableRow } from './UserTableRow';

export const User = () => {
  const { t } = useTranslation();
  const {
    uiStore: { alertStore, toastStore },
  } = useStore();
  const subscription = useSubscriptionModel();

  const dayjsNow = dayjs();

  const { colors } = useTheme();
  const [menuState, setMenuState] = useState<{ anchorEl?: HTMLButtonElement; target?: SubscriptionUserModel }>({
    anchorEl: undefined,
    target: undefined,
  });
  const [isLinkUserDialogOpen, setIsLinkUserDialogOpen] = useState(false);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [selectTableIconIndex, setSelectTableIconIndex] = useState<number | null>(null);
  const [userInfoDialogState, setUserInfoDialogState] = useState<{
    isOpen: boolean;
    user: SubscriptionUserModel | null;
  }>({ isOpen: false, user: null });

  const [filter, setFilter] = useSearchParamsFilter<SubscriptionUserFilter>({
    defaultFilter: { size: 100, sort: undefined, keyword: undefined },
    whiteList: [
      'sort',
      'keyword',
      'memberStatus',
      'userStatus',
      'userRole',
      'lastLoginTimeFrom',
      'lastLoginTimeTo',
      'billableStatus',
      'userGroupId',
      'user',
    ],
  });

  const handleUpdateFilter = {
    updateKeyword: (keyword: string) => {
      setFilter(prev => ({ ...prev, keyword }));
    },
    updateSort: (orderBy: string, order: 'asc' | 'desc' | undefined) => {
      setFilter(prev => ({ ...prev, sort: order ? [`${orderBy}.${order}`] : undefined }));
    },
    updateMemberStatusFilter: (filterKey: string, filterValues: string[]) => {
      setFilter(prev => ({ ...prev, [filterKey]: filterValues }));
    },
    updateBillableStatusFilter: (filterKey: string, filterValues: string[]) => {
      if (filterValues.length === 1) {
        setFilter(prev => ({ ...prev, [filterKey]: filterValues[0] }));
      } else {
        setFilter(prev => ({ ...prev, [filterKey]: undefined }));
      }
    },
    updateUserStatusFilter: (filterKey: string, filterValues: string[]) => {
      if (filterValues.length === 1) {
        setFilter(prev => ({ ...prev, [filterKey]: filterValues[0] }));
      } else {
        setFilter(prev => ({ ...prev, [filterKey]: undefined }));
      }
    },
    updateLastLoginTime: (lastLoginTimeFrom?: DateTimeString, lastLoginTimeTo?: DateTimeString) => {
      setFilter(prev => ({ ...prev, lastLoginTimeFrom, lastLoginTimeTo }));
    },
    updateLastLoginTimeFilter: (filterKey: string, from: dayjs.Dayjs | null, to: dayjs.Dayjs | null) => {
      setFilter(prev => ({
        ...prev,
        lastLoginTimeFrom: from?.format('YYYY-MM-DDTHH:mm:ss'),
        lastLoginTimeTo: to?.add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
      }));
    },
  };

  const {
    isLoading: isUserListLoading,
    isError: isUserListError,
    data: userList,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSubscriptionUsersInfinite(subscription.id, { ...filter });

  const { mutate: deleteUserFromSubscription } = useDeleteSubscriptionUser();
  const { data: subscriptionConnection /* , isLoading: isSubscriptionConnectionLoading */ } =
    useGetSubscriptionConnection(subscription.id);
  const { mutate: updateSubscriptionUser } = useUpdateSubscriptionUser();

  // FIXME: 사용자 추가 버튼 비활성화 조건 임시 해제
  const addUserButtonOption = {
    // visible:
    //   !isSubscriptionConnectionLoading && (!subscriptionConnection || !!subscriptionConnection.isManuallyUserAddable),
    // disabled: !subscriptionConnection,
    visible: true,
    disabled: false,
  };
  const removeUserButtonOption = {
    // visible:
    //   !isSubscriptionConnectionLoading && (!subscriptionConnection || !!subscriptionConnection.isManuallyUserRemovable),
    // disabled: !subscriptionConnection,
    visible: true,
    disabled: false,
  };

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleMenu = {
    open: (idx: number) => (event: React.MouseEvent<HTMLButtonElement>, model: SubscriptionUserModel) => {
      setMenuState({ anchorEl: event.currentTarget, target: model });
      setSelectTableIconIndex(idx);
    },
    close: () => {
      setMenuState(prev => ({ ...prev, anchorEl: undefined }));
      setSelectTableIconIndex(null);
    },
    linkUser: () => {
      handleMenu.close();
      setIsLinkUserDialogOpen(true);
    },
    unlinkUser: () => {
      handleMenu.close();
      alertStore.open({
        message: t('Subscrib_Detail_User_32'),
        title: t('Subscrib_Detail_User_31'),
        useCancel: true,
        confirmName: t('Member_User_24'),
        onConfirm: () => {
          if (menuState.target) {
            const rqData: SubscriptionUserUpdateRq = menuState.target.getSubscriptionUserUpdateRq({
              userId: null,
            });

            updateSubscriptionUser({ subscriptionId: menuState.target.subscriptionId, rqData });
          }
        },
      });
    },
    deleteUser: () => {
      handleMenu.close();
      if (subscriptionConnection?.isManuallyUserRevokeLicense) {
        alertStore.open({
          type: 'error',
          title: '사용자 삭제',
          message: <ModalBody type="auto" />,
          confirmName: '회수',
          useCancel: true,
          onConfirm: () => {
            if (menuState.target) {
              deleteUserFromSubscription(
                {
                  subscriptionId: subscription.id,
                  userLoginIdOrUserId: menuState.target.userLoginId,
                },
                {
                  onSuccess: () => {
                    toastStore.open(t('Subscrib_Detail_User_34'));
                  },
                },
              );
            }
          },
        });
      } else {
        alertStore.open({
          type: 'error',
          title: '사용자 삭제',
          message: <ModalBody type="manual" />,
          useCancel: true,
          confirmName: '회수 완료',
          onConfirm: () => {
            if (menuState.target) {
              deleteUserFromSubscription(
                {
                  subscriptionId: subscription.id,
                  userLoginIdOrUserId: menuState.target.userLoginId,
                },
                {
                  onSuccess: () => {
                    toastStore.open(t('Subscrib_Detail_User_34'));
                  },
                },
              );
            }
          },
        });
      }
    },
  };

  const handleOpenAddUser = () => {
    setIsAddUserDialogOpen(true);
  };
  const handleCloseAddUser = () => {
    setIsAddUserDialogOpen(false);
  };

  const handleCloseLinkUserDialog = () => {
    setIsLinkUserDialogOpen(false);
  };

  const handleUserRoleChange = (e: SelectChangeEvent<string>, user: SubscriptionUserModel) => {
    const rqData: SubscriptionUserUpdateRq = user.getSubscriptionUserUpdateRq({
      userRole: e.target.value,
    });
    updateSubscriptionUser({ subscriptionId: user.subscriptionId, rqData });
  };

  const handleRowClick = (user: SubscriptionUserModel) => {
    setUserInfoDialogState({ isOpen: true, user });
  };

  const handleUserInfoDialogClose = () => {
    setUserInfoDialogState({ isOpen: false, user: null });
  };

  return (
    <>
      <>
        <UserStatusChart />
        <UserLastActiveWrapper>
          <div className="title">{t('Subscrib_Detail_User_01')}</div>
          <LastLoginUserBarChart onChartClick={handleUpdateFilter.updateLastLoginTime} />
        </UserLastActiveWrapper>
        <Divider sx={{ borderTop: `1px solid ${colors['border-gray-lighter']}`, borderBottom: 'none' }} />
        <UserListWrapper>
          <UserListHeader>
            <UserNum>
              {t('Acc_Main_12')} <strong>{userList ? userList.pages[0].totalElements : ''}</strong>
            </UserNum>
            <HeaderRight>
              <UserSearch
                placeholder={t('Subscrib_Detail_User_19')}
                value={filter.keyword}
                onChange={handleUpdateFilter.updateKeyword}
                onSearch={handleUpdateFilter.updateKeyword}
              />
              {addUserButtonOption.visible && (
                <Tooltip
                  placement="bottom-left"
                  label={
                    <>
                      구독 사용자를 자동으로 불러오고 있습니다.
                      <br />
                      해당 서비스에서 사용자가 추가되면 자동 반영됩니다.
                    </>
                  }
                  disableMouseEnterListener={!addUserButtonOption.disabled}
                >
                  {/* Button이 disabled된 경우 마우스 이벤트를 처리하지 않아서 Tooltip이 표시되지 않음 -> 임의의 태그로 감싸서 Tooltip 동작하도록 처리 */}
                  <div>
                    <Button
                      variant="contain"
                      size="small"
                      paddingHorizontal={16}
                      onClick={handleOpenAddUser}
                      disabled={addUserButtonOption.disabled}
                    >
                      {t('Subscrib_Detail_User_18')}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </HeaderRight>
          </UserListHeader>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell
                    width="130px"
                    orderBy="tenantMember"
                    order={getSortOrder(filter.sort, 'tenantMember')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                    filterType="check"
                    filterKey="memberStatus"
                    itemList={[
                      { filterValue: 'ACTIVE', displayName: t('Subscription.memberStatus.ACTIVE') },
                      { filterValue: 'BLOCKED', displayName: t('Subscription.memberStatus.BLOCKED') },
                      { filterValue: 'NON_MEMBER', displayName: t('Subscription.memberStatus.NON_MEMBER') },
                    ]}
                    onFilterChange={handleUpdateFilter.updateMemberStatusFilter}
                  >
                    {t('Subscrib_Detail_User_10')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="200px"
                    orderBy="userLoginId"
                    order={getSortOrder(filter.sort, 'userLoginId')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                  >
                    {t('Subscrib_Detail_User_11')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="140px"
                    orderBy="userName"
                    order={getSortOrder(filter.sort, 'userName')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                  >
                    {t('Workflow_Main_05')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="328px"
                    orderBy="userEmail"
                    order={getSortOrder(filter.sort, 'userEmail')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                  >
                    {t('Subscrib_Detail_User_13')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="200px"
                    orderBy="userOrganization"
                    order={getSortOrder(filter.sort, 'userOrganization')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                  >
                    {t('Member_User_Bulk_Create_18')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="130px"
                    orderBy="userRole"
                    order={getSortOrder(filter.sort, 'userRole')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                  >
                    {t('Member_User_17')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="160px"
                    orderBy="lastLoginTime"
                    order={getSortOrder(filter.sort, 'lastLoginTime')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                    filterType="date"
                    filterKey="lastLoginTime"
                    itemList={[
                      {
                        displayName: t('Subscrib_Detail_User_Filter_01'),
                        startDate: dayjsNow.add(-1, 'month'),
                        endDate: dayjsNow,
                        disableStartDate: true,
                      },
                      {
                        displayName: t('Subscrib_Detail_User_Filter_02'),
                        startDate: dayjsNow.add(-2, 'month'),
                        endDate: dayjsNow,
                        disableStartDate: true,
                      },
                      {
                        displayName: t('Subscrib_Detail_User_Filter_03'),
                        startDate: dayjsNow.add(-3, 'month'),
                        endDate: dayjsNow,
                        disableStartDate: true,
                      },
                    ]}
                    onFilterChange={handleUpdateFilter.updateLastLoginTimeFilter}
                  >
                    {t('Subscrib_Detail_User_14')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="130px"
                    orderBy="billableStatus"
                    order={getSortOrder(filter.sort, 'billableStatus')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                    filterType="check"
                    filterKey="billableStatus"
                    itemList={[
                      { filterValue: 'BILLABLE', displayName: 'Y' },
                      { filterValue: 'NON_BILLABLE', displayName: 'N' },
                    ]}
                    onFilterChange={handleUpdateFilter.updateBillableStatusFilter}
                  >
                    {t('Subscrib_Detail_User_35')}
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="110px"
                    orderBy="userStatus"
                    order={getSortOrder(filter.sort, 'userStatus')}
                    onSortChange={handleUpdateFilter.updateSort}
                    disableSort={userList?.pages[0].totalElements === 0}
                    filterType="check"
                    filterKey="userStatus"
                    itemList={[
                      { filterValue: 'ACTIVE', displayName: t('Subscrib_Detail_User_16') },
                      { filterValue: 'INACTIVE', displayName: t('Subscrib_Detail_User_17') },
                    ]}
                    onFilterChange={handleUpdateFilter.updateUserStatusFilter}
                  >
                    {t('Workflow_Main_07')}
                  </TableHeaderCell>
                  <TableCell width="72px" align="center">
                    {t('Acc_Main_23')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isUserListLoading || isUserListError ? (
                  <>
                    <SkeletonRow colNum={9} />
                    <SkeletonRow colNum={9} />
                    <SkeletonRow colNum={9} />
                  </>
                ) : userList.pages[0].totalElements === 0 ? (
                  <EmptyRow colSpan={9} title={t('Subscrib_Detail_User_29')} />
                ) : (
                  userList.pages.map(page =>
                    page.content.map((user, index) => (
                      <UserTableRow
                        key={user.userLoginId}
                        user={user}
                        appliedFilter={filter}
                        isMenuOpen={selectTableIconIndex === index}
                        onRowClick={handleRowClick}
                        onUserRoleChange={handleUserRoleChange}
                        onMenuOpen={handleMenu.open(index)}
                      />
                    )),
                  )
                )}
              </TableBody>
            </Table>
            {hasNextPage && <div ref={ref} />}
          </TableContainer>
        </UserListWrapper>
      </>

      {isLinkUserDialogOpen && menuState.target && (
        <LinkUserDialog open={isLinkUserDialogOpen} onClose={handleCloseLinkUserDialog} targetUser={menuState.target} />
      )}
      {isAddUserDialogOpen &&
        (subscriptionConnection?.connection.connector.addUserSchema ? (
          <AddUserFromSchemaDialog
            subscriptionId={subscription.id}
            open={isAddUserDialogOpen}
            onClose={handleCloseAddUser}
          />
        ) : (
          <AddUserDialog subscriptionId={subscription.id} open={isAddUserDialogOpen} onClose={handleCloseAddUser} />
        ))}
      <Menu
        anchorEl={menuState.anchorEl}
        open={!!menuState.anchorEl}
        onClose={handleMenu.close}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            marginTop: '5px',
            '& .MuiMenu-list': {
              padding: '4px 5px',
            },
            '& .MuiMenuItem-gutters': {
              padding: '6px 7px',
            },
            '& .MuiList-root': {
              width: '100%',
              minWidth: '124px',
            },
          },
        }}
      >
        <MenuItem disabled={menuState.target?.isMember} onClick={handleMenu.linkUser}>
          {t('Subscrib_Detail_User_21')}
        </MenuItem>
        <MenuItem disabled={!menuState.target?.isMember} onClick={handleMenu.unlinkUser}>
          {t('Subscrib_Detail_User_31')}
        </MenuItem>
        {removeUserButtonOption.visible && (
          <DeleteMenuItem disabled={removeUserButtonOption.disabled} onClick={handleMenu.deleteUser}>
            {t('Subscrib_Detail_User_30')}
          </DeleteMenuItem>
        )}
      </Menu>

      {userInfoDialogState.user && (
        <UserInfoDialog
          open={userInfoDialogState.isOpen}
          onClose={handleUserInfoDialogClose}
          user={userInfoDialogState.user}
        />
      )}
    </>
  );
};

const UserLastActiveWrapper = styled.div`
  padding: 32px 24px 40px;

  & > .title {
    ${fonts.Headline7}
    margin-bottom: 24px;
  }
`;

const UserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 0 43px;
  gap: 12px;
`;

const UserListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
`;

const UserNum = styled.div`
  ${fonts.Headline8};

  & > strong {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserSearch = styled(SearchTextField)`
  width: 250px;
`;

const DeleteMenuItem = styled(MenuItem)`
  color: ${({ theme: { colors } }) => colors['text-red-light']};
`;
