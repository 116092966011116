import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { useGetTenantBilling } from '@queryHooks/useBilling';
import { useGetTenant } from '@queryHooks/useTenant';
import { dateFormat } from '@utils/dateUtil';
import { currencyNumberFormat, numberFormat } from '@utils/numberFormat';
import { TenantMonthlyBillingModel } from '@models/TenantMonthlyBillingModel';
import { usePrintButton } from '../detail/PrintButton';
import { TenantBillingPrintView } from '../detail/TenantBillingDetail/TenantBillingPrintView';

type Props = {
  rowData: TenantMonthlyBillingModel;
};

export const TenantBillingRow = forwardRef(({ rowData }: Props, ref: React.ForwardedRef<HTMLTableRowElement>) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const printViewRef = useRef<HTMLDivElement>(null);

  const { printState, setPrintState, onBeforeGetContentResolve, PrintButton } = usePrintButton();

  const { data: tenant } = useGetTenant(tenantId ?? '');
  const { data: tenantBilling, isSuccess: isTenantBillingSuccess } = useGetTenantBilling(
    {
      id: tenantId ?? '',
      month: rowData.month,
    },
    {
      enabled: printState.isOpen,
    },
  );

  const handleBillingClick = () => {
    navigate(`tenant/${rowData.month}`);
  };

  const handleThreeDotClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };

  const handlePrintStateChange = (state: 'loading' | 'fail' | 'ready') => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      switch (state) {
        case 'loading':
          setPrintState(prev => ({ ...prev, isDataLoading: true }));
          break;
        case 'ready':
          onBeforeGetContentResolve.current();
          setAnchorEl(undefined);
          break;
        case 'fail':
          setPrintState({ isOpen: false, isDataLoading: true });
          break;
      }
    }
  };

  return (
    <>
      <BillingRow ref={ref} onClick={handleBillingClick}>
        <TableCell>{dateFormat(rowData.month, '-', t('DateFormat_YM'))}</TableCell>
        <TableCell>{t('Acc_Main_26')}</TableCell>
        <TableCell>
          <Label variant="solid" color="purple">
            {t('Acc_Main_24')}
          </Label>
        </TableCell>
        <TableCell>
          {dateFormat(rowData.startDate, '-', t('DateFormat_YMD'))} ~{' '}
          {dateFormat(rowData.endDate, '-', t('DateFormat_YMD'))}
        </TableCell>
        <TableCell>{numberFormat({ num: rowData.cost, style: 'currency', currency: rowData.currencyUnit })}</TableCell>
        <TableCell>
          {tenant?.currencyUnit ? currencyNumberFormat(rowData.cost, rowData.currencyUnit, 'text') : '-'}
        </TableCell>
        <TableCell>{dateFormat(rowData?.paymentDate, '-', t('DateFormat_YMD'))}</TableCell>
        <TableCell>
          <Label variant="solid" color={rowData.isForecasted ? 'blue' : rowData.isPayment ? 'gray' : 'yellow'}>
            {/* FIXME: i18n */}
            {rowData.isForecasted ? '정산 예측' : rowData.isPayment ? t('Acc_Detail_06') : t('Acc_Detail_07')}
          </Label>
        </TableCell>
        <TableCell>-</TableCell>
        <TableCell align="center">
          <ThreeDotButton onClick={handleThreeDotClick}>
            <ThreeDotIcon width={18} height={18} color={colors['ic-gray-dark']} />
          </ThreeDotButton>
        </TableCell>
      </BillingRow>

      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { width: '160px' } }}
      >
        <StyledMenuItem disabled={printState.isOpen}>
          <PrintButton
            contentRef={printViewRef}
            // eslint-disable-next-line react/no-unstable-nested-components
            trigger={() => <span>{t('Acc_Detail_16')}</span>}
            documentTitle={`${tenant?.name ?? ''}_${dateFormat(rowData.month, '', t('DateFormat_YM'))}`}
          />
        </StyledMenuItem>
      </StyledMenu>

      {isTenantBillingSuccess && printState.isOpen && (
        <TenantBillingPrintView
          ref={printViewRef}
          tenantName={tenant?.name ?? ''}
          tenantBilling={tenantBilling}
          onPrintStateChange={handlePrintStateChange}
        />
      )}
    </>
  );
});
TenantBillingRow.displayName = 'TenantBillingRow';

const BillingRow = styled(TableRow)`
  cursor: pointer;
`;

const ThreeDotButton = styled(Button)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: none;
    border-radius: 0;
  }
  & .MuiList-root {
    border-radius: 2px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;
