import styled from '@emotion/styled';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export const PopoverContainer = ({ className, children }: ContainerProps) => {
  return <PopoverContainerWrapper className={className}>{children}</PopoverContainerWrapper>;
};

const PopoverContainerWrapper = styled.div`
  width: 100%;
  padding: 4px 5px;
  background-color: ${({ theme }) => theme.colors['bg-white']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
`;
