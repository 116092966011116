import styled from '@emotion/styled';
import { Avatar, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconButton } from '@components/buttons';
import { Button as CustomButton } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const NoProdcutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Categories = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

export const Products = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Logo = styled(Avatar)`
  width: 100%;
  max-width: 172px;
  margin: 0 auto;
  height: 54px;
  padding: 27px 0;
  box-sizing: content-box;
  border-radius: 0;
  & > img {
    object-fit: contain;
  }
  &.hardware {
    height: 100px;
    padding: 16px 0 6px;
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    min-width: 108px;
    max-width: fit-content;
    height: 32px;
    padding: 18px 10px;
    &.hardware {
      height: 58px;
      padding: 18px 0 6px;
    }
  } ;
`;

export const Product = styled(Box, transientOptions)<{ $categoryColor: string }>`
  display: flex;
  flex-direction: column;
  width: 232px;
  height: 283px;
  border: 0.5px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  position: relative;

  & .logo {
    display: flex;
    flex-direction: row;
    height: 102px;
    align-items: center;
    padding: 0px 30px;
    justify-content: center;
  }

  & .info {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 28px;

    & .category-name {
      ${fonts.Caption1};
      color: ${({ $categoryColor }) => $categoryColor};
    }

    & .product-name {
      ${fonts.Headline5};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
      margin-bottom: 12px;
    }

    & .info-title {
      ${fonts.Body6};
      color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    }

    & .plan-name {
      ${fonts.Body4};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
      margin-bottom: 10px;
    }

    & .duration {
      ${fonts.Body4};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
    }
  }
`;

export const NoProduct = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 380px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  align-items: center;
  padding: 90px 0px 106px 0px;

  & .main-text {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-bottom: 4px;
  }

  & .sub-text {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-bottom: 40px;
  }
`;

export const Exclaim = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Button = styled(CustomButton)`
  height: 30px;
  border-radius: 16px;
  padding: 0 16px;
  position: relative;
  &.contain {
    ${fonts.Button4};
    border: 1px solid ${({ theme }) => theme.colors['bg-purple']};
  }
`;

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  position: relative;
  button:not([disabled]).category-selected {
    background-color: ${({ theme }) => theme.colors['bg-purple']};
    border-color: ${({ theme }) => theme.colors['border-purple']};
    color: ${({ theme }) => theme.colors['text-white']};
  }
  button.arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    background: none;
    &:hover span {
      border-color: ${({ theme }) => theme.colors['state-white-purple-hover']};
      background-color: ${({ theme }) => theme.colors['state-white-purple-hover']};
      svg path {
        fill: ${({ theme }) => theme.colors['bg-purple']};
      }
    }
    span {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors['bg-white']};
      z-index: 100;
    }

    &.swiper-button-prev {
      left: 0;
      z-index: 100;
      &:before {
        content: '';
        display: block;
        width: 50px;
        height: 32px;
        position: absolute;
        left: -1px;
        top: 0;
        z-index: 1;
        background: linear-gradient(270deg, rgba(250, 251, 252, 0) 0%, #fafbfc 50%);
      }
      &.swiper-button-disabled {
        display: none;
        &:before {
          content: none;
        }
      }
    }
    &.swiper-button-next {
      right: 0;
      z-index: 100;
      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 32px;
        position: absolute;
        right: -1px;
        top: 0;
        z-index: 1;
        background: linear-gradient(90deg, rgba(250, 251, 252, 0) 0%, #fafbfc 50%);
      }
      &.swiper-button-disabled {
        display: none;
        &:after {
          content: none;
        }
      }
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  width: auto !important;
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  border: ${({ theme }) => `1px solid ${theme.colors['border-gray-lighter']}`};
  width: 26px;
  height: 26px;
  border-radius: 4px;
`;
