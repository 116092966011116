import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import CircleUpFillIcon from '@icons/CircleUpFillIcon';
import { RectangleFillIcon } from '@icons/RectangleFillIcon';
import { useLocalStorage } from '@customHooks/useLocalStorage';
import { useSubscriptionCountStat } from '@queryHooks/useSubscriptionCountStat';
import { WgBox, WgContainer, WgSummary, WgTitle } from '../styles';

export const SubscriptionWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const [customTZ] = useLocalStorage('customTimezone', 'Asia/Seoul');

  const { data: subscriptionCountStat } = useSubscriptionCountStat(dayjs.tz.guess() ?? customTZ);

  const handleClickSeeMore = () => {
    navigate(`/manager/${params.tenantId}/subscriptions/list`);
  };

  return (
    <WgContainer>
      <WgTitle>
        <span>{t('Workflow_Create_68')}</span>
        <Button className="see-more" onClick={handleClickSeeMore} variant="text">
          {t('Home_Add')}
          <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
        </Button>
      </WgTitle>
      <WgSummary>
        <span className="count">
          <span className="count-number">{subscriptionCountStat?.totalCount}</span>
          <span className="counting-unit">{t('Home_Subscription')}</span>
        </span>
        <span>
          <MonthVariation>
            {t('Acc_Main_08')}
            {subscriptionCountStat && subscriptionCountStat.monthlyIncrement >= 0 ? (
              <span className="up">
                <CircleUpFillIcon /> {subscriptionCountStat?.monthlyIncrement}
              </span>
            ) : (
              <span className="down">
                <CircleUpFillIcon color={theme.colors['ic-red-light']} rotateNum={180} />
                {subscriptionCountStat?.monthlyIncrement}
              </span>
            )}
          </MonthVariation>
        </span>
      </WgSummary>
      <WgBox className="padding">
        <CountDetail>
          <span className="status">
            <RectangleFillIcon width={16} height={16} color={theme.colors['ic-green']} />
            <span>{t('Subscrib_Main_02')}</span>
          </span>
          <span className="count-normal">{subscriptionCountStat?.subscriptionCount}</span>
        </CountDetail>
        <CountDetail>
          <span className="status">
            <RectangleFillIcon width={16} height={16} color={theme.colors['ic-yellow-dark']} />
            <span>{t('Subscrib_Main_03')}</span>
          </span>
          <span className="count-normal">{subscriptionCountStat?.monthlyStartingCount}</span>
        </CountDetail>
        <CountDetail>
          <span className="status">
            <RectangleFillIcon width={16} height={16} color={theme.colors['ic-red-light']} />
            <span>{t('Subscrib_Main_04')}</span>
          </span>
          <span className="count-warning">{subscriptionCountStat?.monthlyEndingCount}</span>
        </CountDetail>
        <CountDetail>
          <span className="status">
            <RectangleFillIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <span>{t('Subscrib_Main_05')}</span>
          </span>
          <span className="count-normal">{subscriptionCountStat?.endSubscriptionCount}</span>
        </CountDetail>
      </WgBox>
    </WgContainer>
  );
};

const MonthVariation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  margin-bottom: 3px;
  & .up {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${fonts.Headline7};
    color: ${props => props.theme.colors['text-green-light']};
    gap: 2px;
  }
  & .down {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${fonts.Headline7};
    color: ${props => props.theme.colors['text-red-light']};
    gap: 2px;
  }
`;

const CountDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${fonts.Body2};
  & .status {
    display: flex;
    align-items: center;
  }
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  & .count-normal {
    color: ${props => props.theme.colors['text-gray-main']};
  }
  & .count-warning {
    color: ${props => props.theme.colors['text-red-light']};
  }
`;
