import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { InputBaseComponentProps } from '@mui/material';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

/**
 * MUI TextField를 Currency만 입력받게 할 때 사용
 *
 * https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
 *
 * @example
 * <TextField InputProps={{ inputComponent: CurrencyInput }}/>
 */
export const CurrencyInput = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      valueIsNumericString
    />
  );
}) as unknown as React.ElementType<InputBaseComponentProps>;
