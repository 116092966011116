import styled from '@emotion/styled';
import { FormControlLabel, TextField, Typography } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';

export const Form = styled.form`
  max-width: 380px;
  min-height: calc(100vh - var(--employee-gnb-height));
  height: 100%;
  margin: 0 auto;
  padding: 110px 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    padding: 48px 0 0;
    min-height: calc(100vh - var(--mobile-employee-gnb-height));
  } ;
`;

export const Title = styled.h2`
  margin: 0px 0px 12px;
  ${fonts.Headline3};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  text-align: center;
`;

export const Description = styled(Typography)`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  text-align: center;
  margin-bottom: 60px;
  white-space: pre-line;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    margin-bottom: 40px;
  } ;
` as typeof Typography;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    padding: 0 24px;
  } ;
`;
export const StyledTextField = styled(TextField)`
  & .MuiInputBase-input::placeholder {
    ${fonts.Body1};
    color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    & .MuiInputBase-root {
      height: 48px;
    }
    & .MuiInputBase-input::placeholder {
      ${fonts.Body2};
    }
  } ;
`;

export const TextArea = styled(TextField)`
  & .MuiInputBase-root {
    padding: 0px;
    max-height: unset;
  }

  & .MuiInputBase-input {
    height: 120px;
    max-height: 120px;
    box-sizing: border-box;
    ${fonts.Body2};
  }
`;

export const InquiryWraper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 6px;
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 25px 0px 36px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    margin: 24px 0px 126px;
    padding: 0 24px;
  }
  & .MuiFormControlLabel-labelPlacementEnd {
    align-items: flex-start;
  }
`;

export const FormCheckLabel = styled(FormControlLabel)`
  margin: 0;
  & .MuiCheckbox-root {
    margin-top: 1px;
  }
  & > .MuiFormControlLabel-label {
    ${fonts.Body2};
    margin-left: 8px;
    & > a.MuiTypography-root {
      text-decoration: none;
      margin: 0 2px 0 6px;
    }
  }
`;

export const EssentialCheckboxWrap = styled('div')`
  display: flex;
  flex-direction: column;
  & .MuiFormControlLabel-root + p {
    margin-top: 4px;
  }
`;
export const Submit = styled(Button)`
  width: 100%;
  color: ${({ theme: { colors } }) => colors['text-white']};
  background: ${({ theme: { colors } }) => colors['bg-gradient']};

  &:hover {
    background: ${({ theme: { colors } }) => colors['state-gradient-hover']};
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: 100%;
    border-radius: 3px;
  } ;
`;

export const SubmitButtonWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 101;
    padding: 0 24px 24px;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    &::before {
      position: absolute;
      top: -16px;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 16px;
      background: ${({ theme }) => theme.colors['bg-w-gradient']};
    }
  } ;
`;
