import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { Label } from '@components/Label';
import { type SubscriptionModel } from '@models/SubscriptionModel';
import { AppLogin } from '../../workflow/connectionMgmt/detail/appLogin';

type Props = {
  subscription: SubscriptionModel;
};
export const ConnectionStatusLabel = ({ subscription }: Props) => {
  const navigate = useNavigate();
  const [appLoginModalState, setAppLoginModalState] = useState<{ isOpen: boolean; type: 'new' | 'modify' }>({
    isOpen: false,
    type: 'new',
  });

  const hasConnection = !!subscription.connection;

  const navigateToConectionDetail = () => {
    if (hasConnection) {
      navigate(`../connections/${subscription.connection.connectionId}/connection`);
    }
  };

  const handleFailedClick = () => {
    setAppLoginModalState({ isOpen: true, type: 'modify' });
  };

  const handleUnconnectedClick = () => {
    setAppLoginModalState({ isOpen: true, type: 'new' });
  };

  const handleAppLoginClose = () => {
    setAppLoginModalState({ isOpen: false, type: 'new' });
  };

  return (
    <>
      {hasConnection ? (
        <>
          {subscription.connection.status === 'CONNECTED' && (
            <Tooltip arrow placement="bottom-start" title="연결 상태입니다.">
              <Label variant="contain" color="green" role="button" onClick={navigateToConectionDetail}>
                연결됨
              </Label>
            </Tooltip>
          )}
          {subscription.connection.status === 'FAILED' && (
            <Tooltip arrow placement="bottom-start" title="오류가 발생했습니다. 연결 정보를 확인해주세요.">
              <Label variant="contain" color="red" role="button" onClick={handleFailedClick}>
                오류
              </Label>
            </Tooltip>
          )}
          {subscription.connection.status === 'DISCONNECTED' && (
            <Tooltip arrow placement="bottom-start" title="사용자가 연결을 정지했습니다.">
              <DisconnectedLabel variant="contain" role="button" color="gray" onClick={navigateToConectionDetail}>
                정지
              </DisconnectedLabel>
            </Tooltip>
          )}
          {subscription.connection.status === 'CONNECTING' && (
            <Tooltip arrow placement="bottom-start" title="연결 중입니다.">
              <Label variant="solid" role="button" color="gradient" onClick={navigateToConectionDetail}>
                연결 중
              </Label>
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip arrow placement="bottom-start" title="생성된 커넥션이 없습니다. 커넥션을 생성해주세요.">
          <UnconnectedLabel variant="contain" role="button" color="gray" onClick={handleUnconnectedClick}>
            없음
          </UnconnectedLabel>
        </Tooltip>
      )}
      <AppLogin
        subscription={subscription}
        open={appLoginModalState.isOpen}
        onClose={handleAppLoginClose}
        type={appLoginModalState.type}
      />
    </>
  );
};

const DisconnectedLabel = styled(Label)`
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-m']};
`;

const UnconnectedLabel = styled(Label)`
  color: ${({ theme: { colors } }) => colors['text-white']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-dark-50']};
`;
