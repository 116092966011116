const defaultWidth = 16;
const defaultHeight = 16;

export const SubscriptionIcon = ({ width = defaultWidth, height = defaultHeight, color = '#6A737D' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3334 2.40065H2.66671C2.51943 2.40065 2.40004 2.52004 2.40004 2.66732V9.33398C2.40004 9.48126 2.51943 9.60065 2.66671 9.60065H13.3334C13.4807 9.60065 13.6 9.48126 13.6 9.33398V2.66732C13.6 2.52004 13.4806 2.40065 13.3334 2.40065ZM2.66671 1.33398C1.93033 1.33398 1.33337 1.93094 1.33337 2.66732V9.33398C1.33337 10.0704 1.93033 10.6673 2.66671 10.6673H13.3334C14.0698 10.6673 14.6667 10.0704 14.6667 9.33398V2.66732C14.6667 1.93094 14.0698 1.33398 13.3334 1.33398H2.66671Z"
          fill={color}
        />
        <rect x="2.66663" y="11.666" width="10.6667" height="1.13333" rx="0.566667" fill={color} />
        <rect x="4" y="13.666" width="8" height="1.13333" rx="0.566667" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7088 4.11786C10.866 4.27501 10.866 4.52981 10.7088 4.68696L7.5372 7.8586C7.46064 7.93516 7.35645 7.97764 7.24819 7.97644C7.13992 7.97523 7.0367 7.93045 6.96186 7.85221L5.44499 6.26639C5.29137 6.10578 5.29703 5.85105 5.45763 5.69743C5.61823 5.54381 5.87296 5.54947 6.02658 5.71008L7.25905 6.99856L10.1398 4.11786C10.2969 3.96071 10.5517 3.96071 10.7088 4.11786Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.96179 7.85299L6.81726 7.99123L5.30038 6.40541C5.07041 6.16499 5.07889 5.78366 5.31931 5.55368C5.55974 5.32371 5.94107 5.33219 6.17104 5.57261L7.26215 6.71332L9.99826 3.97722L10.1397 4.11864L7.25898 6.99935L6.02651 5.71086C5.87289 5.55025 5.61816 5.54459 5.45756 5.69821C5.29695 5.85183 5.29129 6.10656 5.44491 6.26717L6.96179 7.85299ZM10.7088 4.68774L7.53713 7.85938C7.46057 7.93594 7.35638 7.97842 7.24811 7.97722C7.13984 7.97601 7.03663 7.93123 6.96179 7.85299L6.81726 7.99123C6.9293 8.10836 7.08381 8.17541 7.24589 8.1772C7.40797 8.17901 7.56394 8.11542 7.67855 8.0008L10.8502 4.82916L10.7088 4.68774ZM10.7088 4.68774L10.8502 4.82916C11.0854 4.5939 11.0854 4.21248 10.8502 3.97722C10.6149 3.74197 10.2335 3.74197 9.99826 3.97722L10.1397 4.11864C10.2968 3.96149 10.5516 3.96149 10.7088 4.11864C10.8659 4.2758 10.8659 4.53059 10.7088 4.68774Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SubscriptionIcon.displayName = 'SubscriptionIcon';
