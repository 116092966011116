import { TenantMemberTextStatus, TotalSubscriptionUserListDTO } from '@repositories/subscriptionUserRepository/Types';

export class TotalSubscriptionUserListModel {
  private readonly _dto: TotalSubscriptionUserListDTO;

  constructor(dto: TotalSubscriptionUserListDTO) {
    this._dto = dto;
  }

  get isAbnormal(): string {
    return this._dto.isAbnormal === true ? 'Y' : 'N';
  }

  get softwareIconUrl(): string | undefined {
    return this._dto?.softwareIconUrl;
  }

  get softwareId(): string {
    return this._dto.softwareId ?? '';
  }

  get softwareName(): string {
    return this._dto.softwareName ?? '';
  }

  get subscriptionId(): string {
    return this._dto.subscriptionId ?? '';
  }

  get subscriptionName(): string {
    return this._dto.subscriptionName ?? '';
  }

  get tenantMemberStatus(): string {
    return this._dto.tenantMemberStatus
      ? this._dto.tenantMemberStatus === 'ACTIVE'
        ? '정상 구성원'
        : '탈퇴 구성원'
      : '비구성원';
  }

  get userEmail(): string {
    return this._dto.userEmail ?? '';
  }

  get userLoginId(): string {
    return this._dto.userLoginId ?? '';
  }

  get userName(): string {
    return this._dto.userName ?? '';
  }

  static getTenantMemberStatus(status: TenantMemberTextStatus): string {
    const statusTextMap: Record<TenantMemberTextStatus, string> = {
      '정상 구성원': 'ACTIVE',
      '탈퇴 구성원': 'BLOCKED',
      비구성원: 'NON_MEMBER',
    };
    return statusTextMap[status] ?? '';
  }

  static getTenantMemberTextStatus(status: string): string {
    const statusTextMap: Record<string, string> = {
      ACTIVE: '정상 구성원',
      BLOCKED: '탈퇴 구성원',
      NON_MEMBER: '비구성원',
    };
    return statusTextMap[status] ?? '';
  }
}
