import { useState, useCallback, useEffect } from 'react';
import { Divider, Box, Button } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import softwareStore from '@stores/SoftwareStore';
import { RefreshMono } from '@images/SvgComponents';
import type { SoftwareCategory } from '@repositories/softwareCategoryRepository';
import type { Software } from '@repositories/softwareRepository';
import { TenantList } from '@repositories/tenantRepository';
import { useGetTenantList } from '@queryHooks/useTenant';
import { SearchFilter } from '@type/Subscription';
import * as S from './FilterStyles';

export const Filter = observer(() => {
  const {
    uiStore: {
      isSubscriptionSearchModalVisible,
      closeSubscriptionSearchModal,
      selectedSubscriptionSearchFilter,
      setSelectedSubscriptionSearchFilter,
    },
    categoryStore,
  } = useStore();

  const { data: pagedTenants } = useGetTenantList({ size: 1000 }, { enabled: isSubscriptionSearchModalVisible });

  const softwareFilterOptions = softwareStore.softwares.map(sw => toJS(sw));
  const tenantFilterOptions = pagedTenants?.content.map(tenant => toJS(tenant)) ?? [];
  const softwareCategoryFilterOptions = categoryStore.categories;
  const [selectedFilter, setSelectedFilter] = useState<SearchFilter>({ ...selectedSubscriptionSearchFilter });

  const handleChangeApplication = useCallback(
    (software: Software) => {
      setSelectedFilter({ ...selectedFilter, software });
    },
    [selectedFilter],
  );
  const handleChangeTenant = useCallback(
    (tenant: TenantList) => {
      setSelectedFilter({ ...selectedFilter, tenant });
    },
    [selectedFilter],
  );
  const handleChangeCategory = useCallback(
    (category: SoftwareCategory) => {
      setSelectedFilter({ ...selectedFilter, category });
    },
    [selectedFilter],
  );
  const handleChangeStatus = useCallback(
    (status: string) => {
      setSelectedFilter({ ...selectedFilter, status });
    },
    [selectedFilter],
  );
  const handleChangeType = useCallback(
    (type: string) => {
      setSelectedFilter({ ...selectedFilter, type });
    },
    [selectedFilter],
  );

  const handleChangeHasRequestedUser = useCallback(
    (hasRequestedUser?: boolean) => {
      setSelectedFilter({ ...selectedFilter, hasRequestedUser });
    },
    [selectedFilter],
  );

  const handleClickClear = useCallback(() => {
    setSelectedSubscriptionSearchFilter({});
    setSelectedFilter({});
    closeSubscriptionSearchModal();
  }, [closeSubscriptionSearchModal, setSelectedSubscriptionSearchFilter]);

  const handleClickSearch = useCallback(() => {
    setSelectedSubscriptionSearchFilter({ ...selectedFilter });
    closeSubscriptionSearchModal();
  }, [closeSubscriptionSearchModal, selectedFilter, setSelectedSubscriptionSearchFilter]);

  useEffect(() => {
    setSelectedFilter({ ...selectedSubscriptionSearchFilter });
  }, [selectedSubscriptionSearchFilter]);

  return (
    <S.FilterDialog open={isSubscriptionSearchModalVisible} onClose={closeSubscriptionSearchModal}>
      <S.FilterDialogTitle> Filter </S.FilterDialogTitle>
      <Divider />
      <S.FilterDialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '440px' }}>
          <S.FieldName>Application Name</S.FieldName>
          <S.AutoSelect
            value={selectedFilter.software ?? null}
            placeholder="All application"
            options={softwareFilterOptions}
            getOptionLabel={option => option.name}
            onChange={handleChangeApplication}
          />
          <S.FieldName>Tenant Code</S.FieldName>
          <S.AutoSelect
            placeholder="All tenant code"
            value={selectedFilter.tenant ?? null}
            options={tenantFilterOptions}
            getOptionLabel={option => `${option.name} (${option.tenantCode})`}
            onChange={handleChangeTenant}
          />
          <S.FieldName>App Category</S.FieldName>
          <S.AutoSelect
            placeholder="All app category"
            value={selectedFilter.category ?? null}
            options={softwareCategoryFilterOptions}
            getOptionLabel={option => option.name}
            onChange={handleChangeCategory}
          />
          <S.FieldName>Subscription Status</S.FieldName>
          <S.AutoSelect
            placeholder="All subscription status"
            value={selectedFilter.status ?? null}
            options={['CREATED', 'REQUESTED', 'ACTIVE', 'CLOSED', 'REJECTED', 'OPEN']}
            onChange={handleChangeStatus}
          />
          <S.FieldName>Subscription Type</S.FieldName>
          <S.AutoSelect
            placeholder="All subscription type"
            value={selectedFilter.type ?? null}
            options={['FIXED', 'FIXED_2', 'ON_DEMAND']}
            onChange={handleChangeType}
          />
          <S.FieldName>Has Request for use</S.FieldName>
          <S.AutoSelect
            placeholder="No filter option"
            value={selectedFilter.hasRequestedUser ?? null}
            options={[true, false]}
            getOptionLabel={option => `${option}`.toLocaleUpperCase()}
            onChange={handleChangeHasRequestedUser}
          />
        </Box>
      </S.FilterDialogContent>
      <S.FilterDialogActions>
        <S.ClearButton startIcon={<RefreshMono />} onClick={handleClickClear}>
          Clear all
        </S.ClearButton>
        <Button variant="contained" onClick={handleClickSearch}>
          Search
        </Button>
      </S.FilterDialogActions>
    </S.FilterDialog>
  );
});
