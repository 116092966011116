// import { useTheme } from '@emotion/react';

const defaultWidth = 24;
const defaultHeight = 24;

export const ErrorIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C11.282 3 10.7 3.58203 10.7 4.3V14.7C10.7 15.418 11.282 16 12 16C12.7179 16 13.3 15.418 13.3 14.7V4.3C13.3 3.58203 12.7179 3 12 3ZM12 21C12.8284 21 13.5 20.3284 13.5 19.5C13.5 18.6716 12.8284 18 12 18C11.1716 18 10.5 18.6716 10.5 19.5C10.5 20.3284 11.1716 21 12 21Z"
        fill={color}
      />
    </g>
  </svg>
);

ErrorIcon.displayName = 'ErrorIcon';
