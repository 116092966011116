export const colorDefinition = {
  black: '#000000',
  white: '#FFFFFF',
  gray: {
    ZERO: '#FAFBFC',
    50: '#F7F9FA',
    100: '#F1f3f5',
    200: '#E1E4E8',
    300: '#D1D5DA',
    400: '#959DA5',
    500: '#6A737D',
    600: '#586069',
    700: '#444D56',
    800: '#2F363D',
    900: '#24292E',
  },
  blue: {
    ZERO: '#F1F8FF',
    100: '#DBEDFF',
    200: '#C8E1FF',
    300: '#79B8FF',
    400: '#2188FF',
    500: '#0366D6',
    600: '#005CC5',
    700: '#044289',
    800: '#032F62',
    900: '#05264C',
  },
  green: {
    ZERO: '#F0FFF4',
    100: '#DCFFE4',
    200: '#BEF5CB',
    300: '#85E89D',
    400: '#34D058',
    500: '#28A745',
    600: '#22863A',
    700: '#176F2C',
    800: '#165C26',
    900: '#144620',
  },
  yellow: {
    ZERO: '#FFFDEF',
    100: '#FFFBDD',
    200: '#FFF5B1',
    300: '#FFEA7F',
    400: '#FFDF5D',
    500: '#FFD33D',
    600: '#F9C513',
    700: '#DBAB09',
    800: '#B08800',
    900: '#735C0F',
  },
  orange: {
    ZERO: '#FFF8F2',
    100: '#FFEBDA',
    200: '#FFD1AC',
    300: '#FFAB70',
    400: '#FB8532',
    500: '#F66A0A',
    600: '#E36209',
    700: '#D15704',
    800: '#C24E00',
    900: '#A04100',
  },
  red: {
    ZERO: '#FFEEF0',
    100: '#FFDCE0',
    200: '#FDAEB7',
    300: '#F97583',
    400: '#EA4A5A',
    500: '#D73A49',
    600: '#CB2431',
    700: '#B31D28',
    800: '#9E1C23',
    900: '#86181D',
  },
  pink: {
    ZERO: '#FFEEF8',
    100: '#FEDBF0',
    200: '#F9B3DD',
    300: '#F692CE',
    400: '#EC6CB9',
    500: '#EA4AAA',
    600: '#D03592',
    700: '#B93A86',
    800: '#99306F',
    900: '#6D224F',
  },
  purple: {
    ZERO: '#F5F0FF',
    100: '#E6DCFD',
    200: '#D1BCF9',
    300: '#B392F0',
    400: '#8A63D2',
    500: '#6F42C1',
    600: '#5A32A3',
    700: '#4C2888',
    800: '#3A1D6E',
    900: '#29134E',
  },
};

export interface IColorSet {
  'text-gray-light': string;
  'text-gray-lighter': string;
  'text-gray-main': string;
  'text-gray-sub-darker': string;
  'text-gray-sub-dark': string;
  'text-gray-sub-light': string;
  'text-gray-sub-lighter': string;
  'text-white': string;
  'text-w-opacity-60%': string;
  'text-blue-light': string;
  'text-blue': string;
  'text-green': string;
  'text-green-light': string;
  'text-green-dark': string;
  'text-yellow': string;
  'text-orange': string;
  'text-red-dark': string;
  'text-red': string;
  'text-red-light': string;
  'text-red-lighter': string;
  'text-pink': string;
  'text-purple-light': string;
  'text-purple': string;
  'text-black': string;
  'text-gradient': string;
  'text-yellow-light': string;
  'text-yellow-dark': string;
  'border-white': string;
  'border-gray-w-lighter': string;
  'border-gray-lighter': string;
  'border-gray-light': string;
  'border-gray-dark': string;
  'border-gray-darker': string;
  'border-bk-opacity-10%': string;
  'border-bk-opacity-15%': string;
  'border-w-opacity-30%': string;
  'border-blue': string;
  'border-green': string;
  'border-green-light': string;
  'border-yellow': string;
  'border-orange': string;
  'border-red': string;
  'border-red-light': string;
  'border-red-lighter': string;
  'border-red-lighter-more': string;
  'border-pink': string;
  'border-purple': string;
  'border-purple-dark': string;
  'border-purple-lighter': string;
  'border-purple-light': string;
  'border-gradient': string;
  'bg-white': string;
  'bg-gray-lighter': string;
  'bg-gray-light': string;
  'bg-gray-light-50': string;
  'bg-gray-light-m': string;
  'bg-gray-light-gradient-90': string;
  'bg-gray-dark': string;
  'bg-gray-dark-50': string;
  'bg-gray-darker': string;
  'bg-gray-main': string;
  'bg-bk-opacity-40%': string;
  'bg-bk-opacity-60%': string;
  'bg-bk-opacity-80%': string;
  'bg-bk-opacity-85%': string;
  'bg-blue-lighter': string;
  'bg-blue-light': string;
  'bg-blue-dark': string;
  'bg-blue-dark-40': string;
  'bg-green': string;
  'bg-green-lighter': string;
  'bg-green-light': string;
  'bg-yellow-lighter': string;
  'bg-yellow-light': string;
  'bg-yellow': string;
  'bg-orange-lighter': string;
  'bg-red-light': string;
  'bg-red-lighter': string;
  'bg-pink-lighter': string;
  'bg-pink': string;
  'bg-purple': string;
  'bg-purple-lighter': string;
  'bg-purple-light': string;
  'bg-purple-light-50': string;
  'bg-purple-dark': string;
  'bg-gradient': string;
  'bg-w-gradient': string;
  'ic-w-opacity-60%': string;
  'ic-white': string;
  'ic-gray-lighter': string;
  'ic-gray-light': string;
  'ic-gray-dark': string;
  'ic-gray-main': string;
  'ic-green': string;
  'ic-green-light': string;
  'ic-pink-light': string;
  'ic-purple': string;
  'ic-purple-light': string;
  'ic-red-light': string;
  'ic-red-dark': string;
  'ic-yellow-dark': string;
  'ic-blue-dark': string;
  'ic-blue-light': string;
  'state-white-hover': string;
  'state-white-pressed': string;
  'state-white-purple-hover': string;
  'state-white-purple-pressed': string;
  'state-purple-hover': string;
  'state-purple-pressed': string;
  'state-gradient-hover': string;
  'state-gradient-pressed': string;
  'graph-green': string;
  'graph-green-light': string;
  'graph-yellow': string;
  'graph-yellow-lighter': string;
  'graph-orange': string;
  'graph-orange-lighter': string;
  'graph-blue': string;
  'graph-blue-light': string;
  'graph-gray': string;
  'graph-gray-light': string;
  'graph-gray-m-light': string;
  'graph-gray-lighter': string;
  'graph-gray-dark': string;
  'graph-purple': string;
  'graph-purple-light': string;
  'graph-purple-lighter': string;
  'graph-purple-gradient': string;
  'graph-pink': string;
}

type ModeType = 'dark' | 'light';

export const colors: { [key in ModeType]: IColorSet } = {
  dark: {
    'text-gray-light': colorDefinition.gray[400],
    'text-gray-lighter': colorDefinition.gray[300],
    'text-gray-main': colorDefinition.gray[900],
    'text-gray-sub-darker': colorDefinition.gray[700],
    'text-gray-sub-dark': colorDefinition.gray[500],
    'text-gray-sub-light': colorDefinition.gray[400],
    'text-gray-sub-lighter': colorDefinition.gray[300],
    'text-white': colorDefinition.white,
    'text-w-opacity-60%': 'rgba(255, 255, 255, 0.6)',
    'text-blue-light': colorDefinition.blue[400],
    'text-blue': colorDefinition.blue[700],
    'text-green': colorDefinition.green[700],
    'text-green-light': colorDefinition.green[400],
    'text-green-dark': colorDefinition.green[800],
    'text-yellow': colorDefinition.yellow[800],
    'text-orange': colorDefinition.orange[700],
    'text-red-dark': colorDefinition.red[800],
    'text-red': colorDefinition.red[600],
    'text-red-light': colorDefinition.red[400],
    'text-red-lighter': colorDefinition.red[100],
    'text-pink': colorDefinition.pink[600],
    'text-purple-light': colorDefinition.purple[500],
    'text-purple': colorDefinition.purple[700],
    'text-gradient': `linear-gradient(to left, ${colorDefinition.purple[500]} 0%, ${colorDefinition.pink[700]} 100% )`,
    'text-yellow-light': colorDefinition.yellow[300],
    'text-yellow-dark': colorDefinition.yellow[900],
    'border-white': colorDefinition.white,
    'border-gray-w-lighter': colorDefinition.gray[100],
    'border-gray-lighter': colorDefinition.gray[200],
    'border-gray-light': colorDefinition.gray[300],
    'border-gray-dark': colorDefinition.gray[400],
    'border-gray-darker': colorDefinition.gray[900],
    'border-bk-opacity-10%': 'rgba(0, 0, 0, 0.1)',
    'border-bk-opacity-15%': 'rgba(0, 0, 0, 0.15)',
    'border-blue': colorDefinition.blue[500],
    'border-green': colorDefinition.green[500],
    'border-green-light': colorDefinition.green[300],
    'border-yellow': colorDefinition.yellow[700],
    'border-orange': colorDefinition.orange[600],
    'border-red-lighter': colorDefinition.red[200],
    'border-red-lighter-more': colorDefinition.red[100],
    'border-red-light': colorDefinition.red[400],
    'border-red': colorDefinition.red[500],
    'border-pink': colorDefinition.pink[500],
    'border-purple': colorDefinition.purple[500],
    'border-purple-dark': colorDefinition.purple[600],
    'border-purple-lighter': colorDefinition.purple[200],
    'border-purple-light': colorDefinition.purple[200],
    'border-gradient': `linear-gradient(to left, ${colorDefinition.purple[500]} 0%, ${colorDefinition.pink[700]} 100% )`,
    'border-w-opacity-30%': 'rgba(255,255,255, 0.3)',
    'bg-white': colorDefinition.white,
    'bg-gray-lighter': colorDefinition.gray.ZERO,
    'bg-gray-light': colorDefinition.gray[100],
    'bg-gray-light-50': colorDefinition.gray[50],
    'bg-gray-light-m': colorDefinition.gray[200],
    'bg-gray-light-gradient-90': `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${colorDefinition.gray.ZERO} 49%)`,
    'bg-gray-dark': colorDefinition.gray[300],
    'bg-gray-dark-50': colorDefinition.gray[500],
    'bg-gray-darker': colorDefinition.gray[600],
    'bg-gray-main': colorDefinition.gray[900],
    'bg-bk-opacity-40%': 'rgba(0, 0, 0, 0.4)',
    'bg-bk-opacity-60%': 'rgba(0, 0, 0, 0.6)',
    'bg-bk-opacity-80%': 'rgba(0, 0, 0, 0.8)',
    'bg-bk-opacity-85%': 'rgba(0, 0, 0, 0.85)',
    'bg-blue-lighter': colorDefinition.blue[100],
    'bg-blue-light': colorDefinition.blue[200],
    'bg-blue-dark-40': colorDefinition.blue[400],
    'bg-blue-dark': colorDefinition.blue[500],
    'bg-green': colorDefinition.green[500],
    'bg-green-lighter': colorDefinition.green[100],
    'bg-green-light': colorDefinition.green[100],
    'bg-yellow-lighter': colorDefinition.yellow[100],
    'bg-yellow-light': colorDefinition.yellow[200],
    'bg-yellow': colorDefinition.yellow[300],
    'bg-orange-lighter': colorDefinition.orange[100],
    'bg-red-light': colorDefinition.red[400],
    'bg-red-lighter': colorDefinition.red.ZERO,
    'bg-pink-lighter': colorDefinition.pink.ZERO,
    'bg-pink': colorDefinition.pink[500],
    'bg-purple-lighter': colorDefinition.purple.ZERO,
    'bg-purple-light': colorDefinition.purple[500],
    'bg-purple-light-50': colorDefinition.purple[200],
    'bg-purple': colorDefinition.purple[600],
    'bg-purple-dark': colorDefinition.purple[700],
    'bg-gradient': `linear-gradient(to left, ${colorDefinition.purple[500]} 0%, ${colorDefinition.pink[700]} 100% )`,
    'bg-w-gradient': `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 60.94%)`,
    'ic-w-opacity-60%': 'rgba(255, 255, 255, 0.6)',
    'ic-white': colorDefinition.white,
    'ic-gray-lighter': colorDefinition.gray[300],
    'ic-gray-light': colorDefinition.gray[400],
    'ic-gray-dark': colorDefinition.gray[500],
    'ic-gray-main': colorDefinition.gray[900],
    'ic-green': colorDefinition.green[500],
    'ic-green-light': colorDefinition.green[400],
    'ic-pink-light': colorDefinition.pink[400],
    'ic-purple-light': colorDefinition.purple[500],
    'ic-purple': colorDefinition.purple[600],
    'ic-red-light': colorDefinition.red[400],
    'ic-red-dark': colorDefinition.red[600],
    'ic-yellow-dark': colorDefinition.yellow[600],
    'ic-blue-dark': colorDefinition.blue[600],
    'ic-blue-light': colorDefinition.blue[400],
    'text-black': colorDefinition.black,
    'state-white-hover': '#F6F6F7',
    'state-white-pressed': '#EDEEEE',
    'state-white-purple-hover': '#F9F7FD',
    'state-white-purple-pressed': '#F3F0FA',
    'state-purple-hover': '#643EA9',
    'state-purple-pressed': '#6B47AC',
    'state-gradient-hover': 'linear-gradient(90deg, #A6357A 0%, #653CAE 100%)',
    'state-gradient-pressed': 'linear-gradient(90deg, #9D3273 0%, #5F39A4 100%);',
    'graph-green': colorDefinition.green[400],
    'graph-green-light': colorDefinition.green[300],
    'graph-yellow': colorDefinition.yellow[400],
    'graph-yellow-lighter': colorDefinition.yellow[100],
    'graph-orange': colorDefinition.orange[300],
    'graph-orange-lighter': colorDefinition.orange[100],
    'graph-blue': colorDefinition.blue[400],
    'graph-blue-light': colorDefinition.blue[300],
    'graph-gray': colorDefinition.gray[300],
    'graph-gray-light': colorDefinition.gray[200],
    'graph-gray-m-light': colorDefinition.gray[100],
    'graph-gray-lighter': colorDefinition.gray.ZERO,
    'graph-purple': colorDefinition.purple[500],
    'graph-purple-light': colorDefinition.purple[400],
    'graph-purple-lighter': colorDefinition.purple[200],
    'graph-purple-gradient': colorDefinition.purple[100],
    'graph-pink': colorDefinition.pink[500],
    'graph-gray-dark': colorDefinition.gray[400],
  },
  light: {
    'text-gray-light': colorDefinition.gray[400],
    'text-gray-lighter': colorDefinition.gray[300],
    'text-gray-main': colorDefinition.gray[900],
    'text-gray-sub-darker': colorDefinition.gray[700],
    'text-gray-sub-dark': colorDefinition.gray[500],
    'text-gray-sub-light': colorDefinition.gray[400],
    'text-gray-sub-lighter': colorDefinition.gray[300],
    'text-white': colorDefinition.white,
    'text-w-opacity-60%': 'rgba(255, 255, 255, 0.6)',
    'text-blue-light': colorDefinition.blue[400],
    'text-blue': colorDefinition.blue[700],
    'text-green': colorDefinition.green[700],
    'text-green-light': colorDefinition.green[400],
    'text-green-dark': colorDefinition.green[800],
    'text-yellow': colorDefinition.yellow[800],
    'text-yellow-dark': colorDefinition.yellow[900],
    'text-orange': colorDefinition.orange[800],
    'text-red-dark': colorDefinition.red[800],
    'text-red': colorDefinition.red[600],
    'text-red-light': colorDefinition.red[400],
    'text-red-lighter': colorDefinition.red[100],
    'text-pink': colorDefinition.pink[700],
    'text-purple-light': colorDefinition.purple[500],
    'text-purple': colorDefinition.purple[700],
    'text-gradient': `linear-gradient(to left, ${colorDefinition.purple[500]} 0%, ${colorDefinition.pink[700]} 100% )`,
    'text-yellow-light': colorDefinition.yellow[300],
    'border-white': colorDefinition.white,
    'border-gray-w-lighter': colorDefinition.gray[100],
    'border-gray-lighter': colorDefinition.gray[200],
    'border-gray-light': colorDefinition.gray[300],
    'border-gray-dark': colorDefinition.gray[400],
    'border-gray-darker': colorDefinition.gray[900],
    'border-bk-opacity-10%': 'rgba(0, 0, 0, 0.1)',
    'border-bk-opacity-15%': 'rgba(0, 0, 0, 0.15)',
    'border-blue': colorDefinition.blue[500],
    'border-green': colorDefinition.green[500],
    'border-green-light': colorDefinition.green[300],
    'border-yellow': colorDefinition.yellow[700],
    'border-orange': colorDefinition.orange[600],
    'border-red-lighter': colorDefinition.red[200],
    'border-red-lighter-more': colorDefinition.red[100],
    'border-red-light': colorDefinition.red[400],
    'border-red': colorDefinition.red[500],
    'border-pink': colorDefinition.pink[500],
    'border-purple': colorDefinition.purple[500],
    'border-purple-dark': colorDefinition.purple[600],
    'border-purple-lighter': colorDefinition.purple[200],
    'border-purple-light': colorDefinition.purple[200],
    'border-w-opacity-30%': 'rgba(255,255,255, 0.3)',
    'border-gradient': `linear-gradient(to left, ${colorDefinition.purple[500]} 0%, ${colorDefinition.pink[700]} 100% )`,
    'bg-white': colorDefinition.white,
    'bg-gray-lighter': colorDefinition.gray.ZERO,
    'bg-gray-light': colorDefinition.gray[100],
    'bg-gray-light-50': colorDefinition.gray[50],
    'bg-gray-light-m': colorDefinition.gray[200],
    'bg-gray-light-gradient-90': `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${colorDefinition.gray.ZERO} 49%)`,
    'bg-gray-dark': colorDefinition.gray[300],
    'bg-gray-dark-50': colorDefinition.gray[500],
    'bg-gray-darker': colorDefinition.gray[600],
    'bg-gray-main': colorDefinition.gray[900],
    'bg-bk-opacity-40%': 'rgba(0, 0, 0, 0.4)',
    'bg-bk-opacity-60%': 'rgba(0, 0, 0, 0.6)',
    'bg-bk-opacity-80%': 'rgba(0, 0, 0, 0.8)',
    'bg-bk-opacity-85%': 'rgba(0, 0, 0, 0.85)',
    'bg-blue-lighter': colorDefinition.blue[100],
    'bg-blue-light': colorDefinition.blue[200],
    'bg-blue-dark': colorDefinition.blue[500],
    'bg-blue-dark-40': colorDefinition.blue[400],
    'bg-green': colorDefinition.green[500],
    'bg-green-lighter': colorDefinition.green[100],
    'bg-green-light': colorDefinition.green[100],
    'bg-yellow-lighter': colorDefinition.yellow[100],
    'bg-yellow-light': colorDefinition.yellow[200],
    'bg-yellow': colorDefinition.yellow[300],
    'bg-orange-lighter': colorDefinition.orange[100],
    'bg-red-light': colorDefinition.red[400],
    'bg-red-lighter': colorDefinition.red.ZERO,
    'bg-pink-lighter': colorDefinition.pink.ZERO,
    'bg-pink': colorDefinition.pink[500],
    'bg-purple-lighter': colorDefinition.purple.ZERO,
    'bg-purple-light': colorDefinition.purple[500],
    'bg-purple-light-50': colorDefinition.purple[200],
    'bg-purple': colorDefinition.purple[600],
    'bg-purple-dark': colorDefinition.purple[700],
    'bg-gradient': `linear-gradient(to left, ${colorDefinition.purple[500]} 0%, ${colorDefinition.pink[700]} 100% )`,
    'bg-w-gradient': `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 60.94%)`,
    'ic-w-opacity-60%': 'rgba(255, 255, 255, 0.6)',
    'ic-white': colorDefinition.white,
    'ic-gray-lighter': colorDefinition.gray[300],
    'ic-gray-light': colorDefinition.gray[400],
    'ic-gray-dark': colorDefinition.gray[500],
    'ic-gray-main': colorDefinition.gray[900],
    'ic-green': colorDefinition.green[500],
    'ic-green-light': colorDefinition.green[400],
    'ic-pink-light': colorDefinition.pink[400],
    'ic-purple-light': colorDefinition.purple[500],
    'ic-purple': colorDefinition.purple[600],
    'ic-red-light': colorDefinition.red[400],
    'ic-red-dark': colorDefinition.red[600],
    'ic-yellow-dark': colorDefinition.yellow[600],
    'ic-blue-dark': colorDefinition.blue[600],
    'ic-blue-light': colorDefinition.blue[400],
    'text-black': colorDefinition.black,
    'state-white-hover': '#F6F6F7',
    'state-white-pressed': '#EDEEEE',
    'state-white-purple-hover': '#F9F7FD',
    'state-white-purple-pressed': '#F3F0FA',
    'state-purple-hover': '#643EA9',
    'state-purple-pressed': '#6B47AC',
    'state-gradient-hover': `linear-gradient(90deg, #A6357A 0%, #653CAE 100%);`,
    'state-gradient-pressed': 'linear-gradient(90deg, #9D3273 0%, #5F39A4 100%);',
    'graph-green': colorDefinition.green[400],
    'graph-green-light': colorDefinition.green[300],
    'graph-yellow': colorDefinition.yellow[400],
    'graph-yellow-lighter': colorDefinition.yellow[100],
    'graph-orange': colorDefinition.orange[300],
    'graph-orange-lighter': colorDefinition.orange[100],
    'graph-blue': colorDefinition.blue[400],
    'graph-blue-light': colorDefinition.blue[300],
    'graph-gray': colorDefinition.gray[300],
    'graph-gray-light': colorDefinition.gray[200],
    'graph-gray-m-light': colorDefinition.gray[100],
    'graph-gray-lighter': colorDefinition.gray.ZERO,
    'graph-purple': colorDefinition.purple[500],
    'graph-purple-light': colorDefinition.purple[400],
    'graph-purple-lighter': colorDefinition.purple[200],
    'graph-purple-gradient': colorDefinition.purple[100],
    'graph-pink': colorDefinition.pink[500],
    'graph-gray-dark': colorDefinition.gray[400],
  },
};
