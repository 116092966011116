import { KEYS, repository } from '@repositories/Repository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ProductRelation, RelatedProduct, RelationType } from '@repositories/productsRelationsRepository/Types';
import IProductRelationsRepository from '@repositories/productsRelationsRepository/IProductRelationsRepository';
import { ProductRelationDetailModel } from '@models/softwareModels/ProductRelationDetailModel';

const productRelationsRepository = repository.get<IProductRelationsRepository>(KEYS.PRODUCT_RELATIONS_REPOSITORY);

const queryKey = {
  all: ['productRelations'] as const,
  one: (productId: SoftwareId) => [...queryKey.all, productId] as const,
};

export const useGetProductRelations = (productId: string) => {
  const result = useQuery<ProductRelationDetailModel[], AxiosError>(queryKey.one(productId), async () => {
    const result = await productRelationsRepository.getOne(productId);
    return result.map(dto => new ProductRelationDetailModel(dto));
  });

  return { data: result.data ?? [] };
};

export const useAddProductRelationsMutaion = () => {
  return useMutation(async (data: ProductRelation) => productRelationsRepository.create(data));
};

export const useUpdateProductRelationsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (requestData: { body: RelatedProduct[]; productId: string }) => {
      const result = await productRelationsRepository.update(requestData.body, requestData.productId);
      return result;
    },
    {
      onSuccess: (result: ProductRelation) => {
        queryClient.invalidateQueries(queryKey.one(result.productId));
      },
    },
  );
};

export const useDeleteProductRelationsMutation = (productId: string, queries: { type: RelationType }) => {
  const queryClient = useQueryClient();

  return useMutation(async (productId: string) => productRelationsRepository.delete(productId, queries), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey.one(productId));
    },
  });
};
