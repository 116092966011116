import { useEffect, useState } from 'react';
import { Controller, type ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Autocomplete, FormHelperText, TextField, css } from '@mui/material';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { Dialog } from '@components/Dialog';
import { DatePicker } from '@components/datePicker';
import { Dropdown } from '@components/dropdown';
import { Button, Loader } from '@components/index';
import { UserGroupSelect } from '@components/userGroupSelect/UserGroupSelect';
import { TimezoneAutocomplete } from '@pages/customer/common';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { type TenantMemberFilter } from '@repositories/tenantRepository';
import { useGetProductPlanDetail, useGetProductPlanList } from '@queryHooks/useSoftware';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import { transientOptions } from '@utils/CommonUtil';
import { DomUtil } from '@utils/DomUtil';
import { type SubscriptionModel } from '@models/SubscriptionModel';
import { type SubscriptionEditForm } from './SubscriptionEditValidationSchema';

type Props = {
  subscription?: SubscriptionModel;
  softwareInfo: {
    id: string;
    iconUrl: string;
    name: string;
  };
  selectedUserGroup: { userGroupId: string; name: string };
  onUserGroupChange: (userGroup: { userGroupId: string; name: string }) => void;
  onNextStepClick: () => void;
  onClose: () => void;
};
export const BasicStep = ({
  subscription,
  softwareInfo,
  selectedUserGroup,
  onUserGroupChange,
  onNextStepClick,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { colors } = useTheme();

  const [memberFilter, setMemberFilter] = useState<Omit<TenantMemberFilter, 'page'>>({});

  const { register, formState, control, watch, trigger, setValue } = useFormContext<SubscriptionEditForm>();
  const [selectedPlanId, selectedStartDate] = watch(['planId', 'startDate']);

  const isEditMode = !!subscription;

  const { data: planList, isLoading: isPlanListLoading } = useGetProductPlanList(softwareInfo.id);
  const { data: planDetail } = useGetProductPlanDetail(softwareInfo.id, selectedPlanId, {
    enabled: !!selectedPlanId,
  });
  const {
    data: pagedUserGroupMembers,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetTenantMembersInfinite(authStore.managerTenantInfo.id, memberFilter);
  const userGroupMembers = pagedUserGroupMembers?.pages.map(({ content }) => content).flat(1) ?? [];

  useEffect(() => {
    if (subscription) {
      setMemberFilter({});
    }
  }, [subscription]);

  const handleAccept = (type?: 'startDate' | 'endDate' | 'paymentDueDate') => () => {
    if (type) {
      trigger(type);
    }
  };

  const handleMemberSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberFilter(prev => ({ ...prev, keyword: e.target.value }));
  }, 1000);

  const handleMemberListScroll = debounce((event: React.UIEvent<HTMLUListElement>) => {
    if (DomUtil.isScrollBottom(event.target as HTMLUListElement) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 100);

  const handlePlanChange = (formOnChange: ControllerRenderProps['onChange']) => (value: string | number) => {
    formOnChange(value);
    setValue('planOptionId', '');
  };

  return isPlanListLoading ? (
    <Loader left={45} top={55} />
  ) : (
    <>
      <FieldWrapper $isFirstField>
        <RequiredFieldKey htmlFor="name">{t('Subscrib_Main_10')}</RequiredFieldKey>
        <TextField
          {...register('name')}
          id="name"
          fullWidth
          placeholder={t('Subscrib_Edit_03')}
          autoFocus
          error={!!formState.errors.name}
          helperText={formState.errors.name?.message}
        />
      </FieldWrapper>

      <FieldWrapper>
        <RequiredFieldKey
          htmlFor="plan-id"
          className={isEditMode && !subscription.isExternalSubscription ? 'disabled' : ''}
        >
          {t('Subscrib_Main_12')}
        </RequiredFieldKey>
        <Controller
          control={control}
          name="planId"
          render={({ field: { ref, value, onChange } }) => (
            <FieldSelect
              id="plan-id"
              ref={ref}
              value={value}
              onChange={handlePlanChange(onChange)}
              size="large"
              placeholder={t('Subscrib_Edit_04')}
              disabled={isEditMode && !subscription.isExternalSubscription}
              $hasError={!!formState.errors.planId?.message}
            >
              {planList?.map(plan => (
                <Dropdown.Menu key={plan.planId} value={plan.planId}>
                  {plan.planName}
                </Dropdown.Menu>
              ))}
            </FieldSelect>
          )}
        />
        {formState.errors.planId?.message && <SelectHelperText>{formState.errors.planId?.message}</SelectHelperText>}
      </FieldWrapper>

      <FieldWrapper>
        <RequiredFieldKey
          htmlFor="plan-option-id"
          className={
            !!formState.errors.planOptionId && !!selectedPlanId
              ? 'error'
              : (isEditMode && !subscription.isExternalSubscription) || !selectedPlanId
              ? 'disabled'
              : ''
          }
        >
          {t('Subscrib_Edit_01')}
        </RequiredFieldKey>
        <Controller
          control={control}
          name="planOptionId"
          render={({ field: { ref, value, onChange } }) => (
            <FieldSelect
              ref={ref}
              id="plan-option-id"
              value={value}
              onChange={onChange}
              size="large"
              placeholder={t('Subscrib_Edit_05')}
              $hasError={!!formState.errors.planOptionId && !!selectedPlanId}
              disabled={(isEditMode && !subscription.isExternalSubscription) || !selectedPlanId}
            >
              {planDetail?.planOptionList?.map(planOption => (
                <Dropdown.Menu key={planOption.planOptionId} value={planOption.planOptionId}>
                  {planOption.optionName}
                </Dropdown.Menu>
              ))}
            </FieldSelect>
          )}
        />
        {formState.errors.planOptionId?.message && !!selectedPlanId && (
          <SelectHelperText>{formState.errors.planOptionId?.message}</SelectHelperText>
        )}
      </FieldWrapper>

      <FieldWrapper>
        <RequiredFieldKey
          htmlFor="timezone"
          className={
            !!formState.errors.planOptionId && !!selectedPlanId
              ? 'error'
              : (isEditMode && !subscription.isExternalSubscription) || !selectedPlanId
              ? 'disabled'
              : ''
          }
        >
          {t('Workflow_Create_42')}
        </RequiredFieldKey>
        <Controller
          control={control}
          name="timezone"
          render={({ field: { ref, value, onChange } }) => (
            <TimezoneAutocomplete
              value={value}
              onChange={onChange}
              disabled={(isEditMode && !subscription.isExternalSubscription) || !selectedPlanId}
              inputRef={ref}
              error={!!formState.errors.timezone && !!selectedPlanId}
            />
          )}
        />
        {formState.errors.timezone?.message && !!selectedPlanId && (
          <SelectHelperText>{formState.errors.timezone?.message}</SelectHelperText>
        )}
      </FieldWrapper>

      <PeriodWrapper>
        <FieldWrapper>
          <RequiredFieldKey
            htmlFor="start-date"
            className={!selectedPlanId || (isEditMode && !subscription.isExternalSubscription) ? 'disabled' : ''}
          >
            {t('Subscrib_Main_13')}
          </RequiredFieldKey>
          <Controller
            control={control}
            name="startDate"
            render={({ field: { ref, value, onChange } }) => (
              <DatePicker
                inputRef={ref}
                inputFormat={t('DateFormat_YMD')}
                value={value}
                onChange={onChange}
                renderInput={params => (
                  <DatePicker.Input
                    {...params}
                    id="start-date"
                    size="medium"
                    fullWidth
                    error={!!formState.errors.startDate && !!selectedPlanId}
                  />
                )}
                showDaysOutsideCurrentMonth
                disabled={!selectedPlanId || (isEditMode && !subscription.isExternalSubscription)}
              />
            )}
          />
          {formState.errors.startDate?.message && !!selectedPlanId && (
            <SelectHelperText>{formState.errors.startDate?.message}</SelectHelperText>
          )}
        </FieldWrapper>

        <FieldWrapper>
          <FieldKey
            htmlFor="end-date"
            className={!selectedPlanId || (isEditMode && !subscription.isExternalSubscription) ? 'disabled' : ''}
          >
            {t('Subscrib_Main_14')}
          </FieldKey>
          <Controller
            control={control}
            name="endDate"
            render={({ field: { ref, value, onChange } }) => (
              <DatePicker
                inputRef={ref}
                inputFormat={t('DateFormat_YMD')}
                value={value}
                onChange={onChange}
                onAccept={handleAccept('startDate')}
                renderInput={params => (
                  <DatePicker.Input
                    {...params}
                    id="end-date"
                    fullWidth
                    size="medium"
                    error={
                      (!!formState.errors.endDate || formState.errors.startDate?.message === t('Date_02')) &&
                      !!selectedPlanId
                    }
                  />
                )}
                showDaysOutsideCurrentMonth
                disabled={!selectedPlanId || (isEditMode && !subscription.isExternalSubscription)}
                shouldDisableDate={day => {
                  const disableDate = dayjs(selectedStartDate).add(1, 'D');
                  return dayjs.isDayjs(day) ? day.isBefore(disableDate) : false;
                }}
              />
            )}
          />
          {formState.errors.endDate?.message && (
            <SelectHelperText>{formState.errors.endDate?.message}</SelectHelperText>
          )}
        </FieldWrapper>
      </PeriodWrapper>

      <FieldWrapper>
        <FieldKey
          htmlFor="payment-due-date"
          className={!selectedPlanId || (isEditMode && !subscription.isExternalSubscription) ? 'disabled' : ''}
        >
          {t('Subscrib_Edit_07')}
        </FieldKey>
        <Controller
          control={control}
          name="paymentDueDate"
          render={({ field: { ref, value, onChange } }) => (
            <DatePicker
              inputRef={ref}
              inputFormat={t('DateFormat_YMD')}
              value={value}
              onChange={onChange}
              renderInput={params => (
                <DatePicker.Input
                  {...params}
                  id="payment-due-date"
                  fullWidth
                  size="medium"
                  error={!!formState.errors.paymentDueDate && !!selectedPlanId}
                />
              )}
              showDaysOutsideCurrentMonth
              componentsProps={{ actionBar: { actions: ['clear', 'today'] } }}
              disabled={!selectedPlanId || (isEditMode && !subscription.isExternalSubscription)}
            />
          )}
        />
        {formState.errors.paymentDueDate?.message && !!selectedPlanId && (
          <SelectHelperText>{formState.errors.paymentDueDate?.message}</SelectHelperText>
        )}
      </FieldWrapper>

      <FieldWrapper>
        <FieldKey htmlFor="owner-id">{t('Subscrib_Edit_08')}</FieldKey>
        <Controller
          control={control}
          name="owner"
          render={({ field: { ref, value, onChange } }) => (
            <Autocomplete
              value={value}
              onChange={(event, value) => onChange(value)}
              options={userGroupMembers.map(({ id, name, email }) => ({ id, name, email }))}
              getOptionLabel={option => (option.id ? `${option.name} (${option.email})` : '')}
              renderInput={params => (
                <TextField
                  {...params}
                  ref={ref}
                  placeholder={t('Subscrib_Edit_09')}
                  variant="outlined"
                  onChange={handleMemberSearchChange}
                  sx={{
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '2px 2px 0 0',
                    },
                    '& .MuiInputBase-root': {
                      '& .MuiAutocomplete-endAdornment': {
                        width: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexShrink: '0',
                        gap: '6px',
                        right: '8px',
                        top: 'calc(50% - 9px)',
                        '& ~ &': {
                          justifyContent: 'flex-start',
                        },
                      },
                      '& .MuiButtonBase-root': {
                        width: '18px',
                        height: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: '0',
                        padding: '0',
                      },
                      '& .MuiSvgIcon-root': {
                        width: '18px',
                        height: '18px',
                        color: `${colors['ic-gray-dark']}`,
                      },
                      '& .MuiTouchRipple-root': {
                        display: 'none',
                      },
                      '&.Mui-disabled': {
                        '& .MuiOutlinedInput-notchedOutline ': {
                          borderColor: `${colors['border-gray-light']}`,
                        },
                        '& .MuiSvgIcon-root': {
                          color: `${colors['ic-gray-light']}`,
                        },
                      },
                    },
                  }}
                />
              )}
              ListboxProps={{ onScroll: handleMemberListScroll, role: 'list-box' }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText={<AutocompleteNoOptions />}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '10px 12px',
                },
                '& .MuiButtonBase-root': {
                  borderRadius: '0',
                  '&:hover': {
                    backgroundColor: `${colors['bg-white']}`,
                  },
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: 0,
                },
              }}
              componentsProps={{
                paper: {
                  sx: {
                    width: '544px',
                    marginTop: 0,
                    borderRadius: '0 0 2px 2px',
                    boxShadow: '0',
                    border: `1px solid ${colors['border-gray-light']}`,
                    borderWidth: '0 1px 1px 1px',
                    overflow: 'hidden',
                    '& .MuiAutocomplete-listbox': {
                      height: '208px',
                    },
                    '& .MuiList-root': {
                      border: 'none',
                      padding: '4px 0',
                      '& .MuiMenuItem-root': {
                        margin: '0 5px',
                      },
                    },
                  },
                },
              }}
            />
          )}
        />
      </FieldWrapper>

      <FieldWrapper $isLastField>
        <FieldKey htmlFor="user-group-id">{t('Subscrib_Main_16')}</FieldKey>
        <StyledUserGroupSelect
          selectedValue={selectedUserGroup}
          onSelectChange={(e, value) => onUserGroupChange(value)}
        />
      </FieldWrapper>

      <Dialog.Footer>
        <Button
          type={subscription ? 'submit' : 'button'}
          variant="contain"
          size="extraLarge"
          style={{ maxWidth: '228px' }}
          onClick={subscription ? undefined : onNextStepClick}
        >
          {subscription ? '완료' : '다음'}
        </Button>
        <Button variant="outline" size="extraLarge" style={{ maxWidth: '228px' }} onClick={onClose}>
          취소
        </Button>
      </Dialog.Footer>
    </>
  );
};

const FieldWrapper = styled.div<{ $isFirstField?: boolean; $isLastField?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: ${({ $isFirstField }) => ($isFirstField ? '26px' : '0px')};
  margin-bottom: ${({ $isLastField }) => ($isLastField ? '0px' : '24px')};
`;

const RequiredFieldKey = styled.label`
  ${fonts.Headline8}
  &:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  &.disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &:after {
      color: ${({ theme }) => theme.colors['text-red-lighter']};
    }
  }
`;

const FieldSelect = styled(Dropdown, transientOptions)<{ $hasError: boolean }>`
  ${({ $hasError, theme: { colors } }) =>
    $hasError
      ? css`
          border: 1px solid ${colors['border-red-light']};
          color: ${colors['text-red-light']};
          -webkit-text-fill-color: ${colors['text-red-light']};
          & path {
            fill: ${colors['text-red-light']};
          }
        `
      : ''}
`;

const SelectHelperText = styled(FormHelperText)`
  margin: 0;
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  ${fonts.Body4};
`;

const PeriodWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldKey = styled.label`
  ${fonts.Headline8}

  &.disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const AutocompleteNoOptions = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <AutocompleteNoOptionsContainer>
      <ErrorIconWrapper>
        <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
      </ErrorIconWrapper>
      <p>{t('Subscrib_Edit_11')}</p>
    </AutocompleteNoOptionsContainer>
  );
};

const AutocompleteNoOptionsContainer = styled.div`
  padding: 16px 0px;
  ${fonts.Body2}
  text-align: center;
`;

const ErrorIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(245, 240, 255, 1);
  margin: 0 auto 12px;
  padding: 7px;
`;

const StyledUserGroupSelect = styled(UserGroupSelect)`
  padding: 10px 8px 10px 12px;
`;
