import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { type Dayjs } from 'dayjs';
import { Button, EmptyRow, TableHeaderCell } from '@components/index';
import { AddExistingBillingModal } from '@pages/customer/manager/billing/history/list/addExistingBillingModal/AddExistingBillingModal';
import { fonts } from '@theme/fontsCustomer';
import { type Filter } from '@repositories/monthlyBillingRepository';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetMonthlyBillingList } from '@queryHooks/useMonthlyBilling';
import { getSortOrder } from '@utils/CommonUtil';
import { AIAnalyze } from './AIAnalyze';
import { BillingLineChart } from './BillingLineChart';
import { BillingRow } from './BillingRow';

export const BillingInSubscription = () => {
  const { t } = useTranslation();
  const subscription = useSubscriptionModel();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [queries, setQueries] = useSearchParamsFilter<Filter>({
    defaultFilter: { sort: ['month.desc'] },
    whiteList: ['sort', 'monthFrom', 'monthTo', 'paymentDateFrom', 'paymentDateTo', 'status'],
  });
  const { data: billings } = useGetMonthlyBillingList({
    subscriptionId: subscription.id,
    includesForecasted: true,
    ...queries,
  });

  const handleSortChange = (orderBy: string, order: SortOrder | undefined) => {
    setQueries(prev => ({ ...prev, sort: order ? [`${orderBy}.${order}`] : [] }));
  };

  const handleCheckFilterChange = (filterKey: string, filterValues: string[]) => {
    setQueries(prev => ({ ...prev, [filterKey]: filterValues }));
  };

  const handleDateFilterChange = (filterKey: string, from: Dayjs | null, to: Dayjs | null) => {
    if (filterKey === 'paymentDate') {
      setQueries(prev => ({
        ...prev,
        [`${filterKey}From`]: from?.format('YYYY-MM-DD'),
        [`${filterKey}To`]: to?.format('YYYY-MM-DD'),
      }));
    } else {
      setQueries(prev => ({
        ...prev,
        [`${filterKey}From`]: from?.format('YYYY-MM'),
        [`${filterKey}To`]: to?.format('YYYY-MM'),
      }));
    }
  };

  return (
    <Container>
      <AIAnalyze />
      <BillingLineChart />
      <TableWrapper>
        <TitleWrapper>
          <Title>정산 내역</Title>
          {subscription.isExternalSubscription && (
            <Button variant="contain" size="small" paddingHorizontal={16} onClick={() => setIsAddModalOpen(true)}>
              {t('Acc_Create_01')}
            </Button>
          )}
        </TitleWrapper>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell
                  width="140"
                  orderBy="month"
                  order={getSortOrder(queries.sort, 'month')}
                  onSortChange={handleSortChange}
                  disableSort={!billings?.content.length}
                  filterKey="month"
                  filterType="date"
                  onFilterChange={handleDateFilterChange}
                  format={t('DateFormat_YM')}
                >
                  {t('Acc_Main_06')}
                </TableHeaderCell>
                <TableHeaderCell width="380">{t('Acc_Create_09')}</TableHeaderCell>
                <TableHeaderCell
                  width="300"
                  orderBy="originalCost"
                  order={getSortOrder(queries.sort, 'originalCost')}
                  onSortChange={handleSortChange}
                  disableSort={!billings?.content.length}
                >
                  {t('Acc_Main_18')}
                </TableHeaderCell>
                <TableHeaderCell
                  width="300"
                  orderBy="cost"
                  order={getSortOrder(queries.sort, 'cost')}
                  onSortChange={handleSortChange}
                  disableSort={!billings?.content.length}
                >
                  {t('Acc_Main_19')}
                </TableHeaderCell>
                <TableHeaderCell
                  width="200"
                  orderBy="paymentDate"
                  order={getSortOrder(queries.sort, 'paymentDate')}
                  onSortChange={handleSortChange}
                  disableSort={!billings?.content.length}
                  filterKey="paymentDate"
                  filterType="date"
                  onFilterChange={handleDateFilterChange}
                >
                  {t('Acc_Main_20')}
                </TableHeaderCell>
                <TableHeaderCell
                  width="140"
                  filterKey="status"
                  filterType="check"
                  itemList={[
                    { displayName: '정산 예측', filterValue: 'FORECASTED' },
                    { displayName: '지불 예정', filterValue: ['CREATED', 'BILLED'] },
                    { displayName: '지불 완료', filterValue: 'PAID' },
                  ]}
                  enableFilterAll
                  onFilterChange={handleCheckFilterChange}
                >
                  지불 상태
                </TableHeaderCell>
                <TableHeaderCell width="140">{t('Acc_Main_22')}</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billings?.content.length ? (
                billings?.content.map(billing =>
                  billing.isForecasted ? null : <BillingRow key={billing.id} billing={billing} queries={queries} />,
                )
              ) : (
                <EmptyRow colSpan={7} title={t('Acc_Main_27')} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
      <AddExistingBillingModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        subscriptionId={subscription.id}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-top: 12px;
`;

const TableWrapper = styled.div`
  margin: 32px 16px 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px 8px;
  height: 32px;
`;

const Title = styled.div`
  margin-top: 6px;
  ${fonts.Headline8}
`;
