export const download = (data: Blob, fileFullName: string) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileFullName;
  document.body.appendChild(a);
  a.click();
  a.remove();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 1);
};

export const parseFileName = (fileFullName: string): [string, string] => {
  const lastDotIndex = fileFullName.lastIndexOf('.');

  if (lastDotIndex < 0) {
    return [fileFullName, ''];
  }
  if (lastDotIndex === 0) {
    return ['', fileFullName.slice(1, fileFullName.length)];
  }

  return [fileFullName.slice(0, lastDotIndex), fileFullName.slice(lastDotIndex + 1, fileFullName.length)];
};
