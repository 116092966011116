import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import logoSrc from '@images/logo_190x32.png';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import type { MetricUsageCostModel } from '@models/billing/MetricUsageCostModel';
import { SubscriptionBillingDetailView } from './layout/SubscriptionBillingDetailView';

type Props = {
  subscription: SubscriptionModel;
  subscriptionBilling: SubscriptionBillingModel;
  metricUsageCosts: MetricUsageCostModel[];
};

export const SubscriptionBillingPrintView = forwardRef(
  ({ subscription, subscriptionBilling, metricUsageCosts }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    return createPortal(
      <Container ref={ref}>
        <Month>{dateFormat(subscriptionBilling.month, '', 'YYYY.MM')}</Month>
        <Title>{t('Acc_Detail_18')}</Title>
        <Logo src={logoSrc} alt="logo" width={190} height={32} />
        <Divider />
        <SubscriptionBillingDetailView
          subscription={subscription}
          subscriptionBilling={subscriptionBilling}
          metricUsageCosts={metricUsageCosts}
        />
      </Container>,
      document.body,
    );
  },
);

const Container = styled.div`
  position: absolute;
  z-index: -1;
  visibility: hidden;

  @media print {
    z-index: 0;
    visibility: visible;
  }
`;

const Month = styled.div`
  ${fonts.Headline7}
  margin: 0px 0px 4px 4px;
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const Title = styled.div`
  ${fonts.Headline3}
  margin-bottom: 52px;
`;

const Logo = styled.img`
  position: absolute;
  top: 30px;
  right: 0px;
  width: 190px;
  height: 32px;
`;

const Divider = styled.hr`
  border: 2px solid ${({ theme: { colors } }) => colors['border-gray-darker']};
  margin-bottom: 48px;
`;
