import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Page = styled(Box)`
  width: 100%;
  overflow: auto;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
`;

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  max-width: 1320px;
  display: flex;
  padding: 86px 20px 0;
  margin: 0 auto;
`;
