import AutocompleteFilter from '@components/filters/AutocompleteFilter';
import styled from '@emotion/styled';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';

export const FieldName = styled(Typography)`
  height: 16px;
  width: 128px;
  left: 24px;
  top: 16px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AutoSelect = styled(AutocompleteFilter)`
  height: 32px;
  border-radius: 8px;
  margin: 16px 0 16px 0;
`;

export const FilterChip = styled(Chip)`
  margin: 0 4px 0 4px;
  height: 32px;
  border-radius: 8px;
  border-color: #e9e9ed;
  padding: 6px 8px 6px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #4676fb;
  .MuiChip-label {
    padding: 0 8px 0 8px;
  }
`;

export const SearchTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
    width: ${props => (props.focused ? `240px` : `100px`)};
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 0px;
  }
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
    }
  }
  .MuiInputBase-input {
    ::placeholder {
      color: ${props => (props.focused ? `#878795` : `#3A3A49`)};
      opacity: 1;
    }
  }
`;

export const FilterButton = styled(Button)`
  width: 100px;
  color: #3a3a49;
`;

export const FilterDialog = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    overflow-y: inherit;
  }
  .MuiDialog-paper {
    border-radius: 16px;
  }
`;

export const FilterDialogTitle = styled(DialogTitle)`
  height: 60px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`;

export const FilterDialogContent = styled(DialogContent)`
  padding: 16px 24px 0 24px;
`;

export const FilterDialogActions = styled(DialogActions)`
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
`;

export const ClearButton = styled(Button)`
  color: #5c5c6b;
`;
