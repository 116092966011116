import { useState, useCallback, useEffect } from 'react';
import { Divider, Box, Button } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import softwareStore from '@stores/SoftwareStore';
import { RefreshMono } from '@images/SvgComponents';
import type { Software } from '@repositories/softwareRepository';
import type { TenantList } from '@repositories/tenantRepository';
import { useGetTenantList } from '@queryHooks/useTenant';
import { useGetUserList } from '@queryHooks/useUser';
import { StatisticSearchFilter } from '@type/Statistics';
import * as S from '../Filter/FilterStyles';

export const StatisticFilter = observer(() => {
  const {
    uiStore: { statisticFilterUiStore },
  } = useStore();
  const softwareFilterOptions = softwareStore.softwares;
  const [selectedFilter, setSelectedFilter] = useState<StatisticSearchFilter>({});

  const { data: pagedUserList } = useGetUserList({}, { enabled: statisticFilterUiStore.visible });
  const { data: pagedTenants } = useGetTenantList({ isRootTenant: true }, { enabled: statisticFilterUiStore.visible });
  const tenantFilterOptions = pagedTenants?.content ?? [];

  useEffect(() => {
    if (statisticFilterUiStore.visible) {
      setSelectedFilter({ ...statisticFilterUiStore.filter });
    }
  }, [statisticFilterUiStore.filter, statisticFilterUiStore.visible]);

  const handleChangeApplication = useCallback(
    (software: Software) => {
      setSelectedFilter({ ...selectedFilter, software });
    },
    [selectedFilter],
  );

  const handleChangeTenant = useCallback(
    (tenant: TenantList) => {
      setSelectedFilter({ ...selectedFilter, tenant });
    },
    [selectedFilter],
  );

  const handleClickClear = useCallback(() => {
    if (statisticFilterUiStore.onSelect) {
      statisticFilterUiStore.onSelect({});
    }
    statisticFilterUiStore.close();
  }, [statisticFilterUiStore]);

  const handleClickSearch = useCallback(() => {
    if (statisticFilterUiStore.onSelect) {
      statisticFilterUiStore.onSelect(selectedFilter);
    }
    statisticFilterUiStore.close();
  }, [selectedFilter, statisticFilterUiStore]);

  return (
    <S.FilterDialog open={statisticFilterUiStore.visible} onClose={statisticFilterUiStore.close}>
      <S.FilterDialogTitle> Filter </S.FilterDialogTitle>
      <Divider />
      <S.FilterDialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '440px' }}>
          <S.FieldName>Tenant</S.FieldName>
          <S.AutoSelect
            placeholder="All tenant"
            value={selectedFilter.tenant}
            options={tenantFilterOptions}
            getOptionLabel={option => `${option?.name} (${option.tenantCode})`}
            onChange={handleChangeTenant}
          />
          <S.FieldName>Software</S.FieldName>
          <S.AutoSelect
            placeholder="All software"
            value={selectedFilter.software}
            options={softwareFilterOptions}
            getOptionLabel={option => option.name}
            onChange={handleChangeApplication}
          />
          <S.FieldName>Sales Department</S.FieldName>
          <S.AutoSelect placeholder="All departments" options={['영업 1팀', '영업 2팀', '영업 3팀']} disabled />
          <S.FieldName>Contract Owner</S.FieldName>

          <S.AutoSelect
            placeholder="Search by name or email, comma seperated"
            options={pagedUserList?.content ?? []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={user => {
              if (user.name) return `${user.name}`;
              return '';
            }}
            value={statisticFilterUiStore.contractUser?.name}
            onChange={() => console.log('aaa')}
            // renderInput={params => <TextField {...params} label="Contract Owner" />}
            disabled
          />
        </Box>
      </S.FilterDialogContent>
      <S.FilterDialogActions>
        <S.ClearButton startIcon={<RefreshMono />} onClick={handleClickClear}>
          Clear all
        </S.ClearButton>
        <Button variant="contained" onClick={handleClickSearch}>
          Search
        </Button>
      </S.FilterDialogActions>
    </S.FilterDialog>
  );
});
