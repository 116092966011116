import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetUsageMetricList } from '@queryHooks/useSoftware';
import { useGetSubscriptionMonthlyUsage } from '@queryHooks/useSubscription';
import { numberTickFormatter } from '@utils/ChartUtil';
import { numberFormat } from '@utils/numberFormat';
import * as S from '../SummaryStyles';
import { SkeletonBarChart } from './SkeletonChart';

const { getStringSize } = require('recharts/lib/util/DOMUtils');

type UsageChartData = { month: string; usage: number; isMeasured: boolean };

type Props = {
  monthFrom: DateTimeString;
  monthTo: DateTimeString;
};
export const Usage = ({ monthFrom, monthTo }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();
  const [selectedUsageMetricCode, setSelectedUsageMetricCode] = useState('');

  const {
    data: { content: monthlyUsages },
    isLoading,
  } = useGetSubscriptionMonthlyUsage(
    subscription.id,
    { status: 'CREATED', monthFrom, monthTo },
    { enabled: !!subscription.id },
  );
  const hasTotalUsage = monthlyUsages?.length > 0 && !!monthlyUsages[0].totalUsage;

  const { data: usageMetrics } = useGetUsageMetricList(subscription.software.id);
  useEffect(() => {
    if (usageMetrics && usageMetrics.length > 0) {
      setSelectedUsageMetricCode(hasTotalUsage ? 'TOTAL_USAGE' : usageMetrics[0].usageMetricCode);
    }
  }, [hasTotalUsage, usageMetrics]);

  const barChartData: UsageChartData[] = monthlyUsages.map(monthlyUsage => {
    const moduleUsage = monthlyUsage.getUsageOfModule(selectedUsageMetricCode);
    return {
      month: dayjs(monthlyUsage.month).format(t('DateFormat_YM')),
      usage: moduleUsage ?? 0,
      isMeasured: moduleUsage !== undefined,
    };
  });

  const yAxisTickFormatter = numberTickFormatter(barChartData.map(({ usage }) => usage));

  const yAxisTickStyle = { ...fonts.Caption2, color: colors['text-gray-light'] };
  const maxValue = Math.max(...barChartData.map(({ usage }) => usage));
  const yAxisWidth = getStringSize(yAxisTickFormatter(maxValue), yAxisTickStyle).width + 15; // tickMargin 고려 + 넉넉히 2 더해줌

  const handleUsageMetricChange = (event: SelectChangeEvent<string>) => {
    setSelectedUsageMetricCode(event.target.value);
  };

  return (
    <S.BarChartContainer>
      <S.ChartHeader>
        <S.HeaderText $hasData={monthlyUsages.length > 0}>{t('Acc_Detail_14')}</S.HeaderText>
        <Select
          size="small"
          sx={{ minWidth: '110px' }}
          value={selectedUsageMetricCode}
          onChange={handleUsageMetricChange}
          MenuProps={{
            PaperProps: {
              sx: {
                maxWidth: '110px',
                width: '100%',
                marginTop: '5px',
              },
            },
          }}
        >
          {hasTotalUsage ? <MenuItem value="TOTAL_USAGE">{t('Usage_Total_Amount')}</MenuItem> : null}
          {usageMetrics?.map(({ usageMetricCode, usageMetricName }) => (
            <MenuItem key={usageMetricCode} value={usageMetricCode}>
              {usageMetricName}
            </MenuItem>
          ))}
        </Select>
      </S.ChartHeader>
      {isLoading ? (
        <Loader />
      ) : monthlyUsages.length === 0 ? (
        <SkeletonBarChart monthFrom={monthFrom} monthTo={monthTo} />
      ) : (
        <ResponsiveContainer width="100%" height={265}>
          <BarChart data={barChartData}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="month" stroke="1" tick={{ ...fonts.Caption2, color: colors['text-gray-light'] }} />
            <YAxis stroke="1" width={yAxisWidth} tick={yAxisTickStyle} tickFormatter={yAxisTickFormatter} />
            <Tooltip cursor={{ fill: colors['state-white-hover'] }} content={<UsageTooltip />} />
            <Bar
              dataKey="usage"
              fill={colors['graph-orange']}
              barSize={10}
              background={{ fill: colors['graph-gray-lighter'] }}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </S.BarChartContainer>
  );
};

const UsageTooltip = ({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: { value: number; payload: UsageChartData }[];
  label?: string;
}) => {
  const { t } = useTranslation();
  return active && payload && payload.length > 0 ? (
    <S.TooltipContainer>
      <p>{label}</p>
      <dl>
        <dt>{t('Acc_Detail_14')}</dt>
        <dd>{payload[0].payload.isMeasured ? numberFormat({ num: payload[0].value, maxFractionDigit: 2 }) : '-'} </dd>
      </dl>
    </S.TooltipContainer>
  ) : null;
};
