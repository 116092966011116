const defaultWidth = 16;
const defaultHeight = 16;

export const CheckIcon = ({ width = defaultWidth, height = defaultHeight, color = '#D1D5DA' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        d="M12.3251 4.26892C12.5945 3.99955 13.0312 3.99955 13.3005 4.26892C13.5699 4.53828 13.5699 4.975 13.3005 5.24436L6.81336 11.7315C6.68213 11.8628 6.50355 11.9356 6.31797 11.9335C6.13239 11.9315 5.95548 11.8547 5.8272 11.7206L2.72463 8.47699C2.46132 8.20171 2.47102 7.7651 2.7463 7.50179C3.02158 7.23848 3.4582 7.24818 3.72151 7.52346L6.33659 10.2574L12.3251 4.26892Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3949 4.17418C13.7163 4.49561 13.7163 5.01675 13.3949 5.33819L6.90767 11.8254C6.75107 11.982 6.53797 12.0688 6.31652 12.0664C6.09507 12.0639 5.88396 11.9723 5.73088 11.8123L2.62831 8.5687C2.3141 8.24021 2.32568 7.71919 2.65417 7.40498C2.98267 7.09077 3.50368 7.10235 3.81789 7.43084L6.33874 10.0663L12.2308 4.17418C12.5523 3.85275 13.0734 3.85275 13.3949 4.17418Z"
        fill={color}
      />
    </g>
  </svg>
);

CheckIcon.displayName = 'CheckIcon';
