import type { SubscriptionDetailOutletContext } from '../SubscriptionDetail';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { OptimizableSubscriptionDetail } from '@pages/customer/manager/billing/analyze/costAnalyze/optimizableCost/OptimizableSubscription';
import { useUiStore } from '@stores/UiStore';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { ImportantFillIcon } from '@icons/ImportantFillIcon';
import { ResetIcon } from '@icons/ResetIcon';
import { TriangleIcon } from '@icons/TriangleIcon';
import { UpdatingIcon } from '@icons/UpdatingIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useOptimizeMonthlyBilling } from '@queryHooks/useBillingOptimization';
import { useGetSubscriptionBillingStatBySubscription } from '@queryHooks/useSubscriptionBillingStat';
import { dateTimeFormat } from '@utils/dateUtil';

export const AIAnalyze = () => {
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();
  const { toastStore } = useUiStore();

  const {
    billing: { isAIAnalyzeOpen, setAIAnalyzeOpen },
  } = useOutletContext<SubscriptionDetailOutletContext>();

  const { data, isFetching } = useGetSubscriptionBillingStatBySubscription({
    subscriptionId: subscription.id,
  });
  const { mutate: optimizeMonthlyBilling, isLoading: isOptimizeLoading } = useOptimizeMonthlyBilling();

  const handleToggleOpen = () => {
    setAIAnalyzeOpen(prev => !prev);
  };

  const handleRefresh = () => {
    const nextYearMonth = dayjs().add(1, 'year').format('YYYY-MM');
    optimizeMonthlyBilling(
      {
        doBillingForecast: true,
        doUsageForecast: true,
        doUsageOptimization: true,
        monthTo: nextYearMonth,
        subscriptionId: subscription.id,
      },
      {
        onSuccess: () => {
          toastStore.open('업데이트를 완료했습니다.');
        },
      },
    );
  };

  return data && data.length > 0 ? (
    <Container $isOpen={isAIAnalyzeOpen}>
      <Header role="button" onClick={handleToggleOpen}>
        <ImportantFillIcon width={20} height={20} color={colorDefinition.yellow[600]} style={{ marginRight: '4px' }} />
        <HeaderText
          style={{ opacity: isAIAnalyzeOpen ? 1 : 0, transform: isAIAnalyzeOpen ? undefined : 'translate(0px, -50%)' }}
        >
          AI 비용 진단
        </HeaderText>
        <HeaderText
          style={{ opacity: isAIAnalyzeOpen ? 0 : 1, transform: isAIAnalyzeOpen ? 'translate(0px, -50%)' : undefined }}
        >
          줄일 수 있는 비용이 있습니다. AI 비용 진단 내용을 확인해보세요.
        </HeaderText>
        <TriangleIcon width={20} height={20} rotateNum={isAIAnalyzeOpen ? 0 : 180} style={{ marginLeft: 'auto' }} />
      </Header>
      <Content $isOpen={isAIAnalyzeOpen}>
        {isFetching || isOptimizeLoading ? (
          <UpdateLoading>
            <UpdatingIcon width={100} height={100} />
            AI 비용 진단 내용을 업데이트 중입니다.
          </UpdateLoading>
        ) : (
          <>
            <Refresh onClick={handleRefresh}>
              {dateTimeFormat(data[0].updatedDate, '-')}
              <ResetIcon width={14} height={14} color={colors['ic-gray-dark']} />
            </Refresh>
            <OptimizableSubscriptionDetail model={data[0]} />
          </>
        )}
      </Content>
    </Container>
  ) : null;
};

const Container = styled.div<{ $isOpen: boolean }>`
  margin: 16px 18px 18px;
  height: ${({ $isOpen }) => ($isOpen ? '290px' : '52px')};
  overflow: hidden;
  transition: height 250ms ease;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  box-shadow: 0px 1px 6px 0px #0000000f;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  cursor: pointer;
  background-color: ${({ theme: { colors } }) => colors['bg-blue-lighter']};
  color: ${({ theme: { colors } }) => colors['text-blue']};
  ${fonts.Headline8};
  position: relative;
`;

const HeaderText = styled.p`
  position: absolute;
  left: 40px;
  transition: opacity 250ms ease, transform 250ms ease;
`;

const Content = styled.div<{ $isOpen: boolean }>`
  border-radius: 0px 0px 5px 5px;
  position: relative;
  height: calc(100% - 51px);
`;

const Refresh = styled.button`
  position: absolute;
  top: 13px;
  right: 15px;
  display: flex;
  align-items: center;
  gap: 4px;
  ${fonts.Body4};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 20px;
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  padding: 2px 9px 2px 11px;
  cursor: pointer;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
    & svg path {
      fill: ${({ theme: { colors } }) => colors['ic-purple']};
    }
  }
`;

const UpdateLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 12px;
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};

  animation: fade-in 300ms;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
