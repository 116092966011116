import { Page } from '@type/Page';
import API from '@repositories/Axios';
import { injectable } from 'inversify';
import qs from 'qs';
import type ITenantRequestRepository from './ITenantRequestRepository';
import type {
  TenantRequestCreateDTO,
  TenantRequestListDTO,
  TenantRequestFilter,
  TenantRequestDTO,
  TenantRequestPatchProcessDTO,
} from './Types';
import 'reflect-metadata';

@injectable()
export class TenantRequestRepository implements ITenantRequestRepository {
  async create(data: TenantRequestCreateDTO): Promise<TenantRequestDTO> {
    const result = await API.post<TenantRequestDTO>(`/api/v1/tenant-requests`, data);
    return result.data;
  }

  async getList(queries?: TenantRequestFilter): Promise<Page<TenantRequestListDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<TenantRequestListDTO>>(`/api/v1/tenant-requests?${queryString}`);
    return result.data;
  }

  async getOne(id: string): Promise<TenantRequestDTO> {
    const result = await API.get<TenantRequestDTO>(`/api/v1/tenant-requests/${id}`);
    return result.data;
  }

  async patchProcess(id: string, data: TenantRequestPatchProcessDTO): Promise<TenantRequestPatchProcessDTO> {
    const result = await API.patch<TenantRequestPatchProcessDTO>(`/api/v1/tenant-requests/${id}/process`, data);
    return result.data;
  }

  update(/* id: string, data: unknown */): Promise<TenantRequestDTO> {
    throw new Error('Method not implemented.');
  }
  delete(/* id: string */): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
