import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Dialog } from '@components/Dialog';
import { Loader } from '@components/index';
import { useStore } from '@stores/RootStore';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useGetSubscription } from '@queryHooks/useSubscription';
import { SubscriptionEditDialogBody } from './body/SubscriptionEditDialogBody';

type Props = {
  subscriptionId: string;
  softwareId: string;
  open: boolean;
  onClose: () => void;
};

export const SubscriptionEditDialog = ({ subscriptionId, softwareId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { toastStore },
  } = useStore();
  const [step, setStep] = useState<'BASIC' | 'ADDITIONAL'>('BASIC');

  const {
    data: subscription,
    isLoading,
    isSuccess,
  } = useGetSubscription(subscriptionId, {
    enabled: open,
  });

  useEffect(() => {
    if (open) {
      setStep('BASIC');
    }
  }, [open]);

  const handleSubmitted = () => {
    toastStore.open({ message: t('Subscrib_Edit_10'), hideAction: true });
    onClose();
  };

  const handleStepBack = () => {
    setStep('BASIC');
  };

  return (
    <Dialog
      title={
        step === 'BASIC' ? (
          t('Subscrib_Main_22')
        ) : (
          <TitleWithGoBack title={t('Subscrib_Main_22')} onClick={handleStepBack} />
        )
      }
      size="large"
      open={open}
      onClose={onClose}
      height={992}
      useCustomContent
    >
      {isLoading && <Loader left={45} top={55} />}
      {isSuccess && (
        <SubscriptionEditDialogBody
          subscription={subscription}
          softwareInfo={{
            id: softwareId,
            iconUrl: subscription.software.iconUrl ?? '',
            name: subscription.software.name,
          }}
          onSubmitted={handleSubmitted}
          onClose={onClose}
          step={step}
          onNextStep={() => setStep('ADDITIONAL')}
        />
      )}
    </Dialog>
  );
};

type TitleProps = {
  title: string;
  onClick: () => void;
};
const TitleWithGoBack = ({ title, onClick }: TitleProps) => {
  return (
    <TitleContainer>
      <IconButton onClick={onClick}>
        <ChevronIcon rotateNum={270} />
      </IconButton>
      {title}
    </TitleContainer>
  );
};
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
