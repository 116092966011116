import * as Yup from 'yup';
import { UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';

export type PasswordChangeFormType = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email(i18next.t('Member_Manager_17')).required(i18next.t('Member_Group_Delete_05')),
});

export const PasswordChangeFormOptions: UseFormProps<PasswordChangeFormType> = {
  resolver: yupResolver(validationSchema),
  mode: 'onChange',
  shouldFocusError: true,
};
