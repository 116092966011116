import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import SearchTextField from '@components/inputs/SearchTextField';
import { fonts } from '@theme/fontsCustomer';
import {
  TotalSubscriptionUserCountFilter,
  TotalSubscriptionUserListFilter,
} from '@repositories/subscriptionUserRepository/Types';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useTotalSubscriptionUser } from '@queryHooks/useSubscriptionUser';
import { TotalSubscriptionUserListModel } from '@models/subscription/TotalSubscriptionUserListModel';
import { SubscriptionFilterCard } from './SubscriptionFilterCard';
import { SubscriptionFilterSelect } from './SubscriptionFilterSelect';
import { SubscriptionUserTable } from './SubscriptionUserTable';

export type ScrollPackage = {
  handleEndReached: () => void;
  hasNextPage: boolean | undefined;
};

export type SubscriptionUserFilterPackage = {
  queries: TotalSubscriptionUserListFilter;
  setQueries: Dispatch<SetStateAction<TotalSubscriptionUserListFilter>>;
};

export type UserCountFilterPackage = {
  userCountKey: TotalSubscriptionUserCountFilter;
  setUserCountKey: Dispatch<SetStateAction<TotalSubscriptionUserCountFilter>>;
};

export type SubscriptionFilterType = {
  subscriptionFilterPackage: SubscriptionUserFilterPackage;
  userCountFilterPackage: UserCountFilterPackage;
};

export type SubscriptionUserTableType = {
  subscriptionUserLists: TotalSubscriptionUserListModel[];
  scrollPackage: ScrollPackage;
  subscriptionUserFilterPackage: SubscriptionUserFilterPackage;
};

export const SubscriptionUserMgmtList = () => {
  const [queries, setQueries] = useSearchParamsFilter<TotalSubscriptionUserListFilter>({
    defaultFilter: {
      page: 0,
      size: 100,
      sort: ['softwareName.asc', 'subscriptionName.asc', 'userName.asc'],
      softwareId: '',
      subscriptionId: '',
      isAbnormal: undefined,
      memberStatus: undefined,
    },
    whiteList: ['sort', 'softwareId', 'subscriptionId', 'isAbnormal', 'memberStatus', 'keyword'],
  });

  const [userCountKey, setUserCountKey] = useState<TotalSubscriptionUserCountFilter>({
    isAbnormal: undefined,
    softwareId: queries.softwareId,
    subscriptionId: queries.subscriptionId,
    memberStatus: queries.memberStatus,
  });

  const {
    data: subscriptionUserList,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useTotalSubscriptionUser({ ...queries });

  const subscriptionUserLists: TotalSubscriptionUserListModel[] =
    subscriptionUserList?.pages.flatMap(({ content }) => content) ?? [];

  const handleEndReached = () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const handleSearch = useCallback(
    (value: string) => {
      setQueries(prevFilter => ({ ...prevFilter, keyword: value }));
    },
    [setQueries],
  );

  return (
    <Container>
      <Title>사용자 관리</Title>
      <BodyWrapper>
        <SubscriptionFilterSelect
          subscriptionFilterPackage={{ queries, setQueries }}
          userCountFilterPackage={{ userCountKey, setUserCountKey }}
        />
        <TableWrapper>
          <SubscriptionFilterCard
            subscriptionFilterPackage={{ queries, setQueries }}
            userCountFilterPackage={{ userCountKey, setUserCountKey }}
          />
          <TableHeaderWrapper>
            <TableHeaderTotalWrapper>
              전체
              <TotalCount className="count"> {subscriptionUserList?.pages[0].totalElements}</TotalCount>
            </TableHeaderTotalWrapper>
            <SearchTextField
              placeholder="이름 또는 메일 계정 검색"
              onChange={handleSearch}
              value={queries.keyword}
              debounce={500}
            />
          </TableHeaderWrapper>
          <SubscriptionUserTable
            subscriptionUserLists={subscriptionUserLists}
            scrollPackage={{ handleEndReached, hasNextPage }}
            subscriptionUserFilterPackage={{ queries, setQueries }}
          />
        </TableWrapper>
      </BodyWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  padding: 28px 32px;
  overflow: auto;
`;

const Title = styled.div`
  padding: 0px 8px;
  ${fonts.Headline5};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 7px;
  width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 28px 28px;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 44px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TableHeaderTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-main']};
  & .count {
    color: ${props => props.theme.colors['text-purple-light']};
  }
`;

const TotalCount = styled.span``;
