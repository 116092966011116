import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Loader } from '@components/loader';
import { useStore } from '@stores/RootStore';
import { useGetWorkflow } from '@queryHooks/useWorkflow';
import { WorkflowDetailHeader } from './WorkflowDetailHeader';
import { ActionConfig } from './action/ActionConfig';
import { ActionSelect } from './action/ActionSelect';
import { WorkflowViewer } from './graph/WorkflowViewer';
import { OptionBar } from './info/OptionBar';
import { TriggerConfig } from './trigger/TriggerConfig';
import { TriggerSelect } from './trigger/TriggerSelect';

export const WorkflowEditPage = observer(() => {
  const { workflowDetailStore } = useStore();
  const { workflowId } = useParams();
  const [isToolbarOpen, setIsToolbarOpen] = useState<boolean>(false);

  const { data: workflow, isSuccess } = useGetWorkflow(workflowId ?? '');

  useLayoutEffect(() => {
    workflowDetailStore.setIsEdit(true);
    workflowDetailStore.setToolbarView('triggerSelect');
  }, [workflowDetailStore]);

  useEffect(() => {
    setIsToolbarOpen(!!workflowDetailStore.toolbar);
  }, [workflowDetailStore.toolbar]);

  if (!isSuccess) return <Loader />;

  return (
    <Container>
      <Header>
        <WorkflowDetailHeader />
      </Header>
      <Body>
        <WorkflowViewer workflow={workflow} />
        <OptionBar open={isToolbarOpen}>
          {workflowDetailStore.toolbar === 'triggerSelect' && <TriggerSelect />}
          {workflowDetailStore.toolbar === 'triggerConfig' && <TriggerConfig />}
          {workflowDetailStore.toolbar === 'actionSelect' && <ActionSelect />}
          {workflowDetailStore.toolbar === 'actionConfig' && <ActionConfig />}
        </OptionBar>
      </Body>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 23px 32px 0px;
  gap: 13px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 4px;
  width: 100%;
  height: calc(100vh - 136px);
  box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;
`;
