import type { IMonthlyBillingRepository } from './IMonthlyBillingRepository';
import type {
  MonthlyBillingCreateDTO,
  MonthlyBillingUpdateDTO,
  StatusUpdateRq,
  MonthlyBillingDTO,
  MonthlyBillingProduceDTO,
  TotalBillingDTO,
  TotalBillingFilter,
} from './Types';
import type { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import type { FileSimpleDTO } from '@repositories/fileRepository/Types';
import type { Filter } from '@repositories/monthlyBillingRepository';
import type { Page } from '@type/Page';

@injectable()
export class MonthlyBillingRepository implements IMonthlyBillingRepository {
  async getList(queries?: Filter): Promise<Page<MonthlyBillingDTO>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat', skipNulls: true });
    const result = await API.get<Page<MonthlyBillingDTO>>(`/api/v1/monthly-billings?${queryString}`);
    return result.data;
  }

  async getOne(id: string): Promise<MonthlyBillingDTO> {
    const result = await API.get<MonthlyBillingDTO>(`/api/v1/monthly-billings/${id}`);
    return result.data;
  }

  async getSubscriptionOne(subscriptionId: SubscriptionId, month: string): Promise<MonthlyBillingDTO> {
    const result = await API.get<MonthlyBillingDTO>(
      `/api/v1/subscriptions/${subscriptionId}/monthly-billings/${month}`,
    );
    return result.data;
  }

  async getTotalBilling(queries?: TotalBillingFilter): Promise<TotalBillingDTO> {
    const queryString = qs.stringify(queries, { skipNulls: true });
    const result = await API.get<TotalBillingDTO>(`/api/v1/total-monthly-billings?${queryString}`);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    await API.delete<void>(`/api/v1/monthly-billings/${id}`);
  }

  async update(id: string, data: MonthlyBillingUpdateDTO): Promise<MonthlyBillingDTO> {
    const result = await API.put<MonthlyBillingDTO>(`/api/v1/monthly-billings/${id}`, data);
    return result.data;
  }

  async updateStatus(id: string, data: StatusUpdateRq): Promise<MonthlyBillingDTO> {
    const result = await API.put<MonthlyBillingDTO>(`/api/v1/monthly-billings/${id}/status`, data);
    return result.data;
  }

  async upgradeVersion(id: string): Promise<MonthlyBillingDTO> {
    const result = await API.post<MonthlyBillingDTO>(`/api/v1/monthly-billings/${id}/versions`);
    return result.data;
  }

  async recalculateCost(id: string): Promise<MonthlyBillingDTO> {
    const result = await API.post<MonthlyBillingDTO>(`/api/v1/monthly-billings/${id}/recalculate-cost`);
    return result.data;
  }

  async recalculateOriginalCost(id: string): Promise<MonthlyBillingDTO> {
    const result = await API.post<MonthlyBillingDTO>(`/api/v1/monthly-billings/${id}/recalculate-original-cost`);
    return result.data;
  }

  async generate(rqData: MonthlyBillingProduceDTO): Promise<MonthlyBillingDTO> {
    const result = await API.post<MonthlyBillingDTO>('/api/v1/actions/generate-monthly-billings', rqData);
    return result.data;
  }

  async create(data: MonthlyBillingCreateDTO): Promise<MonthlyBillingDTO> {
    const result = await API.post<MonthlyBillingDTO>(`/api/v1/monthly-billings`, data);
    return result.data;
  }

  async fileUpload(billingId: string, files: File[], config?: AxiosRequestConfig) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    const result = await API.post<FileSimpleDTO[]>(`/api/v1/monthly-billings/${billingId}/files`, formData, config);
    return result.data;
  }

  async fileDelete(billingId: string, fileId: string): Promise<void> {
    await API.delete(`/api/v1/monthly-billings/${billingId}/files/${fileId}`);
  }
}
