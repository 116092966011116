const defaultWidth = 16;
const defaultHeight = 16;

export const SubtractIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99987 0.533203C3.87614 0.533203 0.533203 3.87614 0.533203 7.99987C0.533203 12.1236 3.87614 15.4665 7.99987 15.4665C12.1236 15.4665 15.4665 12.1236 15.4665 7.99987C15.4665 3.87614 12.1236 0.533203 7.99987 0.533203ZM8.68804 4.61105C8.68804 4.23672 8.38459 3.93327 8.01026 3.93327C7.63594 3.93327 7.33248 4.23672 7.33248 4.61105V8.19928C7.33248 8.57361 7.63594 8.87706 8.01026 8.87706C8.38459 8.87706 8.68804 8.57361 8.68804 8.19928V4.61105ZM8.01026 12.0666C8.49468 12.0666 8.88739 11.6739 8.88739 11.1895C8.88739 10.7051 8.49468 10.3124 8.01026 10.3124C7.52584 10.3124 7.13314 10.7051 7.13314 11.1895C7.13314 11.6739 7.52584 12.0666 8.01026 12.0666Z"
        fill={color}
      />
    </g>
  </svg>
);
