import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { UseInfiniteQueryOptions, UseQueryOptions } from '@tanstack/react-query';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type {
  IMonthlyBillingRepository,
  Filter,
  StatusUpdateRq,
  MonthlyBillingProduceDTO,
  MonthlyBillingUpdateDTO,
  TotalBillingFilter,
  MonthlyBillingCreateDTO,
} from '@repositories/monthlyBillingRepository';
import type { Page } from '@type/Page';
import { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import { TotalBillingModel } from '@models/TotalBillingModel';

const monthlyBillingRepo = repository.get<IMonthlyBillingRepository>(KEYS.MONTHLY_BILLING_REPOSITORY);

const monthlyBillingKeys = {
  all: ['monthlyBilling'] as const,
  lists: () => [...monthlyBillingKeys.all, 'list'] as const,
  list: (queries: Filter) => [...monthlyBillingKeys.lists(), { ...queries }] as const,
  listInfinite: (queries: Filter) => [...monthlyBillingKeys.list(queries), 'infinite'] as const,
  details: () => [...monthlyBillingKeys.all, 'detail'] as const,
  detail: (billingId: string) => [...monthlyBillingKeys.details(), billingId] as const,
  detailBySubscription: (subscriptionId: SubscriptionId, month: string) =>
    [...monthlyBillingKeys.details(), subscriptionId, month] as const,
  totalBilling: (queries?: TotalBillingFilter) =>
    [...monthlyBillingKeys.lists(), 'totalBilling', { ...queries }] as const,
};

export const useGetMonthlyBillingList = (queries: Filter, options?: UseQueryOptions<Page<SubscriptionBillingModel>>) =>
  useQuery({
    queryKey: monthlyBillingKeys.list(queries),
    queryFn: async () => {
      const result = await monthlyBillingRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionBillingModel(dto)),
      };
    },
    ...options,
  });

export const useGetMonthlyBillingListInfinite = (
  queries: Filter,
  options?: UseInfiniteQueryOptions<Page<SubscriptionBillingModel>>,
) =>
  useInfiniteQuery({
    queryKey: monthlyBillingKeys.listInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await monthlyBillingRepo.getList({ ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionBillingModel(dto)),
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetMonthlyBilling = (id: string, options?: UseQueryOptionsType<SubscriptionBillingModel>) =>
  useQuery<SubscriptionBillingModel, AxiosError>({
    queryKey: monthlyBillingKeys.detail(id),
    queryFn: async () => {
      const result = await monthlyBillingRepo.getOne(id);
      return new SubscriptionBillingModel(result);
    },
    cacheTime: 50000,
    staleTime: 30000,
    ...options,
    enabled: !!id && (options?.enabled !== undefined ? options.enabled : true),
  });

export const useGetMonthlyBillingDetails = (id: string[], options?: UseQueryOptionsType<SubscriptionBillingModel>) =>
  useQueries({
    queries: id.map(billingId => ({
      queryKey: monthlyBillingKeys.detail(billingId),
      queryFn: async () => {
        const result = await monthlyBillingRepo.getOne(billingId);
        return new SubscriptionBillingModel(result);
      },
      cacheTime: 50000,
      staleTime: 30000,
      ...options,
      enabled: !!id && (options?.enabled !== undefined ? options.enabled : true),
    })),
  });

export const useGetSubscriptionMonthlyBilling = (
  subscriptionId: SubscriptionId,
  month: string,
  options?: UseQueryOptionsType<SubscriptionBillingModel>,
) =>
  useQuery<SubscriptionBillingModel, AxiosError>(
    monthlyBillingKeys.detailBySubscription(subscriptionId, month),
    async () => {
      const result = await monthlyBillingRepo.getSubscriptionOne(subscriptionId, month);
      return new SubscriptionBillingModel(result);
    },
    {
      ...options,
      cacheTime: 50000,
      staleTime: 30000,
    },
  );

export const useGetTotalBilling = (queries?: TotalBillingFilter, options?: UseQueryOptionsType<TotalBillingModel>) =>
  useQuery({
    queryKey: monthlyBillingKeys.totalBilling(queries),
    queryFn: async () => {
      const result = await monthlyBillingRepo.getTotalBilling(queries);
      return new TotalBillingModel(result);
    },
    ...options,
  });

export const useUpdateMonthlyBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ billingId, rqData }: { billingId: string; rqData: MonthlyBillingUpdateDTO }) => {
      await monthlyBillingRepo.update(billingId, rqData);
      return billingId;
    },
    onSuccess: billingId => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.invalidateQueries(monthlyBillingKeys.detail(billingId));
    },
  });
};

export const useUpdateMonthlyBillingStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: StatusUpdateRq) => {
      const result = await monthlyBillingRepo.updateStatus(rqData.id, rqData);
      return result;
    },
    onSuccess: result => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.invalidateQueries(monthlyBillingKeys.detail(result.id));
    },
  });
};

export const useUpdateMonthlyBillingVersionUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const result = await monthlyBillingRepo.upgradeVersion(id);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.invalidateQueries(monthlyBillingKeys.details());
    },
  });
};

export const useRecalculateMonthlyBillingCost = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (billingId: string) => {
      const result = await monthlyBillingRepo.recalculateCost(billingId);
      return result;
    },
    onSuccess: result => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.invalidateQueries(monthlyBillingKeys.detail(result.id));
    },
  });
};

export const useRecalculateMonthlyBillingOriginalCost = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (billingId: string) => {
      const result = await monthlyBillingRepo.recalculateOriginalCost(billingId);
      return result;
    },
    onSuccess: result => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.invalidateQueries(monthlyBillingKeys.detail(result.id));
    },
  });
};

export const useDeleteMonthlyBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (billingId: string) => {
      await monthlyBillingRepo.delete(billingId);
      return billingId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.removeQueries(monthlyBillingKeys.details());
    },
  });
};

export const useGenerateMonthlyBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: MonthlyBillingProduceDTO) => {
      const result = await monthlyBillingRepo.generate(rqData);
      return new SubscriptionBillingModel(result);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
      queryClient.invalidateQueries(monthlyBillingKeys.details());
    },
  });
};

export const useAddMonthlyBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: MonthlyBillingCreateDTO) => {
      const result = await monthlyBillingRepo.create(data);
      return new SubscriptionBillingModel(result);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(monthlyBillingKeys.lists());
    },
  });
};

export const useUploadMonthlyBillingAttachments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      billingId,
      files,
      config,
    }: {
      billingId: string;
      files: File[];
      config?: AxiosRequestConfig;
    }) => {
      await monthlyBillingRepo.fileUpload(billingId, files, config);
      return billingId;
    },
    onSuccess: billingId => {
      queryClient.invalidateQueries(monthlyBillingKeys.detail(billingId));
    },
  });
};

export const useDeleteMonthlyBillingAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ billingId, fileId }: { billingId: string; fileId: string }) => {
      await monthlyBillingRepo.fileDelete(billingId, fileId);
      return billingId;
    },
    onSuccess: billingId => {
      queryClient.invalidateQueries(monthlyBillingKeys.detail(billingId));
    },
  });
};
