export const EmptyIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill="#F5F0FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9.74982C17.3418 9.74982 16.8083 10.2833 16.8083 10.9415V20.4748C16.8083 21.133 17.3418 21.6665 18 21.6665C18.6581 21.6665 19.1916 21.133 19.1916 20.4748V10.9415C19.1916 10.2833 18.6581 9.74982 18 9.74982ZM18 26.2498C18.7594 26.2498 19.375 25.6342 19.375 24.8748C19.375 24.1154 18.7594 23.4998 18 23.4998C17.2406 23.4998 16.625 24.1154 16.625 24.8748C16.625 25.6342 17.2406 26.2498 18 26.2498Z"
        fill="#6F42C1"
      />
    </svg>
  );
};
