import API from '@repositories/Axios';
import { Page } from '@type/Page';
import qs from 'qs';
import { injectable } from 'inversify';
import { DailyFilters, DailyUsageDTO } from '../Types';
import IDailyUsageRepository from './IDailyUsageRepository';
import 'reflect-metadata';

@injectable()
export class DailyUsageRepository implements IDailyUsageRepository {
  async create(/* data: unknown */): Promise<DailyUsageDTO> {
    throw new Error('Method not implemented.');
  }
  async getList(queries?: DailyFilters): Promise<Page<DailyUsageDTO>> {
    const queryString = qs.stringify(queries);

    const result = await API.get<Page<DailyUsageDTO>>(`/api/v1/daily-usages?${queryString}`);
    return result.data;
  }
}
