import { useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs, { type Dayjs } from 'dayjs';
import { useUiStore } from '@stores/UiStore';
import { type MonthlyBillingCreateDTO } from '@repositories/monthlyBillingRepository';
import { useAddMonthlyBilling, useGetMonthlyBillingList, useUpdateMonthlyBilling } from '@queryHooks/useMonthlyBilling';
import { dateFormat } from '@utils/dateUtil';
import { type SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import { type SubscriptionModel } from '@models/SubscriptionModel';
import { DetailSettingStep } from '../../../AddExistingBillingModal';
import { BillingAttachFile } from '../BillingAttachFile';
import { type AddMonthlyBillingForm, useAddMonthlyBillingForm } from '../validateSchema';
import { BillingCreateForm } from './BillingCreateForm';

type Props = {
  step: DetailSettingStep;
  selectedMonth: Dayjs;
  subscription: SubscriptionModel;
  onClose: () => void;
  onNextStep: () => void;
};
export const BillingCreate = ({ step, selectedMonth, subscription, onClose, onNextStep }: Props) => {
  const { t } = useTranslation();
  const { alertStore, toastStore } = useUiStore();
  const [createdMonthlyBilling, setCreatedMonthlyBilling] = useState<SubscriptionBillingModel | null>(null);

  const methods = useAddMonthlyBillingForm();
  const { handleSubmit } = methods;

  const { data: monthlyBillings } = useGetMonthlyBillingList({
    monthFrom: selectedMonth.format('YYYY-MM'),
    monthTo: selectedMonth.format('YYYY-MM'),
    subscriptionId: subscription.id,
    includesForecasted: true,
  });
  const { mutate: addMonthlyBilling } = useAddMonthlyBilling();
  const { mutate: updateMonthlyBilling } = useUpdateMonthlyBilling();

  const handleSuccess: SubmitHandler<AddMonthlyBillingForm> = formData => {
    const existMonthlyBillings = monthlyBillings?.content.filter(
      ({ startDate, endDate }) =>
        dayjs(startDate).isSame(formData.startDate, 'D') && dayjs(endDate).isSame(formData.endDate, 'D'),
    );

    if (existMonthlyBillings && existMonthlyBillings.length === 1) {
      alertStore.open({
        title: t('Acc_Create_01'),
        message: t('Acc_Create_38'),
        useCancel: true,
        onConfirm: () => {
          const rqData = existMonthlyBillings[0].getUpdateDTO({
            cost: formData.cost,
            currencyUnit: formData.currencyUnit,
            exchangeRate: formData.exchangeRate ?? undefined,
            originalCost: formData.originalCost,
            paymentDate: dateFormat(formData.paymentDate, undefined, 'YYYY-MM-DD'),
          });
          updateMonthlyBilling(
            { billingId: existMonthlyBillings[0].id, rqData },
            {
              onSuccess: () => {
                onClose();
                toastStore.open({ message: t('Acc_Create_15'), hideAction: true });
              },
            },
          );
        },
      });
    } else if (!existMonthlyBillings || existMonthlyBillings.length === 0) {
      const data: MonthlyBillingCreateDTO = {
        cost: formData.cost,
        currencyUnit: formData.currencyUnit,
        endDate: dateFormat(formData.endDate, undefined, 'YYYY-MM-DD'),
        exchangeRate: formData.exchangeRate ?? undefined,
        month: dateFormat(selectedMonth, undefined, 'YYYY-MM'),
        originalCost: formData.originalCost,
        originalCurrencyUnit: subscription.currencyUnit,
        paymentDate: dateFormat(formData.paymentDate, undefined, 'YYYY-MM-DD'),
        startDate: dateFormat(formData.startDate, undefined, 'YYYY-MM-DD'),
        subscriptionId: subscription.id,
      };
      addMonthlyBilling(data, {
        onSuccess: created => {
          setCreatedMonthlyBilling(created);
          onNextStep();
          toastStore.open({ message: t('Acc_Create_15'), hideAction: true });
        },
      });
    } else {
      toastStore.open({ message: 'Unknown Error', hideAction: true });
      onClose();
    }
  };

  return (
    <FormProvider {...methods}>
      <CreateForm id="billing-form" onSubmit={handleSubmit(handleSuccess)}>
        {step === DetailSettingStep.CREATE && (
          <BillingCreateForm
            selectedMonth={selectedMonth}
            subscription={subscription}
            onClose={onClose}
            onNextStep={onNextStep}
          />
        )}
        {step === DetailSettingStep.CREATE_ATTACH_FILE && createdMonthlyBilling && (
          <BillingAttachFile addedMonthlyBilling={createdMonthlyBilling} onClose={onClose} />
        )}
      </CreateForm>
    </FormProvider>
  );
};

const CreateForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
