import i18n from '@locales/i18n';
import * as yup from 'yup';

export const subscriptionCreateSchema = yup
  .object({
    tenant: yup.object().nullable().required('* Tenant를 선택해주세요'),
    software: yup.object().nullable().required('* SW를 선택해주세요'),
    type: yup.string().required('* 구독 타입을 선택해주세요'),
    name: yup.string().required(`* ${i18n.t('GNB_Profile_MyProfile_14')}`),
    startDate: yup.string().required('* 구독 시작 날짜를 입력해주세요'),
    endDate: yup.string().nullable().notRequired(),
    contractConditions: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            moduleCode: yup.string().required('* 모듈 코드는 필수 입력값입니다'),
            usage: yup
              .number()
              .transform((_, ov) => (ov ? Number(ov) : undefined))
              .typeError('숫자 형식으로 입력해주세요')
              .required('* 약정 사용량을 입력해주세요'),
            price: yup
              .number()
              .transform((_, ov) => (ov ? Number(ov) : undefined))
              .typeError('숫자 형식으로 입력해주세요')
              .notRequired(),
            currencyUnit: yup.string().notRequired(),
          })
          .notRequired(),
      )
      .min(1, '* 하나 이상의 약정 사용량을 입력해주세요')
      .notRequired(),

    description: yup.string().notRequired(),
    parentSubscription: yup.object().nullable().notRequired(),
    timeZone: yup.string().nullable().notRequired(),
    currencyUnit: yup.string().required(),
    planId: yup
      .string()
      .notRequired()
      .when('type', {
        is: (type: string) => type !== 'SUB',
        then: yup.string().required('* 요금제를 선택해주세요.'),
      }),
    planOptionId: yup
      .string()
      .notRequired()
      .when('type', {
        is: (type: string) => type !== 'SUB',
        then: yup.string().required('* 요금제 옵션을 선택해주세요.'),
      }),
  })
  .required();

export const SubscriptionUpdateSchema = yup
  .object({
    type: yup.string().required('* 구독 타입을 선택해주세요'),
    name: yup.string().required('* '),
    startDate: yup.string().required('* 구독 시작 날짜를 입력해주세요'),
    endDate: yup.string().nullable().notRequired(),
    description: yup.string().notRequired(),
    isQuantityContract: yup.boolean().required(),
    contractConditions: yup
      .array()
      .of(
        yup.object().shape({
          moduleCode: yup.string().required('* 모듈 코드는 필수 입력값입니다'),
          usage: yup
            .number()
            .transform((_, ov) => (ov ? Number(ov) : undefined))
            .typeError('숫자 형식으로 입력해주세요')
            .required('* 약정 사용량을 입력해주세요'),
          price: yup
            .number()
            .nullable()
            .transform((_, ov) => (ov ? Number(ov) : undefined))
            .typeError('숫자 형식으로 입력해주세요')
            .notRequired(),
          currencyUnit: yup.string().required(),
        }),
      )
      .when('isQuantityContract', {
        is: (isQuantityContract: boolean) => isQuantityContract,
        then: yup.array().min(1, '* 하나 이상의 약정 사용량을 입력해주세요'),
      })
      .notRequired(),
    moduleUsagePrices: yup.array().of(
      yup.object().shape({
        usageFrom: yup
          .number()
          .transform((_, ov) => (ov ? Number(ov) : undefined))
          .typeError('숫자 형식으로 입력해주세요')
          .required(),
        price: yup
          .number()
          .transform((_, ov) => (ov ? Number(ov) : undefined))
          .typeError('숫자 형식으로 입력해주세요')
          .required(),
        usageTo: yup
          .number()
          .transform((_, ov) => (ov ? Number(ov) : undefined))
          .typeError('숫자 형식으로 입력해주세요')
          .required(),
        unit: yup.string().required(),
        currencyUnit: yup.string().required(),
        moduleCode: yup.string().required(),
      }),
    ),
    parentSubscription: yup.object().nullable().notRequired(),
    timeZone: yup.string().notRequired(),
    currencyUnit: yup.string().notRequired(),
  })
  .required();

export const SubscriptionUserCreateSchema = yup
  .object({
    userLoginId: yup.string().required('* ID을 입력해주세요'),
    userName: yup.string().required('* 사용자 이름을 입력해주세요'),
    userEmail: yup.string().email('* 이메일 포맷에 맞게 입력해주세요').notRequired(),
  })
  .required();

export const SubscriptionUserUpdateSchema = yup
  .object({
    userLoginId: yup.string().required('* ID을 입력해주세요'),
    userName: yup.string().required('* 사용자 이름을 입력해주세요'),
    userEmail: yup.string().email('* 이메일 포맷에 맞게 입력해주세요').notRequired(),
  })
  .required();
