import type { TooltipProps } from 'recharts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import throttle from 'lodash/throttle';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { type Props as LabelProps } from 'recharts/types/component/Label';
import { fonts } from '@theme/fontsCustomer';
import { SquareFillIcon } from '@icons/SquareFillIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetSubscriptionUserStat } from '@queryHooks/useSubscription';
import { numberFormat } from '@utils/numberFormat';

type ChartData = {
  title: string;
  datas: { key: string; value: number }[];
};
export const UserStatusChart = () => {
  const { t } = useTranslation();
  const subscription = useSubscriptionModel();
  const { data: userStat, isSuccess } = useGetSubscriptionUserStat(subscription.id);

  const chartData: ChartData[] = isSuccess
    ? [
        {
          title: t('Subscrib_Detail_User'),
          datas: [
            { key: t('Subscription.memberStatus.ACTIVE'), value: userStat.activeTenantMemberCount ?? 0 },
            { key: t('Subscription.memberStatus.BLOCKED'), value: userStat.blockedTenantMemberCount ?? 0 },
            { key: t('Subscription.memberStatus.NON_MEMBER'), value: userStat.nonTenantMemberCount ?? 0 },
          ],
        },
        {
          title: t('Subscrib_Detail_User_Status'),
          datas: [
            { key: t('Subscription.userStatus.ACTIVE'), value: userStat.activeUserCount ?? 0 },
            { key: t('Subscription.userStatus.INACTIVE'), value: userStat.inactiveUserCount ?? 0 },
          ],
        },
        {
          title: t('Subscrib_Detail_Billable_User'),
          datas: [
            { key: t('Subscription.memberStatus.ACTIVE'), value: userStat.billableActiveTenantMemberCount ?? 0 },
            { key: t('Subscription.memberStatus.BLOCKED'), value: userStat.billableBlockedTenantMemberCount ?? 0 },
            { key: t('Subscription.memberStatus.NON_MEMBER'), value: userStat.billableNonTenantMemberCount ?? 0 },
          ],
        },
      ]
    : [];

  return (
    <Grid>
      {chartData.map(({ title, datas }) => (
        <GridItem key={title}>
          <Headline>{title}</Headline>
          <Chart datas={datas} />
        </GridItem>
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0px 10px;
`;

const GridItem = styled.div`
  padding: 24px 39px 32px 40px;
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  &:last-of-type {
    border-right: 0px;
  }
`;

const Headline = styled.span`
  ${fonts.Headline7}
`;

type ChartProps = {
  datas: { key: string; value: number }[];
};
const Chart = ({ datas }: ChartProps) => {
  const { colors } = useTheme();
  const [activeIdx, setActiveIdx] = useState(-1);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const colorOrder = [colors['graph-purple'], colors['graph-purple-light'], colors['graph-purple-lighter']];

  const handleMouseEnter = (e: MouseEvent, index: number) => setActiveIdx(index);
  const handleMouseLeave = () => setActiveIdx(-1);

  const totalValue = datas.reduce((accum, cur) => accum + cur.value, 0);

  const handleMouseMove = useMemo(
    () =>
      throttle((payload: UnknownObject, idx: number, e: React.MouseEvent) => {
        setTooltipPosition({ x: e.nativeEvent.offsetX + 20, y: e.nativeEvent.offsetY + 20 });
      }, 100),
    [],
  );

  return (
    <ChartWrapper>
      <ResponsiveContainer width={222} height={254}>
        <PieChart width={190} height={190}>
          <Pie
            data={datas}
            nameKey="key"
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            innerRadius={65}
            outerRadius={95}
            activeIndex={activeIdx}
            activeShape={{ innerRadius: 63, outerRadius: 97 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            blendStroke
          >
            {datas.map((entry, index) => (
              <Cell
                key={entry.key}
                fill={colorOrder[index % colorOrder.length]}
                filter="drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.09))"
                style={{ outline: 'unset' }}
              />
            ))}
            <Label value={numberFormat({ num: totalValue })} position="center" content={<CustomLabel />} />
          </Pie>
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            content={<CustomTooltip totalValue={totalValue} />}
            position={tooltipPosition}
          />
        </PieChart>
      </ResponsiveContainer>
      <LegendWrapper>
        {datas.map((data, index) => (
          <LegendItem key={data.key}>
            <SquareFillIcon width={24} height={24} color={colorOrder[index]} />
            <LegendKey>{data.key}</LegendKey>
            <LegendValue>
              <b>{numberFormat({ num: data.value })}명</b>
              {totalValue > 0 ? <>({((data.value * 100) / totalValue).toFixed(0)}%)</> : null}
            </LegendValue>
          </LegendItem>
        ))}
      </LegendWrapper>
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;

  & .recharts-layer {
    outline: unset;
  }
`;

const LegendWrapper = styled.div`
  flex-grow: 1;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 7px;
  padding: 8px 16px 8px 8px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);

  & + & {
    margin-top: 6px;
  }
`;

const LegendKey = styled.span`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  margin-left: 4px;
`;

const LegendValue = styled.span`
  margin-left: auto;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  ${fonts.Body2}
  & > b {
    ${fonts.Headline8}
  }
`;

const CustomLabel = (props?: LabelProps & { viewBox?: { cx: number; cy: number } }) => {
  if (!props) {
    return null;
  }

  const { value, viewBox, offset } = props;
  return viewBox && viewBox.cy && offset ? (
    <text textAnchor="middle" x={viewBox.cx} y={viewBox.cy + offset}>
      <LabelTspan>{value}</LabelTspan>
      <Unit> 명</Unit>
    </text>
  ) : null;
};

const LabelTspan = styled.tspan`
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const Unit = styled.tspan`
  ${fonts.Body2}
  margin-left: 2px;
  color: ${props => props.theme.colors['text-gray-main']};
`;

const CustomTooltip = ({
  active,
  payload,
  totalValue,
  coordinate,
  offset,
}: TooltipProps<number, string> & { totalValue: number }) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const data = payload[0];

  return data.value && !!coordinate?.x && !!coordinate?.y && offset ? (
    <TooltipContainer>
      <TooltipTitle>{data.name}</TooltipTitle>
      <TooltipValue>
        <span>
          <b>{data.value}</b> / {totalValue}
        </span>
        <TooltipValuePercent>({((data.value * 100) / totalValue).toFixed(0)}%)</TooltipValuePercent>
      </TooltipValue>
    </TooltipContainer>
  ) : null;
};

const TooltipContainer = styled.div`
  width: 230px;
  padding: 12px 16px 16px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-main']};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
  transform: translate(-50%, 5px);

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    background: ${({ theme: { colors } }) => colors['bg-gray-main']};
    transform: rotate(45deg);
  }
`;

const TooltipTitle = styled.div`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
`;

const TooltipValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  ${fonts.Body4}
  color: ${({ theme: { colors } }) => colors['text-gray-lighter']};

  & b {
    ${fonts.Headline7}
    color: ${({ theme: { colors } }) => colors['text-white']};
  }
`;

const TooltipValuePercent = styled.span`
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-white']};
`;
