import { UserGroupBase } from '@repositories/userGroupsRepository/Types';

export class UserGroupBaseModel {
  private readonly _description: string;
  private readonly _name: string;
  private readonly _tenantId: string;
  private readonly _userGroupId: string;

  constructor(dto?: UserGroupBase) {
    this._description = dto?.description || '';
    this._name = dto?.name || '';
    this._tenantId = dto?.tenantId || '';
    this._userGroupId = dto?.userGroupId || '';
  }

  get description() {
    return this._description;
  }

  get name() {
    return this._name;
  }

  get tenantId() {
    return this._tenantId;
  }

  get userGroupId() {
    return this._userGroupId;
  }
}
