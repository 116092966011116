import type {
  BillingStatDTO,
  BillingStatFilter,
  MonthlyBillingStatDTO,
  MonthlyBillingStatFilter,
  SubscriptionAnnualBillingStatDTO,
  SubscriptionBillingStatFilter,
} from './Types';
import { injectable } from 'inversify';
import QueryString from 'qs';
import API from '@repositories/Axios';
import ISubscriptionBillingStatRepository from './ISubscriptionBillingStatRepository';

@injectable()
export class SubscriptionBillilngStatRepository implements ISubscriptionBillingStatRepository {
  async getSubscriptionBillingStat(filter?: BillingStatFilter): Promise<BillingStatDTO> {
    const queryString = QueryString.stringify(filter);
    const res = await API.get<BillingStatDTO>(`/api/v1/statistics/subscription-billing-stat?${queryString}`);
    return res.data;
  }

  async getSubscriptionBillingStatByMonth(filter?: MonthlyBillingStatFilter): Promise<MonthlyBillingStatDTO[]> {
    const queryString = QueryString.stringify(filter);
    const res = await API.get<MonthlyBillingStatDTO[]>(
      `/api/v1/statistics/subscription-billing-stat-by-month?${queryString}`,
    );
    return res.data;
  }

  async getSubscriptionBillingStatBySubscription(
    filter?: SubscriptionBillingStatFilter,
  ): Promise<SubscriptionAnnualBillingStatDTO[]> {
    const queryString = QueryString.stringify(filter);
    const res = await API.get<SubscriptionAnnualBillingStatDTO[]>(
      `/api/v1/statistics/subscription-billing-stat-by-subscription?${queryString}`,
    );
    return res.data;
  }
}
