import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  height: auto;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  border: 1px solid #fff;
  padding: 30px 30px;
  gap: 30px;
`;

export const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
