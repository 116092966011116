import styled from '@emotion/styled';
import React from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { CarouselArrowNext, CarouselArrowPrev } from '@images/SvgComponents';

export const Carousel: React.FC<{
  centerMode?: boolean;
  centerPadding?: string;
  infiniteLoop?: boolean;
  autoplay?: boolean;
  infinite?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}> = ({ className = '', children, infiniteLoop = false, centerMode = false, centerPadding = '', autoplay = false }) => {
  const ResponsiveSettings = {
    responsive: [
      {
        breakpoint: 1239,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          mobileFirst: true,
          dot: true,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          mobileFirst: true,
          dot: true,
        },
      },
    ],
  };
  return (
    <StyledCarousel
      speed={500}
      infinite={infiniteLoop}
      centerMode={centerMode}
      centerPadding={centerPadding}
      autoplay={autoplay}
      slidesToShow={3}
      slidesToScroll={3}
      className={className}
      prevArrow={<PrevArrow />}
      nextArrow={<NextArrow />}
      {...ResponsiveSettings}
    >
      {React.Children.map(children, child => child)}
    </StyledCarousel>
  );
};

const PrevArrow: React.FC<CustomArrowProps> = ({ className, onClick }) => (
  <button type="button" className={className} onClick={onClick}>
    <CarouselArrowPrev />
  </button>
);

const NextArrow: React.FC<CustomArrowProps> = ({ className, onClick }) => (
  <button type="button" className={className} onClick={onClick}>
    <CarouselArrowNext />
  </button>
);

const StyledCarousel = styled(Slider)`
  & .slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    z-index: 10;
  }

  & .slick-prev:before,
  & .slick-next:before {
    display: none;
  }

  & .slick-disabled {
    cursor: default;
    & > svg > path {
      stroke: ${({ theme: { colors, opacityMap } }) => `${colors.L_Grey}${opacityMap[30]}`};
    }
  }

  & .slick-arrow:not(.slick-disabled):hover > svg > path {
    stroke: ${({ theme: { colors } }) => colors.D_Grey};
  }
`;
