import { useFormContext } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Dialog } from '@components/Dialog';
import { FileIcon } from '@components/FileIcon';
import { IconButton } from '@components/buttons';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { AddIcon } from '@icons/AddIcon';
import { BulletIcon } from '@icons/BulletIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { XIcon } from '@icons/XIcon';
import { useFileDialog } from '@customHooks/useFileDialog';
import { transientOptions } from '@utils/CommonUtil';
import { type SubscriptionEditForm } from './SubscriptionEditValidationSchema';

type Props = {
  selectedFiles: File[];
  onSelectedFilesUpdated: (files: File[]) => void;
  onClose: () => void;
};
export const AdditionalStep = ({ selectedFiles, onSelectedFilesUpdated, onClose }: Props) => {
  const { colors } = useTheme();
  const {
    uiStore: { alertStore },
  } = useStore();

  const { register, watch, formState } = useFormContext<SubscriptionEditForm>();
  const watchMemo = watch('memo');
  const slicedMemo = watchMemo?.slice(0, 1000) ?? '';

  const { openFileDialog } = useFileDialog();

  const handleFileSelectClick = () => {
    openFileDialog(fileList => {
      const files = Array.from(fileList);
      const validFiles = files.filter(({ size }) => size <= 5242880);
      if (validFiles.length !== files.length) {
        alertStore.open({ title: '파일 첨부', message: '5MB 이하의 파일만 첨부할 수 있습니다.' });
      }
      onSelectedFilesUpdated([...selectedFiles, ...validFiles]);
    });
  };

  const handleRemoveFileClick = (target: File) => () => {
    onSelectedFilesUpdated(selectedFiles.filter(file => file !== target));
  };

  return (
    <>
      <Description>
        <DescriptionLine>
          <BulletIcon width={6} height={6} color={colors['ic-gray-lighter']} />
          필요한 경우 사용 관련 결재 문서 등 해당 구독과 관련된 문서를 첨부할 수 있습니다.
        </DescriptionLine>
        <DescriptionLine>
          <BulletIcon width={6} height={6} color={colors['ic-gray-lighter']} />
          파일 당 5MB 이하만 첨부 가능합니다.
        </DescriptionLine>
      </Description>

      <AttachButton
        variant="outline"
        size="large"
        fullWidth
        onClick={handleFileSelectClick}
        startIcon={<AddIcon width={20} height={20} color={colors['ic-purple-light']} />}
      >
        첨부 파일 등록
      </AttachButton>
      {selectedFiles.length === 0 && (
        <EmptyFiles>
          <ErrorIconWrapper>
            <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
          </ErrorIconWrapper>
          첨부된 파일이 없습니다.
        </EmptyFiles>
      )}
      {selectedFiles.length > 0 && (
        <FileWrapper>
          {selectedFiles.map((file, idx) => (
            /* File 객체에 id로 사용할 필드가 없음 + 순서 변화가 일어나지 않으므로 일부러 key에 idx 사용함 */
            // eslint-disable-next-line react/no-array-index-key
            <FileItem key={`${file.name}_${idx}`}>
              <FileInfo>
                <FileIcon fileName={file.name} width={20} height={20} />
                {file.name}
              </FileInfo>
              <IconButton variant="icon" onClick={handleRemoveFileClick(file)}>
                <XIcon width={20} height={20} color={colors['ic-gray-dark']} />
              </IconButton>
            </FileItem>
          ))}
        </FileWrapper>
      )}

      <FieldWrapper>
        <div>
          <FieldKey>메모</FieldKey>
          <MemoCount>
            ( <b>{slicedMemo.length}</b> / 1000 )
          </MemoCount>
        </div>
        <MemoTextField
          placeholder="메모 작성"
          $isOverflow={!!formState.errors.memo}
          {...register('memo')}
          value={slicedMemo}
        />
      </FieldWrapper>

      <Dialog.Footer>
        <Button type="submit" variant="contain" size="extraLarge" style={{ maxWidth: '228px' }}>
          완료
        </Button>
        <Button variant="outline" size="extraLarge" style={{ maxWidth: '228px' }} onClick={onClose}>
          취소
        </Button>
      </Dialog.Footer>
    </>
  );
};

const Description = styled.ul`
  margin: 26px 0px 36px;
`;

const DescriptionLine = styled.li`
  display: flex;
  gap: 8px;
  align-items: center;
  ${fonts.Paragraph2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  & + & {
    margin-top: 4px;
  }
`;

const AttachButton = styled(Button)`
  color: ${({ theme: { colors } }) => colors['text-purple-light']};
  margin-bottom: 12px;
`;

const EmptyFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 294px;
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;

const FileWrapper = styled.ul`
  height: 294px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  position: relative;
`;

const FileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 14px 10px 16px;
  ${fonts.Body2};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};

  &:first-of-type {
    padding-top: 10px;
  }
  &:nth-of-type(n + 7):last-of-type {
    border-bottom: 0px;
    padding-bottom: 10px;
  }
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const FieldWrapper = styled.div<{ $isFirstField?: boolean; $isLastField?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 24px;
`;

const FieldKey = styled.label`
  ${fonts.Headline8}
`;

const MemoCount = styled.span`
  margin-left: 6px;
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
  & > b {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

const MemoTextField = styled('textarea', transientOptions)<{ $isOverflow?: boolean }>`
  width: 100%;
  height: 220px;
  padding: 10px 12px;
  resize: none;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid
    ${({ $isOverflow, theme: { colors } }) => ($isOverflow ? colors['border-red-light'] : colors['border-gray-light'])};
  ${fonts.Body2}
`;
