import type { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { TableHeaderCell } from '@components/table';
import type { TechnicalInquiryListDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryListDTO';
import type { TechnicalInquiryListFilter } from '@repositories/technicalSupportRepository/technicalSupport.types';
import { getSortOrder } from '@utils/CommonUtil';

export type TechnicalSupportTableFilterPackage = {
  queries: TechnicalInquiryListFilter;
  setQueries: Dispatch<SetStateAction<TechnicalInquiryListFilter>>;
};

export const TechnicalSupportTable = ({
  technicalSupportTableFilterPackage,
  technicalSupportLists,
  setTechnicalSupportListRef,
}: {
  technicalSupportTableFilterPackage: TechnicalSupportTableFilterPackage;
  technicalSupportLists: TechnicalInquiryListDTOModel[];
  setTechnicalSupportListRef: (index: number) => (ref: HTMLTableRowElement) => void;
}) => {
  const navigate = useNavigate();
  const { queries, setQueries } = technicalSupportTableFilterPackage;

  const handleClickTableRow = (technicalInquiryId: string) => {
    navigate(`/subscriptions/technical-support/${technicalInquiryId}`);
  };

  const handleChangeSort = (orderBy: string, order: SortOrder | undefined) => {
    setQueries(prevFilter => ({
      ...prevFilter,
      sort: order ? [`${orderBy}.${order}`] : [],
    }));
  };

  const statusText = (text: string) => {
    switch (text) {
      case 'COMPLETED':
        return '답변완료';
      case 'IN_PROGRESS':
        return '진행중';
      case 'CREATED':
        return '요청';
      default:
        return '요청';
    }
  };
  const statusLabelText = (text: string) => {
    switch (text) {
      case 'COMPLETED':
        return 'gray';
      case 'IN_PROGRESS':
        return 'green';
      case 'CREATED':
        return 'pink';
      default:
        return 'gray';
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell
              align="center"
              width="72px"
              // orderBy="num"
              // order={getSortOrder(queries.sort, 'num')}
              // onSortChange={handleChangeSort}
            >
              번호
            </TableHeaderCell>
            <TableHeaderCell
              width="129px"
              orderBy="categoryName"
              order={getSortOrder(queries.sort, 'categoryName')}
              onSortChange={handleChangeSort}
            >
              제품 구분
            </TableHeaderCell>
            <TableHeaderCell
              width="230px"
              orderBy="productName"
              order={getSortOrder(queries.sort, 'productName')}
              onSortChange={handleChangeSort}
            >
              제품명
            </TableHeaderCell>
            <TableHeaderCell width="270px">플랜(요금제)</TableHeaderCell>
            <TableHeaderCell
              width="173px"
              orderBy="createdDate"
              order={getSortOrder(queries.sort, 'createdDate')}
              onSortChange={handleChangeSort}
            >
              문의 날짜
            </TableHeaderCell>
            <TableHeaderCell
              width="112px"
              orderBy="status"
              order={getSortOrder(queries.sort, 'status')}
              onSortChange={handleChangeSort}
            >
              처리 상태
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {technicalSupportLists.length > 0
            ? technicalSupportLists.map((list, idx) => {
                return (
                  <StyleTableRow
                    key={list.technicalInquiryId}
                    onClick={() => handleClickTableRow(list.technicalInquiryId)}
                    ref={setTechnicalSupportListRef(idx)}
                  >
                    <TableCell align="center">{idx + 1}</TableCell>
                    <TableCell>{list.categoryName}</TableCell>
                    <TableCell>
                      <SoftwareLabel>
                        <SoftwareIcon src={list.productIconUrl} />
                        {list.productName}
                      </SoftwareLabel>
                    </TableCell>
                    <TableCell>{list.planName}</TableCell>
                    <TableCell>{dayjs(list.createdDate).format('YYYY.MM.DD')}</TableCell>
                    <TableCell>
                      <Label variant="contain" color={statusLabelText(list.status)}>
                        {statusText(list.status)}
                      </Label>
                    </TableCell>
                  </StyleTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyleTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const SoftwareLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const SoftwareIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;
