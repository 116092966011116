import 'reflect-metadata';

import type {
  Filters,
  ProductPlan,
  ProductPlanBasicPlanUpdateRq,
  ProductPlanCreateRq,
  ProductPlanListDTO,
  ProductPlanUpdateRq,
  SoftwareTranslationDTO,
  UsageMetric,
  UsageMetricCreateRq,
  UsageMetricUpdateRq,
  SoftwareCreateRq,
  Software,
} from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { SoftwareSimpleModel } from '@models/softwareModels';
import { UsageMetricModel } from '@models/softwareModels/UsageMetricModel';
import ISoftwareRepository from './ISoftwareRepository';

@injectable()
export class SoftwareRepository implements ISoftwareRepository {
  async deleteTranslationLang(softwareId: string, langCode: string): Promise<void> {
    await API.delete(`/api/v1/softwares/${softwareId}/translations/${langCode}`);
  }

  async getTranslationLangs(softwareId: string): Promise<SoftwareTranslationDTO[]> {
    const result = await API.get<SoftwareTranslationDTO[]>(`/api/v1/softwares/${softwareId}/translations`);
    return result.data;
  }

  async getTranslationLang(softwareId: string, langCode: string): Promise<SoftwareTranslationDTO> {
    const result = await API.get<SoftwareTranslationDTO>(`/api/v1/softwares/${softwareId}/translations/${langCode}`);
    return result.data;
  }

  async addTranslationLang(
    softwareId: string,
    languageCode: string,
    rqData: SoftwareTranslationDTO,
  ): Promise<SoftwareTranslationDTO> {
    const result = await API.post<SoftwareTranslationDTO>(
      `/api/v1/softwares/${softwareId}/translations/${languageCode}`,
      rqData,
    );
    return result.data;
  }
  async create(data: SoftwareCreateRq, iconImage?: Blob, thumbnail?: Blob): Promise<Software> {
    const formData = new FormData();
    formData.append('software', new Blob([JSON.stringify(data)], { type: 'application/json' }));
    if (iconImage) formData.append('iconImage', iconImage);
    if (thumbnail) formData.append('thumbnail', thumbnail);
    const result = await API.post<Software>('/api/v1/softwares', formData);
    return result.data;
  }

  async update(id: string, data: SoftwareCreateRq, iconImage?: Blob | undefined): Promise<Software> {
    const formData = new FormData();
    formData.append('software', new Blob([JSON.stringify(data)], { type: 'application/json' }));
    if (iconImage) formData.append('iconImage', iconImage);

    const result = await API.post<Software>(`/api/v1/softwares/${id}`, formData);

    return result.data;
  }

  async getOne(id: string): Promise<Software> {
    const result = await API.get<Software>(`/api/v1/softwares/${id}`);
    return result.data;
  }

  async getList(queries?: Filters): Promise<Page<SoftwareSimpleModel>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat' });
    const result = await API.get<Page<SoftwareSimpleModel>>(`/api/v1/softwares?${queryString}`);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    await API.delete<void>(`/api/v1/softwares/${id}`);
  }

  async createPlan(id: string, data: ProductPlanCreateRq): Promise<ProductPlan> {
    const result = await API.post<ProductPlan>(`/api/v1/softwares/${id}/product-plans`, data);
    return result.data;
  }

  async getPlanList(id: string): Promise<ProductPlanListDTO[]> {
    const result = await API.get<ProductPlanListDTO[]>(`/api/v1/softwares/${id}/product-plans`);
    return result.data;
  }

  async getPlanDetail(softwareId: string, planId: string): Promise<ProductPlan> {
    const result = await API.get<ProductPlan>(`/api/v1/softwares/${softwareId}/product-plans/${planId}`);
    return result.data;
  }

  async updatePlan(softwareId: string, planId: string, data: ProductPlanUpdateRq): Promise<ProductPlan> {
    const result = await API.put<ProductPlan>(`/api/v1/softwares/${softwareId}/product-plans/${planId}`, data);
    return result.data;
  }

  async updatePlanBasicPlan(
    softwareId: string,
    planId: string,
    data: ProductPlanBasicPlanUpdateRq,
  ): Promise<ProductPlan> {
    const result = await API.put<ProductPlan>(
      `/api/v1/softwares/${softwareId}/product-plans/${planId}/basic-plan`,
      data,
    );
    return result.data;
  }

  async deletePlan(softwareId: string, planId: string): Promise<void> {
    await API.delete<void>(`/api/v1/softwares/${softwareId}/product-plans/${planId}`);
  }

  async createUsageMetric(id: string, data: UsageMetricCreateRq): Promise<UsageMetricModel> {
    const result = await API.post<UsageMetricModel>(`/api/v1/softwares/${id}/usage-metrics`, data);
    return result.data;
  }
  async getUsageMetricList(id: string): Promise<UsageMetric[]> {
    const result = await API.get<UsageMetric[]>(`/api/v1/softwares/${id}/usage-metrics`);
    return result.data;
  }
  async getUsageMetricDetail(softwareId: string, usageMetricId: string): Promise<UsageMetricModel> {
    const result = await API.get<UsageMetricModel>(`/api/v1/softwares/${softwareId}/usage-metrics/${usageMetricId}`);
    return result.data;
  }
  async updateUsageMetric(
    softwareId: string,
    usageMetricId: string,
    data: UsageMetricUpdateRq,
  ): Promise<UsageMetricModel> {
    const result = await API.put<UsageMetricModel>(
      `/api/v1/softwares/${softwareId}/usage-metrics/${usageMetricId}`,
      data,
    );
    return result.data;
  }
  async deleteUsageMetric(softwareId: string, usageMetricId: string): Promise<void> {
    await API.delete<void>(`/api/v1/softwares/${softwareId}/usage-metrics/${usageMetricId}`);
  }
}
