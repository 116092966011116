import styled from '@emotion/styled';
import { Checkbox, useTheme } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { CheckboxIcon } from '@icons/index';

type Props = StrictPropsWithChildren<{
  filterValue: string | string[];
  checked: boolean;
  onFilterChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    { filterValue, checked }: { filterValue: string | string[]; checked: boolean },
  ) => void;
}>;
export const CheckFilterRow = ({ filterValue, checked, onFilterChange, children }: Props) => {
  const theme = useTheme();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (typeof onFilterChange === 'function') {
      onFilterChange(event, { filterValue, checked });
    }
  };

  return (
    <Container>
      <Checker
        checked={checked}
        name={Array.isArray(filterValue) ? filterValue.join('_') : filterValue}
        onChange={handleChange}
        icon={<CheckboxIcon width={16} height={16} color={theme.colors['ic-gray-lighter']} />}
        checkedIcon={
          <CheckboxIcon width={17} height={17} color={theme.colors['ic-white']} bgColor={theme.colors['ic-purple']} />
        }
      />
      {children}
    </Container>
  );
};

const Checker = styled(Checkbox)`
  width: 16px;
  height: 16px;
`;

const Container = styled.label`
  display: flex;
  align-items: center;
  ${fonts.Body2}
  margin: 0px 5px;
  padding: 6px 7px;
  gap: 6px;
`;
