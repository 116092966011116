import type IWorkflowRepository from './IWorkflowRepository';
import type {
  WorkflowsFilter,
  WorkflowDTO,
  WorkflowCreateDTO,
  WorkflowExecDTO,
  WorkflowExecCreateDTO,
  WorkflowExecFilter,
} from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import type { Page } from '@type/Page';
import 'reflect-metadata';

@injectable()
export class WorkflowRepository implements IWorkflowRepository {
  async getList(queries?: WorkflowsFilter): Promise<Page<WorkflowDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<WorkflowDTO>>(`/api/v1/workflows?${queryString}`);
    return result.data;
  }
  async create(rqData: WorkflowCreateDTO): Promise<WorkflowDTO> {
    const result = await API.post<WorkflowDTO>(`/api/v1/workflows`, rqData);
    return result.data;
  }
  async getOne(workflowId: string): Promise<WorkflowDTO> {
    const result = await API.get<WorkflowDTO>(`/api/v1/workflows/${workflowId}`);
    return result.data;
  }
  async update(workflowId: string, rqData: WorkflowCreateDTO): Promise<WorkflowDTO> {
    const result = await API.put<WorkflowDTO>(`/api/v1/workflows/${workflowId}`, rqData);
    return result.data;
  }
  async delete(workflowId: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/workflows/${workflowId}`);
    return result.data;
  }
  async active(workflowId: string): Promise<WorkflowDTO> {
    const result = await API.post<WorkflowDTO>(`/api/v1/workflows/${workflowId}/activate`);
    return result.data;
  }
  async deActive(workflowId: string): Promise<WorkflowDTO> {
    const result = await API.post<WorkflowDTO>(`/api/v1/workflows/${workflowId}/deactivate`);
    return result.data;
  }

  async execute(workflowId: string, rqData: WorkflowExecCreateDTO): Promise<WorkflowExecDTO> {
    const result = await API.post<WorkflowExecDTO>(`/api/v1/workflows/${workflowId}/executions`, rqData);
    return result.data;
  }

  async getListExecution(workflowId: string, queries?: WorkflowExecFilter): Promise<Page<WorkflowExecDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<WorkflowExecDTO>>(`/api/v1/workflows/${workflowId}/executions?${queryString}`);
    return result.data;
  }

  async getOneExecution(workflowId: string, workflowExecId: string): Promise<WorkflowExecDTO> {
    const result = await API.get<WorkflowExecDTO>(`/api/v1/workflows/${workflowId}/executions/${workflowExecId}`);
    return result.data;
  }
}
