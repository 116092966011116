import type { AutocompleteOption } from '../user/AddUserDialog';
import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Autocomplete, InputAdornment, TextField, useTheme } from '@mui/material';
import debounce from 'lodash.debounce';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { SearchIcon } from '@icons/SearchIcon';
import { TrashIcon } from '@icons/TrashIcon';
import type {
  LicenseSubscriptionUserBulkUpdateDTO,
  LicenseSubscriptionUserUpdateDTO,
} from '@repositories/licenseRepository';
import type { SubscriptionUserDTO, SubscriptionUserFilter } from '@repositories/subscriptionRepository';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useCreateBulkLicenseUser, useGetLicenseUserList, usePatchLicenseUser } from '@queryHooks/useLicense';
import { useGetSubscriptionUsersInfinite } from '@queryHooks/useSubscription';
import { DomUtil } from '@utils/DomUtil';
import type { LicenseModel } from '@models/LicenseModel';
import type { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';

type Props = {
  license: LicenseModel;
  licenseType: boolean;
  subscriptionConnection?: SubscriptionConnectionModel;
  open: boolean;
  onClose: () => void;
};

export const LicenseUserAssignment = ({ license, licenseType, subscriptionConnection, open, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const subscription = useSubscriptionModel();
  const {
    uiStore: { alertStore, toastStore },
  } = useStore();

  const [selectUser, setSelectUser] = useState<AutocompleteOption | null>(null);
  const [userListCopy, setUserListCopy] = useState<SubscriptionUserDTO[]>([]);
  const [filter, setFilter] = useState<Omit<SubscriptionUserFilter, 'page'>>({ size: 100, keyword: undefined });
  const [deleteButtonHover, setDeleteButtonHover] = useState<string>('');
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);

  const { mutate: singleLicenseUserAdd } = usePatchLicenseUser();
  const { mutate: multiLicenseUserAdd } = useCreateBulkLicenseUser();
  const {
    isSuccess: isUserListSuccess,
    data: userList,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSubscriptionUsersInfinite(subscription.id, { ...filter });

  const { data: licenseUserList } = useGetLicenseUserList(license.licenseId, subscription.id, { enabled: !!open });

  const options: AutocompleteOption[] = isUserListSuccess
    ? userList.pages.reduce(
        (accum, cur) => [
          ...accum,
          ...cur.content.map(item => ({
            id: item.user?.id || item.userLoginId || '',
            name: item.user?.name || item.userName || '',
            email: item.user?.email || item.userEmail || '',
          })),
        ],
        [] as AutocompleteOption[],
      )
    : [];

  const handleAutocompleteScroll = debounce((event: React.UIEvent<HTMLUListElement>) => {
    if (DomUtil.isScrollBottom(event.target as HTMLUListElement) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 100);

  const handleMemberSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, keyword: e.target.value }));
  }, 1000);

  const handleSelectUser = (event: SyntheticEvent<Element, Event>, value: AutocompleteOption | null) => {
    setSelectUser(value);
    // 라인선스가 싱글이고 사용자가 이미 있다면
    if (licenseType && userListCopy.length > 0) {
      alertStore.open({
        title: t('Subscrib_Detail_License_38'),
        message: t('Subscrib_Detail_License_39'),
        confirmName: t('GNB_Profile_MyProfile_12'),
        onConfirm: () => {
          setIsButtonActive(true);
          const selectedUser = value?.email
            ? userList?.pages.flatMap(page => page.content).find(u => u.user?.id === value.id)
            : userList?.pages.flatMap(page => page.content).find(u => u.userLoginId === value?.id);

          if (selectedUser) {
            setUserListCopy([
              {
                subscriptionId: subscription.id,
                billableStatus: selectedUser.billableStatus,
                userLoginId: selectedUser.userLoginId,
                userName: selectedUser.userName,
                userEmail: selectedUser.userEmail,
                userStatus: selectedUser.userStatus,
                userType: selectedUser.userType,
                userRole: selectedUser.userRole,
                userOrganization: selectedUser.userOrganization,
                lastLoginTime: selectedUser.lastLoginTime,
                user: selectedUser.user,
                userGroup: selectedUser.userGroup,
                isMember: !!selectedUser.user,
              },
            ]);
          }
        },
        useCancel: true,
        type: 'success',
      });
    } else if (licenseType) {
      // 라이선스 타입이 싱글인 경우
      const selectedUser = value?.email
        ? userList?.pages.flatMap(page => page.content).find(u => u.user?.id === value.id)
        : userList?.pages.flatMap(page => page.content).find(u => u.userLoginId === value?.id);

      if (selectedUser) {
        setIsButtonActive(true);
        setUserListCopy([
          {
            subscriptionId: subscription.id,
            billableStatus: selectedUser.billableStatus,
            userLoginId: selectedUser.userLoginId,
            userName: selectedUser.userName,
            userEmail: selectedUser.userEmail,
            userStatus: selectedUser.userStatus,
            userType: selectedUser.userType,
            userRole: selectedUser.userRole,
            userOrganization: selectedUser.userOrganization,
            lastLoginTime: selectedUser.lastLoginTime,
            user: selectedUser.user,
            userGroup: selectedUser.userGroup,
            isMember: !!selectedUser.user,
          },
        ]);
      }
    } else {
      // 라이선스가 멀티인경우
      const selectedUser = value?.email
        ? userList?.pages.flatMap(page => page.content).find(u => u.user?.id === value.id)
        : userList?.pages.flatMap(page => page.content).find(u => u.userLoginId === value?.id);

      if (selectedUser) {
        const isDuplicate = userListCopy.some(user => user.userLoginId === selectedUser.userLoginId);

        if (!isDuplicate) {
          setIsButtonActive(true);
          setUserListCopy(prev => [
            ...prev,
            {
              subscriptionId: subscription.id,
              billableStatus: selectedUser.billableStatus,
              userLoginId: selectedUser.userLoginId,
              userName: selectedUser.userName,
              userEmail: selectedUser.userEmail,
              userStatus: selectedUser.userStatus,
              userType: selectedUser.userType,
              userRole: selectedUser.userRole,
              userOrganization: selectedUser.userOrganization,
              lastLoginTime: selectedUser.lastLoginTime,
              user: selectedUser.user,
              userGroup: selectedUser.userGroup,
              isMember: !!selectedUser.user,
            },
          ]);
        }
      }
    }
  };

  const handleFinalLicenseUserAdd = () => {
    if (licenseType) {
      // 라이선스가 싱글인 경우
      const data: LicenseSubscriptionUserUpdateDTO = {
        licenseId: license.licenseId,
        userLoginId: userListCopy.length > 0 ? userListCopy[0].userLoginId : undefined,
      };

      singleLicenseUserAdd(
        { subscriptionId: subscription.id, data },
        {
          onSuccess: () => {
            toastStore.open(t('Subscrib_Detail_License_37'));
            onClose();
          },
        },
      );
    } else {
      // 라이선스가 멀티인 경우
      const userLoginIds = userListCopy.map(user => user.userLoginId);
      const addUsers: LicenseSubscriptionUserUpdateDTO[] = userLoginIds.map(userLoginId => ({
        licenseId: license.licenseId,
        userLoginId,
      }));

      const data: LicenseSubscriptionUserBulkUpdateDTO = {
        licenseId: license.licenseId,
        subscriptionUsers: addUsers,
      };

      multiLicenseUserAdd(
        { subscriptionId: subscription.id, data },
        {
          onSuccess: () => {
            toastStore.open(t('Subscrib_Detail_License_37'));
            onClose();
          },
        },
      );
    }
  };

  const handleLicenseUserAdd = () => {
    alertStore.open({
      title: '라이선스 사용자 관리',
      message: <FinalConfirmMessage isLicenseAssignmentConnection={isLicenseAssignmentConnection} />,
      confirmName: '확인',
      onConfirm: handleFinalLicenseUserAdd,
      useCancel: true,
      type: 'success',
    });
  };

  const handleDeleteUser = (userLoginId: string) => {
    setIsButtonActive(true);
    setUserListCopy(prev => prev.filter(user => user.userLoginId !== userLoginId));
  };

  const isLicenseAssignmentConnection =
    subscriptionConnection?.isManuallyUserRevokeLicense && subscriptionConnection.isManuallyUserAssignLicense;

  useEffect(() => {
    setSelectUser(null);
    setUserListCopy(licenseUserList || []);
    setIsButtonActive(false);
  }, [licenseUserList, open]);

  useEffect(() => {
    if (autocompleteOpen) {
      setSelectUser(null);
    }
  }, [autocompleteOpen]);

  return (
    <Dialog open={open} onClose={onClose} title="라이선스 사용자 관리" height={694} size="medium">
      <Autocomplete
        onChange={(event, newValue) => {
          handleSelectUser(event, newValue);
        }}
        onClose={() => {
          setAutocompleteOpen(false);
        }}
        onOpen={() => {
          setAutocompleteOpen(true);
        }}
        options={options}
        value={selectUser}
        getOptionLabel={(options: AutocompleteOption) => `${options.name} (${options.email || options.id})`}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={t('Subscrib_Detail_User_23')}
            variant="outlined"
            onChange={handleMemberSearchChange}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end" style={{ display: 'none' }} />,
            }}
            sx={{
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderRadius: '2px 2px 0 0',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                color: `${theme.colors['text-gray-light']}`,
              },
            }}
          />
        )}
        ListboxProps={{ onScroll: handleAutocompleteScroll, role: 'list-box' }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText={<AutocompleteNoOptions />}
        sx={{
          '& .MuiInputBase-root': {
            padding: '10px 12px',
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: 0,
          },
        }}
        componentsProps={{
          paper: {
            sx: {
              width: '100%',
              maxHeight: '328px',
              marginTop: 0,
              borderRadius: '0 0 2px 2px',
              boxShadow: '0',
              border: `1px solid ${theme.colors['border-gray-light']}`,
              borderWidth: '0 1px 1px 1px',
              overflow: 'hidden',
              '& .MuiAutocomplete-listbox': {
                height: '328px',
                '& .MuiAutocomplete-option': {
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
                '& .MuiAutocomplete-option[aria-selected="true"]': {
                  background: `${theme.colors['state-white-hover']}`,
                  color: `${theme.colors['text-purple-light']}`,
                },
              },
              '& .MuiList-root': {
                border: 'none',
                padding: '4px 0',
                '& .MuiMenuItem-root': {
                  margin: '0 5px',
                },
              },
            },
          },
        }}
      />
      <UserTotalBox>
        <AssignmentText>{t('Subscrib_Detail_License_42')}</AssignmentText>
        <UserTotalNumber>{userListCopy?.length}</UserTotalNumber>
      </UserTotalBox>
      <UserListBox>
        {userListCopy?.length === 0 ? (
          <EmptyUser>
            <ErrorIconWrapper>
              <ErrorIcon width={22} height={22} color={theme.colors['ic-purple-light']} />
            </ErrorIconWrapper>
            <ErrorText>{t('Subscrib_Detail_User_29')}</ErrorText>
          </EmptyUser>
        ) : (
          <ul>
            {userListCopy?.map((item: SubscriptionUserDTO) => (
              <UserInfoBox key={item?.userLoginId}>
                <UserText>{`${item?.user?.name || item.userName} (${item?.user?.email || item.userLoginId})`}</UserText>
                <DeleteButton
                  onClick={() => handleDeleteUser(item.userLoginId)}
                  onMouseEnter={() => setDeleteButtonHover(item.userLoginId)}
                  onMouseLeave={() => setDeleteButtonHover('')}
                >
                  <TrashIcon
                    width={18}
                    height={18}
                    color={
                      item.userLoginId === deleteButtonHover ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']
                    }
                  />
                </DeleteButton>
              </UserInfoBox>
            ))}
          </ul>
        )}
      </UserListBox>
      <AssignmentInfoBox>
        <AssignmnetInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <InfoBoxText>구독의 사용자로 등록된 구성원만 지정할 수 있습니다.</InfoBoxText>
        </AssignmnetInfoItem>
        <AssignmnetInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <InfoBoxText>
            {isLicenseAssignmentConnection
              ? '라이선스 관리가 연동된 구독입니다. 해당 서비스와 연동하여 사용자\n라이선스를 자동으로 할당/회수합니다.'
              : '해당 서비스와 연동하여 사용자 라이선스를 자동 할당/회수 되지\n않습니다. 해당 서비스에서 직접 관리가 필요합니다.'}
          </InfoBoxText>
        </AssignmnetInfoItem>
      </AssignmentInfoBox>
      <ButtonBox>
        <Button
          type="submit"
          variant="contain"
          color="purple"
          size="extraLarge"
          paddingHorizontal={69.5}
          onClick={handleLicenseUserAdd}
          disabled={!isButtonActive}
        >
          {t('GNB_Profile_MyProfile_12')}
        </Button>
        <Button variant="outline" color="gray" size="extraLarge" paddingHorizontal={69.5} onClick={onClose}>
          {t('Subscrib_Detail_BuyRequest_13')}
        </Button>
      </ButtonBox>
    </Dialog>
  );
};

const AutocompleteNoOptions = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <AutocompleteNoOptionsContainer>
      <ErrorIconWrapper>
        <ErrorIcon width={22} height={22} color={theme.colors['ic-purple-light']} />
      </ErrorIconWrapper>
      <p>{t('Subscrib_Detail_License_41')}</p>
    </AutocompleteNoOptionsContainer>
  );
};

const FinalConfirmMessage: React.FC<{ isLicenseAssignmentConnection: boolean | undefined }> = ({
  isLicenseAssignmentConnection,
}) => {
  return (
    <MessageBox>
      <MessageHeader>
        {isLicenseAssignmentConnection
          ? '라이선스 관리가 연동된 구독입니다.'
          : '라이선스 관리가 연동되지 않은 구독입니다.'}
      </MessageHeader>
      <Message>
        {isLicenseAssignmentConnection
          ? '해당 서비스와 연동하여\n사용자 라이선스를 자동으로 할당/회수합니다.'
          : '해당 서비스의 사용자 라이선스가\n자동 할당/회수 되지 않습니다.\n해당 서비스에서 직접 관리가 필요합니다.'}
      </Message>
    </MessageBox>
  );
};

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;
const MessageHeader = styled.span`
  ${fonts.Headline7}
  margin-bottom: 4px;
`;
const Message = styled.span`
  text-align: center;
  ${fonts.Paragraph1}
  white-space: pre-wrap;
  margin-bottom: 4px;
`;

const AutocompleteNoOptionsContainer = styled.div`
  padding: 16px 0px;
  ${fonts.Body2}
  text-align: center;
`;

const ErrorIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(245, 240, 255, 1);
  margin: 0 auto 12px;
  padding: 7px;
`;

const UserTotalBox = styled.div`
  display: flex;
  margin: 30px 0px 8px 0px;
`;

const AssignmentText = styled.span`
  ${fonts.Headline8}
  margin-right: 4px;
`;

const UserTotalNumber = styled.span`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-purple-light']};
`;

const UserListBox = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  height: 336px;
  overflow: scroll;
  border-radius: 5px;
`;

const AssignmentInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 0px 10px 16px;
  margin-top: 12px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-50']};
`;

const InfoBoxText = styled.span`
  white-space: pre-wrap;
  padding: 0px;
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-light']};
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  margin-top: 24px;
`;

const UserInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 16px;
  height: 42px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
`;

const UserText = styled.span`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const EmptyUser = styled.div`
  display: flex;
  height: 336px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.span`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const DeleteButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AssignmnetInfoItem = styled('li')`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 6px;
`;
