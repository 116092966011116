import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Dialog } from '@components/Dialog';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { useUpdateSubscriptionUser } from '@queryHooks/useSubscription';
import { transientOptions } from '@utils/CommonUtil';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionUserModel } from '@models/SubscriptionUserModel';

type Props = {
  open: boolean;
  onClose: () => void;
  user: SubscriptionUserModel;
};
export const UserInfoDialog = ({ user, open, onClose }: Props) => {
  const MEMO_MAX_LENGTH = 200;
  const { t } = useTranslation();
  const {
    uiStore: { toastStore },
  } = useStore();
  const [memo, setMemo] = useState(user.memo);
  const validMemo = memo.slice(0, MEMO_MAX_LENGTH);

  const { mutate: updateSubscriptionUser, isLoading } = useUpdateSubscriptionUser();

  const handleMemoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(e.target.value);
  };

  const handleSaveClick = () => {
    const rqData = user.getSubscriptionUserUpdateRq({ memo: validMemo });
    updateSubscriptionUser(
      { subscriptionId: user.subscriptionId, rqData },
      {
        onSuccess: () => {
          onClose();
          toastStore.open({ message: '저장을 완료했습니다.', hideAction: true });
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose} size="large" title="사용자 정보" height={738}>
      <InfoGrid>
        <GridItem>
          <dt>{t('Subscrib_Detail_User_10')}</dt>
          <dd>
            <Label variant="outline" color={user.isMember ? 'gray' : 'red'} className="user-label">
              {user.isMember ? 'Y' : 'N'}
            </Label>
          </dd>
        </GridItem>
        <GridItem>
          <dt>{t('Subscrib_Detail_User_11')}</dt>
          <dd>{user.userLoginId ?? '-'}</dd>
        </GridItem>
        <GridItem>
          <dt>{t('Workflow_Main_05')}</dt>
          <dd>{user.userName ?? '-'}</dd>
        </GridItem>
        <GridItem>
          <dt>{t('Subscrib_Detail_User_13')}</dt>
          <dd>{user.userEmail ?? '-'}</dd>
        </GridItem>
        <GridItem>
          <dt>{t('Member_User_Bulk_Create_18')}</dt>
          <dd>{user.userOrganization ?? '-'}</dd>
        </GridItem>
        <GridItem>
          <dt>{t('Member_User_17')}</dt>
          <dd>{user.userRole ?? '-'}</dd>
        </GridItem>
        <GridItem>
          <dt>{t('Subscrib_Detail_User_14')}</dt>
          <dd> {dateFormat(user.lastLoginTime, '-', t('DateFormat_YMD'))}</dd>
        </GridItem>
        <GridItem>
          <dt>과금 여부</dt>
          <dd>
            <Label variant="outline" color={user.billableStatus === 'BILLABLE' ? 'gray' : 'red'}>
              {user.billableStatus === 'BILLABLE' ? 'Y' : 'N'}
            </Label>
          </dd>
        </GridItem>
        <GridItem>
          <dt>{t('Workflow_Main_07')}</dt>
          <dd>
            <Label variant="solid" color={user.userStatus === 'INACTIVE' ? 'gray' : 'blue'}>
              {user.userStatus === 'INACTIVE' ? t('Subscrib_Detail_User_17') : t('Subscrib_Detail_User_16')}
            </Label>
          </dd>
        </GridItem>
        <GridItem />
      </InfoGrid>

      <div>
        <MemoTitleWrapper>
          <MemoTitle>메모</MemoTitle>
          <MemoLength $isOverflow={memo.length > MEMO_MAX_LENGTH}>
            <b>{Math.min(memo.length, MEMO_MAX_LENGTH)}</b> / {MEMO_MAX_LENGTH}
          </MemoLength>
        </MemoTitleWrapper>

        <MemoTextField value={validMemo} onChange={handleMemoChange} $isOverflow={memo.length > MEMO_MAX_LENGTH} />
      </div>

      <Dialog.Footer>
        <Button
          variant="contain"
          size="extraLarge"
          fullWidth
          disabled={validMemo === user.memo || isLoading}
          onClick={handleSaveClick}
        >
          {t('Member_Group_11')}
        </Button>
        <Button variant="outline" size="extraLarge" fullWidth onClick={onClose}>
          {t('Subscrib_Detail_BuyRequest_13')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1px;
  row-gap: 1px;
  border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  background-color: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  margin-bottom: 28px;
`;

const GridItem = styled.div`
  padding: 14px 20px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};

  &:nth-of-type(odd) {
    padding-left: 0px;
  }

  & > dt {
    ${fonts.Headline9}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-bottom: 2px;
  }
  & > dd {
    ${fonts.Headline8}
  }
`;

const MemoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MemoTitle = styled.div`
  ${fonts.Headline8}
  margin-bottom: 6px;
`;

const MemoLength = styled('span', transientOptions)<{ $isOverflow: boolean }>`
  ${fonts.Body2}
  color: ${({ $isOverflow, theme: { colors } }) =>
    $isOverflow ? colors['text-red-light'] : colors['text-gray-light']};
  & > b {
    ${fonts.Body2}
    color: ${({ $isOverflow, theme: { colors } }) =>
      $isOverflow ? colors['text-red-light'] : colors['text-gray-main']};
  }
`;

const MemoTextField = styled('textarea', transientOptions)<{ $isOverflow: boolean }>`
  width: 100%;
  height: 140px;
  padding: 10px 12px;
  resize: none;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border: 1px solid
    ${({ $isOverflow, theme: { colors } }) => ($isOverflow ? colors['border-red-light'] : colors['border-gray-light'])};
  ${fonts.Body2}
`;
