import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { UseInfiniteQueryOptions, UseQueryOptions } from '@tanstack/react-query';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type ISubscriptionRepository from '@repositories/subscriptionRepository/ISubscriptionRepository';
import type {
  Filters,
  SubscriptionCreateRq,
  SubscriptionDailyUsageFilter,
  SubscriptionMonthlyUsageFilter,
  SubscriptionUpdateRq,
  SubscriptionUserCreateRq,
  SubscriptionUserFilter,
  SubscriptionUserStatusUpdateRq,
  SubscriptionUserUpdateRq,
  SubscriptionUsersActivityStats,
  ExternalSubscriptionCreateRq,
  RemoteSubscrptionUserCreateDTO,
  SubscriptionMonthlyUserFilter,
  StatusRq,
  SubscriptionSalesManagerUpdateRq,
  SubscriptionTechManagerUpdateRq,
  SubscriptionOwnerUpdateRq,
  UserSubscriptionUpdateDTO,
  SubscriptionMemoUpdateDTO,
} from '@repositories/subscriptionRepository/Types';
import type { Page } from '@type/Page';
import { QueryUtil } from '@utils/queryUtil';
import { SubscriptionListModel } from '@models/SubscriptionListModel';
import { SubscriptionModel } from '@models/SubscriptionModel';
import { SubscriptionUserModel } from '@models/SubscriptionUserModel';
import { MonthlySubscriptionUserModel, SubscriptionUserStatModel } from '@models/subscription';
import { DailyUsageModel } from '@models/usageModels/DailyUsageModel';
import { MonthlyUsageModel } from '@models/usageModels/MonthlyUsageModel';
import { tenantMemberQueryKey } from './useTenant';

const sbRepo = repository.get<ISubscriptionRepository>(KEYS.SUBSCRIPTION_REPOSITORY);
export const sbQueryKey = {
  all: ['subscription'] as const,
  lists: () => [...sbQueryKey.all, 'list'] as const,
  list: (filter?: Filters) => [...sbQueryKey.lists(), { ...filter }] as const,
  listInfinite: (filter?: Filters) => [...sbQueryKey.list(filter), 'infinite'] as const,
  details: () => [...sbQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...sbQueryKey.details(), id] as const,
  dailyUsage: (id: string, filter: SubscriptionDailyUsageFilter) =>
    [...sbQueryKey.detail(id), 'dailyUsage', { ...filter }] as const,
  dailyUsageInfinite: (id: string, filter: SubscriptionDailyUsageFilter) =>
    [...sbQueryKey.dailyUsage(id, filter), 'infinite'] as const,
  monthlyUsage: (id: string, filter: SubscriptionMonthlyUsageFilter) =>
    [...sbQueryKey.detail(id), 'monthlyUsage', { ...filter }] as const,
  monthlyUsageInfinite: (id: string, filter: SubscriptionMonthlyUsageFilter) =>
    [...sbQueryKey.monthlyUsage(id, filter), 'infinite'] as const,
  monthlyUser: (id: string, filter: SubscriptionMonthlyUserFilter) =>
    [...sbQueryKey.detail(id), 'monthlyUser', { ...filter }] as const,
  userStatusCountSummary: (id: string) => [...sbQueryKey.detail(id), 'userStatusCountSummary'] as const,
  userStatus: (id: string) => [...sbQueryKey.detail(id), 'userStatus'] as const,
  usersActivityStats: (id: string) => [...sbQueryKey.detail(id), 'usersActivityStats'] as const,
};

const subscriptionUserQueryKey = {
  all: ['SubscriptionUser'] as const,
  lists: () => [...subscriptionUserQueryKey.all, 'list'] as const,
  list: (subscriptionId: string, filters: SubscriptionUserFilter) =>
    [...subscriptionUserQueryKey.lists(), subscriptionId, { ...filters }] as const,
  listInfinite: (subscriptionId: string, filters: SubscriptionUserFilter) =>
    [...subscriptionUserQueryKey.list(subscriptionId, filters), 'infinite'] as const,
  details: () => [...subscriptionUserQueryKey.all, 'detail'] as const,
  detail: (subscriptionId: string, userLoginId: string) =>
    [...subscriptionUserQueryKey.details(), subscriptionId, userLoginId] as const,
};

export const useGetSubscriptionList = (
  queries?: Filters,
  options?: UseQueryOptionsType<Page<SubscriptionListModel>>,
) => {
  const result = useQuery<Page<SubscriptionListModel>, AxiosError>(
    sbQueryKey.list(queries),
    async () => {
      const result = await sbRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(sb => new SubscriptionListModel(sb)),
      };
    },
    {
      ...options,
      cacheTime: 50000,
      staleTime: 30000,
    },
  );

  const initialData = useMemo(() => ({ content: [] as SubscriptionListModel[], totalElements: 0, totalPages: 0 }), []);
  return {
    ...result,
    data: result.data ?? initialData,
  };
};

export const useGetSubscriptionListInfinite = (
  queries?: Omit<Filters, 'page'>,
  options?: UseInfiniteQueryOptions<Page<SubscriptionListModel>>,
) =>
  useInfiniteQuery({
    queryKey: sbQueryKey.listInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await sbRepo.getList({ ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionListModel(dto)),
        pageParam,
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetMySubscriptions = (options?: UseQueryOptionsType<Page<SubscriptionListModel>>) => {
  const result = useQuery<Page<SubscriptionListModel>, AxiosError>({
    queryKey: ['my-subscriptions'],
    queryFn: async () => {
      const mySubscriptions = await sbRepo.getMySubscriptions();
      return {
        ...mySubscriptions,
        content: mySubscriptions.content.map(sb => new SubscriptionListModel(sb)),
      };
    },
    ...options,
  });
  return { ...result };
};

export const useGetSubscriptionListPrefetch = () => {
  const queryClient = useQueryClient();

  const prefetch = (filter?: Filters) =>
    queryClient.prefetchQuery({
      queryKey: sbQueryKey.list(filter),
      queryFn: async () => {
        const result = await sbRepo.getList(filter);
        return {
          ...result,
          content: result.content.map(sb => new SubscriptionListModel(sb)),
        };
      },
    });

  return { prefetch };
};

export const useGetSubscription = (id: string, options?: UseQueryOptionsType<SubscriptionModel>) =>
  useQuery<SubscriptionModel, AxiosError>(
    sbQueryKey.detail(id),
    async () => {
      const result = await sbRepo.getOne(id);
      return new SubscriptionModel(result);
    },
    {
      ...options,
      cacheTime: 50000,
      staleTime: 30000,
      enabled: QueryUtil.withRequired(!!id, options?.enabled),
    },
  );

export const useGetSubscriptions = (id: string[], options?: UseQueryOptionsType<SubscriptionModel>) =>
  useQueries({
    queries: id.map(subscriptionId => ({
      queryKey: sbQueryKey.detail(subscriptionId),
      queryFn: async () => {
        const result = await sbRepo.getOne(subscriptionId);
        return new SubscriptionModel(result);
      },
      ...options,
      cacheTime: 50000,
      staleTime: 30000,
      enabled: QueryUtil.withRequired(!!id, options?.enabled),
    })),
  });

export const useGetSubscriptionUsers = (
  subscriptionId: SubscriptionId,
  queries?: SubscriptionUserFilter,
  options?: UseQueryOptionsType<Page<SubscriptionUserModel>>,
) =>
  useQuery<Page<SubscriptionUserModel>, AxiosError>(
    subscriptionUserQueryKey.list(subscriptionId, queries ?? {}),
    async () => {
      const result = await sbRepo.getUserList(subscriptionId, queries ?? {});
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionUserModel(dto, subscriptionId)),
      };
    },
    {
      ...options,
      cacheTime: 100 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    },
  );

/**
 * useGetSubscriptionUsers는 Pagination으로 사용중이라 무한 스크롤 용도로 따로 구현.
 */
export const useGetSubscriptionUsersInfinite = (
  subscriptionId: SubscriptionId,
  queries?: Omit<SubscriptionUserFilter, 'page'>,
  options?: UseInfiniteQueryOptions<Page<SubscriptionUserModel>>,
) =>
  useInfiniteQuery({
    queryKey: subscriptionUserQueryKey.listInfinite(subscriptionId, queries ?? {}),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await sbRepo.getUserList(subscriptionId, queries ? { ...queries, page: pageParam } : {});
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionUserModel(dto, subscriptionId)),
        pageParam,
      };
    },
    cacheTime: 100 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetSubscriptionUserCountSummary = (subscriptionId: string) =>
  useQuery({
    queryKey: sbQueryKey.userStatusCountSummary(subscriptionId),
    queryFn: async () => {
      const result = await sbRepo.getSubscriptionUserCountSummary(subscriptionId);
      return result;
    },
  });

export const useGetSubscriptionUserStat = (
  subscriptionId: string,
  options?: UseQueryOptionsType<SubscriptionUserStatModel>,
) =>
  useQuery({
    queryKey: sbQueryKey.userStatus(subscriptionId),
    queryFn: async () => {
      const result = await sbRepo.getUserStatus(subscriptionId);
      return new SubscriptionUserStatModel(result);
    },
    ...options,
  });

export const useGetSubscriptionDailyUsage = (
  subscriptionId: string,
  queries: SubscriptionDailyUsageFilter,
  options?: UseQueryOptionsType<Page<DailyUsageModel>>,
) => {
  const result = useQuery({
    queryKey: sbQueryKey.dailyUsage(subscriptionId, queries),
    queryFn: async () => {
      const result = await sbRepo.getDailyUsage(subscriptionId, queries);
      return {
        ...result,
        content: result.content.map(dto => new DailyUsageModel(dto)),
      };
    },
    ...options,
  });

  const initialData = useMemo(() => ({ content: [] as DailyUsageModel[] }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useGetSubscriptionUser = (
  subscriptionId: string,
  userLoginId: string,
  options?: UseQueryOptionsType<SubscriptionUserModel>,
) =>
  useQuery({
    queryKey: subscriptionUserQueryKey.detail(subscriptionId, userLoginId),
    queryFn: async () => {
      const result = await sbRepo.getSubscriptionUser(subscriptionId, userLoginId);
      return new SubscriptionUserModel(result, subscriptionId);
    },
    enabled: !!subscriptionId && !!userLoginId && (options?.enabled !== undefined ? options.enabled : true),
  });

export const useGetSubscriptionDailyUsageInfinite = (
  subscriptionId: string,
  queries: SubscriptionDailyUsageFilter,
  options?: UseInfiniteQueryOptions<Page<DailyUsageModel>>,
) =>
  useInfiniteQuery({
    queryKey: sbQueryKey.dailyUsageInfinite(subscriptionId, queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await sbRepo.getDailyUsage(subscriptionId, { ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new DailyUsageModel(dto)),
        pageParam,
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetSubscriptionMonthlyUsage = (
  subscriptionId: string,
  queries: SubscriptionMonthlyUsageFilter,
  options?: UseQueryOptionsType<Page<MonthlyUsageModel>>,
) => {
  const result = useQuery({
    queryKey: sbQueryKey.monthlyUsage(subscriptionId, queries),
    queryFn: async () => {
      const result = await sbRepo.getMonthlyUsage(subscriptionId, queries);
      return {
        ...result,
        content: result.content.map(dto => new MonthlyUsageModel(dto)),
      };
    },
    ...options,
  });

  const initialData = useMemo(() => ({ content: [] as MonthlyUsageModel[] }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useGetSubscriptionMonthlyUsageInfinite = (
  subscriptionId: string,
  queries: SubscriptionMonthlyUsageFilter,
  options?: UseInfiniteQueryOptions<Page<MonthlyUsageModel>>,
) =>
  useInfiniteQuery({
    queryKey: sbQueryKey.monthlyUsageInfinite(subscriptionId, queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await sbRepo.getMonthlyUsage(subscriptionId, { ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new MonthlyUsageModel(dto)),
        pageParam,
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetUsersActivityStats = (
  subscriptionId: string,
  options?: UseQueryOptionsType<SubscriptionUsersActivityStats>,
) =>
  useQuery({
    queryKey: sbQueryKey.usersActivityStats(subscriptionId),
    queryFn: async () => {
      const result = await sbRepo.getUsersActivityStats(subscriptionId);
      return result;
    },
    ...options,
  });

export const useGetSubscriptionMonthlyUsers = (
  subscriptionId: string,
  filter: SubscriptionMonthlyUserFilter,
  options?: UseQueryOptions<Page<MonthlySubscriptionUserModel>>,
) =>
  useQuery({
    queryKey: sbQueryKey.monthlyUser(subscriptionId, filter),
    queryFn: async () => {
      const result = await sbRepo.getMonthlyUser(subscriptionId, filter);
      return {
        ...result,
        content: result.content.map(dto => new MonthlySubscriptionUserModel(dto)),
      };
    },
    ...options,
  });

export const useAddSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (subscription: SubscriptionCreateRq) => {
      const result = await sbRepo.create(subscription);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useAddExternalSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ subscription, files }: { subscription: ExternalSubscriptionCreateRq; files?: File[] }) => {
      const result = await sbRepo.createExternal(subscription, files);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useAddSubscriptionUser = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: SubscriptionUserCreateRq) => {
      const result = await sbRepo.addUser(rqData.subscriptionId, rqData);
      return result;
    },
    {
      onSuccess: result => {
        queryClient.invalidateQueries(subscriptionUserQueryKey.lists());
        queryClient.invalidateQueries(tenantMemberQueryKey.lists());
        queryClient.invalidateQueries(sbQueryKey.userStatus(result.subscriptionId));
      },
    },
  );

  return { ...result };
};

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: SubscriptionUpdateRq) => {
      const result = await sbRepo.update(rqData.id, rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateUserSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: UserSubscriptionUpdateDTO) => {
      const result = await sbRepo.updateUserSubscription(rqData.id, rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: StatusRq) => {
      const result = await sbRepo.updateStatus(rqData.id, rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionVersionUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: SubscriptionId) => {
      const result = await sbRepo.upgradeVersion(id);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionSalesManager = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: SubscriptionSalesManagerUpdateRq) => {
      const result = await sbRepo.updateSalesManager(rqData.id, rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionTechManager = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: SubscriptionTechManagerUpdateRq) => {
      const result = await sbRepo.updateTechManager(rqData.id, rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionOwner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: SubscriptionOwnerUpdateRq) => {
      const result = await sbRepo.updateOwner(rqData.id, rqData);
      return result;
    },
    onSuccess: updated => {
      queryClient.invalidateQueries(sbQueryKey.detail(updated.id));
      queryClient.invalidateQueries(sbQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ subscriptionId, rqData }: { subscriptionId: string; rqData: SubscriptionUserUpdateRq }) => {
      await sbRepo.updateUser(subscriptionId, rqData.userLoginId, rqData);
      return { subscriptionId, userLoginId: rqData.userLoginId };
    },
    {
      onSuccess: ({ subscriptionId, userLoginId }) => {
        queryClient.invalidateQueries(subscriptionUserQueryKey.detail(subscriptionId, userLoginId));
        queryClient.invalidateQueries(subscriptionUserQueryKey.lists());
      },
    },
  );
};

export const useUpdateSubscriptionUserStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (rqData: SubscriptionUserStatusUpdateRq) => {
      await sbRepo.updateUserStatus(rqData.subscriptionId, rqData.userLoginId, rqData);
      return { subscriptionId: rqData.subscriptionId, userLoginId: rqData.userLoginId };
    },
    {
      onSuccess: ({ subscriptionId, userLoginId }) => {
        queryClient.invalidateQueries(subscriptionUserQueryKey.detail(subscriptionId, userLoginId));
        queryClient.invalidateQueries(subscriptionUserQueryKey.lists());
      },
    },
  );
};

export const useUpdateSubscriptionMemo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ subscriptionId, memo }: { subscriptionId: string } & SubscriptionMemoUpdateDTO) => {
      await sbRepo.updateSubscriptionMemo(subscriptionId, { memo });
      return subscriptionId;
    },
    onSuccess: subscriptionId => {
      queryClient.invalidateQueries(sbQueryKey.detail(subscriptionId));
    },
  });
};

export const useDeleteSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (subscriptionId: string) => {
      await sbRepo.delete(subscriptionId);
      return subscriptionId;
    },
    onSuccess: subscriptionId => {
      queryClient.invalidateQueries(sbQueryKey.lists());
      queryClient.removeQueries(sbQueryKey.detail(subscriptionId));
    },
  });
};

export const useDeleteSubscriptionUser = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async ({
      subscriptionId,
      userLoginIdOrUserId,
    }: {
      subscriptionId: SubscriptionId;
      userLoginIdOrUserId: string;
    }): Promise<{
      subscriptionId: SubscriptionId;
      userLoginIdOrUserId: string;
    }> => {
      await sbRepo.deleteUser(subscriptionId, userLoginIdOrUserId);
      return { subscriptionId, userLoginIdOrUserId };
    },
    {
      onSuccess: ({ subscriptionId }) => {
        queryClient.invalidateQueries(subscriptionUserQueryKey.lists());
        queryClient.invalidateQueries(sbQueryKey.lists());
        queryClient.invalidateQueries(sbQueryKey.userStatus(subscriptionId));
      },
    },
  );

  return { ...result };
};

export const useAddRemoteUser = () => {
  const queryClient = useQueryClient();

  type MutationVariable = {
    subscriptionId: string;
    raData: RemoteSubscrptionUserCreateDTO;
  };

  return useMutation({
    mutationFn: async (variable: MutationVariable) => {
      const result = await sbRepo.addRemoteUser(variable.subscriptionId, variable.raData);
      return result;
    },
    onSuccess: result => {
      queryClient.invalidateQueries(subscriptionUserQueryKey.all);
      queryClient.invalidateQueries(sbQueryKey.userStatus(result.subscriptionId));
    },
  });
};

export const useFileUpload = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      subscriptionId,
      files,
      config,
    }: {
      subscriptionId: string;
      files: Blob[];
      config?: AxiosRequestConfig;
    }) => {
      await sbRepo.fileUpload({ subscriptionId, files }, config);
      return subscriptionId;
    },
    onSuccess: subscriptionId => {
      queryClient.invalidateQueries(sbQueryKey.detail(subscriptionId));
    },
  });
};

export const useFileDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ subscriptionId, fileId }: { subscriptionId: string; fileId: string }) => {
      await sbRepo.fileDelete(subscriptionId, fileId);
      return subscriptionId;
    },
    onSuccess: subscriptionId => {
      queryClient.invalidateQueries(sbQueryKey.detail(subscriptionId));
    },
  });
};
