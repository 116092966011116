import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { useStore } from '@stores/RootStore';
import { SubscriptionRequestModel } from '@models/SubscriptionRequestModel';
import i18n from '@locales/i18n';

interface Props {
  data: SubscriptionRequestModel;
  idx: number;
}

export const BuyApplyHistoryRow = ({ data, idx }: Props) => {
  const navigate = useNavigate();
  const { categoryStore } = useStore();
  const rootCategoryName = data.software.category
    ? categoryStore.findRootCategory(data.software.category.id)?.name ?? '-'
    : '-';

  const handleClickRow = () => {
    navigate(`${data.id}`);
  };

  return (
    <TableRow onClick={handleClickRow} sx={{ cursor: 'pointer' }}>
      <TableCell align="center">{idx}</TableCell>
      <TableCell>{dayjs(data.formattedCreatedDate).format(i18n.t('DateFormat_YMD'))}</TableCell>
      <TableCell>{rootCategoryName}</TableCell>
      <TableCell>
        <ProductName>
          <Avatar src={data.software.iconUrl} sx={{ width: '16px', height: '16px', borderRadius: '4px' }} />
          {data.software.name}
        </ProductName>
      </TableCell>
      <TableCell>{data.productPlanName}</TableCell>
      <TableCell>{data.user.email}</TableCell>
      <TableCell>
        <Label variant="contain" color={data.statusLabelColor}>
          {data.statusStr}
        </Label>
      </TableCell>
    </TableRow>
  );
};

const ProductName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;
