import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import type { TechnicalInquiryListFilter } from '@repositories/technicalSupportRepository/technicalSupport.types';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useGetInfiniteMyTechnicalInquiryList } from '@queryHooks/useTechnicalSupport';
import { TechnicalSupportTable } from './TechnicalSupportTable';

export const TechnicalSupportList = () => {
  const [queries, setQueries] = useSearchParamsFilter<TechnicalInquiryListFilter>({
    defaultFilter: {
      page: 0,
      size: 100,
      sort: [],
    },
    whiteList: ['sort'],
  });

  const {
    data: technicalSupportList,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetInfiniteMyTechnicalInquiryList(queries);
  const technicalSupportLists = technicalSupportList?.pages.flatMap(({ content }) => content) ?? [];

  const lastTechnicalInquiryIdx = technicalSupportLists.length - 1;

  const [setLastTechnicalInquiryList] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const setTechnicalSupportListRef = (index: number) => {
    return (ref: HTMLTableRowElement) => {
      if (index === lastTechnicalInquiryIdx && hasNextPage) {
        setLastTechnicalInquiryList(ref);
      }
    };
  };

  return (
    <Container>
      <Header>
        <p>
          기술지원 문의 내역
          <br />
          <strong className="count">{`총 ${technicalSupportList?.pages[0].totalElements ?? 0}개`}</strong>
        </p>
      </Header>
      <Body>
        <TechnicalSupportTable
          technicalSupportTableFilterPackage={{ queries, setQueries }}
          technicalSupportLists={technicalSupportLists}
          setTechnicalSupportListRef={setTechnicalSupportListRef}
        />
      </Body>
    </Container>
  );
};

const Container = styled(Box)`
  width: calc(100% - 292px);
  height: calc(100vh - var(--employee-gnb-height) - 80px);
  margin-left: 292px;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  p {
    ${fonts.Subtitle4};
    line-height: 42px;
    & .count {
      font-weight: 700;
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }
  margin-bottom: 40px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  & table {
    max-width: 986px;
    width: 100%;
    overflow: auto;
    & .MuiTableHead-root .MuiTableCell-root {
      background-color: ${({ theme }) => theme.colors['bg-gray-light-50']};
    }
    tbody {
      .MuiTableRow-root {
        background-color: ${({ theme }) => theme.colors['bg-white']};
        &:hover {
          background-color: ${({ theme }) => theme.colors['state-white-hover']};
        }
      }
    }
  }
`;
