import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import qs from 'qs';
import ICommonCodeRepository from './ICommonCodeRepository';
import { CommonCode, CommonCodeCreateRq, CommonCodeFilter, CommonCodeOrderUpdateRq, CommonCodeUpdateRq } from './Types';

@injectable()
export class CommonCodeRepository implements ICommonCodeRepository {
  async create(data: CommonCodeCreateRq): Promise<CommonCode> {
    const result = await API.post<CommonCode>('/api/v1/common-codes', data);
    return result.data;
  }
  async getOne(id: string): Promise<CommonCode> {
    const result = await API.get<CommonCode>(`/api/v1/common-codes/${id}`);
    return result.data;
  }
  async getList(filter: CommonCodeFilter): Promise<Page<CommonCode>> {
    const queryString = qs.stringify(filter, { skipNulls: true });
    const result = await API.get<Page<CommonCode>>(`/api/v1/common-codes?${queryString}`);
    return result.data;
  }
  async update(id: string, data: CommonCodeUpdateRq): Promise<CommonCode> {
    const result = await API.put<CommonCode>(`/api/v1/common-codes/${id}`, data);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    await API.delete(`/api/v1/common-codes/${id}`);
  }
  async updateOrder(id: string, data: CommonCodeOrderUpdateRq): Promise<void> {
    await API.put<CommonCode>(`/api/v1/common-codes/${id}/order`, data);
  }
}
