import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { KEYS, repository } from '@repositories/Repository';
import ITenantBillingStatRepository from '@repositories/tenantBillingStatRepository/ITenantBillingStatRepository';
import {
  TenantBillingStatFilters,
  TenantPaymentStatAggregateFilter,
} from '@repositories/tenantBillingStatRepository/Types';
import { TenantBillingStatModel } from '@models/TenantBillingStatModel';
import { TenantPaymentStatDTOModel } from '@models/TenantPaymentStatDTOModel';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const tenantBillingStatRepo = repository.get<ITenantBillingStatRepository>(KEYS.TENENT_BILLIG_STAT_REPOSITORY);

export const tenantBillingStatKeys = {
  all: ['tenantBillingStat'] as const,
};

export const useGetTenantBillingStat = (
  queries: TenantBillingStatFilters,
  options?: UseQueryOptionsType<TenantBillingStatModel>,
) => {
  const result = useQuery<TenantBillingStatModel, AxiosError>(
    tenantBillingStatKeys.all,
    async () => {
      const result = await tenantBillingStatRepo.getOne(queries);
      return new TenantBillingStatModel(result);
    },
    {
      ...options,
      cacheTime: 5 * 60 * 1000,
      staleTime: 4 * 60 * 1000,
    },
  );

  return result;
};

export const useGetTenantPaymentStat = (
  queries: TenantPaymentStatAggregateFilter,
  options?: UseQueryOptionsType<TenantPaymentStatDTOModel[]>,
) => {
  const result = useQuery<TenantPaymentStatDTOModel[], AxiosError>({
    queryKey: ['tenantPayment', queries],
    queryFn: async () => {
      const result = await tenantBillingStatRepo.getCostStat(queries);
      return result.sort((a, b) => a.cost - b.cost).map(info => new TenantPaymentStatDTOModel(info));
    },
    ...options,
  });

  return result;
};
