import { injectable } from 'inversify';
import { Page } from '@type/Page';
import API from '@repositories/Axios';
import qs from 'qs';
import IExchangeRateRepository from './IExchangeRateRepository';
import { ExchageRateUpdateRq, ExchangeRateCreateRq, ExchangeRateDTO, ExchangeRateFilter } from './Types';

@injectable()
export class ExchangeRateRepository implements IExchangeRateRepository {
  async getList(queries?: ExchangeRateFilter): Promise<Page<ExchangeRateDTO>> {
    const result = await API.get<Page<ExchangeRateDTO>>(`/api/v1/exchange-rates?${qs.stringify(queries)}`);
    return result.data;
  }

  async create(queries?: ExchangeRateCreateRq): Promise<ExchangeRateDTO> {
    const result = await API.post<ExchangeRateDTO>('/api/v1/exchange-rates', queries);
    return result.data;
  }

  async update(updateExchangeRateId?: string, queries?: ExchageRateUpdateRq): Promise<ExchangeRateDTO> {
    const result = await API.put<ExchangeRateDTO>(`/api/v1/exchange-rates/${updateExchangeRateId}`, queries);
    return result.data;
  }

  async delete(deleteExchangeRateId?: string): Promise<void> {
    await API.delete<ExchangeRateDTO>(`/api/v1/exchange-rates/${deleteExchangeRateId}`);
  }
}
