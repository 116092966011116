import type IProductConnectorRepository from './IProductConnectorRepository';
import type { ProductConnectorDTO, ProductConnectorFilter, ProductConnectorListDTO } from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import type { Page } from '@type/Page';
import 'reflect-metadata';

@injectable()
export class ProductConnectorRepository implements IProductConnectorRepository {
  async getConnectorList(queries?: ProductConnectorFilter): Promise<Page<ProductConnectorListDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<ProductConnectorListDTO>>(`/api/v1/product-connectors?${queryString}`);
    return result.data;
  }

  async getConnector(connectorId: string): Promise<ProductConnectorDTO> {
    const result = await API.get<ProductConnectorDTO>(`/api/v1/product-connectors/${connectorId}`);
    return result.data;
  }

  async getConnectorsBySubscriptionId(subscriptionId: string): Promise<Page<ProductConnectorDTO>> {
    const result = await API.get<Page<ProductConnectorDTO>>(`/api/v1/subscriptions/${subscriptionId}/connectors`);
    return result.data;
  }
}
