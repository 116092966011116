import React, {
  ForwardedRef,
  HtmlHTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { ErrorBoundary } from '@components/ErrorBoundary';

type Props = PropsWithChildren<TooltipProps> & HtmlHTMLAttributes<HTMLDivElement>;
type TooltipProps = {
  placement?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
  label?: React.ReactNode;
  ellipsis?: boolean;
  multiEllipsis?: boolean;
  width?: number;
  isTextHidden?: boolean;
  disableMouseEnterListener?: boolean;
  visibleOnlyEllipsis?: boolean; // children이 inline 요소인 경우 동작하지 않습니다.
};

export const Tooltip = React.forwardRef(
  (
    {
      children,
      placement = 'top-left',
      label = 'label',
      className = '',
      multiEllipsis,
      disableMouseEnterListener = false,
      visibleOnlyEllipsis,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { ...rest } = props;
    const target = useRef<HTMLElement | null>(null);
    const [isVisibleHover, setIsVisibleHover] = useState(false);
    const [iconPlacement, setIconPlacement] = useState<string>(placement);

    const handleMouseEnter: MouseEventHandler = () => {
      const hideTooltip =
        disableMouseEnterListener ||
        (visibleOnlyEllipsis && target.current && target.current.scrollWidth <= target.current.clientWidth);
      setIsVisibleHover(!hideTooltip);
    };

    const handleMouseLeave = () => {
      setIsVisibleHover(false);
    };

    const handleMouseWheel = (e: React.WheelEvent) => {
      const currentTargetRect = e.currentTarget.getBoundingClientRect();
      if (isVisibleHover === false) return;
      if (
        (e.deltaY > 0 && e.clientY > currentTargetRect.bottom) ||
        (e.deltaY < 0 && e.clientY < currentTargetRect.top) ||
        e.clientX < currentTargetRect.left ||
        e.clientX > currentTargetRect.right
      ) {
        setIsVisibleHover(false);
      }
      if (Math.abs(e.deltaX) > 0) {
        const tooltipElement = document.getElementById('tooltip-box');
        if (target.current && isVisibleHover && tooltipElement) {
          const position = calculateTooltipPosition(target.current, tooltipElement, placement);
          tooltipElement.style.top = `${position.top}px`;
          tooltipElement.style.left = `${position.left}px`;
        }
      }
    };

    useEffect(() => {
      const tooltipElement = document.getElementById('tooltip-box');
      if (target.current && isVisibleHover && tooltipElement) {
        const position = calculateTooltipPosition(target.current, tooltipElement, placement);
        tooltipElement.style.top = `${position.top}px`;
        tooltipElement.style.left = `${position.left}px`;
      }
    }, [isVisibleHover, placement]);

    const calculateTooltipPosition = (
      targetElement: HTMLElement,
      tooltipElement: HTMLElement,
      placement: string,
    ): { top: number; left: number } => {
      const targetRect = targetElement.getBoundingClientRect();
      const tooltipRect = tooltipElement.getBoundingClientRect();
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      const point: { top: number; left: number; placement: string } = { top: 0, left: 0, placement };

      const leftCalculate = targetRect.right - tooltipRect.width - targetRect.width / 2 + 27;
      const rightCalculate = targetRect.left + targetRect.width / 2 - 27;
      const centerCalculate = targetRect.left + (targetRect.width - tooltipRect.width) / 2;
      const rightLackArea = window.innerWidth - (rightCalculate + scrollLeft) - tooltipRect.width;
      const topCalculate = targetRect.top - tooltipRect.height - 7;
      const bottomCalculate = targetRect.bottom + 7;

      if (placement.includes('top')) {
        if (topCalculate < tooltipRect.height) {
          point.top = bottomCalculate;
          point.placement = placement.replaceAll('top', 'bottom');
        } else {
          point.top = topCalculate;
        }
      } else if (placement.includes('bottom')) {
        if (window.innerHeight < bottomCalculate + targetRect.height) {
          point.top = topCalculate;
          point.placement = placement.replaceAll('bottom', 'top');
        } else {
          point.top = bottomCalculate;
        }
      }

      if (placement.includes('right')) {
        point.left = rightCalculate;
      } else if (placement.includes('left')) {
        point.left = leftCalculate;
      } else if (placement.includes('center')) {
        point.left = centerCalculate;
      }

      const mathAbs = {
        leftCalculate: Math.abs(leftCalculate),
        rightCalculate: Math.abs(rightLackArea),
      };

      if (rightLackArea < 0 && mathAbs.leftCalculate > mathAbs.rightCalculate) {
        point.left = leftCalculate;
        point.placement = placement.replaceAll('right', 'left').replaceAll('center', 'left');
      } else if (leftCalculate < 0 && mathAbs.leftCalculate < mathAbs.rightCalculate) {
        point.left = rightCalculate;
        point.placement = placement.replaceAll('left', 'right').replaceAll('center', 'right');
      }

      setIconPlacement(point.placement);

      return {
        top: point.top + scrollTop,
        left: point.left + scrollLeft,
      };
    };

    const child = children as ReactElement & { ref: ForwardedRef<HTMLElement> };
    const childrenProps = child?.props;

    const mergeHandler =
      (innerHandler: MouseEventHandler, childHandler: MouseEventHandler) =>
      (event: React.MouseEvent<Element, MouseEvent>) => {
        if (typeof childHandler === 'function') {
          childHandler(event);
        }
        innerHandler(event);
      };

    return (
      <ErrorBoundary fallback={child}>
        {React.isValidElement(child)
          ? React.cloneElement(child, {
              ref: (ref: HTMLElement | null) => {
                if (child?.ref) {
                  if (typeof child.ref === 'function') {
                    child.ref(ref);
                  } else {
                    child.ref.current = ref;
                  }
                }
                target.current = ref;
              },
              onMouseEnter: mergeHandler(handleMouseEnter, childrenProps.onMouseEnter),
              onMouseLeave: mergeHandler(handleMouseLeave, childrenProps.onMouseLeave),
              onWheel: mergeHandler(handleMouseWheel, childrenProps.onWheel),
            })
          : child}

        {isVisibleHover && (
          <>
            {createPortal(
              <TooltipBox id="tooltip-box" ref={ref} className={`tooltip-box ${iconPlacement} ${className}`} {...rest}>
                <p className={multiEllipsis ? 'multi-ellipsis' : ''}>{label}</p>
              </TooltipBox>,
              document.body,
            )}
          </>
        )}
      </ErrorBoundary>
    );
  },
);

const TooltipBox = styled.div<Props>`
  width: auto;
  max-width: 440px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-main']};
  border-radius: 3px;
  margin: 0;
  padding: 0;
  p {
    width: auto;
    margin: 0;
    margin: 12px 16px;
    white-space: normal;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: ${({ theme: { colors } }) => colors['text-white']};
    &.multi-ellipsis {
      max-width: 440px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;
    }
  }
  &.top-left {
    &:after {
      position: absolute;
      bottom: -6px;
      right: 20px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0;
      border-top: 7px solid ${({ theme: { colors } }) => colors['bg-gray-main']};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  &.top-center {
    &:after {
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translate(-50%, 0);
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0;
      border-top: 7px solid ${({ theme: { colors } }) => colors['bg-gray-main']};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  &.top-right {
    &:after {
      position: absolute;
      bottom: -6px;
      left: 20px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0;
      border-top: 7px solid ${({ theme: { colors } }) => colors['bg-gray-main']};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  &.bottom-left {
    &:after {
      position: absolute;
      top: -6px;
      right: 20px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0;
      border-bottom: 7px solid ${({ theme: { colors } }) => colors['bg-gray-main']};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  &.bottom-center {
    &:after {
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, 0);
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0;
      border-bottom: 7px solid ${({ theme: { colors } }) => colors['bg-gray-main']};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  &.bottom-right {
    &:after {
      position: absolute;
      top: -6px;
      left: 20px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0;
      border-bottom: 7px solid ${({ theme: { colors } }) => colors['bg-gray-main']};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
`;
