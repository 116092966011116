import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type FormUser = {
  name: string;
  code: string;
  description: string;
};

export const useUserGroupForm = () => {
  // TODO: translation에 추가 작업예정
  const { t } = useTranslation();
  const validationUserSchema = Yup.object().shape({
    name: Yup.string().required(t('Member_Group_Create_08')),
    code: Yup.string().required(t('Member_Group_Create_10')),
  });

  const userFormOptions: UseFormProps<FormUser> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: '',
    },
  };
  return useForm<FormUser>(userFormOptions);
};
