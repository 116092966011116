const defaultWidth = 24;
const defaultHeight = 24;

export const GroupNameIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.14099 10.6869C9.10453 11.1322 8.89657 11.532 8.57959 11.827C8.57962 11.8271 8.57964 11.8271 8.57967 11.8271C8.68099 11.7328 8.77117 11.6278 8.84817 11.514C9.01205 11.2718 9.11622 10.9899 9.14099 10.6869ZM10.4993 15.0133C10.4933 15.0894 10.452 15.1567 10.3904 15.1999H10.3902C10.4518 15.1567 10.4932 15.0894 10.4993 15.0133ZM9.5867 9.24642C9.71927 9.21603 9.85772 9.19992 10.0002 9.19992C10.967 9.19992 11.7508 9.9422 11.7508 10.8578C11.7508 11.4852 11.3829 12.0312 10.8402 12.3128C12.0611 12.6492 12.9605 13.7003 13 14.9561C13.0041 15.0869 12.8955 15.196 12.7575 15.1999H11.2806C11.2955 15.1238 11.3022 15.0439 11.2996 14.961C11.2591 13.6737 10.5704 12.564 9.5574 11.8909C9.80277 11.5019 9.94672 11.043 9.94672 10.5463C9.94672 10.0669 9.81468 9.62481 9.5867 9.24642Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.75C1 3.7835 1.7835 3 2.75 3H21.25C22.2165 3 23 3.7835 23 4.75V19.25C23 20.2165 22.2165 21 21.25 21H2.75C1.7835 21 1 20.2165 1 19.25V4.75ZM2.75 4.5C2.61193 4.5 2.5 4.61193 2.5 4.75V19.25C2.5 19.3881 2.61193 19.5 2.75 19.5H21.25C21.3881 19.5 21.5 19.3881 21.5 19.25V4.75C21.5 4.61193 21.3881 4.5 21.25 4.5H2.75ZM14 10.5C14 10.0858 14.3358 9.75 14.75 9.75H18.25C18.6642 9.75 19 10.0858 19 10.5C19 10.9142 18.6642 11.25 18.25 11.25H14.75C14.3358 11.25 14 10.9142 14 10.5ZM14 13.5C14 13.0858 14.3358 12.75 14.75 12.75H18.25C18.6642 12.75 19 13.0858 19 13.5C19 13.9142 18.6642 14.25 18.25 14.25H14.75C14.3358 14.25 14 13.9142 14 13.5ZM5.35345 10.5461C5.35345 9.55414 6.20258 8.75 7.25004 8.75C8.2975 8.75 9.14663 9.55414 9.14663 10.5461C9.14663 11.2257 8.74799 11.8172 8.16006 12.1223C9.48268 12.4867 10.4571 13.6255 10.4999 14.9859C10.5043 15.1275 10.3867 15.2458 10.2371 15.25H4.26289C4.11331 15.2458 3.99567 15.1275 4.00012 14.9859C4.04288 13.6254 5.01734 12.4867 6.33999 12.1223C5.75207 11.8172 5.35345 11.2257 5.35345 10.5461Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.899902 4.7499C0.899902 3.72818 1.72818 2.8999 2.7499 2.8999H21.2499C22.2716 2.8999 23.0999 3.72818 23.0999 4.7499V19.2499C23.0999 20.2716 22.2716 21.0999 21.2499 21.0999H2.7499C1.72818 21.0999 0.899902 20.2716 0.899902 19.2499V4.7499ZM2.7499 4.5999C2.66706 4.5999 2.5999 4.66706 2.5999 4.7499V19.2499C2.5999 19.3327 2.66706 19.3999 2.7499 19.3999H21.2499C21.3327 19.3999 21.3999 19.3327 21.3999 19.2499V4.7499C21.3999 4.66706 21.3327 4.5999 21.2499 4.5999H2.7499ZM13.8999 10.4999C13.8999 10.0305 14.2805 9.6499 14.7499 9.6499H18.2499C18.7193 9.6499 19.0999 10.0305 19.0999 10.4999C19.0999 10.9693 18.7193 11.3499 18.2499 11.3499H14.7499C14.2805 11.3499 13.8999 10.9693 13.8999 10.4999ZM13.8999 13.4999C13.8999 13.0305 14.2805 12.6499 14.7499 12.6499H18.2499C18.7193 12.6499 19.0999 13.0305 19.0999 13.4999C19.0999 13.9693 18.7193 14.3499 18.2499 14.3499H14.7499C14.2805 14.3499 13.8999 13.9693 13.8999 13.4999Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
