import { type ChangeEvent, useState, type MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IconButton } from '@components/buttons';
import { Button, EmptyRow } from '@components/index';
import { ProfileModal } from '@pages/customer/common/modals';
import { useUiStore } from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { Loading20Icon } from '@icons/LoadingIcon';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { useFileDialog } from '@customHooks/useFileDialog';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useDownloadFile } from '@queryHooks/useFile';
import { useFileDelete, useFileUpload, useUpdateSubscriptionMemo } from '@queryHooks/useSubscription';
import { transientOptions } from '@utils/CommonUtil';
import { dateFormat } from '@utils/dateUtil';
import { download } from '@utils/fileUtil';
import { type FileSimpleModel } from '@models/file/FileSimpleModel';

export const ExtraInfo = () => {
  const MAX_MEMO_LENGTH = 1000;
  const { t } = useTranslation();
  const { alertStore, toastStore } = useUiStore();
  const subscription = useSubscriptionModel();
  const [memoState, setMemoState] = useState<{ isEditMode: boolean; memo: string }>({
    isEditMode: false,
    memo: subscription.memo,
  });
  const [menuState, setMenuState] = useState<{ anchorEl: HTMLButtonElement; target: FileSimpleModel }>();
  const [selectedUploader, setSelectedUploader] = useState<{
    anchorEl: HTMLElement | null;
    id: string;
    rowNum: number;
  }>({
    anchorEl: null,
    id: '',
    rowNum: -1,
  });
  const memoRef = useRef<HTMLTextAreaElement | null>(null);

  const { openFileDialog, dummyFiles, addDummy, setDummyFinished } = useFileDialog({
    fileLength: subscription.attachments.length,
  });
  const { mutate: fileUpload } = useFileUpload();
  const { mutate: downloadFile } = useDownloadFile();
  const { mutate: fileDelete } = useFileDelete();
  const { mutate: updateMemo } = useUpdateSubscriptionMemo();

  const handleMemoChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMemoState(prev => ({ ...prev, memo: e.target.value }));
  };

  const handleAddFileClick = () => {
    openFileDialog(fileList => {
      const files = Array.from(fileList);
      const validFiles = files.filter(({ size }) => size <= 5242880);
      if (validFiles.length !== files.length) {
        alertStore.open({ title: '파일 첨부', message: '5MB 이하의 파일만 첨부할 수 있습니다.' });
      }

      let uploadCount = 0;
      validFiles.forEach(file => {
        const dummy = addDummy(file.name);

        fileUpload({
          subscriptionId: subscription.id,
          files: [file],
          config: {
            onUploadProgress: progress => {
              if (progress.progress === 1) {
                setDummyFinished(dummy.fileId);
                uploadCount += 1;
                if (uploadCount === validFiles.length) {
                  toastStore.open('파일 첨부를 완료했습니다.');
                }
              }
            },
          },
        });
      });
    });
  };

  const handleThreeDotClick = (targetFile: FileSimpleModel) => (e: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ anchorEl: e.currentTarget, target: targetFile });
  };

  const handleMenuClose = () => {
    setMenuState(undefined);
  };

  const handleFileDownload = () => {
    if (menuState?.target) {
      downloadFile(menuState.target.fileId, {
        onSuccess: data => {
          download(data, menuState.target.originalName);
        },
      });
    }
    setMenuState(undefined);
  };

  const handleFileDelete = () => {
    if (menuState?.target) {
      alertStore.open({
        title: '첨부 파일 삭제',
        message: '첨부 파일을 삭제합니다.\n해당 작업은 되돌릴 수 없습니다.',
        type: 'error',
        confirmName: '삭제',
        onConfirm: () => {
          fileDelete(
            { subscriptionId: subscription.id, fileId: menuState.target.fileId },
            {
              onSuccess: () => {
                toastStore.open({ message: '첨부 파일 삭제를 완료했습니다.', hideAction: true });
              },
            },
          );
        },
        useCancel: true,
      });
    }
    setMenuState(undefined);
  };

  const handleEditMemo = () => {
    setMemoState(prev => ({ ...prev, isEditMode: true }));
    setTimeout(() => {
      if (memoRef.current) {
        memoRef.current.focus();
      }
    }, 1);
  };

  const handleCancelEditMemo = () => {
    setMemoState({ isEditMode: false, memo: subscription.memo });
  };

  const handleSaveMemo = () => {
    updateMemo(
      { subscriptionId: subscription.id, memo: memoState.memo },
      {
        onSuccess: () => {
          toastStore.open('메모 저장을 완료했습니다.');
        },
      },
    );
    setMemoState(prev => ({ ...prev, isEditMode: false }));
  };

  const handleUploaderNameClick = (userId: string, rowNum: number) => (event: MouseEvent<HTMLButtonElement>) => {
    setSelectedUploader({ anchorEl: event.currentTarget, id: userId, rowNum });
  };

  const handleProfileModalClose = () => {
    setSelectedUploader({ anchorEl: null, id: '', rowNum: -1 });
  };

  return (
    <>
      <Container>
        <SubTitleWrapper>
          <SubTitle>
            첨부 파일 <b>{subscription.attachments.length}</b> <small>(5MB 이하의 파일만 첨부할 수 있습니다.)</small>
          </SubTitle>
          <Button variant="contain" size="small" paddingHorizontal={15} onClick={handleAddFileClick}>
            추가
          </Button>
        </SubTitleWrapper>
        <TableWrapper>
          <Table stickyHeader style={{ overflow: 'unset', borderCollapse: 'collapse' }}>
            <TableHead>
              <TableRow>
                <TableCell width={72} align="center">
                  번호
                </TableCell>
                <TableCell width={307}>파일 이름</TableCell>
                <TableCell width={140}>업로드 구성원</TableCell>
                <TableCell width={160}>업로드 날짜</TableCell>
                <TableCell width={72} align="center">
                  관리
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscription.attachments.length === 0 && dummyFiles.length === 0 && (
                <EmptyRow colSpan={5} title={<EmptyRowTitle>파일이 없습니다.</EmptyRowTitle>} />
              )}
              {dummyFiles.map((dummy, idx) => (
                <TableRow key={dummy.fileId}>
                  <TableCell align="center">{idx + 1}</TableCell>
                  <TableCell colSpan={4}>
                    <DummyFileName>
                      {dummy.fileName} <Loading20Icon />
                    </DummyFileName>
                  </TableCell>
                </TableRow>
              ))}
              {subscription.attachments.map((attachment, idx) => (
                <TableRow key={attachment.fileId}>
                  <TableCell align="center">{idx + dummyFiles.length + 1}</TableCell>
                  <TableCell>{attachment.originalName}</TableCell>
                  <TableCell>
                    {attachment.creator?.id ? (
                      <UploaderNameButton
                        type="button"
                        onClick={handleUploaderNameClick(attachment.creator.id, idx + dummyFiles.length + 1)}
                        className={selectedUploader.rowNum === idx + dummyFiles.length + 1 ? 'clicked' : ''}
                      >
                        {attachment.creator.name}
                      </UploaderNameButton>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>{dateFormat(attachment.createdDate, '-', t('DateFormat_YMD'))}</TableCell>
                  <TableCell align="center">
                    <IconButton variant="icon" onClick={handleThreeDotClick(attachment)}>
                      <ThreeDotIcon width={18} height={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>

        <SubTitleWrapper>
          <SubTitle>
            메모{' '}
            <small>
              (<b>{Math.min(memoState.memo.length, MAX_MEMO_LENGTH)}</b> / {MAX_MEMO_LENGTH})
            </small>
          </SubTitle>
          <div>
            {memoState.isEditMode ? (
              <>
                <SaveButton variant="outline" size="small" paddingHorizontal={15} onClick={handleSaveMemo}>
                  저장
                </SaveButton>
                <Button variant="outline" size="small" paddingHorizontal={15} onClick={handleCancelEditMemo}>
                  취소
                </Button>
              </>
            ) : (
              <Button variant="outline" size="small" paddingHorizontal={15} onClick={handleEditMemo}>
                수정
              </Button>
            )}
          </div>
        </SubTitleWrapper>
        <MemoTextField
          ref={memoRef}
          placeholder="메모를 작성해주세요."
          value={memoState.memo.slice(0, MAX_MEMO_LENGTH)}
          onChange={handleMemoChange}
          disabled={!memoState.isEditMode}
          $isOverflow={memoState.memo.length > MAX_MEMO_LENGTH}
        />
      </Container>

      <StyledMenu anchorEl={menuState?.anchorEl} open={!!menuState?.anchorEl} onClose={handleMenuClose}>
        <MenuItem onClick={handleFileDownload}>파일 다운로드</MenuItem>
        <DeleteMenuItem onClick={handleFileDelete}>파일 삭제</DeleteMenuItem>
      </StyledMenu>

      <ProfileModal
        open={!!selectedUploader.anchorEl}
        anchorEl={selectedUploader.anchorEl}
        onClose={handleProfileModalClose}
        userId={selectedUploader.id}
      />
    </>
  );
};

const Container = styled.section`
  margin: 40px 0px 0px 16px;
  width: 751px;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  ${fonts.Headline7};
  margin-left: 8px;

  & > b {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }

  & > small {
    ${fonts.Body2}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};

    & > b {
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }
`;

const TableWrapper = styled(TableContainer)`
  max-height: 464px;
  overflow-y: auto;
  margin-bottom: 86px;
`;

const MemoTextField = styled('textarea', transientOptions)<{ $isOverflow?: boolean }>`
  width: 100%;
  height: 192px;
  padding: 5px 11px;
  resize: none;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border: 1px solid
    ${({ $isOverflow, theme: { colors } }) => ($isOverflow ? colors['border-red-light'] : colors['border-gray-light'])};
  border-radius: 2px;
  ${fonts.Body2}
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 160px;
    box-shadow: none;
    border-radius: 0;
  }
  & .MuiList-root {
    border-radius: 2px;
  }
`;

const DeleteMenuItem = styled(MenuItem)`
  color: ${({ theme: { colors } }) => colors['text-red-light']};
`;

const DummyFileName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 9px;
`;

const SaveButton = styled(Button)`
  margin-right: 8px;
`;

const UploaderNameButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
  ${fonts.Underline2}

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.clicked {
    text-decoration: underline;
  }
`;

const EmptyRowTitle = styled.p`
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;
