import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type FormUser = {
  name: string;
  phone: string;
};

export const useUserForm = () => {
  const { t } = useTranslation();
  const validationUserSchema = Yup.object().shape({
    name: Yup.string().required(t('Member_Group_Bulk_Create_22')),
    phone: Yup.string().matches(/^[0-9]+$/, {
      message: t('Subscrib_Detail_License_11'),
      excludeEmptyString: true,
    }),
  });
  const userFormOptions: UseFormProps<FormUser> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<FormUser>(userFormOptions);
};
