import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import type { Subscription, SubscriptionList } from '@repositories/subscriptionRepository';
import type { Usage } from '@repositories/usage';
import { SubscriptionModel } from '@models/SubscriptionModel';
import APIs from '../repositories/APIs';
import { CollectorCreateRq } from '../types/Subscription';

class AppStore {
  /**
   * @deprecated
   */
  currApplication?: SubscriptionModel;

  constructor() {
    this.currApplication = undefined;

    makeAutoObservable<AppStore>(this);
  }

  setCurrentApplication(subscription: SubscriptionModel) {
    this.currApplication = subscription;
  }

  async fetchApplication(id: string) {
    const result = await APIs.Subscription.getOne(id);
    runInAction(() => {
      this.currApplication = new SubscriptionModel(AppStore.convert(result));
    });
  }

  async createCollector(data: CollectorCreateRq) {
    const result = await APIs.Collector.create(data);
    runInAction(() => {
      if (this.currApplication) {
        this.currApplication.setUsageCollector(result);
      }
    });
  }

  async deleteCollector(subscriptionId: string) {
    await APIs.Collector.delete(subscriptionId);
    runInAction(() => {
      if (this.currApplication) {
        this.currApplication.setUsageCollector(undefined);
      }
    });
  }

  async startCollector(subscriptionId: string) {
    const result = await APIs.Collector.start(subscriptionId);
    runInAction(() => {
      if (this.currApplication) {
        this.currApplication.setUsageCollector(result);
      }
    });
  }

  async stopCollector(subscriptionId: string) {
    const result = await APIs.Collector.stop(subscriptionId);
    runInAction(() => {
      if (this.currApplication) {
        this.currApplication.setUsageCollector(result);
      }
    });
  }

  async versionUp(subscriptionId: string) {
    const result = await APIs.Subscription.versionUp(subscriptionId);
    runInAction(() => {
      this.currApplication = new SubscriptionModel(AppStore.convert(result));
    });
  }

  private static convert<T extends Subscription | SubscriptionList>(app: T): T {
    if (!app.endDate) {
      let endDate;
      if (app.type === 'FIXED') {
        endDate = dayjs(app.startDate).add(1, 'year').add(-1, 'day').format('YYYY-MM-DD');
      } else if (app.type === 'FIXED_2') {
        endDate = dayjs(app.startDate).add(2, 'year').add(-1, 'day').format('YYYY-MM-DD');
      } else if (app.type === 'FIXED_3') {
        endDate = dayjs(app.startDate).add(3, 'year').add(-1, 'day').format('YYYY-MM-DD');
      }
      return { ...app, endDate };
    }
    return app;
  }

  genModuleUnit(unit: Usage['unit'], moduleCode: string): string {
    if (unit === 'NONE' || !unit) {
      return this.currApplication?.moduleUsagePrices?.find(m => m.moduleCode === moduleCode)?.unit ?? '';
    }
    return unit;
  }
}

const appStore = new AppStore();

export default appStore;
