import { ErrorIcon } from '@icons/ErrorIcon';
import { useTheme } from '@emotion/react';
import { useStore } from '@stores/RootStore';
import * as S from './PageNotFoundStyles';
import { Trans, useTranslation } from 'react-i18next';

export const PageNotFound = () => {
  const { colors } = useTheme();
  const {
    authStore: { curTenant },
  } = useStore();
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.IconWrapper>
        <ErrorIcon width={32} height={32} color={colors['ic-white']} />
      </S.IconWrapper>
      <S.Message>
        <Trans i18nKey="Manager_Not_Found_Page_01" components={{ 1: <strong /> }} />
      </S.Message>
      <S.SubMessage>{t('Manager_Not_Found_Page_02')}</S.SubMessage>
      <S.GoToHome to={curTenant.id ? `/manager/${curTenant.id}/store` : '/'}>
        {t('Manager_Not_Found_Page_03')}
      </S.GoToHome>
    </S.Container>
  );
};
