import { PromotionModel } from '@models/PromotionModel';
import { KEYS, repository } from '@repositories/Repository';
import { PromotionCreateRq } from '@repositories/promotionRepository';
import IPromotionRepository from '@repositories/promotionRepository/IPromotionRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const promotionRepo = repository.get<IPromotionRepository>(KEYS.PROMOTION_REPOSITORY);

const promotionQueryKey = {
  all: ['promotion'] as const,
  lists: () => [...promotionQueryKey.all, 'list'] as const,
};

export const usePromotionList = (options?: UseQueryOptionsType<Page<PromotionModel>>) => {
  const result = useQuery<Page<PromotionModel>, AxiosError>(
    promotionQueryKey.lists(),
    async () => {
      const result = await promotionRepo.getList();
      return {
        ...result,
        content: result.content.map(dto => new PromotionModel(dto)),
      };
    },
    {
      ...options,
    },
  );

  const initialData = useMemo(() => ({ content: [] as PromotionModel[], totalElements: 0 }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useAddPromotion = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: PromotionCreateRq) => promotionRepo.create({ ...data, bannerImage: undefined }, data.bannerImage),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(promotionQueryKey.lists());
      },
    },
  );
};

export const useDeletePromotion = () => {
  const queryClient = useQueryClient();

  return useMutation(async (index: number) => promotionRepo.delete(index), {
    onSuccess: () => {
      queryClient.invalidateQueries(promotionQueryKey.lists());
    },
  });
};
