import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { PencilIcon } from '@icons/PencilIcon';
import { TrashIcon } from '@icons/TrashIcon';
import { useDeleteSubscriptionRequest, useGetSubscriptionRequest } from '@queryHooks/useSubscriptionRequest';
import { SubscriptionRequestUpdateModal } from './SubscriptionRequestUpdateModal';

export const History = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const rqId = parseInt(id ?? '', 10);
  const navigate = useNavigate();
  const [isOpenSubscriptionRequestUpdateModal, setIsOpenSubscriptionRequestUpdateModal] = useState<boolean>(false);
  const {
    categoryStore,
    uiStore: { alertStore, toastStore },
  } = useStore();

  const { data: subscriptionRequest } = useGetSubscriptionRequest(rqId);
  const { mutate: deleteSubscriptionRequest } = useDeleteSubscriptionRequest();

  const rootCategory = subscriptionRequest?.software.category
    ? categoryStore.findRootCategory(subscriptionRequest.software.category.id)
    : undefined;

  const handleClickUpdateSubscriptionRequestBtn = () => {
    setIsOpenSubscriptionRequestUpdateModal(true);
  };

  const handleClickDeleteSubscriptionRequestBtn = () => {
    alertStore.open({
      title: '신청 내역 삭제',
      message: t('Subscrib_Detail_BuyRequest_11'),
      useCancel: true,
      type: 'error',
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      onConfirm: () => {
        deleteSubscriptionRequest(rqId);
        navigate(-1);
        toastStore.open('삭제를 완료했습니다.');
      },
    });
  };

  return (
    <Container>
      <Inner>
        <div className="header">
          <strong>구매 신청 내역</strong>
          <IconButton onClick={() => navigate(-1)} startIcon={<ChevronIcon rotateNum={270} width={20} height={20} />}>
            목록
          </IconButton>
        </div>
        <HistoryList>
          <HistoryItem>
            <TitleBox>
              <strong>상세내역</strong>
              {subscriptionRequest && subscriptionRequest.status === 'CREATED' ? (
                <div>
                  <TitleButton
                    onClick={() => handleClickUpdateSubscriptionRequestBtn()}
                    startIcon={<PencilIcon width={16} height={16} />}
                  >
                    {t('Ad_Cart_09')}
                  </TitleButton>
                  <TitleButton
                    onClick={() => handleClickDeleteSubscriptionRequestBtn()}
                    startIcon={<TrashIcon width={16} height={16} />}
                  >
                    {t('Subscrib_Detail_BuyRequest_12')}
                  </TitleButton>
                </div>
              ) : null}
            </TitleBox>
            <SubBodyWrap>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  {t('Subscrib_Detail_UseRequest_06')}
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {rootCategory ? rootCategory.name : '-'}
                </Typography>
              </SubItem>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  {t('Subscrib_Detail_UseRequest_25')}
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {subscriptionRequest.tenant.name}
                </Typography>
              </SubItem>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  {t('Subscrib_Detail_UseRequest_07')}
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {subscriptionRequest.software.name}
                </Typography>
              </SubItem>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  {t('Subscrib_Detail_BuyRequest_06')}
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {dayjs(subscriptionRequest.formattedCreatedDate).format(t('DateFormat_YMD'))}
                </Typography>
              </SubItem>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  {t('Subscrib_Main_12')}
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {subscriptionRequest.productPlanName}
                </Typography>
              </SubItem>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  {t('Subscrib_Detail_BuyRequest_02')}
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {subscriptionRequest.user.email}
                </Typography>
              </SubItem>
              <SubItem>
                <SubItemTitle variant="headline9" component="strong">
                  내용
                </SubItemTitle>
                <Typography variant="headline8" component="p">
                  {subscriptionRequest?.inquiry || '-'}
                </Typography>
              </SubItem>
            </SubBodyWrap>
          </HistoryItem>
          <HistoryItem>
            <Typography variant="headline7" component="strong">
              {t('Request_Processing')}
            </Typography>
            {subscriptionRequest.getHistories(true).map(history => {
              return (
                <SubBodyWrap key={nanoid()}>
                  <SubItem>
                    <SubItemTitle variant="headline9" component="strong">
                      {t('Subscrib_Detail_UseRequest_09')}
                    </SubItemTitle>
                    <div className="label-box">
                      <Label variant="contain" color={subscriptionRequest.getStatusLabelColor(history.status)}>
                        {subscriptionRequest.getStatusStr(history.status)}
                      </Label>
                    </div>
                  </SubItem>
                  <SubItem>
                    <SubItemTitle variant="headline9" component="strong">
                      {t('Subscrib_Detail_UseRequest_19')}
                    </SubItemTitle>
                    <Typography variant="headline8" component="p">
                      {dayjs(history.createdDate).format(t('DateFormat_YMD'))}
                    </Typography>
                  </SubItem>
                  <SubItem>
                    <SubItemTitle variant="headline9" component="strong">
                      {t('Acc_Detail_12')}
                    </SubItemTitle>
                    <Typography variant="headline8" component="p">
                      {history.managerName || '-'}
                    </Typography>
                  </SubItem>
                  <SubItem>
                    <SubItemTitle variant="headline9" component="strong">
                      라이선스 발급 내용
                    </SubItemTitle>
                    <Typography variant="headline8" component="p">
                      {history.comment || '-'}
                    </Typography>
                  </SubItem>
                </SubBodyWrap>
              );
            })}
          </HistoryItem>
        </HistoryList>
        {isOpenSubscriptionRequestUpdateModal && (
          <SubscriptionRequestUpdateModal
            isOpen={isOpenSubscriptionRequestUpdateModal}
            onClose={() => setIsOpenSubscriptionRequestUpdateModal(false)}
            subscriptionRequestData={subscriptionRequest}
          />
        )}
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height) - var(--employee-sub-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  & .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    margin: 60px auto 43px;
    & strong {
      ${fonts.Headline4};
      color: ${({ theme }) => theme.colors['text-gray-main']};
    }
  }
`;

const Inner = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HistoryList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const HistoryItem = styled.li`
  width: 100%;
`;

const SubBodyWrap = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors['bg-white']};
`;

const SubItem = styled.li`
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  border-right: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  &:nth-of-type(3n) {
    border-right: none;
  }
  &:last-of-type {
    width: 100%;
    border: none;
  }
  & .label-box {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    height: 18px;
  }
`;

const SubItemTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
` as typeof Typography;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & strong {
    ${fonts.Headline7};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

const IconButton = styled(Button)`
  gap: 4px;
  margin-right: auto;
  & span {
    ${fonts.Headline7};
  }
`;

const TitleButton = styled(Button)`
  display: inline-flex;
  height: 32px;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  width: auto;
  margin-left: 8px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  background-color: ${({ theme }) => theme.colors['bg-white']};
  & span {
    ${fonts.Button4};
  }
`;
