import { UserSimple } from '@repositories/userRepository';

export class UserSimpleModel {
  private readonly _email: string;
  private readonly _id: string;
  private readonly _name: string;
  private readonly _phone: string;
  private readonly _username: string;
  private readonly _isActive: boolean;

  constructor(dto?: UserSimple) {
    this._email = dto?.email || '';
    this._id = dto?.id || '';
    this._name = dto?.name || '';
    this._phone = dto?.phone || '';
    this._username = dto?.username || '';
    this._isActive = dto?.status === 'ACTIVE';
  }

  get email() {
    return this._email;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get phone() {
    return this._phone;
  }

  get username() {
    return this._username;
  }

  get isActive() {
    return this._isActive;
  }
}
