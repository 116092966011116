import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { DatePicker } from '@components/datePicker';
import { fonts } from '@theme/fontsCustomer';
import { DashIcon } from '@icons/DashIcon';
import { transientOptions } from '@utils/CommonUtil';

type Props = {
  queries: { monthFrom?: DateTimeString | null; monthTo?: DateTimeString | null };
  onMonthChange: (monthFrom: DateTimeString | null, monthTo: DateTimeString | null) => void;
};
export const MonthFilterTab = ({ queries, onMonthChange }: Props) => {
  const { t } = useTranslation();
  const [currentSelectTab, setCurrentSelectTab] = useState('allDate');
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleDateChange = (field: 'monthFrom' | 'monthTo') => (date: DateTimeString | null | undefined) => {
    if (field === 'monthFrom') {
      onMonthChange(dayjs(date).format('YYYY-MM'), queries.monthTo ?? null);
    } else {
      onMonthChange(queries.monthFrom ?? null, dayjs(date).format('YYYY-MM'));
    }
  };

  const handleTabClick = (value: string) => () => {
    setCurrentSelectTab(value);

    let startDate: DateTimeString | null = '';
    let endDate: DateTimeString | null = '';
    const today = dayjs();

    switch (value) {
      case 'thisMonth': {
        const firstDay = today.startOf('month');
        const lastDay = today.endOf('month');
        startDate = dayjs(firstDay).format('YYYY-MM');
        endDate = dayjs(lastDay).format('YYYY-MM');
        break;
      }
      case 'lastMonth': {
        const firstDay = dayjs().subtract(1, 'month').startOf('month');
        const lastDay = dayjs().subtract(1, 'month').endOf('month');
        startDate = dayjs(firstDay).format('YYYY-MM');
        endDate = dayjs(lastDay).format('YYYY-MM');
        break;
      }
      case 'recent3Month': {
        const today = dayjs();
        startDate = today.subtract(2, 'month').format('YYYY-MM');
        endDate = today.format('YYYY-MM');
        break;
      }
      case 'thisYear': {
        const today = dayjs();
        startDate = today.startOf('year').format('YYYY-MM');
        endDate = today.endOf('year').format('YYYY-MM');
        break;
      }
      case 'allDate': {
        startDate = null;
        endDate = null;
        break;
      }
    }
    onMonthChange(startDate, endDate);
  };

  const handlePickerToggle = () => {
    setIsPickerOpen(prev => !prev);
  };

  return (
    <BillingMonthBox>
      <DateTabsBox>
        <Tab onClick={handleTabClick('thisMonth')} $isSelected={currentSelectTab === 'thisMonth'}>
          {t('Acc_Main_08')}
        </Tab>
        <Tab onClick={handleTabClick('lastMonth')} $isSelected={currentSelectTab === 'lastMonth'}>
          {t('Acc_Main_09')}
        </Tab>
        <Tab onClick={handleTabClick('recent3Month')} $isSelected={currentSelectTab === 'recent3Month'}>
          {t('Acc_Main_10')}
        </Tab>
        <Tab onClick={handleTabClick('thisYear')} $isSelected={currentSelectTab === 'thisYear'}>
          {t('Acc_Main_11')}
        </Tab>
        <Tab onClick={handleTabClick('allDate')} $isSelected={currentSelectTab === 'allDate'}>
          {t('Acc_Main_12')}
        </Tab>
      </DateTabsBox>
      <DatePickerBox $isPickerOpen={isPickerOpen}>
        <DatePicker
          inputFormat={t('DateFormat_YM')}
          value={queries.monthFrom}
          onChange={handleDateChange('monthFrom')}
          onOpen={handlePickerToggle}
          onClose={handlePickerToggle}
          showDaysOutsideCurrentMonth
          views={['year', 'month']}
          openTo="month"
          renderInput={params => <DatePickerInput {...params} size="small" />}
        />
        <DashIcon width={10} height={10} />
        <DatePicker
          inputFormat={t('DateFormat_YM')}
          value={queries.monthTo}
          onChange={handleDateChange('monthTo')}
          onOpen={handlePickerToggle}
          onClose={handlePickerToggle}
          showDaysOutsideCurrentMonth
          minDate={queries.monthFrom ?? undefined}
          views={['year', 'month']}
          openTo="month"
          renderInput={params => <DatePickerInput {...params} size="small" />}
        />
      </DatePickerBox>
    </BillingMonthBox>
  );
};

const BillingMonthBox = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-50']};
  padding: 17px 0px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  margin: 16px 0px 0px;
  border-radius: 7px 7px 0px 0px;
`;

const DateTabsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  width: 100%;
  height: 34px;
  border-radius: 5px;
`;

const Tab = styled('button', transientOptions)<{ $isSelected: boolean }>`
  ${fonts.Button4}
  cursor: pointer;
  padding: 7px 15px;
  border-radius: 3px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  color: ${({ $isSelected, theme: { colors } }) =>
    $isSelected ? colors['text-purple-light'] : colors['text-gray-main']};
  border: 1px solid
    ${({ $isSelected, theme: { colors } }) => ($isSelected ? colors['border-purple'] : colors['border-gray-lighter'])};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
`;

const DatePickerBox = styled('div', transientOptions)<{ $isPickerOpen: boolean }>`
  width: 386px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  height: 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border: 1px solid
    ${({ $isPickerOpen, theme: { colors } }) =>
      $isPickerOpen ? colors['border-purple'] : colors['border-gray-lighter']};
  border-radius: 2px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
`;

const DatePickerInput = styled(DatePicker.Input)`
  & .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
  & .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
  & .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  & .MuiInputBase-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
