import { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { FileSimpleDTO } from '@repositories/fileRepository/Types';
import { DailyUsageDTO, MonthlyUsageDTO } from '@repositories/usage';
import { Page } from '@type/Page';
import ISubscriptionRepository from './ISubscriptionRepository';
import {
  Filters,
  StatusRq,
  Subscription,
  SubscriptionCreateRq,
  SubscriptionDailyUsageFilter,
  SubscriptionList,
  SubscriptionMonthlyUsageFilter,
  SubscriptionSnapshot,
  SubscriptionUserCreateRq,
  SubscriptionUserFilter,
  SubscriptionUserStatDTO,
  SubscriptionUserStatusUpdateRq,
  SubscriptionUserUpdateRq,
  SubscriptionUsersActivityStats,
  ExternalSubscriptionCreateRq,
  SubscriptionUserDTO,
  RemoteSubscrptionUserCreateDTO,
  SubscriptionMonthlyUserFilter,
  MonthlySubscriptionUserDTO,
  SubscriptionOwnerUpdateRq,
  SubscriptionSalesManagerUpdateRq,
  SubscriptionTechManagerUpdateRq,
  UserSubscriptionUpdateDTO,
  SubscriptionUserCountSummaryDTO,
  SubscriptionMemoUpdateDTO,
} from './Types';

@injectable()
export class SubscriptionRepository implements ISubscriptionRepository {
  async addRemoteUser(subscriptionId: string, rqData: RemoteSubscrptionUserCreateDTO): Promise<SubscriptionUserDTO> {
    const result = await API.post<SubscriptionUserDTO>(`/api/v1/subscriptions/${subscriptionId}/remote-users`, rqData);
    return result.data;
  }

  async getMySubscriptions(): Promise<Page<SubscriptionList>> {
    const result = await API.get<Page<SubscriptionList>>(`/api/v1/my-subscriptions`);
    return result.data;
  }

  async create(data: SubscriptionCreateRq): Promise<Subscription> {
    const result = await API.post<Subscription>('/api/v1/subscriptions', data);
    return result.data;
  }

  async getOne(id: string): Promise<Subscription> {
    const result = await API.get<Subscription>(`/api/v1/subscriptions/${id}`);
    return result.data;
  }
  async getList(queries?: Filters): Promise<Page<SubscriptionList>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat', skipNulls: true });
    const result = await API.get<Page<SubscriptionList>>(`/api/v1/subscriptions?${queryString}`);
    return result.data;
  }
  async update(id: string, data: SubscriptionCreateRq): Promise<Subscription> {
    const result = await API.put<Subscription>(`/api/v1/subscriptions/${id}`, data);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    await API.delete<void>(`/api/v1/subscriptions/${id}`);
  }
  async createExternal(data: ExternalSubscriptionCreateRq, files?: File[]): Promise<Subscription> {
    const formData = new FormData();
    formData.append('subscription', new Blob([JSON.stringify(data)], { type: 'application/json' }));
    if (files) {
      files.forEach(file => formData.append('files', file));
    }
    const result = await API.post<Subscription>('/api/v1/external-subscriptions', formData);
    return result.data;
  }
  async getSnapshotList(/* id: SubscriptionId */): Promise<SubscriptionSnapshot> {
    throw new Error('Method not implemented.');
  }
  async updateStatus(id: string, data: StatusRq): Promise<Subscription> {
    const result = await API.put<Subscription>(`/api/v1/subscriptions/${id}/status`, data);
    return result.data;
  }
  async upgradeVersion(id: string): Promise<Subscription> {
    const result = await API.post<Subscription>(`/api/v1/subscriptions/${id}/versions`);
    return result.data;
  }

  async addUser(subscriptionId: string, data: SubscriptionUserCreateRq): Promise<SubscriptionUserDTO> {
    const result = await API.post<SubscriptionUserDTO>(`/api/v1/subscriptions/${subscriptionId}/users`, data);
    return result.data;
  }

  async getUser(subscriptionId: string, userLoginId: string): Promise<SubscriptionUserDTO> {
    const result = await API.get<SubscriptionUserDTO>(`/api/v1/users/${userLoginId}`);
    return result.data;
  }

  async getUserList(subscriptionId: string, filter: SubscriptionUserFilter): Promise<Page<SubscriptionUserDTO>> {
    const queryString = qs.stringify(filter, { skipNulls: true });
    const result = await API.get<Page<SubscriptionUserDTO>>(
      `/api/v1/subscriptions/${subscriptionId}/users?${queryString}`,
    );
    return result.data;
  }

  async getSubscriptionUser(subscriptionId: string, userLoginId: string): Promise<SubscriptionUserDTO> {
    const result = await API.get<SubscriptionUserDTO>(`/api/v1/subscriptions/${subscriptionId}/users/${userLoginId}`);
    return result.data;
  }

  async getSubscriptionUserCountSummary(subscriptionId: string): Promise<SubscriptionUserCountSummaryDTO> {
    const queryString = qs.stringify({ subscriptionId });
    const result = await API.get<SubscriptionUserCountSummaryDTO>(
      `/api/v1/statistics/subscription-user-count-summary?${queryString}`,
    );
    return result.data;
  }

  async getUserStatus(subscriptionId: string): Promise<SubscriptionUserStatDTO> {
    const queryString = qs.stringify({ subscriptionId });
    const result = await API.get<SubscriptionUserStatDTO>(`/api/v1/statistics/subscription-user-status?${queryString}`);
    return result.data;
  }

  async updateUser(
    subscriptionId: string,
    userLoginId: string,
    data: SubscriptionUserUpdateRq,
  ): Promise<SubscriptionUserDTO> {
    const result = await API.put<SubscriptionUserDTO>(
      `/api/v1/subscriptions/${subscriptionId}/users/${userLoginId}`,
      data,
    );
    return result.data;
  }

  async updateUserStatus(
    subscriptionId: string,
    userLoginId: string,
    data: SubscriptionUserStatusUpdateRq,
  ): Promise<SubscriptionUserDTO> {
    const result = await API.patch<SubscriptionUserDTO>(
      `/api/v1/subscriptions/${subscriptionId}/users/${userLoginId}/status`,
      data,
    );
    return result.data;
  }

  async deleteUser(subscriptionId: string, userLoginIdOrUserId: string): Promise<void> {
    await API.delete(`/api/v1/subscriptions/${subscriptionId}/users/${userLoginIdOrUserId}`);
  }

  async getDailyUsage(subscriptionId: string, queries: SubscriptionDailyUsageFilter): Promise<Page<DailyUsageDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<DailyUsageDTO>>(
      `/api/v1/subscriptions/${subscriptionId}/daily-usages?${queryString}`,
    );
    return result.data;
  }

  async getMonthlyUsage(
    subscriptionId: string,
    queries: SubscriptionMonthlyUsageFilter,
  ): Promise<Page<MonthlyUsageDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<MonthlyUsageDTO>>(
      `/api/v1/subscriptions/${subscriptionId}/monthly-usages?${queryString}`,
    );
    return result.data;
  }

  async getMonthlyUser(
    subscriptionId: string,
    filter: SubscriptionMonthlyUserFilter,
  ): Promise<Page<MonthlySubscriptionUserDTO>> {
    const queryString = qs.stringify(filter);
    const result = await API.get<Page<MonthlySubscriptionUserDTO>>(
      `/api/v1/subscriptions/${subscriptionId}/monthly-users?${queryString}`,
    );
    return result.data;
  }

  async getUsersActivityStats(subscriptionId: string): Promise<SubscriptionUsersActivityStats> {
    const result = await API.get<SubscriptionUsersActivityStats>(
      `/api/v1/actions/subscription-users-activity-stats/${subscriptionId}`,
    );
    return result.data;
  }

  async updateSalesManager(id: string, data: SubscriptionSalesManagerUpdateRq): Promise<Subscription> {
    const result = await API.patch<Subscription>(`/api/v1/subscriptions/${id}/sales-manager`, data);
    return result.data;
  }

  async updateTechManager(id: string, data: SubscriptionTechManagerUpdateRq): Promise<Subscription> {
    const result = await API.patch<Subscription>(`/api/v1/subscriptions/${id}/technical-support-manager`, data);
    return result.data;
  }

  async updateOwner(id: string, data: SubscriptionOwnerUpdateRq): Promise<Subscription> {
    const result = await API.patch<Subscription>(`/api/v1/subscriptions/${id}/owner`, data);
    return result.data;
  }

  async updateUserSubscription(id: string, data: UserSubscriptionUpdateDTO): Promise<Subscription> {
    const result = await API.put<Subscription>(`/api/v1/manager/subscriptions/${id}`, data);
    return result.data;
  }

  async updateSubscriptionMemo(
    subscriptionId: string,
    data: SubscriptionMemoUpdateDTO,
    config?: AxiosRequestConfig,
  ): Promise<SubscriptionMemoUpdateDTO> {
    const result = await API.patch<SubscriptionMemoUpdateDTO>(
      `/api/v1/subscriptions/${subscriptionId}/memo`,
      data,
      config,
    );
    return result.data;
  }

  async fileUpload(
    { subscriptionId, files }: { subscriptionId: string; files: Blob[] },
    config?: AxiosRequestConfig,
  ): Promise<FileSimpleDTO[]> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    const result = await API.post<FileSimpleDTO[]>(`/api/v1/subscriptions/${subscriptionId}/files`, formData, config);
    return result.data;
  }

  async fileDelete(subscriptionId: string, fileId: string): Promise<void> {
    await API.delete(`/api/v1/subscriptions/${subscriptionId}/files/${fileId}`);
  }
}
