const defaultWidth = 24;
const defaultHeight = 24;

export const PlugSplashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1868 18.203C15.1867 20.8733 13.022 23.038 10.3516 23.038C7.75461 23.038 5.63581 20.9906 5.52131 18.4219H5.51676C5.51669 16.9844 4.47114 15.7911 3.09904 15.561C3.09904 15.561 3.20906 15.569 3.09904 15.5594C3.01992 15.5525 2.97778 15.5555 2.94652 15.5578C2.93431 15.5586 2.92376 15.5594 2.91331 15.5594C2.39055 15.5594 2 15.1226 2 14.5764C2 14.0302 2.4428 13.6125 2.98901 13.6125C3.09272 13.6125 3.1393 13.6125 3.28664 13.633C5.56876 13.9498 7.34148 15.8594 7.44594 18.203H7.45053C7.45084 19.805 8.74959 21.1035 10.3516 21.1035C11.9537 21.1035 13.2524 19.805 13.2527 18.203H13.2637V16.8663L10.7766 16.8663C9.77573 16.8663 8.94215 16.0981 8.83697 15.0937L8.23088 11.2764L8.16775 11.2213L10.4272 12.8034L10.7478 14.8761L10.7493 14.8874C10.7512 14.9068 10.7659 14.9158 10.7766 14.9158H13.444L16.2297 16.8663H15.1868L15.1868 18.203ZM19.6002 15.1959C19.6055 15.1621 19.6099 15.128 19.6135 15.0937L20.2196 11.2764L21.3204 10.3154C21.7524 9.9383 22 9.38875 22 8.81144V7.51192C22 6.42078 21.1305 5.52932 20.0494 5.52932H18.0439V2.47528C18.0439 1.94047 17.6172 1.5 17.0824 1.5C16.5476 1.5 16.1209 1.94047 16.1209 2.47528V5.52932L12.3296 5.52932V2.47528C12.3296 1.94047 11.9029 1.5 11.3681 1.5C10.8333 1.5 10.4066 1.94047 10.4066 2.47528V5.52932H8.40106C7.77919 5.52932 7.22732 5.82431 6.8707 6.28262L8.58056 7.47987L20.0494 7.47987C20.0608 7.47987 20.0769 7.4904 20.0769 7.51192V8.81144C20.0769 8.82227 20.0722 8.83148 20.0658 8.83708L18.6804 10.0466C18.4952 10.2082 18.3783 10.4348 18.3518 10.6797L17.8437 13.966L19.6002 15.1959Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97348 7.18085C4.29026 6.72844 4.87384 6.59051 5.27695 6.87277L21.3345 18.1164C21.7377 18.3987 21.8076 18.9942 21.4909 19.4466C21.1741 19.8991 20.5905 20.037 20.1874 19.7547L4.12979 8.51108C3.72669 8.22882 3.6567 7.63325 3.97348 7.18085Z"
        fill={color}
      />
    </svg>
  );
};

PlugSplashIcon.displayName = 'PlugSplashIcon';
