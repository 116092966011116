import type ISubscriptionConnectionRepository from './ISubscriptionConnectionRepository';
import type {
  Filter,
  SubscriptionConnectionDTO,
  SubscriptionConnectionCreateDTO,
  SubscriptionConnectionConnectDTO,
  SubscriptionConnectionUpdateDTO,
} from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import type { Page } from '@type/Page';
import 'reflect-metadata';

@injectable()
export class SubscriptionConnectionRepository implements ISubscriptionConnectionRepository {
  async getList(queries?: Filter): Promise<Page<SubscriptionConnectionDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<SubscriptionConnectionDTO>>(`/api/v1/subscription-connections?${queryString}`);
    return result.data;
  }
  async getOne(subscriptionId: string): Promise<SubscriptionConnectionDTO> {
    const result = await API.get<SubscriptionConnectionDTO>(`/api/v1/subscriptions/${subscriptionId}/connection`);
    return result.data;
  }
  async create(subscriptionId: string, rqData: SubscriptionConnectionCreateDTO): Promise<SubscriptionConnectionDTO> {
    const result = await API.post<SubscriptionConnectionDTO>(
      `/api/v1/subscriptions/${subscriptionId}/connection`,
      rqData,
    );
    return result.data;
  }
  async delete(subscriptionId: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/subscriptions/${subscriptionId}/connection`);
    return result.data;
  }
  async connect(subscriptionId: string, rqData: SubscriptionConnectionConnectDTO): Promise<SubscriptionConnectionDTO> {
    const result = await API.post<SubscriptionConnectionDTO>(`/api/v1/subscriptions/${subscriptionId}/connect`, rqData);
    return result.data;
  }
  async connectWithOauth(subscriptionId: string): Promise<string> {
    const result = await API.get<string>(`/api/v1/subscriptions/${subscriptionId}/oauth`);
    return result.data;
  }
  async disconnect(subscriptionId: string): Promise<SubscriptionConnectionDTO> {
    const result = await API.post<SubscriptionConnectionDTO>(`/api/v1/subscriptions/${subscriptionId}/disconnect`);
    return result.data;
  }
  async update(subscriptionId: string, rqData: SubscriptionConnectionUpdateDTO): Promise<SubscriptionConnectionDTO> {
    const result = await API.put<SubscriptionConnectionDTO>(
      `/api/v1/subscriptions/${subscriptionId}/connection`,
      rqData,
    );
    return result.data;
  }
  async synchronizeStatus(subscriptionId: string): Promise<SubscriptionConnectionDTO> {
    const result = await API.post<SubscriptionConnectionDTO>(
      `/api/v1/subscriptions/${subscriptionId}/synchronize-status`,
    );
    return result.data;
  }
}
