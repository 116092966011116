import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const Conatiner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 240px auto;
`;

export const IconWrapper = styled(Box)`
  width: 44px;
  height: 44px;
  padding: 6px;
  border-radius: 22px;
  background: ${({ theme: { colors } }) => colors['bg-gradient']};
  margin-bottom: 36px;
`;

export const Content = styled(Typography)`
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  text-align: center;
  margin-bottom: 80px;

  & > strong {
    ${fonts.Headline7}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};
  }
`;

export const GoToStore = styled.a`
  padding: 12px 24px;
  text-decoration: none;
  background: ${({ theme: { colors } }) => colors['bg-purple']};
  border-radius: 3px;
  ${fonts.Button2}
  color: ${({ theme: { colors } }) => colors['text-white']};
`;
