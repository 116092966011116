import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CircularProgress, TextField } from '@mui/material';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ProductRequestCreateDTO } from '@repositories/productRequestRepository';
import { useAddProductRequest } from '@queryHooks/useProductRequest';
import { ProductRequestFormData, useProductRequestForm } from './validationScheme';

type Props = {
  onClose: () => void;
};
export const ProductRequest = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { toastStore },
  } = useStore();
  const { register, handleSubmit, formState } = useProductRequestForm();

  const { mutate: addProductRequest, isLoading } = useAddProductRequest();

  const handleValid: SubmitHandler<ProductRequestFormData> = data => {
    const rqData: ProductRequestCreateDTO = {
      subject: data.subject,
      inquiry: data.inquiry,
    };
    addProductRequest(rqData, {
      onSuccess: () => {
        onClose();
        toastStore.open({ message: t('Subscrib_Create_07'), hideAction: true });
      },
    });
  };

  return (
    <section>
      <RequestDescription>
        <p>{t('Subscrib_Create_05')}</p>
      </RequestDescription>
      <Form onSubmit={handleSubmit(handleValid)}>
        <div>
          <label htmlFor="name">
            {t('Product_Inquiry_02')} <b>*</b>
          </label>
          <TextField
            {...register('subject')}
            id="name"
            size="medium"
            fullWidth
            autoFocus
            placeholder={t('Subscrib_Create_09')}
            error={!!formState.errors.subject}
            helperText={formState.errors.subject?.message}
          />
        </div>

        <div>
          <label htmlFor="remark">{t('Acc_Main_22')}</label>
          <MultilineTextField
            {...register('inquiry')}
            id="remark"
            multiline
            rows={15.6}
            fullWidth
            placeholder={t('Subscrib_Create_06')}
          />
        </div>

        <Dialog.Footer>
          <Button variant="contain" size="extraLarge" type="submit" disabled={isLoading} style={{ maxWidth: '464px' }}>
            {isLoading ? <CircularProgress size={24} /> : t('Member_User_24')}
          </Button>
        </Dialog.Footer>
      </Form>
    </section>
  );
};

const RequestDescription = styled.div`
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-purple']};
  margin-bottom: 30px;
  & p {
    white-space: pre;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 18px;

  & label {
    display: block;
    ${fonts.Headline8}
    margin-bottom: 6px;
    & b {
      color: ${({ theme: { colors } }) => colors['text-red-light']};
    }
  }
`;

const MultilineTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 10px 12px;

    & textarea {
      ${fonts.Body2};
    }
  }
`;
