const defaultWidth = 24;
const defaultHeight = 24;

export const PauseIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4993 2.09618C6.13414 2.09618 2.59544 5.63488 2.59544 10.0001C2.59544 14.3653 6.13414 17.904 10.4993 17.904C14.8646 17.904 18.4033 14.3653 18.4033 10.0001C18.4033 5.63488 14.8646 2.09618 10.4993 2.09618ZM1.16602 10.0001C1.16602 4.84542 5.34469 0.666748 10.4993 0.666748C15.654 0.666748 19.8327 4.84542 19.8327 10.0001C19.8327 15.1547 15.654 19.3334 10.4993 19.3334C5.34469 19.3334 1.16602 15.1547 1.16602 10.0001Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33398 6.66675C7.7817 6.66675 7.33398 7.11446 7.33398 7.66675V12.3334C7.33398 12.8857 7.7817 13.3334 8.33398 13.3334H8.50065C9.05294 13.3334 9.50065 12.8857 9.50065 12.3334V7.66675C9.50065 7.11446 9.05294 6.66675 8.50065 6.66675H8.33398ZM12.5007 6.66675C11.9484 6.66675 11.5007 7.11446 11.5007 7.66675V12.3334C11.5007 12.8857 11.9484 13.3334 12.5007 13.3334H12.6673C13.2196 13.3334 13.6673 12.8857 13.6673 12.3334V7.66675C13.6673 7.11446 13.2196 6.66675 12.6673 6.66675H12.5007Z"
        fill={color}
      />
    </svg>
  );
};
