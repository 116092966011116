import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

interface Props {
  type: 'auto' | 'manual';
}

export const ModalBody = ({ type }: Props) => {
  return (
    <ModalBodyContainer>
      <ErrorIcon />
      {type === 'auto' && <ModalMainText>라이선스 자동 회수가 가능한 구독입니다.</ModalMainText>}
      {type === 'manual' && <ModalMainText>라이선스 수동 회수가 필요한 구독입니다.</ModalMainText>}
      {type === 'auto' && (
        <ModalSubText>
          해당 서비스와 연동하여
          <br /> 사용자 라이선스를 자동으로 회수합니다.
          <br /> 사용자는 더이상 서비스 사용이 불가능합니다.
        </ModalSubText>
      )}
      {type === 'manual' && (
        <ModalSubText type={type}>
          회수 완료 시, 사용자 리스트에서만 삭제됩니다.
          <br />
          해당 서비스에서 라이선스를 회수해 주세요.
        </ModalSubText>
      )}
    </ModalBodyContainer>
  );
};

const ErrorIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill="#FFEEF0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.5C17.4017 10.5 16.9166 10.985 16.9166 11.5833V20.25C16.9166 20.8483 17.4017 21.3333 18 21.3333C18.5983 21.3333 19.0833 20.8483 19.0833 20.25V11.5833C19.0833 10.985 18.5983 10.5 18 10.5ZM18 25.5C18.6904 25.5 19.25 24.9404 19.25 24.25C19.25 23.5596 18.6904 23 18 23C17.3096 23 16.75 23.5596 16.75 24.25C16.75 24.9404 17.3096 25.5 18 25.5Z"
        fill="#EA4A5A"
      />
    </svg>
  );
};

const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
`;

const ModalMainText = styled.span`
  margin-top: 20px;
  ${fonts.Headline7};
`;

const ModalSubText = styled.span<{ type?: string }>`
  margin-top: 8px;
  ${fonts.Body1};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
  color: ${props => props.theme.colors['text-gray-sub-darker']};
`;
