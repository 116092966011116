import { useQuery } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import IUserMonthlyBillingRepository from '@repositories/userMonthlyBillingRepository/IUserMonthlyBillingRepository';
import { MonthlyBillingInfoDTO } from '@repositories/userMonthlyBillingRepository/Types';
import { QueryUtil } from '@utils/queryUtil';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const userMonthlyBillingRepo = repository.get<IUserMonthlyBillingRepository>(KEYS.USER_MONTHLY_BILLING_REPOSITORY);

const userMonthlyBillingQueryKey = {
  all: ['userMonthlyBilling'] as const,
  details: () => [...userMonthlyBillingQueryKey.all, 'detail'] as const,
  detail: (subscriptionId: string, month: DateTimeString) =>
    [...userMonthlyBillingQueryKey.details(), subscriptionId, month] as const,
};

export const useGetMonthlyBillingInfo = (
  { subscriptionId, month }: { subscriptionId: string; month: DateTimeString },
  options?: UseQueryOptionsType<MonthlyBillingInfoDTO>,
) =>
  useQuery({
    queryKey: userMonthlyBillingQueryKey.detail(subscriptionId, month),
    queryFn: async () => {
      const monthlyBillingInfo = await userMonthlyBillingRepo.getMonthlyBillingInfo(subscriptionId, month);
      return monthlyBillingInfo;
    },
    ...options,
    enabled: QueryUtil.withRequired(!!subscriptionId && !!month, options?.enabled),
  });
