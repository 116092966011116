const defaultWidth = 14;
const defaultHeight = 14;

export const ThreeDotIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99935 3.49935C6.35502 3.49935 5.83268 2.97701 5.83268 2.33268C5.83268 1.68835 6.35502 1.16602 6.99935 1.16602C7.64368 1.16602 8.16602 1.68835 8.16602 2.33268C8.16602 2.97701 7.64368 3.49935 6.99935 3.49935ZM6.99935 8.16602C6.35502 8.16602 5.83268 7.64368 5.83268 6.99935C5.83268 6.35502 6.35502 5.83268 6.99935 5.83268C7.64368 5.83268 8.16602 6.35502 8.16602 6.99935C8.16602 7.64368 7.64368 8.16602 6.99935 8.16602ZM5.83268 11.666C5.83268 12.3103 6.35502 12.8327 6.99935 12.8327C7.64368 12.8327 8.16602 12.3103 8.16602 11.666C8.16602 11.0217 7.64368 10.4993 6.99935 10.4993C6.35502 10.4993 5.83268 11.0217 5.83268 11.666Z"
        fill={color}
      />
    </g>
  </svg>
);
