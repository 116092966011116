import type React from 'react';
import { forwardRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import logoSrc from '@images/logo_190x32.png';
import { useGetMonthlyBillingDetails } from '@queryHooks/useMonthlyBilling';
import { useGetUsageMetricLists } from '@queryHooks/useSoftware';
import { useGetSubscriptions } from '@queryHooks/useSubscription';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import type { TenantMonthlyBillingModel } from '@models/TenantMonthlyBillingModel';
import { MetricUsageCostModel } from '@models/billing/MetricUsageCostModel';
import type { UsageMetricModel } from '@models/softwareModels/UsageMetricModel';
import { SubscriptionBillingDetailView } from '../SubscriptionBillingDetail/layout/SubscriptionBillingDetailView';
import { TenantBillingDetailView } from './TenantBillingDetailView';

type Props = {
  tenantName: string;
  tenantBilling: TenantMonthlyBillingModel;
  onPrintStateChange: (state: 'loading' | 'fail' | 'ready') => void;
};

export const TenantBillingPrintView = forwardRef(
  ({ tenantName, tenantBilling, onPrintStateChange }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    const subscriptionBillingQueries = useGetMonthlyBillingDetails(
      tenantBilling?.subscriptionBillings.map(({ id }) => id) ?? [],
      {
        enabled: tenantBilling.subscriptionBillings.length > 0,
      },
    );
    const subscriptionBillings = subscriptionBillingQueries.reduce((accum, { isSuccess, data }) => {
      if (isSuccess) {
        accum.push(data);
      }
      return accum;
    }, [] as SubscriptionBillingModel[]);

    const subscriptionQueries = useGetSubscriptions(
      tenantBilling?.subscriptionBillings.map(({ subscription: { subscriptionId } }) => subscriptionId) ?? [],
      {
        enabled: tenantBilling.subscriptionBillings.length > 0,
      },
    );
    const subscriptions = subscriptionQueries.reduce((accum, { isSuccess, data }) => {
      if (isSuccess) {
        accum.push(data);
      }
      return accum;
    }, [] as SubscriptionModel[]);

    const usageMetricQueries = useGetUsageMetricLists(
      subscriptionBillings.map(({ subscription }) => subscription.software.id) ?? [],
      { enabled: subscriptionBillings.length > 0 },
    );
    const softwareUsageMetrics: { [softwareId: string]: UsageMetricModel[] } = usageMetricQueries.reduce(
      (accum, { isSuccess, data }) => {
        if (isSuccess && data.length > 0) {
          Object.assign(accum, { [data[0].softwareId]: data });
        }
        return accum;
      },
      {} as { [softwareId: string]: UsageMetricModel[] },
    );

    useEffect(() => {
      const { isMonthlyBillingLoading, isMonthlyBillingError } = subscriptionBillingQueries.reduce(
        (accum, { isLoading, isError }) => ({
          isMonthlyBillingLoading: accum.isMonthlyBillingLoading || isLoading,
          isMonthlyBillingError: accum.isMonthlyBillingError || isError,
        }),
        { isMonthlyBillingLoading: false, isMonthlyBillingError: false },
      );

      const { isUsageMetricsLoading, isUsageMetricsError } = usageMetricQueries.reduce(
        (accum, { isLoading, isError }) => ({
          isUsageMetricsLoading: accum.isUsageMetricsLoading || isLoading,
          isUsageMetricsError: accum.isUsageMetricsError || isError,
        }),
        { isUsageMetricsLoading: false, isUsageMetricsError: false },
      );

      const { isSubscriptionLoading, isSubscriptionError } = subscriptionQueries.reduce(
        (accum, { isLoading, isError }) => ({
          isSubscriptionLoading: accum.isSubscriptionLoading || isLoading,
          isSubscriptionError: accum.isSubscriptionError || isError,
        }),
        { isSubscriptionLoading: false, isSubscriptionError: false },
      );

      const isLoading = isMonthlyBillingLoading || isUsageMetricsLoading || isSubscriptionLoading;
      const isError = isMonthlyBillingError || isUsageMetricsError || isSubscriptionError;
      onPrintStateChange(isLoading ? 'loading' : isError ? 'fail' : 'ready');
    }, [onPrintStateChange, subscriptionBillingQueries, subscriptionQueries, usageMetricQueries]);

    return createPortal(
      <Container ref={ref}>
        <Month>{dateFormat(tenantBilling.month, '', 'YYYY.MM')}</Month>
        <Title>{t('Acc_Detail_18')}</Title>
        <Logo src={logoSrc} alt="logo" width={190} height={32} />
        <Divider />
        <TenantBillingDetailView tenantName={tenantName} tenantBilling={tenantBilling} />
        <ContentDivider />
        <Subtitle>{t('Acc_Detail_17')}</Subtitle>
        {subscriptionBillings.map(subscriptionBilling => {
          const usageMetrics = softwareUsageMetrics[subscriptionBilling.subscription.software.id];
          const metricUsageCosts = MetricUsageCostModel.createFromMetric(
            usageMetrics,
            subscriptionBilling?.moduleUsageCosts,
          );
          const subscription = subscriptions.find(({ id }) => subscriptionBilling.subscription.subscriptionId === id);

          return (
            !!subscription && (
              <SubscriptionBillingWrapper key={subscriptionBilling.id}>
                <SubscriptionBillingDetailView
                  subscription={subscription}
                  subscriptionBilling={subscriptionBilling}
                  metricUsageCosts={metricUsageCosts}
                />
              </SubscriptionBillingWrapper>
            )
          );
        })}
      </Container>,
      document.body,
    );
  },
);

const Container = styled.div`
  position: absolute;
  z-index: -1;
  visibility: hidden;

  @media print {
    z-index: 0;
    visibility: visible;
  }
`;

const Month = styled.div`
  ${fonts.Headline7}
  margin: 0px 0px 4px 4px;
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const Title = styled.div`
  ${fonts.Headline3}
  margin-bottom: 52px;
`;

const Logo = styled.img`
  position: absolute;
  top: 30px;
  right: 0px;
  width: 190px;
  height: 32px;
`;

const Divider = styled.hr`
  border: 2px solid ${({ theme: { colors } }) => colors['border-gray-darker']};
  margin-bottom: 48px;
`;

const ContentDivider = styled.hr`
  border: 2px solid ${({ theme: { colors } }) => colors['border-gray-darker']};
  margin: 140px 0px 24px;
`;

const Subtitle = styled.div`
  ${fonts.Headline3}
  margin-bottom: 72px;
`;

const SubscriptionBillingWrapper = styled.div`
  margin-bottom: 80px;

  @media print {
    page-break-inside: avoid;
  }
`;
