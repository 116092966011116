import { useEffect, useRef, useState } from 'react';

type Props = IntersectionObserverInit & {
  freezeOnceVisible?: boolean;
  onIntersect?: (entry: IntersectionObserverEntry) => void;
};

export const useIntersectionObserver = ({
  threshold = 0,
  root = null,
  rootMargin = '0%',
  freezeOnceVisible = false,
  onIntersect,
}: Props = {}): [(node: HTMLDivElement | null) => void, IntersectionObserverEntry | undefined] => {
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>();
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const onIntersectRef = useRef(onIntersect);
  useEffect(() => {
    if (typeof onIntersect === 'function') {
      onIntersectRef.current = onIntersect;
    }
  }, [onIntersect]);

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);

    if (typeof onIntersectRef.current === 'function') {
      onIntersectRef.current(entry);
    }
  };

  useEffect(() => {
    const isSupportIntersectionObserver = !!window.IntersectionObserver;

    let observer: IntersectionObserver | null = null;
    if (targetRef && isSupportIntersectionObserver && !frozen) {
      const observerParams = { threshold, root, rootMargin };
      observer = new IntersectionObserver(updateEntry, observerParams);

      observer.observe(targetRef);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [root, rootMargin, frozen, threshold, targetRef]);

  return [setTargetRef, entry];
};
