import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, TableRow, Tooltip, useTheme, Box, TableCell as MuiTableCell } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { StatusChip } from '@pages/customer/common/statusChip/StatusChip';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import type { SubscriptionUseRequestStatus } from '@repositories/subscriptionUseRequestRepository/Types';
import type { SubscriptionUseRequestModel } from '@models/subscription/SubscriptionUseRequestModel';

interface Props {
  idx: number;
  data: SubscriptionUseRequestModel;
}

export const ApplyProductRow = observer(({ data, idx }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { categoryStore } = useStore();
  const navigate = useNavigate();

  const rootCategory = categoryStore.findRootCategory(data.categoryId);

  const handleClickRow = () => {
    navigate(`/subscriptions/usage-apply/${data.id}`);
  };

  const getDisplayStatus = (status: SubscriptionUseRequestStatus, bg?: boolean, font?: boolean) => {
    if (status === 'APPROVED') {
      if (bg) return theme.colors['bg-green'];
      if (font) return theme.colors['text-white'];
      return t('Subscrib_Detail_UseRequest_12');
    }
    if (status === 'CREATED') {
      if (bg) return theme.colors['bg-pink'];
      if (font) return theme.colors['text-white'];
      return t('Subscrib_Detail_UseRequest_10');
    }
    if (status === 'PENDING') {
      if (bg) return theme.colors['bg-yellow'];
      if (font) return theme.colors['text-gray-sub-darker'];
      return t('Subscrib_Detail_UseRequest_11');
    }
    if (bg) return theme.colors['bg-gray-dark'];
    if (font) return theme.colors['text-gray-sub-darker'];
    return t('Subscrib_Detail_UseRequest_13');
  };

  return (
    <TableRow onClick={handleClickRow}>
      <TableCell align="center">{idx}</TableCell>
      <TableCell>{dayjs(data.createdDate).format(t('DateFormat_YMD'))}</TableCell>
      <TableCell>{rootCategory?.name ?? '-'}</TableCell>
      <TableCell>
        <Tooltip
          title={data.productName}
          arrow
          placement="bottom-start"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                maxWidth: 'none',
              },
              '& .MuiTooltip-arrow': {
                transform: 'translate(20px, 0) !important',
              },
            },
          }}
        >
          <Product>
            <Avatar src={data.productIconUrl} sx={{ width: '16px', height: '16px', borderRadius: '4px' }} />
            <span>{data.productName}</span>
          </Product>
        </Tooltip>
      </TableCell>
      <TableCell>{data.planName}</TableCell>

      <TableCell>
        {data.status === 'APPROVED' || data.status === 'REJECTED'
          ? dayjs(data.updateDate).format(t('DateFormat_YMD'))
          : '-'}
      </TableCell>
      <TableCell>
        <StatusChip
          status={getDisplayStatus(data.status)}
          backgroundColor={getDisplayStatus(data.status, true)}
          color={getDisplayStatus(data.status, false, true)}
        />
      </TableCell>
    </TableRow>
  );
});

const TableCell = styled(MuiTableCell)`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Product = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
