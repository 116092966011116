import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import { Tooltip } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { numberFormat } from '@utils/numberFormat';
import { HorizontalProgressBar } from './HorizontalProgressBar';

type Props = {
  index: number;
  iconUrl: string;
  name: string;
  userCount: number;
  progressPercentage: number;
  progressColor: {
    start: string;
    end: string;
  };
};
export const BigCardContent = ({ index, iconUrl, name, userCount, progressPercentage, progressColor }: Props) => {
  return (
    <Container>
      <div className="rank">{index + 1}</div>
      <Icon src={iconUrl} variant="square" />
      <div className="info">
        <Tooltip label={name} visibleOnlyEllipsis placement="top-center">
          <Name>{name}</Name>
        </Tooltip>
        <UserCountWrapper>
          <HorizontalProgressBar percentage={progressPercentage} color={progressColor} />
          <UserCount>{numberFormat({ num: userCount })}명</UserCount>
        </UserCountWrapper>
      </div>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 340px;
  height: 88px;
  padding: 23px 15px;
  background-color: ${props => props.theme.colors['bg-white']};
  border: 0.5px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 7px;
  box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;

  & .rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid ${props => props.theme.colors['border-gray-light']};
    border-radius: 3px;
    ${fonts.Headline10};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
    background-color: ${props => props.theme.colors['bg-white']};
    margin-right: 16px;
    flex-shrink: 0;
  }
  & .info {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    overflow: hidden;
    flex-grow: 1;
  }
`;

const Name = styled.div`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserCount = styled.span`
  ${fonts.Headline8};
  margin-bottom: 3px;
  margin-left: 16px;
  flex-shrink: 0;
`;

const Icon = styled(Avatar)`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid ${({ theme: { colors } }) => colors['border-bk-opacity-10%']};
    border-radius: 4px;
  }
`;
