import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { ConnectionResp } from '@repositories/subscriptionConnectionRepository/Types';
import { TenantMemberSimple } from '@repositories/userRepository';
import { Page } from '@type/Page';
import ITenantRepository from './ITenantRepository';
import {
  Filters,
  MyTenantFilter,
  Tenant,
  TenantAddMemberRq,
  TenantAddMemberRs,
  TenantCreateRq,
  TenantList,
  TenantMemberDTO,
  TenantMemberFilter,
  TenantMemberRoleRs,
  TenantMemberRoleUpdateRq,
  TenantMemberStatusUpdateDTO,
  TenantSimple,
  TenantUpdateDTO,
} from './Types';
import 'reflect-metadata';

@injectable()
export class TenantRepository implements ITenantRepository {
  async getSMPConnections({ tenantId }: { tenantId: string }): Promise<ConnectionResp> {
    const result = await API.get<ConnectionResp>(`/api/v1/tenants/${tenantId}/connections`);
    return result.data;
  }

  async getMember(id: string, userId: string): Promise<TenantMemberSimple> {
    const result = await API.get<TenantMemberSimple>(`/api/v1/tenants/${id}/members/${userId}`);
    return result.data;
  }
  async deleteMemberUsingFlag(id: string, userId: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/tenants/${id}/members/${userId}`);
    return result.data;
  }
  async updateMemberStatus(
    tenantId: string,
    userId: string,
    rqData: TenantMemberStatusUpdateDTO,
  ): Promise<TenantMemberDTO> {
    const result = await API.patch<TenantMemberDTO>(`/api/v1/tenant/${tenantId}/members/${userId}/status`, rqData);
    return result.data;
  }
  updateWith(/* id: string, data: TenantMemberRoleUpdateRq */): Promise<TenantMemberRoleRs> {
    throw new Error('Method not implemented.');
  }
  async getMyTenants(queries?: MyTenantFilter): Promise<TenantSimple[]> {
    const queryString = qs.stringify(queries);
    const result = await API.get<TenantSimple[]>(`/api/v1/my-tenants?${queryString}`);
    return result.data;
  }

  async getMembers(id: string, queries?: TenantMemberFilter): Promise<Page<TenantMemberSimple>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat' });
    const result = await API.get<Page<TenantMemberSimple>>(`/api/v1/tenants/${id}/members?${queryString}`);
    return result.data;
  }

  async addMembers(id: string, data: TenantAddMemberRq[]): Promise<TenantAddMemberRs> {
    const result = await API.post<TenantAddMemberRs>(`/api/v1/tenants/${id}/members`, data);
    return result.data;
  }

  async deleteMember(id: string, userId: string): Promise<TenantAddMemberRs> {
    const result = await API.delete<TenantAddMemberRs>(`/api/v1/tenants/${id}/members/${userId}`);
    return result.data;
  }

  async updateMemberRole(id: string, data: TenantMemberRoleUpdateRq): Promise<TenantMemberRoleRs> {
    const result = await API.put<TenantMemberRoleRs>(`/api/v1/tenants/${id}/member-roles`, data);
    return result.data;
  }

  async updateWithFile(id: string, tenant: TenantCreateRq, logoImage: Blob): Promise<Tenant> {
    const formData = new FormData();
    if (logoImage) {
      formData.append('logoImage', logoImage);
    }
    formData.append('tenant', new Blob([JSON.stringify(tenant)], { type: 'application/json' }));
    const result = await API.post<Tenant>(`/api/v1/tenants/${id}`, formData);
    return result.data;
  }

  async getOne(id: string): Promise<Tenant> {
    const result = await API.get<Tenant>(`/api/v1/tenants/${id}`);
    return result.data;
  }

  async getList(queries?: Filters): Promise<Page<TenantList>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<TenantList>>(`/api/v1/tenants?${queryString}`);
    return result.data;
  }

  async create(data: TenantCreateRq): Promise<Tenant> {
    const result = await API.post<Tenant>(`/api/v1/tenants`, data);
    return result.data;
  }

  async update(id: TenantId, data: TenantUpdateDTO): Promise<Tenant> {
    const result = await API.put<Tenant>(`/api/v1/tenants/${id}`, data);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    await API.delete<void>(`/api/v1/tenants/${id}`);
  }
}
