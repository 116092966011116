import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import uiStore from '@stores/UiStore';
import { KEYS, repository } from '@repositories/Repository';
import type ISubscriptionConnectionRepository from '@repositories/subscriptionConnectionRepository/ISubscriptionConnectionRepository';
import type {
  Filter,
  SubscriptionConnectionConnectDTO,
  SubscriptionConnectionCreateDTO,
  SubscriptionConnectionUpdateDTO,
} from '@repositories/subscriptionConnectionRepository/Types';
import type { Page } from '@type/Page';
import { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';
import { sbQueryKey } from './useSubscription';

const sbConRepo = repository.get<ISubscriptionConnectionRepository>(KEYS.SUBSCRIPTION_CONNECTION_REPOSITORY);
export const sbConQueryKey = {
  all: ['subscriptionConnection'] as const,
  lists: () => [...sbConQueryKey.all, 'list'] as const,
  list: (filter?: Filter) => [...sbConQueryKey.lists(), filter] as const,
  details: () => [...sbConQueryKey.all, 'detail'] as const,
  detail: (subscriptionId: string) => [...sbConQueryKey.details(), subscriptionId] as const,
  oauth: (subscriptionId: string) => ['oauth', subscriptionId] as const,

  connect: (subscriptionId: string) => [...sbConQueryKey.all, 'connect', subscriptionId] as const,
};

export const useGetSubscriptionConnections = (
  queries?: Filter,
  options?: UseQueryOptionsType<Page<SubscriptionConnectionModel>>,
) => {
  const result = useQuery<Page<SubscriptionConnectionModel>, AxiosError>(
    sbConQueryKey.list(queries),
    async () => {
      const result = await sbConRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionConnectionModel(dto)),
      };
    },
    options,
  );

  return result;
};

export const useGetSubscriptionConnection = (
  subscriptionId: string,
  options?: UseQueryOptionsType<SubscriptionConnectionModel>,
) => {
  const result = useQuery<SubscriptionConnectionModel, AxiosError>(
    sbConQueryKey.detail(subscriptionId),
    async () => {
      const result = await sbConRepo.getOne(subscriptionId);
      return new SubscriptionConnectionModel(result);
    },
    options,
  );

  return result;
};

export const useAddSubscriptionConnection = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: SubscriptionConnectionCreateDTO) => {
      const result = await sbConRepo.create(rqData.subscriptionId, rqData);
      return new SubscriptionConnectionModel(result);
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(sbConQueryKey.all);
        queryClient.invalidateQueries(sbQueryKey.list());
        queryClient.invalidateQueries(sbQueryKey.detail(data.subscription.id));
      },
    },
  );

  return result;
};

export const useDeleteSubscriptionConnection = (subscriptionId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async () => {
      const result = await sbConRepo.delete(subscriptionId);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sbConQueryKey.lists());
        queryClient.removeQueries(sbQueryKey.detail(subscriptionId));
      },
    },
  );

  return result;
};

export const useUpdateSubscriptionConnection = (subscriptionId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: SubscriptionConnectionUpdateDTO) => {
      const result = await sbConRepo.update(rqData.subscriptionId, rqData);
      return new SubscriptionConnectionModel(result);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sbConQueryKey.detail(subscriptionId));
      },
    },
  );

  return result;
};

export const useConnect = (subscriptionId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: subscriptionId ? sbConQueryKey.connect(subscriptionId) : undefined,
    mutationFn: async (rqData: SubscriptionConnectionConnectDTO) => {
      const result = await sbConRepo.connect(rqData.subscriptionId ?? '', rqData);
      return new SubscriptionConnectionModel(result);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbConQueryKey.all);
      queryClient.invalidateQueries(sbQueryKey.all);
    },
  });
};
export const useConnectIsMutating = (subscriptionId: string) => useIsMutating(sbConQueryKey.connect(subscriptionId));

export const useDisconnect = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (subscriptionId: string) => {
      const result = await sbConRepo.disconnect(subscriptionId);
      return new SubscriptionConnectionModel(result);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbConQueryKey.all);
      uiStore.toastStore.open(t('Subscrib_Connect_Create_16'));
    },
  });
};

export const useSynchronizeConnectionStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (subscriptionId: string) => {
      const result = await sbConRepo.synchronizeStatus(subscriptionId);
      return new SubscriptionConnectionModel(result);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbConQueryKey.all);
    },
  });
};

export const useConnectWithOauth = (subscriptionId: string, options?: UseQueryOptionsType<string>) => {
  const result = useQuery<string, AxiosError>(
    sbConQueryKey.oauth(subscriptionId),
    async () => {
      const result = await sbConRepo.connectWithOauth(subscriptionId);
      return result;
    },
    options,
  );

  return result;
};
