import { useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';
import { useUiStore } from '@stores/UiStore';
import { MonthlyBillingInfoDTO } from '@repositories/userMonthlyBillingRepository/Types';
import { useGenerateMonthlyBilling } from '@queryHooks/useMonthlyBilling';
import { dateFormat } from '@utils/dateUtil';
import { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import { DetailSettingStep } from '../../../AddExistingBillingModal';
import { BillingAttachFile } from '../BillingAttachFile';
import { GenerateMonthlyBillingForm, useGenerateBillingForm } from '../validateSchema';
import { BillingGenerateForm } from './BillingGenerateForm';
import { BillingGenerateResult } from './BillingGenerateResult';

type Props = {
  step: DetailSettingStep;
  selectedMonth: Dayjs;
  subscription: SubscriptionModel;
  billingInfo: MonthlyBillingInfoDTO;
  onNextStep: () => void;
  onClose: () => void;
};
export const BillingGenerate = ({ step, selectedMonth, subscription, billingInfo, onNextStep, onClose }: Props) => {
  const { t } = useTranslation();
  const { toastStore } = useUiStore();
  const [generatedMonthlyBilling, setGeneratedMonthlyBilling] = useState<SubscriptionBillingModel | null>(null);

  const methods = useGenerateBillingForm();
  const { handleSubmit } = methods;
  const { mutate: generate } = useGenerateMonthlyBilling();

  const handleSuccess: SubmitHandler<GenerateMonthlyBillingForm> = formData => {
    generate(
      {
        month: dateFormat(selectedMonth, undefined, 'YYYY-MM'),
        subscriptionId: subscription.id,
        paymentDate: dateFormat(formData.paymentDate, undefined, 'YYYY-MM-DD'),
        currencyUnit: formData.currencyUnit,
        exchangeRate: formData.exchangeRate,
      },
      {
        onSuccess: (generated: SubscriptionBillingModel) => {
          setGeneratedMonthlyBilling(generated);
          onNextStep();
          toastStore.open({ message: t('Acc_Create_15'), hideAction: true });
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      {(step === DetailSettingStep.GENERATE || step === DetailSettingStep.GENERATE_ATTACH_FILE) && (
        <GenerateForm id="billing-form" onSubmit={handleSubmit(handleSuccess)}>
          {step === DetailSettingStep.GENERATE && selectedMonth && subscription && billingInfo && (
            <BillingGenerateForm
              selectedMonth={selectedMonth}
              subscription={subscription}
              billingInfo={billingInfo}
              onNextStep={onNextStep}
              onClose={onClose}
            />
          )}
          {step === DetailSettingStep.GENERATE_ATTACH_FILE && generatedMonthlyBilling && (
            <BillingAttachFile addedMonthlyBilling={generatedMonthlyBilling} onClose={onClose} />
          )}
        </GenerateForm>
      )}
      {step === DetailSettingStep.GENERATE_RESULT && generatedMonthlyBilling && (
        <BillingGenerateResult generatedBilling={generatedMonthlyBilling} onClose={onClose} />
      )}
    </FormProvider>
  );
};

const GenerateForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
