import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { useGetSubscription } from '@queryHooks/useSubscription';
import { BasicInfoTabPanel } from './BasicInfoTabPanel';
import { ExtraInfoTabPanel } from './ExtraInfoTabPanel';

type Props = {
  open: boolean;
  onClose: () => void;
  subscriptionId: string;
};
export const SubscriptionInfoModal = ({ open, onClose, subscriptionId }: Props) => {
  const [curTab, setCurTab] = useState(0);

  const { data: subscription } = useGetSubscription(subscriptionId, { enabled: open });

  useEffect(() => {
    if (open) {
      setCurTab(0);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} title="구독 정보" size="large" height={832}>
      <SquaredTabs
        variant="fullWidth"
        value={curTab}
        onChange={(_, value) => setCurTab(value)}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <SquaredTab value={0} label="기본 정보" disableRipple />
        <SquaredTab value={1} label="추가 정보" disableRipple />
      </SquaredTabs>
      {subscription && curTab === 0 && <BasicInfoTabPanel subscription={subscription} />}
      {subscription && curTab === 1 && <ExtraInfoTabPanel subscription={subscription} />}
      <Dialog.Footer>
        <FooterButton variant="contain" size="extraLarge" onClick={onClose}>
          확인
        </FooterButton>
      </Dialog.Footer>
    </Dialog>
  );
};

const FooterButton = styled(Button)`
  max-width: 464px;
`;

const SquaredTabs = styled(Tabs)`
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border-radius: 8px;
  padding: 4px;
  min-height: 0px;
  & .MuiTabs-flexContainer {
    gap: 6px;
  }
`;

const SquaredTab = styled(Tab)`
  border-radius: 5px;
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  padding: 8px 0px;
  min-height: 0px;
  ${fonts.Button4}
  &.Mui-selected {
    background-color: ${({ theme: { colors } }) => colors['bg-white']};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;
