import { Button } from '@components/index';
import styled from '@emotion/styled';
import { css } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const ToggleButton = styled(Button)`
  width: 16px;
  height: 16px;
  background: none;
  border: none;
`;

export const OptionItem = styled('li')<{ depth?: number; $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  ${fonts.Body2}
  color: ${({ theme }) => theme.colors['text-gray-main']};
  padding: 10px 7px;
  padding-left: ${({ depth }) => (depth ? depth * 20 + 7 : 7)}px;
  cursor: pointer;
  border-radius: 2px;
  ${({ $isSelected, theme: { colors } }) =>
    $isSelected &&
    css`
      background-color: ${colors['state-white-hover']};
    `};
  &:hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
`;
