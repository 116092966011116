import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Modal, Select, useTheme, Box, MenuItem, Snackbar, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { Label, Button } from '@components/index';
import authStore from '@stores/AuthStore';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import { DashIcon } from '@icons/DashIcon';
import { XIcon } from '@icons/XIcon';
import { useGetProductPlanList } from '@queryHooks/useSoftware';
import { useUpdateSubscriptionUseRequest } from '@queryHooks/useSubscriptionUseRequest';
import { transientOptions } from '@utils/CommonUtil';
import { SubscriptionUseRequestDetailModel } from '@models/subscription/SubscriptionUseRequestDetailModel';
import { FormUseRequest, useFormUser } from './validationSchema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  swId: string;
  modifyData?: SubscriptionUseRequestDetailModel;
}

export const UsageRequestEditModal = ({ isOpen, onClose, swId, modifyData }: Props) => {
  const { t } = useTranslation();
  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const { mutate: updateApply } = useUpdateSubscriptionUseRequest();
  const { data: plans } = useGetProductPlanList(swId, { enabled: isOpen });
  const theme = useTheme();
  const { register, handleSubmit, reset, control, formState, trigger, watch } = useFormUser();

  const [selectedStartDate] = watch(['startDate']);

  const handleStartCalrendarOpen = () => {
    setIsStartCalendarOpen(!isStartCalendarOpen);
  };
  const handleEndCalrendarOpen = () => {
    setIsEndCalendarOpen(!isEndCalendarOpen);
  };
  const handleToast = {
    open: () => {
      setToastOpen(true);
    },
    close: () => {
      setToastOpen(false);
    },
  };

  const handleDateChange =
    (onChange: UnknownFunction, type: 'startDate' | 'endDate') =>
    (value: string | null, keyboardInputValue?: string) => {
      if (keyboardInputValue && dayjs(keyboardInputValue, 'MM DD, YYYY', true).isValid()) {
        onChange(dayjs(keyboardInputValue));
      } else {
        onChange(value, keyboardInputValue);
      }
      if (type === 'endDate') {
        trigger('startDate');
      }
    };

  const validation = {
    success: (data: FormUseRequest) => {
      const rqData = {
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
        inquiry: data.inquiry.replaceAll(' ', '') === '-' ? '' : data.inquiry.trimEnd(),
        productPlanId: data.productPlanId,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        tenantId: authStore.curTenant.id,
      };

      if (modifyData) {
        updateApply(
          { requestId: modifyData.id, rqData },
          {
            onSuccess: () => {
              handleToast.open();
              onClose();
            },
          },
        );
      }
    },
  };

  useEffect(() => {
    if (modifyData) {
      reset({
        startDate: modifyData?.startDate,
        endDate: modifyData?.endDate,
        inquiry: modifyData?.inquiry.replaceAll(' ', '') ? modifyData?.inquiry.trimEnd() : '-',
        productPlanId: modifyData?.productPlanId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const MenuProps = {
    PaperProps: {
      sx: {
        width: 344,
        boxShadow: 'none',
        borderRadius: '0 0 2px 2px',
        left: '50% !important',
        transform: 'translate(-50%, 10px) !important',
      },
    },
  };
  const toastMessage = <>사용 신청을 완료했습니다.</>;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{ '& .MuiBackdrop-root': { background: `${theme.colors['bg-bk-opacity-40%']}` } }}
      >
        <ModalWrapper onSubmit={handleSubmit(validation.success)}>
          <Header>
            {t('Product_Cardview_01')}
            <IconButton onClick={onClose}>
              <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
            </IconButton>
          </Header>
          <Body>
            <Element className="plan-element">
              <strong className="name">
                {t('Subscrib_Main_12')}
                <span>*</span>
              </strong>
              <Controller
                control={control}
                name="productPlanId"
                render={({ field: { onChange, value = '' } }) => (
                  <Select
                    size="large"
                    fullWidth
                    displayEmpty
                    value={value}
                    sx={{ height: '40px' }}
                    onChange={onChange}
                    renderValue={(value: string) => {
                      const findPlanIdInfor = plans?.find(plan => plan.planId === value);
                      return value !== '' ? (
                        <>
                          <SelectedTitle>{plans?.find(plan => plan.planId === value)?.planName}</SelectedTitle>
                          {findPlanIdInfor?.isInUseByTenant ? (
                            <Label className="usebytenant" variant="contain" color="gradient">
                              {t('Ad_Store_Product_State')}
                            </Label>
                          ) : null}
                        </>
                      ) : (
                        <strong>{t('Product_Use_Request_02')}</strong>
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {plans?.map(plan => {
                      return (
                        <SelectItem key={nanoid()} value={plan.planId}>
                          <span className="plan-name-wrapper">
                            <span className="plan-name">{plan.planName}</span>
                            {!plan.isBasicPlan && <CustomCard>custom</CustomCard>}
                          </span>
                          {plan.isInUseByTenant ? (
                            <Label variant="contain" color="gradient">
                              {t('Ad_Store_Product_State')}
                            </Label>
                          ) : null}
                        </SelectItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Element>
            <Element>
              <strong className="name">
                {t('Acc_Detail_04')}
                <span>*</span>
              </strong>
              <DateRangeField>
                <Picker>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DesktopDatePicker
                          inputFormat={t('DateFormat_YMD')}
                          value={value}
                          onChange={handleDateChange(onChange, 'startDate')}
                          open={isStartCalendarOpen}
                          onClose={() => {
                            setIsStartCalendarOpen(false);
                          }}
                          PaperProps={{
                            sx: {
                              position: 'absolute',
                              top: 14,
                              transform: 'translate(-25%, 0) !important',
                              left: 10,
                              borderRadius: '2px',
                              border: `1px solid ${theme.colors['border-gray-lighter']}`,
                              boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
                              '& .MuiCalendarOrClockPicker-root': {
                                margin: '10px 0 0',
                              },
                              '& .MuiPickersDay-root': {
                                '&.Mui-selected': {
                                  backgroundColor: `${theme.colors['bg-purple-lighter']} !important`,
                                  color: `${theme.colors['text-purple-light']}`,
                                  '&:hover': {
                                    backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                                  },
                                },
                                '&:hover': {
                                  backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                                },
                              },
                            },
                          }}
                          showDaysOutsideCurrentMonth
                          renderInput={params => (
                            <DatePickerTextField
                              {...params}
                              size="large"
                              fullWidth
                              error={!!formState.errors.startDate}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: t('DateFormat_YMD') === 'YYYY.MM.DD' ? 'YYYY.MM.DD' : 'MM DD,YYYY',
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Button variant="text" onClick={handleStartCalrendarOpen}>
                                    <CalendarStartIcon
                                      width={16}
                                      height={16}
                                      color={
                                        isStartCalendarOpen
                                          ? theme.colors['ic-purple-light']
                                          : theme.colors['ic-gray-dark']
                                      }
                                    />
                                  </Button>
                                ),
                              }}
                              $isStartCalendarOpen={isStartCalendarOpen}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  <DashIcon width={10} height={10} color={theme.colors['ic-gray-dark']} />
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field: { onChange, value } }) => (
                      <DesktopDatePicker
                        inputFormat={t('DateFormat_YMD')}
                        value={value}
                        onChange={handleDateChange(onChange, 'endDate')}
                        open={isEndCalendarOpen}
                        onClose={() => {
                          setIsEndCalendarOpen(false);
                        }}
                        showDaysOutsideCurrentMonth
                        shouldDisableDate={day => {
                          const disableDate = dayjs(selectedStartDate).add(1, 'D');
                          return dayjs.isDayjs(day) ? day.isBefore(disableDate) : false;
                        }}
                        PaperProps={{
                          sx: {
                            position: 'absolute',
                            top: 14,
                            transform: 'translate(-25%, 0) !important',
                            left: 10,
                            borderRadius: '2px',
                            border: `1px solid ${theme.colors['border-gray-lighter']}`,
                            boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
                            '& .MuiCalendarOrClockPicker-root': {
                              margin: '10px 0 0',
                            },
                            '& .MuiPickersDay-root': {
                              '&.Mui-selected': {
                                backgroundColor: `${theme.colors['bg-purple-lighter']} !important`,
                                color: `${theme.colors['text-purple-light']}`,
                                '&:hover': {
                                  backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                                },
                              },
                              '&:hover': {
                                backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                              },
                            },
                          },
                        }}
                        renderInput={params => (
                          <DatePickerTextField
                            {...params}
                            size="large"
                            fullWidth
                            error={!!formState.errors.endDate || formState.errors.startDate?.message === t('Date_02')}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: t('DateFormat_YMD') === 'YYYY.MM.DD' ? 'YYYY.MM.DD' : 'MM DD,YYYY',
                            }}
                            InputProps={{
                              endAdornment: (
                                <Button variant="text" onClick={handleEndCalrendarOpen}>
                                  <CalendarStartIcon
                                    width={16}
                                    height={16}
                                    color={
                                      isEndCalendarOpen ? theme.colors['ic-purple-light'] : theme.colors['ic-gray-dark']
                                    }
                                  />
                                </Button>
                              ),
                            }}
                            $isEndCalendarOpen={isEndCalendarOpen}
                          />
                        )}
                      />
                    )}
                  />
                </Picker>
                <p className="detail">{t('Product_Use_Request_06')}</p>
                {formState.errors.startDate || formState.errors.endDate ? (
                  <ErrorTextField>
                    <ErrorText>{formState.errors.startDate?.message}</ErrorText>
                    <ErrorText>{formState.errors.endDate?.message}</ErrorText>
                  </ErrorTextField>
                ) : null}
              </DateRangeField>
            </Element>
            <Element>
              <strong className="name">
                {t('Product_Use_Request_07')}
                <span>*</span>
              </strong>
              <StyledTextField
                {...register('inquiry')}
                multiline
                rows={9}
                autoFocus
                placeholder={t('Product_Use_Request_08')}
              />
            </Element>
          </Body>
          <SubmitButton variant="contain" size="extraLarge" type="submit">
            {t('Ad_Store_Purchase_Request_03')}
          </SubmitButton>
        </ModalWrapper>
      </Modal>
      <ToastPopup
        open={toastOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleToast.close}
        message={toastMessage}
        action={
          <ToastCloseButton variant="text" size="small" onClick={handleToast.close}>
            {t('Member_User_24')}
          </ToastCloseButton>
        }
      />
    </>
  );
};

const ModalWrapper = styled.form`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  overflow: hidden;
  z-index: 1050;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 6px 30px 5px rgba(0, 0, 0, 0.05);
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  padding: 18px 20px 16px 28px;
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 12px 28px 0;
  width: 100%;
  & .plan-element {
    & .MuiInputBase-root {
      padding: 6px 8px 6px 12px;
    }
  }
`;

const Element = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  & .name {
    ${fonts.Headline8};
    margin-bottom: 4px;
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & > span {
      margin-left: 2px;
      color: ${({ theme: { colors } }) => colors['ic-red-light']};
    }
  }
  & .detail {
    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
  &:last-of-type {
    margin-bottom: 28px;
  }
  & .MuiInputBase-root {
    padding: 10px 12px;
  }
  & .MuiInputBase-root textarea::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
  & .MuiSelect-select strong {
    ${fonts.Button4};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

const StyledTextField = styled(TextField)`
  background: ${({ theme }) => theme.colors['bg-gray-lighter']};
  color: red;
  & .MuiInputBase-multiline textarea {
    height: 198px !important;
  }
`;

const DateRangeField = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Picker = styled(Box)`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 4px;
  & > svg {
    flex-shrink: 0;
  }
  & > .MuiFormControl-root {
    width: 50%;
  }
`;

const ErrorText = styled('p')`
  ${fonts.Body4};
  color: ${({ theme }) => theme.colors['text-red-light']};
  &.plan {
    margin-top: 4px;
  }
`;

const SubmitButton = styled(Button)`
  width: calc(100% - 56px);
  margin: 0 28px 28px;
  padding: 8px 24px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors['bg-purple']};
  border-image-slice: 1;
`;

const SelectItem = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 23px;
  padding: 10px 4px 10px 7px;
  align-items: center;
  max-width: 414px;
  & .plan-name-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    max-width: 314px;
    & .plan-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const CustomCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  ${fonts.Caption4}
  color: ${props => props.theme.colors['text-gray-sub-darker']};
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 2px;
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
`;

const ToastPopup = styled(Snackbar)`
  & .MuiPaper-root {
    width: 481px;
    padding: 14px 24px 14px 24px;
    background-color: ${({ theme }) => theme.colors['bg-bk-opacity-85%']};
    border-radius: 3px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
  }
  & .MuiSnackbarContent-message {
    padding: 0;
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-white']};
  }
  & .MuiSnackbarContent-action {
    padding: 0;
    margin: 0;
    margin-left: auto;
  }
`;

const ToastCloseButton = styled(Button)`
  ${fonts.Body2};
  color: ${colorDefinition.blue[400]};
`;
const SelectedTitle = styled.strong`
  & ~ span {
    margin-left: 4px;
  }
`;
const ErrorTextField = styled.div`
  display: flex;
  position: relative;

  & p + p {
    position: absolute;
    left: 184px;
  }
`;
const DatePickerTextField = styled(TextField, transientOptions)<{
  $isStartCalendarOpen?: boolean;
  $isEndCalendarOpen?: boolean;
}>`
  & .MuiInputBase-root {
    padding: 10px 12px;
    & .MuiInputBase-input {
      height: auto;
      padding: 0;
      ${fonts.Body2};
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ $isStartCalendarOpen, $isEndCalendarOpen, theme: { colors } }) =>
        $isStartCalendarOpen
          ? colors['ic-purple-light']
          : $isEndCalendarOpen
          ? colors['ic-purple-light']
          : colors['border-gray-light']};
    }
  }
`;
