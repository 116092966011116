import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Box,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { RoutingTabs } from '@components/tab';
import { useStore } from '@stores/RootStore';
import { BulletIcon } from '@icons/BulletIcon';
import { ChevronIcon } from '@icons/ChevronIcon';
import { SearchIcon } from '@icons/SearchIcon';
import { TenantMemberFilter } from '@repositories/tenantRepository/Types';
import { useGetTenantMembers } from '@queryHooks/useTenant';
import i18n from '@locales/i18n';
import { ManagerTableRow } from './ManagerTableRow';
import { ManagerAddFromUser } from './managerAdd';
import { ExternalManagerAddFromModal } from './managerAdd/ExternalManagerAddFromModal';

type MenuAnchor = {
  register: null | HTMLElement;
};

type SearchType = 'name' | 'email' | 'usergroup' | 'phone';

export const ManagerConfig = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    authStore,
    uiStore: {
      managerAddFromUserStore: managerAddFromUserModal,
      externalManagerAddFromModalStore: externalManagerAddFromModal,
    },
  } = useStore();

  const [anchorEl, setAnchorEl] = useState<MenuAnchor>({ register: null });
  const registerMenuOpen = Boolean(anchorEl.register);

  const [searchType, setSearchType] = useState<SearchType>('name');
  const [filters, setFilters] = useState<TenantMemberFilter>({
    size: 10000,
    memberRole: ['GENERAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'],
  });

  const menuHandler = {
    registerOpen: (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(prev => {
        return { ...prev, register: event.currentTarget };
      });
    },
    registerClose: (event: React.MouseEvent<HTMLLIElement>) => {
      if (event.currentTarget.id === 'user') managerAddFromUserModal.open();
      setAnchorEl(prev => {
        return { ...prev, register: null };
      });
      if (event.currentTarget.id === 'user') externalManagerAddFromModal.open();
      setAnchorEl(prev => {
        return { ...prev, register: null };
      });
    },
  };

  const debounceOnChangeSearch = _.debounce((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (searchType === 'name') {
      setFilters({ name: e.target.value });
    }
    if (searchType === 'email') {
      setFilters({ username: e.target.value });
    }
  }, 500);

  const { data: members } = useGetTenantMembers(authStore.curTenant.id, filters);

  const handleChangeModal = {
    open: () => {
      setIsModalOpen(true);
      setAnchorEl(prev => {
        return { ...prev, register: null };
      });
    },
    close: () => {
      setIsModalOpen(false);
      setAnchorEl(prev => {
        return { ...prev, register: null };
      });
    },
  };

  return (
    <Container>
      <HeaderWrapper>
        <Typography component="strong" variant="headline5">
          {t('Member_LNB_06')}
        </Typography>
      </HeaderWrapper>
      <TableInfo>
        <TableInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
            {t('Member_Manager_01')}
          </Typography>
        </TableInfoItem>
        <TableInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
            {t('Member_Manager_02')}
          </Typography>
        </TableInfoItem>
      </TableInfo>
      <Body>
        <RoutingTabs
          tabList={[
            {
              name: `${t('Acc_Main_12')}(${members.content.filter(user => user.isManager).length})`,
              path: 'historyMgmt',
            },
          ]}
          variant="contain"
        />
        <UserTableWrapper>
          <UserTableHeader>
            <StyledButton variant="contain" size="small" onClick={menuHandler.registerOpen} paddingHorizontal={16}>
              {t('Member_Manager_03')}
              <ChevronIcon
                width={18}
                height={18}
                color={theme.colors['ic-white']}
                rotateNum={registerMenuOpen ? 0 : 180}
              />
            </StyledButton>
            <Menu
              anchorEl={anchorEl.register}
              open={registerMenuOpen}
              onClose={menuHandler.registerClose}
              PaperProps={{
                sx: {
                  minWidth: '125px',
                },
              }}
              sx={{
                marginTop: '4px',
                '& .MuiList-root': {
                  minWidth: `${i18n.language === 'ko' ? '125px' : '210px'}`,
                },
              }}
            >
              <MenuItem id="user" onClick={menuHandler.registerClose}>
                {t('Member_Manager_04')}
              </MenuItem>
              {authStore.isAdmin && (
                <MenuItem id="external" onClick={handleChangeModal.open}>
                  {t('Member_Manager_05')}
                </MenuItem>
              )}
            </Menu>
            <Search>
              <Select
                size="small"
                value={searchType}
                onChange={e => setSearchType(e.target.value as SearchType)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxWidth: i18n.language === 'en' ? '93px' : '79px',
                      width: '100%',
                      marginTop: '5px',
                      marginLeft: i18n.language === 'en' ? '0px' : '1px',
                    },
                  },
                }}
              >
                <MenuItem value="name">{t('Workflow_Main_05')}</MenuItem>
                <MenuItem value="email">{t('Member_User_14')}</MenuItem>
                <MenuItem value="usergroup">{t('Member_User_Bulk_Create_18')}</MenuItem>
                <MenuItem value="phone">{t('Member_User_16')}</MenuItem>
              </Select>
              <StyledTextField
                size="small"
                placeholder={t('Member_User_08')}
                onChange={e => debounceOnChangeSearch(e)}
                InputProps={{
                  startAdornment: (
                    <IconWrapper>
                      <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                    </IconWrapper>
                  ),
                }}
              />
            </Search>
          </UserTableHeader>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="419px">{t('Workflow_Main_05')}</TableCell>
                  <TableCell width="254px">{t('Member_User_14')}</TableCell>
                  <TableCell width="200px">{t('Member_LNB_01')}</TableCell>
                  <TableCell width="255px">{t('Member_User_Bulk_Create_18')}</TableCell>
                  <TableCell width="200px">{t('Member_User_16')}</TableCell>
                  <TableCell width="200px">{t('Member_User_17')}</TableCell>
                  {authStore.isAdmin && (
                    <TableCell width="72px" sx={{ textAlign: 'center' }}>
                      {t('Acc_Main_23')}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {members.content.map(admin => {
                  return <ManagerTableRow rowData={admin} key={admin.id} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </UserTableWrapper>
      </Body>
      {managerAddFromUserModal.visible && <ManagerAddFromUser />}
      <ExternalManagerAddFromModal
        isOpen={isModalOpen}
        isClose={handleChangeModal.close}
        adminUsername={members.content.map(admin => admin.username)}
      />
    </Container>
  );
});

const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 0 32px 32px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 8px 14px;
`;
const TableInfo = styled('ul')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: cneter;
  align-items: flex-start;
  gap: 2px;
  padding: 16px 32px;
  margin-bottom: 32px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
`;

const TableInfoItem = styled('li')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const UserTableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: fit-content;
`;

const UserTableHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Search = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;
const StyledTextField = styled(TextField)`
  width: 250px;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;
const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;
