import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import { Button, Dialog, Label } from '@components/index';
import { saveItemToLocalStorage } from '@components/products/productsUtils';
import uiStore from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { useGetProductPlanList, useGetSoftware } from '@queryHooks/useSoftware';
import { CartProductModel } from '@models/softwareModels/CartProductModel';
import { FormRequest, useItAdminForm } from './validationSchema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  swId: string;
  /**
   * 장바구니에서 수정 시 원본 데이터 전달
   */
  originData?: CartProductModel;
}

export const CartModal = ({ isOpen, onClose, swId, originData }: Props) => {
  const { t } = useTranslation();

  const { data: sw } = useGetSoftware(swId, { enabled: isOpen });
  const { data: plans } = useGetProductPlanList(swId, { enabled: isOpen });

  const { register, handleSubmit, reset, control } = useItAdminForm();

  const isEditMode = !!originData;

  const validation = {
    success: (data: FormRequest) => {
      if (!isEditMode) {
        uiStore.openCartToast(swId);
      }
      const plan = plans?.find(plan => plan.planId === data.productPlanId);
      if (plan) {
        saveItemToLocalStorage(sw, plan, data.inquiry, originData?.id);
        onClose();
      }
    },
  };

  useEffect(() => {
    if (isEditMode) {
      reset({
        softwareId: originData.productId,
        inquiry: originData?.inquiry,
        productPlanId: originData.planId,
      });
    }
  }, [isEditMode, originData, reset]);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 344,
        marginTop: 7,
        boxShadow: 'none',
        borderRadius: '0 0 2px 2px',
      },
    },
  };

  return (
    <Dialog open={isOpen} onClose={onClose} title={t('Ad_Store_Product_Detail_02')}>
      <Form onSubmit={handleSubmit(validation.success)}>
        <Element>
          <strong className="name">
            {t('Subscrib_Detail_UseRequest_07')}
            <span>*</span>
          </strong>
          <p className="software_name">{sw.name}</p>
        </Element>
        <Element>
          <strong className="name">
            {t('Subscrib_Main_12')}
            <span>*</span>
          </strong>
          <Controller
            control={control}
            name="productPlanId"
            render={({ field: { onChange, value = '' } }) => (
              <Select
                size="medium"
                fullWidth
                displayEmpty
                value={value}
                onChange={onChange}
                renderValue={(value: string) => {
                  return value !== '' ? (
                    <StyledLabelWrap>
                      {plans?.find(plan => plan.planId === value)?.planName}
                      {plans?.find(plan => plan.planId === value)?.isInUseByTenant ? (
                        <Label variant="contain" color="gradient">
                          {t('Ad_Store_Product_State')}
                        </Label>
                      ) : null}
                    </StyledLabelWrap>
                  ) : (
                    <strong>{t('Product_Use_Request_02')}</strong>
                  );
                }}
                MenuProps={MenuProps}
              >
                {plans?.map(plan => {
                  return (
                    <SelectItem value={plan.planId} key={plan.planId}>
                      {plan.planName}
                      {plan.isInUseByTenant ? (
                        <Label variant="contain" color="gradient">
                          {t('Ad_Store_Product_State')}
                        </Label>
                      ) : null}
                    </SelectItem>
                  );
                })}
              </Select>
            )}
          />
        </Element>
        <Element>
          <strong className="name">{t('Acc_Main_22')}</strong>
          <StyledTextField
            {...register('inquiry')}
            multiline
            rows={9}
            placeholder={`${t('Ad_Store_Purchase_Request_01')}`}
          />
        </Element>
        <Dialog.Footer>
          <SubmitButton variant="contain" size="extraLarge" type="submit">
            {t('Cart_Modal_01')}
          </SubmitButton>
        </Dialog.Footer>
      </Form>
    </Dialog>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 100px;
`;

const Element = styled(Box)`
  display: flex;
  flex-direction: column;
  &.info-banner {
    align-items: center;
    justify-content: flex-start;
    flex-direction: initial;
    gap: 6px;
    background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
    border-radius: 3px;
    padding: 12px 20px;
    & > p {
      ${fonts.Body2};
      color: ${({ theme }) => theme.colors['text-purple']};
    }
  }
  & .name {
    ${fonts.Headline8};
    margin-bottom: 4px;
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & > span {
      margin-left: 2px;
      color: ${({ theme: { colors } }) => colors['ic-red-light']};
    }
  }
  & .software_name {
    padding: 9px 12px;
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    border-radius: 2px;

    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
  & .MuiInputBase-root textarea::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
  & .MuiSelect-select strong {
    ${fonts.Button4};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.colors['bg-purple']};
  border-radius: 3px;
`;

const SelectItem = styled(MenuItem)`
  gap: 4px;
  padding: 10px 10px 10px 7px;
`;

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 10px 12px 25px;
  }
`;

const StyledLabelWrap = styled('div')`
  & .contain {
    margin-left: 4px;
  }
`;
