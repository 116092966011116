// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const PersonFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M12.0003 2.5C9.10083 2.5 6.75037 4.85052 6.75037 7.75C6.75037 9.73662 7.8538 11.4655 9.4812 12.3573C5.81999 13.4225 3.1226 16.7511 3.00424 20.7277C2.99192 21.1417 3.31757 21.4873 3.7316 21.4997H20.2688C20.6828 21.4873 21.0085 21.1417 20.9961 20.7277C20.8778 16.7511 18.1805 13.4226 14.5193 12.3573C16.1468 11.4656 17.2502 9.73665 17.2502 7.75C17.2502 4.85052 14.8998 2.5 12.0003 2.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PersonFillIcon.displayName = 'PersonFillIcon';
