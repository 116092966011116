const defaultWidth = 26;
const defaultHeight = 26;

export const GlobeIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0001 1.95001C6.89733 1.95001 1.95007 6.89726 1.95007 13C1.95007 19.1028 6.89733 24.05 13.0001 24.05C19.1028 24.05 24.0501 19.1028 24.0501 13C24.0501 6.89726 19.1028 1.95001 13.0001 1.95001ZM3.46988 12.4977H8.23676C8.3375 9.4471 9.3409 6.98244 10.3393 5.26113C10.731 4.58569 11.1233 4.02237 11.4627 3.58002C7.0842 4.28914 3.70428 7.97737 3.46988 12.4977ZM8.26171 14.0046H3.50913C3.95782 18.2937 7.24752 21.7373 11.4627 22.42C11.1233 21.9776 10.731 21.4143 10.3393 20.7389C9.39628 19.1131 8.44881 16.8241 8.26171 14.0046ZM9.77225 14.0046H16.2279C16.0441 16.4951 15.2029 18.5252 14.3574 19.9829C13.8795 20.807 13.4024 21.444 13.0478 21.872L13.0001 21.9292L12.9524 21.872C12.5977 21.444 12.1207 20.807 11.6427 19.9829C10.7972 18.5252 9.95609 16.4951 9.77225 14.0046ZM16.2556 12.4977H9.74451C9.84439 9.77711 10.7411 7.57168 11.6427 6.01713C12.1207 5.19304 12.5977 4.55605 12.9524 4.12805L13.0001 4.07085L13.0478 4.12805C13.4024 4.55605 13.8795 5.19304 14.3574 6.01713C15.2591 7.57168 16.1558 9.77711 16.2556 12.4977ZM17.7384 14.0046C17.5513 16.8241 16.6039 19.1131 15.6609 20.7389C15.2691 21.4143 14.8769 21.9776 14.5375 22.42C18.7526 21.7373 22.0423 18.2937 22.491 14.0046H17.7384ZM22.5303 12.4977H17.7634C17.6626 9.4471 16.6592 6.98244 15.6609 5.26113C15.2691 4.58569 14.8769 4.02237 14.5375 3.58002C18.9159 4.28914 22.2959 7.97737 22.5303 12.4977Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9589 3.89748C7.01645 4.77761 4.0154 8.15403 3.70084 12.2811H8.02856C8.16855 9.28276 9.16329 6.85679 10.1518 5.15242C10.4265 4.67883 10.7015 4.25955 10.9589 3.89748ZM3.75276 14.2212C4.26227 18.1178 7.17766 21.2584 10.9589 22.1025C10.7015 21.7405 10.4265 21.3212 10.1518 20.8476C9.2191 19.2395 8.28085 16.9889 8.06028 14.2212H3.75276ZM10.0075 14.2212C10.2219 16.5656 11.0234 18.4833 11.8301 19.8742C12.2493 20.597 12.6676 21.1732 13.0001 21.5876C13.3325 21.1732 13.7508 20.597 14.17 19.8742C14.9768 18.4833 15.7782 16.5656 15.9926 14.2212H10.0075ZM9.97115 12.2811H16.029C15.8912 9.70734 15.0336 7.61484 14.17 6.12582C13.7508 5.40307 13.3325 4.82678 13.0001 4.41241C12.6676 4.82678 12.2493 5.40307 11.8301 6.12582C10.9665 7.61484 10.1089 9.70734 9.97115 12.2811ZM17.9398 14.2212C17.7193 16.9889 16.781 19.2395 15.8483 20.8476C15.5736 21.3212 15.2987 21.7405 15.0412 22.1025C18.8225 21.2584 21.7379 18.1178 22.2474 14.2212H17.9398ZM15.0412 3.89748C15.2986 4.25955 15.5736 4.67883 15.8483 5.15242C16.8368 6.85679 17.8316 9.28276 17.9716 12.2811H22.2993C21.9847 8.15403 18.9837 4.77761 15.0412 3.89748ZM1.7334 13C1.7334 6.7776 6.77766 1.73334 13.0001 1.73334C19.2225 1.73334 24.2667 6.7776 24.2667 13C24.2667 19.2224 19.2225 24.2667 13.0001 24.2667C6.77766 24.2667 1.7334 19.2224 1.7334 13Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

GlobeIcon.displayName = 'GlobeIcon';
