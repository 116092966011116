import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root:after {
    display: none;
  }
  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiTabs-flexContainer {
    width: 100%;
  }
`;
export const StyledTab = styled(Tab)`
  width: 50%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-dark']};
  &:first-of-type {
    border-width: 1px 0 1px 1px;
    &.Mui-selected {
      border-width: 1px;
    }
  }
  &:last-of-type {
    border-width: 1px 1px 1px 0;
    &.Mui-selected {
      border-width: 1px;
    }
  }
  & .MuiTouchRipple-root {
    display: none;
  }
  &.Mui-selected {
    border: 1px solid ${({ theme }) => theme.colors['border-gray-dark']};
    border-bottom-color: ${({ theme }) => theme.colors['bg-white']};
    background-color: ${({ theme }) => theme.colors['bg-white']};

    & .MuiTypography-root {
      color: ${({ theme }) => theme.colors['text-purple-light']};
    }
  }
  & .MuiTypography-root {
    ${fonts.Button3};
    color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'xs')} {
    border-width: 0 0 1px 0;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    border-width: 0 0 1px 0;
    border-color: ${({ theme }) => theme.colors['border-gray-lighter']};

    &.Mui-selected {
      span {
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: -13px;
          left: 0;
          right: 0;

          background-color: ${({ theme }) => theme.colors['text-purple-light']};
          border-radius: 2px 2px 0 0;
        }
      }
    }

    &:first-of-type {
      border-width: 0 0 1px 0;
      border-color: ${({ theme }) => theme.colors['border-gray-lighter']};
      &.Mui-selected {
        border-width: 0 0 1px 0;
        border-color: ${({ theme }) => theme.colors['border-gray-lighter']};
      }
    }
    &:last-of-type {
      border-width: 0 0 1px 0;
      border-color: ${({ theme }) => theme.colors['border-gray-lighter']};
      &.Mui-selected {
        border-width: 0 0 1px 0;
        border-color: ${({ theme }) => theme.colors['border-gray-lighter']};
      }
    }
  } ;
`;
