import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { Button, EmptyRow } from '@components/index';
import { SkeletonRows } from '@components/table/SkeletonRow';
import { fonts } from '@theme/fontsCustomer';
import { SearchIcon } from '@icons/SearchIcon';
import type { Filter } from '@repositories/subscriptionConnectionRepository/Types';
import { useGetSubscriptionConnections } from '@queryHooks/useSubscriptionConnection';
import { ConnectionRow } from './ConnectionRow';

export const ConnectionMgmtMgmtList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [filter, setFilter] = useState<Filter>({
    productName: '',
  });
  const { data: connections, isLoading } = useGetSubscriptionConnections(filter, { cacheTime: 0 });
  const navigate = useNavigate();

  const handleClickConnectionCreate = () => {
    navigate(`create`);
  };

  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, productName: event.target.value }));
  };
  return (
    <Container>
      <Header>
        <strong>{t('Subscrib_Main_23')}</strong>
      </Header>
      <Divider />
      <Body>
        <Toolbar>
          <span className="toolbar-left">
            {t('Acc_Main_12')}
            <span className="count">{connections?.totalElements}</span>
          </span>
          <div className="toolbar-right">
            <StyledTextField
              fullWidth
              size="small"
              value={filter.productName}
              placeholder={t('Subscrib_Connect_Create_01')}
              onChange={handleSearchNameChange}
              InputProps={{
                startAdornment: (
                  <IconWrapper>
                    <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                  </IconWrapper>
                ),
              }}
            />
            <Button size="small" variant="contain" paddingHorizontal={16} onClick={handleClickConnectionCreate}>
              {t('Subscrib_Connect_01')}
            </Button>
          </div>
        </Toolbar>
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="72px" align="center">
                    {t('Workflow_Main_04')}
                  </StyledTableCell>
                  <TableCell width="auto">{t('Workflow_Main_05')}</TableCell>
                  <TableCell width="250px">{t('Subscrib_Connect_Connector')}</TableCell>
                  <TableCell width="100px">{t('Subscrib_Connect_Connector_Type')}</TableCell>
                  <TableCell width="220px">{t('Workflow_Main_07')}</TableCell>
                  <TableCell width="100px">{t('Subscrib_Connect_02')}</TableCell>
                  <TableCell width="100px">{t('Subscrib_Connect_03')}</TableCell>
                  <StyledTableCell width="72px" align="center">
                    {t('Acc_Main_23')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <SkeletonRows rowNum={3} colNum={6} />}
                {connections?.totalElements === 0 && (
                  <EmptyRow
                    colSpan={6}
                    title={
                      <Description>
                        <span>{t('Subscrib_Connect_13')}</span>
                        <span className="sub">{t('Subscrib_Connect_14')}</span>
                      </Description>
                    }
                  />
                )}
                {connections?.content?.map((connection, idx) => {
                  return <ConnectionRow key={connection.connectionId} data={connection} idx={idx} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      </Body>
    </Container>
  );
};

const Description = styled.div`
  ${fonts.Body2};
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .sub {
    color: ${props => props.theme.colors['text-gray-sub-dark']};
  }
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
`;

const Header = styled.div`
  margin: 28px 0px 14px 0px;
  strong {
    ${fonts.Headline5};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-left: 8px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 12px;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;

  & .toolbar-left {
    margin-top: 6px;
    margin-left: 8px;
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & .count {
      margin-left: 4px;
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }

  & .toolbar-right {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
    overflow: hidden;
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors['state-white-hover']};
      }
    }
  }
  th {
    text-align: left;
    ${fonts.Headline9};
    color: ${({ theme }) => theme.colors['text-gray-main']};
    border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
    background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  }
  td {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-main']};
    border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: center;
  }
`;
