import { XIcon } from '@icons/XIcon';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import { IconButton, MenuItem, Modal, Select, useTheme, Checkbox, Chip, SelectChangeEvent } from '@mui/material';
import { useGetTenantMembersInfinite, useUpdateTenantMemberRole } from '@queryHooks/useTenant';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { SearchIcon } from '@icons/SearchIcon';
import { CheckboxBgIcon, CheckboxIcon } from '@icons/index';
import { nanoid } from 'nanoid';
import { useGetUserGroups } from '@queryHooks/index';
import { TenantMemberFilter } from '@repositories/tenantRepository/Types';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as S from './ManagerAddFromUserStyles';

export const ManagerAddFromUser = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedUsers, setSelectedUsers] = useState<TenantMemberSimpleModel[]>([]);
  const ulRef = useRef<HTMLUListElement | null>(null);
  const [textFieldFilters, setTextFieldFilters] = useState<Omit<TenantMemberFilter, 'page'>>({
    memberRole: 'USER',
    tenantMemberStatus: 'ACTIVE',
    userGroupId: 'all',
  });

  const {
    authStore,
    uiStore: { managerAddFromUserStore: modal },
  } = useStore();

  const {
    data: userListPage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetTenantMembersInfinite(authStore.curTenant.id, {
    ...textFieldFilters,
    userGroupId: textFieldFilters.userGroupId === 'all' ? undefined : textFieldFilters.userGroupId,
  });

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const { data: userGroups } = useGetUserGroups({
    tenantId: authStore.managerTenantInfo.id,
  });

  const { mutate: updateRole } = useUpdateTenantMemberRole();

  const handleClickCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    user: TenantMemberSimpleModel,
  ) => {
    setSelectedUsers(prev => (checked ? [...prev, user] : prev.filter(admin => admin.id !== user.id)));
  };

  const handleChangeSelectValue = (event: SelectChangeEvent<string>) => {
    setTextFieldFilters(prev => ({ ...prev, userGroupId: event.target.value }));
  };

  const handleClickAdminAddBtn = () => {
    selectedUsers.forEach(user => {
      updateRole({ memberRole: 'GENERAL_ADMIN', tenantId: authStore.curTenant.id, userId: user.id });
      modal.close();
    });
  };
  const debounceOnChangeSearch = _.debounce((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTextFieldFilters(prev => {
      return { ...prev, keyword: e.target.value };
    });
  }, 500);

  return (
    <Modal open={modal.visible}>
      <S.ModalWrapper>
        <S.Header>
          <S.HeaderTitle>{t('Member_Manager_23')}</S.HeaderTitle>
          <IconButton onClick={modal.close}>
            <XIcon color={theme.colors['ic-gray-main']} width={24} height={24} />
          </IconButton>
        </S.Header>
        <S.Body>
          <S.UserSelectedWrapper>
            {selectedUsers.length > 0 && (
              <S.Candidates>
                {selectedUsers?.map(user => {
                  return (
                    <Chip
                      key={nanoid()}
                      label={user.name}
                      variant="outlined"
                      onDelete={() =>
                        setSelectedUsers(prev => {
                          const origin = [...prev];
                          const selected = origin.filter(admin => admin.id !== user.id);
                          return [...selected];
                        })
                      }
                      deleteIcon={
                        <S.ChipCloseButton variant="text" size="small">
                          <XIcon color={theme.colors['ic-gray-main']} width={14} height={14} />
                        </S.ChipCloseButton>
                      }
                    />
                  );
                })}
              </S.Candidates>
            )}
            <S.Search>
              <Select
                value={textFieldFilters.userGroupId}
                size="small"
                sx={{ width: '139px', flexShrink: '0' }}
                onChange={handleChangeSelectValue}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: '139px',
                      maxHeight: '328px',
                      marginTop: '5px',
                      borderRadius: '0 0 2px 2px',
                      boxShadow: '0',
                      border: `1px solid ${theme.colors['border-gray-light']}`,
                      '& .MuiList-root': {
                        border: 'none',
                        padding: '4px 0',
                        '& .MuiMenuItem-root': {
                          margin: '0 5px',
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="all">{t('Acc_Main_12')}</MenuItem>
                {userGroups?.map(group => {
                  return (
                    <MenuItem key={nanoid()} value={group.userGroupId}>
                      <S.MenuItemText>{group.name || '-'}</S.MenuItemText>
                    </MenuItem>
                  );
                })}
              </Select>
              <S.StyledTextField
                fullWidth
                size="small"
                placeholder="구성원 이름 검색"
                onChange={e => debounceOnChangeSearch(e)}
                InputProps={{
                  startAdornment: (
                    <S.IconWrapper>
                      <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                    </S.IconWrapper>
                  ),
                }}
              />
            </S.Search>
          </S.UserSelectedWrapper>
          <ul ref={ulRef}>
            {userListPage?.pages
              .flatMap(page => page.content)
              .map(user => {
                return (
                  <li key={user.id}>
                    <span>{user.name}</span>
                    <span>{user.username}</span>
                    <Checkbox
                      icon={<CheckboxBgIcon color={theme.colors['border-gray-light']} />}
                      checkedIcon={
                        <CheckboxIcon bgColor={theme.colors['bg-purple']} color={theme.colors['bg-white']} />
                      }
                      checked={!!selectedUsers.find(selectedUser => selectedUser.id === user.id)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleClickCheckbox(event, checked, user)
                      }
                    />
                  </li>
                );
              })}
            {hasNextPage && <div ref={ref} />}
          </ul>
        </S.Body>
        <S.Footer $hasScrollbar={!!ulRef.current && ulRef.current?.clientHeight >= 400}>
          <S.StyledButton
            variant="contain"
            size="extraLarge"
            onClick={handleClickAdminAddBtn}
            disabled={selectedUsers.length === 0}
          >
            {t('Member_Manager_11')}
          </S.StyledButton>
          <S.StyledButton variant="outline" size="extraLarge" onClick={modal.close}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </S.StyledButton>
        </S.Footer>
      </S.ModalWrapper>
    </Modal>
  );
});
