import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { Button as CustomButton } from '@components/index';

export const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - 80px);
  margin-left: 292px;
  overflow: auto;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  ${fonts.Headline3};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  align-items: center;
  gap: 12px;
  margin-bottom: 42px;
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const TitleHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;

  & .title {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }

  & .edit {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
`;

export const RequestContents = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const RequestContent = styled(Box)`
  & .left {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 376px;
    height: 72px;
    justify-content: center;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
    border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  }
  & .right {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 376px;
    height: 72px;
    justify-content: center;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
    padding: 0px 0px 0px 20px;
  }
  & .content-name {
    ${fonts.Headline9};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  & .content-value {
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

export const RequestInquiry = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & .content-name {
    ${fonts.Body5};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    width: 100px;
  }
  & .content-value {
    ${fonts.Body6};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    width: 300px;
  }
  padding: 15px 0px;
`;

export const Button = styled(CustomButton)`
  & > span {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
  }
`;

export const Frame = styled(Box)`
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  margin-bottom: 31px;
`;

export const Columns = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const Column = styled(Box)<{ divide: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 15px 0px 15px 20px;
  gap: 2px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  border-right: ${props => props.divide && `1px solid`} ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  & .content-name {
    ${fonts.Headline9};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  & .content-value {
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
  & .inquiry {
    ${fonts.Paragraph5};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    white-space: pre-line;
  }
`;
