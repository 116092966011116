import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { useDropdownContext } from './DropdownContext';

type Props = StrictPropsWithChildren<{ value: string | number }>;

export const DropdownMenu = ({ value, children }: Props) => {
  const { value: selectedValue, onMenuChange } = useDropdownContext();

  const handleMenuClick = () => {
    if (typeof onMenuChange === 'function') {
      onMenuChange(children, value);
    }
  };

  return (
    <MenuContainer value={value} onClick={handleMenuClick} $isSelected={value === selectedValue}>
      {children}
    </MenuContainer>
  );
};

const MenuContainer = styled.li<{ $isSelected: boolean }>`
  list-style: none;
  appearance: none;
  padding: 6px 7px;
  ${fonts.Button4}
  cursor: pointer;
  color: ${({ $isSelected, theme: { colors } }) =>
    $isSelected ? colors['text-purple-light'] : colors['text-gray-main']};

  background-color: ${({ $isSelected, theme: { colors } }) =>
    $isSelected ? colors['state-white-hover'] : colors['bg-white']};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-hover']};
  }
`;
