const defaultWidth = 24;
const defaultHeight = 24;

export const PlugfillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3516 23.038C13.022 23.038 15.1867 20.8733 15.1868 18.203L15.1868 16.8663H17.6739C18.6748 16.8663 19.5084 16.0981 19.6135 15.0937L20.2196 11.2764L21.3204 10.3154C21.7524 9.9383 22 9.38875 22 8.81144V7.51192C22 6.42078 21.1305 5.52932 20.0494 5.52932H18.0439V2.47528C18.0439 1.94047 17.6172 1.5 17.0824 1.5C16.5476 1.5 16.1209 1.94047 16.1209 2.47528V5.52932H12.3296V2.47528C12.3296 1.94047 11.9029 1.5 11.3681 1.5C10.8333 1.5 10.4066 1.94047 10.4066 2.47528V5.52932L8.40106 5.52932C7.32001 5.52932 6.45051 6.42078 6.45051 7.51192V8.81144C6.45051 9.38875 6.69814 9.9383 7.13015 10.3154L8.23088 11.2764L8.83697 15.0937C8.94215 16.0981 9.77573 16.8663 10.7766 16.8663L13.2637 16.8663V18.203H13.2527C13.2524 19.805 11.9537 21.1035 10.3516 21.1035C8.74959 21.1035 7.45084 19.805 7.45053 18.203H7.44594C7.34148 15.8594 5.56876 13.9498 3.28664 13.633C3.1393 13.6125 3.09272 13.6125 2.98901 13.6125C2.4428 13.6125 2 14.0302 2 14.5764C2 15.1226 2.39055 15.5594 2.91331 15.5594C2.9505 15.5594 2.98901 15.5498 3.09904 15.5594C3.20906 15.569 3.09904 15.561 3.09904 15.561C4.47114 15.7911 5.51669 16.9844 5.51676 18.4219H5.52131C5.63581 20.9906 7.75461 23.038 10.3516 23.038Z"
        fill={color}
      />
    </svg>
  );
};

PlugfillIcon.displayName = 'PlugfillIcon';
