import type IBillingOptimizationRepository from './IBillingOptimizationRepository';
import type { OptimizeMonthlyBillingRq, OptimizedMonthlyBillingDTO } from './Types';
import { injectable } from 'inversify';
import API from '@repositories/Axios';
import type { Page } from '@type/Page';

@injectable()
export class BillingOptimizationRepository implements IBillingOptimizationRepository {
  async optimizeMonthlyBilling(data?: OptimizeMonthlyBillingRq): Promise<Page<OptimizedMonthlyBillingDTO>> {
    const result = await API.post<Page<OptimizedMonthlyBillingDTO>>(`/api/v1/actions/optimize-monthly-billings`, data);
    return result.data;
  }
}
