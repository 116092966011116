const numberTickFormatHelper = (chartValues: number[]) => {
  let maxValue = Math.max(...chartValues.map(value => Math.abs(value)));

  const arr = ['', 'M', 'B', 'T', 'Q'];
  let i = 0;
  while (maxValue >= 1000000 || (i > 0 && maxValue >= 1000)) {
    maxValue /= i === 0 ? 1000000 : 1000;
    i += 1;
  }

  return { denominator: i === 0 ? 1 : 1000 ** (i + 1), symbol: arr[i] };
};

export const numberTickFormatter = (chartValues: number[]) => (value: unknown) => {
  if (typeof value === 'number') {
    const { denominator, symbol } = numberTickFormatHelper(chartValues);
    return `${Math.floor(value / denominator)}${symbol}`;
  }
  return `${value}`;
};
