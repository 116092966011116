import type { Ref, SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimezoneSelect } from 'react-timezone-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Autocomplete, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';

type Props = {
  value: string | null;
  onChange: (value: Props['value']) => void;
  className?: string;
  disabled?: boolean;
  inputRef?: Ref<HTMLDivElement>;
  error?: boolean;
  disableDefaultGuessing?: boolean;
};
export const TimezoneAutocomplete = ({
  value,
  onChange,
  className,
  disabled = false,
  inputRef,
  error = false,
  disableDefaultGuessing = false,
}: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { options, parseTimezone } = useTimezoneSelect({ labelStyle: 'original' });
  const [selectedValue, setSelectedValue] = useState<string | null>(() => {
    if (!value && !disableDefaultGuessing) {
      const tz = dayjs.tz.guess();
      const isValid = options.find(({ value }) => value === tz);
      if (isValid) {
        onChange(tz);
        return tz;
      }
      return '';
    }
    return value;
  });

  const GMT = options.find(({ value }) => value === 'Etc/GMT');
  if (GMT) {
    GMT.value = 'UTC';
  }

  const handleChange = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <StyledAutocomplete
      className={className}
      value={selectedValue}
      onChange={handleChange}
      options={options.map(({ value }) => value)}
      getOptionLabel={value => {
        const tz = parseTimezone(value);
        return tz ? `${tz.label.split(' ')[0]} ${tz.value}` : '';
      }}
      disabled={disabled}
      renderInput={params => (
        <TextField
          ref={inputRef}
          placeholder={t('Subscrib_Edit_06')}
          variant="outlined"
          {...params}
          error={error}
          disabled={disabled}
          sx={{
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderRadius: '2px 2px 0 0',
            },
            '& .MuiInputBase-root': {
              '& .MuiAutocomplete-endAdornment': {
                width: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexShrink: '0',
                gap: '6px',
                right: '8px',
                top: 'calc(50% - 9px)',
                '& ~ &': {
                  justifyContent: 'flex-start',
                },
              },
              '& .MuiButtonBase-root': {
                width: '18px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: '0',
                padding: '0',
              },
              '&.Mui-disabled': {
                '& .MuiAutocomplete-endAdornment': {
                  justifyContent: 'flex-end',
                },
                '& .MuiOutlinedInput-notchedOutline ': {
                  borderColor: `${colors['border-gray-light']}`,
                },
                '& .MuiSvgIcon-root': {
                  color: `${colors['ic-gray-light']}`,
                },
                '&.Mui-error': {
                  backgroundColor: `${colors['bg-white']}`,
                  '& .MuiOutlinedInput-notchedOutline ': {
                    borderColor: `${colors['border-red-light']}`,
                  },
                  '& .MuiSvgIcon-root': {
                    color: `${colors['ic-red-light']}`,
                  },
                },
              },
            },
            '& .MuiSvgIcon-root': {
              width: '18px',
              height: '18px',
              color: `${colors['ic-gray-dark']}`,
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => {
        if (value === 'Etc/GMT' && option === 'UTC') return true;
        return option === value;
      }}
      noOptionsText={<AutocompleteNoOptions />}
      sx={{
        '& .MuiInputBase-root': {
          padding: '10px 12px',
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: 0,
        },
        '& .MuiButtonBase-root': {
          borderRadius: '0',
          '&:hover': {
            backgroundColor: `${colors['bg-white']}`,
          },
        },
      }}
      componentsProps={{
        paper: {
          sx: {
            marginTop: 0,
            borderRadius: '0 0 2px 2px',
            boxShadow: '0',
            border: `1px solid ${colors['border-gray-light']}`,
            borderWidth: '0 1px 1px 1px',
            overflow: 'hidden',
            '& .MuiList-root': {
              border: 'none',
              padding: '4px 0',
              '& .MuiMenuItem-root': {
                margin: '0 5px',
              },
            },
          },
        },
      }}
    />
  );
};

const AutocompleteNoOptions = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <AutocompleteNoOptionsContainer>
      <ErrorIconWrapper>
        <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
      </ErrorIconWrapper>
      <p>{t('Subscrib_Edit_11')}</p>
    </AutocompleteNoOptionsContainer>
  );
};

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiInputBase-root.Mui-disabled {
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light-m']};
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme: { colors } }) => colors['border-gray-light']};
    }
    & .MuiSelect-select {
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
  }

  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &::-webkit-input-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &:-moz-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &::-moz-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
    &:-ms-input-placeholder {
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
  }

  & .Mui-error {
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors['border-red-light']};
    }
    & .MuiSelect-select {
      color: ${({ theme: { colors } }) => colors['text-red-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
    }
    & .MuiInputBase-input {
      &::-webkit-input-placeholder {
        -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
        color: ${({ theme }) => theme.colors['text-red-light']};
      }
    }
  }
` as typeof Autocomplete;

const AutocompleteNoOptionsContainer = styled.div`
  padding: 16px 0px;
  ${fonts.Body2}
  text-align: center;
`;

const ErrorIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(245, 240, 255, 1);
  margin: 0 auto 12px;
  padding: 7px;
`;
