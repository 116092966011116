import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import type { Filters } from '@repositories/softwareRepository/Types';
import { useGetSoftwareList } from '@queryHooks/useSoftware';
import type { SoftwareSimpleModel } from '@models/softwareModels';

type Props = {
  onChange: (product: SoftwareSimpleModel | null) => void;
  value: string | undefined;
};

export const ProductAutocomplete = ({ onChange, value }: Props) => {
  const theme = useTheme();
  const [filter, setFilter] = useState<Filters>({});
  const [selectedProduct, setSelectedProduct] = useState<SoftwareSimpleModel | null>();

  const { data: productFromServer } = useGetSoftwareList(filter, {
    onSuccess: data => {
      if (value) {
        const initProduct = data?.content.find(product => product.id === value);
        setSelectedProduct(initProduct);
      }
    },
  });

  useEffect(() => {
    setSelectedProduct(productFromServer.content.find(product => product.id === value) ?? null);
  }, [productFromServer.content, value]);

  const handleChangeTextField = debounce((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilter({ ...filter, keyword: event.target.value });
  }, 500);

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: SoftwareSimpleModel | null) => {
    setSelectedProduct(value);
    onChange(value);
  };

  return (
    <Autocomplete
      className="autocomplete"
      value={selectedProduct || null}
      size="medium"
      options={productFromServer.content}
      renderInput={params => (
        <TextField
          {...params}
          size="medium"
          sx={{
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderRadius: '2px 2px 0 0',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              color: `${theme.colors['text-gray-light']}`,
            },
          }}
          onChange={handleChangeTextField}
        />
      )}
      fullWidth
      isOptionEqualToValue={(op, val) => op.id === val.id}
      getOptionLabel={product => product.name || '-'}
      placeholder="직접 입력"
      onChange={handleChange}
      ListboxProps={{
        style: {
          maxHeight: '328px',
        },
      }}
      componentsProps={{
        paper: {
          sx: {
            width: '100%',
            marginTop: 0,
            borderRadius: '0 0 2px 2px',
            boxShadow: '0',
            border: `1px solid ${theme.colors['border-gray-light']}`,
            borderWidth: '0 1px 1px 1px',
            overflow: 'hidden',
            '& .MuiAutocomplete-listbox': {
              height: '328px',
            },
            '& .MuiList-root': {
              border: 'none',
              padding: '4px 0',
              '& .MuiMenuItem-root': {
                margin: '0 5px',
              },
            },
          },
        },
      }}
      sx={{
        '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '7.5px 4px 7.5px 12px',
        },
      }}
    />
  );
};
