import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type FormType = {
  password: string;
  passwordConfirm: string;
};

export const useChangePasswordForm = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('Account_Validation_Check_06'))
      .matches(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/, {
        message: t('Account_Validation_Check_06'),
      }),
    passwordConfirm: Yup.string()
      .required(t('Account_Validation_Check_06'))
      .oneOf([Yup.ref('password', undefined)], t('Account_Validation_Check_07')),
  });

  const formOptions: UseFormProps<FormType> = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  };
  return useForm<FormType>(formOptions);
};
