import type { Dayjs } from 'dayjs';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { TableComponents } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { SkeletonRows, TableHeaderCell } from '@components/table';
import { EmptyRow } from '@components/table/EmptyRow';
import { useStore } from '@stores/RootStore';
import type { Filters } from '@repositories/subscriptionRepository';
import { useGetSoftwareList } from '@queryHooks/useSoftware';
import { useDeleteSubscription } from '@queryHooks/useSubscription';
import { getSortOrder } from '@utils/CommonUtil';
import { ObjectUtil } from '@utils/ObjectUtil';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';
import { SubscriptionEditDialog } from '../../subscriptionEditDialog/SubscriptionEditDialog';
import { SubscriptionRow } from './SubscriptionRow';

type TableContext = {
  isLoading: boolean;
  emptyDataMessage: string;
  onRowClick: (item: SubscriptionListModel) => void;
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
`;

const StyledTable = styled(Table)`
  border-radius: 5px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
`;

const tableComponents: TableComponents<SubscriptionListModel, TableContext> = {
  Table: StyledTable,
  TableHead,
  TableRow: ({ children, item, context, ...props }) => (
    <StyledTableRow
      {...props}
      onClick={() => {
        if (typeof context?.onRowClick === 'function') {
          context.onRowClick(item);
        }
      }}
    >
      {children}
    </StyledTableRow>
  ),
  TableBody,
  EmptyPlaceholder: ({ context }) =>
    context ? (
      <TableBody>
        {context.isLoading ? (
          <SkeletonRows rowNum={3} colNum={9} />
        ) : (
          <EmptyRow colSpan={9} title={context.emptyDataMessage} />
        )}
      </TableBody>
    ) : null,
};

export const SubscriptionTable: React.FC<{
  isLoading: boolean;
  filter: Filters;
  subscriptionList: SubscriptionListModel[];
  scrollTarget: HTMLElement | null;
  onUpdateFilter: (filter: Partial<Filters>) => void;
  endReached: () => void;
}> = ({ isLoading, filter, subscriptionList, scrollTarget, onUpdateFilter, endReached }) => {
  const { t } = useTranslation();
  const {
    authStore,
    uiStore: { alertStore, toastStore },
  } = useStore();
  const navigate = useNavigate();

  const [subscriptionEditTarget, setSubscriptionEditTarget] = useState<SubscriptionListModel | null>(null);

  const handleRowClick = (row: SubscriptionListModel) => {
    navigate(row.id);
  };

  const handleHeaderCellClick = (orderBy: string, order: 'asc' | 'desc' | undefined) => {
    onUpdateFilter({ sort: order ? [`${orderBy}.${order}`] : [] });
  };

  const handleFilter = {
    checkFilterChange: (filterKey: string, filterValues: string[]) => {
      onUpdateFilter({ [filterKey]: filterValues });
    },
    dateFilterChange: (filterKey: string, from: Dayjs | null, to: Dayjs | null) => {
      const filterKeyFrom = dateFormat(from, undefined, 'YYYY-MM-DD');
      const filterKeyTo = dateFormat(to, undefined, 'YYYY-MM-DD');
      onUpdateFilter({ [`${filterKey}From`]: filterKeyFrom, [`${filterKey}To`]: filterKeyTo });
    },
  };

  const { data: pagedSoftwares } = useGetSoftwareList({
    tenantId: authStore.managerTenantInfo.id,
    size: 1000,
    sort: ['name.asc'],
  });

  const { mutate: deleteSubscription } = useDeleteSubscription();

  const appliedFilter = ObjectUtil.keys(filter).reduce((applied: { [key in keyof Filters]: boolean }, filterKey) => {
    const filterValue = filter[filterKey];
    if (Array.isArray(filterValue)) {
      return Object.assign(applied, { [filterKey]: filterValue.length > 0 });
    }
    return Object.assign(applied, { [filterKey]: !!filterValue });
  }, {});

  const renderFixedHeaderContent = () => (
    <TableRow>
      <TableHeaderCell
        width={318}
        orderBy="name"
        order={getSortOrder(filter.sort, 'name')}
        onSortChange={handleHeaderCellClick}
        disableSort={subscriptionList.length === 0}
      >
        {t('Subscrib_Main_10')}
      </TableHeaderCell>
      <TableHeaderCell
        width={220}
        orderBy="softwareName"
        order={getSortOrder(filter.sort, 'softwareName')}
        onSortChange={handleHeaderCellClick}
        disableSort={subscriptionList.length === 0}
        filterType="check"
        filterKey="softwareId"
        itemList={pagedSoftwares.content.map(({ name, id }) => ({
          displayName: name,
          filterValue: id,
        }))}
        enableFilterSearch
        enableFilterAll
        onFilterChange={handleFilter.checkFilterChange}
      >
        {t('Subscrib_Detail_UseRequest_07')}
      </TableHeaderCell>
      <TableHeaderCell width={180}>{t('Subscrib_Main_12')}</TableHeaderCell>
      <TableHeaderCell
        width={170}
        orderBy="startDate"
        order={getSortOrder(filter.sort, 'startDate')}
        onSortChange={handleHeaderCellClick}
        disableSort={subscriptionList.length === 0}
        filterType="date"
        filterKey="startDate"
        onFilterChange={handleFilter.dateFilterChange}
      >
        {t('Subscrib_Main_13')}
      </TableHeaderCell>
      <TableHeaderCell
        width={170}
        orderBy="endDate"
        order={getSortOrder(filter.sort, 'endDate')}
        onSortChange={handleHeaderCellClick}
        disableSort={subscriptionList.length === 0}
        filterType="date"
        filterKey="endDate"
        onFilterChange={handleFilter.dateFilterChange}
      >
        {t('Subscrib_Main_14')}
      </TableHeaderCell>
      <TableHeaderCell
        width={150}
        orderBy="endDate"
        order={getSortOrder(filter.sort, 'endDate')}
        onSortChange={handleHeaderCellClick}
        disableSort={subscriptionList.length === 0}
      >
        {t('Subscrib_Main_15')}
      </TableHeaderCell>
      <TableHeaderCell
        width={200}
        orderBy="userGroup"
        order={getSortOrder(filter.sort, 'userGroup')}
        onSortChange={handleHeaderCellClick}
        disableSort={subscriptionList.length === 0}
      >
        {t('Subscrib_Main_16')}
      </TableHeaderCell>
      <TableHeaderCell width={120}>{t('Acc_Main_22')}</TableHeaderCell>
      <TableCell width={72} align="center">
        {t('Acc_Main_23')}
      </TableCell>
    </TableRow>
  );

  const handleMenuClick = (menuKey: 'EDIT' | 'CONNECTION_SETTING' | 'DELETE', target: SubscriptionListModel) => {
    switch (menuKey) {
      case 'EDIT':
        setSubscriptionEditTarget(target);
        break;
      case 'CONNECTION_SETTING':
        navigate(target.connection?.isConnectionExist ? `../connections/${target.id}/connection` : '../connections');
        break;
      case 'DELETE':
        if (target.isExternalSubscription) {
          if (target.connection?.isConnected) {
            alertStore.open({ title: t('Subscrib_Main_26'), message: t('Subscrib_Delete_04') });
          } else {
            alertStore.open({
              title: t('Subscrib_Main_24'),
              message: t('Subscrib_Delete_01'),
              type: 'error',
              confirmName: t('Subscrib_Detail_BuyRequest_12'),
              onConfirm: () => {
                deleteSubscription(target.id);
                toastStore.open({ message: t('Subscrib_Delete_05'), hideAction: true });
              },
              useCancel: true,
            });
          }
        }
        break;
    }
  };

  const renderItemContent = (index: number, model: SubscriptionListModel) => (
    <SubscriptionRow key={model.id} model={model} appliedFilter={appliedFilter} onMenuClick={handleMenuClick} />
  );

  return (
    <>
      <TableVirtuoso
        data={subscriptionList}
        components={tableComponents}
        fixedHeaderContent={renderFixedHeaderContent}
        itemContent={renderItemContent}
        endReached={endReached}
        context={{ isLoading, emptyDataMessage: t('Subscrib_Main_Filter_08'), onRowClick: handleRowClick }}
        increaseViewportBy={{ top: 840, bottom: 840 }}
        customScrollParent={scrollTarget ?? undefined}
      />

      <SubscriptionEditDialog
        subscriptionId={subscriptionEditTarget?.id ?? ''}
        softwareId={subscriptionEditTarget?.software.id ?? ''}
        open={!!subscriptionEditTarget}
        onClose={() => setSubscriptionEditTarget(null)}
      />
    </>
  );
};
