import React, { ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';

type Props = {
  colSpan: number;
  title: ReactNode;
};

export const EmptyRow = ({ colSpan, title }: Props) => {
  const { colors } = useTheme();

  return (
    <EmptyTableRow>
      <TableCell colSpan={colSpan}>
        <EmptyCellContent>
          <ErrorIconWrapper>
            <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
          </ErrorIconWrapper>
          {React.isValidElement(title) ? title : <EmptyCellDescription>{title} </EmptyCellDescription>}
        </EmptyCellContent>
      </TableCell>
    </EmptyTableRow>
  );
};

const EmptyTableRow = styled(TableRow)`
  &:hover {
    background-color: ${({ theme: { colors } }) => colors['bg-white']};
  }
`;

const EmptyCellContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 0px 30px;
  gap: 12px;
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;

const EmptyCellDescription = styled.p`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;
