import { useState } from 'react';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, CircularProgress, useTheme } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useGetSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import type { ConnectorActionModel } from '@models/connection/ConnectorActionModel';
import type { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';

interface Props {
  subscriptionConnection: SubscriptionConnectionModel;
}

export const Action = ({ subscriptionConnection }: Props) => {
  const theme = useTheme();

  const { workflowDetailStore } = useStore();

  const [subscriptionId, setSubscriptionId] = useState<string>('');
  const { data: connectionDetail, isSuccess } = useGetSubscriptionConnection(subscriptionId, {
    enabled: subscriptionId !== '',
  });

  const handleClickAction = (action: ConnectorActionModel) => {
    if (!workflowDetailStore.checkEditable()) return;

    const targetActionNode = workflowDetailStore.findNode(workflowDetailStore.curNodeId ?? '');
    if (targetActionNode) {
      targetActionNode.data.actionModel = {
        ...targetActionNode.data.actionModel,
        type: 'CONNECTION_ACTION',
        actionArguments:
          action.inputParameters.map(param => ({
            argumentName: param.name,
            expression: '',
            expressionType: param.inputType === 'ARRAY' ? 'CUSTOM' : 'SIMPLE',
          })) ?? [],
        actionMethod: action.method,
        actionName: action.name,
        connectionId: subscriptionConnection.connectionId,
      };

      targetActionNode.data.connectionModel = connectionDetail;
      targetActionNode.data.isSmpAction = false;
      workflowDetailStore.setToolbarView('actionConfig');
    }
  };

  return (
    <StyledAccordion>
      <StyledAccordionSummary
        className="accordion-summary"
        expandIcon={<ChevronIcon width={20} height={20} rotateNum={180} color={theme.colors['ic-gray-light']} />}
        onClick={() => {
          setSubscriptionId(subscriptionConnection.subscription.id);
        }}
      >
        <ConnectionInfo>
          <Avatar
            src={subscriptionConnection.subscription.product.iconUrl}
            sx={{ width: '36px', height: '36px' }}
            variant="square"
          />
          <ConnectionMeta>
            <ConnectionAppName>{subscriptionConnection.subscription.product.name}</ConnectionAppName>
            <ConnectionSubscriptionName>{subscriptionConnection.subscription.name}</ConnectionSubscriptionName>
          </ConnectionMeta>
        </ConnectionInfo>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {isSuccess ? (
          connectionDetail?.connection.connector.actions.map(action => {
            return (
              <ActionCandidate
                key={action.name}
                id={action.name}
                onClick={() => {
                  handleClickAction(action);
                }}
              >
                <strong className="action-name">{action.name}</strong>
                <p className="action-desc">{action.description}</p>
              </ActionCandidate>
            );
          })
        ) : (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  box-shadow: none;

  padding: 0;
  margin: 0;
  &:first-of-type {
    border-radius: 5px;
  }
  &::before {
    content: none;
  }
  & .MuiButtonBase-root {
    padding: 17px 29px;
  }
  &.Mui-expanded {
    margin: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  height: 84px;
  & .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
`;

const ConnectionInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  align-items: center;
`;

const ConnectionMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ConnectionAppName = styled.strong`
  ${fonts.Headline9};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const ConnectionSubscriptionName = styled.p`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
`;

const ActionCandidate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  padding: 11px 28px;
  border: 1px solid transparent;
  cursor: pointer;

  :not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  }
  & .action-name {
    ${fonts.Headline9};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
  & .action-desc {
    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  :hover {
    border: 1px solid ${({ theme: { colors } }) => colors['border-purple-light']};
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
  }
`;

const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
