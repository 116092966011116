import styled from '@emotion/styled';
import { Box, Dialog, DialogContent } from '@mui/material';

export const DialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    width: 350px;
    height: 250px;
  }
`;

export const TitleWrapper = styled(Box)`
  padding: 16px 24px;
  border-bottom: 1px solid #e9e9ed;
`;

export const ContentWrapper = styled(DialogContent)`
  padding-top: 16px !important;
`;

export const ContinueBtnWrapper = styled(Box)`
  display: flex;
  justify-content: right;
  padding: 16px;
`;
