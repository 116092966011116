import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Loader from '@components/loader/Loader';
import { BulletIcon } from '@icons/BulletIcon';
import { UserGroupTable } from './UserGroupTable';

export const UserGroupMgmt = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Suspense fallback={<Loader />}>
      <Container>
        <HeaderWrapper>
          <Typography component="strong" variant="headline5">
            {t('Member_LNB_04')}
          </Typography>
        </HeaderWrapper>
        <TableInfo>
          <TableInfoItem>
            <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
            <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
              {t('Member_Group_01')}
            </Typography>
          </TableInfoItem>
          <TableInfoItem>
            <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
            <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
              {t('Member_Group_02')}
            </Typography>
          </TableInfoItem>
          <TableInfoItem>
            <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
            <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
              {t('Member_Group_03')}
            </Typography>
          </TableInfoItem>
        </TableInfo>
        <UserGroupTable />
      </Container>
    </Suspense>
  );
});

const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 0 32px 32px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 8px 14px;
`;
const TableInfo = styled('ul')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: cneter;
  align-items: flex-start;
  gap: 2px;
  padding: 16px 32px;
  margin-bottom: 32px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
`;

const TableInfoItem = styled('li')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
