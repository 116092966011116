import styled from '@emotion/styled';

interface Props {
  percentage: number;
  color: {
    start: string;
    end: string;
  };
}

export const HorizontalProgressBar = ({ percentage, color }: Props) => {
  // percentage: 0에서 100 사이의 퍼센티지 값
  // color: 바의 채우는 색상

  const progressStyle = {
    width: `${percentage}%`, // 퍼센티지에 따라 동적으로 너비 설정
    background: `linear-gradient(to right, ${color.start}, ${color.end})`, // 색상 설정
  };

  return <Progress style={progressStyle} />;
};

const Progress = styled.div`
  position: relative;
  border-radius: 0px 2px 2px 0px;
  height: 100%;
  transition: width 0.3s ease; /* 퍼센티지 변경 시 부드럽게 애니메이션 적용 */
  height: 6px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid ${({ theme: { colors } }) => colors['border-bk-opacity-10%']};
  }
`;
