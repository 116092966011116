import { makeAutoObservable } from 'mobx';
import { Software } from '@repositories/softwareRepository';
import { Tenant } from '@repositories/tenantRepository';
import { InOutcomeShare, InOutcomeShareStat } from '@type/Statistics';
import { ObjectUtil } from '@utils/ObjectUtil';

export class InOutcomeShareStatModel<DataType extends Software | Tenant> implements InOutcomeShareStat<DataType> {
  data: InOutcomeShare<DataType>[];

  monthFrom: DateTimeString;

  monthTo: DateTimeString;

  totalAmount: number;

  totalOriginalAmount: number;

  isSoftwareInOutcome: boolean;

  currencyUnit: CurrencyUnit;

  originalCurrencyUnit: CurrencyUnit;

  constructor(isSoftwareInOutcome: boolean, dto?: InOutcomeShareStat<DataType>) {
    this.data = [];
    this.monthFrom = '';
    this.monthTo = '';
    this.totalAmount = -1;
    this.totalOriginalAmount = -1;
    this.isSoftwareInOutcome = isSoftwareInOutcome;
    this.currencyUnit = 'KRW';
    this.originalCurrencyUnit = 'USD';

    if (dto) {
      this.update(dto);
    }
    makeAutoObservable(this);
  }

  update = (dto: Partial<InOutcomeShareStat<DataType>>) => {
    ObjectUtil.update(this, dto);
  };

  get pieChartData(): { name: string; data: number }[] {
    if (!this.isSoftwareInOutcome) {
      return [];
    }

    const data = this.data as unknown as InOutcomeShare<Software>[];
    return data.map(({ amount, software }) => ({
      name: software ? software.name : 'Others',
      data: amount,
      percent: (amount * 100) / this.totalAmount,
    }));
  }

  get barChartData(): { name: string; data: number; percent: number }[] {
    if (this.isSoftwareInOutcome) {
      return [];
    }

    const data = this.data as unknown as InOutcomeShare<Tenant>[];
    return data.map(({ amount, tenant }) => ({
      name: tenant ? tenant.name : 'Others',
      data: amount,
      percent: (amount * 100) / this.totalAmount,
    }));
  }
}
