import { Modal, Typography, TextField, Button, Avatar } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { ProviderCreateRq } from '@type/Provider';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as S from './ProviderDetailStyles';

export const ProviderDetail = observer(() => {
  const { uiStore, softwareStore } = useStore();
  const [readMode, setReadMode] = useState<boolean>(true);

  const handleEditCancel = () => {
    setReadMode(true);
    const provider = softwareStore.getProvider(uiStore.providerDetailId);
    reset({
      description: provider?.description,
      email: provider?.email,
      icon: provider?.icon,
      name: provider?.name,
      phone: provider?.phone,
      url: provider?.url,
    });
  };

  const handleClose = () => {
    uiStore.closeProviderDetailModal();
    setReadMode(true);
  };

  const { register, handleSubmit, reset } = useForm<ProviderCreateRq>({
    mode: 'onChange',
    shouldFocusError: true,
  });

  const validationHandler = {
    success: async (data: ProviderCreateRq) => {
      console.log('data', data);
      try {
        const result = await softwareStore.updateProvider({ id: data.id as string }, data);
        setReadMode(true);
        reset({
          description: result?.description,
          email: result?.email,
          icon: result?.icon,
          id: result?.id,
          name: result?.name,
          phone: result?.phone,
          url: result?.url,
        });
      } catch (error) {
        console.error('err', error);
      }
    },
    fail: (/* err: any */) => {
      //
    },
  };

  useEffect(() => {
    const provider = softwareStore.getProvider(uiStore.providerDetailId);
    reset({
      description: provider?.description,
      email: provider?.email,
      icon: provider?.icon,
      name: provider?.name,
      phone: provider?.phone,
      url: provider?.url,
      id: provider?.id,
    });
  }, [reset, softwareStore, uiStore.providerDetailId]);

  return (
    <Modal open={uiStore.isVisibleProviderDetailModal} onClose={handleClose}>
      <S.Container>
        <S.ModalTitleWrapper>
          <Typography variant="h4">Detail Provider</Typography>
        </S.ModalTitleWrapper>
        <S.ModalContentWrapper>
          <S.AvatarWapper>
            <Avatar sx={{ width: '100px', height: '100px' }} />
          </S.AvatarWapper>
          <S.ElementTitleWrapper>
            <Typography>Icon URL</Typography>
            <Button variant="text">Preview</Button>
          </S.ElementTitleWrapper>
          <TextField size="small" fullWidth {...register('icon')} disabled={readMode} />
          <S.ElementTitleWrapper>
            <Typography>Distributor</Typography>
          </S.ElementTitleWrapper>
          <TextField size="small" fullWidth {...register('name')} disabled={readMode} />
          <S.ElementTitleWrapper>
            <Typography>Email Address</Typography>
          </S.ElementTitleWrapper>
          <TextField size="small" fullWidth {...register('email')} disabled={readMode} />
          <S.ElementTitleWrapper>
            <Typography>Contact</Typography>
          </S.ElementTitleWrapper>
          <TextField size="small" fullWidth {...register('phone')} disabled={readMode} />
          <S.ElementTitleWrapper>
            <Typography>Website URL</Typography>
          </S.ElementTitleWrapper>
          <TextField size="small" fullWidth {...register('url')} disabled={readMode} />
          <S.ElementTitleWrapper>
            <Typography>Description</Typography>
          </S.ElementTitleWrapper>
          <TextField size="small" fullWidth multiline rows={3} {...register('description')} disabled={readMode} />
        </S.ModalContentWrapper>
        {readMode ? (
          <S.ButtonsWrapper>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
            <Button variant="outlined" onClick={() => setReadMode(false)}>
              Edit
            </Button>
          </S.ButtonsWrapper>
        ) : (
          <form onSubmit={handleSubmit(validationHandler.success, validationHandler.fail)}>
            <S.ButtonsWrapper>
              <Button variant="outlined" onClick={handleEditCancel}>
                Cancel
              </Button>
              <Button variant="outlined" type="submit">
                Save
              </Button>
            </S.ButtonsWrapper>
          </form>
        )}
      </S.Container>
    </Modal>
  );
});
