const defaultWidth = 16;
const defaultHeight = 16;

export const TableIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33301 2.49967C1.33301 1.85534 1.85534 1.33301 2.49967 1.33301H13.4997C14.144 1.33301 14.6663 1.85534 14.6663 2.49967V13.4997C14.6663 14.144 14.144 14.6663 13.4997 14.6663H2.49967C1.85534 14.6663 1.33301 14.144 1.33301 13.4997V2.49967ZM2.33301 5.99967V13.4997C2.33301 13.5917 2.40763 13.6663 2.49967 13.6663H4.99968L4.99967 5.99967H2.33301ZM4.99967 4.99967H2.33301V2.49967C2.33301 2.40763 2.40763 2.33301 2.49967 2.33301H4.99967V4.99967ZM5.99967 5.99967L5.99968 13.6663H13.4997C13.5917 13.6663 13.6663 13.5917 13.6663 13.4997V5.99967H5.99967ZM13.6663 4.99967H5.99967V2.33301H13.4997C13.5917 2.33301 13.6663 2.40763 13.6663 2.49967V4.99967Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2002 2.5002C1.2002 1.78223 1.78222 1.2002 2.5002 1.2002H13.5002C14.2182 1.2002 14.8002 1.78222 14.8002 2.5002V13.5002C14.8002 14.2182 14.2182 14.8002 13.5002 14.8002H2.5002C1.78223 14.8002 1.2002 14.2182 1.2002 13.5002V2.5002ZM2.46686 6.13353V13.5002C2.46686 13.5186 2.48179 13.5335 2.5002 13.5335H4.86686L4.86686 6.13353H2.46686ZM4.86686 2.46686H2.5002C2.48179 2.46686 2.46686 2.48179 2.46686 2.5002V4.86686H4.86686V2.46686ZM6.13353 6.13353L6.13353 13.5335H13.5002C13.5186 13.5335 13.5335 13.5186 13.5335 13.5002V6.13353H6.13353ZM13.5335 4.86686V2.5002C13.5335 2.48179 13.5186 2.46686 13.5002 2.46686H6.13353V4.86686H13.5335Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
