import { DailyUserStatByUserStatusDTO } from '@repositories/userStatRepository/Types';
import { AggregateUserCountByStatusDTOModel } from './AggregateUserCountByStatusDTOModel';

export class DailyUserStatByUserStatusDTOModel {
  private readonly _dto: DailyUserStatByUserStatusDTO;
  private readonly _billableUserBySoftwareTop5: AggregateUserCountByStatusDTOModel[];
  private readonly _billableUserBySubscriptionTop5: AggregateUserCountByStatusDTOModel[];
  private readonly _inactiveUserBySoftwareTop5: AggregateUserCountByStatusDTOModel[];
  private readonly _inactiveUserBySubscriptionTop5: AggregateUserCountByStatusDTOModel[];

  constructor(dto: DailyUserStatByUserStatusDTO) {
    this._dto = dto;
    this._billableUserBySoftwareTop5 =
      dto.billableUserBySoftwareTop5?.map(info => new AggregateUserCountByStatusDTOModel(info)) || [];
    this._billableUserBySubscriptionTop5 =
      dto.billableUserBySubscriptionTop5?.map(info => new AggregateUserCountByStatusDTOModel(info)) || [];
    this._inactiveUserBySoftwareTop5 =
      dto.inactiveUserBySoftwareTop5?.map(info => new AggregateUserCountByStatusDTOModel(info)) || [];
    this._inactiveUserBySubscriptionTop5 =
      dto.inactiveUserBySubscriptionTop5?.map(info => new AggregateUserCountByStatusDTOModel(info)) || [];
  }

  get billableUserBySoftwareTop5() {
    return this._billableUserBySoftwareTop5;
  }

  get billableUserBySubscriptionTop5() {
    return this._billableUserBySubscriptionTop5;
  }

  get inactiveUserBySoftwareTop5() {
    return this._inactiveUserBySoftwareTop5;
  }

  get inactiveUserBySubscriptionTop5() {
    return this._inactiveUserBySubscriptionTop5;
  }
}
