import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { UserGroupSelect } from '@components/userGroupSelect/UserGroupSelect';
import { useStore } from '@stores/RootStore';
import { BulletIcon } from '@icons/BulletIcon';
import { XIcon } from '@icons/XIcon';
import { AddIcon, DashIcon } from '@icons/index';
import useDynamicInput from '@customHooks/useDynamicInput';
import { useScrollToBottom } from '@customHooks/useScrollToBottom';
import { useGetUserList, useInviteUserToTenant } from '@queryHooks/useUser';
import * as S from './UserIndividualRegisterStyles';
import { FormUser, useUserForm } from './validationSchema';

export const UserIndividualRegister = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uiStore: { userIndividualRegisterStore: modal },
    authStore,
  } = useStore();
  const [account, setAccount] = useState<string>();
  const [bodyHeight, setBodyHeight] = useState(0);
  const { scrollToBottom, scrollRef } = useScrollToBottom();

  const {
    data: dynamicGroups,
    add,
    handleChangeValue,
    remove,
  } = useDynamicInput<{ userGroupId: string; name: string }>({
    userGroupId: authStore.managerTenantInfo.id,
    name: authStore.managerTenantInfo.name,
  });

  const { data: userData } = useGetUserList(
    { email: account },
    {
      enabled: !!account,
      onSuccess: data => {
        if (!data.empty && data.content.find(user => user.email === account)) {
          setError('email', { message: '이미 회원가입 된 계정입니다.' });
        }
      },
      cacheTime: 0,
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useUserForm(userData?.content);

  const validation = {
    success: (data: FormUser) => {
      inviteUser(
        {
          email: data.email,
          phone: data.phone,
          username: data.username,
          userGroupIdList: dynamicGroups.map(selected => selected.userGroupId),
          tenantId: authStore.curTenant.id,
          isMailSend: true,
        },
        {
          onSuccess: () => {
            modal.close();
          },
        },
      );
    },
  };

  const handleSeleted = (idx: number) => (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.currentTarget.dataset.name !== undefined && e.currentTarget.dataset.userGroupId !== undefined) {
      handleChangeValue(
        {
          name: e.currentTarget.dataset.name,
          userGroupId: e.currentTarget.dataset.userGroupId,
        },
        idx,
      );
    }
  };

  const handleChangeEmail =
    (onChange: (...event: any[]) => void) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(e.target.value);
      setAccount(e.target.value);
    };

  const { mutate: inviteUser } = useInviteUserToTenant();

  useEffect(() => {
    scrollToBottom();
    if (scrollRef.current !== null) {
      setBodyHeight(scrollRef.current.clientHeight);
    } else {
      setBodyHeight(prev => prev + 1);
    }
  }, [scrollRef, scrollToBottom]);

  return (
    <Modal open={modal.visible}>
      <S.ModalWrapper onSubmit={handleSubmit(validation.success)}>
        <S.Header>
          <S.HeaderTitle>{t('Member_User_12')}</S.HeaderTitle>
          <Button onClick={modal.close}>
            <XIcon color={theme.colors['ic-gray-main']} />
          </Button>
        </S.Header>
        <S.Body ref={scrollRef}>
          <div className="info">
            <S.EssentialInfo>
              <div className="infoTitle">{t('Member_User_Create_01')}</div>
              <S.InfoKey>
                {t('Workflow_Main_05')}
                <span>*</span>
              </S.InfoKey>
              <TextField
                {...register('username')}
                size="medium"
                fullWidth
                placeholder={t('Member_User_Bulk_Create_16')}
                sx={{ marginBottom: '20px' }}
                error={!!errors.username?.message}
                helperText={errors.username?.message}
              />
              <S.InfoKey>
                {t('Member_User_14')}
                <span>*</span>
              </S.InfoKey>
              <Controller
                control={control}
                name="email"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="medium"
                    fullWidth
                    placeholder="example@gamil.com"
                    error={!!errors.email?.message}
                    helperText={errors.email?.message}
                    value={value ?? ''}
                    onChange={handleChangeEmail(onChange)}
                  />
                )}
              />
            </S.EssentialInfo>
            <S.OptionalInfo>
              <div className="infoTitle">{t('Member_User_Bulk_Create_17')}</div>
              <S.InfoKey>{t('Member_User_Bulk_Create_18')}</S.InfoKey>
              <div className="groups">
                {dynamicGroups.map((group, idx) => {
                  return (
                    <div key={group.id} className="group-item">
                      <UserGroupSelect
                        selectedValue={{ name: group.name, userGroupId: group.userGroupId }}
                        onSelectChange={handleSeleted(idx)}
                        optionWidth={342}
                      />
                      <div className="add">
                        <Button
                          className="icon-btn"
                          variant="outline"
                          size="large"
                          onClick={() =>
                            add({ userGroupId: authStore.managerTenantInfo.id, name: authStore.managerTenantInfo.name })
                          }
                          disabled={dynamicGroups.length > idx + 1}
                        >
                          <AddIcon
                            width={20}
                            height={20}
                            color={
                              dynamicGroups.length > idx + 1
                                ? theme.colors['ic-gray-light']
                                : theme.colors['ic-gray-main']
                            }
                          />
                        </Button>
                      </div>
                      {idx > 0 && (
                        <div className="delete">
                          <Button className="icon-btn" variant="outline" size="large" onClick={() => remove(idx)}>
                            <DashIcon width={20} height={20} />
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <S.InfoKey>{t('Member_User_16')}</S.InfoKey>
              <TextField
                {...register('phone')}
                size="medium"
                fullWidth
                placeholder={t('Member_User_Bulk_Create_19')}
                helperText={errors.phone?.message}
                error={!!errors.phone?.message}
              />
            </S.OptionalInfo>
          </div>
          <ul className="description">
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              {t('Member_User_Bulk_Create_20')}
            </li>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              {t('Member_User_Bulk_Create_21')}
            </li>
          </ul>
        </S.Body>
        <S.Footer $hasScrollbar={bodyHeight > 528}>
          <Button variant="contain" size="extraLarge" fullWidth type="submit">
            {t('Member_User_Bulk_Create_24')}
          </Button>
        </S.Footer>
      </S.ModalWrapper>
    </Modal>
  );
});
