import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, TableCell, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { DashIcon, AddIcon, DepthIcon, XIcon, CheckIcon } from '@icons/index';
import { UserGroupCreateRq } from '@repositories/userGroupsRepository/Types';
import { useDeleteTenant } from '@queryHooks/useTenant';
import { useAddUserGroup, useGetUserGroups } from '@queryHooks/useUserGroup';
import { TenantListModel } from '@models/tenantModels/TenantListModel';
import { UserGroupTableSubRow } from './UserGroupTableSubRow';
import { useGroupForm } from './validationSchema';

type FormData = {
  name: string;
  userGroupCode: string;
};
interface Props {
  idx?: number;
  rowData: TenantListModel;
  depth?: number;
}

export const UserGroupTableRow = observer(({ rowData: tenant, depth = -1 }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uiStore: { alertStore: modal },
  } = useStore();
  const [isDuplicationGroupCode, setIsDuplicationGroupCode] = useState(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isVisibleHover, setIsVisibleHover] = useState<boolean>(false);

  const { data: subUserGroup } = useGetUserGroups({ tenantId: tenant.id, isRoot: true });
  const { mutate: deleteUserGroup } = useDeleteTenant();
  const { mutate: createUserGroup } = useAddUserGroup();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useGroupForm();

  const handleClickAddTenant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsCreating(true);
  };

  const rqDelete = () => {
    deleteUserGroup(tenant?.id as string);
  };

  const handleClickRemoveTenant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    modal.open({
      useCancel: true,
      title: `${t('Member_Group_Delete_01')}`,
      message: `${t('Member_Group_Delete_02')}`,
      onConfirm: rqDelete,
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      cancelName: t('Subscrib_Detail_BuyRequest_13'),
      type: 'error',
    });
  };

  const removeTenantCreateRow = () => {
    setIsCreating(false);
    reset();
  };

  const validateHandler = {
    success: async (data: FormData) => {
      try {
        const rqData: UserGroupCreateRq = {
          name: data.name,
          tenantId: tenant.id,
          userGroupCode: data.userGroupCode,
        };
        removeTenantCreateRow();
        createUserGroup(rqData, {
          onError: error => {
            handleDuplicationGroupCodeError(error);
          },
          onSuccess: () => {
            removeTenantCreateRow();
          },
        });
      } catch (error) {
        console.log('에러 발생');
      }
    },
    fail: (err: any) => {
      console.error('Fail create tenant validate: ', err);
    },
  };

  const handleDuplicationGroupCodeError = (error: any) => {
    if (axios.isAxiosError(error) && error.response) {
      const { messageId } = error.response.data?.errorCode || {};
      if (messageId === 'USER_GROUP.ERROR.DUPLICATED_USER_GROUP_CODE') {
        setIsDuplicationGroupCode(true);
        return;
      }
      setIsDuplicationGroupCode(false);
    }
    console.error('예상치 못한 오류:', error);
  };

  return (
    <>
      <StyledTableRow hover onMouseLeave={() => setIsVisibleHover(false)} onMouseEnter={() => setIsVisibleHover(true)}>
        <StyledTableCell className="tenant">
          <Typography variant="body2" component="span">
            {tenant?.name}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left" />
        <StyledTableCell align="left">{tenant?.memberCount}</StyledTableCell>
        <StyledTableCell>
          <DescriptionWrapper>
            {tenant?.subscriptionCount}
            <ButtonWrapper>
              {isVisibleHover && depth === 0 ? (
                <DepthButton size="small" variant="outline" paddingHorizontal={4} onClick={handleClickAddTenant}>
                  <AddIcon width={14} height={14} />
                </DepthButton>
              ) : isVisibleHover && depth >= 0 ? (
                <>
                  <DepthButton size="small" variant="outline" paddingHorizontal={4} onClick={handleClickAddTenant}>
                    <AddIcon width={14} height={14} />
                  </DepthButton>
                  <DepthButton size="small" variant="outline" paddingHorizontal={4} onClick={handleClickRemoveTenant}>
                    <DashIcon width={14} height={14} />
                  </DepthButton>
                </>
              ) : null}
            </ButtonWrapper>
          </DescriptionWrapper>
        </StyledTableCell>
      </StyledTableRow>

      {isCreating && (
        <StyledTableRow>
          <CreatingTableCell>
            <NewDepthForm sx={{ paddingLeft: `${(depth + 1) * 14}px` }}>
              <DepthIconWrap>
                <DepthIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
              </DepthIconWrap>
              <NewTenantField>
                <Tooltip
                  title={errors.name?.message}
                  arrow
                  placement="bottom-start"
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        maxWidth: 'none',
                      },
                      '& .MuiTooltip-arrow': {
                        transform: 'translate(20px, 0) !important',
                      },
                    },
                  }}
                >
                  <TextField
                    {...register('name', { required: '그룹 이름을 입력해주세요' })}
                    size="small"
                    fullWidth
                    error={!!errors.name?.message}
                    placeholder={`Depth ${depth + 1}`}
                  />
                </Tooltip>
              </NewTenantField>
            </NewDepthForm>
          </CreatingTableCell>
          <CreatingTableCell>
            <NewTenantField>
              <Tooltip
                title={isDuplicationGroupCode ? t('Member_Group_Delete_07') : errors.userGroupCode?.message}
                arrow
                placement="bottom-start"
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      maxWidth: 'none',
                    },
                    '& .MuiTooltip-arrow': {
                      transform: 'translate(20px, 0) !important',
                    },
                  },
                }}
              >
                <TextField
                  {...register('userGroupCode', { required: '그룹 코드를 입력해주세요' })}
                  size="small"
                  fullWidth
                  error={!!errors.userGroupCode?.message}
                  placeholder={`Depth ${depth + 1}`}
                />
              </Tooltip>
            </NewTenantField>
          </CreatingTableCell>
          <CreatingTableCell align="center">
            <NewDepthBtnWrap>
              <form onSubmit={handleSubmit(validateHandler.success, validateHandler.fail)}>
                <Button type="submit" variant="outline" size="xsmall" paddingHorizontal={4}>
                  <CheckIcon width={14} height={14} color={theme.colors['ic-green']} />
                </Button>
              </form>
              <Button onClick={removeTenantCreateRow} variant="outline" size="xsmall" paddingHorizontal={4}>
                <XIcon width={14} height={14} color={theme.colors['ic-gray-main']} />
              </Button>
            </NewDepthBtnWrap>
          </CreatingTableCell>
          <StyledTableCell align="center" />
        </StyledTableRow>
      )}

      {subUserGroup?.map(userGroup => {
        return <UserGroupTableSubRow rowData={userGroup} depth={depth + 1} key={userGroup.userGroupId} />;
      })}
    </>
  );
});

const DepthButton = styled(Button)`
  height: 24px;
  border-color: ${({ theme }) => theme.colors['border-gray-light']};
`;

const DepthIconWrap = styled('div')`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const NewDepthForm = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
  & > svg {
    flex-shrink: 0;
  }
`;

const NewTenantField = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  & > svg {
    flex-shrink: 0;
  }
  & .MuiInputBase-input {
    padding: 6px 12px;
    &:placeholder {
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
  }
`;

const NewDepthBtnWrap = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTableCell = styled(TableCell)`
  padding: 0 7px 0 16px;
  &:last-of-type {
    padding-right: 16px;
  }
  &:first-of-type {
    padding: 0 7px 0 20px;
  }
  &.edit-name {
    padding: 0 4px;
  }
  &.tenant {
    padding: 0 16px;
  }
`;

const CreatingTableCell = styled(TableCell)`
  border-width: 1px 0 1px 0;
  padding: 0 8px 0 4px;
  &:first-of-type {
    padding: 0 8px 0 24px;
  }
`;

const StyledTableRow = styled(TableRow)`
  background-color: ${({ theme }) => theme.colors['bg-white']};
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
  &:hover.MuiTableRow-hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
  &.sub-user-group {
    cursor: pointer;
  }
`;
