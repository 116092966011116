// import { useTheme } from '@emotion/react';

const defaultWidth = 24;
const defaultHeight = 24;

export const SupportIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9999 1.94531C7.65724 1.94531 4.09824 5.3007 3.77407 9.56035C3.75836 9.62079 3.75 9.68419 3.75 9.74954V15.7495C3.75 16.1638 4.08579 16.4995 4.5 16.4995H8.25C8.66421 16.4995 9 16.1638 9 15.7495V9.74954C9 9.33533 8.66421 8.99954 8.25 8.99954H5.35558C5.92001 5.84201 8.68007 3.44531 11.9999 3.44531C15.3198 3.44531 18.0798 5.84201 18.6443 8.99954H15.75C15.3358 8.99954 15 9.33533 15 9.74954V15.7495C15 16.1638 15.3358 16.4995 15.75 16.4995H18.6132C18.072 18.8228 15.9881 20.5537 13.5 20.5537H12.0313C12.0046 20.5508 11.9774 20.5493 11.95 20.5493C11.5357 20.5493 11.2 20.8851 11.2 21.2993C11.2 21.7135 11.5357 22.0493 11.95 22.0493C11.9668 22.0493 11.9835 22.0488 12 22.0477V22.0537H13.5C17.0038 22.0537 19.8841 19.384 20.2177 15.9678C20.2387 15.8988 20.25 15.8255 20.25 15.7495V9.74954C20.25 9.68387 20.2416 9.62017 20.2257 9.55947C19.9011 5.30024 16.3423 1.94531 11.9999 1.94531ZM16.5 10.4995H18.7498L18.75 14.9995H16.5V10.4995ZM5.25 14.9995V10.4995H7.5V14.9995H5.25Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7998 22.2545C11.3464 22.1826 10.9998 21.7737 10.9998 21.3001C10.9998 20.7754 11.4251 20.3501 11.9498 20.3501C11.9806 20.3501 12.0111 20.3516 12.0413 20.3545H13.4998C15.8044 20.3545 17.7489 18.8105 18.3545 16.7003H15.7498C15.2251 16.7003 14.7998 16.275 14.7998 15.7503V9.75032C14.7998 9.22565 15.2251 8.80032 15.7498 8.80032H18.4007C17.7612 5.85345 15.138 3.64609 11.9997 3.64609C8.8614 3.64609 6.23825 5.85345 5.59878 8.80032H8.2498C8.77448 8.80032 9.1998 9.22565 9.1998 9.75032V15.7503C9.1998 16.275 8.77448 16.7003 8.2498 16.7003H4.4998C3.97513 16.7003 3.5498 16.275 3.5498 15.7503V9.75032C3.5498 9.67437 3.55876 9.60026 3.57575 9.52909C3.91589 5.17398 7.5574 1.74609 11.9997 1.74609C16.4417 1.74609 20.083 5.17345 20.4236 9.52807C20.4408 9.59956 20.4498 9.67401 20.4498 9.75032V15.7503C20.4498 15.8389 20.4376 15.9249 20.4147 16.0067C20.0626 19.5154 17.1012 22.2545 13.4998 22.2545H11.7998ZM16.4998 10.5003H18.7496L18.7498 15.0003H16.4998V10.5003ZM5.2498 15.0003V10.5003H7.4998V15.0003H5.2498Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SupportIcon.displayName = 'SupportIcon';
