import { MouseEvent, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import { FileIcon } from '@components/FileIcon';
import { IconButton } from '@components/buttons';
import { Button } from '@components/index';
import { useUiStore } from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { UploadIcon } from '@icons/UploadIcon';
import { XIcon } from '@icons/XIcon';
import { useFileDialog } from '@customHooks/useFileDialog';
import { useUploadMonthlyBillingAttachments } from '@queryHooks/useMonthlyBilling';
import { parseFileName } from '@utils/fileUtil';
import { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';

type Props = {
  addedMonthlyBilling: SubscriptionBillingModel;
  onClose: () => void;
};
export const BillingAttachFile = ({ addedMonthlyBilling, onClose }: Props) => {
  const { colors } = useTheme();
  const { alertStore } = useUiStore();
  const { openFileDialog } = useFileDialog();

  const [selectedFiles, setSelectedFiles] = useState<
    {
      id: string;
      fileData: File;
    }[]
  >([]);

  const { mutate: uploadAttachment } = useUploadMonthlyBillingAttachments();

  const handleFileSelectClick = () => {
    openFileDialog(fileList => {
      const validFiles = Array.from(fileList).filter(({ size }) => size <= 5242880);
      if (fileList.length !== validFiles.length) {
        alertStore.open({ title: '파일 첨부', message: '5MB 이하의 파일만 첨부할 수 있습니다.' });
      }
      if (selectedFiles.length + validFiles.length > 10) {
        alertStore.open({ title: '파일 첨부', message: '최대 10개의 파일을 첨부할 수 있습니다.' });
      }
      const filesWithId = validFiles.map(fileData => ({ id: nanoid(), fileData }));
      setSelectedFiles(prev => [...prev, ...filesWithId].slice(0, 10));
    });
  };

  const handleRemoveFileClick = (targetId: string) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedFiles(prev => prev.filter(file => file.id !== targetId));
  };

  const handleAttachFile = () => {
    uploadAttachment(
      { billingId: addedMonthlyBilling.id, files: selectedFiles.map(({ fileData }) => fileData) },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <>
      <Container>
        <Description>필요한 경우 청구서 등 해당 정산과 관련된 문서를 첨부할 수 있습니다.</Description>
        <Description>파일 당 5MB 이하, 최대 10개의 파일을 첨부할 수 있습니다.</Description>

        <AttachButton
          variant="outline"
          size="large"
          fullWidth
          onClick={handleFileSelectClick}
          startIcon={<UploadIcon width={20} height={20} color={colors['ic-purple-light']} />}
        >
          첨부 파일 등록 <small>{`( ${selectedFiles.length} / 10 )`}</small>
        </AttachButton>

        {selectedFiles.length === 0 ? (
          <EmptyFiles>
            <ErrorIconWrapper>
              <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
            </ErrorIconWrapper>
            첨부된 파일이 없습니다.
          </EmptyFiles>
        ) : (
          <FileWrapper>
            {selectedFiles.map(({ id, fileData }) => {
              const [fileName, fileExtension] = parseFileName(fileData.name);
              return (
                <FileItem key={id}>
                  <FileInfo>
                    <FileIcon fileExtension={fileExtension} />
                    <FileName>{fileName}</FileName>
                    {fileExtension && `.${fileExtension}`}
                  </FileInfo>
                  <IconButton variant="icon" onClick={handleRemoveFileClick(id)}>
                    <XIcon width={20} height={20} color={colors['ic-gray-dark']} />
                  </IconButton>
                </FileItem>
              );
            })}
          </FileWrapper>
        )}
      </Container>
      <Footer>
        <Button
          variant="contain"
          size="extraLarge"
          paddingHorizontal={selectedFiles.length > 0 ? 217.5 : 202.5}
          onClick={selectedFiles.length > 0 ? handleAttachFile : onClose}
        >
          {selectedFiles.length > 0 ? '확인' : '건너뛰기'}
        </Button>
      </Footer>
    </>
  );
};

const Container = styled.div`
  padding: 24px 28px;
  flex-grow: 1;
`;

const Description = styled.p`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  & + & {
    margin-top: 2px;
  }
`;

const AttachButton = styled(Button)`
  ${fonts.Button2}
  color: ${({ theme: { colors } }) => colors['text-purple-light']};
  margin: 28px 0px 12px;

  & small {
    ${fonts.Body2}
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
    margin-left: 4px;
  }
`;

const EmptyFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  height: 294px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23D1D5DA' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  ${fonts.Body2}
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;

const FileWrapper = styled.ul`
  height: 294px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  position: relative;
`;

const FileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 11px 14px 10px 16px;
  ${fonts.Body2};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};

  &:first-of-type {
    padding-top: 10px;
  }
  &:nth-of-type(n + 7):last-of-type {
    border-bottom: 0px;
    padding-bottom: 10px;
  }
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  min-width: 0px;
  gap: 6px;
`;

const Footer = styled.div`
  display: flex;
  padding: 24px 0px 28px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
