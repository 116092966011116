// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const PersonIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0003 2.5C8.96275 2.5 6.50037 4.96245 6.50037 8C6.50037 9.88946 7.45313 11.5564 8.90443 12.5466C5.53988 13.7791 3.11629 16.9629 3.00424 20.7277C2.99192 21.1417 3.31757 21.4873 3.7316 21.4997C4.14563 21.512 4.49126 21.1863 4.50358 20.7723C4.62372 16.7357 7.93422 13.5 12.0002 13.5C16.0662 13.5 19.3767 16.7357 19.4968 20.7723C19.5091 21.1863 19.8548 21.512 20.2688 21.4997C20.6828 21.4873 21.0085 21.1417 20.9961 20.7277C20.8841 16.963 18.4606 13.7791 15.0961 12.5466C16.5474 11.5564 17.5002 9.88949 17.5002 8C17.5002 4.96245 15.0378 2.5 12.0003 2.5ZM8.00037 8C8.00037 5.79085 9.7912 4 12.0003 4C14.2094 4 16.0002 5.79085 16.0002 8C16.0002 10.2092 14.2094 12 12.0003 12C9.7912 12 8.00037 10.2092 8.00037 8Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.30017 7.9998C6.30017 4.8518 8.8521 2.2998 12.0001 2.2998C15.1481 2.2998 17.7 4.8518 17.7 7.9998C17.7 9.82484 16.8422 11.4495 15.5084 12.4925C18.7677 13.838 21.0852 17.0022 21.1959 20.7215C21.2115 21.246 20.799 21.6838 20.2745 21.6994C19.7501 21.715 19.3123 21.3025 19.2967 20.7781C19.1798 16.8493 15.9575 13.6998 12 13.6998C8.04248 13.6998 4.82023 16.8493 4.7033 20.7781C4.68769 21.3025 4.2499 21.715 3.72546 21.6994C3.20102 21.6838 2.78853 21.246 2.80414 20.7215C2.91484 17.0022 5.23237 13.838 8.49171 12.4924C7.15801 11.4494 6.30017 9.82481 6.30017 7.9998ZM12.0001 4.1998C9.90147 4.1998 8.20017 5.90111 8.20017 7.9998C8.20017 10.0985 9.90147 11.7998 12.0001 11.7998C14.0987 11.7998 15.8 10.0985 15.8 7.9998C15.8 5.90111 14.0987 4.1998 12.0001 4.1998Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PersonIcon.displayName = 'PersonIcon';
