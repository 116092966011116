import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TableCell, TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import dayjs from 'dayjs';
import { Button, EmptyRow } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetLicenseListInfinite } from '@queryHooks/useLicense';
import { useGetSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import type { LicenseModel } from '@models/LicenseModel';
import { LicenseEditorModal } from './LicenseEditorModal';
import { LicenseRow } from './LicenseRow';

type Filter = {
  sort?: SortString[];
};

export const License = () => {
  const { t } = useTranslation();
  const subscription = useSubscriptionModel();
  const [queries, setQueries] = useSearchParamsFilter<Filter>({
    defaultFilter: { sort: [] },
    whiteList: ['sort'],
  });
  const { data: licenseListPage } = useGetLicenseListInfinite({ subscriptionId: subscription.id, ...queries });
  const { data: subscriptionConnection } = useGetSubscriptionConnection(subscription.id);
  const licenseLists = licenseListPage?.pages.map(({ content }) => content).flat(1) ?? [];
  const [isAddLicenseOpen, setIsAddLicenseOpen] = useState(false);

  const handleAddLicense = () => {
    setIsAddLicenseOpen(true);
  };

  const handleCloseAddLicense = () => {
    setIsAddLicenseOpen(false);
  };

  const findEarliestLicense = (licenseLists: LicenseModel[]) => {
    if (licenseLists.length === 0) {
      return null;
    }
    const earliestLicense = licenseLists.reduce((earliest, current) => {
      const currentCreatedDate = dayjs(current.createdDate);
      if (currentCreatedDate < dayjs(earliest.createdDate)) {
        return current;
      }

      return earliest;
    }, licenseLists[0]);

    return earliestLicense;
  };

  const earliestLicense = findEarliestLicense(licenseLists) || undefined;

  const isLicenseAddBtnDisActivate =
    subscriptionConnection?.isManuallyGetLicense && !subscriptionConnection.isManuallyAddLicense;

  return (
    <Container>
      <Header>
        <TotalText>
          {t('Acc_Main_12')}
          <TotalNumber>{licenseLists.length}</TotalNumber>
        </TotalText>
        {subscription.isExternalSubscription && (
          <Button
            variant="contain"
            onClick={handleAddLicense}
            size="small"
            paddingHorizontal={16}
            disabled={isLicenseAddBtnDisActivate}
          >
            {t('Subscrib_Detail_License_08')}
          </Button>
        )}
      </Header>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="308px">{t('Subscrib_Detail_License_01')}</TableCell>
              <TableCell width="140px">{t('Subscrib_Detail_License_32')}</TableCell>
              <TableCell width="180px">{t('Subscrib_Main_12')}</TableCell>
              <TableCell width="140px">{t('Subscrib_Edit_01')}</TableCell>
              <TableCell width="240px">{t('Acc_Detail_05')}</TableCell>
              <TableCell width="140px">통화 코드</TableCell>
              <TableCell width="200px">{t('Subscrib_Detail_Overview_16')}</TableCell>
              <TableCell width="180px">{t('Workflow_Main_07')}</TableCell>
              <StyledTabelCell width="70px">{t('Acc_Main_23')}</StyledTabelCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenseListPage?.pages[0].totalPages ? (
              licenseLists.map(license => {
                return (
                  <LicenseRow
                    key={license.licenseId}
                    license={license}
                    isEarliestLicense={license.licenseId === earliestLicense?.licenseId}
                    subscriptionConnection={subscriptionConnection}
                  />
                );
              })
            ) : (
              <EmptyRow colSpan={7} title={t('Subscrib_Detail_License_31')} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <LicenseEditorModal
        subscription={subscription}
        dialogType="create"
        open={isAddLicenseOpen}
        onClose={handleCloseAddLicense}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 44px 16px 16px 16px;
  & button > span {
    ${fonts.Button6}
  }
`;

const TotalText = styled.strong`
  margin-top: 6px;
  ${fonts.Headline8}
`;

const TotalNumber = styled.span`
  margin-left: 4px;
  color: ${({ theme: { colors } }) => colors['text-purple-light']};
`;

const StyledTabelCell = styled(TableCell)`
  text-align: center;
`;
