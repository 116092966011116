import type { Theme } from '@emotion/react';
import type { IChangeEvent } from '@rjsf/core';
import type { RJSFSchema, RegistryWidgetsType, WidgetProps } from '@rjsf/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { MenuItem, Select, TextField, css } from '@mui/material';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { Dialog } from '@components/Dialog';
import { FormItemContainer } from '@components/form/FormItemContainer';
import { FormLabel } from '@components/form/FormLabel';
import { Button, Dropdown } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { DottedLine } from '@icons/DottedLine';
import {
  useGetProductConnector,
  useGetProductConnectorList,
  useGetProductConnectorsBySubscription,
} from '@queryHooks/useProductConnector';
import { useAddSubscriptionConnection, useUpdateSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import type { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';

interface Props {
  subscription: SubscriptionModel;
  connection?: SubscriptionConnectionModel;
  type: 'new' | 'modify';
  open: boolean;
  onClose: (oauth?: boolean) => void;
  onSubmit?: (data: SubscriptionConnectionModel) => void;
}

export const AppLogin = ({ subscription, connection, open, onClose, type, onSubmit }: Props) => {
  const params = useParams();
  const { subscriptionId } = params;

  const {
    uiStore: { alertModalStore, openGlobalLoader, closeGlobalLoader },
  } = useStore();

  const { data, isLoading, isError } = useGetProductConnectorsBySubscription(subscriptionId as string, {
    cacheTime: 0,
    enabled: open,
  });

  if (!open) {
    return null;
  }

  if (isLoading) {
    openGlobalLoader();
    return null;
  }
  closeGlobalLoader();

  if (isError) {
    alertModalStore.open('Error');
    onClose();
    return null;
  }

  if (data.content.length === 0) {
    alertModalStore.open('커넥터가 존재하지 않습니다.');
    onClose();
    return null;
  }

  return (
    <AppLoginInner
      subscription={subscription}
      connection={connection}
      open={open}
      onClose={onClose}
      type={type}
      onSubmit={onSubmit}
    />
  );
};

const AppLoginInner = ({ subscription, connection, open, onClose, type, onSubmit }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { subscriptionId } = params;

  const [jsonForm, setJsonForm] = useState({});
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | undefined>(
    connection?.connector?.connectorId,
  );

  const { data: selectedConnector } = useGetProductConnector(selectedConnectorId ?? '', {
    enabled: !!selectedConnectorId,
  });
  const { data: connectorPageData, isLoading: isConnectorListLoading } = useGetProductConnectorList({
    productId: subscription.software.id,
  });
  const connectors = connectorPageData?.content ?? [];

  const { mutate: addConnection } = useAddSubscriptionConnection();
  const { mutate: updateConnector } = useUpdateSubscriptionConnection(subscriptionId as string);

  useEffect(() => {
    if (!isConnectorListLoading && (connectorPageData?.content?.length ?? 0) > 0 && !selectedConnectorId) {
      setSelectedConnectorId(connectorPageData?.content[0].connectorId);
    }
  }, [connectorPageData?.content, isConnectorListLoading, selectedConnectorId]);

  useEffect(() => {
    setJsonForm(selectedConnector ? JSON.parse(selectedConnector.connectionInfoSchema) : {});
  }, [selectedConnector, selectedConnectorId]);

  const handleSubmit = (data: IChangeEvent<any, RJSFSchema, any>) => {
    if (type === 'new') {
      addConnection(
        {
          subscriptionId: subscriptionId as string,
          connectorId: selectedConnector?.connectorId,
          connectionInfo: data.formData,
        },
        {
          onSuccess: conn => {
            onClose();
            if (onSubmit) onSubmit(conn);
          },
        },
      );
    } else {
      updateConnector(
        { subscriptionId: subscriptionId as string, connectionInfo: data?.formData },
        {
          onSuccess: conn => {
            onClose();
            if (onSubmit) onSubmit(conn);
          },
        },
      );
    }
  };

  const handleChangeConnector = (value: string | number) => {
    setSelectedConnectorId(value as string);
  };

  /**
   * Widget 종류들 - https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-widgets-fields/#customizing-the-default-fields-and-widgets
   */
  const widgets: RegistryWidgetsType = {
    TextWidget: CustomTextWidget,
    SelectWidget: CustomSelectWidget,
  };

  const formData = connection?.connection.connectionInfo ?? {};

  return (
    <StyledDialog title={t('Subscrib_Connect_Create_03')} size="small" open={open} onClose={onClose} useCustomContent>
      <StyledDialogBody $hasFooter>
        <FormItemContainer>
          <FormLabel>{t('Subscrib_Connect_Select_Connector')}</FormLabel>
          <Dropdown value={selectedConnectorId} onChange={handleChangeConnector}>
            {connectors.map(connector => (
              <Dropdown.Menu key={connector.connectorId} value={connector.connectorId}>
                {connector.name}
              </Dropdown.Menu>
            ))}
          </Dropdown>
        </FormItemContainer>
        <DottedLine />
        <StyledForm
          schema={jsonForm}
          validator={validator}
          formData={formData}
          onSubmit={handleSubmit}
          uiSchema={{
            'ui:globalOptions': {
              label: true,
            },
            'ui:submitButtonOptions': {
              norender: true,
            },
          }}
          widgets={widgets}
        >
          <Dialog.Footer>
            <Button variant="contain" type="submit" size="extraLarge">
              {t('Member_User_24')}
            </Button>
            <Button variant="outline" size="extraLarge" onClick={() => onClose()}>
              {t('Subscrib_Detail_BuyRequest_13')}
            </Button>
          </Dialog.Footer>
        </StyledForm>
      </StyledDialogBody>
    </StyledDialog>
  );
};

export const CustomTextWidget = ({ schema, required, value, onChange, onFocus, label }: WidgetProps) => {
  return (
    <TextWidgetContainer>
      <span className="title">
        {schema.title || label} {required ? <strong>*</strong> : ''}
      </span>
      <TextField
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={e => onFocus(label, e.target.value)}
        placeholder={schema.title}
        variant="outlined"
        size="medium"
        InputProps={{
          sx: { marginBottom: '20px' },
        }}
      />
    </TextWidgetContainer>
  );
};

export const CustomSelectWidget = ({ schema, required, value, onChange, label }: WidgetProps) => {
  const menuItems: string[] = schema?.enum?.map((key, idx) => (key ?? idx).toString()) ?? ([] as string[]);

  return (
    <SelectWidgetContainer>
      <span className="title">
        {schema.title || label} {required ? <strong>*</strong> : ''}
      </span>
      <Select
        value={value}
        onChange={e => onChange(e.target.value)}
        MenuProps={{
          PaperProps: {
            sx: {
              maxWidth: '416px',
              width: '100%',
              marginTop: '6px',
            },
          },
        }}
      >
        {menuItems.map(menuItem => (
          <MenuItem key={menuItem} value={menuItem}>
            {menuItem}
          </MenuItem>
        ))}
      </Select>
    </SelectWidgetContainer>
  );
};

const StyledForm = styled(Form)`
  max-height: 416px;

  & .MuiGrid-container {
    margin-top: 0px !important;

    & .MuiGrid-item {
      margin-bottom: 0px !important;
      padding: 0px;
    }
  }
`;

const WidgetCommonStyles = ({ colors }: Theme) => css`
  display: flex;
  flex-direction: column;

  & .title {
    margin-bottom: 8px;
    ${fonts.Headline8}
    color: ${colors['text-gray-main']};

    & > strong {
      color: ${colors['text-red-light']};
    }
  }
`;

const TextWidgetContainer = styled.div`
  ${({ theme }) =>
    css`
      ${WidgetCommonStyles(theme)}
    `}

  & .MuiInputLabel-root {
    ${fonts.Body2}
    top: -6px;
  }

  & .MuiOutlinedInput-notchedOutline {
    margin-top: 4px;
    height: 100%;

    & > legend {
      display: none;
    }
  }

  & .MuiFormLabel-root {
    display: none;
  }
`;

const SelectWidgetContainer = styled.div`
  margin-bottom: 20px;
  ${({ theme }) =>
    css`
      ${WidgetCommonStyles(theme)}
    `};
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    width: 416px;
    height: 576px;
    margin: 0px;
  }
  & .MuiGrid-root {
    width: 100%;
  }
  & .MuiGrid-container {
    margin-left: 0px;
    max-width: 416px;
  }
`;

const StyledDialogBody = styled(Dialog.Body)`
  padding: 12px 28px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
