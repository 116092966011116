import dayjs from 'dayjs';
import type { ActionExecResp, WorkflowExecDTO } from '@repositories/workflowRepository/Types';
import { WorkflowModel } from './WorkflowModel';

export class WorkflowExecModel {
  private readonly _dto: WorkflowExecDTO;
  private readonly _workflow: WorkflowModel;

  constructor(dto: WorkflowExecDTO) {
    this._dto = dto;
    this._workflow = new WorkflowModel(dto.workflow);
  }

  get id() {
    return this._dto.execution.id;
  }

  get status() {
    return this._dto.execution.status;
  }

  get statusMessage() {
    return this._dto.execution.statusMessage;
  }

  get executionStartTime() {
    return this._dto.execution.executionStartTime
      ? dayjs.utc(this._dto.execution.executionStartTime).local().format('YYYY-MM-DD HH:mm:ss')
      : '-';
  }

  get executionEndTime() {
    return this._dto.execution.executionEndTime
      ? dayjs.utc(this._dto.execution.executionEndTime).local().format('YYYY-MM-DD HH:mm:ss')
      : '-';
  }

  get executionTimeInSec() {
    const { executionEndTime, executionStartTime } = this._dto.execution;
    if (!executionEndTime || !executionStartTime) return 0;

    return Number(dayjs(executionEndTime).diff(executionStartTime, 'second', true).toFixed(2));
  }

  get workflow() {
    return this._workflow;
  }

  isActionExecuted(actionIndex: number) {
    return !!this._dto.execution.executedActionByStep?.[actionIndex];
  }

  getActionExecution(actionIndex: number): ActionExecResp | undefined {
    return this._dto.execution.executedActionByStep?.[actionIndex];
  }
}
