const defaultWidth = 20;
const defaultHeight = 20;

export const SmpColorIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.15042 7.68025L5.47036 4.54669C4.76047 3.82076 3.60422 3.82076 2.89195 4.54669C2.89195 4.54669 0.482106 7.1842 0.304038 7.35359C0.125971 7.52297 -0.00483242 7.71867 0.000136959 7.97304C0.000136959 7.97304 0.000136959 12.6746 0.000136959 12.9843C0.000136959 13.2941 0.209988 13.4643 0.209988 13.4643C0.284036 13.5094 0.35219 13.5151 0.439888 13.4643L4.51224 9.39898C4.90636 8.9973 5.54503 8.9973 5.93915 9.39898C6.10535 9.56836 6.20269 9.7813 6.2288 10.0039L9.15042 7.68025Z"
          fill="url(#paint0_linear_4539_100192)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.15042 7.68025L5.47036 4.54669C4.76047 3.82076 3.60422 3.82076 2.89195 4.54669C2.89195 4.54669 0.482106 7.1842 0.304038 7.35359C0.125971 7.52297 -0.00483242 7.71867 0.000136959 7.97304C0.000136959 7.97304 0.000136959 12.6746 0.000136959 12.9843C0.000136959 13.2941 0.209988 13.4643 0.209988 13.4643C0.284036 13.5094 0.35219 13.5151 0.439888 13.4643L4.51224 9.39898C4.90636 8.9973 5.54503 8.9973 5.93915 9.39898C6.10535 9.56836 6.20269 9.7813 6.2288 10.0039L9.15042 7.68025Z"
          fill="url(#paint1_linear_4539_100192)"
        />
        <ellipse cx="1.81211" cy="14.7828" rx="0.371922" ry="0.368786" fill="#CC165F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.95 4.5443C12.6621 3.81857 13.8166 3.81857 14.5287 4.5443L19.4661 9.57632C20.1781 10.3021 20.1781 11.4787 19.4661 12.2044C19.4661 12.2044 16.8784 14.6624 16.7115 14.8429C16.5446 15.0233 16.2489 15.1565 16.1043 15.1526H10.3082C10.1003 15.0861 10.2667 14.8717 10.3082 14.8466L14.6709 10.5204C15.0649 10.1188 15.0649 9.46774 14.6709 9.06616C14.2768 8.66458 13.638 8.66458 13.244 9.06616L7.59504 14.8579C7.59504 14.8579 7.30587 15.1526 6.98724 15.1526C6.66861 15.1526 1.82072 15.1526 1.82072 15.1526C1.55956 15.1565 1.54511 14.5292 1.54758 14.5267C5.6002 10.3765 11.95 4.5443 11.95 4.5443Z"
          fill="url(#paint2_linear_4539_100192)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4539_100192"
            x1="1.51462"
            y1="6.17033"
            x2="5.54597"
            y2="10.4277"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4A86F5" />
            <stop offset="1" stopColor="#771BE8" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4539_100192"
            x1="8.20046"
            y1="8.336"
            x2="6.84794"
            y2="7.12459"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.5" />
            <stop offset="0.0001" stopOpacity="0.8" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4539_100192"
            x1="11.5464"
            y1="6.47782"
            x2="18.3404"
            y2="12.2374"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CB155F" />
            <stop offset="1" stopColor="#EFB44B" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  );
};

SmpColorIcon.displayName = 'SmpColorIcon';
