import { SubscriptionRequestModel } from '@models/SubscriptionRequestModel';
import { KEYS, repository } from '@repositories/Repository';
import {
  ISubscriptionRequestRepository,
  SubscriptionRequestCreateDTO,
  SubscriptionRequestFilterDTO,
  SubscriptionRequestManagerUpdateRq,
  SubscriptionRequestStatusUpdateRq,
  SubscriptionRequestUpdateDTO,
} from '@repositories/subscriptionRequestRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const sbReqRepo = repository.get<ISubscriptionRequestRepository>(KEYS.SUBSCRIPTION_REQUEST_REPOSITORY);
const sbReqQueryKey = {
  all: ['subscriptionRequest'] as const,
  lists: () => [...sbReqQueryKey.all, 'list'] as const,
  details: () => [...sbReqQueryKey.all, 'detail'] as const,

  list: (filter: SubscriptionRequestFilterDTO) => [...sbReqQueryKey.lists(), filter] as const,
  detail: (id: number) => [...sbReqQueryKey.details(), id] as const,
};

export const useGetSubscriptionRequests = (
  queries: SubscriptionRequestFilterDTO,
  options?: UseQueryOptionsType<Page<SubscriptionRequestModel>>,
) =>
  useQuery<Page<SubscriptionRequestModel>, AxiosError>(
    sbReqQueryKey.list({ ...queries }),
    async () => {
      const result = await sbReqRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new SubscriptionRequestModel(dto)),
      };
    },
    {
      ...options,
    },
  );

export const useGetSubscriptionRequest = (id: number, options?: UseQueryOptionsType<SubscriptionRequestModel>) => {
  const result = useQuery<SubscriptionRequestModel, AxiosError>(
    sbReqQueryKey.detail(id),
    async () => {
      const result = await sbReqRepo.getOne(id);
      return new SubscriptionRequestModel(result);
    },
    {
      ...options,
      enabled: !!id && id > 0 && (options?.enabled !== undefined ? options.enabled : true),
    },
  );

  const initialData = useMemo(() => new SubscriptionRequestModel(), []);
  return { ...result, data: result.data ?? initialData };
};

export const useAddSubscriptionRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: SubscriptionRequestCreateDTO) => {
      await sbReqRepo.create(data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(sbReqQueryKey.lists());
      },
    },
  );
};

export const useUpdateSubscriptionRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: SubscriptionRequestUpdateDTO) => {
      const { id } = await sbReqRepo.update(data.id, data);
      return id;
    },
    {
      onSuccess: (id: number) => {
        queryClient.invalidateQueries(sbReqQueryKey.detail(id));
        queryClient.invalidateQueries(sbReqQueryKey.lists());
      },
    },
  );
};

export const useDeleteSubscriptionRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      await sbReqRepo.delete(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sbReqQueryKey.lists());
      },
    },
  );
};

export const useUpdateSubscriptionRequestManagerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: SubscriptionRequestManagerUpdateRq) => {
      const result = await sbReqRepo.updateManager(rqData.id, rqData);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbReqQueryKey.details());
      queryClient.invalidateQueries(sbReqQueryKey.lists());
    },
  });
};

export const useUpdateSubscriptionRequestStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: SubscriptionRequestStatusUpdateRq) => {
      const result = await sbReqRepo.updateStatus(rqData.id, rqData);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbReqQueryKey.details());
      queryClient.invalidateQueries(sbReqQueryKey.lists());
    },
  });
};
