import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ChevronIcon } from '@icons/index';
import { useGetUserGroup, useGetUserGroupMembers } from '@queryHooks/useUserGroup';
import * as S from './UserGroupDetailStyles';

export const UserGroupDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: userGroup } = useGetUserGroup(id as string);
  const { data: members } = useGetUserGroupMembers(id as string);

  return (
    <S.Container>
      <S.GroupDetailHeader>
        <S.IconButton size="small" variant="text" onClick={() => navigate(-1)}>
          <ChevronIcon width={24} height={24} rotateNum={-90} />
        </S.IconButton>
        <S.HeaderTitle variant="headline6" component="strong">
          {userGroup?.name}
          <span>({members?.totalElements})</span>
        </S.HeaderTitle>
      </S.GroupDetailHeader>
      <S.StyledRoutingTabs
        variant="contain-round"
        tabList={[
          { name: `${t('Member_Group_06')}`, path: ``, replace: true },
          { name: `${t('Member_Group_07')}`, path: `members`, replace: true },
        ]}
      />
      <Outlet />
    </S.Container>
  );
};
