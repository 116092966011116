import { injectable } from 'inversify';
import API from '@repositories/Axios';
import { IUserStatRepository } from './IUserStatRepository';
import { DailyUserStatByUserStatusDTO } from './Types';

@injectable()
export class UserStatRepository implements IUserStatRepository {
  async getStat(): Promise<DailyUserStatByUserStatusDTO> {
    const result = await API.get<DailyUserStatByUserStatusDTO>(`/api/v1/statistics/daily-user-status-aggregate-top5`);
    return result.data;
  }
}
