import { useQuery } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type ISubscriptionBillingStatRepository from '@repositories/subscriptionBillingStatRepository/ISubscriptionBillingStatRepository';
import type {
  BillingStatFilter,
  MonthlyBillingStatFilter,
  SubscriptionBillingStatFilter,
} from '@repositories/subscriptionBillingStatRepository/Types';
import { BillingStatModel } from '@models/subscriptionBillingStat/BillingStatModel';
import { MonthlyBillingStatModel } from '@models/subscriptionBillingStat/MonthlyBillingStatModel';
import { SubscriptionBillingStatModel } from '@models/subscriptionBillingStat/SubscriptionBillingStatModel';

const repo = repository.get<ISubscriptionBillingStatRepository>(KEYS.SUBSCRIPTION_BILLING_STAT_REPOSITORY);
export const subscriptionBillingStatQueryKey = {
  all: (filter?: BillingStatFilter) => ['stat', { ...filter }],
  byMonth: (filter?: MonthlyBillingStatFilter) => [subscriptionBillingStatQueryKey.all(filter), 'month'],
  bySubscription: (filter?: SubscriptionBillingStatFilter) =>
    [subscriptionBillingStatQueryKey.all(filter), 'subscription'] as const,
};

export const useGetSubscriptionBillingStat = (filter?: BillingStatFilter) =>
  useQuery({
    queryKey: subscriptionBillingStatQueryKey.all(filter),
    queryFn: async () => {
      const result = await repo.getSubscriptionBillingStat(filter);
      return new BillingStatModel(result);
    },
  });

export const useGetSubscriptionBillingStatByMonth = (filter?: MonthlyBillingStatFilter) =>
  useQuery({
    queryKey: subscriptionBillingStatQueryKey.byMonth(filter),
    queryFn: async () => {
      const result = await repo.getSubscriptionBillingStatByMonth(filter);
      return result.map(dto => new MonthlyBillingStatModel(dto));
    },
  });

export const useGetSubscriptionBillingStatBySubscription = (filter?: SubscriptionBillingStatFilter) =>
  useQuery({
    queryKey: subscriptionBillingStatQueryKey.bySubscription(filter),
    queryFn: async () => {
      const result = await repo.getSubscriptionBillingStatBySubscription(filter);
      return result.map(dto => new SubscriptionBillingStatModel(dto));
    },
  });
