import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTimezoneSelect } from 'react-timezone-select';
import { yupResolver } from '@hookform/resolvers/yup';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { AutocompleteSelector } from '@components/admin/AutocompleteSelector';
import { SubscriptionRequestOption } from '@pages/admin/applications/applicationDetail/info/basicInfo/BasicInfo';
import { useStore } from '@stores/RootStore';
import { Add, Remove } from '@images/SvgComponents';
import type { SoftwareModule, ModuleUsagePrice, ModuleUsagePrices } from '@repositories/softwareRepository';
import type { ContractCondition, SubscriptionCreateRq, SubscriptionType } from '@repositories/subscriptionRepository';
import {
  useGetProductPlanDetail,
  useGetProductPlanList,
  useGetSoftware,
  useGetSoftwareList,
} from '@queryHooks/useSoftware';
import { useAddSubscription } from '@queryHooks/useSubscription';
import { useGetSubscriptionRequests } from '@queryHooks/useSubscriptionRequest';
import { useGetTenantList } from '@queryHooks/useTenant';
import { subscriptionCreateSchema } from '@schema/subscriptionSchema';
import { TenantListModel } from '@models/tenantModels';
import * as S from './ApplicationCreateStyles';

const fieldsByStep = new Map<number, string[]>([
  [0, ['name', 'tenant', 'software', 'planId', 'planOptionId', 'currencyUnit', 'description']],
  [1, ['type', 'startDate', 'endDate', 'timeZone', 'contractConditions']],
  [2, ['purchaseContractId']],
]);

type Info = {
  name: string;
  id: string;
};

type FormData = {
  name: string;
  tenant: Info & { tenantCode: string };
  software: Info & { moduleUsagePrices: ModuleUsagePrices; modules: SoftwareModule[] };
  currencyUnit: CurrencyUnit;
  description: string;
  startDate: Date;
  endDate: Date;
  timeZone: string;
  type: SubscriptionType;
  purchaseContractId: string;
  contractConditions: ContractCondition[];
  properties: { key: string; value: string }[];
  planId: string;
  planOptionId: string;
  subscriptionRequest?: SubscriptionRequestOption;
};

export const ApplicationCreate = observer(() => {
  const {
    uiStore: { applicationCreateStore: modal },
    purchaseContractStore,
  } = useStore();

  const { data: pagedTenants } = useGetTenantList({ size: 1000 }, { enabled: modal.visible });
  const tenants = (pagedTenants?.content ?? []).concat(
    new TenantListModel({ id: 'init', level: 0, memberCount: 0, name: '', tenantCode: '' }),
  );
  const {
    data: { content: softwares },
    isSuccess: isSoftwaresSuccess,
  } = useGetSoftwareList({ size: 300 }, { enabled: modal.visible });

  const softwareAutocompleteOptions: Info[] = isSoftwaresSuccess ? softwares.map(({ name, id }) => ({ name, id })) : [];

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isQuantityContract, setIsQuantityContract] = React.useState<boolean>(false);
  const [checkedModules, setCheckedModules] = React.useState<boolean[]>([]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    trigger,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(subscriptionCreateSchema),
    shouldFocusError: true,
  });

  const { mutate: addSubscription } = useAddSubscription();

  const [subscriptionType, endDate, software, selectedPlanId, tenant] = watch([
    'type',
    'endDate',
    'software',
    'planId',
    'tenant',
  ]);
  const selectedSoftwareId = software?.id;
  const { data: selectedSoftware } = useGetSoftware(selectedSoftwareId, { enabled: !!selectedSoftwareId });
  const { data: plans } = useGetProductPlanList(selectedSoftwareId, { enabled: !!selectedSoftwareId });

  const { data: selectedPlan, isSuccess: isSelectedPlanSuccess } = useGetProductPlanDetail(
    selectedSoftwareId,
    selectedPlanId,
    {
      enabled: !!selectedPlanId,
    },
  );

  const planOptionList = isSelectedPlanSuccess ? selectedPlan.planOptionList : [];

  const { update: updateContractCondition, remove: removeContractCondition } = useFieldArray({
    control,
    name: 'contractConditions',
  });

  const {
    append,
    remove,
    fields: properties,
  } = useFieldArray({
    control,
    name: 'properties',
  });

  useEffect(() => {
    if (modal.visible && modal.parentSubscription) setValue('type', 'SUB');

    return () => {
      reset();

      // NOTE. visible 플래그에 의해서 Show&Hide 방식으로 동작하기 때문에 모든 로컬 상태를 초기화해줘야함.
      setActiveStep(0);
      setIsQuantityContract(false);
      setCheckedModules([]);
    };
  }, [modal.parentSubscription, modal.visible, reset, setValue]);

  useEffect(() => {
    // NOTE. 약정 수량을 체크하면 배열 초기화, 체크 해제하면 리셋(undefined)
    if (isQuantityContract) {
      setValue('contractConditions', []);
    } else {
      setValue('contractConditions', undefined);
      setCheckedModules([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuantityContract]);

  const validateHandler = {
    success: async (data: any) => {
      const origin: FormData = { ...data };

      try {
        const activeContractConditions = origin.contractConditions?.filter(
          (condition: ContractCondition, idx: number) => checkedModules[idx],
        );
        const properties: any = {};
        origin.properties?.forEach((property: { key: string; value: string }) => {
          properties[property.key] = property.value;
        });

        const rqData: SubscriptionCreateRq = {
          properties,
          contractConditions: activeContractConditions,
          endDate: dayjs(origin.endDate).format('YYYY-MM-DD'),
          isQuantityContract,
          name: origin.name,
          softwareId: modal.parentSubscription?.software.id || origin.software.id,
          startDate: dayjs(origin.startDate).format('YYYY-MM-DD'),
          tenantId: modal.parentSubscription?.tenant.id || origin.tenant.id,
          type: origin.type,
          currencyUnit: modal.parentSubscription?.currencyUnit || origin.currencyUnit,
          description: origin.description,
          purchaseContractId: origin.purchaseContractId,
          timeZone: modal.parentSubscription?.timeZone || origin.timeZone,
          useSeparateModulePrice: true,
          parentId: modal.parentSubscription?.id,
          planId: origin.planId,
          planOptionId: origin.planOptionId,
          subscriptionRequestId: origin.subscriptionRequest?.id,
        };

        addSubscription(rqData, { onSuccess: modal.close });
      } catch (error) {
        console.error(error);
      }
    },
    fail: (err: any) => {
      console.error('err', err);
    },
  };

  const stepperHandler = {
    next: async () => {
      if (!(await isValid(activeStep))) {
        return;
      }
      // NOTE. 매입 계약 단계에 대한 이전 단계를 지정해야함.
      if (activeStep === 1) {
        await purchaseContractStore.fetchContracts(
          modal.parentSubscription
            ? {
                softwareId: modal.parentSubscription.software.id,
              }
            : { softwareId: selectedSoftware.id },
        );
      }
      setActiveStep(activeStep + 1);
    },
    back: () => {
      if (activeStep === 0) return;
      setActiveStep(prev => {
        return prev - 1;
      });
    },
  };

  const isValid = async (step: number) => {
    const result = await trigger(fieldsByStep.get(step));
    return result;
  };

  const setEndDate = () => {
    const start = getValues('startDate');
    const type = getValues('type');
    if (type === 'FIXED') setValue('endDate', dayjs(start).add(1, 'y').subtract(1, 'day'));
    else if (type === 'FIXED_2') setValue('endDate', dayjs(start).add(2, 'y').subtract(1, 'day'));
    else if (type === 'FIXED_3') setValue('endDate', dayjs(start).add(3, 'y').subtract(1, 'day'));
    else setValue('endDate', undefined);
  };

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    idx: number,
    moduleCode: string,
  ) => {
    const origin = [...checkedModules];
    origin[idx] = checked;
    setCheckedModules(origin);

    // NOTE. 체크박스 체크 시에 FORM 모델을 업데이트함.
    if (checked) {
      updateContractCondition(idx, {
        moduleCode,
        price: undefined,
        usage: 1,
        currencyUnit: 'USD',
      });
    } else {
      removeContractCondition(idx);
    }
  };

  const propertyHandler = {
    add: () => {
      append({ key: '', value: '' });
    },
    remove: (idx: number) => {
      remove(idx);
    },
  };

  const tenantDefaultValue = () => {
    if (modal.parentSubscription) {
      const { tenant } = modal.parentSubscription;
      return { name: tenant.name, id: tenant.id, tenantCode: tenant.tenantCode };
    }
    if (modal.tenant) {
      const { tenant } = modal;
      return { name: tenant.name, id: tenant.id, tenantCode: tenant.tenantCode };
    }
    return undefined;
  };

  const softwareDefaultValue = (): Info | undefined => {
    if (modal.parentSubscription) {
      const { software } = modal.parentSubscription;
      return {
        name: software.name,
        id: software.id,
      };
    }
    if (modal.software) {
      return {
        name: modal.software.name,
        id: modal.software.id,
      };
    }
    // NOTE. 아직 선택되지 않는 항목을 위해서 undefined 를 넘겨야함.
    return undefined;
  };

  const { data: subscriptionRequestPage } = useGetSubscriptionRequests(
    { tenantId: tenant?.id, softwareId: selectedSoftware.id, size: 999 },
    { enabled: !!(tenant?.id && selectedSoftware) },
  );
  const subscriptionRequests: SubscriptionRequestOption[] =
    subscriptionRequestPage?.content.map(resp => ({
      id: resp.id,
      planName: resp.productPlanName,
      createdDate: resp.formattedCreatedDate,
    })) ?? [];

  const { options } = useTimezoneSelect({
    labelStyle: 'original',
  });

  return (
    <Modal open={modal.visible} onClose={modal.close}>
      <S.Container>
        <S.ModalTitleWrapper>
          <CorporateFareIcon />
          <Typography variant="h2">Subscribe Application</Typography>
        </S.ModalTitleWrapper>

        <S.ContentWrapper>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Basic</StepLabel>
              <StepContent>
                <S.FieldsWrapper>
                  <TextField
                    {...register('name')}
                    autoComplete="off"
                    label="Subscription Name"
                    fullWidth
                    helperText={errors.name?.message as string}
                  />
                  <Controller
                    name="tenant"
                    control={control}
                    defaultValue={tenantDefaultValue}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        options={tenants.map(tn => {
                          return { name: tn.name, id: tn.id, tenantCode: tn.tenantCode };
                        })}
                        disabled={!!modal.parentSubscription || !!modal.tenant}
                        getOptionLabel={tenant => (tenant.id === 'init' ? '' : `${tenant.name} (${tenant.tenantCode})`)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={params => (
                          <TextField {...params} label="Tenant" helperText={errors.tenant?.message as string} />
                        )}
                        onChange={(
                          event: React.SyntheticEvent<Element, Event>,
                          value: (Info & { tenantCode: string }) | null,
                        ) => onChange(value)}
                      />
                    )}
                  />
                  <Controller
                    name="software"
                    control={control}
                    defaultValue={softwareDefaultValue}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        options={softwareAutocompleteOptions}
                        disabled={!!modal.parentSubscription || !!modal.software}
                        getOptionLabel={sw => sw.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={params => (
                          <TextField {...params} label="Software" helperText={errors.software?.message as string} />
                        )}
                        onChange={(event: React.SyntheticEvent<Element, Event>, value: Info | null) => onChange(value)}
                      />
                    )}
                  />
                  {!modal.parentSubscription && (
                    <FormControl fullWidth>
                      <InputLabel id="select-plan">Plan</InputLabel>
                      <Controller
                        control={control}
                        name="planId"
                        render={({ field: { value, onChange } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            labelId="select-plan"
                            disabled={!selectedSoftware}
                            label="Plan"
                          >
                            {plans?.map(plan => (
                              <MenuItem key={plan.planId} value={plan.planId}>
                                {plan.planName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.planId ? <FormHelperText>{errors.planId?.message as string}</FormHelperText> : null}
                    </FormControl>
                  )}
                  {!modal.parentSubscription && (
                    <FormControl fullWidth>
                      <InputLabel id="select-plan-option">Plan Option</InputLabel>
                      <Controller
                        control={control}
                        name="planOptionId"
                        render={({ field: { value, onChange } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            labelId="select-plan-option"
                            disabled={!selectedPlanId}
                            label="Plan Option"
                          >
                            {planOptionList.map(planOption => (
                              <MenuItem key={planOption.planOptionId} value={planOption.planOptionId}>
                                {planOption.optionName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.planOptionId ? (
                        <FormHelperText>{errors.planOptionId?.message as string}</FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                  <FormControl fullWidth>
                    <InputLabel id="select-currency-label">Currency Unit</InputLabel>
                    <Controller
                      name="currencyUnit"
                      control={control}
                      defaultValue="USD"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          labelId="select-currency-label"
                          disabled={!!modal.parentSubscription}
                          label="Currency Unit"
                          onChange={onChange}
                        >
                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="KRW">KRW</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <TextField
                    {...register('description')}
                    autoComplete="off"
                    label="Description"
                    fullWidth
                    multiline
                    rows={3}
                  />
                  <S.BtnsWrapper>
                    <Button variant="outlined" onClick={stepperHandler.next}>
                      Continue
                    </Button>
                  </S.BtnsWrapper>
                </S.FieldsWrapper>
              </StepContent>
            </Step>

            <Step>
              <StepLabel>Period</StepLabel>
              <StepContent>
                <S.FieldsWrapper>
                  <S.TypeWrapper>
                    <FormControl>
                      <InputLabel id="select-type-label">구독 타입</InputLabel>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Box width={700}>
                            <Select
                              {...register('type')}
                              value={value}
                              labelId="select-type-label"
                              disabled={!!modal.parentSubscription}
                              label="Subscription Type"
                              onChange={(event: SelectChangeEvent<any>) => {
                                onChange(event);
                                setEndDate();
                              }}
                              fullWidth
                            >
                              {modal.parentSubscription ? (
                                <MenuItem key="SUB" value="SUB">
                                  SUB
                                </MenuItem>
                              ) : (
                                Object.keys(selectedSoftware.moduleUsagePrices).map(key => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {key}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </Box>
                        )}
                      />
                      <FormHelperText>{errors.type?.message as string}</FormHelperText>
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        label="Quantity Contract"
                        control={
                          <Checkbox
                            onChange={(e, checked) => setIsQuantityContract(checked)}
                            checked={isQuantityContract}
                          />
                        }
                      />
                    </FormControl>
                  </S.TypeWrapper>
                  {subscriptionType && (
                    <S.DateWrapper>
                      <Controller
                        name="startDate"
                        control={control}
                        defaultValue={dayjs()}
                        render={({ field: { onChange, value } }) => (
                          <DesktopDatePicker
                            label="Start Date"
                            inputFormat="MM/DD/YYYY"
                            value={value}
                            onChange={value => {
                              onChange(value);
                              setEndDate();
                            }}
                            renderInput={params => <TextField fullWidth {...params} />}
                          />
                        )}
                      />
                      {subscriptionType !== 'ON_DEMAND' && (
                        <Controller
                          key={endDate}
                          name="endDate"
                          control={control}
                          render={({ field: { onChange } }) => {
                            return (
                              <DesktopDatePicker
                                label="End Date"
                                inputFormat="MM/DD/YYYY"
                                value={endDate}
                                onChange={value => {
                                  onChange(value);
                                }}
                                renderInput={params => <TextField fullWidth {...params} />}
                              />
                            );
                          }}
                        />
                      )}
                    </S.DateWrapper>
                  )}
                  <Controller
                    name="timeZone"
                    control={control}
                    defaultValue={modal.parentSubscription ? modal.parentSubscription.timeZone : 'Asia/Seoul'}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        disabled={!!modal.parentSubscription}
                        options={options.map(tz => tz.value)}
                        renderInput={params => <TextField {...params} label="TimeZone" />}
                        onChange={(e, data) => {
                          onChange(data);
                        }}
                      />
                    )}
                  />
                  <FormHelperText sx={{ mb: 2, ml: 2 }}>
                    {(errors.timeZone?.message || errors.contractConditions?.message) as string}
                  </FormHelperText>
                  {isQuantityContract && (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="60" />
                            <TableCell align="center">Module</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">Currency</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedSoftware?.modules?.map((module: SoftwareModule, idx: number) => {
                            return (
                              <TableRow selected={checkedModules[idx]} key={module.moduleCode + idx.toString()}>
                                <TableCell>
                                  <Checkbox
                                    checked={checkedModules[idx]}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                      handleChangeCheckbox(event, checked, idx, module.moduleCode)
                                    }
                                  />
                                </TableCell>
                                <TableCell>{module.name}</TableCell>
                                <TableCell>
                                  {checkedModules[idx] && (
                                    <S.CellWrapper>
                                      <TextField
                                        {...register(`contractConditions.${idx}.usage`)}
                                        disabled={!checkedModules[idx]}
                                        size="small"
                                        helperText={(errors.contractConditions as any)?.[idx]?.usage?.message}
                                      />
                                      {selectedSoftware.moduleUsagePrices
                                        ? selectedSoftware.moduleUsagePrices[subscriptionType].find(
                                            (mp: ModuleUsagePrice) => mp.moduleCode === module.moduleCode,
                                          )?.unit
                                        : null}
                                    </S.CellWrapper>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {checkedModules[idx] && (
                                    <TextField
                                      disabled={!checkedModules[idx]}
                                      {...register(`contractConditions.${idx}.price`)}
                                      size="small"
                                      helperText={(errors.contractConditions as any)?.[idx]?.price?.message}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {checkedModules[idx] && (
                                    <Controller
                                      name={`contractConditions.${idx}.currencyUnit`}
                                      control={control}
                                      defaultValue="USD"
                                      render={({ field: { onChange, value } }) => (
                                        <Select
                                          value={value}
                                          disabled={!checkedModules[idx]}
                                          onChange={onChange}
                                          size="small"
                                        >
                                          <MenuItem value="USD">USD</MenuItem>
                                          <MenuItem value="KRW">KRW</MenuItem>
                                        </Select>
                                      )}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <S.BtnsWrapper>
                    <Button variant="outlined" onClick={stepperHandler.back}>
                      Back
                    </Button>
                    <Button variant="outlined" onClick={stepperHandler.next}>
                      Continue
                    </Button>
                  </S.BtnsWrapper>
                </S.FieldsWrapper>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Sales Information</StepLabel>
              <StepContent>
                <S.FieldsWrapper>
                  <FormControl fullWidth>
                    <AutocompleteSelector
                      name="subscriptionRequest"
                      control={control}
                      options={subscriptionRequests}
                      defaultOption={
                        modal.subscriptionRequest
                          ? {
                              id: modal.subscriptionRequest.id,
                              planName: modal.subscriptionRequest.productPlanName,
                              createdDate: modal.subscriptionRequest.formattedCreatedDate,
                            }
                          : undefined
                      }
                      getOptionLabel={requestOpt =>
                        requestOpt ? `${requestOpt.id}, ${requestOpt.planName}, ${requestOpt.createdDate}` : ''
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      readOnly={!!modal.subscriptionRequest}
                      label="Subscription Request"
                      size="medium"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="purchase-contract">Purchase Contract</InputLabel>
                    <Controller
                      name="purchaseContractId"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="purchase-contract"
                          disabled={purchaseContractStore.purchaseContracts.length === 0}
                          value={value}
                          onChange={onChange}
                        >
                          {purchaseContractStore.purchaseContracts.map(purchaseContract => (
                            <MenuItem key={purchaseContract.id} value={purchaseContract.id}>
                              {purchaseContract.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <S.BtnsWrapper>
                    <Button variant="outlined" onClick={stepperHandler.back}>
                      Back
                    </Button>
                    <Button variant="outlined" onClick={stepperHandler.next}>
                      Continue
                    </Button>
                  </S.BtnsWrapper>
                </S.FieldsWrapper>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Additional Properties</StepLabel>
              <StepContent>
                <S.FieldsWrapper>
                  {properties.map((property, idx) => {
                    return (
                      <S.PropertiesWrapper key={nanoid()}>
                        <TextField {...register(`properties.${idx}.key`)} />
                        <TextField {...register(`properties.${idx}.value`)} fullWidth />
                        <IconButton onClick={() => propertyHandler.remove(idx)}>
                          <Remove />
                        </IconButton>
                      </S.PropertiesWrapper>
                    );
                  })}
                  <Box>
                    <IconButton onClick={propertyHandler.add}>
                      <Add />
                    </IconButton>
                  </Box>
                  <form onSubmit={handleSubmit(validateHandler.success, validateHandler.fail)}>
                    <S.BtnsWrapper>
                      <Button variant="outlined" onClick={stepperHandler.back}>
                        Back
                      </Button>
                      <Button variant="outlined" type="submit">
                        create
                      </Button>
                    </S.BtnsWrapper>
                  </form>
                </S.FieldsWrapper>
              </StepContent>
            </Step>
          </Stepper>
        </S.ContentWrapper>
      </S.Container>
    </Modal>
  );
});
