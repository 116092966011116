import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material';
import { EmptyRow } from '@components/table';
import { fonts } from '@theme/fontsCustomer';
import { SearchIcon } from '@icons/index';
import { Filter } from '@repositories/subscriptionUseRequestRepository/Types';
import { useGetSubscriptionUseRequests } from '@queryHooks/useSubscriptionUseRequest';
import { UsageRegisterRow } from './UsageRegisterRow';

export type Tab = 'wait' | 'complete';

export const UsageRegisterList = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<Tab>('wait');
  const [queries, setQueries] = useState<Filter>({ status: ['CREATED', 'PENDING'], sort: ['createdDate.desc'] });
  const theme = useTheme();
  const { data: useRqs } = useGetSubscriptionUseRequests(queries, { staleTime: 0 });
  const handleClickTab = (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedTab(e.currentTarget.id as Tab);
    if ((e.currentTarget.id as Tab) === 'wait') {
      setQueries(prev => ({ ...prev, status: ['CREATED', 'PENDING'] }));
    } else {
      setQueries(prev => ({ ...prev, status: ['APPROVED', 'REJECTED'] }));
    }
  };
  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueries(prev => ({ ...prev, userName: event.target.value }));
  };
  return (
    <Container>
      <Title>사용 신청 관리</Title>
      <TabWrapper>
        <Tab id="wait" className={selectedTab === 'wait' ? 'selected' : undefined} onClick={handleClickTab}>
          {t('Subscrib_Detail_UseRequest_02')}
        </Tab>
        <Tab id="complete" className={selectedTab === 'complete' ? 'selected' : undefined} onClick={handleClickTab}>
          {t('Subscrib_Detail_UseRequest_23')}
        </Tab>
      </TabWrapper>
      <Search>
        <span className="all-count">
          {t('Acc_Main_12')} <span className="count">{useRqs?.numberOfElements}</span>
        </span>
        <StyledTextField
          fullWidth
          size="small"
          placeholder={t('Subscrib_Detail_UseRequest_32')}
          onChange={handleSearchNameChange}
          InputProps={{
            startAdornment: (
              <IconWrapper>
                <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
              </IconWrapper>
            ),
          }}
        />
      </Search>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell width="72px" align="center">
                {t('Workflow_Main_04')}
              </TableHeadCell>
              <TableHeadCell width="160px">{t('Subscrib_Detail_UseRequest_05')}</TableHeadCell>
              <TableHeadCell width="140px">{t('Subscrib_Detail_UseRequest_06')}</TableHeadCell>
              <TableHeadCell width={selectedTab === 'complete' ? '210px' : '240px'}>
                {t('Subscrib_Detail_UseRequest_07')}
              </TableHeadCell>
              <TableHeadCell width={selectedTab === 'complete' ? '308px' : '348px'}>
                {t('Subscrib_Main_12')}
              </TableHeadCell>
              <TableHeadCell width={selectedTab === 'complete' ? '200px' : '220px'}>
                {t('Subscrib_Detail_UseRequest_08')}
              </TableHeadCell>
              <TableHeadCell width={selectedTab === 'complete' ? '230px' : '300px'}>
                {t('Member_User_14')}
              </TableHeadCell>
              {selectedTab === 'complete' && (
                <TableHeadCell width="160px">{t('Subscrib_Detail_UseRequest_19')}</TableHeadCell>
              )}
              <TableHeadCell width="120px">{t('Subscrib_Detail_UseRequest_09')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {useRqs?.totalElements === 0 && selectedTab === 'wait' ? (
              <EmptyRow colSpan={8} title={t('Subscrib_Detail_UseRequest_14')} />
            ) : useRqs?.totalElements === 0 && selectedTab === 'complete' ? (
              <EmptyRow colSpan={9} title={t('Subscrib_Detail_UseRequest_20')} />
            ) : (
              useRqs?.content.map((rq, idx) => {
                return <UsageRegisterRow key={rq.id} idx={idx} data={rq} type={selectedTab} />;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
`;

const Title = styled('strong')`
  ${fonts.Headline5};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin: 0 0 26px 8px;
`;

const TabWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-dark']};
  margin-bottom: 38px;
`;

const Tab = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 163px;
  height: 44px;
  ${fonts.Button3};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-width: 1px 0 0 1px;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  &:last-of-type {
    border-width: 1px 1px 0 0;
  }
  :hover {
    cursor: pointer;
  }
  &.selected {
    background-color: inherit;
    border-width: 1px 1px 0 1px;
    border-color: ${({ theme: { colors } }) => colors['border-gray-dark']};
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
    &:last-of-type {
      border-width: 1px 1px 0 1px;
    }
    & ~ a {
      border-width: 1px 1px 0 0;
    }
    :after {
      display: block;
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      background-color: ${({ theme: { colors } }) => colors['bg-white']};
    }
  }
`;

const Search = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & .all-count {
    margin-top: 6px;
    display: flex;
    gap: 4px;
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & .count {
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }
  margin: 0 0 12px 8px;
`;

const TableHeadCell = styled(TableCell)`
  ${fonts.Headline9};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};
`;

const StyledTextField = styled(TextField)`
  width: 250px;
  margin-left: auto;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const IconWrapper = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;
