import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Menu, MenuItem, TableCell, TableRow, Tooltip, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { useDeleteTenantMemberUsingFlag, useGetTenant } from '@queryHooks/useTenant';
import { useResendInviteMail } from '@queryHooks/useUser';
import type { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import { UserExitModal } from './userExit';

interface Props {
  rowData: TenantMemberSimpleModel;
  type: 'Active' | 'Wait' | 'Stop';
}

export const UserTableRow = observer(({ rowData: member, type }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uiStore: { userEditStore: modal, alertStore, toastStore },
  } = useStore();
  const params = useParams();
  const navigate = useNavigate();

  const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const registerMenuOpen = Boolean(anchorEl);

  const { mutate: resendInviteMail } = useResendInviteMail();
  const { mutate: deleteMember } = useDeleteTenantMemberUsingFlag();
  const { data: tenant } = useGetTenant(params.tenantId as string);

  const handleClickStopBtn = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    if (member.isManager) {
      alertStore.open({
        title: `${t('Member_User_22')}`,
        message: `${t('Member_User_23')}`,
        useCancel: true,
      });
    } else {
      setIsExitModalOpen(true);
    }
  };

  const handleClickMailIcon = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    alertStore.open({
      type: 'success',
      title: `${t('Member_User_Stanby_Tab_02')}`,
      message: `${t('Member_User_Stanby_Tab_04')}`,
      useCancel: true,
      confirmName: `${t('Member_User_24')}`,
      onConfirm: () =>
        resendInviteMail(member.id, {
          onSuccess: () => {
            toastStore.open(t('Member_User_Stanby_Tab_05'), 'bottom', 'center');
          },
        }),
    });
  };

  const handleClickMemberDelete = () => {
    setAnchorEl(null);
    deleteMember(
      { tenantId: params.tenantId as string, userId: member.id },
      {
        onSuccess: () => {
          toastStore.open(t('Member_User_Delete_Tab_14'), 'bottom', 'center');
        },
      },
    );
  };

  const handleClickDeleteMember = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    if (member.subscriptionCount) {
      alertStore.open({
        type: 'success',
        title: `${t('Member_User_Delete_Tab_12')}`,
        message: `${t('Member_User_Delete_Tab_13')}`,
        useCancel: false,
      });
      setAnchorEl(null);
    } else {
      alertStore.open({
        type: 'error',
        title: `${t('Member_User_Delete_Tab_10')}`,
        message: `${t('Member_User_Delete_Tab_11')}`,
        useCancel: true,
        confirmName: `${t('Member_User_Delete_Tab_10')}`,
        onConfirm: () => {
          handleClickMemberDelete();
        },
      });
    }
  };

  return (
    <TableRow>
      {type === 'Wait' && (
        <StyledTableCell>
          {member.createdDate ? dayjs(member.createdDate).format(t('DateFormat_YMD')) : '-'}
        </StyledTableCell>
      )}
      {type === 'Stop' && (
        <StyledTableCell>
          {member.updatedDate ? dayjs(member.updatedDate).format(t('DateFormat_YMD')) : '-'}
        </StyledTableCell>
      )}
      <StyledTableCell>{member.name}</StyledTableCell>
      <StyledTableCell>{member.username}</StyledTableCell>
      <StyledTableCell>
        {member.usergroups.length > 0 ? (
          <>
            {member.usergroups[0]}&nbsp;
            {member.usergroups.length > 1 && (
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      width: '210px',
                      border: '1px solid #e1e4e8',
                      backgroundColor: '#fff',
                      padding: '6px',
                    },
                    '& .MuiTooltip-arrow': {
                      color: '#fff',
                      '&::before': {
                        border: '1px solid #e1e4e8',
                      },
                    },
                  },
                }}
                title={
                  <>
                    {member.usergroups.map((usergroup, idx) => {
                      if (idx > 0) return <UsergroupOfTooltip key={usergroup}>{usergroup}</UsergroupOfTooltip>;
                      return null;
                    })}
                  </>
                }
                arrow
              >
                <span className="tooltip-text">
                  {t('GNB_Profile_MyProfile_06', { number: member.usergroups.length - 1 })}
                </span>
              </Tooltip>
            )}
          </>
        ) : (
          tenant?.name
        )}
      </StyledTableCell>
      <StyledTableCell>{member.phone}</StyledTableCell>
      {member.isActive && <StyledTableCell>{member.managerStr}</StyledTableCell>}
      <StyledTableCell>{member.subscriptionCount}</StyledTableCell>
      {type === 'Active' && (
        <>
          <StyledTableCell>
            <MemberStatus className={member.status.toLowerCase()}>
              {member.status === 'ACTIVE' && t('Subscrib_Detail_User_16')}
              {member.status === 'CREATED' && t(`Subscrib_Detail_User_17`)}
            </MemberStatus>
          </StyledTableCell>
          <StyledTableCell>
            {member.lastLoginedAt === '-' ? '-' : dayjs(member.lastLoginedAt).format('YYYY.MM.DD, hh:mm')}
          </StyledTableCell>
        </>
      )}
      <StyledTableCell align="center">
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <ThreeDotIcon
            width={18}
            height={18}
            color={registerMenuOpen ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']}
          />
        </IconButton>
        <Menu
          open={registerMenuOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {
              maxWidth: '160px',
              width: '160px',
            },
          }}
          sx={{
            width: '160px',
            margin: '5px 0 0 19px',
            '& .MuiList-root': {
              width: '160px',
            },
          }}
        >
          {type === 'Active' && member.status === 'CREATED' && (
            <MenuItem onClick={(e: React.MouseEvent<HTMLLIElement>) => handleClickMailIcon(e)}>
              {t('Member_User_Stanby_Tab_02')}
            </MenuItem>
          )}
          {type === 'Active' && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                modal.open(member.id);
              }}
            >
              {t('Member_User_20')}
            </MenuItem>
          )}
          {type === 'Stop' && [
            <MenuItem
              key="license-retrieve"
              disabled={!member.subscriptionCount}
              onClick={() =>
                navigate(`/manager/${params.tenantId}/usergroups/memberMgmt/${member.id}/license-retrieve`)
              }
            >
              {t('Member_User_Delete_Tab_01')}
            </MenuItem>,
            <StyledMenuItem key="delete-member" className="menu-item-style" onClick={handleClickDeleteMember}>
              {t('Member_User_Delete_Tab_10')}
            </StyledMenuItem>,
          ]}

          {type !== 'Stop' && (
            <StyledMenuItem
              className="menu-item-style"
              onClick={e => {
                setAnchorEl(null);
                handleClickStopBtn(e);
              }}
            >
              {t('Workflow_Create_10')}
            </StyledMenuItem>
          )}
        </Menu>
      </StyledTableCell>
      <UserExitModal open={isExitModalOpen} onClose={() => setIsExitModalOpen(false)} user={member} />
    </TableRow>
  );
});

const StyledMenuItem = styled(MenuItem)`
  &.menu-item-style {
    color: ${({ theme }) => theme.colors['text-red-light']};
  }
`;

const MemberStatus = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: fit-content;
  padding: 0px 5px;
  border: 0.5px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 2px;
  ${fonts.Caption4};
  &.active {
    color: ${props => props.theme.colors['text-green-dark']};
    background: ${props => props.theme.colors['bg-green-lighter']};
  }
  &.created {
    color: ${props => props.theme.colors['text-gray-sub-darker']};
    background: ${props => props.theme.colors['bg-gray-light']};
  }
`;

const StyledTableCell = styled(TableCell)`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  & > span.tooltip-text {
    ${fonts.Underline2};
    color: ${({ theme: { colors } }) => colors['text-blue-light']};
  }
`;

const UsergroupOfTooltip = styled(Box)`
  display: flex;
  align-items: center;
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  padding: 6px 7px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
`;

const IconButton = styled(Button)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;
