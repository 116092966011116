import type { TenantRequestCreateDTO } from '@repositories/tenantRequestRepository';
import i18next from 'i18next';
import * as yup from 'yup';

export type TenantRequestCreateFormData = TenantRequestCreateDTO;

export const TenantRequestCreateSchema: yup.SchemaOf<TenantRequestCreateFormData> = yup.object({
  username: yup.string().required(i18next.t('Member_Group_Bulk_Create_22')),
  phone: yup
    .string()
    .required(i18next.t('Member_Group_Bulk_Create_22'))
    .matches(/^\d+$/, i18next.t('Subscrib_Detail_License_11')),
  companyName: yup.string().required(i18next.t('Member_Group_Bulk_Create_22')),
  companyEmail: yup.string().required(i18next.t('Member_Group_Bulk_Create_22')).email(i18next.t('Member_Manager_17')),
  jobTitle: yup.string().notRequired(),
  inquiry: yup.string().required(i18next.t('Member_Group_Bulk_Create_22')),
  privacyAgreement: yup.boolean().required(i18next.t('Member_Group_Bulk_Create_22')).default(false),
  marketingAgreement: yup.boolean().required(i18next.t('Member_Group_Bulk_Create_22')).default(false),
});
