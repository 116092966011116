// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const DownloadIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M3.2498 21.4497C2.17285 21.4497 1.2998 20.5767 1.2998 19.4997L1.2998 13.9997C1.2998 13.475 1.72513 13.0497 2.2498 13.0497C2.77447 13.0497 3.1998 13.475 3.1998 13.9997L3.1998 19.4997C3.1998 19.5273 3.22219 19.5497 3.2498 19.5497L20.7498 19.5497C20.7774 19.5497 20.7998 19.5273 20.7998 19.4997L20.7998 13.9997C20.7998 13.475 21.2251 13.0497 21.7498 13.0497C22.2745 13.0497 22.6998 13.475 22.6998 13.9997L22.6998 19.4997C22.6998 20.5767 21.8268 21.4497 20.7498 21.4497L3.2498 21.4497Z"
          fill={color}
        />
        <path
          d="M12.9498 13.2912L12.9498 3.4998C12.9498 2.97513 12.5245 2.5498 11.9998 2.5498C11.4751 2.5498 11.0498 2.97513 11.0498 3.4998L11.0498 13.2912L7.90316 10.3102C7.52228 9.94931 6.92099 9.96556 6.56015 10.3464C6.19931 10.7273 6.21556 11.3286 6.59645 11.6895L11.3464 16.1895C11.7129 16.5366 12.2868 16.5366 12.6532 16.1895L17.4032 11.6895C17.784 11.3286 17.8003 10.7273 17.4395 10.3464C17.0786 9.96556 16.4773 9.94931 16.0965 10.3102L12.9498 13.2912Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

DownloadIcon.displayName = 'DownloadIcon';
