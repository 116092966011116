import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FileIcon } from '@components/FileIcon';
import { IconButton } from '@components/buttons';
import { fonts } from '@theme/fontsCustomer';
import { DownloadIcon } from '@icons/DownloadIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { useDownloadFile } from '@queryHooks/useFile';
import { download } from '@utils/fileUtil';
import { type SubscriptionModel } from '@models/SubscriptionModel';
import { FileSimpleModel } from '@models/file/FileSimpleModel';

type Props = {
  subscription: SubscriptionModel;
};
export const ExtraInfoTabPanel = ({ subscription }: Props) => {
  const { colors } = useTheme();

  const { mutate: downloadFile } = useDownloadFile();

  const handleDownloadClick = (attachment: FileSimpleModel) => () => {
    downloadFile(attachment.fileId, {
      onSuccess: data => {
        download(data, attachment.originalName);
      },
    });
  };

  return (
    <Container>
      <Title>첨부 파일</Title>
      <FileGridBorder>
        {subscription.attachments.length === 0 && (
          <EmptyData>
            <ErrorIconWrapper>
              <ErrorIcon width={20} height={20} color={colors['ic-purple-light']} />
            </ErrorIconWrapper>
            첨부된 파일이 없습니다.
          </EmptyData>
        )}
        {subscription.attachments.length > 0 && (
          <FileGrid>
            {subscription.attachments.map(attachment => (
              <Attachment key={attachment.fileId}>
                <FileInfo>
                  <FileIcon fileExtension={attachment.fileExtension} width={20} height={20} />
                  <FileName>{attachment.fileName}</FileName>
                  {attachment.fileExtension && `.${attachment.fileExtension}`}
                </FileInfo>
                <DownloadButton variant="outline" size="extraSmall" onClick={handleDownloadClick(attachment)}>
                  <DownloadIcon />
                </DownloadButton>
              </Attachment>
            ))}
          </FileGrid>
        )}
      </FileGridBorder>
      <Title>메모</Title>
      <Memo>{subscription.memo}</Memo>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 36px;
`;

const Title = styled.div`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 6px;
`;

const FileGridBorder = styled.div`
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23D1D5DA' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  margin-bottom: 32px;
  padding: 1px;
`;

const FileGrid = styled.div`
  display: grid;
  height: 272px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(5, 1fr);
  padding: 15px 17px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  column-gap: 12px;
  row-gap: 10px;
  overflow: auto;
`;

const EmptyData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 272px;
  border-radius: 5px;
  overflow: auto;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  ${fonts.Body3};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const DownloadButton = styled(IconButton)`
  display: none;
  margin-left: 16px;
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  padding: 9px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
    ${DownloadButton} {
      display: inline-block;
    }
  }
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  min-width: 0px;
`;

const FileName = styled.div`
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;

const Memo = styled.p`
  height: 220px;
  padding: 9px 11px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  white-space: pre-wrap;
`;
