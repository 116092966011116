import { useEffect, useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { GlobalLoader } from '@components/loader';
import { SessionOut } from '@components/sessionOutDialog/SessionOut';
import { Toast } from '@components/toast';
import { useStore } from '@stores/RootStore';
import { useGetCategories } from '@queryHooks/useCategory';
import { useLogout } from '@queryHooks/useUser';
import * as S from './EmployeeStyles';
import { Modals } from './common/modals';
import { TopGnb } from './employee/gnb';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Employee = observer(() => {
  const { authStore, categoryStore } = useStore();
  const navigate = useNavigate();

  const { logout } = useLogout();

  useLayoutEffect(() => {
    authStore.initialize();
  }, [authStore]);

  const handleCloseSessionOut = () => {
    logout();
    navigate('/login');
  };

  const { data: categories } = useGetCategories();

  useEffect(() => {
    if (categories) {
      categoryStore.setCategories(categories.content);
    }
  }, [categories, categoryStore]);

  return (
    <div className="app customer">
      <TopGnb />
      <Main>
        <S.ContentsWrapper>
          <Outlet />
        </S.ContentsWrapper>
        <SessionOut onClose={handleCloseSessionOut} />
      </Main>
      <Modals />
      <GlobalLoader />
      <Toast />
    </div>
  );
});

const Main = styled.div`
  width: 100%;
  padding-top: var(--employee-gnb-height);
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    padding-top: var(--mobile-employee-gnb-height);
  } ;
`;
