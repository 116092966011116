import { createContext, useState, useMemo } from 'react';
import { Theme } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { colorDefinition, colors } from '@theme/colorsCustomer';
import { ColorSet, opacityMap } from './colorSet';
import { fonts } from './theme/fontsCustomer';

type ModeType = 'dark' | 'light';

// color design tokens
export const tokens = (mode: ModeType) => ({
  ...(mode === 'dark' ? colors.dark : colors.light),
});
// mui theme settings
export const themeSettings = (mode: ModeType): ThemeOptions => {
  const colors = tokens(mode);
  const isUserConsole =
    process.env.REACT_APP_USER_CONSOLE === 'true' || process.env.REACT_APP_CUSTOMER_CONSOLE === 'true';

  return {
    breakpoints: {
      values: {
        xxl: 1920,
        xl: 1440,
        lg: 1280,
        md: 1024,
        sm: 950,
        xs: 600,
        xxs: 320,
      },
    },
    components: {
      MuiCssBaseline: {},

      // Button
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '3px',
            textTransform: 'none',
            '&.Mui-disabled': {
              background: '#D1D5DA',
            },
          },
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              backgroundColor: colors['bg-white'],
              border: `1px solid ${colors['border-gray-light']}`,
            },
          },
          {
            props: { variant: 'contained' },
            style: {
              backgroundColor: colors['bg-purple'],
              border: `1px solid rgba(0, 0, 0, 0.15)`,
              color: `${colors['text-white']} !important`,
              ':hover': {
                backgroundColor: `rgba(90, 50, 163, 0.94)`,
                border: `1px solid ${colors['border-gray-light']}`,
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              padding: '0px',
              ':hover': {
                backgroundColor: colors['bg-white'],
              },
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: '32px',
              padding: '8px 16px',
              ...fonts.Button6,
              color: colors['text-gray-main'],
            },
          },
          {
            props: { size: 'medium' },
            style: {
              height: '36px',
              padding: '8px 16px',
              ...fonts.Button3,
              color: colors['text-gray-main'],
            },
          },
          {
            props: { size: 'large' },
            style: {
              height: '40px',
              padding: '8px 24px',
              ...fonts.Button2,
              color: colors['text-gray-main'],
            },
          },
          {
            props: { size: 'extraLarge' },
            style: {
              height: '48px',
              padding: '8px 24px',
              ...fonts.Button2,
              color: colors['text-gray-main'],
            },
          },
          {
            props: { size: '2extraLarge' },
            style: {
              padding: '14px 24px',
              ...fonts.Button2,
            },
          },
        ],
      },

      MuiInputBase: {
        defaultProps: {
          // These should boots the performance of the TextField components as GlobalStyles for the auto-fill keyframes will not be injected/removed on mount/unmount.
          // This option is intended to help with boosting the initial rendering performance if you are loading a big amount of Input components at once.
          disableInjectingGlobalStyles: true,
        },
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            color: colors['border-gray-lighter'],
          },
        },
      },

      // Table
      MuiTableContainer: {
        styleOverrides: {
          root: {
            border: `1px solid ${colors['border-gray-lighter']}`,
            boxShadow: `0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07)`,
            borderRadius: '5px',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '&.MuiTable-root': {
              borderSpacing: 0,
              tableLayout: 'fixed',
              borderStyle: 'hidden',
              overflow: 'hidden',
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: colors['state-white-hover'],
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            height: '42px',
            padding: '0 15px 0 16px',
            border: `1px solid ${colors['border-gray-w-lighter']}`,
            borderCollapse: 'collapse',
          },
          head: {
            backgroundColor: colors['bg-gray-lighter'],
            ...fonts.Headline9,
          },
          body: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            flexDirection: 'row-reverse',
            gap: '4px',
          },
        },
      },

      MuiPagination: {
        defaultProps: {
          shape: 'rounded',
        },
        styleOverrides: {
          root: {
            margin: '0 auto',
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: 'unset',
            background: `${colors['bg-gray-main']}`,
            color: `${colors['text-white']}`,
            padding: '14px 16px',
            border: 'none',
            borderRadius: '3px',
            ...fonts.Body2,
          },
          arrow: {
            color: `${colors['bg-gray-main']}`,
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          list: {
            padding: '4px 4px',
            backgroundColor: `${colors['bg-white']}`,
            border: `1px solid ${colors['border-gray-light']}`,
            borderRadius: '0 0 2px 2px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderRadius: '2px',
            padding: '6px 7px',
            backgroundColor: `${colors['bg-white']}`,
            color: `${colors['text-gray-main']}`,
            ...fonts.Body2,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:active': {
              color: `${colors['text-purple-light']}`,
            },
            '&:hover': {
              backgroundColor: `${colors['state-white-hover']}`,
            },
            '&.Mui-disabled': {
              color: `${colors['text-gray-light']}`,
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
            '&.Mui-selected': {
              backgroundColor: `${colors['state-white-hover']}`,
              color: `${colors['text-purple-light']}`,
              '&:hover': {
                backgroundColor: `${colors['state-white-hover']}`,
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '0 0 2px 2px',
            boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
          },
        },
      },
      // TextField
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              padding: '0',
            },
            '&.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors['border-purple']}`,
              },
              ':hover': {
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors['border-purple']}`,
                },
              },
              '& > .MuiSelect-select ~ .MuiOutlinedInput-notchedOutline': {
                borderRadius: '2px 2px 0 0',
                border: `1px solid ${colors['border-gray-light']}`,
              },
            },
            ':hover': {
              '.MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors['border-gray-light']}`,
              },
            },
            '&.password-field': {
              '.MuiOutlinedInput-root': {
                padding: '0 12px',
              },
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #fff inset',
            },
            '&.Mui-error': {
              '&:hover': {
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors['border-red-light']}`,
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors['border-red-light']}`,
              },
              '&.Mui-focused': {
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colors['border-red-light']}`,
                },
              },
            },
          },
          notchedOutline: {
            border: `1px solid ${colors['border-gray-light']}`,
            borderRadius: '2px',
          },
        },
        variants: [
          {
            props: { disabled: true },
            style: {
              '.Mui-disabled': {
                WebkitTextFillColor: 'black',
              },
            },
          },
          {
            props: { readOnly: true },
            style: {
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: '32px',
              borderRadius: '2px',
              padding: '6px 12px 6px 11px',
              ...fonts.Body2,
            },
          },
          {
            props: { size: 'medium', multiline: false },
            style: {
              height: '40px',
              borderRadius: '2px',
              padding: '10px 12px',
              ...fonts.Body2,
            },
          },
          {
            props: { size: 'medium', multiline: true },
            style: {
              minHeight: '40px',
              borderRadius: '2px',
              padding: '10px 12px',
              ...fonts.Body2,
            },
          },
          {
            props: { size: 'large' },
            style: {
              maxHeight: '54px',
              borderRadius: '2px',
              padding: '13px 12px',
              ...fonts.Body1,
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            background: colors['bg-white'],
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '2px',
              border: `1px solid ${colors['border-gray-light']}`,
            },
            '& .MuiInputBase-input::placeholder': {
              color: colors['text-gray-light'],
              opacity: 1,
            },
            '& > .Mui-error > .MuiInputBase-input': {
              color: colors['text-red-light'],
            },
            '& > .Mui-error > .MuiInputBase-input::placeholder': {
              color: colors['text-red-light'],
              opacity: 1,
            },
            '& > .Mui-error + .MuiFormHelperText-root': {
              color: colors['text-red-light'],
              opacity: 1,
            },
            '& > .Mui-error svg ': {
              '& g path': {
                fill: colors['text-red-light'],
                opacity: 1,
              },
            },
            '& .MuiFormHelperText-root': {
              margin: '4px 0px 0px',
              ...fonts.Body4,
              color: colors['text-gray-light'],
            },
            '&.password-field': {
              '.MuiOutlinedInput-root': {
                padding: '0 12px',
                '.MuiInputBase-input': {
                  padding: '15px 12px 15px 0',
                  width: 'calc( 100% - 32px)',
                },
              },
            },
          },
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              height: '32px',
            },
          },
          {
            props: { size: 'medium' },
            style: {
              minHeight: '40px',
            },
          },
          {
            props: { size: 'large' },
            style: {
              '& .MuiInputBase-input': {
                height: '24px',
                ...fonts.Body1,
                padding: '15px 12px',
              },
            },
          },
        ],
      },

      // AucoComplete
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              height: '100%',
              boxSizing: 'border-box',
              '&::selection': {
                background: colorDefinition.purple[200],
              },
            },
            '& .MuiButtonBase-root:hover': {
              backgroundColor: colors['bg-white'],
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
          paper: {
            borderRadius: '8px',
            '& .MuiAutocomplete-listbox': {
              padding: '5px 4px',
              maxHeight: '208px',
              '& .MuiAutocomplete-option': {
                padding: '10px 7px',
                ...fonts.Body2,
                '&:hover': {
                  background: colors['state-white-hover'],
                },
                '&:active': {
                  background: colors['state-white-hover'],
                  color: colors['text-purple-light'],
                },
                '&[aria-selected="true"]': {
                  background: colors['state-white-hover'],
                  color: colors['text-purple-light'],
                },
                '&[aria-selected="true"].Mui-focused': {
                  background: colors['state-white-hover'],
                  color: colors['text-purple-light'],
                },
              },
            },
          },
        },
        variants: [
          {
            props: { readOnly: true },
            style: {
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '.MuiSvgIcon-root': {
                display: 'none',
              },
            },
          },
        ],
      },

      MuiSelect: {
        styleOverrides: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          root: {
            backgroundColor: colors['bg-white'],
          },
        },
        variants: [
          {
            props: { readOnly: true },
            style: {
              '.MuiSelect-select': {
                cursor: 'text',
              },
              '.MuiSvgIcon-root': {
                display: 'none',
              },
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: '32px',
            },
          },
          {
            props: { size: 'medium' },
            style: {
              height: '40px',
            },
          },
          {
            props: { size: 'large' },
            style: {
              height: '48px',
            },
          },
        ],
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '0px',
          },
        },
      },

      // Typography
      MuiTypography: {
        styleOverrides: {
          root: {
            color: ColorSet[mode].Text['text-gray-main'],
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            borderRadius: '2px',
            '&.Mui-checked': {},
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              width: '20px',
              height: '20px',
            },
          },
        ],
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            padding: '6px 7px 6px 11px',
            backgroundColor: `${colors['bg-gray-lighter']}`,
            border: `1px solid ${colors['border-gray-lighter']}`,
            gap: '8px',
            '& .MuiChip-label': {
              ...fonts.Body2,
              padding: '0',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 36,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 2,
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  border: 0,
                  backgroundColor: `${colors['bg-purple-light']}`,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: `${colors['bg-gray-dark']}`,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: `${colors['bg-purple-light']}`,
                border: '6px solid #fff',
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color: `${colors['bg-gray-light']}`,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: `${colors['bg-gray-dark']}`,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 16,
              height: 16,
            },
            '& .MuiSwitch-track': {
              borderRadius: '10px',
              opacity: 1,
              backgroundColor: `${colors['bg-gray-dark']}`,
            },
          },
        },
      },
    },

    isUserConsole,
    colors: ColorSet[mode],
    opacityMap,

    typography: {
      fontFamily: `Pretendard, Spartan, Pretendard-bold`,
      headline1: {
        ...fonts.Headline1,
      },
      headline2: {
        ...fonts.Headline2,
      },
      headline3: {
        ...fonts.Headline3,
      },
      headline4: {
        ...fonts.Headline4,
      },
      headline5: {
        ...fonts.Headline5,
      },
      headline6: {
        ...fonts.Headline6,
      },
      headline7: {
        ...fonts.Headline7,
      },
      headline8: {
        ...fonts.Headline8,
      },
      headline9: {
        ...fonts.Headline9,
      },
      display1: {
        ...fonts.Display1,
      },
      display2: {
        ...fonts.Display2,
      },
      subtitle1: {
        ...fonts.Subtitle1,
      },
      subtitle2: {
        ...fonts.Subtitle2,
      },
      button1: {
        ...fonts.Button1,
      },
      button2: {
        ...fonts.Button2,
      },
      button3: {
        ...fonts.Button3,
      },
      button4: {
        ...fonts.Button4,
      },
      button5: {
        ...fonts.Button5,
      },
      button6: {
        ...fonts.Button6,
      },
      body1: {
        ...fonts.Body1,
      },
      body2: {
        ...fonts.Body2,
      },
      body3: {
        ...fonts.Body3,
      },
      body4: {
        ...fonts.Body4,
      },
      body5: {
        ...fonts.Body5,
      },
      body6: {
        ...fonts.Body6,
      },
      caption1: {
        ...fonts.Caption1,
      },
      caption2: {
        ...fonts.Caption2,
      },
      caption3: {
        ...fonts.Caption3,
      },
      caption4: {
        ...fonts.Caption4,
      },
      caption5: {
        ...fonts.Caption5,
      },
      underline1: {
        ...fonts.Underline1,
      },
      underline2: {
        ...fonts.Underline2,
      },
      paragraph1: {
        ...fonts.Paragraph1,
      },
      paragraph2: {
        ...fonts.Paragraph2,
      },
      paragraph3: {
        ...fonts.Paragraph3,
      },
      paragraph4: {
        ...fonts.Paragraph4,
      },
    },
    // FIXME: palette 사용하는 부분들 새 color 시스템으로 변경 후 제거 필요
    palette: {
      neutral: {
        main: colors['text-gray-main'],
      },
      danger: {
        main: colors['text-red-light'],
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {},
});

export const useMode = (): [Theme, { toggleColorMode: () => void }] => {
  const [mode, setMode] = useState<'dark' | 'light'>('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => setMode(prev => (prev === 'light' ? 'dark' : 'light')),
    }),
    [],
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
