import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shouldForwardProp from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { Button as CustomButton, Label } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { PencilIcon } from '@icons/PencilIcon';
import { SupportIcon } from '@icons/SupportIcon';
import { TrashIcon } from '@icons/TrashIcon';
import type { TechnicalInquiryHistoryDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryHistoryDTO';
import { useDeleteTechnicalInquiry, useGetTechnicalInquiryDetail } from '@queryHooks/useTechnicalSupport';
import { TechnicalSupportUpdateModal } from './TechnicalSupportUpdateModal';

export const TechnicalSupportDetail = () => {
  const {
    uiStore: { alertStore: deleteModal, toastStore },
  } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false);
  const [isOpenReInquiryModal, setIsOpenReInquiryModal] = useState(false);

  const { data: technicalInquiry } = useGetTechnicalInquiryDetail(id as string);
  const { mutate: technicalInquiryDelete } = useDeleteTechnicalInquiry();

  const findMostRecentHistory = (): TechnicalInquiryHistoryDTOModel | null => {
    if (technicalInquiry && technicalInquiry.histories.length > 0) {
      const sortedHistories = technicalInquiry.histories.sort(
        (a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime(),
      );

      return sortedHistories[0];
    }
    return null;
  };

  const recentHistory = findMostRecentHistory();

  const handleClickEdit = () => {
    setIsOpenUpdateModal(true);
    setIsOpenReInquiryModal(false);
  };

  const handleClicReInquiry = () => {
    setIsOpenReInquiryModal(true);
    setIsOpenUpdateModal(false);
  };

  const onClose = () => {
    setIsOpenUpdateModal(false);
    setIsOpenReInquiryModal(false);
  };

  const handleClickDelete = () => {
    if (technicalInquiry) {
      technicalInquiryDelete(technicalInquiry?.technicalInquiryId, {
        onSuccess: () => {
          navigate('/subscriptions/technical-support');
          toastStore.open('문의 내역 삭제를 완료했습니다.');
        },
      });
    }
  };

  return (
    <Container>
      <Header>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        <span>목록</span>
      </Header>
      <Body>
        <TitleHeader>
          <span className="title">문의 내역</span>

          {recentHistory?.status === 'CREATED' && (technicalInquiry?.histories.length ?? 0) <= 1 && (
            <div className="edit">
              <Button variant="outline" size="small" onClick={handleClickEdit} paddingHorizontal={12}>
                <PencilIcon width={16} height={16} />
                수정
              </Button>
              {technicalInquiry?.histories.length === 1 && (
                <Button
                  variant="outline"
                  size="small"
                  paddingHorizontal={12}
                  onClick={() =>
                    deleteModal.open({
                      confirmName: '삭제',
                      message: '문의 내역을 삭제 하시겠습니까?',
                      title: '문의 내역 삭제',
                      useCancel: true,
                      cancelName: '취소',
                      onConfirm: handleClickDelete,
                      type: 'error',
                    })
                  }
                >
                  <TrashIcon width={16} height={16} />
                  삭제
                </Button>
              )}
            </div>
          )}
        </TitleHeader>
        <Frame>
          <Columns>
            <Column divide>
              <span className="content-name">제품 구분</span>
              <span className="content-value">{technicalInquiry?.categoryName || '-'}</span>
            </Column>
            <Column>
              <span className="content-name">제품 명</span>
              <span className="content-value">{technicalInquiry?.product.name || '-'}</span>
            </Column>
          </Columns>
          <Columns>
            <Column divide>
              <span className="content-name">문의 날짜</span>
              <span className="content-value">
                {technicalInquiry?.createdDate ? dayjs(technicalInquiry.createdDate).format('YYYY.MM.DD') : '-'}
              </span>
            </Column>
            <Column divide>
              <span className="content-name">처리 상태</span>
              <span className="content-value">
                <Label variant="contain" color={technicalInquiry?.conversionStatusColor || 'pink'}>
                  {technicalInquiry?.conversionStatus}
                </Label>
              </span>
            </Column>
          </Columns>
          <Columns>
            <Column divide>
              <span className="content-name">내용</span>
              <span className="inquiry">{technicalInquiry?.inquiry}</span>
            </Column>
          </Columns>
        </Frame>
        <TitleHeader>
          <span className="title">처리 내역</span>
          {technicalInquiry?.histories && technicalInquiry.histories.length > 1 && (
            <div className="edit">
              <Button variant="outline" size="small" onClick={handleClicReInquiry} paddingHorizontal={12}>
                <SupportIcon width={16} height={16} />
                재문의
              </Button>
            </div>
          )}
        </TitleHeader>
        {technicalInquiry?.histories
          .filter(history => history.status === 'CREATED' || history.status === 'COMPLETED')
          .map(history => (
            <Frame key={history.id}>
              <Columns>
                <Column divide>
                  <span className="content-name">처리 상태</span>
                  <span className="content-value">
                    <Label variant="contain" color={history.conversionStatusColor || 'pink'}>
                      {history.conversionStatus}
                    </Label>
                  </span>
                </Column>
                <Column>
                  <span className="content-name">처리 날짜</span>
                  <span className="content-value">{dayjs(history.createDate).format('YYYY.MM.DD')}</span>
                </Column>
              </Columns>
              {history.status === 'COMPLETED' && (
                <Columns>
                  <Column divide>
                    <span className="content-name">담당자</span>
                    <span className="content-value">{history.managerName || '-'}</span>
                  </Column>
                  <Column />
                </Columns>
              )}
              {history.comment && (
                <Columns>
                  <Column>
                    <span className="content-name">내용</span>
                    <span className="inquiry">{history.comment || '-'}</span>
                  </Column>
                </Columns>
              )}
            </Frame>
          ))}
      </Body>
      <TechnicalSupportUpdateModal
        technicalInquiry={technicalInquiry}
        open={isOpenUpdateModal || isOpenReInquiryModal}
        onClose={onClose}
        isUpdateMode={isOpenUpdateModal}
      />
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - 80px);
  margin-left: 292px;
  overflow: auto;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  ${fonts.Headline3};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  align-items: center;
  gap: 12px;
  margin-bottom: 42px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const TitleHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;

  & .title {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }

  & .edit {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
`;

const Button = styled(CustomButton)`
  & > span {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
  }
`;

const Frame = styled(Box)`
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  margin-bottom: 31px;
`;

const Columns = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const Column = styled(Box, {
  shouldForwardProp: prop => shouldForwardProp(prop) && prop !== 'divide',
})<{ divide?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 15px 0px 15px 20px;
  gap: 2px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  border-right: ${({ divide, theme }) => (divide ? `1px solid ${theme.colors['border-gray-w-lighter']}` : 'none')};
  & .content-name {
    ${fonts.Headline9};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  & .content-value {
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
  & .inquiry {
    ${fonts.Paragraph5};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    white-space: pre-line;
  }
`;
