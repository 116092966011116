import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { TenantBillingStats } from './TenantBillingStats';
import { YearlyBilling } from './YearlyBilling';
import { Category5Wg } from './cardWidgets/category5Wg/Category5Wg';
import { Cost5Wg } from './cardWidgets/cost5Wg/Cost5Wg';
import { GroupCost5Wg } from './cardWidgets/groupCost5Wg/GroupCost5Wg';
import { InactiveUser5Wg } from './cardWidgets/inactiveUser5Wg/InactiveUser5Wg';
import { PayingUser5Wg } from './cardWidgets/payingUser5wg/PayingUser5Wg';

export const BillingDashboard = () => {
  return (
    <Container>
      <Title>현황 조회</Title>
      <TenantBillingStats />
      <YearlyBilling />
      <Grid>
        <Cost5Wg />
        <GroupCost5Wg />
        <Category5Wg />
        <PayingUser5Wg />
        <InactiveUser5Wg />
      </Grid>
    </Container>
  );
};

const Container = styled.section`
  padding: 28px 32px;
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow-y: auto;
`;

const Title = styled.h3`
  ${fonts.Headline5}
  margin: 0px 8px 14px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
`;
