import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { IconButton, Modal, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/buttons';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { XIcon } from '@icons/XIcon';

interface Props {
  onClose: () => void;
}

export const SessionOut = observer(({ onClose }: Props) => {
  const { t } = useTranslation();
  const { uiStore } = useStore();
  const theme = useTheme();

  const handleClose = () => {
    uiStore.closeSessionExpiredModal();
    onClose();
  };

  const handleKeyup2 = (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (e?.code === 'Enter') handleClose();
  };

  return (
    <Modal open={uiStore.isSessionExpiredVisible}>
      <ModalWrapper onKeyUp={handleKeyup2}>
        <Header>
          {t('session_expired_popup_title')}
          <IconButton onClick={handleClose}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </IconButton>
        </Header>
        <Body>{t('session_expired_popup_string')}</Body>
        <Footer>
          <Button variant="contain" size="extraLarge" onClick={handleClose} fullWidth className="success">
            {t('Member_User_24')}
          </Button>
        </Footer>
      </ModalWrapper>
    </Modal>
  );
});

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 100px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: inherit;
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  padding: 0px 18px 0px 28px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 48px;
  width: inherit;
  padding: 12px 28px 4px;
  ${fonts.Paragraph1};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
  padding: 24px 28px 28px;
  & > button {
    min-width: 168px;
    &.success {
      &:first-of-type {
        color: ${({ theme }) => theme.colors['text-white']};
        background-color: ${({ theme }) => theme.colors['bg-purple']};
      }
    }
    &:first-of-type {
      color: ${({ theme }) => theme.colors['text-white']};
      background-color: ${({ theme }) => theme.colors['bg-red-light']};
    }
  }
`;
