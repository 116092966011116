const defaultWidth = 18;
const defaultHeight = 18;

export const CardIcon = ({ width = defaultWidth, height = defaultHeight, color = '#6A737D' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M11.3359 10.5312C11.0382 10.5312 10.7969 10.7726 10.7969 11.0703C10.7969 11.368 11.0382 11.6094 11.3359 11.6094H13.8516C14.1493 11.6094 14.3906 11.368 14.3906 11.0703C14.3906 10.7726 14.1493 10.5312 13.8516 10.5312H11.3359Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.63281 2.625C0.938142 2.625 0.375 3.18814 0.375 3.88281V14.3047C0.375 14.9994 0.938142 15.5625 1.63281 15.5625H16.3672C17.0619 15.5625 17.625 14.9994 17.625 14.3047V3.88281C17.625 3.18814 17.0619 2.625 16.3672 2.625H1.63281ZM1.45312 3.88281C1.45312 3.78357 1.53357 3.70312 1.63281 3.70312H16.3672C16.4664 3.70312 16.5469 3.78357 16.5469 3.88281V6.57812H1.45312V3.88281ZM1.45312 7.65625V14.3047C1.45312 14.4039 1.53357 14.4844 1.63281 14.4844H16.3672C16.4664 14.4844 16.5469 14.4039 16.5469 14.3047V7.65625H1.45312Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.6328 2.70005C0.979551 2.70005 0.449988 3.22961 0.449988 3.88286V14.3047C0.449988 14.958 0.979551 15.4875 1.6328 15.4875H16.3672C17.0204 15.4875 17.55 14.958 17.55 14.3047V3.88286C17.55 3.22961 17.0204 2.70005 16.3672 2.70005H1.6328ZM0.299988 3.88286C0.299988 3.14677 0.896708 2.55005 1.6328 2.55005H16.3672C17.1033 2.55005 17.7 3.14677 17.7 3.88286V14.3047C17.7 15.0408 17.1033 15.6375 16.3672 15.6375H1.6328C0.896708 15.6375 0.299988 15.0408 0.299988 14.3047V3.88286ZM1.37811 3.88286C1.37811 3.7422 1.49214 3.62817 1.6328 3.62817H16.3672C16.5078 3.62817 16.6219 3.7422 16.6219 3.88286V6.65317H1.37811V3.88286ZM1.6328 3.77817C1.57498 3.77817 1.52811 3.82504 1.52811 3.88286V6.50317H16.4719V3.88286C16.4719 3.82504 16.425 3.77817 16.3672 3.77817H1.6328ZM1.37811 7.5813H16.6219V14.3047C16.6219 14.4454 16.5078 14.5594 16.3672 14.5594H1.6328C1.49214 14.5594 1.37811 14.4454 1.37811 14.3047V7.5813ZM1.52811 7.7313V14.3047C1.52811 14.3626 1.57498 14.4094 1.6328 14.4094H16.3672C16.425 14.4094 16.4719 14.3626 16.4719 14.3047V7.7313H1.52811ZM11.3359 10.6063C11.0796 10.6063 10.8719 10.8141 10.8719 11.0704C10.8719 11.3267 11.0796 11.5344 11.3359 11.5344H13.8516C14.1078 11.5344 14.3156 11.3267 14.3156 11.0704C14.3156 10.8141 14.1078 10.6063 13.8516 10.6063H11.3359ZM10.7219 11.0704C10.7219 10.7312 10.9968 10.4563 11.3359 10.4563H13.8516C14.1907 10.4563 14.4656 10.7312 14.4656 11.0704C14.4656 11.4095 14.1907 11.6844 13.8516 11.6844H11.3359C10.9968 11.6844 10.7219 11.4095 10.7219 11.0704Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CardIcon.displayName = 'CardIcon';
