import { UserGroupMemberModel } from '@models/userGroupModels/UserGroupMemberModel';
import * as S from './MemberTableRowStyles';

interface Props {
  rowData: UserGroupMemberModel;
}

export const MemberTableRow = ({ rowData: { user: member } }: Props) => {
  // const { id } = useParams();
  // const {
  //   uiStore: { alertStore: modal },
  // } = useStore();

  // const [isVisibleHover, setIsVisibleHover] = useState<boolean>(false);

  // const { data: userGroup } = useGetUserGroup(id as string);
  // const { mutate: removeMember } = useDeleteUserGroupMember();

  // const rqRemoveMemberFromUserGroup = () => {
  //   removeMember({ userGroupId: id as string, userId: member.id });
  // };

  // const handleClickRemoveMemberBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   modal.open({
  //     title: '사용자 삭제',
  //     message: `${member.name}을 ${userGroup?.name} 사용자 그룹에서 삭제하시겠습니까?`,
  //     confirmName: '삭제',
  //     onConfirm: rqRemoveMemberFromUserGroup,
  //   });
  // };

  return (
    // <TableRow hover onMouseLeave={() => setIsVisibleHover(false)} onMouseEnter={() => setIsVisibleHover(true)}>
    <S.StyledTableRow>
      <S.StyledTableCell>{member.name}</S.StyledTableCell>
      <S.StyledTableCell>{member.username}</S.StyledTableCell>
      <S.StyledTableCell>{member.phone}</S.StyledTableCell>
    </S.StyledTableRow>
  );
};
