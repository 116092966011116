import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Fade, Menu, MenuItem, Typography, css, popoverClasses, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { SmallAvatar } from '@components/avatar';
import { Button } from '@components/index';
import { RoutingTabs } from '@components/tab';
import { MyProfileModal } from '@pages/customer/employee/gnb/myProfileModal';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import TailImg from '@images/tail.png';
import { ProfileIcon } from '@icons/ProfileIcon';
import { PersonIcon, LogoutIcon, StoreIcon, StoreFillIcon } from '@icons/index';
import localStorage from '@repositories/browserStorage/localStorage/LocalStorage';
import { useLogout } from '@queryHooks/index';
import { useGetTenant } from '@queryHooks/useTenant';
import { transientOptions } from '@utils/CommonUtil';
import i18n from '../../../../locales/i18n';

const profileStyles = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.06);',
    borderRadius: '3px',
    mt: '18px',
    border: '1px solid #e1e4e8',
    '& .MuiList-root': {
      padding: '6px',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '3px',
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -6,
      right: 40,
      width: 14,
      height: 7,
      bgcolor: 'background.paper',
      background: `url(${TailImg}) 0 0 no-repeat`,
      backgroundSize: '14px 7px',
      webkitBackgroundSize: '14px 7px',
      zIndex: 0,
    },
  },
};

const ManagerGnb = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = location.pathname.split('/')[2];
  const [isLangOpen, setIsLangOpen] = useState<boolean>(false);

  const {
    authStore,
    uiStore: { profileModalStore: modal },
  } = useStore();
  const { data: tenant } = useGetTenant(authStore.curTenant.id);
  const logoUrl = tenant?.logoImageUrl ?? '';
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileMenuOwnerRef = useRef<HTMLDivElement>(null);

  const { logout } = useLogout();

  const handleClickProfileMenu = () => {
    setIsProfileMenuOpen(true);
  };
  const handleCloseProfileMenu = () => {
    setIsProfileMenuOpen(false);
    setIsLangOpen(false);
  };
  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    dayjs.locale(lang);
    localStorage.set('lang', lang);
    document.cookie = `SMP_lang=${lang}; path=/; secure;`;
  };
  const isStore = location.pathname.split('/')[3] === 'store';

  const handleLogout = () => {
    handleCloseProfileMenu();
    logout();
    navigate(`/manager/${tenantId}`);
  };

  return (
    <Container>
      {matches ? null : (
        <>
          <Logo>
            <HomeLink to={`/manager/${tenantId}`}>
              {logoUrl ? (
                <CustomLogoWrapper>
                  <img alt="logo" src={logoUrl} />
                </CustomLogoWrapper>
              ) : (
                <Title>{tenant?.name ?? ''}</Title>
              )}
            </HomeLink>
            <ManagerLogo>
              <Typography component="span" variant="caption5" color={theme.colors['text-white']}>
                {t('GNB_Menu_06')}
              </Typography>
            </ManagerLogo>
          </Logo>

          {/* routingTabs 개선 후 적용 예정 */}
          <MenusWrapper>
            <RoutingTabs
              isAdmin
              variant="line"
              tabList={[
                // FIXME: 미구현된 메뉴들 주석 처리
                { name: t('Ad_GNB_Menu_03'), path: `/manager/${tenantId}/home` },
                // { name: '대시보드', path: `/manager/${tenantId}/dashboard` },
                { name: t('Workflow_Create_68'), path: `/manager/${tenantId}/subscriptions` },
                // { name: t('Ad_GNB_Menu_04'), path: `/manager/${tenantId}/analyze/cost` },
                { name: '비용', path: `/manager/${tenantId}/billings` },
                { name: t('Ad_GNB_Menu_02'), path: `/manager/${tenantId}/workflows` },
                { name: t('Ad_GNB_Menu_05'), path: `/manager/${tenantId}/usergroups` },
                // { name: '기술지원', path: `/manager/${tenantId}/tech-support` },
              ]}
            />
          </MenusWrapper>
          <RightElementWrapper>
            {/* 전체검색 임시 삭제 */}
            {/* <Button variant="text" size="small" onClick={() => navigate('/manager/search')}>
              <SearchIcon width={28} height={28} color={theme.colors['ic-gray-lighter']} />
            </Button> */}
            <IconButton
              variant="text"
              size="large"
              onClick={() => navigate(`/manager/${tenantId}/store`)}
              $isStore={isStore}
            >
              {!isStore ? (
                <StoreIcon width={28} height={29} bgColor={theme.colors['ic-gray-lighter']} />
              ) : (
                <StoreFillIcon width={28} height={29} bgColor={theme.colors['ic-white']} />
              )}
            </IconButton>
            <Profile
              ref={profileMenuOwnerRef}
              onMouseEnter={handleClickProfileMenu}
              onMouseLeave={handleCloseProfileMenu}
              className={authStore.isItAdmin ? 'admin-profile' : ''}
            >
              <SmallAvatar>
                <ProfileIcon width={28} height={28} />
              </SmallAvatar>
            </Profile>
            <Menu
              anchorEl={profileMenuOwnerRef.current}
              open={isProfileMenuOpen}
              onClose={handleCloseProfileMenu}
              MenuListProps={{
                onMouseEnter: handleClickProfileMenu,
                onMouseLeave: handleCloseProfileMenu,
                sx: { pointerEvents: 'auto' },
              }}
              TransitionComponent={Fade}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              PaperProps={profileStyles}
              sx={{ [`&.${popoverClasses.root}`]: { pointerEvents: 'none', marginTop: '-1px' } }}
            >
              <StyledMenuItem
                value="profile"
                onClick={() => {
                  modal.open();
                  handleCloseProfileMenu();
                }}
              >
                <ProfileMenuWrapper>
                  <PersonIcon width={18} height={18} />
                  <Typography noWrap component="span" variant="body1" color={theme.colors['text-gray-main']}>
                    {t('GNB_Profile_Menu_01')}
                  </Typography>
                </ProfileMenuWrapper>
              </StyledMenuItem>
              {/* [23.12.19]영문화 string 작업 완료 전까지 임시 조치 */}
              {/* <StyledMenuItem value="language">
                <LanguageWrapper onClick={() => setIsLangOpen(true)}>
                  <GlobeIcon width={18} height={18} />
                  <Typography
                    noWrap
                    component="span"
                    variant="body1"
                    color={theme.colors['text-gray-main']}
                    sx={{ marginRight: 'auto' }}
                  >
                    {t('GNB_Profile_Menu_02')}
                  </Typography>
                  <TriangleIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
                  {isLangOpen && (
                    <Languages>
                      <Language onClick={() => handleChangeLang('ko')}>
                        <span className={i18n.language === 'ko' ? 'ko' : undefined}>한국어</span>
                        {i18n.language === 'ko' && (
                          <CheckIcon width={20} height={20} color={theme.colors['ic-purple-light']} />
                        )}
                      </Language>
                      <Language onClick={() => handleChangeLang('en')}>
                        <span className={i18n.language === 'en' ? 'en' : undefined}>English</span>
                        {i18n.language === 'en' && (
                          <CheckIcon width={20} height={20} color={theme.colors['ic-purple-light']} />
                        )}
                      </Language>
                    </Languages>
                  )} 
                </LanguageWrapper>
              </StyledMenuItem> */}
              <StyledMenuItem value="logout" onClick={handleLogout}>
                <ProfileMenuWrapper>
                  <LogoutIcon width={18} height={18} />
                  <Typography noWrap component="span" variant="body1" color={theme.colors['text-gray-main']}>
                    {t('GNB_Profile_Menu_03')}
                  </Typography>
                </ProfileMenuWrapper>
              </StyledMenuItem>
            </Menu>
          </RightElementWrapper>
        </>
      )}
      {modal.visible && <MyProfileModal />}
    </Container>
  );
};

const Container = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--manager-gnb-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 0px 50px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-main']};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  z-index: 1001;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    padding: 0 20px;
  }
`;

const RightElementWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  height: inherit;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  z-index: 1001;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    & .MuiButtonBase-root.MuiIconButton-root {
      margin-right: 0;
      &:first-of-type {
        margin-right: 12px;
      }
    }
  }
`;

const CustomLogoWrapper = styled.div`
  position: relative;
  height: 25px;

  & > img {
    width: auto;
    max-width: 300px;
    height: 100%;
    object-fit: contain;
  }
`;

const Logo = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
  z-index: 1001;
`;

const Title = styled.span`
  ${fonts.Display3};
  color: ${({ theme: { colors } }) => colors['text-white']};
`;

const HomeLink = styled(Link)`
  text-decoration: none;
`;

const ManagerLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8.5px;
  background: ${({ theme: { colors } }) => colors['bg-gradient']};
  border-radius: 2px;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  & > span {
    ${fonts.Button4}
    color: ${({ theme: { colors } }) => colors['text-white']}
  }
`;
const MenusWrapper = styled(Box)`
  max-width: 1440px;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  justify-content: center;
  gap: 32px;
  align-items: center;
  & .en-tab-item {
    height: 100%;
    min-width: auto;
    padding: 0 46px;
  }
`;

const IconButton = styled(Button, transientOptions)<{ $isStore: boolean }>`
  width: 42px;
  height: 42px;
  &:hover {
    svg .line {
      fill: ${({ theme }) => theme.colors['ic-white']};
    }
  }
  & span {
    position: relative;
  }

  & span::after {
    ${({ $isStore, theme: { colors } }) =>
      $isStore &&
      css`
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -17px;
        width: 100%;
        height: 3px;
        background-color: ${colors['bg-white']};
      `}
  }
`;

const StyledMenuItem = styled(MenuItem)`
  width: 198px;
  padding: 8px 4px 8px 11px;
  border-radius: 2px;
  overflow: visible;
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
`;
const ProfileMenuWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 6px;
`;

const Languages = styled.ul`
  position: absolute;
  right: 200px;
  top: -7px;
  width: 180px;
  height: fit-content;
  padding: 6px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 3px;
  background-color: ${props => props.theme.colors['bg-white']};
  z-index: 1001;
`;

const Language = styled.li`
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 11px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: ${props => props.theme.colors['text-purple-light']};
  ${fonts.Body2};
  &:hover {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
  & > span {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-main']};
    &.ko,
    &.en {
      color: ${({ theme }) => theme.colors['text-purple-light']};
    }
  }
`;

export default ManagerGnb;
