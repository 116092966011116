const defaultWidth = 16;
const defaultHeight = 16;

export const PPTFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#CB2431' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37272 1.67318C10.2061 1.58984 10.9292 2.24425 10.9292 3.08176V12.9169C10.9292 13.7544 10.2061 14.4089 9.37272 14.3255L3.27474 13.7157C2.55109 13.6434 2 13.0344 2 12.3071V3.69155C2 2.96428 2.55109 2.35534 3.27474 2.28298L9.37272 1.67318ZM11.7141 13.5526H12.9972C13.779 13.5526 14.4128 12.9189 14.4128 12.137V4.29679C14.4128 3.51497 13.779 2.88118 12.9972 2.88118H11.7141V3.97011H12.9972C13.1776 3.97011 13.3239 4.11637 13.3239 4.29679V12.137C13.3239 12.3175 13.1776 12.4637 12.9972 12.4637H11.7141V13.5526ZM4.66667 5.59928V10.5485H5.82878V8.99675H6.65592C7.79069 8.99675 8.49137 8.32341 8.48796 7.30143C8.49137 6.29313 7.80436 5.59928 6.6901 5.59928H4.66667ZM6.44401 8.07389H5.82878V6.54264H6.44401C6.9943 6.54264 7.28483 6.84001 7.28483 7.30143C7.28483 7.76969 6.9943 8.07731 6.44401 8.07389Z"
        fill={color}
      />
    </g>
  </svg>
);
