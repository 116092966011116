import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, Checkbox, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { CheckboxIcon } from '@icons/CheckboxIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { SmpColorIcon } from '@icons/SmpColorIcon';
import { useGetSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import { useGetSMPConnections } from '@queryHooks/useTenant';
import { insertAt } from '@utils/CommonUtil';
import { ConnectorActionModel } from '@models/connection/ConnectorActionModel';
import { InputDataPopup } from './InputDataPopup';

type ShowData = {
  isShow: boolean;
  index?: number;
  cursorPos?: number; // 텍스트필드 내 커서 위치
};

export const ActionConfig = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantId } = useParams();
  const { workflowDetailStore } = useStore();
  const [showData, setShowData] = useState<ShowData>({ isShow: false });
  const focusedInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const actionNode = workflowDetailStore.findNode(workflowDetailStore.curNodeId ?? '');
  const actionModel = actionNode?.data?.actionModel;
  const connectionModel = actionNode?.data?.connectionModel;
  const subscription = connectionModel?.subscription;
  const isSmpAction = actionNode?.data.isSmpAction;
  const isConditionalBranchAction = actionNode?.data.actionModel?.type === 'IF_ACTION';

  const { data: subscriptionConnection } = useGetSubscriptionConnection(connectionModel?.subscription.id ?? '', {
    enabled: !!connectionModel?.subscription.id,
  });
  const { data: smpConnection } = useGetSMPConnections(tenantId as string, {
    enabled: isSmpAction,
  });

  let connectorAction;
  if (isSmpAction) {
    connectorAction = smpConnection?.connector.actions.find(action => action.method === actionModel?.actionMethod);
  } else if (isConditionalBranchAction) {
    connectorAction = new ConnectorActionModel({
      description: t('Workflow.action.condition_description'),
      inputParameters: [
        {
          name: 'expr',
          displayName: t('Workflow.action.condition_expr'),
          inputType: 'BOOLEAN',
        },
      ],
    });
  } else {
    connectorAction = subscriptionConnection?.connection.connector.actions.find(
      action => action.method === actionModel?.actionMethod,
    );
  }

  const handleChangeCheckbox = (checked: boolean, argIndex: number) => {
    if (!workflowDetailStore.checkEditable() || !actionModel?.actionArguments?.[argIndex]) return;

    actionModel.actionArguments[argIndex].expressionType = checked ? 'CUSTOM' : 'SIMPLE';
  };

  const handleChangeTextfieldParam = (event: React.ChangeEvent<HTMLInputElement>, argIndex: number) => {
    if (!workflowDetailStore.checkEditable() || !actionModel?.actionArguments?.[argIndex]) return;

    actionModel.actionArguments[argIndex].expression = event.target.value;
  };

  const handleClickInputDataPopup = (
    type: 'action' | 'trigger',
    field: string,
    displayName: string,
    argIndex: number,
  ) => {
    if (!workflowDetailStore.checkEditable() || !actionModel?.actionArguments) return;

    if (type === 'trigger') {
      actionModel.actionArguments[argIndex].expression = insertAt(
        actionModel.actionArguments[argIndex].expression ?? '',
        showData.cursorPos,
        `#{${field}}`,
      );
    }

    // 선택 시 포커스 이동
    if (focusedInputRef.current) {
      focusedInputRef.current.focus();
    }
  };

  const handleCloseUnputDataPopup = () => {
    setShowData({ isShow: false, index: undefined });
  };

  const renderConfigTitle = () => {
    if (isSmpAction) {
      return (
        <>
          <SmpColorIcon width={56} height={56} />
          <AppInfo>
            <SmpApp>SMP</SmpApp>
          </AppInfo>
        </>
      );
    }
    if (isConditionalBranchAction) {
      return (
        <>
          <Avatar sx={{ width: '56px', height: '56px' }}> IF</Avatar>
          <AppInfo />
        </>
      );
    }
    return (
      <>
        <Avatar src={subscription?.product.iconUrl} sx={{ width: '56px', height: '56px' }} />
        <AppInfo>
          <AppName>{subscription?.product.name}</AppName>
          <SubscriptionName>{subscription?.name}</SubscriptionName>
        </AppInfo>
      </>
    );
  };

  return (
    <Container>
      <ToolbarName>{t('Workflow_Create_24', { name: actionModel?.actionName })}</ToolbarName>
      <App>{renderConfigTitle()}</App>
      <Body>
        {!!connectorAction?.description && <ActionDescription>{connectorAction.description}</ActionDescription>}
        {
          // inputParameter가 없는 경우
          connectorAction?.inputParameters.length === 0 && (
            <EmptyInputParam>
              <EmptyInputIcon>
                <ErrorIcon color={theme.colors['ic-purple-light']} />
              </EmptyInputIcon>
              <EmptyInputDescription>
                <span>{t('Workflow_Create_40')}</span>
                <EmptyInputDescriptionSub>{t('Workflow_Create_41')}</EmptyInputDescriptionSub>
              </EmptyInputDescription>
            </EmptyInputParam>
          )
        }
        <InputParams>
          {connectorAction &&
            connectorAction.inputParameters.map(param => {
              const argIndex = actionModel?.actionArguments?.findIndex(arg => arg.argumentName === param.name) ?? 0;

              return (
                <InputParam key={param.name}>
                  <InputParamHeader>
                    <InputParamLabel>
                      {param.displayName || param.name}
                      <InputParamLabelStar>*</InputParamLabelStar>
                    </InputParamLabel>
                    <InputParamCustom>
                      <Checkbox
                        icon={<CheckboxIcon width={16} height={16} color={theme.colors['border-gray-light']} />}
                        checkedIcon={
                          <CheckboxIcon
                            width={16}
                            height={16}
                            color={theme.colors['ic-white']}
                            bgColor={theme.colors['bg-purple']}
                          />
                        }
                        checked={actionModel?.actionArguments?.[argIndex]?.expressionType === 'CUSTOM'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          if (checked) {
                            setShowData(prev => {
                              return { ...prev, isShow: false };
                            });
                          }
                          handleChangeCheckbox(checked, argIndex);
                        }}
                        disabled={param.inputType === 'ARRAY'}
                      />
                      <span>{t('Workflow_Create_37')}</span>
                    </InputParamCustom>
                  </InputParamHeader>
                  <TextField
                    value={actionModel?.actionArguments?.[argIndex]?.expression ?? ''}
                    fullWidth
                    multiline
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeTextfieldParam(event, argIndex)
                    }
                    onFocus={e => {
                      focusedInputRef.current = e.target;
                    }}
                    // NOTE. 문자열의 커서 위치가 변경된 경우 처리
                    onSelect={() => {
                      // NOTE. 수정 모드인 경우에만 입력데이터 표시
                      if (!workflowDetailStore.checkEditable()) return;
                      setShowData({
                        isShow: true,
                        index: argIndex,
                        cursorPos: focusedInputRef.current?.selectionStart ?? undefined,
                      });
                    }}
                    autoComplete="off"
                    InputProps={{
                      // NOTE. 수정 모드가 아닌 경우, readOnly 모드
                      readOnly: !workflowDetailStore.checkEditable(),
                    }}
                  />
                </InputParam>
              );
            })}
          {showData.isShow && (
            <InputDataPopup
              triggerId={workflowDetailStore.triggerNode?.data.baseTriggerModel?.id}
              onClick={handleClickInputDataPopup}
              onClose={handleCloseUnputDataPopup}
              index={showData.index as number}
            />
          )}
        </InputParams>
      </Body>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: inherit;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  border: 0px;
  padding: 28px 0 20px;
  position: relative;
`;

const ToolbarName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 18px;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const App = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 2px 0;

  & .MuiAvatar-root {
    border-radius: 8px;
  }
`;

const AppInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmpApp = styled.span`
  ${fonts.Headline4};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const AppName = styled.div`
  ${fonts.Body1};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const SubscriptionName = styled.strong`
  ${fonts.Headline4};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const ActionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['bg-purple-lighter']};
  padding: 18px 24px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-purple']};
  margin-bottom: 12px;
`;

const EmptyInputParam = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  height: 230px;
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
  margin-top: 12px;
`;

const EmptyInputIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: ${props => props.theme.colors['bg-purple-lighter']};
`;

const EmptyInputDescription = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${fonts.Body2};
`;

const EmptyInputDescriptionSub = styled.span`
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;

const InputParams = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 24px;
`;

const InputParam = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 6px;
`;

const InputParamHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InputParamLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${fonts.Headline8};
  gap: 4px;
`;

const InputParamLabelStar = styled.span`
  color: ${props => props.theme.colors['text-red-light']};
`;

const InputParamCustom = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  ${fonts.Body2};
`;
