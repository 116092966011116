export const ObjectUtil = {
  /**
   * src로 dst를 업데이트하는 함수. 존재하는 필드만 update한다.
   * null, undefined로 덮어쓸 수 있으므로 주의
   * dst의 값을 덮어쓰는 것이 목적이기 때문에 no-param-reassign disable시킴
   * @param dst 덮어쓸 객체
   * @param src 덮어쓸 값을 가지고 있는 객체
   */
  update: (dst: any, src: any) => {
    Object.keys(src).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(dst, key)) {
        // eslint-disable-next-line no-param-reassign
        dst[key] = src[key];
      }
    });
  },

  /**
   * Object.keys와 동일한 기능. Key가 literal type인 경우 해당 type으로 return해줌
   * @param src Object
   * @returns array of string/literal
   */
  keys: <T extends string>(src: { [key in T]?: unknown }): T[] => {
    try {
      return Object.keys(src) as T[];
    } catch {
      return [];
    }
  },

  /**
   * 객체에서 빈 배열, falsy를 제거한 후 반환
   * @param src Object
   * @return 빈 배열, falsy가 제거된 객체
   */
  removeEmpty: <T extends UnknownObject>(src: T) => {
    try {
      const returnVal: T = {} as T;

      Object.keys(src).forEach(key => {
        const value = src[key];
        if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value)) {
          returnVal[key] = value;
        }
      });

      return returnVal;
    } catch {
      return {} as T;
    }
  },
};
