import React, { useState, useMemo, useEffect } from 'react';
import { TextField, useTheme } from '@mui/material';
import debounce from 'lodash.debounce';
import { SearchIcon, CloseIcon } from '@icons/index';
import * as S from './SearchTextFieldStyles';

interface SearchTextFieldProps {
  placeholder?: string;
  value?: string | null;
  onChange?: (value: string) => void;
  debounce?: number;
  className?: string;
  onSearch?: (value: string) => void;
}

const SearchTextField = ({
  debounce: debounceDelay = 2000,
  value,
  onChange,
  className,
  placeholder = 'Search',
  onSearch,
}: SearchTextFieldProps) => {
  const [inputText, setInputText] = useState(value ?? '');
  const theme = useTheme();
  useEffect(() => setInputText(value ?? ''), [value]);

  const debounceOnChange = useMemo(
    () =>
      debounce(value => {
        if (typeof onChange === 'function') {
          onChange(value);
        }
      }, debounceDelay),
    [debounceDelay, onChange],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputText(event.target.value);
    debounceOnChange(event.target.value);
  };

  const handleClearClick = () => {
    setInputText('');
    if (typeof onChange === 'function') {
      onChange('');
    }
  };

  const handleSearchClick = () => {
    if (typeof onSearch === 'function') {
      debounceOnChange.cancel();
      onSearch(inputText);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && typeof onSearch === 'function') {
      debounceOnChange.cancel();
      onSearch(inputText);
    }
  };

  return (
    <TextField
      className={className}
      size="small"
      variant="outlined"
      value={inputText}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        startAdornment: (
          <S.InputIcon position="start" sx={{ cursor: 'pointer' }} onClick={handleSearchClick}>
            <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
          </S.InputIcon>
        ),
        endAdornment:
          inputText.length > 0 ? (
            <S.InputIcon position="end" onClick={handleClearClick}>
              <CloseIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
            </S.InputIcon>
          ) : null,
      }}
    />
  );
};

export default SearchTextField;
