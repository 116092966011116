import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Drawer } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: 100%;
  }
`;

export const MGnbWrap = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const MGnbHeader = styled('header')`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px 0 24px;
  position: relative;
`;

export const MGnbListWrap = styled('ul')`
  padding: 0 24px;
`;
export const MGnbListItem = styled('li')`
  padding: 20px 0 17px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  cursor: pointer;
  & span {
    ${fonts.Headline6};
    color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  }
`;
export const MGnbLogoLinkWrap = styled(Link)`
  height: 22px;
  display: inline-block;
  text-decoration: none;
`;

export const IntroductionBtn = styled(Button)`
  width: 224px;
  height: 48px;
  border-radius: 83px;
  margin: 16px auto 20px;
  border: 0;
  background: ${({ theme }) => theme.colors['border-gradient']};
  border-image-slice: 1;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  z-index: 1;
  color: #fff;
  flex-shrink: 0;
  & span {
    ${fonts.Button2};
  }
  &.fixed {
    position: fixed;
    left: 50%;
    bottom: 0;
    margin: 0;
    padding: 6px 40px;
    transform: translate(-50%, -40px);
  }
`;

export const Languages = styled.ul`
  position: absolute;
  right: 69px;
  top: 60px;
  width: 113px;
  height: fit-content;
  padding: 6px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 2px;
  background-color: ${props => props.theme.colors['bg-white']};
  z-index: 1002;
`;

export const Language = styled.li`
  display: flex;
  flex-direction: row;
  padding: 6px 0px 6px 7px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  &.ko,
  &.en {
    background-color: ${({ theme }) => theme.colors['state-white-purple-pressed']};
  }
  &:hover {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors['state-white-purple-hover']};
  }
  & > span {
    ${fonts.Body1};
    color: ${({ theme }) => theme.colors['text-gray-main']};
    &.ko,
    &.en {
      ${fonts.Headline7};
      color: ${({ theme }) => theme.colors['text-purple-light']};
    }
  }
`;
export const Dimmed = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
`;
