import type { ReactNode } from 'react';
import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';

type OpenParams = {
  title?: string;
  message?: ReactNode;
  confirmName?: string;
  cancelName?: string;
  useCancel?: boolean;
  onConfirm?: (data?: unknown) => void;
  type?: 'error' | 'success';
};

export class AlertStore {
  private _visible: boolean;

  private _title: string;

  private _message?: ReactNode;

  private _confirmName: string;

  private _cancelName: string;

  private _onConfirm?: (data?: unknown) => void;

  private _useCancel?: boolean;

  private _type?: 'error' | 'success';

  constructor() {
    this._visible = false;
    this._title = 'Title';
    this._message = undefined;
    this._confirmName = i18next.t('Member_User_24');
    this._cancelName = i18next.t('Subscrib_Detail_BuyRequest_13');
    this._onConfirm = undefined;
    this._useCancel = false;
    this._type = 'success';
    makeAutoObservable(this);
  }

  get cancelName() {
    return this._cancelName;
  }

  get useCancel() {
    return this._useCancel;
  }

  get visible() {
    return this._visible;
  }

  get title() {
    return this._title;
  }

  get message() {
    return this._message;
  }

  get confirmName() {
    return this._confirmName;
  }

  get type() {
    return this._type;
  }

  open = (data?: OpenParams) => {
    this._visible = true;
    this._title = data?.title || 'Title';
    this._message = data?.message;
    this._confirmName = data?.confirmName || i18next.t('Member_User_24');
    this._cancelName = data?.cancelName || i18next.t('Subscrib_Detail_BuyRequest_13');
    this._onConfirm = data?.onConfirm;
    this._useCancel = data?.useCancel;
    this._type = data?.type || 'success';
  };

  close = () => {
    this.clear();
  };

  onConfirm = () => {
    const alertOnConfirm = this._onConfirm;
    this.close();
    if (alertOnConfirm) {
      alertOnConfirm();
    }
  };

  private readonly clear = () => {
    this._visible = false;
    this._message = undefined;
    this._title = 'Title';
    this._confirmName = i18next.t('Member_User_24');
    this._cancelName = i18next.t('Subscrib_Detail_BuyRequest_13');
    this._onConfirm = undefined;
    this._useCancel = false;
  };
}
