const defaultWidth = 40;
const defaultHeight = 40;

export const IllustSchedule = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path d="M8 12C8 10.8954 8.89543 10 10 10H29C30.1046 10 31 10.8954 31 12V17H8V12Z" fill="#E1E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7002 12.0002C7.7002 10.4538 8.9538 9.2002 10.5002 9.2002H29.5002C31.0466 9.2002 32.3002 10.4538 32.3002 12.0002V30.0002C32.3002 31.5466 31.0466 32.8002 29.5002 32.8002H10.5002C8.9538 32.8002 7.7002 31.5466 7.7002 30.0002V12.0002ZM10.5002 10.8002C9.83745 10.8002 9.3002 11.3375 9.3002 12.0002V30.0002C9.3002 30.6629 9.83745 31.2002 10.5002 31.2002H29.5002C30.1629 31.2002 30.7002 30.6629 30.7002 30.0002V12.0002C30.7002 11.3375 30.1629 10.8002 29.5002 10.8002H10.5002Z"
          fill="#24292E"
        />
        <path
          d="M13.5 8.5C13.5 7.94772 13.9477 7.5 14.5 7.5H15.5C16.0523 7.5 16.5 7.94772 16.5 8.5V11.5C16.5 12.0523 16.0523 12.5 15.5 12.5H14.5C13.9477 12.5 13.5 12.0523 13.5 11.5V8.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7002 8.5002C12.7002 7.50608 13.5061 6.7002 14.5002 6.7002H15.5002C16.4943 6.7002 17.3002 7.50608 17.3002 8.5002V11.5002C17.3002 12.4943 16.4943 13.3002 15.5002 13.3002H14.5002C13.5061 13.3002 12.7002 12.4943 12.7002 11.5002V8.5002ZM14.5002 8.3002C14.3897 8.3002 14.3002 8.38974 14.3002 8.5002V11.5002C14.3002 11.6107 14.3897 11.7002 14.5002 11.7002H15.5002C15.6107 11.7002 15.7002 11.6107 15.7002 11.5002V8.5002C15.7002 8.38974 15.6107 8.3002 15.5002 8.3002H14.5002Z"
          fill="#24292E"
        />
        <path
          d="M12.5 20.5C12.5 19.9477 12.9477 19.5 13.5 19.5H14.5C15.0523 19.5 15.5 19.9477 15.5 20.5V21.5C15.5 22.0523 15.0523 22.5 14.5 22.5H13.5C12.9477 22.5 12.5 22.0523 12.5 21.5V20.5Z"
          fill="#E1E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9004 20.5004C11.9004 19.6167 12.6167 18.9004 13.5004 18.9004H14.5004C15.384 18.9004 16.1004 19.6167 16.1004 20.5004V21.5004C16.1004 22.384 15.384 23.1004 14.5004 23.1004H13.5004C12.6167 23.1004 11.9004 22.384 11.9004 21.5004V20.5004ZM13.5004 20.1004C13.2795 20.1004 13.1004 20.2795 13.1004 20.5004V21.5004C13.1004 21.7213 13.2795 21.9004 13.5004 21.9004H14.5004C14.7213 21.9004 14.9004 21.7213 14.9004 21.5004V20.5004C14.9004 20.2795 14.7213 20.1004 14.5004 20.1004H13.5004Z"
          fill="#24292E"
        />
        <path
          d="M18.5 20.5C18.5 19.9477 18.9477 19.5 19.5 19.5H20.5C21.0523 19.5 21.5 19.9477 21.5 20.5V21.5C21.5 22.0523 21.0523 22.5 20.5 22.5H19.5C18.9477 22.5 18.5 22.0523 18.5 21.5V20.5Z"
          fill="#E1E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9004 20.5004C17.9004 19.6167 18.6167 18.9004 19.5004 18.9004H20.5004C21.384 18.9004 22.1004 19.6167 22.1004 20.5004V21.5004C22.1004 22.384 21.384 23.1004 20.5004 23.1004H19.5004C18.6167 23.1004 17.9004 22.384 17.9004 21.5004V20.5004ZM19.5004 20.1004C19.2795 20.1004 19.1004 20.2795 19.1004 20.5004V21.5004C19.1004 21.7213 19.2795 21.9004 19.5004 21.9004H20.5004C20.7213 21.9004 20.9004 21.7213 20.9004 21.5004V20.5004C20.9004 20.2795 20.7213 20.1004 20.5004 20.1004H19.5004Z"
          fill="#24292E"
        />
        <path
          d="M24.5 20.5C24.5 19.9477 24.9477 19.5 25.5 19.5H26.5C27.0523 19.5 27.5 19.9477 27.5 20.5V21.5C27.5 22.0523 27.0523 22.5 26.5 22.5H25.5C24.9477 22.5 24.5 22.0523 24.5 21.5V20.5Z"
          fill="#E1E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9004 20.5004C23.9004 19.6167 24.6167 18.9004 25.5004 18.9004H26.5004C27.384 18.9004 28.1004 19.6167 28.1004 20.5004V21.5004C28.1004 22.384 27.384 23.1004 26.5004 23.1004H25.5004C24.6167 23.1004 23.9004 22.384 23.9004 21.5004V20.5004ZM25.5004 20.1004C25.2795 20.1004 25.1004 20.2795 25.1004 20.5004V21.5004C25.1004 21.7213 25.2795 21.9004 25.5004 21.9004H26.5004C26.7213 21.9004 26.9004 21.7213 26.9004 21.5004V20.5004C26.9004 20.2795 26.7213 20.1004 26.5004 20.1004H25.5004Z"
          fill="#24292E"
        />
        <path
          d="M12.5 26.5C12.5 25.9477 12.9477 25.5 13.5 25.5H14.5C15.0523 25.5 15.5 25.9477 15.5 26.5V27.5C15.5 28.0523 15.0523 28.5 14.5 28.5H13.5C12.9477 28.5 12.5 28.0523 12.5 27.5V26.5Z"
          fill="#E1E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9004 26.5004C11.9004 25.6167 12.6167 24.9004 13.5004 24.9004H14.5004C15.384 24.9004 16.1004 25.6167 16.1004 26.5004V27.5004C16.1004 28.384 15.384 29.1004 14.5004 29.1004H13.5004C12.6167 29.1004 11.9004 28.384 11.9004 27.5004V26.5004ZM13.5004 26.1004C13.2795 26.1004 13.1004 26.2795 13.1004 26.5004V27.5004C13.1004 27.7213 13.2795 27.9004 13.5004 27.9004H14.5004C14.7213 27.9004 14.9004 27.7213 14.9004 27.5004V26.5004C14.9004 26.2795 14.7213 26.1004 14.5004 26.1004H13.5004Z"
          fill="#24292E"
        />
        <path
          d="M18.5 26.5C18.5 25.9477 18.9477 25.5 19.5 25.5H20.5C21.0523 25.5 21.5 25.9477 21.5 26.5V27.5C21.5 28.0523 21.0523 28.5 20.5 28.5H19.5C18.9477 28.5 18.5 28.0523 18.5 27.5V26.5Z"
          fill="#E1E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9004 26.5004C17.9004 25.6167 18.6167 24.9004 19.5004 24.9004H20.5004C21.384 24.9004 22.1004 25.6167 22.1004 26.5004V27.5004C22.1004 28.384 21.384 29.1004 20.5004 29.1004H19.5004C18.6167 29.1004 17.9004 28.384 17.9004 27.5004V26.5004ZM19.5004 26.1004C19.2795 26.1004 19.1004 26.2795 19.1004 26.5004V27.5004C19.1004 27.7213 19.2795 27.9004 19.5004 27.9004H20.5004C20.7213 27.9004 20.9004 27.7213 20.9004 27.5004V26.5004C20.9004 26.2795 20.7213 26.1004 20.5004 26.1004H19.5004Z"
          fill="#24292E"
        />
        <path
          d="M24.5 26.5C24.5 25.9477 24.9477 25.5 25.5 25.5H26.5C27.0523 25.5 27.5 25.9477 27.5 26.5V27.5C27.5 28.0523 27.0523 28.5 26.5 28.5H25.5C24.9477 28.5 24.5 28.0523 24.5 27.5V26.5Z"
          fill="#E1E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9004 26.5004C23.9004 25.6167 24.6167 24.9004 25.5004 24.9004H26.5004C27.384 24.9004 28.1004 25.6167 28.1004 26.5004V27.5004C28.1004 28.384 27.384 29.1004 26.5004 29.1004H25.5004C24.6167 29.1004 23.9004 28.384 23.9004 27.5004V26.5004ZM25.5004 26.1004C25.2795 26.1004 25.1004 26.2795 25.1004 26.5004V27.5004C25.1004 27.7213 25.2795 27.9004 25.5004 27.9004H26.5004C26.7213 27.9004 26.9004 27.7213 26.9004 27.5004V26.5004C26.9004 26.2795 26.7213 26.1004 26.5004 26.1004H25.5004Z"
          fill="#24292E"
        />
        <path
          d="M23.5 8.5C23.5 7.94772 23.9477 7.5 24.5 7.5H25.5C26.0523 7.5 26.5 7.94772 26.5 8.5V11.5C26.5 12.0523 26.0523 12.5 25.5 12.5H24.5C23.9477 12.5 23.5 12.0523 23.5 11.5V8.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7002 8.5002C22.7002 7.50608 23.5061 6.7002 24.5002 6.7002H25.5002C26.4943 6.7002 27.3002 7.50608 27.3002 8.5002V11.5002C27.3002 12.4943 26.4943 13.3002 25.5002 13.3002H24.5002C23.5061 13.3002 22.7002 12.4943 22.7002 11.5002V8.5002ZM24.5002 8.3002C24.3897 8.3002 24.3002 8.38974 24.3002 8.5002V11.5002C24.3002 11.6107 24.3897 11.7002 24.5002 11.7002H25.5002C25.6107 11.7002 25.7002 11.6107 25.7002 11.5002V8.5002C25.7002 8.38974 25.6107 8.3002 25.5002 8.3002H24.5002Z"
          fill="#24292E"
        />
      </g>
    </svg>
  );
};
