import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';
import { DatePicker } from '@components/datePicker';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';

type Props = {
  selectedMonth: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  onNextStep: (month: Dayjs | null) => void;
  onClose: () => void;
};
export const BillingMonthSelect = ({ selectedMonth, onChange, onNextStep, onClose }: Props) => {
  const { t } = useTranslation();
  const handleNextStep = () => {
    onNextStep(selectedMonth);
  };

  return (
    <>
      <Content>
        <DescriptionWrapper>
          <Description>{t('Acc_Create_06_01')}</Description>
          <Description>{t('Acc_Create_06_02')}</Description>
        </DescriptionWrapper>
        <RequiredFieldKey htmlFor="billing-date">{t('Acc_Main_06')}</RequiredFieldKey>
        <DatePicker
          inputFormat={t('DateFormat_YM')}
          value={selectedMonth}
          onChange={onChange}
          views={['year', 'month']}
          openTo="month"
          renderInput={params => <DatePicker.Input {...params} id="billing-date" fullWidth />}
        />
      </Content>
      <Footer>
        <Button
          variant="contain"
          size="extraLarge"
          paddingHorizontal={99.5}
          disabled={!selectedMonth}
          onClick={handleNextStep}
        >
          {t('Acc_Create_37')}
        </Button>
        <Button variant="outline" size="extraLarge" paddingHorizontal={98.5} onClick={onClose}>
          {t('GNB_Profile_MyProfile_11')}
        </Button>
      </Footer>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 28px 16px 28px;
`;

const Footer = styled.div`
  display: flex;
  padding: 24px 0px 28px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 28px;
`;

const Description = styled.p`
  ${fonts.Body2}
  white-space: pre-wrap;

  & + & {
    margin-top: 2px;
  }
`;

const RequiredFieldKey = styled.label`
  display: inline-block;
  ${fonts.Headline8}
  margin-bottom: 6px;
  &:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  &.disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &:after {
      color: ${({ theme }) => theme.colors['text-red-lighter']};
    }
  }
`;
