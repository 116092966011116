import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export type FormUseRequest = {
  productPlanId: string;
  inquiry: string;
  endDate: string;
  startDate: string;
};

export const useFormUser = () => {
  const { t } = useTranslation();
  const validationUserSchema = Yup.object().shape({
    productPlanId: Yup.string().required(t('Ad_Store_Purchase_Request_02')),
    inquiry: Yup.string().required(t('Ad_Store_Purchase_Request_02')),
    startDate: Yup.string()
      .required(t('Ad_Store_Purchase_Request_02'))
      .nullable()
      .test('is-valid-startDate', t('Date_01'), value => !!(value && dayjs(value).isValid()))
      .when('endDate', {
        is: (endDateValue: string) => endDateValue !== undefined && endDateValue !== null,
        then: Yup.string()
          .test('is-vlaid-slow-startDate', t('Date_02'), (startDate, testContext) => {
            const startNumberValue = Number.parseInt(dayjs(startDate).format('YYYYMMDD'), 10);
            const endNumberValue = Number.parseInt(dayjs(testContext.parent.endDate).format('YYYYMMDD'), 10);
            if (startNumberValue < endNumberValue) return true;
            return !(endNumberValue && startNumberValue >= endNumberValue);
          })
          .nullable(),
      }),
    endDate: Yup.string()
      .nullable()
      .optional()
      .test('is-valid-endDate', t('Date_01'), value => !!value && dayjs(value).isValid()),
  });
  const userFormOptions: UseFormProps<FormUseRequest> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      inquiry: '',
      startDate: '',
      endDate: '',
      productPlanId: '',
    },
  };
  return useForm<FormUseRequest>(userFormOptions);
};
