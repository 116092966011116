import { t } from 'i18next';
import type { ModuleUsageCost } from '@repositories/monthlyBillingRepository';
import type { MetricUsageCost } from '@type/MonthlyBilling';
import type { UsageMetricModel } from '@models/softwareModels/UsageMetricModel';

export class MetricUsageCostModel {
  private readonly _dto: MetricUsageCost;

  constructor(dto: MetricUsageCost) {
    this._dto = dto;
  }

  get usageMetricCode(): string {
    return this._dto.usageMetricCode ?? '';
  }
  get usageMetricName(): string {
    return this._dto.usageMetricName ?? '';
  }

  get usage(): number | undefined {
    return this._dto.usage;
  }
  get usageUnit(): string | undefined {
    return this._dto.usageUnit;
  }
  get price(): number | undefined {
    return this._dto.price;
  }
  get cost(): number {
    return this._dto.cost ?? 0;
  }
  get originalCost(): number | undefined {
    return this._dto.originalCost;
  }

  static createFromCost = (moduleUsageCosts: ModuleUsageCost[], usageMetrics?: UsageMetricModel[]) => {
    return moduleUsageCosts.map(moduleUsageCost => {
      const metric = usageMetrics?.find(data => data.usageMetricCode === moduleUsageCost.moduleCode);
      let metricName = '-';
      if (metric?.usageMetricName) {
        metricName = metric.usageMetricName;
      } else if (moduleUsageCost.moduleCode === 'LICENSE') {
        metricName = `${t('Plan.pricingType.FLAT_RATE')}${
          moduleUsageCost.licenseName ? ` - ${moduleUsageCost.licenseName}` : ''
        }`;
      }
      return new MetricUsageCostModel({
        usageMetricCode: metric?.usageMetricCode ?? '-',
        usageMetricName: metricName,
        usage: moduleUsageCost.usage,
        usageUnit: metric?.usageUnit ?? '',
        price: moduleUsageCost.price,
        cost: moduleUsageCost?.cost,
        originalCost: moduleUsageCost?.originalCost,
      });
    });
  };

  static createFromMetric = (usageMetrics: UsageMetricModel[], moduleUsageCosts?: ModuleUsageCost[]) => {
    return usageMetrics.map(({ usageMetricCode, usageMetricName, usageUnit }) => {
      const usageMetricBilling = moduleUsageCosts?.find(({ moduleCode }) => moduleCode === usageMetricCode);
      return new MetricUsageCostModel({
        usageMetricCode,
        usageMetricName,
        usage: usageMetricBilling?.usage,
        usageUnit,
        price: usageMetricBilling?.price,
        cost: usageMetricBilling?.cost,
        originalCost: usageMetricBilling?.originalCost,
      });
    });
  };
}
