import { Modal, Typography } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as S from './AlertModalStyles';
import { Button } from '..';

export const AlertModal = observer(() => {
  const { t } = useTranslation();
  const {
    uiStore: { alertModalStore: modal },
  } = useStore();

  return (
    <Modal open={modal.visible}>
      <S.Container>
        <S.MessageWrapper>
          {modal.message.split('\n')?.map(msg => (
            <Typography key={msg} variant="headline6">
              {msg}
            </Typography>
          ))}
        </S.MessageWrapper>
        <Button variant="contain" onClick={modal.close} size="large" autoFocus>
          <Typography variant="button1" color="white">
            {t('Member_User_24')}
          </Typography>
        </Button>
      </S.Container>
    </Modal>
  );
});
