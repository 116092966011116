import { UseFormProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SubscriptionUserUpdateRq } from '@repositories/subscriptionRepository';

export const useSubscriptionUserForm = () => {
  const validationSubscriptionUserSchema = Yup.object().shape({
    memo: Yup.string().max(5000).optional(),
  });

  const subscriptionUserFormOptions: UseFormProps<SubscriptionUserUpdateRq> = {
    resolver: yupResolver(validationSubscriptionUserSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
  };
  return useForm<SubscriptionUserUpdateRq>(subscriptionUserFormOptions);
};
