import type ITechnicalSupportRepository from '../ITechnicalSupportRepository';
import type {
  TechnicalInquiryListFilter,
  TechnicalInquiryCountStatDTO,
  TechnicalInquiryUpdateDTO,
  TechnicalInquiryListDTO,
  TechnicalInquiryCreateDTO,
  TechnicalInquiryDTO,
  TechnicalInquiryHistoryDTO,
  TechnicalInquiryHistoryCreateDTO,
} from '../technicalSupport.types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import type { Page } from '@type/Page';

@injectable()
export class TechnicalSupportRepository implements ITechnicalSupportRepository {
  async getTechnicalInquiryList(queries?: TechnicalInquiryListFilter): Promise<Page<TechnicalInquiryListDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<TechnicalInquiryListDTO>>(`api/v1/technical-inquiries?${queryString}`);
    return result.data;
  }

  async getMyTechnicalInquiryList(queries?: TechnicalInquiryListFilter): Promise<Page<TechnicalInquiryListDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<TechnicalInquiryListDTO>>(`api/v1/my-technical-inquiries?${queryString}`);
    return result.data;
  }

  async getTechnicalInquiryCountStat(): Promise<TechnicalInquiryCountStatDTO> {
    const result = await API.get<TechnicalInquiryCountStatDTO>(`api/v1/technical-inquiry-count-stat`);
    return result.data;
  }
  async createTechnicalInquiry(data: TechnicalInquiryCreateDTO): Promise<TechnicalInquiryDTO> {
    const result = await API.post<TechnicalInquiryDTO>(`api/v1/technical-inquiries`, data);
    return result.data;
  }

  async getTechnicalDetail(technicalInquiryId: string): Promise<TechnicalInquiryDTO> {
    const result = await API.get<TechnicalInquiryDTO>(`/api/v1/technical-inquiries/${technicalInquiryId}`);
    return result.data;
  }

  async patchTechnicalInquiryUpdate(
    technicalInquiryId: string,
    rqDate: TechnicalInquiryUpdateDTO,
  ): Promise<TechnicalInquiryDTO> {
    const result = await API.patch<TechnicalInquiryDTO>(`/api/v1/technical-inquiries/${technicalInquiryId}`, rqDate);
    return result.data;
  }

  async deleteTechnicalInquiry(technicalInquiryId: string): Promise<void> {
    await API.delete<void>(`/api/v1/technical-inquiries/${technicalInquiryId}`);
  }
  async createTechnicalInquiryHistory(data: TechnicalInquiryHistoryCreateDTO): Promise<TechnicalInquiryHistoryDTO> {
    const result = await API.post<TechnicalInquiryHistoryDTO>(
      `/api/v1/technical-inquiries/${data.technicalInquiryId}/technical-inquiry-histories`,
      data,
    );
    return result.data;
  }
}
