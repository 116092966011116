import { InOutType, InputParameter } from '@repositories/subscriptionConnectionRepository/Types';
import { ExpressionType } from '@repositories/workflowRepository/Types';
import { MapInputData, SimpleInputData } from '@type/Common';

export class InputParameterModel {
  private readonly _description: string;
  private readonly _inputType: InOutType;
  private readonly _name: string;
  private readonly _displayName: string;
  private readonly _isRequired: boolean;
  private readonly _jsonSchema: string;
  private _value: SimpleInputData;
  private _jsonSchemaValue: MapInputData;
  private _expressionType: ExpressionType;

  constructor(dto: InputParameter) {
    this._description = dto.description || '';
    this._inputType = dto.inputType || 'STRING';
    this._name = dto.name || '';
    this._displayName = dto.displayName || '';
    this._isRequired = dto.isRequired || false;
    this._jsonSchema = dto.jsonSchema || '';
    this._value = dto.value || {
      format: '',
    };
    this._jsonSchemaValue = {
      actionSource: {
        step: -1,
      },
      inputList: [],
    };
    this._expressionType = dto.expressionType || 'SIMPLE';
  }

  get expressionType() {
    return this._expressionType;
  }

  setExpressionType(type: ExpressionType) {
    this._expressionType = type;
  }

  get jsonSchemaValue() {
    return this._jsonSchemaValue;
  }

  setJsonSchemaValue(value: MapInputData) {
    this._jsonSchemaValue = value;
  }

  get value() {
    return this._value;
  }

  get description() {
    return this._description;
  }

  get inputType() {
    return this._inputType;
  }

  get name() {
    return this._name;
  }

  get displayName() {
    return this._displayName;
  }

  get isRequired() {
    return this._isRequired;
  }

  get jsonSchema() {
    return this._jsonSchema;
  }

  setValue(value: SimpleInputData) {
    this._value = value;
  }
}
