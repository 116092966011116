import { forwardRef } from 'react';

const defaultWidth = 18;
const defaultHeight = 18;

export const DangerFillIcon = forwardRef<SVGSVGElement, SvgProps>(
  ({ width = defaultWidth, height = defaultHeight, color = '#EA4A5A', ...props }, ref) => (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.94255 1.04878C6.0621 0.929226 6.22425 0.862061 6.39333 0.862061H12.6065C12.7756 0.862061 12.9378 0.929226 13.0573 1.04878L17.4507 5.44218C17.5703 5.56173 17.6374 5.72388 17.6374 5.89296V12.1062C17.6374 12.2752 17.5703 12.4374 17.4507 12.5569L13.0573 16.9503C12.9378 17.0699 12.7756 17.1371 12.6065 17.1371H6.39333C6.22425 17.1371 6.0621 17.0699 5.94254 16.9503L1.54915 12.5569C1.42959 12.4374 1.36243 12.2752 1.36243 12.1062V5.89296C1.36243 5.72388 1.42959 5.56173 1.54915 5.44218L5.94255 1.04878Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.51167 4.42456C9.93278 4.42456 10.2742 4.76594 10.2742 5.18706V9.22382C10.2742 9.64494 9.93278 9.98633 9.51167 9.98633C9.09055 9.98633 8.74917 9.64494 8.74917 9.22382V5.18706C8.74917 4.76594 9.09055 4.42456 9.51167 4.42456ZM10.4984 12.5878C10.4984 13.1328 10.0566 13.5746 9.51167 13.5746C8.96669 13.5746 8.5249 13.1328 8.5249 12.5878C8.5249 12.0428 8.96669 11.601 9.51167 11.601C10.0566 11.601 10.4984 12.0428 10.4984 12.5878Z"
          fill="white"
        />
      </g>
    </svg>
  ),
);

DangerFillIcon.displayName = 'DangerFillIcon';
