import { makeAutoObservable } from 'mobx';
import type { Software } from '@repositories/softwareRepository';
import type { TenantList } from '@repositories/tenantRepository';
import type { User } from '@repositories/userRepository';
import { StatisticSearchFilter } from '@type/Statistics';

export class StatisticFilterUiStore {
  visible: boolean;

  onSelect: (selectedFilter: StatisticSearchFilter) => void;

  tenant?: TenantList;

  software?: Software;

  salesDepartment?: string;

  contractUser?: User;

  constructor() {
    this.visible = false;
    this.onSelect = () => null;
    this.tenant = undefined;
    this.software = undefined;
    this.salesDepartment = undefined;
    this.contractUser = undefined;

    makeAutoObservable(this);
  }

  open = (defaultFilter: StatisticSearchFilter & { year?: number }, onSelect?: typeof this.onSelect) => {
    this.visible = true;

    this.tenant = defaultFilter.tenant;
    this.software = defaultFilter.software;
    this.salesDepartment = defaultFilter.salesDepartment;
    this.contractUser = defaultFilter.contractUser;

    if (onSelect) {
      this.onSelect = onSelect;
    }
  };

  clear = () => {
    this.tenant = undefined;
    this.software = undefined;
    this.salesDepartment = undefined;
    this.contractUser = undefined;
  };

  close = () => {
    this.onSelect = () => null;

    this.tenant = undefined;
    this.software = undefined;
    this.salesDepartment = undefined;
    this.contractUser = undefined;

    this.visible = false;
  };

  get filter(): StatisticSearchFilter {
    return {
      tenant: this.tenant,
      software: this.software,
      contractUser: this.contractUser,
      salesDepartment: this.salesDepartment,
    };
  }
}
