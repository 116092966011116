import type { Swiper as SwiperType } from 'swiper';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Navigation } from 'swiper/modules';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { ErrorIcon } from '@icons/ErrorIcon';
import { ChevronIcon, SupportIcon } from '@icons/index';
import { useGetCategories } from '@queryHooks/useCategory';
import { useGetMySubscriptions } from '@queryHooks/useSubscription';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';
import { CategorySimpleModel } from '@models/category/CategorySimpleModel';
import * as S from './ProductsStyles';
import { TechnicalSupportCreateModal } from './modal/TechnicalSupportCreateModal';

interface Props {
  type?: 'Software' | 'Hardware';
}

export type TechnicalSupportModalOption = {
  isOpen: boolean;
  subscriptionId: string;
  tenantId: string;
};

export const Products = observer(({ type }: Props) => {
  const supportsCustomCategory = process.env.REACT_APP_CUSTOM_ROOT_CATEGORY?.toLowerCase() === 'true';
  const { t } = useTranslation();
  const theme = useTheme();
  const { rootCategoryId } = useParams();

  const { categoryStore } = useStore();
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState<Map<string, SubscriptionListModel[]>>(
    new Map<string, SubscriptionListModel[]>(),
  );

  const [categories, setCategories] = useState<CategorySimpleModel[]>([]);
  const [curCategory, setCurCategory] = useState<string>('all');
  const [technicalSupportModal, setTechnicalSupportModal] = useState<TechnicalSupportModalOption>({
    isOpen: false,
    subscriptionId: '',
    tenantId: '',
  });

  const { data: mySbs } = useGetMySubscriptions();
  const { data: rootCategories, isFetching } = useGetCategories({ isRootCategory: true });

  // NOTE. 카테고리 선택하지 않았을 때, 디폴트 카테고리 탭으로 이동
  useEffect(() => {
    if (rootCategoryId || type) return;
    if (!supportsCustomCategory) {
      navigate('software');
    } else if (!isFetching && rootCategories?.content?.[0].id) {
      navigate(rootCategories.content[0].id);
    }
  }, [isFetching, navigate, rootCategories, rootCategoryId, supportsCustomCategory, type]);

  useEffect(() => {
    const subscriptionMap = new Map<string, SubscriptionListModel[]>();

    const mySubscriptions =
      mySbs?.content.filter(sb => {
        const rootCategory = categoryStore.findRootCategory(sb.software.category.id);
        return rootCategory?.name === type || rootCategory?.id === rootCategoryId;
      }) || [];
    subscriptionMap.set('all', mySubscriptions);

    const deduplCategories = _.uniqBy(
      mySubscriptions?.map(pt => pt.software.category),
      'id',
    );

    deduplCategories.forEach(ct => {
      const subscriptionsOfCategory =
        mySubscriptions?.filter(subscription => subscription.software.category.id === ct.id) || [];
      subscriptionMap.set(ct.id, subscriptionsOfCategory);
    });

    setSubscriptions(subscriptionMap);
    deduplCategories.splice(0, 0, new CategorySimpleModel({ id: 'all', code: 'all', name: `${t('Acc_Main_12')}` }));

    setCategories(deduplCategories);
  }, [mySbs, type, rootCategoryId, t, categoryStore]);
  const swiperRef = useRef<SwiperType>();

  const colorList = [
    'text-blue-light',
    'text-green',
    'text-yellow',
    'text-orange',
    'text-red',
    'text-pink',
    'text-purple-light',
  ] as const;

  const makeColor = (str: string) => {
    const array = str.split('');
    const numberArray = array.map(char => char.charCodeAt(0));
    const sum = numberArray.reduce((acc, cur) => acc + cur, 0);
    const index = sum % 7;

    return colorList[index];
  };

  return mySbs ? (
    <S.Container>
      <S.Categories>
        <S.StyledSwiper
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          slidesPerView="auto"
          spaceBetween={4}
          modules={[Navigation]}
        >
          {categories.map(ct => {
            return (
              <S.StyledSwiperSlide key={ct.id}>
                <S.Button
                  variant="outline"
                  size="small"
                  paddingHorizontal={16}
                  className={curCategory === ct.id ? 'category-selected' : ''}
                  onClick={() => setCurCategory(ct.id)}
                  disabled={subscriptions.get(curCategory)?.length === 0}
                >
                  {ct.name}
                </S.Button>
              </S.StyledSwiperSlide>
            );
          })}
          <button className="arrow swiper-button-prev" type="button" onClick={() => swiperRef.current?.slidePrev()}>
            <span>
              <ChevronIcon width={16} height={16} color={theme.colors['ic-gray-dark']} rotateNum={-90} />
            </span>
          </button>
          <button className="arrow swiper-button-next" type="button" onClick={() => swiperRef.current?.slideNext()}>
            <span>
              <ChevronIcon width={16} height={16} color={theme.colors['ic-gray-dark']} rotateNum={90} />
            </span>
          </button>
        </S.StyledSwiper>
      </S.Categories>
      {subscriptions.get(curCategory)?.length ? (
        <S.Products>
          {subscriptions.get(curCategory)?.map(product => {
            return (
              <S.Product key={product.id} $categoryColor={theme.colors[makeColor(product.software.category.name)]}>
                <div className="logo">
                  <S.Logo src={product.software.thumbnailUrl} />
                  <S.StyledIconButton
                    variant="icon"
                    onClick={() =>
                      setTechnicalSupportModal({
                        isOpen: true,
                        subscriptionId: product.id,
                        tenantId: product.tenant.id,
                      })
                    }
                  >
                    <SupportIcon width={16} height={16} />
                  </S.StyledIconButton>
                </div>
                <span className="info">
                  <span className="category-name">{product.software.category.name}</span>
                  <span className="product-name">{product.software.name}</span>
                  <span className="info-title">{t('Subscrib_Main_12')}</span>
                  <span className="plan-name">{product.planName}</span>
                  <span className="info-title">{t('Subscrib_Detail_Overview_13')}</span>
                  <span className="duration">
                    {product.startDate} ~ {product.endDate}
                  </span>
                </span>
              </S.Product>
            );
          })}
        </S.Products>
      ) : (
        <S.NoProdcutContainer>
          <S.NoProduct>
            <S.Exclaim>
              <ErrorIcon width={22} height={22} color={theme.colors['ic-purple-light']} />
            </S.Exclaim>
            <strong className="main-text">{t('my_subscrib_03')}</strong>
            <span className="sub-text">{t('my_subscrib_04')}</span>
            <Button variant="contain" size="medium" onClick={() => navigate(`/store`)} paddingHorizontal={16}>
              {t('Ad_Cart_07')}
            </Button>
          </S.NoProduct>
        </S.NoProdcutContainer>
      )}
      {technicalSupportModal.isOpen && (
        <TechnicalSupportCreateModal
          modalOption={technicalSupportModal}
          onClose={() =>
            setTechnicalSupportModal({
              isOpen: false,
              subscriptionId: '',
              tenantId: '',
            })
          }
        />
      )}
    </S.Container>
  ) : null;
});
