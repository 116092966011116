import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Box,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { SearchIcon } from '@icons/index';
import { TenantMemberFilter } from '@repositories/tenantRepository';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import i18n from '@locales/i18n';
import { UserTableRow } from './UserTableRow';

type MenuAnchor = {
  register: null | HTMLElement;
  search: null | HTMLElement;
};

type SearchType = 'name' | 'email' | 'usergroup' | 'phone';

const ActiveUsers = observer(() => {
  const { t } = useTranslation();
  const tableHeader: TableHeader[] = [
    { name: t('Workflow_Main_05'), width: '198px', align: 'left' },
    { name: t('Member_User_14'), width: '300px', align: 'left' },
    { name: t('Member_User_Bulk_Create_18'), width: '220px', align: 'left' },
    { name: t('Member_User_16'), width: '180px', align: 'left' },
    { name: t('Member_User_17'), width: '220px', align: 'left' },
    { name: t('Member_User_18'), width: '120px', align: 'left' },
    { name: t('Workflow_Main_07'), width: '100px', align: 'left' },
    { name: t('Member_User_29'), width: '190px', align: 'left' },
    { name: t('Acc_Main_23'), width: '72px', align: 'center' },
  ];
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    authStore,
    uiStore: { userIndividualRegisterStore: userRegisterModal },
  } = useStore();
  const [anchorEl, setAnchorEl] = useState<MenuAnchor>({ register: null, search: null });
  const registerMenuOpen = Boolean(anchorEl.register);
  const [searchType, setSearchType] = useState<SearchType>('name');
  const [filters, setFilters] = useState<TenantMemberFilter>();
  const {
    data: pagedActiveMembers,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetTenantMembersInfinite(authStore.curTenant.id, {
    tenantMemberStatus: 'ACTIVE',
    ...filters,
  });
  const members = pagedActiveMembers?.pages.map(({ content }) => content).flat(1);

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const menuHandler = {
    registerOpen: (event: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget } = event;
      setAnchorEl(prev => {
        return { ...prev, register: currentTarget };
      });
    },
    registerClose: (event: React.MouseEvent<HTMLLIElement>) => {
      if (event.currentTarget.id === 'individual') {
        userRegisterModal.open();
      } else if (event.currentTarget.id === 'batch') {
        navigate(`/manager/${authStore.curTenant.id}/usergroups/memberMgmt/batch-register`);
      }
      setAnchorEl(prev => {
        return { ...prev, register: null };
      });
    },
  };

  const debounceOnChangeSearch = _.debounce((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (searchType === 'name') {
      setFilters(prev => {
        return { ...prev, name: e.target.value };
      });
    }
    if (searchType === 'email') {
      setFilters(prev => {
        return { ...prev, username: e.target.value };
      });
    }
  }, 500);

  return (
    <Container>
      <UserTableWrapper>
        <UserTableHeader>
          <StyledButton variant="contain" size="small" onClick={menuHandler.registerOpen} paddingHorizontal={16}>
            {t('Member_User_12')}
            <ChevronIcon
              width={18}
              height={18}
              color={theme.colors['ic-white']}
              rotateNum={registerMenuOpen ? 0 : 180}
            />
          </StyledButton>
          <UserRegisterMenu
            anchorEl={anchorEl.register}
            open={registerMenuOpen}
            onClose={menuHandler.registerClose}
            PaperProps={{
              sx: {
                minWidth: '115px',
              },
            }}
            sx={{
              minWidth: '115px',
              marginTop: '4px',
              '& .MuiList-root': {
                minWidth: '115px',
              },
            }}
          >
            <UserRegisterMenuItem id="individual" onClick={menuHandler.registerClose}>
              {t('Member_User_26')}
            </UserRegisterMenuItem>
            <UserRegisterMenuItem id="batch" onClick={menuHandler.registerClose}>
              {t('Member_User_27')}
            </UserRegisterMenuItem>
          </UserRegisterMenu>
          <Search>
            <Select
              size="small"
              value={searchType}
              onChange={e => setSearchType(e.target.value as SearchType)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxWidth: i18n.language === 'en' ? '93px' : '79px',
                    width: '100%',
                    marginTop: '5px',
                    marginLeft: i18n.language === 'en' ? '0px' : '1px',
                  },
                },
              }}
            >
              <MenuItem value="name">{t('Workflow_Main_05')}</MenuItem>
              <MenuItem value="email">{t('Member_User_14')}</MenuItem>
              <MenuItem value="usergroup">{t('Member_User_Bulk_Create_18')}</MenuItem>
              <MenuItem value="phone">{t('Member_User_16')}</MenuItem>
            </Select>
            <StyledTextField
              fullWidth
              size="small"
              placeholder={t('Member_User_08')}
              onChange={e => debounceOnChangeSearch(e)}
              InputProps={{
                startAdornment: (
                  <IconWrapper>
                    <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                  </IconWrapper>
                ),
              }}
            />
          </Search>
        </UserTableHeader>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map(header => {
                  return (
                    <StyledTableCell key={header.name} width={header.width} align={header.align}>
                      {header.name}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {members?.map(member => {
                return <UserTableRow key={member.id} rowData={member} type="Active" />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {hasNextPage && <div ref={ref} />}
      </UserTableWrapper>
    </Container>
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const UserTableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: fit-content;
`;

const UserTableHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Search = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  margin-left: auto;
`;

const UserRegisterMenu = styled(Menu)`
  & > div > ul {
    padding: 5px 4px;
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    border-radius: 3px;
  }
`;

const UserRegisterMenuItem = styled(MenuItem)`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  width: 100%;
  padding: 10px 11px;
  display: flex;
  align-items: center;
  :hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-hover']};
  }
`;
const StyledTextField = styled(TextField)`
  width: 250px;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const StyledTableCell = styled(TableCell)`
  &:last-of-type {
    text-align: center;
  }
`;

const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default ActiveUsers;
