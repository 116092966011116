import { useRef } from 'react';

export const useScrollToBottom = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  return { scrollRef, scrollToBottom };
};
