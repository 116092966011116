import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useGetTenantBillingsInfinite } from '@queryHooks/useBilling';
import { useGetMonthlyBillingListInfinite } from '@queryHooks/useMonthlyBilling';
import { WgContainer, WgTitle } from '../styles';
import { EmptyPayment } from './EmptyPayment';
import { Payment } from './Payment';
import { Total } from './RecentPaymentWg';

type Filter = {
  sort?: SortString[];
  monthFrom?: DateTimeString | null;
  monthTo?: DateTimeString | null;
};

export const NextPaymentWg = () => {
  const { t } = useTranslation();
  // 초기 날짜 설정
  const initialFilter = {
    sort: [],
    monthFrom: dayjs().format('YYYY-MM'),
    monthTo: dayjs().format('YYYY-MM'),
  };

  const [queries] = useSearchParamsFilter<Filter>({
    defaultFilter: initialFilter,
    whiteList: ['sort'],
  });

  const { data: tenantBillings, isSuccess: isTenantBillingSuccess } = useGetTenantBillingsInfinite({
    sort: ['month.desc'],
    ...queries,
  });

  const { data: subscriptionBillings, isSuccess: isSubscriptionBillingSuccess } = useGetMonthlyBillingListInfinite({
    planScope: 'SUBSCRIPTION',
    sort: ['month.desc'],
    includesForecasted: true,
    ...queries,
  });

  const notCompletedTenantPayments =
    tenantBillings?.pages.flatMap(page => page.content).filter(billing => !billing.isPayment) ?? [];
  const subscriptionPayments =
    subscriptionBillings?.pages.flatMap(page => page.content).filter(billing => !billing.isPayment) ?? [];

  const paymentRows =
    isTenantBillingSuccess && isSubscriptionBillingSuccess
      ? [...notCompletedTenantPayments, ...subscriptionPayments]
      : [];

  return (
    <WgContainer height={516}>
      <WgTitle>
        <span>{t('Ad_Home_Billing_02')}</span>
      </WgTitle>
      <Total>
        <Trans
          i18nKey="Ad_Home_Billing_03"
          values={{ number: paymentRows?.length }}
          components={{ 1: <span className="count" /> }}
        />
      </Total>
      {paymentRows?.length ? (
        <Payments>
          {paymentRows?.map(payment => {
            return <Payment key={payment.id} billing={payment} />;
          })}
        </Payments>
      ) : (
        <EmptyPayment isNext />
      )}
    </WgContainer>
  );
};

export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: 400px;
`;
