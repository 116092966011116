import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { Button } from '@components/index';

export const ModalWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 576px;
  max-height: 692px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-white']};
  border-radius: 6px;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: inherit;
  padding: 0px 17px 0px 28px;
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

export const Body = styled(Box)`
  width: 100%;
  max-height: 532px;
  display: flex;
  flex-direction: column;
  padding: 12px 28px 4px;
  gap: 20px;
  overflow-y: auto;
`;

export const InfoElement = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  & .groups {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .group {
      display: flex;
      flex-direction: row;
      gap: 4px;
      width: 100%;
    }
  }
  & .group-item {
    display: flex;
    align-items: center;
    gap: 6px;
    & .icon-btn {
      padding: 10px 9px;
    }
  }
`;

export const DisableEdit = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  ${fonts.Body2};
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
`;

export const IconButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
  :hover {
    cursor: pointer;
  }
`;

export const BottomButton = styled(Button)`
  width: 168px;
`;

export const Footer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px 28px 28px;
`;

export const SelectPlaceholder = styled(Box)`
  ${fonts.Button4}
  color: ${({ theme: { colors } }) => colors['text-gray-light']}
`;
export const ModalCloseBtn = styled(Button)`
  display: flex;
  align-items: center;
  height: 24px;
`;

export const InputTitle = styled('strong')`
  display: flex;
  align-items: center;
  gap: 2px;
  & > span {
    color: ${({ theme: { colors } }) => colors['ic-red-light']};
  }
`;
