import type { ProductRelationDetail } from '@repositories/productsRelationsRepository/Types';
import { SoftwareSimpleModel } from './SoftwareSimpleModel';

export class ProductRelationDetailModel {
  private readonly _productId: string;
  private readonly _iconUrl: string;
  private readonly _thumbnailUrl: string;
  private readonly _name: string;
  private readonly _description: string;
  private readonly _categoryName: string;
  private readonly _type: 'RELATED' | 'RECOMMEND' | '';

  constructor(dto?: ProductRelationDetail) {
    this._productId = dto?.productId || '';
    this._iconUrl = dto?.iconUrl || '';
    this._thumbnailUrl = dto?.thumbnailUrl || '';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._categoryName = dto?.categoryName || '';
    this._type = dto?.type ?? '';
  }

  public get productId(): string {
    return this._productId;
  }

  public get iconUrl(): string {
    return this._iconUrl;
  }

  public get thumbnailUrl(): string {
    return this._thumbnailUrl;
  }

  public get name(): string {
    return this._name;
  }

  public get description(): string {
    return this._description;
  }

  public get categoryName(): string {
    return this._categoryName;
  }

  public get type(): 'RELATED' | 'RECOMMEND' | '' {
    return this._type;
  }

  public get categoryNameList(): string[] {
    return this._categoryName.split(',');
  }

  public get simpleModel(): SoftwareSimpleModel {
    return new SoftwareSimpleModel({
      id: this._productId,
      name: this._name,
      productCode: '',
      iconUrl: this._iconUrl,
      thumbnailUrl: this._thumbnailUrl,
      description: this._description,
      planVisibilityScope: 'ALL_USERS',
    });
  }
}
