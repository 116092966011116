import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { RoutingTabs } from '@components/tab';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled(Box)`
  width: 100%;
  margin-left: 292px;
  padding-bottom: 50px;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  p {
    ${fonts.Subtitle4};
  }
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 24px;
`;

export const StyledRoutingTabs = styled(RoutingTabs)`
  &.subscription_tab {
    & a:after {
      background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
    }
  }
`;
