import { useState } from 'react';

/**
 * 텍스트의 Overflow 여부를 반환합니다.
 * @returns isOverflow 텍스트 Overflow 여부
 * @returns callbackRef Overflow를 판단할 텍스트를 포함한 HTMLElement의 ref에 넘겨줍니다.
 * @example
 * // Text Ellipsis가 일어났을 때만 Tooltip을 사용하는 예시
 * const [isOverflow, callbackRef] = useTextOverflowDetection();
 * // ...
 * <Tooltip title="foo bar" disableHoverListener={!isOverflow}>
 *   <div ref={callbackRef} style={{
 *     overflow: hidden;
 *     text-overflow: ellipsis;
 *     white-space: nowrap;
 *   }}>bla bla overflow bla bla</div>
 * </Tooptip>
 */
export const useTextOverflowDetection = () => {
  const [isOverflow, setIsOverflow] = useState(false);

  const callbackRef = (ref: HTMLElement | null) => {
    if (ref) {
      setIsOverflow(ref.scrollWidth > ref.clientWidth);
    }
  };

  return [isOverflow, callbackRef] as const;
};
