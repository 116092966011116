// import { useTheme } from '@emotion/react';
const defaultWidth = 28;
const defaultHeight = 28;

export const ProfileIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#fff',
  bgColor = '#6F42C1',
}: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <circle cx="14" cy="14" r="14" fill={bgColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0001 15.833C16.7616 15.833 19.0001 13.5944 19.0001 10.833C19.0001 8.07158 16.7616 5.83301 14.0001 5.83301C11.2387 5.83301 9.00013 8.07158 9.00013 10.833C9.00013 13.5944 11.2387 15.833 14.0001 15.833ZM14 18C9.5534 18 5.67167 19.5284 3.59851 21.7992C5.97021 24.9572 9.74658 27 14.0001 27C18.2535 27 22.0299 24.9572 24.4016 21.7993C22.3284 19.5284 18.4467 18 14 18Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ProfileIcon.displayName = 'ProfileIcon';
