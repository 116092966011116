import { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { CloseIcon } from '@icons/CloseIcon';
import { DropdownIcon } from '@icons/DropdownIcon';

export type SelectItem = {
  value: string;
  id?: string;
  name: string;
  icon?: React.ReactNode;
};

type Props = {
  id: string;
  data: SelectItem[];
  placeholder: string;
  onChange: (params: SelectItem | null) => void;
  value?: SelectItem | null;
  defaultValue?: SelectItem | null;
  disabled?: boolean;
};

export const FilterSelect = ({ id, data, placeholder, onChange, value, defaultValue, disabled }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectItem | null>(defaultValue || null);
  const [selectList, setSelectList] = useState<SelectItem[] | []>(data);
  const [searchTxt, setSearchTxt] = useState<string>('');
  const handleSelectItem = (item: SelectItem) => {
    onChange(item);
    setSelectedItem(item);
    setIsOpenMenu(false);
  };
  const handleCancelSelect = () => {
    onChange(null);
    setSelectedItem(null);
  };

  const handleSearch = (value: string) => {
    const txtEqualList = data.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    setSearchTxt(value);
    setSelectList(txtEqualList);
  };
  useEffect(() => {
    setSelectedItem(value || null);
  }, [value]);

  useEffect(() => {
    if (isOpenMenu) {
      dropdownRef.current?.querySelector('input')?.focus();
    }

    const pageClickEvent = (e: any) => {
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsOpenMenu(!isOpenMenu);
      }
    };
    if (isOpenMenu) {
      window.addEventListener('click', pageClickEvent);
    } else {
      setSearchTxt('');
      setSelectList(data);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isOpenMenu, dropdownRef, data]);

  // TODO: 키보드 선택 이벤트
  // dropdownRef.current?.querySelector
  // parentNode, nextSibling, focus
  // window.addEventListener('keyup', ...)
  // e.key === "ArrowUp", "ArrowDown"
  return (
    <Wrapper id={id} ref={dropdownRef} className="select_component">
      {selectedItem?.value ? (
        <SelectedWrapper>
          <ContentsButton type="button" onClick={() => setIsOpenMenu(!isOpenMenu)}>
            {selectedItem.icon ? (
              <>
                <SoftwareIcon src={String(selectedItem.icon)} alt={selectedItem.name} />
                <SelectedTxt>{selectedItem.name}</SelectedTxt>
              </>
            ) : (
              <SelectedTxt>{selectedItem.name}</SelectedTxt>
            )}
          </ContentsButton>
          <CancelButton type="button" onClick={handleCancelSelect}>
            <CloseIcon width={16} height={16} color="white" />
          </CancelButton>
        </SelectedWrapper>
      ) : (
        <Button
          type="button"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
          className={isOpenMenu ? 'open' : ''}
          disabled={disabled}
          placeholder={placeholder}
        >
          <ButtonPlaceholder>{placeholder}</ButtonPlaceholder>
          <IconWrapper className={isOpenMenu ? 'open' : ''}>
            <DropdownIcon />
          </IconWrapper>
        </Button>
      )}
      <ItemsWrapper className={isOpenMenu ? 'open' : ''}>
        {id === 'memberByStatusList' ? null : (
          <Search
            type="text"
            placeholder="직접 입력"
            onChange={e => handleSearch(e.target.value)}
            value={searchTxt}
            width={dropdownRef.current?.getBoundingClientRect().width}
          />
        )}
        <Items className={id === 'memberByStatusList' ? 'noneBorderTop' : ''}>
          {selectList
            ? selectList.map((item, index) => {
                return (
                  <Item key={`${item.value} ${String(index)}`}>
                    <ItemButton
                      type="button"
                      onClick={() => handleSelectItem(item)}
                      className={selectedItem?.value === item.value ? 'selected' : ''}
                    >
                      {item.icon ? (
                        <>
                          <SoftwareIcon src={String(item.icon)} alt={item.name} />
                          <ItemName>{item.name}</ItemName>
                        </>
                      ) : (
                        <ItemName>{item.name}</ItemName>
                      )}
                    </ItemButton>
                  </Item>
                );
              })
            : null}
        </Items>
      </ItemsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
`;

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.Button4};
  min-width: 160px;
  box-sizing: border-box;
  height: 36px;
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 3px;
  background: ${props => props.theme.colors['bg-white']};
  color: ${props => props.theme.colors['text-gray-main']};
  &.open {
    border: 1px solid ${props => props.theme.colors['border-purple']};
  }
  &:disabled {
    background: ${props => props.theme.colors['bg-gray-light']};
    color: ${props => props.theme.colors['text-gray-light']};
    cursor: not-allowed;
  }
`;

const ButtonPlaceholder = styled.span``;

const IconWrapper = styled.div`
  height: 18px;
  &.open {
    transform: rotateZ(180deg);
  }
`;

const SelectedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  min-width: 160px;
  height: 36px;
  background: ${props => props.theme.colors['bg-purple-light']};
  border-radius: 3px;
  overflow: hidden;
  img {
    display: block;
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.colors['state-purple-hover']};
    }
  }
`;

const ContentsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 8px 8px 8px 12px;
`;

const CancelButton = styled.button`
  height: 100%;
  padding: 0 8px;
`;

const SelectedTxt = styled.span`
  ${fonts.Button4};
  color: ${props => props.theme.colors['text-white']};
`;

const ItemsWrapper = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  max-width: 320px;
  margin-top: 4px;
  border: 1px solid ${props => props.theme.colors['border-gray-light']};
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  background: ${props => props.theme.colors['bg-white']};
  z-index: 100;
  &.open {
    display: block;
  }
  & .noneBorderTop {
    border-top: 0;
  }
`;

const Search = styled.input<{ width?: number }>`
  ${fonts.Body2};
  border: none;
  box-sizing: border-box;
  min-width: 160px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  max-width: 320px;
  padding: 6px 12px;
`;

const Items = styled.ul`
  max-height: 296px;
  padding: 4px 5px;
  border-top: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  overflow-y: scroll;
  &.open {
    display: block;
  }
`;

const Item = styled.li`
  button {
    display: flex;
    align-items: center;
    gap: 6px;
    border: none;
    ${fonts.Body2};
    text-align: left;
    width: 100%;
    padding: 6px 7px;
    background: ${props => props.theme.colors['bg-white']};
    color: ${props => props.theme.colors['text-gray-main']};
    cursor: pointer;
    &:hover,
    &:focus {
      border-radius: 2px;
      background: ${props => props.theme.colors['state-white-hover']};
    }
    &.selected {
      color: ${props => props.theme.colors['text-purple-light']};
      background: ${props => props.theme.colors['state-white-hover']};
    }
  }
`;

const ItemButton = styled.button``;

const SoftwareIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;

const ItemName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
