import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useStore } from '@stores/RootStore';

export type FormContactUs = {
  name: string;
  email: string;
  phone: string;
  companyName?: string;
  inquiry: string;
  softwareId: string;
};

export const useFormContactUs = () => {
  const { authStore } = useStore();
  const { t } = useTranslation();
  const validationFormSchema = Yup.object().shape({
    name: authStore.isLogin ? Yup.string() : Yup.string().required(t('Member_Group_Delete_05')),
    email: authStore.isLogin
      ? Yup.string()
      : Yup.string().required(t('Member_Group_Delete_05')).email(t('Member_Manager_17')),
    phone: authStore.isLogin
      ? Yup.string()
      : Yup.string()
          .required('필수 입력 항목입니다.')
          .required(t('Member_Group_Delete_05'))
          .matches(/^[\d\s]+$/, {
            message: '숫자만 입력 가능합니다.',
          }),
    companyName: authStore.isLogin ? Yup.string() : Yup.string().required('필수 입력 항목입니다.'),
    inquiry: Yup.string().required('필수 입력 항목입니다.'),
  });
  const formOptions: UseFormProps<FormContactUs> = {
    resolver: yupResolver(validationFormSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<FormContactUs>(formOptions);
};
