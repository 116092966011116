import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { Button, Dialog } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import type { AdditionalCommitmentCreateDTO, AdditionalCommitmentDTO } from '@repositories/licenseRepository';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetLicense, useUpdateLicense } from '@queryHooks/useLicense';
import { useGetProductPlanDetail, useGetUsageMetricList } from '@queryHooks/useSoftware';
import { useAdditionalCommitmentForm } from './validationSchema';

type Props = {
  dialogType: 'create' | 'update' | '';
  selectData?: AdditionalCommitmentDTO;
  open: boolean;
  onClose: () => void;
};

type additionalCommitment = {
  usageMetricId: string;
  type: string;
  committedUsage: number | null;
  usageUnitPrice: number | null;
  discountRate: number | null;
  usageUnitPriceWhenExceed: number | null;
};

export const CommitmentEditor = ({ dialogType, selectData, open, onClose }: Props) => {
  const { t } = useTranslation();
  const { licenseId } = useParams();
  const theme = useTheme();
  const {
    uiStore: { toastStore },
  } = useStore();
  const subscriptionInfo = useSubscriptionModel();
  const { data: licenseDetail } = useGetLicense(licenseId ?? '', { enabled: !!open });
  const { data: usageMetricList } = useGetUsageMetricList(licenseDetail?.subscription.product?.id as string, {
    enabled: !!open,
  });
  const { mutate: licenseUpdate } = useUpdateLicense();
  const { data: productPlan } = useGetProductPlanDetail(
    subscriptionInfo.software.id,
    licenseDetail ? licenseDetail?.plan.planId : '',
    { enabled: !!open },
  );
  const [usageMetricUnit, setUsageMetricUnit] = useState<string>();
  const { handleSubmit, reset, control, formState, getValues, setValue, trigger } = useAdditionalCommitmentForm();
  const { errors } = formState;
  const validation = {
    success: (data: additionalCommitment) => {
      const typeString = data.type;
      let type: 'TOTAL_AMOUNT_DISCOUNT' | 'USAGE_BASED_DISCOUNT' | undefined;
      if (typeString === 'TOTAL_AMOUNT_DISCOUNT' || typeString === 'USAGE_BASED_DISCOUNT') {
        type = typeString;
      } else {
        type = undefined;
      }

      if (licenseDetail && licenseDetail.additionalCommitments && dialogType === 'create') {
        // dialogType이 create인 경우
        const updatedCommitments: AdditionalCommitmentCreateDTO[] = licenseDetail.additionalCommitments.map(item => ({
          usageMetricId: item.usageMetric?.usageMetricId,
          type: item.type,
          committedUsage: item.committedUsage,
          usageUnitPrice: item.usageUnitPrice,
          discountRate: item.discountRate,
          usageUnitPriceWhenExceed: item.usageUnitPriceWhenExceed,
        }));

        updatedCommitments.push({
          committedUsage: data.committedUsage || undefined,
          discountRate: data.discountRate != null ? data.discountRate / 100 : undefined,
          type,
          usageMetricId: data.usageMetricId || '',
          usageUnitPrice: data.usageUnitPrice || undefined,
          usageUnitPriceWhenExceed: data.usageUnitPriceWhenExceed || undefined,
        });

        licenseUpdate(
          licenseDetail.getLicenseUpdateDTO({
            additionalCommitments: updatedCommitments,
            additionalProperties: {},
          }),
          {
            onSuccess: () => {
              toastStore.open(t('Additional_Commitment_01'));
              onClose();
            },
          },
        ); // dialogType이 update인 경우
      } else if (licenseDetail && licenseDetail.additionalCommitments && dialogType === 'update') {
        // update할려는 데이터 찾아 값을 넣어주고 이전 데이터까지 합치기
        const updatedAdditionalCommitments = licenseDetail.additionalCommitments.map(item => {
          if (item.additionalCommitmentId === selectData?.additionalCommitmentId) {
            return {
              ...item,
              committedUsage: data.committedUsage || undefined,
              type,
              discountRate: data.discountRate != null ? data.discountRate / 100 : undefined,
              usageMetric: {
                ...item.usageMetric,
                usageMetricId: data.usageMetricId || undefined,
              },
              usageUnitPrice: data.usageUnitPrice || undefined,
              usageUnitPriceWhenExceed: data.usageUnitPriceWhenExceed || undefined,
            };
          }
          return item;
        });

        const updatedCommitments: AdditionalCommitmentCreateDTO[] = updatedAdditionalCommitments.map(item => ({
          usageMetricId: item.usageMetric?.usageMetricId,
          type: item.type,
          committedUsage: item.committedUsage,
          usageUnitPrice: item.usageUnitPrice,
          discountRate: item.discountRate,
          usageUnitPriceWhenExceed: item.usageUnitPriceWhenExceed,
        }));

        licenseUpdate(
          licenseDetail.getLicenseUpdateDTO({
            additionalCommitments: updatedCommitments,
            additionalProperties: {},
          }),
          {
            onSuccess: () => {
              toastStore.open(t('Subscrib_Detail_BuyRequest_08'));
              onClose();
            },
          },
        );
      }
    },
  };

  const handleClose = () => {
    onClose();
  };

  const handleSelectMetric = () => {
    const selectMetric = usageMetricList?.find(metric => metric.usageMetricId === getValues('usageMetricId'));
    setUsageMetricUnit(selectMetric?.usageUnit);
  };

  const handleFindUnitPrice = () => {
    const [usageMetricId, type] = getValues(['usageMetricId', 'type']);
    if (usageMetricId && type) {
      const currentPlanOption = productPlan?.planOptionList.find(plan => {
        return plan.optionName === licenseDetail?.planOption.optionName;
      });
      if (
        currentPlanOption &&
        currentPlanOption.usageMetricPrices.length === 0 &&
        currentPlanOption.unitPrice !== undefined &&
        currentPlanOption.unitPrice !== null
      ) {
        setValue('usageUnitPrice', currentPlanOption.unitPrice);
        trigger('usageUnitPrice');
      } else {
        const findMetric = currentPlanOption?.usageMetricPrices.find(metric => {
          return metric.usageMetricId === usageMetricId;
        });

        if (findMetric) {
          setValue('usageUnitPrice', findMetric.usageUnitPrice);
          trigger('usageUnitPrice');
        } else {
          setValue('usageUnitPrice', null);
        }
      }
    }
  };

  useEffect(() => {
    if (dialogType === 'create') {
      reset({
        usageMetricId: '',
        type: '',
        committedUsage: null,
        usageUnitPrice: null,
        discountRate: 0,
        usageUnitPriceWhenExceed: 0,
      });
    } else {
      reset({
        usageMetricId: selectData?.usageMetric?.usageMetricId,
        type: selectData?.type,
        committedUsage: selectData?.committedUsage,
        usageUnitPrice: selectData?.usageUnitPrice,
        discountRate: (selectData?.discountRate ?? 0) * 100,
        usageUnitPriceWhenExceed: selectData?.usageUnitPriceWhenExceed ?? 0,
      });
    }
  }, [dialogType, reset, selectData, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={dialogType === 'create' ? t('Additional_Commitment_02') : t('Additional_Commitment_03')}
      height={692 + Object.keys(errors).length * 22}
      size="medium"
    >
      <CreateAdditionalForm onSubmit={handleSubmit(validation.success)}>
        <Container>
          <Element>
            <NameText>
              {t('Subscrib_Detail_License_17')}
              <EssentialMark>*</EssentialMark>
            </NameText>
            <Controller
              control={control}
              name="usageMetricId"
              render={({ field: { ref, value, onChange } }) => (
                <FieldSelect
                  inputRef={ref}
                  id="usageMetricId"
                  value={value}
                  onChange={event => {
                    onChange(event);
                    handleSelectMetric();
                    handleFindUnitPrice();
                  }}
                  size="medium"
                  fullWidth
                  displayEmpty
                  renderValue={(value: string) => {
                    const foundMetric = usageMetricList?.find(metric => metric.usageMetricId === value);

                    if (foundMetric) {
                      return foundMetric.usageMetricName || t('Additional_Commitment_04');
                    }

                    return t('Additional_Commitment_04');
                  }}
                  error={!!formState.errors.usageMetricId}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '424px',
                        maxHeight: '208px',
                        marginTop: '9px',
                        borderRadius: '0 0 2px 2px',
                        boxShadow: '0',
                        border: `1px solid ${theme.colors['border-gray-light']}`,
                        '& .MuiList-root': {
                          border: 'none',
                          padding: '4px 0',
                          '& .MuiMenuItem-root': {
                            margin: '0 5px',
                          },
                        },
                      },
                    },
                  }}
                >
                  {usageMetricList?.map(metric => (
                    <StyledMenuItem key={metric.usageMetricId} value={metric.usageMetricId}>
                      {metric.usageMetricName}
                    </StyledMenuItem>
                  ))}
                </FieldSelect>
              )}
            />
            {formState.errors.usageMetricId?.message && (
              <SelectHelperText>{formState.errors.usageMetricId?.message}</SelectHelperText>
            )}
          </Element>
          <Element>
            <NameText>
              {t('Subscrib_Detail_License_18')}
              <EssentialMark>*</EssentialMark>
            </NameText>
            <Controller
              control={control}
              name="type"
              render={({ field: { ref, value, onChange } }) => (
                <FieldSelect
                  inputRef={ref}
                  value={value}
                  onChange={event => {
                    onChange(event);
                    handleFindUnitPrice();
                  }}
                  name="type"
                  size="medium"
                  fullWidth
                  displayEmpty
                  renderValue={(value: string) => {
                    if (value) {
                      return value === 'TOTAL_AMOUNT_DISCOUNT' ? '전체 금액 약정 할인' : '개별 사용량 약정 할인';
                    }
                    return t('Additional_Commitment_05');
                  }}
                  error={!!formState.errors.type}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '424px',
                        maxHeight: '208px',
                        marginTop: '9px',
                        borderRadius: '0 0 2px 2px',
                        boxShadow: '0',
                        border: `1px solid ${theme.colors['border-gray-light']}`,
                        '& .MuiList-root': {
                          border: 'none',
                          padding: '4px 0',
                          '& .MuiMenuItem-root': {
                            margin: '0 5px',
                          },
                        },
                      },
                    },
                  }}
                >
                  <StyledMenuItem value="TOTAL_AMOUNT_DISCOUNT">전체 금액 약정 할인</StyledMenuItem>
                  <StyledMenuItem value="USAGE_BASED_DISCOUNT">개별 사용량 약정 할인</StyledMenuItem>
                </FieldSelect>
              )}
            />
            {formState.errors.type?.message && <SelectHelperText>{formState.errors.type?.message}</SelectHelperText>}
          </Element>
          <Element>
            <NameText>{t('Subscrib_Detail_License_19')}</NameText>
            <FlexBox>
              {dialogType === 'create' ? (
                <InfoBox>
                  {formState.dirtyFields.usageMetricId ? usageMetricUnit : t('Additional_Commitment_06')}
                </InfoBox>
              ) : (
                <InfoBox>
                  {formState.dirtyFields.usageMetricId ? usageMetricUnit : selectData?.usageMetric?.usageUnit}
                </InfoBox>
              )}

              <Controller
                control={control}
                name="committedUsage"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    value={value === null ? '' : value}
                    onChange={onChange}
                    placeholder={value === null ? t('Subscrib_Detail_License_11') : ''}
                    error={!!formState.errors.committedUsage}
                  />
                )}
              />
            </FlexBox>
            {formState.errors.committedUsage?.message && (
              <SelectHelperTextMargin>{formState.errors.committedUsage?.message}</SelectHelperTextMargin>
            )}
          </Element>
          <Element>
            <NameText>{t('Acc_Detail_15')}</NameText>
            <FlexBox>
              <InfoBox>{licenseDetail?.currencyUnit}</InfoBox>
              <Controller
                control={control}
                name="usageUnitPrice"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    value={value === null ? '' : value}
                    onChange={onChange}
                    placeholder={value === null ? t('Subscrib_Detail_License_11') : ''}
                    error={!!formState.errors.usageUnitPrice}
                  />
                )}
              />
            </FlexBox>
            {formState.errors.usageUnitPrice?.message && (
              <SelectHelperTextMargin>{formState.errors.usageUnitPrice?.message}</SelectHelperTextMargin>
            )}
          </Element>
          <Element>
            <NameText>{t('Subscrib_Detail_License_21')}</NameText>
            <Controller
              control={control}
              name="discountRate"
              render={({ field: { value, onChange } }) => (
                <TextField
                  fullWidth
                  value={value === 0 ? '' : value}
                  onChange={onChange}
                  placeholder={value === 0 ? t('Subscrib_Detail_License_11') : ''}
                />
              )}
            />
          </Element>
          <Element>
            <NameText>{t('Subscrib_Detail_License_22')}</NameText>
            <FlexBox>
              <InfoBox>{licenseDetail?.currencyUnit}</InfoBox>
              <Controller
                control={control}
                name="usageUnitPriceWhenExceed"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    value={value === 0 ? '' : value}
                    onChange={onChange}
                    placeholder={value === 0 ? t('Subscrib_Detail_License_11') : ''}
                  />
                )}
              />
            </FlexBox>
          </Element>
          <ButtonBox>
            <Button
              type="submit"
              variant="contain"
              color="purple"
              size="extraLarge"
              paddingHorizontal={69.5}
              disabled={(dialogType !== 'create' && !formState.isDirty) || Object.keys(errors).length > 0}
            >
              {dialogType === 'create' ? t('Member_Manager_11') : t('Additional_Commitment_08')}
            </Button>
            <Button variant="outline" color="gray" size="extraLarge" paddingHorizontal={69.5} onClick={handleClose}>
              {t('Subscrib_Detail_BuyRequest_13')}
            </Button>
          </ButtonBox>
        </Container>
      </CreateAdditionalForm>
    </Dialog>
  );
};

const CreateAdditionalForm = styled.form`
  position: relative;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 780px;
`;

const EssentialMark = styled.span`
  margin-left: 2px;
  color: ${({ theme: { colors } }) => colors['ic-red-light']};
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const NameText = styled.span`
  ${fonts.Headline8}
  margin-bottom: 6px;
`;

const InfoBox = styled.div`
  ${fonts.Body2}
  padding: 8px 12px;
  min-width: 120px;
  border-radius: 2px;
  margin-right: 6px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  background-color: ${({ theme }) => theme.colors['bg-gray-light-50']};
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  margin-top: 10px;
`;

const FieldSelect = styled(Select)`
  &.Mui-disabled {
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light-m']};
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    }
    & .MuiSelect-select {
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
    & .MuiSelect-icon path {
      fill: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
  }

  &.Mui-disabled.Mui-error,
  &.Mui-error {
    background-color: ${({ theme: { colors } }) => colors['bg-white']};
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors['border-red-light']};
    }
    & .MuiSelect-select {
      color: ${({ theme: { colors } }) => colors['text-red-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
    }
    & .MuiSelect-icon path {
      fill: ${({ theme: { colors } }) => colors['text-red-light']};
    }
  }
` as unknown as typeof Select;

const StyledMenuItem = styled(MenuItem)``;

const SelectHelperText = styled(FormHelperText)`
  margin: 0;
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  ${fonts.Body4};
`;

const FlexBox = styled.div`
  display: flex;
`;

const SelectHelperTextMargin = styled(FormHelperText)`
  margin-left: 128px !important;
  margin: 0;
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  ${fonts.Body4};
`;
