const defaultWidth = 16;
const defaultHeight = 16;

export const RadioOffIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  bgColor = '#fff',
}: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="8" cy="8" r="7.5" fill={bgColor} stroke={color} />
      </g>
    </svg>
  );
};

RadioOffIcon.displayName = 'RadioOffIcon';
