import styled from '@emotion/styled';
import { InputAdornment } from '@mui/material';

export const InputIcon = styled(InputAdornment)`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
`;
