import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton, useTheme } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { ErrorIcon, PlugfillIcon, SearchIcon } from '@icons/index';
import type { Filters } from '@repositories/softwareCategoryRepository';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useGetSubscriptionListInfinite } from '@queryHooks/useSubscription';
import * as S from './SubscriptionChoiceStyles';

export const SubscriptionChoice = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authStore } = useStore();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<Filters>();
  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry?.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const {
    data: subscriptions,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSubscriptionListInfinite(filter);

  const handleClickSubscription = (subscriptionId: string) => {
    navigate(`/manager/${authStore.curTenant.id}/subscriptions/connections/${subscriptionId}/connection`);
  };

  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, name: event.target.value }));
  };
  return (
    <S.Container>
      <S.Header>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        <strong className="title">{t('Subscrib_Connect_01')}</strong>
      </S.Header>
      <S.Body>
        <S.Toolbar>
          <S.StyledTextField
            fullWidth
            size="medium"
            placeholder={t('Subscrib_Connect_Create_01')}
            onChange={handleSearchNameChange}
            InputProps={{
              startAdornment: (
                <S.IconWrapper>
                  <SearchIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                </S.IconWrapper>
              ),
            }}
          />
        </S.Toolbar>
        <S.Subscriptions>
          <strong className="total">
            {t('Acc_Main_12')}
            <span className="count">{subscriptions?.pages[0].totalElements ?? 0}</span>
          </strong>
          <div className="list">
            {subscriptions?.pages[0].totalElements ? (
              <>
                {subscriptions?.pages
                  .flatMap(page => page.content)
                  ?.map(subscription => {
                    return (
                      <S.Subscription onClick={() => handleClickSubscription(subscription.id)} key={subscription.id}>
                        <S.StyledAvatar src={subscription.software.iconUrl} />
                        <S.StyledDivider flexItem orientation="vertical" variant="middle" />
                        <div className="info">
                          <div className="app-name-wrap">
                            <span className="app-name">{subscription.software.name}</span>
                            {subscription.connection?.isConnected && (
                              <S.StyledLabel
                                variant="icon"
                                color="green"
                                icon={<PlugfillIcon width={12} height={12} color={theme.colors['ic-white']} />}
                              />
                            )}
                          </div>
                          <span className="subscription-name">{subscription.name}</span>
                        </div>
                      </S.Subscription>
                    );
                  })}
                {hasNextPage && <div ref={ref} />}
              </>
            ) : (
              <Empty>
                <EmptyImg>
                  <ErrorIcon width={34} height={34} color={theme.colors['ic-purple-light']} />
                </EmptyImg>
                <Description>
                  <span>{t('Subscrib_Connect_Create_12')}</span>
                  <span className="sub">{t('Subscrib_Connect_Create_13')}</span>
                </Description>
              </Empty>
            )}
          </div>
        </S.Subscriptions>
      </S.Body>
    </S.Container>
  );
};

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 78px;
  align-items: center;
  justify-content: center;
`;

const EmptyImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: ${props => props.theme.colors['bg-purple-lighter']};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  ${fonts.Headline7};
  & .sub {
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
  }
`;
