
import dayjs from 'dayjs';
import type { BillingStatus } from '@repositories/monthlyBillingRepository';
import type { TenantMonthlyBillingDTO } from '@repositories/tenantMonthlyBillingRepository/Types';
import { SoftwareBillingModel } from './SoftwareBillingModel';
import { SoftwareProviderModel } from './SoftwareProviderModel';
import { SubscriptionBillingModel } from './SubscriptionBillingModel';
import { FileSimpleModel } from './file/FileSimpleModel';
import { TenantModel } from './tenantModels/TenantModel';

export class TenantMonthlyBillingModel {
  private readonly _dto: TenantMonthlyBillingDTO;
  private readonly _tenant: TenantModel;
  private readonly _subTenantBillings: TenantMonthlyBillingModel[];
  private readonly _softwareBillings: SoftwareBillingModel[];
  private readonly _subscriptionBillings: SubscriptionBillingModel[];
  private readonly _attachments: FileSimpleModel[];
  private readonly _provider?: SoftwareProviderModel;

  constructor(dto: TenantMonthlyBillingDTO) {
    this._dto = dto;
    this._tenant = new TenantModel(dto.tenant);
    this._subTenantBillings = dto.subTenantBillings?.map(billing => new TenantMonthlyBillingModel(billing)) ?? [];
    this._softwareBillings = dto.softwareBillings?.map(billing => new SoftwareBillingModel(billing)) ?? [];
    this._subscriptionBillings = dto.subscriptionBillings?.map(billing => new SubscriptionBillingModel(billing)) ?? [];
    this._attachments = dto.attachments?.map(dto => new FileSimpleModel(dto)) ?? [];
    this._provider = dto.provider ? new SoftwareProviderModel(dto.provider) : undefined;
  }

  get id(): string {
    return `${this._dto.month}_Tenant_${this._dto.startDate}_${this._dto.endDate}`;
  }

  get month(): string {
    return this._dto.month;
  }

  get cost(): number {
    return this._dto.cost;
  }

  get currencyUnit(): CurrencyUnit {
    return this._dto.currencyUnit ?? 'KRW';
  }

  get originalCost(): number {
    return this._dto.originalCost;
  }

  get originalCurrencyUnit() {
    return this._dto.originalCurrencyUnit;
  }

  get exchangeRate(): number {
    return this._dto.exchangeRate ?? 0;
  }

  get provider(): SoftwareProviderModel | undefined {
    return this._provider ?? undefined;
  }

  get subTenantBillings(): TenantMonthlyBillingModel[] {
    return this._subTenantBillings;
  }

  get softwareBillings(): SoftwareBillingModel[] {
    return this._softwareBillings;
  }

  get subscriptionBillings(): SubscriptionBillingModel[] {
    return this._subscriptionBillings;
  }

  get tenantId(): string {
    return this._tenant.id;
  }

  get startDate() {
    return this._dto.startDate;
  }

  get endDate() {
    return this._dto.endDate;
  }

  get paymentDate() {
    return this._dto.paymentDate;
  }

  get tenant(): TenantModel {
    return this._tenant;
  }

  get createdDate() {
    const createdDayjs = dayjs(this._dto.createdDate);
    return createdDayjs.isValid() ? createdDayjs : undefined;
  }

  get status(): BillingStatus {
    return this._dto.status;
  }

  get isPayment(): boolean {
    return this._dto.status === 'PAID';
  }

  get isForecasted(): boolean {
    return this._dto.status === 'FORECASTED';
  }

  get attachments() {
    return this._attachments;
  }

  getNumberOfSubscriptions(swId: string) {
    return this._subscriptionBillings.filter(subBilling => subBilling.subscription.softwareId === swId).length;
  }
}
