const defaultWidth = 24;
const defaultHeight = 24;

export const FlagIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.49992 1.5C4.05217 1.5 4.49985 1.94768 4.49985 2.49992L20.3916 2.49992C20.6819 2.46886 20.9832 2.56458 21.2057 2.78709C21.4017 2.98308 21.4993 3.24019 21.4986 3.49707L21.4986 3.49985C21.4986 3.80838 21.3588 4.08428 21.1392 4.2677L16.9032 8.50365L21.0989 12.6993C21.3209 12.8662 21.4712 13.1233 21.4952 13.4163C21.5234 13.7039 21.4274 14.0014 21.2071 14.2216C20.967 14.4618 20.635 14.5543 20.3242 14.499L4.49985 14.499V22.4984H2.5V2.49992C2.5 1.94768 2.94768 1.5 3.49992 1.5ZM18.0705 12.4992L14.7873 9.21595C14.4045 8.83315 14.397 8.21719 14.7647 7.8252C14.7903 7.79217 14.8183 7.76037 14.8486 7.73004L18.0789 4.49977L4.49985 4.49977V12.4992L18.0705 12.4992Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

FlagIcon.displayName = 'FlagIcon';
