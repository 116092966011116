import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { fonts } from '@theme/fontsCustomer';
import type { TechnicalInquiryDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryDTO';
import type { TechnicalInquiryHistoryDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryHistoryDTO';
import { useGetTechnicalInquiryDetail } from '@queryHooks/useTechnicalSupport';

type InquiriesHistoryTableProps = {
  technicalInquiryDetailInfo?: TechnicalInquiryDTOModel;
};
type ProcessingHistoryTableProps = {
  technicalInquiryHistoryInfoList?: TechnicalInquiryHistoryDTOModel[];
};

const InquiriesHistoryTable = ({ technicalInquiryDetailInfo }: InquiriesHistoryTableProps) => {
  const history = technicalInquiryDetailInfo?.histories;
  const inquiriesDate = history?.find(data => data.status === 'CREATED')?.createDate;
  return (
    <InfoWrapper>
      <InfoTittle>기술지원 문의 내역</InfoTittle>
      <Table>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>제품 구분</TableCellTittle>
            <TableCellDetail>{technicalInquiryDetailInfo?.categoryName}</TableCellDetail>
          </FirstTableCell>
          <SecondTableCell>
            <TableCellTittle>문의 날짜</TableCellTittle>
            <TableCellDetail>{inquiriesDate && dayjs(inquiriesDate).format('YYYY.MM.DD')}</TableCellDetail>
          </SecondTableCell>
        </TableRow>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>제품명</TableCellTittle>
            <TableCellDetail>{technicalInquiryDetailInfo?.productName}</TableCellDetail>
          </FirstTableCell>
          <SecondTableCell>
            <TableCellTittle>구성원 이름</TableCellTittle>
            <TableCellDetail>{technicalInquiryDetailInfo?.managerName || '-'}</TableCellDetail>
          </SecondTableCell>
        </TableRow>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>플랜(요금제)</TableCellTittle>
            <TableCellDetail>{technicalInquiryDetailInfo?.planName}</TableCellDetail>
          </FirstTableCell>
          <SecondTableCell>
            <TableCellTittle>계정</TableCellTittle>
            <TableCellDetail>{technicalInquiryDetailInfo?.email}</TableCellDetail>
          </SecondTableCell>
        </TableRow>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>내용</TableCellTittle>
            <TableCellDetail>{technicalInquiryDetailInfo?.inquiry || '-'}</TableCellDetail>
          </FirstTableCell>
        </TableRow>
      </Table>
    </InfoWrapper>
  );
};

const ProcessingHistoryTable = ({ technicalInquiryHistoryInfoList }: ProcessingHistoryTableProps) => {
  const findLastStatusHistory =
    technicalInquiryHistoryInfoList?.find(data => data.status === 'COMPLETED') ||
    technicalInquiryHistoryInfoList?.find(data => data.status === 'CREATED');
  return (
    <InfoWrapper>
      <InfoTittle>처리 내역</InfoTittle>
      <Table>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>처리 내역</TableCellTittle>
            <TableCellDetail>
              <Label variant="contain" color={findLastStatusHistory?.status === 'COMPLETED' ? 'gray' : 'pink'}>
                {findLastStatusHistory?.conversionStatus}
              </Label>
            </TableCellDetail>
          </FirstTableCell>
          <SecondTableCell>
            <TableCellTittle>처리 날짜</TableCellTittle>
            <TableCellDetail>{dayjs(findLastStatusHistory?.createDate).format('YYYY.MM.DD')}</TableCellDetail>
          </SecondTableCell>
        </TableRow>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>담당자</TableCellTittle>
            <TableCellDetail>{findLastStatusHistory?.managerName || '-'}</TableCellDetail>
          </FirstTableCell>
        </TableRow>
        <TableRow>
          <FirstTableCell>
            <TableCellTittle>내용</TableCellTittle>
            <TableCellDetail>
              {technicalInquiryHistoryInfoList?.length && technicalInquiryHistoryInfoList?.length > 1
                ? technicalInquiryHistoryInfoList?.map(data => {
                    return data.comment;
                  })
                : '-'}
            </TableCellDetail>
          </FirstTableCell>
        </TableRow>
      </Table>
    </InfoWrapper>
  );
};

export const TechnicalSupportDetailInfo = () => {
  const { technicalInquiryId } = useParams();

  const { data: technicalInquiryDetailInfo } = useGetTechnicalInquiryDetail(technicalInquiryId as string);

  return (
    <TableWrap>
      <InquiriesHistoryTable technicalInquiryDetailInfo={technicalInquiryDetailInfo} />
      <ProcessingHistoryTable technicalInquiryHistoryInfoList={technicalInquiryDetailInfo?.histories} />
    </TableWrap>
  );
};

const TableWrap = styled.article`
  display: flex;
  flex-direction: column;
  gap: 72px;
`;

const InfoWrapper = styled.article`
  padding-top: 40px;
  padding-left: 24px;
  width: 700px;
`;

const InfoTittle = styled.div`
  ${fonts.Headline7}
  color: ${({ theme }) => theme.colors['text-gray-main']};
  padding-bottom: 17px;
`;

const Table = styled.article`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
`;

const TableRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
`;

const FirstTableCell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;
const SecondTableCell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0px 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  border-left: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']}; ;
`;

const TableCellTittle = styled.span`
  ${fonts.Headline9}
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
`;

const TableCellDetail = styled.span`
  ${fonts.Headline8}
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors['text-gray-main']};
`;
