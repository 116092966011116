import type React from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useGetSubscriptionList } from '@queryHooks/useSubscription';
import { SubscriptionListModel } from '@models/SubscriptionListModel';

interface Props {
  value?: string;
  onChange: (subscription: SubscriptionListModel) => void;
}

const SubscriptionAutocomplete = ({ onChange, value }: Props) => {
  const theme = useTheme();
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionListModel | null>(null);

  const { data: subscriptions } = useGetSubscriptionList(undefined, {
    onSuccess: data => {
      const initSubscription = data.content.find(sb => sb.id === value);
      if (initSubscription) setSelectedSubscription(initSubscription);
    },
  });

  useEffect(() => {
    setSelectedSubscription(subscriptions.content.find(subscription => subscription.id === value) ?? null);
  }, [subscriptions.content, value]);

  const handleChangeAutocomplete = (
    event: React.SyntheticEvent<Element, Event>,
    value: SubscriptionListModel | null,
  ) => {
    setSelectedSubscription(value || new SubscriptionListModel({ id: '-1' }));
    if (value) onChange(value);
  };

  return (
    <Autocomplete
      className="autocomplete"
      value={selectedSubscription || null}
      size="small"
      options={subscriptions.content || []}
      renderInput={params => (
        <TextField
          {...params}
          size="medium"
          sx={{
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderRadius: '2px 2px 0 0',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              color: `${theme.colors['text-gray-light']}`,
            },
          }}
        />
      )}
      fullWidth
      isOptionEqualToValue={(op, val) => op.id === val.id}
      getOptionLabel={group => group.name}
      placeholder={t('Subscription_Auto_Complete_01')}
      onChange={handleChangeAutocomplete}
      ListboxProps={{
        style: {
          maxHeight: '328px',
        },
      }}
      componentsProps={{
        paper: {
          sx: {
            width: '100%',
            marginTop: 0,
            borderRadius: '0 0 2px 2px',
            boxShadow: '0',
            border: `1px solid ${theme.colors['border-gray-light']}`,
            borderWidth: '0 1px 1px 1px',
            overflow: 'hidden',
            '& .MuiAutocomplete-listbox': {
              height: '328px',
            },
            '& .MuiList-root': {
              border: 'none',
              padding: '4px 0',
              '& .MuiMenuItem-root': {
                margin: '0 5px',
              },
            },
          },
        },
      }}
      sx={{
        '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '7.5px 4px 7.5px 12px',
        },
      }}
    />
  );
};

export default SubscriptionAutocomplete;
