import type { Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Divider, IconButton, useTheme, Avatar } from '@mui/material';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { Button, Label } from '@components/index';
import { ProfileModal } from '@pages/customer/common/modals';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { BriefcaseIcon } from '@icons/BriefcaseIcon';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import { ChevronIcon } from '@icons/ChevronIcon';
import { ClockIcon } from '@icons/ClockIcon';
import { DescriptionIcon } from '@icons/DescriptionIcon';
import { DottedBorderIcon } from '@icons/DottedBorderIcon';
import { FlagIcon } from '@icons/FlagIcon';
import { FolderIcon } from '@icons/FolderIcon';
import { GroupIcon } from '@icons/GroupIcon';
import { ManagerIcon } from '@icons/ManagerIcon';
import { PauseIcon } from '@icons/PauseIcon';
import { PencilIcon } from '@icons/PencilIcon';
import { PersonIcon } from '@icons/PersonIcon';
import { PlayIcon } from '@icons/PlayIcon';
import { PlugFillSplashIcon } from '@icons/PlugFillSplashIcon';
import { PlugIcon } from '@icons/PlugIcon';
import { PlugfillIcon } from '@icons/PlugfillIcon';
import { RefreshIcon } from '@icons/RefreshIcon';
import { TitleIcon } from '@icons/TitleIcon';
import ToolsIcon from '@icons/ToolsIcon';
import { TrashIcon } from '@icons/TrashIcon';
import { WorkflowIcon } from '@icons/WorkflowIcon';
import { LoadingIcon } from '@icons/loading/LoadingIcon';
import type { OauthFilter } from '@repositories/oauthRepository/Types';
import type { ConnectionStatus } from '@repositories/subscriptionConnectionRepository/Types';
import { useCallbackOauth } from '@queryHooks/useOauth';
import { useGetSubscription } from '@queryHooks/useSubscription';
import {
  useConnect,
  useConnectIsMutating,
  useConnectWithOauth,
  useDeleteSubscriptionConnection,
  useDisconnect,
  useGetSubscriptionConnection,
  useSynchronizeConnectionStatus,
} from '@queryHooks/useSubscriptionConnection';
import { dateFormat, dateTimeFormat } from '@utils/dateUtil';
import type { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';
import { AppLogin } from './appLogin';

type Modal = {
  isOpen: boolean;
  type: 'new' | 'modify';
};

export const ConnectionDetail = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const {
    categoryStore,
    uiStore: { alertStore, toastStore },
    authStore,
  } = useStore();
  const [hover, setHover] = useState<boolean>(false);
  const prevConnectionStatus = useRef<ConnectionStatus>();

  const { mutate: deleteConnection } = useDeleteSubscriptionConnection(params.subscriptionId as string);

  const { data: subscription, isLoading: isSubscriptionLoading } = useGetSubscription(params.subscriptionId as string);
  const { data: connection, isFetching: isConnectionFetching } = useGetSubscriptionConnection(
    params.subscriptionId as string,
    {
      enabled: !!subscription?.connection?.connectionId.length,
      cacheTime: 0,
      refetchInterval: (data: any) => {
        const now = Date.now();
        let lastRefetchTime: number | null = null;
        if (data?.status === 'CONNECTING') {
          if (lastRefetchTime === null || now - lastRefetchTime >= 60000) {
            // 1분 (60초) 타임아웃
            lastRefetchTime = now;
            return 3000; // 3초 간격으로 refetch
          }
          return 3000;
        }
        if (data?.status === 'FAILED') {
          return false;
        }
        return false;
      },
      retry: false,
      onSuccess: data => {
        if (
          !!prevConnectionStatus.current &&
          prevConnectionStatus.current !== 'CONNECTED' &&
          data?.status === 'CONNECTED'
        ) {
          toastStore.open({
            message: t('Subscrib_Connect_Create_14'),
            hideAction: true,
          });
        }
        prevConnectionStatus.current = data?.status;
      },
    },
  );

  const { mutate: disconnect } = useDisconnect();
  const { mutate: connect } = useConnect(subscription?.id ?? '');
  const { mutate: synchronizeStatus } = useSynchronizeConnectionStatus();
  const connectMutatingCount = useConnectIsMutating(subscription?.id ?? '');
  const { refetch } = useConnectWithOauth(subscription?.id as string, {
    enabled: false,
  });

  const [isModalOpen, setIsModalOpen] = useState<Modal>({ isOpen: false, type: 'new' });
  const [popup, setPopup] = useState<Window | null>(null);

  const [callback, setCallback] = useState<OauthFilter>({ code: '', state: '' });
  const [ownerProfileAnchorEl, setOwnerProfileAnchorEl] = useState<HTMLElement | null>(null);
  useCallbackOauth(callback, {
    enabled: callback.code !== '',
    cacheTime: 0,
  });

  const oauthMessageListener = (e: any) => {
    // 동일한 Origin 의 이벤트만 처리하도록 제한
    if (e.origin !== window.location.origin) {
      return;
    }
    const { code, state } = e.data;
    if (code && state) {
      setCallback({ code, state });
    }
    setPopup(null);
  };

  const handleClose = (oauth?: boolean | undefined) => {
    setIsModalOpen(prev => {
      return { ...prev, isOpen: false };
    });

    if (oauth) {
      //
    }
  };

  const handleAlertStore = (status: 'CONNETED' | 'CONNECTING' | 'WORKFLOW') => {
    switch (status) {
      case 'CONNETED':
        alertStore.open({
          title: t('Subscrib_Connect_Create_18'),
          message: t('Connection_Detail_01'),
          confirmName: `${t('Member_User_24')}`,
        });
        break;
      case 'CONNECTING':
        alertStore.open({
          title: t('Subscrib_Connect_Create_18'),
          message: t('Connection_Detail_02'),
          confirmName: `${t('Member_User_24')}`,
        });
        break;
      case 'WORKFLOW':
        alertStore.open({
          title: t('Subscrib_Connect_Create_18'),
          message: t('Subscrib_Connect_Create_08'),
          confirmName: `${t('Member_User_24')}`,
        });
    }
  };

  const handleClickConnectionDeleteBtn = () => {
    alertStore.open({
      title: t('Subscrib_Connect_06'),
      message: t('Subscrib_Connect_09'),
      useCancel: true,
      type: 'error',
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      onConfirm: () => {
        if (connection?.workflowCount) {
          handleAlertStore('WORKFLOW');
        } else if (connection?.status === 'CONNECTED') {
          handleAlertStore('CONNETED');
        } else if (connectMutatingCount > 0 || connection?.status === 'CONNECTING') {
          handleAlertStore('CONNECTING');
        } else {
          deleteConnection(undefined, {
            onSuccess: () => {
              toastStore.open({ message: t('Subscrib_Connect_Create_17') });
              navigate(`/manager/${authStore.curTenant.id}/subscriptions/connections`);
            },
            onError: (e: unknown) => {
              if (e instanceof AxiosError) {
                if (e.response?.data.errorCode.messageId === 'CONNECTION.ERROR.CANNOT_REMOVE_IN_CURRENT_STATUS') {
                  if (connection?.status === 'CONNECTED') {
                    handleAlertStore('CONNETED');
                  }
                } else if (
                  e.response?.data.errorCode.messageId === 'CONNECTION.ERROR.CANNOT_REMOVE_WHEN_WORKFLOW_EXIST'
                ) {
                  handleAlertStore('WORKFLOW');
                }
              }
            },
          });
        }
      },
    });
  };

  const handleClickDisconnect = () => {
    alertStore.open({
      title: t('Subscrib_Connect_05'),
      message: t('Subscrib_Connect_08'),
      useCancel: true,
      confirmName: t('Subscrib_Connect_Create_11'),
      type: 'error',
      onConfirm: () => disconnect(connection?.subscription.id as string),
    });
  };

  const connectSubscription = async (authType: string) => {
    if (authType === 'oauth2') {
      const { data: url } = await refetch();

      const popupWidth = 800;
      const popupHeight = 600;

      // 부모 창의 중앙에 맞게 팝업 창 위치 계산
      const left = window.screenX + (window.innerWidth - popupWidth) / 2;
      const top = window.screenY + (window.innerHeight - popupHeight) / 2;

      // 팝업 창의 위치와 크기 설정
      const windowFeatures = `width=${popupWidth},height=${popupHeight},top=${top},left=${left},resizable=yes,scrollbars=yes`;

      const popup = window.open(url, t('Connection_Detail_03'), windowFeatures);
      setPopup(popup);
    } else {
      connect(
        { subscriptionId: connection?.subscription.id as string, useAsyncJob: true },
        {
          onSuccess: data => {
            if (data.status === 'FAILED') {
              toastStore.open({ message: t('Subscrib_Connect_Create_15'), hideAction: true });
            }
          },
        },
      );
    }
  };

  const handleClickConnect = async () => {
    connectSubscription(connection?.connection.connectionInfo.authType);
  };

  const handleSubmitAppLogin = async (conn: SubscriptionConnectionModel) => {
    connectSubscription(conn.connection.connectionInfo.authType);
  };

  const handleClickConnectionModifyBtn = () => {
    if (connection?.status !== 'CONNECTED') {
      setIsModalOpen({ isOpen: true, type: 'modify' });
    } else {
      alertStore.open({
        title: t('Subscrib_Connect_Create_07'),
        message: t('Connection_Detail_02'),
        useCancel: true,
        confirmName: `${t('Member_User_24')}`,
      });
    }
  };

  const handleClickConnectionRefreshBtn = () => {
    synchronizeStatus(connection?.subscription.id as string);
  };

  useEffect(() => {
    if (!popup) {
      return;
    }
    window.addEventListener('message', oauthMessageListener, false);
  }, [popup]);

  return (
    <>
      <Container>
        <Header>
          <IconButton onClick={() => navigate(-1)}>
            <ChevronIcon rotateNum={270} />
          </IconButton>
          <strong className="title">커넥션 목록</strong>
        </Header>
        {isSubscriptionLoading ? null : (
          <Body>
            <AppBar>
              <Subscription>
                <StyledAvatar src={subscription?.software.iconUrl} />
                <div className="app-info">
                  <span className="app-name">{subscription?.software.name}</span>
                  <span className="subscription-name">{subscription?.name}</span>
                </div>
              </Subscription>
              <div className="config">
                <StyledButton
                  variant="outline"
                  size="large"
                  paddingHorizontal={24}
                  onClick={handleClickConnectionModifyBtn}
                >
                  <PencilIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
                  {t('Subscrib_Connect_Create_07')}
                </StyledButton>
                <StyledButton
                  variant="outline"
                  size="large"
                  paddingHorizontal={24}
                  onClick={handleClickConnectionDeleteBtn}
                >
                  <TrashIcon width={20} height={20} color={theme.colors['ic-red-light']} />
                  {t('Subscrib_Connect_06')}
                </StyledButton>
              </div>
            </AppBar>
            <Divider />
            <div className="divided">
              <div className="subscription">
                <strong className="name">{t('Subscrib_Detail_Overview_09')}</strong>
                <div className="detail">
                  <div className="values">
                    <div className="info">
                      <span className="info-name">
                        <FolderIcon width={20} height={20} color={theme.colors['ic-gray-light']} />{' '}
                        {t('Subscrib_Detail_UseRequest_06')}
                      </span>
                      <span className="info-value">
                        {categoryStore.findRootCategory(subscription?.randomCategory.id as string)?.name}
                      </span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <TitleIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Detail_UseRequest_07')}
                      </span>
                      <span className="info-value">{subscription?.software.name}</span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <FlagIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Main_12')}
                      </span>
                      <span className="info-value">{subscription?.plan.planName}</span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <ClockIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Edit_01')}
                      </span>
                      <span className="info-value">
                        {subscription?.commitmentPeriod} / {subscription?.paymentPeriod}
                      </span>
                    </div>
                    <div className="info period">
                      <span className="info-name">
                        <CalendarStartIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Detail_Overview_13')}
                      </span>
                      <div className="period_info_box">
                        <span className="info-value">
                          {dateFormat(subscription?.startDate, '-', t('DateFormat_YMD'))} ~{' '}
                          {dateFormat(subscription?.endDate, '-', t('DateFormat_YMD'))}
                        </span>
                        <Timezone>{`(${subscription?.timeZone})`}</Timezone>
                      </div>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <DescriptionIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Acc_Main_22')}
                      </span>
                      <span className="info-value">
                        {subscription?.isExternalSubscription ? t('Subscrib_Main_19') : '-'}
                      </span>
                    </div>
                  </div>
                  <DottedBorderIcon margin="24px 0" />
                  <div className="values">
                    <div className="info">
                      <span className="info-name">
                        <ManagerIcon width={20} height={20} color={theme.colors['ic-gray-light']} />{' '}
                        {t('Subscrib_Edit_08')}
                      </span>
                      <span className="info-value">
                        {subscription?.owner.name ? (
                          <OwnerNameButton
                            type="button"
                            onClick={event => setOwnerProfileAnchorEl(event.currentTarget)}
                            className={ownerProfileAnchorEl ? 'clicked' : ''}
                          >
                            {subscription?.owner.name}
                          </OwnerNameButton>
                        ) : (
                          '-'
                        )}
                      </span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <GroupIcon width={20} height={20} color={theme.colors['ic-gray-light']} />{' '}
                        {t('Subscrib_Main_16')}
                      </span>
                      <span className="info-value">{subscription?.userGroupName}</span>
                    </div>
                  </div>
                  <DottedBorderIcon margin="24px 0" />
                  <div className="values">
                    <div className="info">
                      <span className="info-name">
                        <BriefcaseIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Detail_Overview_14')}
                      </span>
                      <span className="info-value">{subscription?.salesManager.name || '-'}</span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <ToolsIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Detail_Overview_15')}
                      </span>
                      <span className="info-value">{subscription?.technicalSupportManager.name || '-'}</span>
                    </div>
                  </div>
                </div>
                {!subscription?.connection?.connectionId && (
                  <Footer>
                    <Button
                      size="large"
                      variant="contain"
                      fullWidth
                      onClick={() => setIsModalOpen({ isOpen: true, type: 'new' })}
                    >
                      {t('Subscrib_Connect_Create_02', { name: subscription?.software.name })}
                    </Button>
                  </Footer>
                )}
              </div>
              {connection?.connectionId && (
                <div className="connection">
                  <strong className="name">{t('Subscrib_Connect_Detail')}</strong>
                  <div className="detail">
                    {connection.status === 'CONNECTED' && (
                      <ConnectionType
                        className="connected"
                        onClick={handleClickDisconnect}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        {hover ? (
                          <>
                            <PauseIcon width={20} height={20} color={theme.colors['ic-white']} />
                            {t('Subscrib_Connect_Create_11')}
                          </>
                        ) : (
                          <>
                            <PlugfillIcon width={20} height={20} color={theme.colors['text-green']} />
                            {t('Subscrib_Connect_Create_05')}
                          </>
                        )}
                      </ConnectionType>
                    )}
                    {connection.status === 'DISCONNECTED' && (
                      <ConnectionType
                        className="pause"
                        onClick={handleClickConnect}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        {connectMutatingCount > 0 || isConnectionFetching ? (
                          <ConnectionType className="connecting">
                            <LoadingIcon />
                            {t('Connection_Detail_04')}
                          </ConnectionType>
                        ) : hover ? (
                          <>
                            <PlayIcon width={20} height={20} color={theme.colors['ic-white']} />
                            {t('Subscrib_Connect_Create_09')}
                          </>
                        ) : (
                          <>
                            <PauseIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
                            {t('Subscrib_Connect_Create_11')}
                          </>
                        )}
                      </ConnectionType>
                    )}
                    {connection.status === 'FAILED' && (
                      <ConnectionType
                        className="fail"
                        onClick={handleClickConnectionModifyBtn}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        {hover ? (
                          <>
                            <PlayIcon width={20} height={20} color={theme.colors['ic-white']} />
                            {t('Subscrib_Connect_Create_24')}
                          </>
                        ) : (
                          <>
                            <PlugFillSplashIcon width={20} height={20} color={theme.colors['ic-red-dark']} />
                            {t('Subscrib_Connect_Create_06')}
                          </>
                        )}
                      </ConnectionType>
                    )}
                    {connection.status === 'CONNECTING' && (
                      <ConnectionType
                        className="connecting"
                        onClick={handleClickConnectionRefreshBtn}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        {hover ? (
                          <>
                            <RefreshIcon width={20} height={20} color={theme.colors['ic-red-dark']} />
                            {t('Connection_Detail_05')}
                          </>
                        ) : (
                          <>
                            <LoadingIcon />
                            {t('Connection_Detail_04')}
                          </>
                        )}
                      </ConnectionType>
                    )}
                    <div className="info">
                      <span className="info-name">
                        <PlugIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Connect_Connector')}
                      </span>
                      <div style={{ display: 'flex', gap: 5 }}>
                        <span className="info-value">{connection.connector?.name ?? '-'}</span>
                        <Label variant="contain" color={connection.connector?.usageMethodLabelColor ?? 'green'}>
                          {connection.connector?.usageCollectMethod}
                        </Label>
                      </div>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <PersonIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Workflow_Main_06')}
                      </span>
                      <span className="info-value">{connection.creator?.name ?? '-'}</span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <CalendarStartIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Connect_02')}
                      </span>
                      <span className="info-value">{dateTimeFormat(connection.createdDate)}</span>
                    </div>
                    <div className="info">
                      <span className="info-name">
                        <WorkflowIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                        {t('Subscrib_Connect_03')}
                      </span>
                      <span className="info-value">{connection.workflowCount ?? 0}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Body>
        )}
        {subscription && (
          <AppLogin
            subscription={subscription}
            connection={connection}
            open={isModalOpen.isOpen}
            onClose={handleClose}
            type={isModalOpen.type}
            onSubmit={handleSubmitAppLogin}
          />
        )}
      </Container>
      <ProfileModal
        open={!!ownerProfileAnchorEl}
        anchorEl={ownerProfileAnchorEl}
        onClose={() => setOwnerProfileAnchorEl(null)}
        userId={subscription ? subscription.owner.id : ''}
      />
    </>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
`;

const Header = styled.div`
  display: flex;
  margin: 28px 0px 32px 0px;
  align-items: center;
  gap: 4px;
  & .title {
    ${fonts.Headline6};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  & .divided {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 32px;
    & .subscription {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 720px;
      min-width: 400px;
      & .name {
        ${fonts.Headline7};
        color: ${({ theme: { colors } }) => colors['text-gray-main']};
        margin-bottom: 12px;
      }
      & .detail {
        display: flex;
        flex-direction: column;
        border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
        border-radius: 5px;
        width: 100%;
        padding: 28px 24px;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);

        & .values {
          display: flex;
          flex-direction: column;
          & .info {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 32px;
            &.period {
              margin-bottom: 12px;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            & .period_info_box {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
            & .info-name {
              display: flex;
              flex-direction: row;
              gap: 12px;
              ${fonts.Body2};
              color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
            }
            & .info-value {
              ${fonts.Headline8};
              color: ${({ theme: { colors } }) => colors['text-gray-main']};
            }
          }
        }
      }
    }

    & .connection {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
      min-width: 250px;
      & .name {
        ${fonts.Headline7};
        color: ${({ theme: { colors } }) => colors['text-gray-main']};
        margin-bottom: 12px;
      }
      & .detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 24px 28px;
        background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
        border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
        border-radius: 5px;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
        & .info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          &:last-of-type {
            margin-bottom: 0;
          }
          & .info-name {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            ${fonts.Body2};
            color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
          }
          & .info-value {
            ${fonts.Headline8};
            color: ${({ theme: { colors } }) => colors['text-gray-main']};
          }
        }
      }
    }
  }
`;
const StyledButton = styled(Button)`
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  & > span {
    gap: 6px;
  }
  & + & {
    color: ${({ theme }) => theme.colors['text-red-light']};
  }
`;

const AppBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  & .config {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`;

const Subscription = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 2px 0;
  & .app-info {
    display: flex;
    flex-direction: column;
    & .app-name {
      ${fonts.Body1};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
    }
    & .subscription-name {
      ${fonts.Headline4};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 80px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-m']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  padding: 0px 24px;
`;

const ConnectionType = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  ${fonts.Button2};
  &.connected {
    background: ${props => props.theme.colors['bg-green-light']};
    border: 1px solid ${props => props.theme.colors['border-green-light']};
    color: ${props => props.theme.colors['text-green']};
    :hover {
      cursor: pointer;
      color: ${({ theme: { colors } }) => colors['text-white']};
      background: ${({ theme }) => theme.colors['bg-gradient']};
      border: none;
    }
  }
  &.fail {
    background: ${props => props.theme.colors['bg-red-lighter']};
    border: 1px solid ${props => props.theme.colors['border-red-lighter']};
    color: ${props => props.theme.colors['text-red']};
    :hover {
      cursor: pointer;
      color: ${({ theme: { colors } }) => colors['text-white']};
      background: ${({ theme }) => theme.colors['bg-gradient']};
      border: none;
    }
  }
  &.pause {
    background: ${props => props.theme.colors['bg-gray-light-m']};
    border: 1px solid ${props => props.theme.colors['border-gray-light']};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
    :hover {
      cursor: pointer;
      color: ${({ theme: { colors } }) => colors['text-white']};
      background: ${({ theme }) => theme.colors['bg-gradient']};
      border: none;
    }
  }
  &.connecting {
    background: ${props => props.theme.colors['bg-gradient']};
    border: none;
    color: ${props => props.theme.colors['text-white']};
    :hover {
      cursor: pointer;
      color: ${({ theme: { colors } }) => colors['text-red']};
      background: ${({ theme }) => theme.colors['bg-red-lighter']};
      border: 1px solid ${props => props.theme.colors['border-red-lighter']};
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 8px;
`;

const Timezone = styled.small`
  ${fonts.Body4}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
`;

const OwnerNameButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
  ${fonts.Underline3}

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.clicked {
    text-decoration: underline;
  }
`;
