import API from '@repositories/Axios';
import { Page } from '@type/Page';
import qs from 'qs';
import { injectable } from 'inversify';
import { RealtimeFilters, RealtimeUsage } from '../Types';
import 'reflect-metadata';
import IRealtimeUsageRepository from './IRealtimeUsageRepository';

@injectable()
export class RealtimeUsageRepository implements IRealtimeUsageRepository {
  async create(/* data: unknown */): Promise<RealtimeUsage> {
    throw new Error('Method not implemented.');
  }
  async getList(queries?: RealtimeFilters): Promise<Page<RealtimeUsage>> {
    const value = { ...queries };
    if (!value.latestOnly) value.latestOnly = true;

    const queryString = qs.stringify(value);
    const result = await API.get<Page<RealtimeUsage>>(`/api/v1/realtime-usages?${queryString}`);
    return result.data;
  }
}
