// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const CircleFillIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  rotateNum = 0,
  bgColor = '#fff',
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotateNum}deg)` }}
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.799805 12C0.799805 5.81446 5.81422 0.800049 11.9998 0.800049C18.1854 0.800049 23.1998 5.81446 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9286 10.3377L14.9173 12.2984C15.2794 12.6554 15.8635 12.6554 16.2255 12.2984C16.5915 11.9376 16.5915 11.3496 16.2255 10.9888L12.6541 7.46766C12.292 7.11072 11.708 7.11072 11.3459 7.46766L7.77449 10.9888C7.4085 11.3496 7.4085 11.9376 7.77449 12.2984C8.13653 12.6554 8.72061 12.6554 9.08265 12.2984L11.0714 10.3377L11.0714 16.2774C11.0714 16.7936 11.4939 17.2 12 17.2C12.5061 17.2 12.9286 16.7936 12.9286 16.2774L12.9286 10.3377Z"
          fill={bgColor}
        />
      </g>
    </svg>
  );
};

CircleFillIcon.displayName = 'CircleFillIcon';
