import dayjs, { Dayjs } from 'dayjs';
import { AdditionalPermission, User, UserRole } from '@repositories/userRepository';

export class UserModel {
  private readonly _accessToken: string;
  private readonly _additionalPermissions: AdditionalPermission[];
  private readonly _address: string;
  private readonly _companyId: string;
  private readonly _email: string;
  private readonly _id: string;
  private readonly _isDeleted: boolean;
  private readonly _lastLoginedAt?: Dayjs;
  private readonly _name: string;
  private readonly _phone: string;
  private readonly _roles: UserRole[];
  private readonly _username: string;
  private readonly _sources: string[];
  private readonly _createdDate?: Dayjs;
  private readonly _updatedDate?: Dayjs;

  constructor(dto?: User) {
    this._accessToken = dto?.accessToken || '';
    this._additionalPermissions = dto?.additionalPermissions || [];
    this._address = dto?.address || '';
    this._companyId = dto?.companyId || '';
    this._email = dto?.email || '';
    this._id = dto?.id || '';
    this._isDeleted = dto?.isDeleted || false;
    this._lastLoginedAt = dto?.lastLoginedAt ? dayjs(dto?.lastLoginedAt) : undefined;
    this._name = dto?.name || '';
    this._phone = dto?.phone || '';
    this._roles = dto?.roles || [];
    this._username = dto?.username || '';
    this._sources = dto?.sources || [];
    this._createdDate = dto?.createdDate ? dayjs(dto?.createdDate) : undefined;
    this._updatedDate = dto?.updatedDate ? dayjs(dto?.updatedDate) : undefined;
  }

  get accessToken() {
    return this._accessToken;
  }

  get additionalPermissions() {
    return this._additionalPermissions;
  }

  get address() {
    return this._address;
  }

  get companyId() {
    return this._companyId;
  }

  get email() {
    return this._email;
  }

  get id() {
    return this._id;
  }

  get isDeleted() {
    return this._isDeleted;
  }

  get lastLoginedAt() {
    return this._lastLoginedAt?.format('YYYY-MM-DD HH:mm');
  }

  get name() {
    return this._name;
  }

  get phone() {
    return this._phone;
  }

  get roles() {
    return this._roles;
  }

  get username() {
    return this._username;
  }

  get sources() {
    return this._sources;
  }

  get createdAt() {
    return this._createdDate?.format('YYYY-MM-DD HH:mm');
  }

  get updatedAt() {
    return this._updatedDate?.format('YYYY-MM-DD HH:mm');
  }
}
