// import { useTheme } from '@emotion/react';

const defaultWidth = 28;
const defaultHeight = 28;

export const SearchIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8559 5.13333C8.5909 5.13333 5.13345 8.59078 5.13345 12.8558C5.13345 17.1207 8.5909 20.5782 12.8559 20.5782C14.9867 20.5782 16.9142 19.7166 18.3125 18.3203C19.7135 16.9213 20.5783 14.9905 20.5783 12.8558C20.5783 8.59078 17.1209 5.13333 12.8559 5.13333ZM3.03345 12.8558C3.03345 7.43098 7.4311 3.03333 12.8559 3.03333C18.2807 3.03333 22.6783 7.43098 22.6783 12.8558C22.6783 15.1914 21.8621 17.338 20.5008 19.0234L24.6586 23.1735C25.069 23.5832 25.0696 24.248 24.6599 24.6584C24.2503 25.0689 23.5854 25.0695 23.175 24.6598L19.0151 20.5075C17.3309 21.8647 15.1877 22.6782 12.8559 22.6782C7.4311 22.6782 3.03345 18.2805 3.03345 12.8558Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SearchIcon.displayName = 'SearchIcon';
