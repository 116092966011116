import { ForwardedRef, forwardRef } from 'react';

const defaultWidth = 20;
const defaultHeight = 20;

export const CalculateIcon = forwardRef(
  (
    { width = defaultWidth, height = defaultHeight, color = '#24292E', ...props }: SvgProps,
    ref: ForwardedRef<SVGSVGElement>,
  ) => (
    <svg
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M3.12496 1.61663C2.29193 1.61663 1.61663 2.29193 1.61663 3.12496V16.875C1.61663 17.708 2.29193 18.3833 3.12496 18.3833H16.875C17.708 18.3833 18.3833 17.708 18.3833 16.875V3.12496C18.3833 2.29193 17.708 1.61663 16.875 1.61663H3.12496ZM2.96663 3.12496C2.96663 3.03751 3.03751 2.96663 3.12496 2.96663H16.875C16.9624 2.96663 17.0333 3.03751 17.0333 3.12496V16.875C17.0333 16.9624 16.9624 17.0333 16.875 17.0333H3.12496C3.03751 17.0333 2.96663 16.9624 2.96663 16.875V3.12496Z"
          fill={color}
          stroke={color}
          strokeWidth="0.1"
        />
        <rect x="4.58337" y="5.95068" width="4.96528" height="1.35417" rx="0.677083" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5799 11.0063C11.206 11.0063 10.9028 11.3095 10.9028 11.6834C10.9028 12.0574 11.206 12.3605 11.5799 12.3605H14.7396C15.1136 12.3605 15.4167 12.0574 15.4167 11.6834C15.4167 11.3095 15.1136 11.0063 14.7396 11.0063H11.5799ZM11.5799 13.2632C11.206 13.2632 10.9029 13.5663 10.9029 13.9402C10.9029 14.3142 11.206 14.6173 11.5799 14.6173H14.7397C15.1136 14.6173 15.4168 14.3142 15.4168 13.9402C15.4168 13.5663 15.1136 13.2632 14.7397 13.2632H11.5799Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.74313 10.8709C7.74313 10.497 7.43999 10.1938 7.06605 10.1938C6.69211 10.1938 6.38896 10.497 6.38896 10.8709L6.38896 11.9995H5.26046C4.88651 11.9995 4.58337 12.3027 4.58337 12.6766C4.58337 13.0506 4.88652 13.3537 5.26046 13.3537H6.38896L6.38896 14.482C6.38896 14.856 6.69211 15.1591 7.06605 15.1591C7.43999 15.1591 7.74313 14.856 7.74313 14.482V13.3537H8.87157C9.24551 13.3537 9.54865 13.0506 9.54865 12.6766C9.54865 12.3027 9.24551 11.9995 8.87157 11.9995H7.74313V10.8709Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2392 4.69831C11.9748 4.4339 11.5461 4.4339 11.2817 4.69831C11.0173 4.96273 11.0173 5.39144 11.2817 5.65585L12.208 6.58214L11.2898 7.50033C11.0254 7.76474 11.0254 8.19345 11.2898 8.45787C11.5542 8.72228 11.9829 8.72228 12.2473 8.45787L13.1655 7.53968L14.0794 8.45357C14.3438 8.71799 14.7725 8.71799 15.0369 8.45357C15.3014 8.18915 15.3014 7.76045 15.0369 7.49603L14.1231 6.58214L15.0492 5.65596C15.3137 5.39155 15.3137 4.96284 15.0492 4.69842C14.7848 4.434 14.3561 4.43401 14.0917 4.69842L13.1655 5.6246L12.2392 4.69831Z"
          fill={color}
        />
      </g>
    </svg>
  ),
);

CalculateIcon.displayName = 'CalculateIcon';
