import qs from 'qs';
import type {
  Contract,
  ContractCreateRq,
  ContractFilter,
  ContractUpdateRq,
  ContractUpdateStatusRq,
} from '@repositories/contactRepository';
import type {
  Filters as SoftwareFilters,
  SoftwareCreateRq,
  SoftwareSimple,
  Software,
} from '@repositories/softwareRepository/Types';
import type { Subscription, UsageCollector } from '@repositories/subscriptionRepository';
import type {
  DailyFilters,
  DailyUsageDTO,
  HourlyFilters,
  HourlyUsage,
  MonthlyFilters,
  MonthlyUsageDTO,
  RealtimeFilters,
  RealtimeUsage,
} from '@repositories/usage';
import { S3File } from '@type/Common';
import { JobDto } from '@type/Job';
import { Page } from '@type/Page';
import { Provider, ProviderCreateRq, ProviderFilter, ProviderParams } from '@type/Provider';
import {
  Purchase,
  PurchaseContract,
  PurchaseCreateRq,
  PurchaseFilter,
  PurchaseParams,
  SoftwareMonthlyPurchaseGen,
  SoftwareMonthlyPurchaseGenRq,
  SoftwarePurchaseChangeStatus,
  SoftwarePurchaseContractCreateRq,
  SoftwarePurchaseContractFilter,
  SoftwarePurchaseParams,
  SoftwarePurchaseUpdateRq,
  StatusChangeRq,
  SubscriptionPurchase,
  SubscriptionPurchaseCreateRq,
  SubscriptionPurchaseFilter,
  SubscriptionPurchaseGen,
  SubscriptionPurchaseGenRq,
  SubscriptionPurchaseParams,
  SubscriptionPurchaseStatusChangeRq,
  SubscriptionPurchaseUpdateRq,
} from '@type/Purchase';
import { ImportSubscriptionMonthlySalesCreateRq } from '@type/Sale';
import {
  ImportSubscriptionSales,
  Sales,
  SalesCreateRq,
  SalesFilter,
  SalesParams,
  SubscriptionSalesChangeCreateRq,
  SubscriptionSalesGen,
  SubscriptionSalesGenRq,
} from '@type/Sales';
import {
  GetMonthlySalesStatFilter,
  MonthlySalesStat,
  MonthlyPurchaseAggregate,
  MonthlyPurchaseStat,
  SoftwareShareAggregate,
  SubscriptionMonthlyAggregate,
  SubscriptionMonthlyPurchaseStat,
  TenantShareAggregate,
  InOutcomeShareStat,
  MonthlyPurchaseByTypeStat,
  MonthlySalesAggregate,
  MonthlySalesByTypeStat,
  GetProfitStatFilter,
  MonthlyProfitStat,
  OverallProfitSummaryStat,
  MonthlyProfitAggregate,
  MonthlyProfitByTypeStat,
} from '@type/Statistics';
import { CollectorCreateRq } from '@type/Subscription';
import {
  SoftwareYearPlan,
  SoftwareYearPlanCreateRq,
  SoftwareYearPlanFilter,
  SoftwareYearPlanParams,
  YearPlan,
  YearPlanCreateRq,
  YearPlanFilter,
  YearPlanParams,
} from '@type/YearPlan';
import API from './Axios';
import { Tenant } from './tenantRepository';

const APIs = {
  /**
   * Subscription Controller
   */
  Subscription: {
    getOne: async (id: string): Promise<Subscription> => {
      const result = await API.get<Subscription>(`/api/v1/subscriptions/${id}`);
      return result.data;
    },
    versionUp: async (id: string): Promise<Subscription> => {
      const result = await API.post<Subscription>(`/api/v1/subscriptions/${id}/versions`);
      return result.data;
    },
  },

  /**
   * Software Controller
   */
  Software: {
    getList: async (queries?: SoftwareFilters): Promise<Page<SoftwareSimple>> => {
      const queryString = qs.stringify(queries, { arrayFormat: 'repeat' });
      const result = await API.get<Page<SoftwareSimple>>(`/api/v1/softwares?${queryString}`);
      return result.data;
    },
    create: async (data: SoftwareCreateRq, iconImage?: Blob, thumbnail?: Blob): Promise<Software> => {
      const formData = new FormData();
      formData.append('software', new Blob([JSON.stringify(data)], { type: 'application/json' }));
      if (iconImage) formData.append('iconImage', iconImage);
      if (thumbnail) formData.append('thumbnail', thumbnail);
      const result = await API.post<Software>('/api/v1/softwares', formData);
      return result.data;
    },
    getOne: async (id: string): Promise<Software> => {
      const result = await API.get<Software>(`/api/v1/softwares/${id}`);
      return result.data;
    },
    update: async (data: SoftwareCreateRq, id: string, iconImage?: Blob, thumbnail?: Blob): Promise<Software> => {
      const formData = new FormData();
      formData.append('software', new Blob([JSON.stringify(data)], { type: 'application/json' }));
      if (iconImage) formData.append('iconImage', iconImage);
      if (thumbnail) formData.append('thumbnail', thumbnail);

      const result = await API.post<Software>(`/api/v1/softwares/${id}`, formData);

      return result.data;
    },
    delete: async (id: string): Promise<void> => {
      await API.delete<void>(`/api/v1/softwares/${id}`);
    },
  },

  /**
   * DailyUsage Controller
   */
  RealtimeUsage: {
    getRealtimes: async (queries: RealtimeFilters): Promise<Page<RealtimeUsage>> => {
      const value = { ...queries };
      if (!value.latestOnly) value.latestOnly = true;

      const queryString = qs.stringify(value);
      const result = await API.get<Page<RealtimeUsage>>(`/api/v1/realtime-usages?${queryString}`);
      return result.data;
    },
  },

  /**
   * HourlyUsage Controller
   */
  HourlyUsage: {
    getHourly: async (queries: HourlyFilters): Promise<Page<HourlyUsage>> => {
      const queryString = qs.stringify(queries);

      const reuslt = await API.get<Page<HourlyUsage>>(`/api/v1/hourly-usages?${queryString}`);
      return reuslt.data;
    },
  },

  /**
   * DailyUsage Controller
   */
  DailyUsage: {
    getDaily: async (queries: DailyFilters): Promise<Page<DailyUsageDTO>> => {
      const queryString = qs.stringify(queries);

      const result = await API.get<Page<DailyUsageDTO>>(`/api/v1/daily-usages?${queryString}`);
      return result.data;
    },
  },

  /**
   * MonthlyUsage Controller
   */
  MonthlyUsage: {
    getMontly: async (queries: MonthlyFilters): Promise<Page<MonthlyUsageDTO>> => {
      const queryString = qs.stringify(queries);

      const result = await API.get<Page<MonthlyUsageDTO>>(`/api/v1/monthly-usages?${queryString}`);
      return result.data;
    },
  },

  /**
   * Contract Controller
   */
  SoftwareContract: {
    getList: async (queries: ContractFilter): Promise<Page<Contract>> => {
      const queryString = qs.stringify(queries);

      const result = await API.get<Page<Contract>>(`/api/v1/software-contracts?${queryString}`);
      return result.data;
    },
    getOne: async (id: string): Promise<Contract> => {
      const result = await API.get<Contract>(`/api/v1/software-contracts/${id}`);
      return result.data;
    },
    create: async (data: ContractCreateRq): Promise<Contract> => {
      const result = await API.post<Contract>(`/api/v1/software-contracts`, data);
      return result.data;
    },
    delete: async (id: string): Promise<void> => {
      await API.delete<void>(`/api/v1/software-contracts/${id}`);
    },
    update: async (data: ContractUpdateRq, id: ContractId): Promise<Contract> => {
      const result = await API.put<Contract>(`/api/v1/software-contracts/${id}`, data);
      return result.data;
    },
    updateStatus: async (data: ContractUpdateStatusRq, id: ContractId): Promise<Contract> => {
      const result = await API.put<Contract>(`/api/v1/software-contracts/${id}/status`, data);
      return result.data;
    },
  },

  /**
   * Usage-collector Controller
   */
  Collector: {
    create: async (data: CollectorCreateRq): Promise<UsageCollector> => {
      const result = await API.put<UsageCollector>(
        `/api/v1/subscriptions/${data.subscriptionId}/usage-collector`,
        data,
      );
      return result.data;
    },
    delete: async (subscriptionId: string): Promise<void> => {
      await API.delete<void>(`/api/v1/subscriptions/${subscriptionId}/usage-collector`);
    },
    start: async (subscriptionId: string): Promise<UsageCollector> => {
      const result = await API.post<UsageCollector>(`/api/v1/actions/start-usage-collector`, { subscriptionId });
      return result.data;
    },
    stop: async (subscriptionId: string): Promise<UsageCollector> => {
      const result = await API.post<UsageCollector>(`/api/v1/actions/stop-usage-collector`, { subscriptionId });
      return result.data;
    },
  },

  /**
   * Software-monthly-purchase Controller
   */
  SoftwarePurchase: {
    getList: async (queries: PurchaseFilter): Promise<Page<Purchase>> => {
      const queryString = qs.stringify(queries);

      const result = await API.get<Page<Purchase>>(`/api/v1/software-monthly-purchases?${queryString}`);
      return result.data;
    },
    getOne: async (params: PurchaseParams): Promise<Purchase> => {
      const result = await API.get<Purchase>(
        `/api/v1/software-monthly-purchases/${params.contractId}/monthly-purchases/${params.month}`,
      );
      return result.data;
    },
    create: async (data: PurchaseCreateRq): Promise<Purchase> => {
      const result = await API.post<Purchase>(`/api/v1/software-monthly-purchases`, data);
      return result.data;
    },
    update: async (params: PurchaseParams, data: PurchaseCreateRq): Promise<Purchase> => {
      const result = await API.put<Purchase>(
        `/api/v1/software-monthly-purchases/${params.contractId}/monthly-purchases/${params.month}`,
        data,
      );
      return result.data;
    },
    delete: async (params: PurchaseParams): Promise<void> => {
      await API.delete<void>(
        `/api/v1/software-monthly-purchases/${params.contractId}/monthly-purchases/${params.month}`,
      );
    },
    changeStatus: async (params: PurchaseParams, data: StatusChangeRq): Promise<Purchase> => {
      const result = await API.put<Purchase>(
        `/api/v1/software-monthly-purchases/${params.contractId}/monthly-purchases/${params.month}/status`,
        data,
      );
      return result.data;
    },
    generate: async (data: SoftwareMonthlyPurchaseGenRq): Promise<SoftwareMonthlyPurchaseGen> => {
      const result = await API.post<SoftwareMonthlyPurchaseGen>(
        `/api/v1/actions/generate-software-monthly-purchase`,
        data,
      );
      return result.data;
    },
  },

  /**
   * Subscription-monthly-purchase Controller
   */
  SubscriptionPurchase: {
    getList: async (queries: SubscriptionPurchaseFilter): Promise<Page<SubscriptionPurchase>> => {
      const queryString = qs.stringify(queries);

      const result = await API.get<Page<SubscriptionPurchase>>(`/api/v1/subscription-monthly-purchases?${queryString}`);
      return result.data;
    },
    create: async (data: SubscriptionPurchaseCreateRq): Promise<SubscriptionPurchase> => {
      const result = await API.post<SubscriptionPurchase>(`/api/v1/subscription-monthly-purchases`, data);
      return result.data;
    },
    update: async (
      params: SubscriptionPurchaseParams,
      data: SubscriptionPurchaseUpdateRq,
    ): Promise<SubscriptionPurchase> => {
      const result = await API.put<SubscriptionPurchase>(
        `/api/v1/subscription-monthly-purchases/${params.subscriptionId}/purchase-contracts/${params.purchaseContractId}/monthly-purchases/${params.month}`,
        data,
      );
      return result.data;
    },
    changeStatus: async (
      params: SubscriptionPurchaseParams,
      data: SubscriptionPurchaseStatusChangeRq,
    ): Promise<SubscriptionPurchase> => {
      const result = await API.put<SubscriptionPurchase>(
        `/api/v1/subscription-monthly-purchases/${params.subscriptionId}/purchase-contracts/${params.purchaseContractId}/monthly-purchases/${params.month}/status`,
        data,
      );
      return result.data;
    },
    delete: async (params: SubscriptionPurchaseParams): Promise<void> => {
      await API.delete<void>(
        `/api/v1/subscription-monthly-purchases/${params.subscriptionId}/purchase-contracts/${params.purchaseContractId}/monthly-purchases/${params.month}`,
      );
    },
    generate: async (data: SubscriptionPurchaseGenRq): Promise<SubscriptionPurchaseGen> => {
      const result = await API.post<SubscriptionPurchaseGen>(
        `/api/v1/actions/generate-subscription-monthly-purchase`,
        data,
      );
      return result.data;
    },
  },

  /**
   * Overall-yearly-plan Controller
   */
  YearPlan: {
    getList: async (queries: YearPlanFilter): Promise<Page<YearPlan>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<Page<YearPlan>>(`/api/v1/overall-yearly-plans?${queryString}`);
      return result.data;
    },
    getOne: async (params: YearPlanParams): Promise<YearPlan> => {
      const result = await API.get<YearPlan>(`/api/v1/overall-yearly-plans/${params.year}`);
      return result.data;
    },
    create: async (data: YearPlanCreateRq): Promise<YearPlan> => {
      const result = await API.post<YearPlan>(`/api/v1/overall-yearly-plans`, data);
      return result.data;
    },
    update: async (params: YearPlanParams, data: YearPlanCreateRq): Promise<YearPlan> => {
      const result = await API.put<YearPlan>(`/api/v1/overall-yearly-plans/${params.year}`, data);
      return result.data;
    },
    delete: async (params: YearPlanParams): Promise<void> => {
      await API.delete<YearPlan>(`/api/v1/overall-yearly-plans/${params.year}`);
    },
  },

  /**
   * Software-yearly-plan Controller
   */
  SoftwareYearPlan: {
    getList: async (quries: SoftwareYearPlanFilter): Promise<Page<SoftwareYearPlan>> => {
      const queryString = qs.stringify(quries);
      const result = await API.get<Page<SoftwareYearPlan>>(`/api/v1/software-yearly-plans?${queryString}`);
      return result.data;
    },
    getOne: async (params: SoftwareYearPlanParams): Promise<SoftwareYearPlan> => {
      const result = await API.get<SoftwareYearPlan>(
        `/api/v1/software-yearly-plans/${params.softwareId}/yearly-plans/${params.year}`,
      );
      return result.data;
    },
    create: async (data: SoftwareYearPlanCreateRq): Promise<SoftwareYearPlan> => {
      const result = await API.post<SoftwareYearPlan>(`/api/v1/software-yearly-plans`, data);
      return result.data;
    },
    update: async (params: SoftwareYearPlanParams, data: SoftwareYearPlanCreateRq): Promise<SoftwareYearPlan> => {
      const result = await API.put<SoftwareYearPlan>(
        `/api/v1/software-yearly-plans/${params.softwareId}/yearly-plans/${params.year}`,
        data,
      );
      return result.data;
    },
    delete: async (params: SoftwareYearPlanParams): Promise<void> => {
      await API.delete<void>(`/api/v1/software-yearly-plans/${params.softwareId}/yearly-plans/${params.year}`);
    },
  },

  /**
   * Software-provider Controller
   */
  Provider: {
    getList: async (quries?: ProviderFilter): Promise<Page<Provider>> => {
      const queryString = qs.stringify(quries);
      const result = await API.get<Page<Provider>>(`/api/v1/software-providers?${queryString}`);
      return result.data;
    },
    getOne: async (params: ProviderParams): Promise<Provider> => {
      const result = await API.get<Provider>(`/api/v1/software-providers/${params.id}`);
      return result.data;
    },
    create: async (data: ProviderCreateRq): Promise<Provider> => {
      const result = await API.post<Provider>(`/api/v1/software-providers`, data);
      return result.data;
    },
    update: async (params: ProviderParams, data: ProviderCreateRq): Promise<Provider> => {
      const result = await API.put<Provider>(`/api/v1/software-providers/${params.id}`, data);
      return result.data;
    },
    delete: async (params: ProviderParams): Promise<void> => {
      await API.delete<void>(`/api/v1/software-providers/${params.id}`);
    },
  },

  /**
   * Software-purchase-contract Controller
   */
  PurchaseContract: {
    getList: async (quries?: SoftwarePurchaseContractFilter): Promise<Page<PurchaseContract>> => {
      const queryString = qs.stringify(quries);
      const result = await API.get<Page<PurchaseContract>>(`/api/v1/software-purchase-contracts?${queryString}`);
      return result.data;
    },
    getOne: async (params: SoftwarePurchaseParams): Promise<PurchaseContract> => {
      const result = await API.get<PurchaseContract>(`/api/v1/software-purchase-contracts/${params.id}`);
      return result.data;
    },
    create: async (data: SoftwarePurchaseContractCreateRq): Promise<PurchaseContract> => {
      const result = await API.post<PurchaseContract>(`/api/v1/software-purchase-contracts`, data);
      return result.data;
    },
    update: async (params: SoftwarePurchaseParams, data: SoftwarePurchaseUpdateRq): Promise<PurchaseContract> => {
      const result = await API.put<PurchaseContract>(`/api/v1/software-purchase-contracts/${params.id}`, data);
      return result.data;
    },
    delete: async (params: SoftwarePurchaseParams): Promise<void> => {
      await API.delete<void>(`/api/v1/software-purchase-contracts/${params.id}`);
    },
    changeStatus: async (
      params: SoftwarePurchaseParams,
      data: SoftwarePurchaseChangeStatus,
    ): Promise<PurchaseContract> => {
      const result = await API.put<PurchaseContract>(`/api/v1/software-purchase-contracts/${params.id}/status`, data);
      return result.data;
    },
  },

  /**
   * Subscription-monthly-sales Controller
   */
  SubscriptionSales: {
    getList: async (quries?: SalesFilter): Promise<Page<Sales>> => {
      const queryString = qs.stringify(quries);
      const result = await API.get<Page<Sales>>(`/api/v1/subscription-monthly-sales?${queryString}`);
      return result.data;
    },
    create: async (data: SalesCreateRq): Promise<Sales> => {
      const result = await API.post<Sales>(`/api/v1/subscription-monthly-sales`, data);
      return result.data;
    },
    update: async (params: SalesParams, data: SalesCreateRq): Promise<Sales> => {
      const result = await API.put<Sales>(
        `/api/v1/subscription-monthly-sales/${params.subscriptionId}/monthly-sales/${params.month}`,
        data,
      );
      return result.data;
    },
    delete: async (params: SalesParams): Promise<void> => {
      await API.delete<void>(
        `/api/v1/subscription-monthly-sales/${params.subscriptionId}/monthly-sales/${params.month}`,
      );
    },
    import: async (data: ImportSubscriptionMonthlySalesCreateRq): Promise<ImportSubscriptionSales> => {
      const result = await API.post<ImportSubscriptionSales>(`/api/v1/action/import-subscription-monthly-sales`, data);
      return result.data;
    },
    changeStatus: async (params: SalesParams, data: SubscriptionSalesChangeCreateRq): Promise<Sales> => {
      const result = await API.put<Sales>(
        `/api/v1/subscription-monthly-sales/${params.subscriptionId}/monthly-sales/${params.month}/status`,
        data,
      );
      return result.data;
    },
    generate: async (data: SubscriptionSalesGenRq): Promise<SubscriptionSalesGen> => {
      const result = await API.post<SubscriptionSalesGen>(`/api/v1/actions/generate-subscription-monthly-sales`, data);
      return result.data;
    },
  },

  /**
   * Sales-stat Controller
   */
  SalesStat: {
    getMonthlySalesStat: async (queries?: MonthlySalesAggregate): Promise<MonthlySalesStat> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlySalesStat>(`/api/v1/statistics/monthly-sales?${queryString}`);
      return result.data;
    },
    getMonthlySalesBySoftwareStat: async (
      queries?: MonthlySalesAggregate,
    ): Promise<MonthlySalesByTypeStat<Software>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlySalesByTypeStat<Software>>(
        `/api/v1/statistics/monthly-sales-by-software?${queryString}`,
      );
      return result.data;
    },
    getMonthlySalesByTenantStat: async (queries?: MonthlySalesAggregate): Promise<MonthlySalesByTypeStat<Tenant>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlySalesByTypeStat<Tenant>>(
        `/api/v1/statistics/monthly-sales-by-tenant?${queryString}`,
      );
      return result.data;
    },
    getSoftwareSalesShare: async (queries?: GetMonthlySalesStatFilter): Promise<InOutcomeShareStat<Software>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<InOutcomeShareStat<Software>>(
        `/api/v1/statistics/software-sales-share?${queryString}`,
      );
      return result.data;
    },
    getTenantSalesShareStat: async (queries?: GetMonthlySalesStatFilter): Promise<InOutcomeShareStat<Tenant>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<InOutcomeShareStat<Tenant>>(`/api/v1/statistics/tenant-sales-share?${queryString}`);
      return result.data;
    },
  },

  /**
   * Purchase-stat-Controller
   */
  PurchaseStat: {
    getMonthlyPurchaseStat: async (queries?: MonthlyPurchaseAggregate): Promise<MonthlyPurchaseStat> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlyPurchaseStat>(`/api/v1/statistics/monthly-purchase?${queryString}`);
      return result.data;
    },
    getSubscriptionMonthlyPurchaseStat: async (
      queries?: SubscriptionMonthlyAggregate,
    ): Promise<SubscriptionMonthlyPurchaseStat> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<SubscriptionMonthlyPurchaseStat>(
        `/api/v1/statistics/subscription-monthly-purchase?${queryString}`,
      );
      return result.data;
    },
    getMonthlyPurchaseBySoftwareStat: async (
      queries?: MonthlyPurchaseAggregate,
    ): Promise<MonthlyPurchaseByTypeStat<Software>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlyPurchaseByTypeStat<Software>>(
        `/api/v1/statistics/monthly-purchase-by-software?${queryString}`,
      );
      return result.data;
    },
    getMonthlyPurchaseByTenantStat: async (
      queries?: MonthlyPurchaseAggregate,
    ): Promise<MonthlyPurchaseByTypeStat<Tenant>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlyPurchaseByTypeStat<Tenant>>(
        `/api/v1/statistics/monthly-purchase-by-tenant?${queryString}`,
      );
      return result.data;
    },
    getSoftwarePurchaseShareStat: async (queries?: SoftwareShareAggregate): Promise<InOutcomeShareStat<Software>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<InOutcomeShareStat<Software>>(
        `/api/v1/statistics/software-purchase-share?${queryString}`,
      );
      return result.data;
    },
    getTenantPurchaseShareStat: async (queries?: TenantShareAggregate): Promise<InOutcomeShareStat<Tenant>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<InOutcomeShareStat<Tenant>>(
        `/api/v1/statistics/tenant-purchase-share?${queryString}`,
      );
      return result.data;
    },
  },

  /**
   * Profit-stat Controller
   */
  ProfitStat: {
    getMonthlyProfitStat: async (queries?: GetProfitStatFilter): Promise<MonthlyProfitStat> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlyProfitStat>(`/api/v1/statistics/monthly-profit?${queryString}`);
      return result.data;
    },
    getMonthlyProfitBySoftwareStat: async (
      queries?: MonthlyProfitAggregate,
    ): Promise<MonthlyProfitByTypeStat<Software>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlyProfitByTypeStat<Software>>(
        `/api/v1/statistics/monthly-profit-by-software?${queryString}`,
      );
      return result.data;
    },
    getMonthlyProfitByTenantStat: async (
      queries?: MonthlyProfitAggregate,
    ): Promise<MonthlyProfitByTypeStat<Tenant>> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<MonthlyProfitByTypeStat<Tenant>>(
        `/api/v1/statistics/monthly-profit-by-tenant?${queryString}`,
      );
      return result.data;
    },
    getOverallProfitSummaryStat: async (queries?: { year: DateTimeString }): Promise<OverallProfitSummaryStat> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<OverallProfitSummaryStat>(
        `/api/v1/statistics/overall-profit-summary?${queryString}`,
      );
      return result.data;
    },
    getSoftwareProfitSummaryStat: async (queries?: {
      year: DateTimeString;
      softwareId: string;
    }): Promise<OverallProfitSummaryStat> => {
      const queryString = qs.stringify(queries);
      const result = await API.get<OverallProfitSummaryStat>(
        `/api/v1/statistics/software-profit-summary?${queryString}`,
      );
      return result.data;
    },
  },

  /**
   * Dashboard Controller
   */
  Dashboard: {
    getTableauDashboardView: async (body: { viewName: string; workbookName: string }): Promise<{ url: string }> => {
      const result = await API.post<{ url: string }>(`/api/v1/tableau-dashboard-views`, body);
      return result.data;
    },
  },

  /**
   * Recommendation Controller
   */
  Recommendation: {
    getRecommendationList: async (): Promise<Page<{ score: number; software: Software; userId?: UserId }>> => {
      const result = await API.get<Page<{ score: number; software: Software; userId?: UserId }>>(
        `/api/v1/recommendations?type=ALL`,
      );
      return result.data;
    },
  },

  /**
   * S3 Controller
   */
  S3: {
    uploadFiles: async (files: FileList): Promise<S3File[]> => {
      const formData = new FormData();

      Array.from(files).forEach(file => formData.append('files', file));
      const result = await API.post<S3File[]>(`/api/v1/actions/files`, formData);
      return result.data;
    },
    deleteFile: async (key: string): Promise<void> => {
      const queryString = qs.stringify({ key });
      const result = await API.delete<void>(`/api/v1/actions/files?${queryString}`);
      return result.data;
    },
  },

  /*
   * Promotion Controller
   */
  Promotion: {
    getPromotionList: async (): Promise<Page<{ bannerImageUrl: string; idx: number; softwareId: SoftwareId }>> => {
      const result = await API.get<Page<{ bannerImageUrl: string; idx: number; softwareId: SoftwareId }>>(
        `/api/v1/promotions`,
      );
      return result.data;
    },
  },

  /**
   * Job Controller
   */
  Job: {
    getJobList: async (): Promise<JobDto[]> => {
      const result = await API.get<JobDto[]>(`/api/v1/jobs`);
      return result.data;
    },
  },
};

export default APIs;
