import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type IWorkflowRepository from '@repositories/workflowRepository/IWorkflowRepository';
import type {
  WorkflowCreateDTO,
  WorkflowExecCreateDTO,
  WorkflowExecFilter,
  WorkflowsFilter,
} from '@repositories/workflowRepository/Types';
import type { Page } from '@type/Page';
import { WorkflowExecModel } from '@models/workflow/WorkflowExecModel';
import { WorkflowModel } from '@models/workflow/WorkflowModel';

const workflowRepo = repository.get<IWorkflowRepository>(KEYS.WORKFLOW_REPOSITORY);
export const workflowQueryKey = {
  all: ['workflow'] as const,
  lists: () => [workflowQueryKey.all, 'list'] as const,
  list: (filter?: WorkflowsFilter) => [...workflowQueryKey.lists(), filter] as const,
  details: () => [...workflowQueryKey.all, 'detail'] as const,
  detail: (workflowId: string) => [...workflowQueryKey.details(), workflowId] as const,
  listInfiniteExecutions: (workflowId: string, filter?: WorkflowExecFilter) =>
    [...workflowQueryKey.details(), workflowId, filter] as const,
  detailExecution: (workflowId: string, workflowExecId: string) =>
    [...workflowQueryKey.details(), workflowId, workflowExecId] as const,
};

export const useGetWorkflows = (queries?: WorkflowsFilter, options?: UseQueryOptionsType<Page<WorkflowModel>>) => {
  return useQuery<Page<WorkflowModel>, AxiosError>({
    queryKey: workflowQueryKey.list(queries),
    queryFn: async () => {
      const workflows = await workflowRepo.getList(queries);
      return {
        ...workflows,
        content: workflows.content?.map(dto => new WorkflowModel(dto)),
      };
    },
    ...options,
  });
};

export const useGetWorkflow = (workflowId: string, options?: UseQueryOptionsType<WorkflowModel>) => {
  return useQuery<WorkflowModel, AxiosError>({
    queryKey: workflowQueryKey.detail(workflowId),
    queryFn: async () => {
      const workflow = await workflowRepo.getOne(workflowId);
      return new WorkflowModel(workflow);
    },
    ...options,
  });
};

export const useAddWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: WorkflowCreateDTO) => {
      const workflow = await workflowRepo.create(rqData);
      return new WorkflowModel(workflow);
    },
    onSuccess: data => {
      queryClient.invalidateQueries(workflowQueryKey.lists());
      queryClient.invalidateQueries(workflowQueryKey.detail(data.id));
    },
  });
};

export const useDeleteWorkflow = (workflowId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const result = await workflowRepo.delete(workflowId);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowQueryKey.lists());
      queryClient.invalidateQueries(workflowQueryKey.detail(workflowId));
    },
  });
};

export const useUpdateWorkflow = (workflowId: string, name?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [workflowId, name],
    mutationFn: async (rqData: WorkflowCreateDTO) => {
      const workflow = await workflowRepo.update(workflowId, rqData);
      return new WorkflowModel(workflow);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowQueryKey.lists());
      queryClient.invalidateQueries(workflowQueryKey.detail(workflowId));
    },
    cacheTime: 500,
  });
};

export const useActiveWorkflow = (workflowId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const workflow = await workflowRepo.active(workflowId);
      return new WorkflowModel(workflow);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowQueryKey.lists());
      queryClient.invalidateQueries(workflowQueryKey.detail(workflowId));
    },
  });
};

export const useDeactiveWorkflow = (workflowId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const workflow = await workflowRepo.deActive(workflowId);
      return new WorkflowModel(workflow);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowQueryKey.lists());
      queryClient.invalidateQueries(workflowQueryKey.detail(workflowId));
    },
  });
};

export const useExecuteWorkflow = (workflowId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: WorkflowExecCreateDTO) => {
      const workflow = await workflowRepo.execute(workflowId, rqData);
      return new WorkflowExecModel(workflow);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workflowQueryKey.lists());
      queryClient.invalidateQueries(workflowQueryKey.detail(workflowId));
    },
  });
};

export const useGetWorkflowExecutionsInfinite = (
  workflowId: string,
  queries?: Omit<WorkflowExecFilter, 'page'>,
  options?: UseInfiniteQueryOptions<Page<WorkflowExecModel>>,
) => {
  return useInfiniteQuery({
    queryKey: workflowQueryKey.listInfiniteExecutions(workflowId, queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await workflowRepo.getListExecution(workflowId, { ...queries, page: pageParam });
      return {
        ...result,
        content: result.content?.map(dto => new WorkflowExecModel(dto)),
      };
    },
    cacheTime: 100 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });
};

export const useGetWorkflowExecution = (
  execInfo: { workflowId: string; workflowExecId: string },
  options?: UseQueryOptionsType<WorkflowExecModel>,
) => {
  const { workflowId, workflowExecId } = execInfo;
  return useQuery<WorkflowExecModel, AxiosError>({
    queryKey: workflowQueryKey.detailExecution(workflowId, workflowExecId),
    queryFn: async () => {
      const workflow = await workflowRepo.getOneExecution(workflowId, workflowExecId);
      return new WorkflowExecModel(workflow);
    },
    ...options,
  });
};
