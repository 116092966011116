import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const MessageTextArea = styled('textarea')`
  width: 100%;
  height: 192px;
  padding: 5px 11px;
  resize: none;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
  ${fonts.Body2}
`;
