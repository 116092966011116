import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { nanoid } from 'nanoid';
import { ArrowUpRightIcon } from '@icons/ArrowUpRightIcon';
import { ThunderFillIcon } from '@icons/ThunderFillIcon';
import type {
  MetricPrice,
  ProductPlanOption,
  ProductPlanPrice,
  UsageMetricPrice,
} from '@repositories/softwareRepository';
import { useGetProductPlanDetails, useGetProductPlanList } from '@queryHooks/useSoftware';
import { currencyNumberFormat } from '@utils/numberFormat';
import type { ProductPlanModel } from '@models/softwareModels/ProductPlanModel';
import i18n from '@locales/i18n';
import { PlanDetailModal } from './PlanDetailModal';
import * as S from './ProductPlanStyles';

export type NewMetric = UsageMetricPrice & { usageMetricName?: string };

const commitmentPeriodMap = {
  QUARTER: 3,
  SEMIANNUAL: 6,
  ANNUAL: 12,
  NONE: -1,
};

export const ProductPlan = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { id: softwareId } = useParams();
  const { data: plans } = useGetProductPlanList(softwareId as string);
  const planIdList = plans?.map(({ planId }) => planId) ?? [];
  const planDetailQueries = useGetProductPlanDetails(softwareId ?? '', planIdList, {
    enabled: !!softwareId && planIdList.length > 0,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [priceDetail, setPriceDetail] = useState<ProductPlanPrice[]>([]);

  const planList: ProductPlanModel[] = planDetailQueries.reduce((accum, { isSuccess, data }) => {
    if (isSuccess) {
      accum.push(data);
    }
    return accum;
  }, [] as ProductPlanModel[]);

  const pricingTypeMap = {
    BY_USAGE_RANGE: t('Product_Plan_01'),
    CUSTOM: t('Product_Detail_View_01'),
    FLAT_RATE: t('Product_Plan_02'),
    Free: t('Product_Plan_03'),
    PER_ACTIVE_USER: t('Product_Plan_04'),
    PER_CONCURRENT_USER: t('Subscrib_Detail_Overview_16'),
    PER_USER: t('Subscrib_Detail_Overview_16'),
    USAGE_BASED: t('Acc_Detail_14'),
    USAGE_COMMITMENT: t('Acc_Detail_14'),
  };
  const unitPeriodMap = {
    YEAR: t('Product_Plan_05'),
    MONTH: t('Subscrib_Detail_Usage_05'),
    ONCE: '',
  };
  const paymentPeriodMap = {
    ANNUAL: t('Plan.PaymentPeriod.ANNUAL'),
    MONTH: t('Plan.PaymentPeriod.MONTH'),
    ONCE: t('Plan.PaymentPeriod.ONCE'),
    QUARTER: t('Plan.PaymentPeriod.QUARTER'),
    SEMIANNUAL: t('Plan.PaymentPeriod.SEMIANNUAL'),
  };

  const getCommitmentPeriodLabel = (planOption: ProductPlanOption) => {
    let commitmentPeriod;
    if (!planOption.commitmentPeriod) {
      commitmentPeriod = -1;
    } else if (planOption.commitmentPeriod === 'CUSTOM') {
      commitmentPeriod = planOption.customCommitmentPeriod;
    } else {
      commitmentPeriod = commitmentPeriodMap[planOption.commitmentPeriod];
    }
    return commitmentPeriod && commitmentPeriod > 0 ? `${commitmentPeriod} ${t('Plan.CommitmentPeriodUnit')}` : '';
  };

  const emptyPlan = [
    <ThunderFillIcon key={1} color={theme.colors['ic-gray-lighter']} />,
    <ThunderFillIcon key={2} color={theme.colors['ic-gray-lighter']} />,
    <ThunderFillIcon key={3} color={theme.colors['ic-gray-lighter']} />,
    <ThunderFillIcon key={4} color={theme.colors['ic-gray-lighter']} />,
  ];

  const handleClickPriceDetail = (plan: ProductPlanModel) => {
    type Metric = {
      name: string;
      id: string;
    };
    setIsOpen(true);
    const metricInfo = plan.usageMetricList.map(metric => {
      return {
        name: metric.usageMetric.usageMetricName,
        id: metric.usageMetric.usageMetricId,
      };
    });
    const metricInfoMap = new Map<string, Metric>();

    metricInfo.forEach(item => metricInfoMap.set(item.id, item));

    const opList = plan.planOptionList.map(op => {
      const origin = { ...op };
      const metrics: MetricPrice[] = op.usageMetricPrices.map(price => {
        const metricName = metricInfoMap.get(price.usageMetricId)?.name;
        return {
          ...price,
          usageMetricName: metricName,
        };
      });
      return {
        ...origin,
        usageMetricPrices: metrics,
      };
    });

    setPriceDetail(opList);
  };

  const renderMainPricing = (plan: ProductPlanModel) => {
    if (!plan.isBasicPlan) {
      return <div className="price-custom">{t('Product_Plan_06')}</div>;
    }

    if (plan.planOptionList.length) {
      const main = plan.planOptionList[0];

      const commitmentPeriodLabel = getCommitmentPeriodLabel(main);

      if (plan.pricingType === 'Free') {
        return <div className="price-per">{t('Product_Plan_03')}</div>;
      }
      if (plan.pricingType === 'CUSTOM') {
        return <div className="price-per-custom">{t('Product_Plan_07')}</div>;
      }
      // 사용량 기반(볼륨/계층)
      if (plan.pricingType === 'BY_USAGE_RANGE' || plan.pricingType === 'USAGE_BASED') {
        return (
          <>
            <div className="price-per-custom" onClick={() => handleClickPriceDetail(plan)} role="presentation">
              {i18n.t('Product_Detail_View_01')}
              <ArrowUpRightIcon color={theme.colors['ic-purple-light']} width={20} height={20} />
              <span className="per">/{pricingTypeMap[plan.pricingType]}</span>
            </div>
            <div className="payment-period">
              {`${paymentPeriodMap[main.paymentPeriod]}`}
              {commitmentPeriodLabel && ` / ${commitmentPeriodLabel}`}
            </div>
          </>
        );
      }

      // 그외
      return (
        <>
          <div className="price-per">
            {unitPeriodMap[main.unitPeriod]} {currencyNumberFormat(main.unitPrice ?? 0, plan.currencyUnit)}
            <span className="per">/{pricingTypeMap[plan.pricingType]}</span>
          </div>
          <div className="payment-period">
            {`${paymentPeriodMap[main.paymentPeriod]}`}
            {commitmentPeriodLabel && `/ ${commitmentPeriodLabel}`}
          </div>
        </>
      );
    }
    return '';
  };

  const renderSubPricing = (plan: ProductPlanModel) => {
    if (plan.planOptionList.length > 1) {
      const subs = plan.planOptionList.filter((plan, idx) => idx !== 0);
      if (plan.pricingType === 'Free') {
        return null;
      }
      if (plan.pricingType === 'CUSTOM') {
        return null;
      }
      if (plan.pricingType === 'BY_USAGE_RANGE' || plan.pricingType === 'USAGE_BASED') {
        return null;
      }
      return subs.map(op => {
        const commitmentPeriodLabel = getCommitmentPeriodLabel(op);
        return (
          <div className="agreement-payment" key={op.planOptionId}>
            <span className="price">
              {unitPeriodMap[op.unitPeriod]} {currencyNumberFormat(op.unitPrice ?? 0, plan.currencyUnit)}
            </span>
            <span className="type">
              {` / ${paymentPeriodMap[op.paymentPeriod]}`}
              {commitmentPeriodLabel && `/ ${commitmentPeriodLabel}`}
            </span>
          </div>
        );
      });
    }
    return '';
  };

  const renderEmpty = () => {
    emptyPlan.length = 4 - planList.length < 0 ? 0 : 4 - planList.length;
    if (emptyPlan.length) {
      return emptyPlan.map(plan => {
        return (
          <S.Plan key={nanoid()}>
            <S.Empty>{plan}</S.Empty>
          </S.Plan>
        );
      });
    }
    return null;
  };
  return (
    <div>
      {matches ? (
        <S.SliderPlans arrows={false} dots centerMode={!!matches} centerPadding={matches ? '16px' : '0'}>
          {planList
            ?.sort(a => (a.isBasicPlan ? 1 : -1))
            .map(plan => {
              return (
                <div className="plan-box" key={plan.planId}>
                  <S.Plan>
                    <div className="plan-name-wrapper">
                      <strong className="plan-name">{plan.planName}</strong>
                      {!plan.isBasicPlan && <S.CustomCard>custom</S.CustomCard>}
                    </div>
                    <div className="plan-description">{plan.description}</div>
                    <div className="plan-price">
                      {renderMainPricing(plan)}
                      {renderSubPricing(plan)}
                    </div>
                    <div className="feature-set">
                      <div className="feature-name">{t('Ad_Store_Product_Plan')}</div>
                      <div className="feature-item" dangerouslySetInnerHTML={{ __html: plan.featureSet }} />
                    </div>
                  </S.Plan>
                </div>
              );
            })}
          {renderEmpty()}
          <PlanDetailModal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            data={{ prices: priceDetail, currencyUnit: plans?.[0]?.currencyUnit ?? 'USD' }}
          />
        </S.SliderPlans>
      ) : (
        <S.Plans>
          {planList
            ?.sort(a => (a.isBasicPlan ? 1 : -1))
            .map(plan => {
              return (
                <S.Plan key={plan.planId}>
                  <div className="plan-name-wrapper">
                    <strong className="plan-name">{plan.planName}</strong>
                    {!plan.isBasicPlan && <S.CustomCard>custom</S.CustomCard>}
                  </div>
                  <div className="plan-description">{plan.description}</div>
                  <div className="plan-price">
                    {renderMainPricing(plan)}
                    {renderSubPricing(plan)}
                  </div>
                  <div className="feature-set">
                    <div className="feature-name">{t('Ad_Store_Product_Plan')}</div>
                    {plan.featureSet.trim() !== '' && (
                      <div className="feature-item" dangerouslySetInnerHTML={{ __html: plan.featureSet }} />
                    )}
                  </div>
                </S.Plan>
              );
            })}
          {renderEmpty()}
          <PlanDetailModal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            data={{ prices: priceDetail, currencyUnit: plans?.[0]?.currencyUnit ?? 'USD' }}
          />
        </S.Plans>
      )}
    </div>
  );
};
