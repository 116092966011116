import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { ISubscriptionUserRepository } from './ISubscriptionUserRepository';
import {
  TotalSubscriptionUserCountDTO,
  TotalSubscriptionUserCountFilter,
  TotalSubscriptionUserListDTO,
  TotalSubscriptionUserListFilter,
  UserLicenseListFilter,
  UserSubscriptionDTO,
  UserSubscriptionListWithCanRevokeFilter,
} from './Types';

@injectable()
export class SubscriptionUserRepository implements ISubscriptionUserRepository {
  async getUserLicenseList(queries: UserLicenseListFilter): Promise<Page<UserSubscriptionDTO>> {
    const queryString = qs.stringify(queries);
    const resultFromServer = await API.get<Page<UserSubscriptionDTO>>(`/api/v1/user-subscriptions?${queryString}`);
    return resultFromServer.data;
  }
  async getTotalSubscriptionUserList(
    queries: TotalSubscriptionUserListFilter,
  ): Promise<Page<TotalSubscriptionUserListDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<TotalSubscriptionUserListDTO>>(`/api/v1/total-subscription-users?${queryString}`);
    return result.data;
  }
  async getTotalSubscriptionUserCount(
    queries: TotalSubscriptionUserCountFilter,
  ): Promise<TotalSubscriptionUserCountDTO> {
    const queryString = qs.stringify(queries);
    const result = await API.get<TotalSubscriptionUserCountDTO>(`/api/v1/total-subscription-user-count?${queryString}`);
    return result.data;
  }
  async getUserSubscriptionListWithCanRevoke(
    queries: UserSubscriptionListWithCanRevokeFilter,
  ): Promise<UserSubscriptionDTO[]> {
    const queryString = qs.stringify(queries);
    const result = await API.get<UserSubscriptionDTO[]>(`/api/v1/user-revocable-subscriptions?${queryString}`);
    return result.data;
  }
}
