const defaultWidth = 24;
const defaultHeight = 24;

export const WizardSplashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5642 4.43578L20.5 6.5L21.4358 4.43578L23.5 3.5L21.4358 2.56422L20.5 0.5L19.5642 2.56422L17.5 3.5L19.5642 4.43578ZM9 7L8.06422 4.93578L6 4L8.06422 3.06422L9 1L9.93578 3.06422L12 4L9.93578 4.93578L9 7ZM13.6322 11.8423L14.6339 12.844L14.8918 12.5862L17.2439 10.2341L17.244 10.2339L17.3333 10.1446C17.7133 9.76466 17.7575 9.19281 17.4321 8.86737L15.075 6.51035C14.7496 6.18491 14.1778 6.22912 13.7978 6.6091L13.7084 6.69852L11.3562 9.05065L11.3562 9.05067L11.0984 9.30847L12.1001 10.3102L14.3179 8.09244L15.85 9.6245L13.6322 11.8423ZM12.9735 14.5045L9.43793 10.969L2.31662 18.0903C1.93664 18.4702 1.89243 19.0421 2.21787 19.3675L4.57489 21.7245C4.90033 22.05 5.47218 22.0058 5.85215 21.6258L12.9735 14.5045ZM21 17L20.0642 14.9358L18 14L20.0642 13.0642L21 11L21.9358 13.0642L24 14L21.9358 14.9358L21 17Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.47615 4.97331C3.86667 4.58279 4.47525 4.5582 4.83544 4.91839L19.1835 19.2665C19.5437 19.6267 19.5191 20.2353 19.1286 20.6258C18.7381 21.0163 18.1295 21.0409 17.7693 20.6807L3.42123 6.33261C3.06104 5.97241 3.08563 5.36384 3.47615 4.97331Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

WizardSplashIcon.displayName = 'WizardSplashIcon';
