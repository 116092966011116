const defaultWidth = 24;
const defaultHeight = 24;

export const CalendarEndIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  brColor = '#24292E',
}: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99538 1C7.49553 1 7.90098 1.40545 7.90098 1.90561V3.85981H16.0991V1.90561C16.0991 1.40545 16.5046 1 17.0047 1C17.5049 1 17.9103 1.40545 17.9103 1.90561V3.85981H20.3412C21.3678 3.85981 22.2 4.69206 22.2 5.71869V20.971C22.2 21.9977 21.3678 22.8299 20.3412 22.8299H3.65893C2.6323 22.8299 1.80005 21.9977 1.80005 20.971V5.71869C1.80005 4.69206 2.6323 3.85981 3.65893 3.85981H6.08977V1.90561C6.08977 1.40545 6.49522 1 6.99538 1ZM3.65893 5.67103C3.6326 5.67103 3.61126 5.69237 3.61126 5.71869V8.62617H20.3888V5.71869C20.3888 5.69237 20.3675 5.67103 20.3412 5.67103H3.65893ZM20.3888 10.4374H3.61126V20.971C3.61126 20.9974 3.6326 21.0187 3.65893 21.0187H20.3412C20.3675 21.0187 20.3888 20.9974 20.3888 20.971V10.4374Z"
          fill={color}
        />
        <path
          d="M15.1936 12.5656L15.2996 12.6716L15.1936 12.5656L11.1068 16.6523L9.41797 14.8867C9.13699 14.593 8.67107 14.5826 8.37732 14.8636C8.08357 15.1446 8.07321 15.6105 8.35419 15.9042L10.5632 18.2137C10.7001 18.3568 10.8889 18.4387 11.0869 18.4409C11.285 18.4431 11.4755 18.3654 11.6156 18.2254L16.2345 13.6065C16.5219 13.319 16.5219 12.853 16.2345 12.5656C15.947 12.2781 15.481 12.2781 15.1936 12.5656Z"
          fill={color}
          stroke={brColor}
          strokeWidth="0.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

CalendarEndIcon.displayName = 'CalendarEndIcon';
