const defaultWidth = 16;
const defaultHeight = 16;

export const CheckboxIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  bgColor = '#fff',
}: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" fill={bgColor} stroke={color} />
      <path
        d="M12.3251 4.26867C12.5945 3.99931 13.0312 3.99931 13.3005 4.26867C13.5699 4.53803 13.5699 4.97475 13.3005 5.24412L6.81336 11.7313C6.68213 11.8625 6.50355 11.9353 6.31797 11.9333C6.13239 11.9312 5.95548 11.8545 5.8272 11.7203L2.72463 8.47675C2.46132 8.20147 2.47102 7.76486 2.7463 7.50155C3.02158 7.23824 3.4582 7.24794 3.72151 7.52322L6.33659 10.2572L12.3251 4.26867Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3949 4.17442C13.7163 4.49585 13.7163 5.017 13.3949 5.33843L6.90767 11.8256C6.75107 11.9822 6.53797 12.0691 6.31652 12.0666C6.09507 12.0642 5.88396 11.9726 5.73088 11.8125L2.62831 8.56895C2.3141 8.24045 2.32568 7.71944 2.65417 7.40523C2.98267 7.09102 3.50368 7.10259 3.81789 7.43109L6.33874 10.0665L12.2308 4.17442C12.5523 3.85299 13.0734 3.85299 13.3949 4.17442Z"
        fill={color}
      />
    </svg>
  );
};

CheckboxIcon.displayName = 'CheckboxIcon';
