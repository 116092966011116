import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps } from 'react-hook-form';
import * as Yup from 'yup';
import i18next from 'i18next';
import i18n from '@locales/i18n';

export type FormPhone = {
  phone: string;
};

export type FormName = {
  name: string;
};

const validationPhoneSchema = Yup.object().shape({
  phone: Yup.string().matches(/^[0-9]+$/, {
    message: i18next.t('Subscrib_Detail_License_11'),
    excludeEmptyString: true,
  }),
});

const validationNameSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('GNB_Profile_MyProfile_14')),
});

export const phoneFormOptions: UseFormProps<FormPhone> = {
  resolver: yupResolver(validationPhoneSchema),
  mode: 'onChange',
  shouldFocusError: true,
};

export const nameFormOptions: UseFormProps<FormName> = {
  resolver: yupResolver(validationNameSchema),
  mode: 'onChange',
  shouldFocusError: true,
};
