const defaultWidth = 16;
const defaultHeight = 16;

export const GroupUpperIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  bgColor = '#6A737D',
  brColor = '#6A737D',
}: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M2.50004 1.28398C1.82809 1.28398 1.28337 1.82871 1.28337 2.50065V13.5007C1.28337 14.1726 1.82809 14.7173 2.50004 14.7173H13.5C14.172 14.7173 14.7167 14.1726 14.7167 13.5007V2.50065C14.7167 1.8287 14.172 1.28398 13.5 1.28398H2.50004ZM2.38337 2.50065C2.38337 2.43622 2.43561 2.38398 2.50004 2.38398H13.5C13.5645 2.38398 13.6167 2.43622 13.6167 2.50065V13.5007C13.6167 13.5651 13.5645 13.6173 13.5 13.6173H2.50004C2.43561 13.6173 2.38337 13.5651 2.38337 13.5007V2.50065Z"
          fill={bgColor}
          stroke={brColor}
          strokeWidth="0.1"
        />
        <path
          d="M11.2302 7.63755C11.4452 7.42251 11.4452 7.07386 11.2302 6.85882L8.53771 4.16639C8.32268 3.95135 7.97403 3.95135 7.75899 4.16639L5.06655 6.85883C4.85151 7.07387 4.85151 7.42252 5.06655 7.63755C5.28159 7.85259 5.63024 7.85259 5.84528 7.63755L7.59771 5.88512L7.59771 11.4364C7.59771 11.7405 7.84424 11.9871 8.14835 11.9871C8.45246 11.9871 8.69899 11.7405 8.69899 11.4364L8.69899 5.88511L10.4514 7.63755C10.6665 7.85259 11.0151 7.85259 11.2302 7.63755Z"
          fill={bgColor}
          stroke={brColor}
          strokeWidth="0.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

GroupUpperIcon.displayName = 'GroupUpperIcon';
