import type { Dayjs } from 'dayjs';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { EmptyData } from '@components/EmptyData';
import { Loader } from '@components/loader';
import type {
  SubscriptionDailyUsageFilter,
  SubscriptionMonthlyUsageFilter,
} from '@repositories/subscriptionRepository';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetUsageMetricList } from '@queryHooks/useSoftware';
import { useGetSubscriptionDailyUsage, useGetSubscriptionMonthlyUsage } from '@queryHooks/useSubscription';
import { dateFormat } from '@utils/dateUtil';
import { numberFormat } from '@utils/numberFormat';
import { DailyUsageModel } from '@models/usageModels/DailyUsageModel';

type Props =
  | {
      tableMode: 'day';
      tableApiFilter: SubscriptionDailyUsageFilter;
    }
  | {
      tableMode: 'month';
      tableApiFilter: SubscriptionMonthlyUsageFilter;
    };

export const UsageTable = ({ tableMode, tableApiFilter }: Props) => {
  const { t } = useTranslation();
  const subscription = useSubscriptionModel();

  const startDayjs = dayjs(tableMode === 'day' ? tableApiFilter.dateFrom : tableApiFilter.monthFrom);
  const endDayjs = dayjs(tableMode === 'day' ? tableApiFilter.dateTo : tableApiFilter.monthTo);

  const {
    data: dailyUsageData,
    isLoading: isDailyUsageLoading,
    isError: isDailyUsageError,
  } = useGetSubscriptionDailyUsage(
    subscription.id,
    {
      ...tableApiFilter,
      size: Math.max(endDayjs.diff(startDayjs, 'day'), 50),
    },
    {
      enabled: tableMode === 'day',
    },
  );

  const {
    data: monthlyUsageData,
    isLoading: isMonthlyUsageLoading,
    isError: isMonthlyUsageError,
  } = useGetSubscriptionMonthlyUsage(
    subscription.id,
    {
      ...tableApiFilter,
      size: Math.max(endDayjs.diff(startDayjs, 'month'), 50),
    },
    {
      enabled: tableMode === 'month',
    },
  );

  const usageData = tableMode === 'day' ? dailyUsageData : monthlyUsageData;
  const isLoading = tableMode === 'day' ? isDailyUsageLoading : isMonthlyUsageLoading;
  const isError = tableMode === 'day' ? isDailyUsageError : isMonthlyUsageError;

  const { data: usageMetrics } = useGetUsageMetricList(subscription.software.id);

  const totalUsageKey =
    usageData.content[0]?.usageList.findIndex(({ module }) => module === 'TOTAL_USAGE') >= 0 ? 'TOTAL_USAGE' : '';

  const renderRows = () => {
    if (isLoading || isError) {
      return null;
    }

    const rows: ReactNode[] = [];

    const totalUsageMetric = totalUsageKey
      ? [{ usageMetricId: 'total', usageMetricCode: totalUsageKey, usageMetricName: 'Total' }]
      : [];
    const usageMetricsWithTotal = usageMetrics ? [...totalUsageMetric, ...usageMetrics] : [];

    let usageIndex = 0;
    const usages = usageData.content;
    const startDayjs = dayjs(tableMode === 'day' ? tableApiFilter.dateFrom : tableApiFilter.monthFrom);
    let dateIter: Dayjs = dayjs(tableMode === 'day' ? tableApiFilter.dateTo : tableApiFilter.monthTo);
    while (!dateIter.isBefore(startDayjs)) {
      const usage = usages[usageIndex];
      const date = usageIndex >= usages.length ? '' : usage instanceof DailyUsageModel ? usage.date : usage.month;
      const formatString = tableMode === 'day' ? 'YYYY.MM.DD' : 'YYYY.MM';
      if (usageIndex < usages.length && dateIter.isSame(date, tableMode)) {
        rows.push(
          <TableRow key={date}>
            <TableCell>{dateFormat(date, '-', formatString)}</TableCell>
            {usageMetricsWithTotal?.map(({ usageMetricId, usageMetricCode }) => {
              const moduleUsage: number | undefined = usage.getUsageOfModule(usageMetricCode);
              return (
                <TableCell key={`${date}_${usageMetricId}`}>
                  {moduleUsage !== undefined ? numberFormat({ num: moduleUsage, maxFractionDigit: 5 }) : '-'}
                </TableCell>
              );
            })}
            <TableCell>{usage.isForecasted ? t('Subscrib_Detail_Usage.ForecastedUsage') : usage.updatedDate}</TableCell>
          </TableRow>,
        );
        usageIndex += 1;
      } else {
        const prevDateString = dateIter.format(formatString);
        rows.push(
          <TableRow key={prevDateString}>
            <TableCell>{prevDateString}</TableCell>
            {usageMetricsWithTotal?.map(({ usageMetricId }) => (
              <TableCell key={`${prevDateString}_${usageMetricId}`}>-</TableCell>
            ))}
            <TableCell>-</TableCell>
          </TableRow>,
        );
      }

      dateIter = dateIter.add(-1, tableMode);
    }

    return rows;
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <EmptyData />
  ) : tableMode === 'month' ? (
    <TableContainer>
      <Table width="160px" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell width="160px">{t('Usage_Table_Date')}</TableCell>
            {totalUsageKey && <TableCell width="160px">Total</TableCell>}
            {usageMetrics?.map(({ usageMetricName, usageMetricId }) => (
              <TableCell key={usageMetricId} width="160px">
                {usageMetricName}
              </TableCell>
            ))}
            <TableCell key="collectTime" width="36px">
              {t('Subscrib_Detail_Usage.MeasureTime')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer>
      <Table width="160px" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell width="160px">날짜</TableCell>
            {totalUsageKey && <TableCell width="160px">Total</TableCell>}
            {usageMetrics?.map(({ usageMetricId, usageMetricName }) => (
              <TableCell key={usageMetricId} width="160px">
                {usageMetricName}
              </TableCell>
            ))}
            <TableCell key="collectTime" width="36px">
              {t('Subscrib_Detail_Usage.MeasureTime')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};
