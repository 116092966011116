import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Employee } from '../Employee';
import { ChangePassword } from '../common/sign/changePassword';
import { ExpiredPasswordResetLink } from '../common/sign/changePassword/ExpiredPasswordResetLink';
import { ConfirmMail } from '../common/sign/confirmMail/ConfirmMail';
import { RegisteredUser } from '../common/sign/registeredUser/RegisteredUser';
import { SearchPassword } from '../common/sign/searchPassword/SearchPassword';
import { SignIn } from '../common/sign/signIn/SignIn';
import { Store } from '../common/store';
import { Products as StoreProducts } from '../common/store/products';
import { ProductsInCategory } from '../common/store/products/ProductsInCategory';
import { ProductDetail } from '../common/store/products/productDetail';
import { TenantRequest } from '../common/tenantRequest';
import { Introduction } from './introduction';
import { Subscription } from './subscription';
import { ApplyDetail } from './subscription/applyHistory/detail';
import { ApplyHistoryList } from './subscription/applyHistory/list';
import { ProductInCompany } from './subscription/productsInCompany/list/ProductInCompany';
import { ProductInCompanyList } from './subscription/productsInCompany/list/ProductInCompanyList';
import { SubscribingList } from './subscription/subscribing/list';
import { Products } from './subscription/subscribing/list/Products';
import { TechnicalSupportDetail } from './technicalSupport/TechnicalSupportDetail';
import { TechnicalSupportList } from './technicalSupport/TechnicalSupportList';

export const employeeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Employee />}>
      <Route path="/" element={<Navigate to="/store" replace />} />
      <Route path="/introduction" element={<Introduction />} />
      <Route path="/store/*" element={<Store />}>
        <Route path="softwares" element={<StoreProducts type="Software" />} />
        <Route path="hardwares" element={<StoreProducts type="Hardware" />} />
        <Route path="category/:name" element={<ProductsInCategory />} />
        <Route path="product/:id" element={<ProductDetail />} />
        <Route path="*" element={<ProductsInCategory />} />
      </Route>
      <Route path="/subscriptions/*" element={<Subscription />}>
        <Route path="list/*" element={<SubscribingList />}>
          <Route path="software" element={<Products type="Software" />} />
          <Route path="hardware" element={<Products type="Hardware" />} />
          <Route path=":rootCategoryId" element={<Products />} />
          <Route path="*" element={<Products />} />
        </Route>
        <Route path="usage-apply" element={<ApplyHistoryList />} />
        <Route path="usage-apply/:id" element={<ApplyDetail />} />
        <Route>
          <Route path="technical-support" element={<TechnicalSupportList />} />
          <Route path="technical-support/:id" element={<TechnicalSupportDetail />} />
        </Route>
        <Route path="subscriptions-company/*" element={<ProductInCompanyList />}>
          <Route path="software" element={<ProductInCompany type="Software" />} />
          <Route path="hardware" element={<ProductInCompany type="Hardware" />} />
          <Route path=":rootCategoryId" element={<ProductInCompany />} />
          <Route path="*" element={<ProductInCompany />} />
        </Route>
        <Route path="*" element={<Navigate to="list" replace />} />
      </Route>
      <Route path="/tenant-request" element={<TenantRequest />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/change-password/:token" element={<ChangePassword />} />
      <Route path="/search-password" element={<SearchPassword />} />
      <Route path="/confirm-mail/:email" element={<ConfirmMail />} />
      <Route path="/registered-user" element={<RegisteredUser />} />
      <Route path="/expired-password-reset-link" element={<ExpiredPasswordResetLink />} />

      <Route path="*" element={<Navigate to="/store" replace />} />
    </Route>,
  ),
);
