import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { XIcon } from '@icons/XIcon';
import { BulletIcon, ErrorIcon } from '@icons/index';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useGetSubscriptionListInfinite } from '@queryHooks/useSubscription';
import { useUpdateTenantMemberStatus } from '@queryHooks/useTenant';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';

interface Props {
  open: boolean;
  onClose: () => void;
  user: TenantMemberSimpleModel;
}

export const UserExitModal = ({ onClose, open, user }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    categoryStore,
    uiStore: { alertStore, toastStore },
  } = useStore();
  const params = useParams();

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry?.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });
  const {
    data: mySubscriptions,
    isSuccess,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSubscriptionListInfinite({ userId: user.id }, { enabled: open });

  const { mutate: updateUserStatus } = useUpdateTenantMemberStatus();
  const [hasScroll, setHasScroll] = useState(false);
  const bodyRef = useRef<HTMLDivElement>(null);
  const changeUserStatusToStop = (userId: string) => {
    updateUserStatus(
      { tenantId: params.tenantId as string, userId, rqData: { status: 'BLOCKED' } },
      {
        onSuccess: () => {
          toastStore.open(t('Member_User_28'));
          onClose();
        },
      },
    );
  };

  useEffect(() => {
    const bodyScrollHeight = bodyRef.current?.scrollHeight || 0;
    const maxHeight = 588;
    if (bodyRef.current !== null && isSuccess) {
      if (bodyScrollHeight > maxHeight) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    }
  }, [isSuccess, open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <div className="header">
          <span>{t('Member_User_Delete_01')}</span>
          <IconButton onClick={onClose}>
            <XIcon />
          </IconButton>
        </div>
        <div className={hasScroll ? 'body scroll' : 'body'} ref={bodyRef}>
          <UserInfo>
            <strong className="title">{t('Member_User_Delete_02')}</strong>
            <TableWrapper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Info>
                        <span className="info-key">{t('Workflow_Main_05')}</span>
                        <span className="info-value">{user.name}</span>
                      </Info>
                    </TableCell>
                    <TableCell>
                      <Info>
                        <span className="info-key">{t('Member_User_14')}</span>
                        <span className="info-value">{user.username}</span>
                      </Info>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Info>
                        <span className="info-key">{t('Member_User_Bulk_Create_18')}</span>
                        <span className="info-value">{user.usergroups[0] || '-'}</span>
                      </Info>
                    </TableCell>
                    <TableCell>
                      <Info>
                        <span className="info-key">{t('Member_User_16')}</span>
                        <span className="info-value">{user.phone}</span>
                      </Info>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableWrapper>
          </UserInfo>
          <Subscriptions>
            <div className="title">
              <Trans
                i18nKey="Member_User_Delete_03"
                values={{ Name: user.name, totalElements: mySubscriptions?.pages[0].totalElements }}
                components={{
                  1: <span className="name" />,
                  2: <span className="describe" />,
                  3: <span className="count" />,
                }}
              />
            </div>
          </Subscriptions>
          <SubscriptionTableWrap>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="99px">{t('Subscrib_Detail_UseRequest_06')}</TableCell>
                  <TableCell width="147px">{t('Subscrib_Main_10')}</TableCell>
                  <TableCell width="147px">{t('Subscrib_Detail_UseRequest_07')}</TableCell>
                  <TableCell width="147px">{t('Subscrib_Main_12')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mySubscriptions?.pages[0].totalElements === 0 ? (
                  <NoDataRow>
                    <TableCell colSpan={4}>
                      <ErrorWrapper>
                        <IconWrapper>
                          <ErrorIcon width={22} height={22} color={theme.colors['ic-purple-light']} />
                        </IconWrapper>
                        <span>{t('Subscrib_Main_Filter_08')}</span>
                      </ErrorWrapper>
                    </TableCell>
                  </NoDataRow>
                ) : (
                  <>
                    {mySubscriptions?.pages
                      .flatMap(page => page.content)
                      ?.map(sb => {
                        return (
                          <TableRow key={sb.id}>
                            <StyledTableCell width="99px">
                              {categoryStore.findRootCategory(sb.software.categoryId as string)?.name}
                            </StyledTableCell>
                            <StyledTableCell width="147px">{sb.name}</StyledTableCell>
                            <StyledTableCell width="147px">{sb.software.name}</StyledTableCell>
                            <StyledTableCell width="147px">{sb.planName}</StyledTableCell>
                          </TableRow>
                        );
                      })}
                    {hasNextPage && <div ref={ref} />}
                  </>
                )}
              </TableBody>
            </Table>
          </SubscriptionTableWrap>
          <InfoWrapper>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              <span>{t('Member_User_Delete_08')}</span>
            </li>
            <li>
              <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
              <span>{t('Member_User_Delete_09')}</span>
            </li>
          </InfoWrapper>
        </div>
        <div className={hasScroll ? 'footer scroll' : 'footer'}>
          <Button
            size="extraLarge"
            variant="contain"
            style={{ backgroundColor: theme.colors['bg-red-light'], width: '228px' }}
            onClick={() =>
              alertStore.open({
                title: t('Workflow_Create_10'),
                message: t('Member_User_Delete_08'),
                useCancel: true,
                confirmName: t('Subscrib_Detail_User_33'),
                onConfirm: () => changeUserStatusToStop(user.id),
              })
            }
          >
            {t('Member_User_Delete_10')}
          </Button>
          <Button size="extraLarge" variant="outline" style={{ width: '228px' }} onClick={onClose}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 748px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  overflow: hidden;
  & .header {
    height: 60px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0px 18px 0px 28px;
    ${fonts.Headline6};
  }
  & .body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 12px 28px 0 28px;
    &.scroll {
      padding: 12px 28px 12px 28px;
    }
  }
  & .footer {
    height: 100px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: auto;
    &.scroll {
      border-top: 1px solid ${props => props.theme.colors['border-gray-lighter']};
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & .title {
    ${fonts.Headline8};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 72px;
  justify-content: center;
  gap: 2px;
  & .info-key {
    ${fonts.Headline9};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
  }
  & .info-value {
    ${fonts.Headline8};
  }
`;

const Subscriptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-top: 28px;
  & .title {
    display: flex;
    flex-direction: row;
    & .name {
      ${fonts.Headline8};
      margin-right: 2px;
    }
    & .describe {
      ${fonts.Body2};
      margin-right: 4px;
    }
    & .count {
      ${fonts.Headline8};
      color: ${props => props.theme.colors['text-purple-light']};
    }
  }
`;

const TableWrapper = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  & .MuiTableRow-root {
    .MuiTableCell-root {
      &:last-of-type {
        padding: 0 20px;
      }
    }
  }
  & .MuiTableCell-root {
    padding: 0;
  }
`;
const SubscriptionTableWrap = styled('div')`
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  & .MuiTable-root {
    border-radius: 5px;
  }
`;

const InfoWrapper = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 16px;
  margin-top: 12px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light-50']};
  li {
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 4px;
    & span {
      ${fonts.Body4};
      white-space: pre-wrap;
      color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
    }
  }
`;

const ErrorWrapper = styled('div')`
  padding: 28px 0 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  span {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  }
`;
const IconWrapper = styled('div')`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
`;

const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NoDataRow = styled(TableRow)`
  &:hover {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
`;
