const defaultWidth = 24;
const defaultHeight = 24;

export const TitleIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <rect x="2" y="18" width="20" height="2" rx="1" fill={color} />
        <path
          d="M4.97831 14.8501H2.5L6.33986 4H9.29242L13.117 14.8501H10.654L9.82021 12.3474H5.81207L4.97831 14.8501ZM6.3934 10.594H9.23887L7.85438 6.44276H7.77789L6.3934 10.594Z"
          fill={color}
        />
        <path
          d="M14.0956 12.5721C14.1033 10.7588 15.5872 10.1669 17.1859 10.0694C17.7825 10.032 18.8993 9.97952 19.2511 9.96454V9.30514C19.2435 8.62326 18.7692 8.23362 17.9202 8.22613C17.1476 8.23362 16.6657 8.57081 16.5433 9.1253H14.3863C14.524 7.71659 15.7784 6.60761 17.9814 6.60761C19.7942 6.60761 21.5076 7.40188 21.5 9.36508V14.8501H19.3582V13.7261H19.297C18.8916 14.4754 18.0961 15.0074 16.8646 14.9999C15.2736 15.0074 14.1033 14.1832 14.0956 12.5721ZM16.268 12.5122C16.268 13.1341 16.7805 13.4638 17.5071 13.4713C18.5398 13.4638 19.2588 12.8044 19.2511 11.9427V11.3508C18.9069 11.3658 17.9355 11.4182 17.553 11.4482C16.7652 11.5156 16.268 11.8828 16.268 12.5122Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

TitleIcon.displayName = 'TitleIcon';
