import styled from '@emotion/styled';
import { Theme, css, useTheme } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { CircleDashFillIcon } from '@icons/CircleDashFillIcon';
import { CircleFillIcon } from '@icons/CircleFillIcon';
import { useTotalSubscriptionUserCount } from '@queryHooks/useSubscriptionUser';
import { SubscriptionFilterType } from './SubscriptionUserMgmtList';

const MonthlyIncrementTrend = (theme: Theme, monthlyIncrement: number) => {
  const { colors } = theme;
  return (
    <MonthlyUserCountFilterVariance>
      <MonthlyUserCountFilterTitle>이번 달</MonthlyUserCountFilterTitle>
      {monthlyIncrement > 0 ? (
        <UserCountFilterVariance>
          <CircleFillIcon width={16} height={16} color={colors['ic-green-light']} />
          <MonthlyUserCounterFilterNumber color={colors['text-green-light']}>
            {monthlyIncrement}
          </MonthlyUserCounterFilterNumber>
        </UserCountFilterVariance>
      ) : monthlyIncrement === 0 ? (
        <UserCountFilterVariance>
          <CircleDashFillIcon width={16} height={16} color="gray" />
          <MonthlyUserCounterFilterNumber>{monthlyIncrement}</MonthlyUserCounterFilterNumber>
        </UserCountFilterVariance>
      ) : (
        <UserCountFilterVariance>
          <CircleFillIcon width={16} height={16} color={colors['ic-red-light']} rotateNum={180} />
          <MonthlyUserCounterFilterNumber color={colors['text-red-light']}>
            {Math.abs(monthlyIncrement ?? 0)}
          </MonthlyUserCounterFilterNumber>
        </UserCountFilterVariance>
      )}
    </MonthlyUserCountFilterVariance>
  );
};

export const SubscriptionFilterCard = ({
  subscriptionFilterPackage,
  userCountFilterPackage,
}: SubscriptionFilterType) => {
  const { queries, setQueries } = subscriptionFilterPackage;
  const { userCountKey } = userCountFilterPackage;

  const theme = useTheme();
  const { data: subscriptionCountPackage } = useTotalSubscriptionUserCount({ ...userCountKey });

  const handleClickUserCountFilter = (isWarning: boolean) => {
    setQueries(prevFilter => ({
      ...prevFilter,
      isAbnormal: isWarning === true ? isWarning : undefined,
    }));
  };

  return (
    <UserCountFilters>
      <UserCountFilter
        className={queries.isAbnormal ? '' : 'selected'}
        onClick={() => handleClickUserCountFilter(false)}
      >
        <UserCountFilterNumberWrapper>
          <UserCountFilterNumberTitle className="total">전체</UserCountFilterNumberTitle>
          <UserCountFilterNumber className="total">{subscriptionCountPackage?.totalUserCount}</UserCountFilterNumber>
        </UserCountFilterNumberWrapper>
        <UserCountFilterVarianceWrapper>
          {MonthlyIncrementTrend(theme, subscriptionCountPackage?.monthlyUserCountIncrement as number)}
        </UserCountFilterVarianceWrapper>
      </UserCountFilter>
      <UserCountFilter
        className={queries.isAbnormal ? 'selected' : ''}
        onClick={() => handleClickUserCountFilter(true)}
      >
        <UserCountFilterNumberWrapper>
          <UserCountFilterNumberTitle className="warning">이상 감지</UserCountFilterNumberTitle>
          <UserCountFilterNumber className="warning">
            {subscriptionCountPackage?.abnormalUserCount}
          </UserCountFilterNumber>
        </UserCountFilterNumberWrapper>
        <UserCountFilterVarianceWrapper>
          {MonthlyIncrementTrend(theme, subscriptionCountPackage?.monthlyUserCountIncrement as number)}
        </UserCountFilterVarianceWrapper>
      </UserCountFilter>
    </UserCountFilters>
  );
};

const UserCountFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const UserCountFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 110px;
  flex: 1;
  padding: 24px 36px 22px 36px;
  position: relative;
  > div {
    z-index: 10;
  }

  &:after {
    content: '';
    width: 100%;
    height: 110px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    border: 1px solid ${props => props.theme.colors['border-gray-light']};
    border-radius: 5px;
  }

  &.selected:after {
    ${({ theme: { colors } }) => css`
      border: 1px solid transparent;
      border-width: 1px;
      background-image: linear-gradient(${colors['state-white-purple-hover']}, ${colors['state-white-purple-hover']}),
        ${colors['border-gradient']};
      background-origin: border-box;
      background-clip: content-box, border-box;
    `}
    background-color: ${props => props.theme.colors['bg-purple-lighter']};
  }

  :hover {
    cursor: pointer;
  }

  &.selected .total:last-child,
  &.selected .warning:last-child {
    background: ${({ theme }) => theme.colors['text-gradient']};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const UserCountFilterNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UserCountFilterNumberTitle = styled.span`
  ${fonts.Headline8};
  &.warning {
    color: ${props => props.theme.colors['text-red-dark']};
  }
  &.total {
    color: ${props => props.theme.colors['text-gray-sub-darker']};
  }
`;

const UserCountFilterNumber = styled.span`
  ${fonts.Headline3};
  &.warning {
    color: ${props => props.theme.colors['text-red']};
  }
  &.total {
    color: ${props => props.theme.colors['text-gray-main']};
  }
`;

const UserCountFilterVarianceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-bottom: 4px;
`;

const MonthlyUserCountFilterVariance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;

const MonthlyUserCountFilterTitle = styled.strong``;

const MonthlyUserCounterFilterNumber = styled.span<{ color?: string }>`
  font: ${fonts.Headline7};
  color: ${props => (props.color ? props.color : props.theme.colors['text-gray-sub-dark'])};
`;

const UserCountFilterVariance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  ${fonts.Headline7};
  &.up {
    color: ${props => props.theme.colors['text-green-light']};
  }
  &.down {
    color: ${props => props.theme.colors['text-red']};
  }
`;
