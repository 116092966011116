import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  left?: number;
  top?: number;
}

const Loader = ({ left = 56, top = 50 }: Props) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        zIndex: '1',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
