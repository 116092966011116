import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';
import localStorage from '@repositories/browserStorage/localStorage/LocalStorage';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

const userLang = localStorage.get<string>('lang') as string;
if (userLang) {
  dayjs.locale(userLang);
} else {
  const browserLang = navigator.language;
  const formattingLang = browserLang.split('-');
  dayjs.locale(formattingLang[0].toLowerCase());
}
