import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import Slider from 'react-slick';

export const Plans = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    margin: 8px 0;
  } ;
`;

export const SliderPlans = styled(Slider)`
  & .plan-box {
    display: flex;
    justify-content: center;
  }
  .slick-dots li button:before {
    color: #d1d5da;
    font-size: 8px;
    line-height: 20px;
  }
  .slick-dots li.slick-active button:before {
    color: #6f42c1;
  }
`;

export const Plan = styled.div`
  min-width: 210px;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 24px 0 0;
  margin-left: 20px;
  flex-shrink: 0;
  &:first-of-type {
    margin-left: 0;
  }
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-right: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  }

  & .plan-name-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    max-width: 210px;
    margin-bottom: 8px;
    ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
      margin-bottom: 4px;
    }
  }

  & .plan-name {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .plan-description {
    ${fonts.Paragraph2};
    height: 60px;
    overflow: hidden;
    max-width: 210px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
    margin-bottom: 32px;
    ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
      margin-bottom: 24px;
      -webkit-line-clamp: 3;
    }
  }

  & .plan-price {
    height: 80px;
    margin-bottom: 48px;
    ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
      margin-bottom: 24px;
    }
  }

  & .price-per {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    ${fonts.Headline4};
    color: ${({ theme: { colors } }) => colors['text-purple']};
    & .per {
      ${fonts.Headline7};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
      margin-left: 4px;
    }
  }

  & .price-custom {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    ${fonts.Headline4};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};
  }

  & .price-per-custom {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    ${fonts.Headline4};

    color: ${({ theme: { colors } }) => colors['text-purple']};
    :hover {
      cursor: pointer;
    }
    & .per {
      ${fonts.Headline7};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
      margin-left: 4px;
    }
  }

  & .payment-period {
    margin-bottom: 2px;
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }

  & .agreement-payment {
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
    background-color: ${({ theme }) => theme.colors['bg-gray-light']};
    border-radius: 0.5px;
    padding: 1px 5px;
    width: fit-content;

    & .price {
      ${fonts.Headline10};
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
    & .type {
      ${fonts.Body6};
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
  }

  & .feature-set {
    display: flex;
    flex-direction: column;
    max-width: 210px;
    overflow: hidden;
    white-space: normal;
    ${fonts.Paragraph2};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & .feature-name {
      ${fonts.Body4};
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
      margin-bottom: 8px;
      ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
        margin-bottom: 4px;
      }
    }
    & .feature-item {
      ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
        p {
          ${fonts.Paragraph2};
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-height: 60px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    min-width: auto;
    max-width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 28px;
    &:after {
      content: none;
    }
  } ;
`;

export const CustomCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  ${fonts.Caption4}
  color: ${props => props.theme.colors['text-gray-sub-darker']};
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 2px;
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
`;
