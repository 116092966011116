import type { FormName, FormPhone } from './validationSchema';
import type { BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Modal, TextField, Tooltip, Typography, styled, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PencilIcon } from '@icons/PencilIcon';
import { PhoneIcon } from '@icons/PhoneIcon';
import { NameIcon, OrganizationIcon, GroupIcon, MailIcon, KeyIcon, XIcon } from '@icons/index';
import type { ProfileUpdateRq } from '@repositories/userRepository';
import { useGetUserProfile, useUpdateUserProfile } from '@queryHooks/useUser';
import i18n from '@locales/i18n';
import { nameFormOptions, phoneFormOptions } from './validationSchema';

type EditMode = {
  phone: boolean;
  name: boolean;
};

type ModalWrapperProps = BoxProps & {
  language: string;
};

export const MyProfileModal = observer(() => {
  const { t } = useTranslation();
  const {
    uiStore: { profileModalStore: modal },
    authStore,
  } = useStore();

  const naviage = useNavigate();

  const theme = useTheme();

  const [language, setLanguage] = useState<string>(i18n.language);

  const [isEditMode, setIsEditMode] = useState<EditMode>({ phone: false, name: false });
  const {
    register: registerPhone,
    handleSubmit: handleSubmitPhone,
    reset: resetPhone,
    formState: { errors: phoneErrors },
  } = useForm<FormPhone>(phoneFormOptions);

  const {
    register: registerName,
    handleSubmit: handleSubmitName,
    reset: resetName,
    formState: { errors: nameErrors },
  } = useForm<FormName>(nameFormOptions);

  const { data: user } = useGetUserProfile(authStore.id);
  const { mutate: updateUser } = useUpdateUserProfile();

  const validation = {
    successPhone: (data: FormPhone) => {
      if (user) {
        const rqData: ProfileUpdateRq & { id: string } = { name: user.name, phone: data.phone ?? '', id: user.id };
        updateUser(rqData, {
          onSuccess: () => {
            setIsEditMode(prev => {
              return { ...prev, phone: false };
            });
          },
        });
      }
    },
    successName: (data: FormName) => {
      if (user) {
        const rqData: ProfileUpdateRq & { id: string } = { name: data.name, id: user.id };
        updateUser(rqData, {
          onSuccess: () => {
            setIsEditMode(prev => {
              return { ...prev, name: false };
            });
          },
        });
      }
    },
  };

  useEffect(() => {
    setLanguage(i18n.language);
  }, []);

  return (
    <Modal open={modal.visible}>
      <ModalWrapper language={language}>
        <Header>
          <Typography variant="headline6" component="strong">
            {t('GNB_Profile_Menu_01')}
          </Typography>
          <Button variant="text" onClick={modal.close}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </Button>
        </Header>
        <Body className={isEditMode ? 'edit-mode' : ''}>
          <Property>
            <Key>
              <OrganizationIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <span>{t('Member_LNB_01')}</span>
            </Key>
            <Value>
              <span>{user?.company[0]?.name}</span>
            </Value>
          </Property>
          <Property>
            <Key>
              <MailIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <span>{t('Member_User_14')}</span>
            </Key>
            <Value>
              <span>{user?.username}</span>
            </Value>
          </Property>
          <Property className={nameErrors.name?.message ? 'edit-mode' : ''}>
            <Key>
              <NameIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <span>{t('Workflow_Main_05')}</span>
            </Key>
            {isEditMode.name ? (
              <form onSubmit={handleSubmitName(validation.successName)}>
                <Value>
                  <TextField
                    {...registerName('name')}
                    size="small"
                    fullWidth
                    error={!!nameErrors.name?.message}
                    helperText={nameErrors.name?.message}
                    autoFocus={isEditMode.name}
                  />
                  <Buttons>
                    <StyledOutlineBtn
                      variant="outline"
                      onClick={() => {
                        setIsEditMode(prev => {
                          return { ...prev, name: false };
                        });
                        resetName({ name: '' });
                      }}
                      size="small"
                      paddingHorizontal={12}
                    >
                      {t('Subscrib_Detail_BuyRequest_13')}
                    </StyledOutlineBtn>
                    <Button variant="contain" size="small" type="submit" paddingHorizontal={12}>
                      {t('Member_User_24')}
                    </Button>
                  </Buttons>
                </Value>
              </form>
            ) : (
              <Value>
                <span>{user?.name}</span>
                <IconButton
                  onClick={() =>
                    setIsEditMode(prev => {
                      return { ...prev, name: true };
                    })
                  }
                  variant="outline"
                  size="small"
                >
                  <PencilIcon width={16} height={16} color={theme.colors['ic-gray-main']} />
                </IconButton>
              </Value>
            )}
          </Property>
          <Property>
            <Key>
              <GroupIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <span>{t('Member_User_Bulk_Create_18')}</span>
            </Key>
            <Value>
              {(user?.userGroups.length as number) > 1 ? (
                <Groups>
                  <span>{user?.userGroups[0].name}&nbsp;</span>
                  <Tooltip
                    title={user?.userGroups.map((usergroup, idx) => {
                      if (idx > 0) return <UsergroupOfTooltip>{usergroup.name}</UsergroupOfTooltip>;
                      return null;
                    })}
                    arrow
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          width: '210px',
                          border: `1px solid ${theme.colors['border-gray-lighter']}`,
                          backgroundColor: theme.colors['bg-white'],
                          padding: '6px',
                          marginTop: '4px !important',
                        },
                        '& .MuiTooltip-arrow': {
                          color: theme.colors['text-white'],
                          '&::before': {
                            border: `1px solid ${theme.colors['border-gray-lighter']}`,
                          },
                        },
                      },
                    }}
                  >
                    <RestUsergroups>
                      {t('GNB_Profile_MyProfile_06', { number: (user?.userGroups.length as number) - 1 })}
                    </RestUsergroups>
                  </Tooltip>
                </Groups>
              ) : (
                <span>{user?.userGroups[0]?.name}</span>
              )}
            </Value>
          </Property>
          <Property className={phoneErrors.phone?.message ? 'edit-mode' : ''}>
            <Key>
              <PhoneIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <span>{t('Member_User_16')}</span>
            </Key>
            {isEditMode.phone ? (
              <form onSubmit={handleSubmitPhone(validation.successPhone)}>
                <Value>
                  <TextField
                    {...registerPhone('phone')}
                    size="small"
                    fullWidth
                    error={!!phoneErrors.phone?.message}
                    helperText={phoneErrors.phone?.message}
                    autoFocus={isEditMode.phone}
                  />
                  <Buttons>
                    <StyledOutlineBtn
                      variant="outline"
                      onClick={() => {
                        setIsEditMode(prev => {
                          return { ...prev, phone: false };
                        });
                        resetPhone({ phone: '' });
                      }}
                      size="small"
                      paddingHorizontal={12}
                    >
                      {t('Subscrib_Detail_BuyRequest_13')}
                    </StyledOutlineBtn>
                    <Button variant="contain" size="small" type="submit" paddingHorizontal={12}>
                      {t('Member_User_24')}
                    </Button>
                  </Buttons>
                </Value>
              </form>
            ) : (
              <Value>
                <span>{user?.phone || '-'}</span>
                <IconButton
                  onClick={() =>
                    setIsEditMode(prev => {
                      return { ...prev, phone: true };
                    })
                  }
                  variant="outline"
                  size="small"
                >
                  <PencilIcon width={16} height={16} color={theme.colors['ic-gray-main']} />
                </IconButton>
              </Value>
            )}
          </Property>
          <Property>
            <Key>
              <KeyIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <span>{t('GNB_Profile_MyProfile_07')}</span>
            </Key>
            <Value>
              <span>
                {t('GNB_Profile_MyProfile_10', {
                  Day: dayjs(user?.lastPasswordChangeDate).isValid()
                    ? dayjs(user?.lastPasswordChangeDate).format(t('DateFormat_YMD'))
                    : '',
                })}
              </span>
              <Button
                size="small"
                variant="contain"
                onClick={() => {
                  modal.close();
                  naviage('/change-password');
                }}
                paddingHorizontal={12}
              >
                {t('GNB_Profile_MyProfile_09')}
              </Button>
            </Value>
          </Property>
        </Body>
      </ModalWrapper>
    </Modal>
  );
});

const ModalWrapper = styled(Box)<ModalWrapperProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${props => (props.language === 'ko' ? '600px' : '653px')};
  min-height: 430px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 8px 10px -5px rgba(0, 0, 0, 0.08);
  overflow: auto;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  padding: 18px 20px 16px 28px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 34px 48px 64px 60px;
  align-items: flex-start;
  justify-content: center;
  &.edit-mode {
    padding: 34px 48px 0 60px;
  }
`;

const Property = styled(Box)`
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > form {
    width: 100%;
  }
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &.edit-mode {
    margin-bottom: 12px;
  }
`;

const Key = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 157px;
  flex-shrink: 0;
  align-items: center;

  & > span {
    ${fonts.Body1}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    padding: 4px 0;
  }
`;

const Value = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  & > span {
    ${fonts.Body1}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    padding: 4px 0;
  }
  & .MuiFormControl-root {
    max-width: 225px;
    height: auto;
  }
  // & p.MuiFormHelperText-root {
  //   margin: 4px 0 12px;
  // }
`;

const Buttons = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const IconButton = styled(Button)`
  width: 32px;
  border-color: ${({ theme }) => theme.colors['border-gray-light']};
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors['state-white-purple-hover']};
    & svg path {
      fill: ${({ theme }) => theme.colors['ic-purple']};
    }
  }
`;

const Groups = styled(Box)`
  display: flex;
`;

const UsergroupOfTooltip = styled('span')`
  display: flex;
  align-items: center;
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  padding: 8px 7px;
`;

const RestUsergroups = styled(Box)`
  ${fonts.Underline1}
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
  text-decoration-color: ${({ theme: { colors } }) => colors['text-blue-light']};
`;

const StyledOutlineBtn = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
`;
