import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetMonthlyBillingList } from '@queryHooks/useMonthlyBilling';
import { numberTickFormatter } from '@utils/ChartUtil';
import { numberFormat } from '@utils/numberFormat';
import * as S from '../SummaryStyles';
import { SkeletonBarChart } from './SkeletonChart';

const { getStringSize } = require('recharts/lib/util/DOMUtils');

type CostChartData = { month: string; cost: number; currencyUnit: CurrencyUnit };

type Props = {
  monthFrom: DateTimeString;
  monthTo: DateTimeString;
};
export const Cost = ({ monthFrom, monthTo }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();

  const {
    isSuccess,
    data: monthlyBilling,
    isLoading,
  } = useGetMonthlyBillingList({
    subscriptionId: subscription.id,
    monthFrom,
    monthTo,
    sort: ['month.asc'],
  });

  const chartData: CostChartData[] =
    isSuccess && monthlyBilling.content.length > 0
      ? monthlyBilling.content.map(({ cost, currencyUnit, month }) => ({
          month: dayjs(month).format(t('DateFormat_YM')),
          cost,
          currencyUnit,
        })) ?? []
      : [];

  const yAxisTickFormatter = numberTickFormatter(chartData.map(({ cost }) => cost));

  const yAxisTickStyle = { ...fonts.Caption2, color: colors['text-gray-light'] };
  const maxValue = Math.max(...chartData.map(({ cost }) => cost));
  const yAxisWidth = getStringSize(yAxisTickFormatter(maxValue), yAxisTickStyle).width + 15; // tickMargin 고려 + 넉넉히 2 더해줌

  return (
    <S.BarChartContainer>
      <S.ChartHeader>
        <S.HeaderText $hasData={chartData.length > 0}>{t('Subscrib_Detail_Overview_18')}</S.HeaderText>
      </S.ChartHeader>
      {isLoading ? (
        <Loader />
      ) : chartData.length === 0 ? (
        <SkeletonBarChart monthFrom={monthFrom} monthTo={monthTo} />
      ) : (
        <ResponsiveContainer width="100%" height={265}>
          <BarChart data={chartData}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="month" stroke="1" tick={{ ...fonts.Caption2, color: colors['text-gray-light'] }} />
            <YAxis stroke="1" width={yAxisWidth} tick={yAxisTickStyle} tickFormatter={yAxisTickFormatter} />
            <Tooltip cursor={{ fill: colors['state-white-hover'] }} content={<CostTooltip />} />
            <Bar
              dataKey="cost"
              fill={colors['graph-yellow']}
              barSize={10}
              background={{ fill: colors['graph-gray-lighter'] }}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </S.BarChartContainer>
  );
};

const CostTooltip = ({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: { value: number; payload: CostChartData }[];
  label?: string;
}) => {
  const { t } = useTranslation();
  return active && payload && payload.length > 0 ? (
    <S.TooltipContainer>
      <p>{label}</p>
      <dl>
        <dt>{t('Subscrib_Detail_Overview_18')}</dt>
        <dd>
          {numberFormat({
            num: payload[0].value,
            maxFractionDigit: 2,
            style: 'currency',
            currency: payload[0].payload.currencyUnit,
          })}
        </dd>
      </dl>
    </S.TooltipContainer>
  ) : null;
};
