import { InquiryListModel } from '@models/InquiryListModel';
import { InquiryModel } from '@models/InquiryModel';
import { KEYS, repository } from '@repositories/Repository';
import { IInquiryRepository, InquiryCreateRq, InquiryFilter } from '@repositories/inquiryRepository';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';

const inquiryRepo = repository.get<IInquiryRepository>(KEYS.INQUIRY_REPOSITORY);

const inquiryQueryKey = {
  all: ['inquiry'] as const,
  lists: () => [...inquiryQueryKey.all, 'list'] as const,
  details: () => [...inquiryQueryKey.all, 'detail'] as const,

  list: (filter: InquiryFilter) => [...inquiryQueryKey.lists(), { ...filter }] as const,
  detail: (id: string) => [...inquiryQueryKey.details(), id] as const,
};

export const useGetInquiries = (filter: InquiryFilter, options?: UseQueryOptions<Page<InquiryListModel>>) =>
  useQuery({
    queryKey: inquiryQueryKey.list(filter),
    queryFn: async () => {
      const result = await inquiryRepo.getList(filter);
      return {
        ...result,
        content: result.content.map(dto => new InquiryListModel(dto)),
      };
    },
    ...options,
  });

export const useGetInquiry = (id: string, options?: UseQueryOptions<InquiryModel>) =>
  useQuery({
    queryKey: inquiryQueryKey.detail(id),
    queryFn: async () => {
      const result = await inquiryRepo.getOne(id);
      return new InquiryModel(result);
    },
    ...options,
  });

export const useAddInquiry = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: InquiryCreateRq) => {
      const result = await inquiryRepo.create(data);
      return result.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(inquiryQueryKey.lists());
    },
  });
};
