import type { PlanVisibilityScope, SoftwareSimple } from '@repositories/softwareRepository/Types';
import { CategorySimpleModel } from '@models/category/CategorySimpleModel';

export class SoftwareSimpleModel {
  private readonly _id: string;
  private readonly _productCode: string;
  private readonly _name: string;
  private readonly _description: string;
  private readonly _url: string;
  private readonly _companyName: string;
  private readonly _iconUrl: string;
  private readonly _thumbnailUrl: string;
  private readonly _category: CategorySimpleModel;
  private readonly _categoryCount: number;
  private readonly _isSubscribing: boolean;
  private readonly _providerId: string | null;
  private readonly _planVisibilityScope: PlanVisibilityScope;

  constructor(dto?: SoftwareSimple) {
    this._id = dto?.id || '';
    this._productCode = dto?.productCode || '';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._url = dto?.url || '';
    this._companyName = dto?.companyName || '';
    this._iconUrl = dto?.iconUrl || '';
    this._thumbnailUrl = dto?.thumbnailUrl || '';
    this._category = new CategorySimpleModel(dto?.category);
    this._categoryCount = dto?.categoryCount || 0;
    this._isSubscribing = dto?.isSubscribing ?? false;
    this._providerId = dto?.providerId || null;
    this._planVisibilityScope = dto?.planVisibilityScope || 'ALL_USERS';
  }

  get providerId(): string | null {
    return this._providerId;
  }

  get id(): string {
    return this._id;
  }

  get productCode(): string {
    return this._productCode;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get url(): string {
    return this._url;
  }

  get companyName(): string {
    return this._companyName;
  }

  get iconUrl(): string {
    return this._iconUrl;
  }

  get thumbnailUrl(): string {
    return this._thumbnailUrl;
  }

  get categoryId(): string | undefined {
    return this.category?.id;
  }

  get category(): CategorySimpleModel {
    return this._category;
  }

  get categoryCount(): number {
    return this._categoryCount;
  }

  get isSubscribing(): boolean {
    return this._isSubscribing;
  }

  public get planVisibilityScope(): PlanVisibilityScope {
    return this._planVisibilityScope;
  }
}
