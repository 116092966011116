import { MonthlyPurchase, MonthlyPurchaseStat } from '@type/Statistics';
import { MonthlyData, MonthlySummaryWithTotal } from '@type/Summary';
import { MonthlyDataMapper } from '@utils/mapper';
import { ObjectUtil } from '@utils/ObjectUtil';
import { makeAutoObservable } from 'mobx';

export class MonthlyPurchaseStatModel implements MonthlyPurchaseStat {
  data: MonthlyPurchase[];

  totalAmount: number;

  totalOriginalAmount: number;

  totalSubscriptionAmount: number;

  totalSubscriptionOriginalAmount: number;

  exhaustionRate: number;

  currencyUnit: CurrencyUnit;

  originalCurrencyUnit: CurrencyUnit;

  constructor(dto?: MonthlyPurchaseStat) {
    this.totalAmount = -1;
    this.totalOriginalAmount = -1;
    this.totalSubscriptionAmount = -1;
    this.totalSubscriptionOriginalAmount = -1;
    this.exhaustionRate = -1;
    this.data = [];
    this.currencyUnit = 'KRW';
    this.originalCurrencyUnit = 'USD';

    if (dto) {
      this.update(dto);
    }

    makeAutoObservable(this);
  }

  update = (dto: MonthlyPurchaseStat) => {
    ObjectUtil.update(this, dto);
  };

  get monthlyData(): MonthlySummaryWithTotal {
    const monthlyData = {} as MonthlyData;

    MonthlyDataMapper.forEach(month => {
      monthlyData[month] = 0;
    });

    this.data.forEach(element => {
      const month = parseInt(element.month.split('-')[1], 10);
      monthlyData[MonthlyDataMapper[month]] = element.amount;
    });

    delete monthlyData['-'];

    return {
      name: '',
      data: monthlyData,
      unit: this.currencyUnit,
      total: this.totalAmount,
    };
  }

  get lineChartData(): { name: string; data: number }[] {
    return this.data.map(element => ({
      name: MonthlyDataMapper[parseInt(element.month.split('-')[1], 10)].toString(),
      data: element.amount,
    }));
  }

  get stackChartData(): { name: string; data: number }[] {
    return this.data.map(element => ({
      name: MonthlyDataMapper[parseInt(element.month.split('-')[1], 10)].toString(),
      data: element.amount,
    }));
  }
}
