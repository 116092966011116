import { CartProductModel } from '@models/softwareModels/CartProductModel';
import { BrowserStorageMapper } from '../BrowserStorageMapper';

class CartProductModelMapper implements BrowserStorageMapper<CartProductModel> {
  fromJson(json: Record<string, any>): CartProductModel {
    return new CartProductModel({
      companyName: json._companyName,
      endDate: json._endDate,
      iconUrl: json._iconUrl,
      request: json._request,
      startDate: json._startDate,
      subscriptionType: json._subscriptionType,
      thumbnailUrl: json._thumbnailUrl,
      isModified: json._isModified,
      isChecked: json._isChecked,
      productPlans: json._productPlans,
      description: json._description,

      id: json._id,
      productId: json._productId,
      productName: json._productName,
      planId: json._planId,
      planName: json._planName,
      inquiry: json._inquiry,
    });
  }
}

export default new CartProductModelMapper();
