import type { UseFormProps } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import type { ModuleUsageCost } from '@repositories/monthlyBillingRepository';
import { transformToNumber } from '@utils/YupUtil';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';

export type MonthlyBillingUpdateHookForm = {
  cost: number;
  originalCost?: number;
  exchangeRate?: number;
  paymentDate?: string;
  moduleUsageCosts: ModuleUsageCost[];
};

export const useMonthlyBillingUpdateForm = (billing: SubscriptionBillingModel) => {
  const validationSchema = Yup.object().shape({
    cost: Yup.number()
      .transform((_, value) => transformToNumber(value))
      .required('필수 입력 사항입니다.')
      .typeError('숫자만 입력 가능합니다.'),
    originalCost: Yup.number()
      .transform((_, value) => transformToNumber(value))
      .typeError('숫자만 입력 가능합니다.'),
    exchangeRate: Yup.number()
      .transform((_, value) => transformToNumber(value))
      .typeError('숫자만 입력 가능합니다.'),
    paymentDate: Yup.string()
      .nullable()
      .test('is-YYYY.MM.DD', '날짜 형식이 올바르지 않습니다. (YYYY.MM.DD)', value => {
        return dayjs(value, 'YYYY.MM.DD', true).isValid();
      }),
    moduleUsageCosts: Yup.array().of(
      Yup.object().shape({
        licenseId: Yup.string(),
        licenseName: Yup.string(),
        moduleCode: Yup.string(),
        cost: Yup.number()
          .transform((_, value) => transformToNumber(value))
          .typeError('숫자만 입력 가능합니다.'),
        originalCost: Yup.number()
          .transform((_, value) => transformToNumber(value))
          .typeError('숫자만 입력 가능합니다.'),
        price: Yup.number()
          .transform((_, value) => transformToNumber(value))
          .typeError('숫자만 입력 가능합니다.'),
        usage: Yup.number()
          .transform((_, value) => transformToNumber(value))
          .typeError('숫자만 입력 가능합니다.'),
      }),
    ),
  });

  const mothlyBillingUpdateFormOptions: UseFormProps<MonthlyBillingUpdateHookForm> = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      cost: billing.cost,
      originalCost: billing?.originalCost,
      exchangeRate: billing?.exchangeRate,
      paymentDate: billing?.paymentDate ? dayjs(billing.paymentDate).format('YYYY.MM.DD') : undefined,
      moduleUsageCosts: (billing?.moduleUsageCosts ?? []).map(usageCost => ({
        licenseId: usageCost.licenseId,
        licenseName: usageCost.licenseName,
        moduleCode: usageCost.moduleCode,
        cost: usageCost.cost,
        originalCost: usageCost.originalCost,
        price: usageCost.price,
        usage: usageCost.usage,
      })),
    },
  };

  return useForm<MonthlyBillingUpdateHookForm>(mothlyBillingUpdateFormOptions);
};
