import { MonthlyProfitStat } from '@type/Statistics';
import { MonthlyData, MonthlySummaryWithTotal } from '@type/Summary';
import { MonthlyDataMapper } from '@utils/mapper';
import { ObjectUtil } from '@utils/ObjectUtil';
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

export class MonthlyProfitStatModel implements MonthlyProfitStat {
  currencyUnit: CurrencyUnit;

  data: {
    accumulatedProfit: number;
    accumulatedProfitRate: number;
    month: string;
    profit: number;
    profitRate: number;
    purchaseAmount: number;
    purchaseExchangeRate: number;
    purchaseOriginalAmount: number;
    salesAmount: number;
    salesExchangeRate: number;
    salesOriginalAmount: number;
  }[];

  originalCurrencyUnit: CurrencyUnit;

  totalProfit: number;

  totalProfitRate: number;

  totalPurchaseAmount: number;

  totalPurchaseOriginalAmount: number;

  totalSalesAmount: number;

  totalSalesOriginalAmount: number;

  constructor(dto?: MonthlyProfitStat) {
    this.currencyUnit = 'KRW';
    this.data = [];
    this.originalCurrencyUnit = 'USD';
    this.totalProfit = -1;
    this.totalProfitRate = -1;
    this.totalPurchaseAmount = -1;
    this.totalPurchaseOriginalAmount = -1;
    this.totalSalesAmount = -1;
    this.totalSalesOriginalAmount = -1;

    if (dto) {
      this.update(dto);
    }

    makeAutoObservable(this);
  }

  update = (dto: Partial<MonthlyProfitStat>) => {
    ObjectUtil.update(this, dto);
  };

  get monthlyData(): MonthlySummaryWithTotal {
    const monthlyData = {} as MonthlyData;

    MonthlyDataMapper.forEach(month => {
      monthlyData[month] = 0;
    });

    this.data.forEach(element => {
      const month = parseInt(element.month.split('-')[1], 10);
      monthlyData[MonthlyDataMapper[month]] = element.profit;
    });

    delete monthlyData['-'];
    return {
      name: '',
      data: monthlyData,
      unit: this.currencyUnit,
      total: this.totalProfit,
    };
  }

  get lineChartData(): { name: string; data: number; isPredict: boolean }[] {
    const current = dayjs();
    return this.data.map(element => ({
      name: MonthlyDataMapper[parseInt(element.month.split('-')[1], 10)].toString(),
      data: element.profit,
      isPredict: current.isBefore(element.month, 'month'),
    }));
  }
}
