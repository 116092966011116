const defaultWidth = 24;
const defaultHeight = 24;

export const RectangleFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect x="10" y="4" width="4" height="17" rx="1" fill={color} />
      </g>
    </svg>
  );
};

RectangleFillIcon.displayName = 'RectangleFillIcon';
