import { ForwardedRef, forwardRef } from 'react';

const defaultWidth = 16;
const defaultHeight = 16;

export const CalendarColorIcon = forwardRef(
  ({ width = defaultWidth, height = defaultHeight }: SvgProps, ref: ForwardedRef<SVGSVGElement>) => (
    <svg ref={ref} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <g filter="url(#filter0_d_2846_7187)">
          <path
            d="M1 3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V3Z"
            fill="#8A63D2"
          />
        </g>
        <g filter="url(#filter1_d_2846_7187)">
          <path d="M1 3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V4.33333H1V3Z" fill="#5A32A3" />
        </g>
        <g filter="url(#filter2_d_2846_7187)">
          <path
            d="M11.709 6.41211V12.0684H10.5293V7.5293H10.498L9.20117 8.3418V7.30273L10.5996 6.41211H11.709Z"
            fill="#F5F0FF"
          />
          <path
            d="M6.15625 12.1465C4.91406 12.1465 4.02344 11.4629 4 10.4746H5.1875C5.20703 10.8965 5.61719 11.1738 6.16406 11.1777C6.72656 11.1738 7.12891 10.8613 7.125 10.4043C7.12891 9.94336 6.71875 9.61914 6.05469 9.61523H5.53125V8.74805H6.05469C6.61328 8.74805 7.00391 8.44336 7 7.99805C7.00391 7.56445 6.67187 7.26758 6.17187 7.27148C5.67187 7.26758 5.26172 7.54883 5.24219 7.98242H4.11719C4.13672 7.00977 5.01172 6.33398 6.17187 6.33398C7.35156 6.33398 8.13672 7.0332 8.13281 7.92773C8.13672 8.56055 7.69531 9.00977 7.0625 9.12305V9.16992C7.89453 9.27148 8.35156 9.77539 8.34375 10.4824C8.35156 11.4512 7.43359 12.1465 6.15625 12.1465Z"
            fill="#F5F0FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2846_7187"
            x="0"
            y="0.5"
            width="16"
            height="16"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2846_7187" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2846_7187" result="shape" />
          </filter>
          <filter
            id="filter1_d_2846_7187"
            x="0"
            y="0.5"
            width="16"
            height="5.33398"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2846_7187" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2846_7187" result="shape" />
          </filter>
          <filter
            id="filter2_d_2846_7187"
            x="3.5"
            y="6.33398"
            width="8.70898"
            height="6.8125"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2846_7187" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2846_7187" result="shape" />
          </filter>
        </defs>
      </g>
    </svg>
  ),
);

CalendarColorIcon.displayName = 'CalendarColorIcon';
