import { Typography } from '@mui/material';
import { CheckIcon } from '@icons/CheckIcon';
import { useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';
import * as S from './TenantRequestDoneStyles';

export const TenantRequestDone = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <S.Conatiner>
      <S.IconWrapper>
        <CheckIcon width={32} height={32} color={colors['ic-white']} />
      </S.IconWrapper>

      <Typography variant="headline4" marginBottom="20px">
        {t('Service_Request_11')}
      </Typography>

      <S.Content variant="body1">
        {t('Tenant_Request_Done_01')}👏
        <br />
        <Trans i18nKey="Tenant_Request_Done_02" components={{ 1: <strong /> }} />
      </S.Content>

      <S.GoToStore href="/store">{t('Ad_Cart_07')}</S.GoToStore>
    </S.Conatiner>
  );
};
