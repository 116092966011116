import type { Theme } from '@emotion/react';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

type ButtonVariant = 'outline' | 'outline-shadow' | 'contain' | 'text';
type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large' | 'extraLarge' | '2extraLarge';
// type ButtonColor = 'purple' | 'gray' | 'gradient';

type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
  paddingHorizontal?: number;
  className?: string;
  fullWidth?: boolean;
  color?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      startIcon,
      endIcon,
      size,
      variant,
      children,
      paddingHorizontal = 0,
      className,
      fullWidth = false,
      color,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledButton
        ref={ref}
        className={className}
        size={size}
        variant={variant}
        paddingHorizontal={paddingHorizontal}
        fullWidth={fullWidth}
        type="button"
        color={color}
        {...props}
      >
        {startIcon}
        <span>{children}</span>
        {endIcon}
      </StyledButton>
    );
  },
);

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    &.start {
      margin-left: 4px;
    }
    &.end {
      margin-right: 4px;
    }
  }
  &:disabled {
    cursor: auto;
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
    &:hover {
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
  }

  ${({ variant, size, paddingHorizontal, theme: { colors } }) => {
    if (variant === 'outline' && size === 'xsmall') {
      return css`
        ${OutlineCss(colors)}
        ${ExtraSmallCss}
        padding: 4px ${paddingHorizontal}px;
      `;
    }
    if (variant === 'outline' && size === 'small') {
      return css`
        ${OutlineCss(colors)}
        height: 32px;
        border-radius: 3px;
        ${fonts.Button4};
        padding: 5px ${paddingHorizontal}px;
      `;
    }
    if (variant === 'outline' && size === 'medium') {
      return css`
        ${OutlineCss(colors)}
        height: 36px;
        border-radius: 3px;
        ${fonts.Button4};
        padding: 7px ${paddingHorizontal}px;
      `;
    }
    if (variant === 'outline' && size === 'large') {
      return css`
        ${OutlineCss(colors)}
        ${LargeCss}
        padding: 7px ${paddingHorizontal}px;
      `;
    }
    if (variant === 'outline' && size === 'extraLarge') {
      return css`
        ${OutlineCss(colors)}
        ${ExtraLargeCss}
        padding: 11px ${paddingHorizontal}px;
      `;
    }
    if (variant === 'outline' && size === '2extraLarge') {
      return css`
        ${OutlineCss(colors)}
        ${TwoExtraLargeCss}
        padding: 14px ${paddingHorizontal}px;
      `;
    }
    if (variant === 'contain' && size === 'small') {
      return css`
        ${ContainCss(colors)}
        ${SmallCss}
        padding: 6px ${paddingHorizontal}px;
        border: none;
      `;
    }
    if (variant === 'contain' && size === 'medium') {
      return css`
        ${ContainCss(colors)}
        ${MediumCss}
        padding: 8px ${paddingHorizontal}px;
        border: none;
      `;
    }
    if (variant === 'contain' && size === 'large') {
      return css`
        ${ContainCss(colors)}
        ${LargeCss}
        padding: 8px ${paddingHorizontal}px;
        border: none;
      `;
    }
    if (variant === 'contain' && size === 'extraLarge') {
      return css`
        ${ContainCss(colors)}
        ${ExtraLargeCss}
        padding: 12px ${paddingHorizontal}px;
        border: none;
      `;
    }
    if (variant === 'contain' && size === '2extraLarge') {
      return css`
        ${ContainCss(colors)}
        ${TwoExtraLargeCss}
        padding: 15px ${paddingHorizontal}px;
        border: none;
      `;
    }

    if (variant === 'text' && size === 'small') {
      return css`
        ${SmallCss}
        ${TextCss(colors)}
        padding: 0 ${paddingHorizontal}px;
      `;
    }

    if (variant === 'text' && size === 'medium') {
      return css`
        ${MediumCss}
        ${TextCss(colors)}
        padding: 0 ${paddingHorizontal}px;
      `;
    }

    if (variant === 'text' && size === 'large') {
      return css`
        ${LargeCss}
        ${TextCss(colors)}
        padding: 0 ${paddingHorizontal}px;
      `;
    }

    if (variant === 'text' && size === 'extraLarge') {
      return css`
        ${ExtraLargeCss}
        ${TextCss(colors)}
        padding: 0 ${paddingHorizontal}px;
      `;
    }

    if (variant === 'text' && size === '2extraLarge') {
      return css`
        ${TwoExtraLargeCss}
        ${TextCss(colors)}
        padding: 0 ${paddingHorizontal}px;
      `;
    }

    return ``;
  }}
  ${({ fullWidth }) => (fullWidth ? `width: 100%;` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}
`;

const OutlineCss = (themeColors: Theme['colors']) => css`
  background: ${themeColors['bg-white']};
  border: 1px solid ${themeColors['border-gray-light']};
  color: ${themeColors['text-gray-main']};
  flex-shrink: 0;
  &:hover {
    background: ${themeColors['state-white-hover']};
  }

  &:disabled {
    background: ${themeColors['bg-white']};
    border: 1px solid ${themeColors['border-gray-light']};
    &:hover {
      background: ${themeColors['bg-white']};
      border: 1px solid ${themeColors['border-gray-light']};
    }
  }
`;

const ContainCss = (themeColors: Theme['colors']) => css`
  background: ${themeColors['bg-purple']};
  color: ${themeColors['text-white']};
  flex-shrink: 0;
  &:hover {
    background: ${themeColors['state-purple-hover']};
  }

  &:disabled {
    background: ${themeColors['bg-gray-dark']};
    &:hover {
      background: ${themeColors['bg-gray-dark']};
    }
  }
`;

const TextCss = (themeColors: Theme['colors']) => css`
  padding: 0;
  background: none;
  border: none;
  flex-shrink: 0;
  &:hover {
    color: ${themeColors['state-purple-hover']};
    & > svg path {
      fill: ${themeColors['state-purple-hover']};
    }
  }

  &:disabled {
    color: ${themeColors['text-gray-light']};
    & > svg path {
      fill: ${themeColors['text-gray-light']};
    }
    &:hover {
      color: ${themeColors['text-gray-light']};
      & > svg path {
        fill: ${themeColors['text-gray-light']};
      }
    }
  }
`;

const ExtraSmallCss = css`
  height: 26px;
  border-radius: 1px;
  ${fonts.Body4};
`;

const SmallCss = css`
  height: 32px;
  border-radius: 3px;
  ${fonts.Button6};
`;

const MediumCss = css`
  height: 36px;
  border-radius: 3px;
  ${fonts.Button3};
`;

const LargeCss = css`
  height: 40px;
  border-radius: 3px;
  ${fonts.Button2};
`;

const ExtraLargeCss = css`
  height: 48px;
  border-radius: 3px;
  ${fonts.Button2};
`;

const TwoExtraLargeCss = css`
  height: 54px;
  border-radius: 3px;
  ${fonts.Button2};
`;
