import { LiHTMLAttributes, useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { useSelectedLocation } from '@customHooks/useSelectedLocation';
import { transientOptions } from '@utils/CommonUtil';
import i18n from '@locales/i18n';

type Tab = {
  name: string;
  path: string;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  replace?: boolean;
};

type Props = {
  tabList: Tab[];
  subTitle?: string;
  variant?: 'line' | 'contain' | 'contain-round';
  className?: string;
  width?: string;
  isAdmin?: boolean;
} & LiHTMLAttributes<HTMLLIElement>;

export const RoutingTabs = ({ tabList, subTitle, className = '', variant = 'line', isAdmin = false, width }: Props) => {
  const [curTab] = useSelectedLocation({ itemList: tabList });
  const [hoveredTab, setHoveredTab] = useState<string | null>(null);
  const renderTabItem = (tab: Tab) => {
    const handleMouseEnter = () => setHoveredTab(tab.name);
    const handleMouseLeave = () => setHoveredTab(null);

    switch (variant) {
      case 'contain':
        return tab.disabled ? (
          <DisabledContainTabItem key={tab.name}>
            {tab.name}
            {tab.endIcon}
          </DisabledContainTabItem>
        ) : (
          <ContainTabItem
            key={tab.name}
            width={width}
            state={{ subTitle }}
            to={tab.path}
            selected={curTab?.path === tab.path}
            replace={tab.replace}
          >
            {tab.name}
            {tab.endIcon}
          </ContainTabItem>
        );

      case 'line':
        return (
          <TabItem
            key={tab.name}
            variant={variant}
            width={width}
            selected={curTab?.path === tab.path}
            className={`${i18n.language === 'en' ? 'en-tab-item' : ''} ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Tab
              state={{ subTitle }}
              to={`${tab.path}`}
              selected={curTab?.path === tab.path}
              variant={variant}
              $isAdmin={isAdmin}
              replace={tab.replace}
              $isHover={hoveredTab === tab.name}
            >
              <span>{tab.name}</span>
            </Tab>
          </TabItem>
        );

      default:
        return (
          <TabItem
            key={tab.name}
            variant={variant}
            width={width}
            selected={curTab?.path === tab.path}
            className={`${i18n.language === 'en' ? 'en-tab-item' : ''} ${className}`}
          >
            <Tab
              state={{ subTitle }}
              to={`${tab.path}`}
              selected={curTab?.path === tab.path}
              variant={variant}
              $isAdmin={isAdmin}
              replace={tab.replace}
            >
              {tab.name}
            </Tab>
          </TabItem>
        );
    }
  };

  return (
    <TabWrapper variant={variant} className={tabList.length === 1 ? `single-tab ${className}` : className}>
      {tabList?.map(tab => renderTabItem(tab))}
    </TabWrapper>
  );
};

const TabWrapper = styled('div')<{
  variant: 'line' | 'contain' | 'contain-round';
}>`
  width: 100%;
  display: flex;
  ${({ variant, theme: { colors } }) => {
    if (variant === 'contain') {
      return css`
        border-bottom: 1px solid ${colors['border-gray-dark']};
      `;
    }
    return ``;
  }};
  &.single-tab {
    a {
      color: ${({ theme }) => theme.colors['border-purple']};
      border-width: 1px 1px 0 1px;
      border-color: ${({ theme }) => theme.colors['border-gray-dark']};
      :after {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        background-color: ${({ theme }) => theme.colors['bg-white']};
      }
    }
  }
`;

const TabItem = styled('div', {
  shouldForwardProp: propName => propName !== 'width',
})<{
  variant: 'line' | 'contain' | 'contain-round';
  width?: string;
  selected?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ variant, selected, width, theme: { colors } }) => {
    if (variant === 'line') {
      return css`
        min-width: 140px;
        width: ${width || 'auto'};
        align-items: flex-end;
      `;
    }
    if (variant === 'contain') {
      return css`
        width: ${width || '122px'};
        height: 43px;
        align-items: center;
        padding: 0 20px;
        border: 1px solid ${colors['border-gray-lighter']};
        border-width: 1px 0 0 1px;
        border-radius: 0;
        background-color: inherit;
        box-sizing: content-box;
        -webkit-box-sizing: content-box;
        &:last-of-type {
          border-width: 1px 1px 0 0;
        }
        ${selected &&
        css`
          & {
            background-color: inherit;
            border-width: 1px 1px 0 1px;
            border-color: ${colors['border-gray-dark']};
            &:last-of-type {
              border-width: 1px 1px 0 1px;
            }
            & ~ li {
              border-width: 1px 1px 0 0;
            }
            :after {
              display: block;
              content: '';
              width: 100%;
              height: 3px;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -2px;
              background-color: ${colors['bg-white']};
            }
          }
        `}
      `;
    }
    if (variant === 'contain-round') {
      return css`
        width: ${width || 'auto'};
      `;
    }
    return ``;
  }};
`;

const Tab = styled(Link, transientOptions)<{
  selected: boolean;
  variant: 'line' | 'contain' | 'contain-round';
  $isAdmin: boolean;
  $isHover?: boolean;
}>`
  display: inline-flex;
  align-items: flex-end;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  ${({ variant, selected, theme: { colors }, $isAdmin, $isHover }) => {
    if (variant === 'line' && !$isAdmin) {
      return css`
        width: 100%;
        height: 100%;
        justify-content: center;
        align-itesm: center;
        ${fonts.Button1};
        color: ${colors['text-gray-main']};
        & span {
          padding-bottom: 15px;
          border-bottom: ${selected
            ? `3px solid ${colors['border-gray-darker']}`
            : $isHover
            ? `3px solid ${colors['border-gray-darker']}`
            : '3px solid transparent'};
        }
      `;
    }
    if (variant === 'line' && $isAdmin) {
      return css`
        width: 100%;
        height: 100%;
        align-itesm: center;
        justify-content: center;
        ${fonts.Button1};
        color: ${colors['text-white']};
        & span {
          padding-bottom: 15px;
          border-bottom: ${selected
            ? `3px solid ${colors['border-white']}`
            : $isHover
            ? `3px solid ${colors['border-white']}`
            : '3px solid transparent'};
        }
      `;
    }
    if (variant === 'contain') {
      return css`
        ${fonts.Button3}
        color: ${selected ? colors['text-purple-light'] : colors['text-gray-sub-dark']};
        &:hover {
          color: ${colors['border-purple']};
        }
      `;
    }
    if (variant === 'contain-round') {
      return css`
        padding: 6px 16px;
        ${fonts.Button4}
        color: ${selected ? colors['text-white'] : colors['text-gray-main']};
        background-color: ${selected ? colors['bg-purple'] : colors['bg-white']};
        border: 1px solid ${selected ? colors['border-purple'] : colors['border-gray-light']};
        border-radius: 16px;
        &:hover {
          background-color: ${[colors['state-white-hover']]};
          ${selected &&
          css`
            background-color: ${[colors['bg-purple']]};
          `}
        }
      `;
    }
    return ``;
  }};
`;

/**
 * FIXME: ContainTabItem, DisabledContainTabItem css 정리 필요.
 * disable시 Link 컴포넌트를 비활성화시킬 수 없어서 두 컴포넌트로 분리하여 처리함
 */
const ContainTabItem = styled(Link)<{ selected: boolean; width?: string }>`
  position: relative;
  display: flex;
  gap: 2px;
  justify-content: center;

  width: ${({ width }) => width || '152px'};
  height: 43px;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-width: 1px 0 0 1px;
  border-radius: 0;
  background-color: inherit;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  &:last-of-type {
    border-width: 1px 1px 0 0;
  }
  ${({ selected, theme: { colors } }) =>
    selected &&
    css`
      & {
        background-color: inherit;
        border-width: 1px 1px 0 1px;
        border-color: ${colors['border-gray-dark']};
        &:last-of-type {
          border-width: 1px 1px 0 1px;
        }
        & ~ a {
          border-width: 1px 1px 0 0;
        }
        :after {
          display: block;
          content: '';
          width: 100%;
          height: 3px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          background-color: ${colors['bg-white']};
        }
      }
    `}

  text-decoration: none;
  ${fonts.Button3}
  color: ${({ selected, theme: { colors } }) =>
    selected ? colors['text-purple-light'] : colors['text-gray-sub-dark']};
  &:hover {
    color: ${({ theme: { colors } }) => colors['border-purple']};
  }
`;

const DisabledContainTabItem = styled.div<{ width?: string }>`
  position: relative;
  display: flex;
  gap: 2px;
  justify-content: center;
  cursor: default;

  width: ${({ width }) => width || '152px'};
  height: 43px;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-width: 1px 0 0 1px;
  border-radius: 0;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  &:last-of-type {
    border-width: 1px 1px 0 0;
  }
  ${fonts.Button3}
  color: ${({ theme: { colors } }) => colors['text-gray-lighter']};
`;
