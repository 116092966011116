import { injectable } from 'inversify';
import { Page } from '@type/Page';
import API from '@repositories/Axios';
import qs from 'qs';
import IProductRelationsRepository from './IProductRequestRepository';
import { ProductRequestDTO, Filter, ProductRequestCreateDTO } from './Types';

@injectable()
export class ProductRequestRepository implements IProductRelationsRepository {
  async getList(queries?: Filter): Promise<Page<ProductRequestDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<ProductRequestDTO>>(`/api/v1/product-requests?${queryString}`);
    return result.data;
  }
  async create(data: ProductRequestCreateDTO): Promise<ProductRequestDTO> {
    const result = await API.post<ProductRequestDTO>(`/api/v1/product-requests`, data);
    return result.data;
  }
  async getOne(id: string): Promise<ProductRequestDTO> {
    const result = await API.get<ProductRequestDTO>(`/api/v1/product-requests/${id}`);
    return result.data;
  }
  update(/* id: string, data: unknown */): Promise<ProductRequestDTO> {
    throw new Error('Method not implemented.');
  }
  delete(/* id: string */): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
