import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Label } from '@components/Label';
import { TableHeaderCell } from '@components/table';
import { useUiStore } from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { OrganizationFillIcon } from '@icons/OrganizationFillIcon';
import { useFileDialog } from '@customHooks/useFileDialog';
import { useDeleteTenantBillingAttachment, useUploadTenantBillingAttachments } from '@queryHooks/useBilling';
import { dateFormat } from '@utils/dateUtil';
import { currencyNumberFormat } from '@utils/numberFormat';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { TenantMonthlyBillingModel } from '@models/TenantMonthlyBillingModel';
import { type FileSimpleModel } from '@models/file/FileSimpleModel';
import { BillingAttachmentInfo } from '../BillingAttachmentInfo';

type Props = {
  tenantName: string;
  tenantBilling: TenantMonthlyBillingModel;
  onSubscribtionBillingClick?: (subscriptionBilling: SubscriptionBillingModel) => void;
  PrintButton?: React.ReactNode;
};

export const TenantBillingDetailView = ({
  tenantName,
  tenantBilling,
  onSubscribtionBillingClick,
  PrintButton,
}: Props) => {
  const { alertStore, toastStore } = useUiStore();
  const { t } = useTranslation();
  const { colors } = useTheme();

  const isPrintView = !PrintButton;

  const { openFileDialog, dummyFiles, addDummy, setDummyFinished } = useFileDialog({
    fileLength: tenantBilling.attachments.length,
  });
  const { mutate: fileUpload } = useUploadTenantBillingAttachments();
  const { mutate: fileDelete } = useDeleteTenantBillingAttachment();

  const handleSubscriptionBillingClick = (subscriptionBilling: SubscriptionBillingModel) => () => {
    if (typeof onSubscribtionBillingClick === 'function') {
      onSubscribtionBillingClick(subscriptionBilling);
    }
  };

  const handleAttachFileClick = () => {
    if (tenantBilling.attachments.length >= 10) {
      alertStore.open({
        title: '파일 첨부 실패',
        message: '최대 10개의 파일을 첨부할 수 있습니다.\n첨부된 파일을 삭제 후 다시 시도해주세요.',
      });
    } else {
      openFileDialog(fileList => {
        const files = Array.from(fileList);
        if (tenantBilling.attachments.length + files.length > 10) {
          alertStore.open({
            title: '파일 첨부 실패',
            message: '최대 10개의 파일을 첨부할 수 있습니다.\n첨부된 파일을 삭제 후 다시 시도해주세요.',
          });
        }

        const validFileList = files.filter(({ size }) => size <= 5242880);
        if (validFileList.length !== files.length) {
          alertStore.open({ title: '파일 첨부', message: '5MB 이하의 파일만 첨부할 수 있습니다.' });
        }

        let resultCount = 0;
        validFileList.slice(0, 10 - tenantBilling.attachments.length).forEach(file => {
          const dummy = addDummy(file.name);
          fileUpload({
            tenantId: tenantBilling.tenantId,
            month: tenantBilling.month,
            files: [file],
            config: {
              onUploadProgress: progress => {
                if (progress.progress === 1) {
                  setDummyFinished(dummy.fileId);
                  resultCount += 1;
                  if (resultCount === validFileList.length) {
                    toastStore.open('파일 첨부를 완료했습니다.');
                  }
                }
              },
            },
          });
        });
      });
    }
  };

  const handleDeleteFileClick = (attachment: FileSimpleModel) => {
    alertStore.open({
      title: '첨부 파일 삭제',
      message: '첨부 파일을 삭제합니다.\n해당 작업은 되돌릴 수 없습니다.',
      type: 'error',
      confirmName: '삭제',
      onConfirm: () => {
        fileDelete(
          { tenantId: tenantBilling.tenant.id, month: tenantBilling.month, fileId: attachment.fileId },
          {
            onSuccess: () => {
              toastStore.open('첨부 파일 삭제를 완료했습니다.');
            },
          },
        );
      },
      useCancel: true,
    });
  };

  return (
    <>
      <Header>
        <Logo>
          <OrganizationFillIcon color={colors['ic-white']} />
        </Logo>
        <div>
          <TenantBilling>{t('Acc_Main_24')}</TenantBilling>
          <TenantName>{tenantName}</TenantName>
        </div>
        {PrintButton}
      </Header>

      <DescriptionGrid>
        <DescriptionItem>
          <dt>{t('Acc_Detail_01')}</dt>
          <dd>-</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_08')}</dt>
          <dd>{tenantBilling.provider?.name ?? '-'}</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_02')}</dt>
          <dd>{dateFormat(tenantBilling.createdDate, '-', t('DateFormat_YMD'))}</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_09')}</dt>
          <dd>{tenantBilling.provider?.businessRegistrationNumber ?? '-'}</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_03')}</dt>
          <dd>-</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_10')}</dt>
          <dd>{tenantBilling.provider?.address ?? '-'}</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_04')}</dt>
          <dd>
            {dateFormat(tenantBilling.startDate, '-', t('DateFormat_YMD'))} ~{' '}
            {dateFormat(tenantBilling.endDate, '-', t('DateFormat_YMD'))}
          </dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_11')}</dt>
          <dd>{tenantBilling.provider?.representative ?? '-'}</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_05')}</dt>
          <dd>-</dd>
        </DescriptionItem>
        <DescriptionItem>
          <dt>{t('Acc_Detail_12')}</dt>
          <dd>-</dd>
        </DescriptionItem>
      </DescriptionGrid>

      {!isPrintView && (
        <BillingAttachmentInfo
          dummyFiles={dummyFiles}
          attachments={tenantBilling.attachments}
          onAttachFileClick={handleAttachFileClick}
          onDeleteFileClick={handleDeleteFileClick}
        />
      )}

      <Subtitle>{t('Acc_Detail_20')}</Subtitle>
      <BillingInfoGrid>
        <BillingInfoItem>
          <dt>{t('Acc_Main_06')}</dt>
          <dd>{dateFormat(tenantBilling?.month, '-', t('DateFormat_YM'))}</dd>
        </BillingInfoItem>
        <BillingInfoItem>
          <dt>{t('Acc_Main_16')}</dt>
          <dd>
            <Label variant="solid" color="purple">
              {t('Acc_Main_24')}
            </Label>
          </dd>
        </BillingInfoItem>
        <BillingInfoItem>
          <dt>{t('Acc_Create_31')}</dt>
          <dd>{currencyNumberFormat(tenantBilling.originalCost, tenantBilling.originalCurrencyUnit, 'text')}</dd>
        </BillingInfoItem>
        <BillingInfoItem>
          <dt>{t('Acc_Create_10')}</dt>
          <dd>{currencyNumberFormat(tenantBilling.exchangeRate, tenantBilling.currencyUnit, 'text')}</dd>
        </BillingInfoItem>
        <BillingInfoItem>
          <dt>{t('Acc_Main_13')}</dt>
          <dd>{currencyNumberFormat(tenantBilling.cost, tenantBilling.currencyUnit, 'text')}</dd>
        </BillingInfoItem>
        <BillingInfoItem>
          <dt>{t('Acc_Main_20')}</dt>
          <dd>{dateFormat(tenantBilling?.paymentDate, '-', t('DateFormat_YMD'))}</dd>
        </BillingInfoItem>
        <BillingInfoItem>
          <dt>상태</dt>
          <dd>
            <Label
              variant="solid"
              color={tenantBilling.isForecasted ? 'blue' : tenantBilling.isPayment ? 'gray' : 'yellow'}
            >
              {/* FIXME: i18n */}
              {tenantBilling.isForecasted
                ? '정산 예측'
                : tenantBilling.isPayment
                ? t('Acc_Detail_06')
                : t('Acc_Detail_07')}
            </Label>
          </dd>
        </BillingInfoItem>
        <BillingInfoItem>
          {tenantBilling.isPayment ? (
            <>
              <dt>{t('Acc_Detail_13')}</dt>
              <dd>-</dd>
            </>
          ) : null}
        </BillingInfoItem>
      </BillingInfoGrid>

      <Subtitle>{t('Acc_Detail_19')}</Subtitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell width={464}>{t('Acc_Main_15')}</TableHeaderCell>
              <TableHeaderCell width={320}>{t('Subscrib_Main_12')}</TableHeaderCell>
              <TableHeaderCell width={280}>{t('Acc_Create_09')}</TableHeaderCell>
              <TableHeaderCell width={240}>{t('Acc_Main_18')}</TableHeaderCell>
              <TableHeaderCell width={240}>{t('Acc_Main_19')}</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenantBilling?.subscriptionBillings.map(subscriptionBilling => (
              <TableRow
                key={subscriptionBilling.id}
                onClick={handleSubscriptionBillingClick(subscriptionBilling)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  {subscriptionBilling.subscription.name} ({subscriptionBilling.subscription.software.name})
                </TableCell>
                <TableCell>{subscriptionBilling.subscription.productPlan.planName || '-'}</TableCell>
                <TableCell>
                  {dateFormat(subscriptionBilling.startDate, '-', t('DateFormat_YMD'))} ~{' '}
                  {dateFormat(subscriptionBilling.endDate, '-', t('DateFormat_YMD'))}
                </TableCell>
                <TableCell>
                  {currencyNumberFormat(
                    subscriptionBilling.originalCost,
                    subscriptionBilling.originalCurrencyUnit,
                    'text',
                  )}
                </TableCell>
                <TableCell>
                  {currencyNumberFormat(subscriptionBilling.cost, subscriptionBilling.currencyUnit, 'text')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const Logo = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-light']};
  margin-right: 16px;
`;

const TenantBilling = styled.div`
  ${fonts.Body1}
`;

const TenantName = styled.div`
  ${fonts.Headline4}
`;

const DescriptionGrid = styled.dl`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  margin-bottom: 48px;
`;

const DescriptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  &:nth-of-type(-n + 2) {
    border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-dark']};
  }

  & > dt {
    ${fonts.Headline9}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }

  & > dd {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

const Subtitle = styled.h3`
  ${fonts.Headline8}
  margin-bottom: 20px;
`;

const BillingInfoGrid = styled.dl`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1px;
  column-gap: 1px;
  background-color: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  margin-bottom: 48px;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

const BillingInfoItem = styled.div`
  padding: 15px 20px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};

  & > dt {
    ${fonts.Headline9}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-bottom: 2px;
  }

  & > dd {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;
