import type { TechnicalInquiryUpdateHookForm } from './validationSchema';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import type { TechnicalInquiryDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryDTO';
import type {
  TechnicalInquiryHistoryCreateDTO,
  TechnicalInquiryUpdateDTO,
} from '@repositories/technicalSupportRepository/technicalSupport.types';
import { useCreateTechnicalInquiryHistory, usePutTechnicalInquiryUpdate } from '@queryHooks/useTechnicalSupport';
import { useTechnicalSupportUpdateForm } from './validationSchema';

type Props = {
  technicalInquiry?: TechnicalInquiryDTOModel;
  open: boolean;
  onClose: () => void;
  isUpdateMode?: boolean;
};

export const TechnicalSupportUpdateModal = ({ technicalInquiry, open, onClose, isUpdateMode }: Props) => {
  const {
    uiStore: { toastStore },
  } = useStore();
  const { mutate: technicalInquiryUpdate } = usePutTechnicalInquiryUpdate();
  const { mutate: technicalInquiryHistoryCreate } = useCreateTechnicalInquiryHistory();

  const { tittle, success } = isUpdateMode
    ? {
        tittle: '기술지원 문의 수정',
        success: (data: TechnicalInquiryUpdateHookForm) => {
          const rqData: TechnicalInquiryUpdateDTO = {
            inquiry: data.inquiry,
            technicalInquiryId: technicalInquiry?.technicalInquiryId,
          };
          if (technicalInquiry) {
            technicalInquiryUpdate(
              { technicalInquiryId: technicalInquiry?.technicalInquiryId, rqData },
              {
                onSuccess: () => {
                  onClose();
                  reset();
                  toastStore.open('수정이 완료 되었습니다.');
                },
              },
            );
          }
        },
      }
    : {
        tittle: '기술지원 문의',
        success: (data: TechnicalInquiryUpdateHookForm) => {
          if (technicalInquiry) {
            const rqData: TechnicalInquiryHistoryCreateDTO = {
              managerId: technicalInquiry?.histories[0].managerId,
              status: 'CREATED',
              comment: data.inquiry,
              technicalInquiryId: technicalInquiry?.technicalInquiryId,
            };
            technicalInquiryHistoryCreate(rqData, {
              onSuccess: () => {
                onClose();
                reset();
                toastStore.open('문의를 완료했습니다.');
              },
            });
          }
        },
      };

  const { handleSubmit, reset, register, control, formState } = useTechnicalSupportUpdateForm();

  const [inquiry] = useWatch({
    control,
    name: ['inquiry'],
  });

  useEffect(() => {
    if (technicalInquiry && isUpdateMode) {
      reset({
        inquiry: technicalInquiry.inquiry,
      });
    }
  }, [reset, technicalInquiry, open, isUpdateMode]);

  return (
    <Dialog open={open} onClose={onClose} title={tittle} size="medium" height={600}>
      <UpdateForm onSubmit={handleSubmit(success)}>
        <InfoPosition>
          <InfoBox>
            <TextLi>담당자가 확인 후 연락드릴 예정입니다.</TextLi>
            <TextLi>문의 내역은 회사 관리자가 확인 가능합니다.</TextLi>
          </InfoBox>
        </InfoPosition>
        <InputBox>
          <MemoText>
            <InputText>
              문의 내용<EssentialMark>*</EssentialMark>
            </InputText>
            <TextCount>{`(${inquiry?.length ?? 0}/300)`}</TextCount>
          </MemoText>
          <MemoBox placeholder="문의 내용을 작성해주세요." maxLength={300} {...register('inquiry')} />
          {formState.errors.inquiry?.message && <ErrorText>{formState.errors.inquiry?.message}</ErrorText>}
        </InputBox>
        <ButtonBox>
          <Button type="submit" variant="contain" color="purple" size="extraLarge" paddingHorizontal={90}>
            완료
          </Button>
          <Button variant="outline" color="gray" size="extraLarge" paddingHorizontal={90} onClick={onClose}>
            취소
          </Button>
        </ButtonBox>
      </UpdateForm>
    </Dialog>
  );
};

const UpdateForm = styled.form`
  position: relative;
  height: 500px;
`;

const InfoPosition = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const InfoBox = styled.div`
  padding: 12px 33px 12px 28px;
  width: 424px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
`;

const TextLi = styled.li`
  position: relative;
  ${fonts.Body2}
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  list-style-type: none;
  white-space: pre;
  &::before {
    position: absolute;
    content: '•';
    color: ${({ theme }) => theme.colors['ic-gray-light']};
    font-size: 1.5em;
    left: -15px;
    top: -2px;
    opacity: 30%;
  }
`;

const InputBox = styled.div`
  margin-bottom: 24px;
`;

const MemoText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const TextCount = styled.div`
  ${fonts.Body4}
  color: ${({ theme }) => theme.colors['text-gray-light']};
`;

const InputText = styled.span`
  ${fonts.Headline8}
`;

const MemoBox = styled.textarea`
  width: 100%;
  padding: 10px 12px;
  height: 200px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-radius: 2px;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: 8px;
`;

const ErrorText = styled.span`
  margin: 0;
  color: ${({ theme }) => theme.colors['text-red-light']};
  ${fonts.Body4};
`;

export const EssentialMark = styled.span`
  margin-left: 2px;
  color: ${({ theme }) => theme.colors['ic-red-light']};
`;
