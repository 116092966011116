import { XIcon } from '@icons/XIcon';
import { CartProductModel } from '@models/softwareModels/CartProductModel';
import { Checkbox, Divider, IconButton, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { CheckboxIcon, CheckedBgIcon } from '@icons/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './ItemOfCartStyles';
import { CartModal } from './CartModal';

interface Props {
  item: CartProductModel;
  onChangeCheckbox: () => void;
  onRemoveCartItem: () => void;
  onConfirmOrderModify: () => void;
  itemChecked: boolean;
}

export const ItemOfCart = observer(
  ({ item, onChangeCheckbox, onRemoveCartItem, onConfirmOrderModify, itemChecked }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleClickRemoveBtn = () => {
      onRemoveCartItem();
    };

    const handleClickOrderModifyBtn = () => {
      setIsEditModalOpen(true);
    };

    const handleEditModalClose = () => {
      setIsEditModalOpen(false);
      onConfirmOrderModify();
    };

    return (
      <>
        <S.Container $itemChecked={itemChecked}>
          <S.ProductSelectWrapper>
            <Checkbox
              size="small"
              icon={<CheckedBgIcon width={20} height={20} color={theme.colors['border-gray-light']} />}
              checkedIcon={
                <CheckboxIcon
                  width={20}
                  height={20}
                  bgColor={theme.colors['bg-purple']}
                  color={theme.colors['bg-white']}
                />
              }
              checked={item.isChecked}
              onChange={onChangeCheckbox}
            />
            <S.CartItemAvatar src={item.iconUrl} />
            <S.ProductNameWrapper>
              <strong>{item.productName}</strong>
              <span>{item.companyName}</span>
            </S.ProductNameWrapper>
          </S.ProductSelectWrapper>
          <Divider orientation="vertical" flexItem sx={{ height: '64px', alignSelf: 'center', margin: '0 20px' }} />
          <S.ProductInfoWrapper>
            <S.ProdcutInfo>
              <S.InfoItemTitle>{t('Subscrib_Main_12')}</S.InfoItemTitle>
              <S.InfoItemText>{item.planName}</S.InfoItemText>
            </S.ProdcutInfo>
            <S.ProdcutInfo>
              <S.InfoItemTitle>{t('Acc_Main_22')}</S.InfoItemTitle>
              <S.InfoItemText>{item.inquiry}</S.InfoItemText>
            </S.ProdcutInfo>
          </S.ProductInfoWrapper>
          <S.BtnsWrapper>
            <Button variant="outline" size="small" onClick={handleClickOrderModifyBtn} paddingHorizontal={12}>
              {t('Ad_Cart_09')}
            </Button>
            <IconButton onClick={handleClickRemoveBtn}>
              <XIcon width={24} height={24} />
            </IconButton>
          </S.BtnsWrapper>
        </S.Container>
        <CartModal isOpen={isEditModalOpen} onClose={handleEditModalClose} swId={item.productId} originData={item} />
      </>
    );
  },
);
