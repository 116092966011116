import type { BillingStatDTO } from '@repositories/subscriptionBillingStatRepository/Types';

export class BillingStatModel {
  private readonly dto: BillingStatDTO;

  constructor(dto: BillingStatDTO) {
    this.dto = dto;
  }

  get annualPaymentEstimate() {
    return this.dto.annualPaymentEstimate;
  }

  get monthlyPaymentAmountEstimate() {
    return this.dto.monthlyPaymentEstimate;
  }

  get annualBillingAmountEstimate() {
    return this.dto.annualBillingAmountEstimate;
  }

  get monthlyBillingAmountEstimate() {
    return this.dto.monthlyBillingAmountEstimate;
  }
}
