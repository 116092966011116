import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';
import { Label } from '@components/Label';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { CashColorIcon } from '@icons/CashColorIcon';
import { ChevronIcon } from '@icons/ChevronIcon';
import { GraphColorIcon } from '@icons/GraphColorIcon';
import { ReceiptColorIcon } from '@icons/ReceiptColorIcon';
import { numberFormat } from '@utils/numberFormat';
import type { SubscriptionBillingStatModel } from '@models/subscriptionBillingStat/SubscriptionBillingStatModel';

type Props = {
  model: SubscriptionBillingStatModel;
};
export const OptimizableSubscription = ({ model }: Props) => {
  const { colors } = useTheme();
  const { authStore } = useStore();

  return (
    <Container>
      <SubscriptionInfo>
        <SubscriptionName>
          <Thumbnail src={model.productIcon} />
          {model.subscriptionName}
        </SubscriptionName>
        <SubscriptionLink to={`/manager/${authStore.managerTenantInfo.id}/subscriptions/list/${model.subscriptionId}`}>
          구독 상세
          <ChevronIcon width={16} height={16} rotateNum={90} color={colors['ic-blue-light']} />
        </SubscriptionLink>
      </SubscriptionInfo>
      <OptimizableSubscriptionDetail model={model} />
    </Container>
  );
};

export const OptimizableSubscriptionDetail = ({ model }: Props) => {
  const { authStore } = useStore();
  return (
    <Detail>
      <PaymentWrapper>
        <DetailTitle>
          <ReceiptColorIcon width={18} height={18} />
          지불 예정 금액
        </DetailTitle>
        <Label variant="solid" color="white">
          년 기준
        </Label>
        <Value>
          <small>({authStore.managerTenantInfo.currencyUnit})</small>
          {numberFormat({ num: model.annualPaymentEstimate })}
        </Value>
        <Label variant="solid" color="white">
          월 기준
        </Label>
        <Value $isMonthly>
          <small>({authStore.managerTenantInfo.currencyUnit})</small>
          {numberFormat({ num: model.monthlyPaymentEstimate })}
        </Value>
      </PaymentWrapper>
      <PaymentWrapper>
        <DetailTitle>
          <CashColorIcon width={18} height={18} />
          줄일 수 있는 금액
        </DetailTitle>
        <Label variant="solid" color="white">
          년 기준
        </Label>
        <Value>
          <small>({authStore.managerTenantInfo.currencyUnit})</small>
          {numberFormat({ num: model.remainingAnnualPaymentReductionEstimate })}
        </Value>
        <Label variant="solid" color="white">
          월 기준
        </Label>
        <Value $isMonthly>
          <small>({authStore.managerTenantInfo.currencyUnit})</small>
          {numberFormat({ num: model.remainingMonthlyPaymentReductionEstimate })}
        </Value>
      </PaymentWrapper>
      <AnalysisWrapper>
        <AnalysisTitle>
          <GraphColorIcon width={18} height={18} />
          분석
        </AnalysisTitle>
        <AnalysisItemWrapper>
          {model.userOptimizationPoints.length > 0 && (
            <div>
              <AnalysisSubTitle>사용자 최적화</AnalysisSubTitle>
              <ol>
                {model.userOptimizationPoints.map(
                  (optModel, idx) =>
                    optModel.usage && (
                      <AnalysisItem key={optModel.type} data-order={idx + 1}>
                        <p>
                          <Trans
                            defaults={optModel.description}
                            values={{ usage: numberFormat({ num: optModel.usage }) }}
                            components={{ b: <b /> }}
                          />
                        </p>
                      </AnalysisItem>
                    ),
                )}
              </ol>
            </div>
          )}
          {model.planOptimizationPoints.length > 0 && (
            <div>
              <AnalysisSubTitle>플랜 최적화</AnalysisSubTitle>
              <ol>
                {model.planOptimizationPoints.map(
                  (optModel, idx) =>
                    optModel.cost && (
                      <AnalysisItem key={optModel.type} data-order={idx + 1}>
                        <p>
                          <Trans
                            defaults={optModel.description}
                            values={{ usage: numberFormat({ num: optModel.cost }) }}
                            components={{ b: <b /> }}
                          />
                        </p>
                      </AnalysisItem>
                    ),
                )}
              </ol>
            </div>
          )}
        </AnalysisItemWrapper>
      </AnalysisWrapper>
    </Detail>
  );
};

OptimizableSubscription.Skeleton = () => (
  <Container>
    <SubscriptionInfo>
      <SubscriptionName>
        <Skeleton width={100} />
      </SubscriptionName>
    </SubscriptionInfo>
    <Detail>
      <PaymentWrapper>
        <DetailTitle>
          <ReceiptColorIcon width={18} height={18} />
          지불 예정 금액
        </DetailTitle>
        <Label variant="solid" color="white">
          년 기준
        </Label>
        <Value>
          <Skeleton width={200} />
        </Value>
        <Label variant="solid" color="white">
          월 기준
        </Label>
        <Value $isMonthly>
          <Skeleton width={170} />
        </Value>
      </PaymentWrapper>
      <PaymentWrapper>
        <DetailTitle>
          <CashColorIcon width={18} height={18} />
          줄일 수 있는 금액
        </DetailTitle>
        <Label variant="solid" color="white">
          년 기준
        </Label>
        <Value>
          <Skeleton width={200} />
        </Value>
        <Label variant="solid" color="white">
          월 기준
        </Label>
        <Value $isMonthly>
          <Skeleton width={170} />
        </Value>
      </PaymentWrapper>
      <AnalysisWrapper>
        <AnalysisTitle>
          <GraphColorIcon width={18} height={18} />
          분석
        </AnalysisTitle>
        <AnalysisItemWrapper>
          <ol>
            <Skeleton width={300} />
            <Skeleton width={200} />
            <Skeleton width={250} />
          </ol>
          <ol>
            <Skeleton width={300} />
            <Skeleton width={200} />
          </ol>
        </AnalysisItemWrapper>
      </AnalysisWrapper>
    </Detail>
  </Container>
);

const Container = styled.div`
  margin: 0px 20px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 7px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  box-shadow: 0px 1px 6px 0px #0000000f;
  overflow: hidden;

  & + & {
    margin-top: 20px;
  }
`;

const Thumbnail = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${({ theme: { colors } }) => colors['border-bk-opacity-10%']};
`;

const SubscriptionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  padding: 13px 19px 13px 27px;
`;

const SubscriptionName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${fonts.Headline7}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Detail = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 24px 27px 23px;
`;

const DetailTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 34px;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-purple']};
`;

const PaymentWrapper = styled.div`
  padding: 8px 0px 0px;
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  & + & {
    margin-left: 28px;
  }
`;

const AnalysisWrapper = styled.div`
  padding: 8px 0px 2px;
  grid-column: 3 / 5;
  margin-left: 28px;
`;

const AnalysisTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-purple']};
`;

const AnalysisSubTitle = styled.div`
  ${fonts.Headline8};
  margin-bottom: 16px;
`;

const Value = styled.div<{ $isMonthly?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${fonts.Headline5};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin: ${({ $isMonthly }) => ($isMonthly ? '4px 0px 0px' : '4px 0px 20px')};

  & > small {
    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
`;

const AnalysisItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const SubscriptionLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
`;

const AnalysisItem = styled.li`
  display: flex;
  align-items: center;
  ${fonts.Body7};
  list-style-position: inside;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};

  & + & {
    margin-top: 8px;
  }
  & > p > b {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
  &::marker {
    content: '';
  }
  &::before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    content: attr(data-order);
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    border-radius: 3px;
    margin-right: 8px;
    ${fonts.Caption1};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
`;
