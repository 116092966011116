// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const ViewListIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M3 10.5C2.17 10.5 1.5 11.17 1.5 12C1.5 12.83 2.17 13.5 3 13.5C3.83 13.5 4.5 12.83 4.5 12C4.5 11.17 3.83 10.5 3 10.5ZM3 4.5C2.17 4.5 1.5 5.17 1.5 6C1.5 6.83 2.17 7.5 3 7.5C3.83 7.5 4.5 6.83 4.5 6C4.5 5.17 3.83 4.5 3 4.5ZM3 16.5C2.17 16.5 1.5 17.18 1.5 18C1.5 18.82 2.18 19.5 3 19.5C3.82 19.5 4.5 18.82 4.5 18C4.5 17.18 3.83 16.5 3 16.5ZM8 19H20V17H8V19ZM8 13H20V11H8V13ZM8 5V7H20V5H8Z"
          fill={color}
        />
        <circle cx="20" cy="6" r="1" fill={color} />
        <circle cx="8" cy="6" r="1" fill={color} />
        <circle cx="8" cy="12" r="1" fill={color} />
        <circle cx="20" cy="12" r="1" fill={color} />
        <circle cx="20" cy="18" r="1" fill={color} />
        <circle cx="8" cy="18" r="1" fill={color} />
      </g>
    </svg>
  );
};

ViewListIcon.displayName = 'ViewListIcon';
