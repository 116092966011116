import { Purchase, PurchaseContract, PurchaseStatus } from '@type/Purchase';
import { ObjectUtil } from '@utils/ObjectUtil';
import { MonthlyDataMapper } from '@utils/mapper';

export class PurchaseModel implements Omit<Purchase, 'createdDate' | 'updatedDate'> {
  amount: number;

  contract: PurchaseContract | undefined;

  currencyUnit: CurrencyUnit;

  exchangeRate: number;

  month: string;

  originalAmount: number;

  originalCurrencyUnit: CurrencyUnit;

  status: PurchaseStatus;

  createdBy: string;

  createdDate: Date;

  updatedBy: string;

  updatedDate: Date;

  index: number;

  constructor(dto?: Purchase, idx?: number) {
    this.amount = -1;
    this.currencyUnit = 'KRW';
    this.exchangeRate = -1;
    this.month = '';
    this.originalAmount = -1;
    this.originalCurrencyUnit = 'USD';
    this.status = 'EXPECTED';
    this.createdBy = '';
    this.createdDate = new Date();
    this.updatedBy = '';
    this.updatedDate = new Date();
    this.contract = undefined;

    if (idx !== undefined) {
      this.index = idx;
    } else {
      this.index = -1;
    }

    if (dto) {
      this.update(dto);
    }
  }

  update = (dto: Purchase) => {
    ObjectUtil.update(this, dto);
  };

  genMonthName = (useYear?: boolean) => {
    let name = '';
    const splitMonth = this.month.split('-');
    const month = MonthlyDataMapper[parseInt(splitMonth[1], 10)].toString();
    if (month === 'JAN' || this.index === 0 || useYear) {
      name = `${month} ${splitMonth[0]}`;
    } else {
      name = month;
    }
    return name;
  };

  get stackChartData(): { name: string; data: number } {
    return {
      name: this.genMonthName(),
      data: this.amount,
    };
  }
}
