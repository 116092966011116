// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const ListIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.43142 4.14453C3.95585 4.14453 3.57031 4.53007 3.57031 5.00564C3.57031 5.48122 3.95585 5.86675 4.43142 5.86675H19.6034C20.0789 5.86675 20.4645 5.48122 20.4645 5.00564C20.4645 4.53007 20.0789 4.14453 19.6034 4.14453H4.43142ZM4.43142 11.0334C3.95585 11.0334 3.57031 11.419 3.57031 11.8945C3.57031 12.3701 3.95585 12.7556 4.43142 12.7556H19.6034C20.0789 12.7556 20.4645 12.3701 20.4645 11.8945C20.4645 11.419 20.0789 11.0334 19.6034 11.0334H4.43142ZM4.43142 17.9223C3.95585 17.9223 3.57031 18.3079 3.57031 18.7834C3.57031 19.259 3.95585 19.6445 4.43142 19.6445H19.6034C20.0789 19.6445 20.4645 19.259 20.4645 18.7834C20.4645 18.3079 20.0789 17.9223 19.6034 17.9223H4.43142Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ListIcon.displayName = 'ListIcon';
