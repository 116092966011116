import { CompanyModel } from '@models/CompanyModel';
import { KEYS, repository } from '@repositories/Repository';
import { CompanyCreateRq, CompanyFilter, CompanyUpdateRq } from '@repositories/companyRepository';
import ICompanyRepository from '@repositories/companyRepository/ICompanyRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const companyRepo = repository.get<ICompanyRepository>(KEYS.COMPANY_REPOSITORY);

const companyQueryKey = {
  all: ['company'] as const,
  lists: () => [...companyQueryKey.all, 'list'] as const,

  list: (filter: CompanyFilter) => [...companyQueryKey.lists(), filter] as const,
  details: () => [...companyQueryKey.all, 'detail'] as const,
  detail: (id: number) => [...companyQueryKey.details(), id] as const,
};

export const useCompanyList = (queries: CompanyFilter, options?: UseQueryOptionsType<Page<CompanyModel>>) => {
  const result = useQuery<Page<CompanyModel>, AxiosError>(
    companyQueryKey.list(queries),
    async () => {
      const result = await companyRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new CompanyModel(dto)),
      };
    },
    {
      ...options,
    },
  );

  const initialData = useMemo(() => ({ content: [] as CompanyModel[], totalElements: 0 }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useGetCompany = (id: CompanyId, options?: UseQueryOptionsType<CompanyModel>) => {
  const result = useQuery<CompanyModel, AxiosError>(
    companyQueryKey.detail(id),
    async () => {
      const result = await companyRepo.getOne(id);
      return new CompanyModel(result);
    },
    {
      ...options,
      cacheTime: 0,
    },
  );

  return { ...result, data: result.data };
};

export const useAddCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: CompanyCreateRq) => companyRepo.create(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(companyQueryKey.lists());
    },
  });
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: CompanyUpdateRq) => {
      const { id } = await companyRepo.update(data.id, data);
      return id;
    },
    {
      onSuccess: id => {
        queryClient.invalidateQueries(companyQueryKey.detail(id));
        queryClient.invalidateQueries(companyQueryKey.lists());
      },
    },
  );
};

export const useDeleteCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: number) => companyRepo.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(companyQueryKey.lists());
    },
  });
};
