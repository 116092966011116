import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { SquareFillIcon } from '@icons/SquareFillIcon';
import { YearlyBillingChart } from './YearlyBillingChart';

export const YearlyBilling = () => {
  const { colors } = useTheme();

  return (
    <Container>
      <TitleWrapper>
        <Title>비용 추세</Title>
        <LegendWrapper>
          <Legend>
            <SquareFillIcon width={20} height={20} color={colors['graph-purple-light']} />
            <span>지불 완료</span>
          </Legend>
          <Legend>
            <SquareFillIcon width={20} height={20} color={colors['graph-gray']} />
            <span>지불 예측</span>
          </Legend>
        </LegendWrapper>
      </TitleWrapper>
      <ChartWrapper>
        <YearlyBillingChart barSize={28} height={403} />
      </ChartWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-50']};
  padding: 24px 28px 28px;
  border-radius: 5px;
  grid-column: 1 / 3;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
`;

const Title = styled.h4`
  ${fonts.Headline12}
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  & > span {
    margin-left: 4px;
  }
`;

const ChartWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 7px;
  padding: 25px 27px 24px 23px;
`;
