import type { UseFormProps } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type TechnicalInquiryUpdateHookForm = {
  inquiry: string;
};

export const useTechnicalSupportUpdateForm = () => {
  const validationSchema = Yup.object().shape({
    inquiry: Yup.string().required('필수 입력 값입니다.'),
  });

  const TechnicalInquiryUpdateFormOptions: UseFormProps<TechnicalInquiryUpdateHookForm> = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<TechnicalInquiryUpdateHookForm>(TechnicalInquiryUpdateFormOptions);
};
