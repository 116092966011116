const defaultWidth = 24;
const defaultHeight = 24;

export const CashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9998 2.5153C6.76156 2.5153 2.51512 6.76174 2.51512 12C2.51512 17.2382 6.76156 21.4847 11.9998 21.4847C17.2381 21.4847 21.4845 17.2382 21.4845 12C21.4845 6.76174 17.2381 2.5153 11.9998 2.5153ZM0.799805 12C0.799805 5.8144 5.81422 0.799988 11.9998 0.799988C18.1854 0.799988 23.1998 5.8144 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25502 17.39L7.2135 12.9706H5.66529V12.9665C5.29686 12.9606 5 12.6601 5 12.2903C5 11.9168 5.30278 11.614 5.67627 11.614C5.7054 11.614 5.76225 11.6194 5.76225 11.6194H6.88978L5.84826 7.20001H7.79056L8.70541 11.6194H10.0706L11.084 7.20001H12.8996L13.913 11.6194H15.2853L16.1931 7.20001H18.1354L17.0939 11.6194H18.118C18.1462 11.6159 18.2892 11.6236 18.3183 11.6236C18.3475 11.6236 18.2618 11.6159 18.29 11.6194L18.3183 11.6236C18.6374 11.678 18.8803 11.9558 18.8803 12.2903C18.8803 12.6248 18.6374 12.9163 18.3183 12.9706H16.7701L15.7286 17.39H13.6737L12.6674 12.9706H11.3162L10.3099 17.39H8.25502ZM8.97986 12.9706L9.31061 14.561H9.39506L9.761 12.9706H8.97986ZM11.6259 11.6194H12.3578L12.027 10.1557H11.9566L11.6259 11.6194ZM14.2226 12.9706L14.5886 14.5751H14.673L15.0038 12.9706H14.2226Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CashIcon.displayName = 'CashIcon';
