import type { NodeDataType } from './GraphType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { NodeProps } from 'reactflow';
import { Handle, Position } from 'reactflow';
import styled from '@emotion/styled';
import { Avatar, IconButton, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PlayIcon } from '@icons/PlayIcon';
import { PlusIcon } from '@icons/PlusIcon';
import { SmpColorIcon } from '@icons/SmpColorIcon';
import { TrashIcon } from '@icons/TrashIcon';
import 'reactflow/dist/base.css';

type Props = NodeProps<NodeDataType>;

export const ActionNode = observer((node: Props) => {
  const { data, isConnectable: isNodeConnectable } = node;
  const { t } = useTranslation();
  const theme = useTheme();
  const { workflowDetailStore } = useStore();
  const isEditable = workflowDetailStore.checkEditable();
  const isSelected = workflowDetailStore.curNodeId === node.id;
  const exceptionEdge = workflowDetailStore.findEdgeBySource(node.id, 'exception');
  const conditionThenEdge = workflowDetailStore.findEdgeBySource(node.id, 'conditionThen');
  const isConnectable = isNodeConnectable && isEditable;

  const handleClickActionBehavior = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.curNodeId = node.id;
    workflowDetailStore.setToolbarView(isEditable ? 'actionSelect' : 'actionConfig');
  };

  const handleClickActionBehaviorConfig = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.curNodeId = node.id;
    workflowDetailStore.setToolbarView('actionConfig');
  };

  const handleClickActionExecResult = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.curNodeId = node.id;
    workflowDetailStore.setToolbarView('actionExec');
  };

  const handleClickTrash = (e: React.MouseEvent<HTMLButtonElement>, node: NodeProps) => {
    e.stopPropagation();
    workflowDetailStore.removeActionNode(node.id);
  };

  const handleClickNode = () => {
    workflowDetailStore.curNodeId = node.id;
    workflowDetailStore.setToolbarView(isEditable ? 'actionSelect' : 'actionConfig');
  };

  const handleClickActionExceptionBtn = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.addExceptionActionNode(node.id);
  };

  const handleClickActionConditionThenBtn = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.addConditionThenActionNode(node.id);
  };

  return (
    <Container isSelected={isSelected} onClick={handleClickNode}>
      <Type>
        <span>
          {data.isSmpAction ? (
            <SmpColorIcon />
          ) : data.connectionModel?.subscription?.product.iconUrl ? (
            <Avatar
              src={data.connectionModel.subscription?.product.iconUrl}
              sx={{
                width: '16px',
                height: '16px',
                border: `1px solid ${theme.colors['border-bk-opacity-15%']}`,
                borderRadius: '2px',
              }}
              variant="square"
            />
          ) : (
            <PlayIcon width={16} height={16} />
          )}
          <span>{`${t('Workflow_Create_36')} ${data.actionIndex}`} </span>
        </span>
        {isEditable && (
          <IconBtn onClick={e => handleClickTrash(e, node)}>
            <TrashIcon width={14} height={14} />
          </IconBtn>
        )}
      </Type>
      <>
        <Behavior
          isSelected={isSelected && workflowDetailStore.toolbar === 'actionSelect'}
          onClick={handleClickActionBehavior}
        >
          {data.actionModel?.actionName || t('Workflow_Create_38')}
        </Behavior>
        {data.actionModel?.actionName && !workflowDetailStore.execution && (
          <Config
            onClick={handleClickActionBehaviorConfig}
            isSelected={isSelected && workflowDetailStore.toolbar === 'actionConfig'}
          >
            {`${t('Setting')}`}
          </Config>
        )}
        {workflowDetailStore.execution && (
          <Config
            onClick={handleClickActionExecResult}
            isSelected={isSelected && workflowDetailStore.toolbar === 'actionExec'}
          >
            {t('Workflow.graph.execResult')}
          </Config>
        )}
      </>
      {isEditable && isSelected && !exceptionEdge && data.actionModel?.type === 'CONNECTION_ACTION' && (
        <SideButton>
          <Add onClick={handleClickActionExceptionBtn}>
            <PlusIcon color={theme.colors['ic-red-light']} />
          </Add>
        </SideButton>
      )}
      {isEditable && isSelected && !conditionThenEdge && data.actionModel?.type === 'IF_ACTION' && (
        <SideButton borderColor={theme.colors['border-blue']}>
          <Add onClick={handleClickActionConditionThenBtn}>
            <PlusIcon color={theme.colors['ic-blue-light']} />
          </Add>
        </SideButton>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        id="normal"
        isConnectable={isConnectable}
        style={{
          background: theme.colors['border-gray-light'],
          width: 10,
          height: 10,
          borderRadius: '50%',
          border: '1px solid gray',
        }}
      />
      <Handle
        type="target"
        position={Position.Top}
        id="normal"
        isConnectable={isConnectable}
        style={{
          background: theme.colors['border-gray-light'],
          width: 10,
          height: 10,
          borderRadius: '50%',
          border: '1px solid gray',
        }}
      />
      {data.actionModel?.type === 'CONNECTION_ACTION' && (
        <Handle
          type="source"
          position={Position.Right}
          id="exception"
          isConnectable={isConnectable}
          style={{
            background: theme.colors['border-red'],
            width: 10,
            height: 10,
            borderRadius: '50%',
            border: '1px solid gray',
          }}
        />
      )}
      {data.actionModel?.type === 'IF_ACTION' && (
        <Handle
          type="source"
          position={Position.Right}
          id="conditionThen"
          isConnectable={isConnectable}
          style={{
            background: theme.colors['border-blue'],
            width: 10,
            height: 10,
            borderRadius: '50%',
            border: '1px solid gray',
          }}
        />
      )}
    </Container>
  );
});

export const MemoizedMovie = React.memo(ActionNode);

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;

  border: ${props =>
    `${props.isSelected ? '2px' : '1px'} solid
    ${props.isSelected ? props.theme.colors['border-purple'] : props.theme.colors['border-gray-lighter']};`};
  height: fit-content;
  width: '392px';
  background: ${props => props.theme.colors['bg-gray-light']};
  border-radius: 4px;
  padding: '8px';
  box-shadow: ${props => (props.isSelected ? '0px 8px 10px -5px rgba(0, 0, 0, 0.08)' : '')};
`;

const Type = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 4px;

  ${fonts.Headline9};
  align-items: center;
  margin-bottom: 4px;
  justify-content: space-between;
  & > span {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
`;

const Behavior = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  border-bottom-left-radius: ${props => (props.isSelected ? '0px' : '3px')};
  border-bottom-right-radius: ${props => (props.isSelected ? '0px' : '3px')};
  padding: 0px 16px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  background: ${props => props.theme.colors['bg-white']};

  ${props => (props.isSelected ? fonts.Headline8 : fonts.Body2)};
  color: ${props => (props.isSelected ? props.theme.colors['text-purple'] : props.theme.colors['text-gray-main'])};
`;

const Config = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 0px 16px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-top: 0px;
  background: ${props => props.theme.colors['bg-white']};
  ${props => (props.isSelected ? fonts.Headline8 : fonts.Body2)};
  color: ${props => (props.isSelected ? props.theme.colors['text-purple'] : props.theme.colors['text-gray-main'])};
`;

const IconBtn = styled(IconButton)`
  width: 24px;
  height: 24px;
  background: ${props => props.theme.colors['bg-white']};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
`;

const SideButton = styled(IconButton)<{ borderColor?: string }>`
  position: absolute;
  top: 50%;
  right: -30px; /* 노드의 오른쪽 밖으로 약간 이동 */
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  min-width: 24px;
  padding: 0;
  border-radius: 50%;
  background: ${props => props.theme.colors['bg-white']};
  border: 1px solid ${props => props.borderColor ?? props.theme.colors['border-red-lighter']};
`;

const Add = styled.div`
  display: flex;
  flex-direction: row;
  width: 24px;
  height: 24px;
  align-items: center;
  border: 1px solid ${props => props.theme.colors['border-red-lighter']};
  border-radius: 3px;
  background: ${props => props.theme.colors['bg-white']};
  justify-content: center;
  :hover {
    border: 1px solid ${props => props.theme.colors['border-purple']};
    background-color: ${props => props.theme.colors['bg-purple-light-50']};
  }
`;
