import React, { createContext, useContext } from 'react';
import { CircularProgress } from '@mui/material';
import { SubscriptionModel } from '../models';

const ModelContext = createContext<SubscriptionModel | undefined>(undefined);

export const useSubscriptionModel = () => {
  const subscriptionModel = useContext(ModelContext);

  if (!subscriptionModel) {
    throw new Error('useSubscriptionModel의 상위에 SubscriptionModelProvider를 사용해야 합니다.');
  }

  return subscriptionModel;
};

export const SubscriptionModelProvider: React.FC<{
  children: React.ReactNode;
  value: SubscriptionModel | undefined;
}> = ({ children, value }) => {
  return value ? <ModelContext.Provider value={value}>{children}</ModelContext.Provider> : <CircularProgress />;
};
