import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetSubscriptionMonthlyUsers } from '@queryHooks/useSubscription';
import { numberFormat } from '@utils/numberFormat';
import * as S from '../SummaryStyles';
import { SkeletonBarChart } from './SkeletonChart';

const { getStringSize } = require('recharts/lib/util/DOMUtils');

type MonthlyUsersChartData = { month: string; total: number; active: number };
type Props = {
  monthFrom: DateTimeString;
  monthTo: DateTimeString;
};
export const MonthlyUsers = ({ monthFrom, monthTo }: Props) => {
  const { t } = useTranslation();
  const subscription = useSubscriptionModel();
  const { colors } = useTheme();

  const yAxisTickStyle = { ...fonts.Caption2, color: colors['text-gray-light'] };

  const {
    data: monthlyUsers,
    isSuccess,
    isLoading,
  } = useGetSubscriptionMonthlyUsers(subscription.id, { monthFrom, monthTo });

  const barChartData: MonthlyUsersChartData[] = isSuccess
    ? monthlyUsers.content.map(({ month, monthlyActiveUserCount, totalUserCount }) => ({
        month: dayjs(month).format(t('DateFormat_YM')),
        total: totalUserCount,
        active: monthlyActiveUserCount,
      }))
    : [];

  const maxValue = Math.max(...barChartData.map(({ total }) => total));
  const yAxisWidth = getStringSize(maxValue, yAxisTickStyle).width + 5; // tickMargin 고려 + 넉넉히 2 더해줌

  return (
    <S.BarChartContainer>
      <S.ChartHeader>
        <S.HeaderText $hasData={barChartData.length > 0}>{t('Subscrib_Detail_Overview_16')}</S.HeaderText>
      </S.ChartHeader>
      {isLoading ? (
        <Loader />
      ) : barChartData.length === 0 ? (
        <SkeletonBarChart monthFrom={monthFrom} monthTo={monthTo} />
      ) : (
        <ResponsiveContainer width="100%" height={265}>
          <BarChart data={barChartData}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              xAxisId={0}
              stroke="1"
              tick={{ ...fonts.Caption2, color: colors['text-gray-light'] }}
            />
            <XAxis dataKey="month" xAxisId={1} hide />
            <XAxis dataKey="month" xAxisId={2} hide />
            <YAxis stroke="1" width={yAxisWidth} tick={yAxisTickStyle} domain={[0, maxValue]} />
            <Tooltip cursor={{ fill: colors['state-white-hover'] }} content={<LicenseTooltip />} />
            <Bar dataKey="total" fill={colors['graph-gray-light']} xAxisId={1} barSize={10} />
            <Bar dataKey="active" fill={colors['graph-blue']} xAxisId={0} barSize={10} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </S.BarChartContainer>
  );
};

const LicenseTooltip = ({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: { value: number; payload: MonthlyUsersChartData }[];
  label?: string;
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  return active && payload && payload.length > 0 ? (
    <S.TooltipContainer>
      <p>{label}</p>
      <dl>
        <dt>
          <Box component="div" width="10px" height="10px" sx={{ backgroundColor: colors['graph-gray-light'] }} />
          {t('Subscrib_Detail_Overview_23')}
        </dt>
        <dd>{numberFormat({ num: payload[0].payload.total })} </dd>
        <dt>
          <Box component="div" width="10px" height="10px" sx={{ backgroundColor: colors['graph-blue'] }} />
          {t('Subscrib_Detail_Overview_22')}
        </dt>
        <dd>{numberFormat({ num: payload[0].payload.active })} </dd>
      </dl>
    </S.TooltipContainer>
  ) : null;
};
