import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { Form as SchemaForm } from '@rjsf/mui';

export const Form = styled.form`
  height: 100%;
`;

export const Field = styled.div`
  margin-bottom: 20px;
`;

export const FieldName = styled.div`
  margin-bottom: 4px;
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};

  & > strong {
    margin-left: 2px;
    color: ${({ theme: { colors } }) => colors['text-red-light']};
  }
`;

export const IdTextField = styled.div`
  padding: 10px 12px;
  background: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
`;

export const StyledForm = styled(SchemaForm)`
  max-height: 416px;

  & .MuiGrid-container {
    margin-top: 0px !important;

    & .MuiGrid-item {
      margin-bottom: 0px !important;
      padding-top: 0px;
      padding-bottom: 20px;

      &:last-of-type {
        padding-bottom: 0px;
      }
    }
  }
`;

export const SubscriptionUserLabel = styled.div`
  margin-bottom: 8px;
  ${fonts.Headline8}
  color: ${props => props.theme.colors['text-gray-main']};
`;
