import type { MouseEventHandler } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, Menu, MenuItem, TableCell } from '@mui/material';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { Tooltip } from '@components/tooltip/Tooltip';
import { DangerFillIcon, PlugFillSplashIcon, PlugfillIcon, ThreeDotIcon } from '@icons/index';
import type { Filters } from '@repositories/subscriptionRepository';
import { transientOptions } from '@utils/CommonUtil';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';

type Props = {
  model: SubscriptionListModel;
  appliedFilter: { [key in keyof Filters]: boolean };
  onMenuClick: (menuKey: 'EDIT' | 'CONNECTION_SETTING' | 'DELETE', target: SubscriptionListModel) => void;
};

export const SubscriptionRow = ({ model, appliedFilter, onMenuClick }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const labelTypeColor = {
    ACTIVE: 'green',
    INACTIVE: 'gray',
    SCHEDULED_TO_START: 'yellow',
    SCHEDULED_TO_END: 'red',
    REQUESTED: 'pink',
  } as const;

  const handleThreeDotClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: Event) => {
    event.stopPropagation();
    setAnchorEl(undefined);
  };

  const handleMenuClick =
    (menuKey: 'EDIT' | 'CONNECTION_SETTING' | 'DELETE'): MouseEventHandler =>
    event => {
      event.stopPropagation();
      setAnchorEl(undefined);
      onMenuClick(menuKey, model);
    };

  return (
    <ErrorBoundary>
      <>
        <StyledCell>
          <SubscriptionNameColumnWrapper>
            {model.isLatestUsageWorkflowFailed && (
              <IconWrapper>
                <Tooltip
                  label={model.usageWorkflow.latestExecutionStatusMessage || t('Subscrib_Main_20')}
                  placement="bottom-right"
                >
                  <DangerFillIcon width={16} height={16} />
                </Tooltip>
              </IconWrapper>
            )}
            <SubscriptionNameWrapper>
              {model.connection?.isConnected ? (
                <Label
                  variant="icon"
                  color="green"
                  icon={<PlugfillIcon width={12} height={12} color={colors['ic-white']} />}
                />
              ) : (
                <Label
                  variant="icon"
                  color="gray"
                  icon={<PlugFillSplashIcon width={12} height={12} color={colors['ic-white']} />}
                />
              )}
              <Tooltip label={model.name} visibleOnlyEllipsis placement="bottom-center">
                <Name>{model.name}</Name>
              </Tooltip>
            </SubscriptionNameWrapper>
          </SubscriptionNameColumnWrapper>
        </StyledCell>
        <StyledCell $isFilterApplied={appliedFilter.softwareId}>
          <TableDataWrapper>
            <Avatar sx={{ width: 16, height: 16, borderRadius: '4px' }} src={model.software.iconUrl} />
            <Tooltip label={model.software.name} visibleOnlyEllipsis placement="bottom-center">
              <Name>{model.software.name}</Name>
            </Tooltip>
          </TableDataWrapper>
        </StyledCell>
        <Tooltip label={model.planName} visibleOnlyEllipsis placement="bottom-center">
          <StyledCell>{model.planName}</StyledCell>
        </Tooltip>
        <StyledCell $isFilterApplied={appliedFilter.startDateFrom && appliedFilter.startDateTo}>
          {dateFormat(model.startDate, '-', t('DateFormat_YMD'))}
        </StyledCell>
        <StyledCell $isFilterApplied={appliedFilter.endDateFrom && appliedFilter.endDateTo}>
          {dateFormat(model.endDate, '-', t('DateFormat_YMD'))}
        </StyledCell>
        <StyledCell>
          <Label variant="solid" color={labelTypeColor[model.status]}>
            {model.statusStr}
          </Label>
        </StyledCell>
        <StyledCell>
          {model.userGroup.isDeleted ? (
            <DeleteGroupSpan>{`${model.userGroup.name || '-'}${t('Subscrib_Main_25')}`}</DeleteGroupSpan>
          ) : (
            model.userGroup.name || model.tenant.name || '-'
          )}
        </StyledCell>
        <StyledCell>
          {model.isExternalSubscription ? (
            <Label variant="solid" color="white">
              {t('Subscrib_Main_19')}
            </Label>
          ) : (
            '-'
          )}
        </StyledCell>
        <StyledCell align="center">
          <ThreeDotButton onClick={handleThreeDotClick}>
            <ThreeDotIcon width={18} height={18} color={anchorEl ? colors['ic-purple'] : colors['ic-gray-dark']} />
          </ThreeDotButton>
        </StyledCell>
      </>

      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SubscriptionMenuItem onClick={handleMenuClick('EDIT')}>{t('Subscrib_Main_22')}</SubscriptionMenuItem>
        <SubscriptionMenuItem onClick={handleMenuClick('CONNECTION_SETTING')}>
          {t('Subscrib_Main_23')}
        </SubscriptionMenuItem>
        <DeleteMenuItem disabled={!model.isExternalSubscription} onClick={handleMenuClick('DELETE')}>
          {t('Subscrib_Main_24')}
        </DeleteMenuItem>
      </StyledMenu>
    </ErrorBoundary>
  );
};

const StyledCell = styled(TableCell, transientOptions)<{ $isFilterApplied?: boolean }>`
  white-space: nowrap;
  background-color: ${({ theme, $isFilterApplied }) =>
    $isFilterApplied ? theme.colors['bg-gray-lighter'] : 'transparent'};
`;
const DeleteGroupSpan = styled.span`
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
`;
const SubscriptionNameColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
`;

const SubscriptionNameWrapper = styled.div`
  margin-left: 28px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const TableDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled.span`
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SubscriptionMenuItem = styled(MenuItem)`
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
` as typeof MenuItem;

const DeleteMenuItem = styled(MenuItem)`
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

const ThreeDotButton = styled(Button)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 160px;
    box-shadow: none;
    border-radius: 0;
  }
  & .MuiList-root {
    border-radius: 2px;
  }
`;
