// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const ImageIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 12C16.6569 12 18 10.6569 18 9C18 7.34315 16.6569 6 15 6C13.3431 6 12 7.34315 12 9C12 10.6569 13.3431 12 15 12ZM15 10.5C15.8284 10.5 16.5 9.82843 16.5 9C16.5 8.17157 15.8284 7.5 15 7.5C14.1716 7.5 13.5 8.17157 13.5 9C13.5 9.82843 14.1716 10.5 15 10.5Z"
          fill={color}
        />
        <path
          d="M3 19.5C3 20.3284 3.67157 21 4.5 21L19.5 21C20.3284 21 21 20.3284 21 19.5L21 4.5C21 3.67158 20.3284 3 19.5 3H4.5C3.67158 3 3 3.67157 3 4.5V19.5ZM4.5 17.5607L9 13.0607L15.4393 19.5H4.5L4.5 17.5607ZM4.5 15.4393L4.5 4.5L19.5 4.5L19.5 19.5H17.5607L9 10.9393L4.5 15.4393Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5999 15.1983L8.9999 10.7983L17.602 19.4004H19.3999L19.3999 4.60039L4.5999 4.60039L4.5999 15.1983ZM4.5999 17.6025L4.5999 19.4004L15.1978 19.4004L8.9999 13.2025L4.5999 17.6025ZM4.4999 21.1004C3.61625 21.1004 2.8999 20.384 2.8999 19.5004V4.50039C2.8999 3.61673 3.61625 2.90039 4.4999 2.90039H19.4999C20.3836 2.90039 21.0999 3.61674 21.0999 4.50039L21.0999 19.5004C21.0999 20.3841 20.3836 21.1004 19.4999 21.1004L4.4999 21.1004ZM14.9999 10.4004C15.7731 10.4004 16.3999 9.77359 16.3999 9.00039C16.3999 8.22719 15.7731 7.60039 14.9999 7.60039C14.2267 7.60039 13.5999 8.22719 13.5999 9.00039C13.5999 9.77359 14.2267 10.4004 14.9999 10.4004ZM18.0999 9.00039C18.0999 10.7125 16.712 12.1004 14.9999 12.1004C13.2878 12.1004 11.8999 10.7125 11.8999 9.00039C11.8999 7.28831 13.2878 5.90039 14.9999 5.90039C16.712 5.90039 18.0999 7.28831 18.0999 9.00039Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ImageIcon.displayName = 'ImageIcon';
