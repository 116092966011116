const defaultWidth = 16;
const defaultHeight = 16;

export const ManagerFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#6F42C1' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.57599 0.799594C7.42341 0.177479 8.57659 0.177479 9.424 0.799595L10.3409 1.47271C10.5236 1.60683 10.736 1.69481 10.96 1.72915L12.0843 1.90152C13.1234 2.06084 13.9388 2.87626 14.0981 3.91537L14.2705 5.03967C14.3049 5.26369 14.3928 5.47609 14.527 5.65878L15.2001 6.57567C15.8222 7.42309 15.8222 8.57627 15.2001 9.42368L14.527 10.3406C14.3928 10.5233 14.3049 10.7357 14.2705 10.9597L14.0981 12.084C13.9388 13.1231 13.1234 13.9385 12.0843 14.0978L10.96 14.2702C10.736 14.3045 10.5236 14.3925 10.3409 14.5266L9.424 15.1998C8.57659 15.8219 7.42341 15.8219 6.57599 15.1998L5.6591 14.5266C5.47641 14.3925 5.26401 14.3045 5.04 14.2702L3.91569 14.0978C2.87658 13.9385 2.06116 13.1231 1.90184 12.084L1.72947 10.9597C1.69513 10.7357 1.60715 10.5233 1.47303 10.3406L0.799915 9.42368C0.177799 8.57627 0.1778 7.42309 0.799915 6.57567L1.47303 5.65878C1.60715 5.47609 1.69513 5.26369 1.72947 5.03967L1.90184 3.91537C2.06116 2.87626 2.87658 2.06084 3.91569 1.90152L5.04 1.72915C5.26401 1.69481 5.47641 1.60683 5.6591 1.47271L6.57599 0.799594ZM11.8326 5.48922C12.0558 5.6975 12.0558 6.03519 11.8326 6.24346L7.2612 10.5101C7.03804 10.7184 6.67623 10.7184 6.45308 10.5101L4.16736 8.3768C3.94421 8.16852 3.94421 7.83083 4.16736 7.62255C4.39052 7.41427 4.75233 7.41427 4.97548 7.62255L6.85714 9.37876L11.0245 5.48922C11.2477 5.28094 11.6095 5.28094 11.8326 5.48922Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
