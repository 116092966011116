const defaultWidth = 20;
const defaultHeight = 20;

export const CashColorIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <g filter="url(#filter0_d_10505_26327)">
          <path
            d="M18.625 10C18.625 14.7635 14.7635 18.625 10 18.625C5.23654 18.625 1.375 14.7635 1.375 10C1.375 5.23654 5.23654 1.375 10 1.375C14.7635 1.375 18.625 5.23654 18.625 10Z"
            fill="#8A63D2"
          />
        </g>
        <g filter="url(#filter1_d_10505_26327)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 17.425C14.1007 17.425 17.425 14.1007 17.425 10C17.425 5.89929 14.1007 2.575 10 2.575C5.89929 2.575 2.575 5.89929 2.575 10C2.575 14.1007 5.89929 17.425 10 17.425ZM10 18.625C14.7635 18.625 18.625 14.7635 18.625 10C18.625 5.23654 14.7635 1.375 10 1.375C5.23654 1.375 1.375 5.23654 1.375 10C1.375 14.7635 5.23654 18.625 10 18.625Z"
            fill="#5A32A3"
          />
        </g>
        <g filter="url(#filter2_d_10505_26327)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3583 8.95H14.65V10.5H13.0139L12.325 13.6H10.775L10 10.1047L9.22504 13.6H7.67504L6.98615 10.5H5.35004V8.95H6.6417L6.12504 6.625H7.67504L8.45004 10.1047L9.21729 6.625H9.22504H10.7673H10.775L11.55 10.1047L12.325 6.625H13.875L13.3583 8.95Z"
            fill="#E6DCFD"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_10505_26327"
            x="0.375"
            y="0.875"
            width="19.25"
            height="19.25"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10505_26327" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10505_26327" result="shape" />
          </filter>
          <filter
            id="filter1_d_10505_26327"
            x="0.875"
            y="1.375"
            width="18.25"
            height="18.25"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10505_26327" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10505_26327" result="shape" />
          </filter>
          <filter
            id="filter2_d_10505_26327"
            x="4.85004"
            y="6.625"
            width="10.3"
            height="7.9751"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10505_26327" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10505_26327" result="shape" />
          </filter>
        </defs>
      </g>
    </svg>
  );
};

CashColorIcon.displayName = 'CartIcon';
