import { makeAutoObservable, runInAction, toJS } from 'mobx';
import APIs from '@repositories/APIs';
import type { Software } from '@repositories/softwareRepository';
import type { Tenant } from '@repositories/tenantRepository';
import { SubscriptionProfit } from '@type/Profit';
import {
  PurchaseCreateRq,
  PurchaseFilter,
  PurchaseParams,
  SoftwareMonthlyPurchaseGenRq,
  StatusChangeRq,
  SubscriptionPurchase,
  SubscriptionPurchaseCreateRq,
  SubscriptionPurchaseFilter,
  SubscriptionPurchaseGenRq,
  SubscriptionPurchaseParams,
  SubscriptionPurchaseStatusChangeRq,
  SubscriptionPurchaseUpdateRq,
} from '@type/Purchase';
import { ImportSubscriptionMonthlySalesCreateRq, SubscriptionMonthlySaleParams } from '@type/Sale';
import {
  Sales,
  SalesCreateRq,
  SalesFilter,
  SalesParams,
  SubscriptionSalesChangeCreateRq,
  SubscriptionSalesGenRq,
} from '@type/Sales';
import {
  MonthlyPurchaseAggregate,
  SoftwareShareAggregate,
  SubscriptionMonthlyAggregate,
  SubscriptionMonthlyPurchaseStat,
  TenantShareAggregate,
  MonthlySalesAggregate,
  GetProfitStatFilter,
  MonthlyProfitAggregate,
  GetSalesStatFilter,
} from '@type/Statistics';
import { InOutcomeShareStatModel } from '@models/InOutcomeShareStatModel';
import { MonthlyProfitByTypeStatModel } from '@models/MonthlyProfitByTypeStatModel';
import { MonthlyProfitStatModel } from '@models/MonthlyProfitStatModel';
import { MonthlyPurchaseByTypeStatModel } from '@models/MonthlyPurchaseByTypeStatModel';
import { MonthlyPurchaseStatModel } from '@models/MonthlyPurchaseStatModel';
import { MonthlySalesByTypeStatModel } from '@models/MonthlySalesByTypeStatModel';
import { MonthlySalesStatModel } from '@models/MonthlySalesStatModel';
import { OverallProfitSummaryStatModel } from '@models/OverallProfitSummaryStatModel';
import { PurchaseModel } from '@models/PurchaseModel';
import { SoftwareProfitSummaryStatModel } from '@models/SoftwareProfitSummaryStatModel';

class InOutcomeStore {
  private _softwarePurchases: Map<string, PurchaseModel>;

  private _softwarePurchase: PurchaseModel | null;

  private _subscriptionPurchases: Map<string, SubscriptionPurchase>;

  private _subscriptionSalesList: Map<SubscriptionSalesId, Sales>;

  private _subscriptionSales: Sales | null;

  private _subscriptionProfit: SubscriptionProfit | null;

  private _subscriptionMonthlyPurchaseStat: SubscriptionMonthlyPurchaseStat | null;

  private _chartMap: Map<string, unknown>;

  /* Purchase */
  private _monthlyPurchaseStat: MonthlyPurchaseStatModel;

  private _monthlyPurchaseBySoftwareStat: MonthlyPurchaseByTypeStatModel<Software>;

  private _monthlyPurchaseByTenantStat: MonthlyPurchaseByTypeStatModel<Tenant>;

  private _softwarePurchaseShareStat: InOutcomeShareStatModel<Software>;

  private _tenantPurchaseShareStat: InOutcomeShareStatModel<Tenant>;

  /* Sales */

  private _monthlySalesBySoftwareStat: MonthlySalesByTypeStatModel<Software>;

  private _monthlySalesByTenantStat: MonthlySalesByTypeStatModel<Tenant>;

  private _monthlySalesStat: MonthlySalesStatModel;

  private _softwareSalesShareStat: InOutcomeShareStatModel<Software>;

  private _tenantSalesShareStat: InOutcomeShareStatModel<Tenant>;

  /* Profit */
  monthlyProfitStat: MonthlyProfitStatModel;

  monthlyProfitBySoftwareStat: MonthlyProfitByTypeStatModel<Software>;

  monthlyProfitByTenantStat: MonthlyProfitByTypeStatModel<Tenant>;

  overallProfitSummaryStat: OverallProfitSummaryStatModel;

  softwareProfitSummaryStat: SoftwareProfitSummaryStatModel;

  constructor() {
    this._softwarePurchases = new Map<string, PurchaseModel>();
    this._softwarePurchase = new PurchaseModel();
    this._subscriptionPurchases = new Map<string, SubscriptionPurchase>();
    this._subscriptionSalesList = new Map<SubscriptionSalesId, Sales>();
    this._subscriptionSales = null;
    this._subscriptionProfit = null;
    this._subscriptionMonthlyPurchaseStat = null;
    this._chartMap = new Map<string, unknown>();
    /* Purchase */
    this._monthlyPurchaseStat = new MonthlyPurchaseStatModel();
    this._softwarePurchaseShareStat = new InOutcomeShareStatModel(true);
    this._tenantPurchaseShareStat = new InOutcomeShareStatModel(false);
    this._monthlyPurchaseBySoftwareStat = new MonthlyPurchaseByTypeStatModel(true);
    this._monthlyPurchaseByTenantStat = new MonthlyPurchaseByTypeStatModel(false);
    this._tenantPurchaseShareStat = new InOutcomeShareStatModel(false);
    /* Sales */
    this._tenantSalesShareStat = new InOutcomeShareStatModel(false);
    this._softwareSalesShareStat = new InOutcomeShareStatModel(true);
    this._monthlySalesStat = new MonthlySalesStatModel();
    this._monthlySalesBySoftwareStat = new MonthlySalesByTypeStatModel(true);
    this._monthlySalesByTenantStat = new MonthlySalesByTypeStatModel(false);
    /* Profit */
    this.monthlyProfitStat = new MonthlyProfitStatModel();
    this.monthlyProfitBySoftwareStat = new MonthlyProfitByTypeStatModel(true);
    this.monthlyProfitByTenantStat = new MonthlyProfitByTypeStatModel(false);
    this.overallProfitSummaryStat = new OverallProfitSummaryStatModel();
    this.softwareProfitSummaryStat = new SoftwareProfitSummaryStatModel();

    makeAutoObservable<InOutcomeStore>(this);
  }

  async fetchSubscriptionSalesList(queries: SalesFilter) {
    const result = await APIs.SubscriptionSales.getList(queries);
    runInAction(() => {
      this._subscriptionSalesList.clear();
      result.content.forEach(sales => {
        this._subscriptionSalesList.set(sales.subscriptionId + sales.month, sales);
      });
    });
    return result.totalElements;
  }

  // eslint-disable-next-line class-methods-use-this
  async createSubscriptionSales(data: SalesCreateRq) {
    const result = await APIs.SubscriptionSales.create(data);
    return result;
  }

  async updateSubscriptionSales(params: SalesParams, data: SalesCreateRq) {
    const result = await APIs.SubscriptionSales.update(params, data);
    runInAction(() => {
      this._subscriptionSales = result;
    });
    return result;
  }

  async deleteSubscriptionSales(params: SalesParams) {
    await APIs.SubscriptionSales.delete(params);
    runInAction(() => {
      this._subscriptionSales = null;
    });
  }

  getSubscriptionSales(id: string) {
    return this._subscriptionSalesList.get(id);
  }

  // eslint-disable-next-line class-methods-use-this
  async importSubscriptionSales(data: ImportSubscriptionMonthlySalesCreateRq) {
    const result = await APIs.SubscriptionSales.import(data);
    return result;
  }

  async changeSubscriptionSalesStatus(params: SalesParams, data: SubscriptionSalesChangeCreateRq) {
    const result = await APIs.SubscriptionSales.changeStatus(params, data);
    runInAction(() => {
      this._subscriptionSales = result;
      this._subscriptionSalesList.set(result.subscriptionId + result.month, result);
    });
    return result;
  }

  async generateSubscriptionMonthlySales(data: SubscriptionSalesGenRq) {
    const result = await APIs.SubscriptionSales.generate(data);
    runInAction(() => {
      result.data.forEach(sbSales => {
        this._subscriptionSalesList.set(sbSales.subscriptionId + sbSales.month, sbSales);
      });
    });
  }

  async updateSubscriptionSalesList(
    updatedItems: SalesCreateRq[],
    newItems: SalesCreateRq[],
    deletedItems: SubscriptionMonthlySaleParams[],
  ) {
    const updateResult = await Promise.all(
      updatedItems?.map(item => {
        return APIs.SubscriptionSales.update({ month: item.month, subscriptionId: item.subscriptionId }, item);
      }),
    );
    const insertResult = await Promise.all(
      newItems?.map(item => {
        return APIs.SubscriptionSales.create(item);
      }),
    );
    await Promise.all(
      deletedItems?.map(item => {
        return APIs.SubscriptionSales.delete(item);
      }),
    );

    runInAction(() => {
      updateResult.forEach(resp => {
        this._subscriptionSalesList.set(resp.subscriptionId + resp.month, resp);
      });
      insertResult.forEach(resp => {
        this._subscriptionSalesList.set(resp.subscriptionId + resp.month, resp);
      });
      deletedItems.forEach(item => {
        this._subscriptionSalesList.delete(item.subscriptionId + item.month);
      });
    });
  }

  async fetchSoftwarePurchases(queries: PurchaseFilter) {
    const result = await APIs.SoftwarePurchase.getList(queries);
    runInAction(() => {
      this._softwarePurchases.clear();
      result.content.forEach((purchase, idx) => {
        this._softwarePurchases.set(purchase.month as string, new PurchaseModel(purchase, idx));
      });
    });
    return result.totalElements;
  }

  async fetchSoftwarePurchase(params: PurchaseParams) {
    const result = await APIs.SoftwarePurchase.getOne(params);
    runInAction(() => {
      this._softwarePurchase = new PurchaseModel(result);
    });
    return this._softwarePurchase;
  }

  // eslint-disable-next-line class-methods-use-this
  async createSoftwarePurchase(data: PurchaseCreateRq) {
    const result = await APIs.SoftwarePurchase.create(data);
    runInAction(() => {
      this._softwarePurchases.set(result.month, new PurchaseModel(result, this._softwarePurchases.size));
    });
    return result;
  }

  async updateSoftwarePurchase(params: PurchaseParams, data: PurchaseCreateRq) {
    const result = await APIs.SoftwarePurchase.update(params, data);
    runInAction(() => {
      const prev = this._softwarePurchases.get(result.month);
      this._softwarePurchase = new PurchaseModel(result);
      this._softwarePurchases.set(result.month, new PurchaseModel(result, prev?.index));
    });
    return this._softwarePurchase;
  }

  async deleteSoftwarePurchase(params: PurchaseParams) {
    await APIs.SoftwarePurchase.delete(params);
    runInAction(() => {
      this._softwarePurchase = null;
      this._softwarePurchases.delete(params.month);
    });
  }

  async changeStatusSoftwarePurchase(params: PurchaseParams, data: StatusChangeRq) {
    const result = await APIs.SoftwarePurchase.changeStatus(params, data);
    runInAction(() => {
      const prev = this._softwarePurchases.get(result.month);
      this._softwarePurchase = new PurchaseModel(result);
      this._softwarePurchases.set(result.month, new PurchaseModel(result, prev?.index));
    });
    return this._softwarePurchase;
  }

  async generateSoftwareMonthlyPurchase(data: SoftwareMonthlyPurchaseGenRq) {
    const result = await APIs.SoftwarePurchase.generate(data);
    runInAction(() => {
      result.data.forEach((purchase, idx) => {
        this._softwarePurchases.set(purchase.month as string, new PurchaseModel(purchase, idx));
      });
    });
  }

  /* subscription */
  async fetchSubscriptionPurchases(queries: SubscriptionPurchaseFilter) {
    const result = await APIs.SubscriptionPurchase.getList(queries);
    runInAction(() => {
      this._subscriptionPurchases.clear();
      result.content.forEach(purchase => {
        this._subscriptionPurchases.set(purchase.subscription.id + purchase.month, purchase);
      });
    });
    return result.totalElements;
  }

  getSubscriptionPurchase(id: string) {
    return this._subscriptionPurchases.get(id);
  }

  // eslint-disable-next-line class-methods-use-this
  async createSubscriptionPurchase(data: SubscriptionPurchaseCreateRq) {
    const result = await APIs.SubscriptionPurchase.create(data);
    runInAction(() => {
      this._subscriptionPurchases.set(result.subscription.id + result.month, result);
    });
    return result;
  }

  async updateSubscriptionPurchase(params: SubscriptionPurchaseParams, data: SubscriptionPurchaseUpdateRq) {
    const result = await APIs.SubscriptionPurchase.update(params, data);
    runInAction(() => {
      this._subscriptionPurchases.set(result.subscription.id + result.month, result);
    });
    return result;
  }

  async updateSubscriptionPurchaseList(
    updatedItems: SubscriptionPurchaseUpdateRq[],
    newItems: SubscriptionPurchaseCreateRq[],
    deletedItems: SubscriptionPurchaseParams[],
  ) {
    const updateResult = await Promise.all(
      updatedItems?.map(item => {
        return APIs.SubscriptionPurchase.update(
          { subscriptionId: item.subscriptionId, purchaseContractId: item.purchaseContractId, month: item.month },
          item,
        );
      }),
    );
    const insertResult = await Promise.all(
      newItems?.map(item => {
        return APIs.SubscriptionPurchase.create(item);
      }),
    );
    await Promise.all(
      deletedItems?.map(item => {
        return APIs.SubscriptionPurchase.delete(item);
      }),
    );

    runInAction(() => {
      updateResult.forEach(resp => {
        this._subscriptionPurchases.set(resp.subscription.id + resp.month, resp);
      });
      insertResult.forEach(resp => {
        this._subscriptionPurchases.set(resp.subscription.id + resp.month, resp);
      });
      deletedItems.forEach(item => {
        this._subscriptionPurchases.delete(item.subscriptionId + item.month);
      });
    });
  }

  async deleteSubscriptionPurchase(params: SubscriptionPurchaseParams) {
    await APIs.SubscriptionPurchase.delete(params);
    runInAction(() => {
      this._subscriptionPurchases.delete(params.subscriptionId + params.month);
    });
  }

  async changeSubscriptionPurchaseStatus(
    params: SubscriptionPurchaseParams,
    data: SubscriptionPurchaseStatusChangeRq,
  ): Promise<SubscriptionPurchase> {
    const result = await APIs.SubscriptionPurchase.changeStatus(params, data);
    runInAction(() => {
      this._subscriptionPurchases.set(result.subscription.id + result.month, result);
    });
    return result;
  }

  async generateSubscriptionMonthlyPurchase(data: SubscriptionPurchaseGenRq) {
    const result = await APIs.SubscriptionPurchase.generate(data);
    runInAction(() => {
      result.data.forEach(sbPurchase => {
        this._subscriptionPurchases.set(sbPurchase.subscription.id + sbPurchase.month, sbPurchase);
      });
    });
  }

  /* Purchase */
  async fetchSubscriptionMonthlyPurchaseStat(queries: SubscriptionMonthlyAggregate) {
    const result = await APIs.PurchaseStat.getSubscriptionMonthlyPurchaseStat(queries);
    runInAction(() => {
      this._subscriptionMonthlyPurchaseStat = result;
    });
    return this._subscriptionMonthlyPurchaseStat;
  }

  async fetchMonthlyPurchaseStat(queries: MonthlyPurchaseAggregate) {
    const result = await APIs.PurchaseStat.getMonthlyPurchaseStat(queries);
    runInAction(() => {
      this._monthlyPurchaseStat.update(result);
    });
    return this._monthlyPurchaseStat;
  }

  /**
   * @deprecated
   */
  async fetchMonthlyPurchaseBySoftwareStat(queries: MonthlyPurchaseAggregate) {
    const result = await APIs.PurchaseStat.getMonthlyPurchaseBySoftwareStat(queries);
    runInAction(() => {
      this._monthlyPurchaseBySoftwareStat.update(result);
    });
    return this._monthlyPurchaseBySoftwareStat;
  }

  async fetchMonthlyPurchaseByTenantStat(queries: MonthlyPurchaseAggregate) {
    const result = await APIs.PurchaseStat.getMonthlyPurchaseByTenantStat(queries);
    runInAction(() => {
      this._monthlyPurchaseByTenantStat.update(result);
    });
    return this._monthlyPurchaseByTenantStat;
  }

  async fetchSoftwarePurchaseShareStat(queries: SoftwareShareAggregate) {
    const result = await APIs.PurchaseStat.getSoftwarePurchaseShareStat(queries);
    runInAction(() => {
      this._softwarePurchaseShareStat.update(result);
    });
    return this._softwarePurchaseShareStat;
  }

  async fetchTenantPurchaseShareStat(queries: TenantShareAggregate) {
    const result = await APIs.PurchaseStat.getTenantPurchaseShareStat(queries);
    runInAction(() => {
      this._tenantPurchaseShareStat.update(result);
    });
    return this._tenantPurchaseShareStat;
  }
  /* * * * * * */

  /* Sales */
  fetchMonthlySalesStat = async (queries?: MonthlySalesAggregate) => {
    const result = await APIs.SalesStat.getMonthlySalesStat(queries);
    runInAction(() => {
      this._monthlySalesStat.update(result);
    });
  };

  fetchSoftwareSalesShareStat = async (queries?: GetSalesStatFilter) => {
    const result = await APIs.SalesStat.getSoftwareSalesShare(queries);
    runInAction(() => {
      this._softwareSalesShareStat.update(result);
    });
  };

  fetchTenantSalesShareStat = async (queries?: GetSalesStatFilter) => {
    const result = await APIs.SalesStat.getTenantSalesShareStat(queries);
    runInAction(() => {
      this._tenantSalesShareStat.update(result);
    });
  };

  fetchMonthlySalesBySoftware = async (queries?: MonthlySalesAggregate) => {
    const result = await APIs.SalesStat.getMonthlySalesBySoftwareStat(queries);
    runInAction(() => {
      this._monthlySalesBySoftwareStat.update(result);
    });
  };

  fetchMonthlySalesByTenant = async (queries?: MonthlySalesAggregate) => {
    const result = await APIs.SalesStat.getMonthlySalesByTenantStat(queries);
    runInAction(() => {
      this._monthlySalesByTenantStat.update(result);
    });
  };

  /* * * * */
  /* Profit */
  fetchMonthlyProfitStat = async (queries?: GetProfitStatFilter) => {
    const result = await APIs.ProfitStat.getMonthlyProfitStat(queries);
    runInAction(() => {
      this.monthlyProfitStat.update(result);
    });
  };

  fetchMonthlyProfitBySoftwareStat = async (queries?: MonthlyProfitAggregate) => {
    const result = await APIs.ProfitStat.getMonthlyProfitBySoftwareStat(queries);
    runInAction(() => {
      this.monthlyProfitBySoftwareStat.update(result);
    });
  };

  fetchMonthlyProfitByTenantStat = async (queries?: MonthlyProfitAggregate) => {
    const result = await APIs.ProfitStat.getMonthlyProfitByTenantStat(queries);
    runInAction(() => {
      this.monthlyProfitByTenantStat.update(result);
    });
  };

  fetchOverallProfitSummaryStat = async (queries?: { year: DateTimeString }) => {
    const result = await APIs.ProfitStat.getOverallProfitSummaryStat(queries);
    runInAction(() => {
      this.overallProfitSummaryStat.update(result);
    });
  };

  fetchSoftwareProfitSummaryStat = async (queries: { softwareId: string; year: DateTimeString }) => {
    const result = await APIs.ProfitStat.getSoftwareProfitSummaryStat(queries);
    runInAction(() => {
      this.softwareProfitSummaryStat.update(queries?.softwareId, result);
    });
  };
  /* * * * * */

  get softwarePurchases(): PurchaseModel[] {
    return Array.from(this._softwarePurchases.values());
  }

  get softwarePurchase(): PurchaseModel {
    return this._softwarePurchase as PurchaseModel;
  }

  getSoftwarePurchase(id: string) {
    return this._softwarePurchases.get(id);
  }

  get subscriptionPurchases(): SubscriptionPurchase[] {
    return toJS(Array.from(this._subscriptionPurchases?.values()));
  }

  get SubscriptionProfit(): SubscriptionProfit {
    return this._subscriptionProfit as SubscriptionProfit;
  }

  get subscriptionSalesList(): Sales[] {
    return Array.from(this._subscriptionSalesList.values());
  }

  get subscriptionSales(): Sales {
    return this._subscriptionSales as Sales;
  }

  get softwareSalesShareStat() {
    return this._softwareSalesShareStat;
  }

  get tenantSalesShareStat() {
    return this._tenantSalesShareStat;
  }

  get subscriptionMonthlyPurchaseStat(): SubscriptionMonthlyPurchaseStat {
    return this._subscriptionMonthlyPurchaseStat as SubscriptionMonthlyPurchaseStat;
  }

  get monthlyPurchaseStat(): MonthlyPurchaseStatModel {
    return this._monthlyPurchaseStat;
  }

  get monthlySalesStat(): MonthlySalesStatModel {
    return this._monthlySalesStat;
  }

  get monthlyPurchaseBySoftwareStat(): MonthlyPurchaseByTypeStatModel<Software> {
    return this._monthlyPurchaseBySoftwareStat;
  }

  get monthlyPurchaseByTenantStat(): MonthlyPurchaseByTypeStatModel<Tenant> {
    return this._monthlyPurchaseByTenantStat;
  }

  get monthlySalesBySoftwareStat(): MonthlySalesByTypeStatModel<Software> {
    return this._monthlySalesBySoftwareStat;
  }

  get monthlySalesByTenantStat(): MonthlySalesByTypeStatModel<Tenant> {
    return this._monthlySalesByTenantStat;
  }

  get softwarePurchaseShareStat(): InOutcomeShareStatModel<Software> {
    return this._softwarePurchaseShareStat;
  }

  get tenantPurchaseShareStat(): InOutcomeShareStatModel<Tenant> {
    return this._tenantPurchaseShareStat;
  }

  get subscriptionPurchaseChartData(): {
    dataKeys: string[];
    datas: {
      name: string;
      [key: string]: any;
    }[];
  } {
    const dataKeys: Set<string> = new Set();
    const datas: Map<DateTimeString, { name: string; [key: string]: any }> = new Map();

    this.subscriptionPurchases.forEach(purchase => {
      if (purchase.subscription.name) {
        dataKeys.add(purchase.subscription.name);

        const old = datas.get(purchase.month);
        if (old) {
          datas.set(purchase.month, { ...old, [purchase.subscription.name]: purchase.amount });
        } else {
          datas.set(purchase.month, { name: purchase.month, [purchase.subscription.name]: purchase.amount });
        }
      }
    });

    return {
      dataKeys: Array.from(dataKeys.values()),
      datas: Array.from(datas.values()).sort((item1, item2) => item1.name.localeCompare(item2.name)),
    };
  }

  get subscriptionSalesChartData(): {
    dataKeys: string[];
    datas: {
      name: string;
      [key: string]: any;
    }[];
  } {
    const dataKeys: Set<string> = new Set();
    const datas: Map<DateTimeString, { name: string; [key: string]: any }> = new Map();

    this.subscriptionSalesList.forEach(sales => {
      dataKeys.add(sales.subscriptionId);

      const old = datas.get(sales.month);
      if (old) {
        datas.set(sales.month, { ...old, [sales.subscriptionId]: sales.amount });
      } else {
        datas.set(sales.month, { name: sales.month, [sales.subscriptionId]: sales.amount });
      }
    });

    return {
      dataKeys: Array.from(dataKeys.values()),
      datas: Array.from(datas.values()).sort((item1, item2) => item1.name.localeCompare(item2.name)),
    };
  }

  get subscriptionPurchaseEndMonth(): string {
    const ids = Array.from(this._subscriptionPurchases.values())
      ?.map(sb => sb.month)
      .sort((a, b) => a.localeCompare(b));
    const idsEnd = ids[ids.length - 1];
    return idsEnd;
  }

  get subscriptionSalesStartMonth(): string {
    const ids = Array.from(this._subscriptionSalesList.values())
      ?.map(sb => sb.month)
      .sort((a, b) => a.localeCompare(b));
    const idsEnd = ids[0];
    return idsEnd;
  }

  get subscriptionSalesEndMonth(): string {
    const ids = Array.from(this._subscriptionSalesList.values())
      ?.map(sb => sb.month)
      .sort((a, b) => a.localeCompare(b));
    const idsEnd = ids[ids.length - 1];
    return idsEnd;
  }
}
const purchaseStore = new InOutcomeStore();

export default purchaseStore;
