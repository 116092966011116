import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Typography, useTheme } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { EyeIcon, EyeSplashIcon } from '@icons/index';
import { useChangeUserPassword, useInitialPassword, useLogout } from '@queryHooks/useUser';
import * as S from './ChangePasswordStyles';
import { FormType, useChangePasswordForm } from './validationSchema';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();

  const {
    authStore,
    uiStore: { toastStore },
  } = useStore();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useChangePasswordForm();

  const { mutate: initialPassword } = useInitialPassword();
  const { mutate: changePassword } = useChangeUserPassword();
  const { logout } = useLogout();

  const validation = {
    successPassword: (data: FormType) => {
      if (params.token) {
        initialPassword(
          { activeCode: params.token as string, password: data.passwordConfirm },
          {
            onSuccess: () => {
              toastStore.open(t('Change_Password'), undefined, undefined, undefined, true);
              navigate('/login');
            },
          },
        );
      } else {
        changePassword(
          { userId: authStore.id, password: data.passwordConfirm },
          {
            onSuccess: () => {
              logout();
              toastStore.open(t('Change_Password'), undefined, undefined, undefined, true);
              navigate('/login');
            },
          },
        );
      }
    },
  };

  const passwordValue = watch('password');
  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <S.Container onSubmit={handleSubmit(validation.successPassword)}>
      <S.Content>
        <Typography component="strong" variant="headline3">
          {t('GNB_Profile_MyProfile_09')}
        </Typography>
        <S.PasswordWrapper>
          <S.Password>
            <Typography component="span" variant="headline8">
              {t('GNB_Profile_MyProfile_07')}
            </Typography>
            <TextField
              {...register('password')}
              className="password-field"
              type={showPassword ? 'text' : 'password'}
              size="large"
              placeholder={t('Password_Change_01')}
              helperText={errors.password?.message}
              error={!!errors.password?.message}
              InputProps={{
                endAdornment:
                  passwordValue.length === 0 ? null : (
                    <S.PasswordToggleButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <EyeIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                      ) : (
                        <EyeSplashIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                      )}
                    </S.PasswordToggleButton>
                  ),
              }}
            />
          </S.Password>
          <S.Password>
            <Typography component="span" variant="headline8">
              {t('GNB_Profile_MyProfile_09')}
            </Typography>
            <TextField
              {...register('passwordConfirm')}
              type={showPassword ? 'text' : 'password'}
              size="large"
              placeholder={t('Password_Change_03')}
              className="password-field"
              helperText={errors.passwordConfirm?.message}
              error={!!errors.passwordConfirm?.message}
              InputProps={{
                endAdornment:
                  passwordValue.length === 0 ? null : (
                    <S.PasswordToggleButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <EyeIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                      ) : (
                        <EyeSplashIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                      )}
                    </S.PasswordToggleButton>
                  ),
              }}
            />
          </S.Password>
        </S.PasswordWrapper>
        <S.ConfirmBtn type="submit" size="2extraLarge" variant="contain" color="">
          {t('Member_User_24')}
        </S.ConfirmBtn>
      </S.Content>
    </S.Container>
  );
};
