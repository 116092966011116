import { ExpressionType, WorkflowActionArgumentDTO } from '@repositories/workflowRepository/Types';

export class WorkflowActionArgumentModel {
  private readonly _argumentName: string;
  private readonly _expression?: string;
  private readonly _expressionType?: ExpressionType;
  private readonly _id?: string;

  constructor(dto: WorkflowActionArgumentDTO) {
    this._argumentName = dto.argumentName;
    this._expression = dto.expression;
    this._expressionType = dto.expressionType;
    this._id = dto.id;
  }

  get argumentName() {
    return this._argumentName;
  }

  get expression() {
    return this._expression;
  }

  get expressionType() {
    return this._expressionType;
  }

  get id() {
    return this._id;
  }
}
