import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useGetMonthlyBilling } from '@queryHooks/useMonthlyBilling';
import { useGetUsageMetricList } from '@queryHooks/useSoftware';
import { useGetSubscription } from '@queryHooks/useSubscription';
import { dateFormat } from '@utils/dateUtil';
import { MetricUsageCostModel } from '@models/billing/MetricUsageCostModel';
import { usePrintButton } from '../PrintButton';
import { SubscriptionBillingPrintView } from './SubscriptionBillingPrintView';
import { SubscriptionBillingDetailView } from './layout/SubscriptionBillingDetailView';

export const SubscriptionBillingDetail = () => {
  const { subscriptionBillingId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const printViewRef = useRef<HTMLDivElement>(null);

  const { printState, onBeforeGetContentResolve, PrintButton } = usePrintButton();

  const {
    data: subscriptionBilling,
    isLoading,
    isSuccess: isSubscriptionBillingSuccess,
  } = useGetMonthlyBilling(subscriptionBillingId ?? '');
  const { data: usageMetrics, isSuccess: isUsageMetricsSuccess } = useGetUsageMetricList(
    subscriptionBilling?.subscription.software.id ?? '',
  );
  const { data: subscription, isSuccess: isSubscriptionSuccess } = useGetSubscription(
    subscriptionBilling?.subscription.subscriptionId ?? '',
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  const metricUsageCosts = MetricUsageCostModel.createFromCost(
    subscriptionBilling?.moduleUsageCosts ?? [],
    usageMetrics,
  );

  useEffect(() => {
    if (
      printState.isOpen &&
      isSubscriptionBillingSuccess &&
      isUsageMetricsSuccess &&
      isSubscriptionSuccess &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [
    isSubscriptionBillingSuccess,
    isSubscriptionSuccess,
    isUsageMetricsSuccess,
    onBeforeGetContentResolve,
    printState.isOpen,
  ]);

  return (
    <Container>
      <GoBackRow>
        <IconButton onClick={handleBackClick}>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        {t('Acc_Detail_18')}
      </GoBackRow>
      {isLoading && <Loader />}
      {isSubscriptionBillingSuccess && isSubscriptionSuccess && (
        <Content>
          <SubscriptionBillingDetailView
            subscription={subscription}
            subscriptionBilling={subscriptionBilling}
            metricUsageCosts={metricUsageCosts}
            PrintButton={
              <PrintButton
                contentRef={printViewRef}
                documentTitle={`${subscriptionBilling?.subscription.name}_${dateFormat(
                  subscriptionBilling?.month,
                  '',
                  'YYYY.MM',
                )}`}
              />
            }
          />
        </Content>
      )}

      {isSubscriptionBillingSuccess && isSubscriptionSuccess && printState.isOpen && (
        <SubscriptionBillingPrintView
          ref={printViewRef}
          subscription={subscription}
          subscriptionBilling={subscriptionBilling}
          metricUsageCosts={metricUsageCosts}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow-y: auto;
`;

const GoBackRow = styled.h2`
  ${fonts.Headline6}
  display: flex;
  align-items: center;
  padding: 28px 32px 0px 32px;
  gap: 4px;
`;

const Content = styled.div`
  padding: 32px 60px 52px;
`;
