import React, { useLayoutEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { observer } from 'mobx-react';
import { ErrorNoti } from '@pages/admin/modals';
import { employeeRouter } from '@pages/customer/employee/employeeRouter';
import { managerRouter } from '@pages/customer/manager/managerRouter';
import { StoreProvider, useStore } from '@stores/RootStore';
import i18n from './locales/i18n';
import { ColorModeContext, useMode } from './themeCustomer';
import './initDayjs';

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter/600.css';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 1000 * 60,
      staleTime: 4 * 1000 * 60,
    },
  },
});

export const CustomerApp: React.FC = observer(() => {
  const [theme, colorMode] = useMode();
  const { uiStore } = useStore();
  const isManager = window.location.pathname.split('/')[1] === 'manager';

  useLayoutEffect(() => {
    uiStore.setTypeUi(isManager ? 'IT_ADMIN_UI' : 'USER_UI');
  }, [isManager, uiStore]);

  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              {/* <Global styles={reset} /> */}
              <CssBaseline />
              <GoogleOAuthProvider clientId={CLIENT_ID}>
                <StoreProvider>
                  <RouterProvider router={isManager ? managerRouter : employeeRouter} />
                  <ErrorNoti />
                </StoreProvider>
              </GoogleOAuthProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LocalizationProvider>
    </I18nextProvider>
  );
});
