import { ProductPlanListModel, SoftwareModel } from '@models/softwareModels';
import { CartProductModel } from '@models/softwareModels/CartProductModel';
import CartProductModelMapper from '@repositories/browserStorage/mapper/CartProductModelMapper';
import sessionStorage from '@repositories/browserStorage/sessionStorage/SessionStorage';

export const saveItemToLocalStorage = (sw: SoftwareModel, plan: ProductPlanListModel, inquiry: string, id?: string) => {
  const item = new CartProductModel({
    companyName: sw.companyName ?? '',
    thumbnailUrl: sw.thumbnailUrl,
    iconUrl: sw.iconUrl,
    productPlans: plan,
    description: sw.description,
    isModified: false,
    isChecked: false,

    id,
    productId: sw.id,
    productName: sw.name,
    planId: plan.planId,
    planName: plan.planName,
    inquiry,
  });

  const origin = sessionStorage.get('cartProduct', CartProductModelMapper);
  if (Array.isArray(origin)) {
    const alreadyItemIndex = origin.findIndex(exist => exist.isSame(item));
    if (alreadyItemIndex >= 0) {
      origin.splice(alreadyItemIndex, 1);
    }
    origin.push(item);
    sessionStorage.set('cartProduct', JSON.stringify(origin));
  } else {
    sessionStorage.set('cartProduct', JSON.stringify([item]));
  }
};
