const defaultWidth = 24;
const defaultHeight = 24;

export const EyeSplashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58561 10.1724C5.24658 11.4081 4.41471 12.8937 4.06995 13.8417C3.88121 14.3608 3.30745 14.6285 2.78841 14.4398C2.26938 14.2511 2.00162 13.6773 2.19036 13.1583C2.63348 11.9397 3.63343 10.1753 5.22925 8.7026C5.36737 8.57514 5.5102 8.44967 5.65774 8.32679L7.35297 9.53766C7.08172 9.7376 6.82603 9.95053 6.58561 10.1724ZM9.71472 11.2246C8.59156 11.9642 7.85024 13.2364 7.85024 14.6818C7.85024 16.9662 9.70216 18.8181 11.9866 18.8181C13.9161 18.8181 15.5371 17.497 15.9942 15.71L9.71472 11.2246ZM15.9528 13.5036L13.5002 11.7778L11.7578 10.5516C11.8336 10.5475 11.9098 10.5454 11.9866 10.5454C13.8617 10.5454 15.4454 11.7931 15.9528 13.5036ZM8.98653 8.60146L7.13148 7.29606C8.47584 6.52014 10.0956 6 11.9938 6C14.8958 6 17.1471 7.21576 18.7583 8.7026C20.3542 10.1753 21.3541 11.9397 21.7972 13.1583C21.986 13.6773 21.7182 14.2511 21.1992 14.4398C20.6801 14.6285 20.1064 14.3608 19.9176 13.8417C19.5729 12.8937 18.741 11.4081 17.402 10.1724C16.0783 8.95091 14.2918 8 11.9938 8C10.8712 8 9.87067 8.22692 8.98653 8.60146Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.59067 6.71307C2.90744 6.26067 3.49103 6.12274 3.89413 6.405L19.9517 17.6486C20.3548 17.9309 20.4248 18.5265 20.108 18.9789C19.7913 19.4313 19.2077 19.5692 18.8046 19.287L2.74698 8.0433C2.34387 7.76104 2.27389 7.16548 2.59067 6.71307Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

EyeSplashIcon.displayName = 'EyeSplashIcon';
