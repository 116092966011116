import { forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

type Props = {
  value: string | number;
  steps: { value: string | number; label?: string }[];
  className?: string;
};

export const Stepper = forwardRef<HTMLDivElement, Props>(({ className, value: curStep, steps }, ref) => {
  const [selectedStep, setSelectedStep] = useState<HTMLDivElement | null>(null);

  return (
    <Container ref={ref} className={className}>
      {steps.map(({ value, label }, idx) => (
        <Step
          key={value}
          ref={ref => {
            if (value === curStep) {
              setSelectedStep(ref);
            }
          }}
          $isSelected={value === curStep}
        >
          <FlexBox>
            <Index $isSelected={value === curStep}>{idx + 1}</Index> {label ?? value}
          </FlexBox>
        </Step>
      ))}
      {selectedStep && <Indicator style={{ width: selectedStep.clientWidth, left: selectedStep.offsetLeft }} />}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  gap: 16px;
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  height: 6px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-light']};
  border-radius: 3px;
  transition: left 300ms;
`;

const Step = styled('div', transientOptions)<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ $isSelected, theme: { colors } }) =>
    $isSelected ? colors['text-gray-sub-darker'] : colors['text-gray-light']};
  gap: 6px;
  ${fonts.Body4}

  &::before {
    height: 6px;
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light-m']};
    border-radius: 3px;
    content: '';
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Index = styled('div', transientOptions)<{ $isSelected: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  ${fonts.Caption3}
  color: ${({ theme: { colors } }) => colors['text-white']};
  background-color: ${({ $isSelected, theme: { colors } }) =>
    $isSelected ? colors['bg-purple-light'] : colors['bg-gray-light-m']};
`;
