import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, css, useTheme } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { CircleFillIcon } from '@icons/CircleFillIcon';
import { SubscriptionCountStatKey } from '@repositories/SubscriptionCountStatRepository';
import { transientOptions } from '@utils/CommonUtil';
import { numberFormat } from '@utils/numberFormat';

export const SubscriptionFilterCard: React.FC<{
  status: SubscriptionCountStatKey;
  statusName: string;
  count: number;
  monthlyIncrementRate: number;
  onClick?: () => void;
  onMouseEnter?: () => void;
  className?: string;
}> = ({ status, statusName, count, monthlyIncrementRate, onClick, onMouseEnter, className }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const typeColor: { [key in SubscriptionCountStatKey]: string } = {
    totalCount: '#000000',
    subscriptionCount: '#28A745',
    monthlyStartingCount: '#F9C513',
    monthlyEndingCount: '#EA4A5A',
    endSubscriptionCount: '#6A737D',
    errorSubscriptionCount: '#EC6CB9',
  };

  return (
    <FilterButton className={className} $status={status} onClick={onClick} onMouseEnter={onMouseEnter}>
      <Header>
        <TypeName $status={status} $badgeColor={statusName === t('Acc_Main_12') ? '' : typeColor[status]}>
          {statusName}
        </TypeName>
      </Header>
      <Contents>
        <Count className="count" $subscriptionType={status}>
          {count}
        </Count>
        <ComparisonWrapper>
          {status === 'endSubscriptionCount' ? (
            <Comparison>{t('Subscrib_Main_Filter_04')}</Comparison>
          ) : status === 'monthlyStartingCount' || status === 'monthlyEndingCount' ? (
            <Comparison>{t('Acc_Main_08')}</Comparison>
          ) : status === 'subscriptionCount' ? (
            <>
              <Comparison>{t('Acc_Main_08')}</Comparison>
              {monthlyIncrementRate === 0 ? null : monthlyIncrementRate > 0 ? (
                <CircleFillIcon width={16} height={16} color={theme.colors['ic-green-light']} />
              ) : (
                <CircleFillIcon width={16} height={16} color={theme.colors['ic-red-light']} rotateNum={180} />
              )}
              <ComparisonValue $value={monthlyIncrementRate}>
                {numberFormat({ num: Math.abs(monthlyIncrementRate), maxFractionDigit: 2 })}
              </ComparisonValue>
            </>
          ) : null}
        </ComparisonWrapper>
      </Contents>
    </FilterButton>
  );
};

const FilterButton = styled(Button, transientOptions)<{ $status: SubscriptionCountStatKey }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  min-width: 200px;
  height: 140px;
  text-align: left;
  padding: 34px 36px 24px 36px;
  flex-grow: 1;
  cursor: ${({ $status }) => ($status === 'errorSubscriptionCount' ? 'auto' : 'pointer')};
  border-radius: 0;
  &:after {
    content: '';
    width: 100%;
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid ${({ theme }) => theme.colors['border-gray-dark']};
    border-right-color: ${({ theme }) => theme.colors['border-gray-lighter']};
    border-width: 1px 1px 1px 0;
  }
  &:hover:after,
  &.clicked:after {
    ${({ $status, theme: { colors } }) =>
      $status !== 'errorSubscriptionCount'
        ? css`
            border: 1px solid transparent;
            border-width: 1px;
            background-image: linear-gradient(
                ${colors['state-white-purple-hover']},
                ${colors['state-white-purple-hover']}
              ),
              ${colors['border-gradient']};
            background-origin: border-box;
            background-clip: content-box, border-box;
          `
        : ''}
  }
  &.clicked:after {
    background-image: linear-gradient(#fff, #fff), ${({ theme }) => theme.colors['border-gradient']};
    border-width: 2px;
  }
  &.clicked {
    & .count {
      background: ${({ theme }) => theme.colors['text-gradient']};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &:first-of-type {
    &:after {
      border-width: 1px;
      border-radius: 5px 0 0 5px;
    }
    &.clicked:after {
      border-width: 2px;
    }
  }
  &:last-of-type {
    &:after {
      border-radius: 0 5px 5px 0;
      border-right-color: ${({ theme }) => theme.colors['border-gray-dark']};
    }
    &.clicked:after {
      border-radius: 0 5px 5px 0;
      border-right-color: transparent;
    }
  }
  &.prevButton:after {
    border-right-width: 0;
  }
  &.clicked + button:after {
    border-left-width: 0;
  }
  & .MuiTouchRipple-root {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  z-index: 100;
`;

const TypeName = styled('span', transientOptions)<{ $badgeColor: string; $status: SubscriptionCountStatKey }>`
  position: relative;
  ${fonts.Headline7};

  ${({ $status, $badgeColor, theme: { colors } }) => {
    if ($status === 'totalCount') {
      return css`
        padding-left: 0;
        &::after {
          display: none;
        }
      `;
    }
    if ($status === 'errorSubscriptionCount') {
      return css`
        color: ${colors['text-red-dark']};
      `;
    }
    if ($badgeColor) {
      return css`
        padding-left: 14px;
        &::after {
          content: '';
          display: block;
          width: 4px;
          height: 17px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          background-color: ${$badgeColor};
          border-radius: 1px;
        }
      `;
    }

    return ``;
  }}
`;

const Comparison = styled('span')`
  ${fonts.Body4};
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  margin-right: 6px;
`;

const ComparisonValue = styled.span<{ $value: number }>`
  ${fonts.Headline7}
  margin-left: 2px;
  color: ${({ $value, theme: { colors } }) =>
    $value > 0 ? colors['text-green-light'] : $value < 0 ? colors['text-red-light'] : colors['text-gray-main']};
`;

const Contents = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 100;
`;

const Count = styled('span', transientOptions)<{ $subscriptionType: SubscriptionCountStatKey }>`
  ${fonts.Headline11}

  ${({ $subscriptionType, theme }) => {
    if ($subscriptionType === 'errorSubscriptionCount') {
      return css`
        color: ${theme.colors['text-red']};
      `;
    }

    return ``;
  }}
`;

const ComparisonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
