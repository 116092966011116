const defaultWidth = 100;
const defaultHeight = 100;

export const UpdatingIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <rect x="19" y="30" width="12" height="40" fill="#8a63d2">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="18;30;30"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.2s"
        />
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="64;40;40"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.2s"
        />
      </rect>
      <rect x="44" y="30" width="12" height="40" fill="#6f42c1">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="20.999999999999996;30;30"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.1s"
        />
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="58.00000000000001;40;40"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.1s"
        />
      </rect>
      <rect x="69" y="30" width="12" height="40" fill="#b392f0">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="20.999999999999996;30;30"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        />
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="58.00000000000001;40;40"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
        />
      </rect>
      <g />
    </g>
  </svg>
);

UpdatingIcon.displayName = 'UpdatingIcon';

// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   viewBox="0 0 100 100"
//   preserveAspectRatio="xMidYMid"
//   width="100"
//   height="100"
//   style="shape-rendering: auto; display: block; background: rgb(255, 255, 255);"
//   xmlns:xlink="http://www.w3.org/1999/xlink"
// >
//   <g>
//     <rect x="19" y="30" width="12" height="40" fill="#8a63d2">
//       <animate
//         attributeName="y"
//         repeatCount="indefinite"
//         dur="1s"
//         calcMode="spline"
//         keyTimes="0;0.5;1"
//         values="18;30;30"
//         keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
//         begin="-0.2s"
//       />
//       <animate
//         attributeName="height"
//         repeatCount="indefinite"
//         dur="1s"
//         calcMode="spline"
//         keyTimes="0;0.5;1"
//         values="64;40;40"
//         keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
//         begin="-0.2s"
//       />
//     </rect>
//     <rect x="44" y="30" width="12" height="40" fill="#6f42c1">
//       <animate
//         attributeName="y"
//         repeatCount="indefinite"
//         dur="1s"
//         calcMode="spline"
//         keyTimes="0;0.5;1"
//         values="20.999999999999996;30;30"
//         keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
//         begin="-0.1s"
//       />
//       <animate
//         attributeName="height"
//         repeatCount="indefinite"
//         dur="1s"
//         calcMode="spline"
//         keyTimes="0;0.5;1"
//         values="58.00000000000001;40;40"
//         keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
//         begin="-0.1s"
//       />
//     </rect>
//     <rect x="69" y="30" width="12" height="40" fill="#b392f0">
//       <animate
//         attributeName="y"
//         repeatCount="indefinite"
//         dur="1s"
//         calcMode="spline"
//         keyTimes="0;0.5;1"
//         values="20.999999999999996;30;30"
//         keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
//       />
//       <animate
//         attributeName="height"
//         repeatCount="indefinite"
//         dur="1s"
//         calcMode="spline"
//         keyTimes="0;0.5;1"
//         values="58.00000000000001;40;40"
//         keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
//       />
//     </rect>
//     <g />
//   </g>
// </svg>;
