// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const ChevronIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  rotateNum = 0,
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotateNum}deg)` }}
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9217 15.4218C19.2927 15.0508 19.2927 14.4493 18.9217 14.0783L12.6717 7.82826C12.3007 7.45726 11.6992 7.45726 11.3282 7.82826L5.0782 14.0783C4.7072 14.4493 4.7072 15.0508 5.0782 15.4218C5.4492 15.7928 6.0507 15.7928 6.4217 15.4218L12 9.84352L17.5782 15.4218C17.9492 15.7928 18.5507 15.7928 18.9217 15.4218Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ChevronIcon.displayName = 'ChevronIcon';
