const defaultWidth = 20;
const defaultHeight = 20;

export const UploadIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        d="M4.875 18.334C3.97754 18.334 3.25 17.6064 3.25 16.709L3.25 12.959C3.25 12.5218 3.60444 12.1673 4.04167 12.1673C4.47889 12.1673 4.83333 12.5218 4.83333 12.959L4.83333 16.709C4.83333 16.732 4.85199 16.7507 4.875 16.7507L16.125 16.7507C16.148 16.7507 16.1667 16.732 16.1667 16.709L16.1667 12.959C16.1667 12.5218 16.5211 12.1673 16.9583 12.1673C17.3956 12.1673 17.75 12.5218 17.75 12.959L17.75 16.709C17.75 17.6064 17.0225 18.334 16.125 18.334L4.875 18.334Z"
        fill={color}
      />
      <path
        d="M11.2917 4.75784L11.2917 12.9173C11.2917 13.3545 10.9372 13.709 10.5 13.709C10.0628 13.709 9.70833 13.3545 9.70833 12.9173L9.70833 4.75784L7.08613 7.24203C6.76873 7.54273 6.26766 7.52919 5.96696 7.21178C5.66626 6.89438 5.6798 6.3933 5.9972 6.0926L9.95554 2.3426C10.2609 2.05333 10.7391 2.05333 11.0445 2.3426L15.0028 6.0926C15.3202 6.3933 15.3337 6.89437 15.033 7.21178C14.7323 7.52919 14.2313 7.54273 13.9139 7.24203L11.2917 4.75784Z"
        fill={color}
      />
    </g>
  </svg>
);

UploadIcon.displayName = 'UploadIcon';
