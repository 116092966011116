import { FileSimpleDTO } from '@repositories/fileRepository/Types';

export class FileSimpleModel {
  private readonly dto: FileSimpleDTO;

  constructor(dto: FileSimpleDTO) {
    this.dto = dto;
  }

  get fileId() {
    return this.dto.fileId;
  }

  get originalName() {
    return this.dto.originalName;
  }

  get fileExtension() {
    return this.dto.fileExtension ?? '';
  }

  get fileName() {
    const splitted = this.originalName.split('.');
    if (splitted.length > 0 && splitted[splitted.length - 1] === this.fileExtension) {
      return splitted.slice(0, splitted.length - 1).join('.');
    }
    return this.originalName;
  }

  get creator() {
    return this.dto.creator;
  }

  get createdDate() {
    return this.dto.createdDate;
  }
}
