import dayjs, { Dayjs } from 'dayjs';
import i18n from '@locales/i18n';

export const dateFormat = (srcDate: Dayjs | string | undefined | null, fallback = '', format = ''): string => {
  if (!srcDate) return fallback;

  const date = typeof srcDate === 'string' ? dayjs(srcDate) : srcDate;

  let result;
  if (format) {
    result = date.format(format);
  } else {
    result = date.fromNow() ? date.format(i18n.t('DateFormat_MD')) : date.format(i18n.t('DateFormat_YMD'));
  }

  return result === 'Invalid Date' ? fallback : result;
};

export const dateTimeFormat = (
  srcDateTime: Dayjs | string,
  fallback = '',
  options: { fullFormat?: boolean; utcTime?: boolean } = { fullFormat: false, utcTime: false },
): string => {
  if (srcDateTime === undefined) {
    return fallback;
  }

  const mergedOptions = { fullFormat: false, utcTime: false, ...options };

  let dateTime: Dayjs;
  try {
    const utcDateTime = dayjs.utc(srcDateTime);
    const localDateTime = utcDateTime.local();

    dateTime = mergedOptions.utcTime ? utcDateTime : localDateTime;
  } catch {
    return fallback;
  }

  const returnVal =
    !mergedOptions.fullFormat && dateTime.fromNow()
      ? dateTime.format(i18n.t('DateFormat_MD_Time'))
      : dateTime.format(i18n.t('DateFormat_YMD_Time'));

  return returnVal === 'Invalid Date' ? fallback : returnVal;
};
