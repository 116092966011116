import styled from '@emotion/styled';
import ReactQuill, { Quill, ReactQuillProps } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module-ts';
import 'react-quill/dist/quill.snow.css';

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px', '24px'];
Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize);

const QuillWrapper: React.FC<ReactQuillProps> = ({ ...props }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ size: [...Size.whitelist] }],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'image'],
    ],
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  return <ReactQuill {...props} theme="snow" modules={modules} />;
};

export const Editor = styled(QuillWrapper)`
  width: 100%;
  min-width: 613px;
  height: 100%;
  border: ${({ readOnly }) => (readOnly ? 'none' : '1px solid #CCCCCC')};
  border-radius: 8px;
  ${({ theme }) => theme.breakpoints.between('sm', 'xl')} {
    min-width: 100%;
  } ;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    min-width: 100px;
  } ;
  & div.ql-toolbar {
    display: ${({ readOnly }) => (readOnly ? 'none' : 'block')};
    height: 43px;
    border: none;
    border-bottom: 1px solid #cccccc;

    & span.ql-formats {
      margin-right: 5px;
    }
  }

  & div.ql-container {
    border: none;
    font-family: 'Pretendard';
    font-size: 16px;
  }

  & div.ql-editor {
    height: ${({ readOnly }) => (readOnly ? '100%' : 'calc(100% - 43px)')};
    min-height: ${({ readOnly }) => (readOnly ? 'unset' : '100px')};
    overflow: auto;
    padding: 12px 0;
  }

  & .ql-editor ul,
  & .ql-editor ul li,
  & .ql-editor ol,
  & .ql-editor ol li {
    padding-left: 1em;
  }

  ${Array.from(Array(9).keys())
    .map(k => k + 1)
    .map(level => `& .ql-editor .ql-indent-${level} { padding-left: ${level * 1.5}em }`)}

  & .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }
}
`;
