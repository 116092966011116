import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Popover } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { XIcon } from '@icons/XIcon';
import { GroupIcon, MailIcon, NameIcon, PhoneIcon } from '@icons/index';
import { useGetSubscriptionUser } from '@queryHooks/useSubscription';

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isScreenWidthReduceCheck: boolean;
  subscriptionId: string;
  userLoginId: string;
  multi?: boolean;
};

export const LicenseUserInfoPopup = ({
  anchorEl,
  onClose,
  isScreenWidthReduceCheck,
  subscriptionId,
  userLoginId,
  multi,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: userInfo } = useGetSubscriptionUser(subscriptionId, userLoginId, {
    enabled: Boolean(anchorEl),
  });

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: isScreenWidthReduceCheck ? (multi ? -33 : -10) : multi ? 380 : 'right',
      }}
      transformOrigin={{ vertical: 'top', horizontal: isScreenWidthReduceCheck ? 'right' : 'left' }}
      PaperProps={{
        sx: {
          borderRadius: '6px',
          boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);',
        },
      }}
    >
      <UserContainer>
        <UserHeader>
          <UserTitle>{t('Subscrib_Detail_Overview_19')}</UserTitle>
          <IconButton onClick={onClose}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </IconButton>
        </UserHeader>
        <UserBody>
          <UserProperty>
            <PropertyKey>
              <MailIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <PropertyKeyText>{t('Member_User_14')}</PropertyKeyText>
            </PropertyKey>
            <PropertyValue>
              <PropertyValueText>{userInfo?.userLoginId}</PropertyValueText>
            </PropertyValue>
          </UserProperty>
          <UserProperty>
            <PropertyKey>
              <NameIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <PropertyKeyText>{t('Workflow_Main_05')}</PropertyKeyText>
            </PropertyKey>
            <PropertyValue>
              <PropertyValueText>{userInfo?.user?.name}</PropertyValueText>
            </PropertyValue>
          </UserProperty>
          <UserProperty>
            <PropertyKey>
              <GroupIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <PropertyKeyText>{t('Member_User_Bulk_Create_18')}</PropertyKeyText>
            </PropertyKey>
            <PropertyValue>
              <PropertyValueText>{userInfo?.userOrganization ? userInfo.userOrganization : '-'}</PropertyValueText>
            </PropertyValue>
          </UserProperty>
          <UserProperty>
            <PropertyKey>
              <PhoneIcon color={theme.colors['ic-gray-light']} width={18} height={18} />
              <PropertyKeyText>{t('Member_User_16')}</PropertyKeyText>
            </PropertyKey>
            <PropertyValue>
              <PropertyValueText>{userInfo?.user?.phone ? userInfo?.user?.phone : '-'}</PropertyValueText>
            </PropertyValue>
          </UserProperty>
        </UserBody>
      </UserContainer>
    </Popover>
  );
};

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 292px;
  padding: 18px 2px 0px 28px;
  border-radius: 6px;
`;

const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UserTitle = styled.span`
  ${fonts.Headline6}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const UserBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 0px 0px 32px;
`;

const UserProperty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
`;
const PropertyKey = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 129px;
  flex-shrink: 0;
  align-items: center;
`;
const PropertyValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;
const PropertyKeyText = styled.span`
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;
const PropertyValueText = styled.span`
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
`;
