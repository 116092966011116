import type { ConnectorType, ProductConnectorDTO } from '@repositories/productConnectorRepository/Types';
import { SoftwareSimpleModel } from '@models/softwareModels';
import { ConnectorActionModel } from './ConnectorActionModel';

export class ProductConnectorModel {
  private readonly _dto: ProductConnectorDTO;
  private readonly _product: SoftwareSimpleModel;

  private readonly _addUserSchema?: string;
  private readonly _actions: ConnectorActionModel[];
  private readonly _id: string;
  private readonly _name: string;
  private readonly _productCode: string;
  private readonly _targetClass: string;
  private readonly _type: 'JAVA' | 'JAVASCRIPT';
  private readonly _connectionInfoSchema: string;

  constructor(dto: ProductConnectorDTO) {
    this._dto = dto;
    this._product = new SoftwareSimpleModel(dto.product);

    this._addUserSchema = dto.addUserSchema;
    this._actions = dto.actions?.map(action => new ConnectorActionModel(action || {})) ?? [];
    this._id = dto.id || '';
    this._name = dto.name || '';
    this._productCode = dto.productCode || '';
    this._targetClass = dto.targetClass || '';
    this._type = dto.type || 'JAVASCRIPT';
    this._connectionInfoSchema = dto.connectionInfoSchema || '';
  }

  get connectorId(): string {
    return this._dto.connectorId || '';
  }

  get name(): string {
    return this._dto.name;
  }

  get description(): string | undefined {
    return this._dto.description;
  }

  get product() {
    return this._product;
  }

  get remoteConnectorId() {
    return this._dto.remoteConnectorId;
  }

  get usageCollectMethod() {
    return this._dto.usageCollectMethod;
  }

  get productCode(): string {
    return this._dto.productCode;
  }

  get type(): ConnectorType | undefined {
    return this._dto.type;
  }

  get targetClass(): string | undefined {
    return this._dto.targetClass;
  }

  get actions(): ConnectorActionModel[] {
    return this._actions;
  }

  get addUserSchema() {
    return this._addUserSchema;
  }

  get connectionInfoSchema() {
    return this._connectionInfoSchema;
  }

  get id() {
    return this._id;
  }

  get usageMethodLabelColor() {
    if (this._dto.usageCollectMethod === 'RPA') {
      return 'red';
    }
    return 'green';
  }
}
