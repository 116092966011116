import { Skeleton, TableCell, TableRow } from '@mui/material';

type SkeletonRowsProps = {
  colNum: number;
  rowNum: number;
};
export const SkeletonRows = ({ colNum, rowNum }: SkeletonRowsProps) => {
  return (
    <>
      {Array.from(new Array(rowNum).keys()).map(idx => (
        <SkeletonRow key={idx} colNum={colNum} />
      ))}
    </>
  );
};

type SkeletonRowProps = {
  colNum: number;
};
export const SkeletonRow = ({ colNum }: SkeletonRowProps) => (
  <TableRow>
    {Array.from(new Array(colNum).keys()).map(row => (
      <TableCell key={row}>
        <Skeleton />
      </TableCell>
    ))}
  </TableRow>
);
