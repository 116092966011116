import type { WorkflowOutletContext } from '../WorkflowDetail';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Loader } from '@components/loader';
import { useStore } from '@stores/RootStore';
import { useGetWorkflowExecution } from '@queryHooks/useWorkflow';
import type { WorkflowExecModel } from '@models/workflow/WorkflowExecModel';
import { ActionConfig } from '../action/ActionConfig';
import { ActionExecView } from '../action/ActionExecView';
import { WorkflowViewer } from '../graph/WorkflowViewer';
import { OptionBar } from '../info/OptionBar';
import { TriggerConfig } from '../trigger/TriggerConfig';
import { TriggerExecView } from '../trigger/TriggerExecView';

export const WorkflowExecDetailPage = observer(() => {
  const { workflowDetailStore } = useStore();
  const { workflow } = useOutletContext<WorkflowOutletContext>();
  const { workflowId = '', workflowExecId = '' } = useParams<{
    workflowId: string;
    workflowExecId: string;
  }>();
  const [isToolbarOpen, setIsToolbarOpen] = useState<boolean>(false);

  const { data: execution, isSuccess } = useGetWorkflowExecution(
    { workflowId, workflowExecId },
    {
      enabled: !!workflowId && !!workflowExecId,
      refetchInterval: (data?: WorkflowExecModel) => {
        if (data?.status === 'READY' || data?.status === 'RUNNING' || data?.status === 'STOP_REQUESTED') {
          return 1000;
        }
        return false;
      },
    },
  );

  useEffect(() => {
    setIsToolbarOpen(!!workflowDetailStore.toolbar);
  }, [workflowDetailStore.toolbar]);

  if (!isSuccess) return <Loader />;
  return (
    <Container>
      <Body>
        <WorkflowViewer workflow={workflow} execution={execution} />
        <OptionBar open={isToolbarOpen}>
          {workflowDetailStore.toolbar === 'triggerConfig' && <TriggerConfig />}
          {workflowDetailStore.toolbar === 'triggerExec' && <TriggerExecView />}
          {workflowDetailStore.toolbar === 'actionConfig' && <ActionConfig />}
          {workflowDetailStore.toolbar === 'actionExec' && <ActionExecView />}
        </OptionBar>
      </Body>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 4px;
  width: 100%;
  height: calc(100vh - 236px);
  box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;
  margin-top: 10px;
`;
