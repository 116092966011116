import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, useTheme } from '@mui/material';
import { CloseIcon, GlobeIcon, ListIcon, MobileLogoIcon } from '@icons/index';
import i18n from '@locales/i18n';
import * as S from './MobileGnbStyles';

export const MobileGnb = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const gnbItem = [
    {
      id: 0,
      name: t('GNB_Menu_01'),
      to: '/introduction',
    },
    {
      id: 1,
      name: t('GNB_Menu_02'),
      to: '/store',
    },
  ];
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleIntroduceInquiryClick = () => {
    setOpen(false);
    navigate('/tenant-request');
  };
  const [isLangOpen, setIsLangOpen] = useState<boolean>(false);
  const dimmedtRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const renderGnbList = () => (
    <S.MGnbListWrap onClick={() => setOpen(false)} role="presentation">
      {gnbItem.map(item => (
        <S.MGnbListItem key={item.id} onClick={() => navigate(item.to)}>
          <span>{item.name}</span>
        </S.MGnbListItem>
      ))}
    </S.MGnbListWrap>
  );

  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    document.cookie = `SMP_lang=${lang}; path=/; secure;`;

    setIsLangOpen(false);
  };
  const handleToggle = () => {
    setIsLangOpen(!isLangOpen);
  };

  return (
    <>
      <IconButton onClick={() => setIsLangOpen(true)}>
        <GlobeIcon
          width={26}
          height={26}
          color={isLangOpen ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
        />
      </IconButton>
      <IconButton onClick={() => setOpen(true)}>
        <ListIcon width={28} height={28} />
      </IconButton>
      <S.StyledDrawer open={open} anchor="right" onClose={() => setOpen(false)}>
        <S.MGnbWrap>
          <S.MGnbHeader>
            <S.MGnbLogoLinkWrap to="/">
              <MobileLogoIcon />
            </S.MGnbLogoLinkWrap>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon width={28} height={28} />
            </IconButton>
          </S.MGnbHeader>
          <S.IntroductionBtn onClick={handleIntroduceInquiryClick}>
            <span>{t('GNB_Menu_04')}</span>
          </S.IntroductionBtn>
          {renderGnbList()}
        </S.MGnbWrap>
      </S.StyledDrawer>
      {open ||
      pathname === '/tenant-request' ||
      (pathname !== '/store/softwares' && pathname !== '/store/hardwares') ? null : (
        <S.IntroductionBtn className="fixed" onClick={handleIntroduceInquiryClick}>
          <span>{t('GNB_Menu_04')}</span>
        </S.IntroductionBtn>
      )}
      {createPortal(
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isLangOpen && (
            <>
              <S.Languages>
                <S.Language
                  onClick={() => handleChangeLang('ko')}
                  className={i18n.language === 'ko' ? 'ko' : undefined}
                >
                  <span className={i18n.language === 'ko' ? 'ko' : undefined}>한국어</span>
                </S.Language>
                <S.Language
                  onClick={() => handleChangeLang('en')}
                  className={i18n.language === 'en' ? 'en' : undefined}
                >
                  <span className={i18n.language === 'en' ? 'en' : undefined}>English</span>
                </S.Language>
              </S.Languages>
              <S.Dimmed ref={dimmedtRef} onClick={handleToggle} />
            </>
          )}
        </>,
        document.body,
      )}
    </>
  );
};
