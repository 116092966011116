import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AutocompleteOption } from './AddUserDialog';

export type LinkUserForm = {
  userId: string;
};

export type AddUserForm = {
  user: AutocompleteOption;
  userLoginId: string;
  userRole?: string;
};

export const useLinkUserForm = () => {
  const linkUserValidationSchema: yup.SchemaOf<LinkUserForm> = yup.object().shape({
    userId: yup.string().required(),
  });

  const linkUserFormOptions: UseFormProps<LinkUserForm> = {
    resolver: yupResolver(linkUserValidationSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
  };
  return useForm<LinkUserForm>(linkUserFormOptions);
};

export const useAddUserForm = () => {
  const addUserValidationSchema: yup.SchemaOf<AddUserForm> = yup.object().shape({
    user: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
        email: yup.string(),
      })
      .required(),
    userLoginId: yup.string().required(),
    userRole: yup.string(),
  });

  const addUserFormOptions: UseFormProps<AddUserForm> = {
    resolver: yupResolver(addUserValidationSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
  };
  return useForm<AddUserForm>(addUserFormOptions);
};
