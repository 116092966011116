import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import type { Contract, ContractModulePrices } from '@repositories/contactRepository';
import type { Software } from '@repositories/softwareRepository';
import { ObjectUtil } from '@utils/ObjectUtil';

export class ContractModel
  implements
    Omit<Contract, 'createdBy' | 'createdDate' | 'deleted' | 'tenant' | 'updatedBy' | 'updatedDate' | 'version'>
{
  id: string;

  software: Software;

  startDate: DateTimeString;

  endDate: DateTimeString;

  // createdBy: string;

  // createDate: Date;

  currencyUnit: CurrencyUnit;

  // deleted: boolean;

  status: Contract['status'];

  contractModulePrices: ContractModulePrices;

  // tenant: Tenant;

  // updatedBy: string;

  updatedDate: Date;

  // version: number;

  timeZone: string;

  createdDate: Date;

  properties: { [key: string]: string };

  constructor(dto?: Contract) {
    this.id = '';
    this.software = {
      id: '',
      productCode: '',
      name: '',
      planVisibilityScope: 'ALL_USERS',
    };
    this.startDate = '';
    this.endDate = '';
    this.currencyUnit = 'KRW';
    this.status = 'IN_REVIEW';
    this.contractModulePrices = {};
    this.updatedDate = new Date();
    this.timeZone = '';
    this.createdDate = new Date();
    this.properties = {};

    if (dto) {
      this.update(dto);
    }
    makeAutoObservable(this);
  }

  update = (dto: Partial<Contract>) => {
    ObjectUtil.update(this, dto);
  };

  get statusUpdatedDate() {
    return `${this.status} on ${dayjs(this.updatedDate).format('YYYY.MM.DD')}`;
  }

  get softwareId(): string {
    return this.software.id;
  }

  get additionalPropertiesArr(): { key: string; value: string }[] {
    return ObjectUtil.keys(this.properties).map(key => ({ key, value: this.properties[key] }));
  }
}
