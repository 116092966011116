const defaultWidth = 24;
const defaultHeight = 24;

export const SquareFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M7.75 6C6.7835 6 6 6.7835 6 7.75V16.25C6 17.2165 6.7835 18 7.75 18H16.25C17.2165 18 18 17.2165 18 16.25V7.75C18 6.7835 17.2165 6 16.25 6H7.75Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SquareFillIcon.displayName = 'SquareFillIcon';
