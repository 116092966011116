// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const TriangleIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  rotateNum = 0,
  className = '',
  ...props
}: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      style={{ transform: `rotate(${rotateNum}deg)`, ...props.style, transition: 'transform 200ms' }}
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M6.64013 12.8598L11.7348 7.76517C11.8813 7.61873 12.1187 7.61873 12.2652 7.76517L17.3598 12.8598C17.596 13.0961 17.4288 13.5 17.0946 13.5H6.9053C6.5712 13.5 6.40389 13.0961 6.64013 12.8598Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

TriangleIcon.displayName = 'TriangleIcon';
