import { Button, Label } from '@components/index';
import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
`;

export const Header = styled(Box)`
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const RequestWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 0px 24px 40px 24px;
`;

export const Title = styled('strong')`
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 2px;
`;

export const RequestContents = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  }
  & div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 50%;
    padding: 14px 0 15px 20px;
    &:first-of-type {
      padding: 14px 0 15px;
      border-right: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
    }
    &.inquiry {
      width: 100%;
      border-right: none;
      padding-right: 20px;
    }
  }
  & .content-name {
    ${fonts.Headline9};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  & .content-value {
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

export const Subscribing = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  ${fonts.Caption5};
  color: ${({ theme: { colors } }) => colors['text-gradient']};
`;

export const RequestInquiry = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & .content-name {
    ${fonts.Body5};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    width: 100px;
  }
  & .content-value {
    ${fonts.Body6};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    width: 300px;
  }
  padding: 15px 0px;
`;

export const RequestReplyWrapper = styled.form`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
`;

export const Buttons = styled(Box)`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 24px;
  justify-content: space-between;
  & button {
    position: relative;
    width: 33.333%;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
    &.contain {
      gap: 4px;
      color: ${({ theme }) => theme.colors['text-white']};
      background-color: ${({ theme }) => theme.colors['bg-purple']};
    }
    &:after {
      right: 0;
    }
    &:before {
      left: 0;
    }
    &:after,
    &:before {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background-color: ${({ theme }) => theme.colors['border-gray-light']};
    }
    &:first-of-type {
      border-radius: 3px 0 0 3px;
      border-width: 1px 0 1px 1px;
      &:after,
      &:before {
        content: none;
      }
    }
    &:last-of-type {
      border-radius: 0 3px 3px 0;
      border-width: 1px 1px 1px 0;
      &:after,
      &:before {
        content: none;
      }
    }
    &.approved {
      &:before {
        content: none;
      }
    }
    &.pending {
      &:after {
        content: none;
      }
    }
    &.rejected {
      &:after,
      &:before {
        content: none;
      }
    }
  }
`;

export const SubTitle = styled('strong')`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 4px;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 8px;
`;

export const PendingDescription = styled('p')`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

export const StylredLabel = styled(Label)`
  padding: 2px 8px;
  ${fonts.Caption5};
  margin-left: 4px;
`;

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 6px 12px;
  }
  & .MuiInputBase-input {
    ${fonts.Body2};
  }
`;

export const StyledButton = styled(Button)`
  ${fonts.Button3};
`;
