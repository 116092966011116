import { Modal, Select, Box, MenuItem, TextField } from '@mui/material';
import { XIcon } from '@icons/XIcon';
import { useTheme } from '@emotion/react';
import { ManagerIcon } from '@icons/ManagerIcon';
import { SubscriptionRequestModel } from '@models/index';
import { useGetProductPlanList } from '@queryHooks/useSoftware';
import { useEffect } from 'react';
import { Label } from '@components/Label';
import { Controller } from 'react-hook-form';
import { SubscriptionRequestUpdateDTO } from '@repositories/subscriptionRequestRepository';
import { useUpdateSubscriptionRequestMutation } from '@queryHooks/useSubscriptionRequest';
import { useStore } from '@stores/RootStore';
import styled from '@emotion/styled';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { useTranslation } from 'react-i18next';
import { FormRequest, useItAdminForm } from './validationSchema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  subscriptionRequestData: SubscriptionRequestModel;
}

export const SubscriptionRequestUpdateModal = ({ isOpen, onClose, subscriptionRequestData }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uiStore: { toastStore },
  } = useStore();

  const { data: subscriptionRequestPlans } = useGetProductPlanList(subscriptionRequestData.software.id);
  const { mutate: updateSubscription } = useUpdateSubscriptionRequestMutation();

  const { handleSubmit, reset, control } = useItAdminForm();

  const validation = {
    success: (data: FormRequest) => {
      const rqData: SubscriptionRequestUpdateDTO = {
        id: subscriptionRequestData.id,
        productPlanId: data.productPlanId,
        inquiry: data.inquiry,
        status: subscriptionRequestData.status,
        managerId: subscriptionRequestData.managerId,
        startDate: subscriptionRequestData.startDate,
        endDate: subscriptionRequestData.endDate,
        timeZone: subscriptionRequestData.timeZone,
      };
      updateSubscription(rqData, {
        onSuccess: () => {
          toastStore.open(t('Subscrib_Detail_BuyRequest_08'));
          onClose();
        },
      });
    },
  };

  const MenuProps = {
    PaperProps: {
      style: {
        width: 352,
        marginTop: 7,
        boxShadow: 'none',
        borderRadius: '0 0 2px 2px',
      },
    },
  };

  useEffect(() => {
    if (subscriptionRequestData.productPlanId && subscriptionRequestData.inquiry) {
      reset({
        productPlanId: subscriptionRequestData.productPlanId,
        inquiry: subscriptionRequestData.inquiry,
      });
    }
  }, [reset, subscriptionRequestData]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper onSubmit={handleSubmit(validation.success)}>
        <Header>
          {t('Subscrib_Detail_BuyRequest_09')}
          <IconButton onClick={() => onClose()}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </IconButton>
        </Header>
        <Body>
          <Element className="info-banner">
            <ManagerIcon width={16} height={16} color={theme.colors['ic-purple-light']} />
            <BannerText>{t('Subscrib_Detail_BuyRequest_10')}</BannerText>
          </Element>
          <Element>
            <NameStrong>
              {t('Subscrib_Detail_UseRequest_07')}
              <EssentialMark>*</EssentialMark>
            </NameStrong>
            <SoftwareNameBox>
              <SoftwareName>{subscriptionRequestData.software.name}</SoftwareName>
            </SoftwareNameBox>
          </Element>
          <Element>
            <NameStrong>
              {t('Subscrib_Main_12')}
              <EssentialMark>*</EssentialMark>
            </NameStrong>
            <Controller
              control={control}
              name="productPlanId"
              render={({ field: { onChange, value = '' } }) => (
                <Select
                  size="large"
                  fullWidth
                  displayEmpty
                  value={value}
                  onChange={onChange}
                  renderValue={(value: string) => {
                    return value !== '' ? (
                      <strong>{subscriptionRequestPlans?.find(plan => plan.planId === value)?.planName}</strong>
                    ) : (
                      <strong>{subscriptionRequestData.productPlanName}</strong>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {subscriptionRequestPlans?.map(plan => {
                    return (
                      <SelectItem key={plan.planId} value={plan.planId}>
                        {plan.planName}
                        {plan.isInUseByTenant ? (
                          <Label variant="contain" color="gradient">
                            {t('Ad_Store_Product_State')}
                          </Label>
                        ) : null}
                      </SelectItem>
                    );
                  })}
                </Select>
              )}
            />
          </Element>
          <Element>
            <NameStrong>{t('Acc_Main_22')}</NameStrong>
            <Controller
              control={control}
              name="inquiry"
              render={({ field: { onChange, value } }) => (
                <StyledTextField multiline rows={7} value={value} onChange={onChange} />
              )}
            />
          </Element>
        </Body>
        <SubmitButton variant="contain" size="extraLarge" type="submit">
          {t('Member_Manager_11')}
        </SubmitButton>
      </ModalWrapper>
    </Modal>
  );
};
const ModalWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  overflow: hidden;
  padding: 18px 20px 28px 28px;
  z-index: 1050;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 6px 30px 5px rgba(0, 0, 0, 0.05);
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${fonts.Headline6};
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Element = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  &:first-of-type {
    margin-bottom: 16px;
  }
  &.info-banner {
    align-items: center;
    justify-content: flex-start;
    flex-direction: initial;
    gap: 6px;
    background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
    border-radius: 3px;
    padding: 12px 20px;
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
  & .MuiInputBase-root textarea::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
  & .MuiSelect-select strong {
    ${fonts.Button4};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

const BannerText = styled.p`
  ${fonts.Body2};
  color: ${({ theme }) => theme.colors['text-purple']};
`;

const SoftwareNameBox = styled.div`
  padding: 14px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
`;

const SoftwareName = styled.span`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const NameStrong = styled.strong`
  ${fonts.Headline8};
  margin-bottom: 6px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const EssentialMark = styled.span`
  margin-left: 2px;
  color: ${({ theme: { colors } }) => colors['ic-red-light']};
`;

const SelectItem = styled(MenuItem)`
  gap: 4px;
  padding: 10px 10px 10px 7px;
`;

const StyledTextField = styled(TextField)`
  background: ${({ theme }) => theme.colors['bg-gray-lighter']};
  & .MuiInputBase-root {
    padding: 10px 12px 21px;
  }
`;

const SubmitButton = styled(Button)`
  ${fonts.Button2}
  width: 100%;
  background: ${({ theme }) => theme.colors['bg-purple']};
`;
