import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import qs from 'qs';
import IRecommendationRepository from './IRecommendationRepository';
import { RecommendationRs, RecommendationCreateRq, RecommendationFilter } from './Types';

@injectable()
export class RecommendationRepository implements IRecommendationRepository {
  async create(data: RecommendationCreateRq): Promise<RecommendationRs> {
    const result = await API.post<RecommendationRs>('/api/v1/recommendations', data);
    return result.data;
  }
  async getList(filter: RecommendationFilter): Promise<Page<RecommendationRs>> {
    const queryString = qs.stringify(filter, { skipNulls: true });
    const result = await API.get<Page<RecommendationRs>>(`/api/v1/recommendations?${queryString}`);
    return result.data;
  }
  async delete(id: string, tenantId?: string): Promise<void> {
    if (tenantId) {
      await API.delete(`/api/v1/recommendations/${id}/tenants/${tenantId}`);
    } else {
      await API.delete(`/api/v1/recommendations/${id}`);
    }
  }
}
