import { Button } from '@components/index';
import { RoutingTabs } from '@components/tab';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 28px 32px 32px;
`;

export const GroupDetailHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 32px;
`;

export const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const HeaderTitle = styled(Typography)`
  & > span {
    margin-left: 2px;
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
` as typeof Typography;

export const StyledRoutingTabs = styled(RoutingTabs)`
  gap: 8px;
`;
