import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useUiStore } from '@stores/UiStore';
import { KEYS, repository } from '@repositories/Repository';
import IFileRepository from '@repositories/fileRepository/IFileRepository';

const fileRepo = repository.get<IFileRepository>(KEYS.FILE_REPOSITORY);

export const useDownloadFile = () => {
  const { toastStore } = useUiStore();

  return useMutation<Blob, AxiosError, string>({
    mutationFn: async (fileId: string) => {
      const result = await fileRepo.downloadFile(fileId);
      return result;
    },
    onError: err => {
      if (err.response?.status === 404) {
        toastStore.open('삭제된 파일입니다.');
      }
    },
  });
};
