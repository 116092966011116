import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import authStore from '@stores/AuthStore';
import { KEYS, repository } from '@repositories/Repository';
import { IGoogleOauthRepository } from '@repositories/googleOauthRepository/IGoogleOauthRepository';
import { AuthCodeDto } from '@repositories/googleOauthRepository/Types';

const googleOauthRepo = repository.get<IGoogleOauthRepository>(KEYS.GOOGLE_OAUTH_REPOSITORY);

export const useGoogleSignIn = () => {
  const { tenantId } = useParams();

  return useMutation({
    mutationFn: async (rqData: AuthCodeDto) => {
      const result = await googleOauthRepo.signIn(rqData);
      return result;
    },
    onSuccess: user => {
      const result = authStore.login(user.id, user.name, user.additionalPermissions, tenantId);
      if (!result) {
        throw Error('로그인 실패, authStore의 login');
      }
    },
    cacheTime: 0,
  });
};
