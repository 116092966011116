const defaultWidth = 24;
const defaultHeight = 24;

export const CartIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.0534 17.0639C7.64317 17.0639 6.49995 18.2071 6.49995 19.6173C6.49995 21.0276 7.64317 22.1708 9.0534 22.1708C10.4636 22.1708 11.6068 21.0276 11.6068 19.6173C11.6068 18.2071 10.4636 17.0639 9.0534 17.0639ZM8.08485 19.6173C8.08485 19.0824 8.51848 18.6488 9.0534 18.6488C9.58831 18.6488 10.0219 19.0824 10.0219 19.6173C10.0219 20.1523 9.58831 20.5859 9.0534 20.5859C8.51848 20.5859 8.08485 20.1523 8.08485 19.6173Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4703 17.0639C16.0601 17.0639 14.9169 18.2071 14.9169 19.6173C14.9169 21.0276 16.0601 22.1708 17.4703 22.1708C18.8806 22.1708 20.0238 21.0276 20.0238 19.6173C20.0238 18.2071 18.8806 17.0639 17.4703 17.0639ZM16.5018 19.6173C16.5018 19.0824 16.9354 18.6488 17.4703 18.6488C18.0053 18.6488 18.4389 19.0824 18.4389 19.6173C18.4389 20.1523 18.0053 20.5859 17.4703 20.5859C16.9354 20.5859 16.5018 20.1523 16.5018 19.6173Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.68249 4.20002H1.19995V2.40002H3.68249C4.58618 2.40002 5.36483 3.03654 5.54454 3.92217L5.80385 5.20002H20.2533C21.4689 5.20002 22.3718 6.32562 22.1081 7.51219L20.5525 14.5122C20.3594 15.3815 19.5883 16 18.6978 16H7.80808C6.91241 16 6.13837 15.3745 5.9504 14.4988L4.18605 6.27902L3.78049 4.28014C3.77104 4.23352 3.73005 4.20002 3.68249 4.20002ZM6.18181 7.00002L7.71031 14.121C7.7202 14.1671 7.76094 14.2 7.80808 14.2H18.6978C18.7446 14.2 18.7852 14.1675 18.7954 14.1217L20.351 7.12172C20.3648 7.05927 20.3173 7.00002 20.2533 7.00002H6.18181Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CartIcon.displayName = 'CartIcon';
