import { ObjectUtil } from '@utils/ObjectUtil';

const kebabFonts = {
  Headline1: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '56px',
    'line-height': '72px',
    'letter-spacing': '1px',
  },
  Headline2: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '48px',
    'line-height': '60px',
    'letter-spacing': '1px',
  },
  Headline3: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '32px',
    'line-height': '40px',
    'letter-spacing': '1px',
  },
  Headline4: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '24px',
    'line-height': '32px',
    'letter-spacing': '0.25px',
  },
  Headline5: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '20px',
    'line-height': '28px',
    'letter-spacing': '0.25px',
  },
  Headline6: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '18px',
    'line-height': '26px',
    'letter-spacing': '0.25px',
  },
  Headline7: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '16px',
    'line-height': '24px',
    'letter-spacing': '0.25px',
  },
  Headline8: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Headline9: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '13px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Headline10: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '12px',
    'line-height': '18px',
    'letter-spacing': '0.15px',
  },
  Headline11: {
    'font-family': 'Pretendard',
    'font-style': 'normal',
    'font-weight': '700',
    'font-size': '40px',
    'line-height': '52px',
  },
  Headline12: {
    'font-family': 'Pretendard',
    'font-style': 'normal',
    'font-weight': '700',
    'font-size': '17px',
    'line-height': '24px',
    'letter-spacing': '0.25px',
  },
  Display1: {
    'font-family': 'Spartan',
    'font-weight': '700',
    'font-size': '32px',
    'line-height': '40px',
  },
  Display2: {
    'font-family': 'Spartan',
    'font-weight': '500',
    'font-size': '32px',
    'line-height': '40px',
  },
  Display3: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '18px',
    'line-height': '25px',
    'letter-spacing': '1px',
  },
  Subtitle1: {
    'font-family': 'Spartan',
    'font-weight': '800',
    'font-size': '14px',
    'line-height': '20px',
  },
  Subtitle2: {
    'font-family': 'Spartant',
    'font-weight': '800',
    'font-size': '13px',
    'line-height': '20px',
  },
  Subtitle3: {
    'font-family': 'Spartant',
    'font-weight': '800',
    'font-size': '12px',
    'line-height': '18px',
  },
  Subtitle4: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '32px',
    'line-height': '40px',
    'letter-spacing': '1px',
  },
  Button1: {
    'font-family': 'Pretendard',
    'font-weight': '800',
    'font-size': '16px',
    'line-height': '24px',
    'letter-spacing': '1px',
  },
  Button2: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '16px',
    'line-height': '24px',
    'letter-spacing': '1px',
  },
  Button3: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '1px',
  },
  Button4: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Button5: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '13px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Button6: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '13px',
    'line-height': '20px',
    'letter-spacing': '1px',
  },
  Body1: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    'letter-spacing': '0.15px',
  },
  Body2: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Body3: {
    'font-family': 'Pretendard',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Body4: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '13px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Body5: {
    'font-family': 'Pretendard',
    'font-weight': '400',
    'font-size': '13px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Body6: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '12px',
    'line-height': '18px',
    'letter-spacing': '0.15px',
  },
  Body7: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '15px',
    'line-height': '22px',
    'letter-spacing': '0.15px',
  },
  Caption1: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '11px',
    'line-height': '16px',
    'letter-spacing': '0.15px',
  },
  Caption2: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '11px',
    'line-height': '16px',
    'letter-spacing': '0.15px',
  },
  Caption3: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '10px',
    'line-height': '14px',
    'letter-spacing': '0.15px',
  },
  Caption4: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '12px',
    'line-height': '18px',
    'letter-spacing': '0.15px',
  },
  Caption5: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '12px',
    'line-height': '18px',
    'letter-spacing': '0.15px',
  },

  Underline1: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    'letter-spacing': '0.15px',
    'text-decoration': 'underline',
  },
  Underline2: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
    'text-decoration': 'underline',
  },
  Underline3: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
    'text-decoration': 'underline',
  },
  Paragraph1: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '16px',
    'line-height': '24px',
    'letter-spacing': '0.15px',
  },
  Paragraph2: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Paragraph3: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '13px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
  Paragraph4: {
    'font-family': 'Pretendard',
    'font-weight': '500',
    'font-size': '12px',
    'line-height': '18px',
    'letter-spacing': '0.15px',
  },
  Paragraph5: {
    'font-family': 'Pretendard',
    'font-weight': '700',
    'font-size': '14px',
    'line-height': '20px',
    'letter-spacing': '0.15px',
  },
};

const camelize = (s: string) => s.replace(/-./g, x => x[1].toUpperCase());

type KebabPreset = {
  'font-family': string;
  'font-weight': string;
  'font-size': string;
  'line-height': string;
  'letter-spacing'?: string;
  'text-decoration'?: string;
};

export const fonts = ObjectUtil.keys(kebabFonts).reduce((accum, presetKey) => {
  const kebabPreset: KebabPreset = kebabFonts[presetKey];

  const camelPreset = ObjectUtil.keys(kebabPreset).reduce((preset, cssKey) => {
    if (kebabPreset[cssKey]) {
      return {
        ...preset,
        [camelize(cssKey)]: kebabPreset[cssKey],
      };
    }
    return preset;
  }, {});

  return {
    ...accum,
    [presetKey]: camelPreset,
  };
}, {} as { [key in keyof typeof kebabFonts]: { [key in KebabToCamel<keyof KebabPreset>]: string } });
