const defaultWidth = 24;
const defaultHeight = 24;

export const ClockIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M12.5 7.25C12.5 6.83579 12.1642 6.5 11.75 6.5C11.3358 6.5 11 6.83579 11 7.25V12.75C11 13.0191 11.1442 13.2677 11.3779 13.4012L14.8779 15.4012C15.2375 15.6067 15.6957 15.4817 15.9012 15.1221C16.1067 14.7625 15.9817 14.3043 15.6221 14.0988L12.5 12.3148V7.25Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.799805 12C0.799805 5.8144 5.81422 0.799988 11.9998 0.799988C18.1854 0.799988 23.1998 5.8144 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12ZM11.7498 6.29999C12.2745 6.29999 12.6998 6.72532 12.6998 7.24999V12.1987L15.7211 13.9252C16.1767 14.1855 16.3349 14.7658 16.0746 15.2213C15.8143 15.6769 15.234 15.8351 14.7785 15.5748L11.2785 13.5748C10.9825 13.4057 10.7998 13.0909 10.7998 12.75V7.24999C10.7998 6.72532 11.2251 6.29999 11.7498 6.29999ZM11.9998 2.49999C6.7531 2.49999 2.4998 6.75328 2.4998 12C2.4998 17.2467 6.7531 21.5 11.9998 21.5C17.2465 21.5 21.4998 17.2467 21.4998 12C21.4998 6.75328 17.2465 2.49999 11.9998 2.49999Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ClockIcon.displayName = 'ClockIcon';
