import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores/RootStore';

export const withLogin = (WrappedComponent: React.ComponentType) => {
  const Component = observer((props: UnknownObject) => {
    const { authStore, uiStore } = useStore();
    const location = useLocation();
    const navigate = useNavigate();

    const { tenantId } = useParams();

    useEffect(() => {
      if (!authStore.isLogin) {
        navigate(uiStore.isItAdminTypeUi ? `/manager/${tenantId}/login` : '/login', {
          state: { routeAfterLogin: location.pathname },
        });
      }
    }, [authStore.isLogin, location, navigate, tenantId, uiStore.isItAdminTypeUi]);

    return authStore.isLogin ? <WrappedComponent {...props} /> : null;
  });

  return Component;
};
