import type IFileRepository from './IFileRepository';
import { injectable } from 'inversify';
import API from '@repositories/Axios';

@injectable()
export class FileRepository implements IFileRepository {
  async downloadFile(fileId: string): Promise<Blob> {
    const result = await API.get<Blob>(`/api/v1/files/${fileId}/download`, { responseType: 'blob' });
    return result.data;
  }
}
