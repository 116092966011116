import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { FormItemContainer } from '@components/form/FormItemContainer';
import { FormVerticalContainer } from '@components/form/FormVerticalContainer';
import { MessageText } from '@components/text/MessageText';
import { MessageTextArea } from '@components/text/MessageTextArea';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getActionInputOutputText = (output: any) => {
  if (typeof output === 'undefined') {
    return undefined;
  }

  if (typeof output !== 'string' && typeof output !== 'number') {
    return JSON.stringify(output, null, 2);
  }
  return output.toString();
};

export const ActionExecView = observer(() => {
  const { t } = useTranslation();
  const { workflowDetailStore } = useStore();

  const actionNode = workflowDetailStore.findNode(workflowDetailStore.curNodeId ?? '');
  const actionIndex = actionNode?.data?.actionIndex ?? 0;

  const actionExecution = workflowDetailStore.execution?.getActionExecution(actionIndex);

  const actionOutputText = getActionInputOutputText(actionExecution?.actionOutput);
  const actionInputText = getActionInputOutputText(actionExecution?.actionArguments);

  return (
    <Container>
      <ToolbarName>{t('Workflow.actionExec.exec')}</ToolbarName>
      <Body>
        {actionExecution ? (
          <FormVerticalContainer>
            <FormItemContainer>
              <NameText>{t('Workflow.actionExec.input')}</NameText>
              <MessageTextArea value={actionInputText} readOnly />
            </FormItemContainer>
            <FormItemContainer>
              <NameText>{t('Workflow.actionExec.output')}</NameText>
              <MessageTextArea value={actionOutputText} readOnly />
            </FormItemContainer>
            <FormItemContainer>
              <NameText>{t('Workflow.statusMsg')}</NameText>
              <MessageText>{actionExecution.statusMessage}</MessageText>
            </FormItemContainer>
          </FormVerticalContainer>
        ) : (
          <MessageText>{t('Workflow.actionExec.noExecutionHistory')}</MessageText>
        )}
      </Body>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: inherit;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  border: 0px;
  padding: 28px 0 20px;
  position: relative;
`;

const ToolbarName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 18px;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const NameText = styled.span`
  ${fonts.Headline8}
`;
