import { AnnualAchievement, AnnualAchievementBySoftware, OverallProfitSummaryStat } from '@type/Statistics';
import { makeAutoObservable } from 'mobx';

export class SoftwareProfitSummaryStatModel {
  softwareProfitSummaryStat: Map<string, AnnualAchievement>;

  constructor() {
    this.softwareProfitSummaryStat = new Map<string, AnnualAchievement>();

    makeAutoObservable(this);
  }

  update = (softwareId: string, dto: OverallProfitSummaryStat) => {
    this.softwareProfitSummaryStat.set(softwareId, {
      expectedPurchase: dto.purchaseAmount ?? 0,
      purchase: dto.purchaseAmount ?? 0,
      purchaseAchievementRate: dto.purchaseAchievementRate ?? 0,
      expectedSales: dto.salesAmount ?? 0,
      sales: dto.salesAmount ?? 0,
      salesAchievementRate: dto.salesAchievementRate ?? 0,
    });
  };

  get annualAchievementBySoftware(): AnnualAchievementBySoftware[] {
    return Array.from(this.softwareProfitSummaryStat, ([key, value]) => {
      return { softwareId: key, annualAchievement: value };
    }).sort((prev, next) => {
      return next.annualAchievement.sales - prev.annualAchievement.sales;
    });
  }
}
