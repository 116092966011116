import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Typography, useTheme } from '@mui/material';
import { ChevronIcon } from '@icons/ChevronIcon';

interface Props {
  title: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const GoBackHeader = ({ title, onClick }: Props) => {
  const { colors } = useTheme();

  const handleGoBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <GoBackRow to=".." onClick={handleGoBack}>
      <ChevronIcon rotateNum={-90} />
      <Typography variant="headline6" component="strong" color={colors['text-gray-main']}>
        {title}
      </Typography>
    </GoBackRow>
  );
};

const GoBackRow = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 14px;
  text-decoration: none;
`;
