import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useGetTenantBillingStat } from '@queryHooks/useTenantBillingStat';
import { AnalyzeElement } from './AnalyzeElement';

export const CostSummary = () => {
  const { data: tenantBillingStat, isLoading } = useGetTenantBillingStat({ month: dayjs().format('YYYY-MM') });

  return (
    <Container>
      {isLoading ? (
        <>
          <AnalyzeElement.Skeleton title="올해 총 지불 예정 금액" />
          <AnalyzeElement.Skeleton title="이번 달 지불 예정 금액" />
        </>
      ) : (
        <>
          <AnalyzeElement
            title="올해 총 지불 예정 금액"
            value={tenantBillingStat?.annualPaymentEstimate ?? 0}
            criteria="전년 대비"
            variations={0} // FIXME: API 필요
          />
          <AnalyzeElement
            title="이번 달 지불 예정 금액"
            value={tenantBillingStat?.monthlyPaymentEstimate ?? 0}
            criteria="전월 대비"
            variations={tenantBillingStat?.monthlyPaymentEstimateGrowthRate ?? 0}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border: 1px solid ${props => props.theme.colors['border-gray-dark']};
  border-radius: 5px;
  margin-bottom: 56px;
  overflow: hidden;
`;
