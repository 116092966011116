const defaultWidth = 24;
const defaultHeight = 24;

export const RefreshIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      {/* 위쪽 화살표 */}
      <path
        d="M16.5 5.5A8 8 0 0 0 5.5 10"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 5.5L18.5 7.5L15.5 7.5" fill={color} />

      {/* 아래쪽 화살표 */}
      <path
        d="M7.5 18.5A8 8 0 0 0 18.5 14"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 18.5L5.5 16.5L8.5 16.5" fill={color} />
    </svg>
  );
};
