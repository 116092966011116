import API from '@repositories/Axios';
import { Page } from '@type/Page';
import qs from 'qs';
import { injectable } from 'inversify';
import { MonthlyFilters, MonthlyUsageDTO } from '../Types';
import 'reflect-metadata';
import IMonthlyUsageRepository from './IMonthlyUsageRepository';

@injectable()
export class MonthlyUsageRepository implements IMonthlyUsageRepository {
  async create(/* data: unknown */): Promise<MonthlyUsageDTO> {
    throw new Error('Method not implemented.');
  }
  async getList(queries?: MonthlyFilters): Promise<Page<MonthlyUsageDTO>> {
    const queryString = qs.stringify(queries);

    const result = await API.get<Page<MonthlyUsageDTO>>(`/api/v1/monthly-usages?${queryString}`);
    return result.data;
  }
}
