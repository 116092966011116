import type { TechnicalInquiryListDTO, TechnicalStatus } from '../technicalSupport.types';

export class TechnicalInquiryListDTOModel {
  private readonly _dto: TechnicalInquiryListDTO;

  constructor(dto: TechnicalInquiryListDTO) {
    this._dto = dto;
  }

  get originDTO(): TechnicalInquiryListDTO {
    return this._dto;
  }

  get account(): string {
    return this._dto.account ?? '';
  }

  get categoryId(): string {
    return this._dto.categoryId ?? '';
  }

  get categoryName(): string {
    return this._dto.categoryName ?? '';
  }

  get createdDate(): string {
    return this._dto.createdDate ?? '';
  }

  get planId(): string {
    return this._dto.planId ?? '';
  }

  get planName(): string {
    return this._dto.planName ?? '';
  }

  get productId(): string {
    return this._dto.productId ?? '';
  }

  get productIconUrl(): string {
    return this._dto.productIconUrl ?? '';
  }

  get productName(): string {
    return this._dto.productName ?? '';
  }

  get status(): TechnicalStatus {
    return this._dto.status ?? 'CREATED';
  }

  get technicalInquiryId(): string {
    return this._dto.technicalInquiryId ?? '';
  }

  get userId(): string {
    return this._dto.userId ?? '';
  }

  get userName(): string {
    return this._dto.userName ?? '';
  }

  get conversionStatus(): '요청' | '답변 완료' | '진행중' {
    switch (this._dto.status) {
      case 'CREATED':
        return '요청';
      case 'IN_PROGRESS':
        return '진행중';
      case 'COMPLETED':
        return '답변 완료';
      default:
        return '요청';
    }
  }

  get conversionStatusColor(): 'pink' | 'green' | 'gray' {
    switch (this._dto.status) {
      case 'CREATED':
        return 'pink';
      case 'IN_PROGRESS':
        return 'green';
      case 'COMPLETED':
        return 'gray';
      default:
        return 'pink';
    }
  }
}
