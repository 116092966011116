import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  TextField,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon, SearchIcon } from '@icons/index';
import type { TenantMemberFilter } from '@repositories/tenantRepository';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import { UserTableRow } from './UserTableRow';

type SearchType = 'name' | 'email' | 'usergroup' | 'phone';

const StopUsers = () => {
  const { t } = useTranslation();
  const tableHeader: TableHeader[] = [
    { name: t('Member_User_Delete_Tab_15'), width: '200' },
    { name: t('Workflow_Main_05'), width: '373' },
    { name: t('Member_User_14'), width: '300' },
    { name: t('Member_User_Bulk_Create_18'), width: '255' },
    { name: t('Member_User_16'), width: '200' },
    { name: t('Member_User_18'), width: '200' },
    { name: t('Acc_Main_23'), width: '72' },
  ];
  const { authStore } = useStore();
  const theme = useTheme();
  const [searchType, setSearchType] = useState<SearchType>('name');
  const [filters, setFilters] = useState<TenantMemberFilter>({
    tenantMemberStatus: 'BLOCKED',
    isDeleted: false,
    sort: ['updatedDate.desc'],
  });

  const debounceOnChangeSearch = _.debounce((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (searchType === 'name') {
      setFilters(prev => {
        return { ...prev, name: e.target.value };
      });
    }
    if (searchType === 'email') {
      setFilters(prev => {
        return { ...prev, username: e.target.value };
      });
    }
  }, 500);

  const {
    data: pagedCreatedMembers,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetTenantMembersInfinite(authStore.curTenant.id, filters);
  const members = pagedCreatedMembers?.pages.map(({ content }) => content).flat(1);

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  return (
    <Container>
      <UserTableWrapper>
        <UserTableHeader>
          <Search>
            <Select
              size="small"
              value={searchType}
              onChange={e => setSearchType(e.target.value as SearchType)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxWidth: '79px',
                    width: '100%',
                    marginTop: '5px',
                    marginLeft: '1px',
                  },
                },
              }}
            >
              <MenuItem value="name">{t('Workflow_Main_05')}</MenuItem>
              <MenuItem value="email">{t('Member_User_14')}</MenuItem>
              <MenuItem value="usergroup">{t('Member_User_Bulk_Create_18')}</MenuItem>
              <MenuItem value="phone">{t('Member_User_16')}</MenuItem>
            </Select>
            <StyledTextField
              fullWidth
              size="small"
              placeholder={t('Member_User_08')}
              onChange={e => debounceOnChangeSearch(e)}
              InputProps={{
                startAdornment: (
                  <IconWrapper>
                    <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                  </IconWrapper>
                ),
              }}
            />
          </Search>
        </UserTableHeader>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map(header => {
                  return (
                    <StyledTableCell width={header.width} key={header.name}>
                      {header.name}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {members?.length ? (
                members
                  ?.filter(member => member.isStop)
                  .map(member => {
                    return <UserTableRow key={member.id} rowData={member} type="Stop" />;
                  })
              ) : (
                <NoDataRow>
                  <TableCell colSpan={7}>
                    <Empty>
                      <div className="icon">
                        <ErrorIcon color={theme.colors['ic-purple-light']} />
                      </div>
                      <span>{t('Member_User_Delete_Tab_03')}</span>
                    </Empty>
                  </TableCell>
                </NoDataRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {hasNextPage && <div ref={ref} />}
      </UserTableWrapper>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const UserTableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: fit-content;
`;

const UserTableHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Search = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  margin-left: auto;
`;

const StyledTextField = styled(TextField)`
  width: 250px;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const StyledTableCell = styled(TableCell)`
  &:last-of-type {
    text-align: center;
  }
`;

const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  height: 126px;

  & .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: ${props => props.theme.colors['bg-purple-lighter']};
  }

  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;

const NoDataRow = styled(TableRow)`
  &:hover {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
`;

export default StopUsers;
