import {
  LicenseStatus,
  LicenseUserStatus,
  UserSubscriptionDTO,
} from '@repositories/subscriptionUserRepository/Types';
import { SubscriptionSimpleModel } from './SubscriptionSimpleModel';
import { SoftwareSimpleModel } from './softwareModels';

export class UserSubscriptionDTOModel {
  private readonly _dto: UserSubscriptionDTO;
  private readonly _subscription: SubscriptionSimpleModel;
  private readonly _software: SoftwareSimpleModel;

  constructor(dto: UserSubscriptionDTO) {
    this._dto = dto;
    this._subscription = new SubscriptionSimpleModel(dto.subscription);
    this._software = new SoftwareSimpleModel(dto.software);
  }

  get subscription(): SubscriptionSimpleModel {
    return this._subscription;
  }

  get software(): SoftwareSimpleModel {
    return this._software;
  }

  get licenseCode(): string {
    return this._dto.licenseCode;
  }

  get licenseStatus(): LicenseStatus {
    return this._dto.licenseStatus;
  }

  get lastLoginTime(): string {
    return this._dto.lastLoginTime;
  }

  get billableStatus(): boolean {
    return this._dto.billableStatus === 'BILLABLE';
  }

  get subscriptionUserStatus(): LicenseUserStatus {
    return this._dto.subscriptionUserStatus;
  }

  get canRevode(): boolean {
    return this._dto.canRevoke;
  }
}
