import { Navigate, useParams } from 'react-router-dom';
import { Loader } from '@components/loader';
import { useGetCategories } from '@queryHooks/useCategory';
import { Products } from './Products';

export const ProductsInCategory = () => {
  const { name } = useParams();
  const decodedName = decodeURIComponent(name ?? '');
  const { data: rootCategories, isSuccess } = useGetCategories({ isRootCategory: true });
  const supportsCustomCategory = process.env.REACT_APP_CUSTOM_ROOT_CATEGORY?.toLowerCase() === 'true';

  // 카테고리가 지정되지 않는 경우, 기본 카테고리 상품 표시
  if (!decodedName) {
    if (!isSuccess) return <Loader />;

    return (
      <Navigate
        to={
          supportsCustomCategory && rootCategories?.content?.[0]
            ? `category/${encodeURIComponent(rootCategories?.content?.[0].name)}`
            : 'category/Software'
        }
        replace
      />
    );
  }

  return <Products type={decodedName} key={decodedName} />;
};
