import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useGetUserGroupMembers } from '@queryHooks/useUserGroup';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ErrorIcon } from '@icons/ErrorIcon';
import { Loader } from '@components/loader';
import { useTranslation } from 'react-i18next';
import * as S from './MembersStyles';
import { MemberTableRow } from './MemberTableRow';

export const Members = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();
  const { data: pagedMembers, isSuccess } = useGetUserGroupMembers(id ?? '');

  return (
    <S.Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={800}>{t('Workflow_Main_05')}</TableCell>
              <TableCell width={440}>{t('Member_User_14')}</TableCell>
              <TableCell width={360}>{t('Member_User_16')}</TableCell>
            </TableRow>
          </TableHead>
          {isSuccess ? (
            <TableBody>
              {pagedMembers.totalElements === 0 ? (
                <S.ErrorTableCell colSpan={3}>
                  <div>
                    <span>
                      <ErrorIcon width={22} height={22} color={theme.colors['ic-purple']} />
                    </span>
                    <Typography variant="body2" component="p">
                      {t('Member_Group_Detail_MemberTab')}
                    </Typography>
                  </div>
                </S.ErrorTableCell>
              ) : (
                pagedMembers.content?.map(member => {
                  return <MemberTableRow key={member.user.id} rowData={member} />;
                })
              )}
            </TableBody>
          ) : (
            <Loader />
          )}
        </Table>
      </TableContainer>
    </S.Container>
  );
});
