import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled(Box)`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
`;

export const InfoWrapper = styled(Box)`
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 18px 18px 18px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  }
  &:last-of-type {
    height: auto;
    border-bottom: none;
  }
`;

export const InfoNameWrapper = styled('div')`
  max-width: 278px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  &.align-top {
    margin-bottom: auto;
  }
  svg {
    flex-shrink: 0;
  }
  strong {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

export const InfoValue = styled('strong')`
  width: 100%;
  ${fonts.Headline8};
  color: ${({ theme }) => theme.colors['text-gray-main']};
`;
export const TextAreaWrapper = styled('div')`
  max-width: 488px;
  width: 100%;
  min-height: 192px;
`;

export const PropertiesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 18px;
`;

export const StyledButton = styled(Button)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors['text-gray-main']};
  border-color: ${({ theme }) => theme.colors['border-gray-light']};
`;
export const StyledSubmitButton = styled(Button)`
  flex-shrink: 0;
`;
export const GroupTextField = styled(TextField)`
  &.MuiFormControl-root {
    min-height: 20px;
    height: auto;
    &.read-mode {
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
      & .MuiInputBase-root {
        padding: 0;
      }
    }
  }
`;
export const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    min-height: 20px;
    height: auto;
    &.description {
      width: 100%;
      height: 100%;
      .MuiInputBase-input {
        padding: 6px 12px;
      }
    }
  }
  & .MuiInputBase-root {
    width: 100%;
    min-height: 20px;
    height: auto;
    padding: 0;
    .MuiInputBase-input::placeholder {
      ${fonts.Body2};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
  }
`;
