import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { DirectApiConfig } from './DirectApiConfig';
import { NoTriggerConfig } from './NoTriggerConfig';
import { SchedulerConfig } from './SchedulerConfig';

export const TriggerConfig = () => {
  const { t } = useTranslation();

  const { workflowDetailStore } = useStore();
  const isConfigurable = !!workflowDetailStore.triggerNode?.data?.baseTriggerModel?.isConfigurable;

  const renderTriggerConfig = () => {
    if (!isConfigurable) {
      return <NoTriggerConfig />;
    }

    return workflowDetailStore.triggerNode?.data?.baseTriggerModel?.type === 'SCHEDULER_TRIGGER' ? (
      <SchedulerConfig />
    ) : (
      <DirectApiConfig />
    );
  };

  return (
    <Container>
      <TriggerName>{t('Workflow_Create_20')}</TriggerName>
      {renderTriggerConfig()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: inherit;
`;

const TriggerName = styled.strong`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 16px;
`;
