import type { Tab } from './UsageRegisterList';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, TableCell, TableRow, styled } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import type { SubscriptionUseRequestModel } from '@models/subscription/SubscriptionUseRequestModel';
import i18n from '@locales/i18n';

interface Props {
  idx: number;
  data: SubscriptionUseRequestModel;
  type: Tab;
}

export const UsageRegisterRow = ({ idx, data: useRq, type }: Props) => {
  const navigate = useNavigate();
  const { categoryStore } = useStore();

  const handleClickRow = () => {
    navigate(`${useRq.id}`);
  };

  const productType = useRq?.categoryId ? categoryStore.findRootCategory(useRq.categoryId)?.name : '';

  return (
    <TableBodyRows onClick={handleClickRow}>
      <TableBodyCell align="center">{idx + 1}</TableBodyCell>
      <TableBodyCell>{dayjs(useRq.createdDate).format(i18n.t('DateFormat_YMD'))}</TableBodyCell>
      <TableBodyCell>{productType}</TableBodyCell>
      <TableBodyCell>
        <ProductName>
          <Avatar src={useRq.productIconUrl} sx={{ width: '16px', height: '16px' }} />
          {useRq.productName}
        </ProductName>
      </TableBodyCell>
      <TableBodyCell>{useRq.planName}</TableBodyCell>
      <TableBodyCell>{useRq.userName}</TableBodyCell>
      <TableBodyCell>{useRq.userAccount}</TableBodyCell>
      {type === 'complete' && <TableBodyCell>{dayjs(useRq.updateDate).format(i18n.t('DateFormat_YMD'))}</TableBodyCell>}
      <TableBodyCell>
        <Label variant="contain" color={useRq.statusLabelColor}>
          {useRq.statusStr}
        </Label>
      </TableBodyCell>
    </TableBodyRows>
  );
};

const TableBodyRows = styled(TableRow)`
  cursor: pointer;
`;

const TableBodyCell = styled(TableCell)`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const ProductName = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;
