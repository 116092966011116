import type { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { MenuItem, Select, TableCell, TableRow } from '@mui/material';
import { Label } from '@components/Label';
import { Button, TableBodyCell, Tooltip } from '@components/index';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { type SubscriptionUserFilter } from '@repositories/subscriptionRepository';
import type { TenantMemberStatus } from '@repositories/userRepository';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import { dateFormat } from '@utils/dateUtil';
import { type SubscriptionUserModel } from '@models/SubscriptionUserModel';
import { getMemberStatusLabel } from '@locales/i18nLabels';

type Props = {
  user: SubscriptionUserModel;
  appliedFilter: SubscriptionUserFilter;
  isMenuOpen: boolean;
  onRowClick: (user: SubscriptionUserModel) => void;
  onUserRoleChange: (event: SelectChangeEvent<string>, user: SubscriptionUserModel) => void;
  onMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, user: SubscriptionUserModel) => void;
};

const getMemberStatusColor = (memberStatus?: TenantMemberStatus) => {
  if (memberStatus === 'ACTIVE') return 'white';
  if (memberStatus === 'BLOCKED') return 'gray';
  return 'red';
};

export const UserTableRow = ({ user, appliedFilter, isMenuOpen, onRowClick, onUserRoleChange, onMenuOpen }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();

  const { data: subscriptionConnection } = useGetSubscriptionConnection(subscription.id);
  const hasAddUserSchema = !!(subscriptionConnection && subscriptionConnection.addUserSchemaUserRoles.length > 0);

  const handleRowClick = () => {
    onRowClick(user);
  };

  const handleUserRoleChange = (event: SelectChangeEvent<string>) => {
    onUserRoleChange(event, user);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onMenuOpen(event, user);
  };

  return (
    <UserRow onClick={handleRowClick}>
      <TableBodyCell $isFilterApplied={!!appliedFilter.isMember}>
        <UserLabel variant="solid" color={getMemberStatusColor(user.tenantMemberStatus)}>
          {getMemberStatusLabel(user.tenantMemberStatus)}
        </UserLabel>
      </TableBodyCell>
      <Tooltip label={user.userLoginId} visibleOnlyEllipsis placement="bottom-center">
        <TableBodyCell>{user.userLoginId || '-'}</TableBodyCell>
      </Tooltip>
      <Tooltip label={user.userName} visibleOnlyEllipsis placement="bottom-center">
        <TableBodyCell>{user.userName || '-'}</TableBodyCell>
      </Tooltip>
      <Tooltip label={user.userEmail} visibleOnlyEllipsis placement="bottom-center">
        <TableCell>{user.userEmail || '-'}</TableCell>
      </Tooltip>
      <TableCell>{user.userOrganization || '-'}</TableCell>
      <TableCell sx={hasAddUserSchema ? { padding: '0px 4px' } : undefined}>
        {hasAddUserSchema ? (
          <UserRoleSelect value={user.userRole} onChange={handleUserRoleChange} fullWidth size="small">
            {subscriptionConnection.addUserSchemaUserRoles.map(role => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </UserRoleSelect>
        ) : (
          user.userRole || '-'
        )}
      </TableCell>
      <TableBodyCell $isFilterApplied={!!appliedFilter.lastLoginTimeFrom || !!appliedFilter.lastLoginTimeTo}>
        {dateFormat(user.lastLoginTime, '-', t('DateFormat_YMD'))}
      </TableBodyCell>
      <TableBodyCell $isFilterApplied={!!appliedFilter.billableStatus}>
        <UserLabel variant="outline" color={user.billableStatus === 'BILLABLE' ? 'gray' : 'red'}>
          {user.billableStatus === 'BILLABLE' ? 'Y' : 'N'}
        </UserLabel>
      </TableBodyCell>
      <TableBodyCell $isFilterApplied={!!appliedFilter.userStatus}>
        <Label variant="solid" color={user.userStatus === 'INACTIVE' ? 'gray' : 'blue'}>
          {user.userStatus === 'INACTIVE' ? t('Subscrib_Detail_User_17') : t('Subscrib_Detail_User_16')}
        </Label>
      </TableBodyCell>
      <TableCell align="center">
        <IconButton onClick={handleMenuOpen}>
          <ThreeDotIcon color={isMenuOpen ? colors['ic-purple'] : colors['ic-gray-dark']} width={18} height={18} />
        </IconButton>
      </TableCell>
    </UserRow>
  );
};

const UserLabel = styled(Label)`
  &.user-label {
    width: 19px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

const IconButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

const UserRoleSelect = styled(Select)`
  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-hover']};
  }

  & .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &.Mui-focused > .MuiSelect-select ~ .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiSelect-select[aria-expanded='true'] ~ .MuiOutlinedInput-notchedOutline {
    border: ${({ theme: { colors } }) => `1px solid ${colors['border-gray-light']}`};
  }
` as unknown as typeof Select;

const UserRow = styled(TableRow)`
  cursor: pointer;
`;
