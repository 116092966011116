import { XIcon } from '@icons/XIcon';
import { IconButton, Modal } from '@mui/material';
import { ProductPlanPrice } from '@repositories/softwareRepository';
import { numberFormat } from '@utils/numberFormat';
import { useTranslation } from 'react-i18next';
import * as S from './PlanDetailModalStyles';

interface Props {
  open: boolean;
  onClose: () => void;
  data: { prices: ProductPlanPrice[]; currencyUnit: CurrencyUnit };
}

export const PlanDetailModal = ({ onClose, open, data }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose}>
      <S.Container>
        <S.Header>
          {t('Product_Detail_View_01')}
          <IconButton onClick={onClose}>
            <XIcon />
          </IconButton>
        </S.Header>
        <S.Body>
          {data.prices.map(option => {
            return (
              <S.Plan key={option.planOptionId}>
                <div className="plan-name">{option.optionName}</div>
                <div className="metrics">
                  {option.usageMetricPrices.map(price => {
                    return (
                      <div key={price.usageMetricId} className="metric">
                        <span>{price.usageMetricName}</span>
                        <span>
                          {price.unitPeriod === 'MONTH'
                            ? `${t('Subscrib_Detail_Usage_05')} `
                            : `${t('Plan_Detail_Modal_01')} `}
                          {numberFormat({ num: price.usageUnitPrice, currency: data.currencyUnit, style: 'currency' })}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </S.Plan>
            );
          })}
        </S.Body>
      </S.Container>
    </Modal>
  );
};
