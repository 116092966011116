import { useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { GlobalLoader } from '@components/loader';
import { Toast } from '@components/toast';
import { useStore } from '@stores/RootStore';
import { useGetCategories } from '@queryHooks/useCategory';
import { useGetTenant } from '@queryHooks/useTenant';
import { Modals } from './common/modals';
import ManagerGnb from './manager/gnb/ManagerGnb';

export const Manager = observer(() => {
  const location = useLocation();
  const { authStore, categoryStore } = useStore();

  const tenantId = location.pathname.split('/')[2];

  const { data: tenant } = useGetTenant(tenantId);

  useLayoutEffect(() => {
    if (tenant) {
      authStore.setManagerTenantInfo(tenant);
    }
  }, [authStore, tenant]);

  useLayoutEffect(() => {
    authStore.initialize(tenantId);
  }, [authStore, tenantId]);

  const { data: categories } = useGetCategories();

  useEffect(() => {
    if (categories && categories.content.length > 0) {
      categoryStore.setCategories(categories.content);
    }
  }, [categories, categoryStore]);

  return (
    <div className="app customer">
      <ManagerGnb />
      <Main>
        <ContentsWrapper>
          <Outlet />
        </ContentsWrapper>
      </Main>
      <Modals />
      <GlobalLoader />
      <Toast />
    </div>
  );
});

export const Main = styled.main`
  width: 100%;
  padding-top: var(--manager-gnb-height);
`;

export const ContentsWrapper = styled.div`
  height: 100%;
`;
