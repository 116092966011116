import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import * as Yup from 'yup';

export type FormUseRq = {
  comment: string;
};

export const useUserForm = () => {
  const validationUserSchema = Yup.object().shape({
    comment: Yup.string().notRequired(),
  });

  const userFormOptions: UseFormProps<FormUseRq> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<FormUseRq>(userFormOptions);
};
