import styled from '@emotion/styled';
import { Avatar, Box, css } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

export const Container = styled(Box, transientOptions)<{
  $itemChecked: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 31px 24px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors['bg-white']};
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);

  ${({ $itemChecked, theme: { colors } }) =>
    $itemChecked
      ? css`
          border: 1px solid ${colors['border-purple']};
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
        `
      : ''}
`;

export const ProductSelectWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 357px;
  width: 100%;
`;

export const CartItemAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 8px;
  margin-left: 12px;
`;
export const ProductNameWrapper = styled(Box)`
  max-width: 253px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
  margin-left: 16px;
`;

export const ProductInfoWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

export const ProdcutInfo = styled('dl')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin: 0;
  padding-right: 10px;
`;

export const InfoItemTitle = styled('dt')`
  min-width: 72px;
  ${fonts.Headline8};
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  flex-shrink: 0;
`;
export const InfoItemText = styled('dd')`
  max-width: 564px;
  width: 100%;
  max-height: 40px;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  ${fonts.Body2};
  text-align: left;
  color: ${({ theme }) => theme.colors['text-gray-main']};
  margin: 0;
`;

export const BtnsWrapper = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: right;
  flex-shrink: 0;
`;
