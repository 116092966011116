import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  type RawAxiosRequestHeaders,
} from 'axios';
import authStore from '@stores/AuthStore';
import uiStore from '@stores/UiStore';
import { HttpErrHandler, SmpAxiosError, handleError } from '@utils/handleError';

import i18n from '../locales/i18n';
import localStorage from './browserStorage/localStorage/LocalStorage';
import sessionStorage from './browserStorage/sessionStorage/SessionStorage';

const axiosInstance = axios.create();

interface SystemErrorHandler extends HttpErrHandler {
  400: (error: AxiosError<{ errorCode: { messageId: string } }>) => void;
  401: (error: AxiosError<{ errorCode: { messageId: string } }>) => void;
}

const systemHandler: SystemErrorHandler = {
  400(error) {
    if (error.config?.method === 'get') {
      // uiStore.errorNotiUiStore.open('서비스 실패');
    } else {
      // FIXME: 에러 처리 정책 결정한 후 수정 필요
      // const { message } = error.response?.data as { message: string };
      // uiStore.errorNotiUiStore.open(message);
    }
  },
  401(error) {
    const serviceError = error.response?.data;
    // AccessToken과 refreshToken이 만료되었을 때, 로그아웃 진행
    if (!!serviceError && serviceError.errorCode.messageId === 'AUTH.ERROR.NO_TOKEN_ERROR') {
      uiStore.openSessionExpiredModal();
      axiosInstance.defaults.headers.common.Authorization = undefined;
      authStore.logout();
    }
  },
  common(err) {
    const serviceError = err.response?.data;
    if (serviceError) {
      console.error({
        'layer :': 'axios common err handler',
        'status: ': err.response?.status,
        'serviceErrCode: ': serviceError.errorCode?.messageId,
        'serviceErrMessage: ': serviceError.message,
      });
    }
  },
  default(err) {
    console.error('axios err default handler: ', err);
  },
};

let timer: NodeJS.Timeout | null = null;

const logout = () => {
  authStore.logout();
  uiStore.openSessionExpiredModal();
  if (timer) {
    clearTimeout(timer);
  }
};

interface AxiosType {
  instance: AxiosInstance;
  get: <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  post: <T>(url: string, data?: UnknownObject, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  delete: <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  put: <T>(url: string, data?: UnknownObject, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  patch: <T>(url: string, data?: UnknownObject, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  getDefaultHeader: () => RawAxiosRequestHeaders;
}

if (process.env.REACT_APP_MODE === 'product') {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL;
} else {
  axiosInstance.defaults.baseURL = window.location.origin;
}

axiosInstance.defaults.withCredentials = true;

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

const userLang = localStorage.get<string>('lang') as string;
if (userLang) {
  i18n.changeLanguage(userLang);
  localStorage.set('lang', userLang);
  document.cookie = `SMP_lang=${userLang}; path=/; secure; ${
    process.env.REACT_APP_MODE === 'product' ? `domain=${process.env.REACT_APP_DOMAIN}` : ''
  }`;
} else {
  const browserLang = navigator.language;
  const formattingLang = browserLang.split('-');
  i18n.changeLanguage(formattingLang[0].toLowerCase());
  localStorage.set('lang', formattingLang[0].toLowerCase());
  document.cookie = `SMP_lang=${formattingLang[0].toLowerCase()}; path=/; secure;  ${
    process.env.REACT_APP_MODE === 'product' ? `domain=${process.env.REACT_APP_DOMAIN}` : ''
  }`;
}

const tk = sessionStorage.get('tk');
if (tk && typeof tk === 'string') {
  axiosInstance.defaults.headers.common.Authorization = tk;
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (timer) clearTimeout(timer);
    const hasToken = sessionStorage.get('tk');
    if (hasToken) {
      timer = setTimeout(() => {
        logout();
      }, 21600000);
    }
    return response;
  },
  (error: SmpAxiosError) => {
    if (timer) clearTimeout(timer);
    const hasToken = sessionStorage.get('tk');
    if (hasToken) {
      timer = setTimeout(() => {
        logout();
      }, 21600000);
    }
    handleError(error, systemHandler);
    return Promise.reject(error);
  },
);

const API: AxiosType = {
  instance: axiosInstance,

  getDefaultHeader() {
    return authStore.curTenant.id ? { 'X-TenantID': authStore.curTenant.id } : {};
  },

  async get<T>(url: string, config: AxiosRequestConfig = {}) {
    const response = await this.instance.get<T>(url, {
      ...config,
      headers: { ...this.getDefaultHeader(), ...config.headers },
    });
    const tk = response.headers.authorization;

    if (tk) {
      this.instance.defaults.headers.common.Authorization = response.headers.authorization;
      sessionStorage.set('tk', response.headers.authorization as string);
    }
    return response;
  },

  async post<T>(url: string, data = {}, config: AxiosRequestConfig = {}) {
    const response = await this.instance.post<T, AxiosResponse<T>>(url, data, {
      ...config,
      headers: { ...this.getDefaultHeader(), ...config.headers },
    });
    const tk = response.headers.authorization;
    if (tk) {
      this.instance.defaults.headers.common.Authorization = response.headers.authorization;
      sessionStorage.set('tk', response.headers.authorization as string);
    }
    return response;
  },

  async put<T>(url: string, data = {}, config: AxiosRequestConfig = {}) {
    const response = await this.instance.put<T>(url, data, {
      ...config,
      headers: { ...this.getDefaultHeader(), ...config.headers },
    });
    const tk = response.headers.authorization;
    if (tk) {
      this.instance.defaults.headers.common.Authorization = response.headers.authorization;
      sessionStorage.set('tk', response.headers.authorization as string);
    }
    return response;
  },

  async delete<T>(url: string, config: AxiosRequestConfig = {}) {
    const response = await this.instance.delete<T>(url, {
      ...config,
      headers: { ...this.getDefaultHeader(), ...config.headers },
    });
    const tk = response.headers.authorization;
    if (tk) {
      this.instance.defaults.headers.common.Authorization = response.headers.authorization;
      sessionStorage.set('tk', response.headers.authorization as string);
    }
    return response;
  },

  async patch<T>(url: string, data = {}, config: AxiosRequestConfig = {}) {
    const response = await this.instance.patch<T>(url, data, {
      ...config,
      headers: { ...this.getDefaultHeader(), ...config.headers },
    });
    const tk = response.headers.authorization;
    if (tk) {
      this.instance.defaults.headers.common.Authorization = response.headers.authorization;
      sessionStorage.set('tk', response.headers.authorization as string);
    }
    return response;
  },
};

export default API;
