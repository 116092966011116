import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Modal, Snackbar, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { Button, Dialog } from '@components/index';
import { UserGroupSelect } from '@components/userGroupSelect/UserGroupSelect';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { XIcon } from '@icons/XIcon';
import { KEYS, repository } from '@repositories/Repository';
import IUserGroupsRepository from '@repositories/userGroupsRepository/IUserGroupsRepository';
import { useGetUserGroup, useUpdateUserGroup } from '@queryHooks/index';
import * as S from './InfoParentGroupEditStyle';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ParentGroupEidt = observer(({ isOpen, onClose }: Props) => {
  const theme = useTheme();
  const [isDepthErrorDialogOpen, setIsDepthErrorDialogOpen] = useState(false);
  const [isSubGroupErrorDialogOpen, setIsSubGroupErrorDialogOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(true);
  const { id } = useParams();
  const { mutate: updateUserGroup } = useUpdateUserGroup();
  const [toastOpen, setToastOpen] = useState(false);
  const handleToast = {
    open: () => {
      setToastOpen(true);
    },
    close: () => {
      setToastOpen(false);
    },
  };
  const {
    uiStore: { userGroupCreateStore: modal },
  } = useStore();
  const { handleSubmit } = useForm<FormData>({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const { data: userGroup } = useGetUserGroup(id as string, { enabled: modal.visible });

  const [selectMenuCurValue, setSelectMenuCurValue] = useState({
    userGroupId: '',
    name: '',
  });

  const parentGroupCheckValidation = async () => {
    if (userGroup && selectMenuCurValue.userGroupId) {
      const userGroupRepo = repository.get<IUserGroupsRepository>(KEYS.USERGROUP_REPOSITORY);
      const result = await userGroupRepo.checkValidationForUpdate({
        from: userGroup.userGroupId,
        to: selectMenuCurValue.userGroupId,
      });
      return result;
    }
    return false;
  };

  const validateHandler = {
    success: async () => {
      if (userGroup && selectMenuCurValue.userGroupId === '') {
        updateUserGroup(
          {
            description: userGroup.description,
            name: userGroup.name,
            parentUserGroupId: selectMenuCurValue.userGroupId,
            userGroupCode: userGroup.userGroupCode,
            userGroupId: userGroup.userGroupId,
          },
          {
            onSuccess: () => {
              handleToast.open();
              onClose();
            },
          },
        );
      } else if (userGroup) {
        try {
          const validation = await parentGroupCheckValidation();
          if (validation) {
            updateUserGroup(
              {
                description: userGroup.description,
                name: userGroup.name,
                parentUserGroupId: selectMenuCurValue.userGroupId,
                userGroupCode: userGroup.userGroupCode,
                userGroupId: userGroup.userGroupId,
              },
              {
                onSuccess: () => {
                  handleToast.open();
                  onClose();
                },
              },
            );
          }
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            const { messageId } = error.response.data?.errorCode || {};
            if (messageId === 'USER_GROUP.ERROR.TARGET_BELONGS_TO_SUBGROUP') {
              setIsSubGroupErrorDialogOpen(true);
            } else if (messageId === 'USER_GROUP.ERROR.DEPTH_LIMIT_EXCEEDED') {
              setIsDepthErrorDialogOpen(true);
            }
          }
        }
      }
    },
    fail: () => {
      console.log('error');
    },
  };

  const handleSeleted = (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.currentTarget.dataset.name !== undefined && e.currentTarget.dataset.userGroupId !== undefined) {
      setSelectMenuCurValue({
        name: e.currentTarget.dataset.name,
        userGroupId: e.currentTarget.dataset.userGroupId,
      });
    }
    setIsSelectOpen(!isSelectOpen);
    setIsSelected(false);
  };

  useEffect(() => {
    setSelectMenuCurValue({
      userGroupId: '',
      name: t('Member_Group_Move_11'),
    });
  }, []);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <S.Continer>
          <S.ModalHeader>
            <Typography variant="headline6" component="strong">
              {t('Member_Group_Move_01')}
            </Typography>
            <S.ModalCloseBtn size="small" variant="text" onClick={onClose}>
              <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
            </S.ModalCloseBtn>
          </S.ModalHeader>
          <S.ModalBody>
            <S.ModalForm onSubmit={handleSubmit(validateHandler.success, validateHandler.fail)}>
              <S.ContentWrapper>
                <strong>{t('Member_Group_Move_02')}</strong>
                <UserGroupSelect
                  selectedValue={{ name: selectMenuCurValue.name, userGroupId: selectMenuCurValue.userGroupId }}
                  onSelectChange={handleSeleted}
                />
                <ul>
                  <li>
                    <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
                    <span>{t('Member_Group_Create_09')}</span>
                  </li>
                  <li>
                    <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
                    <span>{t('Member_Group_Move_04')}</span>
                  </li>
                  <li>
                    <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
                    <span>{t('Member_Group_Move_05')}</span>
                  </li>
                </ul>
              </S.ContentWrapper>
              <S.BottomBtnsWrapper>
                <Button size="extraLarge" variant="contain" type="submit" disabled={isSelected}>
                  {t('Member_User_24')}
                </Button>
                <Button
                  size="extraLarge"
                  variant="outline"
                  onClick={() => {
                    onClose();
                    setSelectMenuCurValue({ userGroupId: '', name: t('Member_Group_Move_11') });
                    setIsSelected(true);
                  }}
                >
                  {t('Subscrib_Detail_BuyRequest_13')}
                </Button>
              </S.BottomBtnsWrapper>
            </S.ModalForm>
          </S.ModalBody>
        </S.Continer>
      </Modal>
      <ToastPopup
        open={toastOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={handleToast.close}
        message={t('Member_Group_Move_06')}
      />
      <Dialog
        title={t('Member_Group_Move_07')}
        open={isDepthErrorDialogOpen}
        onClose={() => setIsDepthErrorDialogOpen(false)}
        paddingHorizontal="0px"
      >
        <DialogTextWrap>
          <p>{t('Member_Group_Move_08')}</p>
        </DialogTextWrap>
        <Dialog.Footer $paddingHorizontal="0px">
          <DialogButton size="extraLarge" variant="contain" onClick={() => setIsDepthErrorDialogOpen(false)}>
            {t('Member_User_24')}
          </DialogButton>
        </Dialog.Footer>
      </Dialog>
      <Dialog
        title={t('Member_Group_Move_07')}
        open={isSubGroupErrorDialogOpen}
        onClose={() => setIsSubGroupErrorDialogOpen(false)}
        paddingHorizontal="0px"
      >
        <DialogTextWrap>
          <p>{t('Member_Group_Move_09')}</p>
        </DialogTextWrap>
        <Dialog.Footer $paddingHorizontal="0px">
          <DialogButton size="extraLarge" variant="contain" onClick={() => setIsSubGroupErrorDialogOpen(false)}>
            {t('Member_User_24')}
          </DialogButton>
        </Dialog.Footer>
      </Dialog>
    </>
  );
});

const ToastPopup = styled(Snackbar)`
  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors['bg-bk-opacity-85%']};
  }
`;

const DialogTextWrap = styled('div')`
  text-align: center;
  padding-bottom: 100px;
  p {
    ${fonts.Paragraph1};
    color: ${({ theme }) => theme.colors['text-gray-main']};
    white-space: pre;
  }
`;

const DialogButton = styled(Button)`
  margin: 0 28px 0;
`;
