import { createContext, useContext } from 'react';
import { DropdownProps } from './Dropdown';

type DropdownContextProps = Pick<DropdownProps, 'value' | 'onChange' | 'width'> & {
  onMenuChange?: (label: React.ReactNode, value: string | number) => void;
};

const DropdownContext = createContext<DropdownContextProps>({});

export const DropdownContextProvider = ({
  value,
  children,
}: {
  value: DropdownContextProps;
  children: React.ReactNode;
}) => {
  return <DropdownContext.Provider value={value}>{children}</DropdownContext.Provider>;
};

export const useDropdownContext = () => useContext(DropdownContext);
