import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { useGetWorkflowBaseTriggers } from '@queryHooks/useWorkflowBaseTrigger';
import type { WorkflowBaseTriggerDTOModel } from '@models/workflow/WorkflowBaseTriggerDTOModel';
import { Trigger } from './Trigger';

export const TriggerSelect = observer(() => {
  const { t } = useTranslation();

  const { workflowDetailStore } = useStore();

  const { data: triggers } = useGetWorkflowBaseTriggers();

  const handleClickTrigger = (trigger: WorkflowBaseTriggerDTOModel) => {
    if (!workflowDetailStore.checkEditable()) return;
    if (!workflowDetailStore.triggerNode) return;

    if (!workflowDetailStore.triggerNode.data.triggerModel) {
      workflowDetailStore.triggerNode.data.triggerModel = { baseTriggerId: trigger.id };
    }
    workflowDetailStore.triggerNode.data.triggerModel.baseTriggerId = trigger.id;
    workflowDetailStore.triggerNode.data.baseTriggerModel = trigger;
    // NOTE. 설정이 필요한 트리거의 경우 바로 트리거 화면으로
    if (trigger.isConfigurable) {
      workflowDetailStore.setToolbarView('triggerConfig');
    }
  };

  return (
    <Container>
      <Name>{t('Workflow_Create_03')}</Name>
      {triggers?.content?.map(trigger => {
        return (
          <Trigger
            key={trigger.id}
            trigger={trigger}
            onClick={handleClickTrigger}
            selected={workflowDetailStore.triggerNode?.data.triggerModel?.baseTriggerId}
          />
        );
      })}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  background: inherit;
  & .toolbar-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-bottom: 6px;
  }
`;

const Name = styled.strong`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 6px;
`;
