const defaultWidth = 24;
const defaultHeight = 24;

export const GraphColorIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <g filter="url(#filter0_d_2238_5187)">
          <path
            d="M1.5 3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H20.5C21.6046 1.5 22.5 2.39543 22.5 3.5V20.5C22.5 21.6046 21.6046 22.5 20.5 22.5H3.5C2.39543 22.5 1.5 21.6046 1.5 20.5V3.5Z"
            fill="#8A63D2"
          />
        </g>
        <g filter="url(#filter1_d_2238_5187)">
          <path
            d="M4 16.7266C4 16.5172 4.08204 16.3163 4.22851 16.1667L8.41199 11.8961C8.64538 11.6578 9.00208 11.5894 9.30708 11.7243L13.5819 13.6151C13.841 13.7297 14.1413 13.6987 14.3716 13.5337L19.367 9.95366C19.6317 9.76397 20 9.95315 20 10.2788V19.2C20 19.6418 19.6418 20 19.2 20H4.8C4.35817 20 4 19.6418 4 19.2V16.7266Z"
            fill="#5A32A3"
          />
        </g>
        <g filter="url(#filter2_d_2238_5187)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.51229 13.2916C4.32337 13.4808 4 13.347 4 13.0796V11.2994C4 11.2198 4.03161 11.1435 4.08787 11.0872L7.28319 7.89191C7.89191 7.28319 8.82186 7.13228 9.59183 7.51727L13.2471 9.3449C13.3465 9.3946 13.4652 9.38528 13.5556 9.32069L19.5256 5.05641C19.7242 4.91458 20 5.05652 20 5.30053V6.81811C20 6.915 19.9532 7.00592 19.8744 7.06223L14.5711 10.8502C13.9683 11.2808 13.1768 11.3315 12.5142 11.0002L8.89077 9.1965C8.77527 9.139 8.63595 9.16179 8.54479 9.25309L4.51229 13.2916Z"
            fill="#E6DCFD"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2238_5187"
            x="0.5"
            y="1"
            width="23"
            height="23"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2238_5187" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2238_5187" result="shape" />
          </filter>
          <filter
            id="filter1_d_2238_5187"
            x="3.5"
            y="9.87805"
            width="17"
            height="11.1219"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2238_5187" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2238_5187" result="shape" />
          </filter>
          <filter
            id="filter2_d_2238_5187"
            x="3.5"
            y="5"
            width="17"
            height="9.38025"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2238_5187" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2238_5187" result="shape" />
          </filter>
        </defs>
      </g>
    </svg>
  );
};

GraphColorIcon.displayName = 'GraphColorIcon';
