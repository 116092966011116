import { DailyUsageModel } from '@models/usageModels/DailyUsageModel';
import { HourlyUsageModel } from '@models/usageModels/HourlyUsageModel';
import { MonthlyUsageModel } from '@models/usageModels/MonthlyUsageModel';
import { RealtimeUsageModel } from '@models/usageModels/RealtimeUsageModel';
import { KEYS, repository } from '@repositories/Repository';
import IDailyUsageRepository from '@repositories/usage/dailyUsageRepository/IDailyUsageRepository';
import IHourlyUsageRepository from '@repositories/usage/hourlyUsageRepository/IHourlyUsageRepository';
import IRealtimeUsageRepository from '@repositories/usage/realtimeUsageRepository/IRealtimeUsageRepository';
import IMonthlyUsageRepository from '@repositories/usage/monthlyUsageRepository/IMonthlyUsageRepository';
import { DailyFilters, HourlyFilters, MonthlyFilters, RealtimeFilters } from '@repositories/usage/Types';
import { useQuery } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { TrendUsageModel } from '@models/usageModels/TrendUsageModel';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const realtimeUsageRepo = repository.get<IRealtimeUsageRepository>(KEYS.REALTIME_USAGE_REPOSITORY);
const hourlyUsageRepo = repository.get<IHourlyUsageRepository>(KEYS.HOURLY_USAGE_REPOSITORY);
const dailyUsageRepo = repository.get<IDailyUsageRepository>(KEYS.DAILY_USAGE_REPOSITORY);
const monthlyUsageRepo = repository.get<IMonthlyUsageRepository>(KEYS.MONTHLY_USAGE_REPOSITORY);

const realtimeUsageKey = {
  all: ['Realtime'] as const,
  lists: () => [...realtimeUsageKey.all, 'list'] as const,
  list: (filter?: RealtimeFilters) => [...realtimeUsageKey.lists(), { filter }] as const,
};

export const useGetRealtimeUsages = (
  queries?: RealtimeFilters,
  options?: UseQueryOptionsType<Page<RealtimeUsageModel>>,
) => {
  const result = useQuery<Page<RealtimeUsageModel>, AxiosError>(
    dailyUsageKey.list(queries),
    async () => {
      const result = await realtimeUsageRepo.getList(queries);
      return { ...result, content: result.content.map(realtime => new RealtimeUsageModel(realtime)) };
    },
    {
      ...options,
      cacheTime: 5 * 60 * 1000,
      staleTime: 4 * 60 * 1000,
    },
  );
  return { ...result };
};

const hourlyUsageKey = {
  all: ['Hourly'] as const,
  lists: () => [...hourlyUsageKey.all, 'list'] as const,
  list: (filter?: HourlyFilters) => [...hourlyUsageKey.lists(), { filter }] as const,
};

export const useGetHourlyUsages = (queries?: HourlyFilters, options?: UseQueryOptionsType<Page<HourlyUsageModel>>) => {
  const result = useQuery<Page<HourlyUsageModel>, AxiosError>(
    hourlyUsageKey.list(queries),
    async () => {
      const result = await hourlyUsageRepo.getList(queries);
      return { ...result, content: result.content.map(hourly => new HourlyUsageModel(hourly)) };
    },
    {
      ...options,
      cacheTime: 5 * 60 * 1000,
      staleTime: 4 * 60 * 1000,
    },
  );
  return { ...result, dataMap: new TrendUsageModel(result.data?.content) };
};

const dailyUsageKey = {
  all: ['Daily'] as const,
  lists: () => [...dailyUsageKey.all, 'list'] as const,
  list: (filter?: DailyFilters) => [...dailyUsageKey.lists(), { filter }] as const,
};

export const useGetDailyUsages = (queries?: DailyFilters, options?: UseQueryOptionsType<Page<DailyUsageModel>>) => {
  const result = useQuery<Page<DailyUsageModel>, AxiosError>(
    dailyUsageKey.list(queries),
    async () => {
      const result = await dailyUsageRepo.getList(queries);
      return { ...result, content: result.content.map(daily => new DailyUsageModel(daily)) };
    },
    {
      ...options,
      cacheTime: 5 * 60 * 1000,
      staleTime: 4 * 60 * 1000,
    },
  );
  return { ...result, dataMap: new TrendUsageModel(result.data?.content) };
};

const monthlyUsageKey = {
  all: ['Monthly'] as const,
  lists: () => [...monthlyUsageKey.all, 'list'] as const,
  list: (filter?: MonthlyFilters) => [...monthlyUsageKey.lists(), { filter }] as const,
};

export const useGetMonthlyUsages = (queries?: MonthlyFilters, options?: UseQueryOptionsType<Page<MonthlyUsageModel>>) =>
  useQuery<Page<MonthlyUsageModel>, AxiosError>({
    queryKey: dailyUsageKey.list(queries),
    queryFn: async () => {
      const result = await monthlyUsageRepo.getList(queries);
      return { ...result, content: result.content.map(monthly => new MonthlyUsageModel(monthly)) };
    },
    ...options,
    cacheTime: 5 * 60 * 1000,
    staleTime: 4 * 60 * 1000,
  });
