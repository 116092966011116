const defaultWidth = 18;
const defaultHeight = 18;

export const PlusIcon = ({ width = defaultWidth, height = defaultHeight, color = '#FFFFFF' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2077 4.09233C8.2077 3.65477 8.56242 3.30005 8.99998 3.30005C9.43755 3.30005 9.79227 3.65477 9.79227 4.09233V8.20777H13.9078C14.3453 8.20777 14.7001 8.56248 14.7001 9.00005C14.7001 9.43761 14.3453 9.79233 13.9078 9.79233H9.79227V13.9078C9.79227 14.3453 9.43755 14.7001 8.99998 14.7001C8.56242 14.7001 8.2077 14.3453 8.2077 13.9078V9.79233H4.09233C3.65477 9.79233 3.30005 9.43761 3.30005 9.00005C3.30005 8.56248 3.65477 8.20777 4.09233 8.20777H8.2077V4.09233Z"
        fill={color}
      />
    </g>
  </svg>
);

PlusIcon.displayName = 'PlusIcon';
