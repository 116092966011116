import type {
  Filter,
  LicenseCreateDTO,
  LicenseDTO,
  LicenseSubscriptionUserBulkUpdateDTO,
  LicenseSubscriptionUserUpdateDTO,
  LicenseUpdateDTO,
} from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { SubscriptionUserDTO } from '@repositories/subscriptionRepository';
import { LicenseStatus } from '@repositories/subscriptionUserRepository/Types';
import { Page } from '@type/Page';
import { ILicenseRepository } from './ILicenseRepository';

@injectable()
export class LicenseRepository implements ILicenseRepository {
  async getList(queries?: Filter): Promise<Page<LicenseDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<LicenseDTO>>(`/api/v1/licenses?${queryString}`);
    return result.data;
  }

  async create(data: LicenseCreateDTO): Promise<LicenseDTO> {
    const result = await API.post<LicenseDTO>(`/api/v1/licenses`, data);
    return result.data;
  }

  async getOne(id: string): Promise<LicenseDTO> {
    const result = await API.get<LicenseDTO>(`/api/v1/licenses/${id}`);
    return result.data;
  }

  async update(id: string, data: LicenseUpdateDTO): Promise<LicenseDTO> {
    const result = await API.put<LicenseDTO>(`/api/v1/licenses/${id}`, data);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    await API.delete(`/api/v1/licenses/${id}`);
  }

  async getUserList(id: string): Promise<SubscriptionUserDTO[]> {
    const result = await API.get<SubscriptionUserDTO[]>(`/api/v1/licenses/${id}/subscription-users`);
    return result.data;
  }

  async patchUser(id: string, data: LicenseSubscriptionUserUpdateDTO): Promise<LicenseDTO> {
    const result = await API.patch<LicenseDTO>(`/api/v1/licenses/${id}/subscription-user`, data);
    return result.data;
  }

  async updateUser(id: string, data: LicenseSubscriptionUserBulkUpdateDTO): Promise<LicenseDTO> {
    const result = await API.post<LicenseDTO>(`/api/v1/licenses/${id}/bulk-subscription-users`, data);
    return result.data;
  }

  async updateLicenseStatus(licenseId: string, data: LicenseStatus): Promise<LicenseDTO> {
    const result = await API.put<LicenseDTO>(`/api/v1/licenses/${licenseId}/status`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  }
}
