const defaultWidth = 24;
const defaultHeight = 24;

export const GroupIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.91253 8.21052C3.91253 5.33284 6.24532 3 9.12298 3C12.0006 3 14.3334 5.33284 14.3334 8.21052C14.3334 10.0006 13.4308 11.5798 12.0558 12.5178C15.2433 13.6855 17.5392 16.7018 17.6454 20.2683C17.6571 20.6606 17.3485 20.988 16.9563 20.9997C16.5641 21.0114 16.2366 20.7028 16.225 20.3106C16.1111 16.4865 12.9749 13.421 9.1229 13.421C5.27092 13.421 2.13466 16.4865 2.02084 20.3106C2.00917 20.7028 1.68173 21.0114 1.28949 20.9997C0.897253 20.988 0.588744 20.6606 0.600418 20.2683C0.706569 16.7017 3.00259 13.6854 6.19007 12.5178C4.81515 11.5797 3.91253 10.0005 3.91253 8.21052ZM9.12298 4.42105C7.03017 4.42105 5.33358 6.11765 5.33358 8.21052C5.33358 10.3034 7.03017 12 9.12298 12C11.2158 12 12.9124 10.3034 12.9124 8.21052C12.9124 6.11765 11.2158 4.42105 9.12298 4.42105Z"
          fill={color}
        />
        <path
          d="M16.9767 8.21052C16.8367 8.21052 16.6996 8.22048 16.5657 8.23962C16.1773 8.29519 15.8173 8.02532 15.7618 7.63686C15.7062 7.2484 15.9761 6.88845 16.3645 6.83289C16.5648 6.80424 16.7692 6.78947 16.9767 6.78947C19.3468 6.78947 21.2682 8.71089 21.2682 11.0811C21.2682 12.4792 20.5995 13.7203 19.5664 14.5033C21.7751 15.4934 23.3145 17.7109 23.3145 20.2895C23.3145 20.6819 22.9964 21 22.604 21C22.2115 21 21.8934 20.6819 21.8934 20.2895C21.8934 18.067 20.4184 16.1873 18.3924 15.5793L17.8861 15.4274V13.8396L18.2747 13.6422C19.2093 13.1673 19.8471 12.198 19.8471 11.0811C19.8471 9.49569 18.562 8.21052 16.9767 8.21052Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6859 15.576V13.7167L18.1839 13.4637C19.0539 13.0216 19.6469 12.1197 19.6469 11.0809C19.6469 9.60595 18.4513 8.41033 16.9765 8.41033C16.8461 8.41033 16.7184 8.41961 16.5939 8.43741C16.0961 8.50861 15.6348 8.16278 15.5636 7.66498C15.4924 7.16718 15.8382 6.70591 16.336 6.63471C16.5457 6.60472 16.7595 6.58928 16.9765 6.58928C19.4571 6.58928 21.468 8.60024 21.468 11.0809C21.468 12.4282 20.8744 13.6363 19.9368 14.4587C22.0591 15.5384 23.5143 17.743 23.5143 20.2893C23.5143 20.7921 23.1066 21.1998 22.6038 21.1998C22.1009 21.1998 21.6932 20.7921 21.6932 20.2893C21.6932 18.1575 20.2784 16.354 18.3347 15.7707L17.6859 15.576ZM3.71234 8.21033C3.71234 5.22219 6.13467 2.7998 9.12279 2.7998C12.1109 2.7998 14.5332 5.22219 14.5332 8.21033C14.5332 9.93621 13.725 11.4733 12.4671 12.4637C15.5498 13.7439 17.7403 16.7407 17.8451 20.2622C17.8601 20.7648 17.4647 21.1844 16.9621 21.1994C16.4594 21.2144 16.0398 20.819 16.0248 20.3164C15.9142 16.6 12.8662 13.6209 9.1227 13.6209C5.37918 13.6209 2.33117 16.6 2.22056 20.3164C2.2056 20.819 1.786 21.2144 1.28335 21.1994C0.780701 21.1844 0.385353 20.7648 0.400313 20.2622C0.505123 16.7406 2.69564 13.7438 5.77839 12.4637C4.52055 11.4732 3.71234 9.93618 3.71234 8.21033ZM9.12279 4.62086C7.14043 4.62086 5.53339 6.2279 5.53339 8.21033C5.53339 10.1928 7.14043 11.7998 9.12279 11.7998C11.1051 11.7998 12.7122 10.1928 12.7122 8.21033C12.7122 6.2279 11.1051 4.62086 9.12279 4.62086Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

GroupIcon.displayName = 'GroupIcon';
