import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { UseFormProps, useForm } from 'react-hook-form';
import * as Yup from 'yup';

export type DetailSettingFormData = {
  name: string;
  planId: string;
  planOptionId: string;
  startDate: string;
  endDate?: string | null;
};

export const useDetailSettingForm = () => {
  const detailSettingSchema: Yup.SchemaOf<DetailSettingFormData> = Yup.object().shape({
    name: Yup.string().required(),
    planId: Yup.string().required(),
    planOptionId: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().nullable(),
  });

  const detailSettingFormOption: UseFormProps<DetailSettingFormData> = {
    resolver: yupResolver(detailSettingSchema),
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      name: '',
      planId: '',
      planOptionId: '',
      startDate: dayjs().format('YYYY.MM.DD'),
      endDate: null,
    },
  };
  return useForm<DetailSettingFormData>(detailSettingFormOption);
};

export type ProductRequestFormData = {
  subject: string;
  inquiry?: string;
};

export const useProductRequestForm = () => {
  const productRequestSchema: Yup.SchemaOf<ProductRequestFormData> = Yup.object().shape({
    subject: Yup.string().required(i18next.t('Member_Group_Delete_05')),
    inquiry: Yup.string(),
  });

  const productRequestFormOption: UseFormProps<ProductRequestFormData> = {
    resolver: yupResolver(productRequestSchema),
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      subject: '',
      inquiry: '',
    },
  };
  return useForm<ProductRequestFormData>(productRequestFormOption);
};
