import type { TechnicalInquiryHistoryDTO, TechnicalStatus, TechnicalType } from '../technicalSupport.types';

export class TechnicalInquiryHistoryDTOModel {
  private readonly _dto: TechnicalInquiryHistoryDTO;

  constructor(dto: TechnicalInquiryHistoryDTO) {
    this._dto = dto;
  }

  get originDTO(): TechnicalInquiryHistoryDTO {
    return this._dto;
  }

  get createDate(): string {
    return this._dto.createdDate ?? '';
  }

  get comment(): string {
    return this._dto.comment ?? '';
  }

  get id(): string {
    return this._dto.id ?? '';
  }

  get managerId(): string {
    return this._dto.managerId ?? '';
  }

  get managerName(): string {
    return this._dto.managerName ?? '';
  }

  get status(): TechnicalStatus {
    return this._dto.status ?? 'CREATED';
  }

  get technicalInquiryId(): string {
    return this._dto.technicalInquiryId ?? '';
  }

  get type(): TechnicalType {
    return this._dto.type ?? 'MANAGER';
  }

  get conversionStatus(): '요청' | '답변 완료' | '진행중' {
    switch (this._dto.status) {
      case 'CREATED':
        return '요청';
      case 'IN_PROGRESS':
        return '진행중';
      case 'COMPLETED':
        return '답변 완료';
      default:
        return '요청';
    }
  }

  get conversionStatusColor(): 'pink' | 'green' | 'gray' {
    switch (this._dto.status) {
      case 'CREATED':
        return 'pink';
      case 'IN_PROGRESS':
        return 'green';
      case 'COMPLETED':
        return 'gray';
      default:
        return 'pink';
    }
  }
}
