import { useMemo } from 'react';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { KEYS, repository } from '@repositories/Repository';
import ISoftwareRepository from '@repositories/softwareRepository/ISoftwareRepository';
import {
  Filters,
  ProductPlanBasicPlanUpdateRq,
  ProductPlanCreateRq,
  ProductPlanUpdateRq,
  SoftwareCreateRq,
  SoftwareTranslationDTO,
  UsageMetricCreateRq,
  UsageMetricUpdateRq,
} from '@repositories/softwareRepository/Types';
import { Page } from '@type/Page';
import { ProductPlanListModel, SoftwareModel, SoftwareSimpleModel } from '@models/softwareModels';

import { ProductPlanModel } from '@models/softwareModels/ProductPlanModel';
import { SoftwareTranslationDTOModel } from '@models/softwareModels/SoftwareTranslationDTOModel';
import { UsageMetricModel } from '@models/softwareModels/UsageMetricModel';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const swRepo = repository.get<ISoftwareRepository>(KEYS.SOFTWARE_REPOSITORY);
export const swQueryKey = {
  all: ['software'] as const,
  lists: () => [...swQueryKey.all, 'list'] as const,
  one: (softwareId: SoftwareId) => [...swQueryKey.all, softwareId] as const,
  list: (filter: Filters) => [...swQueryKey.lists(), { ...filter }] as const,
  listInfinite: (filter: Filters) => [...swQueryKey.list(filter), 'infinite'] as const,
  planList: (softwareId: SoftwareId) => [...swQueryKey.one(softwareId), 'plan'],
  plan: (softwareId: SoftwareId, planId: string) => [...swQueryKey.planList(softwareId), planId] as const,
  usageMetricList: (softwareId: SoftwareId) => [...swQueryKey.one(softwareId), 'usageMetric'],
  usageMetric: (softwareId: SoftwareId, metricId: string) => [...swQueryKey.planList(softwareId), metricId] as const,
};

const transKey = {
  all: ['translations'] as const,
  lists: () => [...transKey.all, 'list'] as const,
  list: (softwareId: string) => [...transKey.lists(), softwareId] as const,
};

export const useGetSoftwareList = (filter: Filters, options?: UseQueryOptionsType<Page<SoftwareSimpleModel>>) => {
  const result = useQuery<Page<SoftwareSimpleModel>, AxiosError>(
    swQueryKey.list(filter),
    async () => {
      const result = await swRepo.getList(filter);
      return {
        ...result,
        content: result.content.map(dto => new SoftwareSimpleModel(dto)),
      };
    },
    options,
  );

  const initialData = useMemo(() => ({ content: [] as SoftwareSimpleModel[], totalElements: 0 }), []);
  return { ...result, data: result.data ?? initialData };
};

export const useGetSoftwareListInfinite = (
  filter: Omit<Filters, 'page'>,
  options?: UseInfiniteQueryOptions<Page<SoftwareSimpleModel>>,
) =>
  useInfiniteQuery({
    queryKey: swQueryKey.listInfinite(filter),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await swRepo.getList({ ...filter, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new SoftwareSimpleModel(dto)),
        pageParam,
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetSoftware = (softwareId: SoftwareId, options?: UseQueryOptionsType<SoftwareModel>) => {
  const result = useQuery<SoftwareModel, AxiosError>(
    swQueryKey.one(softwareId),
    async () => {
      const result = await swRepo.getOne(softwareId);
      return new SoftwareModel(result);
    },
    {
      ...options,
      enabled: !!softwareId && (options?.enabled !== undefined ? options.enabled : true),
    },
  );

  const initialData = useMemo(() => new SoftwareModel(), []);
  return { ...result, data: result.data ?? initialData };
};

export const useAddSoftwareMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ data, iconImage, thumbnail }: { data: SoftwareCreateRq; iconImage?: Blob; thumbnail?: Blob }) =>
      swRepo.create(data, iconImage, thumbnail),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.lists());
      },
    },
  );
};

export const useGetProductPlanList = (swId: string, options?: UseQueryOptionsType<ProductPlanListModel[]>) =>
  useQuery<ProductPlanListModel[], AxiosError>({
    queryKey: swQueryKey.planList(swId),
    queryFn: async () => {
      const result = await swRepo.getPlanList(swId);
      result.sort((a, b) => {
        return (a.planGrade ? a.planGrade : 0) - (b.planGrade ? b.planGrade : 0);
      });
      return result.map(dto => new ProductPlanListModel(dto));
    },
    ...options,
  });

export const useGetProductPlanDetail = (
  swId: string,
  productPlanId: string,
  options?: UseQueryOptionsType<ProductPlanModel>,
) =>
  useQuery<ProductPlanModel, AxiosError>({
    queryKey: swQueryKey.plan(swId, productPlanId),
    queryFn: async () => {
      const result = await swRepo.getPlanDetail(swId, productPlanId);
      return new ProductPlanModel(result);
    },
    ...options,
  });

export const useGetProductPlanDetails = (
  swId: string,
  productPlanIds: string[],
  options?: UseQueryOptionsType<ProductPlanModel>,
) =>
  useQueries({
    queries: productPlanIds.map(productPlanId => ({
      queryKey: swQueryKey.plan(swId, productPlanId),
      queryFn: async () => {
        const result = await swRepo.getPlanDetail(swId, productPlanId);
        return new ProductPlanModel(result);
      },
      ...options,
    })),
  });

export const useAddProductPlan = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: ProductPlanCreateRq) => {
      const result = await swRepo.createPlan(swId, rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.planList(swId));
      },
    },
  );

  return { ...result };
};

export const useUpdateProductPlan = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: ProductPlanUpdateRq) => {
      const result = await swRepo.updatePlan(swId, rqData.planId, rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.planList(swId));
      },
    },
  );

  return { ...result };
};

export const useUpdateProductPlanBasicPlan = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: ProductPlanBasicPlanUpdateRq) => {
      const result = await swRepo.updatePlanBasicPlan(swId, rqData.planId, rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.planList(swId));
      },
    },
  );

  return { ...result };
};

export const useDeleteProductPlan = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (planId: string) => {
      await swRepo.deletePlan(swId, planId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.planList(swId));
      },
    },
  );

  return { ...result };
};

export const useGetUsageMetricList = (swId: string, options?: UseQueryOptionsType<UsageMetricModel[]>) =>
  useQuery<UsageMetricModel[], AxiosError>({
    queryKey: swQueryKey.usageMetricList(swId),
    queryFn: async () => {
      const result = await swRepo.getUsageMetricList(swId);
      return result.map(dto => new UsageMetricModel(swId, dto));
    },
    ...options,
    enabled: !!swId && (options?.enabled !== undefined ? !!options.enabled : true),
  });

export const useGetUsageMetricLists = (swIds: string[], options?: UseQueryOptionsType<UsageMetricModel[]>) =>
  useQueries({
    queries: swIds.map(swId => ({
      queryKey: swQueryKey.usageMetricList(swId),
      queryFn: async () => {
        const result = await swRepo.getUsageMetricList(swId);
        return result.map(dto => new UsageMetricModel(swId, dto));
      },
      ...options,
      enabled: !!swId && (options?.enabled !== undefined ? !!options.enabled : true),
    })),
  });

export const useGetUsageMetricDetail = (
  swId: string,
  metricId: string,
  options?: UseQueryOptionsType<UsageMetricModel>,
) => {
  const result = useQuery<UsageMetricModel, AxiosError>(
    swQueryKey.plan(swId, metricId),
    async () => {
      const result = await swRepo.getUsageMetricDetail(swId, metricId);
      return result;
    },
    { ...options },
  );

  return { ...result };
};

export const useAddUsageMetric = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: UsageMetricCreateRq) => {
      const result = await swRepo.createUsageMetric(swId, rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.usageMetricList(swId));
      },
    },
  );

  return { ...result };
};

export const useUpdateUsageMetric = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: UsageMetricUpdateRq) => {
      const result = await swRepo.updateUsageMetric(swId, rqData.usageMetricId, rqData);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.usageMetricList(swId));
      },
    },
  );

  return { ...result };
};

export const useDeleteUsageMetric = (swId: string) => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (metricId: string) => {
      await swRepo.deleteUsageMetric(swId, metricId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swQueryKey.usageMetricList(swId));
      },
    },
  );

  return { ...result };
};

export const useAddSoftwareTranslationLang = () => {
  const queryClient = useQueryClient();

  const result = useMutation(
    async (rqData: SoftwareTranslationDTO) => {
      await swRepo.addTranslationLang(rqData.softwareId, rqData.languageCode, rqData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(transKey.all);
      },
    },
  );

  return { ...result };
};

export const useGetSoftwareTranslationLangs = (softwareId: string) => {
  return useQuery<SoftwareTranslationDTO[], AxiosError>(transKey.list(softwareId), async () => {
    const result = await swRepo.getTranslationLangs(softwareId);
    return result.map(dto => new SoftwareTranslationDTOModel(dto));
  });
};

export const useGetSoftwareTranslationLang = (softwareId: string, langCode: string) => {
  return useQuery<SoftwareTranslationDTO, AxiosError>(['translations', langCode], async () => {
    const result = await swRepo.getTranslationLang(softwareId, langCode);
    return new SoftwareTranslationDTOModel(result);
  });
};

export const useDeleteSoftwareTranslationLang = () => {
  const queryClient = useQueryClient();
  type TranslationDeleteType = {
    softwareId: string;
    langCode: string;
  };

  return useMutation(
    async (value: TranslationDeleteType) => {
      await swRepo.deleteTranslationLang(value.softwareId, value.langCode);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(transKey.all);
      },
    },
  );
};
