import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, Box, Snackbar, css, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import uiStore from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import TailImg from '@images/tail.png';
import { BulletIcon } from '@icons/BulletIcon';
import { CartIcon } from '@icons/CartIcon';
import { useGetCategories, useGetSoftware } from '@queryHooks/index';
import { transientOptions } from '@utils/index';

export const Store: React.FC = observer(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const paths = pathname.split('/').filter(p => p);
  const currentPath = decodeURIComponent(paths[paths.length - 1] ?? '');
  const isDetailPage = paths[1] === 'product' && paths.length === 3;
  const theme = useTheme();
  const { colors } = theme;
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const isManager = location.pathname.split('/')[1] === 'manager';
  const cartRef = useRef<HTMLAnchorElement>(null);
  const {
    uiStore: { cartSoftwareId, isCartToast },
  } = useStore();

  const { data: sw } = useGetSoftware(cartSoftwareId);
  const { data: rootCategories } = useGetCategories({ isRootCategory: true });
  const supportsCustomCategory = process.env.REACT_APP_CUSTOM_ROOT_CATEGORY?.toLowerCase() === 'true';

  const calculateTooltipPosition = (cartButtonElement: HTMLElement): { top: number; right: number } => {
    const cartBtnRect = cartButtonElement.getBoundingClientRect();

    const top = cartBtnRect.bottom + 9;
    const right = window.innerWidth - cartBtnRect.right + 15;
    return { top, right };
  };

  useEffect(() => {
    const updateToastPosition = () => {
      const cartButtonElement = cartRef.current;
      const toastElement = document.getElementById('cart-toast');

      if (cartButtonElement && toastElement) {
        const position = calculateTooltipPosition(cartButtonElement);
        toastElement.style.top = `${position.top}px`;
        toastElement.style.right = `${position.right}px`;
      }
    };

    // 초기 로딩 시 위치 조정
    updateToastPosition();

    // 브라우저 리사이즈 이벤트 핸들러
    const handleResize = () => {
      updateToastPosition();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      // 이전 리사이즈 이벤트 리스너 제거
      window.removeEventListener('resize', handleResize);
    };
  }, [isCartToast]);

  const toastMessage = (
    <ToastWrapper>
      <Avatar src={sw.iconUrl} sx={{ width: '36px', height: '36px' }} />
      <div className="text-wrapper">
        <strong>{sw.name}</strong>
        <span>{t('Ad_Add_Cart_Complete')}</span>
      </div>
    </ToastWrapper>
  );

  const itAdminStyles = {
    elevation: 0,
    sx: {
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: -7,
        right: 13,
        width: 14,
        height: 7,
        bgcolor: 'background.paper',
        background: `url(${TailImg}) 0 0 no-repeat`,
        backgroundSize: '14px 7px',
        webkitBackgroundSize: '14px 7px',
        zIndex: 0,
      },
    },
  };

  const handleToastClose = () => {
    uiStore.closeCartToast();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <PageContainer id="publicStore" className={isDetailPage ? 'detail' : ''}>
        {isDetailPage ? null : (
          <Container>
            <TabsWrapper>
              {rootCategories &&
                rootCategories.content
                  .filter(
                    category => supportsCustomCategory || category.name === 'Software' || category.name === 'Hardware',
                  )
                  .map((category, index) => (
                    <>
                      {index !== 0 && !matches && <BulletIcon color={colors['text-w-opacity-60%']} />}
                      <PublicMainCategoryLink
                        key={category.id}
                        to={`category/${encodeURIComponent(category.name)}`}
                        state={{ subTitle: 'Store' }}
                      >
                        <CategoryLinkTitle $isSelected={currentPath === category.name}>
                          {category.name === 'Software'
                            ? t('Subscrib_Detail_Overview_11')
                            : category.name === 'Hardware'
                            ? t('Subscrib_Detail_Overview_12')
                            : category.name}
                        </CategoryLinkTitle>
                      </PublicMainCategoryLink>
                    </>
                  ))}
              {isManager && (
                <SubMenuWrapper>
                  <MenuLinkItem to="apply-histories" $isSelected={paths[3] === 'apply-histories'}>
                    {t('Subscrib_GNB_06')}
                  </MenuLinkItem>
                  <MenuLinkItem to="cart" $isSelected={currentPath === 'cart'} ref={cartRef}>
                    <CartIcon
                      width={14}
                      height={14}
                      color={currentPath === 'cart' ? colors['ic-white'] : colors['text-w-opacity-60%']}
                    />
                    {t('Ad_Cart_01')}
                  </MenuLinkItem>
                </SubMenuWrapper>
              )}
            </TabsWrapper>
          </Container>
        )}
        <Outlet />
      </PageContainer>
      {createPortal(
        <ToastPopup
          id="cart-toast"
          className="cart-toast"
          open={isCartToast}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message={toastMessage}
          ContentProps={itAdminStyles}
        />,
        document.body,
      )}
    </>
  );
});

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors['bg-purple-dark']};
`;

const TabsWrapper = styled(Box)`
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 34px;
  padding: 12px 20px;
  position: relative;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    height: 48px;
    gap: 48px;
    padding: 0;
  } ;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fafbfc;
  &.detail {
    height: max-content;
    background-color: #fff;
  }
`;

const PublicMainCategoryLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 0px;
  text-decoration: unset;
`;

const CategoryLinkTitle = styled('span', transientOptions)<{ $isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${fonts.Headline7};
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors['text-white'] : theme.colors['text-w-opacity-60%'])};
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    height: 100%;
    ${({ $isSelected, theme }) =>
      $isSelected &&
      css`
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 2px 2px 0px 0px;
          background-color: ${theme.colors['bg-white']};
        }
      `};
  } ;
`;

const SubMenuWrapper = styled('ul')`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const MenuLinkItem = styled(Link, transientOptions)<{ $isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${fonts.Body2};
  text-decoration: none;
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors['text-white'] : theme.colors['text-w-opacity-60%'])};
  &:first-of-type:after {
    content: '';
    width: 1px;
    height: 10px;
    display: flex;
    margin: 0 10px 0 12px;
    background-color: ${({ theme }) => theme.colors['border-w-opacity-30%']};
  }
  &:last-of-type {
    gap: 4px;
  }
`;

const ToastPopup = styled(Snackbar)`
  &.cart-toast {
    bottom: auto;
    left: auto;
  }
  & .MuiPaper-root {
    position: relative;
    width: 254px;
    padding: 15px 18px;
    border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
    background-color: ${({ theme }) => theme.colors['bg-white']};
    border-radius: 3px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
  }
  & .MuiSnackbarContent-message {
    padding: 0;
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-white']};
  }
  & .MuiSnackbarContent-action {
    padding: 0;
  }
`;

const ToastWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  & > .MuiAvatar-root {
    border-radius: 6px;
  }
  & > .text-wrapper {
    display: flex;
    flex-direction: column;
    & > strong {
      ${fonts.Body2};
      color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
    }
    & > span {
      ${fonts.Headline8};
      color: ${({ theme }) => theme.colors['text-gray-main']};
    }
  }
`;
