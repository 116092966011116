import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { CloseIcon } from '@icons/CloseIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { WizardIcon } from '@icons/WizardIcon';
import { useGetWorkflowBaseTrigger } from '@queryHooks/useWorkflowBaseTrigger';
import type { WorkflowBaseTriggerFieldDTOModel } from '@models/workflow/WorkflowBaseTriggerFieldDTOModel';

interface Props {
  triggerId: string | undefined;
  onClick: (type: 'action' | 'trigger', field: string, displayName: string, idx: number) => void;
  onClose: () => void;
  index: number;
}

export const InputDataPopup = ({ triggerId, onClick, onClose, index }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: triggerDetail } = useGetWorkflowBaseTrigger(triggerId || '', { enabled: !!triggerId });

  const handleClickInputData = (input: WorkflowBaseTriggerFieldDTOModel) => {
    onClick('trigger', input.name, input.displayName || input.name, index);
  };

  return createPortal(
    <Container>
      <Header>
        <Title>{t('Input_Data_Popup_01')}</Title>
        <Button variant="text" onClick={onClose}>
          <CloseIcon width={24} height={24} />
        </Button>
      </Header>
      <Body>
        {!!triggerDetail?.fields.length && (
          <InputData disableGutters defaultExpanded>
            <DataTitleWrapper
              expandIcon={<ChevronIcon rotateNum={180} width={20} height={20} color={theme.colors['ic-gray-light']} />}
            >
              <DataTitle>
                <WizardIcon width={16} height={16} />
                <span>{t('Workflow_Create_02')}</span>
              </DataTitle>
            </DataTitleWrapper>
            <DataItems>
              {triggerDetail?.fields?.map(field => {
                return (
                  <DataItem onClick={() => handleClickInputData(field)} key={field.name}>
                    {field.displayName}
                  </DataItem>
                );
              })}
            </DataItems>
          </InputData>
        )}
        {/* {!!actionFlows?.length &&
          actionFlows.map(actionFlow => {
            if (actionFlow?.action?.outputSchema) {
              return (
                <Accordion
                  disableGutters
                  key={actionFlow.action.name}
                  defaultExpanded
                  sx={{ boxShadow: 'none', border: '1px solid ' }}
                >
                  <DataTitleWrapper expandIcon={<ChevronIcon />}>
                    <DataTitle>
                      <Avatar src={actionFlow.subscription?.product.iconUrl} sx={{ width: '16px', height: '16px' }} />
                      <span>{t('Workflow_Create_36')}</span>
                    </DataTitle>
                  </DataTitleWrapper>
                  <DataItems>{t('Workflow_Create_36')}</DataItems>
                </Accordion>
              );
            }
            return null;
          })} */}
        {!triggerDetail?.fields.length && (
          <Empty>
            <EmptyCircle>
              <ErrorIcon color={theme.colors['ic-purple']} />
            </EmptyCircle>
            <EmptyInfo>
              {t('Workflow_Create_34')}
              <EmptySubInfo>{t('Workflow_Create_35')}</EmptySubInfo>
            </EmptyInfo>
          </Empty>
        )}
      </Body>
    </Container>,
    document.body,
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  bottom: 8px;
  right: 768px;
  transform: translate(calc(50% - 233px), 0);
  width: 400px;
  height: 640px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0px 8px 10px -5px #00000014, 0px 16px 24px 2px #0000000a, 0px 6px 30px 5px #0000000d;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 18px 0px 28px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
`;

const Title = styled.strong`
  ${fonts.Headline6}
`;

const Body = styled.div`
  padding: 20px 28px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
`;

const InputData = styled(Accordion)`
  box-shadow: none;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-radius: 4px;
`;

const DataTitleWrapper = styled(AccordionSummary)`
  height: 40px;
`;

const DataTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${fonts.Headline8};
  align-items: center;
  padding: 0px 4px;
`;

const DataItems = styled(AccordionDetails)`
  padding: 0px;
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0px 20px;
  ${fonts.Body2};
  background-color: ${props => props.theme.colors['bg-gray-light-50']};
  border: 1px solid ${props => props.theme.colors['bg-gray-light-50']};
  :hover {
    background-color: ${props => props.theme.colors['state-white-purple-hover']};
    border: 1px solid ${props => props.theme.colors['border-purple-light']};
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const EmptyCircle = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${props => props.theme.colors['bg-purple-lighter']};
  align-items: center;
  justify-content: center;
`;

const EmptyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${fonts.Body2};
`;

const EmptySubInfo = styled.div`
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;
