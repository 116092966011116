const defaultWidth = 24;
const defaultHeight = 24;

export const PhoneIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M22.4993 17.4018C22.5165 18.4596 22.221 19.5994 21.5944 20.5106L21.3733 20.8054C20.9605 21.4156 20.3653 21.862 19.6586 22.1287C19.1674 22.3141 18.8601 22.3678 18.0673 22.4596C14.6578 22.7814 10.9318 21.1751 7.25279 17.8716C4.79514 15.6602 2.95479 12.8941 2.06866 10.0663C1.69469 8.87044 1.5 7.72164 1.5 6.68189C1.5 5.43514 1.77894 4.3552 2.33995 3.54399C2.8722 2.80615 3.88364 2.19324 5.40423 1.61394C6.14991 1.32986 6.99155 1.59024 7.45146 2.24727L10.3658 6.41126C10.8466 7.09834 10.7807 8.03237 10.2082 8.64373L9.82894 9.03487L9.36998 9.48385C9.25535 9.58171 9.08095 9.81763 9.08095 9.81763C9.04106 10.3594 9.61343 11.474 11.28 13.1954L11.7759 13.6649C13.3127 15.0954 13.7264 15.2102 14.1984 14.8784L14.3558 14.7487C14.5228 14.6204 14.96 14.3133 15.8924 13.6662C16.4273 13.2951 17.1217 13.2532 17.6966 13.5575L18.1208 13.784C21.031 15.3521 22.3605 16.2959 22.489 17.2639L22.4993 17.4018ZM20.7495 17.4301C20.7518 17.5752 20.5495 17.3516 20.1282 17.0446C19.5138 16.5968 18.5682 16.0273 17.2996 15.3437L16.8839 15.1217L15.5048 16.0823C15.4728 16.1034 15.458 16.1117 15.4638 16.105C13.96 17.2972 12.6647 16.9628 10.051 14.4524C8.04527 12.3816 7.25115 10.8352 7.33514 9.69605C7.37436 9.07272 7.79996 8.5019 8.21301 8.16028C8.38871 7.98973 8.56166 7.81824 8.71748 7.6588L8.93644 7.43038L6.02223 3.26648C4.79462 3.73416 4.03547 4.1941 3.76459 4.56947C3.43863 5.04112 3.24998 5.77185 3.24998 6.68188C3.24998 7.53045 3.41474 8.50249 3.73735 9.53408C4.52516 12.0481 6.18748 14.5467 8.41659 16.5526C11.7632 19.5576 15.0421 20.9711 17.8862 20.7028L18.3368 20.6461C18.6484 20.6014 18.8242 20.5578 19.0457 20.4743C19.4292 20.3295 19.7317 20.1026 19.978 19.742C20.4457 19.1656 20.6954 18.428 20.7419 17.7279L20.7495 17.4301Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PhoneIcon.displayName = 'PhoneIcon';
