const defaultWidth = 24;
const defaultHeight = 24;

export const PencilIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5624 2.71257C17.2458 2.02916 18.3538 2.02916 19.0373 2.71258L21.623 5.29836C22.3065 5.98178 22.3065 7.08982 21.623 7.77324L18.8302 10.5661L18.8177 10.5783L8.98961 19.9142C8.79178 20.103 8.55245 20.2429 8.29081 20.3225L2.7682 22.0033C2.50321 22.084 2.21535 22.012 2.01949 21.8161C1.82364 21.6203 1.75167 21.3324 1.83232 21.0674L3.50592 15.5684C3.59357 15.2805 3.75402 15.02 3.97177 14.8121L13.7757 5.4993L16.5624 2.71257ZM14.2876 7.08427L5.00748 15.8971C4.97637 15.9268 4.95345 15.9641 4.94093 16.0052L3.67677 20.1588L7.85407 18.8875C7.89144 18.8761 7.92563 18.8561 7.9539 18.8292L17.2267 10.0233L14.2876 7.08427ZM18.2998 8.97514L20.5624 6.71258C20.66 6.61494 20.66 6.45665 20.5624 6.35902L17.9766 3.77324C17.879 3.6756 17.7207 3.6756 17.623 3.77324L15.3605 6.0358L18.2998 8.97514Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9577 10.7214L18.9702 10.7092L21.7648 7.91464C22.5263 7.15312 22.5263 5.91845 21.7648 5.15693L19.179 2.57114C18.4175 1.80962 17.1828 1.80962 16.4213 2.57114L13.6364 5.35605L3.83397 14.6674C3.59134 14.899 3.41255 15.1893 3.31488 15.5102L1.64128 21.0092C1.53913 21.3448 1.63029 21.7095 1.87837 21.9575C2.12646 22.2056 2.49108 22.2968 2.82673 22.1946L8.34934 20.5138C8.64084 20.4251 8.90758 20.2692 9.128 20.0589L18.9557 10.7233L18.9577 10.7214ZM7.79613 18.6961L3.97759 19.8583L5.13256 16.0634C5.13506 16.0552 5.1397 16.0477 5.14587 16.0418L14.2843 7.36349L16.9404 10.0196L7.8161 18.6845C7.81045 18.6899 7.80361 18.6939 7.79613 18.6961ZM17.8354 3.91455L20.4212 6.50033C20.4407 6.51986 20.4407 6.55152 20.4212 6.57104L18.3001 8.69219L15.6436 6.03569L17.7647 3.91455C17.7842 3.89502 17.8159 3.89502 17.8354 3.91455Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PencilIcon.displayName = 'PencilIcon';
