import type { CartesianViewBox } from 'recharts/types/util/types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useStore } from '@stores/RootStore';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { ArrowIcon } from '@icons/ArrowIcon';
import { CircleDashFillIcon } from '@icons/CircleDashFillIcon';
import CircleUpFillIcon from '@icons/CircleUpFillIcon';
import { DepthIcon } from '@icons/DepthIcon';
import { Loading48Icon } from '@icons/LoadingIcon';
import { useGetSubscriptionBillingStatByMonth } from '@queryHooks/useSubscriptionBillingStat';
import { numberTickFormatter } from '@utils/ChartUtil';
import { currencyNumberFormat, numberFormat } from '@utils/numberFormat';

type YealryBillingChartData = {
  month: string;
  monthlyPayment?: number;
  monthlyPaymentGrowthRate?: number;
  monthlyPaymentEstimate?: number;
  monthlyPaymentEstimateGrowthRate?: number;
  currencyUnit?: CurrencyUnit;
  subscriptionCount: number;
  subscriptionCountGrowth: number;
  billableUserCount: number;
  billableUserCountGrowth: number;
  billableMemberCount: number;
  billableNonMemberCount: number;
};

type Props = {
  barSize: number;
  width?: number | string;
  height?: number | string;
  year?: number;
  subscriptionId?: string;
};
export const YearlyBillingChart = ({ barSize, width = '100%', height = '100%', year, subscriptionId }: Props) => {
  const { colors } = useTheme();
  const { authStore } = useStore();

  const curDayjs = dayjs();
  const targetYear = year ?? dayjs().year();
  const curYearMonth = curDayjs.format('YYYY-MM');

  const { data: statByMonth, isLoading } = useGetSubscriptionBillingStatByMonth({
    monthFrom: `${targetYear}-01`,
    monthTo: `${targetYear}-12`,
    subscriptionId,
  });

  const monthList = Array.from(new Array(12).keys()).map(idx => `${targetYear}-${String(idx + 1).padStart(2, '0')}`);

  const chartData = monthList.reduce<YealryBillingChartData[]>(
    (accum, curMonth, index) => {
      const data = statByMonth?.find(({ month }) => month === curMonth);
      if (data) {
        /* eslint-disable no-param-reassign */
        accum[index].monthlyPayment = data.monthlyPayment;
        accum[index].monthlyPaymentEstimate = data.monthlyPaymentEstimate;
        accum[index].currencyUnit = authStore.managerTenantInfo.currencyUnit;
        accum[index].monthlyPaymentEstimateGrowthRate = data.monthlyPaymentEstimateGrowthRate;
        accum[index].subscriptionCount = data.subscriptionCount;
        accum[index].billableUserCount = data.billableUserCount;
        accum[index].billableMemberCount = data.billableMemberCount;
        accum[index].billableNonMemberCount = data.billableNonMemberCount;

        if (index > 0) {
          const curMonthlyPayment = accum[index].monthlyPayment;
          const prevMonthlyPayment = accum[index - 1].monthlyPayment;
          accum[index].monthlyPaymentGrowthRate =
            curMonthlyPayment && prevMonthlyPayment
              ? (curMonthlyPayment - prevMonthlyPayment) / prevMonthlyPayment
              : undefined;

          const curSubscriptionCount = accum[index].subscriptionCount;
          const prevSubscriptionCount = accum[index - 1].subscriptionCount;
          accum[index].subscriptionCountGrowth = curSubscriptionCount - prevSubscriptionCount;

          const curTotalUserCount = accum[index].billableUserCount;
          const prevTotalUserCount = accum[index - 1].billableUserCount;
          accum[index].billableUserCountGrowth = curTotalUserCount - prevTotalUserCount;
        }
        /* eslint-enable no-param-reassign */
      }

      return accum;
    },
    monthList.map(month => ({
      month,
      subscriptionCount: 0,
      subscriptionCountGrowth: 0,
      totalUserCount: 0,
      totalUserCountGrowth: 0,
      billableUserCount: 0,
      billableUserCountGrowth: 0,
      billableMemberCount: 0,
      billableNonMemberCount: 0,
    })),
  );

  const yAxisTickFormatter = numberTickFormatter(chartData.map(({ monthlyPayment }) => monthlyPayment ?? 0));

  return (
    <RelativeContainer>
      {isLoading && (
        <Loading48Icon
          style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}
        />
      )}
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart data={chartData} margin={{ top: 25 }} barGap={-barSize}>
          <defs>
            <linearGradient id="purple-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.purple[300]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.purple[100]}`} />
            </linearGradient>
            <linearGradient id="border-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.purple[400]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.purple[200]}`} />
            </linearGradient>
            <linearGradient id="forecasted-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.gray[200]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.gray[100]}`} />
            </linearGradient>
            <linearGradient id="forecasted-border-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.gray[300]}`} />
              <stop offset="100%" stopColor={`${colorDefinition.gray[200]}`} />
            </linearGradient>
            <linearGradient id="bg-gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={`${colorDefinition.purple[100]}33`} />
              <stop offset="100%" stopColor={`${colorDefinition.purple[100]}00`} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={colors['border-gray-lighter']} strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="month"
            padding={{ left: 19, right: 24 }}
            tickLine={false}
            tick={{ ...fonts.Body6, color: colors['text-gray-sub-dark'] }}
            axisLine={{ stroke: colors['border-gray-light'] }}
            tickFormatter={value => dayjs(value).format('MMM')}
          />
          <YAxis
            axisLine={{ strokeWidth: '0px' }}
            tickLine={false}
            tick={{ ...fonts.Body6, color: colors['text-gray-sub-dark'] }}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip content={<TooltipContent isSingleSubscription={!!subscriptionId} />} />

          <Area dataKey="monthlyPayment" fill="url(#bg-gradient)" animationDuration={500} />
          <Bar
            dataKey="monthlyPaymentEstimate"
            animationDuration={500}
            barSize={barSize}
            shape={<BarShape isForecasted />}
          >
            {chartData.map((entry: YealryBillingChartData) => (
              <Cell key={entry.month} fill="url(#forecasted-gradient)" />
            ))}
          </Bar>
          <Bar dataKey="monthlyPayment" animationDuration={500} barSize={barSize} shape={<BarShape />}>
            {chartData.map((entry: YealryBillingChartData) => (
              <Cell key={entry.month} fill="url(#purple-gradient)" />
            ))}
          </Bar>
          <Line
            key={nanoid()}
            dataKey="monthlyPayment"
            strokeWidth={1}
            stroke={colors['graph-purple']}
            dot={{
              r: barSize >= 20 ? 3 : 2,
              stroke: colors['bg-white'],
              strokeWidth: 1,
              fill: colors['graph-purple'],
              fillOpacity: 1,
            }}
            activeDot={{
              r: barSize > 20 ? 6 : 5,
              stroke: colors['graph-purple'],
              strokeWidth: barSize > 20 ? 6 : 5,
              fill: colors['bg-white'],
              fillOpacity: 1,
              filter: 'drop-shadow(0px 2px 6px  rgba(0, 0, 0, 0.09))',
            }}
            animationDuration={0}
            filter="drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2))"
          />
          <ReferenceLine x={curYearMonth} stroke={colors['border-gray-darker']} label={<ReferenceLineLabel />} />
        </ComposedChart>
      </ResponsiveContainer>
    </RelativeContainer>
  );
};

const TooltipContent = ({
  active,
  payload,
  label,
  isSingleSubscription,
}: {
  active?: boolean;
  payload?: { value: number; payload: YealryBillingChartData }[];
  label?: string;
  isSingleSubscription?: boolean;
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  if (!payload || payload.length === 0) {
    return null;
  }
  const billing = payload[0].payload;

  const renderValueGrowthRate = (value: number | undefined) => (
    <ValueGrowthRate $growthRate={value}>
      {value ? (
        <>
          <CircleUpFillIcon
            width={12}
            height={12}
            color={value > 0 ? colors['ic-green-light'] : colors['ic-red-light']}
            rotateNum={value > 0 ? 0 : 180}
          />{' '}
          {numberFormat({ num: Math.abs(value) * 100, maxFractionDigit: 4 })}%
        </>
      ) : (
        <>
          <CircleDashFillIcon width={12} height={12} color={colors['ic-gray-light']} /> 0%
        </>
      )}
    </ValueGrowthRate>
  );

  const renderCountGrowth = (count: number) =>
    count === 0 ? null : (
      <CountGrowthWrapper $count={count}>
        <ArrowIcon width={9} height={9} color={colors['ic-white']} rotateNum={count > 0 ? -45 : 135} />
        {Math.abs(count)}
      </CountGrowthWrapper>
    );

  return active && payload && payload.length > 0 ? (
    <TooltipContainer>
      <TooltipLabel>{dayjs(label).format(t('DateFormat_YM'))}</TooltipLabel>

      <ValueWrapper>
        <ColorBar style={{ background: colors['graph-gray'] }} />
        <div>
          <ValueTitle>지불 예측</ValueTitle>
          <Value>
            {typeof billing.monthlyPaymentEstimate === 'number'
              ? currencyNumberFormat(billing.monthlyPaymentEstimate, billing.currencyUnit)
              : '-'}
          </Value>
          {renderValueGrowthRate(billing.monthlyPaymentEstimateGrowthRate)}
        </div>
      </ValueWrapper>

      <ValueWrapper>
        <ColorBar style={{ background: colors['graph-purple-light'] }} />
        <div>
          <ValueTitle>지불 완료</ValueTitle>
          <Value>
            {typeof billing.monthlyPayment === 'number'
              ? currencyNumberFormat(billing.monthlyPayment, billing.currencyUnit)
              : '-'}
          </Value>
          {renderValueGrowthRate(billing.monthlyPaymentGrowthRate)}
        </div>
      </ValueWrapper>
      {!isSingleSubscription && (
        <div style={{ marginTop: '8px' }}>
          <SubscriptionInfo>
            <SubscriptionInfoTitle>구독</SubscriptionInfoTitle>
            <SubscriptionInfoValue>
              <span>{numberFormat({ num: billing.subscriptionCount })}</span>
              {renderCountGrowth(billing.subscriptionCountGrowth)}
            </SubscriptionInfoValue>
          </SubscriptionInfo>
          <SubscriptionInfo>
            <SubscriptionInfoTitle>구독 과금 사용자</SubscriptionInfoTitle>
            <SubscriptionInfoValue>
              <span>{numberFormat({ num: billing.billableUserCount })}</span>
              {renderCountGrowth(billing.billableUserCountGrowth)}
            </SubscriptionInfoValue>
          </SubscriptionInfo>
          <SubscriptionInfo>
            <SubscriptionInfoTitle>
              <DepthIcon width={10} height={10} color={colors['ic-gray-light']} />
              구성원 / 비구성원
            </SubscriptionInfoTitle>
            <SubscriptionInfoValue>
              {billing.billableMemberCount} / {billing.billableNonMemberCount}
            </SubscriptionInfoValue>
          </SubscriptionInfo>
        </div>
      )}
    </TooltipContainer>
  ) : null;
};

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const TooltipContainer = styled.div`
  min-width: 230px;
  background: ${({ theme: { colors } }) => colors['bg-gray-main']};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  padding: 12px 16px;
  z-index: 99999999;
`;

const TooltipLabel = styled.p`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
  margin-bottom: 18px;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 8px;
  }
`;

const ValueTitle = styled.p`
  ${fonts.Body4};
  color: ${({ theme: { colors } }) => colors['text-gray-lighter']};
  margin-bottom: 2px;
`;

const Value = styled.p`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-white']};
`;

const ValueGrowthRate = styled.p<{ $growthRate: number | undefined }>`
  display: flex;
  align-items: center;
  gap: 2px;
  ${fonts.Body6};
  color: ${({ $growthRate, theme: { colors } }) =>
    $growthRate
      ? $growthRate < 0
        ? colors['text-red-light']
        : colors['text-green-light']
      : colors['text-gray-light']};
`;

const ColorBar = styled.div`
  width: 3px;
  height: 50px;
  border-radius: 1px;
  margin-right: 8px;
`;

const SubscriptionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & + & {
    margin-top: 2px;
  }
`;

const SubscriptionInfoTitle = styled.dt`
  ${fonts.Body4};
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
`;

const SubscriptionInfoValue = styled.dd`
  display: flex;
  align-items: center;
  gap: 4px;
  ${fonts.Body4};
  color: ${({ theme: { colors } }) => colors['text-white']};
`;

const CountGrowthWrapper = styled.div<{ $count: number }>`
  display: flex;
  align-items: center;
  gap: 1px;
  ${fonts.Caption2}
  border-radius: 2px;
  padding: 0px 2px;
  background-color: ${({ $count, theme: { colors } }) => ($count < 0 ? colors['bg-red-light'] : colors['bg-green'])};
  color: ${({ theme: { colors } }) => colors['text-white']};
`;

const BarShape = (props: UnknownObject) => {
  const { fill, width: borderWidth, height: borderHeight, x: borderX, y: borderY, index, isForecasted } = props;
  const borderR = 6;
  const r = 5;
  const width = borderWidth - 2;
  const height = borderHeight - 1;
  const x = borderX + 1;
  const y = borderY + 1;

  if (!y || borderHeight <= 0 || height <= 0) {
    return null;
  }
  return (
    <>
      <defs>
        <clipPath id={`border-round-corner-${index}-${!!isForecasted}`}>
          <rect x={borderX} y={borderY} width={borderWidth} height={borderHeight + borderR} rx={borderR} ry={borderR} />
        </clipPath>
        <clipPath id={`round-corner-${index}-${!!isForecasted}`}>
          <rect x={x} y={y} width={width} height={height + r} rx={r} ry={r} />
        </clipPath>
      </defs>
      <rect
        fill={isForecasted ? 'url(#forecasted-border-gradient)' : 'url(#border-gradient)'}
        width={borderWidth}
        height={borderHeight}
        x={borderX}
        y={borderY}
        clipPath={`url(#border-round-corner-${index}-${!!isForecasted})`}
      />
      <rect
        fill={fill}
        width={width}
        height={height}
        x={x}
        y={y}
        clipPath={`url(#round-corner-${index}-${!!isForecasted})`}
      />
    </>
  );
};

const ReferenceLineLabel = ({ viewBox, offset }: { viewBox?: CartesianViewBox; offset?: number }) => {
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const x = viewBox?.x ?? 0;
  const y = (viewBox?.y ?? 0) - (offset ?? 0);
  const width = i18n.language === 'ko' ? 31 : 51;
  const height = 20;

  return (
    <g x={x} y={y}>
      <rect x={x - width / 2} y={0} width={width} height={height} fill={colors['bg-gray-main']} rx={2} />
      <text x={x} y={y - 5.5} textAnchor="middle" style={{ ...fonts.Caption4 }} fill={colors['text-white']}>
        {t('Subscrib_Detail_Acc_Graph')}
      </text>
    </g>
  );
};
