// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const LogoutIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M3 3.25C3 2.2835 3.7835 1.5 4.75 1.5H10.25C10.6642 1.5 11 1.83579 11 2.25C11 2.66421 10.6642 3 10.25 3H4.75C4.61193 3 4.5 3.11193 4.5 3.25V20.75C4.5 20.8881 4.61193 21 4.75 21H10.25C10.6642 21 11 21.3358 11 21.75C11 22.1642 10.6642 22.5 10.25 22.5H4.75C3.7835 22.5 3 21.7165 3 20.75V3.25Z"
          fill={color}
        />
        <path
          d="M19.0064 12.75L15.7055 16.2342C15.4207 16.5349 15.4335 17.0096 15.7342 17.2945C16.0349 17.5793 16.5096 17.5665 16.7945 17.2658L21.2945 12.5158C21.5685 12.2265 21.5685 11.7735 21.2945 11.4842L16.7945 6.73419C16.5096 6.43349 16.0349 6.42066 15.7342 6.70554C15.4335 6.99041 15.4207 7.46511 15.7055 7.76581L19.0063 11.25H10.75C10.3358 11.25 10 11.5858 10 12C10 12.4142 10.3358 12.75 10.75 12.75H19.0064Z"
          fill={color}
        />
        <path
          d="M2.7998 3.2498C2.7998 2.17285 3.67285 1.2998 4.7498 1.2998H10.2498C10.7745 1.2998 11.1998 1.72513 11.1998 2.2498C11.1998 2.77448 10.7745 3.1998 10.2498 3.1998H4.7498C4.72219 3.1998 4.6998 3.22219 4.6998 3.2498V20.7498C4.6998 20.7774 4.72219 20.7998 4.7498 20.7998H10.2498C10.7745 20.7998 11.1998 21.2251 11.1998 21.7498C11.1998 22.2745 10.7745 22.6998 10.2498 22.6998H4.7498C3.67285 22.6998 2.7998 21.8268 2.7998 20.7498V3.2498Z"
          fill={color}
        />
        <path
          d="M18.5412 12.9498H10.7498C10.2251 12.9498 9.7998 12.5245 9.7998 11.9998C9.7998 11.4751 10.2251 11.0498 10.7498 11.0498H18.5412L15.5602 7.90316C15.1993 7.52228 15.2156 6.92099 15.5964 6.56015C15.9773 6.19931 16.5786 6.21556 16.9395 6.59645L21.4395 11.3464C21.7866 11.7129 21.7866 12.2868 21.4395 12.6532L16.9395 17.4032C16.5786 17.784 15.9773 17.8003 15.5964 17.4395C15.2156 17.0786 15.1993 16.4773 15.5602 16.0964L18.5412 12.9498Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

LogoutIcon.displayName = 'LogoutIcon';
