import styled from '@emotion/styled';
import { Box, Divider } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  title: string;
  children?: ReactNode;
  divider?: boolean;
}
export const SectionElement = ({ title, divider = true, ...props }: Props) => {
  return (
    <Container>
      <ElementBox>
        <ElementTitle component="span">{title}</ElementTitle>
        {React.Children.count(props.children) > 1 ? <Container>{props.children}</Container> : props.children}
      </ElementBox>
      {divider && <Divider sx={{ marginY: '10px' }} />}
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ElementBox = styled(Box)`
  display: flex;
  min-height: 40px;
  align-items: center;
  width: 100%;
  white-space: pre-wrap;
`;

const ElementTitle = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  width: 225px;
  flex-shrink: 0;
`;
