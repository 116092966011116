import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { tenantMemberRepository } from '@repositories/Repository';
import { TenantMemberBulkUpdateDTO } from '@repositories/tenantMemberRepository/Types';
import { TenantMemberSummaryDTOModel } from '@models/TenantMemberSummaryDTOModel';
import { tenantMemberQueryKey } from './useTenant';

const tenantMemberRepo = tenantMemberRepository;

export const useAddBatchUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['batchUSer'],
    mutationFn: async ({ tenantId, rqData }: { tenantId: string; rqData: TenantMemberBulkUpdateDTO }) => {
      const result = await tenantMemberRepo.addBatchMember({ tenantId, rqData });
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
    },
  });
};

export const useGetTenantMemberSummary = (tenantId: string) => {
  return useQuery<TenantMemberSummaryDTOModel, AxiosError>({
    queryKey: ['tenantMemberSummary', tenantId],
    queryFn: async () => {
      const result = await tenantMemberRepo.getMemberSummary(tenantId);
      return new TenantMemberSummaryDTOModel(result);
    },
  });
};
