import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Checkbox, useTheme, Snackbar, Box } from '@mui/material';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { CartIcon, CheckboxIcon, CheckedBgIcon } from '@icons/index';
import CartProductModelMapper from '@repositories/browserStorage/mapper/CartProductModelMapper';
import sessionStorage from '@repositories/browserStorage/sessionStorage/SessionStorage';
import { SubscriptionRequest } from '@repositories/subscriptionRequestRepository';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useAddSubscriptionRequest } from '@queryHooks/useSubscriptionRequest';
import { CartProductModel } from '@models/softwareModels/CartProductModel';
import { ItemOfCart } from './ItemOfCart';

export const Cart = observer(() => {
  const { t } = useTranslation();
  const target = useRef<HTMLDivElement>();

  const [isBoxShadow, setIsBoxShadow] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const theme = useTheme();
  const {
    uiStore: { alertModalStore },
    authStore,
  } = useStore();
  const [cartItems, setCartItems] = useState<CartProductModel[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);

  const { mutate: addSubscriptionRequest } = useAddSubscriptionRequest();

  useEffect(() => {
    const cartProducts = sessionStorage.get<CartProductModel>('cartProduct', CartProductModelMapper);
    if (Array.isArray(cartProducts)) setCartItems(cartProducts);
    else setCartItems([]);
  }, []);

  const handleChangeAllCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const origin = [...cartItems];
    origin.forEach(item => item.setCheck(checked));
    setCartItems(origin);
  };

  const handleChangeCheckbox = (idx: number) => {
    const origin = [...cartItems];
    origin[idx].setCheck(!origin[idx].isChecked);
    setCartItems(origin);
  };
  const handleRemoveCartItem = (idx: number) => {
    const originCartItems = [...cartItems];
    originCartItems.splice(idx, 1);
    setCartItems(originCartItems);

    sessionStorage.set('cartProduct', JSON.stringify(originCartItems));
  };

  const handleConfirmOrderModify = () => {
    const cartProducts = sessionStorage.get<CartProductModel>('cartProduct', CartProductModelMapper);
    if (Array.isArray(cartProducts)) setCartItems(cartProducts);
  };

  const handleClickSelectionRemoveBtn = () => {
    const remainItems = cartItems.filter(item => !item.isChecked);
    setCartItems(remainItems);

    sessionStorage.set('cartProduct', JSON.stringify(remainItems));
  };

  const handleClickSubscriptionRequestBtn = () => {
    // 로그인을 안햇을 때
    if (!authStore.isLogin) {
      alertModalStore.open('로그인이 필요합니다.');
    }
    // 로그인을 했을 때
    else {
      const rqData: SubscriptionRequest[] = cartItems
        .filter(item => item.isChecked)
        .map(item => {
          return {
            inquiry: item.request,
            softwareId: item.productId,
            productPlanId: item.planId,
            timeZone: 'Asia/Seoul',
          };
        });

      addSubscriptionRequest(
        { tenantId: authStore.curTenant.id, subscriptionRequests: rqData },
        {
          onSuccess: () => {
            const remainItems = cartItems.filter(item => !item.isChecked);
            setCartItems(remainItems);
            sessionStorage.set('cartProduct', JSON.stringify(remainItems));
            setIsToastOpen(true);
          },
        },
      );
    }
  };

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting) {
        setIsScrollBottom(true);
      } else {
        setIsScrollBottom(false);
      }
    },
  });

  const debouncedHandleScroll = debounce(() => {
    const isScrolled = (target.current?.scrollTop ?? 0) > 0;

    if (isScrolled !== hasScroll) {
      setHasScroll(isScrolled);
    }
  }, 200);

  useEffect(() => {
    const containerRef = target.current;
    const windowSubTopHeight = window.innerHeight - (containerRef?.getBoundingClientRect().top ?? 0);
    const targetScrollHeight = containerRef?.scrollHeight;
    containerRef?.addEventListener('scroll', debouncedHandleScroll);

    if (targetScrollHeight && windowSubTopHeight < targetScrollHeight) setIsBoxShadow(true);
    else if (targetScrollHeight && windowSubTopHeight >= targetScrollHeight) setIsBoxShadow(false);
    return () => {
      containerRef?.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  return (
    <>
      <Container ref={target}>
        <Body>
          <Title>{t('Ad_Cart_01')}</Title>
          <SummaryInfoWrapper>
            {t('Acc_Main_12')}
            <span>{cartItems.length}</span>
          </SummaryInfoWrapper>
          <ListHeaderWrapper>
            <AllSelectWrapper>
              <Checkbox
                size="small"
                icon={
                  <CheckedBgIcon
                    width={20}
                    height={20}
                    color={theme.colors['border-gray-light']}
                    bgColor={cartItems.length > 0 ? theme.colors['bg-white'] : theme.colors['bg-gray-light']}
                  />
                }
                disabled={cartItems.length === 0}
                checkedIcon={
                  <CheckboxIcon
                    width={20}
                    height={20}
                    bgColor={theme.colors['bg-purple']}
                    color={theme.colors['bg-white']}
                  />
                }
                onChange={handleChangeAllCheckbox}
              />
              <span>{t('Ad_Cart_03')}</span>
            </AllSelectWrapper>
            <ChoiceDeleteButton
              variant="text"
              onClick={handleClickSelectionRemoveBtn}
              size="small"
              paddingHorizontal={12}
            >
              {t('Ad_Cart_04')}
            </ChoiceDeleteButton>
          </ListHeaderWrapper>
          {cartItems.length === 0 ? (
            <EmptyCart />
          ) : (
            <ListsWrapper>
              {cartItems.map((product, idx) => (
                <ItemOfCart
                  itemChecked={product.isChecked}
                  key={nanoid()}
                  item={product}
                  onChangeCheckbox={() => handleChangeCheckbox(idx)}
                  onRemoveCartItem={() => handleRemoveCartItem(idx)}
                  onConfirmOrderModify={handleConfirmOrderModify}
                />
              ))}
            </ListsWrapper>
          )}
          {hasScroll && <div ref={ref} />}
          {cartItems.length !== 0 && (
            <PurchaseButton
              paddingHorizontal={10}
              className={isScrollBottom && hasScroll ? 'scroll-bottom' : isBoxShadow ? 'bottom-shadow' : ''}
              variant="contain"
              size="2extraLarge"
              onClick={() => setIsDialogOpen(true)}
              disabled={!cartItems.filter(item => item.isChecked).length}
            >
              {t('Ad_Cart_10', { number: cartItems.filter(item => item.isChecked === true).length })} {t('Ad_Cart_11')}
            </PurchaseButton>
          )}
        </Body>
      </Container>
      <Dialog
        title={t('Subscrib_Detail_BuyRequest_09')}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        buttonProps={{ text: `${t('Ad_Cart_14')}`, onClick: handleClickSubscriptionRequestBtn }}
      >
        <RequestConfirm>
          <p>{t('Ad_Cart_13', { number: cartItems.filter(item => item.isChecked === true).length })}</p>
        </RequestConfirm>
      </Dialog>
      <Snackbar
        open={isToastOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setIsToastOpen(false)}
        message={<SnackbarMessage />}
        sx={{
          '& .MuiPaper-root': {
            padding: '14px 24px',
          },
          '& .MuiSnackbarContent-message': {
            padding: 0,
            marginRight: '80px',
          },
          '& .MuiSnackbarContent-action': {
            margin: 0,
            padding: 0,
          },
        }}
        action={
          <ToastCloseButton variant="text" size="small" onClick={() => setIsToastOpen(false)}>
            {t('Member_User_24')}
          </ToastCloseButton>
        }
      />
    </>
  );
});

const SnackbarMessage = () => {
  const { t } = useTranslation();
  return <div>{t('Ad_Store_Purchase_Request_Complete')}</div>;
};

const EmptyCart = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { authStore } = useStore();

  const handleGoToStoreClick = () => {
    navigate(`/manager/${authStore.managerTenantInfo.id}/store`);
  };

  return (
    <EmptyCartContainer>
      <CartIcon width={88} height={88} color={colors['ic-gray-lighter']} />
      <strong>{t('Ad_Cart_05')}</strong>
      <p className="">{t('Ad_Cart_06')}</p>
      <Button variant="contain" size="extraLarge" color="purple" paddingHorizontal={24} onClick={handleGoToStoreClick}>
        {t('Ad_Cart_07')}
      </Button>
    </EmptyCartContainer>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  overflow-y: auto;
  height: calc(100vh - var(--manager-gnb-height) - var(--employee-sub-gnb-height));
`;

const Body = styled(Box)`
  position: relative;
  max-width: 1320px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0;
  margin: 60px 0 0;
`;

const Title = styled('strong')`
  text-align: center;
  ${fonts.Headline4};
  margin-bottom: 60px;
`;

const SummaryInfoWrapper = styled('strong')`
  ${fonts.Headline7};
  span {
    color: ${({ theme }) => theme.colors['text-purple-light']};
    margin-left: 4px;
  }
  margin-bottom: 10px;
`;

const ListHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-width: 1px 0 1px;
  margin-bottom: 28px;
`;

const AllSelectWrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  span {
    margin-left: 12px;
    ${fonts.Body2};
  }
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 8px 0 22px;
    background-color: ${({ theme }) => theme.colors['border-gray-light']};
  }
`;

const ChoiceDeleteButton = styled(Button)`
  & span {
    ${fonts.Button4};
  }
`;

const ListsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RequestConfirm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    ${fonts.Paragraph1}
    white-space: pre;
    & > strong {
      ${fonts.Headline7}
      color: ${({ theme: { colors } }) => colors['text-purple-light']}
    }
  }
`;

const EmptyCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0px 96px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};

  & strong {
    ${fonts.Headline5}
    margin: 32px 0px 4px;
  }
  & p {
    ${fonts.Body1}
    margin-bottom: 50px;
  }
`;

const ToastCloseButton = styled(Button)`
  ${fonts.Body2};
  color: ${({ theme }) => theme.colors['bg-blue-dark-40']};
`;

const PurchaseButton = styled(Button)`
  position: sticky;
  left: 50%;
  bottom: 24px;
  transform: translate(0, 0);
  width: 100%;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors['text-white']};
  background: ${({ theme }) => theme.colors['bg-gradient']};

  &:disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    pointer-events: none;
  }

  & strong {
    ${fonts.Headline5};
    margin: 0 2px 0 8px;
  }
  transition: margin-top 0.4s ease;

  &.scroll-bottom {
    margin-top: 40px;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  }
  &.bottom-shadow {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  }
`;
