import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@components/dropdown';
import { colorDefinition } from '@theme/colorsCustomer';
import { useGetUserStat } from '@queryHooks/useUserStat';
import i18n from '@locales/i18n';
import { BigCardContent } from '../BigCardContent';
import { CardWgContainer, CardWgContents } from '../styles';

export const PayingUser5Wg = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<'product' | 'subscription'>('product');

  const handleChangeType = (value: string | number) => {
    if (value === 'product' || value === 'subscription') {
      setFilter(value);
    }
  };

  const { data: userStat } = useGetUserStat();

  const colorSet = [
    {
      start: colorDefinition.purple[300],
      end: colorDefinition.purple[500],
    },
    {
      start: colorDefinition.purple[200],
      end: colorDefinition.purple[400],
    },
    {
      start: colorDefinition.purple[100],
      end: colorDefinition.purple[300],
    },
    {
      start: colorDefinition.pink[100],
      end: colorDefinition.pink[300],
    },
    {
      start: colorDefinition.pink[200],
      end: colorDefinition.pink[400],
    },
  ];

  return (
    <CardWgContainer>
      <div className="title">
        과금 사용자 수 TOP 5
        <Dropdown
          variant="no-line"
          size="small"
          value={filter}
          onChange={handleChangeType}
          width={i18n.language === 'ko' ? '95px' : '109px'}
        >
          <Dropdown.Menu value="product">{t('Ad_Analysis_fileter_01')}</Dropdown.Menu>
          <Dropdown.Menu value="subscription">{t('Ad_Analysis_fileter_02')}</Dropdown.Menu>
        </Dropdown>
      </div>
      <CardWgContents className="bigger">
        {filter === 'product'
          ? userStat?.billableUserBySoftwareTop5?.map((el, idx, origin) => {
              return (
                <BigCardContent
                  key={el.softwareId}
                  index={idx}
                  iconUrl={el.iconUrl}
                  name={el.softwareName}
                  userCount={el.billableUserCount}
                  progressPercentage={
                    (el.billableUserCount / origin.reduce((acc, cur) => acc + cur.billableUserCount, 0)) * 100
                  }
                  progressColor={colorSet[idx]}
                />
              );
            })
          : userStat?.billableUserBySubscriptionTop5?.map((el, idx, origin) => {
              return (
                <BigCardContent
                  key={el.subscriptionId}
                  index={idx}
                  iconUrl={el.iconUrl}
                  name={el.subscriptionName}
                  userCount={el.billableUserCount}
                  progressPercentage={
                    (el.billableUserCount / origin.reduce((acc, cur) => acc + cur.billableUserCount, 0)) * 100
                  }
                  progressColor={colorSet[idx]}
                />
              );
            })}
      </CardWgContents>
    </CardWgContainer>
  );
};
