import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

export const ModalWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 644px;
  max-height: 792px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border: 1px solid #fff;
  border-radius: 6px;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: inherit;
  padding: 0px 17px 0px 28px;
`;

export const HeaderTitle = styled(Box)`
  ${fonts.Headline6}
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 12px 28px 28px;
  max-height: 632px;
  overflow-y: auto;
  width: 100%;
  & .info {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 16px;
  }
  & .description {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
    padding: 12px 8px;
    border-radius: 3px;
    justify-content: center;
    & > li {
      display: flex;
      align-items: baseline;
      white-space: pre-wrap;
      gap: 4px;
      ${fonts.Body4}
      color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']}
    }
  }
`;

export const EssentialInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  & .infoTitle {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
    margin-bottom: 10px;
  }
`;

export const InfoKey = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2px;
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin-bottom: 4px;
  & > span {
    margin-top: -3px;
    color: ${({ theme: { colors } }) => colors['text-red-light']};
  }
`;

export const OptionalInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  & .infoTitle {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
    margin-bottom: 10px;
  }

  & .groups {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
  }
  & .group-item {
    display: flex;
    align-items: center;
    gap: 6px;
    & .icon-btn {
      padding: 10px 10px;
    }
  }
  & .group {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    & .add {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
      border-radius: 2px;
      :hover {
        cursor: pointer;
      }
    }
    & .delete {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
      border-radius: 2px;
      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const Footer = styled(Box, transientOptions)<{ $hasScrollbar: boolean }>`
  padding: 24px 28px 28px;
  border-top: ${({ $hasScrollbar, theme: { colors } }) =>
    $hasScrollbar ? `1px solid ${colors['border-gray-lighter']}` : 'none'};
`;
