import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { KEYS, repository } from '@repositories/Repository';
import { IUserStatRepository } from '@repositories/userStatRepository/IUserStatRepository';
import { DailyUserStatByUserStatusDTOModel } from '@models/DailyUserStatByUserStatusDTOModel';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const userStatRepo = repository.get<IUserStatRepository>(KEYS.USER_STAT_REPOSITORY);

export const useGetUserStat = (options?: UseQueryOptionsType<DailyUserStatByUserStatusDTOModel>) => {
  return useQuery<DailyUserStatByUserStatusDTOModel, AxiosError>({
    queryKey: ['userStat'],
    queryFn: async () => {
      const result = await userStatRepo.getStat();
      return new DailyUserStatByUserStatusDTOModel(result);
    },
    ...options,
  });
};
