import type { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Chip, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { AddIcon } from '@icons/AddIcon';
import { TriangleIcon } from '@icons/TriangleIcon';
import { XIcon } from '@icons/XIcon';
import type { LogicalOperator, WorkflowTriggerConditionCreateDTO } from '@repositories/workflowRepository/Types';
import { useGetWorkflowBaseTrigger } from '@queryHooks/useWorkflowBaseTrigger';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';
import type { SoftwareSimpleModel } from '@models/softwareModels';
import type { UserGroupSimpleModel } from '@models/userGroupModels/UserGroupSimpleModel';
import type { WorkflowBaseTriggerDTOModel } from '@models/workflow/WorkflowBaseTriggerDTOModel';
import type { WorkflowBaseTriggerFieldDTOModel } from '@models/workflow/WorkflowBaseTriggerFieldDTOModel';
import GroupAutocomplete from '../../createAndDetail/GroupAutocomplete';
import { ProductAutocomplete } from '../../createAndDetail/ProductAutocomplete';
import SubscriptionAutocomplete from '../../createAndDetail/SubscriptionAutocomplete';

interface Props {
  title: string;
  condition: WorkflowTriggerConditionCreateDTO;
  baseTrigger: WorkflowBaseTriggerDTOModel;
  isEditable: boolean;
  isLogicalOperatorDisabled: boolean;
  onChangeLogicalOperator?: (operator: LogicalOperator) => void;
  onChangeField?: (field: WorkflowBaseTriggerFieldDTOModel) => void;
  onChangeValue?: (value: string | undefined) => void;
  onClickAddBtn?: () => void;
  onClickRemoveBtn?: () => void;
}

export const ConditionSetting = ({
  title,
  condition,
  baseTrigger,
  isEditable,
  isLogicalOperatorDisabled,
  onChangeLogicalOperator,
  onChangeField,
  onChangeValue,
  onClickAddBtn,
  onClickRemoveBtn,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: triggerDetail } = useGetWorkflowBaseTrigger(baseTrigger.id as string);

  const handleChangeLogicalOperator = (event: SelectChangeEvent<'AND' | 'OR'>) => {
    if (!event) return;

    if (onChangeLogicalOperator) {
      onChangeLogicalOperator(event.target.value as LogicalOperator);
    }
  };

  const handleClickTriggerField = (field: WorkflowBaseTriggerFieldDTOModel) => {
    if (onChangeField) onChangeField(field);
  };

  const handleChangeGroupValue = (group: UserGroupSimpleModel) => {
    if (onChangeValue) onChangeValue(group.userGroupId);
  };

  const handleChangeSubscriptionValue = (subscription: SubscriptionListModel) => {
    if (onChangeValue) onChangeValue(subscription.id);
  };

  const handleChangeProductValue = (product: SoftwareSimpleModel | null) => {
    if (onChangeValue) onChangeValue(product?.id ?? undefined);
  };

  const handleChangeDefaultValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue) onChangeValue(event.target.value);
  };

  const handleClickAddBtn = () => {
    if (onClickAddBtn) onClickAddBtn();
  };

  const handleClickRemoveBtn = () => {
    if (onClickRemoveBtn) onClickRemoveBtn();
  };

  const renderTriggerField = () => {
    switch (condition.fieldName) {
      case '':
        return (
          <TriggerField>
            <span>{t('Workflow_Create_22')}</span>
            <TriangleIcon rotateNum={-180} width={20} height={20} color={theme.colors['ic-gray-lighter']} />
          </TriggerField>
        );
      case 'userGroupId':
        return <GroupAutocomplete value={condition.value} onChange={handleChangeGroupValue} />;
      case 'subscriptionId':
        return <SubscriptionAutocomplete value={condition.value} onChange={handleChangeSubscriptionValue} />;
      case 'productId':
        return <ProductAutocomplete value={condition.value} onChange={handleChangeProductValue} />;
      default:
        return <TextField fullWidth size="medium" onChange={handleChangeDefaultValue} value={condition.value} />;
    }
  };

  return (
    <>
      <Content>
        <TriggerHeaderContainer>
          <ConfigNumber>
            <span>{title}</span>
          </ConfigNumber>
          <IconButton onClick={handleClickRemoveBtn}>
            <XIcon width={20} height={20} color={theme.colors['ic-gray-dark']} />
          </IconButton>
        </TriggerHeaderContainer>
        <TriggerConfigContainer>
          <Select
            value={!isLogicalOperatorDisabled ? condition.logicalOperator : undefined}
            onChange={handleChangeLogicalOperator}
            disabled={isLogicalOperatorDisabled}
            size="medium"
            className="operator-select"
          >
            <MenuItem value="OR">OR</MenuItem>
            <MenuItem value="AND">AND</MenuItem>
          </Select>
          <Select
            value={condition.fieldName}
            size="medium"
            fullWidth
            className="trigger-select"
            displayEmpty
            renderValue={v => {
              return v === ''
                ? t('Workflow_Create_21')
                : triggerDetail?.fields.find(field => field.name === v)?.displayName;
            }}
            MenuProps={{
              sx: {
                marginTop: '-4px',
                '& .MuiButtonBase-root:hover': {
                  backgroundColor: `${theme.colors['state-white-hover']}`,
                },
              },
            }}
            disabled={!isEditable}
          >
            {triggerDetail?.fields
              ?.filter(field => field.isConditionValue)
              .map((field, fieldsIdx) => {
                return (
                  <MenuItem
                    key={field.name + String(fieldsIdx)}
                    value={field.name}
                    onClick={() => handleClickTriggerField(field)}
                  >
                    {field.displayName}
                  </MenuItem>
                );
              })}
          </Select>
          {renderTriggerField()}
        </TriggerConfigContainer>
      </Content>
      <Chip
        onClick={handleClickAddBtn}
        icon={<AddIcon />}
        size="small"
        sx={{
          pl: 1,
          pr: 1,
          '& .MuiChip-label': {
            display: 'none', // 레이블 제거
          },
        }}
      />
    </>
  );
};

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 24px;
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-radius: 4px;
`;

const ConfigNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${fonts.Headline8}
`;

const TriggerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TriggerConfigContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  & .operator-select {
    min-width: 90px;
  }
  & .trigger-select {
    width: 188px;
    max-height: 40px;
    flex-shrink: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors['bg-white']};
    & .MuiSelect-select {
      padding: 13px 11px 13px 19px;
      ${fonts.Button4};
      color: ${({ theme }) => theme.colors['text-gray-main']};
    }
  }
  & .autocomplete {
    & .MuiAutocomplete-inputRoot {
      padding: 0;
      height: 40px;
    }
    & .MuiAutocomplete-input {
      padding: 14px 19px;
      &.MuiOutlinedInput-root .MuiAutocomplete-input {
        padding: 14px 19px;
      }
    }
  }
`;

const TriggerField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.colors['bg-gray-lighter']};
  padding: 13px 11px 13px 19px;
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  border-radius: 2px;
  & span {
    ${fonts.Button4};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;
