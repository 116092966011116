import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import qs from 'qs';
import ICompanyRepository from './ICompanyRepository';
import { Company, CompanyCreateRq, CompanyFilter, CompanyUpdateRq } from './Types';

@injectable()
export class CompanyRepository implements ICompanyRepository {
  async create(data: CompanyCreateRq): Promise<Company> {
    const result = await API.post<Company>('/api/v1/companies', data);
    return result.data;
  }
  async getOne(id: number): Promise<Company> {
    const result = await API.get<Company>(`/api/v1/companies/${id}`);
    return result.data;
  }
  async getList(filter: CompanyFilter): Promise<Page<Company>> {
    const queryString = qs.stringify(filter, { skipNulls: true });
    const result = await API.get<Page<Company>>(`/api/v1/companies?${queryString}`);
    return result.data;
  }
  async update(id: number, data: CompanyUpdateRq): Promise<Company> {
    const result = await API.put<Company>(`/api/v1/companies/${id}`, data);
    return result.data;
  }
  async delete(id: number): Promise<void> {
    await API.delete(`/api/v1/companies/${id}`);
  }
}
