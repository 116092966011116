const defaultWidth = 24;
const defaultHeight = 25;

export const StoreFillIcon = ({ width = defaultWidth, height = defaultHeight, color = '#fff' }: SvgProps) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <g filter="url(#filter0_d_1737_6042)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.14138 7.68411C2.20249 6.73696 2.98852 6 3.93765 6H20.0625C21.0116 6 21.7977 6.73696 21.8588 7.68411L22.7298 21.1841C22.7967 22.2217 21.9732 23.1 20.9335 23.1H3.06668C2.02697 23.1 1.20347 22.2217 1.27041 21.1841L2.14138 7.68411Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.804 13.4332L9.87194 11.788C9.49925 11.4069 8.89222 11.4069 8.51827 11.788C8.51827 11.788 7.25311 13.1727 7.15962 13.2617C7.06613 13.3506 6.99746 13.4533 7.00007 13.5869C7.00007 13.5869 7.00007 16.0552 7.00007 16.2178C7.00007 16.3804 7.11024 16.4698 7.11024 16.4698C7.14912 16.4935 7.1849 16.4964 7.23094 16.4698L9.36892 14.3355C9.57584 14.1246 9.91114 14.1246 10.1181 14.3355C10.2053 14.4244 10.2564 14.5362 10.2701 14.6531L11.804 13.4332Z"
            fill="url(#paint0_linear_1737_6042)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.804 13.4332L9.87194 11.788C9.49925 11.4069 8.89222 11.4069 8.51827 11.788C8.51827 11.788 7.25311 13.1727 7.15962 13.2617C7.06613 13.3506 6.99746 13.4533 7.00007 13.5869C7.00007 13.5869 7.00007 16.0552 7.00007 16.2178C7.00007 16.3804 7.11024 16.4698 7.11024 16.4698C7.14912 16.4935 7.1849 16.4964 7.23094 16.4698L9.36892 14.3355C9.57584 14.1246 9.91114 14.1246 10.1181 14.3355C10.2053 14.4244 10.2564 14.5362 10.2701 14.6531L11.804 13.4332Z"
            fill="url(#paint1_linear_1737_6042)"
          />
          <ellipse cx="7.95112" cy="17.1611" rx="0.195259" ry="0.193612" fill="#CC165F" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2734 11.7858C13.6472 11.4047 14.2534 11.4047 14.6272 11.7858L17.2193 14.4276C17.5932 14.8086 17.5932 15.4263 17.2193 15.8073C17.2193 15.8073 15.8608 17.0978 15.7732 17.1925C15.6856 17.2873 15.5303 17.3572 15.4544 17.3551H12.4115C12.3023 17.3202 12.3897 17.2077 12.4115 17.1945L14.7019 14.9232C14.9087 14.7124 14.9087 14.3706 14.7019 14.1597C14.495 13.9489 14.1596 13.9489 13.9527 14.1597L10.987 17.2004C10.987 17.2004 10.8352 17.3551 10.6679 17.3551C10.5007 17.3551 7.95552 17.3551 7.95552 17.3551C7.81841 17.3572 7.81083 17.0278 7.81212 17.0265C9.93975 14.8476 13.2734 11.7858 13.2734 11.7858Z"
            fill="url(#paint2_linear_1737_6042)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.2002 6.8C6.2002 3.59675 8.79694 1 12.0002 1C15.2034 1 17.8002 3.59675 17.8002 6.8H17.0002V7.6H7.0002V6.8H6.2002ZM7.87629 6H16.1241C15.7506 4.06302 14.0462 2.6 12.0002 2.6C9.95416 2.6 8.24984 4.06302 7.87629 6Z"
            fill={color}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1737_6042"
            x="0.266602"
            y="0.5"
            width="23.4668"
            height="24.1"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.5" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1737_6042" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1737_6042" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_1737_6042"
            x1="7.79518"
            y1="12.6404"
            x2="9.91163"
            y2="14.8756"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4A86F5" />
            <stop offset="1" stopColor="#771BE8" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1737_6042"
            x1="11.3052"
            y1="13.7774"
            x2="10.5952"
            y2="13.1414"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.5" />
            <stop offset="0.0001" stopOpacity="0.8" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1737_6042"
            x1="13.0615"
            y1="12.8009"
            x2="16.6284"
            y2="15.8246"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CB155F" />
            <stop offset="1" stopColor="#EFB44B" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  );
};

StoreFillIcon.displayName = 'StoreFillIcon';
