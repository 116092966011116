import type { FormContactUs } from './validationSchema';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Modal, TextField, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ManagerFillIcon } from '@icons/ManagerFIllIcon';
import { CloseIcon } from '@icons/index';
import type { InquiryCreateRq } from '@repositories/inquiryRepository';
import { useGetSoftware, useGetUser } from '@queryHooks/index';
import { useAddInquiry } from '@queryHooks/useInquiry';
import { useFormContactUs } from './validationSchema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  swId: string;
}

export const ContactUs = observer(({ isOpen, onClose, swId }: Props) => {
  const { t } = useTranslation();
  const {
    authStore,
    uiStore: { toastStore },
  } = useStore();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMemoOver, setIsMemoOver] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useFormContactUs();
  const { data: software } = useGetSoftware(swId);
  const { data: user } = useGetUser(authStore.id, { enabled: !!authStore.isLogin });
  const { mutate: addInquiry } = useAddInquiry();

  const validation = {
    success: (data: FormContactUs) => {
      const req: InquiryCreateRq = {
        name: user ? user.name : data.name,
        email: user ? user.email : data.email,
        phone: user ? user.phone : data.phone,
        companyName: data.companyName,
        inquiry: data.inquiry,
        softwareId: software.id,
      };
      addInquiry(req, {
        onSuccess: () => {
          toastStore.open('상품 문의를 완료했습니다.');
          onClose();
        },
      });
    },
  };

  const handleChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value.length > 300) {
      setValue('inquiry', value.slice(0, 300));
      setIsMemoOver(true);
    } else {
      setIsMemoOver(false);
    }
  };

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ '& .MuiBackdrop-root': { background: 'transparent' } }}>
      <ModalFormWrapper onSubmit={handleSubmit(validation.success)}>
        <TitleContainer>
          <strong>상품 문의</strong>
          <IconButton onClick={onClose} className="mobile-close-btn">
            <CloseIcon width={24} height={24} />
          </IconButton>
        </TitleContainer>
        <BodyContainer height={!authStore.isLogin ? '770px' : undefined}>
          <Explanation>
            <ManagerFillIcon width={16} height={16} color={theme.colors['ic-purple-light']} />
            <ExplanationText>담당자가 확인 후 연락드릴 예정입니다.</ExplanationText>
          </Explanation>
          <SubTitle>
            상품명<span>*</span>
          </SubTitle>
          <SoftwareName>{software.name}</SoftwareName>
          {!authStore.isLogin && (
            <>
              <SubTitle>
                회사 이름<span>*</span>
              </SubTitle>
              <InquiryField
                className={errors.companyName && 'form-error'}
                {...register('companyName')}
                size="medium"
                placeholder={t('Product_Inquiry_08')}
                error={!!errors.companyName?.message}
                helperText={errors.companyName?.message}
              />
              <SubTitle>
                담당자 이름<span>*</span>
              </SubTitle>
              <InquiryField
                className={errors.name && 'form-error'}
                {...register('name')}
                size="medium"
                placeholder={t('GNB_Profile_MyProfile_14')}
                error={!!errors.name?.message}
                helperText={errors.name?.message}
              />
              <SubTitle>
                회사 이메일<span>*</span>
              </SubTitle>
              <InquiryField
                className={errors.email && 'form-error'}
                {...register('email')}
                size="medium"
                placeholder={t('Product_Inquiry_09')}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
              <SubTitle>
                {t('Member_User_16')}
                <span>*</span>
              </SubTitle>
              <InquiryField
                className={errors.phone && 'form-error'}
                {...register('phone')}
                size="medium"
                placeholder={t('Product_Inquiry_10')}
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
              />
            </>
          )}
          <InquiryBox>
            <SubTitle>
              문의 내용<span>*</span>
            </SubTitle>
            <InquiryTextField
              {...register('inquiry', { onChange: handleChangeTextArea })}
              placeholder="문의 내용을 작성해주세요."
              isMemoOver={isMemoOver}
              isLogin={authStore.isLogin}
              error={!!errors.inquiry?.message}
            />
            <InquiryCount>
              {watch('inquiry') ? watch('inquiry').length : 0} <span>/ 300</span>
            </InquiryCount>
          </InquiryBox>
          {errors.inquiry?.message && <ErrorText>{errors.inquiry?.message}</ErrorText>}
        </BodyContainer>
        <ButtonContainer>
          <SubmitButton variant="contain" size="extraLarge" type="submit">
            완료
          </SubmitButton>
          <CloseButton variant={matches ? 'contain' : 'outline'} size="extraLarge" onClick={onClose}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </CloseButton>
        </ButtonContainer>
      </ModalFormWrapper>
    </Modal>
  );
});

const ModalFormWrapper = styled.form`
  width: 480px;
  position: fixed;
  right: 20px;
  bottom: 17px;
  transform: translate(0, 0);
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  } ;
`;
const TitleContainer = styled(Box)`
  padding: 18px 28px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  & strong {
    ${fonts.Headline6};
  }
`;
const BodyContainer = styled(Box)`
  padding: 4px 28px 30px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    height: 100%;
    padding: 12px 24px 24px;
  } ;
`;
const ButtonContainer = styled(Box)`
  display: flex;
  margin-top: 30px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    position: relative;
    margin-top: 0;
    padding: 0;
    gap: 0;
    &::before {
      position: absolute;
      top: -16px;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 16px;
      background: ${({ theme }) => theme.colors['bg-w-gradient']};
    }
  } ;
`;
const SubTitle = styled('strong')`
  ${fonts.Headline8};
  margin-bottom: 6px;
  & span {
    margin-left: 2px;
    color: ${({ theme }) => theme.colors['text-red-light']};
  }
`;
const SoftwareName = styled('div')`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
  border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
  padding: 9px 12px;
  border-radius: 2px;
  margin-bottom: 24px;
  ${fonts.Body2};
`;

const InquiryField = styled(TextField)`
  ${fonts.Body2}
  margin-bottom: 24px;

  & > div {
    height: 48px;
    flex-shrink: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }

  &.form-error {
    margin-bottom: 60px;
  }
`;

const InquiryTextField = styled.textarea<{ isMemoOver: boolean; isLogin: boolean; error: boolean }>`
  ${fonts.Body2}
  width: 100%;
  padding: 6px 12px;
  height: ${({ isLogin }) => (isLogin ? '240px' : '160px')};
  background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  resize: none;
  border: 1px solid
    ${({ theme, isMemoOver, error }) =>
      isMemoOver || error ? theme.colors['border-red-light'] : theme.colors['border-gray-lighter']};
  border-radius: 2px;
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'lg')} {
    display: none;
    &.mobile-close-btn {
      width: 28px;
      height: 28px;
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  } ;
`;

const SubmitButton = styled(Button)`
  flex: 1;
  height: 54px;
  background: ${({ theme }) => theme.colors['bg-gradient']};
  border-radius: 0;
  border-bottom-left-radius: 6px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: 50%;
    height: 54px;
  }
  &:hover {
    background: ${({ theme: { colors } }) => colors['state-gradient-hover']};
  }
`;
const CloseButton = styled(Button)`
  flex: 1;
  height: 54px;
  background: ${({ theme }) => theme.colors['bg-gray-light']};
  border-radius: 0;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-lighter']};
  border-bottom-right-radius: 6px;
  ${({ theme }) => theme.breakpoints.between('xxs', 'sm')} {
    width: 50%;
    height: 54px;
  } ;
`;

const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: initial;
  gap: 6px;
  background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
  border-radius: 3px;
  padding: 12px 20px;
`;

const ExplanationText = styled.p`
  ${fonts.Body2}
  color: ${({ theme }) => theme.colors['text-purple']};
`;

const InquiryBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const InquiryCount = styled.span`
  ${fonts.Body4};
  position: absolute;
  right: 10px;
  bottom: 5px;
  margin: 0;
  span {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const ErrorText = styled.span`
  ${fonts.Body4}
  color: ${({ theme }) => theme.colors['text-red-light']};
  margin-top: 4px;
`;
