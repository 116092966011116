import { injectable } from 'inversify';
import { User } from '@repositories/userRepository';
import API from '@repositories/Axios';
import { IGoogleOauthRepository } from './IGoogleOauthRepository';
import 'reflect-metadata';
import { AuthCodeDto } from './Types';

@injectable()
export class GoogleOauthRepository implements IGoogleOauthRepository {
  async signIn(rqData: AuthCodeDto): Promise<User> {
    const result = await API.post<User>(`/api/v1/actions/google/sign-in/redirect`, rqData);
    return result.data;
  }
}
