import type { UseFormProps } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type FormAdditionalCommitmentRequest = {
  usageMetricId: string;
  type: string;
  committedUsage: number | null;
  usageUnitPrice: number | null;
  discountRate: number;
  usageUnitPriceWhenExceed: number;
};

export const useAdditionalCommitmentForm = () => {
  const { t } = useTranslation();

  const validationUserSchema = Yup.object().shape({
    usageMetricId: Yup.string().required(t('Ad_Store_Purchase_Request_02')),
    type: Yup.string().required(t('Ad_Store_Purchase_Request_02')),
    committedUsage: Yup.number()
      .transform((_, ov) => (ov ? Number(ov) : undefined))
      .typeError(t('Subscrib_Detail_License_13'))
      .nullable(),
    usageUnitPrice: Yup.number()
      .transform((_, ov) => (ov ? Number(ov) : undefined))
      .typeError(t('Subscrib_Detail_License_13'))
      .nullable(),
    discountRate: Yup.number()
      .transform((_, ov) => (ov ? Number(ov) : undefined))
      .typeError(t('Subscrib_Detail_License_13'))
      .nullable(),
    usageUnitPriceWhenExceed: Yup.number()
      .transform((_, ov) => (ov ? Number(ov) : undefined))
      .typeError(t('Subscrib_Detail_License_13'))
      .nullable(),
  });

  const additionalCommitmentAddFormOptions: UseFormProps<FormAdditionalCommitmentRequest> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };

  return useForm<FormAdditionalCommitmentRequest>(additionalCommitmentAddFormOptions);
};
