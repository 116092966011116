const defaultWidth = 16;
const defaultHeight = 16;

const CircleUpFillIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#34D058',
  rotateNum = 0,
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotateNum}deg)` }}
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.533325 8.00011C0.533325 3.87639 3.87627 0.533447 7.99999 0.533447C12.1237 0.533447 15.4667 3.87639 15.4667 8.00011C15.4667 12.1238 12.1237 15.4668 7.99999 15.4668C3.87627 15.4668 0.533325 12.1238 0.533325 8.00011Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.61905 6.89187L9.9449 8.19904C10.1863 8.437 10.5756 8.437 10.817 8.19904C11.061 7.95849 11.061 7.56649 10.817 7.32594L8.43606 4.97852C8.19469 4.74056 7.80531 4.74056 7.56395 4.97852L5.18299 7.32594C4.939 7.56649 4.939 7.95849 5.18299 8.19904C5.42435 8.437 5.81374 8.437 6.0551 8.19904L7.38095 6.89187L7.38095 10.8517C7.38095 11.1958 7.66258 11.4667 8 11.4667C8.33742 11.4667 8.61905 11.1958 8.61905 10.8517L8.61905 6.89187Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CircleUpFillIcon;
