import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { BriefcaseIcon } from '@icons/BriefcaseIcon';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import { ClockIcon } from '@icons/ClockIcon';
import { FlagIcon } from '@icons/FlagIcon';
import { FolderIcon } from '@icons/FolderIcon';
import { GroupIcon } from '@icons/GroupIcon';
import { InfoIcon } from '@icons/InfoIcon';
import { ManagerIcon } from '@icons/ManagerIcon';
import ToolsIcon from '@icons/ToolsIcon';
import { dateFormat } from '@utils/dateUtil';
import { type SubscriptionModel } from '@models/SubscriptionModel';

type Props = {
  subscription: SubscriptionModel;
};
export const BasicInfoTabPanel = ({ subscription }: Props) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <Container>
      <dl>
        <DataRow>
          <DataKey>
            <FolderIcon width={20} height={20} color={colors['ic-gray-light']} />
            제품 구분
          </DataKey>
          <DataValue>{subscription.softwareCategoryName}</DataValue>
        </DataRow>
        <DataRow>
          <DataKey>
            <FlagIcon width={20} height={20} color={colors['ic-gray-light']} />
            플랜(요금제)
          </DataKey>
          <DataValue>{subscription.plan.planName}</DataValue>
        </DataRow>
        <DataRow>
          <DataKey>
            <ClockIcon width={20} height={20} color={colors['ic-gray-light']} />
            플랜 옵션
          </DataKey>
          <DataValue>{`${subscription.commitmentPeriod} / ${subscription.paymentPeriod}`}</DataValue>
        </DataRow>
        <DataRow $isMultipleRow>
          <DataKey>
            <CalendarStartIcon width={20} height={20} color={colors['ic-gray-light']} />
            구독 기간
          </DataKey>
          <DataValue>
            <div>
              {dateFormat(subscription.startDate, '-', t('DateFormat_YMD'))} ~{' '}
              {dateFormat(subscription.endDate, '-', t('DateFormat_YMD'))}
            </div>
            <Timezone>({subscription.timeZone})</Timezone>
          </DataValue>
        </DataRow>
        <DataRow>
          <DataKey>
            <InfoIcon width={20} height={20} color={colors['ic-gray-light']} />
            비고
          </DataKey>
          <DataValue>{subscription.isExternalSubscription ? t('Subscrib_Main_19') : '-'}</DataValue>
        </DataRow>
      </dl>
      <DashedDivider />
      <dl>
        <DataRow>
          <DataKey>
            <ManagerIcon width={20} height={20} color={colors['ic-gray-light']} />
            구독 관리자
          </DataKey>
          <DataValue>
            {subscription.owner.name ? (
              <OwnerNameButton
                type="button"
                // onClick={event => setOwnerProfileAnchorEl(event.currentTarget)}
                // className={ownerProfileAnchorEl ? 'clicked' : ''}
              >
                {subscription.owner.name}
              </OwnerNameButton>
            ) : (
              '-'
            )}
          </DataValue>
        </DataRow>
        <DataRow>
          <DataKey>
            <GroupIcon width={20} height={20} color={colors['ic-gray-light']} />
            사용 그룹
          </DataKey>
          <DataValue>{subscription.userGroupName}</DataValue>
        </DataRow>
      </dl>
      <DashedDivider />
      <dl>
        <DataRow>
          <DataKey>
            <BriefcaseIcon width={20} height={20} color={colors['ic-gray-light']} />
            영업 담당자
          </DataKey>
          <DataValue>{subscription.salesManager.name || '-'}</DataValue>
        </DataRow>
        <DataRow>
          <DataKey>
            <ToolsIcon width={20} height={20} color={colors['ic-gray-light']} />
            기술 담당자
          </DataKey>
          <DataValue>{subscription.technicalSupportManager.name || '-'}</DataValue>
        </DataRow>
      </dl>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 36px;
`;

const DataRow = styled.div<{ $isMultipleRow?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ $isMultipleRow }) => ($isMultipleRow ? '16px' : '36px')};
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const DataKey = styled.dt`
  display: flex;
  align-items: center;
  gap: 12px;
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const DataValue = styled.dd`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  text-align: right;
`;

const Timezone = styled.small`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
`;

const DashedDivider = styled(Divider)`
  height: 1px;
  border-bottom: 1px dashed ${({ theme: { colors } }) => colors['border-gray-lighter']};
  margin: 28px 0px;
`;

const OwnerNameButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
  ${fonts.Underline3}

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.clicked {
    text-decoration: underline;
  }
`;
