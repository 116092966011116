import { useLayoutEffect, useState } from 'react';
import { nanoid } from 'nanoid';

type FileMeta = {
  fileId: string;
  fileName: string;
  isFinished: boolean;
};

/**
 *
 * @param fileLength 업로드가 완료된 Dummy 파일을 목록에서 지워주는 조건입니다. Effect의 Dependency Array에 들어가며,
 * setDummyFinished에 의해 플래그가 세팅된 임시 파일들이 dummyFiles에서 제거됩니다.
 * @returns openFileDialog 파일 선택 다이얼로그를 호출합니다.
 * @returns dummyFiles 업로드가 진행중인 임시 파일들 목록입니다.
 * @returns addDummy 업로드가 진행중인 임시 파일을 추가합니다. 파일명을 넘겨주고 임시 파일 객체를 반환합니다.
 * @returns setDummyFinished 임시 파일의 업로드가 완료됐다고 플래그를 세팅합니다. 파일 업로드가 완료된 경우 호출합니다.
 * @example
 * const { openFileDialog, dummyFiles, addDummy, setDummyFinished } = useFileDialog(realFileList.length > 0);
 *
 * openFileDialog(fileList => {
 *   // ...
 *   const dummy = addDummy(file.name);
 *   uploadFileApi({
 *     // ...
 *     config: {
 *       onUploadProgress: progress => {
 *         if(progress.progress === 1) {
 *           setDummyFinished(dummy.fileId);
 *           // ...
 *         }
 *       }
 *     }
 *   })
 * })
 */
type Props = {
  fileLength?: number;
};
export const useFileDialog = ({ fileLength }: Props = {}) => {
  const [dummyFiles, setDummyFiles] = useState<FileMeta[]>([]);

  useLayoutEffect(() => {
    if (fileLength) {
      setDummyFiles(fileLength ? prev => prev.filter(({ isFinished }) => !isFinished) : []);
    }
  }, [fileLength]);

  const openFileDialog = (onFileSelected?: (fileList: FileList) => void) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = () => {
      if (input.files) {
        if (typeof onFileSelected === 'function') {
          onFileSelected(input.files);
        }
      }
    };
    input.click();
  };

  const addDummy = (fileName: string) => {
    const dummy = { fileId: nanoid(), fileName, isFinished: false };
    setDummyFiles(prev => [dummy, ...prev]);
    return dummy;
  };

  const setDummyFinished = (dummyFileId: string) => {
    setDummyFiles(prev => {
      const next = [...prev];
      const target = next.find(({ fileId }) => fileId === dummyFileId);
      if (target) {
        target.isFinished = true;
      }
      return next;
    });
  };

  return { openFileDialog, dummyFiles, addDummy, setDummyFinished };
};
