import { injectable } from 'inversify';
import { Page } from '@type/Page';
import API from '@repositories/Axios';
import qs from 'qs';
import { IInquiryRepository } from './IInquiryRepository';
import { Inquiry, InquiryCreateRq, InquiryFilter, InquiryList } from './Types';

@injectable()
export class InquiryRepository implements IInquiryRepository {
  async getOne(id: string): Promise<Inquiry> {
    const result = await API.get<Inquiry>(`/api/v1/product-inquiries/${id}`);
    return result.data;
  }

  async getList(queries?: InquiryFilter): Promise<Page<InquiryList>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<InquiryList>>(`/api/v1/product-inquiries?${queryString}`);
    return result.data;
  }

  async create(data: InquiryCreateRq): Promise<Inquiry> {
    const result = await API.post<Inquiry>(`/api/v1/product-inquiries`, data);
    return result.data;
  }

  update(/* id: string, data: unknown */): Promise<Inquiry> {
    throw new Error('Method not implemented.');
  }
  delete(/* id: string */): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
