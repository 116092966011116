import { Button } from '@components/index';
import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';

export const Continer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 616px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 10px;

  overflow: auto;
`;

export const ContentWrapper = styled('ul')`
  display: flex;
  flex-direction: column;
  padding: 12px 28px 4px;
  gap: 20px;
`;
export const ContentItem = styled('li')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BottomBtnsWrapper = styled(Box)`
  width: 100%;
  display: flex;
  display: row;
  justify-content: right;
  gap: 8px;
  padding: 24px 28px 28px;
  & > button {
    width: 168px;
  }
`;

export const ModalHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 16px 28px;
`;
export const GroupTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 0;
    padding-right: 50px;
  }
  & .MuiInputBase-input {
    padding: 10px 12px;
  }
`;

export const DescriptionField = styled(TextField)`
  & .MuiInputBase-root {
    height: 140px;
    display: flex;
    align-items: flex-start;
    padding: 10px 12px;
  }
  & .MuiInputBase-input::placeholder {
    ${fonts.Body2};
  }
`;

export const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalCloseBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
`;
