import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';

export const Continer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 556px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 10px;

  overflow: auto;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;

  strong {
    ${fonts.Headline9};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
  li {
    display: flex;
    align-items: center;
    gap: 4px;
    & span {
      ${fonts.Body4};
      color: ${({ theme }) => theme.colors['text-gray-light']};
      white-space: pre;
    }
    &:last-of-type {
      align-items: flex-start;
      & svg {
        margin-top: 6px;
      }
    }
  }
`;

export const BottomBtnsWrapper = styled(Box)`
  width: 100%;
  display: flex;
  algin-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: auto;
  padding: 24px 28px 28px;
  & > button {
    width: 168px;
    &:disabled {
      background-color: ${({ theme }) => theme.colors['bg-gray-dark']};
      color: ${({ theme }) => theme.colors['text-gray-light']};
    }
  }
`;

export const ModalHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 16px 28px;
`;
export const ModalBody = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 12px 28px 4px;
`;

export const ModalCloseBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
`;

export const ModalForm = styled('form')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
