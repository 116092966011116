import { SubscriptionUseRequestDetailModel } from '@models/subscription/SubscriptionUseRequestDetailModel';
import { SubscriptionUseRequestModel } from '@models/subscription/SubscriptionUseRequestModel';
import { KEYS, repository } from '@repositories/Repository';
import ISubscriptionUseRequestRepository from '@repositories/subscriptionUseRequestRepository/ISubscriptionUseRequestRepository';
import {
  Filter,
  SubscriptionUseRequestCreateRq,
  SubscriptionUseRequestStatusUpdateRq,
  SubscriptionUseRequestUpdateRq,
} from '@repositories/subscriptionUseRequestRepository/Types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const sbUseRqRepo = repository.get<ISubscriptionUseRequestRepository>(KEYS.SUBSCRIPTION_USE_REQUEST);

const sbUseRqKey = {
  all: ['subscriptionUseRq'] as const,
  lists: () => [...sbUseRqKey.all, 'lists'] as const,
  list: (filter?: Filter) => [...sbUseRqKey.lists(), filter] as const,
  details: () => [...sbUseRqKey.all, 'detail'] as const,
  detail: (id: string) => [...sbUseRqKey.details(), id] as const,
  my: () => [...sbUseRqKey.lists(), 'my'] as const,
};

export const useGetMySubscriptionUseRequests = (
  queries?: Filter,
  options?: UseQueryOptionsType<Page<SubscriptionUseRequestModel>>,
) => {
  const result = useQuery<Page<SubscriptionUseRequestModel>, AxiosError>({
    queryKey: sbUseRqKey.my(),
    queryFn: async () => {
      const useRqList = await sbUseRqRepo.getMySubscriptionUseList(queries);
      return { ...useRqList, content: useRqList.content.map(rq => new SubscriptionUseRequestModel(rq)) };
    },
    ...options,
  });

  return { ...result };
};

export const useGetSubscriptionUseRequests = (
  queries?: Filter,
  options?: UseQueryOptionsType<Page<SubscriptionUseRequestModel>>,
) =>
  useQuery<Page<SubscriptionUseRequestModel>, AxiosError>({
    queryKey: sbUseRqKey.list(queries),
    queryFn: async () => {
      const useRqList = await sbUseRqRepo.getList(queries);
      return { ...useRqList, content: useRqList.content.map(rq => new SubscriptionUseRequestModel(rq)) };
    },
    ...options,
  });

export const useGetSubscriptionUseRequest = (
  requestId: string,
  options?: UseQueryOptionsType<SubscriptionUseRequestDetailModel>,
) =>
  useQuery<SubscriptionUseRequestDetailModel, AxiosError>({
    queryKey: sbUseRqKey.detail(requestId),
    queryFn: async () => {
      const useRq = await sbUseRqRepo.getOne(requestId);
      return new SubscriptionUseRequestDetailModel(useRq);
    },
    ...options,
    enabled: !!requestId && (options?.enabled !== undefined ? options.enabled : true),
  });

export const useAddSubscriptionUseRequest = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (rqData: SubscriptionUseRequestCreateRq) => {
      const result = await sbUseRqRepo.create(rqData);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbUseRqKey.lists());
    },
  });

  return { ...result };
};

export const useUpdateStatusSubscriptionUseRequest = () => {
  type UpdateData = {
    requestId: string;
    rqData: SubscriptionUseRequestStatusUpdateRq;
  };
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (data: UpdateData) => {
      const result = await sbUseRqRepo.updateStatus(data.requestId, data.rqData);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbUseRqKey.all);
    },
  });

  return { ...result };
};

export const useUpdateSubscriptionUseRequest = () => {
  type UpdateData = {
    requestId: string;
    rqData: SubscriptionUseRequestUpdateRq;
  };

  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: async (data: UpdateData) => {
      const result = await sbUseRqRepo.update(data.requestId, data.rqData);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbUseRqKey.all);
    },
  });

  return { ...result };
};

export const useDeleteSubscriptionUseRequest = () => {
  type DeleteData = {
    requestId: string;
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: DeleteData) => {
      const result = await sbUseRqRepo.delete(data.requestId);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(sbUseRqKey.lists());
    },
  });
};
