import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 364px;
  max-height: 496px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  border: 1px solid #fff;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  ${fonts.Headline6};
  padding: 0px 32px;
`;

export const PriceDetail = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors['bg-gray-light']};
  padding: 28px 32px;
  margin: 0px 32px;
  border-radius: 5px;
  height: 100%;
  gap: 8px;

  & .option-name {
    display: flex;
    flex-direction: row;
    ${fonts.Headline8};
    color: ${props => props.theme.colors['text-purple-light']};
  }

  & .option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${fonts.Body1};
    align-items: center;
    & .price {
      display: flex;
      flex-direction: row;
      ${fonts.Headline4};
      color: ${props => props.theme.colors['text-purple-light']};
      align-items: center;
      & .unit {
        ${fonts.Body1};
        color: ${props => props.theme.colors['text-gray-main']};
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 12px 28px 32px;
`;

export const Plan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  & .plan-name {
    display: flex;
    flex-direction: row;
    ${fonts.Headline8};
    color: ${props => props.theme.colors['text-purple-light']};
  }
  & .metrics {
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
    border-radius: 5px;
    margin-bottom: 16px;
    & .metric {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 14px 20px;
      align-items: center;
      height: 48px;
      width: 100%;
    }
  }
`;
