import { WorkflowTriggerType } from '@repositories/workflowBaseTriggerRepository/Types';
import { WorkflowTrigger } from '@repositories/workflowRepository/Types';

export class WorkflowTriggerModel {
  private readonly _conditionExpression: string;
  private readonly _cronExpression: string;
  private readonly _cronTimeZone: string;
  private readonly _executionStartTime: string;
  private readonly _type: WorkflowTriggerType;

  constructor(dto: WorkflowTrigger) {
    this._conditionExpression = dto.conditionExpression || '';
    this._cronExpression = dto.cronExpression || '';
    this._cronTimeZone = dto.cronTimeZone || '';
    this._executionStartTime = dto.executionStartTime || '';
    this._type = dto.type || 'DIRECT_API_TRIGGER';
  }

  get conditionExpression() {
    return this._conditionExpression;
  }

  get cronExpression() {
    return this._cronExpression;
  }

  get cronTimeZone() {
    return this._cronTimeZone;
  }

  get executionStartTime() {
    return this._executionStartTime;
  }

  get type() {
    return this._type;
  }
}
