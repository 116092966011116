import { HtmlHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

type PopoverRowProps = {
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
};

export const PopoverRow = ({
  children,
  className,
  selected,
  ...props
}: PopoverRowProps & HtmlHTMLAttributes<HTMLDivElement>) => {
  return (
    <Row className={className} selected={selected} {...props}>
      {children}
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  ${fonts.Body2}
  padding: 6px 7px;
  cursor: pointer;
  background-color: ${({ theme, selected }) => (selected ? theme.colors['state-white-hover'] : undefined)};
  :hover {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
`;
