import type { NodeDataType } from './GraphType';

import React from 'react';
import { useTranslation } from 'react-i18next';
import type { NodeProps } from 'reactflow';
import { Handle, Position } from 'reactflow';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { WizardIcon } from '@icons/WizardIcon';
import 'reactflow/dist/base.css';

type Props = NodeProps<NodeDataType>;

export const TriggerNode = observer((node: Props) => {
  const { data, isConnectable: isNodeConnectable } = node;
  const { t } = useTranslation();
  const theme = useTheme();
  const { workflowDetailStore } = useStore();
  const isEditable = workflowDetailStore.checkEditable();
  const isConnectable = isNodeConnectable && isEditable;

  const handleClickTriggerBehavior = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.curNodeId = node.id;
    if (isEditable) {
      workflowDetailStore.setToolbarView('triggerSelect');
    } else {
      workflowDetailStore.setToolbarView('triggerConfig');
    }
  };

  const handleClickTriggerBehaviorConfig = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    workflowDetailStore.curNodeId = node.id;
    workflowDetailStore.setToolbarView('triggerConfig');
  };

  const handleClickNode = () => {
    workflowDetailStore.curNodeId = node.id;
    workflowDetailStore.setToolbarView(isEditable ? 'triggerSelect' : 'triggerConfig');
  };

  return (
    <Container isSelected={workflowDetailStore.curNodeId === node.id} onClick={handleClickNode}>
      <Type>
        <span>
          <WizardIcon width={16} height={16} />
          <span>{t('Workflow_Create_02')}</span>
        </span>
      </Type>
      <Behavior
        isSelected={workflowDetailStore.curNodeId === node.id && workflowDetailStore.toolbar === 'triggerSelect'}
        onClick={handleClickTriggerBehavior}
      >
        {data.baseTriggerModel?.name || t('Workflow_Create_03')}
      </Behavior>
      {!!data.baseTriggerModel?.isConfigurable && (
        <Config
          onClick={handleClickTriggerBehaviorConfig}
          isSelected={workflowDetailStore.curNodeId === node.id && workflowDetailStore.toolbar === 'triggerConfig'}
        >
          {t('Setting')}
        </Config>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        id="normal"
        isConnectable={isConnectable}
        style={{
          background: theme.colors['border-gray-light'],
          width: 10,
          height: 10,
          borderRadius: '50%',
          border: '1px solid gray',
        }}
      />
    </Container>
  );
});

export const MemoizedMovie = React.memo(TriggerNode);

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;

  border: ${props =>
    `${props.isSelected ? '2px' : '1px'} solid
    ${props.isSelected ? props.theme.colors['border-purple'] : props.theme.colors['border-gray-lighter']};`};
  height: fit-content;
  width: '392px';
  background: ${props => props.theme.colors['bg-gray-light']};
  border-radius: 4px;
  padding: '8px';
  box-shadow: ${props => (props.isSelected ? '0px 8px 10px -5px rgba(0, 0, 0, 0.08)' : '')};
`;

const Type = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 4px;

  ${fonts.Headline9};
  align-items: center;
  margin-bottom: 4px;
  justify-content: space-between;
  & > span {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
`;

const Behavior = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  border-bottom-left-radius: ${props => (props.isSelected ? '0px' : '3px')};
  border-bottom-right-radius: ${props => (props.isSelected ? '0px' : '3px')};
  padding: 0px 16px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  background: ${props => props.theme.colors['bg-white']};

  ${props => (props.isSelected ? fonts.Headline8 : fonts.Body2)};
  color: ${props => (props.isSelected ? props.theme.colors['text-purple'] : props.theme.colors['text-gray-main'])};
`;

const Config = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 0px 16px;
  border: 1px solid ${props => props.theme.colors['border-gray-lighter']};
  border-top: 0px;
  background: ${props => props.theme.colors['bg-white']};
  ${props => (props.isSelected ? fonts.Headline8 : fonts.Body2)};
  color: ${props => (props.isSelected ? props.theme.colors['text-purple'] : props.theme.colors['text-gray-main'])};
`;
