import { Button } from '@components/index';
import styled from '@emotion/styled';
import { Box, TableCell as MuiTableCell, TextField } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

export const ModalWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  min-height: 644px;
  max-height: 644px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border: 1px solid #fff;
  border-radius: 6px;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: inherit;
  padding: 0px 20px 0px 28px;
`;

export const HeaderTitle = styled(Box)`
  ${fonts.Headline6}
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  min-height: 484px;
  max-height: 484px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  & > ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  }
  & li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 11px 16px;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
    ${fonts.Body2};
    &:last-of-type {
      border-bottom: none;
    }
    & > span {
      &:first-of-type {
        width: 142px;
      }
      &:last-of-type {
        margin-left: auto;
      }
      }
    }
  }
`;

export const Footer = styled(Box, transientOptions)<{ $hasScrollbar: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 24px 28px;
  border-top: ${({ $hasScrollbar, theme: { colors } }) =>
    $hasScrollbar ? `1px solid ${colors['border-gray-lighter']}` : 'none'};
`;

export const Candidates = styled(Box)`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 6px 4px;
  width: 100%;
  max-height: 74px;
  margin-bottom: 12px;
  overflow-y: auto;
`;

export const Search = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  width: 168px;
`;

export const TableCell = styled(MuiTableCell)`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  height: 42px;
  :first-of-type {
    padding-left: 16px;
  }
  :last-of-type {
    padding-right: 16px;
  }
`;

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

export const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export const ChipCloseButton = styled(Button)`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.MuiChip-deleteIcon {
    margin: 0;
  }
`;

export const MenuItemText = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
`;

export const UserSelectedWrapper = styled('div')`
  position: sticky;
  top: 0;
  padding-top: 12px;
  background-color: ${({ theme }) => theme.colors['bg-white']};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  z-index: 1001;
`;
