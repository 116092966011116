import type { TechnicalInquiryDTO } from '../technicalSupport.types';
import { SoftwareSimpleModel } from '@models/softwareModels';
import { TechnicalInquiryHistoryDTOModel } from './TechnicalInquiryHistoryDTO';

export class TechnicalInquiryDTOModel {
  private readonly _dto: TechnicalInquiryDTO;
  private readonly _histories: TechnicalInquiryHistoryDTOModel[] | [];
  private readonly _product: SoftwareSimpleModel;

  constructor(dto: TechnicalInquiryDTO) {
    this._dto = dto;
    this._histories = dto.histories ? dto.histories.map(history => new TechnicalInquiryHistoryDTOModel(history)) : [];
    this._product = new SoftwareSimpleModel(dto.product);
  }

  get originDTO(): TechnicalInquiryDTO {
    return this._dto;
  }

  get companyName(): string {
    return this._dto.companyName ?? '';
  }

  get createdDate(): string {
    return this._dto.createdDate ?? '';
  }

  get email(): string {
    return this._dto.email ?? '';
  }

  get histories(): TechnicalInquiryHistoryDTOModel[] | [] {
    return this._histories ?? [];
  }

  get inquiry(): string {
    return this._dto.inquiry ?? '';
  }

  get managerName(): string {
    return this._dto.managerName ?? '';
  }

  get phone(): string {
    return this._dto.phone ?? '';
  }

  get product(): SoftwareSimpleModel {
    return this._product;
  }

  get productName(): string {
    return this._dto.product.name ?? '';
  }

  get technicalInquiryId(): string {
    return this._dto.technicalInquiryId ?? '';
  }

  get tenantCode(): string {
    return this._dto.tenantCode ?? '';
  }

  get tenantName(): string {
    return this._dto.tenantName ?? '';
  }

  get planName(): string {
    return this._dto.planName ?? '';
  }

  get categoryName(): string {
    return this._dto.categoryName ?? '';
  }

  get conversionStatus(): '요청' | '답변 완료' | '진행중' {
    switch (this._dto.status) {
      case 'CREATED':
        return '요청';
      case 'IN_PROGRESS':
        return '진행중';
      case 'COMPLETED':
        return '답변 완료';
      default:
        return '요청';
    }
  }

  get conversionStatusColor(): 'pink' | 'green' | 'gray' {
    switch (this._dto.status) {
      case 'CREATED':
        return 'pink';
      case 'IN_PROGRESS':
        return 'green';
      case 'COMPLETED':
        return 'gray';
      default:
        return 'pink';
    }
  }
}
