import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import IUserSubscriptionStatRepository from './ISubscriptionCountStatRepositoryRepository';
import { SubscriptionCountStat } from './Types';

@injectable()
export class SubscriptionCountStatRepository implements IUserSubscriptionStatRepository {
  create(/* data: unknown */): Promise<SubscriptionCountStat> {
    throw new Error('API not exist.');
  }
  async getOne(timeZone: string): Promise<SubscriptionCountStat> {
    const result = await API.get<SubscriptionCountStat>(`/api/v1/statistics/subscription-status?timeZone=${timeZone}`);
    return result.data;
  }
  getList(): Promise<Page<SubscriptionCountStat>> {
    throw new Error('API not exist.');
  }
  update(/* id: string, data: unknown */): Promise<SubscriptionCountStat> {
    throw new Error('API not exist.');
  }
  delete(/* id: string */): Promise<void> {
    throw new Error('API not exist.');
  }
}
