import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SessionOut } from '@components/sessionOutDialog/SessionOut';
import { useLogout } from '@queryHooks/useUser';

export const ManagerLayout = () => {
  const location = useLocation();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const tenantId = location.pathname.split('/')[2];

  const handleCloseSessionOut = () => {
    logout();
    navigate(`/manager/${tenantId}/login`);
  };

  return (
    <>
      <Outlet />
      <SessionOut onClose={handleCloseSessionOut} />
    </>
  );
};
