import type { SubscriptionUserCountStatDTO, SubscriptionUserStatDTO } from '@repositories/subscriptionRepository';

export class SubscriptionUserStatModel {
  private readonly _data: SubscriptionUserCountStatDTO[];
  private readonly _monthlyIncrement: number;
  private readonly _monthlyIncrementRate: number;
  private readonly _requestedCount: number;
  private readonly _totalCount: number;
  private readonly _unregisteredCount: number;

  constructor(dto: SubscriptionUserStatDTO) {
    this._data = dto.data;
    this._monthlyIncrement = dto.monthlyIncrement;
    this._monthlyIncrementRate = dto.monthlyIncrementRate;
    this._requestedCount = dto.requestedCount;
    this._totalCount = dto.totalCount;
    this._unregisteredCount = dto.unregisteredCount;
  }

  get totalUserCount(): number {
    return this._totalCount;
  }

  get activeUserCount(): number {
    return this._data.reduce((accum, { userStatus, count }) => (userStatus === 'ACTIVE' ? accum + count : accum), 0);
  }

  get inactiveUserCount(): number {
    return this._data.reduce((accum, { userStatus, count }) => (userStatus === 'INACTIVE' ? accum + count : accum), 0);
  }

  get activeTenantMemberCount(): number {
    return this._data.reduce(
      (accum, { tenantMemberStatus, count }) => tenantMemberStatus === 'ACTIVE' ? accum + count : accum,
      0,
    );
  }

  get blockedTenantMemberCount(): number {
    return this._data.reduce(
      (accum, { tenantMemberStatus, count }) => (tenantMemberStatus === 'BLOCKED' ? accum + count : accum),
      0,
    );
  }

  get nonTenantMemberCount(): number {
    return this._data.reduce(
      (accum, { tenantMemberStatus, count }) => (tenantMemberStatus === null ? accum + count : accum),
      0,
    );
  }
  get billableActiveTenantMemberCount(): number {
    return this._data.reduce(
      (accum, { billableStatus, tenantMemberStatus, count }) =>
        billableStatus === 'BILLABLE' && tenantMemberStatus === 'ACTIVE' ? accum + count : accum,
      0,
    );
  }

  get billableBlockedTenantMemberCount(): number {
    return this._data.reduce(
      (accum, { billableStatus, tenantMemberStatus, count }) =>
        billableStatus === 'BILLABLE' && tenantMemberStatus === 'BLOCKED' ? accum + count : accum,
      0,
    );
  }

  get billableNonTenantMemberCount(): number {
    return this._data.reduce(
      (accum, { billableStatus, tenantMemberStatus, count }) =>
        billableStatus === 'BILLABLE' && tenantMemberStatus === null ? accum + count : accum,
      0,
    );
  }
}
