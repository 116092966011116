import React, { useLayoutEffect, useRef, useState } from 'react';
import type Slider from 'react-slick';
import * as S from './ImageCarouselStyles';

const MAX_HEIGHT = 900;

export const ImageCarousel: React.FC<{
  data: { id: string; url: string }[];
  autoplaySpeed?: number;
  ratio?: number;
  onClick?: (id: string, url: string) => void;
}> = React.memo(({ data, autoplaySpeed = 3000, ratio = 12.5, onClick }) => {
  const [imgHeight, setImgHeight] = useState(240);
  const sliderRef = useRef<Slider | null>(null);

  useLayoutEffect(() => {
    if (sliderRef.current) {
      const imgWidth = sliderRef.current.innerSlider?.list?.clientWidth;
      if (imgWidth) {
        setImgHeight(Math.min((imgWidth * ratio) / 100, MAX_HEIGHT));
      }
    }
  }, [ratio]);

  const handleClick = (id: string, url: string) => () => {
    if (typeof onClick === 'function') {
      onClick(id, url);
    }
  };

  return (
    <S.StyledSlider
      ref={sliderRef}
      dots
      infinite
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay
      autoplaySpeed={autoplaySpeed}
      pauseOnHover
      arrows={false}
    >
      {data.map(({ id, url }) => (
        <button type="button" key={id} onClick={handleClick(id, url)}>
          <img alt={url} src={url} width="100%" height={imgHeight} />
        </button>
      ))}
    </S.StyledSlider>
  );
});
