import { Button, Modal, TextField, Typography } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { ProviderCreateRq } from '@type/Provider';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as S from './ProviderCreateStyles';

export const ProviderCreate = observer(() => {
  const { uiStore, softwareStore } = useStore();

  const handleClose = () => {
    uiStore.closeProviderCreateModal();
  };

  const { register, handleSubmit, reset } = useForm<ProviderCreateRq>({
    mode: 'onChange',
    shouldFocusError: true,
  });

  const validationHandler = {
    success: async (data: ProviderCreateRq) => {
      try {
        await softwareStore.createProvider(data);
        handleClose();
        reset();
      } catch (error) {
        console.error('err', error);
      }
    },
    fail: (/* err: any */) => {
      //
    },
  };

  return (
    <Modal open={uiStore.isVisibleProviderCreateModal} onClose={handleClose}>
      <S.Container>
        <S.ModalTitleWrapper>
          <Typography variant="h4">Create Provider</Typography>
        </S.ModalTitleWrapper>
        <S.ModalContentWrapper>
          <S.ContentElement>
            <S.ContentNameElement>
              <Typography whiteSpace="nowrap">Company Name</Typography>
            </S.ContentNameElement>
            <TextField {...register('name')} fullWidth size="small" />
          </S.ContentElement>
          <S.ContentElement>
            <S.ContentNameElement>
              <Typography whiteSpace="nowrap">Email</Typography>
            </S.ContentNameElement>
            <TextField {...register('email')} fullWidth size="small" />
          </S.ContentElement>
          <S.ContentElement>
            <S.ContentNameElement>
              <Typography whiteSpace="nowrap">Phone</Typography>
            </S.ContentNameElement>
            <TextField {...register('phone')} fullWidth size="small" />
          </S.ContentElement>
          <S.ContentElement>
            <S.ContentNameElement>
              <Typography whiteSpace="nowrap">URL</Typography>
            </S.ContentNameElement>
            <TextField {...register('url')} fullWidth size="small" />
          </S.ContentElement>
          <S.ContentElement>
            <S.ContentNameElement>
              <Typography whiteSpace="nowrap">Icon</Typography>
            </S.ContentNameElement>
            <TextField {...register('icon')} fullWidth size="small" />
          </S.ContentElement>
          <S.ContentElement>
            <S.ContentNameElement>
              <Typography whiteSpace="nowrap">Description</Typography>
            </S.ContentNameElement>
            <TextField {...register('description')} fullWidth size="small" multiline rows={3} />
          </S.ContentElement>
        </S.ModalContentWrapper>
        <form onSubmit={handleSubmit(validationHandler.success, validationHandler.fail)}>
          <S.ButtonsWrapper>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="outlined" type="submit">
              Create
            </Button>
          </S.ButtonsWrapper>
        </form>
      </S.Container>
    </Modal>
  );
});
