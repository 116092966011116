import API from '@repositories/Axios';
import { injectable } from 'inversify';
import qs from 'qs';
import IProductRelationsRepository from './IProductRelationsRepository';
import { ProductRelation, ProductRelationDetail, RelatedProduct, RelationType } from './Types';

@injectable()
export class ProductRelationsRepository implements IProductRelationsRepository {
  async getOne(productId: string): Promise<ProductRelationDetail[]> {
    const result = await API.get<ProductRelationDetail[]>(`/api/v1/product-relations/${productId}`);
    return result.data;
  }
  async create(data: ProductRelation): Promise<ProductRelation> {
    const result = await API.post<ProductRelation>('/api/v1/product-relations', data);
    return result.data;
  }
  async update(data: RelatedProduct[], productId: string): Promise<ProductRelation> {
    const result = await API.put<ProductRelation>(`/api/v1/product-relations/${productId}`, data);
    return result.data;
  }
  async delete(productId: string, queries: { type: RelationType }): Promise<void> {
    const queryString = qs.stringify(queries);
    await API.delete(`/api/v1/product-relations/${productId}?${queryString}`);
  }
}
