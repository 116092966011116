import { Usage } from '@repositories/usage/Types';

export class UsageModel {
  private readonly _limit: number;
  private readonly _value: number;
  private readonly _unit: string;

  constructor(dto?: Usage) {
    this._limit = dto?.limit || 0;
    this._value = dto?.value || 0;
    this._unit = dto?.unit || '';
  }

  get limit() {
    return this._limit;
  }

  get value() {
    return this._value;
  }

  get unit() {
    return this._unit;
  }
}
