import type IUserRepository from './IUserRepository';
import type {
  PasswordInitialUpdateRq,
  Profile,
  ProfileUpdateRq,
  User,
  UserCreateRq,
  UserFilter,
  UserInviteRq,
  UserPasswordRecoveryDTO,
  UserSignInRq,
  UserSimple,
  UserStatusUpdateRq,
  UserUpdateRq,
} from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import type { Page } from '@type/Page';

@injectable()
export class UserRepository implements IUserRepository {
  async recoveryPassword(rqData: UserPasswordRecoveryDTO): Promise<void> {
    const result = await API.post<void>(`/api/v1/actions/password-recovery`, rqData);
    return result.data;
  }
  async inviteUsersToTenant(rqData?: UserInviteRq[]): Promise<void> {
    const result = await API.post<void>(`/api/v1/actions/invite-users`, rqData);
    return result.data;
  }
  async initialPassword(rqData: PasswordInitialUpdateRq): Promise<void> {
    const result = await API.put<void>(`/api/v1/users/initial-password`, rqData);
    return result.data;
  }
  async inviteUserToTenant(rqData?: UserInviteRq, userId?: string): Promise<void> {
    if (userId) {
      const result = await API.post<void>(`/api/v1/actions/invite?userId=${userId}`);
      return result.data;
    }
    const result = await API.post<void>(`/api/v1/actions/invite`, rqData);
    return result.data;
  }

  async updateProfile(id: string, rqData: ProfileUpdateRq): Promise<Profile> {
    const result = await API.patch<Profile>(`/api/v1/users/${id}/profiles`, rqData);
    return result.data;
  }

  async changePassword(userId: string, password: string): Promise<void> {
    await API.patch<void>(`/api/v1/users/${userId}/password`, { password });
  }

  async getProfile(id: string): Promise<Profile> {
    const result = await API.get<Profile>(`/api/v1/users/${id}/profiles`);
    return result.data;
  }

  async signIn(rqData: UserSignInRq): Promise<User> {
    const result = await API.post<User>('api/v1/actions/sign-in', rqData);
    return result.data;
  }

  async create(data: UserCreateRq): Promise<User> {
    const result = await API.post<User>('/api/v1/users', data);
    return result.data;
  }

  async getOne(id: string): Promise<User> {
    const result = await API.get<User>(`/api/v1/users/${id}`);
    return result.data;
  }

  async getList(filter: UserFilter): Promise<Page<User>> {
    const queryString = qs.stringify(filter, { skipNulls: true });
    const result = await API.get<Page<User>>(`/api/v1/users?${queryString}`);
    return result.data;
  }

  async update(id: string, data: UserUpdateRq): Promise<User> {
    const result = await API.put<User>(`/api/v1/users/${id}`, data);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    await API.delete(`/api/v1/users/${id}`);
  }

  async updateUserStatus(userId: string, rqData: UserStatusUpdateRq): Promise<UserSimple> {
    const result = await API.put<UserSimple>(`/api/v1/users/${userId}/status`, rqData);
    return result.data;
  }
}
