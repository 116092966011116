const defaultWidth = 16;
const defaultHeight = 16;

export const CheckboxOffNoIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  brColor = '#D1D5DA',
  bgColor = '#fff',
}: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill={bgColor} stroke={brColor} />
    </g>
  </svg>
);

CheckboxOffNoIcon.displayName = 'CheckboxOffNoIcon';
