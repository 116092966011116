const defaultWidth = 24;
const defaultHeight = 24;

export const PlugFillSplashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#FFFFFF' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1868 18.203C15.1867 20.8733 13.022 23.038 10.3516 23.038C7.75461 23.038 5.63581 20.9906 5.52131 18.4219H5.51676C5.51669 16.9844 4.47114 15.7911 3.09904 15.561C3.09904 15.561 3.20906 15.569 3.09904 15.5594C3.01992 15.5525 2.97778 15.5555 2.94652 15.5578C2.93431 15.5586 2.92376 15.5594 2.91331 15.5594C2.39055 15.5594 2 15.1226 2 14.5764C2 14.0302 2.4428 13.6125 2.98901 13.6125C3.09272 13.6125 3.1393 13.6125 3.28664 13.633C5.56876 13.9498 7.34148 15.8594 7.44594 18.203H7.45053C7.45084 19.805 8.74959 21.1035 10.3516 21.1035C11.9537 21.1035 13.2524 19.805 13.2527 18.203H13.2637V16.8663L10.7766 16.8663C9.77573 16.8663 8.94215 16.0981 8.83697 15.0937L8.23088 11.2764L8.16775 11.2213L16.2297 16.8663H15.1868L15.1868 18.203ZM19.6002 15.1959L6.8707 6.28262C7.22732 5.82431 7.77919 5.52932 8.40106 5.52932H10.4066V2.47528C10.4066 1.94047 10.8333 1.5 11.3681 1.5C11.9029 1.5 12.3296 1.94047 12.3296 2.47528V5.52932L16.1209 5.52932V2.47528C16.1209 1.94047 16.5476 1.5 17.0824 1.5C17.6172 1.5 18.0439 1.94047 18.0439 2.47528V5.52932H20.0494C21.1305 5.52932 22 6.42078 22 7.51192V8.81144C22 9.38875 21.7524 9.9383 21.3204 10.3154L20.2196 11.2764L19.6135 15.0937C19.6099 15.128 19.6055 15.1621 19.6002 15.1959Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97348 7.18085C4.29026 6.72844 4.87384 6.59051 5.27695 6.87277L21.3345 18.1164C21.7377 18.3987 21.8076 18.9942 21.4909 19.4466C21.1741 19.8991 20.5905 20.037 20.1874 19.7547L4.12979 8.51108C3.72669 8.22882 3.6567 7.63325 3.97348 7.18085Z"
        fill={color}
      />
    </g>
  </svg>
);

PlugFillSplashIcon.displayName = 'PlugFillSplashIcon';
