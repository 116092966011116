import { SoftwareCategory } from '@repositories/softwareCategoryRepository';

export class SoftwareCategoryModel {
  private readonly _iconUrl: string;
  private readonly _id: string;
  private readonly _code: string;
  private readonly _name: string;
  private readonly _description: string;
  private readonly _parentCategory: SoftwareCategoryModel | undefined;
  private readonly _subCategories: SoftwareCategoryModel[];

  constructor(dto?: SoftwareCategory) {
    this._id = dto?.id || '';
    this._code = dto?.code || '';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._iconUrl = dto?.iconUrl || '';
    this._parentCategory = dto?.parentCategory ? new SoftwareCategoryModel(dto?.parentCategory) : undefined;
    this._subCategories = dto?.subCategories?.map(sub => new SoftwareCategoryModel(sub)) ?? [];
  }

  get id() {
    return this._id;
  }

  get code() {
    return this._code;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get iconUrl() {
    return this._iconUrl;
  }

  get parentCategory() {
    return this._parentCategory;
  }

  get subCategories() {
    return this._subCategories;
  }
}
