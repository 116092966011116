import { Button, Typography } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react';
import React from 'react';
import * as S from './ErrorNotiStyles';

export const ErrorNoti: React.FC = observer(() => {
  const {
    uiStore: { errorNotiUiStore },
  } = useStore();

  return (
    <S.DialogContainer open={errorNotiUiStore.visible}>
      <S.TitleWrapper>
        <Typography variant="h4">Error</Typography>
      </S.TitleWrapper>
      <S.ContentWrapper>{errorNotiUiStore.content}</S.ContentWrapper>
      <S.ContinueBtnWrapper>
        <Button variant="outlined" onClick={errorNotiUiStore.close}>
          Close
        </Button>
      </S.ContinueBtnWrapper>
    </S.DialogContainer>
  );
});
ErrorNoti.displayName = 'ErrorNoti';
