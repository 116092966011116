import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const CardWgContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 388px;
  width: 100%;
  min-height: 582px;
  padding: 22px 0px 28px;
  background: ${props => props.theme.colors['bg-gray-light-50']};
  border-radius: 5px;
  & .title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    ${fonts.Headline12};
    padding: 0px 16px 0px 24px;
  }
`;

export const PieChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 19px;
  width: 100%;
  height: 200px;
  position: relative;
`;

export const CardWgContents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 22px;
  gap: 6px;
  margin-top: 20px;
  &.bigger {
    gap: 12px;
    margin-top: 21px;
  }
`;
