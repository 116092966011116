import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

export const Container = styled.div`
  display: grid;
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  overflow: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

export const Slot = styled.div`
  height: 408px;
  min-width: 400px;
  border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};

  &.app-info {
    grid-column: 1 / 5;
  }

  &:nth-of-type(4n),
  &:nth-of-type(5) {
    border-right: none;
  }

  &:nth-last-of-type(-n + 1) {
    border-bottom: none;
  }
`;
/*
&.license {
  grid-column: 1 / 3;
}
&.app-info {
  grid-column: 3 / 5;
}

&:nth-of-type(4n),
&:nth-of-type(6) {
  border-right: none;
}

&:nth-last-of-type(-n + 2) {
  border-bottom: none;
}
*/

export const BarChartContainer = styled.div`
  position: relative;
  padding: 32px 20px 47px;
  height: 100%;

  & .recharts-cartesian-grid-horizontal > line {
    stroke: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};

    &:first-of-type {
      stroke-dasharray: none;
    }

    &:last-of-type {
      stroke: none;
    }
  }

  & .recharts-cartesian-axis-tick > text > tspan {
    fill: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
`;

export const PieChartContainer = styled.div`
  padding: 24px 18px 47px;
  height: 100%;

  & > .usage {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin: 0px 6px 40px;
    & > strong {
      color: ${({ theme: { colors } }) => colors['text-green-light']};
    }
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  ${fonts.Headline7}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  margin: 0px 12px 40px;
  justify-content: space-between;
  gap: 20px;
  & > .header-text {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 4px;
  }
`;

export const HeaderText = styled('div', transientOptions)<{ $hasData: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;
  color: ${({ $hasData, theme: { colors } }) => ($hasData ? colors['text-gray-main'] : colors['text-gray-lighter'])};
`;

export const AppInfoContainer = styled.div`
  padding: 32px;
  & > .title {
    ${fonts.Headline7}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-bottom: 16px;
  }
`;

export const AppDescription = styled.p`
  ${fonts.Paragraph2}
  margin-bottom: 16px;
`;

export const AppInfo = styled.div`
  background: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  border-radius: 3px;
  padding: 18px 32px;
  margin-bottom: 24px;

  & > div {
    display: flex;
    align-items: center;
    gap: 12px;
    ${fonts.Body2}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`;

export const Related = styled.div`
  ${fonts.Headline8};
  margin-bottom: 12px;
`;

export const RelatedProducts = styled.ul`
  display: flex;
  gap: 20px;
  padding-bottom: 12px;
  overflow: auto;
`;

export const IconWrapper = styled.li`
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 5px;
  background: ${({ theme: { colors } }) => colors['bg-white']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
`;

export const TooltipContainer = styled.div`
  min-width: 230px;
  background: ${({ theme: { colors } }) => colors['bg-gray-main']};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  padding: 12px 16px;

  & > p {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
    margin-bottom: 18px;
  }

  & > dl {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    row-gap: 6px;
    margin: 0px;

    & > dt {
      display: flex;
      align-items: center;
      gap: 11px;
      ${fonts.Body2}
      color: ${({ theme: { colors } }) => colors['text-white']};
    }
    & > dd {
      ${fonts.Headline8}
      color: ${({ theme: { colors } }) => colors['text-white']};
      margin: 0px;
      text-align: right;
    }
  }
`;
