import { WorkflowBaseTriggerDTOModel } from '@models/workflow/WorkflowBaseTriggerDTOModel';
import { KEYS, repository } from '@repositories/Repository';
import IWorkflowBaseTriggerRepository from '@repositories/workflowBaseTriggerRepository/IWorkflowBaseTriggerRepository';
import {
  WorkflowBaseTriggerCreateDTO,
  WorkflowBaseTriggersFilter,
} from '@repositories/workflowBaseTriggerRepository/Types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from '@type/Page';
import { AxiosError } from 'axios';
import { UseQueryOptionsType } from './UseQueryOptionsType';

const triggerRepo = repository.get<IWorkflowBaseTriggerRepository>(KEYS.WORKFLOW_BASE_TRIGGER_REPOSITORY);
export const triggerQueryKey = {
  all: ['trigger'] as const,
  lists: () => [triggerQueryKey.all, 'list'] as const,
  list: (filter?: WorkflowBaseTriggersFilter) => [...triggerQueryKey.lists(), filter] as const,
  details: () => [...triggerQueryKey.all, 'detail'] as const,
  detail: (triggerId: string) => [...triggerQueryKey.details(), triggerId] as const,
};

export const useGetWorkflowBaseTriggers = (
  queries?: WorkflowBaseTriggersFilter,
  options?: UseQueryOptionsType<Page<WorkflowBaseTriggerDTOModel>>,
) => {
  return useQuery<Page<WorkflowBaseTriggerDTOModel>, AxiosError>({
    queryKey: triggerQueryKey.list(queries),
    queryFn: async () => {
      const triggers = await triggerRepo.getList(queries);
      return {
        ...triggers,
        content: triggers.content?.map(dto => new WorkflowBaseTriggerDTOModel(dto)),
      };
    },
    ...options,
  });
};

export const useGetWorkflowBaseTrigger = (
  triggerId: string,
  options?: UseQueryOptionsType<WorkflowBaseTriggerDTOModel>,
) => {
  return useQuery<WorkflowBaseTriggerDTOModel, AxiosError>({
    queryKey: triggerQueryKey.detail(triggerId),
    queryFn: async () => {
      const trigger = await triggerRepo.getOne(triggerId);
      return new WorkflowBaseTriggerDTOModel(trigger);
    },
    ...options,
  });
};

export const useAddWorkflowBaseTrigger = (triggerId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: WorkflowBaseTriggerCreateDTO) => {
      const trigger = await triggerRepo.create(rqData);
      return new WorkflowBaseTriggerDTOModel(trigger);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(triggerQueryKey.lists());
      queryClient.invalidateQueries(triggerQueryKey.detail(triggerId));
    },
  });
};

export const useUpdateWorkflowBaseTrigger = (triggerId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: WorkflowBaseTriggerCreateDTO) => {
      const trigger = await triggerRepo.update(triggerId, rqData);
      return new WorkflowBaseTriggerDTOModel(trigger);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(triggerQueryKey.lists());
      queryClient.invalidateQueries(triggerQueryKey.detail(triggerId));
    },
  });
};

export const useDeleteWorkflowBaseTrigger = (triggerId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const result = await triggerRepo.delete(triggerId);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(triggerQueryKey.lists());
      queryClient.invalidateQueries(triggerQueryKey.detail(triggerId));
    },
  });
};
