import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Dialog } from '@components/Dialog';
import { useStore } from '@stores/RootStore';
import { ChevronIcon } from '@icons/ChevronIcon';
import type { SoftwareSimpleModel } from '@models/softwareModels';
import { SubscriptionEditDialogBody } from '../../../subscriptionEditDialog/body/SubscriptionEditDialogBody';
import { ProductRequest, AppList } from './contents';

type Props = {
  open: boolean;
  onClose: () => void;
};

type PageState =
  | {
      page: 'APP_LIST' | 'PRODUCT_REQUEST';
    }
  | {
      page: 'DETAIL_SETTING';
      selectedProduct: SoftwareSimpleModel;
      step: 'BASIC' | 'ADDITIONAL';
    };

export const AddExistingSubscriptionModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { toastStore },
  } = useStore();

  const [pageState, setPageState] = useState<PageState>({
    page: 'APP_LIST',
  });

  const goToAppList = () => {
    setPageState({ page: 'APP_LIST' });
  };

  const goToAddRequest = () => {
    setPageState({ page: 'PRODUCT_REQUEST' });
  };

  const goToPrevStep = () => {
    if (pageState.page !== 'DETAIL_SETTING') {
      return;
    }

    if (pageState.step === 'BASIC') {
      setPageState({ page: 'APP_LIST' });
    } else {
      setPageState(prev => ({ ...prev, step: 'BASIC' }));
    }
  };

  useEffect(() => {
    if (!open) {
      goToAppList();
    }
  }, [open]);

  const handleProductClick = (product: SoftwareSimpleModel) => {
    setPageState({ page: 'DETAIL_SETTING', selectedProduct: product, step: 'BASIC' });
  };

  const handleSubmitted = () => {
    toastStore.open({ message: t('Subscrib_Create_08'), hideAction: true });
    onClose();
  };

  const renderTitle = () => {
    switch (pageState.page) {
      case 'DETAIL_SETTING':
        return <TitleWithGoBack title={t('Subscrib_Create_10')} onClick={goToPrevStep} />;
      case 'PRODUCT_REQUEST':
        return <TitleWithGoBack title={t('Subscrib_Create_04')} onClick={goToAppList} />;
      case 'APP_LIST':
      default:
        return t('Subscrib_Create_01');
    }
  };

  const renderContent = () => {
    switch (pageState.page) {
      case 'DETAIL_SETTING':
        return (
          <SubscriptionEditDialogBody
            softwareInfo={{
              id: pageState.selectedProduct.id,
              iconUrl: pageState.selectedProduct.iconUrl,
              name: pageState.selectedProduct.name,
            }}
            onSubmitted={handleSubmitted}
            onClose={onClose}
            step={pageState.step}
            onNextStep={() => setPageState(prev => ({ ...prev, step: 'ADDITIONAL' }))}
          />
        );
      case 'PRODUCT_REQUEST':
        return <ProductRequest onClose={onClose} />;
      case 'APP_LIST':
      default:
        return <AppList onProductClick={handleProductClick} onAddRequestClick={goToAddRequest} />;
    }
  };

  return (
    <Dialog
      size="large"
      title={renderTitle()}
      open={open}
      onClose={onClose}
      height={pageState.page === 'DETAIL_SETTING' ? 1032 : 748}
      useCustomContent={pageState.page === 'APP_LIST' || pageState.page === 'DETAIL_SETTING'}
    >
      {renderContent()}
    </Dialog>
  );
};

type TitleProps = {
  title: string;
  onClick: () => void;
};
export const TitleWithGoBack = ({ title, onClick }: TitleProps) => {
  return (
    <TitleContainer>
      <IconButton onClick={onClick}>
        <ChevronIcon rotateNum={270} />
      </IconButton>
      {title}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
