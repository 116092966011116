import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Button } from '@components/index';
import { YearlyBillingChart } from '@pages/customer/manager/billing/dashboard/YearlyBillingChart';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useGetTenantBillingStat } from '@queryHooks/useTenantBillingStat';
import { numberFormat } from '@utils/numberFormat';
import { WgContainer, WgTitle } from '../styles';

export const BillingWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const { authStore } = useStore();

  const handleClickSeeMore = () => {
    navigate(`/manager/${tenantId}/billings/history`);
  };

  const dashboardNumber = (value?: number) => (value ? numberFormat({ num: value ?? 0 }) : '-');

  const { data: tenantBillingStat } = useGetTenantBillingStat({ month: dayjs().format('YYYY-MM') });

  const currencyUnit = authStore.managerTenantInfo.currencyUnit;

  return (
    <WgContainer width={632} height={516}>
      <WgTitle>
        <span>{t('Acc_GNB_02')}</span>
        <span className="see-more">
          <Button className="see-more" onClick={handleClickSeeMore} variant="text">
            {t('Home_Add')}
            <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
          </Button>
        </span>
      </WgTitle>
      <ExpectedBillings>
        <span className="billing">
          <span>{t('Ad_Home_Settlement_01')}</span>
          <span className="cost">
            <span className="unit">({currencyUnit})</span>
            {dashboardNumber(tenantBillingStat?.annualBillingAmountEstimate)}
          </span>
        </span>
        <span className="line" />
        <span className="billing">
          <span>{t('Ad_Home_Settlement_02')}</span>
          <span className="cost">
            <span className="unit">({currencyUnit})</span>
            {dashboardNumber(tenantBillingStat?.monthlyBillingAmount)}
          </span>
        </span>
      </ExpectedBillings>
      <CostChart>
        <YearlyBillingChart barSize={18} />
      </CostChart>
    </WgContainer>
  );
};

const ExpectedBillings = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 0px 8px;
  margin-top: 24px;
  margin-bottom: 38px;
  height: fit-content;

  & .billing {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 4px;
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
    & .cost {
      display: flex;
      flex-direction: row;
      align-items: end;
      & .unit {
        ${fonts.Body2};
        color: ${props => props.theme.colors['text-gray-sub-darker']};
        margin-right: 2px;
        padding-bottom: 3px;
      }
      ${fonts.Headline4};
      color: ${props => props.theme.colors['text-gray-main']};
    }
  }
  & .line {
    width: 1px;
    height: 44px;
    background: ${props => props.theme.colors['border-gray-lighter']};
    border: none;
    margin-right: 24px;
    margin-top: 4px;
  }
`;

const CostChart = styled.div`
  display: flex;
  height: 100%;
  padding: 0px 8px;
`;
