import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import qs from 'qs';
import IWorkflowBaseTriggerRepository from './IWorkflowBaseTriggerRepository';
import { WorkflowBaseTriggersFilter, WorkflowBaseTriggerDTO, WorkflowBaseTriggerCreateDTO } from './Types';
import 'reflect-metadata';

@injectable()
export class WorkflowBaseTriggerRepository implements IWorkflowBaseTriggerRepository {
  async getList(queries?: WorkflowBaseTriggersFilter): Promise<Page<WorkflowBaseTriggerDTO>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<WorkflowBaseTriggerDTO>>(`/api/v1/workflow-base-triggers?${queryString}`);
    return result.data;
  }
  async getOne(triggerId: string): Promise<WorkflowBaseTriggerDTO> {
    const result = await API.get<WorkflowBaseTriggerDTO>(`/api/v1/workflow-base-triggers/${triggerId}`);
    return result.data;
  }
  async create(rqData: WorkflowBaseTriggerCreateDTO): Promise<WorkflowBaseTriggerDTO> {
    const result = await API.post<WorkflowBaseTriggerDTO>(`/api/v1/workflow-base-triggers`, rqData);
    return result.data;
  }
  async update(triggerId: string, rqData: WorkflowBaseTriggerCreateDTO): Promise<WorkflowBaseTriggerDTO> {
    const result = await API.put<WorkflowBaseTriggerDTO>(`/api/v1/workflow-base-triggers/${triggerId}`, rqData);
    return result.data;
  }
  async delete(triggerId: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/workflow-base-triggers/${triggerId}`);
    return result.data;
  }
}
