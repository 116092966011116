import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { WizardIcon } from '@icons/WizardIcon';
import { useGetSubscriptionBillingStatBySubscription } from '@queryHooks/useSubscriptionBillingStat';
import { OptimizableSubscription } from './OptimizableSubscription';
import { TotalOptimizable } from './TotalOptimizable';

export const OptimizableCost = () => {
  const { colors } = useTheme();
  const { data, isLoading } = useGetSubscriptionBillingStatBySubscription();

  return (
    <Container>
      <TotalOptimizable />

      {isLoading ? (
        <>
          <OptimizableSubscription.Skeleton />
          <OptimizableSubscription.Skeleton />
          <OptimizableSubscription.Skeleton />
        </>
      ) : data?.length === 0 ? (
        <EmptyData>
          <IconWrapper>
            <WizardIcon width={32} height={32} color={colors['ic-purple-light']} />
          </IconWrapper>
          <p>
            모든 구독이 <b>최적화된 비용</b>으로 관리되고 있습니다.
          </p>
          <p>비용을 줄일 수 있는 구독이 감지되면 표시됩니다.</p>
        </EmptyData>
      ) : (
        data?.map(subscriptionBillingStat => (
          <OptimizableSubscription key={subscriptionBillingStat.id} model={subscriptionBillingStat} />
        ))
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 0px 20px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-50']};
  border-radius: 12px;
  box-shadow: 0px 1px 0px 0px #00000005, 0px 0px 3px 0px #00000012;
`;

const EmptyData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  box-shadow: 0px 1px 6px 0px #0000000f;
  padding: 75px 0px 81px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border-radius: 7px;
  ${fonts.Body1};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};

  & b {
    ${fonts.Headline7};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  border-radius: 50%;
  margin-bottom: 20px;
`;
