import styled from '@emotion/styled';
import { Box, TextField, css } from '@mui/material';
import { Button } from '@components/index';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { transientOptions } from '@utils/CommonUtil';

export const ModalWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 364px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border: 1px solid #fff;
  border-radius: 6px;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: inherit;
  padding: 0px 20px 0px 28px;
`;

export const Body = styled(Box, transientOptions)<{ $isNextInfoModal?: boolean }>`
  display: flex;
  flex-direction: column;
  white-space: pre;
  ${({ $isNextInfoModal }) =>
    $isNextInfoModal &&
    css`
      align-items: center;
      p {
        ${fonts.Paragraph1};
        text-align: center;
        margin-bottom: 12px;
      }
    `};
  padding: 12px 28px 4px;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -24px;
    left: 0;
  }
`;
export const HeaderTitle = styled(Box)`
  ${fonts.Headline6}
`;
export const subTitle = styled('strong')`
  ${fonts.Headline8}
  margin-bottom: 4px;
  & span {
    color: ${({ theme: { colors } }) => colors['text-red-light']};
  }
`;

export const InfoWrapper = styled('ul')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: cneter;
  align-items: flex-start;
  gap: 2px;
  padding: 12px 8px;
  margin-top: 40px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
`;
export const InfoItem = styled('li')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  & span {
    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    white-space: pre;
  }
  & + li {
    svg {
      margin-top: -20px;
    }
  }
`;

export const IconButton = styled(Button)`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px 28px 28px;
`;

export const StyledButton = styled(Button)`
  width: 168px;
`;

export const IconWrapper = styled('div')`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colorDefinition.purple.ZERO};
  margin-bottom: 20px;
`;

export const EmailWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  & span {
    ${fonts.Headline5};
    color: ${({ theme: { colors } }) => colors['text-purple']};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
