import { Page } from '@type/Page';
import { injectable } from 'inversify';
import 'reflect-metadata';
import API from '@repositories/Axios';
import qs from 'qs';
import ISubscriptionUseRequestRepository from './ISubscriptionUseRequestRepository';
import {
  Filter,
  SubscriptionUseRequestCreateRq,
  SubscriptionUseRequest,
  SubscriptionUseRequestDetail,
  SubscriptionUseRequestHistory,
  SubscriptionUseRequestStatusUpdateRq,
  SubscriptionUseRequestUpdateRq,
} from './Types';

@injectable()
export class SubscriptionUseRequestRepository implements ISubscriptionUseRequestRepository {
  async getMySubscriptionUseList(queries?: Filter | undefined): Promise<Page<SubscriptionUseRequest>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat' });
    const result = await API.get<Page<SubscriptionUseRequest>>(`/api/v1/my-subscription-use-requests?${queryString}`);
    return result.data;
  }
  async getList(queries?: Filter | undefined): Promise<Page<SubscriptionUseRequest>> {
    const queryString = qs.stringify(queries, { arrayFormat: 'repeat' });
    const result = await API.get<Page<SubscriptionUseRequest>>(`/api/v1/subscription-use-requests?${queryString}`);
    return result.data;
  }

  async create(rqData: SubscriptionUseRequestCreateRq): Promise<SubscriptionUseRequest> {
    const result = await API.post<SubscriptionUseRequest>(`/api/v1/subscription-use-requests`, rqData);
    return result.data;
  }

  async getOne(requestId: string): Promise<SubscriptionUseRequestDetail> {
    const result = await API.get<SubscriptionUseRequestDetail>(`/api/v1/subscription-use-requests/${requestId}`);
    return result.data;
  }

  async update(requestId: string, rqData: SubscriptionUseRequestUpdateRq): Promise<SubscriptionUseRequest> {
    const result = await API.put<SubscriptionUseRequest>(`/api/v1/subscription-use-requests/${requestId}`, rqData);
    return result.data;
  }

  async updateStatus(
    requestId: string,
    rqData: SubscriptionUseRequestStatusUpdateRq,
  ): Promise<SubscriptionUseRequestHistory> {
    const result = await API.put<SubscriptionUseRequestHistory>(
      `/api/v1/subscription-use-requests/${requestId}/status`,
      rqData,
    );
    return result.data;
  }

  async delete(requestId: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/subscription-use-requests/${requestId}`);
    return result.data;
  }
}
