import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { TechnicalSupportListTable } from './TechnicalSupportListTable';

export const TechnicalSupportList = () => {
  return (
    <TechnicalSupportListLayOut>
      <Tittle>기술지원 문의 내역</Tittle>
      <TechnicalSupportListTable />
    </TechnicalSupportListLayOut>
  );
};

const TechnicalSupportListLayOut = styled.section`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
`;

const Tittle = styled.section`
  ${fonts.Headline5}
  padding: 0px 0px 14px 8px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors['border-gray-lighter']}`};
`;
