import type { MonthlySubscriptionsBillingStatisticDTO, Params } from './Types';
import { injectable } from 'inversify';
import qs from 'qs';
import API from '@repositories/Axios';
import { IUserSubscriptionStatRepository } from './IUserSubscriptionStatRepository';

@injectable()
export class UserSubscriptionStatRepository implements IUserSubscriptionStatRepository {
  async getAnnualSubscriptionStat(queries: Params): Promise<MonthlySubscriptionsBillingStatisticDTO[]> {
    const queryString = qs.stringify(queries);
    const result = await API.get<MonthlySubscriptionsBillingStatisticDTO[]>(
      `/api/v1/statistics/annual-subscription-billing?${queryString}`,
    );
    return result.data;
  }
}
