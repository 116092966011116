import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Menu, MenuItem, TableCell, TableRow, useTheme } from '@mui/material';
import { Button, Label } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import type { WorkflowExecModel } from '@models/workflow/WorkflowExecModel';

interface Props {
  data: WorkflowExecModel;
  idx: number;
}

export const WorkflowExecRow = ({ data, idx }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchor);

  const handleClickRow = () => {
    navigate(`${data.id}`);
  };

  const handleClickThreedot = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleClickWorkflowDeleteMenu = () => {
    handleMenuClose();
  };

  const menuStyles = {
    elevation: 0,
    sx: {
      '& .MuiMenu-list': {
        borderRadius: '4px',
      },
    },
  };

  return (
    <>
      <TableRow onClick={handleClickRow}>
        <TableCell align="center">{idx + 1}</TableCell>
        <TableCell>{data.executionStartTime}</TableCell>
        <TableCell align="right">{data.executionTimeInSec} {t('Workflow.exec.seconds')}</TableCell>
        <TableCell>
          <Label variant="solid" color={data.status === 'FAILED' ? 'red' : 'green'}>
            {data.status}
          </Label>
        </TableCell>
        <TableCell>{data.statusMessage}</TableCell>
        <TableCell align="center" onClick={e => e.stopPropagation()}>
          <ThreeDotButton onClick={handleClickThreedot}>
            <ThreeDotIcon
              width={18}
              height={18}
              color={menuOpen ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']}
            />
          </ThreeDotButton>
        </TableCell>
      </TableRow>
      <Menu
        open={menuOpen}
        anchorEl={menuAnchor}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={menuStyles}
        sx={{ marginTop: '5px' }}
      >
        <MenuItem onClick={handleClickWorkflowDeleteMenu} disabled>
          <Delete>{t('Common.delete')}</Delete>
        </MenuItem>
      </Menu>
    </>
  );
};

const Delete = styled.span`
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-red-light']};
`;

const ThreeDotButton = styled(Button)`
  width: 100%;
  height: 42px;
  cursor: pointer;
`;
