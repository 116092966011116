import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Popover, TextField } from '@mui/material';
import { CheckFilterRow } from './CheckFilterRow';

export type CheckFilterItemType = {
  displayName: string;
  filterValue: string | string[];
};

type Props = {
  anchorEl: HTMLButtonElement;
  open: boolean;
  onClose: () => void;
  enableFilterSearch?: boolean;
  enableFilterAll?: boolean;
  itemList: CheckFilterItemType[];
  checkedFilterValues: Set<string>;
  onFilterCheckChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    { filterValue, checked }: { filterValue: string | string[] | 'ALL'; checked: boolean },
  ) => void;
};

export const CheckFilterContents = ({
  anchorEl,
  open,
  onClose,
  enableFilterSearch,
  enableFilterAll,
  itemList,
  checkedFilterValues,
  onFilterCheckChange,
}: Props) => {
  const [filterSearchKeyword, setFilterSearchKeyword] = useState<string>('');
  const { t } = useTranslation();

  const handleFilter = {
    close: () => {
      onClose();
    },
    search: (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterSearchKeyword(event.target.value);
    },
  };

  const isAllChecked = itemList.every(({ filterValue }) => {
    if (Array.isArray(filterValue)) {
      return filterValue.every(value => checkedFilterValues.has(value));
    }
    return checkedFilterValues.has(filterValue);
  });

  return (
    <Container
      anchorEl={anchorEl}
      open={open}
      onClose={handleFilter.close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {enableFilterSearch && (
        <FilterSearch size="small" value={filterSearchKeyword} onChange={handleFilter.search} placeholder="직접 입력" />
      )}
      <SelectWrap>
        {enableFilterAll && filterSearchKeyword.length === 0 && (
          <CheckFilterRow key="ALL" checked={isAllChecked} filterValue="ALL" onFilterChange={onFilterCheckChange}>
            {t('Acc_Main_12')}
          </CheckFilterRow>
        )}
        <ListWrap>
          {itemList
            ?.filter(({ displayName }) => displayName.toLowerCase().includes(filterSearchKeyword.toLowerCase()))
            .map(item => (
              <CheckFilterRow
                key={Array.isArray(item.filterValue) ? item.filterValue.join('_') : item.filterValue}
                checked={
                  Array.isArray(item.filterValue)
                    ? item.filterValue.every(value => checkedFilterValues.has(value))
                    : checkedFilterValues.has(item.filterValue)
                }
                filterValue={item.filterValue}
                onFilterChange={onFilterCheckChange}
              >
                {item.displayName}
              </CheckFilterRow>
            ))}
        </ListWrap>
      </SelectWrap>
    </Container>
  );
};

const Container = styled(Popover)`
  & .MuiPaper-root {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    box-shadow: unset;
  }
`;

const SelectWrap = styled('div')`
  width: 100%;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-width: 0px 1px 1px 1px;
`;

const ListWrap = styled('div')`
  max-height: 293px;
  height: 100%;
  overflow: auto;
`;
const FilterSearch = styled(TextField)`
  height: 36px;
  & .MuiOutlinedInput-notchedOutline,
  & .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
    border-radius: 1.5px 1.5px 0 0;
  }
  & .MuiInputBase-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors['border-purple']};
    border-radius: 1.5px 1.5px 0 0;
  }
  & .MuiInputBase-root {
    height: 36px;
    padding: 0;
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    border-radius: 2px 2px 0 0;
    &.Mui-focused {
      border-width: 1px 1px 0;
    }
    & .MuiInputBase-input {
      height: auto;
      padding: 9px 12px 6px;
      border: none;
    }
  }
`;
