import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type { TechnicalSupportRepository } from '@repositories/technicalSupportRepository/impl/TechnicalSupportRepository';
import { TechnicalInquiryCountStatDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryCountStatDTO';
import { TechnicalInquiryDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryDTO';
import { TechnicalInquiryHistoryDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryHistoryDTO';
import { TechnicalInquiryListDTOModel } from '@repositories/technicalSupportRepository/model/TechnicalInquiryListDTO';
import type {
  TechnicalInquiryCreateDTO,
  TechnicalInquiryHistoryCreateDTO,
  TechnicalInquiryListFilter,
  TechnicalInquiryUpdateDTO,
} from '@repositories/technicalSupportRepository/technicalSupport.types';
import type { Page } from '@type/Page';

export const technicalSupportKey = {
  all: ['TechnicalSupport'] as const,
  lists: () => [...technicalSupportKey.all, 'list'] as const,
  list: (filter?: TechnicalInquiryListFilter) => [...technicalSupportKey.lists(), { ...filter }] as const,
  listInfinite: (filter?: TechnicalInquiryListFilter) => [...technicalSupportKey.list(filter), 'infinite'] as const,
  myListInfinite: (filter?: TechnicalInquiryListFilter) =>
    [...technicalSupportKey.list(filter), 'my-infinite'] as const,
  details: () => [...technicalSupportKey.all, 'detail'] as const,
  detail: (id: string) => [...technicalSupportKey.details(), id] as const,
};

const technicalSupportRepo = repository.get<TechnicalSupportRepository>(KEYS.TECHNICAL_SUPPORT_REPOSITORY);

export const useGetInfiniteTechnicalInquiryList = (
  queries?: TechnicalInquiryListFilter,
  options?: UseInfiniteQueryOptions<Page<TechnicalInquiryListDTOModel>>,
) =>
  useInfiniteQuery({
    queryKey: technicalSupportKey.listInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await technicalSupportRepo.getTechnicalInquiryList({ ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new TechnicalInquiryListDTOModel(dto)),
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetInfiniteMyTechnicalInquiryList = (
  queries?: TechnicalInquiryListFilter,
  options?: UseInfiniteQueryOptions<Page<TechnicalInquiryListDTOModel>>,
) =>
  useInfiniteQuery({
    queryKey: technicalSupportKey.myListInfinite(queries),
    queryFn: async ({ pageParam = 0 }) => {
      const result = await technicalSupportRepo.getMyTechnicalInquiryList({ ...queries, page: pageParam });
      return {
        ...result,
        content: result.content.map(dto => new TechnicalInquiryListDTOModel(dto)),
      };
    },
    ...options,
    getNextPageParam: lastPage => (lastPage.last ? undefined : lastPage.number + 1),
  });

export const useGetTechnicalCountStat = () =>
  useQuery({
    queryKey: technicalSupportKey.detail('count-stat'),
    queryFn: async () => {
      const result = await technicalSupportRepo.getTechnicalInquiryCountStat();
      return new TechnicalInquiryCountStatDTOModel(result);
    },
  });

export const useAddTechnicalInquiry = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: TechnicalInquiryCreateDTO) => {
      await technicalSupportRepo.createTechnicalInquiry(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: technicalSupportKey.lists() });
    },
  });
};

// 문의 내역 상세
export const useGetTechnicalInquiryDetail = (
  technicalInquiryId: string,
  options?: UseQueryOptionsType<TechnicalInquiryDTOModel>,
) =>
  useQuery({
    queryKey: technicalSupportKey.detail(technicalInquiryId),
    queryFn: async () => {
      const result = await technicalSupportRepo.getTechnicalDetail(technicalInquiryId);
      return new TechnicalInquiryDTOModel(result);
    },
    ...options,
  });

// 문의 내역 수정
export const usePutTechnicalInquiryUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      technicalInquiryId,
      rqData,
    }: {
      technicalInquiryId: string;
      rqData: TechnicalInquiryUpdateDTO;
    }) => {
      const result = await technicalSupportRepo.patchTechnicalInquiryUpdate(technicalInquiryId, rqData);
      return new TechnicalInquiryDTOModel(result);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: technicalSupportKey.detail(variables.technicalInquiryId) });
    },
  });
};

// 문의 내역 삭제
export const useDeleteTechnicalInquiry = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (technicalInquiryId: string) => {
      await technicalSupportRepo.deleteTechnicalInquiry(technicalInquiryId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: technicalSupportKey.lists() });
    },
  });
};

// 기술  지원 요청 처리 내역 추가
export const useCreateTechnicalInquiryHistory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: TechnicalInquiryHistoryCreateDTO) => {
      const result = await technicalSupportRepo.createTechnicalInquiryHistory(data);
      return new TechnicalInquiryHistoryDTOModel(result);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: technicalSupportKey.details() });
    },
  });
};
