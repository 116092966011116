import { useEffect, useState } from 'react';
import i18n from '@locales/i18n';
import { IntroductionEn } from './IntroductionEn';
import { IntroductionKo } from './IntroductionKo';
import * as S from './IntroductionKoStyles';

export const Introduction = () => {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const updateLanguage = () => {
      setLanguage(i18n.language);
    };

    // Subscribe to language changes
    i18n.on('languageChanged', updateLanguage);

    // Unsubscribe when component unmounts
    return () => {
      i18n.off('languageChanged', updateLanguage);
    };
  }, []);

  return <S.Container className="container">{language === 'ko' ? <IntroductionKo /> : <IntroductionEn />}</S.Container>;
};
