import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { CheckIcon } from '@icons/CheckIcon';
import { ChevronIcon } from '@icons/ChevronIcon';
import {
  useGetSubscriptionUseRequest,
  useUpdateStatusSubscriptionUseRequest,
} from '@queryHooks/useSubscriptionUseRequest';
import type { SubscriptionUseRequestHistoryModel } from '@models/subscription/SubscriptionUseRequestHistoryModel';
import * as S from './UsageRegisterDetailStyles';
import { useUserForm } from './validationSchema';

type Status = 'APPROVED' | 'REJECTED' | 'PENDING';

export const UsageRegisterDetail = observer(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    uiStore: { alertStore: modal },
    categoryStore,
  } = useStore();
  const params = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState<Status | undefined>(undefined);
  const { data: usageRq, isLoading, isError } = useGetSubscriptionUseRequest(params.id ?? '');

  const handleClickBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (status === e.currentTarget.id) setStatus(undefined);
    else setStatus(e.currentTarget.id as Status);
  };

  const { register, getValues } = useUserForm();
  const { mutate: updateUseRqStatus } = useUpdateStatusSubscriptionUseRequest();

  const validation = {
    success: () => {
      const comment = getValues('comment');
      if (status)
        updateUseRqStatus(
          { requestId: params.id as string, rqData: { status, comment } },
          {
            onSuccess: () => {
              navigate(`/manager/${params.tenantId}/subscriptions/usage-registers`);
            },
          },
        );
    },
  };

  useEffect(() => {
    if (usageRq?.status !== 'CREATED') setStatus(usageRq?.status);
    else setStatus(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageRq]);

  const productType = usageRq?.categoryId ? categoryStore.findRootCategory(usageRq.categoryId)?.name : '';

  return (
    <S.Container>
      <S.Header onClick={() => navigate(-1)}>
        <ChevronIcon rotateNum={270} />
        {t('Subscrib_GNB_04')}
      </S.Header>
      <S.RequestWrapper>
        <S.Title>{t('Subscrib_Detail_UseRequest_27')}</S.Title>
        <S.RequestContents>
          <div>
            <strong className="content-name">{t('Subscrib_Detail_UseRequest_06')}</strong>
            <span className="content-value">{productType}</span>
          </div>
          <div>
            <strong className="content-name">{t('Subscrib_Detail_UseRequest_05')}</strong>
            <span className="content-value">{dayjs(usageRq?.createdDate).format(t('DateFormat_YMD'))}</span>
          </div>
        </S.RequestContents>
        <S.RequestContents>
          <div>
            <strong className="content-name">{t('Subscrib_Detail_UseRequest_07')}</strong>
            <span className="content-value">{usageRq?.productName}</span>
          </div>
          <div>
            <strong className="content-name">{t('Subscrib_Detail_UseRequest_08')}</strong>
            <span className="content-value">{usageRq?.userName}</span>
          </div>
        </S.RequestContents>
        <S.RequestContents>
          <div>
            <strong className="content-name">{t('Subscrib_Main_12')}</strong>
            <span className="content-value">
              {usageRq?.planName}
              {usageRq?.isInUseByTenant && (
                <S.StylredLabel variant="contain" color="gradient">
                  {t('Ad_Store_Product_State')}
                </S.StylredLabel>
              )}
            </span>
          </div>
          <div>
            <strong className="content-name">{t('Member_User_Bulk_Create_18')}</strong>
            <span className="content-value">
              {usageRq?.userGroup !== undefined && usageRq?.userGroup.length > 0 ? usageRq?.userGroup[0].name : '-'}
            </span>
          </div>
        </S.RequestContents>
        <S.RequestContents>
          <div>
            <strong className="content-name">{t('Acc_Detail_04')}</strong>
            <span className="content-value">{`${dayjs(usageRq?.startDate).format(t('DateFormat_YMD'))}~${dayjs(
              usageRq?.endDate,
            ).format(t('DateFormat_YMD'))}`}</span>
          </div>
        </S.RequestContents>
        <S.RequestContents>
          <div className="inquiry">
            <strong className="content-name">{t('Subscrib_Detail_UseRequest_15')}</strong>
            <span className="content-value">{usageRq?.inquiry}</span>
          </div>
        </S.RequestContents>
      </S.RequestWrapper>
      {isLoading || isError ? null : usageRq.isCompleted ? (
        !!usageRq.completeHistory && <CompleteRequestReply completeHistory={usageRq.completeHistory} />
      ) : (
        <S.RequestReplyWrapper>
          <S.Title>{t('Subscrib_Detail_UseRequest_16')}</S.Title>
          <S.PendingDescription>{t('Subscrib_Detail_UseRequest_17')}</S.PendingDescription>
          <S.Buttons>
            <S.StyledButton
              id="APPROVED"
              className={status === 'APPROVED' ? 'contain' : 'outline'}
              variant={status === 'APPROVED' ? 'contain' : 'outline'}
              onClick={handleClickBtn}
              size="extraLarge"
              startIcon={status === 'APPROVED' && <CheckIcon width={16} height={16} color={theme.colors['ic-white']} />}
            >
              {t('Subscrib_Detail_UseRequest_12')}
            </S.StyledButton>
            <S.StyledButton
              id="REJECTED"
              className={
                status === 'APPROVED'
                  ? 'approved'
                  : status === 'PENDING'
                  ? 'pending'
                  : status === 'REJECTED'
                  ? 'rejected contain'
                  : ''
              }
              variant={status === 'REJECTED' ? 'contain' : 'outline'}
              onClick={handleClickBtn}
              size="extraLarge"
              startIcon={status === 'REJECTED' && <CheckIcon width={16} height={16} color={theme.colors['ic-white']} />}
            >
              {t('Subscrib_Detail_UseRequest_13')}
            </S.StyledButton>
            <S.StyledButton
              id="PENDING"
              className={status === 'PENDING' ? 'contain' : 'outline'}
              variant={status === 'PENDING' ? 'contain' : 'outline'}
              onClick={handleClickBtn}
              size="extraLarge"
              startIcon={status === 'PENDING' && <CheckIcon width={16} height={16} color={theme.colors['ic-white']} />}
            >
              {t('Subscrib_Detail_UseRequest_11')}
            </S.StyledButton>
          </S.Buttons>
          <S.SubTitle>{t('Subscrib_Detail_UseRequest_15')}</S.SubTitle>
          <S.StyledTextField
            {...register('comment')}
            multiline
            rows={6}
            placeholder={
              status === 'APPROVED'
                ? t('Subscrib_Detail_UseRequest_28')
                : status === 'PENDING'
                ? t('Subscrib_Detail_UseRequest_29')
                : status === 'REJECTED'
                ? t('Subscrib_Detail_UseRequest_30')
                : t('Subscrib_Detail_UseRequest_31')
            }
          />
          <S.ButtonWrapper>
            <Button
              paddingHorizontal={16}
              variant="contain"
              size="large"
              onClick={() =>
                modal.open({
                  title: t('Subscrib_Detail_UseRequest_21'),
                  message: t('Subscrib_Detail_UseRequest_22'),
                  useCancel: true,
                  onConfirm: validation.success,
                })
              }
              disabled={status === undefined}
            >
              {t('Subscrib_Detail_UseRequest_18')}
            </Button>
          </S.ButtonWrapper>
        </S.RequestReplyWrapper>
      )}
    </S.Container>
  );
});

type CompleteRequestReplyProps = {
  completeHistory: SubscriptionUseRequestHistoryModel;
};
export const CompleteRequestReply = ({ completeHistory }: CompleteRequestReplyProps) => {
  const { t } = useTranslation();
  return (
    <S.RequestReplyWrapper>
      <S.Title>{t('Request_Processing')}</S.Title>
      <S.RequestContents>
        <div>
          <strong className="content-name">{t('Subscrib_Detail_UseRequest_09')}</strong>
          <span className="content-value">
            <Label variant="contain" color={completeHistory.statusLabelColor}>
              {completeHistory.statusStr}
            </Label>
          </span>
        </div>
        <div>
          <strong className="content-name">{t('Subscrib_Detail_UseRequest_19')}</strong>
          <span className="content-value">{dayjs(completeHistory.createdDate).format(t('DateFormat_YMD'))}</span>
        </div>
      </S.RequestContents>
      <S.RequestContents>
        <div>
          <strong className="content-name">{t('Acc_Detail_12')}</strong>
          <span className="content-value">{completeHistory.managerName}</span>
        </div>
      </S.RequestContents>
      <S.RequestInquiry>
        <div>
          <strong className="content-name">{t('Subscrib_Detail_BuyRequest_05')}</strong>
          <p className="content-value">{completeHistory.comment}</p>
        </div>
      </S.RequestInquiry>
    </S.RequestReplyWrapper>
  );
};
