import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { TableBody, TableRow, TableCell, Table, TableHead, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { Label } from '@components/Label';
import { TableHeaderCell } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { UserLicenseListFilter } from '@repositories/subscriptionUserRepository/Types';
import { useSubscriptionUserLicenses } from '@queryHooks/useSubscriptionUser';
import { useGetTenantMember } from '@queryHooks/useTenant';
import { getSortOrder } from '@utils/index';
import { SubscriptionRow } from './SubscriptionRow';

export const LicenseRetrieve = observer(() => {
  const params = useParams();
  const { tenantId, userId } = params;
  const { data: user, isSuccess } = useGetTenantMember(tenantId as string, userId as string);
  const [autoLicensesFilter, setAutoLicensesFilter] = useState<UserLicenseListFilter>({ sort: [], loginId: '' });
  const [manualLicensesFilter, setManualLicensesFilter] = useState<UserLicenseListFilter>({ sort: [], loginId: '' });
  const { data: userLicenses } = useSubscriptionUserLicenses(
    { ...autoLicensesFilter, loginId: user?.username as string },
    { enabled: isSuccess },
  );
  const autoLicenses = userLicenses?.content.filter(license => license.canRevode);
  const manualLicenses = userLicenses?.content.filter(license => !license.canRevode);
  const navigate = useNavigate();

  const handleLicensesFilterUpdate = (
    filterStateSetter: React.Dispatch<React.SetStateAction<UserLicenseListFilter>>,
    orderBy: string,
    order: 'asc' | 'desc' | undefined,
  ) => {
    filterStateSetter((prev: UserLicenseListFilter) => ({
      ...prev,
      sort: order ? [`${orderBy}.${order}`] : undefined,
      loginId: user?.username as string,
    }));
  };

  const handleAutoLicensesFilterUpdateFilter = {
    updateSort: (orderBy: string, order: 'asc' | 'desc' | undefined) =>
      handleLicensesFilterUpdate(setAutoLicensesFilter, orderBy, order),
  };

  const handleManualLicensesUpdateFilter = {
    updateSort: (orderBy: string, order: 'asc' | 'desc' | undefined) =>
      handleLicensesFilterUpdate(setManualLicensesFilter, orderBy, order),
  };

  return (
    <Container>
      <Header>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        <strong>구독 라이선스 회수</strong>
      </Header>
      <Body>
        <UserInfoWrapper>
          <UserInfoTitle>탈퇴 구성원 정보</UserInfoTitle>
          <Divider />
          <UserInfoDetail>
            <TableBody>
              <StyledTableRow>
                <TableCell>
                  <UserInfoDetailEl>
                    <UserInfoDetailElName>구분</UserInfoDetailElName>
                    <UserInfoDetailElValue>
                      <Label variant="solid" color="red">
                        탈퇴 구성원
                      </Label>
                    </UserInfoDetailElValue>
                  </UserInfoDetailEl>
                </TableCell>
                <TableCell>
                  <UserInfoDetailEl>
                    <UserInfoDetailElName>탈퇴 날짜</UserInfoDetailElName>
                    <UserInfoDetailElValue>{user?.deletedDate || '-'}</UserInfoDetailElValue>
                  </UserInfoDetailEl>
                </TableCell>
                <TableCell>
                  <UserInfoDetailEl />
                </TableCell>
                <TableCell>
                  <UserInfoDetailEl />
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell>
                  <UserInfoDetailEl>
                    <UserInfoDetailElName>이름</UserInfoDetailElName>
                    <UserInfoDetailElValue>{user?.name}</UserInfoDetailElValue>
                  </UserInfoDetailEl>
                </TableCell>
                <TableCell>
                  <UserInfoDetailEl>
                    <UserInfoDetailElName>계정</UserInfoDetailElName>
                    <UserInfoDetailElValue>{user?.email}</UserInfoDetailElValue>
                  </UserInfoDetailEl>
                </TableCell>
                <TableCell>
                  <UserInfoDetailEl>
                    <UserInfoDetailElName>그룹</UserInfoDetailElName>
                    <UserInfoDetailElValue>
                      {user?.usergroups.map(group => group).length ? user?.usergroups.map(group => group) : '-'}
                    </UserInfoDetailElValue>
                  </UserInfoDetailEl>
                </TableCell>
                <TableCell>
                  <UserInfoDetailEl>
                    <UserInfoDetailElName>연락처</UserInfoDetailElName>
                    <UserInfoDetailElValue>{user?.phone}</UserInfoDetailElValue>
                  </UserInfoDetailEl>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </UserInfoDetail>
          <Divider className="bottom" />
        </UserInfoWrapper>
        <UseSubscriptionListWrapper>
          <UseSubscriptionListTitle>사용 항목</UseSubscriptionListTitle>
          <LicenseRetrieveList>
            {autoLicenses?.length === 0 ? null : (
              <div>
                <SubscriptionType>라이선스 자동 회수 가능</SubscriptionType>
                <SubscriptionTypeDescription>
                  해당 서비스와 연동하여 사용자 라이선스를 자동으로 회수합니다.
                </SubscriptionTypeDescription>
                <TableWrapper>
                  <TableHead>
                    <StyledTableRow>
                      <TableCell width={342}>구독명</TableCell>
                      <TableCell width={240}>제품명</TableCell>
                      <TableCell width={240}>라이선스 코드</TableCell>
                      <TableCell width={180}>라이선스 상태</TableCell>
                      <TableCell width={120}>사용자 상태</TableCell>
                      <TableCell width={160}>마지막 사용 날짜</TableCell>
                      <TableHeaderCell
                        width={120}
                        orderBy="billableStatus"
                        order={getSortOrder(autoLicensesFilter.sort, 'billableStatus')}
                        onSortChange={handleAutoLicensesFilterUpdateFilter.updateSort}
                      >
                        과금 여부
                      </TableHeaderCell>
                      <TableCell width={150}>관리</TableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {autoLicenses?.map(license => {
                      return <SubscriptionRow key={license.subscription.id} license={license} type="auto" />;
                    })}
                  </TableBody>
                </TableWrapper>
              </div>
            )}
            {manualLicenses?.length === 0 ? null : (
              <div>
                <SubscriptionType>라이선스 수동 회수 가능</SubscriptionType>
                <SubscriptionTypeDescription>
                  해당 서비스에서 라이선스를 직접 회수해야합니다. [라이선스 회수 완료] 클릭 시, 구독의 사용자
                  리스트에서만 삭제됩니다.
                </SubscriptionTypeDescription>
                <TableWrapper>
                  <TableHead>
                    <StyledTableRow>
                      <TableCell width={342}>구독명</TableCell>
                      <TableCell width={240}>제품명</TableCell>
                      <TableCell width={240}>라이선스 코드</TableCell>
                      <TableCell width={180}>라이선스 상태</TableCell>
                      <TableCell width={120}>사용자 상태</TableCell>
                      <TableCell width={160}>마지막 사용 날짜</TableCell>
                      <TableHeaderCell
                        width={120}
                        orderBy="billableStatus"
                        order={getSortOrder(manualLicensesFilter.sort, 'billableStatus')}
                        onSortChange={handleManualLicensesUpdateFilter.updateSort}
                      >
                        과금 여부
                      </TableHeaderCell>
                      <TableCell width={150}>관리</TableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {manualLicenses?.map(license => {
                      return <SubscriptionRow key={license.subscription.id} license={license} type="manual" />;
                    })}
                  </TableBody>
                </TableWrapper>
              </div>
            )}
          </LicenseRetrieveList>
        </UseSubscriptionListWrapper>
      </Body>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 88px);
  height: calc(100vh - var(--manager-gnb-height));
  padding: 28px 56px 28px 32px;
  overflow-y: auto;
`;
const TableWrapper = styled(Table)`
  border-radius: 5px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fonts.Headline5};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 40px;
  gap: 4px;
`;

const Body = styled.div`
  margin: 0 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 72px;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfoTitle = styled.strong`
  ${fonts.Headline7};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const Divider = styled.hr`
  margin: 16px 0px 0px 0px;
  width: 100%;
  border: 0;
  height: 1px;
  background: ${props => props.theme.colors['border-gray-light']};
  &.bottom {
    margin: 0;
  }
`;

const UserInfoDetail = styled(Table)`
  & > tbody td {
    height: 72px;
  }
`;

const UserInfoDetailEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: inherit;
  justify-content: center;
  padding: 0px 4px;
`;

const UserInfoDetailElName = styled.span`
  ${fonts.Headline9};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;

const UserInfoDetailElValue = styled.span`
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const UseSubscriptionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UseSubscriptionListTitle = styled.span`
  ${fonts.Headline7};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 16px;
`;

const SubscriptionType = styled.strong`
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 2px;
`;

const SubscriptionTypeDescription = styled.p`
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  margin-bottom: 12px;
`;

const LicenseRetrieveList = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
`;
