import styled from '@emotion/styled';
import { Skeleton, useTheme } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { CircleDashFillIcon } from '@icons/CircleDashFillIcon';
import CircleUpFillIcon from '@icons/CircleUpFillIcon';
import { numberFormat } from '@utils/numberFormat';

type Props = {
  title: string;
  criteria: string;
  value: number;
  variations: number;
};

export const AnalyzeElement = ({ title, criteria, value, variations }: Props) => {
  const theme = useTheme();
  const { authStore } = useStore();

  const currencyUnit = authStore.managerTenantInfo.currencyUnit;

  const variationClassName =
    variations > 0 ? 'variation-positive' : variations === 0 ? 'variation-zero' : 'variation-negative';

  return (
    <Container>
      <Title>{title}</Title>
      <Cost>
        <div>
          <CurrencyUnit>({currencyUnit})</CurrencyUnit>
          {numberFormat({ num: value })}
        </div>
        <Variations>
          {criteria}
          <span className={variationClassName}>
            {variations > 0 ? (
              <CircleUpFillIcon />
            ) : variations === 0 ? (
              <CircleDashFillIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
            ) : (
              <CircleUpFillIcon rotateNum={180} color={theme.colors['ic-red-light']} />
            )}
            {Math.abs(variations).toFixed(2)}%
          </span>
        </Variations>
      </Cost>
    </Container>
  );
};

const AnalyzeElementSkeleton = ({ title }: Partial<Props>) => (
  <Container>
    <Title>{title}</Title>
    <Cost>
      <Skeleton width="300px" />
      <Variations>
        <Skeleton width="150px" />
      </Variations>
    </Cost>
  </Container>
);
AnalyzeElement.Skeleton = AnalyzeElementSkeleton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 36px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};

  & + & {
    border-left: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  }
`;

const Title = styled.div`
  ${fonts.Headline7};
  color: ${props => props.theme.colors['text-gray-main']};
  margin-bottom: 16px;
`;

const Cost = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  ${fonts.Headline11};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const CurrencyUnit = styled.span`
  ${fonts.Body1};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  margin-bottom: 4px;
  margin-right: 4px;
`;

const Variations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  & .variation-positive {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    ${fonts.Headline6};
    color: ${props => props.theme.colors['text-green-light']};
  }
  & .variation-zero {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    ${fonts.Headline6};
    color: ${props => props.theme.colors['text-gray-light']};
  }
  & .variation-negative {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    ${fonts.Headline6};
    color: ${props => props.theme.colors['text-red-light']};
  }
`;
