const defaultWidth = 16;
const defaultHeight = 16;

export const GraphIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M1.66699 1.83301C1.66699 1.55687 1.44313 1.33301 1.16699 1.33301C0.89085 1.33301 0.666992 1.55687 0.666992 1.83301V14.1663C0.666992 14.4425 0.89085 14.6663 1.16699 14.6663H13.3337C13.6098 14.6663 13.8337 14.4425 13.8337 14.1663C13.8337 13.8902 13.6098 13.6663 13.3337 13.6663H1.66699V1.83301Z"
          fill={color}
        />
        <path
          d="M14.8539 5.18656C15.0491 4.9913 15.0491 4.67472 14.8539 4.47945C14.6586 4.28419 14.342 4.28419 14.1468 4.47945L10.3337 8.29257L7.85388 5.81279C7.65862 5.61753 7.34203 5.61753 7.14677 5.81279L3.14677 9.81279C2.95151 10.008 2.95151 10.3246 3.14677 10.5199C3.34203 10.7152 3.65862 10.7152 3.85388 10.5199L7.50033 6.87345L9.98011 9.35323C10.1754 9.54849 10.4919 9.54849 10.6872 9.35323L14.8539 5.18656Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.16654 1.2002C1.51632 1.2002 1.79987 1.48375 1.79987 1.83353V13.5335H13.9999C14.3496 13.5335 14.6332 13.8171 14.6332 14.1669C14.6332 14.5166 14.3496 14.8002 13.9999 14.8002H1.16654C0.816756 14.8002 0.533203 14.5166 0.533203 14.1669V1.83353C0.533203 1.48375 0.816756 1.2002 1.16654 1.2002ZM14.9477 4.38569C15.195 4.63303 15.195 5.03403 14.9477 5.28136L10.781 9.44803C10.5337 9.69536 10.1327 9.69536 9.88537 9.44803L7.49987 7.06253L3.9477 10.6147C3.70037 10.862 3.29937 10.862 3.05204 10.6147C2.8047 10.3674 2.8047 9.96636 3.05204 9.71903L7.05203 5.71903C7.29937 5.4717 7.70037 5.4717 7.9477 5.71903L10.3332 8.10453L14.052 4.38569C14.2994 4.13836 14.7004 4.13836 14.9477 4.38569Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
