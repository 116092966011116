const defaultWidth = 24;
const defaultHeight = 24;

export const ArrowIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#24292E',
  rotateNum = 0,
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotateNum}deg)` }}
    >
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4001 14.9955C18.4001 15.5987 17.9111 16.0878 17.3078 16.0878C16.7045 16.0878 16.2155 15.5987 16.2155 14.9955L16.2155 9.32483L7.46479 18.0755C7.03821 18.5021 6.3466 18.5021 5.92003 18.0755C5.49345 17.649 5.49345 16.9573 5.92003 16.5308L14.6707 7.78008L9.0001 7.78008C8.39684 7.78008 7.90779 7.29103 7.90779 6.68777C7.90779 6.0845 8.39684 5.59546 9.0001 5.59546L17.3078 5.59546C17.9111 5.59546 18.4001 6.0845 18.4001 6.68777L18.4001 14.9955Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

ArrowIcon.displayName = 'ArrowIcon';
