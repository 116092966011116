import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { TableRow, TableCell, Avatar } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { useDeleteSubscriptionUser } from '@queryHooks/useSubscription';
import { UserSubscriptionDTOModel } from '@models/UserSubscriptionDTOModel';
import { ModalBody } from './ModalBody';

interface Props {
  license: UserSubscriptionDTOModel;
  type: 'auto' | 'manual';
}

export const SubscriptionRow = observer(({ license, type }: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { alertStore, toastStore },
  } = useStore();
  const params = useParams();
  const navigate = useNavigate();

  const { userId } = params;

  const { mutate: deleteUserFromSubscription } = useDeleteSubscriptionUser();

  const handleClickRetrieve = () => {
    if (type === 'auto') {
      alertStore.open({ title: '라이선스 회수', message: '라이선스 자동 회수가 가능한 구독입니다.' });
    } else {
      alertStore.open({
        title: '라이선스 회수',
        type: 'error',
        message: <ModalBody type={type} />,
        confirmName: '회수 완료',
        onConfirm: () => {
          deleteUserFromSubscription(
            { subscriptionId: license.subscription.id, userLoginIdOrUserId: userId as string },
            {
              onSuccess: () => {
                toastStore.open(t('Member_User_Delete_Tab_09'));
              },
            },
          );
        },
        useCancel: true,
      });
    }
  };

  const handleClickSubscription = () => {
    navigate(`/manager/${params.tenantId}/subscriptions/list/${license.subscription.id}/user`);
  };

  return (
    <StyledTableRow key={license.subscription.id}>
      <StyledTableCell>
        <SubscriptionNameCell onClick={handleClickSubscription}>{license.subscription.name}</SubscriptionNameCell>
      </StyledTableCell>
      <StyledTableCell>
        <ProductNameCell>
          <Avatar
            sx={{ width: '16px', height: '16px', borderRadius: '4px' }}
            variant="square"
            src={license.software.iconUrl}
          />
          {license.software.name}
        </ProductNameCell>
      </StyledTableCell>
      <StyledTableCell>{license.licenseCode}</StyledTableCell>
      <StyledTableCell>
        {!license.licenseStatus && '-'}
        {license.licenseStatus === 'ACTIVE' && (
          <Label variant="solid" color="blue">
            유효한 라이선스
          </Label>
        )}
        {license.licenseStatus === 'INACTIVE' && (
          <Label variant="solid" color="gray">
            유효하지 않은 라이선스
          </Label>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {license.subscriptionUserStatus === 'ACTIVE' ? (
          <Label variant="solid" color="blue">
            활성
          </Label>
        ) : (
          <Label variant="solid" color="gray">
            비활성
          </Label>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {license.lastLoginTime ? dayjs(license.lastLoginTime).format('YYYY.MM.DD') : '-'}
      </StyledTableCell>
      <StyledTableCell>
        <Label variant="outline" color={license.billableStatus ? 'gray' : 'red'}>
          {license.billableStatus ? 'Y' : 'N'}
        </Label>
      </StyledTableCell>
      <StyledTableCell>
        <LicenseRetrieve variant="contain" type="button" onClick={handleClickRetrieve}>
          {type === 'auto' ? '라이선스 회수' : '라이선수 회수 완료'}
        </LicenseRetrieve>
      </StyledTableCell>
    </StyledTableRow>
  );
});

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
`;

const SubscriptionNameCell = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-blue-light']};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ProductNameCell = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-main']};
`;

const LicenseRetrieve = styled(Button)`
  background: ${props => props.theme.colors['bg-gray-light']};
  padding: 3px 8px;
  border-radius: 3px;
  ${fonts.Body4};
  &:hover {
    background: ${props => props.theme.colors['state-white-purple-pressed']};
  }
`;

const StyledTableCell = styled(TableCell)`
  text-overflow: clip;
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-collapse: collapse;
`;
