const defaultWidth = 40;
const defaultHeight = 40;

export const IllustOut = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M16 27.5V13C16 12.1716 16.6716 11.5 17.5 11.5H27.5C28.3284 11.5 29 12.1716 29 13V27.5C29 28.3284 28.3284 29 27.5 29H17.5C16.6716 29 16 28.3284 16 27.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5002 12.3002C17.1136 12.3002 16.8002 12.6136 16.8002 13.0002V27.5002C16.8002 27.8868 17.1136 28.2002 17.5002 28.2002H27.5002C27.8868 28.2002 28.2002 27.8868 28.2002 27.5002V13.0002C28.2002 12.6136 27.8868 12.3002 27.5002 12.3002H17.5002ZM15.2002 13.0002C15.2002 11.7299 16.2299 10.7002 17.5002 10.7002H27.5002C28.7705 10.7002 29.8002 11.7299 29.8002 13.0002V27.5002C29.8002 28.7704 28.7704 29.8002 27.5002 29.8002H17.5002C16.2299 29.8002 15.2002 28.7704 15.2002 27.5002V13.0002Z"
          fill="#24292E"
        />
        <path
          d="M11 27.7609V12.739C11 12.0198 11.5105 11.4018 12.2167 11.266L22.2167 9.3429C23.1418 9.16499 24 9.87387 24 10.8159V29.684C24 30.6261 23.1418 31.3349 22.2167 31.157L12.2167 29.2339C11.5105 29.0981 11 28.4801 11 27.7609Z"
          fill="#D1D5DA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0658 8.55774C23.4843 8.28496 24.8002 9.37189 24.8002 10.8164V29.6845C24.8002 31.1289 23.4843 32.2159 22.0658 31.9431L12.0658 30.02C10.9829 29.8117 10.2002 28.8642 10.2002 27.7614V12.7394C10.2002 11.6367 10.9829 10.6891 12.0658 10.4808L22.0658 8.55774ZM23.2002 10.8164C23.2002 10.3767 22.7997 10.0459 22.368 10.129L12.368 12.052C12.0384 12.1154 11.8002 12.4038 11.8002 12.7394V27.7614C11.8002 28.097 12.0384 28.3854 12.368 28.4488L22.368 30.3719C22.7997 30.4549 23.2002 30.1241 23.2002 29.6845V10.8164Z"
          fill="#24292E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0498 29.0498L6.9498 29.0498L6.9498 27.4498L11.0498 27.4498L11.0498 29.0498Z"
          fill="#24292E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0498 29.0498L28.9498 29.0498L28.9498 27.4498L34.0498 27.4498L34.0498 29.0498Z"
          fill="#24292E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9998 18.2002C20.4416 18.2002 20.7998 18.5584 20.7998 19.0002L20.7998 21.5002C20.7998 21.942 20.4416 22.3002 19.9998 22.3002C19.558 22.3002 19.1998 21.942 19.1998 21.5002L19.1998 19.0002C19.1998 18.5584 19.558 18.2002 19.9998 18.2002Z"
          fill="#24292E"
        />
      </g>
    </svg>
  );
};
