import { makeAutoObservable } from 'mobx';

export class AlertModalStore {
  private _visible: boolean;
  private _message: string;

  constructor() {
    this._visible = false;
    this._message = '';
    makeAutoObservable(this);
  }

  get visible(): boolean {
    return this._visible;
  }

  get message(): string {
    return this._message;
  }

  open = (message: string) => {
    this._visible = true;
    this._message = message;
  };

  close = () => {
    this.clear();
  };

  private readonly clear = () => {
    this._visible = false;
  };
}
