import type { AdditionalPermission, Permission } from '@repositories/userRepository';

type Type = 'Tenant';

export const jwtUtil = {
  parseAp: (aps: string[]): AdditionalPermission[] => {
    const results = aps.map(ap => {
      const splited = ap.split(':');
      const result: AdditionalPermission = {
        resourceType: splited[0] as Type,
        resourceId: splited[1],
        permission: splited[2] as Permission,
      };
      return result;
    });
    return results;
  },
};
