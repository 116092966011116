import type { UseFormProps } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import type { LicenseCurrencyUnit, LicenseUserType } from '@repositories/licenseRepository/Types';

export type FormLicenseRequest = {
  planOptionId: string;
  endDate: string;
  startDate: string;
  licenseUserCount: number;
  currencyUnit: LicenseCurrencyUnit;
  renewalDate: string;
  licenseCode?: string;
  licenseUserType?: LicenseUserType;
};

export const CURRENCY_UNIT_TYPE_LIST: LicenseCurrencyUnit[] = ['EUR', 'JPY', 'KRW', 'USD'];

export const useLicenseAddForm = () => {
  const { t } = useTranslation();
  const validationUserSchema = Yup.object().shape({
    planOptionId: Yup.string().required(t('Ad_Store_Purchase_Request_02')),
    startDate: Yup.string()
      .required(t('Ad_Store_Purchase_Request_02'))
      .nullable()
      .test('is-valid-startDate', t('Date_01'), value => !!value && dayjs(value).isValid())
      .when('endDate', {
        is: (endDateValue: string) => endDateValue !== undefined && endDateValue !== null,
        then: Yup.string()
          .test('is-vlaid-slow-startDate', t('Date_02'), (startDate, testContext) => {
            const startNumberValue = Number.parseInt(dayjs(startDate).format('YYYYMMDD'), 10);
            const endNumberValue = Number.parseInt(dayjs(testContext.parent.endDate).format('YYYYMMDD'), 10);
            if (startNumberValue < endNumberValue) return true;
            return !(endNumberValue && startNumberValue >= endNumberValue);
          })
          .nullable(),
      }),
    endDate: Yup.string()
      .nullable()
      .optional()
      .test('is-valid-endDate', t('Date_01'), value => {
        if (value == null || value === '') return true;
        return dayjs(value).isValid();
      }),
    licenseUserCount: Yup.number(),
    currencyUnit: Yup.string().oneOf(CURRENCY_UNIT_TYPE_LIST).required('필수 선택 항목입니다.'),
    renewalDate: Yup.string().nullable(),
    licenseCode: Yup.string(),
    licenseUserType: Yup.string()
      .oneOf(
        ['CONCURRENT_USER', 'ENTERPRISE', 'FLOATING', 'INDIVIDUAL', 'MULTI_USER'],
        '유효한 라이선스 타입을 선택해 주세요',
      )
      .required('라이선스 타입을 선택해 주세요'),
  });

  const licenseAddFormOptions: UseFormProps<FormLicenseRequest> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<FormLicenseRequest>(licenseAddFormOptions);
};
