import type { UseFormProps } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';

export type AutocompleteOption = {
  id: string;
  name: string;
  email: string;
};

export type SubscriptionEditForm = {
  name: string;
  planId: string;
  planOptionId: string;
  timezone: string;
  startDate: string;
  endDate?: string | null;
  paymentDueDate?: string | null;
  owner?: AutocompleteOption;
  memo?: string;
};

export const useSubScriptionEditForm = () => {
  const { t } = useTranslation();
  const subscriptionEdtiValidationSchema: yup.SchemaOf<SubscriptionEditForm> = yup.object().shape({
    name: yup.string().required(t('Member_Group_Delete_05')).max(50, '최대 50자 까지 입력 가능합니다.'),
    planId: yup.string().required(t('Ad_Store_Purchase_Request_02')),
    planOptionId: yup.string().required(t('Ad_Store_Purchase_Request_02')),
    timezone: yup.string().required(t('Ad_Store_Purchase_Request_02')).nullable(),
    startDate: yup
      .string()
      .required(t('Ad_Store_Purchase_Request_02'))
      .nullable()
      .test('is-valid-startDate', t('Date_01'), value => !!value && dayjs(value).isValid())
      .when('endDate', {
        is: (endDateValue: string) => endDateValue !== undefined && endDateValue !== null,
        then: yup
          .string()
          .test('is-vlaid-slow-startDate', t('Date_02'), (startDate, testContext) => {
            const startNumberValue = Number.parseInt(dayjs(startDate).format('YYYYMMDD'), 10);
            const endNumberValue = Number.parseInt(dayjs(testContext.parent.endDate).format('YYYYMMDD'), 10);
            if (startNumberValue < endNumberValue) return true;
            return !(endNumberValue && startNumberValue >= endNumberValue);
          })
          .nullable(),
      }),
    endDate: yup
      .string()
      .nullable()
      .optional()
      .test('is-valid-endDate', t('Date_01'), value => {
        if (value === null) {
          return true;
        }
        return !!value && dayjs(value).isValid();
      }),
    paymentDueDate: yup.string().nullable(),
    owner: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
        email: yup.string(),
      })
      .nullable()
      .optional(),
    memo: yup.string().optional().max(1000, 'Err'),
  });

  const subscriptionEditFormOptions: UseFormProps<SubscriptionEditForm> = {
    resolver: yupResolver(subscriptionEdtiValidationSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: '',
      planId: '',
      planOptionId: '',
      timezone: '',
      startDate: '',
      endDate: null,
      paymentDueDate: '',
      owner: {
        id: '',
        name: '',
        email: '',
      },
    },
  };
  return useForm<SubscriptionEditForm>(subscriptionEditFormOptions);
};
