import type { UsageUiFiler } from './usage';
import type { SelectChangeEvent } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, MenuItem, Select, Skeleton, TextField, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { Button, Tooltip } from '@components/index';
import { Loader } from '@components/loader';
import { RoutingTabs } from '@components/tab';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { DangerFillIcon } from '@icons/DangerFillIcon';
import { PencilIcon } from '@icons/PencilIcon';
import { CheckIcon, XIcon } from '@icons/index';
import { SubscriptionModelProvider } from '@customHooks/useSubscriptionModel';
import { useGetSubscription, useGetSubscriptionUserStat, useUpdateUserSubscription } from '@queryHooks/useSubscription';
import { useGetSubscriptionBillingStat } from '@queryHooks/useSubscriptionBillingStat';
import { useGetTenant } from '@queryHooks/useTenant';
import { transientOptions } from '@utils/CommonUtil';
import { dateFormat } from '@utils/dateUtil';
import { numberFormat } from '@utils/numberFormat';
import { ConnectionStatusLabel } from './ConnectionStatusLabel';

export type SubscriptionDetailOutletContext = {
  usage: { usageUiFilter: UsageUiFiler; setUsageUiFilter: Dispatch<SetStateAction<UsageUiFiler>> };
  billing: { isAIAnalyzeOpen: boolean; setAIAnalyzeOpen: Dispatch<SetStateAction<boolean>> };
};

export const SubscriptionDetail = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { subscriptionId, tenantId } = useParams();
  const navigate = useNavigate();
  const [isEditSubscriptionMode, setIsEditSubscriptionMode] = useState({ name: false });
  const [estimatedCostSelectMode, setEstimatedCostSelectMode] = useState<'annual' | 'monthly'>('annual');

  const { mutate: updateSubscription } = useUpdateUserSubscription();
  const { data: subscription, isSuccess } = useGetSubscription(subscriptionId ?? '', {
    enabled: !!subscriptionId,
  });
  const { data: subscriptionBillingStat } = useGetSubscriptionBillingStat({ subscriptionId });

  const { data: tenant } = useGetTenant(tenantId ?? '');

  const [subscriptionName, setSubscriptionName] = useState<string>('');

  /**
   * NOTE: 사용량 탭의 API 조회 필터. 탭 전환시 상태를 유지하기 위해 상위 컴포넌트로 끌어올려 관리
   */
  const [usageUiFilter, setUsageUiFilter] = useState<UsageUiFiler>({
    viewMode: 'chart',
    dateMode: 'month',
    startDate: '',
    endDate: '',
  });

  const [isAIAnalyzeOpen, setIsAIAnalyzeOpen] = useState(false);

  useEffect(() => {
    if (subscription) {
      setUsageUiFilter({
        viewMode: 'chart',
        dateMode: 'month',
        startDate: subscription.startDate,
        endDate: dayjs().format('YYYY-MM-DD'),
      });
    }
  }, [subscription]);

  const {
    data: userStatus,
    isLoading: isUserStatusLoading,
    isError: isUserStatError,
  } = useGetSubscriptionUserStat(subscriptionId ?? '', { enabled: !!subscriptionId });

  const labelTypeColor = {
    ACTIVE: 'green',
    INACTIVE: 'gray',
    SCHEDULED_TO_START: 'yellow',
    SCHEDULED_TO_END: 'red',
    REQUESTED: 'pink',
  } as const;

  const handleEstimatedCostSelectChange = (e: SelectChangeEvent<'annual' | 'monthly'>) => {
    if (e.target.value === 'annual' || e.target.value === 'monthly') {
      setEstimatedCostSelectMode(e.target.value);
    }
  };
  const handleClickEditSubscriptionName = () => {
    setIsEditSubscriptionMode({ name: true });
    setSubscriptionName(subscription?.name ?? '');
  };

  const handleEditSubscriptionName = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (subscription) {
      const rqData = subscription.getUserSubscriptionUpdateDTO({
        name: subscriptionName,
      });
      updateSubscription(rqData);
      setIsEditSubscriptionMode(prev => {
        return { ...prev, name: false };
      });
    }
  };
  const handleCancelClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsEditSubscriptionMode(prev => {
      return { ...prev, name: false };
    });
  };
  const handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      if (subscription) {
        const rqData = subscription.getUserSubscriptionUpdateDTO({
          name: subscriptionName,
        });
        updateSubscription(rqData);
        setIsEditSubscriptionMode(prev => {
          return { ...prev, name: false };
        });
      }
    }
    if (e.key === 'Escape') {
      setIsEditSubscriptionMode(prev => {
        return { ...prev, name: false };
      });
    }
  };

  const handleSubscriptionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionName(e.target.value.slice(0, 50));
  };

  const handleGoBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Link의 to=".."으로 뒤로가기 시 searchParams가 유지되지 않아서 navigate으로 처리
    e.preventDefault();
    navigate(-1);
  };

  return (
    <Container>
      <GoBackRow to=".." onClick={handleGoBack}>
        <ChevronIcon rotateNum={-90} />
        <Typography variant="headline6" component="strong" color={colors['text-gray-main']}>
          {t('Subscrib_GNB_02')}
        </Typography>
      </GoBackRow>
      <Header>
        <HeaderLeft>
          {isSuccess ? (
            <>
              <Icon alt="icon" src={subscription.softwareIconUrl} width={56} height={56} />
              <div className="app-info-wrapper">
                <SoftwareName>{subscription.software.name}</SoftwareName>
                <SubscriptionNameWrapper onKeyUp={handleEnterKey}>
                  {isEditSubscriptionMode.name ? (
                    <EditTextField
                      size="small"
                      value={subscriptionName}
                      placeholder={t('Subscrib_Edit_03')}
                      onChange={handleSubscriptionNameChange}
                      InputProps={{
                        endAdornment: (
                          <>
                            <NameLengthWrap $isMaxNameLength={subscriptionName.length === 50}>
                              <span>{subscriptionName.length}</span>
                              <span>/50</span>
                            </NameLengthWrap>
                            <NameEditBtnWrap>
                              <Button
                                type="submit"
                                variant="outline"
                                size="xsmall"
                                paddingHorizontal={4}
                                onClick={handleEditSubscriptionName}
                              >
                                <CheckIcon width={14} height={14} color={theme.colors['ic-green']} />
                              </Button>
                              <Button onClick={handleCancelClick} variant="outline" size="xsmall" paddingHorizontal={4}>
                                <XIcon width={14} height={14} color={theme.colors['ic-gray-main']} />
                              </Button>
                            </NameEditBtnWrap>
                          </>
                        ),
                      }}
                      autoFocus
                    />
                  ) : (
                    <>
                      <Tooltip label={subscription.name} visibleOnlyEllipsis placement="bottom-center">
                        <SubscriptionName>{subscription.name}</SubscriptionName>
                      </Tooltip>
                      <SubscriptionNameEditButton
                        variant="outline"
                        size="small"
                        onClick={handleClickEditSubscriptionName}
                      >
                        <PencilIcon width={14} height={14} />
                      </SubscriptionNameEditButton>
                    </>
                  )}
                </SubscriptionNameWrapper>
              </div>
            </>
          ) : null}
        </HeaderLeft>
        <HeaderRight>
          <InfoItem>
            <InfoItemKey>커넥션</InfoItemKey>
            <InfoItemValue>
              {subscription ? <ConnectionStatusLabel subscription={subscription} /> : <Skeleton width={43} />}
            </InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemKey>{t('Subscrib_Detail_Overview_03')}</InfoItemKey>
            <InfoItemValue>
              {subscriptionId !== undefined &&
                subscription?.licenses.filter(({ licenseStatus }) => licenseStatus === 'ACTIVE').length}
              <small> / {subscription?.licenses.length}</small>
            </InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemKey>{t('Subscrib_Detail_Overview_22')}</InfoItemKey>
            <InfoItemValue>
              {isUserStatusLoading || isUserStatError ? (
                <Skeleton />
              ) : (
                <>
                  {userStatus.activeUserCount}
                  <small> / {userStatus.totalUserCount}</small>
                </>
              )}
            </InfoItemValue>
          </InfoItem>
          {subscriptionBillingStat && tenant && (
            <InfoItem>
              <EstimatedCostSelect
                size="small"
                value={estimatedCostSelectMode}
                onChange={handleEstimatedCostSelectChange}
                MenuProps={{ MenuListProps: { sx: { width: '152px' } } }}
              >
                <MenuItem value="annual">올해 예측 비용</MenuItem>
                <MenuItem value="monthly">이번 달 예측 비용</MenuItem>
              </EstimatedCostSelect>
              <InfoItemValue>
                {numberFormat({
                  num:
                    estimatedCostSelectMode === 'annual'
                      ? subscriptionBillingStat.annualPaymentEstimate
                      : subscriptionBillingStat.monthlyPaymentAmountEstimate,
                  style: 'currency',
                  currency: tenant.currencyUnit,
                })}
              </InfoItemValue>
            </InfoItem>
          )}
          <InfoItem>
            <InfoItemKey>{t('Workflow_Main_07')}</InfoItemKey>
            <InfoItemValue>
              {isSuccess ? (
                <Label variant="solid" color={labelTypeColor[subscription.viewStatus]}>
                  {subscription.statusStr}
                </Label>
              ) : (
                <Skeleton width={42} />
              )}
            </InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemKey>{t('Subscrib_Detail_Overview_08')}</InfoItemKey>
            <InfoItemValue>{dateFormat(subscription?.nextPaymentDate, '-', t('DateFormat_YMD'))}</InfoItemValue>
          </InfoItem>
        </HeaderRight>
      </Header>

      <RoutingTabs
        variant="contain"
        tabList={[
          { name: t('Acc_Detail_21'), path: '', replace: true },
          {
            name: `${t('Acc_Detail_14')}`,
            path: 'usage',
            replace: true,
            endIcon: subscription?.isLatestUsageWorkflowFailed ? (
              <Tooltip
                label={subscription.usageWorkflow.latestExecutionStatusMessage || t('Subscrib_Main_20')}
                placement="bottom-left"
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <DangerFillIcon width={16} height={16} />
                </Box>
              </Tooltip>
            ) : undefined,
          },
          { name: t('Subscrib_Detail_Overview_16'), path: 'user', replace: true },
          { name: t('Subscrib_Detail_Overview_03'), path: 'license', replace: true },
          { name: '비용', path: 'billing', replace: true },
          ...(subscription?.isExternalSubscription
            ? []
            : [
                {
                  name: t('Subscrib_GNB_06'),
                  path: 'history',
                  replace: true,
                },
              ]),
          { name: '추가 정보', path: 'extra-info', replace: true },
        ]}
      />
      {isSuccess ? (
        <SubscriptionModelProvider value={subscription}>
          <Outlet
            context={{ usage: { usageUiFilter, setUsageUiFilter }, billing: { isAIAnalyzeOpen, setIsAIAnalyzeOpen } }}
          />
        </SubscriptionModelProvider>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  padding: 28px 32px;
  overflow: auto;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
`;

const GoBackRow = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 32px;
  text-decoration: none;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 28px;
  margin-bottom: 50px;
  gap: 20px;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
  } ;
`;

const HeaderLeft = styled.div`
  width: auto;
  display: flex;
  gap: 8px;
  overflow: hidden;
  flex-grow: 1;
  & .app-info-wrapper {
    width: calc(100% - 72px);
  }
`;

const Icon = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 4px;
`;

const SoftwareName = styled.span`
  ${fonts.Body1}
  margin-left: 8px;
`;
const SubscriptionNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
const SubscriptionName = styled.strong`
  ${fonts.Headline4}
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const EditTextField = styled(TextField)`
  width: 100%;
  overflow-y: hidden;
  & .MuiInputBase-root {
    padding: 0 5px 0 8px;
    ${fonts.Headline4}
  }
  & .MuiInputBase-input::placeholder {
    -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const SubscriptionNameEditButton = styled(Button)`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 2px;
`;

const HeaderRight = styled.div`
  display: flex;
  ${({ theme }) => theme.breakpoints.down('xl')} {
    gap: 30px;
  } ;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 160px;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  }

  ${({ theme }) => theme.breakpoints.down('xl')} {
    width: auto;
    padding: 0;
    padding-right: 20px;
  } ;
`;

const InfoItemKey = styled.div`
  padding: 4px 16px 0px 30px;
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const InfoItemValue = styled.div`
  ${fonts.Headline7}
  padding: 0px 16px 0px 30px;

  & > small {
    ${fonts.Headline7}
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
`;

const EstimatedCostSelect = styled(Select)`
  margin-top: -2px;
  width: 152px;
  margin-left: 18px;
  margin-right: 16px;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-hover']};
  }

  & .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &.Mui-focused > .MuiSelect-select ~ .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiSelect-select[aria-expanded='true'] ~ .MuiOutlinedInput-notchedOutline {
    border: ${({ theme: { colors } }) => `1px solid ${colors['border-gray-light']}`};
  }
` as unknown as typeof Select;

const NameLengthWrap = styled('div', transientOptions)<{ $isMaxNameLength: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 10px 0 8px;
  span {
    ${fonts.Body4};
    color: ${({ $isMaxNameLength, theme }) =>
      $isMaxNameLength ? theme.colors['text-red-light'] : theme.colors['text-gray-main']};
    & + span {
      color: ${({ $isMaxNameLength, theme }) =>
        $isMaxNameLength ? theme.colors['text-red-light'] : theme.colors['text-gray-light']};
    }
  }
`;

const NameEditBtnWrap = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`;
