import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { EmptyIcon } from '@icons/EmptyIcon';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import { List, WgBox, WgContainer, WgSummary, WgTitle } from '../styles';

export const LicenseRetriveWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantId } = useParams();
  const navigate = useNavigate();

  const handleClickSeeMore = () => {
    navigate(`/manager/${tenantId}/usergroups/memberMgmt/stop`);
  };

  const handleClickUser = (id: string) => {
    navigate(`/manager/${tenantId}/usergroups/memberMgmt/${id}/license-retrieve`);
  };

  const { data: pagedCreatedMembers } = useGetTenantMembersInfinite(tenantId as string, {
    tenantMemberStatus: 'BLOCKED',
  });

  const members = pagedCreatedMembers?.pages
    .map(({ content }) => content)
    .flat(1)
    .filter(member => member.subscriptionCount);

  return (
    <WgContainer width={414}>
      <WgTitle>
        <span>{t('Ad_Home_License')}</span>
        <span className="see-more">
          <Button className="see-more" onClick={handleClickSeeMore} variant="text">
            {t('Home_Add')}
            <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
          </Button>
        </span>
      </WgTitle>
      <WgSummary>
        <span className="count">
          <span className="count-number">{members?.length}</span>
          <span className="counting-unit">{t('Home_People')}</span>
        </span>
      </WgSummary>
      <WgBox width={382}>
        <List>
          {members?.length ? (
            members?.map(member => {
              return (
                <Member key={member.id} onClick={() => handleClickUser(member.id)}>
                  {member.name}
                  <span className="count">{member.subscriptionCount}</span>
                </Member>
              );
            })
          ) : (
            <Empty>
              <EmptyIcon />
              {t('Home_Empty')}
            </Empty>
          )}
        </List>
      </WgBox>
    </WgContainer>
  );
};

const Member = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  & .count {
    color: ${props => props.theme.colors['text-gray-main']};
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.colors['text-purple']};
    & .count {
      color: ${props => props.theme.colors['text-purple']};
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 100dvh;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;
