import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Button, EmptyRow } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import type { AdditionalCommitmentCreateDTO, AdditionalCommitmentDTO } from '@repositories/licenseRepository';
import { useGetLicense, useUpdateLicense } from '@queryHooks/useLicense';
import { useGetUsageMetricList } from '@queryHooks/useSoftware';
import { currencyTextFormat } from '@utils/numberFormat';
import { CommitmentEditor } from './CommitmentEditor';

export const AdditionalCommitmentList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { licenseId } = useParams();
  const {
    uiStore: { alertStore, toastStore },
  } = useStore();

  const [isCreateAdditionalOpen, setIsCreateAdditionalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [threeDotSelectAdditional, setThreeDotSelectAdditional] = useState<AdditionalCommitmentDTO>();
  const [dialogType, setDialogType] = useState<'create' | 'update' | ''>('');
  const [clickedRow, setClickedRow] = useState<AdditionalCommitmentDTO | null>(null);
  const { data: licenseDetail } = useGetLicense(licenseId ?? '');
  const { data: usageMetricList } = useGetUsageMetricList(licenseDetail?.subscription.product?.id as string);
  const { mutate: licenseUpdate } = useUpdateLicense();

  const handleAdditionalCommitmentAdd = () => {
    setDialogType('create');
    setIsCreateAdditionalOpen(true);
  };

  const handleCloseAdditionalCommitmentAdd = () => {
    setDialogType('');
    setIsCreateAdditionalOpen(false);
  };

  const handleThreeDotClick = (event: React.MouseEvent<HTMLButtonElement>, row: AdditionalCommitmentDTO) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setThreeDotSelectAdditional(row);
    setClickedRow(row);
  };

  const handleMenuClose = () => {
    setDialogType('');
    setAnchorEl(undefined);
    setClickedRow(null);
  };

  const handleUpdateAdditionalClick = () => {
    setAnchorEl(undefined);
    setDialogType('update');
    setIsCreateAdditionalOpen(true);
  };

  const handleDeleteAdditionalClick = () => {
    setAnchorEl(undefined);
    alertStore.open({
      title: t('Additional_Commitment_09'),
      message: t('Additional_Commitment_10'),
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      onConfirm: () => {
        const deletedRemainArrayItem = licenseDetail?.additionalCommitments.filter(
          item => item.additionalCommitmentId !== threeDotSelectAdditional?.additionalCommitmentId,
        );

        if (deletedRemainArrayItem) {
          const updatedCommitments: AdditionalCommitmentCreateDTO[] = deletedRemainArrayItem?.map(item => ({
            usageMetricId: item.usageMetric?.usageMetricId,
            type: item.type,
            committedUsage: item.committedUsage,
            usageUnitPrice: item.usageUnitPrice,
            discountRate: item.discountRate,
            usageUnitPriceWhenExceed: item.usageUnitPriceWhenExceed,
          }));

          if (licenseDetail) {
            licenseUpdate(
              licenseDetail.getLicenseUpdateDTO({
                additionalCommitments: updatedCommitments,
                additionalProperties: {},
              }),
              {
                onSuccess: () => {
                  toastStore.open(t('Subscrib_Detail_License_26'));
                },
              },
            );
          }
        }
      },
      useCancel: true,
      type: 'error',
    });
  };

  return (
    <Footer>
      <FooterTitle>
        <TitleText>{t('Subscrib_Detail_License_24')}</TitleText>
        {licenseDetail?.subscription.type === 'EXTERNAL' && (
          <Button
            variant="contain"
            size="small"
            color="purple-2"
            paddingHorizontal={12}
            onClick={handleAdditionalCommitmentAdd}
          >
            {t('Member_Manager_11')}
          </Button>
        )}
      </FooterTitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="374px">{t('Subscrib_Detail_License_17')}</TableCell>
              <TableCell width="260px">{t('Subscrib_Detail_License_18')}</TableCell>
              <TableCell width="200px">{t('Subscrib_Detail_License_19')}</TableCell>
              <TableCell width="230px">{t('Acc_Detail_15')}</TableCell>
              <TableCell width="200px">{t('Subscrib_Detail_License_21')}</TableCell>
              <TableCell width="230px">{t('Subscrib_Detail_License_22')}</TableCell>
              <StyledTabelCellHeader width="72px">{t('Acc_Main_23')}</StyledTabelCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenseDetail?.additionalCommitments.length === 0 ? (
              <EmptyRow colSpan={7} title={t('Subscrib_Detail_License_23')} />
            ) : (
              licenseDetail?.additionalCommitments.map(row => (
                <TableRow key={row.additionalCommitmentId}>
                  <TableCell width="368px">
                    {
                      usageMetricList?.find(metric => metric.usageMetricId === row.usageMetric?.usageMetricId)
                        ?.usageMetricName
                    }
                  </TableCell>
                  <TableCell width="360px">
                    {row.type === 'TOTAL_AMOUNT_DISCOUNT' ? '전체 금액 약정 할인' : '개별 사용량 약정 할인'}
                  </TableCell>
                  <TableCell width="230px">
                    [{row.usageMetric?.usageUnit}] {row.committedUsage}
                  </TableCell>
                  <TableCell width="230px">
                    {currencyTextFormat(
                      row.usageUnitPrice,
                      licenseDetail.currencyUnit ?? licenseDetail.plan?.currencyUnit,
                    )}
                  </TableCell>
                  <TableCell width="230px">{row.discountRate ? row.discountRate * 100 : '-'}</TableCell>
                  <TableCell width="230px">
                    {row.usageUnitPriceWhenExceed ? row.usageUnitPriceWhenExceed : '-'}
                  </TableCell>
                  <StyledCell align="center">
                    <ThreeDotButton onClick={event => handleThreeDotClick(event, row)}>
                      <ThreeDotIcon
                        width={18}
                        height={18}
                        color={clickedRow === row ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']}
                      />
                    </ThreeDotButton>
                  </StyledCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CommitmentEditor
        dialogType={dialogType}
        selectData={threeDotSelectAdditional}
        open={isCreateAdditionalOpen}
        onClose={handleCloseAdditionalCommitmentAdd}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <UpdateMenuItem onClick={handleUpdateAdditionalClick}>{t('Additional_Commitment_03')}</UpdateMenuItem>
        <DeleteMenuItem onClick={handleDeleteAdditionalClick}>{t('Additional_Commitment_09')}</DeleteMenuItem>
      </StyledMenu>
    </Footer>
  );
};

const TitleText = styled.span`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px 6px 8px;
`;

const StyledTabelCellHeader = styled(TableCell)`
  text-align: center;
`;

const StyledCell = styled(TableCell)`
  white-space: nowrap;
`;

const ThreeDotButton = styled(Button)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: none;
    border-radius: 0;
  }
  & .MuiList-root {
    width: 160px;
    border-radius: 2px;
  }
`;

const UpdateMenuItem = styled(MenuItem)`
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;

const DeleteMenuItem = styled(MenuItem)`
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  &:active {
    color: ${({ theme: { colors } }) => colors['text-purple-light']};
  }
`;
