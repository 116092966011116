/* eslint-disable react/jsx-props-no-spreading */
const defaultWidth = 750;
const defaultHeight = 1;

export const DottedLine = ({ width = defaultWidth, height = defaultHeight, color = '#D1D5DA', ...props }: SvgProps) => {
  return (
    <svg width="100%" height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <line x1="4.37114e-08" y1="0.5" x2="750" y2="0.500063" stroke={color} strokeDasharray="4 4" />
      </g>
    </svg>
  );
};
