const defaultWidth = 20;
const defaultHeight = 20;

export const GoogleColorIcon = ({ width = defaultWidth, height = defaultHeight }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C12.4354 0 14.6675 0.870615 16.402 2.3176L13.8412 5.39056C12.8005 4.52237 11.4613 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10H20Z"
          fill="#D54F3E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.06939 5.49609C0.385346 6.84971 0 8.37998 0 10.0002C0 15.5231 4.47715 20.0002 10 20.0002C15.4632 20.0002 19.9031 15.6193 19.9984 10.1789L15.9822 10.4658C15.7446 13.562 13.157 16.0002 10 16.0002C6.68629 16.0002 4 13.314 4 10.0002C4 9.27518 4.12861 8.58016 4.36426 7.93674L1.06939 5.49609Z"
          fill="#F0BE41"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.990234 14.3416C2.60651 17.6899 6.03402 19.9999 10.0011 19.9999C15.5239 19.9999 20.0011 15.5227 20.0011 9.99987C20.0011 9.97902 20.001 9.95818 20.0009 9.93735L15.9747 9.43408C15.9922 9.62034 16.0011 9.80906 16.0011 9.99987C16.0011 13.3136 13.3148 15.9999 10.0011 15.9999C7.31045 15.9999 5.03348 14.2288 4.27231 11.7889L0.990234 14.3416Z"
          fill="#57A55C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6593 8H10V12H15.6593C15.2539 13.147 14.5097 14.1338 13.5469 14.8404L16.8526 17.2837C18.7906 15.4599 20.0007 12.8713 20.0007 10C20.0007 9.31507 19.9318 8.64622 19.8007 8H19H15.6593Z"
          fill="#5080E9"
        />
      </g>
    </svg>
  );
};

GoogleColorIcon.displayName = 'GoogleColorIcon';
