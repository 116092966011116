import { BrowserStorageMapper } from '../BrowserStorageMapper';
import { SessionStorageKey } from './SessionStorageKey';

const PREFIX = 'SMP_';

class SessionStorage {
  private readonly storage: typeof window.sessionStorage;

  constructor() {
    this.storage = window.sessionStorage;
  }

  private getOriginKey(key: string) {
    return `${PREFIX}${key}`;
  }

  get<T>(key: SessionStorageKey, mapper?: BrowserStorageMapper<T>): T | string | null | T[] {
    const originObj = this.storage.getItem(this.getOriginKey(key));

    if (originObj && mapper) {
      const jsonObj = JSON.parse(originObj);
      if (Array.isArray(jsonObj)) {
        return jsonObj.map(obj => mapper.fromJson(obj) as T);
      }
      return mapper.fromJson(jsonObj) as T;
    }
    return originObj;
  }

  set(key: SessionStorageKey, value: string): void {
    this.storage.setItem(this.getOriginKey(key), value);
  }

  clear(): void {
    this.storage.clear();
  }

  removeItem(key: SessionStorageKey): void {
    this.storage.removeItem(this.getOriginKey(key));
  }

  key(idx: number): string | null {
    return this.storage.key(idx);
  }

  get length(): number {
    return this.storage.length;
  }
}

const sessionStorage = new SessionStorage();

export default sessionStorage;
