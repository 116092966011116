import styled from '@emotion/styled';
import { Box, Divider as MuiDivider } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { Link } from 'react-router-dom';

export const Container = styled.nav`
  max-width: 220px;
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - 80px);
  overflow: auto;
  position: fixed;
  top: var(--employee-gnb-height) - 80px;
`;

export const Title = styled(Box)`
  ${fonts.Headline5};
  margin: 0px 0px 19px 0px;
`;

export const MenuContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.selected {
    & > a {
      & > span {
        color: ${({ theme: { colors } }) => colors['text-purple-light']};
      }
      & svg > g > path {
        fill: ${({ theme: { colors } }) => colors['ic-purple-light']};
      }
    }
  }
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

export const MenuLink = styled(Link)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 17px 7px 18px 0;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  & > span {
    ${fonts.Headline8};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

export const Divider = styled(MuiDivider)`
  border-color: ${({ theme: { colors } }) => colors['border-gray-darker']};
  border-bottom-width: 1px;
`;
