import styled from '@emotion/styled';
import { Avatar, Box, Button, Typography } from '@mui/material';

export const Container = styled(Button)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 3px;
  overflow: hidden;
  background-color: ${({ theme: { colors } }) => colors.White};
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    border: 1px solid #e1e4e8;
  }
`;

export const Thumbnail = styled(Avatar)`
  width: 380px;
  height: 184px;
  object-fit: cover;
  border-radius: 3px;
`;

export const Icon = styled(Avatar)`
  width: 68px;
  height: 68px;
  border-radius: 3px;
  & .MuiAvatar-img {
    object-fit: contain;
  }
`;

export const CardContentWrapper = styled.div`
  display: flex;
  padding: 24px 28px 28px;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

export const AppMetaInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: left;
  justify-content: center;
`;

export const AppCategory = styled(Typography)`
  width: fit-content;
  border-radius: 2px;
  padding: 5px 8px 2px 8px;
  background-color: ${({ theme: { colors } }) => `${colors.L_Blue}`};
  color: #2557d6;
  font-family: 'Spartan';
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
` as typeof Typography;

export const Name = styled(Typography)`
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.15px;
  color: #24292e;
` as typeof Typography;
