import { Dispatch, MutableRefObject, RefObject, SetStateAction, useRef, useState } from 'react';
import ReactToPrint, { IReactToPrintProps } from 'react-to-print';
import { useTheme } from '@emotion/react';
import { Button } from '@components/index';
import { CircularProgress } from '@mui/material';
import { DownloadIcon } from '@icons/DownloadIcon';
import i18n from '@locales/i18n';

type PrintTriggerButtonProps = {
  icColor: string;
  isLoading: boolean;
  disabled: boolean;
};

const PrintTriggerButton =
  ({ icColor, isLoading, disabled }: PrintTriggerButtonProps) =>
  () => {
    return (
      <Button
        variant="contain"
        size="large"
        paddingHorizontal={24}
        startIcon={isLoading ? <CircularProgress size={20} /> : <DownloadIcon width={20} height={20} color={icColor} />}
        style={{ marginLeft: 'auto', gap: '6px' }}
        disabled={disabled}
      >
        {i18n.t('Billing_Detail_01')}
      </Button>
    );
  };

type Props = {
  printState: { isOpen: boolean; isDataLoading: boolean };
  setPrintState: Dispatch<SetStateAction<{ isOpen: boolean; isDataLoading: boolean }>>;
  onBeforeGetContentResolve: MutableRefObject<((value?: unknown) => void) | undefined>;
} & IReactToPrintProps;

const PrintButton = ({
  printState,
  setPrintState,
  onBeforeGetContentResolve,
  content,
  trigger,
  documentTitle,
}: Props) => {
  const { colors } = useTheme();

  return (
    <ReactToPrint
      pageStyle="
        @page { 
          size: 594mm 841mm;
          margin: 6% 9% 8%;
        } 
        @media print { 
          body { 
            -webkit-print-color-adjust: exact; 
          } 
        }
      "
      content={content}
      documentTitle={documentTitle}
      removeAfterPrint
      trigger={
        trigger ??
        PrintTriggerButton({
          icColor: printState.isOpen ? colors['ic-gray-light'] : colors['ic-white'],
          isLoading: printState.isDataLoading,
          disabled: printState.isOpen,
        })
      }
      onBeforeGetContent={() =>
        new Promise(resolve => {
          setPrintState({ isOpen: true, isDataLoading: true });
          // eslint-disable-next-line no-param-reassign
          onBeforeGetContentResolve.current = resolve;
        })
      }
      onBeforePrint={() => setPrintState(prev => ({ ...prev, isDataLoading: false }))}
      onAfterPrint={() => setPrintState(prev => ({ ...prev, isOpen: false }))}
    />
  );
};

type Options = {
  contentRef: RefObject<HTMLElement>;
  trigger?: IReactToPrintProps['trigger'];
  documentTitle: string;
};
export const usePrintButton = () => {
  const [printState, setPrintState] = useState<{ isOpen: boolean; isDataLoading: boolean }>({
    isOpen: false,
    isDataLoading: false,
  });
  const onBeforeGetContentResolve = useRef<(value?: unknown) => void>();

  const PrintButtonComp = ({ contentRef, trigger, documentTitle }: Options) => (
    <PrintButton
      content={() => contentRef.current}
      printState={printState}
      setPrintState={setPrintState}
      onBeforeGetContentResolve={onBeforeGetContentResolve}
      trigger={trigger}
      documentTitle={documentTitle}
    />
  );

  return { printState, setPrintState, onBeforeGetContentResolve, PrintButton: PrintButtonComp } as const;
};
