// import { useTheme } from '@emotion/react';

const defaultWidth = 24;
const defaultHeight = 24;

export const TrashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.90657 3.58736C9.88629 3.58736 9.86984 3.6038 9.86984 3.62409V4.59122H14.1302V3.62409C14.1302 3.6038 14.1137 3.58736 14.0934 3.58736H9.90657ZM15.9175 3.62409V4.59122H20.6063C21.0999 4.59122 21.5 4.99134 21.5 5.48491C21.5 5.97848 21.0999 6.3786 20.6063 6.3786H19.8351V7.08855H19.835V20.4982C19.7445 21.4332 18.8892 22.1466 17.9498 22.1466H6.05031C5.11094 22.1466 4.32518 21.4332 4.23469 20.4982L4.1683 7.08855H4.1649V6.40298V6.3786H3.39369C2.90012 6.3786 2.5 5.97848 2.5 5.48491C2.5 4.99134 2.90012 4.59122 3.39369 4.59122H8.08247V3.62409C8.08247 2.61666 8.89915 1.79999 9.90657 1.79999H14.0934C15.1008 1.79999 15.9175 2.61666 15.9175 3.62409ZM17.9841 6.40298V6.3786H6.01593L6.01593 6.40298L6.01593 6.40451V7.08855H6.01582L6.01375 20.3261C6.01558 20.3449 6.0314 20.3593 6.05031 20.3593H17.9498C17.9687 20.3593 17.9845 20.3449 17.9863 20.3261L17.9841 7.08855V7.02415L17.984 6.40298L17.9841 6.40298ZM8.76795 9.63513C8.76795 9.14833 9.16258 8.75369 9.64939 8.75369C10.1362 8.75369 10.5308 9.14833 10.5308 9.63513V17.4702C10.5308 17.957 10.1362 18.3516 9.64939 18.3516C9.16258 18.3516 8.76795 17.957 8.76795 17.4702V9.63513ZM13.5669 9.63522C13.5669 9.14842 13.9616 8.75378 14.4484 8.75378C14.9352 8.75378 15.3298 9.14842 15.3298 9.63522V17.4703C15.3298 17.9571 14.9352 18.3517 14.4484 18.3517C13.9616 18.3517 13.5669 17.9571 13.5669 17.4703V9.63522Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

TrashIcon.displayName = 'TrashIcon';
