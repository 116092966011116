import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';

export const Nav = styled.nav`
  width: 100%;
  max-width: 256px;
  height: auto;
  overflow: auto;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  padding: 20px 16px 0;
  box-shadow: -6px 0px 12px 0px rgba(0, 0, 0, 0.03) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.04) inset;
  flex-shrink: 0;
`;

export const MenuItemTitle = styled('div')`
  width: 224px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
  gap: 12px;
  padding: 0px 0px 0 8px;
  margin-bottom: 4px;
  & span {
    ${fonts.Body2};
  }
`;

export const SubMenuWrap = styled.ul`
  width: 100%;
  & + .menu-item {
    margin-top: 12px;
  }
`;

export const SubMenuiItem = styled.li`
  width: 224px;
  height: 32px;
  margin-bottom: 4px;
`;

export const MenuLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding-left: 4px;
  border-radius: 3px;
  & .menu-item {
    width: 100%;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
  background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  &.selected {
    background-color: ${({ theme }) => theme.colors['state-white-pressed']};
    & .menu-item span {
      ${fonts.Headline8};
      color: ${({ theme }) => theme.colors['text-purple-light']};
    }
  }
  :hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
  }
`;
