import API from '@repositories/Axios';
import qs from 'qs';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { Page } from '@type/Page';
import IUserGroupsRepository from './IUserGroupsRepository';
import {
  UserGroupsFilter,
  UserGroupSimple,
  UserGroupDTO,
  UserGroupCreateRq,
  UserGroupUpdateRq,
  UserGroupMember,
  UserGroupMemberCreateRq,
  UserGroupMemberFilter,
  UserGroupListCreateDTO,
} from './Types';

@injectable()
export class UserGroupRepository implements IUserGroupsRepository {
  async addBatchUsergroups(rqData: UserGroupListCreateDTO): Promise<UserGroupDTO[]> {
    const result = await API.post<UserGroupDTO[]>(`/api/v1/user-groups-list`, rqData);
    return result.data;
  }
  /**
   * add members to UserGroup
   * @param id
   * @param data
   */
  async addUsergroupMembers(id: string, data: UserGroupMemberCreateRq[]): Promise<UserGroupMember[]> {
    const result = await API.post<UserGroupMember[]>(`/api/v1/user-groups/${id}/member-list`, data);
    return result.data;
  }

  /**
   * add member to UserGroup
   * @param id
   * @param data
   */
  async addUsergroupMember(id: string, data: UserGroupMemberCreateRq): Promise<UserGroupMember> {
    const result = await API.post<UserGroupMember>(`/api/v1/user-groups/${id}/member-list`, data);
    return result.data;
  }

  /**
   * get members from UserGroup
   * @param id
   * @param queries
   */
  async getUsergroupMembers(id: string, queries?: UserGroupMemberFilter | undefined): Promise<Page<UserGroupMember>> {
    const queryString = qs.stringify(queries);
    const result = await API.get<Page<UserGroupMember>>(`/api/v1/user-groups/${id}/members?${queryString}`);
    return result.data;
  }

  /**
   * delete member from UserGroup
   * @param id
   * @param userId
   */
  async deleteUserGroupMember(id: string, userId: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/user-groups/${id}/members/${userId}`);
    return result.data;
  }

  /**
   * get usergroup List
   * @param queries
   * @returns UserGroupSimple[]
   */
  async getList(queries: UserGroupsFilter): Promise<UserGroupSimple[]> {
    const queryString = qs.stringify(queries);
    const result = await API.get<UserGroupSimple[]>(`/api/v1/user-groups?${queryString}`);
    return result.data;
  }

  /**
   * create usergroup
   * @param data
   * @returns Usergroup
   */
  async create(data: UserGroupCreateRq): Promise<UserGroupDTO> {
    const result = await API.post<UserGroupDTO>(`/api/v1/user-groups`, data);
    return result.data;
  }

  /**
   * get usergroup
   * @param id
   * @returns
   */
  async getOne(id: string): Promise<UserGroupDTO> {
    const result = await API.get<UserGroupDTO>(`/api/v1/user-groups/${id}`);
    return result.data;
  }

  /**
   * update usergroup
   * @param id
   * @param data
   * @returns
   */
  async update(id: string, data: UserGroupUpdateRq): Promise<UserGroupDTO> {
    const result = await API.put<UserGroupDTO>(`/api/v1/user-groups/${id}`, data);
    return result.data;
  }

  /**
   * delete usergroup
   * @param id
   * @returns
   */
  async delete(id: string): Promise<void> {
    const result = await API.delete<void>(`/api/v1/user-groups/${id}`);
    return result.data;
  }

  /**
   * ParentGroup check Validation For Update
   * @param queries
   * @returns
   */
  async checkValidationForUpdate(queries?: { from: string; to: string }): Promise<boolean> {
    const queryString = qs.stringify(queries);
    const result = await API.get<boolean>(`/api/v1/user-groups/valid?${queryString}`);
    return result.data;
  }
}
