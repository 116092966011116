import { RealtimeUsage } from '@repositories/usage/Types';
import dayjs, { Dayjs } from 'dayjs';
import { ModuleUsageListModel } from './ModuleUsageListModel';

export class RealtimeUsageModel {
  private readonly _dateTime: Dayjs;
  private readonly _subscriptionId: string;
  private readonly _moduleUsageList: ModuleUsageListModel[];

  constructor(dto?: RealtimeUsage) {
    this._dateTime = dayjs(dto?.dateTime);
    this._subscriptionId = dto?.subscriptionId || '';
    this._moduleUsageList = dto?.usageList.map(usage => new ModuleUsageListModel(usage)) || [];
  }

  get dateTime(): Dayjs {
    return this._dateTime;
  }

  get subscriptionId(): string {
    return this._subscriptionId;
  }

  get moduleUsageList(): ModuleUsageListModel[] {
    return this._moduleUsageList;
  }

  getCountSlicedRealtimeUsageList(count: number): ModuleUsageListModel[] {
    return this._moduleUsageList.slice(0, count) ?? [];
  }
}
