import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

export interface Props<T> {
  control: Control<FieldValues>;
  name: string;
  options: T[];
  defaultOption?: T;
  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (option1: T, option2: T) => boolean;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  label?: string;
}

export const AutocompleteSelector = <T,>({
  control,
  name,
  options,
  defaultOption,
  getOptionLabel,
  isOptionEqualToValue,
  readOnly,
  size,
  label,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultOption}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          value={value ?? null}
          onChange={(_, value) => onChange(value)}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={params => {
            return <TextField {...params} size={size ?? 'small'} label={label} />;
          }}
          readOnly={readOnly}
        />
      )}
    />
  );
};
