import { SvgIcon, SvgIconProps } from '@mui/material';

// @deprecated

interface SubBillingTypeProps {
  selected: boolean;
}

interface ColorProps {
  color: string;
}

export const ArrowDownIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.175 7.6668L10 11.4835L13.825 7.6668L15 8.8418L10 13.8418L5 8.8418L6.175 7.6668Z" fill="#24292E" />
  </svg>
);
export const ArrowUpIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.175 12.8418L10 9.02513L13.825 12.8418L15 11.6668L10 6.6668L5 11.6668L6.175 12.8418Z" fill="#24292E" />
  </svg>
);

export const ContactUsIcon = () => (
  <svg width={28} height={24} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_1801_14523" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9614 1.46716C5.8873 1.46716 1.77393 5.45435 1.77393 10.3734C1.77393 12.7425 2.73468 14.8893 4.29043 16.4859L3.53093 20.4693C3.51475 20.5545 3.52101 20.6429 3.54901 20.7245C3.57701 20.8061 3.62564 20.8778 3.68946 20.9315C3.75328 20.9851 3.82979 21.0188 3.91042 21.0285C3.99105 21.0383 4.07264 21.0239 4.14605 20.9868L8.28568 18.8934C9.15741 19.15 10.0574 19.2799 10.9614 19.2797C16.0356 19.2797 20.1489 15.2925 20.1489 10.3734C20.1489 5.45435 16.0356 1.46716 10.9614 1.46716"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9614 1.46716C5.8873 1.46716 1.77393 5.45435 1.77393 10.3734C1.77393 12.7425 2.73468 14.8893 4.29043 16.4859L3.53093 20.4693C3.51475 20.5545 3.52101 20.6429 3.54901 20.7245C3.57701 20.8061 3.62564 20.8778 3.68946 20.9315C3.75328 20.9851 3.82979 21.0188 3.91042 21.0285C3.99105 21.0383 4.07264 21.0239 4.14605 20.9868L8.28568 18.8934C9.15741 19.15 10.0574 19.2799 10.9614 19.2797C16.0356 19.2797 20.1489 15.2925 20.1489 10.3734C20.1489 5.45435 16.0356 1.46716 10.9614 1.46716"
      fill="white"
    />
    <path
      d="M4.29043 16.4859L6.25503 16.8605L6.45025 15.8366L5.72283 15.0901L4.29043 16.4859ZM3.53093 20.4693L1.56632 20.0948L1.56601 20.0964L3.53093 20.4693ZM4.14605 20.9869L5.04702 22.7724L5.04862 22.7716L4.14605 20.9869ZM8.28568 18.8934L8.85046 16.9748L8.09027 16.751L7.38311 17.1087L8.28568 18.8934ZM10.9614 19.2797V17.2797H10.9608L10.9614 19.2797ZM10.9614 -0.532837C4.84184 -0.532837 -0.226074 4.29158 -0.226074 10.3734H3.77393C3.77393 6.61712 6.93276 3.46716 10.9614 3.46716V-0.532837ZM-0.226074 10.3734C-0.226074 13.2987 0.96334 15.9373 2.85802 17.8817L5.72283 15.0901C4.50601 13.8414 3.77393 12.1863 3.77393 10.3734H-0.226074ZM2.32582 16.1113L1.56632 20.0948L5.49553 20.8439L6.25503 16.8605L2.32582 16.1113ZM1.56601 20.0964C1.48542 20.5209 1.51592 20.9616 1.65724 21.3735L5.44078 20.0755C5.52611 20.3242 5.54409 20.5882 5.49584 20.8423L1.56601 20.0964ZM1.65724 21.3735C1.79873 21.7859 2.05063 22.1664 2.40192 22.4619L4.977 19.401C5.20064 19.5892 5.35529 19.8263 5.44078 20.0755L1.65724 21.3735ZM2.40192 22.4619C2.75459 22.7586 3.19242 22.9561 3.66953 23.014L4.15131 19.0431C4.46716 19.0814 4.75198 19.2117 4.977 19.401L2.40192 22.4619ZM3.66953 23.014C4.1473 23.072 4.62571 22.985 5.04702 22.7724L3.24508 19.2013C3.51956 19.0628 3.8348 19.0047 4.15131 19.0431L3.66953 23.014ZM5.04862 22.7716L9.18824 20.6782L7.38311 17.1087L3.24348 19.2021L5.04862 22.7716ZM7.72089 20.812C8.77526 21.1224 9.86561 21.28 10.962 21.2797L10.9608 17.2797C10.2492 17.2799 9.53956 17.1777 8.85046 16.9748L7.72089 20.812ZM10.9614 21.2797C17.081 21.2797 22.1489 16.4552 22.1489 10.3734H18.1489C18.1489 14.1297 14.9901 17.2797 10.9614 17.2797V21.2797ZM22.1489 10.3734C22.1489 4.29158 17.081 -0.532837 10.9614 -0.532837V3.46716C14.9901 3.46716 18.1489 6.61712 18.1489 10.3734H22.1489Z"
      fill="#3A3A49"
      mask="url(#path-1-inside-1_1801_14523)"
    />
    <mask id="path-3-inside-2_1801_14523" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9614 9.97815C23.5858 9.97815 26.5239 12.6363 26.5239 15.9156C26.5239 17.495 25.8377 18.9263 24.7264 19.9906L25.2689 22.6463C25.2805 22.7031 25.276 22.762 25.256 22.8164C25.236 22.8708 25.2013 22.9185 25.1557 22.9543C25.1101 22.9901 25.0555 23.0126 24.9979 23.0191C24.9403 23.0256 24.882 23.016 24.8296 22.9913L21.8727 21.5956C21.25 21.7667 20.6072 21.8533 19.9614 21.8531C16.3371 21.8531 13.3989 19.195 13.3989 15.9156C13.3989 12.6363 16.3371 9.97815 19.9614 9.97815"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9614 9.97815C23.5858 9.97815 26.5239 12.6363 26.5239 15.9156C26.5239 17.495 25.8377 18.9263 24.7264 19.9906L25.2689 22.6463C25.2805 22.7031 25.276 22.762 25.256 22.8164C25.236 22.8708 25.2013 22.9185 25.1557 22.9543C25.1101 22.9901 25.0555 23.0126 24.9979 23.0191C24.9403 23.0256 24.882 23.016 24.8296 22.9913L21.8727 21.5956C21.25 21.7667 20.6072 21.8533 19.9614 21.8531C16.3371 21.8531 13.3989 19.195 13.3989 15.9156C13.3989 12.6363 16.3371 9.97815 19.9614 9.97815"
      fill="white"
    />
    <path
      d="M24.7264 19.9906L22.7669 20.3909L22.546 19.3097L23.343 18.5463L24.7264 19.9906ZM25.2689 22.6463L27.2285 22.246L27.2288 22.2477L25.2689 22.6463ZM24.8296 22.9913L23.9774 24.8007L23.9759 24.7999L24.8296 22.9913ZM21.8727 21.5956L21.3428 19.6671L22.0568 19.471L22.7263 19.787L21.8727 21.5956ZM19.9614 21.8531V19.8531H19.962L19.9614 21.8531ZM19.9614 7.97815C24.4973 7.97815 28.5239 11.348 28.5239 15.9156H24.5239C24.5239 13.9245 22.6743 11.9781 19.9614 11.9781V7.97815ZM28.5239 15.9156C28.5239 18.0978 27.5715 20.035 26.1098 21.435L23.343 18.5463C24.1039 17.8175 24.5239 16.8922 24.5239 15.9156H28.5239ZM26.686 19.5903L27.2285 22.246L23.3094 23.0466L22.7669 20.3909L26.686 19.5903ZM27.2288 22.2477C27.3143 22.668 27.2812 23.1038 27.1332 23.5064L23.3788 22.1264C23.2708 22.4201 23.2467 22.7382 23.3091 23.0449L27.2288 22.2477ZM27.1332 23.5064C26.9852 23.909 26.7282 24.2625 26.3908 24.5274L23.9205 21.3813C23.6744 21.5746 23.4868 21.8326 23.3788 22.1264L27.1332 23.5064ZM26.3908 24.5274C26.0535 24.7923 25.6491 24.9581 25.2229 25.0064L24.7728 21.0318C24.4618 21.067 24.1667 21.188 23.9205 21.3813L26.3908 24.5274ZM25.2229 25.0064C24.7967 25.0546 24.3655 24.9834 23.9774 24.8007L25.6817 21.1819C25.3985 21.0485 25.0838 20.9966 24.7728 21.0318L25.2229 25.0064ZM23.9759 24.7999L21.019 23.4043L22.7263 19.787L25.6832 21.1826L23.9759 24.7999ZM22.4025 23.5242C21.6071 23.7427 20.7858 23.8534 19.9608 23.8531L19.962 19.8531C20.4285 19.8533 20.893 19.7907 21.3428 19.6671L22.4025 23.5242ZM19.9614 23.8531C15.4256 23.8531 11.3989 20.4833 11.3989 15.9156H15.3989C15.3989 17.9068 17.2485 19.8531 19.9614 19.8531V23.8531ZM11.3989 15.9156C11.3989 11.348 15.4256 7.97815 19.9614 7.97815V11.9781C17.2485 11.9781 15.3989 13.9245 15.3989 15.9156H11.3989Z"
      fill="#3A3A49"
      mask="url(#path-3-inside-2_1801_14523)"
    />
  </svg>
);

export const RefreshMono = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9247 10.2386C15.7181 10.2027 15.5056 10.2502 15.3341 10.3708C15.1625 10.4915 15.0459 10.6753 15.0099 10.8819C14.8047 12.0609 14.1894 13.1293 13.2727 13.8986C12.3559 14.6678 11.1968 15.0882 10.0002 15.0856C7.1964 15.0856 4.9153 12.8045 4.9153 10C4.9153 7.19629 7.1964 4.91519 10.0002 4.91519C11.2748 4.91519 12.4737 5.38578 13.4109 6.236L12.7571 6.88981C12.6716 6.97502 12.6114 7.08229 12.5832 7.19969C12.555 7.31708 12.5599 7.44 12.5974 7.55476C12.6349 7.66953 12.7035 7.77163 12.7956 7.84972C12.8877 7.92781 12.9996 7.97882 13.119 7.99708L15.895 8.42549C15.9964 8.44112 16.1001 8.43261 16.1976 8.40066C16.2951 8.3687 16.3837 8.3142 16.4562 8.24158C16.5287 8.16896 16.5831 8.08026 16.6149 7.9827C16.6466 7.88513 16.655 7.78144 16.6391 7.68006L16.2114 4.90464C16.193 4.78538 16.142 4.67353 16.0639 4.58152C15.9858 4.48951 15.8838 4.42092 15.7691 4.38338C15.6544 4.34583 15.5315 4.34079 15.4142 4.36882C15.2968 4.39684 15.1895 4.45684 15.1041 4.54214L14.5294 5.11753C13.3011 3.96915 11.6817 3.33126 10.0002 3.33338C6.32443 3.33338 3.3335 6.32432 3.3335 10C3.3335 13.6764 6.32443 16.6667 10.0002 16.6667C13.2481 16.6667 16.0097 14.348 16.568 11.1534C16.6039 10.9468 16.5564 10.7344 16.4357 10.5628C16.3151 10.3913 16.1313 10.2747 15.9247 10.2386Z"
      fill="#5C5C6B"
    />
  </svg>
);

export const Seperator = () => (
  <SvgIcon width="18" height="16" viewBox="0 0 18 16">
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_758_10041)">
        <path
          d="M8.75 0.5L8.75 15.5L9.25 15.5L9.25 0.5L8.75 0.5ZM8.75 0.5L8.75 15.5L9.25 15.5L9.25 0.5L8.75 0.5Z"
          fill="#C3CDD9"
        />
      </g>
      <defs>
        <clipPath id="clip0_758_10041">
          <rect width="18" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export const SubBillingType2 = ({ selected }: SubBillingTypeProps) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.5 10A9.5 9.5 0 0 1 10 .5h10a9.5 9.5 0 0 1 9.5 9.5v10a9.5 9.5 0 0 1-9.5 9.5H10A9.5 9.5 0 0 1 .5 20V10Z"
      fill={selected ? '#4676FB' : '#fff'}
      stroke={selected ? '#4676FB' : '#E9EFF4'}
    />
    <path
      d="M9.4 8.933a1.4 1.4 0 0 0-1.4 1.4V12.2a1.4 1.4 0 0 0 1.4 1.4h1.867a1.4 1.4 0 0 0 1.4-1.4v-1.867a1.4 1.4 0 0 0-1.4-1.4H9.4Zm5.133 2.8H22V10.8h-7.467v.933ZM9.4 16.4A1.4 1.4 0 0 0 8 17.8v1.866a1.4 1.4 0 0 0 1.4 1.4h1.867a1.4 1.4 0 0 0 1.4-1.4V17.8a1.4 1.4 0 0 0-1.4-1.4H9.4Zm5.133 2.8H22v-.934h-7.467v.934Z"
      fill={selected ? '#E9EFF4' : '#4676FB'}
    />
  </svg>
);

export const SubBillingType3 = ({ selected }: SubBillingTypeProps) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.5 10A9.5 9.5 0 0 1 10 .5h10a9.5 9.5 0 0 1 9.5 9.5v10a9.5 9.5 0 0 1-9.5 9.5H10A9.5 9.5 0 0 1 .5 20V10Z"
      fill={selected ? '#4676FB' : '#fff'}
      stroke={selected ? '#4676FB' : '#E9EFF4'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.659 9.866H8v-.933h13.659v.933Zm0 3.734H8v-.934h13.659v.934Zm0 3.733H8V16.4h13.659v.933Zm0 3.733H8v-.933h13.659v.933Z"
      fill={selected ? '#E9EFF4' : '#4676FB'}
    />
    <defs>
      <linearGradient id="a" x1={15} y1={0} x2={15} y2={30} gradientUnits="userSpaceOnUse">
        <stop stopColor="#638AFF" />
        <stop offset={1} stopColor="#4676FB" />
      </linearGradient>
    </defs>
  </svg>
);

export const HambergerMenu = () => (
  <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 .5h20A7.5 7.5 0 0 1 35.5 8v20a7.5 7.5 0 0 1-7.5 7.5H8A7.5 7.5 0 0 1 .5 28V8A7.5 7.5 0 0 1 8 .5Z"
      fill="#fff"
      stroke="#E9E9ED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.577 14.267h10.667a.8.8 0 1 0 0-1.6H12.577a.8.8 0 0 0 0 1.6Zm10.667 3.066H12.577a.8.8 0 0 0 0 1.6h10.667a.8.8 0 1 0 0-1.6Zm0 4.667H12.577a.8.8 0 0 0 0 1.6h10.667a.8.8 0 0 0 0-1.6Z"
      fill="#3A3A49"
    />
  </svg>
);

export const ArrowDownDepth = () => (
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.979 9.853a.755.755 0 0 0 1.026 0l2.842-2.842a.724.724 0 0 0 0-1.027.724.724 0 0 0-1.026 0L7.5 8.305l-2.321-2.32a.743.743 0 0 0-.521-.222.743.743 0 0 0-.521.221.724.724 0 0 0 0 1.027l2.842 2.842Z"
      fill="#ADC0F8"
    />
    <path
      d="M7.5 15c4.137 0 7.5-3.363 7.5-7.5C15 3.363 11.637 0 7.5 0A7.507 7.507 0 0 0 0 7.5C0 11.637 3.363 15 7.5 15Zm0-13.547A6.058 6.058 0 0 1 13.547 7.5 6.058 6.058 0 0 1 7.5 13.547 6.058 6.058 0 0 1 1.453 7.5 6.058 6.058 0 0 1 7.5 1.453Z"
      fill="#ADC0F8"
    />
  </svg>
);

export const ArrowUpDepth = () => (
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.021 5.147a.755.755 0 0 0-1.026 0L4.153 7.99a.724.724 0 0 0 0 1.027.724.724 0 0 0 1.026 0L7.5 6.695l2.321 2.32a.743.743 0 0 0 .521.222c.19 0 .38-.08.521-.221a.724.724 0 0 0 0-1.027L8.021 5.147Z"
      fill="#ADC0F8"
    />
    <path
      d="M7.5 0A7.507 7.507 0 0 0 0 7.5C0 11.637 3.363 15 7.5 15c4.137 0 7.5-3.363 7.5-7.5C15 3.363 11.637 0 7.5 0Zm0 13.547A6.058 6.058 0 0 1 1.453 7.5 6.058 6.058 0 0 1 7.5 1.453 6.058 6.058 0 0 1 13.547 7.5 6.058 6.058 0 0 1 7.5 13.547Z"
      fill="#ADC0F8"
    />
  </svg>
);

export const ThreeDot = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 6 24">
    <path
      d="M2.991 9.366c1.429 0 2.572 1.17 2.572 2.634 0 1.463-1.143 2.634-2.572 2.634C1.563 14.634.42 13.464.42 12c0-1.463 1.143-2.634 2.571-2.634ZM.42 2.634c0 1.464 1.143 2.634 2.571 2.634 1.429 0 2.572-1.17 2.572-2.634C5.563 1.171 4.42 0 2.99 0 1.563 0 .42 1.17.42 2.634Zm0 18.732C.42 22.829 1.563 24 2.991 24c1.429 0 2.572-1.17 2.572-2.634S4.42 18.732 2.99 18.732c-1.428 0-2.571 1.17-2.571 2.634Z"
      fill="#ADC0F8"
    />
  </SvgIcon>
);

export const StatusDot = ({ color }: ColorProps) => {
  return (
    <svg width={8} height={8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={4} cy={4} r={4} fill={color} />
    </svg>
  );
};

export const Search = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7278 11.9057C9.39193 12.944 7.71081 13.4338 6.0267 13.2754C4.34259 13.117 2.78213 12.3224 1.66305 11.0533C0.543968 9.78421 -0.0495848 8.13609 0.00324691 6.44452C0.0560786 4.75295 0.751322 3.14512 1.94742 1.94841C3.14351 0.751707 4.75051 0.0561073 6.44122 0.00324857C8.13193 -0.0496101 9.77921 0.544247 11.0476 1.6639C12.3161 2.78355 13.1103 4.34482 13.2686 6.02979C13.4269 7.71476 12.9373 9.39674 11.8996 10.7333L15.7549 14.5761C16.0813 14.9014 16.0818 15.4298 15.756 15.7557C15.4302 16.0816 14.9018 16.0814 14.5763 15.7552L10.7361 11.9057H10.7278ZM6.65533 11.6396C7.31018 11.6396 7.95863 11.5106 8.56364 11.2598C9.16865 11.0091 9.71837 10.6416 10.1814 10.1783C10.6445 9.71503 11.0118 9.16503 11.2624 8.55971C11.513 7.95439 11.642 7.30561 11.642 6.65042C11.642 5.99522 11.513 5.34645 11.2624 4.74113C11.0118 4.13581 10.6445 3.5858 10.1814 3.12251C9.71837 2.65922 9.16865 2.29172 8.56364 2.04099C7.95863 1.79025 7.31018 1.6612 6.65533 1.6612C5.33278 1.6612 4.0644 2.18685 3.12922 3.12251C2.19404 4.05817 1.66867 5.3272 1.66867 6.65042C1.66867 7.97364 2.19404 9.24267 3.12922 10.1783C4.0644 11.114 5.33278 11.6396 6.65533 11.6396Z"
        fill="#3A3A49"
      />
    </svg>
  );
};

export const Check = (props: SvgIconProps) => {
  return (
    <SvgIcon width={14} height={10} viewBox="0 0 14 10" {...props}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m1.8 4.6 3.6 3.6 6.8-6.8" stroke="#fff" strokeWidth={1.6} strokeLinecap="square" />
      </svg>
    </SvgIcon>
  );
};

export const BlueCheck = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66675 9.66667L8.66675 12.6667L14.3334 7" stroke="#4676FB" strokeWidth="1.6" strokeLinecap="square" />
  </svg>
);

export const Filter = (/* props: any */) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.97287 1.521H13.7274C13.8576 1.56667 13.9756 1.64153 14.0725 1.73987C14.1693 1.8382 14.2423 1.95739 14.2859 2.08831C14.3296 2.21922 14.3427 2.35839 14.3242 2.49514C14.3058 2.6319 14.2563 2.76262 14.1795 2.87729L9.65851 7.85035V14.1797L6.04174 11.4671V7.85035L1.52078 2.87729C1.444 2.76262 1.39449 2.6319 1.37604 2.49514C1.35759 2.35839 1.37068 2.21922 1.41432 2.08831C1.45796 1.95739 1.53098 1.8382 1.6278 1.73987C1.72461 1.64153 1.84265 1.56667 1.97287 1.521Z"
        stroke="#3A3A49"
        strokeWidth="1.80839"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Collecting = (props: any) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 15.467A7.467 7.467 0 0 1 3.38 2.133M8 .533a7.467 7.467 0 0 1 4.62 13.334m-.353-3.2V14.4H16M0 1.6h3.733v3.733"
      stroke="#4676FB"
    />
  </svg>
);

export const Add = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={29} height={29} rx={7.5} fill="#fff" />
    <g clipPath="url(#a)" stroke="#4676FB">
      <path d="M15 8v14m-7-7h14" />
    </g>
    <rect x={0.5} y={0.5} width={29} height={29} rx={7.5} stroke="#E9E9ED" />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 8h14v14H8z" />
      </clipPath>
    </defs>
  </svg>
);

export const Remove = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.2 10.8V9.4c0-.516.418-.934.933-.934h3.734c.515 0 .933.418.933.933v1.4m-9.8.467h14m-12.6 0V20.6c0 .516.418.934.933.934h9.334a.933.933 0 0 0 .933-.934v-9.333M15 14.533v4.666m-2.8-2.8v2.8m5.6-2.8v2.8"
      stroke="#4676FB"
    />
    <rect x={0.5} y={0.5} width={29} height={29} rx={7.5} stroke="#E9E9ED" />
  </svg>
);

export const LeftArrow = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.5 8v14a7.5 7.5 0 0 1-7.5 7.5H8A7.5 7.5 0 0 1 .5 22V8A7.5 7.5 0 0 1 8 .5h14A7.5 7.5 0 0 1 29.5 8Z"
      fill="#fff"
      stroke="#E9E9ED"
    />
    <path
      d="m12.153 15.496 4.153 4.154c.2.2.525.2.726 0l.484-.485c.2-.2.2-.524.001-.724l-3.292-3.308 3.292-3.307c.2-.2.2-.525 0-.725l-.485-.484c-.2-.2-.525-.2-.726 0l-4.153 4.153c-.2.2-.2.526 0 .726Z"
      fill="#3A3A49"
    />
  </svg>
);

export const RightArrow = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.5 22V8A7.5 7.5 0 0 1 8 .5h14A7.5 7.5 0 0 1 29.5 8v14a7.5 7.5 0 0 1-7.5 7.5H8A7.5 7.5 0 0 1 .5 22Z"
      fill="#fff"
      stroke="#E9E9ED"
    />
    <path
      d="m17.847 14.504-4.153-4.154c-.2-.2-.526-.2-.726 0l-.484.485c-.2.2-.2.524-.001.724l3.292 3.308-3.292 3.307c-.2.2-.2.525 0 .725l.485.484c.2.2.525.2.726 0l4.153-4.153c.2-.2.2-.526 0-.726Z"
      fill="#3A3A49"
    />
  </svg>
);

export const DownArrowWithCircle = (props: any) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={15} height={15} rx={7.5} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.646 5.66a.471.471 0 0 1 .708 0l3.292 3.59a.471.471 0 0 0 .708 0l3.292-3.59a.471.471 0 0 1 .708 0 .58.58 0 0 1 0 .77L9.06 10.022a1.414 1.414 0 0 1-2.122 0l-3.293-3.59a.58.58 0 0 1 0-.771Z"
      fill="#3A3A49"
    />
    <rect x={0.5} y={0.5} width={15} height={15} rx={7.5} stroke="#E9E9ED" />
  </svg>
);

export const Export = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.5 10A9.5 9.5 0 0 1 10 .5h10a9.5 9.5 0 0 1 9.5 9.5v10a9.5 9.5 0 0 1-9.5 9.5H10A9.5 9.5 0 0 1 .5 20V10Z"
      fill="#fff"
      stroke="#E9E9ED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.467 16.982V8.067h1.067v8.846l2.29-2.29.753.754-3.562 3.563-3.843-3.548.723-.784 2.572 2.374Zm-5.333 3.885v-6.4H8.067v7.467h13.867v-7.467h-1.067v6.4H9.134Z"
      fill="#3A3A49"
    />
  </svg>
);

export const CollapseDown = (props: any) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m15.93 20.338 7.788-7.788a.962.962 0 0 0 0-1.36l-.908-.908a.962.962 0 0 0-1.359-.002l-6.201 6.172-6.201-6.172a.962.962 0 0 0-1.359.002l-.908.908a.962.962 0 0 0 0 1.36l7.788 7.788a.962.962 0 0 0 1.36 0Z"
      fill="#4676FB"
    />
  </svg>
);

export const ExpandDown = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.46678 2.66669L4.00011 6.40002L0.533447 2.66669" stroke="#3A3A49" strokeLinecap="round" />
  </svg>
);

export const BlueCancel = (props: any) => (
  <SvgIcon width={20} height={20} viewBox="0 0 20 20" {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.80005 6.79999L13.2 13.2M6.80005 13.2L13.2 6.79999" stroke="#4676FB" />
    </svg>
  </SvgIcon>
);

export const Caption = (props: any) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 5.2v.5h1v-.5h-1Zm1-.01v-.5h-1v.5h1Zm0 6.077V7.533h-1v3.734h1Zm0-6.067v-.01h-1v.01h1ZM6.6 8.5H8v-1H6.6v1Zm0 2.8h2.8v-1H6.6v1ZM8 1.967A6.033 6.033 0 0 1 14.034 8h1A7.033 7.033 0 0 0 8 .967v1ZM1.967 8A6.033 6.033 0 0 1 8 1.967v-1A7.033 7.033 0 0 0 .967 8h1ZM8 14.034A6.033 6.033 0 0 1 1.967 8h-1A7.033 7.033 0 0 0 8 15.034v-1Zm0 1A7.033 7.033 0 0 0 15.034 8h-1A6.033 6.033 0 0 1 8 14.034v1Z"
      fill="#3A3A49"
    />
  </svg>
);

export const ArrowForward = (props: any) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m8 15 5-5-5-5" stroke="#878795" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CarouselArrowNext = ({ stroke = '#24292E' }: SvgIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.75 7.5L14.25 12L9.75 16.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CarouselArrowPrev = ({ stroke = '#24292E' }: SvgIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 7.5L9.75 12L14.25 16.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
