import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type IExchangeRateRepository from '@repositories/exchangeRateRepository/IExchangeRateRepository';
import type {
  ExchageRateUpdateRq,
  ExchangeRateCreateRq,
  ExchangeRateFilter,
} from '@repositories/exchangeRateRepository/Types';
import type { Page } from '@type/Page';
import { ExchangeRateModel } from '@models/exchangeRate/ExchangeRateModel';

const exchangeRateRepo = repository.get<IExchangeRateRepository>(KEYS.EXCHANGE_RATE_REPOSITORY);
const exchangeRateQueryKey = {
  all: ['exchangeRateRequest'] as const,
  lists: () => [...exchangeRateQueryKey.all, 'list'] as const,

  list: (filter: ExchangeRateFilter) => [...exchangeRateQueryKey.lists(), filter] as const,
};

export const useGetExchangeRates = (
  queries?: ExchangeRateFilter,
  options?: UseQueryOptionsType<Page<ExchangeRateModel>>,
) =>
  useQuery<Page<ExchangeRateModel>, AxiosError>({
    queryKey: exchangeRateQueryKey.list(queries ? { ...queries } : {}),
    queryFn: async () => {
      const result = await exchangeRateRepo.getList(queries ?? {});
      return {
        ...result,
        content: result.content.map(dto => new ExchangeRateModel(dto)),
      };
    },
    ...options,
  });

export const useAddExchangeRates = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ExchangeRateCreateRq) => {
      const result = await exchangeRateRepo.create(data);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(exchangeRateQueryKey.lists());
    },
  });
};

export const useUpdateExchangeRates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ updateExchangeRateId, data }: { updateExchangeRateId: string; data: ExchageRateUpdateRq }) => {
      const result = await exchangeRateRepo.update(updateExchangeRateId, data);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(exchangeRateQueryKey.lists());
    },
  });
};

export const useDeleteExchangeRates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (deleteExchangeRateId: string) => {
      const result = await exchangeRateRepo.delete(deleteExchangeRateId);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(exchangeRateQueryKey.lists());
    },
  });
};
