import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { SearchTextField } from '@components/inputs';
import { TableBodyCell, TableHeaderCell } from '@components/table';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import type { TechnicalInquiryListFilter } from '@repositories/technicalSupportRepository/technicalSupport.types';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useGetInfiniteTechnicalInquiryList } from '@queryHooks/useTechnicalSupport';
import { getSortOrder } from '@utils/CommonUtil';

type TableHeaderProps = {
  keyword?: string;
  totalElement?: number;
  handleChangeKeyword: (keyword: string) => void;
};

type TableHeadRowProps = {
  queries: TechnicalInquiryListFilter;
  handleChangeSort: (orderBy: string, order: SortOrder | undefined) => void;
};

const TableHeader = ({ keyword, totalElement, handleChangeKeyword }: TableHeaderProps) => {
  return (
    <TableHeaderWrap>
      <TableHeaderTitle>
        전체<span className="counter">{totalElement ?? 0}</span>
      </TableHeaderTitle>
      <StyledTextField
        placeholder="제품명 검색"
        value={keyword}
        onChange={handleChangeKeyword}
        onSearch={handleChangeKeyword}
      />
    </TableHeaderWrap>
  );
};

const TableHeadRow = ({ queries, handleChangeSort }: TableHeadRowProps) => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableHeaderCell align="center" width="72px">
          번호
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="140px"
          orderBy="categoryName"
          order={getSortOrder(queries.sort, 'categoryName')}
          onSortChange={handleChangeSort}
        >
          제품 구분
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="260px"
          orderBy="productName"
          order={getSortOrder(queries.sort, 'productName')}
          onSortChange={handleChangeSort}
        >
          제품명
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="428px"
          orderBy="planRate"
          order={getSortOrder(queries.sort, 'planRate')}
          onSortChange={handleChangeSort}
        >
          플랜(요금제)
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="160px"
          orderBy="userName"
          order={getSortOrder(queries.sort, 'userName')}
          onSortChange={handleChangeSort}
        >
          구성원(이름)
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="240px"
          orderBy="account"
          order={getSortOrder(queries.sort, 'account')}
          onSortChange={handleChangeSort}
        >
          구매 신청 계정
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="180px"
          orderBy="createdDate"
          order={getSortOrder(queries.sort, 'createdDate')}
          onSortChange={handleChangeSort}
        >
          문의 날짜
        </StyledTableHeaderCell>
        <StyledTableHeaderCell
          width="120px"
          orderBy="status"
          order={getSortOrder(queries.sort, 'status')}
          onSortChange={handleChangeSort}
        >
          처리 상태
        </StyledTableHeaderCell>
      </TableRow>
    </TableHead>
  );
};

export const TechnicalSupportListTable = () => {
  const theme = useTheme();
  const navigator = useNavigate();
  const { tenantId } = useParams();
  const [queries, setQueries] = useSearchParamsFilter<TechnicalInquiryListFilter>({
    defaultFilter: {
      sort: [],
      keyword: '',
    },
    whiteList: ['sort', 'keyword'],
  });
  const { data: technicalInquiry } = useGetInfiniteTechnicalInquiryList({ ...queries, tenantId });
  const technicalInquiryFlat = technicalInquiry?.pages.flatMap(data => data.content);

  const handleChangeKeyword = (value: string) => {
    setQueries(prev => ({ ...prev, keyword: value }));
  };

  const handleChangeSort = (orderBy: string, order: SortOrder | undefined) => {
    setQueries(prevFilter => ({
      ...prevFilter,
      sort: order ? [`${orderBy}.${order}`] : [],
    }));
  };

  return (
    <TableLayout>
      <TableHeader
        keyword={queries.keyword}
        totalElement={technicalInquiry?.pages[0].totalElements}
        handleChangeKeyword={handleChangeKeyword}
      />
      <TableWrap>
        <Table>
          <TableHeadRow queries={queries} handleChangeSort={handleChangeSort} />
          <TableBody>
            {technicalInquiryFlat?.length ? (
              technicalInquiryFlat.map((data, index) => {
                return (
                  <TableRow key={data.technicalInquiryId} onClick={() => navigator(`${data.technicalInquiryId}`)}>
                    <TableBodyCell align="center">{index}</TableBodyCell>
                    <TableBodyCell>{data.categoryName}</TableBodyCell>
                    <TableBodyCell>
                      <ProductLabel>
                        <ProductLabelIcon src={data.productIconUrl} />
                        {data.productName}
                      </ProductLabel>
                    </TableBodyCell>
                    <TableBodyCell>{data.planName}</TableBodyCell>
                    <TableBodyCell>{data.userName}</TableBodyCell>
                    <TableBodyCell>{data.account}</TableBodyCell>
                    <TableBodyCell>{dayjs(data.createdDate).format('YYYY.MM.DD')}</TableBodyCell>
                    <TableBodyCell>
                      <Label variant="contain" color={data.conversionStatusColor}>
                        {data.conversionStatus}
                      </Label>
                    </TableBodyCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableBodyCell colSpan={8}>
                  <Empty>
                    <div className="icon">
                      <ErrorIcon color={theme.colors['ic-purple-light']} />
                    </div>
                    <span>기술지원 문의 내역이 없습니다.</span>
                  </Empty>
                </TableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrap>
    </TableLayout>
  );
};

const TableLayout = styled.section`
  margin-top: 40px;
`;

const TableHeaderWrap = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTextField = styled(SearchTextField)`
  width: 250px;
`;

const TableHeaderTitle = styled.div`
  ${fonts.Headline8}
  display: flex;
  gap: 4px;
  padding: 8px 0 4px 16px;
  .counter {
    color: ${({ theme }) => theme.colors['text-purple-light']};
  }
`;

const TableWrap = styled(TableContainer)`
  width: 100%;
  margin-top: 16px;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  padding: 11px 12px;
`;

const ProductLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ProductLabelIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  height: 126px;

  & .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: ${props => props.theme.colors['bg-purple-lighter']};
  }

  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;
