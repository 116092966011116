import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { EmptyIcon } from '@icons/EmptyIcon';
import { useGetWorkflows } from '@queryHooks/useWorkflow';
import { List, WgBox, WgContainer, WgTitle } from '../styles';

export const WorkflowWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const handleClickSeeMore = () => {
    navigate(`/manager/${params.tenantId}/workflows/management`);
  };

  const handleClickWorkflow = (id: string) => {
    navigate(`/manager/${params.tenantId}/workflows/management/${id}`);
  };

  const { data: workflows } = useGetWorkflows({});

  const recentWorkflows = workflows?.content
    .filter(workflow => workflow.latestExecutionStatus !== 'FAILED')
    .filter(workflow => workflow.latestExecutionTime);

  const errorWorkflows = workflows?.content
    .filter(workflow => workflow.latestExecutionStatus === 'FAILED')
    .filter(workflow => workflow.latestExecutionTime);

  return (
    <WgContainer width={632}>
      <WgTitle>
        <span>{t('Ad_GNB_Menu_02')}</span>
        <Button className="see-more" onClick={handleClickSeeMore} variant="text">
          {t('Home_Add')}
          <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
        </Button>
      </WgTitle>
      <Workflows>
        <Content>
          <span className="status">{t('Ad_Home_Workflow')}</span>
          <WgBox width={290} height={154} marginTop={8}>
            <List>
              {recentWorkflows?.length ? (
                recentWorkflows.map(workflow => {
                  return (
                    <button
                      type="button"
                      className="workflow"
                      key={workflow.id}
                      onClick={() => handleClickWorkflow(workflow.id)}
                    >
                      <span className="name">{workflow.name}</span>
                      <span className="date">{workflow.latestExecutionTime?.format(t('DateFormat_MD_Time'))}</span>
                    </button>
                  );
                })
              ) : (
                <Empty>
                  <EmptyIcon />
                  {t('Home_Workflow_01')}
                </Empty>
              )}
            </List>
          </WgBox>
        </Content>
        <Content className="err">
          <span className="status">{t('Subscrib_Connect_Create_06')}</span>
          <WgBox width={290} height={154} marginTop={8}>
            <List>
              {errorWorkflows?.length ? (
                errorWorkflows.map(workflow => {
                  return (
                    <button
                      type="button"
                      className="workflow"
                      key={workflow.id}
                      onClick={() => handleClickWorkflow(workflow.id)}
                    >
                      <span className="name">{workflow.name}</span>
                      <span className="date">{workflow.latestExecutionTime?.format(t('DateFormat_MD_Time'))}</span>
                    </button>
                  );
                })
              ) : (
                <Empty>
                  <EmptyIcon />
                  {t('Home_Workflow_02')}
                </Empty>
              )}
            </List>
          </WgBox>
        </Content>
      </Workflows>
    </WgContainer>
  );
};

const Workflows = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
  height: 182px;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
  & .status {
    padding: 0px 0px 0px 8px;
  }
  &.err {
    color: ${props => props.theme.colors['text-red-light']};
  }

  & .workflow {
    display: flex;
    flex-direction: row;
    width: 258px;
    height: 20px;
    align-items: center;
    justify-content: space-between;
    ${fonts.Body2};
    border: none;
    background-color: inherit;
    color: ${props => props.theme.colors['text-gray-main']};
    & .name {
      width: 157px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      :hover {
        cursor: pointer;
        text-decoration: underline;
        color: ${props => props.theme.colors['text-purple']};
      }
    }
    & .date {
      color: ${props => props.theme.colors['text-gray-light']};
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 290px;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;
