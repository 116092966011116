import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Button, Label } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { EmptyIcon } from '@icons/EmptyIcon';
import { useGetSubscriptionRequests } from '@queryHooks/useSubscriptionRequest';
import { List, WgBox, WgContainer, WgSummary, WgTitle } from '../styles';

export const PurchaseRqWg = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { tenantId } = useParams();
  const navigate = useNavigate();

  const handleClickSeeMore = () => {
    navigate(`/manager/${tenantId}/store/apply-histories`);
  };

  const handleClickRq = (id: string) => {
    navigate(`/manager/${tenantId}/store/apply-histories/${id}`);
  };

  const { data: subscriptionRequestsData } = useGetSubscriptionRequests({
    productName: '',
    sort: ['createdDate.desc'],
  });

  return (
    <WgContainer width={415}>
      <WgTitle>
        <span>{t('Subscrib_Detail_BuyRequest_01')}</span>
        <span className="see-more">
          <Button className="see-more" onClick={handleClickSeeMore} variant="text">
            {t('Home_Add')}
            <ChevronIcon rotateNum={90} width={16} height={16} color={theme.colors['ic-gray-dark']} />
          </Button>
        </span>
      </WgTitle>
      <WgSummary>
        <span className="count">
          <span className="count-number">{subscriptionRequestsData?.totalElements}</span>
          <span className="counting-unit">{t('Ad_Cart_15')}</span>
        </span>
      </WgSummary>
      <WgBox width={385}>
        <List>
          {subscriptionRequestsData?.content.length ? (
            subscriptionRequestsData?.content.map(rq => {
              return (
                <Request key={rq.id} onClick={() => handleClickRq(rq.id.toString())}>
                  <span className="info">
                    <Avatar sx={{ width: '16px', height: '16px' }} variant="square" src={rq.software.iconUrl} />
                    {rq.software.name}
                  </span>
                  <span className="status-date">
                    <Label variant="contain" color={rq.statusLabelColor}>
                      {rq.statusStr}
                    </Label>
                    <span className="line" />
                    <span className="date">
                      {' '}
                      {dayjs().year() === dayjs(rq.formattedCreatedDate).year()
                        ? dayjs(rq.formattedCreatedDate).format('MM.DD')
                        : dayjs(rq.formattedCreatedDate).format(t('DateFormat_YMD'))}
                    </span>
                  </span>
                </Request>
              );
            })
          ) : (
            <Empty>
              <EmptyIcon />
              {t('Home_Empty')}
            </Empty>
          )}
        </List>
      </WgBox>
    </WgContainer>
  );
};

const Request = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-main']};
  gap: 25px;
  :hover {
    cursor: pointer;
  }

  & .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    :hover {
      text-decoration: underline;
      color: ${props => props.theme.colors['text-purple']};
    }
  }

  & .status-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    & .line {
      width: 1px;
      height: 11px;
      background-color: ${props => props.theme.colors['border-gray-light']};
    }
    & .date {
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      width: 33px;
      white-space: pre;
      color: ${props => props.theme.colors['text-gray-light']};
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 100dvh;
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;
