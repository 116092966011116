import { useState } from 'react';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import debounce from 'lodash.debounce';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { SubscriptionUserUpdateRq } from '@repositories/subscriptionRepository';
import { TenantMemberFilter } from '@repositories/tenantRepository';
import { useUpdateSubscriptionUser } from '@queryHooks/useSubscription';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import { DomUtil } from '@utils/DomUtil';
import { SubscriptionUserModel } from '@models/SubscriptionUserModel';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import * as S from './LinkUserDialogStyles';
import { LinkUserForm, useLinkUserForm } from './validationSchema';

type Props = {
  targetUser: SubscriptionUserModel;
  open: boolean;
  onClose: () => void;
};

type AutocompleteOption = Pick<TenantMemberSimpleModel, 'id' | 'name'>;

export const LinkUserDialog = ({ targetUser, open, onClose }: Props) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const theme = useTheme();
  const [memberFilter, setMemberFilter] = useState<Omit<TenantMemberFilter, 'page'>>({ size: 100, keyword: undefined });

  const hookForm = useLinkUserForm();

  const { mutate: updateSubscriptionUser } = useUpdateSubscriptionUser();

  const handleValid: SubmitHandler<LinkUserForm> = formData => {
    const rqData: SubscriptionUserUpdateRq = targetUser.getSubscriptionUserUpdateRq({
      userId: formData.userId,
    });

    updateSubscriptionUser({ subscriptionId: targetUser.subscriptionId, rqData }, { onSuccess: onClose });
  };

  const {
    isSuccess: isUserListSuccess,
    data: userListPage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetTenantMembersInfinite(authStore.managerTenantInfo.id, { ...memberFilter });

  const options: AutocompleteOption[] = isUserListSuccess
    ? userListPage.pages?.reduce(
        (accum, cur) => [...accum, ...cur.content.map(user => ({ id: user.id, name: `${user.name}(${user.email})` }))],
        [] as AutocompleteOption[],
      )
    : [];

  const handleAutocompleteScroll = debounce((event: React.UIEvent<HTMLUListElement>) => {
    if (DomUtil.isScrollBottom(event.target as HTMLUListElement) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 100);

  const handleMemberSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberFilter(prev => ({ ...prev, keyword: e.target.value }));
  }, 1000);

  return (
    <Dialog open={open} onClose={onClose} title={t('Subscrib_Detail_User_21')} height={600}>
      <FormProvider {...hookForm}>
        <S.Form onSubmit={hookForm.handleSubmit(handleValid)}>
          <S.Field>
            <S.FieldName>
              {t('Workflow_Main_05')}
              <strong>*</strong>
            </S.FieldName>
            <Controller
              control={hookForm.control}
              name="userId"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  onChange={(event, value) => onChange(value?.id)}
                  options={options}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder={t('Subscrib_Detail_User_23')}
                      variant="outlined"
                      onChange={handleMemberSearchChange}
                    />
                  )}
                  ListboxProps={{ onScroll: handleAutocompleteScroll, role: 'list-box' }}
                  // sx={{ marginTop: '10px' }}
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '10px 12px',
                    },
                    '& .MuiButtonBase-root': {
                      borderRadius: '0',
                      '&:hover': {
                        backgroundColor: `${theme.colors['bg-white']}`,
                      },
                    },
                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                      padding: 0,
                    },
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: '344px',
                        marginTop: '4px',
                        borderRadius: '0 0 2px 2px',
                        boxShadow: '0',
                        border: `1px solid ${theme.colors['border-gray-light']}`,
                        borderWidth: '1px 1px 1px 1px',
                        overflow: 'hidden',
                        '& .MuiAutocomplete-listbox': {
                          height: '328px',
                        },
                        '& .MuiList-root': {
                          border: 'none',
                          padding: '4px 0',
                          '& .MuiMenuItem-root': {
                            margin: '0 5px',
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            />
          </S.Field>
          <S.Field>
            <S.FieldName>
              {t('Subscrib_Detail_User_11')}
              <strong>*</strong>
            </S.FieldName>
            <S.IdTextField>{targetUser.userLoginId}</S.IdTextField>
          </S.Field>
          <Dialog.Footer>
            <Button variant="contain" size="extraLarge" type="submit">
              {t('Member_Manager_11')}
            </Button>
            <Button variant="outline" size="extraLarge" type="button" onClick={onClose}>
              {t('Subscrib_Detail_BuyRequest_13')}
            </Button>
          </Dialog.Footer>
        </S.Form>
      </FormProvider>
    </Dialog>
  );
};
