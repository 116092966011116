type NumberFormatParam = {
  num?: number;
  maxFractionDigit?: number;
  minFractionDigit?: number;
  style?: 'currency' | 'percent';
  currency?: CurrencyUnit;
};

export const numberFormat = ({ num, maxFractionDigit = 10, minFractionDigit, currency, style }: NumberFormatParam) => {
  if (num === undefined) {
    return '-';
  }

  try {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: maxFractionDigit,
      minimumFractionDigits: minFractionDigit,
      style,
      currency,
    }).format(num);
  } catch {
    return '-';
  }
};

/**
 *
 * @param num 변환할 값
 * @param currency CurrencyUnit
 * @param currencyStyle 'symbol'은 `$123.00`, 'text'는 `(USD) 123.00` 형태. default는 'symbol'
 * @returns
 */
export const currencyNumberFormat = (
  num: number,
  currency?: CurrencyUnit,
  currencyStyle: 'text' | 'symbol' = 'symbol',
) => {
  const formattedNum = numberFormat({ num, style: 'currency', currency });

  if (currencyStyle === 'symbol') {
    return formattedNum;
  }
  return num > 0
    ? `(${currency}) ${formattedNum.slice(1, formattedNum.length)}`
    : `(${currency}) -${formattedNum.slice(2, formattedNum.length)}`;
};

export const currencyTextFormat = (value?: number | null, currencyUnit?: CurrencyUnit) => {
  if (typeof value !== 'number') return '-';

  return currencyNumberFormat(value, currencyUnit, 'text');
};

export const getCurrencySymbol = (currencyUnit: string, locale = 'en-US') => {
  try {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyUnit })
      .formatToParts(0)
      .find(({ type }) => type === 'currency')?.value;
  } catch {
    return undefined;
  }
};
