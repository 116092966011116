import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { Label } from '@components/Label';
import { UsageRequestEditModal } from '@components/products/usageRequest/UsageRequestEditModal';
import { useStore } from '@stores/RootStore';
import { ChevronIcon } from '@icons/ChevronIcon';
import { PencilIcon } from '@icons/PencilIcon';
import { TrashIcon } from '@icons/TrashIcon';
import { useDeleteSubscriptionUseRequest, useGetSubscriptionUseRequest } from '@queryHooks/useSubscriptionUseRequest';
import * as S from './ApplyDetailStyles';

export const ApplyDetail = () => {
  const { t } = useTranslation();
  const {
    uiStore: { alertStore: deleteModal },
    categoryStore,
  } = useStore();
  const params = useParams();
  const navigate = useNavigate();

  const { data: applySb } = useGetSubscriptionUseRequest(params.id as string);
  const { mutate: deleteApply } = useDeleteSubscriptionUseRequest();

  const [isOpenModifyModal, setIsOpenModifyModal] = useState<boolean>(false);

  const handleDelete = () => {
    deleteApply(
      { requestId: params.id as string },
      {
        onSuccess: () => {
          navigate('/subscriptions/usage-apply');
        },
      },
    );
  };

  const productType = applySb?.categoryId ? categoryStore.findRootCategory(applySb.categoryId)?.name : '';

  return (
    <S.Container>
      <S.Header>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        <span>{t('Apply_Detail_01')}</span>
      </S.Header>
      <S.Body>
        <S.TitleHeader>
          <span className="title">{t('Apply_Detail_02')}</span>
          {applySb?.status === 'CREATED' || applySb?.status === 'PENDING' ? (
            <div className="edit">
              <S.Button
                variant="outline"
                size="small"
                onClick={() => setIsOpenModifyModal(true)}
                paddingHorizontal={12}
              >
                <PencilIcon width={16} height={16} />
                <span>{t('Ad_Cart_09')}</span>
              </S.Button>
              <S.Button
                variant="outline"
                size="small"
                paddingHorizontal={12}
                onClick={() =>
                  deleteModal.open({
                    confirmName: t('Subscrib_Detail_BuyRequest_12'),
                    message: t('Apply_Detail_03'),
                    title: t('Apply_Detail_04'),
                    useCancel: true,
                    cancelName: t('Subscrib_Detail_BuyRequest_13'),
                    onConfirm: handleDelete,
                    type: 'error',
                  })
                }
              >
                <TrashIcon width={16} height={16} />
                <span>{t('Subscrib_Detail_BuyRequest_12')}</span>
              </S.Button>
            </div>
          ) : null}
        </S.TitleHeader>
        <S.Frame>
          <S.Columns>
            <S.Column divide>
              <span className="content-name">{t('Subscrib_Detail_UseRequest_05')}</span>
              <span className="content-value">{dayjs(applySb?.createdDate).format(t('DateFormat_YMD'))}</span>
            </S.Column>
            <S.Column divide={false}>
              <span className="content-name">{t('Subscrib_Detail_UseRequest_06')}</span>
              <span className="content-value">{productType}</span>
            </S.Column>
          </S.Columns>
          <S.Columns>
            <S.Column divide>
              <span className="content-name">{t('Subscrib_Detail_UseRequest_07')}</span>
              <span className="content-value">{applySb?.productName}</span>
            </S.Column>
            <S.Column divide>
              <span className="content-name">{t('Subscrib_Main_12')}</span>
              <span className="content-value">{applySb?.planName}</span>
            </S.Column>
          </S.Columns>
          <S.Columns>
            <S.Column divide>
              <span className="content-name">{t('Acc_Detail_04')}</span>
              <span className="content-value">{`${dayjs(applySb?.startDate).format(t('DateFormat_YMD'))} ~ ${dayjs(
                applySb?.endDate,
              ).format(t('DateFormat_YMD'))}`}</span>
            </S.Column>
            <S.Column divide />
          </S.Columns>
          <S.Columns>
            <S.Column divide>
              <span className="content-name">{t('Subscrib_Detail_UseRequest_15')}</span>
              <span className="inquiry">{applySb?.inquiry.replaceAll(' ', '') ? applySb?.inquiry : '-'}</span>
            </S.Column>
          </S.Columns>
        </S.Frame>

        <S.TitleHeader>
          <span className="title">{t('Request_Processing')}</span>
        </S.TitleHeader>
        {applySb?.histories?.map(history => {
          return (
            <S.Frame key={history.status}>
              <S.Columns>
                <S.Column divide>
                  <span className="content-name">{t('Subscrib_Detail_UseRequest_09')}</span>
                  <span className="content-value">
                    <Label variant="contain" color={history.statusLabelColor}>
                      {history.statusStr}
                    </Label>
                  </span>
                </S.Column>
                <S.Column divide={false}>
                  <span className="content-name">{t('Subscrib_Detail_UseRequest_33')}</span>
                  <span className="content-value">{dayjs(history.createdDate).format(t('DateFormat_YMD'))}</span>
                </S.Column>
              </S.Columns>
              <S.Columns>
                <S.Column divide>
                  <span className="content-name">{t('Apply_Detail_05')}</span>
                  <span className="content-value">{history.managerName || '-'}</span>
                </S.Column>
                <S.Column divide={false} />
              </S.Columns>
              <S.Columns>
                <S.Column divide={false}>
                  <span className="content-name">{t('Subscrib_Detail_UseRequest_15')}</span>
                  <span className="inquiry">{history.comment || '-'}</span>
                </S.Column>
              </S.Columns>
            </S.Frame>
          );
        })}
      </S.Body>
      <UsageRequestEditModal
        isOpen={isOpenModifyModal}
        onClose={() => setIsOpenModifyModal(false)}
        swId={applySb?.productId as string}
        modifyData={applySb}
      />
    </S.Container>
  );
};
