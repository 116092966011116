const defaultWidth = 18;
const defaultHeight = 18;

export const MailIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 3.975H3C2.91716 3.975 2.85 4.04216 2.85 4.125V13.875C2.85 13.9578 2.91716 14.025 3 14.025H15C15.0828 14.025 15.15 13.9578 15.15 13.875V4.125C15.15 4.04216 15.0828 3.975 15 3.975ZM3 2.625C2.17157 2.625 1.5 3.29657 1.5 4.125V13.875C1.5 14.7034 2.17157 15.375 3 15.375H15C15.8284 15.375 16.5 14.7034 16.5 13.875V4.125C16.5 3.29657 15.8284 2.625 15 2.625H3Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.85645 6.17439L2.64429 5.07812L8.57758 9.34213C8.81486 9.43415 8.94305 9.45271 9.03188 9.45118C9.11839 9.44969 9.22226 9.42861 9.41583 9.3474L15.3564 5.07812L16.1443 6.17439L10.0873 10.5273L10.023 10.5557C9.7254 10.6871 9.41517 10.7948 9.05511 10.801C8.69699 10.8071 8.35727 10.7121 7.99349 10.5624L7.92052 10.5324L1.85645 6.17439Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

MailIcon.displayName = 'MailIcon';
