import { nanoid } from 'nanoid';
import { useCallback, useState } from 'react';

const useDynamicInput = <T>(obj: T) => {
  const [data, setData] = useState<Array<T & { id: string }>>([{ id: nanoid(), ...obj }]);

  const add = useCallback((data: T) => {
    if (data) {
      setData(prev => {
        return [...prev, { id: nanoid(), ...data }];
      });
    }
  }, []);

  const remove = useCallback((index: number) => {
    setData(prev => [...prev].filter((value: T & { id: string }, idx: number) => idx !== index));
  }, []);

  const handleChangeValue = (value: T, idx: number) => {
    setData(prev => {
      const values = [...prev];
      values[idx] = { id: values[idx].id, ...value };
      return values;
    });
  };

  const clear = useCallback(() => {
    setData([]);
  }, []);

  const init = useCallback((data: T[]) => {
    if (data) {
      const initData = data?.map(v => {
        return {
          id: nanoid(),
          ...v,
        };
      });
      setData(initData);
    }
  }, []);

  return {
    data,
    add,
    remove,
    handleChangeValue,
    clear,
    init,
  };
};

export default useDynamicInput;
