import styled from '@emotion/styled';
import { Collapse } from '@components/transitions/Collapse';

interface Props {
  open: boolean;
  children: React.ReactNode;
}

export const OptionBar = ({ open, children }: Props) => {
  return (
    <Collapse open={open} orientation="horizontal" height="100%">
      <OptionBarContainer>{children}</OptionBarContainer>
    </Collapse>
  );
};

const OptionBarContainer = styled.div`
  height: 100%;
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 36px 32px;
  border-left: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  overflow: auto;
  background-color: ${props => props.theme.colors['bg-white']};
`;
