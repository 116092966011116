import type { MonthlyBillingInfoDTO } from './Types';
import { injectable } from 'inversify';
import API from '@repositories/Axios';
import IUserMonthlyBillingRepository from './IUserMonthlyBillingRepository';

@injectable()
export class UserMonthlyBillingRepository implements IUserMonthlyBillingRepository {
  async getMonthlyBillingInfo(subscriptionId: string, month: string): Promise<MonthlyBillingInfoDTO> {
    const result = await API.get<MonthlyBillingInfoDTO>(
      `/api/v1/subscriptions/${subscriptionId}/monthly-billings/${month}/info`,
    );
    return result.data;
  }
}
