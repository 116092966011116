import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import throttle from 'lodash/throttle';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { fonts } from '@theme/fontsCustomer';
import { SquareFillIcon } from '@icons/SquareFillIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetUsersActivityStats } from '@queryHooks/useSubscription';
import { ObjectUtil } from '@utils/ObjectUtil';
import { numberFormat } from '@utils/numberFormat';

type BarChartData = {
  '0 ~ 30일': number;
  '31일 ~ 60일': number;
  '61일 ~ 90일': number;
  '91일 이상': number;
  '알 수 없음': number;
};

type Props = {
  onChartClick: (lastLoginTimeFrom?: DateTimeString, lastLoginTimeTo?: DateTimeString) => void;
};
export const LastLoginUserBarChart = ({ onChartClick }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const subscription = useSubscriptionModel();
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [selectedBarChartIndex, setSelectedBarChartIndex] = useState<0 | 1 | 2 | 3 | undefined>();
  const { data: activityStats, isSuccess: isActivityStatsSuccess } = useGetUsersActivityStats(subscription.id);

  const barChartDataMap = useMemo(
    () => ({
      [t('Subscrib_Detail_User_07')]: activityStats?.within30 ?? 0,
      [t('Subscrib_Detail_User_08')]: activityStats?.within31To60 ?? 0,
      [t('Subscrib_Detail_User_09')]: activityStats?.within61To90 ?? 0,
      [t('Subscrib_Detail_User_05')]: activityStats?.moreThan91 ?? 0,
      [t('Subscrib_Detail_User_06')]: activityStats?.noRecord ?? 0,
    }),
    [activityStats, t],
  );
  // BarChart의 data는 Array 형태 + 렌더링마다 data가 바뀌면 툴팁 위치에 문제가 생겨서 메모이제이션 처리
  const barChartDataArr = useMemo(() => [barChartDataMap], [barChartDataMap]);
  const barChartColorArr = [
    colors['graph-green'],
    colors['graph-green-light'],
    colors['graph-yellow'],
    colors['graph-orange'],
    colors['graph-gray'],
  ];

  const maxValue = isActivityStatsSuccess
    ? ObjectUtil.keys(activityStats).reduce((sum, key) => sum + activityStats[key], 0)
    : 0;

  const chartIndexs = [0, 1, 2, 3, 4] as const;

  const chartDataKeys = ObjectUtil.keys(barChartDataMap);
  const lastChartKey = chartIndexs.findLastIndex(idx => barChartDataMap[chartDataKeys[idx]] > 0);

  const handleClickChart = (idx: 0 | 1 | 2 | 3) => {
    if (selectedBarChartIndex === idx) {
      setSelectedBarChartIndex(undefined);
      onChartClick(undefined, undefined);
    } else {
      setSelectedBarChartIndex(idx);

      let from;
      let to;
      switch (idx) {
        case 0:
          from = -30;
          to = 0;
          break;
        case 1:
          from = -60;
          to = -31;
          break;
        case 2:
          from = -90;
          to = -61;
          break;
        case 3:
          to = -91;
          break;
      }

      const lastLoginTimeFrom = from === undefined ? undefined : dayjs().add(from, 'day').format('YYYY-MM-DDTHH:mm:ss');
      const lastLoginTimeTo = dayjs().add(to, 'day').format('YYYY-MM-DDTHH:mm:ss');

      onChartClick(lastLoginTimeFrom, lastLoginTimeTo);
    }
  };

  const handleMouseMove = useMemo(
    () =>
      throttle((payload: UnknownObject, idx: number, e: React.MouseEvent) => {
        setTooltipPosition({ x: e.nativeEvent.offsetX + 20, y: e.nativeEvent.offsetY + 20 });
      }, 100),
    [],
  );

  return (
    <ResponsiveContainer width="100%" height={108}>
      <BarChart data={barChartDataArr} layout="vertical">
        <XAxis type="number" hide domain={[0, maxValue]} />
        <YAxis type="category" hide dataKey="name" />
        {maxValue > 0 && (
          <Tooltip
            shared={false}
            content={<BarTooltip />}
            allowEscapeViewBox={{ x: true, y: true }}
            position={tooltipPosition}
            wrapperStyle={{ outline: 'none', border: 'none' }}
          />
        )}
        <Legend content={<BarLegend />} />
        {maxValue === 0 && (
          <Bar
            dataKey="background"
            layout="horizontal"
            stackId={1}
            shape={BarShape(false, true, true, false)}
            background={{ fill: colors['graph-gray-m-light'] }}
            barSize={60}
          />
        )}
        {chartIndexs.map(idx => (
          <Bar
            key={idx}
            dataKey={chartDataKeys[idx]}
            fill={barChartColorArr[idx]}
            stackId={1}
            shape={BarShape(selectedBarChartIndex === idx, idx === 0, idx === lastChartKey, idx !== 4)}
            barSize={60}
            onClick={() => (idx !== 4 ? handleClickChart(idx) : undefined)}
            onMouseMove={handleMouseMove}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const BarTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: { name: string; value: number; payload: BarChartData }[];
}) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }
  const data = payload[0].payload;
  const sum = ObjectUtil.keys(data).reduce((accum, key) => accum + data[key], 0);
  const percent = (payload[0].value * 100) / sum;

  return (
    <TooltipContainer>
      <p>{payload[0].name}</p>
      <span>
        {numberFormat({ num: payload[0].value })}{' '}
        <strong>{`(${numberFormat({ num: percent, maxFractionDigit: 2 })}%)`}</strong>
      </span>
    </TooltipContainer>
  );
};

const BarShape =
  (isSelected: boolean, isFirst: boolean, isLast: boolean, clickable: boolean) => (props: UnknownObject) => {
    const { fill, width, height, x } = props;
    const y = 5;
    const r = isFirst || isLast ? 2 : 0;

    const rect = {
      width,
      height: height + 9,
      x,
      y: y - 5,
    };

    const getClipPathId = () => {
      const prefix = isSelected ? 'selected' : 'normal';
      if (isFirst) {
        return `url(#${prefix}-left-round-corner)` as const;
      }
      if (isLast) {
        return `url(#${prefix}-right-round-corner)` as const;
      }

      return undefined;
    };

    return (
      <>
        <defs>
          {isSelected && isFirst && (
            <clipPath id="selected-left-round-corner">
              <rect x={rect.x} y={rect.y} width={rect.width + r} height={rect.height} rx={r} ry={r} />
            </clipPath>
          )}
          {isSelected && isLast && (
            <clipPath id="selected-right-round-corner">
              <rect x={rect.x - r} y={rect.y} width={rect.width + r} height={rect.height} rx={r} ry={r} />
            </clipPath>
          )}
          {!isSelected && isFirst && (
            <clipPath id="normal-left-round-corner">
              <rect x={x} y={y} width={width + r} height={height} rx={r} ry={r} />
            </clipPath>
          )}
          {!isSelected && isLast && (
            <clipPath id="normal-right-round-corner">
              <rect x={x - r} y={y} width={width + r} height={height} rx={r} ry={r} />
            </clipPath>
          )}
        </defs>
        {isSelected ? (
          <>
            <rect
              fill={fill}
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              clipPath={getClipPathId()}
              filter="drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.09))"
              cursor={clickable ? 'pointer' : 'default'}
            />
            <rect
              width={rect.width}
              height={rect.height}
              x={rect.x}
              y={rect.y}
              clipPath={getClipPathId()}
              stroke="rgba(0, 0, 0, 0.1)"
              strokeWidth={1}
              fillOpacity={0}
              cursor={clickable ? 'pointer' : 'default'}
            />
          </>
        ) : (
          <rect
            fill={fill}
            x={x}
            y={y}
            width={width}
            height={height}
            clipPath={getClipPathId()}
            cursor={clickable ? 'pointer' : 'default'}
          />
        )}
      </>
    );
  };

const BarLegend = ({ payload }: { payload?: { value: string; color: string }[] }) => (
  <LegendContainer>
    {payload?.map(entry =>
      entry.value === 'background' ? null : (
        <li key={entry.value}>
          <SquareFillIcon width={24} height={24} color={entry.color} />
          {entry.value}
        </li>
      ),
    )}
  </LegendContainer>
);

const TooltipContainer = styled.div`
  position: relative;
  min-width: 230px;
  background: ${({ theme: { colors } }) => colors['bg-gray-main']};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  padding: 12px 16px;
  z-index: 99999999;
  transform: translate(-50%, 5px);

  & > p {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
    margin-bottom: 18px;
  }

  & > span {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-white']};

    & > strong {
      color: ${({ theme: { colors } }) => colors['text-yellow-light']};
    }
  }

  & > dl {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    row-gap: 6px;
    margin: 0px;

    & > dt {
      display: flex;
      align-items: center;
      gap: 11px;
      ${fonts.Body2}
      color: ${({ theme: { colors } }) => colors['text-white']};
    }
    & > dd {
      ${fonts.Headline8}
      color: ${({ theme: { colors } }) => colors['text-white']};
      margin: 0px;
      text-align: right;

      &.summary {
        color: ${({ theme: { colors } }) => colors['text-yellow-light']};
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    width: 10px;
    height: 10px;
    background: ${({ theme: { colors } }) => colors['bg-gray-main']};
    transform: rotate(45deg);
  }
`;

const LegendContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 38px;
  transform: translate(0px, 10px);

  & > li {
    display: flex;
    align-items: center;
    gap: 4px;
    ${fonts.Body2}
  }
`;
