import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';

export const ExpiredPasswordResetLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickStoreBtn = () => {
    navigate('/store/softwares');
  };

  return (
    <Container>
      <UserProfile />
      <Description>{t('Password_Change_04')}</Description>
      <Button variant="contain" size="extraLarge" paddingHorizontal={24} onClick={handleClickStoreBtn}>
        {t('Ad_Cart_07')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - var(--employee-gnb-height));
  width: 100%;
  padding-top: 140px;
`;

const Description = styled.div`
  ${fonts.Headline4};
  margin-top: 40px;
  margin-bottom: 24px;
`;

const UserProfile = () => {
  return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="70" fill="#FAFBFC" />
      <circle cx="70" cy="70.125" r="57" fill="#F1F3F5" />
      <g filter="url(#filter0_dd_90_2477)">
        <circle cx="70" cy="70.25" r="44" fill="#D1D5DA" />
      </g>
      <g filter="url(#filter1_d_90_2477)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6182 102.079C40.483 101.24 41.9313 100.092 43.7489 99.3129C45.3123 98.6429 47.734 97.9997 49.9906 97.4004C52.1543 96.8258 54.1662 96.2915 55.1239 95.8125C55.3039 95.7225 55.4822 95.6346 55.6586 95.5477C58.0534 94.3675 60.0746 93.3715 60.8114 89.6875C61.2489 87.5 61.103 84.2917 60.8114 83.5625C60.2025 82.0403 59.5355 81.3871 58.6165 80.4872C58.479 80.3526 58.3358 80.2124 58.1864 80.0629C57.3343 79.2108 55.6524 76.2838 55.5649 73.7069C55.5604 73.5744 55.5095 73.4473 55.4132 73.3562C55.2749 73.2253 55.1191 73.0853 54.9516 72.9348C54.0018 72.0812 52.6772 70.8909 52.0614 69.125C51.1638 66.5513 51.6531 62.7755 53.1467 62.1805C53.459 62.0562 53.7736 61.7883 53.7208 61.4563C53.6891 61.257 53.6529 61.0458 53.6148 60.8239C53.3694 59.3919 53.048 57.5176 53.3739 55.5625C53.7406 53.362 54.5296 49.2723 57.7489 46.375C62.1239 42.4375 68.2489 42.4375 69.1239 42.4375C69.4608 42.4375 70.1807 42.5673 71.0659 42.7269C72.4794 42.9817 74.3146 43.3125 75.6864 43.3125C77.3826 43.3125 78.4651 43.1371 79.1377 42.9904C79.4075 42.9315 79.6782 43.0922 79.7451 43.3601L80.452 46.1875C80.4822 46.3081 80.5567 46.4124 80.6575 46.4852C81.5087 47.099 82.5738 48.229 83.5614 49.875C84.7904 51.9233 86.0194 57.0408 85.4524 61.2753C85.4014 61.6558 85.7814 61.9502 86.1578 62.0257C86.9148 62.1776 87.3845 63.2167 87.0658 66.4557C87.0629 66.4844 87.0575 66.5138 87.0498 66.5417C86.9689 66.8372 86.9025 67.1466 86.8327 67.4717C86.5003 69.0196 86.0915 70.9234 83.6755 73.3852C83.6025 73.4596 83.5525 73.5551 83.534 73.6577C83.3628 74.607 82.9099 76.553 82.2489 77.875C81.7364 78.8999 80.6238 80.2247 79.7018 81.3225C79.0495 82.0992 78.4927 82.7623 78.3114 83.125C77.8739 84.0004 77.4364 87.0625 77.8739 89.25C78.1364 90.5625 80.4989 94.5 82.6864 95.375C85.2124 96.3854 88.5939 97.1761 91.319 97.8133C93.0815 98.2254 94.5694 98.5733 95.3739 98.875C97.9032 99.8235 99.0616 101.229 100.005 102.431C92.1448 109.763 81.5955 114.25 69.9982 114.25C58.2164 114.25 47.5161 109.619 39.6182 102.079Z"
          fill="#FAFBFC"
        />
      </g>
      <g filter="url(#filter2_d_90_2477)">
        <g filter="url(#filter3_dd_90_2477)">
          <circle cx="102" cy="99" r="12" fill="url(#paint0_linear_90_2477)" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.751 95.0144C107.937 95.2003 107.937 95.5016 107.751 95.6875L100.453 102.986C100.362 103.076 100.239 103.126 100.111 103.125C99.9829 103.124 99.8608 103.071 99.7723 102.978L96.2819 99.329C96.1002 99.139 96.1069 98.8377 96.2969 98.6561C96.4868 98.4744 96.7881 98.481 96.9698 98.671L100.124 101.968L107.078 95.0144C107.264 94.8285 107.565 94.8285 107.751 95.0144Z"
          fill="white"
        />
        <path
          d="M106.866 94.8023C107.169 94.4993 107.66 94.4993 107.963 94.8023C108.266 95.1053 108.266 95.5966 107.963 95.8997L100.665 103.198C100.517 103.345 100.317 103.427 100.108 103.425C99.8991 103.423 99.7 103.336 99.5557 103.185L96.0653 99.5364C95.7691 99.2267 95.78 98.7355 96.0897 98.4393C96.3994 98.143 96.8906 98.154 97.1868 98.4636L100.129 101.539L106.866 94.8023Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.069 94.6962C108.431 95.0578 108.431 95.6441 108.069 96.0057L100.771 103.304C100.595 103.48 100.355 103.578 100.106 103.575C99.8571 103.572 99.6196 103.469 99.4474 103.289L95.957 99.64C95.6035 99.2705 95.6166 98.6843 95.9861 98.3308C96.3557 97.9774 96.9418 97.9904 97.2953 98.3599L100.131 101.325L106.76 94.6962C107.121 94.3346 107.708 94.3346 108.069 94.6962Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_90_2477"
          x="23"
          y="23.25"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_90_2477" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_90_2477" result="effect2_dropShadow_90_2477" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_90_2477" result="shape" />
        </filter>
        <filter
          id="filter1_d_90_2477"
          x="33.6182"
          y="37.4375"
          width="72.3867"
          height="83.8125"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_90_2477" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_90_2477" result="shape" />
        </filter>
        <filter
          id="filter2_d_90_2477"
          x="84"
          y="82"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_90_2477" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_90_2477" result="shape" />
        </filter>
        <filter
          id="filter3_dd_90_2477"
          x="87"
          y="84"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_90_2477" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_90_2477" result="effect2_dropShadow_90_2477" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_90_2477" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_90_2477" x1="90" y1="99" x2="114" y2="99" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B93A86" />
          <stop offset="1" stopColor="#6F42C1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
