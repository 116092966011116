import useDynamicInput from '@customHooks/useDynamicInput';
import { AddIcon } from '@icons/AddIcon';
import { DashIcon } from '@icons/DashIcon';
import { XIcon } from '@icons/XIcon';
import { Modal, TextField, useTheme } from '@mui/material';
import { useGetTenant } from '@queryHooks/useTenant';
import { useGetUserProfile, useUpdateUserProfile } from '@queryHooks/useUser';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react';
import { UserGroupSelect } from '@components/userGroupSelect/UserGroupSelect';
import { Button } from '@components/index';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './UserEditStyles';
import { FormUser, useUserForm } from './validationSchema';

export const UserEdit = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uiStore: { userEditStore: modal },
    authStore,
  } = useStore();

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useUserForm();

  const { data: tenant } = useGetTenant(authStore.curTenant.id);
  const { data: member } = useGetUserProfile(modal.userId);
  const { mutate: updateProfile } = useUpdateUserProfile();

  const {
    data: dynamicGroups,
    add,
    handleChangeValue,
    remove,
    init,
  } = useDynamicInput<{ userGroupId: string; name: string }>({
    userGroupId: authStore.managerTenantInfo.id,
    name: authStore.managerTenantInfo.name,
  });

  useEffect(() => {
    if (member) {
      resetField('name', { defaultValue: member.name });
      resetField('phone', { defaultValue: member.phone });
      if (member.userGroups.length) {
        const initData = member.userGroups.map(group => {
          return {
            userGroupId: group.userGroupId,
            name: group.name,
          };
        });
        init(initData);
      }
    }
  }, [init, member, resetField]);

  const validation = {
    success: (data: FormUser) => {
      updateProfile(
        {
          id: member?.id as string,
          name: data.name,
          phone: data.phone,
          userGroupIdList: dynamicGroups.map(selected => selected.userGroupId),
        },
        {
          onSuccess: () => {
            modal.close();
          },
        },
      );
    },
  };

  const handleSeleted = (idx: number) => (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.currentTarget.dataset.name !== undefined && e.currentTarget.dataset.userGroupId !== undefined) {
      handleChangeValue(
        {
          name: e.currentTarget.dataset.name,
          userGroupId: e.currentTarget.dataset.userGroupId,
        },
        idx,
      );
    }
  };

  return (
    <Modal open={modal.visible}>
      <S.ModalWrapper onSubmit={handleSubmit(validation.success)}>
        <S.Header>
          {t('Member_User_20')}
          <S.ModalCloseBtn size="small" variant="text" onClick={modal.close}>
            <XIcon width={24} height={24} />
          </S.ModalCloseBtn>
        </S.Header>
        <S.Body>
          <S.InfoElement>
            {t('Member_LNB_01')}
            <S.DisableEdit>{tenant?.name}</S.DisableEdit>
          </S.InfoElement>
          <S.InfoElement>
            {t('Member_User_14')}
            <S.DisableEdit>{member?.username}</S.DisableEdit>
          </S.InfoElement>
          <S.InfoElement>
            <S.InputTitle>
              {t('Workflow_Main_05')}
              <span>*</span>
            </S.InputTitle>
            <TextField
              {...register('name')}
              size="medium"
              error={!!errors.name?.message}
              helperText={errors.name?.message}
              autoFocus
            />
          </S.InfoElement>
          <S.InfoElement>
            {t('Member_User_Bulk_Create_18')}
            <div className="groups">
              {dynamicGroups.map((group, idx) => {
                return (
                  <div className="group-item " key={group.id}>
                    <UserGroupSelect
                      selectedValue={{ name: group.name, userGroupId: group.userGroupId }}
                      onSelectChange={handleSeleted(idx)}
                      optionWidth={342}
                    />
                    <div className="add">
                      <Button
                        className="icon-btn"
                        variant="outline"
                        size="large"
                        onClick={() =>
                          add({ userGroupId: authStore.managerTenantInfo.id, name: authStore.managerTenantInfo.name })
                        }
                        disabled={dynamicGroups.length > idx + 1}
                      >
                        <AddIcon
                          width={20}
                          height={20}
                          color={
                            dynamicGroups.length > idx + 1
                              ? theme.colors['ic-gray-light']
                              : theme.colors['ic-gray-main']
                          }
                        />
                      </Button>
                    </div>
                    {idx > 0 && (
                      <div className="delete">
                        <Button className="icon-btn" variant="outline" size="large" onClick={() => remove(idx)}>
                          <DashIcon width={20} height={20} />
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </S.InfoElement>
          <S.InfoElement>
            {t('Member_User_16')}
            <TextField
              {...register('phone')}
              size="medium"
              error={!!errors.phone?.message}
              helperText={errors.phone?.message}
            />
          </S.InfoElement>
        </S.Body>
        <S.Footer>
          <S.BottomButton variant="contain" size="extraLarge" type="submit">
            {t('Member_Group_11')}
          </S.BottomButton>
          <S.BottomButton variant="outline" size="extraLarge" onClick={modal.close}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </S.BottomButton>
        </S.Footer>
      </S.ModalWrapper>
    </Modal>
  );
});
