import { useMutation, useQueryClient } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type IBillingOptimizationRepository from '@repositories/billingOptimizationRepository/IBillingOptimizationRepository';
import type { OptimizeMonthlyBillingRq } from '@repositories/billingOptimizationRepository/Types';
import { subscriptionBillingStatQueryKey } from './useSubscriptionBillingStat';

const repo = repository.get<IBillingOptimizationRepository>(KEYS.BILLING_OPTIMIZATION_REPOSITORY);

export const useOptimizeMonthlyBilling = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data?: OptimizeMonthlyBillingRq) => {
      await repo.optimizeMonthlyBilling(data);
      return data?.subscriptionId;
    },
    onSuccess: subscriptionId => {
      queryClient.invalidateQueries(subscriptionBillingStatQueryKey.bySubscription({ subscriptionId }));
    },
  });
};
