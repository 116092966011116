import APIs from '@repositories/APIs';
import {
  SoftwareYearPlan,
  SoftwareYearPlanCreateRq,
  SoftwareYearPlanFilter,
  SoftwareYearPlanParams,
  YearPlan,
  YearPlanCreateRq,
  YearPlanFilter,
  YearPlanParams,
} from '@type/YearPlan';
import { makeAutoObservable, runInAction } from 'mobx';

class PlanStore {
  private readonly _plans: Map<string, YearPlan>;

  private _plan: YearPlan | null;

  private readonly _softwarePlans: Map<string, SoftwareYearPlan>;

  private _softwarePlan: SoftwareYearPlan | null;

  private _softwarePlanTotalElement: number;

  constructor() {
    this._plans = new Map<string, YearPlan>();
    this._plan = null;
    this._softwarePlans = new Map<string, SoftwareYearPlan>();
    this._softwarePlan = null;
    this._softwarePlanTotalElement = 0;

    makeAutoObservable<PlanStore>(this);
  }

  async fetchSoftwarePlans(queries: SoftwareYearPlanFilter) {
    const result = await APIs.SoftwareYearPlan.getList(queries);
    runInAction(() => {
      this._softwarePlans.clear();
      result.content.forEach(plan => {
        this._softwarePlans.set(plan.software.id, plan);
      });
      this._softwarePlanTotalElement = result.totalElements;
    });
    return result.totalElements;
  }

  async fetchSoftwarePlan(params: SoftwareYearPlanParams) {
    const result = await APIs.SoftwareYearPlan.getOne(params);
    runInAction(() => {
      this._softwarePlan = result;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async createSoftwarePlan(data: SoftwareYearPlanCreateRq) {
    const result = await APIs.SoftwareYearPlan.create(data);
    runInAction(() => {
      this._softwarePlans.set(result.software.id, result);
      this._softwarePlanTotalElement += 1;
    });
    return result;
  }

  async updateSoftwarePlan(params: SoftwareYearPlanParams, data: SoftwareYearPlanCreateRq) {
    const result = await APIs.SoftwareYearPlan.update(params, data);
    runInAction(() => {
      this._softwarePlan = result;
      this._softwarePlans.set(result.software.id, result);
    });
  }

  async deleteSoftwarePlan(params: SoftwareYearPlanParams) {
    await APIs.SoftwareYearPlan.delete(params);
    runInAction(() => {
      this._softwarePlan = null;
    });
  }

  async fetchPlans(queries: YearPlanFilter) {
    const result = await APIs.YearPlan.getList(queries);
    runInAction(() => {
      this._plans.clear();
      result.content.forEach(plan => {
        this._plans.set(plan.year.toString(), plan);
      });
    });
  }

  async fetchPlan(params: YearPlanParams) {
    const result = await APIs.YearPlan.getOne(params);
    runInAction(() => {
      this._plan = result;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async createPlan(data: YearPlanCreateRq) {
    const result = await APIs.YearPlan.create(data);
    runInAction(() => {
      this._plan = result;
    });
    return result;
  }

  async updatePlan(params: YearPlanParams, data: YearPlanCreateRq) {
    const result = await APIs.YearPlan.update(params, data);
    runInAction(() => {
      this._plan = result;
    });
  }

  async deletePlan(params: YearPlanParams) {
    await APIs.YearPlan.delete(params);
    runInAction(() => {
      this._plan = null;
    });
  }

  clearPlan() {
    this._plan = null;
  }

  get plans(): YearPlan[] {
    return Array.from(this._plans.values());
  }

  get plan(): YearPlan {
    return this._plan as YearPlan;
  }

  get softwarePlans(): SoftwareYearPlan[] {
    return Array.from(this._softwarePlans.values());
  }

  get softwarePlan(): SoftwareYearPlan {
    return this._softwarePlan as SoftwareYearPlan;
  }

  get softwarePlanTotalElement(): number {
    return this._softwarePlanTotalElement;
  }
}

const planStore = new PlanStore();

export default planStore;
