import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, MenuItem, Modal, Select, TextField, useTheme } from '@mui/material';
import { Button, Label } from '@components/index';
import authStore from '@stores/AuthStore';
import uiStore from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { ManagerIcon } from '@icons/ManagerIcon';
import { XIcon } from '@icons/XIcon';
import { SubscriptionRequestCreateDTO } from '@repositories/subscriptionRequestRepository';
import { useAddSubscriptionRequest } from '@queryHooks/index';
import { useGetProductPlanList, useGetSoftware } from '@queryHooks/useSoftware';
import { SubscriptionRequestModel } from '@models/index';
import { FormRequest, useItAdminFormOptions } from './validationSchema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  swId: string;
  formData?: SubscriptionRequestModel;
}

export const PurchaseRequestModal = ({ isOpen, onClose, swId, formData }: Props) => {
  const { t } = useTranslation();
  const { data: sw } = useGetSoftware(swId, { enabled: isOpen });
  const { data: plans } = useGetProductPlanList(swId, { enabled: isOpen });

  const { mutate: applySubscription } = useAddSubscriptionRequest();
  const theme = useTheme();
  const { register, handleSubmit, reset, control } = useItAdminFormOptions();

  const validation = {
    success: (data: FormRequest) => {
      const rqData: SubscriptionRequestCreateDTO = {
        subscriptionRequests: [
          {
            softwareId: sw.id,
            inquiry: data.inquiry,
            productPlanId: data.productPlanId,
          },
        ],
        tenantId: authStore.curTenant.id,
      };
      applySubscription(rqData, {
        onSuccess: () => {
          uiStore.toastStore.open({ message: t('Ad_Store_Purchase_Request_Complete') });
          onClose();
        },
      });
    },
  };

  useEffect(() => {
    if (formData) {
      reset({
        softwareId: sw.id,
        inquiry: formData?.inquiry,
        productPlanId: formData.software.id,
      });
    }
  }, [formData, reset, sw.id]);

  const MenuProps = {
    PaperProps: {
      style: {
        width: 424,
        marginTop: 7,
        boxShadow: 'none',
        borderRadius: '0 0 2px 2px',
      },
    },
  };

  return (
    <Modal open onClose={onClose} sx={{ '& .MuiBackdrop-root': { background: 'transparent' } }}>
      <ModalWrapper onSubmit={handleSubmit(validation.success)}>
        <Header>
          {t('Subscrib_Detail_BuyRequest_09')}
          <IconButton onClick={onClose}>
            <XIcon width={24} height={24} color={theme.colors['ic-gray-main']} />
          </IconButton>
        </Header>
        <Body>
          <Element className="info-banner">
            <ManagerIcon width={16} height={16} color={theme.colors['ic-purple-light']} />
            <p>{t('Subscrib_Detail_BuyRequest_10')}</p>
          </Element>
          <Element>
            <strong className="name">
              {t('Subscrib_Detail_UseRequest_07')}
              <span>*</span>
            </strong>
            <div className="software_name">
              <span>{sw.name}</span>
            </div>
          </Element>
          <Element>
            <strong className="name">
              {t('Subscrib_Main_12')}
              <span>*</span>
            </strong>
            <Controller
              control={control}
              name="productPlanId"
              render={({ field: { onChange, value = '' } }) => (
                <Select
                  size="large"
                  fullWidth
                  displayEmpty
                  value={value}
                  onChange={onChange}
                  renderValue={(value: string) => {
                    const findPlanIdInfor = plans?.find(plan => plan.planId === value);
                    return value !== '' ? (
                      <>
                        <SelectedTitle>{findPlanIdInfor?.planName}</SelectedTitle>
                        {findPlanIdInfor?.isInUseByTenant ? (
                          <Label className="usebytenant" variant="contain" color="gradient">
                            {t('Ad_Store_Product_State')}
                          </Label>
                        ) : null}
                      </>
                    ) : (
                      <strong>{t('Product_Use_Request_02')}</strong>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {plans?.map(plan => {
                    return (
                      <SelectItem key={plan.planId} value={plan.planId}>
                        {plan.planName}
                        {plan.isInUseByTenant ? (
                          <Label variant="contain" color="gradient">
                            {t('Ad_Store_Product_State')}
                          </Label>
                        ) : null}
                      </SelectItem>
                    );
                  })}
                </Select>
              )}
            />
          </Element>
          <Element>
            <strong className="name">{t('Acc_Main_22')}</strong>
            <StyledTextField
              {...register('inquiry')}
              multiline
              rows={7}
              placeholder={`${t('Ad_Store_Purchase_Request_01')}`}
            />
          </Element>
        </Body>
        <SubmitButton variant="contain" size="extraLarge" type="submit">
          {t('Ad_Store_Purchase_Request_03')}
        </SubmitButton>
      </ModalWrapper>
    </Modal>
  );
};

const SelectedTitle = styled.strong`
  & ~ span {
    margin-left: 4px;
  }
`;

const ModalWrapper = styled.form`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 480px;
  background-color: ${({ theme: { colors } }) => colors.White};
  border-radius: 6px;
  overflow: hidden;
  z-index: 1050;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04),
    0px 6px 30px 5px rgba(0, 0, 0, 0.05);
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  padding: 18px 20px 16px 28px;
  ${fonts.Headline6};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 4px 28px 0;
  width: 100%;
`;

const Element = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &:first-of-type {
    margin-bottom: 16px;
  }
  &.info-banner {
    align-items: center;
    justify-content: flex-start;
    flex-direction: initial;
    gap: 6px;
    background-color: ${({ theme }) => theme.colors['bg-purple-lighter']};
    border-radius: 3px;
    padding: 12px 20px;
    & > p {
      ${fonts.Body2};
      color: ${({ theme }) => theme.colors['text-purple']};
    }
  }
  & .name {
    ${fonts.Headline8};
    margin-bottom: 6px;
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    & > span {
      margin-left: 2px;
      color: ${({ theme: { colors } }) => colors['ic-red-light']};
    }
  }
  & .software_name {
    padding: 14px 12px;
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
    border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    border-radius: 2px;
    span {
      ${fonts.Body2};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
    }
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
  & .MuiInputBase-root textarea::placeholder {
    ${fonts.Body2};
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
  & .MuiSelect-select strong {
    ${fonts.Button4};
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;
const StyledTextField = styled(TextField)`
  background: ${({ theme }) => theme.colors['bg-gray-lighter']};
  & .MuiInputBase-root {
    padding: 10px 12px 21px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.colors['bg-gradient']};
  border-image-slice: 1;
  border-radius: 0;
`;

const SelectItem = styled(MenuItem)`
  gap: 4px;
  padding: 10px 10px 10px 7p;
`;

const IconButton = styled(Button)`
  width: 24px;
  height: 24px;
`;
