import { Typography, useTheme } from '@mui/material';

import React, { useState } from 'react';
import { useGetUserGroups } from '@queryHooks/index';
import { DepthIcon, TriangleIcon } from '@icons/index';
import { UserGroupSimpleModel } from '@models/userGroupModels/UserGroupSimpleModel';
import * as S from './UserGroupSubItemStyles';

type Props = {
  depth?: number;
  rowData: UserGroupSimpleModel;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selectedUserGroupId: string;
} & React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;

export const UserGroupSubItem = ({
  value,
  rowData: userGroup,
  depth = 1,
  onClick,
  selectedUserGroupId,
  ...props
}: Props) => {
  const theme = useTheme();
  const [isFolded, setIsFolded] = useState<boolean>(depth !== 0);
  const { data: subUserGroups } = useGetUserGroups(
    { tenantId: userGroup.tenantId, parentUserGroupId: userGroup.userGroupId },
    { enabled: !isFolded },
  );
  const handleSubGroupToggleFold = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsFolded(!isFolded);
  };

  const isSelected = userGroup.userGroupId === selectedUserGroupId;

  return (
    <>
      <S.OptionItem
        {...props}
        value={value}
        depth={depth}
        data-user-group-id={userGroup.userGroupId}
        data-name={userGroup.name || '-'}
        onClick={onClick}
        $isSelected={isSelected}
      >
        {userGroup?.subUserGroupCount !== 0 ? (
          <S.ToggleButton variant="outline" type="button" onClick={handleSubGroupToggleFold}>
            {isFolded ? (
              <TriangleIcon
                rotateNum={180}
                width={16}
                height={16}
                color={isSelected ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
              />
            ) : (
              <TriangleIcon
                width={16}
                height={16}
                color={isSelected ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
              />
            )}
          </S.ToggleButton>
        ) : (
          <DepthIcon
            width={16}
            height={16}
            color={isSelected ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']}
          />
        )}
        <Typography
          variant="body2"
          component="span"
          color={isSelected ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
          sx={{
            '&.MuiTypography-root': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {userGroup.name || '-'}
        </Typography>
      </S.OptionItem>
      {!isFolded
        ? subUserGroups?.map(subUserGroup => {
            return (
              <UserGroupSubItem
                rowData={subUserGroup}
                depth={depth + 1}
                key={subUserGroup.userGroupId}
                onClick={onClick}
                value={userGroup.userGroupId}
                selectedUserGroupId={selectedUserGroupId}
              />
            );
          })
        : null}
    </>
  );
};
UserGroupSubItem.displayName = 'UserGroupSubItem';
