const defaultWidth = 24;
const defaultHeight = 24;

export const XIcon = ({ width = defaultWidth, height = defaultHeight, color = '#444D56' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M6.85104 5.64896C6.5191 5.31701 5.9809 5.31701 5.64896 5.64896C5.31701 5.9809 5.31701 6.5191 5.64896 6.85104L10.7979 12L5.64896 17.149C5.31701 17.4809 5.31701 18.0191 5.64896 18.351C5.9809 18.683 6.5191 18.683 6.85104 18.351L12 13.2021L17.149 18.351C17.4809 18.683 18.0191 18.683 18.351 18.351C18.683 18.0191 18.683 17.4809 18.351 17.149L13.2021 12L18.351 6.85104C18.683 6.5191 18.683 5.9809 18.351 5.64896C18.0191 5.31701 17.4809 5.31701 17.149 5.64896L12 10.7979L6.85104 5.64896Z"
          fill={color}
          stroke={color}
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};

XIcon.displayName = 'XIcon';
