import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FileIcon } from '@components/FileIcon';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { CalendarColorIcon } from '@icons/CalendarColorIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { ReceiptColorIcon } from '@icons/ReceiptColorIcon';
import { useGetMonthlyBilling } from '@queryHooks/useMonthlyBilling';
import { dateFormat } from '@utils/dateUtil';
import { numberFormat } from '@utils/numberFormat';
import { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';

type Props = {
  generatedBilling: SubscriptionBillingModel;
  onClose: () => void;
};
export const BillingGenerateResult = ({ generatedBilling, onClose }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { data: monthlyBilling } = useGetMonthlyBilling(generatedBilling.id);

  return (
    <>
      <Content>
        <PeriodLabel>
          <b>{dateFormat(monthlyBilling?.month, '-', t('DateFormat_YM'))}</b> 사용량 기반 정산을 추가했습니다.
        </PeriodLabel>

        <DateInfoWrapper>
          <DateInfo>
            <div>
              <CalendarColorIcon width={16} height={16} />
              {t('Acc_Create_09')}
            </div>
            <b>
              {`${dateFormat(monthlyBilling?.startDate, '-', t('DateFormat_YMD'))} ~ ${dateFormat(
                monthlyBilling?.endDate,
                '-',
                t('DateFormat_YMD'),
              )}`}
            </b>
          </DateInfo>
          <DateInfo>
            <div>
              <ReceiptColorIcon width={16} height={16} />
              {t('Acc_Main_20')}
            </div>
            <b>{`${dateFormat(monthlyBilling?.paymentDate, '-', t('DateFormat_YMD'))}`}</b>
          </DateInfo>
        </DateInfoWrapper>

        <Field>
          <FieldKey>{t('Acc_Main_18')}</FieldKey>
          <FieldValueBox>
            <FieldValue $width="140px">{monthlyBilling?.originalCurrencyUnit}</FieldValue>
            <FieldValue>
              {monthlyBilling?.originalCost !== undefined ? numberFormat({ num: monthlyBilling?.originalCost }) : '-'}
            </FieldValue>
          </FieldValueBox>
        </Field>

        <Field>
          <FieldKey>{t('Acc_Create_10')}</FieldKey>
          <FieldValue>
            {monthlyBilling?.exchangeRate !== undefined ? numberFormat({ num: monthlyBilling.exchangeRate }) : '-'}
          </FieldValue>
        </Field>

        <Field>
          <FieldKey>{t('Acc_Main_19')}</FieldKey>
          <FieldValueBox>
            <FieldValue $width="140px">{monthlyBilling?.currencyUnit}</FieldValue>
            <FieldValue>{monthlyBilling?.cost ? numberFormat({ num: monthlyBilling.cost }) : '-'}</FieldValue>
          </FieldValueBox>
        </Field>

        <Field>
          <FieldKey>첨부 파일</FieldKey>
          {monthlyBilling?.attachments.length === 0 && (
            <EmptyFiles>
              <ErrorIconWrapper>
                <ErrorIcon width={22} height={22} color={colors['ic-purple-light']} />
              </ErrorIconWrapper>
              첨부된 파일이 없습니다.
            </EmptyFiles>
          )}
          {monthlyBilling && monthlyBilling.attachments.length > 0 && (
            <DashedBorder>
              <FileGrid>
                {monthlyBilling?.attachments.map(attachment => (
                  <Attachment key={attachment.fileId}>
                    <FileIcon fileExtension={attachment.fileExtension} width={20} height={20} />
                    <FileName>{attachment.fileName}</FileName>
                    {attachment.fileExtension && `.${attachment.fileExtension}`}
                  </Attachment>
                ))}
              </FileGrid>
            </DashedBorder>
          )}
        </Field>

        <Info>
          <BulletIcon width={6} height={6} color={colors['ic-gray-lighter']} />
          {t('Acc_Create_12')}
        </Info>
      </Content>

      <Footer>
        <Button variant="contain" size="extraLarge" paddingHorizontal={217.5} onClick={onClose}>
          {t('GNB_Profile_MyProfile_12')}
        </Button>
      </Footer>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  padding: 24px 28px 16px 28px;
`;

const Footer = styled.div`
  display: flex;
  padding: 24px 0px 28px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const PeriodLabel = styled.p`
  ${fonts.Body2}

  & b {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-purple']}
  }
`;

const DateInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

const DateInfo = styled.div`
  margin: 12px 0px 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 30px;
  gap: 8px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-purple-light']};
  border-radius: 5px;
  ${fonts.Body2}
  & > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  & > b {
    color: ${({ theme: { colors } }) => colors['text-purple']};
    ${fonts.Headline7}
  }
`;

const Field = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

const FieldKey = styled.label<{ $required?: boolean }>`
  display: block;
  ${fonts.Headline8}
  margin-bottom: 6px;
  ${({ $required }) =>
    $required
      ? css`
          &:after {
            content: '*';
            color: red;
            margin-left: 2px;
          }
        `
      : ''}

  &.disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &:after {
      color: ${({ theme }) => theme.colors['text-red-lighter']};
    }
  }
`;

const FieldValueBox = styled.div`
  display: flex;
  gap: 6px;
`;

const FieldValue = styled.div<{ $width?: string }>`
  display: inline-block;
  width: ${({ $width }) => $width ?? '100%'};
  height: 40px;
  padding: 9px 12px;
  ${fonts.Body2}
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-50']};
  gap: 6px;
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const DashedBorder = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  padding: 16px 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
`;

const FileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 10px;
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  min-width: 0px;
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  padding: 9px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const FileName = styled.div`
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmptyFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  height: 122px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  ${fonts.Body2}
`;

const ErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
`;
