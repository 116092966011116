import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';

export const TableTemplate = ({ children }: { children: React.ReactNode }) => {
  const childArray = React.Children.toArray(children) as (React.ReactNode & { type: React.ComponentType })[];
  const header = childArray.find(({ type }) => type.displayName === TableTemplate.Header.displayName);
  const body = childArray.find(({ type }) => type.displayName === TableTemplate.Body.displayName);
  const footer = childArray.find(({ type }) => type.displayName === TableTemplate.Footer.displayName);

  return (
    <BackgroundWrapper>
      {header}
      {body}
      {footer}
    </BackgroundWrapper>
  );
};
TableTemplate.displayName = 'TableTemplate';

const BackgroundWrapper = styled(Box)`
  box-shadow: ${({ theme: { isUserConsole } }) => (isUserConsole ? 'unset' : '0px 5px 20px 0px #0000000d')};
  background: ${({ theme: { isUserConsole, colors } }) => (isUserConsole ? 'transparent' : colors.White)};
  border-radius: 16px;
`;

const TableToolbarWrapper = styled(Box)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px 0px 20px;
`;
const Header: React.FC<{
  spaceBetween?: boolean;
  alignRight?: boolean;
  children: React.ReactNode;
}> = ({ spaceBetween, alignRight, children }) => {
  const justifyContent = spaceBetween ? 'space-between' : alignRight ? 'flex-end' : 'unset';
  return <TableToolbarWrapper sx={{ justifyContent }}>{children}</TableToolbarWrapper>;
};
TableTemplate.Header = Header;
TableTemplate.Header.displayName = 'TableTemplate.Header';

const TableBodyWrapper = styled(Box)`
  // padding: 20px 20px 0px 20px;
  overflow: auto;
  /* min-height: 336px; */
  border-radius: 16px;
`;
const Body: React.FC<{ disableMinHeight?: boolean; children: React.ReactNode }> = ({
  disableMinHeight = false,
  children,
}) => {
  return <TableBodyWrapper sx={{ minHeight: disableMinHeight ? '0px' : '336px' }}>{children}</TableBodyWrapper>;
};
TableTemplate.Body = Body;
TableTemplate.Body.displayName = 'TableTemplate.Body';

// eslint-disable-next-line react/jsx-no-useless-fragment
const Footer: React.FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>;
TableTemplate.Footer = Footer;
TableTemplate.Footer.displayName = 'TableTemplate.Footer';
