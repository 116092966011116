const defaultWidth = 12;
const defaultHeight = 12;

export const PlayIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00039 1.25756C3.38127 1.25756 1.25805 3.38078 1.25805 5.9999C1.25805 8.61903 3.38127 10.7422 6.00039 10.7422C8.61951 10.7422 10.7427 8.61903 10.7427 5.9999C10.7427 3.38078 8.61951 1.25756 6.00039 1.25756ZM0.400391 5.9999C0.400391 2.90711 2.9076 0.399902 6.00039 0.399902C9.09319 0.399902 11.6004 2.90711 11.6004 5.9999C11.6004 9.0927 9.09319 11.5999 6.00039 11.5999C2.9076 11.5999 0.400391 9.0927 0.400391 5.9999Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.59961 7.79216V4.20799C4.59961 3.93109 4.90595 3.76384 5.13887 3.91358L7.92656 5.70567C8.14087 5.84344 8.14087 6.15672 7.92656 6.29449L5.13887 8.08657C4.90595 8.23631 4.59961 8.06907 4.59961 7.79216Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

PlayIcon.displayName = 'PlayIcon';
