import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  width: 1200px;
  height: 900px;
  background-color: ${({ theme }) => theme.colors.White};
  border: 2px solid #fff;
  border-radius: 16px;
  overflow: auto;
`;

export const ModalTitleWrapper = styled(Box)`
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Line};
  padding: 0px 24px;
`;

export const ModalContentWrapper = styled(Box)`
  padding: 16px 24px;
  min-height: 752px;
`;

export const ContentElementWrapper = styled(Box)`
  display: flex;
  height: 68px;
  gap: 40px;
  margin: 0px 0px 24px 0px;
`;

export const ContentFullWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 68px;
  margin: 0px 0px 24px 0px;
  gap: 12px;
`;

export const ContentTableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 24px 0px;
  gap: 12px;
`;

export const ContentElement = styled(Box)`
  width: 556px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DateElement = styled(Box)`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 24px;
  gap: 16px;
  height: 88px;
`;
