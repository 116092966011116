import { Trans, useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { useGetCategories } from '@queryHooks/useCategory';
import { useGetMySubscriptions } from '@queryHooks/useSubscription';
import * as S from './SubscribingListStyles';

export const SubscribingList = () => {
  const { t } = useTranslation();
  const { authStore } = useStore();

  const { data: mySubscriptions } = useGetMySubscriptions();
  const { data: rootCategories, isFetching } = useGetCategories({ isRootCategory: true });

  const supportsCustomCategory = process.env.REACT_APP_CUSTOM_ROOT_CATEGORY?.toLowerCase() === 'true';
  const tabList =
    supportsCustomCategory && rootCategories
      ? rootCategories.content.map(category => ({ name: category.name, path: category.id }))
      : [
          { name: t('Subscrib_Detail_Overview_11'), path: 'software' },
          { name: t('Subscrib_Detail_Overview_12'), path: 'hardware' },
        ];

  if (isFetching) return <CircularProgress />;

  return (
    <S.Container>
      <S.Header>
        <p>
          <Trans
            i18nKey="Subscribing_List_01"
            values={{ Name: authStore.name, number: mySubscriptions?.totalElements }}
            components={{ 1: <strong />, 2: <br />, 3: <strong className="count" /> }}
          />
        </p>
      </S.Header>
      <S.Body>
        <S.StyledRoutingTabs className="subscription_tab" tabList={tabList} variant="contain" />
        <Outlet />
      </S.Body>
    </S.Container>
  );
};
