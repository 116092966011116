import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { KEYS, repository } from '@repositories/Repository';
import type IProductConnectorRepository from '@repositories/productConnectorRepository/IProductConnectorRepository';
import type { ProductConnectorFilter } from '@repositories/productConnectorRepository/Types';
import type { Page } from '@type/Page';
import { ProductConnectorListModel } from '@models/connection/ProductConnectorListModel';
import { ProductConnectorModel } from '@models/connection/ProductConnectorModel';

const pdConnRepo = repository.get<IProductConnectorRepository>(KEYS.PRODUCT_CONNECTOR_REPOSITORY);

const connectorKeys = {
  all: ['productConnector'] as const,
  lists: () => [...connectorKeys.all, 'list'] as const,
  list: (queries: ProductConnectorFilter) => [...connectorKeys.lists(), { ...queries }] as const,
  details: () => [...connectorKeys.all, 'detail'] as const,
  detail: (connectorId: string) => [...connectorKeys.details(), connectorId] as const,
};

export const useGetProductConnectorList = (
  queries: ProductConnectorFilter,
  options?: UseQueryOptionsType<Page<ProductConnectorListModel>>,
) => {
  return useQuery({
    queryKey: connectorKeys.list(queries),
    queryFn: async () => {
      const result = await pdConnRepo.getConnectorList(queries);
      return {
        ...result,
        content: result.content.map(dto => new ProductConnectorListModel(dto)),
      };
    },
    ...options,
  });
};

export const useGetProductConnector = (connectorId: string, options?: UseQueryOptions<ProductConnectorModel>) => {
  return useQuery({
    queryKey: connectorKeys.detail(connectorId),
    queryFn: async () => {
      const result = await pdConnRepo.getConnector(connectorId);
      return new ProductConnectorModel(result);
    },
    ...options,
  });
};

export const useGetProductConnectorsBySubscription = (
  subscriptionId: string,
  options?: UseQueryOptionsType<Page<ProductConnectorModel>>,
) => {
  const result = useQuery<Page<ProductConnectorModel>, AxiosError>(
    ['productConnector', subscriptionId],
    async () => {
      const productConnectors = await pdConnRepo.getConnectorsBySubscriptionId(subscriptionId);
      return {
        ...productConnectors,
        content: productConnectors.content.map(connector => new ProductConnectorModel(connector)),
      };
    },
    {
      ...options,
      enabled: !!subscriptionId && (options?.enabled !== undefined ? options.enabled : true),
    },
  );

  return result;
};
