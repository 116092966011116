import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { Dialog } from '@components/index';
import { ChevronIcon } from '@icons/ChevronIcon';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';
import { DetailSetting } from './content';
import { SubscriptionList } from './content/subscriptionList/SubscriptionList';

export enum DetailSettingStep {
  SELECT_MONTH = 0,
  GENERATE = 10,
  GENERATE_ATTACH_FILE = 11,
  GENERATE_RESULT = 12,
  CREATE = 20,
  CREATE_ATTACH_FILE = 21,
}

type PageState =
  | {
      page: 'SUBSCRIPTION_LIST';
    }
  | {
      page: 'DETAIL_SETTING';
      step: DetailSettingStep;
      subscriptionId: string;
    };

type Props = {
  open: boolean;
  onClose: () => void;
  subscriptionId?: string;
};
export const AddExistingBillingModal = ({ open, onClose, subscriptionId }: Props) => {
  const { t } = useTranslation();
  const defaultPageState: PageState = subscriptionId
    ? { page: 'DETAIL_SETTING', subscriptionId, step: DetailSettingStep.SELECT_MONTH }
    : { page: 'SUBSCRIPTION_LIST' };
  const [pageState, setPageState] = useState<PageState>(defaultPageState);

  const handleSubscriptionClick = (model: SubscriptionListModel) => {
    setPageState({ page: 'DETAIL_SETTING', subscriptionId: model.id, step: DetailSettingStep.SELECT_MONTH });
  };

  const renderTitle = () => {
    switch (pageState.page) {
      case 'DETAIL_SETTING':
        return pageState.step === DetailSettingStep.GENERATE_ATTACH_FILE ||
          pageState.step === DetailSettingStep.CREATE_ATTACH_FILE ||
          pageState.step === DetailSettingStep.GENERATE_RESULT ? (
          '정산 추가 완료'
        ) : subscriptionId ? (
          t('Acc_Create_01')
        ) : (
          <TitleWithGoBack
            title={t('Acc_Create_07')}
            onClick={() => {
              setPageState(
                pageState.step === DetailSettingStep.SELECT_MONTH
                  ? { page: 'SUBSCRIPTION_LIST' }
                  : {
                      ...pageState,
                      step:
                        pageState.step === DetailSettingStep.GENERATE || pageState.step === DetailSettingStep.CREATE
                          ? DetailSettingStep.SELECT_MONTH
                          : pageState.step - 1,
                    },
              );
            }}
          />
        );
      default:
        return t('Acc_Create_01');
    }
  };

  const handleNextStep = (nextStep: DetailSettingStep) => {
    setPageState(prev => ({ ...prev, step: nextStep }));
  };

  const renderContent = () => {
    switch (pageState.page) {
      case 'DETAIL_SETTING':
        return (
          <DetailSetting
            subscriptionId={pageState.subscriptionId}
            onClose={handleClose}
            step={pageState.step}
            onNextStep={handleNextStep}
          />
        );
      default:
        return <SubscriptionList onSubscriptionClick={handleSubscriptionClick} />;
    }
  };

  const handleClose = () => {
    onClose();
    setPageState(defaultPageState);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={renderTitle()}
      size="large"
      height={
        pageState.page === 'SUBSCRIPTION_LIST'
          ? 748
          : pageState.step === DetailSettingStep.CREATE ||
            pageState.step === DetailSettingStep.CREATE_ATTACH_FILE ||
            pageState.step === DetailSettingStep.GENERATE_RESULT
          ? 992
          : 902
      }
      useCustomContent
    >
      <ErrorBoundary>{renderContent()}</ErrorBoundary>
    </Dialog>
  );
};

type TitleProps = {
  title: string;
  onClick: () => void;
};
const TitleWithGoBack = ({ title, onClick }: TitleProps) => {
  return (
    <TitleContainer>
      <IconButton onClick={onClick}>
        <ChevronIcon rotateNum={270} />
      </IconButton>
      {title}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
