const defaultWidth = 16;
const defaultHeight = 16;

export const DropdownIcon = ({ width = defaultWidth, height = defaultHeight, color = '#6A737D' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          d="M4.42675 7.42678L7.82323 10.8232C7.92086 10.9209 8.07915 10.9209 8.17678 10.8232L11.5732 7.42678C11.7307 7.26928 11.6192 7 11.3964 7H4.60353C4.3808 7 4.26926 7.26929 4.42675 7.42678Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
