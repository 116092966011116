import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddTenant, useGetTenantList } from '@queryHooks/useTenant';
import { TenantCreateRq } from '@repositories/tenantRepository';
import * as S from './TenantCreateStyles';

const schema = yup
  .object({
    name: yup.string().required('* 테넌트 이름을 입력해주세요'),
    tenantCode: yup.string().required('테넌트 코드를 입력해주세요'),
    parentId: yup.string().notRequired(),
    description: yup.string().notRequired(),
    nip_tenant_code: yup.string().notRequired(),
    nip_product_code: yup.string().notRequired(),
  })
  .required();

type Step = { label: string };

export const TenantCreate = observer(() => {
  const { uiStore } = useStore();
  const [activeStep, setActiveStep] = useState<number>(0);
  const currencyUnitOptions: CurrencyUnit[] = ['EUR', 'KRW', 'USD'];
  const { data: allTenants, isLoading: isAllTenantsLoading } = useGetTenantList(
    { isRootTenant: false },
    { enabled: uiStore.isVisibleTenantCreateModal },
  );
  const { mutate: addTenant, isLoading: isAddTenantLoading } = useAddTenant();

  const handleNext = () => {
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleCloseModal = () => {
    reset();
    uiStore.closeTenantCreateModal();
  };

  // hook-form
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const validateHandler = {
    success: async (data: any) => {
      try {
        const rqData: TenantCreateRq = {
          name: data.name,
          description: data.description,
          tenantCode: data.tenantCode,
          parentId: data.parentId,
          properties: {
            nip_tenant_code: data.nip_tenant_code,
            nip_product_code: data.nip_product_code,
          },
          companyId: data.company?.id,
          currencyUnit: data.currencyUnit,
        };
        addTenant(rqData);
        setActiveStep(0);
        handleCloseModal();
      } catch (error) {
        console.error('테넌트 생성 실패', error);
      }
    },

    fail: (err: unknown) => {
      console.error('err', err);
      handleBack();
    },
  };

  return (
    <S.DialogContainer open={uiStore.isVisibleTenantCreateModal} onClose={handleCloseModal}>
      <S.TitleWrapper>
        <Typography variant="h4">Create Tenant</Typography>
      </S.TitleWrapper>
      <S.ContentWrapper>
        <form onSubmit={handleSubmit(validateHandler.success, validateHandler.fail)}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Basic</StepLabel>
              <StepContent>
                <S.TenantName
                  {...register('name')}
                  autoComplete="off"
                  label="Tenant Name"
                  helperText={errors.name?.message as string}
                  fullWidth
                />
                <Controller
                  name="parentId"
                  control={control}
                  render={({ field: { onChange, ref } }) => (
                    <S.ParentTenant
                      ref={ref}
                      onChange={(_, data) => onChange(data?.id)}
                      loading={isAllTenantsLoading}
                      options={allTenants?.content ?? []}
                      isOptionEqualToValue={(op, val) => op.id === val.id}
                      getOptionLabel={tenant => `${tenant.tenantCode} - ${tenant.name}`}
                      renderInput={params => <TextField {...params} label="Parent Tenant" />}
                    />
                  )}
                />
                <S.TenantCode
                  {...register('tenantCode')}
                  autoComplete="off"
                  label="Tenant Code"
                  helperText={errors.tenantCode?.message as string}
                  fullWidth
                />
                <S.Description
                  {...register('description')}
                  autoComplete="off"
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                />
                <Controller
                  name="company"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <S.Company
                      fullWidth
                      value={value ?? null}
                      onChange={(_, value) => onChange(value)}
                      label="Company"
                    />
                  )}
                />
                <Controller
                  name="currencyUnit"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={currencyUnitOptions}
                      fullWidth
                      value={value}
                      blurOnSelect
                      onChange={(_, value) => onChange(value)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          label="CurrencyUnit" // Add label here
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
                <S.ContinueBtnWrapper>
                  <Button onClick={handleNext} variant="outlined">
                    Continue
                  </Button>
                </S.ContinueBtnWrapper>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Additional Properties</StepLabel>
              <StepContent>
                <S.NIPTenantCode
                  {...register('nip_tenant_code')}
                  autoComplete="off"
                  label="NIP Tenant Code"
                  fullWidth
                />
                <S.NIPProductCode
                  {...register('nip_product_code')}
                  autoComplete="off"
                  label="NIP Product Code"
                  fullWidth
                />
              </StepContent>
              <S.BottomBtnsWrapper activestep={activeStep}>
                <Button onClick={handleBack} variant="outlined" sx={{ mr: '15px' }}>
                  Back
                </Button>
                <Button type="submit" variant="outlined" disabled={isAddTenantLoading}>
                  Create
                </Button>
              </S.BottomBtnsWrapper>
            </Step>
          </Stepper>
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              zIndex: '1',
              visibility: `${isAddTenantLoading ? 'visible' : 'hidden'}`,
            }}
          >
            <CircularProgress />
          </Box>
        </form>
      </S.ContentWrapper>
    </S.DialogContainer>
  );
});
