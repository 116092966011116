const defaultWidth = 12;
const defaultHeight = 12;

export const WizardIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15896 4.04622L1.89998 9.3052L2.66601 10.0712L7.92499 4.81225L7.15896 4.04622ZM7.53752 3.25517C7.3748 3.09245 7.08888 3.11456 6.89889 3.30455L1.15831 9.04513C0.968321 9.23512 0.946215 9.52104 1.10893 9.68376L2.28744 10.8623C2.45016 11.025 2.73609 11.0029 2.92607 10.8129L8.66666 5.07232C8.85665 4.88233 8.87875 4.5964 8.71603 4.43368L7.53752 3.25517Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.90227 4.89043C5.73955 4.72771 5.63919 4.56425 5.67812 4.52532L6.85418 3.34926C6.8931 3.31034 1.15877 9.15877 1.32149 9.32149L2.5 10.5C2.66272 10.6627 8.66087 5.0781 8.62195 5.11703L7.44588 6.29309C7.40696 6.33201 7.2435 6.23166 7.08078 6.06894L5.90227 4.89043Z"
          fill={color}
        />
        <path
          d="M4.03211 2.46789L4.5 3.5L4.96789 2.46789L6 2L4.96789 1.53211L4.5 0.5L4.03211 1.53211L3 2L4.03211 2.46789Z"
          fill={color}
        />
        <path
          d="M9.78211 2.21789L10.25 3.25L10.7179 2.21789L11.75 1.75L10.7179 1.28211L10.25 0.25L9.78211 1.28211L8.75 1.75L9.78211 2.21789Z"
          fill={color}
        />
        <path
          d="M10.0321 7.46789L10.5 8.5L10.9679 7.46789L12 7L10.9679 6.53211L10.5 5.5L10.0321 6.53211L9 7L10.0321 7.46789Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

WizardIcon.displayName = 'WizardIcon';
