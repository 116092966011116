import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, TableCell, TableRow, Tooltip, useTheme } from '@mui/material';
import { nanoid } from 'nanoid';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PencilIcon } from '@icons/PencilIcon';
import { TrashIcon } from '@icons/TrashIcon';
import { useUpdateTenantMemberRole } from '@queryHooks/useTenant';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import { ManagerChangeModal } from './ManagerChangeModal';

interface Props {
  rowData: TenantMemberSimpleModel;
}

export const ManagerTableRow = ({ rowData: member }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    authStore,
    uiStore: { alertStore: modal },
  } = useStore();

  const { mutate: removeAdminRole } = useUpdateTenantMemberRole();

  const handleClickRoleRemoveBtn = () => {
    removeAdminRole({ userId: member.id, tenantId: authStore.managerTenantInfo.id, memberRole: 'USER' });
  };

  const handleChangeModal = {
    open: () => {
      setIsModalOpen(true);
    },
    close: () => {
      setIsModalOpen(false);
    },
  };

  return (
    <>
      <TableRow>
        <StyledTableCell>{member.name}</StyledTableCell>
        <StyledTableCell>{member.username}</StyledTableCell>
        <StyledTableCell>{authStore.managerTenantInfo.name}</StyledTableCell>
        <StyledTableCell>
          {member.usergroups.length > 0 ? (
            <>
              {member.usergroups[0]}&nbsp;
              {member.usergroups.length > 1 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        width: '210px',
                        border: '1px solid #e1e4e8',
                        backgroundColor: '#fff',
                        padding: '6px',
                      },
                      '& .MuiTooltip-arrow': {
                        color: '#fff',
                        '&::before': {
                          border: '1px solid #e1e4e8',
                        },
                      },
                    },
                  }}
                  title={
                    <>
                      {member.usergroups.map((usergroup, idx) => {
                        if (idx > 0) return <UsergroupOfTooltip key={nanoid()}>{usergroup}</UsergroupOfTooltip>;
                        return null;
                      })}
                    </>
                  }
                  arrow
                >
                  <span className="tooltip-text">
                    {t('GNB_Profile_MyProfile_06', { number: member.usergroups.length - 1 })}
                  </span>
                </Tooltip>
              )}
            </>
          ) : (
            '-'
          )}
        </StyledTableCell>
        <StyledTableCell>{member.phone}</StyledTableCell>
        <StyledTableCell>{member.managerStr}</StyledTableCell>
        {authStore.isAdmin && (
          <StyledTableCell className="management_cell">
            {member.memberRole === 'SUPER_ADMIN' ? (
              <StyledButton variant="text" size="small" onClick={handleChangeModal.open}>
                <PencilIcon width={18} height={18} color={colors['ic-gray-dark']} />
              </StyledButton>
            ) : (
              <StyledButton
                variant="text"
                size="small"
                onClick={() =>
                  modal.open({
                    type: 'error',
                    title: `${t('Member_Manager_20')}`,
                    confirmName: t('Subscrib_Detail_BuyRequest_12'),
                    message: `${t('Member_Manager_21')}`,
                    useCancel: true,
                    onConfirm: handleClickRoleRemoveBtn,
                  })
                }
              >
                <TrashIcon width={18} height={18} color={colors['ic-red-light']} />
              </StyledButton>
            )}
          </StyledTableCell>
        )}
      </TableRow>

      <ManagerChangeModal isOpen={isModalOpen} isClose={handleChangeModal.close} />
    </>
  );
};

const StyledTableCell = styled(TableCell)`
  &.management_cell {
    text-align: center;
  }
  & > span.tooltip-text {
    ${fonts.Underline2};
    color: ${({ theme: { colors } }) => colors['text-blue-light']};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const UsergroupOfTooltip = styled(Box)`
  display: flex;
  align-items: center;
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  padding: 6px 7px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
`;
