import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { Label } from '@components/Label';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import uiStore from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { useConnect, useDeleteSubscriptionConnection, useDisconnect } from '@queryHooks/useSubscriptionConnection';
import { dateFormat, dateTimeFormat } from '@utils/dateUtil';
import type { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';

interface Props {
  data: SubscriptionConnectionModel;
  idx: number;
}

export const ConnectionRow = ({ data, idx }: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { alertStore },
  } = useStore();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);
  const navigate = useNavigate();
  const { mutate: disconnect } = useDisconnect();
  const { mutate: connect } = useConnect(data.connectionId);
  const { mutate: deleteConnection } = useDeleteSubscriptionConnection(data.subscription.id);

  const handleClickThreeDotIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const handleClickDisconnect = () => {
    disconnect(data.connectionId);
    setMenuAnchor(null);
  };

  const handleClickConnect = () => {
    connect({ subscriptionId: data.connectionId });
    setMenuAnchor(null);
  };

  const handleAlertStore = (status: 'CONNETED' | 'CONNECTING' | 'WORKFLOW') => {
    switch (status) {
      case 'CONNETED':
        alertStore.open({
          title: t('Subscrib_Connect_Create_18'),
          message: t('Connection_Detail_01'),
          confirmName: `${t('Member_User_24')}`,
        });
        break;
      case 'CONNECTING':
        alertStore.open({
          title: t('Subscrib_Connect_Create_18'),
          message: t('Connection_Detail_02'),
          confirmName: `${t('Member_User_24')}`,
        });
        break;
      case 'WORKFLOW':
        alertStore.open({
          title: t('Subscrib_Connect_Create_18'),
          message: '워크플로우가 적용된 구독입니다. \n 워크플로우 액션에서 해당 구독을 모두 삭제해주세요.',
          confirmName: `${t('Member_User_24')}`,
        });
    }
  };

  const handleClickConnectionDeleteBtn = () => {
    setMenuAnchor(null);

    alertStore.open({
      title: t('Subscrib_Connect_06'),
      message: t('Subscrib_Connect_09'),
      useCancel: true,
      type: 'error',
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      onConfirm: () => {
        if (data.workflowCount) {
          handleAlertStore('WORKFLOW');
        } else if (data.status === 'CONNECTED') {
          handleAlertStore('CONNETED');
        } else if (data.status === 'CONNECTING') {
          handleAlertStore('CONNECTING');
        } else {
          deleteConnection(undefined, {
            onSuccess: () => {
              uiStore.toastStore.open({ message: t('Subscrib_Connect_Create_17') });
            },
            onError: (e: unknown) => {
              if (e instanceof AxiosError) {
                if (e.response?.data.errorCode.messageId === 'CONNECTION.ERROR.CANNOT_REMOVE_IN_CURRENT_STATUS') {
                  if (data.status === 'CONNECTED') {
                    handleAlertStore('CONNETED');
                  }
                } else if (
                  e.response?.data.errorCode.messageId === 'CONNECTION.ERROR.CANNOT_REMOVE_WHEN_WORKFLOW_EXIST'
                ) {
                  handleAlertStore('WORKFLOW');
                }
              }
            },
          });
        }
      },
    });
  };

  const handleClickRow = () => {
    navigate(`${data.subscription.id}/connection`);
  };

  const menuStyles = {
    elevation: 0,
    sx: {
      '& .MuiMenu-list': {
        width: '160px',
        borderRadius: '4px',
      },
    },
  };

  return (
    <>
      <StyledRow onClick={handleClickRow} hover>
        <TableCell align="center">{idx + 1}</TableCell>
        <TableCell>
          <App>
            <StyledAvatar src={data.subscription.product.iconUrl} />
            <div className="info">
              <strong className="name">{data.subscription.product.name}</strong>
              <p className="subscription-name">{data.subscription.name}</p>
            </div>
          </App>
        </TableCell>
        <TableCell>
          <span className="info-value">{data.connector?.name ?? '-'}</span>
        </TableCell>
        <TableCell>
          <Label variant="contain" color={data.connector?.usageMethodLabelColor ?? 'green'}>
            {data.connector?.usageCollectMethod}
          </Label>
        </TableCell>
        <TableCell>
          <Status>
            <div className="chip">
              {data.status === 'CONNECTED' && (
                <Tooltip title={t('Subscrib_Connect_Create_20')} arrow placement="bottom-start">
                  <LabelWrap>
                    <Label variant="contain" color="green" role="banner">
                      {t('Subscrib_Connect_Create_05')}
                    </Label>
                  </LabelWrap>
                </Tooltip>
              )}
              {data.status === 'FAILED' && (
                <Tooltip title={t('Subscrib_Connect_Create_21')} arrow placement="bottom-start">
                  <LabelWrap>
                    <Label variant="contain" color="red" role="banner">
                      {t('Subscrib_Connect_Create_06')}
                    </Label>
                  </LabelWrap>
                </Tooltip>
              )}
              {data.status === 'DISCONNECTED' && (
                <Tooltip title={t('Subscrib_Connect_Create_22')} arrow placement="bottom-start">
                  <LabelWrap>
                    <Label variant="contain" color="gray" role="banner">
                      {t('Subscrib_Connect_Create_11')}
                    </Label>
                  </LabelWrap>
                </Tooltip>
              )}
              {data.status === 'CONNECTING' && (
                <Tooltip title={t('Subscrib_Connect_Create_23')} arrow placement="bottom-start">
                  <LabelWrap>
                    <Label variant="contain" color="gradient" role="banner">
                      {t('Subscrib_Connect_Create_19')}
                    </Label>
                  </LabelWrap>
                </Tooltip>
              )}
              {data.status !== 'CONNECTED' && (
                <span>{`(${t('Subscrib_Connect_12')}: ${dateTimeFormat(data.updatedDate)})`}</span>
              )}
            </div>
            <span>{dateTimeFormat(data.createdDate)}</span>
          </Status>
        </TableCell>
        <TableCell>{dateFormat(data.createdDate)}</TableCell>
        <TableCell>{data.workflowCount ?? 0}</TableCell>
        <TableCell align="center">
          <IconWrapper onClick={handleClickThreeDotIcon}>
            <ThreeDotIcon width={18} height={18} />
          </IconWrapper>
        </TableCell>
      </StyledRow>
      <Menu
        open={open}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        PaperProps={menuStyles}
      >
        {data.status !== 'CONNECTED' ? (
          <MenuItem onClick={handleClickConnect}>{t('Subscrib_Connect_11')}</MenuItem>
        ) : (
          <MenuItem onClick={handleClickDisconnect}>{t('Subscrib_Connect_05')}</MenuItem>
        )}
        <MenuItem onClick={handleClickConnectionDeleteBtn}>
          <Delete>{t('Subscrib_Connect_06')}</Delete>
        </MenuItem>
      </Menu>
    </>
  );
};

const LabelWrap = styled.div`
  height: 18px;
`;

const App = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 15px 0 14px;
  & .info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    & .name {
      ${fonts.Headline9};
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
    }
    & .subscription-name {
      ${fonts.Body2}
      color: ${({ theme: { colors } }) => colors['text-gray-main']};
    }
  }
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${fonts.Body4};
  & .chip {
    display: flex;
    gap: 4px;
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  border-radius: 6px;
`;

const IconWrapper = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 0 auto;
  &:hover {
    & svg path {
      fill: ${({ theme }) => theme.colors['ic-purple']};
    }
  }
`;
const Delete = styled.span`
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-red-light']};
`;
