import { useGetSoftware } from '@queryHooks/useSoftware';
import dayjs from 'dayjs';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import * as S from './SummaryStyles';
import { SubscriptionInfo, MonthlyUsers, Usage, Cost, AppInfo } from './slots';

export const Summary = () => {
  const subscription = useSubscriptionModel();
  const { data: software } = useGetSoftware(subscription.software.id, { enabled: !!subscription.software.id });

  const curDayjs = dayjs();

  const monthFrom = curDayjs.add(-1, 'year').format('YYYY-MM');
  const monthTo = curDayjs.format('YYYY-MM');

  return (
    <S.Container>
      <S.Slot>
        <SubscriptionInfo />
      </S.Slot>
      <S.Slot>
        <MonthlyUsers monthFrom={monthFrom} monthTo={monthTo} />
      </S.Slot>
      <S.Slot>
        <Usage monthFrom={monthFrom} monthTo={monthTo} />
      </S.Slot>
      <S.Slot>
        <Cost monthFrom={monthFrom} monthTo={monthTo} />
      </S.Slot>
      {/* <S.Slot className="license">
        <LicenseUsers model={subscription} />
      </S.Slot> */}
      <S.Slot className="app-info">
        <AppInfo software={software} />
      </S.Slot>
    </S.Container>
  );
};
