import API from '@repositories/Axios';
import { Page } from '@type/Page';
import { injectable } from 'inversify';
import IPromotionRepository from './IPromotionRepository';
import { PromotionRs, PromotionCreateRq } from './Types';

@injectable()
export class PromotionRepository implements IPromotionRepository {
  async create(data: PromotionCreateRq, bannerImage?: Blob): Promise<PromotionRs> {
    const formData = new FormData();
    formData.append('promotion', new Blob([JSON.stringify(data)], { type: 'application/json' }));
    if (bannerImage) formData.append('image', bannerImage);
    const result = await API.post<PromotionRs>('/api/v1/promotions', formData);
    return result.data;
  }
  async getList(): Promise<Page<PromotionRs>> {
    const result = await API.get<Page<PromotionRs>>(`/api/v1/promotions`);
    return result.data;
  }
  async delete(index: number): Promise<void> {
    await API.delete(`/api/v1/promotions/${index}`);
  }
}
