const defaultWidth = 24;
const defaultHeight = 24;

export const DashIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <rect x="4" y="10.9" width="16" height="2.2" rx="1.1" fill={color} />
      </g>
    </svg>
  );
};

DashIcon.displayName = 'DashIcon';
