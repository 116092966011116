import { ModuleUsageList } from '@repositories/usage/Types';
import { UsageModel } from './UsageModel';

export class ModuleUsageListModel {
  private readonly _module: string;
  private readonly _usages: UsageModel[];

  constructor(dto?: ModuleUsageList) {
    this._module = dto?.module || '';
    this._usages = dto?.usages.map(usage => new UsageModel(usage)) || [];
  }

  get module(): string {
    return this._module;
  }

  get usages(): UsageModel[] {
    return this._usages;
  }
}
