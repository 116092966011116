import { UserSimpleModel } from '@models/user/UserSimpleModel';
import { UserGroupMember } from '@repositories/userGroupsRepository/Types';

export class UserGroupMemberModel {
  private readonly _user: UserSimpleModel;

  constructor(dto?: UserGroupMember) {
    this._user = new UserSimpleModel(dto?.user);
  }

  get user() {
    return this._user;
  }
}
