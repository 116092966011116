import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { withLogin } from '@hoc/withLogin';
import { Manager } from '../Manager';
import { Cart } from '../common/cart';
import { SignIn } from '../common/sign/signIn/SignIn';
import { Store } from '../common/store';
import { History as BuyApplyHistory } from '../common/store/buyApplyHistory/detail';
import { BuyApplyHistoryList } from '../common/store/buyApplyHistory/list';
import { Products } from '../common/store/products';
import { ProductsInCategory } from '../common/store/products/ProductsInCategory';
import { ProductDetail } from '../common/store/products/productDetail';
import { ManagerLayout } from './ManagerLayout';
import { Billing } from './billing';
import { CostAnalyze } from './billing/analyze/costAnalyze/CostAnalyze';
import { BillingDashboard } from './billing/dashboard/BillingDashboard';
import { SubscriptionBillingDetail, TenantBillingDetail } from './billing/history/detail';
import { BillingList } from './billing/history/list';
import { Home } from './home/Home';
import { PageNotFound } from './pageNotFound';
import { Subscription, SubscriptionDetail, SubscriptionList } from './subscription';
import { BillingInSubscription } from './subscription/detail/billing';
import { ExtraInfo } from './subscription/detail/extraInfo/ExtraInfo';
import { History as SubscriptionHistory } from './subscription/detail/history';
import { License } from './subscription/detail/license';
import { LicenseDetail } from './subscription/detail/license/detail/LicenseDetail';
import { Summary } from './subscription/detail/summary';
import { Usage } from './subscription/detail/usage';
import { User } from './subscription/detail/user';
import { TechnicalSupportDetail } from './subscription/technicalSupport/TechnicalSupportDetail';
import { TechnicalSupportList } from './subscription/technicalSupport/TechnicalSupportList';
import { UsageRegisterDetail } from './subscription/usageRegister/detail';
import { UsageRegisterList } from './subscription/usageRegister/list';
import { SubscriptionUserDetail } from './subscription/userMgmt/detail/SubscriptionUserDetail';
import { SubscriptionUserMgmtList } from './subscription/userMgmt/list/SubscriptionUserMgmtList';
import { UserGroup } from './userGroup';
import { CompanyInfo } from './userGroup/companyInfo';
import { LicenseRetrieve } from './userGroup/licenseRetrieve/LicenseRetrieve';
import { ManagerConfig } from './userGroup/managerConfig';
import { UserGroupMgmt } from './userGroup/userGroupMgmt';
import { UserGroupBatchRegister } from './userGroup/userGroupMgmt/userBatchRegister/UserGroupBatchRegister';
import { UserGroupDetail } from './userGroup/userGroupMgmt/userGroupDetail';
import { Info } from './userGroup/userGroupMgmt/userGroupDetail/info';
import { Members } from './userGroup/userGroupMgmt/userGroupDetail/members';
import { UserMgmt } from './userGroup/userMgmt';
import ActiveUsers from './userGroup/userMgmt/ActiveUsers';
import StopUsers from './userGroup/userMgmt/StopUsers';
import { UserBatchAdd } from './userGroup/userMgmt/userBatchAdd';
import { Workflow } from './workflow';
import { SubscriptionChoice } from './workflow/connectionMgmt/create';
import { ConnectionDetail } from './workflow/connectionMgmt/detail';
import { ConnectionMgmtMgmtList } from './workflow/connectionMgmt/list';
import { WorkflowCreatePage } from './workflow/workflowMgmt/createAndDetail/WorkflowCreatePage';
import { WorkflowDetail } from './workflow/workflowMgmt/detail/WorkflowDetail';
import { WorkflowEditPage } from './workflow/workflowMgmt/detail/WorkflowEditPage';
import { WorkflowExecDetailPage } from './workflow/workflowMgmt/detail/exec/WorkflowExecDetailPage';
import { WorkflowExecListPage } from './workflow/workflowMgmt/detail/exec/WorkflowExecListPage';
import { WorkflowInfo } from './workflow/workflowMgmt/detail/info/WorkflowInfo';
import { WorkflowMgmtList } from './workflow/workflowMgmt/list';

const WithLoginStore = withLogin(Store);

export const managerRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ManagerLayout />}>
      <Route path="/manager/:tenantId" element={<Navigate to="home" replace />} />
      <Route path="/manager/:tenantId/login" element={<SignIn isItAdminPage />} />

      <Route path="/manager/:tenantId" element={<Manager />}>
        <Route path="home" element={<Home />} />
        <Route path="subscriptions/*" element={<Subscription />}>
          <Route path="list" element={<SubscriptionList />} />
          <Route path="list/:subscriptionId" element={<SubscriptionDetail />}>
            <Route path="" element={<Summary />} />
            <Route path="usage" element={<Usage />} />
            <Route path="user" element={<User />} />
            <Route path="license" element={<License />} />
            <Route path="license/:licenseId" element={<LicenseDetail />} />
            <Route path="billing" element={<BillingInSubscription />} />
            <Route path="history" element={<SubscriptionHistory />} />
            <Route path="extra-info" element={<ExtraInfo />} />
          </Route>
          <Route path="user-mgmt" element={<SubscriptionUserMgmtList />} />
          <Route path="user-mgmt/:userId" element={<SubscriptionUserDetail />} />
          <Route path="connections" element={<ConnectionMgmtMgmtList />} />
          <Route path="connections/create" element={<SubscriptionChoice />} />
          <Route path="connections/:subscriptionId/connection" element={<ConnectionDetail />} />
          <Route path="usage-registers" element={<UsageRegisterList />} />
          <Route path="usage-registers/:id" element={<UsageRegisterDetail />} />
          <Route path="technical-support" element={<TechnicalSupportList />} />
          <Route path="technical-support/:technicalInquiryId" element={<TechnicalSupportDetail />} />
          <Route path="*" element={<Navigate to="list" replace />} />
        </Route>
        <Route path="billings/*" element={<Billing />}>
          <Route path="cost" element={<CostAnalyze />} />
          <Route path="dashboard" element={<BillingDashboard />} />
          <Route path="history" element={<BillingList />} />
          <Route path="history/tenant/:month" element={<TenantBillingDetail />} />
          <Route path="history/subscription/:subscriptionBillingId" element={<SubscriptionBillingDetail />} />
          <Route path="*" element={<Navigate to="cost" replace />} />
        </Route>
        <Route path="workflows/*" element={<Workflow />}>
          <Route path="management" element={<WorkflowMgmtList />} />
          <Route path="management/create" element={<WorkflowCreatePage />} />
          <Route path="management/:workflowId" element={<WorkflowDetail />}>
            <Route path="" element={<WorkflowInfo />} />
            <Route path="executions" element={<WorkflowExecListPage />} />
            <Route path="executions/:workflowExecId" element={<WorkflowExecDetailPage />} />
          </Route>
          <Route path="management/:workflowId/edit" element={<WorkflowEditPage />} />
          <Route path="*" element={<Navigate to="management" replace />} />
        </Route>
        <Route path="usergroups/*" element={<UserGroup />}>
          <Route path="companyInfo" element={<CompanyInfo />} />
          <Route path="groupMgmt" element={<UserGroupMgmt />} />
          <Route path="groupMgmt/:id/*" element={<UserGroupDetail />}>
            <Route path="" element={<Info />} />
            <Route path="members" element={<Members />} />
          </Route>
          <Route path="groupMgmt/batch-register" element={<UserGroupBatchRegister />} />
          <Route path="memberMgmt/*" element={<UserMgmt />}>
            <Route path="active" element={<ActiveUsers />} />
            <Route path="stop" element={<StopUsers />} />
            <Route path="*" element={<Navigate to="active" replace />} />
          </Route>
          <Route path="memberMgmt/batch-register" element={<UserBatchAdd />} />
          <Route path="memberMgmt/:userId/license-retrieve" element={<LicenseRetrieve />} />
          <Route path="managerMgmt" element={<ManagerConfig />} />
          <Route path="*" element={<Navigate to="companyInfo" replace />} />
        </Route>
        <Route path="store/*" element={<WithLoginStore />}>
          <Route path="softwares" element={<Products type="Software" key="Software" />} />
          <Route path="hardwares" element={<Products type="Hardware" key="Hardware" />} />
          <Route path="category/:name" element={<ProductsInCategory />} />
          <Route path="product/:id" element={<ProductDetail />} />
          <Route path="apply-histories" element={<BuyApplyHistoryList />} />
          <Route path="apply-histories/:id" element={<BuyApplyHistory />} />
          <Route path="cart" element={<Cart />} />
          <Route path="*" element={<ProductsInCategory />} />
        </Route>
        <Route path="cart" element={<Cart />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);
