import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';

export const Container = styled.form`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height));
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Content = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 130px;
`;

export const PasswordWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 90px;
`;

export const Password = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const InputTitle = styled(Box)`
  ${fonts.Headline8}
`;

export const ConfirmBtn = styled(Button)`
  width: 100%;
  margin-top: 30px;
  background: ${({ theme: { colors } }) => colors['bg-gradient']};
  &:hover {
    background: ${({ theme: { colors } }) => colors['bg-gradient']};
  }
`;

export const PasswordToggleButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: cneter;
  justify-content: center;
  flex-shrink: 0;
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background: none;
    & > svg path {
      fill: ${({ theme }) => theme.colors['ic-purple-light']};
    }
  }
`;
