import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { ConnectionStatus, SubscriptionConnectionDTO } from '@repositories/subscriptionConnectionRepository/Types';
import { parseJSON } from '@utils/CommonUtil';
import { SubscriptionSimpleModel } from '@models/SubscriptionSimpleModel';
import { TenantSimpleModel } from '@models/tenantModels';
import { ConnectionRespModel } from './ConnectionRespModel';
import { ProductConnectorModel } from './ProductConnectorModel';

export class SubscriptionConnectionModel {
  private readonly dto: SubscriptionConnectionDTO;
  private readonly _connection: ConnectionRespModel;
  private readonly _tenant?: TenantSimpleModel;
  private readonly _connector?: ProductConnectorModel;

  // FIXME: dto로 바꾸기
  private readonly _connectionId: string;
  private readonly _status: ConnectionStatus;
  private readonly _statusMessage: string;
  private readonly _subscription: SubscriptionSimpleModel;
  private readonly _remoteConnectionId: string;
  private readonly _createdBy: string;
  private readonly _createdDate: Dayjs;
  private readonly _updatedBy: string;
  private readonly _updatedDate: Dayjs;

  constructor(dto: SubscriptionConnectionDTO) {
    this.dto = dto;

    this._connection = new ConnectionRespModel(dto.connection || {});
    this._connector = dto.connector ? new ProductConnectorModel(dto.connector) : undefined;
    this._tenant = dto.connector ? new TenantSimpleModel(dto.tenant) : undefined;

    this._connectionId = dto.connectionId || '';
    this._status = dto.status || 'DISCONNECTED';
    this._statusMessage = dto.statusMessage || '';
    this._subscription = new SubscriptionSimpleModel(dto.subscription);
    this._remoteConnectionId = dto.remoteConnectionId || '';
    this._createdBy = dto.createdBy || '';
    this._createdDate = dayjs(dto.createdDate);
    this._updatedBy = dto.updatedBy || '';
    this._updatedDate = dayjs(dto.updatedDate);
  }

  get isConnectionExist() {
    return !!this.dto.status;
  }

  get creator() {
    return this.dto.creator;
  }

  get productName() {
    return this.dto.productName;
  }

  get workflowCount() {
    return this.dto.workflowCount;
  }

  get connection() {
    return this._connection;
  }

  get addUserSchemaObject() {
    return parseJSON<UnknownObject>(this.connection.connector.addUserSchema);
  }

  get addUserSchemaUserRoles(): string[] {
    return this.addUserSchemaObject?.properties?.userRole?.enum ?? [];
  }

  get connectionId() {
    return this._connectionId;
  }

  get status() {
    return this._status;
  }

  get statusMessage() {
    return this._statusMessage;
  }

  get subscription() {
    return this._subscription;
  }

  get connector() {
    return this._connector;
  }

  get tenant() {
    return this._tenant;
  }

  get remoteConnectionId() {
    return this._remoteConnectionId;
  }

  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this._createdDate;
  }

  get updatedBy() {
    return this._updatedBy;
  }

  get updatedDate() {
    return this._updatedDate;
  }

  get isConnected() {
    return this.status === 'CONNECTED';
  }

  get isManuallyUserAddable() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'addUser');
  }

  get isManuallyUserRemovable() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'removeUser');
  }

  get isManuallyGetLicense() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'getLicense');
  }

  get isManuallyAddLicense() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'addLicense');
  }

  get isManuallyRemoveLicese() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'removeLicense');
  }

  get isManuallyUserRevokeLicense() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'revokeLicense');
  }

  get isManuallyUserAssignLicense() {
    return !!this.connection.connector.actions.find(({ method }) => method === 'assignLicense');
  }
}
