import 'reflect-metadata';
import type { ISubscriptionUserRepository } from './subscriptionUserRepository/ISubscriptionUserRepository';
import type ITenantMemberRepository from './tenantMemberRepository/ITenantMemberRepository';
import { Container } from 'inversify';
import { SubscriptionCountStatRepository } from './SubscriptionCountStatRepository';
import { BillingOptimizationRepository } from './billingOptimizationRepository/BillingOptimizationRepository';
import { CommonCodeRepository } from './commonCodeRepository';
import { CompanyRepository } from './companyRepository';
import { ExchangeRateRepository } from './exchangeRateRepository/ExchangeRateRepository';
import { FileRepository } from './fileRepository/FileRepository';
import { GoogleOauthRepository } from './googleOauthRepository/GoogleOauthRepository';
import { InquiryRepository } from './inquiryRepository/InquiryRepository';
import { LicenseRepository } from './licenseRepository/LicenseRepository';
import { MonthlyBillingRepository } from './monthlyBillingRepository/MonthlyBillingRepository';
import { OauthRepository } from './oauthRepository/OauthRepository';
import { ProductConnectorRepository } from './productConnectorRepository/ProductConnectorRepository';
import { ProductRequestRepository } from './productRequestRepository';
import { ProductRelationsRepository } from './productsRelationsRepository';
import { PromotionRepository } from './promotionRepository';
import { RecommendationRepository } from './recommendationRepository';
import { SoftwareCategoryRepository } from './softwareCategoryRepository/SoftwareCategoryRepository';
import { SoftwareRepository } from './softwareRepository/SoftwareRepository';
import { SubscriptionBillilngStatRepository } from './subscriptionBillingStatRepository/SubscriptionBillingStatRepository';
import { SubscriptionConnectionRepository } from './subscriptionConnectionRepository/SubscriptionConnectionRepository';
import { SubscriptionRepository } from './subscriptionRepository/SubscriptionRepository';
import { SubscriptionRequestRepository } from './subscriptionRequestRepository/SubscriptionRequestRepository';
import { SubscriptionUseRequestRepository } from './subscriptionUseRequestRepository/SubscriptionUseRequestRepository';
import { SubscriptionUserRepository } from './subscriptionUserRepository/SubscriptionUserRepository';
import { TechnicalSupportRepository } from './technicalSupportRepository/impl/TechnicalSupportRepository';
import { TenantBillingStatRepository } from './tenantBillingStatRepository/TenantBillingStatRepository';
import { TenantMemberRepository } from './tenantMemberRepository/TenantMemberRepository';
import { TenantMonthlyBillingRepository } from './tenantMonthlyBillingRepository/TenantMonthlyBillingRepository';
import { TenantRepository } from './tenantRepository/TenantRepository';
import { TenantRequestRepository } from './tenantRequestRepository/TenantRequestRepository';
import { DailyUsageRepository } from './usage/dailyUsageRepository/DailyUsageRepository';
import { HourlyUsageRepository } from './usage/hourlyUsageRepository/HourlyUsageRepository';
import { MonthlyUsageRepository } from './usage/monthlyUsageRepository/MonthlyUsageRepository';
import { RealtimeUsageRepository } from './usage/realtimeUsageRepository/RealtimeUsageRepository';
import { UserGroupRepository } from './userGroupsRepository/UserGroupRepository';
import { UserMonthlyBillingRepository } from './userMonthlyBillingRepository/UserMonthlyBillingRepository';
import { UserRepository } from './userRepository';
import { UserStatRepository } from './userStatRepository/UserStatRepository';
import { UserSubscriptionStatRepository } from './userSubscriptionStatRepository/UserSubscriptionStatRepository';
import { WorkflowBaseTriggerRepository } from './workflowBaseTriggerRepository/WorkflowBaseTriggerRepository';
import { WorkflowRepository } from './workflowRepository/WorkflowRepository';

export const KEYS = {
  BILLING_OPTIMIZATION_REPOSITORY: Symbol('BillingOptimizationRepository'),
  TENANT_REPOSITORY: Symbol('TenantRepository'),
  TENANT_REQUEST_REPOSITORY: Symbol('TenantRequestRepository'),
  SUBSCRIPTION_REPOSITORY: Symbol('SubscriptionRepository'),
  SUBSCRIPTION_REQUEST_REPOSITORY: Symbol('SubscriptionRequestRepository'),
  SOFTWARE_REPOSITORY: Symbol('SoftwareRepository'),
  USER_REPOSITORY: Symbol('UserRepository'),
  TENANT_MONTHLY_BILLING_REPOSITORY: Symbol('TenantMonthlyBillingRepository'),
  DAILY_USAGE_REPOSITORY: Symbol('DailyUsageRepository'),
  HOURLY_USAGE_REPOSITORY: Symbol('HourlyUsageRepository'),
  REALTIME_USAGE_REPOSITORY: Symbol('RealtimeUsageRepository'),
  MONTHLY_USAGE_REPOSITORY: Symbol('MonthlyUsageRepository'),
  USERGROUP_REPOSITORY: Symbol('UserGroupRepository'),
  USER_MONTHLY_BILLING_REPOSITORY: Symbol('UserMonthlyBillingRepository'),
  PROMOTION_REPOSITORY: Symbol('PromotionRepository'),
  SOFTWARE_CATEGORY_REPOSITORY: Symbol('SoftwareCategoryRepository'),
  RECOMMENDATION_REPOSITORY: Symbol('RecommendationRepository'),
  USER_SUBSCRIPTION_STAT_REPOSITORY_DEPRECATED: Symbol('UserSubscriptionStatRepository'),
  PRODUCT_RELATIONS_REPOSITORY: Symbol('ProductRelationsRepository'),
  PRODUCT_REQUEST_REPOSITORY: Symbol('ProductRequestRepository'),
  COMMON_CODE_REPOSITORY: Symbol('CommonCodeRepository'),
  COMPANY_REPOSITORY: Symbol('CompanyRepository'),
  SUBSCRIPTION_USE_REQUEST: Symbol('SubscriptionUseRequestRepository'),
  MONTHLY_BILLING_REPOSITORY: Symbol('MonthlyBillingRepository'),
  LICENSE_REPOSITORY: Symbol('LicenseRepository'),
  REALTIME_BILLING_REPOSITORY: Symbol('RealtimeBillingRepository'),
  SUBSCRIPTION_CONNECTION_REPOSITORY: Symbol('SubscriptionConnectionRepository'),
  PRODUCT_CONNECTOR_REPOSITORY: Symbol('ProductConnectorRepository'),
  INQUIRY_REPOSITORY: Symbol('InquiryRepository'),
  WORKFLOW_REPOSITORY: Symbol('WorkflowRepository'),
  WORKFLOW_BASE_TRIGGER_REPOSITORY: Symbol('WorkflowBaseTriggerRepository'),
  OAUTH_REPOSITORY: Symbol('OauthRepository'),
  GOOGLE_OAUTH_REPOSITORY: Symbol('GoogleOauthRepository'),
  TENENT_BILLIG_STAT_REPOSITORY: Symbol('TenantBillingStatRepository'),
  TENANT_MEMBER_REPOSITORY: Symbol('TenantMemberRepository'),
  EXCHANGE_RATE_REPOSITORY: Symbol('ExchangeRateRepository'),
  FILE_REPOSITORY: Symbol('FileRepository'),
  USER_STAT_REPOSITORY: Symbol('UserStatRepository'),
  USER_SUBSCRIPTION_STAT_REPOSITORY: Symbol('UserSubscriptionStatRepository'),
  SUBSCRIPTION_USER_REPOSITORY: Symbol('SubscriptionUserRepository'),
  SUBSCRIPTION_BILLING_STAT_REPOSITORY: Symbol('SubscriptionBillingStatRepositry'),
  TECHNICAL_SUPPORT_REPOSITORY: Symbol('TechnicalSupporyRepository'),
};

const _container = new Container();

_container.bind(KEYS.BILLING_OPTIMIZATION_REPOSITORY).to(BillingOptimizationRepository).inSingletonScope();
_container.bind(KEYS.TENANT_REPOSITORY).to(TenantRepository).inSingletonScope();
_container.bind(KEYS.TENANT_REQUEST_REPOSITORY).to(TenantRequestRepository).inSingletonScope();
_container.bind(KEYS.SUBSCRIPTION_REPOSITORY).to(SubscriptionRepository).inSingletonScope();
_container.bind(KEYS.SUBSCRIPTION_REQUEST_REPOSITORY).to(SubscriptionRequestRepository).inSingletonScope();
_container.bind(KEYS.SOFTWARE_REPOSITORY).to(SoftwareRepository).inSingletonScope();
_container.bind(KEYS.USER_REPOSITORY).to(UserRepository).inSingletonScope();
_container.bind(KEYS.TENANT_MONTHLY_BILLING_REPOSITORY).to(TenantMonthlyBillingRepository).inSingletonScope();
_container.bind(KEYS.DAILY_USAGE_REPOSITORY).to(DailyUsageRepository).inSingletonScope();
_container.bind(KEYS.HOURLY_USAGE_REPOSITORY).to(HourlyUsageRepository).inSingletonScope();
_container.bind(KEYS.REALTIME_USAGE_REPOSITORY).to(RealtimeUsageRepository).inSingletonScope();
_container.bind(KEYS.MONTHLY_USAGE_REPOSITORY).to(MonthlyUsageRepository).inSingletonScope();
_container.bind(KEYS.USERGROUP_REPOSITORY).to(UserGroupRepository).inSingletonScope();
_container.bind(KEYS.USER_MONTHLY_BILLING_REPOSITORY).to(UserMonthlyBillingRepository).inSingletonScope();
_container.bind(KEYS.PROMOTION_REPOSITORY).to(PromotionRepository).inSingletonScope();
_container.bind(KEYS.SOFTWARE_CATEGORY_REPOSITORY).to(SoftwareCategoryRepository).inSingletonScope();
_container.bind(KEYS.RECOMMENDATION_REPOSITORY).to(RecommendationRepository).inSingletonScope();
_container
  .bind(KEYS.USER_SUBSCRIPTION_STAT_REPOSITORY_DEPRECATED)
  .to(SubscriptionCountStatRepository)
  .inSingletonScope();
_container.bind(KEYS.PRODUCT_RELATIONS_REPOSITORY).to(ProductRelationsRepository).inSingletonScope();
_container.bind(KEYS.PRODUCT_REQUEST_REPOSITORY).to(ProductRequestRepository).inSingletonScope();
_container.bind(KEYS.COMMON_CODE_REPOSITORY).to(CommonCodeRepository).inSingletonScope();
_container.bind(KEYS.COMPANY_REPOSITORY).to(CompanyRepository).inSingletonScope();
_container.bind(KEYS.SUBSCRIPTION_USE_REQUEST).to(SubscriptionUseRequestRepository).inSingletonScope();
_container.bind(KEYS.MONTHLY_BILLING_REPOSITORY).to(MonthlyBillingRepository).inSingletonScope();
_container.bind(KEYS.LICENSE_REPOSITORY).to(LicenseRepository).inSingletonScope();
_container.bind(KEYS.SUBSCRIPTION_CONNECTION_REPOSITORY).to(SubscriptionConnectionRepository).inSingletonScope();
_container.bind(KEYS.PRODUCT_CONNECTOR_REPOSITORY).to(ProductConnectorRepository).inSingletonScope();
_container.bind(KEYS.INQUIRY_REPOSITORY).to(InquiryRepository).inSingletonScope();
_container.bind(KEYS.WORKFLOW_REPOSITORY).to(WorkflowRepository).inSingletonScope();
_container.bind(KEYS.WORKFLOW_BASE_TRIGGER_REPOSITORY).to(WorkflowBaseTriggerRepository).inSingletonScope();
_container.bind(KEYS.OAUTH_REPOSITORY).to(OauthRepository).inSingletonScope();
_container.bind(KEYS.GOOGLE_OAUTH_REPOSITORY).to(GoogleOauthRepository).inSingletonScope();
_container.bind(KEYS.TENENT_BILLIG_STAT_REPOSITORY).to(TenantBillingStatRepository).inSingletonScope();
_container.bind(KEYS.TENANT_MEMBER_REPOSITORY).to(TenantMemberRepository).inSingletonScope();
_container.bind(KEYS.EXCHANGE_RATE_REPOSITORY).to(ExchangeRateRepository).inSingletonScope();
_container.bind(KEYS.FILE_REPOSITORY).to(FileRepository).inSingletonScope();
_container.bind(KEYS.USER_STAT_REPOSITORY).to(UserStatRepository).inSingletonScope();
_container.bind(KEYS.USER_SUBSCRIPTION_STAT_REPOSITORY).to(UserSubscriptionStatRepository).inSingletonScope();
_container.bind(KEYS.SUBSCRIPTION_USER_REPOSITORY).to(SubscriptionUserRepository).inSingletonScope();
_container.bind(KEYS.SUBSCRIPTION_BILLING_STAT_REPOSITORY).to(SubscriptionBillilngStatRepository).inSingletonScope();
_container.bind(KEYS.TECHNICAL_SUPPORT_REPOSITORY).to(TechnicalSupportRepository).inSingletonScope();

export const repository = _container;

export const tenantMemberRepository = _container.get<ITenantMemberRepository>(KEYS.TENANT_MEMBER_REPOSITORY);
export const subscriptionUserRepository = _container.get<ISubscriptionUserRepository>(
  KEYS.SUBSCRIPTION_USER_REPOSITORY,
);
