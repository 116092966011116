import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { useGetTenantBilling } from '@queryHooks/useBilling';
import { useGetTenant } from '@queryHooks/useTenant';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import { usePrintButton } from '../PrintButton';
import { TenantBillingDetailView } from './TenantBillingDetailView';
import { TenantBillingPrintView } from './TenantBillingPrintView';

export const TenantBillingDetail = () => {
  const { tenantId, month } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const printViewRef = useRef<HTMLDivElement>(null);

  const { printState, setPrintState, onBeforeGetContentResolve, PrintButton } = usePrintButton();

  const { data: tenant } = useGetTenant(tenantId ?? '');
  const {
    data: tenantBilling,
    isLoading,
    isSuccess: isTenantBillingSuccess,
  } = useGetTenantBilling({ id: tenantId ?? '', month: month ?? '' }, { enabled: !!tenantId && !!month });

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubscriptionBillingClick = (subscriptionBilling: SubscriptionBillingModel) => {
    navigate(`../history/subscription/${subscriptionBilling.id}`, { state: { fromTenantBilling: true } });
  };

  const handlePrintStateChange = (state: 'loading' | 'fail' | 'ready') => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      switch (state) {
        case 'loading':
          setPrintState(prev => ({ ...prev, isDataLoading: true }));
          break;
        case 'ready':
          onBeforeGetContentResolve.current();
          break;
        case 'fail':
          setPrintState({ isOpen: false, isDataLoading: true });
          break;
      }
    }
  };

  return (
    <Container>
      <GoBackRow>
        <IconButton onClick={handleBackClick}>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        {t('Acc_Detail_18')}
      </GoBackRow>
      {isLoading && <Loader />}
      {isTenantBillingSuccess && (
        <Content>
          <TenantBillingDetailView
            tenantName={tenant?.name ?? ''}
            tenantBilling={tenantBilling}
            onSubscribtionBillingClick={handleSubscriptionBillingClick}
            PrintButton={
              <PrintButton
                contentRef={printViewRef}
                documentTitle={`${tenant?.name ?? ''}_${dateFormat(tenantBilling.month, '', 'YYYY.MM')}`}
              />
            }
          />
        </Content>
      )}
      {isTenantBillingSuccess && printState.isOpen && (
        <TenantBillingPrintView
          ref={printViewRef}
          tenantName={tenant?.name ?? ''}
          tenantBilling={tenantBilling}
          onPrintStateChange={handlePrintStateChange}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow-y: auto;
`;

const GoBackRow = styled.h2`
  ${fonts.Headline6}
  display: flex;
  align-items: center;
  padding: 28px 32px 0px 32px;
  gap: 4px;
`;

const Content = styled.div`
  padding: 32px 60px 52px;
`;
