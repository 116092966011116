// import { useTheme } from '@emotion/react';
const defaultWidth = 24;
const defaultHeight = 24;

export const DescriptionIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <path d="M6.75 7.5H17.25V9H6.75V7.5Z" fill={color} />
        <path d="M13.5 11.25H6.75V12.75H13.5V11.25Z" fill={color} />
        <path
          d="M4.5 3C3.67157 3 3 3.67157 3 4.5V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V4.5C21 3.67157 20.3284 3 19.5 3H4.5ZM19.5 4.5V19.5H4.5L4.5 4.5H19.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9998 7.2998V9.1998H6.99976V7.2998H16.9998ZM6.99976 11.0498H12.9998V12.9498H6.99976V11.0498ZM19.2998 4.6998H4.6998L4.6998 19.2998H19.2998V4.6998ZM2.7998 4.4998C2.7998 3.56092 3.56092 2.7998 4.4998 2.7998H19.4998C20.4387 2.7998 21.1998 3.56092 21.1998 4.4998V19.4998C21.1998 20.4387 20.4387 21.1998 19.4998 21.1998H4.4998C3.56092 21.1998 2.7998 20.4387 2.7998 19.4998V4.4998Z"
          fill={color}
        />
        <circle cx="6.95" cy="8.2498" r="0.95" fill={color} />
        <circle cx="6.95" cy="11.9998" r="0.95" fill={color} />
        <circle cx="12.95" cy="11.9998" r="0.95" fill={color} />
        <circle cx="16.95" cy="8.2498" r="0.95" fill={color} />
      </g>
    </svg>
  );
};

DescriptionIcon.displayName = 'DescriptionIcon';
