import type { ReactElement } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { AddIcon } from '@icons/AddIcon';
import type { LogicalOperator } from '@repositories/workflowRepository/Types';
import type { WorkflowBaseTriggerFieldDTOModel } from '@models/workflow/WorkflowBaseTriggerFieldDTOModel';
import { ConditionSetting } from './ConditionSetting';

export const DirectApiConfig = observer(() => {
  const { workflowDetailStore } = useStore();

  const baseTrigger = workflowDetailStore.triggerNode?.data.baseTriggerModel;
  const triggerModel = workflowDetailStore.triggerNode?.data.triggerModel;

  const handleAddNewCondition = () => {
    if (!triggerModel) return;
    if (!triggerModel.conditions) triggerModel.conditions = [];

    triggerModel.conditions.push({ fieldName: '' });
  };

  const handleChangeLogicalOperator = (operator: LogicalOperator, index: number) => {
    if (!triggerModel) return;
    if (!triggerModel.conditions) triggerModel.conditions = [];

    triggerModel.conditions.splice(index, 1, { ...triggerModel.conditions[index], logicalOperator: operator });
  };

  const handleChangeField = (field: WorkflowBaseTriggerFieldDTOModel, index: number) => {
    if (!triggerModel) return;
    if (!triggerModel.conditions) triggerModel.conditions = [];

    triggerModel.conditions.splice(index, 1, {
      ...triggerModel.conditions[index],
      fieldName: field.name,
      value: undefined,
    });
  };

  const handleChangeValue = (value: string | undefined, index: number) => {
    if (!triggerModel) return;
    if (!triggerModel.conditions) triggerModel.conditions = [];

    triggerModel.conditions.splice(index, 1, { ...triggerModel.conditions[index], value });
  };

  const handleClickAddBtn = (index: number) => {
    if (!triggerModel) return;
    if (!triggerModel.conditions) triggerModel.conditions = [];

    triggerModel.conditions.splice(index + 1, 0, { fieldName: '' });
  };

  const handleClickRemoveBtn = (index: number) => {
    if (!triggerModel) return;
    if (!triggerModel.conditions) triggerModel.conditions = [];

    triggerModel.conditions.splice(index, 1);

    // NOTE. 첫번째 조건을 삭제하여 첫번째 조건이되는 다음 조건은 오퍼레이터는 리셋되어야함.
    if (index === 0 && triggerModel.conditions.length > 0) {
      triggerModel.conditions[index].logicalOperator = undefined;
    }
  };

  const conditions = triggerModel?.conditions ?? [];

  let conditionSettings: ReactElement[] = [];
  if (baseTrigger) {
    conditionSettings = conditions.map((condition, idx) => {
      return (
        <ConditionSetting
          // eslint-disable-next-line react/no-array-index-key
          key={`${idx}`}
          title={`${t('Setting')} ${idx + 1}`}
          condition={condition}
          baseTrigger={baseTrigger}
          isEditable={workflowDetailStore.checkEditable()}
          isLogicalOperatorDisabled={idx === 0}
          onChangeLogicalOperator={logicalOp => handleChangeLogicalOperator(logicalOp, idx)}
          onChangeField={field => handleChangeField(field, idx)}
          onChangeValue={value => handleChangeValue(value, idx)}
          onClickAddBtn={() => handleClickAddBtn(idx)}
          onClickRemoveBtn={() => handleClickRemoveBtn(idx)}
        />
      );
    });
  }

  return (
    <Container>
      {conditionSettings.length === 0 && (
        <>
          <EmptyCondtionDescription>{t('Workflow_Create_71')}</EmptyCondtionDescription>{' '}
          <IconButton onClick={handleAddNewCondition}>
            <AddIcon width={20} height={20} />
          </IconButton>
        </>
      )}
      {conditionSettings.length > 0 && conditionSettings}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;
`;

const EmptyCondtionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['bg-purple-lighter']};
  padding: 18px 24px;
  ${fonts.Body4};
  color: ${props => props.theme.colors['text-purple']};
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
`;
