// import { useTheme } from '@emotion/react';
const defaultWidth = 16;
const defaultHeight = 16;

type Props = SvgProps & {
  upArrowColor?: string;
  downArrowColor?: string;
};

export const CaretIcon = ({
  width = defaultWidth,
  height = defaultHeight,
  color = '#959DA5',
  upArrowColor,
  downArrowColor,
}: Props) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        d="M4.42724 6.06931L7.82372 2.67287C7.92135 2.57524 8.07964 2.57524 8.17727 2.67287L11.5737 6.06931C11.7312 6.22681 11.6197 6.49609 11.3969 6.49609H4.60402C4.38129 6.49609 4.26975 6.2268 4.42724 6.06931Z"
        fill={upArrowColor ?? color}
      />
      <path
        d="M4.42724 9.92287L7.82372 13.3193C7.92135 13.4169 8.07964 13.4169 8.17727 13.3193L11.5737 9.92287C11.7312 9.76537 11.6197 9.49609 11.3969 9.49609H4.60402C4.38129 9.49609 4.26975 9.76538 4.42724 9.92287Z"
        fill={downArrowColor ?? color}
      />
    </g>
  </svg>
);
