import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { useGetMySubscriptionUseRequests } from '@queryHooks/useSubscriptionUseRequest';
import { ApplyProductRow } from './ApplyProductRow';

export const ApplyHistoryList = () => {
  const { t } = useTranslation();
  const { authStore } = useStore();

  const { data: myApplySubscriptions } = useGetMySubscriptionUseRequests({ sort: ['createdDate.desc'] });
  return (
    <Container>
      <Header>
        <p>
          <Trans
            i18nKey="my_subscrib_01"
            values={{ Name: authStore.name, number: myApplySubscriptions?.totalElements }}
            components={{ 1: <strong />, 2: <br />, 3: <strong className="count" /> }}
          />
        </p>
      </Header>
      <Body>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="72px" align="center">
                  {t('Workflow_Main_04')}
                </TableCell>
                <TableCell width="153px">{t('Subscrib_Detail_UseRequest_05')}</TableCell>
                <TableCell width="129px">{t('Subscrib_Detail_UseRequest_06')}</TableCell>
                <TableCell width="170px">{t('Subscrib_Detail_UseRequest_07')}</TableCell>
                <TableCell width="210px">{t('Subscrib_Main_12')}</TableCell>
                <TableCell width="153px">{t('Subscrib_Detail_UseRequest_19')}</TableCell>
                <TableCell width="99px">{t('Subscrib_Detail_UseRequest_09')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myApplySubscriptions?.content.length ? (
                myApplySubscriptions?.content?.map((rq, idx) => {
                  return <ApplyProductRow key={rq.id} data={rq} idx={idx + 1} />;
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <NoApplyProducts>
                      <Exclaim>
                        <ErrorIcon width={22} height={22} />
                      </Exclaim>
                      <span className="sub-text">{t('my_subscrib_02')}</span>
                    </NoApplyProducts>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--employee-gnb-height) - 80px);
  margin-left: 292px;
`;

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  p {
    ${fonts.Subtitle4};
    line-height: 42px;
    & .count {
      font-weight: 700;
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }
  margin-bottom: 40px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  & table {
    max-width: 986px;
    width: 100%;
    overflow: auto;
    & .MuiTableHead-root .MuiTableCell-root {
      background-color: ${({ theme }) => theme.colors['bg-gray-light-50']};
    }
    tbody {
      .MuiTableRow-root {
        background-color: ${({ theme }) => theme.colors['bg-white']};
        &:hover {
          background-color: ${({ theme }) => theme.colors['state-white-hover']};
        }
      }
    }
  }
`;

const NoApplyProducts = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 166px;

  & .sub-text {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-top: 12px;
  }
`;

const Exclaim = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  align-items: center;
  justify-content: center;
`;
