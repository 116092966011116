import { Button, Dialog } from '@components/index';
import validator from '@rjsf/validator-ajv8';
import {
  CustomSelectWidget,
  CustomTextWidget,
} from '@pages/customer/manager/workflow/connectionMgmt/detail/appLogin/AppLogin';
import { RegistryWidgetsType, RJSFSchema } from '@rjsf/utils';
import { IChangeEvent } from '@rjsf/core';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import { useStore } from '@stores/RootStore';
import debounce from 'lodash.debounce';
import { DomUtil } from '@utils/DomUtil';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import { useAddRemoteUser } from '@queryHooks/useSubscription';
import { useGetSubscriptionConnection } from '@queryHooks/useSubscriptionConnection';
import { useTranslation } from 'react-i18next';
import * as S from './LinkUserDialogStyles';
import { AutocompleteOption } from './AddUserDialog';

type Props = {
  subscriptionId: string;
  open: boolean;
  onClose: () => void;
};

const AddUserFromSchemaDialog = ({ subscriptionId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    authStore,
    uiStore: { toastStore },
  } = useStore();
  const [user, setUser] = useState<TenantMemberSimpleModel>(new TenantMemberSimpleModel());

  const { data: subscriptionConnection } = useGetSubscriptionConnection(subscriptionId, {
    enabled: open,
    staleTime: 0,
    cacheTime: 0,
  });

  const {
    isSuccess: isUserListSuccess,
    data: userListPage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetTenantMembersInfinite(authStore.managerTenantInfo.id, { size: 100 }, { enabled: open });

  const options: AutocompleteOption[] = isUserListSuccess
    ? userListPage.pages.reduce(
        (accum, cur) => [...accum, ...cur.content.map(({ id, name, email }) => ({ id, name, email }))],
        [] as AutocompleteOption[],
      )
    : [];

  const handleAutocompleteScroll = debounce((event: React.UIEvent<HTMLUListElement>) => {
    if (DomUtil.isScrollBottom(event.target as HTMLUListElement) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 100);

  const widgets: RegistryWidgetsType = {
    TextWidget: CustomTextWidget,
    SelectWidget: CustomSelectWidget,
  };

  const { mutate: addRemoteUser } = useAddRemoteUser();

  const handleSubmit = (data: IChangeEvent<any, RJSFSchema, any>) => {
    addRemoteUser(
      { subscriptionId: subscriptionId as string, raData: { userId: user.id, userInfo: data.formData } },
      {
        onSuccess: () => {
          toastStore.open(t('Add_User_Success'));
          setUser(new TenantMemberSimpleModel());
        },
      },
    );
    onClose();
  };

  const handleClose = () => {
    setUser(new TenantMemberSimpleModel());
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} title={t('Subscrib_Detail_User_18')} height={600}>
      <S.SubscriptionUserLabel>{t('Member_Group_07')}</S.SubscriptionUserLabel>
      <Autocomplete
        onChange={(event, value) => {
          setUser(new TenantMemberSimpleModel(value as AutocompleteOption));
        }}
        options={options}
        value={user}
        getOptionLabel={option => {
          return option.name === '-' ? '' : `${option.name} (${option.email})`;
        }}
        renderInput={params => <TextField {...params} placeholder={t('Subscrib_Detail_User_23')} variant="outlined" />}
        ListboxProps={{ onScroll: handleAutocompleteScroll, role: 'list-box' }}
        sx={{ marginBottom: '20px' }}
      />

      <S.StyledForm
        schema={subscriptionConnection?.addUserSchemaObject ?? {}}
        validator={validator}
        onSubmit={handleSubmit}
        uiSchema={{
          'ui:globalOptions': {
            label: false,
          },
          'ui:submitButtonOptions': {
            norender: true,
          },
        }}
        widgets={widgets}
      >
        <Dialog.Footer>
          <Button variant="contain" type="submit" size="extraLarge">
            {t('Member_User_24')}
          </Button>
          <Button variant="outline" size="extraLarge" onClick={handleClose}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </Button>
        </Dialog.Footer>
      </S.StyledForm>
    </Dialog>
  );
};

export default AddUserFromSchemaDialog;
