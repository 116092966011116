import styled from '@emotion/styled';
import { Autocomplete, Box, Dialog, DialogContent, TextField } from '@mui/material';
import { CompanySelect } from '@pages/admin/tenants/common/CompanySelect';

export const DialogContainer = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 16px;
    width: 550px;
    height: 700px;
    overflow-y: auto;
    border: 2px solid #fff;
    background-color: #fff;
  }
`;

export const TitleWrapper = styled(Box)`
  padding: 16px 24px;
  border-bottom: 1px solid #e9e9ed;
`;

export const ContentWrapper = styled(DialogContent)`
  padding-top: 16px !important;
`;

export const TenantName = styled(TextField)`
  margin: 16px 0px 16px 0px;
`;

export const TenantCode = styled(TextField)`
  margin: 0px 0px 16px 0px;
`;

export const Description = styled(TextField)`
  margin: 0px 0px 16px 0px;
`;

export const Company = styled(CompanySelect)`
  margin: 0px 0px 16px 0px;
`;

export const ContinueBtnWrapper = styled(Box)`
  display: flex;
  justify-content: right;
`;

export const ParentTenant = styled(Autocomplete)`
  margin: 0px 0px 16px 0px;
` as typeof Autocomplete;

export const NIPTenantCode = styled(TextField)`
  margin: 20px 0px 0px 0px;
`;

export const NIPProductCode = styled(TextField)`
  margin: 20px 0px 0px 0px;
`;

export const BottomBtnsWrapper = styled(Box)<{ activestep: number }>`
  display: flex;
  justify-content: right;
  visibility: ${props => (props.activestep !== 1 ? 'hidden' : null)};
  margin: 16px 0px 0px 0px;
`;
