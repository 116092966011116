const defaultWidth = 14;
const defaultHeight = 14;

export const InfoIcon = ({ width = defaultWidth, height = defaultHeight, color = '#D1D5DA' }: SvgProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
      <path
        d="M7.58334 4.37516C7.58334 4.69733 7.32218 4.9585 7.00001 4.9585C6.67784 4.9585 6.41668 4.69733 6.41668 4.37516C6.41668 4.053 6.67784 3.79183 7.00001 3.79183C7.32218 3.79183 7.58334 4.053 7.58334 4.37516Z"
        fill={color}
      />
      <path
        d="M5.83334 6.56266C5.83334 6.32104 6.02922 6.12516 6.27084 6.12516H7.14584C7.38747 6.12516 7.58334 6.32104 7.58334 6.56266V9.04183H8.02084C8.26247 9.04183 8.45834 9.23771 8.45834 9.47933C8.45834 9.72095 8.26247 9.91683 8.02084 9.91683H6.27084C6.02922 9.91683 5.83334 9.72095 5.83334 9.47933C5.83334 9.23771 6.02922 9.04183 6.27084 9.04183H6.70834V7.00016H6.27084C6.02922 7.00016 5.83334 6.80429 5.83334 6.56266Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00001 0.583496C3.45618 0.583496 0.583344 3.45634 0.583344 7.00016C0.583344 10.544 3.45618 13.4168 7.00001 13.4168C10.5438 13.4168 13.4167 10.544 13.4167 7.00016C13.4167 3.45634 10.5438 0.583496 7.00001 0.583496ZM1.45834 7.00016C1.45834 3.93958 3.93943 1.4585 7.00001 1.4585C10.0606 1.4585 12.5417 3.93958 12.5417 7.00016C12.5417 10.0607 10.0606 12.5418 7.00001 12.5418C3.93943 12.5418 1.45834 10.0607 1.45834 7.00016Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 1.4674C3.94439 1.4674 1.46731 3.94449 1.46731 7.00013C1.46731 10.0558 3.94439 12.5329 7.00004 12.5329C10.0557 12.5329 12.5328 10.0558 12.5328 7.00013C12.5328 3.94449 10.0557 1.4674 7.00004 1.4674ZM0.466705 7.00013C0.466705 3.39187 3.39178 0.466797 7.00004 0.466797C10.6083 0.466797 13.5334 3.39187 13.5334 7.00013C13.5334 10.6084 10.6083 13.5335 7.00004 13.5335C3.39178 13.5335 0.466705 10.6084 0.466705 7.00013ZM7.64749 4.35148C7.64749 4.70906 7.35761 4.99893 7.00004 4.99893C6.64246 4.99893 6.35259 4.70906 6.35259 4.35148C6.35259 3.99391 6.64246 3.70403 7.00004 3.70403C7.35761 3.70403 7.64749 3.99391 7.64749 4.35148ZM5.764 6.55869C5.764 6.28238 5.98799 6.05839 6.2643 6.05839H7.14719C7.42349 6.05839 7.64749 6.28238 7.64749 6.55869V9.00133H8.03007C8.30638 9.00133 8.53037 9.22532 8.53037 9.50163C8.53037 9.77794 8.30638 10.0019 8.03007 10.0019H6.2643C5.98799 10.0019 5.764 9.77794 5.764 9.50163C5.764 9.22532 5.98799 9.00133 6.2643 9.00133H6.64688V7.05899H6.2643C5.98799 7.05899 5.764 6.835 5.764 6.55869Z"
        fill={color}
      />
    </g>
  </svg>
);
