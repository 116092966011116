import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export type FormType = {
  name: string;
  userGroupCode: string;
};

export const useGroupForm = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Member_Group_Bulk_Create_22')).max(20, t('Member_Group_Delete_06')),
    userGroupCode: Yup.string().required(t('그룹 코드를 입력해주세요')).max(20, t('Member_Group_Delete_06')),
  });
  const formOptions: UseFormProps<FormType> = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      userGroupCode: '',
    },
  };
  return useForm<FormType>(formOptions);
};
