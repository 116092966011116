import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Box, MenuItem as MuiMenuItem, Select as MuiSelect, SelectChangeEvent, TextField, styled } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { fonts } from '@theme/fontsCustomer';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import { DashIcon } from '@icons/DashIcon';

type Props = {
  filter: { dateMode: 'month' | 'day'; startDayjs: Dayjs; endDayjs: Dayjs };
  onDateModeChange: (dateMode: Props['filter']['dateMode']) => void;
  onStartDateChange: (startDate: Props['filter']['startDayjs'] | null) => void;
  onEndDateChange: (endDate: Props['filter']['endDayjs'] | null) => void;
  disabled: boolean;
};
export const UsageFilter = ({ filter, onDateModeChange, onStartDateChange, onEndDateChange, disabled }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const handleChangeDateMode = (event: SelectChangeEvent<'month' | 'day'>) => {
    if (event.target.value === 'month' || event.target.value === 'day') {
      onDateModeChange(event.target.value);
    }
  };

  const handleStartDateChange = (value: string | null) => {
    const startDate = dayjs(value);
    onStartDateChange(startDate.isValid() ? startDate : null);
  };

  const handleEndDateChange = (value: string | null) => {
    const endDate = dayjs(value);
    onEndDateChange(endDate.isValid() ? endDate : null);
  };

  return (
    <FilterWrapper>
      <Select
        size="small"
        value={filter.dateMode}
        SelectDisplayProps={{ style: { textOverflow: 'unset' } }}
        onChange={handleChangeDateMode}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: { width: '68px' },
          },
        }}
      >
        <MenuItem value="day">{t('Subscrib_Detail_Usage_04')}</MenuItem>
        <MenuItem value="month">{t('Subscrib_Detail_Usage_05')}</MenuItem>
      </Select>
      <Box display="flex" alignItems="center" gap="6px">
        <DesktopDatePicker
          disabled={disabled}
          inputFormat={filter.dateMode === 'month' ? t('DateFormat_YM') : t('DateFormat_YMD')}
          value={filter.startDayjs}
          onChange={handleStartDateChange}
          renderInput={params => (
            <TextField
              {...params}
              sx={{ width: '142px', opacity: disabled ? '0.3' : undefined }}
              inputProps={{ ...params.inputProps, readOnly: true }}
            />
          )}
          InputProps={{ size: 'small' }}
          disableFuture
          showDaysOutsideCurrentMonth
          views={filter.dateMode === 'month' ? ['year', 'month'] : ['year', 'month', 'day']}
          shouldDisableDate={day => dayjs(day).isAfter(filter.endDayjs)}
          OpenPickerButtonProps={{ style: { right: '8px' } }}
          components={{ OpenPickerIcon }}
          PaperProps={{
            sx: {
              margin: '10px 0 0',
              borderRadius: '2px',
              border: `1px solid ${theme.colors['border-gray-lighter']}`,
              boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
              '& .MuiCalendarOrClockPicker-root': {
                margin: '10px 0 0',
              },
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: `${theme.colors['bg-purple-lighter']} !important`,
                  color: `${theme.colors['text-purple-light']}`,
                  '&:hover': {
                    backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                  },
                },
                '&:hover': {
                  backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                },
              },
              '& .MuiPickersDay-today': {
                border: 'none',
              },
            },
          }}
        />
        <DashBox disabled={disabled}>
          <DashIcon width={10} height={10} />
        </DashBox>
        <DesktopDatePicker
          disabled={disabled}
          inputFormat={filter.dateMode === 'month' ? t('DateFormat_YM') : t('DateFormat_YMD')}
          value={filter.endDayjs}
          onChange={handleEndDateChange}
          renderInput={params => (
            <TextField
              {...params}
              sx={{ width: '142px', opacity: disabled ? '0.3' : undefined }}
              inputProps={{ ...params.inputProps, readOnly: true }}
            />
          )}
          InputProps={{ size: 'small' }}
          disableFuture
          showDaysOutsideCurrentMonth
          views={filter.dateMode === 'month' ? ['year', 'month'] : ['year', 'month', 'day']}
          shouldDisableDate={day => dayjs(day).isBefore(filter.startDayjs)}
          OpenPickerButtonProps={{ style: { right: '8px' } }}
          components={{ OpenPickerIcon }}
          PaperProps={{
            sx: {
              margin: '10px 0 0',
              borderRadius: '2px',
              border: `1px solid ${theme.colors['border-gray-lighter']}`,
              boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.06)',
              '& .MuiCalendarOrClockPicker-root': {
                margin: '10px 0 0',
              },
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: `${theme.colors['bg-purple-lighter']} !important`,
                  color: `${theme.colors['text-purple-light']}`,
                  '&:hover': {
                    backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                  },
                },
                '&:hover': {
                  backgroundColor: `${theme.colors['state-white-purple-hover']}`,
                },
              },
              '& .MuiPickersDay-today': {
                border: 'none',
              },
            },
          }}
        />
      </Box>
    </FilterWrapper>
  );
};

const OpenPickerIcon = () => {
  const { colors } = useTheme();
  return <CalendarStartIcon width={16} height={16} color={colors['ic-gray-dark']} />;
};

const FilterWrapper = styled('div')`
  display: flex;
  align-items: center;
  ${fonts.Headline6}
  gap: 10px;
`;

const Select = styled(MuiSelect)<{ disabled: boolean }>`
  width: 68px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : undefined)};
` as unknown as typeof MuiSelect;

const MenuItem = styled(MuiMenuItem)`
  width: 59px;
`;

const DashBox = styled('div')<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : undefined)};
`;
