import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  useTheme,
} from '@mui/material';
import { Button, Loader } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import { SearchIcon } from '@icons/index';
import type { WorkflowsFilter } from '@repositories/workflowRepository/Types';
import { useGetWorkflows } from '@queryHooks/useWorkflow';
import { WorkflowRow } from './WorkflowRow';

export const WorkflowMgmtList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authStore, workflowDetailStore } = useStore();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<WorkflowsFilter>({
    name: '',
  });

  const { data: workflows, isSuccess } = useGetWorkflows(filter);
  const handleClickWorkflowCreateBtn = () => {
    workflowDetailStore.setToolbarView('triggerSelect');
    navigate(`/manager/${authStore.curTenant.id}/workflows/management/create`);
  };

  const handleSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({ ...prev, name: event.target.value }));
  };

  if (!isSuccess) return <Loader />;
  return (
    <Container>
      <Header>
        <strong>{t('Workflow_LNB_01')}</strong>
      </Header>
      <Divider />
      <Body>
        <Toolbar>
          <strong className="toolbar-left">
            {t('Acc_Main_12')}
            <span className="count">{workflows?.totalElements}</span>
          </strong>
          <div className="toolbar-right">
            <StyledTextField
              fullWidth
              size="small"
              value={filter.name}
              placeholder={t('Workflow_Main_02')}
              onChange={handleSearchNameChange}
              InputProps={{
                startAdornment: (
                  <IconWrapper>
                    <SearchIcon width={16} height={16} color={theme.colors['ic-gray-light']} />
                  </IconWrapper>
                ),
              }}
            />
            <Button size="small" variant="contain" paddingHorizontal={16} onClick={handleClickWorkflowCreateBtn}>
              {t('Workflow_Main_03')}
            </Button>
          </div>
        </Toolbar>
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="72px" align="center">
                    {t('Workflow_Main_04')}
                  </StyledTableCell>
                  <TableCell width="556px">{t('Workflow_Main_05')}</TableCell>
                  <TableCell width="240px">{t('Workflow_Main_06')}</TableCell>
                  <TableCell width="80px">{t('Workflow_Main_07')}</TableCell>
                  <TableCell width="180px">{t('Workflow_Main_08')}</TableCell>
                  <TableCell width="200px">{t('Workflow_Main_09')}</TableCell>
                  <StyledTableCell width="72px" align="center">
                    {t('Acc_Main_23')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workflows?.content.length ? (
                  workflows?.content.map((workflow, idx) => {
                    return <WorkflowRow data={workflow} key={workflow.id} idx={idx} />;
                  })
                ) : (
                  <NoDataRow>
                    <TableCell colSpan={8} rowSpan={5}>
                      <Empty>
                        <Avatar sx={{ backgroundColor: theme.colors['bg-purple-lighter'] }}>
                          <ErrorIcon color={theme.colors['ic-purple-light']} />
                        </Avatar>
                        <div className="main-text">{t('Workflow_Main_20')}</div>
                        <div className="sub-text">{t('Workflow_Main_21')}</div>
                      </Empty>
                    </TableCell>
                  </NoDataRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      </Body>
    </Container>
  );
};

const NoDataRow = styled(TableRow)`
  &.MuiTableRow-root:hover {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
`;

const Container = styled('div')`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 0px 32px;
`;

const Header = styled('div')`
  margin: 28px 0px 14px 0px;
  strong {
    ${fonts.Headline5};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-left: 8px;
  }
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  gap: 12px;
`;

const Toolbar = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;

  & .toolbar-left {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-top: 6px;
    margin-left: 8px;
    & .count {
      margin-left: 4px;
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }

  & .toolbar-right {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;

const TableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 29px;
  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
    overflow: hidden;
  }
  & .MuiTableRow-root {
    cursor: pointer;
  }
  th {
    text-align: left;
    color: ${({ theme }) => theme.colors['text-gray-main']};
    border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
    background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  }
  td {
    color: ${({ theme }) => theme.colors['text-gray-main']};
    border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  }
`;

const Empty = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 28px 0px 30px 0px;
  align-items: center;
  & .main-text {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-top: 12px;
  }
  & .sub-text {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 6px 8px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-light']};
  }
`;

const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: center;
  }
`;
