import { Box, CircularProgress } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { observer } from 'mobx-react-lite';
import { opacityMap } from '../../colorSet';

export const GlobalLoader: React.FC = observer(() => {
  const {
    uiStore: { isGlobalLoaderVisible },
  } = useStore();

  return isGlobalLoaderVisible ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
        backgroundColor: `#222222${opacityMap[50]}`,
      }}
    >
      <CircularProgress />
    </Box>
  ) : null;
});
GlobalLoader.displayName = 'GlobalLoader';
