import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UserModel } from '@models/index';

export type FormUser = {
  username: string;
  email: string;
  phone: string;
};

export const useUserForm = (userData?: UserModel[]) => {
  const { t } = useTranslation();
  const validationUserSchema = Yup.object().shape({
    username: Yup.string().required(t('Member_Group_Bulk_Create_22')),
    email: Yup.string()
      .required(t('Account_Validation_Check_02'))
      .email(t('Member_Manager_17'))
      .test('is-unique', '이미 회원가입 된 계정입니다.', value => {
        return !userData?.some(user => user.email === value);
      }),
    phone: Yup.string().matches(/^[0-9]+$/, {
      message: t('Member_User_Bulk_Create_23'),
      excludeEmptyString: true,
    }),
  });
  const userFormOptions: UseFormProps<FormUser> = {
    resolver: yupResolver(validationUserSchema),
    mode: 'onChange',
    shouldFocusError: true,
  };
  return useForm<FormUser>(userFormOptions);
};
