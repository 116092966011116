import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Popover, Skeleton, Tooltip } from '@mui/material';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { GroupIcon, MailIcon, NameIcon, OrganizationIcon, PhoneIcon, XIcon } from '@icons/index';
import { useGetUserProfile } from '@queryHooks/useUser';
import { transientOptions } from '@utils/CommonUtil';

type Props = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  userId: UserId;
};
/**
 * 유저 프로필 모달. 나의 프로필 모달은 `MyProfileModal` 컴포넌트 사용
 */
export const ProfileModal = ({ open, anchorEl, onClose, userId }: Props) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { colors } = useTheme();
  const { data: user, isSuccess } = useGetUserProfile(userId, { enabled: open && !!userId });

  const [isUserGroupHover, setIsUserGroupHover] = useState(false);

  const userGroups = isSuccess ? user.getTenantUserGroups(authStore.managerTenantInfo.id) : [];

  return (
    <Popover
      title={t('Subscrib_Detail_Overview_19')}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      transformOrigin={{ horizontal: -8, vertical: 0 }}
      PaperProps={{
        sx: {
          borderRadius: '6px',
          boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);',
        },
      }}
    >
      <Container>
        <Header>
          <Title>{t('Subscrib_Detail_Overview_19')}</Title>
          <Button onClick={onClose}>
            <XIcon />
          </Button>
        </Header>
        <Body>
          <Field>
            <KeyWrapper>
              <OrganizationIcon width={18} height={18} color={colors['ic-gray-light']} />
              <KeyName>{t('Product_Inquiry_06')}</KeyName>
            </KeyWrapper>
            {isSuccess ? (
              <Value>{user?.company[0]?.name || '-'}</Value>
            ) : (
              <Skeleton width={100} sx={{ display: 'inline-block' }} />
            )}
          </Field>
          <Field>
            <KeyWrapper>
              <MailIcon width={18} height={18} color={colors['ic-gray-light']} />
              <KeyName>{t('Member_User_14')}</KeyName>
            </KeyWrapper>
            {isSuccess ? (
              <Value>{user?.email || '-'}</Value>
            ) : (
              <Skeleton width={100} sx={{ display: 'inline-block' }} />
            )}
          </Field>

          <Field>
            <KeyWrapper>
              <NameIcon width={18} height={18} color={colors['ic-gray-light']} />
              <KeyName>{t('Workflow_Main_05')}</KeyName>
            </KeyWrapper>
            {isSuccess ? <Value>{user?.name || '-'}</Value> : <Skeleton width={50} sx={{ display: 'inline-block' }} />}
          </Field>

          <Field>
            <KeyWrapper>
              <GroupIcon width={18} height={18} color={colors['ic-gray-light']} />
              <KeyName>{t('Member_User_Bulk_Create_18')}</KeyName>
            </KeyWrapper>

            {isSuccess ? (
              <>
                <Value>{userGroups.length > 0 ? userGroups[0].name : '-'}</Value>
                {userGroups.length - 1 > 0 ? (
                  <UserGroupTooltip
                    arrow
                    title={user.userGroups.map(({ name }, idx) =>
                      idx === 0 ? null : <UserGroupNameWrapper key={name}>{name}</UserGroupNameWrapper>,
                    )}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          padding: '6px',
                          backgroundColor: colors['bg-white'],
                          color: colors['text-gray-main'],
                          border: `1px solid ${colors['border-gray-lighter']}`,
                        },
                      },
                      arrow: {
                        sx: {
                          color: colors['bg-white'],
                          '&::before': {
                            border: `1px solid ${colors['border-gray-lighter']}`,
                          },
                        },
                      },
                    }}
                  >
                    <OtherGroups
                      $isHover={isUserGroupHover}
                      onMouseEnter={() => setIsUserGroupHover(true)}
                      onMouseLeave={() => setIsUserGroupHover(false)}
                    >
                      {t('GNB_Profile_MyProfile_05')} {user.userGroups.length - 1}
                    </OtherGroups>
                  </UserGroupTooltip>
                ) : null}
              </>
            ) : (
              <Skeleton width={100} sx={{ display: 'inline-block' }} />
            )}
          </Field>

          <Field>
            <KeyWrapper>
              <PhoneIcon width={18} height={18} color={colors['ic-gray-light']} />
              <KeyName>{t('Member_User_16')}</KeyName>
            </KeyWrapper>
            {isSuccess ? <Value>{user?.phone || '-'}</Value> : <Skeleton width={50} sx={{ display: 'inline-block' }} />}
          </Field>
        </Body>
      </Container>
    </Popover>
  );
};

const Container = styled.div`
  width: 480px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border-radius: 6px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 16px 28px;
`;

const Title = styled.span`
  ${fonts.Headline6}
`;

const Body = styled.div`
  width: 360px;
  margin: 0 auto;
  padding: 12px 0px 44px;
`;

const Field = styled.div`
  height: 32px;
  margin-bottom: 16px;
  & * {
    vertical-align: middle;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const KeyWrapper = styled.div`
  display: inline-block;
  width: 132px;
`;

const KeyName = styled.span`
  margin-left: 12px;
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const Value = styled.span`
  ${fonts.Body1}
`;

const OtherGroups = styled('span', transientOptions)<{ $isHover?: boolean }>`
  ${fonts.Body1}
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
  margin-left: 4px;
  text-decoration: ${({ $isHover }) => ($isHover ? 'underline' : 'none')};
`;

const UserGroupTooltip = styled(Tooltip)`
  background-color: white;
`;

const UserGroupNameWrapper = styled.div`
  padding: 6px 7px;
`;
