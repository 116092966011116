import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { Button } from '@components/index';
import { DangerFillIcon } from '@icons/DangerFillIcon';

interface Props {
  message: string;
  onClickButton: () => void;
}
export const CostMismatchTooltipIcon = ({ message, onClickButton }: Props) => {
  const { t } = useTranslation();

  const handleClickBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onClickButton();
  };

  return (
    <Tooltip
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {message}
          <Button variant="text" color="skyblue" size="small" onClick={handleClickBtn}>
            {t('Acc_Detail_CalculateCostAuto')}
          </Button>
        </div>
      }
      sx={{ marginRight: 1 }}
    >
      <DangerFillIcon style={{ verticalAlign: 'middle' }} width={16} height={16} />
    </Tooltip>
  );
};
