import API from '@repositories/Axios';
import { Page } from '@type/Page';
import qs from 'qs';
import { injectable } from 'inversify';
import { HourlyFilters, HourlyUsage } from '../Types';
import 'reflect-metadata';
import IHourlyUsageRepository from './IHourlyUsageRepository';

@injectable()
export class HourlyUsageRepository implements IHourlyUsageRepository {
  async create(/* data: unknown */): Promise<HourlyUsage> {
    throw new Error('Method not implemented.');
  }
  async getList(queries?: HourlyFilters): Promise<Page<HourlyUsage>> {
    const queryString = qs.stringify(queries);

    const reuslt = await API.get<Page<HourlyUsage>>(`/api/v1/hourly-usages?${queryString}`);
    return reuslt.data;
  }
}
