import styled from '@emotion/styled';
import { withLogin } from '@hoc/withLogin';
import { BillingWg } from './wigets/billingWg/BillingWg';
import { LicenseRetriveWg } from './wigets/licenseRetriveWg/LicenseRetriveWg';
import { MemberWg } from './wigets/memberWg/MemberWg';
import { NextPaymentWg } from './wigets/paymentWg/NextPaymentWg';
import { RecentPaymentWg } from './wigets/paymentWg/RecentPaymentWg';
import { PurchaseRqWg } from './wigets/purchaseRqWg/PurchaseRqWg';
import { SbUsageRqWg } from './wigets/sbUsageRqWg/SbUsageRqWg';
import { SubscriptionWg } from './wigets/subscriptionWg/SubscriptionWg';
import { WorkflowWg } from './wigets/workflowWg/WorkflowWg';

export const Home = withLogin(() => {
  return (
    <Container>
      <Contents>
        <SubscriptionWg />
        <MemberWg />
        <WorkflowWg />
      </Contents>
      <Contents>
        <BillingWg />
        <RecentPaymentWg />
        <NextPaymentWg />
      </Contents>
      <Divider />
      <Contents>
        <LicenseRetriveWg />
        <SbUsageRqWg />
        <PurchaseRqWg />
      </Contents>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: auto;
  padding: 60px 0px;
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
`;

const Contents = styled.div`
  display: flex;
  flex-direction: row;
  width: 1284px;
  gap: 20px;
`;

const Divider = styled.div`
  width: 1284px;
  height: 1px;
  background: ${props => props.theme.colors['border-gray-lighter']};
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
`;
