import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type AddRequestFormData = {
  email: string;
};

export const useAddRequestForm = () => {
  const { t } = useTranslation();

  const addRequestSchema: Yup.SchemaOf<AddRequestFormData> = Yup.object().shape({
    email: Yup.string().required(t('Member_Manager_14')).email(t('Member_Manager_17')),
  });

  const addRequestFormOption: UseFormProps<AddRequestFormData> = {
    resolver: yupResolver(addRequestSchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: '',
    },
  };
  return useForm<AddRequestFormData>(addRequestFormOption);
};
