// import { useTheme } from '@emotion/react';
const defaultWidth = 4;
const defaultHeight = 4;

export const BulletIcon = ({ width = defaultWidth, height = defaultHeight, color = '#24292E' }: SvgProps) => {
  // const {
  //   colors: { Gray },
  // } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`scale(${width / defaultWidth}, ${height / defaultHeight})`}>
        <circle cx="2" cy="2" r="1" fill={color} />
      </g>
    </svg>
  );
};

BulletIcon.displayName = 'BulletIcon';
