import React, { ButtonHTMLAttributes, ForwardedRef, ReactElement, forwardRef } from 'react';
import styled from '@emotion/styled';

type CommonProps = ButtonHTMLAttributes<HTMLButtonElement>;

type VariantIcon = {
  variant: 'icon';
  disabled?: never;
};
type VariantOutline = {
  variant: 'outline';
  size: 'large' | 'medium' | 'small' | 'extraSmall';
  disabled?: boolean;
};
type VariantContain = {
  variant: 'contain';
  disabled?: boolean;
};

type Props = CommonProps & (VariantIcon | VariantOutline | VariantContain);

/**
 * Icon의 크기, 색상은 컴포넌트 내부에서 덮어쓰므로 사용하는 쪽에서 넘길 필요가 없음
 *
 * 주의: Icon은 svg인 경우에만 제대로 동작하며, 색상은 path의 fill 값을 덮어쓰도록 CSS로 처리함
 *
 * @param children - 표시할 Icon
 * @param variant - icon, outline, contain
 * @param size - variant가 outline인 경우에만 사용 가능. large, medium, small
 * @param disabled - variant가 outline, contain인 경우에만 사용 가능
 */
export const IconButton = forwardRef(({ children, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { variant } = props;

  const renderButton = () => {
    const svgChildren = children as ReactElement<SvgProps>;
    switch (variant) {
      case 'icon': {
        const width = svgChildren.props.width ?? 24;
        const height = svgChildren.props.height ?? 24;
        return (
          <IconBtn {...props} ref={ref} width={width} height={height}>
            {React.cloneElement(svgChildren, { width, height, ...svgChildren.props })}
          </IconBtn>
        );
      }
      case 'outline': {
        const iconSizeMap: { [key in VariantOutline['size']]: number } = {
          large: 24,
          medium: 20,
          small: 16,
          extraSmall: 14,
        };
        return (
          <OutlineBtn {...props} ref={ref}>
            {React.cloneElement(svgChildren, {
              width: iconSizeMap[props.size],
              height: iconSizeMap[props.size],
              ...svgChildren.props,
            })}
          </OutlineBtn>
        );
      }
      case 'contain':
        return (
          <ContainBtn {...props} ref={ref}>
            {React.cloneElement(svgChildren, {
              width: 21.34,
              height: 21.34,
              ...svgChildren.props,
            })}
          </ContainBtn>
        );
      default:
        return null;
    }
  };

  return renderButton();
});

const IconBtn = styled.button<{ width: number; height: number }>`
  display: inline-block;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: ${({ width }) => (width ? `${width}px` : '36px')};
  height: ${({ height }) => (height ? `${height}px` : '36px')};

  &:hover {
    & path {
      fill: ${({ theme: { colors } }) => colors['ic-purple-light']};
    }
  }

  &:active {
    & path {
      fill: ${({ theme: { colors } }) => colors['ic-gray-main']};
    }
  }
`;

const OutlineBtn = styled.button<VariantOutline>`
  cursor: pointer;
  padding: ${({ size }) =>
    size === 'extraSmall' ? '4px' : size === 'small' ? '7px' : size === 'medium' ? '9px' : '11px'};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border-radius: ${({ size }) => (size === 'large' ? '3px' : '2px')};
  width: ${({ size }) =>
    size === 'extraSmall' ? '24px' : size === 'small' ? '32px' : size === 'medium' ? '40px' : '48px'};
  height: ${({ size }) =>
    size === 'extraSmall' ? '24px' : size === 'small' ? '32px' : size === 'medium' ? '40px' : '48px'};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
    & path {
      fill: ${({ theme: { colors } }) => colors['ic-purple-light']};
    }
  }

  &:disabled {
    cursor: auto;
    background-color: ${({ theme: { colors } }) => colors['bg-white']};
    & path {
      fill: ${({ theme: { colors } }) => colors['ic-gray-light']};
    }
  }
`;

const ContainBtn = styled.button`
  cursor: pointer;
  padding: 7.33px;
  border: none;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border-radius: 18px;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
    & path {
      fill: ${({ theme: { colors } }) => colors['ic-purple']};
    }
  }

  &:disabled {
    cursor: auto;
    background-color: ${({ theme: { colors } }) => colors['bg-gray-dark']};
    & path {
      fill: ${({ theme: { colors } }) => colors['ic-gray-light']};
    }
  }
`;
