import APIs from '@repositories/APIs';
import type {
  DailyFilters,
  HourlyFilters,
  ModuleUsageList,
  MonthlyFilters,
  RealtimeFilters,
  RealtimeUsage,
} from '@repositories/usage';
import dayjs from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';

class UsageStore {
  private _realTime: RealtimeUsage | null;

  private readonly _usages: Map<string, ModuleUsageList>;

  private readonly _usageKeys: DateTimeString[];

  constructor() {
    this._realTime = null;
    this._usages = new Map<string, ModuleUsageList>();
    this._usageKeys = [];

    makeAutoObservable<UsageStore>(this);
  }

  async fetchRealtimeUsage(queries: RealtimeFilters) {
    const result = await APIs.RealtimeUsage.getRealtimes(queries);
    runInAction(() => {
      this._realTime = null;
      result.content.forEach(data => {
        this._realTime = data;
      });
    });
  }

  async fetchHourlyUsage(queries: HourlyFilters) {
    if (queries.dateHourTo && queries.dateHourFrom) {
      // eslint-disable-next-line no-param-reassign
      queries.size = dayjs(queries.dateHourTo).diff(dayjs(queries.dateHourFrom), 'hour');
    }

    const result = await APIs.HourlyUsage.getHourly(queries);
    runInAction(() => {
      this._usages.clear();
      this._usageKeys.length = 0;
      result.content.forEach(hour => {
        this._usageKeys.push(dayjs(hour.dateHour).format('YYYY-MM-DDTHH'));
        hour.usageList.forEach(usage => {
          this._usages.set(dayjs(hour.dateHour).format('YYYY-MM-DDTHH') + usage.module, usage);
        });
      });
    });
  }

  async fetchDailyUsage(queries: DailyFilters) {
    if (queries.dateTo && queries.dateFrom) {
      // eslint-disable-next-line no-param-reassign
      queries.size = dayjs(queries.dateTo).diff(dayjs(queries.dateFrom), 'day');
    }

    const result = await APIs.DailyUsage.getDaily(queries);
    runInAction(() => {
      this._usages.clear();
      this._usageKeys.length = 0;
      result.content.forEach(daily => {
        this._usageKeys.push(dayjs(daily.date).format('YYYY-MM-DD'));
        daily.usageList.forEach(usage => {
          this._usages.set(dayjs(daily.date).format('YYYY-MM-DD') + usage.module, usage);
        });
      });
    });
  }

  async fetchMonthlyUsage(queries: MonthlyFilters) {
    if (queries.monthTo && queries.monthFrom) {
      // eslint-disable-next-line no-param-reassign
      queries.size = dayjs(queries.monthTo).diff(dayjs(queries.monthFrom), 'month');
    }

    const result = await APIs.MonthlyUsage.getMontly(queries);
    runInAction(() => {
      this._usages.clear();
      this._usageKeys.length = 0;
      result.content.forEach(monthly => {
        this._usageKeys.push(dayjs(monthly.month).format('YYYY-MM'));
        monthly.usageList.forEach(usage => {
          this._usages.set(dayjs(monthly.month).format('YYYY-MM') + usage.module, usage);
        });
      });
    });
  }

  get realTime(): RealtimeUsage {
    return this._realTime as RealtimeUsage;
  }

  get usages(): Map<string, ModuleUsageList> {
    return this._usages;
  }

  get usageKeys(): string[] {
    return this._usageKeys;
  }
}

const usageStore = new UsageStore();

export default usageStore;
