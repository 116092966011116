import { WorkflowAction, WorkflowActionType } from '@repositories/workflowRepository/Types';
import { ConnectionRespModel } from '@models/connection/ConnectionRespModel';

export class WorkflowActionModel {
  private readonly _actionArguments: unknown;
  private readonly _connection: ConnectionRespModel;
  private readonly _connectionId: string;
  private readonly _method: string;
  private readonly _type: WorkflowActionType;

  constructor(dto: WorkflowAction) {
    this._actionArguments = dto.actionArguments;
    this._connectionId = dto.connectionId || '';
    this._method = dto.method || '';
    this._type = dto.type || 'CONNECTION_ACTION';
    this._connection = dto.connection ? new ConnectionRespModel(dto.connection) : new ConnectionRespModel({});
  }

  get actionArguments() {
    return this._actionArguments;
  }

  get connectionId() {
    return this._connectionId;
  }

  get method() {
    return this._method;
  }

  get type() {
    return this._type;
  }

  get connection() {
    return this._connection;
  }
}
