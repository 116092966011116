import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { colorDefinition } from '@theme/colorsCustomer';
import { fonts } from '@theme/fontsCustomer';
import { Button } from '..';

export const Toast = observer(() => {
  const { t } = useTranslation();
  const {
    uiStore: { toastStore },
  } = useStore();

  return (
    <StyledSnackbar
      open={toastStore.visible}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: toastStore.vertical, horizontal: toastStore.horizontal }}
      onClose={toastStore.close}
      message={toastStore.message}
      action={
        toastStore.hideAction ? null : (
          <ToastCloseButton variant="text" size="small" onClick={toastStore.action?.onClick ?? toastStore.close}>
            {toastStore.action?.text || `${t('Member_User_24')}`}
          </ToastCloseButton>
        )
      }
    />
  );
});

const StyledSnackbar = styled(Snackbar)`
  & .MuiPaper-root {
    padding: 14px 24px;
  }

  & .MuiSnackbarContent-message {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-white']};
    padding: 0px;
    min-height: 20px;
    white-space: pre;
  }

  & .MuiSnackbarContent-action {
    margin-right: 0px;
  }
`;

const ToastCloseButton = styled(Button)`
  ${fonts.Body2};
  color: ${colorDefinition.blue[400]};
  height: 20px;
`;
