import type { Swiper as SwiperType } from 'swiper';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Navigation } from 'swiper/modules';
import { Button } from '@components/index';
import { CardElement } from '@components/products';
import { useStore } from '@stores/RootStore';
import { ChevronIcon } from '@icons/ChevronIcon';
import { ErrorIcon } from '@icons/ErrorIcon';
import { useGetCategories } from '@queryHooks/useCategory';
import { useGetSubscriptionList } from '@queryHooks/useSubscription';
import { CategorySimpleModel } from '@models/category/CategorySimpleModel';
import type { SoftwareSimpleModel } from '@models/softwareModels';
import * as S from './ProductInCompanyStyles';

interface Props {
  type?: 'Software' | 'Hardware' | '';
}

export const ProductInCompany = observer(({ type = '' }: Props) => {
  const supportsCustomCategory = process.env.REACT_APP_CUSTOM_ROOT_CATEGORY?.toLowerCase() === 'true';
  const { t } = useTranslation();
  const theme = useTheme();
  const { rootCategoryId } = useParams();
  const { categoryStore } = useStore();
  const navigate = useNavigate();

  const [products, setProducts] = useState<Map<string, SoftwareSimpleModel[]>>(
    new Map<string, SoftwareSimpleModel[]>(),
  );

  const [categories, setCategories] = useState<CategorySimpleModel[]>([]);
  const [curCategory, setCurCategory] = useState<string>('all');

  const { data: subscriptions } = useGetSubscriptionList();
  const { data: rootCategories, isFetching } = useGetCategories({ isRootCategory: true });

  // NOTE. 카테고리 선택하지 않았을 때, 디폴트 카테고리 탭으로 이동
  useEffect(() => {
    if (rootCategoryId || type) return;
    if (!supportsCustomCategory) {
      navigate('software');
    } else if (!isFetching && rootCategories?.content?.[0].id) {
      navigate(rootCategories.content[0].id);
    }
  }, [isFetching, navigate, rootCategories, rootCategoryId, supportsCustomCategory, type]);

  useEffect(() => {
    const productMap = new Map<string, SoftwareSimpleModel[]>();

    let companyProducts =
      subscriptions?.content
        .filter(sb => {
          const rootCategory = categoryStore.findRootCategory(sb.software.category.id);
          return rootCategory?.name === type || rootCategory?.id === rootCategoryId;
        })
        .map(subscription => subscription.software) || [];

    companyProducts = _.uniqBy(companyProducts, 'id');

    productMap.set('all', companyProducts);

    const deduplCategories = _.uniqBy(
      companyProducts.map(product => product.category),
      'id',
    );

    deduplCategories.forEach(ct => {
      const productsOfCategory = companyProducts?.filter(product => product.category.id === ct.id) || [];
      productMap.set(ct.id, productsOfCategory);
    });

    setProducts(productMap);
    deduplCategories.splice(0, 0, new CategorySimpleModel({ id: 'all', code: 'all', name: `${t('Acc_Main_12')}` }));
    setCategories(deduplCategories);
  }, [subscriptions, type, t, categoryStore, rootCategoryId]);

  useEffect(() => {
    setCurCategory('all');
  }, [type]);
  const swiperRef = useRef<SwiperType>();

  const handleProductClick = (e: React.MouseEvent<HTMLLIElement>, software: SoftwareSimpleModel) => {
    e.stopPropagation();
    navigate(`/store/product/${software.id}`);
  };

  return subscriptions ? (
    <S.Container>
      <S.Categories>
        <S.StyledSwiper
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          slidesPerView="auto"
          spaceBetween={4}
          modules={[Navigation]}
        >
          {categories.map(ct => {
            return (
              <S.StyledSwiperSlide key={ct.id}>
                <S.Button
                  className={curCategory === ct.id ? 'category-selected' : ''}
                  variant="outline"
                  size="small"
                  paddingHorizontal={16}
                  onClick={() => setCurCategory(ct.id)}
                  disabled={products.get(curCategory)?.length === 0}
                >
                  {ct.name}
                </S.Button>
              </S.StyledSwiperSlide>
            );
          })}
          <button className="arrow swiper-button-prev" type="button" onClick={() => swiperRef.current?.slidePrev()}>
            <span>
              <ChevronIcon width={16} height={16} color={theme.colors['ic-gray-dark']} rotateNum={-90} />
            </span>
          </button>
          <button className="arrow swiper-button-next" type="button" onClick={() => swiperRef.current?.slideNext()}>
            <span>
              <ChevronIcon width={16} height={16} color={theme.colors['ic-gray-dark']} rotateNum={90} />
            </span>
          </button>
        </S.StyledSwiper>
      </S.Categories>
      {products.get(curCategory)?.length ? (
        <S.Products>
          {products.get(curCategory)?.map(product => {
            return (
              <CardElement
                sw={product}
                isHardware={type !== 'Hardware'}
                key={product.id}
                onClick={handleProductClick}
              />
            );
          })}
        </S.Products>
      ) : (
        <S.NoProdcutContainer>
          <S.NoProduct>
            <S.Exclaim>
              <ErrorIcon width={22} height={22} color={theme.colors['ic-purple-light']} />
            </S.Exclaim>
            <strong className="main-text">{t('Products_01')}</strong>
            <span className="sub-text">{t('Products_02')}</span>
            <Button variant="contain" size="medium" onClick={() => navigate(`/store`)} paddingHorizontal={16}>
              {t('Ad_Cart_07')}
            </Button>
          </S.NoProduct>
        </S.NoProdcutContainer>
      )}
    </S.Container>
  ) : null;
});
