import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TriangleIcon } from '@icons/TriangleIcon';
import { Typography } from '@mui/material';
import { UserGroupSubItem } from '@components/userGroupSelect/UserGroupSubItem';
import { useTheme } from '@emotion/react';
import { useGetTenantList } from '@queryHooks/useTenant';
import { useGetUserGroups } from '@queryHooks/useUserGroup';
import { createPortal } from 'react-dom';
import * as S from './UserGroupSelectStyle';

interface Props {
  optionWidth?: number;
  depth?: number;
  selectedValue: {
    userGroupId: string;
    name: string;
  };
  className?: string;
  onSelectChange: (
    e: React.MouseEvent<HTMLLIElement>,
    selected: {
      userGroupId: string;
      name: string;
    },
  ) => void;
}

const OPTION_WRAPPER_MAX_HEIGHT = 232;

export const UserGroupSelect = ({ depth = 0, selectedValue, onSelectChange, className, optionWidth }: Props) => {
  const theme = useTheme();
  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const optionWrapperRef = useRef<HTMLUListElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [isGroupMenuFolded, setIsGroupMenuFolded] = useState<boolean>(depth !== 0);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [optionWrapperPosition, setOptionWrapperPosition] = useState<Partial<DOMRect>>({
    width: 0,
    left: 0,
    top: undefined,
  });

  const { data: tenants, isSuccess: isTenantsSuccess } = useGetTenantList({ isRootTenant: true });

  const { data: subUserGroup } = useGetUserGroups(
    { tenantId: tenants?.content[0]?.id ?? '', isRoot: true },
    { enabled: !isGroupMenuFolded && !!tenants?.content[0]?.id },
  );

  const handleTenantToggleFold = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsGroupMenuFolded(!isGroupMenuFolded);
  };

  const handleToggle = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const isTenantsSeleted = selectedValue.userGroupId === '';
  const handleSeleted = (e: React.MouseEvent<HTMLLIElement>) => {
    if (e.currentTarget.dataset.name !== undefined && e.currentTarget.dataset.userGroupId !== undefined) {
      onSelectChange(e, { userGroupId: e.currentTarget.dataset.userGroupId, name: e.currentTarget.dataset.name });
      setIsSelectOpen(!isSelectOpen);
    }
  };

  const updatePosition = useCallback(() => {
    const selectWrapperRect = selectWrapperRef.current?.getBoundingClientRect();
    const optionWrapperRect = optionWrapperRef.current?.getBoundingClientRect();

    if (selectWrapperRect && optionWrapperRect) {
      const isOverflow = selectWrapperRect.bottom + OPTION_WRAPPER_MAX_HEIGHT > window.innerHeight;

      setOptionWrapperPosition({
        width: optionWidth || selectWrapperRect.width,
        left: selectWrapperRect.left,
        top: isOverflow ? selectWrapperRect.top - optionWrapperRect.height + 1 : selectWrapperRect.bottom - 1,
      });
    }
  }, [optionWidth, selectWrapperRef]);
  useEffect(() => {
    const observer = new ResizeObserver(updatePosition);

    if (isSelectOpen && optionWrapperRef.current) {
      observer.observe(optionWrapperRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isSelectOpen, updatePosition]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition); // resize 이벤트 리스너 추가
    return () => {
      window.removeEventListener('resize', updatePosition); // 컴포넌트 언마운트 시 리스너 제거
    };
  }, [updatePosition]);

  return (
    <S.SelectWrapper ref={selectWrapperRef}>
      <S.SelectedButton type="button" onClick={handleToggle} className={className}>
        <span> {selectedValue.name}</span>
        <TriangleIcon rotateNum={isSelectOpen ? 0 : 180} width={16} height={16} color={theme.colors['ic-gray-dark']} />
      </S.SelectedButton>
      {createPortal(
        <div className="groupModal-root">
          {isSelectOpen && isTenantsSuccess && (
            <>
              <S.OptionWrapper
                ref={optionWrapperRef}
                style={{
                  maxHeight: `${OPTION_WRAPPER_MAX_HEIGHT}px`,
                  width: optionWrapperPosition.width,
                  left: optionWrapperPosition.left,
                  top: optionWrapperPosition.top,
                }}
              >
                <S.OptionItem
                  data-user-group-id=""
                  data-name={tenants.content[0]?.name}
                  value={[tenants.content[0]?.id, tenants.content[0]?.name]}
                  onClick={handleSeleted}
                >
                  <S.ToggleButton variant="outline" type="button" onClick={handleTenantToggleFold}>
                    {isGroupMenuFolded ? (
                      <TriangleIcon
                        rotateNum={180}
                        width={16}
                        height={16}
                        color={isTenantsSeleted ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
                      />
                    ) : (
                      <TriangleIcon
                        width={16}
                        height={16}
                        color={isTenantsSeleted ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
                      />
                    )}
                  </S.ToggleButton>
                  <Typography
                    variant="body2"
                    component="span"
                    color={isTenantsSeleted ? theme.colors['ic-purple'] : theme.colors['ic-gray-main']}
                  >
                    {tenants.content[0]?.name}
                  </Typography>
                </S.OptionItem>
                {!isGroupMenuFolded
                  ? subUserGroup?.map(userGroup => (
                      <UserGroupSubItem
                        value={userGroup.userGroupId}
                        rowData={userGroup}
                        onClick={handleSeleted}
                        selectedUserGroupId={selectedValue.userGroupId}
                        key={userGroup.userGroupId}
                      />
                    ))
                  : null}
              </S.OptionWrapper>
              <S.Dimmed ref={selectRef} onClick={handleToggle} />
            </>
          )}
        </div>,
        document.body,
      )}
    </S.SelectWrapper>
  );
};
