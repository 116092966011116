import styled from '@emotion/styled';
import { List, ListItem, OutlinedInput } from '@mui/material';

export const TagsTextField = styled(OutlinedInput)`
  width: 392px;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 14px;
  max-height: 128px;
  overflow-y: auto;

  & input.MuiInputBase-input {
    display: flex;
    flex: 1;
    height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const OptionList = styled(List)`
  position: absolute;
  padding: 0px;
  border-radius: 8px;
  max-height: 280px;
  z-index: 9999;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05), -4px 4px 5px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  overflow-y: auto;
`;

export const Option = styled(ListItem, { shouldForwardProp: propName => propName !== 'isHover' })<{
  isHover: boolean;
}>`
  background-color: ${({ isHover }) => (isHover ? '#F2F5FF' : '#ffffff')};
  display: flex;
  margin: 0px 16px;
  width: calc(100% - 32px);
  padding: 12px 0px;
  border-bottom: 1px solid #e9e9ed;
`;
