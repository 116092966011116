import type { UseQueryOptionsType } from './UseQueryOptionsType';
import type { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import authStore from '@stores/AuthStore';
import { useStore } from '@stores/RootStore';
import { KEYS, repository } from '@repositories/Repository';
import type {
  ChangePassword,
  PasswordInitialUpdateRq,
  ProfileUpdateRq,
  User,
  UserCreateRq,
  UserFilter,
  UserInviteRq,
  UserPasswordRecoveryDTO,
  UserSignInRq,
  UserStatusUpdateRq,
  UserUpdateRq,
} from '@repositories/userRepository';
import type IUserRepository from '@repositories/userRepository/IUserRepository';
import type { Page } from '@type/Page';
import { ProfileModel } from '@models/user/ProfileModel';
import { UserModel } from '@models/user/UserModel';
import { swQueryKey } from './useSoftware';
import { tenantMemberQueryKey } from './useTenant';
import { userGroupQueryKey } from './useUserGroup';

const userRepo = repository.get<IUserRepository>(KEYS.USER_REPOSITORY);

export const userQueryKey = {
  all: ['user'] as const,
  lists: () => [...userQueryKey.all, 'list'] as const,

  list: (filter: UserFilter) => [...userQueryKey.lists(), filter] as const,
  details: () => [...userQueryKey.all, 'detail'] as const,
  detail: (id: string) => [...userQueryKey.details(), id] as const,
};

const profileQueryKey = {
  detail: () => ['profile'] as const,
};

export const useGetUserList = (queries: UserFilter, options?: UseQueryOptionsType<Page<UserModel>>) =>
  useQuery<Page<UserModel>, AxiosError>(
    userQueryKey.list(queries),
    async () => {
      const result = await userRepo.getList(queries);
      return {
        ...result,
        content: result.content.map(dto => new UserModel(dto)),
      };
    },
    {
      ...options,
    },
  );

export const useGetUser = (id: UserId, options?: UseQueryOptionsType<UserModel>) =>
  useQuery<UserModel, AxiosError>({
    queryKey: userQueryKey.detail(id),
    queryFn: async () => {
      const result = await userRepo.getOne(id);
      return new UserModel(result);
    },
    ...options,
    enabled: !!id && (options?.enabled !== undefined ? options.enabled : true),
    cacheTime: 0,
  });

export const useGetUserProfile = (id: UserId, options?: UseQueryOptionsType<ProfileModel>) =>
  useQuery<ProfileModel, AxiosError>({
    queryKey: profileQueryKey.detail(),
    queryFn: async () => {
      const result = await userRepo.getProfile(id);
      return new ProfileModel(result);
    },
    ...options,
    enabled: !!id && (options?.enabled !== undefined ? options.enabled : true),
    cacheTime: 0,
  });

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ProfileUpdateRq & { id: string }) => {
      const result = await userRepo.updateProfile(data.id, {
        address: data.address,
        name: data.name,
        phone: data.phone,
        userGroupIdList: data.userGroupIdList,
      });
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(profileQueryKey.detail());
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
      queryClient.invalidateQueries(userGroupQueryKey.lists());
    },
  });
};

export const useAddUser = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UserCreateRq) => userRepo.create(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKey.lists());
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UserUpdateRq) => {
      const { id } = await userRepo.update(data.id, data);
      return id;
    },
    {
      onSuccess: id => {
        queryClient.invalidateQueries(userQueryKey.detail(id));
        queryClient.invalidateQueries(userQueryKey.lists());
      },
    },
  );
};

export const useChangeUserPassword = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ChangePassword) => {
      await userRepo.changePassword(data.userId, data.password);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(profileQueryKey.detail());
      },
    },
  );
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => userRepo.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKey.lists());
    },
  });
};

export const useSignIn = (isAdmin?: boolean) => {
  const queryClient = useQueryClient();
  const { tenantId } = useParams();

  return useMutation<User, AxiosError | Error, UserSignInRq>(async (rqData: UserSignInRq) => userRepo.signIn(rqData), {
    onSuccess: data => {
      const success = authStore.login(data.id, data.name, data.additionalPermissions, tenantId, isAdmin ?? false);
      if (!success) {
        throw Error('INVALID_TENANT');
      }

      // 회사가 제품을 구독하는지 여부가 로그인 후에 값이 제대로 나옴 -> 로그인 후에 제품 쿼리키 invalidate하여 다시 조회하도록 처리
      queryClient.invalidateQueries(swQueryKey.all);
    },
    onError: (/* error: AxiosError */) => {
      throw Error('로그인 실패');
    },
  });
};

export const useLogout = () => {
  const { authStore } = useStore();
  const queryClient = useQueryClient();

  const logout = () => {
    authStore.logout();
    queryClient.clear();
  };

  return { logout };
};

export const useInviteUserToTenant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: UserInviteRq) => userRepo.inviteUserToTenant(rqData),
    onSuccess: () => {
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
    },
  });
};

export const useResendInviteMail = () => {
  return useMutation({
    mutationFn: async (userId: string) => userRepo.inviteUserToTenant(undefined, userId),
  });
};

export const useUpdateUserStatus = () => {
  type UserStatusUpdate = {
    userId: string;
    rqData: UserStatusUpdateRq;
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: UserStatusUpdate) => userRepo.updateUserStatus(rqData.userId, rqData.rqData),
    onSuccess: () => {
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
    },
  });
};

export const useInitialPassword = () => {
  return useMutation({
    mutationFn: async (rqData: PasswordInitialUpdateRq) => userRepo.initialPassword(rqData),
    cacheTime: 100,
  });
};

export const useInviteUsersToTenant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rqData: UserInviteRq[]) => userRepo.inviteUsersToTenant(rqData),
    onSuccess: () => {
      queryClient.invalidateQueries(tenantMemberQueryKey.lists());
    },
    cacheTime: 100,
  });
};

export const useSendPasswordRecoveryMail = () => {
  type MutationVariables = {
    rqData: UserPasswordRecoveryDTO;
  };

  return useMutation({
    mutationFn: async (variables: MutationVariables) => userRepo.recoveryPassword({ email: variables.rqData.email }),
  });
};
