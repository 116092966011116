import type { PasswordChangeFormType } from './validationSchema';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { useSendPasswordRecoveryMail } from '@queryHooks/useUser';
import { PasswordChangeFormOptions } from './validationSchema';

export const SearchPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordChangeFormType>(PasswordChangeFormOptions);

  const validation = {
    success: (data: PasswordChangeFormType) => {
      sendPasswordRecoveryMail(
        { rqData: data },
        {
          onSuccess: () => {
            navigate(`/confirm-mail/${data.email}`);
          },
        },
      );
    },
    fail: (err: any) => {
      console.log('err', err);
    },
  };

  const { mutate: sendPasswordRecoveryMail, isLoading } = useSendPasswordRecoveryMail();

  return (
    <Container onSubmit={handleSubmit(validation.success, validation.fail)}>
      <Description>{t('Search_Password_01')}</Description>
      <Email>
        <EmailLabel>{t('Product_Inquiry_03')}</EmailLabel>
        <TextField
          {...register('email')}
          size="large"
          fullWidth
          error={!!errors.email?.message}
          helperText={errors.email?.message}
        />
      </Email>
      <PasswordChangeBtn variant="contain" size="2extraLarge" fullWidth type="submit" disabled={isLoading}>
        {t('GNB_Profile_MyProfile_09')}
      </PasswordChangeBtn>
    </Container>
  );
};

const Container = styled.form`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.div`
  ${fonts.Headline3};
  margin-top: 160px;
  text-align: center;
  white-space: pre;
`;

const Email = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  gap: 6px;
`;

const EmailLabel = styled.label`
  margin-top: 54px;
  ${fonts.Headline8};
`;

const PasswordChangeBtn = styled(Button)`
  width: 380px;
  margin-top: 20px;
  background: ${props => props.theme.colors['bg-gradient']};
`;
