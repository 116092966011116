import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { fonts } from '@theme/fontsCustomer';
import { useTextOverflowDetection } from '@customHooks/useTextOverflowDetection';
import type { SubscriptionListModel } from '@models/SubscriptionListModel';

type Props = {
  subscription: SubscriptionListModel;
  onSubscriptionClick: (model: SubscriptionListModel) => void;
};
export const SubscriptionItem = ({ subscription, onSubscriptionClick }: Props) => {
  const [isOverflow, callbackRef] = useTextOverflowDetection();

  const handleSubscriptionClick = (model: SubscriptionListModel) => () => {
    onSubscriptionClick(model);
  };

  return (
    <SubscriptionWrapper key={subscription.id} onClick={handleSubscriptionClick(subscription)}>
      <AppIcon src={subscription.software.iconUrl} width={50} height={50} loading="lazy" />
      <NameWrapper>
        <SoftwareName>{subscription.software.name}</SoftwareName>
        <Tooltip
          title={subscription.name}
          disableHoverListener={!isOverflow}
          arrow
          placement="bottom-start"
          componentsProps={{ arrow: { sx: { transform: 'translate(20px, 0) !important' } } }}
        >
          <SubscriptionName ref={callbackRef}>{subscription.name}</SubscriptionName>
        </Tooltip>
      </NameWrapper>
    </SubscriptionWrapper>
  );
};

const SubscriptionName = styled.div`
  ${fonts.Body2}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubscriptionWrapper = styled.li`
  display: flex;
  gap: 12px;
  cursor: pointer;

  &:hover ${SubscriptionName} {
    text-decoration: underline;
  }
`;

const AppIcon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-bk-opacity-10%']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 172px;
`;

const SoftwareName = styled.div`
  ${fonts.Headline9}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
